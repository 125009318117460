/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';
import { IconProps } from 'components/assets/Icons/Icons';

export const LocationMarkIconSvg = (props: CustomIconComponentProps | SVGProps<SVGSVGElement>) => (
    <svg {...props} viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Location mark">
            <path id="Stroke 5" d="M4.14018 16.6667H11.2513" stroke="#676DFD" strokeWidth="0.75" />
            <path
                id="Stroke 1"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.70702 1.33325C10.7852 1.33325 13.2804 3.82925 13.2804 6.90659C13.2804 8.34392 12.7941 9.51547 11.8377 10.6381C10.883 11.7599 7.69547 14.5555 7.69547 14.5555C7.69547 14.5555 4.50791 11.7599 3.55324 10.6381C2.59769 9.51547 2.11147 8.34392 2.11147 6.90659C2.11147 3.82925 4.60658 1.33325 7.6848 1.33325H7.70702Z"
                stroke="#49565D"
                strokeWidth="0.75"
                strokeLinecap="round"
            />
            <path
                id="Stroke 3"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.82159 6.83154C9.82159 8.00577 8.86959 8.95777 7.69537 8.95777C6.52204 8.95777 5.57004 8.00577 5.57004 6.83154C5.57004 5.65821 6.52204 4.70532 7.69537 4.70532C8.86959 4.70532 9.82159 5.65821 9.82159 6.83154Z"
                fill="white"
                stroke="#49565D"
                strokeWidth="0.75"
                strokeLinecap="round"
            />
        </g>
    </svg>
);

export const LocationMarkIcon = (props: IconProps) => (
    <Icon component={LocationMarkIconSvg} {...props} />
);
