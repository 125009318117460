import { useCallback } from 'react';
import { Complex } from '@placer-ui/types/src/entities/place';
import {
    onlyForPurchasedPropertiesText,
    useOpenVoidAnalysisAsDropdownOption,
} from 'features/void-analysis/sections/entry-point-redirection/hooks/entry-point-redirect-hooks';
import { useExplorePermissions } from 'features/explore/hooks/use-explore-permissions';
import { EMPTY_SPACE_POI_TYPE } from 'features/empty-space/constants/empty-space-constants';
import { useSplunkDataForVoidAnalysis } from 'features/explore/hooks/use-explore-tracking';
import { getDeviceType } from 'utils/get-user-agent-data/utils';
import { useCheckCoordinateForNearbyReport } from 'features/explore/hooks/use-explore-nearby-activity';
import { useFullscreenContext } from 'utils/fullscreen-provider/fullscreen-provider';

export const useExploreVoidOpenReport = () => {
    const openVoidAnalysisAsDropdownOption = useOpenVoidAnalysisAsDropdownOption();
    const { hasOpenExploreReportSamePageInTablet } = useExplorePermissions();
    const { isFullscreen } = useFullscreenContext();

    const splunkDataForVoidAnalysis = useSplunkDataForVoidAnalysis();
    const checkCoordinateForNearbyReport = useCheckCoordinateForNearbyReport();

    return useCallback(
        (poi: Complex, splunkComponent?: string) => {
            const openInNewTab =
                getDeviceType() === 'desktop' || !hasOpenExploreReportSamePageInTablet;

            const voidAnalysisButton = openVoidAnalysisAsDropdownOption({
                poi,
                splunkData: splunkDataForVoidAnalysis(splunkComponent),
                openInNewTab,
                isEmptySpace: poi.type === EMPTY_SPACE_POI_TYPE,
            });
            const { text: nearbyTooltip, disabled: nearbyDisabled } =
                checkCoordinateForNearbyReport(poi);

            return {
                ...voidAnalysisButton,
                disabled:
                    voidAnalysisButton.disabled ||
                    nearbyDisabled ||
                    poi?.access?.level === 'not_allowed',
                tooltip:
                    voidAnalysisButton.tooltip ||
                    nearbyTooltip ||
                    (poi?.access?.level === 'not_allowed' ? onlyForPurchasedPropertiesText : ''),
                icon: voidAnalysisButton.icon,
                useTooltipParentContainer: isFullscreen,
            };
        },
        [
            isFullscreen,
            checkCoordinateForNearbyReport,
            hasOpenExploreReportSamePageInTablet,
            openVoidAnalysisAsDropdownOption,
            splunkDataForVoidAnalysis,
        ],
    );
};
