/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

type ArrowsThreeToIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type ArrowsThreeToIconProps = SVGProps<SVGSVGElement> & ArrowsThreeToIconUniqueProps;

export const ArrowsThreeToIcon = function ArrowsThreeToIcon({
    width = 48,
    height = 147,
    ...props
}: ArrowsThreeToIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 48 147"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M2.76353 73.7148C34.3724 69.3504 -7.79243 12.3792 45.2402 12.3792M45.2402 12.3792L41.2403 8.37921M45.2402 12.3792L41.2403 16.3792"
                stroke="#767676"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2.76353 73.7148C34.3724 78.0793 -7.79243 135.05 45.2402 135.05M45.2402 135.05L41.2403 139.05M45.2402 135.05L41.2403 131.05"
                stroke="#767676"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2.7595 73.7151C16.7014 73.7151 45.2402 73.7151 45.2402 73.7151M45.2402 73.7151L41.2403 69.7148M45.2402 73.7151L41.2403 77.7149"
                stroke="#767676"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
ArrowsThreeToIcon.__tags = [] as string[];
