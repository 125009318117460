import { Position } from 'geojson';
import type { GeometryTypes } from './components/geometry/geometry';
import { range } from 'lodash';
import type { Geolocation } from '@placer-ui/types';
import type { MapConfiguration } from 'ui-components/google-map/types/types';
import { getLatLngWithOffset } from 'ui-components/google-map/hooks/use-get-map-position-with-offset';

const EARTH_MEAN_RADIUS_KM = 6371;
const EARTH_PERIMETER = 2 * Math.PI;
const EARTH_RADIUS = 6378137;
const DEVICE_INDEPENDENT_PIXELS = 256;

// Google Maps tiles are 256 device-independent pixels.
// https://support.google.com/maps/thread/127339245/curious-about-ground-simple-distance-gsd?hl=en
const METERS_ON_EQUATOR_AT_SMALLEST_ZOOM_LEVEL =
    (EARTH_PERIMETER * EARTH_RADIUS) / DEVICE_INDEPENDENT_PIXELS;

export const calcDistanceInMiles = (lat: number, zoom: number) => {
    const kmPerPx =
        (METERS_ON_EQUATOR_AT_SMALLEST_ZOOM_LEVEL * Math.cos((lat * Math.PI) / 180)) /
        Math.pow(2, zoom);
    return kmToMiles(kmPerPx);
};

export const refreshZoom = (map: google.maps.Map) => {
    // inside modals, we need to refresh the map in delay
    // (the map is not ready when the component is rendered in it's final size)
    // also causes an issue with deck gl syncing https://github.com/visgl/deck.gl/issues/925

    google.maps.event.addListenerOnce(map, 'tilesloaded', () => {
        google.maps.event.addListenerOnce(map, 'tilesloaded', () => {
            google.maps.event.trigger(map, 'resize');
            map.setZoom(map.getZoom());
        });
    });
};

export const createFeatureCollection = ({ type, coordinates }: GeometryTypes) => {
    const features = [
        {
            type: 'Feature',
            geometry: {
                type,
                coordinates,
            },
        },
    ];

    return {
        type: 'FeatureCollection',
        features,
    };
};

export const positionToLatLngLiteral = (latLng: Position) => {
    let latitude = latLng[1];
    let longitude = latLng[0];

    return {
        lat: latitude,
        lng: longitude,
    };
};

export const extractGeometryGeoJsonPoints = (
    geoJson: GeometryTypes,
    disableOptimizeBoundsPoints?: boolean,
): google.maps.LatLngLiteral[] => {
    let boundsList: google.maps.LatLngLiteral[] = [];

    if (!geoJson) {
        return boundsList;
    }

    if (geoJson.type === 'Polygon') {
        geoJson?.coordinates.forEach((points: Position[]) => {
            boundsList = points.reduce((acc: google.maps.LatLngLiteral[], point) => {
                acc.push(positionToLatLngLiteral(point));
                return acc;
            }, []);
        });
    }

    if (geoJson.type === 'MultiPolygon' && geoJson?.coordinates) {
        geoJson?.coordinates.forEach((coordinatesSet: Position[][]) => {
            coordinatesSet.forEach((points: Position[]) => {
                if (disableOptimizeBoundsPoints) {
                    const latLngLiteralPoints = points.map((point) =>
                        positionToLatLngLiteral(point),
                    );
                    boundsList.push(...latLngLiteralPoints);
                } else {
                    for (let i = 1; i < 4; i++) {
                        const boundingPoint = points[Math.round(points.length / i) - 1];
                        const boundingPointObject = positionToLatLngLiteral(boundingPoint);

                        boundsList.push(boundingPointObject);
                    }
                }
            });
        });
    }

    return boundsList;
};

export const extractGeometryFromCircle = (
    center: google.maps.LatLngLiteral,
    radius: number,
    segments: number = 360,
): google.maps.LatLngLiteral[] => {
    return range(0, 360, 360 / segments).map((bearing: number) =>
        calcDestinationPoint({
            center,
            bearing,
            distance: radius,
        }),
    );
};

const toRad = (num: number) => {
    return (num * Math.PI) / 180;
};

const toDeg = (num: number) => {
    return (num * 180) / Math.PI;
};

type CalcDestinationPointType = {
    center: google.maps.LatLngLiteral;
    bearing: number;
    distance: number;
};

// https://stackoverflow.com/a/2637079
export const calcDestinationPoint = ({ center, bearing, distance }: CalcDestinationPointType) => {
    distance = distance / EARTH_MEAN_RADIUS_KM;
    bearing = toRad(bearing);
    const { lat, lng } = center;
    const lat1 = toRad(lat),
        lon1 = toRad(lng);

    const lat2 = Math.asin(
        Math.sin(lat1) * Math.cos(distance) +
            Math.cos(lat1) * Math.sin(distance) * Math.cos(bearing),
    );

    const lon2 =
        lon1 +
        Math.atan2(
            Math.sin(bearing) * Math.sin(distance) * Math.cos(lat1),
            Math.cos(distance) - Math.sin(lat1) * Math.sin(lat2),
        );

    if (isNaN(lat2) || isNaN(lon2))
        return {
            lat,
            lng,
        };

    return new google.maps.LatLng(toDeg(lat2), toDeg(lon2));
};

export const milesToMeters = (miles: number) => {
    return miles * 1609.344;
};

export const feetToMeters = (feet: number) => {
    return feet * 0.3048;
};

export const milesToFeet = (miles: number) => {
    return miles * 5280;
};

export const metersToMiles = (meters: number) => {
    return meters * 0.000621371;
};

export const milesToKm = (meters: number) => {
    return meters * 1.609344;
};

export const kmToMiles = (meters: number) => {
    return meters * 0.621371;
};

export const svgToDataURL = (image: string) => {
    const buff = Buffer.from(image);
    const base64data = buff.toString('base64');

    return `data:image/svg+xml;base64,${base64data}`;
};

// http://www.movable-type.co.uk/scripts/latlong.html
export const calcCoordinatesDistanceMeters = (
    a: google.maps.LatLngLiteral,
    b: google.maps.LatLngLiteral,
    convertToMiles: boolean = false,
): number => {
    const lat1 = toRad(a.lat);
    const lat2 = toRad(b.lat);
    const deltaLats = toRad(b.lat - a.lat);
    const deltaLngs = toRad(b.lng - a.lng);

    const x =
        Math.sin(deltaLats / 2) * Math.sin(deltaLats / 2) +
        Math.cos(lat1) * Math.cos(lat2) * Math.sin(deltaLngs / 2) * Math.sin(deltaLngs / 2);
    const c = 2 * Math.atan2(Math.sqrt(x), Math.sqrt(1 - x));

    const distance = EARTH_MEAN_RADIUS_KM * c * 1000;
    return convertToMiles ? metersToMiles(distance) : distance;
};

// convert radius in meters to radius in pixels
export const convertRadiusMetersToPixels = (meters: number, zoom: number) => {
    return (meters / (2 * Math.PI * EARTH_MEAN_RADIUS_KM)) * Math.pow(2, zoom);
};

//convert radius in pixels to radius in meters
export const convertRadiusPixelsToMeters = (pixels: number, zoom: number) => {
    return (pixels * (2 * Math.PI * EARTH_MEAN_RADIUS_KM)) / Math.pow(2, zoom);
};

export function zoomToLocation(
    map: google.maps.Map,
    center: Geolocation,
    zoom: number,
    offsetX: number = 0,
    offsetY: number = 0,
) {
    map?.setOptions({
        zoom,
        center,
    });
    const newCenter = getLatLngWithOffset({
        mapRef: map,
        latLng: new google.maps.LatLng(center.lat, center.lng),
        offsetX,
        offsetY,
    });
    map?.setOptions({
        center: newCenter,
    });
}

const getMapBoundsParams = (bounds: google.maps.LatLngBounds) => {
    const ne = bounds.getNorthEast().toJSON();
    const sw = bounds.getSouthWest().toJSON();

    return {
        ne: [ne.lat, ne.lng],
        sw: [sw.lat, sw.lng],
    };
};

export const extractCurrentMapConfiguration = (map: google.maps.Map<Element>): MapConfiguration => {
    const { ne, sw } = getMapBoundsParams(map.getBounds()!);
    const center = map.getCenter().toJSON();
    const zoom = map.getZoom();

    return {
        center,
        zoom,
        ne,
        sw,
    };
};