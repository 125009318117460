import type { Place } from '@placer-ui/types';
import { isRestrictedArea } from 'core/services/report-entities-service/report-entities-service';
import { useCallback } from 'react';
import { selectHasUserPermission } from 'store/selectors/app-selectors';
import { useSelectorWithProps } from 'utils/structured-selector/structured-selector';

export const useHasRestrictPoisIfPartOfCriticalPermission = () => {
    return useSelectorWithProps(
        {
            permission: 'restrict_pois_if_part_of_critical',
        },
        selectHasUserPermission,
    );
};

export const useIsRestrictedPoi = () => {
    const hasRestrictPoisIfPartOfCriticalPermission =
        useHasRestrictPoisIfPartOfCriticalPermission();

    return useCallback(
        (poi: Place) => {
            return hasRestrictPoisIfPartOfCriticalPermission && isRestrictedArea(poi);
        },
        [hasRestrictPoisIfPartOfCriticalPermission],
    );
};
