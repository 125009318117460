/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

type PinMobileIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type PinMobileIconProps = SVGProps<SVGSVGElement> & PinMobileIconUniqueProps;

export const PinMobileIcon = function PinMobileIcon({
    width = 14,
    height = 16,
    ...props
}: PinMobileIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 14 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M11.8982 10.7541L11.8981 10.7542C11.3465 11.4009 10.1285 12.6141 9.03009 13.6819C8.48469 14.2121 7.97464 14.7008 7.60087 15.057C7.50142 15.1518 7.41164 15.2372 7.33344 15.3114C7.25525 15.2372 7.16549 15.1518 7.06606 15.057C6.69234 14.7008 6.18234 14.2121 5.63697 13.6819C4.53862 12.6141 3.32062 11.4009 2.76866 10.7541L2.76858 10.754C1.69448 9.49588 1.16675 8.21248 1.16675 6.63599C1.16675 3.24834 3.92075 0.5 7.31954 0.5H7.34671C10.7461 0.5 13.5001 3.24837 13.5001 6.63599C13.5001 8.21239 12.9718 9.49584 11.8982 10.7541Z"
                fill="currentColor"
                stroke="currentColor"
            />
            <ellipse cx="7.33333" cy="6.66665" rx="2.33333" ry="2.33333" fill="white" />
        </svg>
    );
};
PinMobileIcon.__tags = [] as string[];
