import memoize from 'lodash/memoize';
import isNil from 'lodash/isNil';
import {
    DEFAULT_VISITS,
    FILTERS_BY_DURATION,
    VISIT_DURATION_OPTIONS,
} from 'shared/constants/visit-duration';
import { VisitDurationSegments } from 'types/visit-duration';
import { FilterInsights, InsightsDate } from 'core/entities/filter';
import { FilterConfig, Filter } from 'features/insights/types/filter-types';
import type {
    Attribute,
    Attributes,
    BackendAttributeFilter,
    ConfigFilterValue,
    FilterAttribute,
    FilterConfigurationPayload,
    FilterOperator,
    FilterType,
} from '@placer-ui/types';
import {
    convertDistanceFilterData,
    convertDistanceFilterDataFromUrl,
    convertTimeFilterData,
    convertTimeFilterDataFromUrl,
} from 'features/insights/utils/convert-filters/convert-multiple-values-filters';
import { shouldRenderMobileApp } from 'utils/detect-environment';
import { metersToMiles, milesToMeters } from 'ui-components/google-map/utils';
import { APP_CONSTS } from 'core/constants/app-consts';

const OPERATORS = APP_CONSTS.filter.operators;
const convertMinMaxFilterToInBetween = (extraFilter: FilterConfig, currentFilter?: FilterConfig) => {
    let filterValue;
    let operator = extraFilter.operator;
    if (currentFilter) {
        const currentValue = currentFilter.value;
        const currentOperator = currentFilter.operator;
        operator = OPERATORS.BETWEEN as FilterOperator;
        if (
            currentOperator ===
                OPERATORS.LESS_THEN_OR_EQUAL ||
            currentOperator === OPERATORS.LESS_THEN
        ) {
            filterValue = [
                extraFilter.value as number,
                currentValue as number,
            ];
        } else {
            filterValue = [
                currentValue as number,
                extraFilter.value as number,
            ];
        }
    } else {
        filterValue = extraFilter.value;
    }
    return {
        operator,
        value: filterValue,
    };
};

function convertInBetweenFilterToMinMax(config: FilterConfig, type: FilterType) {
    const { operator, value } = config;
    let minMaxFilter: Attribute[];
    if (operator === OPERATORS.BETWEEN) {
        const [min, max] = value as number[];
        const minFilter: Attribute = [
            APP_CONSTS.filter.operators.MORE_THEN_OR_EQUAL,
            type,
            min,
        ];
        const maxFilter: Attribute = [
            APP_CONSTS.filter.operators.LESS_THEN_OR_EQUAL,
            type,
            max,
        ];
        minMaxFilter = [minFilter, maxFilter];
    } else {
        minMaxFilter = [[operator, type, value as number] as Attribute];
    }
    return minMaxFilter;
}

export const fromBackendFilters = memoize(
    (backendFilters: Array<FilterAttribute | FilterInsights>) => {
        const filters: Filter[] = [{}];

        if (!backendFilters.length) {
            return filters;
        }
        for (const [index, backendFilter] of backendFilters.entries()) {
            filters[index] = {};
            for (const [key, value] of Object.entries(backendFilter)) {
                switch (key) {
                    case 'date': {
                        const dateFilter = value as InsightsDate;
                        filters[index].date = {
                            operator: OPERATORS.BETWEEN as FilterOperator,
                            value: [
                                dateFilter.start,
                                dateFilter.end,
                                ...(dateFilter.chosenLabel
                                    ? String(dateFilter.chosenLabel).split('|')
                                    : []),
                            ],
                        };
                        break;
                    }
                    case 'attributes': {
                        if (value) {
                            const attributesFilter = value as BackendAttributeFilter[];
                            for (const attribute of attributesFilter) {
                                if (typeof attribute !== 'string') {
                                    // ignore "all"
                                    let operator = attribute[0];
                                    // range filter
                                    if (operator === 'any') {
                                        attribute.slice(1).forEach((attr) => {
                                            const filterName = (attr as Attributes)[1][1];
                                            const converterFunc =
                                                filterName !== 'time'
                                                    ? convertDistanceFilterDataFromUrl
                                                    : convertTimeFilterDataFromUrl;
                                            const [customFilterName, customFilterValue] =
                                                converterFunc(attr as any);
                                            const prevFilterValue =
                                                filters[index][filterName as FilterType]?.value;
                                            let values: string[] = [];

                                            if (prevFilterValue) {
                                                if (typeof prevFilterValue !== 'string') {
                                                    values = [...(prevFilterValue as string[])];
                                                    values.push(customFilterValue);
                                                } else {
                                                    values.push(prevFilterValue);
                                                }
                                            }
                                            if (customFilterValue) {
                                                filters[index][customFilterName as FilterType] = {
                                                    operator:
                                                        filterName !== 'time' ? 'in' : 'between',
                                                    value:
                                                        values.length > 0
                                                            ? values
                                                            : [customFilterValue],
                                                };
                                            }
                                        });
                                    } else {
                                        const [, filterType, filterValue] = attribute;

                                        if (
                                            filterType === 'home_distance' ||
                                            filterType === 'work_distance'
                                        ) {
                                            if (!shouldRenderMobileApp()) {
                                                const backendValue = {
                                                    operator,
                                                    value: metersToMiles(
                                                        Number(filterValue),
                                                    ).toFixed(0),
                                                };
                                                filters[index][filterType] = convertMinMaxFilterToInBetween(
                                                  backendValue,
                                                  filters[index][filterType],
                                                );
                                            } else {
                                                const customFilterValue =
                                                    convertDistanceFilterDataFromUrl(
                                                        filterType,
                                                        operator,
                                                        filterValue,
                                                    ) as string;

                                                const prevFilterValue =
                                                    filters[index][filterType]?.value;
                                                let values: string[] = [];
                                                if (prevFilterValue) {
                                                    if (typeof prevFilterValue !== 'string') {
                                                        values = [...(prevFilterValue as string[])];
                                                        values.push(customFilterValue);
                                                    } else {
                                                        values.push(prevFilterValue);
                                                    }
                                                }
                                                filters[index][filterType] = {
                                                    operator: 'in',
                                                    value:
                                                        values.length > 0
                                                            ? values
                                                            : [customFilterValue],
                                                };
                                            }
                                        } else {
                                            let formattedFilterValue;
                                            switch (filterType) {
                                                case 'time':
                                                    formattedFilterValue = [
                                                        (filterValue as string[]).join('-'),
                                                    ];
                                                    break;
                                                case 'visit_duration':
                                                    formattedFilterValue = filterValue;

                                                    break;
                                                case 'days_of_week':
                                                case 'household_income':
                                                    formattedFilterValue = (filterValue as number[])
                                                        .filter((val) => !isNil(val))
                                                        .map((val) => val.toString());
                                                    break;
                                                case 'visit_frequency':
                                                    // If needed (both min and max is selected), the backend uses two
                                                    // instances of the filter to represent the range. Merge it into one
                                                    // value with the 'between' operator.
                                                    const currentFilter = filters[index][filterType];
                                                    if (currentFilter) {
                                                        const currentValue = currentFilter.value;
                                                        const currentOperator = currentFilter.operator;
                                                        operator = OPERATORS.BETWEEN as FilterOperator;
                                                        if (
                                                            currentOperator ===
                                                                OPERATORS.LESS_THEN_OR_EQUAL ||
                                                            currentOperator === OPERATORS.LESS_THEN
                                                        ) {
                                                            formattedFilterValue = [
                                                                filterValue as number,
                                                                currentValue as number,
                                                            ];
                                                        } else {
                                                            formattedFilterValue = [
                                                                currentValue as number,
                                                                filterValue as number,
                                                            ];
                                                        }
                                                    } else {
                                                        formattedFilterValue = filterValue;
                                                    }
                                                    break;
                                                default:
                                                    formattedFilterValue = filterValue;
                                                    break;
                                            }
                                            filters[index][filterType] = {
                                                operator,
                                                value: formattedFilterValue,
                                            };
                                        }
                                    }
                                }
                            }
                        }
                        break;
                    }
                    case 'config':
                        if (value) {
                            filters[index].config = {
                                operator: '==',
                                value: value as ConfigFilterValue,
                            };
                        }
                        break;
                }
            }
        }
        return filters;
    },
);

export const toBackendFilters = memoize(
    (filters: Filter[], options = { withDefaultFilters: false }) => {
        const backendFilters: FilterInsights[] = [];

        for (const filter of filters) {
            const backendFilter = {} as FilterInsights;
            for (const entry of Object.entries(filter)) {
                const [type, config] = entry as [FilterType, FilterConfig];

                if (!config || (!options.withDefaultFilters && config.isDefaultFilter)) {
                    continue;
                }

                switch (type) {
                    case 'time': {
                        const { operator, value } = config;
                        if (!backendFilter.attributes) {
                            backendFilter.attributes = ['all'];
                        }
                        if ((value as string[]).length > 1) {
                            convertTimeFilterData(value as string[], type, backendFilter);
                        } else {
                            const values = (value as string[])[0].split('-');
                            backendFilter.attributes.push([operator, type, values]);
                        }
                        break;
                    }
                    case 'date': {
                        const [start, end, chosenLabel, dateLabel] = config.value as string[];

                        let chosenLabelValue;
                        if (dateLabel) {
                            chosenLabelValue = `${chosenLabel}|${dateLabel}`;
                        } else if (chosenLabel) {
                            chosenLabelValue = chosenLabel;
                        }

                        backendFilter.date = {
                            start,
                            end,
                            ...{ chosenLabel: chosenLabelValue },
                        };
                        break;
                    }
                    case 'work_distance':
                    case 'home_distance': {
                        const { value } = config;
                        if (!backendFilter.attributes) {
                            backendFilter.attributes = ['all'];
                        }
                        if (shouldRenderMobileApp()) {
                            convertDistanceFilterData(value as string[], type, backendFilter);
                        } else {
                            const attrBackendFilter = convertInBetweenFilterToMinMax(config, type);
                            attrBackendFilter.forEach((attrFilter) => {
                                attrFilter[2] = milesToMeters(parseInt(attrFilter[2] as string));
                            });
                            backendFilter.attributes.push(...attrBackendFilter);
                        }
                        break;
                    }
                    case 'household_income': {
                        const { operator, value } = config;
                        if (!backendFilter.attributes) {
                            backendFilter.attributes = ['all'];
                        }
                        (value as string[]).length > 0 &&
                        backendFilter.attributes.push([
                            operator,
                            type,
                            (value as string[]).map((val) => parseInt(val as string)),
                        ]);
                        break;
                    }
                    case 'visit_duration': {
                        const { operator, value } = config;

                        if (value !== DEFAULT_VISITS) {
                            if (!backendFilter.attributes) {
                                backendFilter.attributes = ['all'];
                            }

                            const isNewVisitDuration = VISIT_DURATION_OPTIONS.includes(
                                value as string,
                            );

                            if (isNewVisitDuration) {
                                backendFilter.attributes.push(
                                    FILTERS_BY_DURATION[value as VisitDurationSegments]!,
                                );
                            } else {
                                backendFilter.attributes.push([
                                    operator,
                                    type,
                                    parseInt(value as string),
                                ]);
                            }
                        }
                        break;
                    }
                    case 'days_of_week': {
                        const { operator, value } = config;
                        if (!backendFilter.attributes) {
                            backendFilter.attributes = ['all'];
                        }
                        const numValues = (value as string[]).map((val) => parseInt(val)).sort();
                        backendFilter.attributes?.push([operator, type, numValues]);
                        break;
                    }
                    case 'config':
                        if (config) {
                            backendFilter.config = config.value
                                ? {
                                      ...(config.value as ConfigFilterValue),
                                  }
                                : { ...(config as FilterConfigurationPayload) };
                        }
                        break;
                    case 'visit_frequency':
                        if (!backendFilter.attributes) {
                            backendFilter.attributes = ['all'];
                        }
                        const attrBackendFilter = convertInBetweenFilterToMinMax(config, type);
                        backendFilter.attributes.push(...attrBackendFilter);
                        break;
                    default: {
                        const { operator, value } = config;

                        if (!backendFilter.attributes) {
                            backendFilter.attributes = ['all'];
                        }
                        // caused by ConfigFilterValue but this will never bt an instance of ConfigFilterValue
                        // @ts-expect-error
                        backendFilter.attributes.push([operator, type, value]);
                        break;
                    }
                }
            }

            backendFilters.push(backendFilter);
        }
        checkAndUpdateEmptyFilters(backendFilters);
        return backendFilters;
    },
);

const checkAndUpdateEmptyFilters = (backendFilters: FilterInsights[]) => {
    for (const backendFilter of backendFilters) {
        for (const [key, value] of Object.entries(backendFilter)) {
            switch (key) {
                case 'attributes': {
                    if (value[1]?.[2]?.length === 0) {
                        delete backendFilter[key];
                    }
                    break;
                }
                default:
                    break;
            }
        }
    }
};
