import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import type { FilterType, VisitDurationType } from '@placer-ui/types';
import { FilterConfig } from 'features/insights/types/filter-types';
import { FilterDescriptionOptions } from './types';
import { AUDIENCE_TYPE_DICT, DEFAULT_AUDIENCE } from 'shared/constants/audience-type';
import { timeOfDayValueOptions } from 'features/mobile-report/components/analyze-page/filters/time-of-day';
import { homeDistanceValueOptions } from 'features/mobile-report/components/analyze-page/filters/home-distance';
import { workDistanceValueOptions } from 'features/mobile-report/components/analyze-page/filters/work-distance';
import { dateFormats, getSameYearFormatRange } from 'utils/date/date';
import {
    MOBILE_VISITS_DURATION_TITLE_DICT,
    getVisitDurationValue,
} from 'shared/constants/visit-duration';
import { selectReportDefaultDates } from 'features/insights/store/selectors/insights-selectors';
import { getAudienceTypeFilterName } from 'features/insights/utils/filter-names/filter-names';
import { useCreateHouseholdIncomeOptions } from 'features/mobile-report/components/analyze-page/hooks/use-create-household-income-filter';
import { useAreSameFilters } from 'features/insights/hooks/use-filter-description/use-are-same-filters';

export const useMobileFilterDescription = () => {
    const defaultDates = useSelector(selectReportDefaultDates);
    const householdIncomeOptions = useCreateHouseholdIncomeOptions();
    const areSameFilters = useAreSameFilters();

    return useCallback(
        (
            key: FilterType,
            filterConfig: FilterConfig | undefined,
            options: FilterDescriptionOptions = { isCompact: false },
            all: any[] = [],
        ) => {
            const { isCompact } = options;
            const defaultDesc = isCompact ? '' : 'All Selected';
            switch (key) {
                case 'date':
                    if (filterConfig) {
                        const [start, end, dateLabel] = filterConfig?.value as string[];
                        const date = Object.values(defaultDates?.dates || {}).find(
                            (date) =>
                                date.start === start &&
                                date.end === end &&
                                (dateLabel ? date.name === dateLabel : true),
                        );

                        const formattedDate = getSameYearFormatRange(
                            start,
                            end,
                            dateFormats.monthDay,
                            dateFormats.monthDayAndYear,
                        );

                        return date?.name || formattedDate;
                    }
                    return defaultDesc;
                case 'days_of_week':
                    const daysOfWeek = (filterConfig?.value || []) as string[];

                    daysOfWeek.sort();
                    if (daysOfWeek.length) {
                        const daysWithName = daysOfWeek.slice(0, 2);
                        const restOfDays = daysOfWeek.slice(2);
                        const daysNames = daysWithName.map((day) =>
                            moment()
                                .weekday((parseInt(day) + 1) % 7)
                                .format(isCompact ? 'ddd' : 'dddd'),
                        );
                        return `${daysNames.join(', ')}${
                            restOfDays.length ? ` + ${restOfDays.length}` : ''
                        }`;
                    }
                    return defaultDesc;
                case 'time':
                    if (filterConfig) {
                        const resultTodOptionLabel: string[] = [];

                        timeOfDayValueOptions.forEach((valueOption) => {
                            if ((filterConfig.value as string[]).includes(valueOption.value)) {
                                resultTodOptionLabel.push(
                                    valueOption.name.split('(')[0].trim() || '',
                                );
                            }
                        });
                        return `${resultTodOptionLabel[0]}${
                            resultTodOptionLabel.length > 1
                                ? ` + ${resultTodOptionLabel.length - 1}`
                                : ''
                        }`;
                    }
                    return defaultDesc;
                case 'visits_type':
                    if (filterConfig) {
                        const { values } = getAudienceTypeFilterName(
                            filterConfig.value as string[],
                        );
                        if ((filterConfig.value as string[]).includes(DEFAULT_AUDIENCE)) {
                            return AUDIENCE_TYPE_DICT[DEFAULT_AUDIENCE];
                        } else if (!isCompact && values.length === 3) {
                            return defaultDesc;
                        } else {
                            const [first, ...rest] = values;
                            return `${first}${rest.length ? ` + ${rest.length}` : ''}`;
                        }
                    } else if (all.length > 1 && !areSameFilters(key)) {
                        return AUDIENCE_TYPE_DICT[DEFAULT_AUDIENCE];
                    }
                    return defaultDesc;
                case 'visit_duration':
                    if (filterConfig) {
                        return MOBILE_VISITS_DURATION_TITLE_DICT[
                            getVisitDurationValue(filterConfig) as VisitDurationType
                        ];
                    }

                    return defaultDesc;
                case 'home_distance':
                    if (filterConfig) {
                        const hdSelectedValuesNumber = (filterConfig.value as string[]).length;
                        const hdOptionLabel = homeDistanceValueOptions.find(
                            (option) => option.value === (filterConfig.value as string[])[0],
                        )?.name;
                        return (
                            `Home: ${
                                hdSelectedValuesNumber > 1
                                    ? `${hdOptionLabel} + ${hdSelectedValuesNumber - 1}`
                                    : hdOptionLabel
                            }` || ''
                        );
                    }
                    return defaultDesc;
                case 'work_distance':
                    if (filterConfig) {
                        const wdSelectedValuesNumber = (filterConfig.value as string[]).length;
                        const wdOptionLabel = workDistanceValueOptions.find(
                            (option) => option.value === (filterConfig.value as string[])[0],
                        )?.name;
                        return (
                            `Work: ${
                                wdSelectedValuesNumber > 1
                                    ? `${wdOptionLabel} + ${wdSelectedValuesNumber - 1}`
                                    : wdOptionLabel
                            }` || ''
                        );
                    }
                    return defaultDesc;
                case 'household_income':
                    if (filterConfig) {
                        const hhiSelectedValuesNumber = (filterConfig.value as string[]).length;
                        const hhiOptionName = householdIncomeOptions.find(
                            (option) => option.value === (filterConfig.value as string[])[0],
                        )?.name;
                        return (
                            `HHI: ${
                                hhiSelectedValuesNumber > 1
                                    ? `${hhiOptionName} + ${hhiSelectedValuesNumber - 1}`
                                    : hhiOptionName
                            }` || ''
                        );
                    }
                    return defaultDesc;
                default:
                    return defaultDesc;
            }
        },
        [areSameFilters, defaultDates?.dates, householdIncomeOptions],
    );
};
