import { SgMarketingIcon } from 'features/onboarding/solutions-guide/icons/sg-marketing-icon';
import React from 'react';

export const ADVERTISING_SOLUTIONS_GUIDE = {
    icon: <SgMarketingIcon />,
    solutions: [
        {
            title: 'Consumer Insights',
            description:
                'Access unique, near-real time consumer insights ' +
                'and create robust consumer profiles for any business.',
            url: '/advanced-reports/freemium/persona-live',
        },
        {
            title: 'Campaign Impact Analysis',
            description:
                'Measure campaign success, lift, or incrementality, with accurate offline foot traffic data.',
            url: '/insights/complexes/5c6df591c9c3ba20d333d8ef/overview',
        },
        {
            title: 'Sponsorships & Partnerships',
            description:
                'Leverage cross-shopping data to identify promising sponsorship and partnership opportunities.',
            url: '/insights/complexes/5c6df591c9c3ba20d333d8ef/overview',
        },
        {
            title: 'Audience Targeting',
            description:
                'Optimize direct mail or digital ad geotargeting to reach the highest potential zip codes.',
            url: '/insights/complexes/5c6df591c9c3ba20d333d8ef/trade-area',
        },
        {
            title: 'Market Trends',
            description:
                'Analyze and forecast industry trends to stay ahead of the curve and win new business.',
            url: '/labs/industry-trends',
        },
        {
            title: 'Out-Of-Home Planning',
            description:
                'Evaluate the reach and exposure of OOH ad units to understand and showcase their value.',
            url: '/insights/complexes/4c3c209b7f01faf7e651a79e/overview',
        },
    ],
};
