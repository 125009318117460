export enum ERROR_TYPES {
    page = 'advanced-reports',
    tab = 'site-selection',
    reportSelectionSection = 'report-selection-page',
    reportCreationSection = 'report-creation-transitioning-page',
    topMatchesSection = 'top-matches-page',
    rebuildSection = 'rebuild-page',
    matchAnalysisSection = 'match-analysis-page',
    reportInfoApi = 'fetch-report-info-failure',
    reportInfoStatus = 'report-info-status-failure',
    generalUnexpectedError = 'general-unexpected-failure',
    topMatchesRecordsApi = 'fetch-top-matches-records-failure',
    recentReportsApi = 'fetch-recent-reports-failure',
    sharedReportsApi = 'fetch-shared-reports-failure',
    topChainsApi = 'fetch-top-chains-failure',
    reportCreationApi = 'create-report-failure',
    searchTopMatchesMap = 'search-top-matches-map',
    editReportName = 'edit-report-name',
    fetchFilters = 'fetch-filters',
    fetchFiltersLayout = 'fetch-filters-layout',
    fetchChainVenues = 'fetch-chain-venues',
    saveSite = 'save-site-as-favorite',
    fetchEntityTTA = 'fetch-entity-tta',
    siteInfo = 'fetch-site-info',
    fetchScoreConfig = 'fetch-score-config',
    fetchDefaultScoreConfig = 'fetch-default-score-config',
    saveScoreConfig = 'save-score-config',
    scoreConfigFetchUserTags = 'score-config-fetch-user-tags',
    scoreConfigFetchRegionalChains = 'score-config-fetch-regonal-chains',
    siteOverviewRegionData = 'site-overview-region-data-update-failure',
    stateName = 'fetch-state-name-failure',
    rebuildReport = 'rebuild-report',
    fetchViewState = 'fetch-view-state',
    fetchLatestViewState = 'fetch-latest-view-state',
    fetchTraitStatus = 'fetch-trait-status',
    siteNotPurchased = 'site-not-purchased',
    siteRestricted = 'site-restricted',
}
