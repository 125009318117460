/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

export type BackArrowIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type BackArrowIconProps = SVGProps<SVGSVGElement> & BackArrowIconUniqueProps;

export const BackArrowIcon = function BackArrowIcon({
    width = 13,
    height = 22,
    ...props
}: BackArrowIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 13 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M10.5147 5.8902L5.50393 11.0355L4.54265 12.0899L5.50393 13.1442L10.5147 18.2895C10.9151 18.7007 10.9151 19.365 10.5147 19.7762C10.1142 20.1874 9.46735 20.1874 9.0669 19.7762L2.30034 12.8279C1.89989 12.4167 1.89989 11.7525 2.30034 11.3413L9.0669 4.393C9.46735 3.98179 10.1142 3.98179 10.5147 4.393C10.9151 4.8042 10.9151 5.47899 10.5147 5.8902Z"
                fill="#49565D"
            />
        </svg>
    );
};
BackArrowIcon.__tags = [] as string[];
