/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

type LocationMobileIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type LocationMobileIconProps = SVGProps<SVGSVGElement> & LocationMobileIconUniqueProps;

export const LocationMobileIcon = function LocationMobileIcon({
    width = 18,
    height = 18,
    ...props
}: LocationMobileIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.6577 18H4.65771V17.25H12.6577V18Z"
                fill="#E1E2FF"
            />
            <path
                d="M8.41017 15.5319C8.41021 15.5319 8.41023 15.5319 8.65749 15.25C8.90475 15.5319 8.90543 15.5313 8.90546 15.5313L8.65749 15.7488L8.41017 15.5319Z"
                fill="#5E63E5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.65736 3.79346C7.13005 3.79346 5.89136 5.03355 5.89136 6.56046C5.89136 8.08846 7.13015 9.32746 8.65736 9.32746C10.1855 9.32746 11.4244 8.08856 11.4244 6.56046C11.4244 5.03345 10.1856 3.79346 8.65736 3.79346ZM6.64136 6.56046C6.64136 5.44736 7.54467 4.54346 8.65736 4.54346C9.77115 4.54346 10.6744 5.44746 10.6744 6.56046C10.6744 7.67435 9.77125 8.57746 8.65736 8.57746C7.54457 8.57746 6.64136 7.67445 6.64136 6.56046Z"
                fill="#5E63E5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.41017 15.5319L8.65749 15.25L8.90546 15.5313L8.94575 15.4959L9.06192 15.3935C9.16244 15.3047 9.30713 15.1765 9.48408 15.0188C9.8379 14.7035 10.321 14.2698 10.838 13.7963C11.8643 12.8564 13.0478 11.7384 13.6029 11.0862C14.7289 9.76453 15.3155 8.3625 15.3155 6.645C15.3155 2.97593 12.3406 0 8.67049 0H8.64549C4.97534 0 2.00049 2.97593 2.00049 6.645C2.00049 8.36246 2.58703 9.76444 3.71191 11.086C4.26695 11.7382 5.4506 12.8563 6.47697 13.7963C6.99394 14.2698 7.47707 14.7035 7.8309 15.0188C8.00785 15.1765 8.15254 15.3047 8.25305 15.3935L8.36922 15.4959L8.41017 15.5319ZM2.75049 6.645C2.75049 3.39007 5.38963 0.75 8.64549 0.75H8.67049C11.9263 0.75 14.5655 3.39007 14.5655 6.645C14.5655 8.1615 14.0579 9.39562 13.0319 10.6C12.513 11.2098 11.3666 12.2952 10.3315 13.2432C9.81769 13.7137 9.3372 14.1451 8.98509 14.4589C8.85747 14.5726 8.74676 14.6709 8.65749 14.7499C8.56822 14.6709 8.45751 14.5726 8.32989 14.4589C7.97778 14.1451 7.49729 13.7137 6.98351 13.2432C5.94837 12.2952 4.80201 11.2097 4.28305 10.5999C3.25794 9.39555 2.75049 8.16153 2.75049 6.645Z"
                fill="#5E63E5"
            />
        </svg>
    );
};
LocationMobileIcon.__tags = [] as string[];
