import { findIndex } from 'lodash/fp';
import type {
    Billboard,
    Chain,
    ExternalVenueProviders,
    Place,
    PlacerEntityWrapper,
    PlacerResponseData,
    Venue,
    VenuePlaceTypes,
} from '@placer-ui/types';

import type { Restrictions, RestrictionsType } from 'types/entity-search';
import type { SwitchItem } from 'ui-components/dropdown-with-switches/dropdown-with-switches';
import {
    MAX_AMOUNT_OF_RECENT_POIS,
    MAX_AMOUNT_OF_BRANDS,
    COORDINATES_ENTITY,
    ADDRESS_ENTITY,
} from 'features/explore/constants';
import type { RecentEntitiesModel } from 'core/services/recent-entities-service/recent-entities-model';
import { fetchWrapper } from 'API/authentification/fetch-wrapper';
import type { PlaceOverlay } from 'ui-components/google-map-layers/types/google-map-layers-types';
import { reportException } from 'core/exceptions';
import type { ExploreSearchResult, MapboxDropPinTypeData } from 'features/explore/types/types';
import { EMPTY_SPACE_POI_TYPE } from 'features/empty-space/constants/empty-space-constants';
import { getFeatureFlags } from 'core/flow-control';
import { createCustomPOI } from 'utils/create-custom-poi/create-custom-poi';
import { createPlaceWithNearbyResult } from 'utils/create-place-with-nearby-result/create-place-with-nearby-result';
import { searchAPIMeasureWrapper } from 'API/search/search-request-measurment-wrapper';

export const getRestrictionsItems = (restrictions: SwitchItem<String>[]) => {
    return restrictions.reduce<Restrictions>((acc, item) => {
        const restrictionId = item.id as RestrictionsType;
        acc[restrictionId] = item.checked;
        return acc;
    }, {});
};

/**
 * This function get all unique pois from the searched results and limit them up to
 * MAX_NUMBER_OF_ITEMS_AT_AUTOSUGGEST
 */
export const getUniqueValues = (searchResults: RecentEntitiesModel[]) => {
    const uniqValues = searchResults.filter((searchResult, index) => {
        const firstVenueIndex = findIndex(
            {
                id: searchResult.id,
                type: searchResult.type,
            },
            searchResults,
        );

        return firstVenueIndex === index;
    });
    return uniqValues.slice(0, MAX_AMOUNT_OF_BRANDS + MAX_AMOUNT_OF_RECENT_POIS);
};

export const getSearchEntityInfo = async (
    id: string,
    source?: string,
): Promise<PlacerEntityWrapper<Billboard | Chain | Venue>[]> => {
    const url = `/search?query=${id}`;
    try {
        const { data } = await searchAPIMeasureWrapper(
            fetchWrapper<PlacerResponseData<PlacerEntityWrapper<Billboard | Chain | Venue>[]>>({
                targetUrl: url,
            }),
            source,
        );
        return data;
    } catch (err) {
        reportException(err, {
            method: 'GET',
            target_url: url,
        });
        return [];
    }
};

export const getSelectedPoiOverlay = (poiOverlays?: PlaceOverlay[], overlayId?: string) => {
    return poiOverlays?.find((item) => item.overlayID === overlayId);
};

export const createMapboxTypeToPlaceType = (data: MapboxDropPinTypeData): Place => {
    const { lng, type, id, lat, name, state, formatted_address } = data;
    return {
        id,
        type: type,
        category_info: {
            sub_category: 'Address',
            group: 'Address',
            primary: 'Address',
        },
        access: {
            level: 'full',
            approval_status: 'approved',
            warnings: [],
        },
        collection: 'empty_spaces',
        name,
        purchased: false,
        address: {
            address: '',
            city: '',
            country: '',
            country_code: '',
            formatted_address,
            short_formatted_address: null,
            state: state,
            state_code: state,
            street_name: null,
            zip_code: null,
        },
        category: '',
        sub_category: '',
        geolocation: {
            lat,
            lng,
        },
        logo_img: '',
        number_of_venues: 0,
    };
};

export const isPoiRestrictedCoordinate = (poi?: PlaceOverlay) => {
    const { enable_explore_drop_pin_improvements_ff } = getFeatureFlags();

    return !!(
        enable_explore_drop_pin_improvements_ff &&
        poi &&
        poi?.type === EMPTY_SPACE_POI_TYPE &&
        (poi?.provider_data?.entity_id === COORDINATES_ENTITY ||
            poi?.provider_data?.entity_id === ADDRESS_ENTITY)
    );
};

export const createCustomPOIFromAddressCoordinate = async (
    poi: ExploreSearchResult,
    defaultRadius: number,
    signal?: AbortSignal,
) => {
    const { info } = poi;
    const { name, address, category, sub_category, type } = info;
    return await createCustomPOI({
        name,
        type: type as VenuePlaceTypes,
        address,
        category,
        sub_category,
        geojson: createPlaceWithNearbyResult(info, defaultRadius).geojson,
        provider: 'mapbox' as ExternalVenueProviders,
        signal,
    });
};

export const preCustomPOICreation = (poi: PlacerEntityWrapper<Venue>) => {
    return {
        name: poi.info.name,
        category: 'Address',
        sub_category: 'Address',
        provider_data: {
            provider: 'mapbox' as ExternalVenueProviders,
            entity_type: 'address',
            ...poi.info.provider_data,
        },
        profile: 'nearby_activity',
        filter: {
            config: {
                ft_radius: 250,
            },
        },
        purchased: true,
    };
};
