import { useSelectorWithProps } from 'utils/structured-selector/structured-selector';
import { selectHasUserPermission } from 'store/selectors/app-selectors';

export const useEmptySpacePermissions = () => {
    return {
        hasEmptySpacePermission: useSelectorWithProps(
            { permission: 'void_enable_empty_space' },
            selectHasUserPermission,
        ),
        hasMiniExplorePermission: useSelectorWithProps(
            { permission: 'void_enable_mini_explore' },
            selectHasUserPermission,
        ),
        hasVoidAnalysisCustomTaMethodPermission: useSelectorWithProps(
            { permission: 'void_select_method_combine_TTA_ff' },
            selectHasUserPermission,
        ),
    };
};
