import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'store/store';
import { useSelector } from 'react-redux';
import type { ChainsByCategoriesType } from '@placer-ui/types';

export const chainsByCategoriesSelector = createSelector(
    (state: AppState) => state.configuration.chainsByCategories,
    (categories) => categories,
);

export const useChainsByCategories = (): ChainsByCategoriesType =>
    useSelector(chainsByCategoriesSelector);
