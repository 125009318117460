import { ResponseCreateEmptySpaceData } from 'features/empty-space/types/types';
import type { Place } from '@placer-ui/types';

export const convertEmptySpaceToPlaceType = (data: ResponseCreateEmptySpaceData): Place => {
    const { lng, type, id, lat, name, state, formatted_address } = data;
    //@ts-expect-error PLAC-47814
    return {
        id,
        type,
        name,
        purchased: false,
        address: {
            address: '',
            city: '',
            country: '',
            country_code: '',
            formatted_address,
            short_formatted_address: null,
            state: state,
            state_code: state,
            street_name: null,
            zip_code: null,
        },
        category: '',
        sub_category: '',
        geolocation: {
            lat,
            lng,
        },
        logo_img: '',
        number_of_venues: 0,
    };
};
