import { BILLBOARD, COMPLEX, REGION, PROPERTY, ReportTypes } from 'core/constants/report-type';

export enum RestrictedItems {
    ENABLE_AVAILABLE_POIS = 'Available',
    ENABLE_CUSTOM_POIS = 'Custom POI',
    ENABLE_NEARBY_ACTIVITY_POIS = 'Nearby Activity',
    ENABLE_CLOSED_POIS = 'Closed',
}

export const MAX_AMOUNT_OF_RECENT_POIS = 6;
export const MAX_AMOUNT_OF_BRANDS = 3;

export const categoriesToExclude = ['Region'];
export const categoriesToKeep = ['Shopping District'];
export const hiddenCategories = ['Address', 'Country'];

export const FILTER_TYPE_LABELS = {
    [PROPERTY]: 'Properties',
    [BILLBOARD]: 'Traffic Pins',
    [COMPLEX]: 'Complexes',
    [REGION]: 'Regions/Markets',
};

export const EMPTY_STATE_TITLES: Partial<Record<ReportTypes, string>> = {
    Property: 'No Properties were found in map bounds.',
    Billboard: 'No Traffic Pins were found in map bounds.',
};

export const reportSplunkMapOverlay: Record<'complex' | 'poi' | 'nearby', string> = {
    poi: 'Property',
    nearby: 'Nearby Activity',
    complex: 'Enclosing Property',
};

export const COORDINATES_ENTITY = 'coordinate';

export const ADDRESS_ENTITY = 'address';
