import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniqueId } from 'lodash';
import {
    PageOrientationOptionType,
    PageSizeOptionType,
} from '../components/print-sidebar/layout-section/layout-options';
import {
    BaseItemType,
    ColorsConfigType,
    CoverPageConfigType,
    DataDescriptionPayload,
    GroupConfigType,
    PageConfigType,
    PrintContextState,
    PrintWidgetImages,
    PrintWidgetMode,
    WidgetConfigType,
} from './types';
import type { Dictionary } from '@placer-ui/types';
import { FileTitleFormatter } from 'utils/file-title-formatter/use-file-title';
import { defaultFileTitleFormatter } from 'utils/file-title-formatter/default-file-title-formatter';
import { ErrorContent } from 'extensions/widget/store/widget-constants';

export type SetPoiColorOption = {
    poi: string;
    color: string;
};

export type InitAction = {
    colorsConfig: ColorsConfigType;
    groupOrder: GroupConfigType[];
    widgetConfig: WidgetConfigType[];
    showViewInOldUi: boolean;
    fileTitleFormatter: FileTitleFormatter;
    enableDataDescription: boolean;
    isUpdateColors: boolean;
    coverPages?: CoverPageConfigType;
    enablePageHeaderFooter?: boolean;
    hideWidgetFrames?: boolean;
    reportTitle: string;
    reportSubtitle?: string;
    reportColor: string;
    shareLink: boolean;
    pageConfig: PageConfigType;
    serverSideRender?: boolean;
    printId: string;
    customHeaderDates?: string;
    exportType?: {
        type: 'report' | 'widget';
        name: string;
    };
};

export type SetOrderAction = {
    id: string;
    index: number;
};

export type DuplicateGroupAction = {
    groupId: string;
    widgetIds: string[];
    widgetIdDictionary?: Dictionary<string>;
};

type SetWidgetImagesAction = {
    widgetId: string;
    widgetImagesData: PrintWidgetImages;
};

export type ClearWidgetImagesAction = {
    widgetId: string;
    widgetImages?: []; // set to empty to differentiate between starting state to state after clear
};

export type SetWidgetExportJobIdAction = {
    widgetId: string;
    jobId?: string;
};

export type SetWidgetExportErrorAction = {
    widgetId: string;
    widgetError?: ErrorContent;
};

type SetWidgetModeAction = {
    widgetId: string;
    widgetMode: PrintWidgetMode;
};

export const initialState: PrintContextState = {
    shareLink: true,
    exportToMail: false,
    isExporting: false,
    showViewInOldUi: false,
    fileTitleFormatter: defaultFileTitleFormatter(),
    reportTitle: '',
    reportSubtitle: undefined,
    pageConfig: {
        size: 'LETTER',
        orientation: 'portrait',
    },
    colorsConfig: {},
    groupOrder: [],
    widgets: [],
    enableDataDescription: true,
    isUpdateColors: false,
    customerLogoVisible: true,
    enablePageHeaderFooter: false,
    hideWidgetFrames: false,
    hoverWidgetId: null,
    reportColor: '#5e63e5',
    serverSideRender: false,
    printId: '',
};

const reorderArray = <T extends BaseItemType>(
    list: T[],
    itemId: string,
    index: number,
    byOffset: boolean = false,
) => {
    const itemIndex = list.findIndex((item) => item.id === itemId);
    const result = list.filter((item) => item.id !== itemId);
    const item = list[itemIndex];
    if (item) {
        result.splice(byOffset ? index + itemIndex : index, 0, item);
    }

    return result;
};

const printContextSlice = createSlice({
    name: 'printContext',
    initialState,
    reducers: {
        init(state, action: PayloadAction<InitAction>) {
            state.colorsConfig = action.payload.colorsConfig;
            state.groupOrder = action.payload.groupOrder;
            state.widgets = action.payload.widgetConfig;
            state.showViewInOldUi = action.payload.showViewInOldUi;
            state.fileTitleFormatter = action.payload.fileTitleFormatter;
            state.enableDataDescription = action.payload.enableDataDescription;
            state.isUpdateColors = action.payload.isUpdateColors;
            state.coverPages = action.payload.coverPages;
            state.coverPageVisible = !action.payload.coverPages?.disableByDefault;
            state.enablePageHeaderFooter = action.payload.enablePageHeaderFooter;
            state.hideWidgetFrames = action.payload.hideWidgetFrames;
            state.reportTitle = action.payload.reportTitle;
            state.reportColor = action.payload.reportColor;
            state.shareLink = action.payload.shareLink;
            state.reportSubtitle = action.payload.reportSubtitle;
            state.pageConfig = action.payload.pageConfig;
            state.serverSideRender = action.payload.serverSideRender;
            state.printId = action.payload.printId;
            state.customHeaderDates = action.payload.customHeaderDates;
            state.exportType = action.payload.exportType;
        },
        cleanup: () => initialState,
        setPdfUrl(state, action: PayloadAction<string>) {
            state.pdfUrl = action.payload;
        },
        setShareFlow(state, action: PayloadAction<boolean>) {
            state.shareLink = action.payload;
        },
        setExportToMail(state, action: PayloadAction<boolean>) {
            state.exportToMail = action.payload;
        },
        setSelectedCustomizeWidgetId(state, action: PayloadAction<string | undefined>) {
            state.selectedCustomizeWidgetId = action.payload;
        },
        setIsUpdateAllWidgetsInGroup(state, action: PayloadAction<boolean>) {
            state.isUpdateAllWidgetsInGroup = action.payload;
        },
        setUploadedLink(state, action: PayloadAction<string | undefined>) {
            state.uploadedLink = action.payload;
        },
        setShowDataDescription(state, action: PayloadAction<DataDescriptionPayload>) {
            state.widgets.find((widget) => widget.id === action.payload.id)!.showDataDescription =
                action.payload.ShowDataDescription;
        },
        setIsUpdateColors(state, action: PayloadAction<boolean>) {
            state.isUpdateColors = action.payload;
        },
        setCustomerLogoVisible(state, action: PayloadAction<boolean>) {
            state.customerLogoVisible = action.payload;
        },
        setPageSize(state, action: PayloadAction<PageSizeOptionType>) {
            state.pageConfig.size = action.payload;
        },
        setPageOrientation(state, action: PayloadAction<PageOrientationOptionType>) {
            state.pageConfig.orientation = action.payload;
        },
        setReportColor(state, action: PayloadAction<string>) {
            state.reportColor = action.payload;
        },
        setColorConfig(state, action: PayloadAction<ColorsConfigType>) {
            state.colorsConfig = action.payload;
        },
        setPoiColor(state, action: PayloadAction<SetPoiColorOption>) {
            state.colorsConfig[action.payload.poi] = action.payload.color;
        },
        setIsExporting(state, action: PayloadAction<boolean>) {
            state.isExporting = action.payload;
        },
        setCoverPageVisible(state, action: PayloadAction<boolean>) {
            state.coverPageVisible = action.payload;
        },
        setGroupVisibility(state, action: PayloadAction<BaseItemType>) {
            state.groupOrder.find((group) => group.id === action.payload.id)!.visible =
                action.payload.visible;
        },
        setWidgetVisibility(state, action: PayloadAction<WidgetConfigType>) {
            state.widgets.find((widget) => widget.id === action.payload.id)!.visible =
                action.payload.visible;
        },
        setGroupOrder(state, action: PayloadAction<SetOrderAction>) {
            state.groupOrder = reorderArray(
                state.groupOrder,
                action.payload.id,
                action.payload.index,
            );
        },
        setWidgetOrder(state, action: PayloadAction<SetOrderAction>) {
            state.widgets = reorderArray(
                state.widgets,
                action.payload.id,
                action.payload.index,
                true,
            );
        },
        setReportTitle(state, action: PayloadAction<string>) {
            state.reportTitle = action.payload;
        },
        setHoverWidgetId(state, action: PayloadAction<string | null>) {
            state.hoverWidgetId = action.payload;
        },
        duplicateWidgetGroup(state, action: PayloadAction<DuplicateGroupAction>) {
            const currentGroupIdx = state.groupOrder.findIndex(
                (group) => group.id === action.payload.groupId,
            );
            const mappedWidgetIds = action.payload.widgetIdDictionary;
            if (state.groupOrder[currentGroupIdx]) {
                const newGroupId = uniqueId(`${state.groupOrder[currentGroupIdx].type}_`);
                const newGroup: GroupConfigType = {
                    ...state.groupOrder[currentGroupIdx],
                    id: newGroupId,
                    type: state.groupOrder[currentGroupIdx].type,
                };

                state.groupOrder.splice(currentGroupIdx + 1, 0, newGroup);
                const widgets = state.widgets
                    .filter((widget) => action.payload.widgetIds.includes(widget.id))
                    .map((widget) => ({
                        ...widget,
                        id:
                            mappedWidgetIds && mappedWidgetIds[widget.id]
                                ? mappedWidgetIds[widget.id]
                                : uniqueId(`${widget.widgetModel?.type}_`),
                        type: newGroupId,
                    }));

                state.widgets = state.widgets.concat(widgets);
            }
        },
        setWidgetMode(state, action: PayloadAction<SetWidgetModeAction>) {
            const { widgetId, widgetMode } = action.payload;
            const widget = state.widgets.find((widget) => widget.id === widgetId);
            if (widget) {
                widget.widgetMode = widgetMode;
            }
        },
        setWidgetExportJobIds(state, action: PayloadAction<SetWidgetExportJobIdAction[]>) {
            action.payload.forEach(({ widgetId, jobId }) => {
                const widget = state.widgets.find((widget) => widget.id === widgetId);
                if (widget) {
                    widget.exportJobId = jobId;
                }
            });
        },
        setWidgetExportJobId(state, action: PayloadAction<SetWidgetExportJobIdAction>) {
            const { widgetId, jobId } = action.payload;
            const widget = state.widgets.find((widget) => widget.id === widgetId);
            if (widget) {
                widget.exportJobId = jobId;
            }
        },
        setWidgetExportError(state, action: PayloadAction<SetWidgetExportErrorAction>) {
            const { widgetId, widgetError } = action.payload;
            const widget = state.widgets.find((widget) => widget.id === widgetId);
            if (widget) {
                widget.widgetError = widgetError;

                if (widgetError) {
                    widget.widgetImages = undefined;
                }
            }
        },
        setWidgetImages(state, action: PayloadAction<SetWidgetImagesAction>) {
            const { widgetId, widgetImagesData } = action.payload;
            const widget = state.widgets.find((widget) => widget.id === widgetId);
            if (widget && !widget.widgetImages?.length) {
                widget.widgetImages = widgetImagesData;
            }
        },
        resetAllWidgetImages(state) {
            state.widgets.forEach((widget) => {
                widget.widgetImages = undefined;
            });
        },
        clearWidgetImages(state, action: PayloadAction<ClearWidgetImagesAction>) {
            const { widgetId, widgetImages } = action.payload;
            const widget = state.widgets.find((widget) => widget.id === widgetId);
            if (widget) {
                widget.exportJobId = undefined;
                widget.widgetImages = widgetImages;
            }
        },
        removeWidgetById(state, action: PayloadAction<string>) {
            state.widgets = state.widgets.filter((widget) => widget.id !== action.payload);
        },
    },
});

type ActionsType<T> = {
    [K in keyof T]: T[K] extends (...args: any[]) => infer A ? A : never;
}[keyof T];

export type PrintActionTypes = ActionsType<typeof printContextSlice.actions>;
export const actions = printContextSlice.actions;
export const reducer = printContextSlice.reducer;
