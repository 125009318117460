import React, { useMemo } from 'react';
import styles from './poi-selection-pagination.module.scss';

import { usePoiSelectionContext } from 'features/poi-selection-map/context/poi-selection-context';
import { Button } from 'ui-components/button/button';
import { SmallSelectArrowIcon } from 'components/assets/Icons/Icons';

type PageChangeType = 'next' | 'previous';

export const PoiSelectionPagination = () => {
    const { setResultsPagination, resultsPagination, poisList } = usePoiSelectionContext();
    const { currentPage, resultsPerPage, totalResults } = resultsPagination;
    const enableBack = currentPage > 0;
    const enableNext = useMemo(() => {
        if (totalResults) {
            return (currentPage + 1) * resultsPerPage <= totalResults;
        }
        return poisList && poisList.length === resultsPerPage;
    }, [currentPage, poisList, resultsPerPage, totalResults]);

    const onPageChange = (type: PageChangeType) => {
        setResultsPagination({
            ...resultsPagination,
            currentPage: type === 'next' ? currentPage + 1 : currentPage - 1,
        });
    };

    if (totalResults && totalResults < resultsPerPage) return null;

    const getButton = (type: PageChangeType) => {
        const isNext = type === 'next';
        const disabled = isNext ? !enableNext : !enableBack;
        const rotation = isNext ? -90 : 90;
        const text = isNext ? 'Next' : 'Back';
        return (
            <Button
                onClick={() => onPageChange(type)}
                type="link"
                disabled={disabled}
                className={styles.button}
            >
                {isNext && text}
                <SmallSelectArrowIcon rotate={rotation} />
                {!isNext && text}
            </Button>
        );
    };

    return (
        <div className={styles.paginationContainer}>
            <div className={styles.content}>
                <div className={styles.buttonsWrapper}>
                    {getButton('previous')}
                    <div className={styles.currentPageIndicator}>Page {currentPage + 1}</div>
                    {getButton('next')}
                </div>
            </div>
        </div>
    );
};
