import { propertiesApi } from 'API/properties-api/properties-api';
import { shouldRenderMobileApp } from 'utils/detect-environment';
import type { User } from 'core/entities';
import type { URLEntity } from 'features/insights/types/url-types';
import type { SettingsState } from 'features/settings/store/slice';

export function getShouldAddCompetitor(
    user: User | undefined,
    userSettings: SettingsState,
    entities: URLEntity[],
) {
    const isFreemiumUser = !user?.settings?.account || user?.settings?.account?.is_active === false;
    const reportCompetitorSetting =
        isFreemiumUser ||
        userSettings.tabs?.platformConfiguration?.platform_config_report_comparison;
    return entities.length === 1 && Boolean(reportCompetitorSetting);
}

export async function fetchCompetitor(entity: URLEntity, signal: AbortSignal) {
    const competitors = await propertiesApi.getCompetitors(entity.id, entity.type, {
        signal,
    });
    if (competitors.length) {
        const chosenCompetitor = competitors[0];
        const competitor: URLEntity = {
            id: chosenCompetitor.info.id,
            type: chosenCompetitor.info.type,
            filter: shouldRenderMobileApp()
                ? entity.filter
                : {
                      ...entity.filter,
                      // for mobile we create the same filter for all entities,
                      // on dashboard we don't want the competitor to be nearby
                      config: undefined,
                  },
        };
        return competitor;
    }
}
