export const ZOOM_TO_MARKER_TARGET = 17;

export const GESTURE_HANDLING_OPTIONS: Record<
    google.maps.GestureHandlingOptions,
    google.maps.GestureHandlingOptions
> = {
    none: 'none',
    auto: 'auto',
    cooperative: 'cooperative',
    greedy: 'greedy',
};
