/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';
import { IconProps } from 'components/assets/Icons/Icons';

export const UnexpectedIllustrationIconSvg = (
    props: CustomIconComponentProps | SVGProps<SVGSVGElement>,
) => (
    <svg {...props} viewBox="0 0 232 139" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.52712 45.7402H46.2802C49.3327 45.7402 51.8073 48.2179 51.8073 51.2742V56.2752H0V51.2742C0 48.2179 2.47457 45.7402 5.52712 45.7402Z"
            fill="#E7F1F4"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.9178 40.1954H30.8498C33.5971 40.1954 35.8242 42.4253 35.8242 45.176V51.286C35.8242 51.5916 35.5767 51.8394 35.2715 51.8394H21.4961C21.1908 51.8394 20.9434 51.5916 20.9434 51.286V45.176C20.9434 42.4253 23.1705 40.1954 25.9178 40.1954Z"
            fill="#E7F1F4"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M198.658 24.8523H226.472C229.525 24.8523 232 27.3299 232 30.3863C232 30.5172 231.995 30.6481 231.986 30.7787L231.855 32.6149H193.275L193.145 30.7787C192.929 27.73 195.221 25.0829 198.266 24.8662C198.397 24.8569 198.527 24.8523 198.658 24.8523Z"
            fill="#E7F1F4"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M205.705 19.8621H208.954C211.106 19.8621 212.85 21.6083 212.85 23.7624C212.85 23.8485 212.847 23.9347 212.841 24.0206L212.6 27.6624C212.58 27.9532 212.339 28.1792 212.048 28.1792H202.611C202.32 28.1792 202.079 27.9532 202.06 27.6624L201.818 24.0206C201.676 21.8712 203.301 20.0132 205.447 19.8706C205.533 19.8649 205.619 19.8621 205.705 19.8621Z"
            fill="#E7F1F4"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M206.429 86.3989C209.481 86.3989 211.956 88.8766 211.956 91.9329L211.956 91.9435L183.414 91.9437C183.406 89.0389 185.661 86.5946 188.594 86.4098C188.71 86.4026 188.826 86.3989 188.941 86.3989H206.429Z"
            fill="#E7F1F4"
        />
        <path
            d="M199.889 90.8347C202.02 90.8347 203.747 88.9728 203.747 86.6761C203.747 84.3794 202.02 82.5176 199.889 82.5176C197.759 82.5176 196.031 84.3794 196.031 86.6761C196.031 88.9728 197.759 90.8347 199.889 90.8347Z"
            fill="#E7F1F4"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M66.6467 4.03979C64.2047 4.03979 62.225 6.02191 62.225 8.46698C62.225 8.56206 62.2281 8.65712 62.2342 8.75201L62.2779 9.42589H90.7387L90.7817 8.75201C90.9389 6.31202 89.0908 4.2064 86.6539 4.04898C86.5591 4.04286 86.4641 4.03979 86.3692 4.03979H66.6467Z"
            fill="#E7F1F4"
        />
        <path
            d="M73.8527 8.07948C71.6528 8.07948 69.8694 6.27083 69.8694 4.03974C69.8694 1.80865 71.6528 0 73.8527 0C76.0526 0 77.8359 1.80865 77.8359 4.03974C77.8359 6.27083 76.0526 8.07948 73.8527 8.07948Z"
            fill="#E7F1F4"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M67.281 113.172C69.7231 113.172 71.7027 115.154 71.7027 117.599C71.7027 117.694 71.6997 117.789 71.6936 117.884L71.6498 118.558H43.189L43.1461 117.884C42.9888 115.444 44.8369 113.339 47.2739 113.181C47.3687 113.175 47.4636 113.172 47.5586 113.172H67.281Z"
            fill="#E7F1F4"
        />
        <path
            d="M60.075 117.212C62.2749 117.212 64.0583 115.403 64.0583 113.172C64.0583 110.941 62.2749 109.132 60.075 109.132C57.8752 109.132 56.0918 110.941 56.0918 113.172C56.0918 115.403 57.8752 117.212 60.075 117.212Z"
            fill="#E7F1F4"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M118.871 4.06348C119.176 4.06348 119.423 4.31054 119.423 4.6153V12.6565C119.423 12.9613 119.176 13.2083 118.871 13.2083C118.567 13.2083 118.32 12.9613 118.32 12.6565V4.6153C118.32 4.31054 118.567 4.06348 118.871 4.06348Z"
            fill="#49565D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M128.241 7.29932C128.545 7.29932 128.792 7.54638 128.792 7.85114V13.7655C128.792 14.0703 128.545 14.3174 128.241 14.3174C127.936 14.3174 127.689 14.0703 127.689 13.7655V7.85114C127.689 7.54638 127.936 7.29932 128.241 7.29932Z"
            fill="#49565D"
        />
        <path
            d="M128.792 2.21528C128.792 1.91051 128.545 1.66345 128.241 1.66345C127.936 1.66345 127.689 1.91051 127.689 2.21528V5.54742C127.689 5.85218 127.936 6.09924 128.241 6.09924C128.545 6.09924 128.792 5.85218 128.792 5.54742V2.21528Z"
            fill="#49565D"
        />
        <path
            d="M138.161 12.6513C138.161 12.3465 137.914 12.0995 137.61 12.0995C137.305 12.0995 137.059 12.3465 137.059 12.6513V20.4192C137.059 20.724 137.305 20.9711 137.61 20.9711C137.914 20.9711 138.161 20.724 138.161 20.4192V12.6513Z"
            fill="#49565D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M107.297 11.3628C107.602 11.3628 107.848 11.6099 107.848 11.9146V18.7558C107.848 19.0606 107.602 19.3076 107.297 19.3076C106.993 19.3076 106.746 19.0606 106.746 18.7558V11.9146C106.746 11.6099 106.993 11.3628 107.297 11.3628Z"
            fill="#49565D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M131.272 68.6558C137.886 68.471 141.836 70.8737 143.122 75.8639C148.082 68.6558 154.696 65.791 162.963 67.2696L145.051 59.507L131.272 68.6558Z"
            fill="#484DD9"
        />
        <path
            d="M121.352 118.836L143.122 75.3094"
            stroke="#676DFD"
            strokeWidth="0.551744"
            strokeLinecap="square"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M111.431 68.6558C104.817 68.471 100.867 70.8737 99.5813 75.8639C94.6211 68.6558 88.0074 65.791 79.7402 67.2696L97.6523 59.507L111.431 68.6558Z"
            fill="#484DD9"
        />
        <path
            d="M121.352 118.836L99.582 75.3094"
            stroke="#676DFD"
            strokeWidth="0.551744"
            strokeLinecap="square"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M121.076 24.5182V30.6862C121.076 32.0576 122.186 33.1694 123.556 33.1694C124.926 33.1694 126.036 32.0576 126.036 30.6862L126.036 24.7969C127.53 24.9658 129.001 25.2102 130.446 25.5262L130.445 27.3593C130.445 28.7308 131.556 29.8426 132.926 29.8426C134.295 29.8426 135.406 28.7308 135.406 27.3593L135.406 26.9242C152.599 32.8938 164.948 49.3063 164.948 68.6182C164.948 69.3701 164.929 70.1176 164.892 70.8607C160.249 66.8547 154.217 64.4287 147.619 64.4287C132.96 64.4287 121.076 76.3741 121.076 91.1092V91.3297C121.076 76.5946 109.192 64.2082 94.5335 64.2082C87.9308 64.2082 81.8962 66.6372 77.2519 70.6473C77.2221 69.9743 77.2041 69.2987 77.2041 68.6182C77.2041 48.2893 90.8876 31.1734 109.502 26.0691L109.502 29.5825C109.502 31.5635 111.106 33.1694 113.085 33.1694C115.063 33.1694 116.667 31.5635 116.667 29.5825L116.666 24.7382C118.116 24.5927 119.588 24.5182 121.076 24.5182Z"
            fill="#676DFD"
        />
        <path d="M77.5361 70.3192L121.076 119.113" stroke="#676DFD" strokeWidth="0.551744" />
        <path d="M164.616 70.5964L121.076 119.113" stroke="#676DFD" strokeWidth="0.551744" />
        <path d="M121.076 87.8018V119.113" stroke="#676DFD" strokeWidth="0.551744" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M137.61 24.8523C138.828 24.8523 139.815 25.8406 139.815 27.0596V38.1703C139.815 39.3894 138.828 40.3776 137.61 40.3776C136.393 40.3776 135.406 39.3894 135.406 38.1703V27.0596C135.406 25.8406 136.393 24.8523 137.61 24.8523ZM107.297 23.7434C108.515 23.7434 109.502 24.7316 109.502 25.9507V37.0613C109.502 38.2804 108.515 39.2687 107.297 39.2687C106.08 39.2687 105.093 38.2804 105.093 37.0613V25.9507C105.093 24.7316 106.08 23.7434 107.297 23.7434ZM118.871 17.6442C120.089 17.6442 121.076 18.6324 121.076 19.8515V30.9621C121.076 32.1812 120.089 33.1694 118.871 33.1694C117.654 33.1694 116.667 32.1812 116.667 30.9621V19.8515C116.667 18.6324 117.654 17.6442 118.871 17.6442ZM128.241 17.6442C129.458 17.6442 130.445 18.6324 130.445 19.8515V30.9621C130.445 32.1812 129.458 33.1694 128.241 33.1694C127.023 33.1694 126.036 32.1812 126.036 30.9621V19.8515C126.036 18.6324 127.023 17.6442 128.241 17.6442Z"
            fill="#676DFD"
        />
        <path d="M127.255 125.809H115.48V136.236H127.255V125.809Z" fill="#676DFD" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M117.072 111.372H125.664C126.543 111.372 127.255 112.085 127.255 112.965V129.683L115.48 127.942V112.965C115.48 112.085 116.193 111.372 117.072 111.372Z"
            fill="#49565D"
        />
        <path
            d="M112.809 96.3715C112.809 95.4572 112.069 94.7161 111.155 94.7161C110.242 94.7161 109.502 95.4572 109.502 96.3715V98.6752C109.502 99.5895 110.242 100.331 111.155 100.331C112.069 100.331 112.809 99.5895 112.809 98.6752V96.3715Z"
            fill="#F9BCA7"
        />
        <path
            d="M111.155 98.5974V108.855L116.674 113.845"
            stroke="#49565D"
            strokeWidth="3.31046"
            strokeLinejoin="round"
        />
        <path
            d="M129.894 96.3715C129.894 95.4572 130.635 94.7161 131.548 94.7161C132.461 94.7161 133.201 95.4572 133.201 96.3715V98.6752C133.201 99.5895 132.461 100.331 131.548 100.331C130.635 100.331 129.894 99.5895 129.894 98.6752V96.3715Z"
            fill="#F9BCA7"
        />
        <path
            d="M131.548 98.5974V108.855L126.029 113.845"
            stroke="#49565D"
            strokeWidth="3.31046"
            strokeLinejoin="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M118.749 97.4884H123.405C125.163 97.4884 126.588 98.9155 126.588 100.676V105.257C126.588 107.091 125.104 108.578 123.272 108.578H121.077C118.033 108.578 115.565 106.107 115.565 103.06V100.676C115.565 98.9155 116.991 97.4884 118.749 97.4884Z"
            fill="#F9BCA7"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M123.006 92.4982C123.462 92.4982 123.833 92.8688 123.833 93.3259V95.5438C123.833 95.0867 124.203 94.7161 124.659 94.7161C125.116 94.7161 125.486 95.0867 125.486 95.5438L125.486 96.981C125.572 96.9505 125.665 96.934 125.762 96.934C126.218 96.934 126.588 97.3046 126.588 97.7617V105.258C126.588 106.484 125.924 107.556 124.936 108.13L124.935 103.029C124.935 101.806 123.945 100.815 122.724 100.815L115.565 100.815V94.4349C115.565 93.9777 115.936 93.6071 116.392 93.6071C116.849 93.6071 117.219 93.9777 117.219 94.4349V96.0983C117.219 95.6411 117.589 95.2705 118.046 95.2705C118.502 95.2705 118.872 95.6411 118.872 96.0983V93.3259C118.872 92.8688 119.242 92.4982 119.699 92.4982C120.156 92.4982 120.526 92.8688 120.526 93.3259V94.9893C120.526 94.5322 120.896 94.1616 121.352 94.1616C121.809 94.1616 122.179 94.5322 122.179 94.9893V93.3259C122.179 92.8688 122.549 92.4982 123.006 92.4982Z"
            fill="#49565D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M119.405 108.163H123.33V111.011C123.33 112.096 122.452 112.976 121.368 112.976C120.284 112.976 119.405 112.096 119.405 111.011V108.163Z"
            fill="#F9BCA7"
        />
        <path
            d="M122.402 136.53L137.383 132.492L139.209 127.867"
            stroke="#676DFD"
            strokeWidth="3.97256"
            strokeLinecap="round"
        />
        <path
            d="M122.179 136.53L120.303 136.53L105.321 132.492L103.496 127.867"
            stroke="#676DFD"
            strokeWidth="3.97256"
            strokeLinecap="round"
        />
        <path
            d="M140.366 118.001C140.366 117.697 140.119 117.449 139.815 117.449C139.51 117.449 139.264 117.697 139.264 118.001V122.442C139.264 122.747 139.51 122.994 139.815 122.994C140.119 122.994 140.366 122.747 140.366 122.442V118.001Z"
            fill="#49565D"
        />
        <path
            d="M140.366 114.12C140.366 113.815 140.119 113.568 139.815 113.568C139.51 113.568 139.264 113.815 139.264 114.12V115.234C139.264 115.539 139.51 115.786 139.815 115.786C140.119 115.786 140.366 115.539 140.366 115.234V114.12Z"
            fill="#49565D"
        />
        <path
            d="M138.162 120.219C138.162 119.914 137.915 119.667 137.611 119.667C137.306 119.667 137.06 119.914 137.06 120.219V122.442C137.06 122.747 137.306 122.994 137.611 122.994C137.915 122.994 138.162 122.747 138.162 122.442V120.219Z"
            fill="#49565D"
        />
    </svg>
);

export const UnexpectedIllustrationIcon = (props: IconProps) => (
    <Icon component={UnexpectedIllustrationIconSvg} {...props} />
);
