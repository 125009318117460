/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

type HolidaysChristianIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type HolidaysChristianIconProps = SVGProps<SVGSVGElement> & HolidaysChristianIconUniqueProps;

export const HolidaysChristianIcon = function HolidaysChristianIcon({
    width = 32,
    height = 32,
    ...props
}: HolidaysChristianIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_146_63)">
                <path
                    d="M15 8C14.4477 8 14 8.44771 14 9V14H9C8.44772 14 8 14.4477 8 15V17C8 17.5523 8.44771 18 9 18H14V28C14 28.5523 14.4477 29 15 29H17C17.5523 29 18 28.5523 18 28V18H23C23.5523 18 24 17.5523 24 17V15C24 14.4477 23.5523 14 23 14H18V9C18 8.44772 17.5523 8 17 8H15Z"
                    fill="#5E63E5"
                />
                <path
                    d="M17 1C17 0.447715 16.5523 0 16 0C15.4477 0 15 0.447715 15 1V3C15 3.55228 15.4477 4 16 4C16.5523 4 17 3.55228 17 3V1Z"
                    fill="#FFD89E"
                />
                <path
                    d="M31 17C31.5523 17 32 16.5523 32 16C32 15.4477 31.5523 15 31 15H29C28.4477 15 28 15.4477 28 16C28 16.5523 28.4477 17 29 17H31Z"
                    fill="#FFD89E"
                />
                <path
                    d="M4 16C4 16.5523 3.55228 17 3 17H1C0.447715 17 0 16.5523 0 16C0 15.4477 0.447715 15 1 15H3C3.55228 15 4 15.4477 4 16Z"
                    fill="#FFD89E"
                />
                <path
                    d="M5.39333 6.80769C5.78385 7.19822 6.41702 7.19822 6.80754 6.80769C7.19807 6.41717 7.19807 5.784 6.80754 5.39348L5.39333 3.97927C5.0028 3.58874 4.36964 3.58874 3.97911 3.97927C3.58859 4.36979 3.58859 5.00295 3.97911 5.39348L5.39333 6.80769Z"
                    fill="#FFD89E"
                />
                <path
                    d="M25.1923 6.80779C24.8018 6.41726 24.8018 5.7841 25.1923 5.39357L26.6065 3.97936C26.997 3.58883 27.6302 3.58883 28.0207 3.97936C28.4113 4.36988 28.4113 5.00305 28.0207 5.39357L26.6065 6.80779C26.216 7.19831 25.5828 7.19831 25.1923 6.80779Z"
                    fill="#FFD89E"
                />
                <path
                    d="M0 22H13V28C13 29.1046 13.8954 30 15 30H17C18.1046 30 19 29.1046 19 28V22H32V32H0V22Z"
                    fill="#FFD89E"
                />
            </g>
            <defs>
                <clipPath id="clip0_146_63">
                    <rect width="32" height="32" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
HolidaysChristianIcon.__tags = ['holidays', 'christ'] as string[];
