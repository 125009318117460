/* eslint-disable max-len */
import React, { createContext, PropsWithChildren, useContext, useState } from 'react';
import { initialPoiSearchContextState } from 'features/poi-selection-map/poi-selection-menu/menu-items/search-menu-item/context/initial-state';
import {
    SearchFilters,
    SearchPoiContextState,
} from 'features/poi-selection-map/poi-selection-menu/menu-items/search-menu-item/types/search-poi-types';
import { PoiEntity } from 'ui-components/google-map-layers/types/google-map-layers-types';
import { PlacePois } from 'features/poi-selection-map/types/poi-selection-types';
import { v4 as uuidv4 } from 'uuid';
import type { PlaceTypeEntities } from '@placer-ui/types';

export const SearchPoiStateContext = createContext<SearchPoiContextState>(
    initialPoiSearchContextState,
);

export const useSearchPoiContext = () => {
    return useContext(SearchPoiStateContext);
};
const {
    searchResults: initialSearchResults,
    currentSearchTerm: initialSearchTerm,
    isLoadingPois: initialIsLoadingPois,
    isLoadingSearchSuggestions: initialIsLoadingSearchSuggestions,
    hasError: initialHasError,
    searchApiSuggestions: initialSuggestions,
    selectedSearchSuggestion: initialSelectedSuggestion,
    searchApiFilters: initialSearchApiFilters,
} = initialPoiSearchContextState;

export const SearchPoiContext = ({ children }: PropsWithChildren<{}>) => {
    const [searchResults, setSearchResults] = useState<PlacePois>(initialSearchResults);
    const [currentSearchTerm, setCurrentSearchTerm] = useState<string>(initialSearchTerm);
    const [searchApiSuggestions, setSearchApiSuggestions] = useState<
        PlaceTypeEntities[] | undefined
    >(initialSuggestions);
    const [selectedSearchSuggestion, setSelectedSearchSuggestion] = useState<PoiEntity | undefined>(
        initialSelectedSuggestion,
    );
    const [isLoadingPois, setIsLoadingPois] = useState<boolean>(initialIsLoadingPois);
    const [isLoadingSearchSuggestions, setIsLoadingSearchSuggestions] = useState<boolean>(
        initialIsLoadingSearchSuggestions,
    );
    const [searchId, setSplunkSearchId] = useState<string>(uuidv4());
    const [isCnp, setIsCnp] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(initialHasError);
    const [searchApiFilters, setSearchApiFilters] = useState<SearchFilters | undefined>(
        initialSearchApiFilters,
    );

    const state: SearchPoiContextState = {
        searchResults,
        currentSearchTerm,
        searchApiSuggestions,
        selectedSearchSuggestion,
        isLoadingPois,
        isLoadingSearchSuggestions,
        hasError,
        searchId,
        searchApiFilters,
        isCnp,
        setSearchResults,
        setCurrentSearchTerm,
        setSearchApiSuggestions,
        setSelectedSearchSuggestion,
        setIsLoadingPois,
        setIsLoadingSearchSuggestions,
        setHasError,
        setSearchApiFilters,
        setSplunkSearchId,
        setIsCnp,
    };

    return (
        <SearchPoiStateContext.Provider value={state}>{children}</SearchPoiStateContext.Provider>
    );
};
