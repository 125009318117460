import {
    NewCommentPayload,
    OfflineReportsConfiguration,
    OrderManagementRequest,
    OfflineReportsCreditsBalance,
    UserAccountDetails,
    ShareReportPayload,
} from './../../core/entities/offline-reports/offline-reports';
import { fetchWrapper, fetchWithBasePath } from 'API/authentification/fetch-wrapper';
import {
    OfflineReportDetails,
    OfflineReportListItem,
    Comment,
    SubmitOfflineReportRequestPayload,
    AttachmentItem,
} from 'core/entities';
import type { PlacerResponseData } from '@placer-ui/types';
import { TableauTicketResponse } from 'API/external-iframe-reports-api/response';
import { getAppGatewayEndpoint } from 'core/default-endpoint-url';
import { getFeatureFlags } from 'core/flow-control';

export const offlineReportsAPI = {
    getOfflineReports(): Promise<OfflineReportListItem[]> {
        return fetchWrapper({ targetUrl: '/offline_reports/metadata/' });
    },
    getOfflineReportDetails(id: string): Promise<OfflineReportDetails> {
        return fetchWrapper({ targetUrl: `/offline_reports/metadata/${id}/` });
    },
    submitOfflineReportRequest(
        payload: SubmitOfflineReportRequestPayload,
        enable_salesforce_data: boolean,
    ): Promise<{ result: 'SUCCESS' }> {
        const reactAppGateway = getAppGatewayEndpoint();
        const xtraReportsUpdateUrl = `${reactAppGateway}/xtra-reports/update-reports-data/`;
        const params = !enable_salesforce_data
            ? {
                  targetUrl: '/offline_reports/orders/',
              }
            : {
                  targetUrl: `${xtraReportsUpdateUrl}request`,
                  endpoint: '',
                  basePath: '',
              };
        return fetchWrapper({
            ...params,
            method: 'POST',
            body: payload,
        });
    },
    getOfflineReportsSharedWithMe(
        enable_salesforce_data: boolean,
        marketplace_xtra_enabled?: boolean,
    ): Promise<{ requests: OrderManagementRequest[] }> {
        const reactAppGateway = getAppGatewayEndpoint();
        const xtraReportsGetUrl = `${reactAppGateway}/xtra-reports/get-reports-data/`;
        const marketplaceXtraQuery = marketplace_xtra_enabled
            ? `&marketplace_xtra_enabled=${marketplace_xtra_enabled}`
            : '';
        const params = !enable_salesforce_data
            ? {
                  targetUrl: '/offline_reports/orders/?owner=shared',
              }
            : {
                  // eslint-disable-next-line max-len
                  targetUrl: `${xtraReportsGetUrl}get-all-cases/?owner=shared${marketplaceXtraQuery}`,
                  endpoint: '',
                  basePath: '',
              };
        return fetchWrapper({
            ...params,
        });
    },
    getOfflineReportsUserAccountInfo: () => {
        return fetchWrapper<UserAccountDetails>({
            targetUrl: '/user/account/',
        });
    },
    getOfflineReportsConfigurations(): Promise<OfflineReportsConfiguration> {
        return fetchWrapper({ targetUrl: '/offline_reports/configurations/' });
    },
    getOfflineReportsRequests(
        enable_salesforce_data: boolean,
        marketplace_xtra_enabled?: boolean,
    ): Promise<{
        requests: OrderManagementRequest[];
    }> {
        const reactAppGateway = getAppGatewayEndpoint();
        const xtraReportsGetUrl = `${reactAppGateway}/xtra-reports/get-reports-data/`;
        const marketplaceXtraQuery = marketplace_xtra_enabled
            ? `?marketplace_xtra_enabled=${marketplace_xtra_enabled}`
            : '';
        const params = !enable_salesforce_data
            ? {
                  targetUrl: '/offline_reports/orders/',
              }
            : {
                  targetUrl: `${xtraReportsGetUrl}get-all-cases/${marketplaceXtraQuery}`,
                  endpoint: '',
                  basePath: '',
              };
        return fetchWrapper({
            ...params,
        });
    },
    getOfflineReportsRequestsComments(
        requestId: string,
        enable_salesforce_data: boolean,
    ): Promise<{ comments: Array<Comment> }> {
        const reactAppGateway = getAppGatewayEndpoint();
        const xtraReportsGetUrl = `${reactAppGateway}/xtra-reports/get-reports-data/`;
        const params = !enable_salesforce_data
            ? {
                  targetUrl: `/offline_reports/orders/${requestId}/`,
              }
            : {
                  targetUrl: `${xtraReportsGetUrl}${requestId}/comments`,
                  endpoint: '',
                  basePath: '',
              };
        return fetchWrapper({
            ...params,
        });
    },
    postOfflineReportsComment(
        requestId: string,
        payload: NewCommentPayload,
        enable_salesforce_data: boolean,
    ): Promise<{ result: 'SUCCESS'; comments: Array<any> }> {
        const reactAppGateway = getAppGatewayEndpoint();
        const xtraReportsUpdateUrl = `${reactAppGateway}/xtra-reports/update-reports-data/`;
        const params = !enable_salesforce_data
            ? {
                  targetUrl: `/offline_reports/orders/${requestId}/`,
              }
            : {
                  targetUrl: `${xtraReportsUpdateUrl}${requestId}/addComment`,
                  endpoint: '',
                  basePath: '',
              };
        return fetchWrapper({
            ...params,
            method: 'POST',
            body: payload,
        });
    },
    shareOfflineReportsComment(
        requestId: string,
        payload: ShareReportPayload,
    ): Promise<PlacerResponseData<{}>> {
        return fetchWrapper({
            targetUrl: `/offline_reports/orders/${requestId}/share/`,
            method: 'POST',
            body: payload,
        });
    },
    getCreditsBalance(): Promise<OfflineReportsCreditsBalance> {
        return fetchWrapper({ targetUrl: '/user/credit_balance' });
    },
    updateReadReceipt(
        requestId: string,
        flag: boolean,
        enable_salesforce_data: boolean,
    ): Promise<{ result: 'SUCCESS' }> {
        const reactAppGateway = getAppGatewayEndpoint();
        const xtraReportsUpdateUrl = `${reactAppGateway}/xtra-reports/update-reports-data/`;
        const params = !enable_salesforce_data
            ? {
                  targetUrl: `/offline_reports/orders/${requestId}/read/`,
              }
            : {
                  targetUrl: `${xtraReportsUpdateUrl}${requestId}/read`,
                  endpoint: '',
                  basePath: '',
              };
        return fetchWrapper({
            ...params,
            method: 'POST',
            body: { flag },
        });
    },
    getRequestMaterialZip(
        id: number,
        filesList: AttachmentItem[],
        contentTypeHeader?: string,
        enable_salesforce_data?: boolean,
    ): Promise<Response> {
        const reactAppGateway = getAppGatewayEndpoint();
        const xtraReportsGetUrl = `${reactAppGateway}/xtra-reports/get-reports-data/`;
        const params = !enable_salesforce_data
            ? {
                  passedURL: `/offline_reports/download_file/${id}/`,
              }
            : {
                  passedURL: `${xtraReportsGetUrl}${id}/download`,
                  baseUrl: '',
                  basePath: '',
              };
        return fetchWithBasePath({
            ...params,
            options: {
                method: 'POST',
                body: JSON.stringify({
                    filesList,
                    contentTypeHeader,
                }),
            },
        });
    },
    getTableauEmbeddedTicket(): Promise<TableauTicketResponse> {
        return fetchWrapper({
            targetUrl: '/offline_reports/get_tableau_ticket/',
        });
    },
    getTableauEmbeddedJWTtoken(): Promise<TableauTicketResponse> {
        const reactAppGateway = getAppGatewayEndpoint();   
        const xtraReportsUpdateUrl = `${reactAppGateway}/tableau-jwt-service/getJwtToken/token`;
        const { tableau_get_account_dedicated_jwt_token_ff } = getFeatureFlags();

        const params = !tableau_get_account_dedicated_jwt_token_ff
         ? {
                targetUrl: '/offline_reports/get_tableau_jwt_token/',
           }
         : {
                targetUrl: xtraReportsUpdateUrl,
                basePath: '',
                endpoint:'',
           };

        return fetchWrapper({
            ...params,
        });
    },
};
