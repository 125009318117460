import React, { forwardRef, PropsWithChildren } from 'react';
import classnames from 'classnames';
import styles from 'ui-components/google-map/components/custom-info-window/custom-info-window.module.scss';

import { CustomInfoWindowProps } from 'ui-components/google-map/components/custom-info-window/custom-info-window';

type CustomInfoWindowContainerProps = PropsWithChildren<
    Pick<
        CustomInfoWindowProps,
        'animation' | 'className' | 'backgroundColor' | 'borderColor' | 'boxShadow' | 'width'
    >
>;

export const CustomInfoWindowContainer = forwardRef<HTMLDivElement, CustomInfoWindowContainerProps>(
    (
        {
            animation = true,
            className,
            backgroundColor,
            borderColor,
            boxShadow,
            children,
            width,
        }: CustomInfoWindowContainerProps,
        ref,
    ) => {
        const classes = classnames('popup-bubble', styles.tooltip, className, {
            [styles.animated]: animation,
        });

        const divStyle = {
            ...(backgroundColor ? { backgroundColor: backgroundColor } : {}),
            ...(borderColor ? { borderColor: borderColor } : {}),
            ...(boxShadow ? { boxShadow: boxShadow } : {}),
            ...(width ? { width: width } : {}),
        };

        return (
            <div
                ref={ref}
                className={classes}
                style={divStyle}
                onClick={(e) => {
                    e.stopPropagation();
                }}
                onDoubleClick={(e) => e.stopPropagation()}
                onTouchStart={(e) => e.stopPropagation()}
            >
                {children}
            </div>
        );
    },
);
