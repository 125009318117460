import { goTo } from 'router/go-to';
import { getAppApiEndpoint } from 'core/default-endpoint-url';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

export const useNavigateToSolution = () => {
    const history = useHistory();

    return useCallback(
        (solutionUrl: string) => {
            // Todo: Remove this condition when fixing this URL redirection issue. See task:
            // https://placer.atlassian.net/browse/GROW-3408
            if (solutionUrl.includes('chains/demographics')) {
                goTo(getAppApiEndpoint() + solutionUrl);
            } else {
                history.push({
                    pathname: solutionUrl,
                });
            }
        },
        [history],
    );
};
