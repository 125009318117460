import React from 'react';
import { DashboardRoute, PlRoute } from 'router';
import { AppDispatch } from 'store/store';
import { DocumentTitle } from 'components/document-title/document-title';

export const renderRoute = (route: DashboardRoute, dispatch: AppDispatch) => (
    <PlRoute
        key={Array.isArray(route.path) ? route.path[0] : route.path}
        path={route.path}
        render={(props) => {
            if (props.match.isExact && route.initializer) {
                route.initializer({ dispatch });
            }

            if (route.render) {
                return route.render(props);
            } else if (route.Component) {
                const Component = route.Component;
                return (
                    <DocumentTitle title={route.title}>
                        <Component />
                    </DocumentTitle>
                );
            }
        }}
    />
);
