import React from 'react';
import { ScoreParamConfiguration } from 'features/void-analysis/common/types/match-score';
import { ScoreBoundsKeys } from 'features/void-analysis/common/types/server-responses';
import {
    CannibalizationIcon,
    CoTenantsIcon,
    DemographicsIcon,
    HouseholdCharacteristicsIcon,
    VisitsIcon,
} from 'components/assets/Icons/Icons';
import { SCORE_PARAMS_TOOLTIP_TEXTS } from 'features/void-analysis/sections/ranking-page/components/report-content/components/match-score-breakdown/constants';

export const scoreBoundsKeys: ScoreBoundsKeys[] = [
    'demographics',
    'cannibalization',
    'household_characteristics',
    'avg_monthly_ft',
    'natural_co_tenants',
];

export const useScoreParamsConfiguration = () => {
    const { demographics, naturalCoTenants } = SCORE_PARAMS_TOOLTIP_TEXTS;

    const paramsConfig: Record<ScoreBoundsKeys, ScoreParamConfiguration> = {
        avg_monthly_ft: {
            label: 'Avg. Monthly Visits',
            icon: <VisitsIcon />,
        },
        demographics: {
            label: 'Demographic Fit Score',
            icon: <DemographicsIcon />,
            infoTooltipText: demographics,
        },
        cannibalization: {
            label: 'Cannibalization',
            icon: <CannibalizationIcon style={{ color: 'var(--brand-color-primary)' }} />,
        },
        natural_co_tenants: {
            label: 'Frequent Co-Tenants',
            icon: <CoTenantsIcon />,
            infoTooltipText: naturalCoTenants,
        },
        household_characteristics: {
            label: 'Household Char. Score',
            icon: <HouseholdCharacteristicsIcon />,
        },
    };

    return {
        order: scoreBoundsKeys,
        paramsConfig,
    };
};
