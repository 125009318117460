import React from 'react';
import { DashboardRouter } from 'router/router';
import { Loading } from 'ui-components/loading/loading-component';
import { GeneralAndInitializersMemo } from 'features/general-and-initializers/general-and-initializers';
import { Header } from 'features/header/header';

export const DesktopApp = () => {
    return (
        <DashboardRouter
            AppLoader={<Loading className="pl-app-loader" show />}
            RouteLoader={<Loading className="pl-route-loader" show />}
            Initializers={<GeneralAndInitializersMemo />}
            Header={<Header />}
        />
    );
};

export const DesktopAppMemo = React.memo(DesktopApp);
DesktopAppMemo.displayName = 'DesktopApp - memo';
