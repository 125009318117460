import { WidgetContext } from 'extensions/widget/context/WidgetContext';
import { useWidgetPrintMode } from 'extensions/widget/hooks/use-widget-context-hooks';
import { Widget, WidgetModel, WidgetModelWithId } from 'extensions/widget/models/widget-model';
import { uniqueId as generateId } from 'lodash';
import React, { useMemo } from 'react';

export type WithUniqueIdType = {
    uniqueId?: string;
};

export type WithUniqueIdComponent = ({ uniqueId }: WithUniqueIdType) => JSX.Element | null;

export class WidgetFactoryClass {
    createWidget<FetchFiltersType, VisualFiltersType>(
        WidgetComponent: Widget<FetchFiltersType, VisualFiltersType>,
        overrideModel: Partial<WidgetModel<FetchFiltersType, VisualFiltersType>> = {},
    ) {
        return ({ uniqueId }: WithUniqueIdType) => {
            const isPrintMode = useWidgetPrintMode();
            // TODO useMemo used only because  getModel is method,
            //  we need to switch it to static object
            const model: WidgetModelWithId<FetchFiltersType, VisualFiltersType> = useMemo(() => {
                const widgetId = overrideModel?.type || WidgetComponent.getModel().type;

                return {
                    ...WidgetComponent.getModel(),
                    ...overrideModel,
                    uniqueId: isPrintMode
                        ? // if this is print mode - a new instance separate from original
                          uniqueId || generateId(`${widgetId}_`)
                        : // if this is not print mode - use widget type constant as id for caching
                          widgetId,
                };
            }, [isPrintMode, uniqueId]);

            return (
                <WidgetContext.Provider value={model}>
                    <WidgetComponent />
                </WidgetContext.Provider>
            );
        };
    }
}
