export const CHAINS_AUDIENCE_PROFILE_METADATA_API = 'audienceProfileMetaDataApi';
export const CHAINS_AUDIENCE_PROFILE_VENUES_API = 'audienceProfileVenuesApi';
export const CHAINS_AUDIENCE_PROFILE_VENUES_METRIC_RANK_API =
    'audienceProfileVenuesMetricRankingAPI';
export const CHAINS_DEMOGRAPHICS_API = 'chainDemographicsApi';
export const CHAINS_DEMOGRAPHICS_CBG_API = 'chainDemographicsCBGApi';
export const CHAINS_DEMOGRAPHICS_TRACTS_API = 'chainDemographicsTRACTSApi';
export const CHAINS_COMBINED_CHAINS = 'chainCombinedApi';
export const CBGS_LEVELS_AMOUNT = 4;
export const CBGS_LEVELS_DISCARD_PERCENTAGE_AMOUNT = 5;
export const CHAINS_DEMOGRAPHICS_INITIAL_ZOOM = 3.5;
export const CHAINS_DEMOGRAPHICS_WEIGHTED_SCALE_BINS = [1, 8, 27, 64];
export const POTENTIAL_MARKET_ONLY_DATASETS = ['ags_att_behavior'];

export const CHAINS_AUDIENCE_PROFILE_MAP_ID = 'chains-audience-profile-map';
export const COMPONENT_SPLUNK_NAME = 'chains-audience-profile';
export const AUDIENCE_PROFILE_WIDGET_TITLE = 'Audience Profile';

export const ALPHA = 0.85;
export const MAP_FILL_OPACITY = 0.2;
export const HYBRID_FILL_OPACITY = 0.3;
export const LINE_COLORS: Record<string, string> = {
    chain_1_map: '#797df6',
    chain_2_map: '#e98b9b',
    chain_3_map: '#41c8b8',
    chain_4_map: '#e1c441',
    chain_5_map: '#dd8c46',
    chain_6_map: '#4facfc',
    chain_7_map: '#a1cc3e',
    chain_8_map: '#e494e5',

    chain_1_hybrid: '#a3a6fa',
    chain_2_hybrid: '#e98b9b',
    chain_3_hybrid: '#41c8b8',
    chain_4_hybrid: '#e1c441',
    chain_5_hybrid: '#dd8c46',
    chain_6_hybrid: '#4facfc',
    chain_7_hybrid: '#a1cc3e',
    chain_8_hybrid: '#d9a0da',
};

export const FILL_COLORS: Record<string, string> = {
    chain_1_map: '#3734ef',
    chain_2_map: '#f55676',
    chain_3_map: '#4fde9e',
    chain_4_map: '#f5ef42',
    chain_5_map: '#e2824b',
    chain_6_map: '#4ba4f1',
    chain_7_map: '#afe619',
    chain_8_map: '#e38af4',

    chain_1_hybrid: '#3734ef',
    chain_2_hybrid: '#f55676',
    chain_3_hybrid: '#4ee4b2',
    chain_4_hybrid: '#f5ef42',
    chain_5_hybrid: '#e2824b',
    chain_6_hybrid: '#4ba4f1',
    chain_7_hybrid: '#afe619',
    chain_8_hybrid: '#e38af4',
};

export const CHAINS_USER_SETTINGS_FIELD = 'chainsDatasetsPreferences';
