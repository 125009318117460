import { Tree } from 'antd';
import cn from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DataNode, EventDataNode, Key } from 'rc-tree/lib/interface';
import { MultiSelectOption, TreeNode } from 'ui-components/multi-select/types';
import { OuterSearchInput } from 'ui-components/table/components/outer-search-input/outer-search-input';
import {
    SelectAndClearAll,
    SelectAndClearAllType,
} from 'ui-components/multi-select/checkbox-list-v4/select-and-clear-all/select-and-clear-all';
import { useTreeOptionsData } from 'ui-components/multi-select/checkbox-list-v4/hooks/checkbox-list-hooks';
import { ArrowIconButton } from 'ui-components/arrow-icon-button/arrow-icon-button';
import { getLeafNodes } from 'ui-components/multi-select/checkbox-list-v4/utils/util';
import { drawerTypeSignal } from 'features/mobile-report/state/mobile-report.signals';
import { LockIcon } from 'components/assets/Icons/Icons';

import styles from './checkbox-tree.module.scss';

const isSubstring = (str: string, testStr: string) =>
    str?.toLowerCase().includes(testStr?.toLowerCase());

export type CheckboxListProps = {
    options: MultiSelectOption[];
    searchPlaceholder?: string;
    isSearchDisabled?: boolean;
    isSearchHidden?: boolean;
    isSelectAndClearAllHidden?: boolean;
    onOptionsChange?: (keys: Key[], removeItem: boolean, totalOptionsAmount?: number) => void;
    treeCheckedKeys: Key[];
    isDropMenu?: boolean;
    disabled?: boolean;
    isLimited?: boolean;
    shouldSetHeight?: boolean;
    maxHeight?: number;
    hideSelectAll?: boolean;
};

export const CheckboxTree = ({
    options,
    searchPlaceholder,
    onOptionsChange,
    isSearchDisabled,
    isSearchHidden,
    isSelectAndClearAllHidden,
    treeCheckedKeys,
    isDropMenu = false,
    disabled,
    isLimited = false,
    shouldSetHeight = true,
    maxHeight = 300,
    hideSelectAll = false,
}: CheckboxListProps) => {
    const [searchValue, setSearchValue] = useState<string>('');
    const [checkedKeys, setCheckedKeys] = useState<Key[]>(treeCheckedKeys);
    const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
    const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);

    const treeOptionsConverted = useTreeOptionsData({
        options,
        searchValue,
        isDropMenu,
    });

    const [treeOptions, setTreeOptions] = useState<TreeNode[]>(treeOptionsConverted);

    const allLeaves = getLeafNodes(treeOptionsConverted);

    const allLeavesKeys = allLeaves.map((checkboxOption) => checkboxOption.key);

    const filter = (treeOptions: TreeNode[], text: string) => {
        const getNodes = (result: TreeNode[], node: TreeNode) => {
            if (isSubstring(node.name, text)) {
                result.push(node);
                return result;
            }
            if (Array.isArray(node.children)) {
                const childrenNodes = (node.children as TreeNode[]).reduce(getNodes, []);
                if (childrenNodes.length)
                    result.push({
                        ...node,
                        children: childrenNodes as TreeNode[],
                    });
            }
            return result;
        };

        return treeOptions.reduce(getNodes, []);
    };

    const checkboxItems: { key: Key; title: string }[] = useMemo(() => [], []);

    const getAllNodesList = useCallback(
        (data: TreeNode[]) => {
            for (let i = 0; i < data.length; i++) {
                const node = data[i];
                const { key, name } = node;
                checkboxItems.push({
                    key,
                    title: name,
                });
                if (node.children) {
                    getAllNodesList(node.children as TreeNode[]);
                }
            }
        },
        [checkboxItems],
    );

    useEffect(() => {
        const filterSearch = filter(treeOptionsConverted, searchValue);
        getAllNodesList(filterSearch);

        setTreeOptions(filterSearch);
    }, [getAllNodesList, searchValue, treeOptionsConverted]);

    const getParentKey = useCallback((key: Key, tree: DataNode[]): Key => {
        let parentKey: Key;
        tree.forEach((treeNode) => {
            if (treeNode.children) {
                if (treeNode.children.some((item) => item.key === key)) {
                    parentKey = treeNode.key;
                } else if (getParentKey(key, treeNode.children)) {
                    parentKey = getParentKey(key, treeNode.children);
                }
            }
        });
        return parentKey!;
    }, []);

    useEffect(() => {
        const filterSearch = filter(treeOptionsConverted, searchValue);

        if (searchValue) {
            const newExpandedKeys = checkboxItems
                .map((item) => {
                    if (isSubstring(item.title, searchValue)) {
                        return getParentKey(item.key, filterSearch);
                    }
                    return null;
                })
                .filter((item, i, self) => item && self.indexOf(item) === i) as Key[];
            setExpandedKeys(newExpandedKeys);
            setAutoExpandParent(true);
        }
    }, [checkboxItems, getParentKey, searchValue, treeOptionsConverted]);

    const onTreeSearchChange = (value: string) => {
        setSearchValue(value);
        if (value === '') {
            setExpandedKeys([]);
            setAutoExpandParent(false);
        }
    };

    const onExpand = (newExpandedKeys: Key[]) => {
        setExpandedKeys(newExpandedKeys);
        setAutoExpandParent(false);
    };

    const toggleCheckbox = (
        checkedKeys:
            | {
                  checked: Key[];
                  halfChecked: Key[];
              }
            | Key[],
        event?: {
            node: EventDataNode;
            checked: boolean;
        },
    ) => {
        if (!event) return;

        const checkedNode = event.node;
        const allCheckedLeaves = (checkedKeys as Key[]).filter((key) =>
            allLeavesKeys.includes(key),
        );
        setCheckedKeys(allCheckedLeaves);
        const checkedNodeLeaves = getLeafNodes(checkedNode.children as TreeNode[]);
        const checkedNodeLeavesKeys = checkedNodeLeaves.map((node) => node.key);
        const optionsToCheck =
            checkedNodeLeavesKeys.length > 0 ? checkedNodeLeavesKeys : [checkedNode.key];
        const isUncheckAction = !event?.checked;
        onOptionsChange?.(optionsToCheck, isUncheckAction, allLeaves.length);
    };

    const onSelectAll = (type: SelectAndClearAllType) => {
        const totalOptions = allLeaves.length;
        const isClearAction = type === 'clear all';
        onOptionsChange?.(allLeavesKeys, isClearAction, totalOptions);
    };

    const openUpgradeYourPlanPopup = () => {
        drawerTypeSignal.value = 'upgradeYourPlan';
    };

    const onSelect = (
        keys: Key[],
        info: {
            node: EventDataNode;
        },
    ) => {
        const selectedNode = info.node;
        const selectedNodeLeaves = selectedNode.children
            ? getLeafNodes(selectedNode.children as TreeNode[])
            : [selectedNode];
        const selectedKeys = selectedNodeLeaves?.map((node) => node.key);
        const isUncheckAction = selectedNode.checked;
        onOptionsChange?.(selectedKeys, isUncheckAction, allLeaves.length);
    };

    useEffect(() => {
        setCheckedKeys(treeCheckedKeys);
    }, [treeCheckedKeys]);

    const isAllSelected = checkedKeys.length === allLeavesKeys.length;

    return (
        <>
            {!isSearchHidden && (
                <OuterSearchInput
                    inputValue={searchValue}
                    onChange={onTreeSearchChange}
                    wrapperClassName={styles.searchBoxWrapper}
                    className={styles.searchBox}
                    placeholder={searchPlaceholder}
                    disabled={isLimited || disabled || isSearchDisabled}
                />
            )}
            {!isSelectAndClearAllHidden && (
                <SelectAndClearAll
                    onClick={onSelectAll}
                    disabledSelectAll={isLimited || disabled || isAllSelected}
                    disabledClearAll={isLimited || disabled || !checkedKeys.length}
                    hideSelectAll={hideSelectAll}
                />
            )}
            <Tree
                treeData={treeOptions}
                onExpand={isLimited ? openUpgradeYourPlanPopup : onExpand}
                autoExpandParent={autoExpandParent}
                expandedKeys={expandedKeys}
                checkedKeys={checkedKeys}
                onSelect={isLimited ? openUpgradeYourPlanPopup : onSelect}
                multiple
                checkable
                onCheck={isLimited ? openUpgradeYourPlanPopup : toggleCheckbox}
                switcherIcon={
                    isLimited ? <LockIcon /> : <ArrowIconButton arrowDirection={'down'} />
                }
                className={cn(styles.treeNodes, { [styles.limited]: isLimited })}
                disabled={disabled}
                height={shouldSetHeight ? maxHeight : undefined}
            />
        </>
    );
};
