import { useEffect, useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useAppDispatch } from 'store/app-store-hooks';

import { experianMosaicActions } from 'store/configuration/experian-mosaic/slice';
import { fetchMosaicData } from 'store/configuration/experian-mosaic/apis';
import { parseMosaicMetadataResponse } from 'store/configuration/experian-mosaic/utils';
import { useIsFreemiumUser } from 'hooks/use-freemium-user/use-freemium-user';

export const useFetchExperianMosaicData = () => {
    const dispatch = useAppDispatch();
    const isFreemiumUser = useIsFreemiumUser();

    const connectedAction = useMemo(
        () => bindActionCreators(experianMosaicActions, dispatch),
        [dispatch],
    );

    useEffect(() => {
        if (!isFreemiumUser) {
            const fetchExperianMosaicData = async () => {
                try {
                    const response = await fetchMosaicData();
                    if (response.error_code === 0) {
                        const mosaicMetadata = parseMosaicMetadataResponse(response.data);
                        connectedAction.setData(mosaicMetadata.data);
                        connectedAction.setHierarchicalTree(mosaicMetadata.tree);
                        connectedAction.setDatasetVersion(response.data.id);
                        connectedAction.setIsLoaded(true);
                    } else {
                        throw new Error(response.result);
                    }
                } catch (error) {
                    console.error(error);
                }
            };
            fetchExperianMosaicData();
        }
    }, [connectedAction, isFreemiumUser]);
};
