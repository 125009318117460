import styles from './explore-drawer-option-body.module.scss';
import React from 'react';
import { ExploreOpenReportButton } from 'features/explore/shared/components/open-report-button/explore-open-report-button';
import { ToggleCompareButton } from 'features/explore/components/explore-side-bar/components/explore-drawer/explore-drawer-option/toggle-compare-button/toggle-compare-button';
import { POIActionProps } from 'features/explore/components/explore-side-bar/components/explore-drawer/drawer-options';

export const RegularOptionBody = ({ poi, enableSelection }: POIActionProps) => {
    return (
        <div className={styles.action}>
            <ExploreOpenReportButton poi={poi} />
            {enableSelection && <ToggleCompareButton poi={poi} />}
        </div>
    );
};
