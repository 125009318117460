/* eslint-disable max-len */
import React from 'react';

import {
    useRemoveSelectedSearchSuggestionEffect,
    useSearchApiOnMapConfigChange,
    useSearchApiOnSearchInputChange,
    useSetMapOptionsOnInitialRender,
    useUpdateUserSettingsOnFiltersChange,
    useUpdateUserSettingsOnMapBoundsChange,
} from 'features/poi-selection-map/poi-selection-menu/menu-items/search-menu-item/hooks/search-poi-hooks';
import { SearchPoiSelectorTable } from 'features/poi-selection-map/poi-selection-menu/menu-items/search-menu-item/search-poi-selector-table/search-poi-selector-table';
import { SearchMapLegend } from 'features/poi-selection-map/poi-selection-menu/menu-items/search-menu-item/search-map-legend/search-map-legend';

export const SearchPoi = () => {
    useSetMapOptionsOnInitialRender();
    useUpdateUserSettingsOnMapBoundsChange();
    useUpdateUserSettingsOnFiltersChange();
    useSearchApiOnMapConfigChange();
    useSearchApiOnSearchInputChange();
    useRemoveSelectedSearchSuggestionEffect();

    return (
        <>
            <SearchPoiSelectorTable />
            <SearchMapLegend />
        </>
    );
};
