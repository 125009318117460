import { useEffect, useMemo, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { useAppDispatch } from 'store/app-store-hooks';
import type {
    ConfigurationCategoriesData,
    ChainsByCategoriesType,
    ConfigurationCategoriesDataGroupCategory,
    ConfigurationCategoriesDataSubCategory,
    ConfigurationCategoriesDataChain,
} from '@placer-ui/types';

import { useSelectCategoriesData } from 'store/configuration/categories/selectors/selectors-hooks';
import { useChainsByCategories } from 'store/configuration/chains-by-categories/selectors';
import { configurationCategoriesActions } from 'store/configuration/categories/slices/categories-slice';
import { sortStrings } from 'utils/strings-sorter/strings-sorter';

function isSubCategory(category: any): category is ConfigurationCategoriesDataSubCategory {
    return category.type === 'sub_category';
}

// update by reference - adds chains into sub-categories
function addChains(
    categories: ConfigurationCategoriesData[],
    chainsByCategories: ChainsByCategoriesType,
) {
    categories.forEach((category) => {
        if (isSubCategory(category)) {
            const subCategory: ConfigurationCategoriesDataSubCategory = category;
            const chains = chainsByCategories[subCategory.label] ?? [];
            const children: ConfigurationCategoriesDataChain[] = chains
                .map((chainItem) => {
                    return {
                        type: 'chain',
                        id: chainItem.id,
                        label: chainItem.name,
                    } as ConfigurationCategoriesDataChain;
                })
                .sort((A, B) => sortStrings(A.label, B.label));
            subCategory.children = children;
        } else if (Array.isArray(category.children)) {
            addChains(category.children, chainsByCategories);
        }
    });
}

export const useCombineCategoriesAndChains = () => {
    const dispatch = useAppDispatch();
    const categories = useSelectCategoriesData();
    const chainsByCategories = useChainsByCategories();
    const wereCategoriesAndChainsBuilt = useRef(false);

    const connectedAction = useMemo(
        () => bindActionCreators(configurationCategoriesActions, dispatch),
        [dispatch],
    );

    useEffect(() => {
        if (Array.isArray(categories) && categories.length) {
            // categories is initialized
            if (typeof chainsByCategories === 'object' && chainsByCategories !== null) {
                // chainsByCategories is valid
                const chainsKeys = Object.keys(chainsByCategories);
                if (chainsKeys.length) {
                    // chainsByCategories is initialized
                    if (!wereCategoriesAndChainsBuilt.current) {
                        const categoriesWithChains = structuredClone(categories);
                        addChains(categoriesWithChains, chainsByCategories);
                        connectedAction.setCategoriesWithChains(
                            categoriesWithChains as ConfigurationCategoriesDataGroupCategory[],
                        );
                        wereCategoriesAndChainsBuilt.current = true;
                    }
                }
            }
        }
    }, [categories, chainsByCategories, connectedAction]);
};
