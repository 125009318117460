import { googleLogout } from '@react-oauth/google';

import type { AppDispatch } from 'store/store';
import type { QueryClient } from '@tanstack/react-query';
import { userAPI } from 'API/user-api';
import { impersonateUserCookieStorage, userCookieStorage } from 'core/authentification';
import { goToSignIn } from 'router/go-to';
import { destroyStore } from 'store/destroy-store';
import { destroyFlowControl } from 'core/flow-control/flow-control';

export const invalidateUser = (dispatch: AppDispatch, queryClient: QueryClient) => {
    window.placerMobile?.token.reset();
    googleLogout();
    userCookieStorage.removeUser();
    impersonateUserCookieStorage.removeUser();
    queryClient.clear();
    dispatch(destroyStore());
    destroyFlowControl();
};

export const authLogout = (queryClient: QueryClient) => async (dispatch: AppDispatch) => {
    try {
        await userAPI.logout();
    } catch (e) {
        console.error(e);
    } finally {
        invalidateUser(dispatch, queryClient);
        goToSignIn({ forcePageReload: true });
    }
};

export const impersonateAuthLogout =
    (queryClient: QueryClient) => async (dispatch: AppDispatch) => {
        invalidateUser(dispatch, queryClient);
    };
