import { useCallback } from 'react';
import { sendSplunk } from 'API/send-splunk/send-splunk';
import { getPathStateName } from 'tracking/get-path-state-name';
import { useTrackingContext } from 'tracking/context';
import type { DefaultEventData, EventData, SearchEventsData } from 'types/splunk/events';
import { getUserAgentTechnicalData } from 'utils/get-user-agent-data/get-user-agent-data';
import { userCookieStorage } from 'core/authentification';

export const useSplunkCallback = <EventDataType extends EventData>(
    defaultData?: DefaultEventData,
) => {
    const token = userCookieStorage.token;
    const { reportConfig, viewName } = useTrackingContext();

    return useCallback(
        (eventData?: EventDataType) => {
            if (!token) {
                return;
            }
            const defaultViewData = {
                view_name: viewName,
                technical_data: getUserAgentTechnicalData(),
                ...(reportConfig ? { report_config: reportConfig } : {}),
            };

            const eventPayload = {
                state: getPathStateName(),
                ...defaultViewData,
                ...defaultData,
                ...eventData,
            };

            sendSplunk(token, eventPayload);
        },
        [token, viewName, reportConfig, defaultData],
    );
};

export const useSplunkSearchCallback = () => {
    const sendEvent = useSplunkCallback<SearchEventsData>();
    return useCallback(
        (eventData: SearchEventsData) => {
            sendEvent(eventData);
        },
        [sendEvent],
    );
};
