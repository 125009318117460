import { createSelector } from '@reduxjs/toolkit';

import {
    selectChainsLimitedReportDefaultDateRage,
    selectChainsFullReportDefaultDateRage,
} from 'store/selectors/app-selectors';
import { selectAccessLevelToChainsMap } from '../chains-report-chains-selectors-factory';

export const selectChainsReportDefaultDateRange = createSelector(
    selectAccessLevelToChainsMap,
    selectChainsFullReportDefaultDateRage,
    selectChainsLimitedReportDefaultDateRage,
    (
        { LIMITED, MISSING_PERMISSIONS },
        { fullDefaultStartDate, fullDefaultEndDate },
        { limitedDefaultStartDate, limitedDefaultEndDate },
    ) => {
        if (LIMITED.length || MISSING_PERMISSIONS.length) {
            return {
                defaultStartDate: limitedDefaultStartDate,
                defaultEndDate: limitedDefaultEndDate,
            };
        }

        return {
            defaultStartDate: fullDefaultStartDate,
            defaultEndDate: fullDefaultEndDate,
        };
    },
);
