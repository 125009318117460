import { createAsyncThunk } from '@reduxjs/toolkit';

import { ChainsReportState } from 'features/industry/store/chains-store';
import {
    setChainsSearchSearchOpenStatus,
    setSelectedChainsEntityModels,
} from 'features/industry/store/chains-report-search-store';
import { chainsEntitySelectors } from 'features/industry/store/chains-report-chains-store';

export const openChainsSearchSection = createAsyncThunk<void, void, { state: ChainsReportState }>(
    'industry/open-search-section',
    async (_, { dispatch, getState }) => {
        const state = getState();
        const chainsInReport = chainsEntitySelectors.selectAll(state);

        dispatch(setSelectedChainsEntityModels(chainsInReport));
        dispatch(setChainsSearchSearchOpenStatus(true));
    },
);
