import { VenueFlags } from 'core/entities';
import { ReactElement } from 'react';
import { PlaceWithFilters } from 'features/insights/store/poi/poi-types';
import { NearbyActivityOption } from './explore-drawer-option/options/nearby-report-option';
import { EnclosedPoiOption } from './explore-drawer-option/options/enclosed-poi-option';
import { VerifiedReportOption } from './explore-drawer-option/options/verified-report-option';
import { PointOfInterestFilters } from 'core/entities/point-of-interest-entities';

export type POIActionProps = {
    poi: PointOfInterestFilters;
    enclosingComplex?: PlaceWithFilters[];
    enableSelection?: boolean;
};

const limitedVenueOptions = [NearbyActivityOption];
const enclosedVenueOptions = [EnclosedPoiOption];

export const DrawerOptions: Record<
    VenueFlags | 'default',
    (({ poi, enclosingComplex }: POIActionProps) => ReactElement)[]
> = {
    flagged_indoor_mall: enclosedVenueOptions,
    flagged_multistory_building: enclosedVenueOptions,
    ft_abnormality: limitedVenueOptions,
    nearby_activity: limitedVenueOptions,
    flagged_dense_area: limitedVenueOptions,
    privacy_concerns: [],
    not_available: [],
    unknown_flag: limitedVenueOptions,
    default: [VerifiedReportOption, NearbyActivityOption],
};
