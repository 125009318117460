import { capitalize, lowerCase } from 'lodash';

// Capitalize each word in text, without stripping non-letter characters (like dashes)
export const safeStartCase = (text: string) => {
    if (!text) return '';

    return text.replace(/\w+/g, capitalize);
};

export const safeLowerCase = (text: string) => {
    if (!text) return '';

    return text.replace(/\w+/g, lowerCase);
};
