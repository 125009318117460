import _ from 'lodash/fp';
import { createSelector } from '@reduxjs/toolkit';
import {
    ConfigurationCategoriesData,
    ConfigurationCategoriesDataGroupCategory,
} from '@placer-ui/types';

import { selectConfiguration } from 'store/configuration/common/configuration-selectors';

function removeInvalidCategoriesRecursive(
    categories: ConfigurationCategoriesData[],
    validateChains: boolean,
) {
    const clonedCategories: ConfigurationCategoriesData[] = [];
    categories.forEach((category) => {
        if (category.type === 'sub_category') {
            // note: only sub_category have the is_visible property
            if (category.is_visible) {
                if (
                    !validateChains ||
                    (validateChains && Array.isArray(category.children) && category.children.length)
                ) {
                    clonedCategories.push(category);
                }
            }
        } else if (Array.isArray(category.children)) {
            // reaching here means the category must have some children
            const children = removeInvalidCategoriesRecursive(category.children, validateChains);
            if (children.length) {
                // only if category has children and grandchildren
                clonedCategories.push({
                    ...category,
                    children,
                });
            }
        }
    });
    return clonedCategories;
}

export const selectConfigurationCategories = createSelector(
    selectConfiguration,
    (configuration) => configuration.categories,
);

export const selectConfigurationReportOptions = createSelector(
    selectConfiguration,
    (configuration) => configuration?.reportOptions,
);

/** get all categories data as a tree */
export const selectCategoriesData = createSelector(selectConfigurationCategories, (categories) => {
    return categories.category_data as ConfigurationCategoriesData[];
});
export const selectCategoriesWithChains = createSelector(
    selectConfigurationCategories,
    (categories) => {
        return categories.categoriesWithChains as ConfigurationCategoriesDataGroupCategory[];
    },
);

export const selectPrimaryCategoriesData = createSelector(
    selectConfigurationCategories,
    (categories) =>
        categories.category_data?.flatMap((groupPrimary) => groupPrimary.children) ?? [],
);

/** get categories data which are visible and have visible children */
export const selectAvailableCategoriesData = createSelector(selectCategoriesData, (categories) => {
    return removeInvalidCategoriesRecursive(categories, false);
});
export const selectAvailableCategoriesWithChains = createSelector(
    selectCategoriesWithChains,
    (categories) => {
        return removeInvalidCategoriesRecursive(categories, true);
    },
);

function getSubCategoriesNamesRecursive(categories: ConfigurationCategoriesData[]): string[] {
    const names: string[] = [];
    categories.forEach((category) => {
        if (category.type === 'sub_category') {
            names.push(category.label);
        } else if (Array.isArray(category.children)) {
            names.push(...getSubCategoriesNamesRecursive(category.children));
        }
    });
    return names;
}
/** select available sub-categories' names */
export const selectAvailableSubCategoryNames = createSelector(
    selectAvailableCategoriesData,
    (categories) => getSubCategoriesNamesRecursive(categories),
);

export const selectAvailablePrimaryCategories = createSelector(
    selectAvailableCategoriesData,
    (categories) => {
        return categories.flatMap((groupPrimary) => groupPrimary.children!);
    },
);

export const selectIsCategoriesInitialized = createSelector(selectCategoriesData, (categories) => {
    return !_.isEmpty(categories);
});
