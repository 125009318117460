import type { Dictionary } from '@placer-ui/types';
import { VisitDurationSegments } from 'types/visit-duration';
import { ChainEntityModel, ReportOption, TagEntityModel } from 'core/entities';
import {
    LENGTH_OF_STAY_ATTRIBUTE,
    MORE_THEN_FILTER_OPERATOR,
} from 'core/constants/filter-attributes';
import {
    ALL_VISITS,
    FILTERS_BY_DURATION,
    LONG_VISITS,
    SHORT_VISITS,
} from 'shared/constants/visit-duration';
import { updatePOIAttribute } from 'utils/poi-filter/poi-filter';

export const updateAddedPOIsBySelectedDuration = <T extends ChainEntityModel | TagEntityModel>(
    currentEntities: Dictionary<T>,
    selectedFromSearch: T[],
    entitiesData: Dictionary<ReportOption>,
) => {
    let updatedEntities = selectedFromSearch;

    let durationIndex: number = 0;
    const poiWithSelectedDurationFilter = Object.values(currentEntities).find((poi) =>
        poi?.filters.attributes?.find((attr, index) => {
            const isDuration = attr[1] === LENGTH_OF_STAY_ATTRIBUTE;

            durationIndex = index;

            return isDuration;
        }),
    );

    if (!poiWithSelectedDurationFilter) {
        // Means all visit duration filters are default
        return updatedEntities;
    }

    const [operator, , value] = poiWithSelectedDurationFilter.filters.attributes![durationIndex];
    let selectedDurationFilter: VisitDurationSegments;

    if (value === 0) {
        selectedDurationFilter = ALL_VISITS;
    } else if (operator === MORE_THEN_FILTER_OPERATOR) {
        selectedDurationFilter = LONG_VISITS;
    } else {
        selectedDurationFilter = SHORT_VISITS;
    }

    return updatedEntities.map((poi) => {
        const { id } = poi.originalEntity;

        if (
            currentEntities[id] ||
            entitiesData?.[id].default_profile_name === selectedDurationFilter
        ) {
            return poi; // Filter is already updated
        }
        return updatePOIAttribute(poi, FILTERS_BY_DURATION[selectedDurationFilter]!);
    });
};
