import { useSelector } from 'react-redux';
import {
    isLimitedViewSelector,
    poiSelector,
} from 'features/insights/store/selectors/insights-selectors';
import { useMemo } from 'react';
import { LENGTH_OF_STAY_ATTRIBUTE } from 'core/constants/filter-attributes';
import { useMobileQuickFilters } from 'features/insights/hooks/use-quick-filters';
import type {
    Attribute,
    AttributeCategory,
    Attributes,
    Dictionary,
    VisitDurationType,
} from '@placer-ui/types';
import {
    ALL_VISITS,
    LONG_VISITS,
    SHORT_VISITS,
    VISITS_DURATION_TITLE_DICT,
} from 'shared/constants/visit-duration';
import { getIsCoordinate } from 'features/insights/components/nearby-activity-warning-bar/utils';
import type { PoiByIdState } from 'features/insights/store/poi/poi-types';

const getAttributesForConfiguration = (attributes?: Attributes) => {
    if (attributes && attributes.length) {
        const filteredAttributes = [...attributes].splice(1, attributes.length - 1) as Attribute[];
        const splunkFilter: Dictionary<any> = {};
        filteredAttributes.forEach((filter) => {
            if (filter.length) {
                const category = filter[1] as AttributeCategory;
                const filterValue = filter[2];
                switch (category) {
                    case LENGTH_OF_STAY_ATTRIBUTE:
                        splunkFilter.length_of_stay =
                            filterValue === 0
                                ? VISITS_DURATION_TITLE_DICT[ALL_VISITS]
                                : `${filter[0] === '<' ? 'less than' : 'more than'} ${filterValue}`;
                        break;
                    case 'household_income':
                        if (!splunkFilter.trade_area_filters) {
                            splunkFilter.trade_area_filters = {};
                        }
                        splunkFilter.trade_area_filters.household_income = filterValue;
                        break;
                    case 'home_distance':
                    case 'work_distance':
                        if (!splunkFilter.trade_area_filters) {
                            splunkFilter.trade_area_filters = {};
                        }
                        splunkFilter.trade_area_filters[category] = `${
                            filter[0] === '<' ? 'less than' : 'more than'
                        } ${filterValue}`;
                        break;
                    case 'visits_type':
                    case 'days_of_week':
                    case 'time':
                    case 'gender':
                    case 'data_source':
                    case 'age':
                    default:
                        splunkFilter[category] = filterValue;
                        break;
                }
            }
        });

        return splunkFilter;
    }

    return null;
};

export const getPropertyReportConfiguration = (poisMap: PoiByIdState, limited: boolean) => {
    const pois = Object.keys(poisMap).map((id) => poisMap[id]);

    const config: Dictionary<any> = {
        limited,
        num_of_entities: pois.length,
        contains_closed_venues: !!pois.find((poi) => poi.originalEntity.isClosed),
        report_dates: pois.map((poi) => poi.originalEntity.customData.filter.date.name).join(', '),
        custom_filters: {},
    };

    pois.forEach(({ originalEntity, filter }, i) => {
        const { id, name, subTitle, info } = originalEntity;
        const index = i + 1;
        const isCoordinate = getIsCoordinate(originalEntity);

        config[`e${index}_id`] = id;
        config[`e${index}_name`] = name + ' / ' + subTitle;
        config[`e${index}_category`] = isCoordinate ? 'Address-coordinate' : info?.category || '';
        config[`e${index}_sub_category`] = info?.sub_category || '';
        config[`e${index}_type`] = info?.type || '';
        config[`e${index}_custom_poi`] = info?.is_custom_poi;
        config[`e${index}_nearby_activity`] = !!filter?.config;
        config[`e${index}_closed_venues`] = info?.is_closed;

        const hasDurationFilter = filter?.attributes?.find(
            (filter) => filter[1] === LENGTH_OF_STAY_ATTRIBUTE,
        );

        const filters = getAttributesForConfiguration(filter.attributes);

        if (!hasDurationFilter) {
            const defaultProfileName = info?.default_profile_name;
            switch (defaultProfileName) {
                case SHORT_VISITS:
                    config.custom_filters[`e${index}`] = {
                        length_of_stay: 'less than 10',
                    };
                    break;
                case LONG_VISITS:
                    config.custom_filters[`e${index}`] = {
                        length_of_stay: 'more than 10',
                    };
                    break;
                default:
                    config.custom_filters[`e${index}`] = {
                        length_of_stay: `${
                            VISITS_DURATION_TITLE_DICT[defaultProfileName as VisitDurationType]
                        }`,
                    };
                    break;
            }
        }

        if (filters !== null) {
            config.custom_filters[`e${index}`] = {
                ...filters,
                ...config.custom_filters[`e${index}`],
            };
        }
    });

    return config;
};

export const getPropertyReportName = (poisMap: PoiByIdState) => {
    const splitUrl = window.location.href.split(/[?,/]/);
    let viewName = splitUrl[6];

    const viewNameSuffix = Object.keys(poisMap)
        .map((id) => poisMap[id].originalEntity.name)
        .join(' / ');

    return `${viewName} - ${viewNameSuffix}`;
};

export const useTrackingData = () => {
    const poisMap = useSelector(poiSelector);
    const limited = useSelector(isLimitedViewSelector);
    const reportConfig = getPropertyReportConfiguration(poisMap, limited);
    const viewName = getPropertyReportName(poisMap);

    return useMemo(() => {
        return {
            reportConfig,
            viewName,
        };
    }, [reportConfig, viewName]);
};

export const useMobileTrackingData = () => {
    const poisMap = useSelector(poiSelector);
    const limited = useSelector(isLimitedViewSelector);
    const reportConfig = getPropertyReportConfiguration(poisMap, limited);
    const viewName = getPropertyReportName(poisMap);
    const quickFilters = useMobileQuickFilters();

    return useMemo(() => {
        const dateLabel = quickFilters.find(({ type }) => type === 'date')?.text;
        const dates = Object.keys(poisMap)
            .map((_) => dateLabel)
            .join(', ');
        return {
            reportConfig: {
                ...reportConfig,
                url: window.location.href,
                report_dates: dates,
            },
            viewName,
        };
    }, [poisMap, quickFilters, reportConfig, viewName]);
};
