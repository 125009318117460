import React from 'react';
import { DesertedIslandSVG } from 'components/assets';

import styles from './starred-reports-empty.module.scss';
import { StarOutlineIcon } from 'components/assets/Icons/Icons';
import classNames from 'classnames';

type StarredReportsEmptyProps = {
    reportType?: string;
    className?: string;
};

export const StarredReportsEmpty = ({
    reportType,
    className,
}: StarredReportsEmptyProps) => {
    const suffix = reportType
        ? `${reportType} report’s toolbar`
        : 'report’s toolbar';
    return (
        <div className={classNames(styles.wrapper, className)}>
            <h2>You haven't starred any reports yet</h2>
            <p>
                To star a report click the{' '}
                <StarOutlineIcon className={styles.inlineIcon} /> on the{' '}
                {suffix}
            </p>
            <div className={styles.imgWrapper}>
                <DesertedIslandSVG className={styles.img} />
                <div className={styles.square} />
            </div>
        </div>
    );
};
