const PREFIX = 'admin-panel';

export const getPathStateName = (relativeUrl?: string) => {
    const url = relativeUrl || (window.location.pathname + window.location.search);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, section, ...splitUrl] = url.split(/[?,/]/);
    const parts = [PREFIX, section];

    switch (section) {
        case 'explore':
        case 'custom-poi':
            // ignore splitUrl[0]
            break;
        case 'insights':
            parts.push(splitUrl[0] === 'placer-xtra' ? 'placer-xtra' : splitUrl[2]);
            break;
        default:
            if (splitUrl[0]) {
                parts.push(splitUrl[0]);
            }
    }
    return parts.join('.');
};
