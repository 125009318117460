/* eslint-disable max-len */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RouteInitializerProps } from 'router';
import type { Dictionary, PlaceType } from '@placer-ui/types';
import { FilterEntity } from 'features/void-analysis/common/types/filters';
import { RequestFilterEntity } from 'features/void-analysis/common/types/request-filters';
import { PoiEntity } from 'features/void-analysis/common/types/chains';
import {
    RecentReport,
    ScoreBounds,
    MatchScoreCalculation,
} from 'features/void-analysis/common/types/server-responses';
import {
    RankingsSortType,
    RankingTableColumn,
} from 'features/void-analysis/common/types/ranking-table';
import { DEFAULT_VISIBLE_COLUMNS } from 'features/void-analysis/constants';
import {
    CreatedOrder,
    SharedEntity,
    VoidAnalysisRankings,
    VoidEntity,
} from 'features/void-analysis/common/types/entities';
import { VoidAnalysisUi } from 'features/void-analysis/common/types/ui';
import {
    CardsKpisSelectedTypes,
    PrintCustomization,
} from 'features/void-analysis/common/types/print-customization';
import {
    initialMatchScoreCalculation,
    initialScoreBounds,
    printCustomizationInitialState,
} from 'features/void-analysis/store/initial-state';
import { DfsSimilarityParamSelectedTypes } from 'features/void-analysis/common/types/print-customization';
import { SharedReport } from 'core/entities/share-report/share-report';
import { ReportMethodData } from 'features/void-analysis/common/types/report-method-metadata';
import {
    Method,
    MethodData,
    MethodsName,
} from 'features/void-analysis/sections/empty-space/types/methods-selector-modal-types';
import { ErrorType } from 'API/common/constants';

export type ErrorSections = 'sharedWithMe';

export type ErrorInfo = {
    isError: boolean;
    info?: ErrorType;
};
export interface VoidAnalysisState {
    entities: Dictionary<VoidEntity>;
    ids: string[];
    recentReports: RecentReport[] | null;
    sharedWithMeReports: SharedReport[] | null;
    isLoadingContent: boolean;
    isRequestingData: boolean;
    reportId: string;
    sharedEntity?: SharedEntity;
    createdOrders: Dictionary<string>;
    advancedSettings: FilterEntity[];
    rankingPage: {
        table: {
            records: PoiEntity[][];
            totalRecords: number;
            currentPage: number;
            visibleColumns: RankingTableColumn[];
            editTableColumnsSorted: RankingTableColumn[];
            sortBy?: RankingsSortType;
        };
        filters: FilterEntity[];
        filtersRequest: RequestFilterEntity[];
        changedFilters: string[];
        scoresBounds?: ScoreBounds;
        isChangingFilters: boolean;
        checkboxFilterClearSearch?: boolean;
        matchScoreCalculation?: MatchScoreCalculation;
        showClearAll?: boolean;
        useDefaultFilters?: boolean;
        advancedSettingsVisible: boolean;
    };
    ui: VoidAnalysisUi;
    errorInfo: ErrorInfo;
    printCustomization: PrintCustomization;
    methodData: {
        reportMethodData: ReportMethodData;
        methodsInfo: MethodData;
    };
    matav_version_id: string;
    errors: {
        [key in ErrorSections]?: string;
    };
}

export const initialState: VoidAnalysisState = {
    entities: {},
    ids: [],
    recentReports: null,
    sharedWithMeReports: null,
    isLoadingContent: false,
    isRequestingData: false,
    reportId: '',
    advancedSettings: [],
    createdOrders: {},
    rankingPage: {
        table: {
            records: [],
            totalRecords: 0,
            currentPage: 1,
            visibleColumns: DEFAULT_VISIBLE_COLUMNS,
            editTableColumnsSorted: [],
            sortBy: undefined,
        },
        filters: [],
        filtersRequest: [],
        changedFilters: [],
        isChangingFilters: false,
        scoresBounds: initialScoreBounds,
        checkboxFilterClearSearch: false,
        matchScoreCalculation: initialMatchScoreCalculation,
        showClearAll: undefined,
        useDefaultFilters: undefined,
        advancedSettingsVisible: false,
    },
    errorInfo: {
        isError: false,
    },
    ui: {
        contentWidth: 0,
        totalPageWidth: 0,
        reportsPage: {
            headerMapExpanded: false,
        },
    },
    printCustomization: printCustomizationInitialState,
    methodData: {
        reportMethodData: {
            ta_entity_id: '',
            ta_entity_name: '',
            ta_method: 'tta' as MethodsName,
            ta_entity_type: 'complex' as PlaceType,
            ta_entity_distance: 0,
            ta_entity_address: null,
        },
        methodsInfo: {
            methods: [] as Method[],
            default: 'tta' as MethodsName,
        },
    },
    matav_version_id: '',
    errors: {
        sharedWithMe: undefined, // Shared With Me tab
    },
};
export const initialVoidAnalysisState: VoidAnalysisState = initialState;

export const voidAnalysisSlice = createSlice({
    name: 'void_analysis',
    initialState: initialVoidAnalysisState,
    reducers: {
        resetState: () => {
            return initialVoidAnalysisState;
        },
        setIsLoadingContent: (state: VoidAnalysisState, action: PayloadAction<boolean>) => {
            state.isLoadingContent = action.payload;
        },
        setIsRequestingData: (state: VoidAnalysisState, action: PayloadAction<boolean>) => {
            state.isRequestingData = action.payload;
        },
        setIsChangingFilters: (state: VoidAnalysisState, action: PayloadAction<boolean>) => {
            state.rankingPage.isChangingFilters = action.payload;
        },
        setError: (
            state: VoidAnalysisState,
            action: PayloadAction<{ section: ErrorSections; errorInfo: string }>,
        ) => {
            state.errors = {
                ...state.errors,
                [action.payload.section]: action.payload.errorInfo,
            };
        },
        clearError: (state: VoidAnalysisState, action: PayloadAction<ErrorSections>) => {
            state.errors = {
                ...state.errors,
                [action.payload]: undefined,
            };
        },
        setGeneralError: (state: VoidAnalysisState, action: PayloadAction<ErrorInfo>) => {
            state.errorInfo = action.payload;
        },
        setRecentReportsList: (
            state: VoidAnalysisState,
            action: PayloadAction<RecentReport[] | null>,
        ) => {
            state.recentReports = action.payload;
        },
        setSharedWithMeReportsList: (
            state: VoidAnalysisState,
            action: PayloadAction<SharedReport[] | null>,
        ) => {
            state.sharedWithMeReports = action.payload;
        },
        setReportId: (state: VoidAnalysisState, action: PayloadAction<string>) => {
            state.reportId = action.payload;
        },
        setSharedEntity: (state: VoidAnalysisState, action: PayloadAction<SharedEntity>) => {
            state.sharedEntity = action.payload;
        },
        removeSharedEntity: (state: VoidAnalysisState) => {
            state.sharedEntity = undefined;
        },
        setCreatedOrder: (state: VoidAnalysisState, action: PayloadAction<CreatedOrder>) => {
            const { poiId, type } = action.payload;
            state.createdOrders[poiId] = type;
        },
        setRankingsAndFilters: (
            state: VoidAnalysisState,
            action: PayloadAction<VoidAnalysisRankings>,
        ) => {
            const { filters, records, totalRecords, scoresBounds, matchScoreCalculation } =
                action.payload;
            state.rankingPage.table.records[state.rankingPage.table.currentPage] = records;
            state.rankingPage.table.totalRecords = totalRecords;
            state.rankingPage.filters = filters;
            state.rankingPage.scoresBounds = scoresBounds;
            state.rankingPage.matchScoreCalculation = matchScoreCalculation;
        },
        setRankingPageTableRecords: (
            state: VoidAnalysisState,
            action: PayloadAction<PoiEntity[][]>,
        ) => {
            state.rankingPage.table.records = action.payload;
        },
        setAdvancedSettings: (state: VoidAnalysisState, action: PayloadAction<FilterEntity[]>) => {
            state.advancedSettings = action.payload;
        },
        setCurrentPage: (state: VoidAnalysisState, action: PayloadAction<number>) => {
            state.rankingPage.table.currentPage = action.payload;
        },
        clearTableRecords: (state: VoidAnalysisState) => {
            state.rankingPage.table.records = [];
        },
        setVisibleColumns: (
            state: VoidAnalysisState,
            action: PayloadAction<RankingTableColumn[]>,
        ) => {
            state.rankingPage.table.visibleColumns = action.payload;
        },
        setRankingsTableSortBy: (
            state: VoidAnalysisState,
            action: PayloadAction<RankingsSortType>,
        ) => {
            state.rankingPage.table.sortBy = action.payload;
        },
        editTableColumnsSorted: (
            state: VoidAnalysisState,
            action: PayloadAction<RankingTableColumn[]>,
        ) => {
            state.rankingPage.table.editTableColumnsSorted = action.payload;
        },
        changeFilterState: (state: VoidAnalysisState, action: PayloadAction<FilterEntity>) => {
            const idx = state.rankingPage.filters.findIndex(
                (filter) => filter.name === action.payload.name,
            );

            state.rankingPage.filters[idx] = {
                ...action.payload,
                changed: true,
            };
            state.rankingPage.changedFilters.push(action.payload.name);
        },
        clearAllChangedFilters: (state: VoidAnalysisState) => {
            state.rankingPage.filters.forEach((filter) => {
                filter.changed = false;
            });
        },
        setCheckboxFilterClearSearch: (
            state: VoidAnalysisState,
            action: PayloadAction<boolean>,
        ) => {
            state.rankingPage.checkboxFilterClearSearch = action.payload;
        },
        clearChangedFiltersList: (state: VoidAnalysisState) => {
            state.rankingPage.changedFilters = [];
        },
        setRequestFilters: (
            state: VoidAnalysisState,
            action: PayloadAction<RequestFilterEntity[]>,
        ) => {
            state.rankingPage.filtersRequest = action.payload;
        },
        setVoidEntity: (state: VoidAnalysisState, action: PayloadAction<VoidEntity[]>) => {
            const entities = action.payload;

            state.ids = entities.map((entity) => entity.id);
            state.entities = entities.reduce<Dictionary<VoidEntity>>((entities, entity) => {
                entities[entity.id] = entity;

                return entities;
            }, {});
        },
        setMethodData: (state: VoidAnalysisState, action: PayloadAction<ReportMethodData>) => {
            state.methodData.reportMethodData = action.payload;
        },
        setMatavVersionId: (state: VoidAnalysisState, action: PayloadAction<string>) => {
            state.matav_version_id = action.payload;
        },
        setMethodsInfo: (state: VoidAnalysisState, action: PayloadAction<MethodData>) => {
            state.methodData.methodsInfo = action.payload;
        },
        setAdvancedSettingsVisible: (state: VoidAnalysisState, action: PayloadAction<boolean>) => {
            state.rankingPage.advancedSettingsVisible = action.payload;
        },
        cleanVoidEntity: (state: VoidAnalysisState) => {
            state.ids = [];
            state.entities = {};
        },
        clearRankingContent: (state: VoidAnalysisState) => {
            state.reportId = '';
            state.entities = {};
            state.ids = [];
            state.rankingPage.table = { ...initialVoidAnalysisState.rankingPage.table };
            state.rankingPage.filters = [];
            state.rankingPage.filtersRequest = [];
            state.rankingPage.matchScoreCalculation = initialMatchScoreCalculation;
        },
        setUiProperties: (
            state: VoidAnalysisState,
            action: PayloadAction<Partial<VoidAnalysisUi>>,
        ) => {
            state.ui = {
                ...state.ui,
                ...action.payload,
            };
        },
        setPrintCustomizationCardKpiSelection: (
            state: VoidAnalysisState,
            action: PayloadAction<CardsKpisSelectedTypes>,
        ) => {
            const selectedCard = state.printCustomization.matchAnalysisPage.cardsKpiSelected.find(
                (card) => card.key === action.payload.key,
            );
            selectedCard!.selected = action.payload.selected;
        },
        setPrintCustomizationCardsKpiSelections: (
            state: VoidAnalysisState,
            action: PayloadAction<CardsKpisSelectedTypes[]>,
        ) => {
            state.printCustomization.matchAnalysisPage.cardsKpiSelected = action.payload;
        },
        setPrintDfsSimilarityParamSelection: (
            state: VoidAnalysisState,
            action: PayloadAction<DfsSimilarityParamSelectedTypes>,
        ) => {
            const selectedCard =
                state.printCustomization.matchAnalysisPage.dfsSimilarityParamSelected.find(
                    (card) => card.key === action.payload.key,
                );
            if (selectedCard) {
                selectedCard.selected = action.payload.selected;
            } else {
                state.printCustomization.matchAnalysisPage.dfsSimilarityParamSelected.push({
                    key: action.payload.key,
                    selected: action.payload.selected,
                });
            }
        },
        setPrintDfsSimilarityParamSelections: (
            state: VoidAnalysisState,
            action: PayloadAction<DfsSimilarityParamSelectedTypes[]>,
        ) => {
            state.printCustomization.matchAnalysisPage.dfsSimilarityParamSelected = action.payload;
        },
        setPrintDfsPopulationOverviewSelection: (
            state: VoidAnalysisState,
            action: PayloadAction<boolean>,
        ) => {
            state.printCustomization.matchAnalysisPage.dfsPopulationOverviewSelected =
                action.payload;
        },
        setShowClearAll: (state: VoidAnalysisState, action: PayloadAction<boolean | undefined>) => {
            state.rankingPage.showClearAll = action.payload;
        },
        setUseDefaultFilters: (
            state: VoidAnalysisState,
            action: PayloadAction<boolean | undefined>,
        ) => {
            state.rankingPage.useDefaultFilters = action.payload;
        },
        setVoidAnalysisData: (state: VoidAnalysisState, action: PayloadAction<any>) => {
            const {
                entities,
                ids,
                recentReports,
                sharedWithMeReports,
                isLoadingContent,
                isRequestingData,
                reportId,
                advancedSettings,
                createdOrders,
                rankingPage,
                errorInfo,
                ui,
                printCustomization,
                methodData,
                errors,
            } = action.payload;

            state.entities = entities;
            state.ids = ids;
            state.recentReports = recentReports;
            state.sharedWithMeReports = sharedWithMeReports;
            state.isLoadingContent = isLoadingContent;
            state.isRequestingData = isRequestingData;
            state.reportId = reportId;
            state.advancedSettings = advancedSettings;
            state.createdOrders = createdOrders;
            state.rankingPage = rankingPage;
            state.errorInfo = errorInfo;
            state.ui = ui;
            state.printCustomization = printCustomization;
            state.methodData = methodData;
            state.errors = errors;
        },
    },
});

export const voidAnalysisReducer = {
    voidAnalysis: voidAnalysisSlice.reducer,
};

export const voidAnalysisActions = voidAnalysisSlice.actions;

export const voidAnalysisStoreInitializer = ({ dispatch }: RouteInitializerProps) => {
    dispatch(voidAnalysisSlice.actions.resetState());
};
