/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';
import styles from 'components/assets/Icons/Icons.module.scss';

import { IconProps } from 'components/assets/Icons/Icons';

type PinWithSvgProps = {
    props: CustomIconComponentProps | SVGProps<SVGSVGElement>;
    pinIconDataURL: string;
    pathProps?: React.SVGProps<SVGPathElement>;
    imageProps?: React.SVGProps<SVGImageElement>;
};

export const PinWithIconSvg = ({ props, pinIconDataURL, pathProps, imageProps }: PinWithSvgProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        viewBox="0 0 20 25"
        className={styles.animation}
    >
        <g
            transform="translate(0 .5)"
            stroke={props.style?.borderColor || props.fill}
            fillRule="evenodd"
        >
            <path
                {...pathProps}
                d="M9.98.5a9.521 9.521 0 016.735 2.766A9.395 9.395 0 0119.5 9.954c0 2.443-.836 4.433-2.463 6.34-.627.735-1.797 1.928-3.024 3.14l-.568.56A295.178 295.178 0 0110 23.312a297.614 297.614 0 01-3.516-3.388h0l-.402-.396-.5-.496-.488-.488c-.88-.885-1.66-1.697-2.131-2.25C1.335 14.386.5 12.396.5 9.953A9.412 9.412 0 013.276 3.27 9.462 9.462 0 019.979.5z"
                fill="inherit"
            />
            <image href={pinIconDataURL} transform="translate(4, 4)" {...imageProps}/>
        </g>
    </svg>
);

export const DefaultPinSvg = (props: CustomIconComponentProps | SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        viewBox="0 0 20 25"
        className={styles.animation}
    >
        <g
            transform="translate(0 .5)"
            stroke={props.style?.borderColor || '#49565d'}
            fillRule="evenodd"
        >
            <path
                d="M9.98.5a9.521 9.521 0 016.735 2.766A9.395 9.395 0 0119.5 9.954c0 2.443-.836 4.433-2.463 6.34-.627.735-1.797 1.928-3.024 3.14l-.568.56A295.178 295.178 0 0110 23.312a297.614 297.614 0 01-3.516-3.388h0l-.402-.396-.5-.496-.488-.488c-.88-.885-1.66-1.697-2.131-2.25C1.335 14.386.5 12.396.5 9.953A9.412 9.412 0 013.276 3.27 9.462 9.462 0 019.979.5z"
                fill="inherit"
            />
            <circle fill="#FFF" cx="10" cy="10" r="3.5" />
        </g>
    </svg>
);

export const PinIcon = (props: IconProps) => <Icon component={DefaultPinSvg} {...props} />;
