/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

export type UtilityIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type UtilityIconProps = SVGProps<SVGSVGElement> & UtilityIconUniqueProps;

export const UtilityIcon = function UtilityIcon({
    width = 18,
    height = 19,
    color = '#49565D',
    ...props
}: UtilityIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_1566_81511)">
                <circle cx="9" cy="9.03247" r="8.5" stroke={color} />
                <path
                    d="M7 12.9495C7 12.8206 7.03002 12.6169 7.09006 12.3384C7.12508 12.1785 7.17761 11.9671 7.24765 11.7041L8.17073 8.14569C8.18074 8.10444 8.18824 8.06318 8.19325 8.02192C8.20325 7.98067 8.20826 7.94199 8.20826 7.90589C8.20826 7.69445 8.14321 7.56294 8.01313 7.51137C7.88305 7.4598 7.6454 7.42628 7.30019 7.41081V6.99309C7.59037 6.97246 8.01063 6.93121 8.56098 6.86932C9.11632 6.80228 9.50907 6.74555 9.73921 6.69914L10.3996 6.56763L9.20638 11.0775C9.10632 11.4643 9.03877 11.7324 9.00375 11.882C8.9137 12.2636 8.86867 12.5111 8.86867 12.6246C8.86867 12.7381 8.89368 12.8128 8.94371 12.8489C8.99375 12.8799 9.04878 12.8953 9.10882 12.8953C9.26392 12.8953 9.45654 12.7587 9.68668 12.4854C9.91682 12.212 10.1194 11.9258 10.2946 11.6267L10.6323 11.851C10.132 12.6091 9.74922 13.1197 9.48405 13.3827C9.04878 13.8159 8.58349 14.0325 8.08818 14.0325C7.808 14.0325 7.55535 13.9448 7.33021 13.7695C7.11007 13.589 7 13.3156 7 12.9495ZM9.97186 3.03247C10.257 3.03247 10.4997 3.13561 10.6998 3.34189C10.8999 3.54818 11 3.79829 11 4.09225C11 4.3862 10.8999 4.63889 10.6998 4.85033C10.4997 5.05662 10.257 5.15976 9.97186 5.15976C9.68668 5.15976 9.44153 5.05662 9.2364 4.85033C9.03627 4.63889 8.93621 4.3862 8.93621 4.09225C8.93621 3.79829 9.03627 3.54818 9.2364 3.34189C9.44153 3.13561 9.68668 3.03247 9.97186 3.03247Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_1566_81511">
                    <rect width="18" height="18" fill="white" transform="translate(0 0.0324707)" />
                </clipPath>
            </defs>
        </svg>
    );
};
UtilityIcon.__tags = ['utility, info'] as string[];
