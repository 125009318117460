import { FEATURE_FLAGS_DEFAULTS, UserFeatureFlags } from 'core/entities/user/user-feature-flags';
import { UserPermissions } from 'core/entities/user/user-permissions';

type FlowControlState = {
    isInitialized: boolean;
    featureFlags: UserFeatureFlags;
    permissions: UserPermissions;
};

export const state = {
    isInitialized: false,
    featureFlags: FEATURE_FLAGS_DEFAULTS,
    permissions: {},
} as FlowControlState;
