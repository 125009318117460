import { useCallback, useMemo } from 'react';
import { renderToString } from 'react-dom/server';
import { GeoJsonLayer, RGBAColor } from 'deck.gl';
import { IconLayer, TextLayer } from '@deck.gl/layers';
import { GeoJsonLayerProps } from '@deck.gl/layers/geojson-layer/geojson-layer';
import { FontSettings } from '@deck.gl/layers/text-layer/font-atlas-manager';
import { Polygon } from 'geojson';
import type { CategoriesIconsDictionary, Dictionary, Place } from '@placer-ui/types';
import { BillboardPOI, PlacePOI } from 'core/entities';
import { getReportEntityFlag } from 'core/services/report-entities-service/report-entities-service';
import { svgToDataURL } from 'ui-components/google-map/utils';
import { useCommonZoom } from 'ui-components/google-map/hooks/get-map-zoom';
import { getEntityColor } from 'ui-components/google-map-layers/utils/map-layers-entity-color';
import { useClusteredVenues } from 'ui-components/google-map-layers/hooks/use-clustered-venues';
import { useGoogleMapLayersContextState } from 'ui-components/google-map-layers/context/google-map-layers-context';
import { VenuePolygon } from 'ui-components/google-map/types/types';
import {
    MapLayerVenue,
    MarkerDefinitions,
    PlaceOverlay,
    VenueStyle,
} from 'ui-components/google-map-layers/types/google-map-layers-types';
import { useCommonMapTypeId } from 'ui-components/google-map/hooks/get-map-id';

import { DefaultPinSvg, PinWithIconSvg } from 'components/assets/Icons/PinIcon';
import { DotSvg } from 'components/assets/Icons/DotIcon';
import { hexToRgbaArray } from 'utils/hex-to-rgba/hex-to-rgba';
import { textToMultiline } from 'utils/text-to-multiline/text-to-multiline';
import { useIsRestrictedPoi } from 'hooks/use-restricted-poi/use-restricted-poi';
import {
    getActiveOverlay,
    getIsOverlaySelected,
} from 'ui-components/google-map-layers/utils/get-place-overlay';
import { getFetchedCategoriesIcons } from 'utils/get-fetched-categories-icons/get-fetched-categories-icons';
import { parseHtmlSvgText } from 'utils/parse-html-text/parse-html-text';
import { TrafficPinSmallIconSvg } from 'components/assets/Icons/TrafficPinSmallIcon';

// We need this type as Deck.gl types were not updated to match latest params
// https://deck.gl/docs/api-reference/layers/text-layer
type LabelsFontSettings = FontSettings & {
    smoothing: number;
};

type GetDrawTypeProps = {
    venue: MapLayerVenue;
    activeId?: string;
    hoverId?: string;
};

type PinSvgProp = {
    style: VenueStyle;
    poi: Place;
    ignoreCategory: boolean;
};

type MapboxDeckProp = {
    beforeId?: string;
};

const getDotSvg = (style: VenueStyle) =>
    DotSvg({
        style,
        width: 50,
        height: 50,
    });

const getPinSvg = ({ style, poi, ignoreCategory = false }: PinSvgProp) => {
    let svgIcon;
    if (BillboardPOI.isBillboard(poi)) {
        svgIcon = TrafficPinSmallIconSvg;
    } else {
        svgIcon = PlacePOI.getCategoryIcon(poi)?.svg || undefined;
    }

    const pinProps = {
        style,
        width: 50,
        height: 50,
    };

    if (!svgIcon || ignoreCategory) return DefaultPinSvg(pinProps);

    const stringIcon = renderToString(
        svgIcon({
            width: 12,
            height: 12,
            color: '#fff',
        }),
    );

    return PinWithIconSvg({
        props: pinProps,
        pinIconDataURL: svgToDataURL(stringIcon),
    });
};

type IconParsedUrlSvg = {
    urlIconCategoriesMapping: Dictionary<CategoriesIconsDictionary>;
    style: VenueStyle;
    poi: Place;
    ignoreCategory: boolean;
};

const getSvgLogoUrl = ({
    urlIconCategoriesMapping,
    style,
    ignoreCategory,
    poi,
}: IconParsedUrlSvg) => {
    const category = PlacePOI.getCategoryIcon(poi);
    const sub = PlacePOI.getSubCategory(poi);
    const primary = PlacePOI.getPrimaryCategory(poi);
    const group = PlacePOI.getGroupCategory(poi);

    let smallIcon;
    let bigIcon;
    smallIcon =
        urlIconCategoriesMapping[sub]?.smallIconPath ||
        urlIconCategoriesMapping[primary]?.smallIconPath ||
        urlIconCategoriesMapping[group]?.smallIconPath;

    bigIcon =
        urlIconCategoriesMapping[sub]?.bigIconPath ||
        urlIconCategoriesMapping[primary]?.bigIconPath ||
        urlIconCategoriesMapping[group]?.bigIconPath;
    const fetchedLogoUrl = smallIcon ?? bigIcon;

    const pinProps = {
        style,
        width: 50,
        height: 50,
    };
    const imageProps = {
        width: 12,
        height: 12,
    };

    const isBillboard = BillboardPOI.isBillboard(poi);
    if (!fetchedLogoUrl || ignoreCategory || isBillboard) {
        if (category?.svg || isBillboard) {
            return getPinSvg({
                style,
                poi,
                ignoreCategory,
            });
        }

        return DefaultPinSvg(pinProps);
    }

    const svgElement = parseHtmlSvgText(fetchedLogoUrl);

    const paths = svgElement.querySelectorAll('path');
    if (paths?.length) {
        paths.forEach((path) => {
            path.style.fill = '#fff';
        });
    }

    return PinWithIconSvg({
        props: pinProps,
        pinIconDataURL: svgToDataURL(svgElement.outerHTML),
        imageProps,
    });
};

export const useIconsPropsMapping = () => {
    const isRestrictedPoiCallback = useIsRestrictedPoi();

    const { categoryPathMapping: fetchedCategoriesIconsMapping } = getFetchedCategoriesIcons();

    return useMemo(() => {
        const iconsPropsMapping: Record<'dot' | 'pin', MarkerDefinitions> = {
            dot: {
                getSize: ({ info }, state) => ([state?.hoverId].includes(info.id) ? 14 : 10),
                getPixelOffset: [0, 0],
                getIcon: ({ info }, state) => {
                    const isSelected = state?.selectedPois?.some(
                        (result) => result.info.id === info.id,
                    );
                    const venueStyle = getEntityColor(info, isSelected);

                    return {
                        id: `dot-${info.id}`,
                        url: svgToDataURL(renderToString(getDotSvg(venueStyle))),
                        width: 50,
                        height: 50,
                    };
                },
            },
            pin: {
                getSize: ({ info }, state) =>
                    [state?.activeId, state?.hoverId].includes(info.id) ? 30 : 24,
                getPixelOffset: [0, -10],
                getIcon: ({ info }, state) => {
                    const isSelected = state?.selectedPois?.some(
                        (result) => result.info.id === info.id,
                    );
                    const venueStyle = getEntityColor(info, isSelected);
                    const flag = getReportEntityFlag(info);
                    const isPrivacy = flag === 'privacy_concerns';

                    const svgUrlIcon = getSvgLogoUrl({
                        urlIconCategoriesMapping: fetchedCategoriesIconsMapping,
                        style: venueStyle,
                        poi: info,
                        ignoreCategory: isPrivacy || isRestrictedPoiCallback(info),
                    });

                    const svgPinIcon = svgToDataURL(renderToString(svgUrlIcon));

                    return {
                        id: `pin-${info.id}`,
                        url: svgPinIcon,
                        width: 50,
                        height: 50,
                        anchorY: 30,
                    };
                },
            },
        };

        return iconsPropsMapping;
    }, [isRestrictedPoiCallback, fetchedCategoriesIconsMapping]);
};

// Logos aren't rendered as part of the map's iconsLayer, therefore this function
// will be used only with either 'pin' or 'dot'
const useGetIconProps = () => {
    const iconsPropsMapping = useIconsPropsMapping();
    return useCallback(
        ({ venue, activeId, hoverId }: GetDrawTypeProps) => {
            const active = venue.info.id === activeId;
            const hovered = venue.info.id === hoverId;
            const drawType = active || hovered ? 'pin' : (venue.drawType as 'pin' | 'dot');

            return iconsPropsMapping[drawType];
        },
        [iconsPropsMapping],
    );
};

// Offsetting each layer so layers would sit in front of any other map overlay.
// This is needed for cooperating with Google's 3D visualisations as they don't support transparency
const layerOffsetFn: Dictionary<() => [number, number]> = {
    polygons: () => [0, -1e5],
    markers: () => [0, -1e6],
    labels: () => [0, -1e7],
};

/**
 * Creates the displayed layer of markers
 */
const useMapMarkersLayer = () => {
    const { hoverId, activeId, allPois, selectedPois, setHoverId, setActiveId, mapboxBeta } =
        useGoogleMapLayersContextState();
    const zoom = useCommonZoom(!!mapboxBeta)();
    const mapTypeId = useCommonMapTypeId(!!mapboxBeta)();
    const markers = useClusteredVenues(allPois, zoom);
    const getIconProps = useGetIconProps();

    return useMemo(() => {
        const { logo, pin, dot, all } = markers;
        const activeMarker = all.find(({ info }) => info.id === activeId);
        const hybridMap = mapTypeId === 'hybrid';
        const stateParams = {
            activeId,
            hoverId,
            selectedPois,
        };

        const labelsFillColor: RGBAColor = hybridMap ? [255, 255, 255] : [0, 0, 0];
        const labelsOutlineColor: RGBAColor = hybridMap ? [0, 0, 0] : [255, 255, 255, 225 * 0.75];

        const iconsLayer = new IconLayer<MapLayerVenue>({
            id: 'map-markers-layer',
            data: [...dot, ...pin, ...(activeMarker?.drawType === 'logo' ? [activeMarker] : [])],
            pickable: true,
            sizeMinPixels: 10,
            sizeMaxPixels: 50,
            parameters: {
                depthTest: true,
            },
            //@ts-expect-error PLAC-47814
            getPosition: ({ info }) => [info.geolocation.lng, info.geolocation.lat],
            getIcon: (venue) =>
                getIconProps({
                    venue,
                    activeId,
                    hoverId,
                }).getIcon(venue, stateParams),
            getSize: (venue) =>
                getIconProps({
                    venue,
                    activeId,
                    hoverId,
                }).getSize(venue, stateParams),
            getPixelOffset: (venue) =>
                getIconProps({
                    venue,
                    activeId,
                    hoverId,
                }).getPixelOffset,
            getPolygonOffset: layerOffsetFn.markers,
            onClick: ({ object: venue }) => setActiveId(venue.info.id),
            onHover: ({ object: venue }) => setHoverId(venue?.info.id),
        });

        const labelsLayer = new TextLayer<MapLayerVenue>({
            id: 'map-markers-labels-layer',
            data: [...logo, ...pin],
            //@ts-expect-error PLAC-47814
            getPosition: ({ info }) => [info.geolocation.lng, info.geolocation.lat],
            getText: ({ info }) => textToMultiline(info.name, 15),
            getSize: 12,
            fontWeight: 600,
            getColor: labelsFillColor,
            opacity: 1,
            outlineWidth: 0.5,
            outlineColor: labelsOutlineColor,
            fontSettings: {
                sdf: true,
                fontSize: 40,
                smoothing: 0.2,
            } as LabelsFontSettings,
            sizeUnits: 'pixels',
            fontFamily: 'Open Sans',
            getTextAnchor: 'middle',
            getAlignmentBaseline: 'top',
            getPixelOffset: [0, 4],
            getPolygonOffset: layerOffsetFn.labels,
        });

        return {
            layers: [iconsLayer, labelsLayer],
            clusteredMarkers: markers,
        };
    }, [
        activeId,
        getIconProps,
        hoverId,
        mapTypeId,
        markers,
        selectedPois,
        setActiveId,
        setHoverId,
    ]);
};

export const useGetActiveOverlay = () => {
    const { getOverlayPOI } = useGoogleMapLayersContextState();

    return useCallback(
        (poi: PlaceOverlay) => {
            const poiOverlays = getOverlayPOI(poi);
            if (poiOverlays?.length) {
                return getActiveOverlay(poiOverlays);
            }
        },
        [getOverlayPOI],
    );
};

export const useIsIdSelected = () => {
    const { getOverlayPOI } = useGoogleMapLayersContextState();

    return useCallback(
        (selectedOverlay: PlaceOverlay) => {
            return getIsOverlaySelected(getOverlayPOI(selectedOverlay));
        },
        [getOverlayPOI],
    );
};

/**
 * Creates the displayed layer of polygon(s)
 */
type MapPolygonLayerProps = {
    alwaysShowPolygon?: boolean;
    additionalMapboxDeckProp?: MapboxDeckProp;
};

const useMapPolygonLayer = ({
    alwaysShowPolygon,
    additionalMapboxDeckProp,
}: MapPolygonLayerProps = {}) => {
    const { hoverId, activeId, allPois, disablePolyOnHoverForNearby, mapboxBeta } =
        useGoogleMapLayersContextState();
    const getActiveOverlay = useGetActiveOverlay();

    const polygons = useMemo(() => {
        const venues = alwaysShowPolygon
            ? allPois
            : allPois.filter(({ info }) => {
                  if (disablePolyOnHoverForNearby && PlacePOI.isNearbyActivity(info)) {
                      return activeId === info.id;
                  }
                  return [hoverId, activeId].includes(info.id);
              });

        return venues.reduce<VenuePolygon[]>((features, venue) => {
            const currentOverlayPOI = getActiveOverlay(venue.info);

            const geometry = currentOverlayPOI?.geojson || venue.info.geojson;

            if (geometry) {
                features.push({
                    type: 'Feature',
                    geometry: geometry as Polygon,
                    properties: {
                        ...venue,
                        ...getEntityColor(venue.info),
                    },
                });
            }

            return features;
        }, []);
    }, [
        alwaysShowPolygon,
        allPois,
        disablePolyOnHoverForNearby,
        hoverId,
        activeId,
        getActiveOverlay,
    ]);

    return useMemo(
        () =>
            new GeoJsonLayer<VenuePolygon, GeoJsonLayerProps<VenuePolygon> & MapboxDeckProp>({
                id: 'map-polygons',
                data: polygons,
                filled: true,
                stroked: true,
                getPolygonOffset: mapboxBeta ? undefined : layerOffsetFn.polygons,
                getFillColor: ({ properties }) => hexToRgbaArray(properties.fill, 0.4) as RGBAColor,
                getLineColor: ({ properties }) =>
                    hexToRgbaArray(properties.borderColor) as RGBAColor,
                getLineWidth: 1,
                lineWidthMinPixels: 3,
                beforeId: 'road-label',
                ...additionalMapboxDeckProp,
            }),
        [polygons, mapboxBeta, additionalMapboxDeckProp],
    );
};

export const useMapLayers = ({
    alwaysShowPolygon,
    additionalMapboxDeckProp,
}: MapPolygonLayerProps = {}) => {
    const { layers, clusteredMarkers } = useMapMarkersLayer();
    const [markersLayer, labelsLayer] = layers;
    const polygonLayer = useMapPolygonLayer({
        alwaysShowPolygon,
        additionalMapboxDeckProp,
    });

    return {
        clusteredMarkers,
        layers: [polygonLayer, markersLayer, labelsLayer],
    };
};
