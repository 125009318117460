import { createSlice } from '@reduxjs/toolkit';

import { createReportChainsUiState } from 'features/chains/store/chains-report-ui-state-store/models';
import {
    setStickyBarState,
    setMissingPermissionsCheckSuppressed,
    setDatePickerState,
    setCustomFilterModalState,
    setChainsReportOptions,
} from 'features/chains/store/chains-report-ui-state-store/actions';

const initialState = createReportChainsUiState();

export const chainsReportUiStateSlice = createSlice({
    name: 'report',
    initialState: initialState,
    reducers: {
        resetState: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(setMissingPermissionsCheckSuppressed, (state, action) => {
            state.isMissingPermissionsCheckSuppressed = action.payload;
        });

        builder.addCase(setChainsReportOptions, (state, action) => {
            state.reportOptions = action.payload;
        });

        builder.addCase(setStickyBarState, (state, action) => {
            state.isStickyBarOpen = action.payload;
        });

        builder.addCase(setDatePickerState, (state, action) => {
            state.isDatePickerOpen = action.payload;
        });

        builder.addCase(setCustomFilterModalState, (state, action) => {
            state.isCustomFilterModalOpen = action.payload;
        });
    },
});

export const chainsReportUiStateReducer = chainsReportUiStateSlice.reducer;
