import React from 'react';
import classnames from 'classnames';
import { Radio as AntdRadio } from 'antd';
import styles from './radio-button-group.module.scss';
import { Tooltip } from 'ui-components/tooltip/tooltip';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { RadioGroupOptionType } from 'antd/es/radio';

export type RadioButtonItem = {
    value: string;
    label: string;
    disabled?: boolean;
    disabledDescription?: string;
    isInteractive?: boolean;
};

export type RadioButtonGroupProps = {
    options: RadioButtonItem[];
    onChange?: (value: string) => void;
    value?: string;
    defaultValue?: string;
    size?: SizeType | 'mini';
    flat?: boolean;
    className?: string;
    optionType?: RadioGroupOptionType;
    disabled?: boolean;
};

export const RadioButtonGroup = ({
    options,
    onChange,
    value,
    defaultValue,
    size = 'large',
    flat = true,
    className,
    optionType = 'button',
    disabled,
}: RadioButtonGroupProps) => {
    return (
        <AntdRadio.Group
            data-testid="radio-button-group"
            defaultValue={defaultValue}
            className={classnames(styles.radioButtonsGroup, className, {
                [styles.nonFlat]: !flat,
                [styles.mini]: size === 'mini',
            })}
            onChange={({ target: { value } }) => onChange && onChange(value)}
            size={size !== 'mini' ? size : undefined}
            {...(value ? { value } : {})}
            disabled={disabled}
        >
            {optionType === 'button'
                ? options.map(({ value, label, disabled, disabledDescription, isInteractive }) =>
                      disabled ? (
                          <Tooltip small key={value} title={disabledDescription} placement="top">
                              <AntdRadio.Button
                                  value={value}
                                  disabled={disabled && !isInteractive}
                                  className={isInteractive ? styles.disabledByInteractive : ''}
                              >
                                  {label}
                              </AntdRadio.Button>
                          </Tooltip>
                      ) : (
                          <AntdRadio.Button key={value} value={value}>
                              {label}
                          </AntdRadio.Button>
                      ),
                  )
                : options.map(({ value, label, disabled, disabledDescription }) =>
                      disabled ? (
                          <Tooltip small key={value} title={disabledDescription} placement="top">
                              <AntdRadio value={value} disabled={disabled}>
                                  {label}
                              </AntdRadio>
                          </Tooltip>
                      ) : (
                          <AntdRadio key={value} value={value}>
                              {label}
                          </AntdRadio>
                      ),
                  )}
        </AntdRadio.Group>
    );
};
