import React from 'react';
import { useIsFreemiumUser } from 'hooks/use-freemium-user/use-freemium-user';
import { useAppUiActions } from 'store/app-ui/use-app-ui-actions';
import { UPGRADE_PLAN_SCREEN } from 'extensions/upgrade-your-plan-dialog';

import { RegularArrowIcon } from 'components/assets/Icons/Icons';
import styles from './request-a-venue-autocomplete-option.module.scss';

export const RequestAVenueAutocompleteOption = () => {
    const isFreemiumUser = useIsFreemiumUser();
    const { openUpgradePlanPopup } = useAppUiActions();

    const handleFreemiumClick = () => {
        openUpgradePlanPopup(UPGRADE_PLAN_SCREEN.default);
    };

    return (
        <div
            className={styles.optionContentWrapper}
            {...(isFreemiumUser ? { onClick: handleFreemiumClick } : { 'data-elevio-module': 2 })}
        >
            <div className={styles.cantFindMessageText}>Can't find what you are looking for?</div>
            <div className={styles.letUsKnowWrapper}>
                {isFreemiumUser ? (
                    'Contact Us'
                ) : (
                    <>
                        <RegularArrowIcon /> Let us know
                    </>
                )}
            </div>
        </div>
    );
};
