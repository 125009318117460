import React, { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import {
    buildChainURL,
    selectChainsDefaultReportURL,
} from 'features/chains/store/chains-report-chains-store';
import { PlSwitch, PlRoute } from 'shared/router';
import { useBasepath } from 'router/hooks/use-basepath';
import { ErrorRoutes } from 'router/errors/error-routes';
import { RedirectOnEmptySearch, RedirectOnlyIfFirstEntry } from 'router/RedirectComponents';
import { selectChainDefaultReport } from 'store/selectors/app-selectors';
import { useRelativeUrl } from 'hooks/use-relative-url';

export const chainsBasename = '/chains';

export const ChainsRouter = ({ children }: PropsWithChildren<{}>) => {
    const { basepath } = useBasepath();
    const chainsBasePath = basepath === '/' ? '' : basepath;
    const urlPath = useRelativeUrl();
    const defaultReportURL =
        chainsBasePath + useSelector((state: any) => selectChainsDefaultReportURL(state, urlPath));
    const defaultFreemium = useSelector(selectChainDefaultReport);
    return (
        <PlSwitch>
            <PlRoute path="/:section" exact>
                <RedirectOnEmptySearch defaultUrl={defaultReportURL}>
                    {children}
                </RedirectOnEmptySearch>
            </PlRoute>
            <PlRoute path="/" exact>
                <RedirectOnlyIfFirstEntry path={defaultReportURL} />
            </PlRoute>
            <PlRoute path="/page-not-found" exact>
                <RedirectOnlyIfFirstEntry path={buildChainURL(urlPath, defaultFreemium)} />
            </PlRoute>
            <ErrorRoutes ctaText="Go to Overview" failBackUrl={defaultReportURL} />
        </PlSwitch>
    );
};
