import { compose } from 'lodash/fp';

import { ChainEntityModel } from 'core/entities';
import {
    cleanWidgetsContentAndSelectors,
    cleanWidgetsFetchFilters,
    wrapActionWithWidgetStatusUpdate,
} from 'extensions/widget/store/widget-actions';
import {
    selectChainsReportHasChains,
    setReportChainsAtChains,
} from 'features/chains/store/chains-report-chains-store';
import { setMissingPermissionsCheckSuppressed } from 'features/chains/store/chains-report-ui-state-store';

import { PLACE_COLORS } from 'core/constants/place-colors';
import { ChainsReportState } from 'features/chains/store/chains-store';
import { selectChainsReportAllChainsEntityModels } from '../../selectors';
import {
    selectChainsFullReportDefaultDateRage,
    selectChainsLimitedReportDefaultDateRage,
} from 'store/selectors/app-selectors';
import { AppDispatch } from 'store/store';

type UpdateReportHavingChainsEntityModelsProps = {
    chainEntityModels: ChainEntityModel[];
    suppressMissingPermissionsPopup?: boolean;
};

const updateReportHavingChainsEntityModelsLogic = ({
    chainEntityModels,
    suppressMissingPermissionsPopup,
}: UpdateReportHavingChainsEntityModelsProps) => (
    dispatch: AppDispatch,
    getState: () => ChainsReportState,
) => {
    const state = getState();
    const isReportInitialized = selectChainsReportHasChains(state);
    const {
        limitedDefaultEndDate,
        limitedDefaultStartDate,
    } = selectChainsLimitedReportDefaultDateRage(state);
    const { fullDefaultEndDate, fullDefaultStartDate } = selectChainsFullReportDefaultDateRage(
        state,
    );
    const currentReportEntities = selectChainsReportAllChainsEntityModels(state);

    const shouldSetDateRangeToFreemiumMonthForLimitedChains = !!suppressMissingPermissionsPopup;
    const newReportEntities = createReportEntities({
        currentReportEntities,
        newReportRawEntities: chainEntityModels,
        limitedReportDefaultStartDate: limitedDefaultStartDate,
        limitedReportDefaultEndDate: limitedDefaultEndDate,
        fullReportDefaultStartDate: fullDefaultStartDate,
        fullReportDefaultEndDate: fullDefaultEndDate,
        shouldSetDateRangeToFreemiumMonthForLimitedChains,
    });

    dispatch(cleanWidgetsContentAndSelectors());
    dispatch(setMissingPermissionsCheckSuppressed(!!suppressMissingPermissionsPopup));
    dispatch(setReportChainsAtChains(newReportEntities));

    if (isReportInitialized) {
        dispatch(cleanWidgetsFetchFilters());
    }
};

export const updateReportHavingChainsEntityModels = (
    props: UpdateReportHavingChainsEntityModelsProps,
) => (dispatch: AppDispatch) => {
    dispatch(wrapActionWithWidgetStatusUpdate(updateReportHavingChainsEntityModelsLogic(props)));
};

type CreateReportEntityArgs = CreateDateRangeSetterArgs & {
    newReportRawEntities: ChainEntityModel[]; // TODO: should be RawChainEntityModel (without color, filters)
};

function createReportEntities({
    newReportRawEntities,
    currentReportEntities,
    limitedReportDefaultStartDate,
    limitedReportDefaultEndDate,
    fullReportDefaultStartDate,
    fullReportDefaultEndDate,
    shouldSetDateRangeToFreemiumMonthForLimitedChains,
}: CreateReportEntityArgs): ChainEntityModel[] {
    const setDateRange = createDateRangeSetter({
        currentReportEntities,
        limitedReportDefaultStartDate,
        limitedReportDefaultEndDate,
        fullReportDefaultStartDate,
        fullReportDefaultEndDate,
        shouldSetDateRangeToFreemiumMonthForLimitedChains,
    });

    return compose(
        filterEmptyReportData,
        setReportEntitiesColor,
        setDateRange,
    )(newReportRawEntities);
}

function filterEmptyReportData(newReportRawEntities: ChainEntityModel[]) {
    return newReportRawEntities.filter(Boolean);
}

function setReportEntitiesColor(newReportRawEntities: ChainEntityModel[]) {
    return newReportRawEntities.map((newReportRawEntity, i) => ({
        ...newReportRawEntity,
        color: PLACE_COLORS.main[i],
    }));
}

type CreateDateRangeSetterArgs = {
    currentReportEntities: ChainEntityModel[];
    limitedReportDefaultStartDate: string;
    limitedReportDefaultEndDate: string;
    fullReportDefaultStartDate: string;
    fullReportDefaultEndDate: string;
    shouldSetDateRangeToFreemiumMonthForLimitedChains: boolean;
};

function createDateRangeSetter({
    currentReportEntities,
    limitedReportDefaultStartDate,
    limitedReportDefaultEndDate,
    fullReportDefaultStartDate,
    fullReportDefaultEndDate,
    shouldSetDateRangeToFreemiumMonthForLimitedChains,
}: CreateDateRangeSetterArgs) {
    return setDateRange;

    function setDateRange(newReportRawEntities: ChainEntityModel[]) {
        if (!shouldSetDateRangeToFreemiumMonthForLimitedChains) {
            return newReportRawEntities;
        }

        const isNewReportLimited = newReportRawEntities.some(
            (newReportRawEntity) => !newReportRawEntity.originalEntity.purchased,
        );
        if (isNewReportLimited) {
            return newReportRawEntities.map((newReportRawEntity) => ({
                ...newReportRawEntity,
                filters: {
                    date_start: limitedReportDefaultStartDate,
                    date_end: limitedReportDefaultEndDate,
                },
            }));
        }

        const isCurrentReportLimited = currentReportEntities.some(
            (currentReportEntity) => !currentReportEntity.originalEntity.purchased,
        );
        if (isCurrentReportLimited) {
            return newReportRawEntities.map((newReportRawEntity) => ({
                ...newReportRawEntity,
                filters: {
                    date_start: fullReportDefaultStartDate,
                    date_end: fullReportDefaultEndDate,
                },
            }));
        }

        return newReportRawEntities.map((newReportRawEntity) => {
            const sameChainInCurrentReport = getSameEntity(
                currentReportEntities,
                newReportRawEntity,
            );

            if (sameChainInCurrentReport) {
                return {
                    ...newReportRawEntity,
                    filters: {
                        ...sameChainInCurrentReport.filters,
                    },
                };
            }

            return {
                ...newReportRawEntity,
                filters: {
                    date_start: newReportRawEntity.filters.date_start || fullReportDefaultStartDate,
                    date_end: newReportRawEntity.filters.date_end || fullReportDefaultEndDate,
                },
            };
        });
    }
}

function getSameEntity(currentEntities: ChainEntityModel[], newEntity: ChainEntityModel) {
    const sameEntityInTheReport = currentEntities.find(
        (currentEntity) => currentEntity.uid === newEntity.uid,
    );

    if (sameEntityInTheReport) {
        return sameEntityInTheReport;
    }

    const sameChainInCurrentReport = currentEntities.find(
        (currentEntity) => currentEntity.originalEntity.id === newEntity.originalEntity.id,
    );

    if (sameChainInCurrentReport) {
        return sameChainInCurrentReport;
    }
}
