import React, { createContext, PropsWithChildren, useState } from 'react';
import { initialMapState } from 'ui-components/google-map-with-context/context/initial-state';

export const MapContext = createContext(initialMapState);

export const GoogleMapStateContextProvider = ({ children }: PropsWithChildren<{}>) => {
    const [map, setMap] = useState<google.maps.Map | undefined>(undefined);

    return (
        <MapContext.Provider
            value={{
                map,
                setMap,
            }}
        >
            {children}
        </MapContext.Provider>
    );
};
