import React from 'react';
import styles from './skeleton-loader.module.scss';
import classnames from 'classnames';

type SkeletonLoaderProps = React.ComponentPropsWithoutRef<'div'> & {
    width?: number | string;
    height: number | string;
    className?: string;
};

export const SKELETON_LOADER_TEST_ID = 'skeleton-loader';
export const SkeletonLoader = ({
    width = '100%',
    height,
    className,
    ...props
}: SkeletonLoaderProps) => {
    return (
        <div
            data-testid={SKELETON_LOADER_TEST_ID}
            className={classnames(styles.skeletonLoader, className)}
            style={{
                width,
                height,
            }}
            {...props}
        />
    );
};
