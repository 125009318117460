/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

type SearchMobileIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type SearchMobileIconProps = SVGProps<SVGSVGElement> & SearchMobileIconUniqueProps;

export const SearchMobileIcon = function SearchMobileIcon({
    width = 18,
    height = 18,
    ...props
}: SearchMobileIconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 18 18"
            fill="none"
            {...props}
        >
            <g clipPath="url(#clip0_10521_17267)">
                <path
                    d="M17.858 17.1103L13.3278 12.5801C14.6197 11.0945 15.2842 9.1653 15.1814 7.19923C15.0786 5.23316 14.2165 3.38381 12.7767 2.04101C11.337 0.698209 9.43208 -0.0331305 7.46362 0.00115312C5.49516 0.0354368 3.61691 0.832665 2.22479 2.22479C0.832665 3.61691 0.0354368 5.49516 0.00115312 7.46362C-0.0331305 9.43208 0.698209 11.337 2.04101 12.7767C3.38381 14.2165 5.23316 15.0786 7.19923 15.1814C9.1653 15.2842 11.0945 14.6197 12.5801 13.3278L17.1103 17.858C17.2106 17.9515 17.3433 18.0023 17.4803 17.9999C17.6174 17.9975 17.7481 17.942 17.8451 17.8451C17.942 17.7481 17.9975 17.6174 17.9999 17.4803C18.0023 17.3433 17.9515 17.2106 17.858 17.1103ZM1.08446 7.60908C1.08446 6.31863 1.46712 5.05716 2.18405 3.98419C2.90099 2.91122 3.91999 2.07495 5.11221 1.58111C6.30443 1.08728 7.61631 0.958071 8.88197 1.20982C10.1476 1.46158 11.3102 2.08299 12.2227 2.99547C13.1352 3.90796 13.7566 5.07053 14.0083 6.33618C14.2601 7.60184 14.1309 8.91372 13.637 10.1059C13.1432 11.2982 12.3069 12.3172 11.234 13.0341C10.161 13.751 8.89952 14.1337 7.60908 14.1337C5.87928 14.1316 4.22094 13.4435 2.99779 12.2204C1.77464 10.9972 1.08656 9.33887 1.08446 7.60908Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_10521_17267">
                    <rect width="18" height="18" fill="currentColor" />
                </clipPath>
            </defs>
        </svg>
    );
};
SearchMobileIcon.__tags = [] as string[];
