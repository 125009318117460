export const MAGNITUDES = {
    default: 0,
    thousand: 1e3,
    tens_of_thousand: 1e4,
    million: 1e6,
    billion: 1e9,
};
type NumberFormatReturn = ReturnType<typeof Intl.NumberFormat.prototype.format>;
type NumberFormatFunction = (number: number, maximumFractionDigits?: number) => NumberFormatReturn;

export const formatDefault: NumberFormatFunction = (number, maximumFractionDigits) => {
    return new Intl.NumberFormat('en-US', {
        style: 'decimal',
        maximumFractionDigits,
    }).format(number);
};

export const formatThousands: NumberFormatFunction = (number, maximumFractionDigits = 2) => {
    return `${new Intl.NumberFormat('en-US', {
        style: 'decimal',
        maximumFractionDigits,
    }).format(number / MAGNITUDES.thousand)}K`;
};

export const formatMillions: NumberFormatFunction = (number, maximumFractionDigits = 2) => {
    return `${new Intl.NumberFormat('en-US', {
        style: 'decimal',
        maximumFractionDigits,
    }).format(number / MAGNITUDES.million)}M`;
};

export const formatBillions: NumberFormatFunction = (number, maximumFractionDigits = 2) => {
    return `${new Intl.NumberFormat('en-US', {
        style: 'decimal',
        maximumFractionDigits,
    }).format(number / MAGNITUDES.billion)}B`;
};

const getMagnitudeFunction = (
    number: number,
    shouldFormatThousands: boolean,
): NumberFormatFunction => {
    const absoluteValue = Math.abs(number);
    if (absoluteValue >= MAGNITUDES.billion) {
        return formatBillions;
    } else if (absoluteValue >= MAGNITUDES.million) {
        return formatMillions;
    } else if (shouldFormatThousands && absoluteValue >= MAGNITUDES.thousand) {
        // formatting thousands is not the default behavior across app, must be explicitly stated
        return formatThousands;
    }
    return formatDefault;
};

export const numberFormat = (
    number: number,
    maximumFractionDigits?: number,
    shorten = true,
    formatThousands = false,
): NumberFormatReturn => {
    const absNumber = Math.abs(number);
    const magnitudeFunction = shorten
        ? getMagnitudeFunction(absNumber, formatThousands)
        : formatDefault;
    return `${number < 0 ? '-' : ''}${magnitudeFunction(absNumber, maximumFractionDigits)}`;
};

export const percentageFormat = (
    percentage: number,
    maximumFractionDigits = 1,
    showPositiveSign = false,
) => {
    if (percentage === 0) return '0';
    else if (Math.abs(percentage) < 0.5) {
        return percentage < 0 ? '>-0.5' : `<${showPositiveSign ? '+' : ''}0.5`;
    } else
        return `${showPositiveSign && percentage > 0 ? '+' : ''}${numberFormat(
            percentage,
            maximumFractionDigits,
        )}`;
};

export const calcFractionToPercentagesNumber = (fraction: number): number => {
    return Number(fraction.toFixed(0));
};

export const numberFormatCommas = (number: number) => new Intl.NumberFormat('en-US').format(number);

export const currencyFormat = (
    number: number,
    maximumFractionDigits: number = 0,
    symbol = '$',
    shorten = false,
    formatThousands = false,
) => `${symbol}${numberFormat(number, maximumFractionDigits, shorten, formatThousands)}`;

export const ordinalNumberFormatter = (number: number) => {
    const j = number % 10,
        k = number % 100;
    if (j === 1 && k !== 11) {
        return number + 'st';
    }
    if (j === 2 && k !== 12) {
        return number + 'nd';
    }
    if (j === 3 && k !== 13) {
        return number + 'rd';
    }
    return number + 'th';
};

export const isValidNumber = (val?: number | null, zeroValid: boolean = false) =>
    val !== null && val !== undefined && !isNaN(val) && (zeroValid || val !== 0);

/**
 * @param percentage - a number represented as percentage
 * @param desiredFractionDigits - The desired digits amount after the dot
 * @returns the decimal fraction as a string
 */
export const convertPercentageToDecimal = (
    percentage: number,
    desiredFractionDigits: number = 4,
): string => {
    const trailingZero = '0';
    const decimal = (percentage / 100).toFixed(desiredFractionDigits);
    const fractionDigits = decimal.substring(decimal.indexOf('.')).length;

    return decimal + trailingZero.repeat(Math.max(desiredFractionDigits - fractionDigits, 0));
};

export const scoreToPercentage = (fraction: number): number => {
    return Math.trunc(fraction);
};

export const convertPercentageToDecimalV2 = (
    percentage: number,
    desiredFractionDigits: number = 4,
) => {
    const decimalPercentage = percentage / 100;

    return new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: desiredFractionDigits,
    }).format(decimalPercentage);
};

export const formatNumberWithFractionDigits = (
    num: number,
    numberType: string = '%',
    fractionDigits: number = 1,
) => `${num.toFixed(fractionDigits)}${numberType}`;
