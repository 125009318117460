/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

export type HouseInSquareIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type HouseInSquareIconProps = SVGProps<SVGSVGElement> & HouseInSquareIconUniqueProps;

export const HouseInSquareIcon = function HouseInSquareIcon({
    width = 57,
    height = 57,
    ...props
}: HouseInSquareIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 57 57"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect x="0.330078" y="0.646484" width="56" height="56" rx="3" fill="#F3F3FD" />
            <path d="M17.3301 30.6465H27.3301V39.6465H17.3301V30.6465Z" fill="white" />
            <path
                d="M36.3301 40.6465H31.3301L31.3301 32.6465C31.3301 32.0942 31.7778 31.6465 32.3301 31.6465L36.3301 31.6465V40.6465Z"
                fill="white"
            />
            <path
                d="M33.3301 21.6465H40.3301V25.1465C40.3301 27.0795 38.7631 28.6465 36.8301 28.6465C34.8971 28.6465 33.3301 27.0795 33.3301 25.1465V21.6465Z"
                fill="#E1E2FF"
            />
            <path
                d="M28.3301 21.6465H35.3301V25.1465C35.3301 27.0795 33.7631 28.6465 31.8301 28.6465C29.8971 28.6465 28.3301 27.0795 28.3301 25.1465V21.6465Z"
                fill="#E1E2FF"
            />
            <path
                d="M24.3301 21.6465H30.3301V24.6465C30.3301 26.3033 28.9869 27.6465 27.3301 27.6465C25.6732 27.6465 24.3301 26.3033 24.3301 24.6465V21.6465Z"
                fill="#E1E2FF"
            />
            <path
                d="M21.6634 29.6465H27.8149C28.0994 29.6465 28.3301 29.8771 28.3301 30.1616V31.9798C28.3301 33.8208 26.8377 35.3132 24.9967 35.3132C23.1558 35.3132 21.6634 33.8208 21.6634 31.9798V29.6465Z"
                fill="#E1E2FF"
            />
            <path
                d="M16.3301 29.6465H22.9967V31.9798C22.9967 33.8208 21.5044 35.3132 19.6634 35.3132C17.8225 35.3132 16.3301 33.8208 16.3301 31.9798V29.6465Z"
                fill="#E1E2FF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.3301 19.1465C17.3301 18.3181 18.0322 17.6465 18.8983 17.6465H38.7619C39.628 17.6465 40.3301 18.3181 40.3301 19.1465V39.1465C40.3301 39.9749 39.628 40.6465 38.7619 40.6465H29.3301V39.6465H38.7619C39.0506 39.6465 39.2846 39.4226 39.2846 39.1465V19.1465C39.2846 18.8703 39.0506 18.6465 38.7619 18.6465H18.8983C18.6096 18.6465 18.3755 18.8703 18.3755 19.1465V28.6465H17.3301V19.1465Z"
                fill="#5E63E5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M32.3301 39.6465H35.3301V32.6465H32.3301L32.3301 39.6465ZM36.3301 40.6465V31.6465H32.3301C31.7778 31.6465 31.3301 32.0942 31.3301 32.6465L31.3301 40.6465H36.3301Z"
                fill="#5E63E5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.3301 37.6465V39.6465H23.3301V37.6465H21.3301ZM20.3301 40.6465H24.3301V37.6465C24.3301 37.0942 23.8824 36.6465 23.3301 36.6465H20.3301V40.6465Z"
                fill="#5E63E5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.3301 29.6465H22.9967V31.9798C22.9967 33.8208 21.5044 35.3132 19.6634 35.3132C18.7549 35.3132 17.9313 34.9497 17.3301 34.3603V39.6465L27.3301 39.6465V30.6465H28.3301V40.6465H17.3301C16.7778 40.6465 16.3301 40.1988 16.3301 39.6465V29.6465ZM17.3301 30.6465H21.9967V31.9798C21.9967 33.2685 20.9521 34.3132 19.6634 34.3132C18.3747 34.3132 17.3301 33.2685 17.3301 31.9798V30.6465Z"
                fill="#5E63E5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.1093 33.6465C22.6856 34.6428 23.7629 35.3132 24.9967 35.3132C26.8376 35.3132 28.33 33.8208 28.33 31.9798V30.1616C28.33 29.8771 28.0994 29.6465 27.8148 29.6465H22.3301V33.6465H22.1093ZM22.6633 30.6465H27.3301L27.33 31.9798C27.33 33.2685 26.2853 34.3132 24.9967 34.3132C23.708 34.3132 22.6633 33.2685 22.6633 31.9798V30.6465Z"
                fill="#5E63E5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.3301 21.6465H30.3301V24.6465C30.3301 26.3033 28.9869 27.6465 27.3301 27.6465C26.5617 27.6465 25.8608 27.3576 25.3301 26.8826V28.6465H24.3301V21.6465ZM25.3301 22.6465H29.3301V24.6465C29.3301 25.7511 28.4346 26.6465 27.3301 26.6465C26.2255 26.6465 25.3301 25.7511 25.3301 24.6465V22.6465Z"
                fill="#5E63E5"
            />
            <path
                d="M28.6669 26.6465C29.2286 27.829 30.4339 28.6465 31.8301 28.6465C33.7631 28.6465 35.3301 27.0795 35.3301 25.1465V21.6465H29.3301L29.3301 22.6465H34.3301V25.1465C34.3301 26.5272 33.2108 27.6465 31.8301 27.6465C30.4494 27.6465 29.3301 26.5272 29.3301 25.1465V26.6465H28.6669Z"
                fill="#5E63E5"
            />
            <path
                d="M34.3301 27.596C34.9653 28.2443 35.8507 28.6465 36.8301 28.6465C38.7631 28.6465 40.3301 27.0795 40.3301 25.1465V21.6465H34.3301V22.6465H39.3301V25.1465C39.3301 26.5272 38.2108 27.6465 36.8301 27.6465C35.4494 27.6465 34.3301 26.5272 34.3301 25.1465V27.596Z"
                fill="#5E63E5"
            />
        </svg>
    );
};
HouseInSquareIcon.__tags = ['venue', 'store', 'house'] as string[];
