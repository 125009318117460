/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

export type BuildingIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type BuildingIconProps = SVGProps<SVGSVGElement> & BuildingIconUniqueProps;

export const BuildingIcon = function BuildingIcon({
    width = 18,
    height = 19,
    ...props
}: BuildingIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_2006_20382)">
                <rect
                    x="18"
                    y="17.1714"
                    width="0.999999"
                    height="18"
                    rx="0.5"
                    transform="rotate(90 18 17.1714)"
                    fill="#49565D"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.5 0.171387C2.22386 0.171387 2 0.395244 2 0.671387V15.6714C2 15.9475 2.22386 16.1714 2.5 16.1714H12V0.671387C12 0.395244 11.7761 0.171387 11.5 0.171387H2.5ZM3.43013 2.9353C3.43013 2.65916 3.65399 2.4353 3.93013 2.4353H5.43013C5.70627 2.4353 5.93013 2.65916 5.93013 2.9353V4.4353C5.93013 4.71144 5.70627 4.9353 5.43013 4.9353H3.93013C3.65399 4.9353 3.43013 4.71144 3.43013 4.4353V2.9353ZM3.43013 7.42139C3.43013 7.14524 3.65399 6.92139 3.93013 6.92139H5.43013C5.70627 6.92139 5.93013 7.14524 5.93013 7.42139V8.92139C5.93013 9.19753 5.70627 9.42139 5.43013 9.42139H3.93013C3.65399 9.42139 3.43013 9.19753 3.43013 8.92139V7.42139ZM3.93013 11.4075C3.65399 11.4075 3.43013 11.6313 3.43013 11.9075V13.4075C3.43013 13.6836 3.65399 13.9075 3.93013 13.9075H5.43013C5.70627 13.9075 5.93013 13.6836 5.93013 13.4075V11.9075C5.93013 11.6313 5.70627 11.4075 5.43013 11.4075H3.93013ZM8.06978 2.9353C8.06978 2.65916 8.29364 2.4353 8.56978 2.4353H10.0698C10.3459 2.4353 10.5698 2.65916 10.5698 2.9353V4.4353C10.5698 4.71144 10.3459 4.9353 10.0698 4.9353H8.56978C8.29364 4.9353 8.06978 4.71144 8.06978 4.4353V2.9353ZM8.56978 6.92139C8.29364 6.92139 8.06978 7.14524 8.06978 7.42139V8.92139C8.06978 9.19753 8.29364 9.42139 8.56978 9.42139H10.0698C10.3459 9.42139 10.5698 9.19753 10.5698 8.92139V7.42139C10.5698 7.14524 10.3459 6.92139 10.0698 6.92139H8.56978ZM8.06978 11.9075C8.06978 11.6313 8.29364 11.4075 8.56978 11.4075H10.0698C10.3459 11.4075 10.5698 11.6313 10.5698 11.9075V13.4075C10.5698 13.6836 10.3459 13.9075 10.0698 13.9075H8.56978C8.29364 13.9075 8.06978 13.6836 8.06978 13.4075V11.9075Z"
                    fill="#49565D"
                />
                <path
                    d="M13 6.17139H15.5C15.7761 6.17139 16 6.39524 16 6.67139V15.6714C16 15.9475 15.7761 16.1714 15.5 16.1714H13V6.17139Z"
                    fill="#49565D"
                />
            </g>
            <defs>
                <clipPath id="clip0_2006_20382">
                    <rect width="18" height="18" fill="white" transform="translate(0 0.171387)" />
                </clipPath>
            </defs>
        </svg>
    );
};
BuildingIcon.__tags = ['building', 'house', 'all', 'sites'] as string[];
