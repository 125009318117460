type BaseUrlState = {
    beApiEndpoint: string;
    apigwEndpoint: string;
    esetAndRankingEndpoint: string;
};

export const customBaseUrl = {
    beApiEndpoint: process.env.REACT_APP_API_ENDPOINT,
    apigwEndpoint: process.env.REACT_APP_GATEWAY,
} as BaseUrlState;
