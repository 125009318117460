/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import styles from './tags-selector-menu.module.scss';

import { usePoiSelectionContext } from 'features/poi-selection-map/context/poi-selection-context';
import type { Tag } from '@placer-ui/types';
import { TagsSelectorTable } from 'features/poi-selection-map/poi-selection-menu/menu-items/tags-menu-items/tags-selector-table/tags-selector-table';
import { TagMemberSelectorTable } from 'features/poi-selection-map/poi-selection-menu/menu-items/tags-menu-items/tag-member-selector-table/tag-member-selector-table';
import {
    useFetchAndSetAllPlacesForTag,
    useFetchAndSetTags,
} from 'features/poi-selection-map/poi-selection-menu/menu-items/tags-menu-items/my-tags-menu-item/hooks/tag-selection-hooks';

export type TagSelectorType = 'personalTags' | 'companyTags';

type CurrentPage = 'tag-selection' | 'pois-selection';

type TagsSelectorMenuProps = {
    type: TagSelectorType;
};

export const TagsSelectorMenu = ({ type }: TagsSelectorMenuProps) => {
    const { sharedState, clearPoisList } = usePoiSelectionContext();
    const hasRequestedTags = useRef<boolean>(false);
    const fetchAndSetTags = useFetchAndSetTags();
    const fetchAndSetAllPlacesForTag = useFetchAndSetAllPlacesForTag();
    const [selectedTagId, setSelectedTagId] = useState<string>('');
    const [selectedTagName, setSelectedTagName] = useState<string>('');
    const [currentPage, setCurrentPage] = useState<CurrentPage>('tag-selection');
    const tags: Tag[] | undefined = sharedState[type];
    const lastTagRequest = useRef<string>(selectedTagId);

    useEffect(() => {
        if (!hasRequestedTags.current && !tags) {
            hasRequestedTags.current = true;
            fetchAndSetTags();
        }
    }, [fetchAndSetTags, tags]);

    useEffect(() => {
        if (selectedTagId && selectedTagId !== lastTagRequest.current) {
            lastTagRequest.current = selectedTagId;
            fetchAndSetAllPlacesForTag(selectedTagId);
        }
    }, [fetchAndSetAllPlacesForTag, selectedTagId]);

    const onTagSelected = (tagId: string) => {
        setSelectedTagId(tagId);
        setSelectedTagName(tags?.find((tag) => tag.id === tagId)?.name || '');
        setCurrentPage('pois-selection');
    };

    const onBackNavigation = () => {
        clearPoisList();
        setSelectedTagId('');
        lastTagRequest.current = '';
        setCurrentPage('tag-selection');
    };

    return (
        <div
            className={classNames(styles.container, {
                [styles.poiSelectionPage]: currentPage === 'pois-selection',
            })}
        >
            <TagsSelectorTable
                type={type}
                onTagSelected={onTagSelected}
                className={styles.childTable}
                visible={currentPage === 'tag-selection'}
            />
            <TagMemberSelectorTable
                className={styles.childTable}
                onBackNavigation={onBackNavigation}
                visible={currentPage === 'pois-selection'}
                selectedTagName={selectedTagName}
            />
        </div>
    );
};
