import { PlacePOI } from 'core/entities';
import React, { useEffect, useRef } from 'react';
import styles from './explore-list-item.module.scss';
import { formatFullAddress } from 'utils/address-formatter/address-formatter';
import {
    ExploreListItemMetrics,
    ExploreMetric,
} from 'features/explore/components/explore-side-bar/components/explore-list-item/explore-list-item-metrics';
import classNames from 'classnames';
import { Checkbox } from 'ui-components/checkbox/checkbox';
import {
    getReportEntityClosingDate,
    getReportEntityFlag,
} from 'core/services/report-entities-service/report-entities-service';
import { ExploreListItemBadges } from 'features/explore/components/explore-side-bar/components/explore-list-item/explore-list-item-badges';
import { VenueStatusIndicatorV2 } from 'ui-components/venue-status-indicator/v2/venue-status-indicator-v2';
import { useIsLockedPoi } from 'hooks/use-locked-poi-hooks/use-locked-poi-hooks';
import { SkeletonLoader } from 'utils/skeleton-loader/skeleton-loader';
import { isAddressType } from 'utils/poi-provider/poi-provider';
import { ADDRESS_TITLE } from 'core/constants/address';
import { SquareLogo } from 'ui-components/square-logo/square-logo';
import { useIsRestrictedPoi } from 'hooks/use-restricted-poi/use-restricted-poi';
import { useSegmentCallback } from 'hooks/use-segment-callback/use-segment-callback';
import { StoreIdBadge } from 'components/store-id-badge/store-id-badge';
import { getCategoryIconUrlWithFallback } from 'core/entities/category/utils';
import { useSelectAvailableCategoriesData } from 'store/configuration/categories/selectors/selectors-hooks';
import { AlertIcon } from 'components/assets/Icons/Icons';
import type { Place } from '@placer-ui/types';
import { EMPTY_SPACE_POI_TYPE } from 'features/empty-space/constants/empty-space-constants';
import { ADDRESS_ENTITY, COORDINATES_ENTITY } from 'features/explore/constants';
import { useExploreUIState } from 'features/explore/context/use-explore-ui-state';

export type ExploreListItemProps = {
    poi: Place;
    metrics?: ExploreMetric[];
    isHover?: boolean;
    onClick?: () => void;
    onHover?: (isHover: boolean) => void;
    showCheckbox?: boolean;
    isSelected?: boolean;
    onSelect?: (isSelected: boolean) => void;
    actionButton?: React.ReactNode;
    withFlag?: boolean;
    loading?: boolean;
    scrollToItem?: boolean;
    hasStoreIdBadgePermission?: boolean;
};

export const ExploreListItemLoading = () => {
    return (
        <div
            className={classNames(styles.exploreListItem, styles.loading)}
            data-testid="explore-list-item-loading"
        >
            <SkeletonLoader width={60} height={60} className={styles.logo} />
            <div className={styles.text}>
                <SkeletonLoader width={100} height={12} />
                <SkeletonLoader width={250} height={8} />
                <SkeletonLoader width={330} height={8} />
                <SkeletonLoader width={150} height={6} />
            </div>
        </div>
    );
};

export const ExploreListItem = ({
    poi,
    metrics,
    isHover,
    onClick,
    onHover,
    isSelected = false,
    withFlag = true,
    showCheckbox = false,
    onSelect,
    actionButton,
    scrollToItem,
    hasStoreIdBadgePermission,
}: ExploreListItemProps) => {
    const categoriesIconData = useSelectAvailableCategoriesData();
    const poiType = isAddressType(poi) ? 'address' : poi.type;
    const itemRef = useRef<HTMLDivElement>(null);
    const isRestrictedPoiCallback = useIsRestrictedPoi();
    const { track } = useSegmentCallback();
    const isRestricted = isRestrictedPoiCallback(poi);
    const category = PlacePOI.getPrimaryCategory(poi);
    const group = PlacePOI.getGroupCategory(poi);
    const isLockedPoiCallback = useIsLockedPoi();
    const isLockedPoi = isLockedPoiCallback(poi);
    const flag = getReportEntityFlag(poi);
    const closingDate = getReportEntityClosingDate(poi);
    const { customPoiAddresses } = useExploreUIState();

    const isPrivacy = flag === 'privacy_concerns';
    const storeId = poi?.store_id;
    let detailsComponent;
    if (withFlag && (isPrivacy || isRestricted)) {
        detailsComponent = (
            <VenueStatusIndicatorV2 flag={flag} name={poi.name} isRestricted={isRestricted} />
        );
    } else if (withFlag && closingDate) {
        detailsComponent = <VenueStatusIndicatorV2 closingDate={closingDate} name={poi.name} />;
    } else if (metrics?.length && !flag) {
        detailsComponent = <ExploreListItemMetrics metrics={metrics} />;
    }

    // scroll the list to this specific item if it is the suggested POI
    useEffect(() => {
        const itemElement = itemRef?.current;
        const listElement = itemElement?.parentElement;

        if (scrollToItem && itemElement && listElement) {
            listElement.scrollTop = itemElement.offsetTop - listElement.offsetTop;
        }
    }, [scrollToItem]);

    const onCheckboxChange = () => {
        if (onSelect) {
            track({
                category: 'Explore',
                action: 'POI Selected In Menu',
                label: category,
            });
            onSelect(!isSelected);
        }
    };

    const categoryIconUrl = getCategoryIconUrlWithFallback({
        categoriesIconData,
        info: poi,
    });
    const logoUrl = !poi.provider_data?.provider ? poi.logo_img : undefined;
    const icon = !poi.provider_data?.provider ? categoryIconUrl : undefined;

    return (
        <div
            ref={itemRef}
            className={classNames(styles.exploreListItem, {
                [styles.inList]: onHover && onSelect,
                [styles.isHover]: isHover,
                [styles.checkVisible]: showCheckbox && !isPrivacy && !isLockedPoi,
                [styles.enableSelection]: onSelect || showCheckbox,
            })}
            onClick={onClick}
            onMouseOver={() => onHover && onHover(true)}
            onMouseLeave={() => onHover && onHover(false)}
            data-testid={`explore-list-item-${poi.id}`}
        >
            {onSelect && (
                <div
                    data-testid={`explore-list-item-checkbox-${
                        showCheckbox ? 'visible' : 'hidden'
                    }`}
                    className={classNames(styles.checkbox, {
                        [styles.hoveredCheckbox]: showCheckbox,
                    })}
                >
                    {!isPrivacy && !isLockedPoi && !isRestricted && (
                        <Checkbox
                            name={''}
                            value={poi.id}
                            selected={isSelected}
                            onChange={onCheckboxChange}
                        />
                    )}
                </div>
            )}
            <SquareLogo
                logoUrl={logoUrl}
                name={poi.name}
                placeType={poiType}
                className={styles.logo}
                icon={icon}
            />
            <div className={styles.textContainer}>
                <div className={styles.titleContainer}>
                    <div className={styles.title}>
                        {isAddressType(poi) &&
                        (!!customPoiAddresses[poi.provider_data?.entity_id || poi.id] ||
                            poi.provider_data?.entity_id === ADDRESS_ENTITY ||
                            (poi.type !== EMPTY_SPACE_POI_TYPE &&
                                poi.provider_data?.entity_id !== COORDINATES_ENTITY))
                            ? ADDRESS_TITLE
                            : poi.name}{' '}
                        <ExploreListItemBadges poi={poi} />
                    </div>
                    {hasStoreIdBadgePermission && storeId && (
                        <StoreIdBadge storeId={storeId} dataTestId={`store-id-${poi.id}`} />
                    )}
                </div>
                <div className={styles.subtitle}>{formatFullAddress(poi.type, poi)}</div>
                {poi.is_new_poi && (
                    <div className={styles.newlyAddedLocation}>
                        <AlertIcon />
                        <div>
                            {poi.is_custom_poi
                                ? 'Custom POI - Recently created'
                                : 'Recently created POI'}
                        </div>
                    </div>
                )}
                {!poi.is_new_poi && poi.is_data_outdated && (
                    <div className={styles.newlyAddedLocation}>
                        <AlertIcon />
                        {
                            <div>
                                {poi.is_custom_poi
                                    ? 'Custom POI - Recently updated'
                                    : 'Recently updated POI'}
                            </div>
                        }
                    </div>
                )}
                {detailsComponent}
                {group && (
                    <div className={styles.categoryTags}>
                        {group === category ? group : `${group} • ${category}`}
                    </div>
                )}
            </div>
            {isHover && actionButton && !isPrivacy && !isRestricted && (
                <div className={styles.actionButton}>{actionButton}</div>
            )}
        </div>
    );
};
