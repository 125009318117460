/* eslint-disable max-len */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelectorWithProps } from 'utils/structured-selector/structured-selector';
import { AddNewReportModal } from 'features/void-analysis/sections/add-new-report/components/add-new-report-modal/add-new-report-modal';
import { AddNewReportButton } from 'features/void-analysis/sections/add-new-report/components/add-new-report-button/add-new-report-button';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/app-store-hooks';
import { getCategories } from 'store/configuration/categories/actions';
import { useVoidAnalysisSelectors } from 'features/void-analysis/store/selectors';
import { selectTopSearchRecentEntities } from 'store/header/search/selectors/select-top-search-recent-entities';
import { PlacePOI } from 'core/entities';
import { getEntitiesById } from 'API/common/get-entities-by-id/get-entities-by-id';
import {
    CreateJobAndRedirect,
    CreateJobConfig,
    IsOnlineReportSupportedType,
    useCreateJobUrlAndRedirect,
    useIsOnlineReportSupported,
    useUnsupportedEntityText,
} from 'features/void-analysis/sections/entry-point-redirection/hooks/entry-point-redirect-hooks';
import { useAddNewReportContext } from 'features/void-analysis/context/add-new-report-context';
import { searchPOI } from 'API/search';
import { useDebouncedCallback } from 'use-debounce';
import {
    NUMBER_OF_SEARCH_RESULTS,
    SEARCH_PROPERTY_DEBOUNCE_TIME,
} from 'features/void-analysis/constants';
import { useVaEnableLocksPermission } from 'features/void-analysis/common/hooks/hooks';
import {
    SHOPPING_CENTERS_CATEGORY,
    useSubCategoriesForShoppingCenters,
} from 'hooks/use-select-sub-categories-from-multiple-categories/use-select-sub-categories-from-multiple-categories';
import { VoidReportParams } from 'features/void-analysis/sections/empty-space/types/run-void-analysis-button-types';
import { useEmptySpacePermissions } from 'features/void-analysis/sections/empty-space/hooks/use-empty-space-permissions';
import {
    useCreateEmptySpaceVoidJobUrlAndRedirect,
    isOnlineReportEmptySpaceSupported,
    unsupportedEmptySpaceEntityText,
} from 'features/void-analysis/sections/empty-space/hooks/use-method-selector-modal-hooks';
import type {
    Chain,
    Place,
    PlaceCollection,
    PlaceType,
    PlacerEntityWrapper,
} from '@placer-ui/types';
import { getFeatureFlags } from 'core/flow-control';

type RecentEntitiesSetter = (value: PlacerEntityWrapper<Place>[]) => void;

export const supportedPlaceType: PlaceType = 'complex';

export const useHasAddNewReportPermission = () => {
    const { enable_void_analysis_create_report } = getFeatureFlags();
    return !!enable_void_analysis_create_report;
};

export const useGetAddNewReportButton = () => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const onModalClose = () => setShowModal(false);
    const hasAddNewReportPermission = useHasAddNewReportPermission();
    const enableLocksPermission = useVaEnableLocksPermission();

    const onClick = () => {
        setShowModal(true);
    };

    if (!enableLocksPermission && !hasAddNewReportPermission) return null;

    return (
        <div>
            <AddNewReportButton
                isLocked={!hasAddNewReportPermission && enableLocksPermission}
                onClick={onClick}
            />
            <AddNewReportModal onClose={onModalClose} visible={showModal} />
        </div>
    );
};

const normalizeEntities = (entities: Chain[] | null) => {
    return (
        entities?.map<PlacerEntityWrapper<Place>>((entity) => ({
            info: entity,
        })) || []
    );
};

export const useGetRecentSearchEntitiesEffect = (setData: RecentEntitiesSetter) => {
    const dataWasRequested = useRef<boolean>(false);
    const fetchCategoryOptions = useFetchCategoryOptions();
    const recentEntities: Place[] = useSelectorWithProps(
        { reportType: 'Property' },
        selectTopSearchRecentEntities,
    );
    const ids = useMemo(
        () =>
            recentEntities
                .filter((entity) => entity.type === supportedPlaceType)
                .map((entity) => ({
                    id: entity.id,
                    type: entity.type,
                })),
        [recentEntities],
    );

    const acceptedSubCategories = useSubCategoriesForShoppingCenters();

    useEffect(() => {
        fetchCategoryOptions();
    }, [fetchCategoryOptions]);

    useEffect(() => {
        if (acceptedSubCategories.length && !dataWasRequested.current) {
            dataWasRequested.current = true;
            const requestData = async () => {
                try {
                    const { data } = await getEntitiesById<Chain>(ids);
                    const filteredData = data.filter((chain) =>
                        acceptedSubCategories.includes(chain.sub_category),
                    );
                    setData(normalizeEntities(filteredData));
                } catch (error) {
                    console.log(error);
                }
            };
            requestData();
        }
    }, [acceptedSubCategories, ids, setData]);
};

export const useSelectCategoryOptions = () => {
    const { selectCategoriesOptions } = useVoidAnalysisSelectors();
    return useSelector(selectCategoriesOptions);
};

export const useFetchCategoryOptions = () => {
    const dispatch = useAppDispatch();
    const categoryOptions = useSelectCategoryOptions();
    return useCallback(() => {
        if (!categoryOptions.length) {
            dispatch(getCategories());
        }
    }, [categoryOptions.length, dispatch]);
};

export const useIsPoiSupportedForReport = () => {
    const IsOnlineReportSupported = useIsOnlineReportSupported();
    const getUnsupportedEntityText = useUnsupportedEntityText();
    const { hasEmptySpacePermission } = useEmptySpacePermissions();

    return useCallback(
        (poi: Place) => {
            const subCategory = poi.category_info?.sub_category ?? poi.sub_category;
            const isOnlineReportSupportedType: IsOnlineReportSupportedType = {
                isCustomPoi: poi.is_custom_poi,
                type: poi.type,
                subCategory: subCategory,
                stateCode: poi.address?.state_code,
                isPurchased: poi.purchased,
                category: poi.category,
                providerData: poi.provider_data,
                groupInfo: {
                    group: PlacePOI.getGroupCategory(poi),
                    subCategory: subCategory,
                },
            };
            const isOnlineReportSupported = hasEmptySpacePermission
                ? isOnlineReportEmptySpaceSupported(isOnlineReportSupportedType)
                : IsOnlineReportSupported(isOnlineReportSupportedType);
            const tooltipContent = hasEmptySpacePermission
                ? unsupportedEmptySpaceEntityText(isOnlineReportSupportedType)
                : getUnsupportedEntityText(isOnlineReportSupportedType);
            return {
                isOnlineReportSupported,
                tooltipContent,
            };
        },
        [getUnsupportedEntityText, IsOnlineReportSupported, hasEmptySpacePermission],
    );
};

export const useRunVoidReport = () => {
    const createJobLinkAndRedirect = useCreateJobUrlAndRedirect();
    const createEmptySpaceVoidJobUrlAndRedirect = useCreateEmptySpaceVoidJobUrlAndRedirect();
    const { hasEmptySpacePermission } = useEmptySpacePermissions();

    return useCallback(
        (poi: VoidReportParams, config: CreateJobConfig) => {
            config.verifyOrderIdExists = config.verifyOrderIdExists ?? true;
            hasEmptySpacePermission
                ? createEmptySpaceVoidJobUrlAndRedirect(poi, config)
                : createJobLinkAndRedirect({
                      poiId: poi.entity_id,
                      type: poi.entity_type,
                      config,
                  } as CreateJobAndRedirect);
        },
        [createJobLinkAndRedirect, createEmptySpaceVoidJobUrlAndRedirect, hasEmptySpacePermission],
    );
};

export const useOnSearchTermChanged = () => {
    const fetchResultsOnSearchChange = useFetchResultsOnSearchChange();
    const { updateSearchResults } = useAddNewReportContext();
    return useCallback(
        (value: string) => {
            fetchResultsOnSearchChange(value);
            if (!value) {
                updateSearchResults(undefined);
            }
        },
        [fetchResultsOnSearchChange, updateSearchResults],
    );
};

export const useFetchResultsOnSearchChange = () => {
    const { updateSearchResults, setIsRequestingResults, onPoiSelected } = useAddNewReportContext();
    const { hasEmptySpacePermission } = useEmptySpacePermissions();

    const enablePlaces = useMemo(() => {
        const places: PlaceCollection[] = ['venues', 'complexes'];

        if (hasEmptySpacePermission) {
            places.push('billboards');
        }
        return places;
    }, [hasEmptySpacePermission]);

    const onSearchTermChange = async (term: string) => {
        const searchAPI = searchPOI(enablePlaces);
        try {
            setIsRequestingResults(true);
            onPoiSelected(undefined);
            const { data } = await searchAPI({
                term,
                limit: NUMBER_OF_SEARCH_RESULTS,
                category: SHOPPING_CENTERS_CATEGORY,
            });
            updateSearchResults(data);
            setIsRequestingResults(false);
        } catch (error) {
            console.log(error);
            setIsRequestingResults(false);
        }
    };
    const debouncedOnSearchTermChanged = useDebouncedCallback(
        onSearchTermChange,
        SEARCH_PROPERTY_DEBOUNCE_TIME,
    );
    return useCallback(
        (value: string) => {
            if (value) {
                debouncedOnSearchTermChanged(value);
            } else {
                onPoiSelected(undefined);
                debouncedOnSearchTermChanged.cancel();
            }
        },
        [debouncedOnSearchTermChanged, onPoiSelected],
    );
};
