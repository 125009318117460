export const SORTING = {
    ASCENDING: 'ASC',
    DESCENDING: 'DESC',
} as const;

export const ANTD_SORTING = {
    ASCENDING: 'ascend',
    DESCENDING: 'descend',
} as const;

export const SORTING_MAPPED_TO_ANTD_SORTING = {
    [SORTING.ASCENDING]: ANTD_SORTING.ASCENDING,
    [SORTING.DESCENDING]: ANTD_SORTING.DESCENDING,
} as const;

export const ANTD_SORTING_MAPPED_TO_SORTING = {
    [ANTD_SORTING.ASCENDING]: SORTING.ASCENDING,
    [ANTD_SORTING.DESCENDING]: SORTING.DESCENDING,
} as const;

export type Sorting = typeof SORTING[keyof typeof SORTING];
export type AntdSorting = typeof ANTD_SORTING[keyof typeof ANTD_SORTING];
