export function sortStrings(str1: string | null, str2: string | null, isCaseSensitive = false) {
    if (str1 === null) {
        return 1;
    }

    if (str2 === null) {
        return -1;
    }

    const a = isCaseSensitive ? str1 : str1.toLowerCase();
    const b = isCaseSensitive ? str2 : str2.toLowerCase();

    return a.localeCompare(b);
}

export function compareCaseInsensitive(str1: string, str2: string) {
    return str1.trim().toLowerCase() === str2.trim().toLowerCase();
}

export function includesCaseInsensitive(value: string | number, searchTerm: string) {
    return value.toString().toLowerCase().includes(searchTerm.toLowerCase());
}
