import React, { useEffect, useRef, useCallback, PropsWithChildren } from 'react';
import { useAppDispatch } from 'store/app-store-hooks';
import generateID from 'utils/generate-id/generate-id';
import { setKeyValue } from 'store/app-ui/actions';
import { useSelectorWithProps } from 'utils/structured-selector/structured-selector';
import { selectKeyValue } from 'store/app-ui/selectors/application-selectors-factory';

const KEY = 'stopScroll';
const getBarWidth = () => window.innerWidth - document.body.offsetWidth;

interface ScrollTrapProps {
    stopScroll: boolean;
}

export const ScrollTrap = ({ stopScroll, children }: PropsWithChildren<ScrollTrapProps>) => {
    const currentTrapID = useSelectorWithProps(KEY, selectKeyValue);
    const dispatch = useAppDispatch();
    const trapIDRef = useRef(generateID('trap'));

    const setStopScroll = useCallback(
        (value: string | null) => {
            dispatch(
                setKeyValue({
                    key: KEY,
                    value,
                }),
            );
        },
        [dispatch],
    );

    const disableScroll = useCallback(() => {
        setStopScroll(trapIDRef.current);

        const rightOffset = `${getBarWidth()}px`;

        document.body.style.setProperty('position', 'relative');
        document.body.style.setProperty('width', `calc(100% - ${rightOffset})`);
        document.body.style.setProperty('overflow', 'hidden');
        document.body.style.setProperty('--right-offset', rightOffset);
    }, [setStopScroll]);

    const enableScroll = useCallback(() => {
        if (currentTrapID !== trapIDRef.current) return;

        setStopScroll(null);
        document.body.style.setProperty('position', '');
        document.body.style.setProperty('width', '');
        document.body.style.setProperty('overflow', '');
        document.body.style.setProperty('--right-offset', '');
    }, [setStopScroll, currentTrapID]);

    useEffect(() => {
        return () => {
            enableScroll();
        };
    }, [enableScroll]);

    useEffect(() => {
        if (stopScroll) {
            disableScroll();
        } else {
            enableScroll();
        }
    }, [stopScroll, disableScroll, enableScroll]);

    return <>{children}</>;
};
