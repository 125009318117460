import { getDeviceType } from 'utils/get-user-agent-data/utils';
import { isMobileApp } from 'utils/detect-environment';
import { getFeatureFlags } from 'core/flow-control';

export const useLockTabletOrientation = () => {
    const featureFlags = getFeatureFlags();

    if (getDeviceType() === 'desktop' || isMobileApp()) {
        return;
    }

    const shouldUnlockLandscapeMode =
        featureFlags.override_lock_tablet_landscape_mode && featureFlags.lock_tablet_landscape_mode;

    if (featureFlags.lock_tablet_landscape_mode) {
        document.body.classList.add('lockTabletToLandscapeMode');
    }

    if (shouldUnlockLandscapeMode) {
        document.body.classList.remove('lockTabletToLandscapeMode');
    }
};
