import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

import { IconProps } from 'components/assets/Icons/Icons';

/* eslint-disable max-len */
export const HotelIconSvg = (props: CustomIconComponentProps | SVGProps<SVGSVGElement>) => (
    <svg {...props} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
        <g fill="currentColor" fillRule="evenodd">
            <path d="M6.5 6.5v-2h3.2c.992 0 1.8.673 1.8 1.5v.5h-5z" stroke="currentColor" />
            <path d="M5 5.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0" />
            <path d="M0 9h12V6H0v3zm1-1h10V7H1v1z" />
            <rect x="11" y="7" width="1" height="4" rx=".5" />
            <rect y="1" width="1" height="10" rx=".5" />
        </g>
    </svg>
);

export const HotelIcon = (props: IconProps) => <Icon component={HotelIconSvg} {...props} />;
