import { fetchWrapper } from 'API/authentification/fetch-wrapper';
import { MethodData } from 'features/void-analysis/sections/empty-space/types/methods-selector-modal-types';
import type { PlacerResponseData } from '@placer-ui/types';
import {
    EntityOption,
    LocationOption,
} from 'features/void-analysis/sections/empty-space/types/run-void-analysis-button-types';
import queryString from 'query-string';
import { getAppGatewayEndpoint } from 'core/default-endpoint-url';
import { ReportsStatusResponse } from 'features/void-analysis/common/types/server-responses';

export const methodSelectorModalApi = {
    getMethodsInfo: (entityDetails: LocationOption): Promise<PlacerResponseData<MethodData>> => {
        const url = `/void-analysis/report-variants?${queryString.stringify(entityDetails)}`;

        return fetchWrapper({
            targetUrl: url,
        });
    },
    getReportOrderId: async (entityOption: EntityOption): Promise<ReportsStatusResponse> => {
        const { entity_type, entity_id } = entityOption;
        const queryParamsString = `${queryString.stringify({
            entity_type,
            entity_id,
        })}`;

        return fetchWrapper({
            endpoint: getAppGatewayEndpoint(),
            basePath: '/void-analysis',
            targetUrl: `/entity-report?${queryParamsString}`,
        });
    },
};
