import { inRange } from 'lodash';

const MIN_DIFF = 5;
const HIGH_MIN_DIFF = 10;
const LOW_MIDDLE_DIFF = 15;
const MIDDLE_DIFF = 25;
const MAX_DIFF = 50;

export const MIN_ITEMS_IN_GRAPH = 5;

const HHI_MIN_RANGE = [10, 50];
const HHI_HIGH_MIN_RANGE = [50, 60];
const HHI_LOW_MIDDLE_RANGE = [60, 75];
const HHI_MIDDLE_RANGE = [75, 150];
const HHI_MAX_RANGE = [150, 200];

export const LENGTH_OF_STAY_ABOVE_150_TEXT =
    'Median Stay is not supported for Length of Stay filter above 150 min';

/**
 * @param houseHolds is number array that represent the min val of each selected range for
 * demographics household income filter.
 * @return number[][] the original array divide by 1000
 * and transform to pairs of min - max range value each in the relevant range.
 * [0,1000,6000,75000] - > [[0],[10,15],[60,75],[75,100]]
 */

const houseHoldToPairs = (houseHolds: number[]): number[][] => {
    return houseHolds
        .map((item) => item / 1000)
        .reduce((rangeValues, currItem) => {
            if (currItem === 0 || currItem === 200) {
                rangeValues = [...rangeValues, [currItem]];
            } else if (inRange(currItem, ...HHI_MIN_RANGE)) {
                rangeValues = [...rangeValues, [currItem, currItem + MIN_DIFF]];
            } else if (inRange(currItem, ...HHI_HIGH_MIN_RANGE)) {
                rangeValues = [...rangeValues, [currItem, currItem + HIGH_MIN_DIFF]];
            } else if (inRange(currItem, ...HHI_LOW_MIDDLE_RANGE)) {
                rangeValues = [...rangeValues, [currItem, currItem + LOW_MIDDLE_DIFF]];
            } else if (inRange(currItem, ...HHI_MIDDLE_RANGE)) {
                rangeValues = [...rangeValues, [currItem, currItem + MIDDLE_DIFF]];
            } else if (inRange(currItem, ...HHI_MAX_RANGE)) {
                rangeValues = [...rangeValues, [currItem, currItem + MAX_DIFF]];
            }
            return rangeValues;
        }, [] as number[][]);
};

/**
 * @param rangeValues is the output of houseHoldToPairs - range pairs.
 * @return number[][] returns ranges without overlap boundaries
 * [[0],[10,15],[15,20],[20,25],[60,75]] -> [[0],[10,25],[60,75]]
 */

const houseHoldPairsToRange = (rangeValues: number[][]) => {
    let tempRangeValues: number[] = [];
    return rangeValues.reduce((ranges, currRange, i) => {
        const nextElement = rangeValues[i + 1];
        if (tempRangeValues.length > 0 && tempRangeValues[1] === currRange[1]) return ranges;
        if (currRange.length === 1) return [...ranges, currRange];
        else {
            if (tempRangeValues.length > 0 && tempRangeValues[1] !== currRange[0]) {
                ranges.push(tempRangeValues);
                tempRangeValues = currRange;
            }

            if (!tempRangeValues.length && nextElement && currRange[1] !== nextElement[0]) {
                ranges.push(currRange);
            } else if (!tempRangeValues.length && nextElement && currRange[1] === nextElement[0]) {
                tempRangeValues = [currRange[0], nextElement[1]];
            } else if (tempRangeValues.length > 0 && tempRangeValues[1] === currRange[0]) {
                tempRangeValues[1] = currRange[1];
            }

            if (!nextElement || nextElement.length === 1) {
                ranges.push(tempRangeValues);
            }
        }

        return ranges;
    }, [] as number[][]);
};

/**
 * @param houseHolds
 * @return string[] - [[0],[10,25],[60,75]] -> ['<10k','10K-25K','60K-75K']
 */

export const sumHouseHoldRange = (houseHolds: number[]): string[] => {
    const rangeValuesArr = houseHoldToPairs(houseHolds);

    return houseHoldPairsToRange(rangeValuesArr).map((rangeItem) => {
        if (rangeItem.length === 1) {
            return rangeItem[0] === 0 ? '<10k' : '>200k';
        } else {
            const [min, max] = rangeItem;
            return `$${min}K-$${max}K`;
        }
    });
};
