import { createSelector } from '@reduxjs/toolkit';

import { ReportTypes } from 'core/constants/report-type';
import type { PlaceCollection } from '@placer-ui/types';
import { getEntityTypesByReportType } from 'core/entities';
import { getRecentEntitiesByReportType } from 'core/services/recent-entities-service/recent-entities-service';
import { userCustomSettingsRecentPoiIdsSelector } from 'store/selectors/app-selectors';

export const selectTopSearchRecentEntities = createSelector(
    userCustomSettingsRecentPoiIdsSelector,
    (_: any, { reportType }: { reportType: ReportTypes | PlaceCollection[] }) => reportType,
    (recentEntities, reportType) => {
        if (Array.isArray(reportType)) {
            return reportType;
        }
        const entityTypes = getEntityTypesByReportType(reportType);

        return getRecentEntitiesByReportType(recentEntities, entityTypes);
    },
);
