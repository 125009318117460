import React from 'react';
import { Redirect } from 'react-router-dom';
import { NoInternetConnection } from 'shared/components/no-internet-connection/no-internet-connection';
import {
    SsoLandingPage,
    AccountActivationPage,
    AuthPages,
    ExpiredResetPassword,
    ResetPassword,
    ArcGISRedirectPage,
} from 'router/lazy-pages';
import { DashboardRoute } from 'router/types';

export const getPublicRoutes = (): DashboardRoute[] => [
    {
        path: '/account-activate',
        title: 'Account Activation',
        Component: AccountActivationPage,
        isFullPage: true,
    },
    {
        path: '/auth',
        title: 'Authentication',
        Component: AuthPages,
        isFullPage: true,
    },
    {
        // support legacy auth routes
        path: '/pages/:authpage(signin|signup)',
        title: 'Authentication',
        render({ match: { params } }) {
            return <Redirect to={`/auth/${params.authpage}`} />;
        },
    },
    {
        path: '/sso',
        Component: SsoLandingPage,
    },
    {
        path: '/ArcGIS',
        Component: ArcGISRedirectPage,
    },
    {
        path: '/reset-pass',
        title: 'Set a New Password',
        Component: ResetPassword,
        isFullPage: true,
    },
    {
        path: '/expired-reset-password',
        title: 'That’s An Expired Link',
        Component: ExpiredResetPassword,
        isFullPage: true,
    },
    {
        path: '/offline',
        Component: NoInternetConnection,
    },
];
