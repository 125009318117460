import { SgRestaurantIcon } from 'features/onboarding/solutions-guide/icons/sg-restaurant-icon';
import React from 'react';

export const RESTAURANTS_SOLUTIONS_GUIDE = {
    icon: <SgRestaurantIcon />,
    solutions: [
        {
            title: 'Site Selection & Expansion',
            description:
                'Easily identify ideal sites for a new restaurant ' +
                'locations based on customizable scoring parameters and filters.',
            url: '/advanced-reports/freemium/site-selection',
        },
        {
            title: 'Marketing & Advertising',
            description:
                'Better understand where visitors are coming from ' +
                'to optimize ad geotargeting to reach the highest potential areas.',
            url: '/insights/venues/678d071a426890642ad8d460/trade-area',
        },
        {
            title: 'Visitor Insights',
            description:
                'Uncover comprehensive demographic and psychographic ' +
                'insights about your diners at the property or chain level.',
            url: '/insights/venues/678d071a426890642ad8d460/audience',
        },
        {
            title: 'Competitive Intelligence',
            description:
                'Discover how sales, transactions, and ticket sizes are ' +
                'trending at countless chain or franchise locations.',
            url: '/insights/venues/678d071a426890642ad8d460/retail-sales',
        },
        {
            title: 'Operational Efficiency',
            description:
                'Analyze visit trends to improve demand planning and schedule staff or inventory accordingly.',
            url: '/insights/venues/678d071a426890642ad8d460/overview',
        },
        {
            title: 'Performance Monitoring',
            description:
                'Quantify, benchmark, explain, and showcase the performance ' +
                'of new and existing restaurants or franchises.',
            url: '/insights/venues/678d071a426890642ad8d460/overview',
        },
    ],
};
