import { createSelector } from '@reduxjs/toolkit';

import { AppState } from 'store/store';
import { MAX_STARRED_REPORT_COUNT } from 'store/star-report/constants';
import _ from 'lodash/fp';
import { ReportEntity } from 'core/entities/report/report-entity';
import { isDateLabel } from 'core/services/date-service';
import type { Attribute } from '@placer-ui/types';

export const selectStarReport = (state: AppState) => state.starReport;

export const selectStarReportIsReportListLoaded = createSelector(
    selectStarReport,
    (starReport) => starReport.reports !== undefined,
);

export const selectStarReportIsLoading = createSelector(
    selectStarReport,
    (starReport) => starReport.reports === undefined,
);

export const selectStarReportIsSaving = createSelector(
    selectStarReport,
    (starReport) => starReport.processingApiAction === 'saving',
);

export const selectStarReportIsUpdating = createSelector(
    selectStarReport,
    (starReport) => starReport.processingApiAction === 'updating',
);

export const selectStarReportIsRemoving = createSelector(
    selectStarReport,
    (starReport) => starReport.processingApiAction === 'removing',
);

export const selectStarReportChangesProcessing = createSelector(
    selectStarReportIsSaving,
    selectStarReportIsUpdating,
    selectStarReportIsRemoving,
    (isSaving, isUpdating, isRemoving) => isSaving || isUpdating || isRemoving,
);

export const selectStarReportReports = createSelector(
    selectStarReport,
    (starReport) => starReport.reports || [],
);

export const selectStarReportHasMaxReports = createSelector(
    selectStarReportReports,
    (reports) => reports.length >= MAX_STARRED_REPORT_COUNT,
);

export const selectAttributeCount = (entities: ReportEntity[]) => {
    const uniqueAttributesList = entities.reduce((accumulator, entity) => {
        entity.filters.attributes?.forEach((attribute) => {
            if (attribute !== 'all' && !accumulator.some((attr) => _.isEqual(attr, attribute))) {
                accumulator.push(attribute);
            }
        });

        return accumulator;
    }, [] as Attribute[]);

    return uniqueAttributesList.length;
};

export const selectReportDates = (entities: ReportEntity[]) => {
    const primaryEntity = _.head(entities);
    const startDate = primaryEntity?.filters?.start_date;
    const endDate = primaryEntity?.filters?.end_date;
    const isSameRelativeDate = entities.every(
        (entity) =>
            !!entity.filters.date_label &&
            isDateLabel(entity.filters.date_label) &&
            entity.filters.date_label === entities[0].filters.date_label,
    );
    const dateLabel = isSameRelativeDate ? primaryEntity?.filters?.date_label : undefined;

    const isSameDay = entities.every((entity) => {
        return entity.filters.start_date === startDate && entity.filters.end_date === endDate;
    });

    return {
        isSameDay,
        startDate,
        endDate,
        dateLabel,
    };
};
