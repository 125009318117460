import { ChainEntityModel } from 'core/entities';
import { EntityAccessLevel, EntityAccessLevelTypes } from 'core/constants';
import { EntitiesAccessLevelService } from 'core/services/entities-access-level-service';

class ChainsAccessLevelService extends EntitiesAccessLevelService<
    ChainEntityModel
> {
    getAccessLevelOfEntity(entity: ChainEntityModel): EntityAccessLevelTypes {
        const chain = entity.originalEntity;
        if (!chain) {
            return EntityAccessLevel.FULL;
        }
        if (chain.purchased) {
            return EntityAccessLevel.FULL;
        }
        if (chain.enable_limited_report) {
            return EntityAccessLevel.LIMITED;
        }
        return EntityAccessLevel.MISSING_PERMISSIONS;
    }
}

export const chainsAccessLevelService = new ChainsAccessLevelService();

export const getChainsBasedAccessLevel = (
    chainsEntityModels: ChainEntityModel[],
) => chainsAccessLevelService.getEntitiesLowestAccessLevel(chainsEntityModels);
