import React, { FC, SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';

import { RecentReportUIShape } from 'store/header/navigation/models';
import { telemetryApiService } from 'API/telemetry-api/telemetry-api';
import styles from './recent-report-link.module.scss';

export const RecentReportLink: FC<React.PropsWithChildren<RecentReportUIShape>> = ({
    children,
    link,
    basename,
    data,
}) => {
    const url = `${basename || ''}${link.pathname}${link.search}`;
    const onClickHandler = (e: SyntheticEvent) => {
        telemetryApiService.reportStartEvent('recentReportsLoadingTime');
    };

    const navComponentProps = {
        className: styles.link,
        onClick: onClickHandler,
    };

    return (
        <Link to={url} {...navComponentProps}>
            {children}
        </Link>
    );
};
