import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'store/store';
import { useSelector } from 'react-redux';

const exploresSelector = (state: AppState) => state.explore;

const filtersSelector = createSelector(
    exploresSelector,
    (explore) => explore || {},
);

export const useExploreConfiguration = () => useSelector(filtersSelector);
