import { useExploreUIState } from 'features/explore/context/use-explore-ui-state';
import { useCallback, useMemo } from 'react';
import { selectUserMaxCompetitorsInReport } from 'store/selectors/app-selectors';
import { useSelector } from 'react-redux';
import {
    useAddSelectedResult,
    useRemoveAllSelectedId,
    useRemoveSelectedId,
    useSetExploreMultiselectDrawerVisibility,
} from 'features/explore/context/use-explore-ui-state-actions';
import { useGetOverlayPOI } from 'features/explore/hooks/use-explore-overlay-poi';
import { ExploreSearchResult } from 'features/explore/types/types';
import { getSelectedPoiOverlay, isPoiRestrictedCoordinate } from 'features/explore/utils/utils';
import { PlaceOverlay } from 'ui-components/google-map-layers/types/google-map-layers-types';
import { getIsOverlaySelected } from 'ui-components/google-map-layers/utils/get-place-overlay';

export const useIsIdSelected = () => {
    const getOverlayPOI = useGetOverlayPOI();

    return useCallback(
        (selectedOverlay: PlaceOverlay) => {
            return getIsOverlaySelected(getOverlayPOI(selectedOverlay));
        },
        [getOverlayPOI],
    );
};

export type EnableSelectionCheckbox = {
    poi: PlaceOverlay;
    withHover?: boolean;
    activeOverlay?: PlaceOverlay;
};

export const useEnableSelectionCheckbox = () => {
    const { hoverId } = useExploreUIState();
    const { selectedResults, maxCompetitors } = useSelectedResultsStatus();
    const selectedId = useIsIdSelected();
    const getOverlay = useGetOverlayPOI();

    return useCallback(
        ({ withHover = true, poi, activeOverlay }: EnableSelectionCheckbox) => {
            const poiOverlays = getOverlay(poi);
            const selectedOverlay = getSelectedPoiOverlay(poiOverlays, activeOverlay?.overlayID);
            const { id: poiId } = poi;

            if (isPoiRestrictedCoordinate(poi)) {
                return false;
            }
            // if theres an activeOverlay - selection from drawer
            if (selectedOverlay) {
                return selectedOverlay.isSelected || selectedResults.length < maxCompetitors;
            }
            // if the selection coming from the sidebar / marker tooltip
            return (
                selectedId(poi) ||
                (selectedResults.length < maxCompetitors && (withHover ? poiId === hoverId : true))
            );
        },

        [hoverId, maxCompetitors, getOverlay, selectedId, selectedResults.length],
    );
};

export type ToggleSelectedId = {
    poi: PlaceOverlay;
    uncheckPoiOverlay?: boolean;
    poiSearchResult?: ExploreSearchResult;
};

export const useToggleSelectedId = () => {
    const addSelected = useAddSelectedResult();
    const removeSelectedId = useRemoveSelectedId();
    const removeAllSelectedOverlays = useRemoveAllSelectedId();
    const getOverlayPOI = useGetOverlayPOI();

    return useCallback(
        ({ poi, uncheckPoiOverlay, poiSearchResult }: ToggleSelectedId) => {
            const { id: poiId, overlayID } = poi;
            const poiSearchResultId = poiSearchResult?.info.id;
            const poiOverlays = getOverlayPOI(poiSearchResult?.info ?? poi);
            const overlayId = overlayID || poiOverlays?.[0].overlayID;

            const selectedOverlay =
                getSelectedPoiOverlay(poiOverlays, overlayId) || poiOverlays?.[0];

            if (!selectedOverlay) return;

            if (!selectedOverlay?.isSelected) {
                selectedOverlay && addSelected(selectedOverlay, poiSearchResultId ?? poiId);
            } else {
                // if user deselect from sidebar / marker tooltip
                if (uncheckPoiOverlay) {
                    selectedOverlay &&
                        removeAllSelectedOverlays(poiSearchResultId ?? poiId, selectedOverlay);
                } else {
                    selectedOverlay &&
                        removeSelectedId(poiSearchResultId ?? poiId, selectedOverlay);
                }
            }
        },
        [getOverlayPOI, addSelected, removeAllSelectedOverlays, removeSelectedId],
    );
};

export const useSelectedResultsStatus = () => {
    const { overlayPOIs } = useExploreUIState();
    const selectedOverlays = Object.entries(overlayPOIs).reduce<PlaceOverlay[]>(
        (acc, [parentId, poiOverlay]) => {
            if (poiOverlay) {
                const filteredOverlays = poiOverlay
                    .filter((overlay) => overlay?.isSelected)
                    .map((overlay) => ({
                        ...overlay,
                        parentId,
                    }));
                acc = [...acc, ...filteredOverlays];
            }
            return acc;
        },
        [],
    );
    const maxCompetitors = useSelector(selectUserMaxCompetitorsInReport);

    return useMemo(
        () => ({
            selectedResults: selectedOverlays,
            maxCompetitors,
        }),
        [maxCompetitors, selectedOverlays],
    );
};

export const useToggleMultiselectDrawer = () => {
    const setDrawerVisible = useSetExploreMultiselectDrawerVisibility();
    const { exploreMultiselectDrawerOpen } = useExploreUIState();
    return useCallback(
        () => setDrawerVisible(!exploreMultiselectDrawerOpen),
        [exploreMultiselectDrawerOpen, setDrawerVisible],
    );
};
