import React, { useState } from 'react';
import classNames from 'classnames';
import { Dropdown } from 'antd';
import { DropDownProps } from 'antd/lib/dropdown';

import { SelectArrowIcon } from 'components/assets/Icons/Icons';
import { ReportTypes } from 'core/constants/report-type';
import { EntityTypeDropdownOptionsOverlay } from './components';
import { useSelectorWithProps } from 'utils/structured-selector/structured-selector';
import { selectHasUserPermission } from 'store/selectors/app-selectors';

import styles from './entity-type-dropdown.module.scss';

const dropdownTrigger: DropDownProps['trigger'] = ['click'];

const useEntityTypeDropdown = () => {
    const [visible, setVisible] = useState(false);

    return {
        visible,
        setVisible,
    };
};

type EntityTypeDropdownProps = {
    selectedReportType: ReportTypes;
    selectEntityType: (entityType: ReportTypes) => void;
};

export const EntityTypeDropdown = ({
    selectedReportType,
    selectEntityType,
}: EntityTypeDropdownProps) => {
    const { visible, setVisible } = useEntityTypeDropdown();

    const arrowClassNames = classNames(styles.arrowIcon, {
        [styles.arrowIconUp]: visible,
    });

    const selectEntityTypeAndCloseOverlay = (entityType: ReportTypes) => {
        setVisible(false);
        selectEntityType(entityType);
    };

    const hasNewBillboardsTextPermission = useSelectorWithProps(
        { permission: 'enable_new_billboards_text_change' },
        selectHasUserPermission,
    );

    const selectedLabel = hasNewBillboardsTextPermission && selectedReportType === 'Billboard'
        ? 'Traffic Pin' : selectedReportType;

    return (
        <Dropdown
            trigger={dropdownTrigger}
            overlay={
                <EntityTypeDropdownOptionsOverlay
                    selectedEntityType={selectedReportType}
                    selectEntityType={selectEntityTypeAndCloseOverlay}
                />
            }
            onVisibleChange={setVisible}
            overlayClassName="filterDropdownOverlay"
            visible={visible}
        >
            <div className={styles.selectedEntityTypeButton}>
                {selectedLabel}
                <div className={arrowClassNames}>
                    <SelectArrowIcon />
                </div>
            </div>
        </Dropdown>
    );
};
