import React, { PropsWithChildren, ReactNode } from 'react';
import classnames from 'classnames';
import wastelandPlacerOwlSvg from 'shared/images/wasteland-placer-owl.svg';
import { Button } from 'ui-components/button/button';
import styles from './report-not-available-error.module.scss';

export type ReportNotAvailableErrorProps = PropsWithChildren<{
    title?: string;
    showPrimaryButton?: boolean;
    showSecondaryButton?: boolean;
    primaryText?: string;
    secondaryText?: string;
    primaryIcon?: ReactNode;
    className?: string;
    onPrimaryClick?: () => void;
    onSecondaryClick?: () => void;
    isLoading?: boolean;
}>;

const DEFAULT_TITLE = 'Report Is Not Available';

export const ReportNotAvailableError = ({
    title = DEFAULT_TITLE,
    primaryText,
    secondaryText,
    primaryIcon,
    showPrimaryButton,
    showSecondaryButton,
    className,
    onPrimaryClick,
    onSecondaryClick,
    isLoading,
    children,
}: ReportNotAvailableErrorProps) => {
    const handlePrimaryClick = () => {
        onPrimaryClick && onPrimaryClick();
    };

    const handleSecondaryClick = () => {
        onSecondaryClick && onSecondaryClick();
    };

    return (
        <div className={classnames(styles.reportNotAvailableErrorWrapper, className)}>
            <div className={styles.errorMessageWrapper}>
                <div className={styles.title}>{title}</div>
                <div className={styles.message}>{children}</div>
                <div>
                    {showSecondaryButton && (
                        <Button
                            size="large"
                            onClick={handleSecondaryClick}
                            className={styles.secondaryButton}
                            disabled={isLoading}
                        >
                            {secondaryText}
                        </Button>
                    )}
                    {showPrimaryButton && (
                        <Button
                            type={primaryIcon ? 'brand-primary' : 'brand-primary-full'}
                            className={styles.primaryButton}
                            size="large"
                            onClick={handlePrimaryClick}
                            loading={isLoading && { delay: 100 }}
                        >
                            {primaryIcon && <span className={styles.icon}>{primaryIcon}</span>}
                            {primaryText}
                        </Button>
                    )}
                </div>
            </div>
            <img
                className={styles.reportNotAvailableImage}
                src={wastelandPlacerOwlSvg}
                alt={title}
            />
        </div>
    );
};
