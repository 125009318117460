import React, { useCallback, useMemo } from 'react';

import { usePoiSelectionContext } from 'features/poi-selection-map/context/poi-selection-context';
import { useSplunkData } from 'features/poi-selection-map/hooks/use-poi-selection-tracking-hooks';

export const usePoiSelectionActionButton = () => {
    const {
        configuration: { poiSelectionActionButton },
    } = usePoiSelectionContext();
    return useMemo(() => poiSelectionActionButton, [poiSelectionActionButton]);
};

export const usePoiButtonWithId = () => {
    const poiSelectionActionButton = usePoiSelectionActionButton();
    const { poisListPerCurrentPage } = usePoiSelectionContext();
    const splunkData = useSplunkData();

    return useCallback(
        (id: string) => {
            const poi = poisListPerCurrentPage?.find((poi) => poi.info.id === id);
            return (
                <>
                    {poi && splunkData
                        ? poiSelectionActionButton({
                              entity: poi,
                              splunkData: {
                                  ...splunkData,
                                  origin: 'Side Panel Table',
                              },
                          })
                        : null}
                </>
            );
        },
        [poiSelectionActionButton, poisListPerCurrentPage, splunkData],
    );
};
