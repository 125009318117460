import '@placer-ui/components/style.css';
import 'src/main.scss';
import * as Sentry from '@sentry/react';
import React from 'react';
import { Router } from 'react-router-dom';
import { ReactQueryClient } from 'queries/query-client';
import { UiComponentsProvider } from '@placer-ui/components';
import { history } from 'router/history';
import { Dashboard } from './dashboard';
import { AppStoreProvider } from 'store/app-store-provider';
import { isProduction } from 'utils/detect-environment';

const AppRoot = () => {
    return (
        <ReactQueryClient>
            <UiComponentsProvider>
                <AppStoreProvider>
                    <Router history={history}>
                        <Dashboard />
                    </Router>
                </AppStoreProvider>
            </UiComponentsProvider>
        </ReactQueryClient>
    );
};

export const App = isProduction() ? AppRoot : Sentry.withProfiler(AppRoot);
