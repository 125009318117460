import type { Filter } from '@placer-ui/types';
import { FilterInsights } from 'core/entities/filter';
import { INSIGHTS_REPORT_PATH } from 'features/insights/consts/routes';
import { generatePath } from 'react-router-dom';
import { history } from 'router/history';

type GoToInsightsPageOptions = {
    id: string;
    collection: string;
    competitor?: string;
    filters?: Filter[] | FilterInsights[];
    section?: string;
    newTab?: boolean;
    useWindow?: boolean;
};

export const goToInsightsPage = ({
    id,
    collection,
    competitor,
    filters = [] as GoToInsightsPageOptions['filters'],
    section = 'overview',
    newTab = false,
    useWindow = false,
}: GoToInsightsPageOptions) => {
    const filtersForUrl = filters?.map((filter) => {
        const filterAsFilter = filter as Filter;
        const filterAsFilterInsights = filter as FilterInsights;
        const label = {
            ...(filterAsFilter?.date_label && {
                chosenLabel: filterAsFilter.date_label,
            }),
            ...(filterAsFilterInsights?.date?.chosenLabel && {
                chosenLabel: filterAsFilterInsights?.date?.chosenLabel,
            }),
        };

        const startDate = filterAsFilter?.date_start ?? filterAsFilterInsights?.date?.start;
        const endDate = filterAsFilter?.date_end ?? filterAsFilterInsights?.date?.end;
        return {
            date: {
                start: startDate,
                end: endDate,
                ...label,
            },
            attributes: filter?.attributes,
            config: filter?.config,
        };
    });

    const path = generatePath(INSIGHTS_REPORT_PATH, {
        id,
        collection,
        section,
    });
    const search = new URLSearchParams();
    if (competitor) {
        search.set('competitor', competitor);
    }
    if (filtersForUrl) {
        search.set('filter', JSON.stringify(filtersForUrl));
    }

    if (newTab) {
        window.open(`${path}?${search.toString()}`);
    } else {
        history.push({
            pathname: path,
            search: search.toString(),
        });
    }
};
