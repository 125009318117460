import React, { CSSProperties, SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

import { IconProps } from 'components/assets/Icons/Icons';

/* eslint-disable max-len */
const VenueIconSvg = (props: CustomIconComponentProps | SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
        <title>Venue icon</title>
        <g fill="none" fillRule="evenodd">
            <path fill="#FFF" d="M1.375 15.625h13.25V.375H1.375z" />
            <path fill="#49565D" d="M1 16h14V0H1v16zm.75-.75h12.5V.75H1.75v14.5z" />
            <path
                fill="#FFF"
                d="M4.375 15.625V9.5c0-.069.056-.125.125-.125h3c.069 0 .125.056.125.125v6.125h-3.25z"
            />
            <path
                fill="#49565D"
                d="M7.5 9h-3a.5.5 0 0 0-.5.5V16h4V9.5a.5.5 0 0 0-.5-.5zm-2.75 6.25h2.5v-5.5h-2.5v5.5z"
            />
            <path stroke="#49565D" strokeLinecap="round" strokeWidth=".75" d="M12.5 11.5h-3" />
            <path
                fill="currentColor"
                d="M3 6.625A2.629 2.629 0 0 1 .375 4V.375h5.25V4A2.629 2.629 0 0 1 3 6.625"
            />
            <path
                fill="#49565D"
                d="M6 0H0v4a3 3 0 1 0 6 0V0zm-.75.75V4c0 1.24-1.01 2.25-2.25 2.25S.75 5.24.75 4V.75h4.5z"
            />
            <path
                fill="currentColor"
                d="M8 6.625A2.629 2.629 0 0 1 5.375 4V.375h5.25V4A2.629 2.629 0 0 1 8 6.625"
            />
            <path
                fill="#49565D"
                d="M11 0H5v4a3 3 0 1 0 6 0V0zm-.75.75V4c0 1.24-1.01 2.25-2.25 2.25S5.75 5.24 5.75 4V.75h4.5z"
            />
            <path
                fill="currentColor"
                d="M13 6.625A2.629 2.629 0 0 1 10.375 4V.375h5.25V4A2.629 2.629 0 0 1 13 6.625"
            />
            <path
                fill="#49565D"
                d="M16 0h-6v4a3 3 0 1 0 6 0V0zm-.75.75V4c0 1.24-1.01 2.25-2.25 2.25S10.75 5.24 10.75 4V.75h4.5z"
            />
            <path stroke="#49565D" strokeLinecap="round" strokeWidth=".75" d="M12.5 9.5h-3" />
        </g>
    </svg>
);

// for backwards compatibility
const defaultStyle: CSSProperties = {
    color: '#676DFD',
    fontSize: 16,
};

type VenueIconProps = IconProps & {
    overrideInheritedStyle?: boolean;
};

export const VenueIcon = ({ overrideInheritedStyle = true, ...props }: VenueIconProps) => (
    <Icon component={VenueIconSvg} {...props} style={overrideInheritedStyle ? defaultStyle : {}} />
);
