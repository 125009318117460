import React, { useState } from 'react';
import styles from './search-section.module.scss';
import { PROPERTY, ReportTypes } from 'core/constants/report-type';
import { useHeaderActions } from 'store/header/use-header-actions';
import { EntityTypeDropdown } from './components/entity-type-dropdown/entity-type-dropdown';
import { SearchAutocomplete } from './components/search-autocomplete/search-autocomplete';
import { RecentEntitiesModelWithFreeTextOption } from 'core/services/recent-entities-service/recent-entities-model';
import { getReportEntityFlag } from 'core/services/report-entities-service/report-entities-service';
import { useAppDispatch } from 'store/app-store-hooks';
import { setKeyValue } from 'store/app-ui/actions';
import { SEARCH_OPTION_POI_STORE_KEY } from 'components/search-options-dialog/consts';
import { TagPOI } from 'core/entities';
import { useExplorePermissions } from 'features/explore/hooks/use-explore-permissions';
import { useOpenMultiplePropertyReport } from 'hooks/use-navigation-hooks/open-property-hooks';
import { useNearbyActivityRadiusDefault } from 'hooks/use-nearby-activity-radius/use-nearby-activity-radius';
import { PlaceWithFilters } from 'features/insights/store/poi/poi-types';
import { useAppUiActions } from 'store/app-ui/use-app-ui-actions';
import { UPGRADE_PLAN_SCREEN } from 'extensions/upgrade-your-plan-dialog';
import { useGoToExplorePage } from 'router/go-to-routes/go-to-explore';
import { useIsRestrictedPoi } from 'hooks/use-restricted-poi/use-restricted-poi';
import type { ExploreConfiguration } from 'features/explore/types/types';
import type { Place } from '@placer-ui/types';

const DEFAULT_REPORT_TYPE = PROPERTY;

const GO_TO_EXPLORE_COMMON_PARAMS: Pick<ExploreConfiguration, 'zoom' | 'term'> = {
    zoom: 15,
    term: '',
};

const useSearchSection = () => {
    const [selectedEntityType, setSelectedEntityType] = useState<ReportTypes>(DEFAULT_REPORT_TYPE);
    const { resetSearchState } = useHeaderActions();

    const onSelectEntityType = (reportType: ReportTypes) => {
        resetSearchState();
        setSelectedEntityType(reportType);
    };

    return {
        onSelectEntityType,
        selectedEntityType,
    };
};
export const SearchSection = () => {
    const { openReport } = useHeaderActions();
    const { selectedEntityType, onSelectEntityType } = useSearchSection();
    const dispatch = useAppDispatch();
    const { hasUnlockNearbyActivity, hasLogSelectedSearchResultPermission } =
        useExplorePermissions();
    const redirectToReport = useOpenMultiplePropertyReport();
    const filtersRadius = useNearbyActivityRadiusDefault();
    const { openUpgradePlanPopup } = useAppUiActions();
    const goToExploreNewPage = useGoToExplorePage();
    const isRestrictedPoiCallback = useIsRestrictedPoi();

    const onSelect = (entity: RecentEntitiesModelWithFreeTextOption) => {
        if (typeof entity === 'string' || TagPOI.isTag(entity as Place)) {
            return;
        }
        const reportEntityFlag = getReportEntityFlag(entity as Place);
        const isRestricted = isRestrictedPoiCallback(entity as Place);

        if (isRestricted) {
            goToExploreNewPage(
                {
                    center: (entity as Place).geolocation,
                    restrictions: {
                        enable_closed_pois: true,
                    },
                    ...GO_TO_EXPLORE_COMMON_PARAMS,
                },
                {
                    activeId: (entity as Place).id,
                },
            );
        } else if (reportEntityFlag) {
            const flagsToIgnore = ['flagged_indoor_mall', 'flagged_multistory_building'];
            const noUnlockNearbyActivity =
                !hasUnlockNearbyActivity &&
                reportEntityFlag &&
                !flagsToIgnore.includes(reportEntityFlag);

            // open upgrade plan popup if a user does not have the explore2 + nearby activity +
            // unlock nearby activity + the entity flag is not enclosing / multistory
            if (noUnlockNearbyActivity) {
                openUpgradePlanPopup(UPGRADE_PLAN_SCREEN.default);
            } else {
                const openAsNearbyActivity = !flagsToIgnore.includes(reportEntityFlag);
                if (openAsNearbyActivity) {
                    const entityPlace: PlaceWithFilters = {
                        ...(entity as Place),
                        filter: {
                            config: {
                                ft_radius: filtersRadius,
                            },
                        },
                    };
                    redirectToReport({
                        entities: [entityPlace],
                    });
                } else {
                    dispatch(
                        setKeyValue({
                            key: SEARCH_OPTION_POI_STORE_KEY,
                            value: entity,
                        }),
                    );
                }
            }
        } else {
            openReport(entity);
        }
    };

    return (
        <div className={styles.searchSectionWrapper}>
            <EntityTypeDropdown
                selectedReportType={selectedEntityType}
                selectEntityType={onSelectEntityType}
            />
            <SearchAutocomplete
                selectedEntityType={selectedEntityType}
                onSelection={onSelect}
                logSelectedResult={hasLogSelectedSearchResultPermission}
            />
        </div>
    );
};
