import { useMemo } from 'react';

import { TrackingContextType } from 'tracking/context';
import { usePoiSelectionContext } from 'features/poi-selection-map/context/poi-selection-context';
import { PoiSelectionSplunkData } from 'features/void-analysis/sections/entry-point-redirection/types/entry-point-tracking-types';

export const usePoiSelectionTrackingContent = () => {
    return useMemo<TrackingContextType>(
        () => ({
            state: 'poi-selection-map',
            viewName: 'poi-selection-map',
        }),
        [],
    );
};

export const useSplunkData = () => {
    const { mapConfig, currentItemConfiguration } = usePoiSelectionContext();
    return useMemo<PoiSelectionSplunkData | null>(() => {
        if (!mapConfig || !currentItemConfiguration) return null;
        const { neWithOffset: ne, swWithOffset: sw, zoom, center } = mapConfig;
        if (!center || zoom === undefined || !ne || !sw) return null;
        const { id: currentItemId } = currentItemConfiguration;
        return {
            component: currentItemId,
            report_config: {
                ne,
                sw,
                center,
                zoom,
            },
        };
    }, [currentItemConfiguration, mapConfig]);
};
