import type { Dictionary, Place, PlacerEntityWrapper } from '@placer-ui/types';
import { BillboardPOI } from 'core/entities';

export type MetricByPOITypeDictionary = {
    label: string;
    billboardLabel: string;
};

export const metricByTypeDictionary: Dictionary<MetricByPOITypeDictionary> = {
    estimated_foottraffic: {
        label: 'Visits',
        billboardLabel: 'Impressions',
    },
    FT: {
        label: 'Visits',
        billboardLabel: 'Impressions',
    },
    estimated_customer_count: {
        label: 'Visitors',
        billboardLabel: 'Viewers',
    },
    FT_PER_SQFT: {
        label: 'Visits / sq ft',
        billboardLabel: 'Visits / sq ft',
    },
};

export const getLabelByMetric = (poi: PlacerEntityWrapper<Place>) =>
    `Monthly ${
        BillboardPOI.isBillboard(poi.info)
            ? metricByTypeDictionary['estimated_foottraffic'].billboardLabel
            : metricByTypeDictionary['estimated_foottraffic'].label
    }`;
