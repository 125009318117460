import { MetricsWithRanksKeys } from 'types/properties-data';
import { getMetricsAndRankFromKey } from 'features/my-zone-properties/utils/metrics-utils';
import { MultiSelectOption } from 'ui-components/multi-select/types';
import { getSelectedSubOptionsKeys } from 'ui-components/dropdown-multi-select/utils/utils';
import { All_CATEGORIES } from 'features/insights/widgets/overview/tenants/constants';
import { safeLowerCase } from 'utils/start-case/start-case';
import type { ConfigurationCategoriesData } from '@placer-ui/types';
import { Key } from 'react';

export const getUpdatedMetricWithRankForCategory = (
    metricWithRank: MetricsWithRanksKeys,
    hasNewCategoriesFormat: boolean,
): MetricsWithRanksKeys => {
    const { rank } = getMetricsAndRankFromKey(metricWithRank);

    if (hasNewCategoriesFormat && rank?.startsWith('category')) {
        return metricWithRank.replace('category', 'primary') as MetricsWithRanksKeys;
    }
    if (!hasNewCategoriesFormat && rank?.startsWith('primary')) {
        return metricWithRank.replace('primary', 'category') as MetricsWithRanksKeys;
    }
    if (!hasNewCategoriesFormat && rank?.startsWith('group')) {
        return metricWithRank.replace('group', 'category') as MetricsWithRanksKeys;
    }

    return metricWithRank;
};

export const getUpdatedMetricWithRankListForCategory = (
    metricWithRankList: MetricsWithRanksKeys[],
    hasNewCategoriesFormat: boolean,
) => {
    return metricWithRankList.map((metricWithRank) =>
        getUpdatedMetricWithRankForCategory(metricWithRank, hasNewCategoriesFormat),
    );
};

export const getMultiSelectLabel = (
    options: MultiSelectOption[],
    allText: string = All_CATEGORIES,
    startGroupString: string = 'Categories',
) => {
    // All Selected options
    const selectedCategories = options.filter(({ selected }) => selected);

    // All the selected sub options keys
    const selectedCategoriesKeys = getSelectedSubOptionsKeys(options);

    // Only selected keys of the selected options
    const parentCategoriesKeys = getSelectedSubOptionsKeys(selectedCategories);

    // Check if only single Primary category selected
    const selectedPrimaries = options.reduce<MultiSelectOption[]>(
        (acc, { subOptions, selected }) => {
            const selectedPrimaryCategories =
                subOptions?.filter(({ selected, subOptions }) => {
                    const hasNoSubOptions = !!subOptions?.length;
                    return selected && hasNoSubOptions;
                }) ?? [];
            if (!selected && selectedPrimaryCategories.length === 1) {
                const primaryOption = selectedPrimaryCategories[0];
                acc.push(primaryOption);
            }
            return acc;
        },
        [],
    );

    const selectedCategoriesLength = selectedCategories.length;
    const selectedPrimariesLength = selectedPrimaries.length;
    const selectedCategoriesKeysLength = selectedCategoriesKeys.length;
    const selectedPrimariesKeysLength = selectedPrimaries.flatMap((op) => op.subOptions).length;

    if (selectedCategoriesLength === options.length) {
        return allText;
    }

    if (
        selectedPrimariesLength === 1 &&
        !selectedCategoriesLength &&
        selectedPrimariesKeysLength === selectedCategoriesKeysLength
    ) {
        return selectedPrimaries[0].name;
    }

    if (
        selectedCategoriesLength === 1 &&
        parentCategoriesKeys.length === selectedCategoriesKeysLength
    ) {
        return selectedCategories[0].name;
    }

    if (selectedCategoriesKeysLength > 1) {
        return `${startGroupString} (${selectedCategoriesKeysLength})`;
    }

    if (selectedCategoriesLength > 1) {
        return `${startGroupString} (${selectedCategories.length})`;
    }

    if (selectedCategoriesKeysLength === 1) {
        return selectedCategoriesKeys[0];
    }

    return allText;
};

export const getPrimaryCategoryLabel = (
    selectedKey: string,
    primary: ConfigurationCategoriesData[],
) => {
    // Only matches the selected single key with its primary category label
    return (
        primary.find(
            (primaryKey) => safeLowerCase(selectedKey) === safeLowerCase(primaryKey.label),
        ) ?? ''
    );
};

const getSubCategories = (node: MultiSelectOption, acc: MultiSelectOption[]) => {
    const { subOptions, selected } = node;
    if (selected) {
        acc.push(node);
    } else {
        subOptions?.forEach((subOpt) => {
            return getSubCategories(subOpt, acc);
        });
    }
    return acc;
};

export const getChipsLabels = (options: MultiSelectOption[]): MultiSelectOption[] => {
    return options.reduce<MultiSelectOption[]>((acc, grpOpt) => {
        acc = getSubCategories(grpOpt, acc);
        return acc;
    }, []);
};

export const getParentNodes = (nodes?: MultiSelectOption[], result: Key[] = []): Key[] => {
    nodes?.forEach((node, idx) => {
        if (!nodes[idx].subOptions?.length && nodes[idx].key) {
            result.push(nodes[idx].key as Key);
        } else {
            result = getParentNodes(nodes[idx].subOptions as MultiSelectOption[], result);
        }
    });

    return result;
};

export const getGroupSubCategories = (
    categoriesList: ConfigurationCategoriesData[],
    groupCategory?: string,
    primaryCategory?: string,
) => {
    if (groupCategory) {
        if (primaryCategory) {
            return (
                categoriesList
                    .find((group) => group.label === groupCategory)
                    ?.children!.find((primary) => primary.label === primaryCategory)
                    ?.children!.map((sub) => sub.label) || []
            );
        }
        return (
            categoriesList
                .find((group) => group.label === groupCategory)
                ?.children!.flatMap((primary) => primary.children!)
                .map((sub) => sub.label) || []
        );
    }
    return categoriesList
        .flatMap((group) => group.children!)
        .flatMap((primary) => primary.children!)
        .map((sub) => sub.label);
};

export const getGroupPrimaryCategories = (
    categoriesList: ConfigurationCategoriesData[],
    groupCategory?: string,
) => {
    if (groupCategory) {
        return (
            categoriesList
                .find((group) => group.label === groupCategory)
                ?.children!.map((primary) => primary.label) || []
        );
    }
    return categoriesList.flatMap((group) => group.children!).map((primary) => primary.label);
};
