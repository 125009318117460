import { RankingTableColumn } from 'features/void-analysis/common/types/ranking-table';
import { SectionType } from 'types/section';
import { BrandType } from 'features/void-analysis/common/types/chains';

export const TABLE_PAGE_SIZE = 20;
export const PRINT_TABLE_PAGE_SIZE = 100;
export const DEFAULT_VISIBLE_COLUMNS: RankingTableColumn[] = [
    'chain_name',
    'typical_size',
    'nearest_location',
    'number_of_venues',
    'expansion_rate',
    'total_match_score',
];

export const TABLE_MIN_COLUMNS = 3;
export const TABLE_MAX_COLUMNS = 6;

export const FILTER_CHANGE_DEBOUNCE_TIME = 1000;
export const SEARCH_PROPERTY_DEBOUNCE_TIME = 500;
export const SEARCH_CHAIN_DEBOUNCE_TIME = 1000;

export const STICKY_HEADER_POSITION_THRESHOLD = 288;

export const NUMBER_OF_SEARCH_RESULTS = 20;

export const DATE_FORMAT = 'MMM DD, YYYY';

export const VOID_ANALYSIS_DASHBOARD_SECTION: SectionType = 'Advanced Reports';

export const MEDIAN_DRIVE_TIME_COLUMN: RankingTableColumn = 'median_dvt';

export const ADD_NEW_REPORT_LABEL = 'Add New Report';

export const MISSING_WIDGET_DATA_ELEVIO_ID = '529';

export const CO_TENANCY_DISTANCE_DEFAULT_VALE = 0.25;

export const LOADING_REPORT_TEXT = 'Loading Your Void Analysis Report';

export const SMALL_CHAIN_VENUES_NUMBER = 10;

export const SMALL_CHAINS_BRAND_TYPES: BrandType[] = ['small_chain', 'property'];
