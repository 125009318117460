import * as Sentry from '@sentry/react';
import {
    EXTREMELY_SLOW,
    MS_TO_SEC,
    SENTRY_MAX_THRESHOLD,
    SENTRY_MEDIUM_THRESHOLD,
    SENTRY_MIN_THRESHOLD,
    SLOW,
    VERY_SLOW,
} from 'extensions/widget/constants/constants';
import { getCookies } from 'utils/cookies/utils';
import type { Dictionary } from '@placer-ui/types';

export const sendSentry = (msg: string, severity: Sentry.SeverityLevel) => {
    Sentry.captureEvent({
        message: msg,
        level: severity,
    });
};

type SentryWidgetLoading = {
    entitiesIds: string[];
    loadingTime: string;
    status?: string;
    msg: string;
    severity: Sentry.SeverityLevel;
    title?: string;
    cacheDates?: boolean;
    userName?: string;
    printId?: string;
    jobId?: string;
};

export const setSentryWidgetLoading = ({
    entitiesIds,
    loadingTime,
    status,
    msg,
    severity,
    title,
    cacheDates,
    userName,
    printId,
    jobId,
}: SentryWidgetLoading) => {
    Sentry.withScope((scope) => {
        scope.clear();
        scope.setTag('entities_ids', entitiesIds.toString());
        scope.setTag('loading_time', loadingTime);
        scope.setTag('widget_name', title);
        scope.setTag('cache_dates', cacheDates);
        scope.setTag('user_name', userName);
        if (printId) {
            scope.setTag('print_id', printId);
        }
        if (jobId) {
            scope.setTag('job_id', jobId);
        }
        if (status) {
            scope.setTag('status', status);
        }
        setCookieTags(scope);
        sendSentry(msg, severity);
    });
};

type SentryPageLoading = {
    loadingTime: string;
    status?: string;
    msg: string;
    severity: Sentry.SeverityLevel;
    pageMode?: string;
    title: string;
    userName?: string;
    printId?: string;
};

export const setSentryPageLoading = ({
    loadingTime,
    status,
    msg,
    severity,
    pageMode,
    title,
    userName,
    printId,
}: SentryPageLoading) => {
    Sentry.withScope((scope) => {
        scope.clear();
        scope.setTag('page_type', pageMode);
        scope.setTag('loading_time', loadingTime);
        scope.setTag('title', title);
        scope.setTag('user_name', userName);
        if (printId) {
            scope.setTag('print_id', printId);
        }
        if (status) {
            scope.setTag('status', status);
        }
        setCookieTags(scope);
        sendSentry(msg, severity);
    });
};

type SentryApiLoading = {
    entityId?: string;
    targetUrl: string;
    loadingTime: string;
    status?: string;
    msg: string;
    errorCode?: string;
    severity: Sentry.SeverityLevel;
    userName?: string;
};

export const setSentryApiLoading = ({
    entityId,
    loadingTime,
    status,
    msg,
    targetUrl,
    errorCode,
    severity,
    userName,
}: SentryApiLoading) => {
    Sentry.withScope((scope) => {
        scope.clear();
        scope.setTag('entity_id', entityId);
        scope.setTag('target_URL', targetUrl);
        scope.setTag('loading_time', loadingTime);
        scope.setTag('user_name', userName);
        if (status) {
            scope.setTag('status', status);
        }
        if (errorCode) {
            scope.setTag('error_code', errorCode);
        }
        setCookieTags(scope);
        sendSentry(msg, severity);
    });
};

export const checkForTimeResponse = (timeUntilApiResponse: number) => {
    if (
        timeUntilApiResponse > SENTRY_MIN_THRESHOLD &&
        timeUntilApiResponse <= SENTRY_MEDIUM_THRESHOLD
    ) {
        return SLOW;
    } else if (
        timeUntilApiResponse > SENTRY_MEDIUM_THRESHOLD &&
        timeUntilApiResponse <= SENTRY_MAX_THRESHOLD
    ) {
        return VERY_SLOW;
    } else if (timeUntilApiResponse > SENTRY_MAX_THRESHOLD) {
        return EXTREMELY_SLOW;
    }

    return undefined;
};

export const calculateTime = ({ end, start }: MonitorMapType) => {
    if (!start || !end) {
        return 0;
    }
    return (end - start) / MS_TO_SEC;
};

export const setCookieTags = (scope: Sentry.Scope) => {
    const cookies = getCookies();
    const suiteCookie = cookies.find((co) => co.includes('suite='));
    const testNameCookie = cookies.find((co) => co.includes('test_name='));

    if (suiteCookie) {
        scope.setTag('suite', suiteCookie.split('=')[1]);
    }
    if (testNameCookie) {
        scope.setTag('test_name', testNameCookie.split('=')[1]);
    }
};

export type MonitorMapType = { start: number; end: number; isInitial?: boolean };
export type MonitorMapping = Dictionary<MonitorMapType>;
