import { upperFirst, capitalize } from 'lodash';
import { ChainsVenue } from 'core/entities/chain/venue';
import type { Chain, Place } from '@placer-ui/types';

const missingAddressValue = 'N/A';

const splitAndCapitalize = (name: string = '') => {
    return name.split(' ').map(capitalize).join(' ');
};

const splitAndUpperCaseFirstLetterOnly = (name: string = '') => {
    return name.split(' ').map(upperFirst).join(' ');
};

export interface FormatShortAddressProps {
    type: string;
    place: Chain | Place | ChainsVenue;
    showAreaType?: boolean;
}

/**
 * Format Chain Address.
 * @param {Chain} chain - Chain type.
 * @returns {string} returns Chain address - i.e Nationwide
 * or CBSA, 100 - United States or United States (State)
 */
const formatChain = (chain: Chain, showAreaType = true): string => {
    if (!chain.area) {
        return missingAddressValue;
    }

    if (chain.area.type === 'nationwide') {
        return 'Nationwide';
    } else if (chain.area.type === 'cbsa' || chain.area.type === 'dma') {
        return `${chain.area.type.toLocaleUpperCase()}, ${
            chain.area.code
        } - ${splitAndUpperCaseFirstLetterOnly(chain.area.name)}`;
    } else if (!showAreaType) {
        return `${splitAndCapitalize(chain.area.name)}`;
    }

    return `${splitAndCapitalize(chain.area.name)} (${splitAndCapitalize(chain.area.type)})`;
};

/**
 * Format Full Address.
 * @param {string} type of poi  i.e. venue | chain | complex | billboard
 * @param {Place | Chain} place - Place or Chain or ChainVenue type.
 * @returns {string} returns full address i.e 373 Willowbrook, Coldwater, MC 49036
 */
export const formatFullAddress = (type: string, place: Chain | Place | ChainsVenue): string => {
    if (!place.address) {
        return missingAddressValue;
    }

    const { address, city, state_code, zip_code, formatted_address } = place.address;

    if (type === 'chain') {
        return formatChain(place as Chain);
    }
    // Other types venue | complex | billboard
    if (zip_code) {
        if (address) {
            return `${address}, ${city}, ${state_code} ${zip_code}`;
        } else if (formatted_address) {
            return formatted_address;
        } else return missingAddressValue;
    } else if (address && city && state_code) {
        return `${address}, ${city}, ${state_code}`;
    } else if (formatted_address) {
        return formatted_address;
    } else {
        return missingAddressValue;
    }
};

/**
 * Format Short Address.
 * @param {string} type of poi  i.e. venue | chain | complex | billboard
 * @param {Place | Chain} place - Place or Chain or ChainVenue type.
 * @returns {string} returns short address i.e Willowbrook, Coldwater, MC
 */
export const formatShortAddress = ({
    type,
    place,
    showAreaType = true,
}: FormatShortAddressProps): string => {
    if (type === 'chain') {
        return formatChain(place as Chain, showAreaType);
    }
    if (type === 'tag') {
        return 'Custom Chain';
    }

    if (!place.address) {
        return missingAddressValue;
    }

    const { street_name, city, state_code, short_formatted_address } = place?.address ?? {};

    // Other types venue | complex | billboard
    if (street_name && city && state_code) {
        return `${street_name}, ${city}, ${state_code}`;
    } else if (short_formatted_address) {
        return short_formatted_address;
    } else {
        return missingAddressValue;
    }
};
