import React, { PropsWithChildren } from 'react';
import { shouldRenderMobileApp } from 'utils/detect-environment';

export const Mobile = ({ children }: PropsWithChildren<{}>) => {
    const isMobile = shouldRenderMobileApp();
    return isMobile ? <>{children}</> : null;
};

export const Desktop = ({ children }: PropsWithChildren<{}>) => {
    const isMobile = shouldRenderMobileApp();
    return !isMobile ? <>{children}</> : null;
};
