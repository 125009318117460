import React, { ComponentProps, ReactNode } from 'react';
import { Tooltip as AntdTooltip, TooltipProps as AntdTooltipProps } from 'antd';
import { PropsWithChildren } from 'react';
import {
    EllipsisElementRefType,
    useContainsTextEllipsis,
} from 'hooks/use-contains-text-ellipsis/use-contains-text-ellipsis';
import classnames from 'classnames';
import styles from './tooltip.module.scss';
import { useWidgetPrintMode } from 'extensions/widget/hooks/use-widget-context-hooks';

export interface TooltipType {
    title?: string | ReactNode;
    placement?: ComponentProps<typeof AntdTooltip>['placement'];
    arrowPointAtCenter?: boolean;
    className?: string;
    autoAdjustOverflow?: boolean;
    useParentContainer?: boolean;
    forceShow?: boolean;
    ellipsisElementRef?: EllipsisElementRefType;
    showOnlyInCaseOfEllipsis?: boolean;
    small?: boolean;
    isMobile?: boolean;
}

export type TooltipProps = TooltipType & AntdTooltipProps;

export const Tooltip = ({
    title,
    children,
    className,
    placement,
    autoAdjustOverflow = true,
    useParentContainer = false,
    forceShow = false,
    ellipsisElementRef,
    showOnlyInCaseOfEllipsis = false,
    small = false,
    isMobile = false,
    ...props
}: PropsWithChildren<TooltipProps>) => {
    const tooltipClassnames = classnames(styles.tooltip, className, {
        [styles.small]: small,
        [styles.isMobile]: isMobile,
    });
    const isPrintMode = useWidgetPrintMode();
    const containsTextEllipsis = useContainsTextEllipsis(ellipsisElementRef);
    const showTitle = showOnlyInCaseOfEllipsis ? containsTextEllipsis : true;

    if (isPrintMode && !forceShow) return <> {children} </>;

    if (small && !placement) {
        placement = 'left';
    }

    return (
        <AntdTooltip
            getPopupContainer={
                useParentContainer
                    ? (triggerNode) => triggerNode.parentElement || document.body
                    : undefined
            }
            title={showTitle ? title : ''}
            placement={placement}
            overlayClassName={tooltipClassnames}
            autoAdjustOverflow={autoAdjustOverflow}
            {...props}
        >
            {children}
        </AntdTooltip>
    );
};
