import { setUser } from 'store/auth/auth';
import { userAPI } from 'API/user-api';
import type { BaseUserAuth } from '@placer-ui/types';
import { UserData } from 'core/entities';
import { getAuthError } from 'features/authentication/utils/get-auth-error';
import {
    PlatformSignInParameters,
    PlatformSigninSuccessResponse,
} from 'store/auth/actions/auth-actions/auth-action-types';
import { redirectToDashboard, saveSignedInUser } from 'store/auth/utils/utils';
import { AppDispatch } from 'store/store';
import { isMobileWeb, isMobile } from 'utils/detect-environment';
import { history } from 'router/history';
import {
    isNewGoogleUser,
    navigateToFullSignupForm,
    saveUserInLocalStorage,
} from 'store/auth/actions/auth-google-signin/utils';
import { sendGoogleAuthEvents } from 'features/authentication/utils/send-google-auth-events';
import { AuthValue } from 'features/authentication/types/types';

export const authPlatformSignin =
    (
        platformData: PlatformSignInParameters,
        next_url: string,
        hasGoogleAuthFullSignupPermission: boolean = false,
    ) =>
    async (dispatch: AppDispatch) => {
        const response: Response = await userAPI.platformSignIn(platformData);

        if (response.ok) {
            // success
            const data = await response.json();
            const successResponse = data as PlatformSigninSuccessResponse;
            const cookieUser: BaseUserAuth = {
                username: successResponse.username,
                email: successResponse.username,
                token: successResponse.token,
            };

            if (isMobileWeb()) {
                history.push('/auth/app-redirect', { user: cookieUser });

                return;
            }

            if (hasGoogleAuthFullSignupPermission) {
                const isNewUser = await isNewGoogleUser(successResponse.token);
                const userEmail = successResponse.username;
                if (isNewUser) {
                    sendGoogleAuthEvents({
                        userEmail,
                        authMode: AuthValue.SignUp,
                    });
                    navigateToFullSignupForm(successResponse.username, next_url);
                    saveUserInLocalStorage(cookieUser);
                    return;
                } else {
                    sendGoogleAuthEvents({
                        userEmail,
                        authMode: AuthValue.Login,
                    });
                }
            }

            saveSignedInUser(cookieUser);
            dispatch(setUser(cookieUser as UserData));

            if (!isMobile()) {
                redirectToDashboard(next_url);
            }
        } else {
            throw await getAuthError(response);
        }
    };
