/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';
import { IconProps } from 'components/assets/Icons/Icons';

export const DrugstoresAndPharmaciesIconSvg = (
    props: CustomIconComponentProps | SVGProps<SVGSVGElement>,
) => (
    <svg {...props} viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8 3V4H9C9.55229 4 10 4.44772 10 5V11C10 11.5523 9.55229 12 9 12H1C0.447715 12 0 11.5523 0 11V5C0 4.44772 0.447715 4 1 4H2V3H8ZM5.5 5.0025H4.5V7.005L2.465 7.00563V8.00563L4.5 8.005V10.0025H5.5V8.005L7.47126 8.00563V7.00563L5.5 7.005V5.0025Z"
            fill="currentColor"
        />
        <path d="M1 0H9V2H1V0Z" fill="currentColor" />
    </svg>
);

export const DrugstoresAndPharmaciesIcon = (props: IconProps) => (
    <Icon component={DrugstoresAndPharmaciesIconSvg} {...props} />
);
