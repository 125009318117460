import React, { MouseEvent, useState, useLayoutEffect, useContext, useRef } from 'react';
import moment, { Moment } from 'moment';
import { PickerPanel as DatePanelBody } from 'rc-picker/lib';
import RangeContext from 'rc-picker/lib/RangeContext';
import PanelContext from 'rc-picker/lib/PanelContext';
import generateConfig from 'rc-picker/lib/generate/moment';
import './range-panel.scss';
import { DateContext } from 'ui-components/date-picker/date-picker';
import { RangeValue } from 'ui-components/date-picker/date-types/RangeValue';
import { DatePanelHeader } from './date-panel-header/date-panel-header';
import { RangeData } from '../date-types/RangeData';
import locale from 'rc-picker/lib/locale/en_US';

const initialRangeState: RangeValue<null> = [null, null];
const LEFT_SIDE = 0;
const RIGHT_SIDE = 1;
const today = moment();

const calcInitViewValue = (
    leftView: Moment,
    rightView: Moment,
    maxDate: Moment = today,
): RangeValue<Moment> => {
    if (leftView.isSame(rightView)) {
        if (rightView.isSame(maxDate, 'month')) {
            return [generateConfig.addMonth(leftView, -1), rightView];
        } else {
            return [leftView, generateConfig.addMonth(rightView, 1)];
        }
    }

    return [leftView, rightView];
};

const calcViewValue = (
    leftView: Moment,
    rightView: Moment,
    position: 'left' | 'right',
    maxDate: Moment = today,
    minDate?: Moment,
): RangeValue<Moment> => {
    switch (position) {
        case 'left':
            if (rightView.isSameOrBefore(leftView)) {
                const newRightView = generateConfig.addMonth(leftView, 1);

                return calcViewValue(leftView, newRightView, 'right', maxDate, minDate);
            }
            return [leftView, rightView];

        case 'right':
            if (leftView.isSameOrAfter(rightView)) {
                const newLeftView = generateConfig.addMonth(rightView, -1);

                // Prevent the left view from being out of the range
                if (
                    minDate &&
                    newLeftView.isBefore(minDate.startOf('month')) &&
                    !minDate.isSame(maxDate, 'month')
                ) {
                    return [rightView, generateConfig.addMonth(rightView, 1)];
                }
                return [newLeftView, rightView];
            }

            if (rightView.isAfter(maxDate)) {
                const newRightView = maxDate.clone().startOf('month');
                return calcViewValue(leftView, newRightView, 'right', maxDate, minDate);
            }

            return [leftView, rightView];
    }
};

interface RangePanelProps {
    onSelect: (value: RangeData<Moment>) => void;
}

export const RangePanel = ({ onSelect }: RangePanelProps) => {
    const anchorRef = useRef(null);
    const { maxDate, minDate, startDate, endDate } = useContext(DateContext);

    const [selectedValue, setSelectedValue] = useState<RangeValue<Moment | null>>([null, null]);

    const [viewValue, setViewValue] = useState<RangeValue<Moment>>([today, today]);

    const [hoverRangedValue, setHoverRangedValue] =
        useState<RangeValue<Moment | null>>(initialRangeState);

    useLayoutEffect(() => {
        const startView = startDate?.clone().startOf('month');
        const endView = endDate?.clone().startOf('month');
        const rightView = endView || moment(maxDate).startOf('month');
        const leftView = startView || generateConfig.addMonth(rightView, -1);

        setViewValue(calcInitViewValue(leftView, rightView, maxDate));
        setSelectedValue([startDate || null, endDate || null]);
    }, [maxDate, startDate, endDate]);

    const onViewChange = (date: Moment, position: 'left' | 'right') => {
        switch (position) {
            case 'left':
                return setViewValue(
                    calcViewValue(date, viewValue[RIGHT_SIDE], position, maxDate, minDate),
                );

            case 'right':
                return setViewValue(
                    calcViewValue(viewValue[LEFT_SIDE], date, position, maxDate, minDate),
                );
        }
    };

    const onDateMouseEnter = (date: Moment) => {
        if (
            selectedValue[LEFT_SIDE] &&
            !selectedValue[RIGHT_SIDE] &&
            date.isAfter(selectedValue[LEFT_SIDE]!)
        ) {
            setHoverRangedValue([selectedValue[LEFT_SIDE], date]);
        } else {
            setHoverRangedValue(initialRangeState);
        }
    };

    const onDateMouseLeave = () => {
        setHoverRangedValue(initialRangeState);
    };

    const onPreventDefault = (event: MouseEvent) => {
        event.preventDefault();
    };

    const onSelectChange = (date: Moment) => {
        if (
            !selectedValue[LEFT_SIDE] ||
            selectedValue[RIGHT_SIDE] ||
            date.isBefore(selectedValue[LEFT_SIDE]!)
        ) {
            setSelectedValue([date, null]);
        } else {
            setSelectedValue([selectedValue[LEFT_SIDE], date]);
            onSelect({ value: [selectedValue[LEFT_SIDE]!, date] });
        }
    };

    const disabledDate = (date: Moment) => {
        if (minDate && maxDate) {
            return !date.isBetween(minDate, maxDate, 'dates', '[]');
        }

        if (minDate) {
            return date.isBefore(minDate);
        }

        if (maxDate) {
            return date.isAfter(maxDate);
        }

        return false;
    };

    const datePanel = (position: 'left' | 'right') => {
        const side = position === 'left' ? LEFT_SIDE : RIGHT_SIDE;

        return (
            <RangeContext.Provider
                value={{
                    inRange: true,
                    panelPosition: position,
                    rangedValue: selectedValue,
                    hoverRangedValue: hoverRangedValue,
                }}
            >
                <div className="datePickerRangePanelContainer" ref={anchorRef}>
                    <DatePanelHeader
                        pickerValue={viewValue[side]}
                        onChange={(date) => onViewChange(date, position)}
                        getPopupContainer={() => anchorRef.current!}
                    />
                    <DatePanelBody<Moment>
                        prefixCls={'ant-picker'}
                        mode="date"
                        generateConfig={generateConfig}
                        value={selectedValue[side]}
                        pickerValue={viewValue[side]}
                        disabledDate={disabledDate}
                        locale={locale}
                        direction="ltr"
                        tabIndex={-1}
                    />
                </div>
            </RangeContext.Provider>
        );
    };

    return (
        <PanelContext.Provider
            value={{
                hideHeader: true,
                onDateMouseEnter,
                onDateMouseLeave,
                onSelect: onSelectChange,
            }}
        >
            <div className="datePickerRangePanelsContainer" onMouseDown={onPreventDefault}>
                {datePanel('left')}
                {datePanel('right')}
            </div>
        </PanelContext.Provider>
    );
};
