/* eslint-disable max-len */
import React from 'react';
import { Modal } from 'antd';
import { AddNewReportSearchContent } from 'features/void-analysis/sections/add-new-report/components/add-new-report-search-content/add-new-report-search-content';
import styles from './add-new-report-modal.module.scss';
import { AddNewReportContext } from 'features/void-analysis/context/add-new-report-context';
import { ModalHeader } from 'ui-components/dialog/components/modal-header/modal-header';

type AddNewReportModalProps = {
    visible: boolean;
    onClose: () => void;
};

export const AddNewReportModal = ({ visible, onClose }: AddNewReportModalProps) => {
    return (
        <AddNewReportContext>
            <Modal
                className={styles.modal}
                centered
                destroyOnClose
                closable={false}
                footer={false}
                visible={visible}
                width={760}
            >
                <div>
                    <ModalHeader
                        title={<span className={styles.title}>Search for Properties</span>}
                        elevioDataId={'450'}
                        onClose={onClose}
                    />
                    <div className={styles.modalContent}>
                        <AddNewReportSearchContent />
                    </div>
                </div>
            </Modal>
        </AddNewReportContext>
    );
};
