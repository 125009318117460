import { AppState } from 'store/store';
import { createSelector } from '@reduxjs/toolkit';
import { ErrorInfo, VoidAnalysisState } from 'features/void-analysis/store/slice';
import type { Dictionary } from '@placer-ui/types';
import { FilterEntity } from 'features/void-analysis/common/types/filters';
import { RequestFilterEntity } from 'features/void-analysis/common/types/request-filters';
import {
    VoidAnalysisReportMetadata,
    VoidAnalysisReport,
    VoidEntity,
    VoidAnalysisPoi,
    SharedEntity,
} from 'features/void-analysis/common/types/entities';
import {
    MatchScoreCalculation,
    ScoreBounds,
} from 'features/void-analysis/common/types/server-responses';
import {
    normalizeReportMetadata,
    createFiltersDictionaryById,
    normalizeUserReport,
} from 'features/void-analysis/store/normalizers';
import { PoiEntity } from 'features/void-analysis/common/types/chains';
import {
    RankingsSortType,
    RankingTableColumn,
} from 'features/void-analysis/common/types/ranking-table';
import { VoidAnalysisUi } from 'features/void-analysis/common/types/ui';
import { poiSelector } from 'features/insights/store/selectors/insights-selectors';
import { PrintCustomization } from 'features/void-analysis/common/types/print-customization';
import { SharedReport } from 'core/entities/share-report/share-report';
import { selectCategories } from 'store/selectors/app-selectors';
import { ReportMethodData } from 'features/void-analysis/common/types/report-method-metadata';
import { MethodData } from 'features/void-analysis/sections/empty-space/types/methods-selector-modal-types';
import { ErrorSections } from 'features/void-analysis/store/slice';

export const selectVoidAnalysisState = (state: AppState) => state.voidAnalysis as VoidAnalysisState;

export const selectEntities = createSelector(
    selectVoidAnalysisState,
    (state: VoidAnalysisState): Dictionary<VoidEntity> => state.entities,
);

export const selectReportId = createSelector(
    selectVoidAnalysisState,
    (state: VoidAnalysisState): string => state.reportId,
);

export const selectShareEntity = createSelector(
    selectVoidAnalysisState,
    (state: VoidAnalysisState): SharedEntity | undefined => state.sharedEntity,
);

export const selectCreatedOrders = createSelector(
    selectVoidAnalysisState,
    (state: VoidAnalysisState) => state.createdOrders,
);

export const selectEntityData = createSelector(
    selectVoidAnalysisState,
    (state: VoidAnalysisState): VoidEntity | undefined => {
        if (state.ids.length > 1) {
            const chainId = state.ids[0];
            return state.entities[chainId];
        }
    },
);

export const selectMatavVersionId = createSelector(
    selectVoidAnalysisState,
    (state: VoidAnalysisState): string => {
        return state.matav_version_id;
    },
);

export const selectPOIData = createSelector(selectVoidAnalysisState, (state: VoidAnalysisState) => {
    if (state.ids.length) {
        const voidEntityId = state.ids[state.ids.length - 1];
        return state.entities[voidEntityId];
    }
});

export const useVoidAnalysisSelectors = () => {
    const selectIsLoadingContent = createSelector(
        selectVoidAnalysisState,
        (state: VoidAnalysisState): boolean => state.isLoadingContent,
    );

    const selectIsRequestingData = createSelector(
        selectVoidAnalysisState,
        (state: VoidAnalysisState): boolean => state.isRequestingData,
    );

    const selectIsChangingFilters = createSelector(
        selectVoidAnalysisState,
        (state: VoidAnalysisState): boolean => state.rankingPage.isChangingFilters,
    );

    const selectRecentReports = createSelector(
        selectVoidAnalysisState,
        (state: VoidAnalysisState): VoidAnalysisReport[] | null =>
            state.recentReports ? state.recentReports.map(normalizeUserReport) : null,
    );

    const selectSharedWithMeReports = createSelector(
        selectVoidAnalysisState,
        (state: VoidAnalysisState): SharedReport[] | null =>
            state.sharedWithMeReports ? state.sharedWithMeReports : null,
    );

    const selectErrors = createSelector(
        selectVoidAnalysisState,
        (state: VoidAnalysisState): { [key in ErrorSections]?: string } => state.errors,
    );

    const selectIsGeneralError = createSelector(
        selectVoidAnalysisState,
        (state: VoidAnalysisState): ErrorInfo => state.errorInfo,
    );

    const selectCurrentPage = createSelector(
        selectVoidAnalysisState,
        (state: VoidAnalysisState): number => state.rankingPage.table.currentPage,
    );

    const selectVisibleColumns = createSelector(
        selectVoidAnalysisState,
        (state: VoidAnalysisState): RankingTableColumn[] => state.rankingPage.table.visibleColumns,
    );

    const selectRankingTableSortBy = createSelector(
        selectVoidAnalysisState,
        (state: VoidAnalysisState): RankingsSortType | undefined => state.rankingPage.table.sortBy,
    );

    const selectEditTableColumnsSorted = createSelector(
        selectVoidAnalysisState,
        (state: VoidAnalysisState): RankingTableColumn[] =>
            state.rankingPage.table.editTableColumnsSorted,
    );

    const selectAllFilters = createSelector(
        selectVoidAnalysisState,
        (state: VoidAnalysisState): Dictionary<FilterEntity | undefined> =>
            createFiltersDictionaryById(state.rankingPage.filters),
    );

    const selectChangedFiltersList = createSelector(
        selectVoidAnalysisState,
        (state: VoidAnalysisState): string[] => state.rankingPage.changedFilters,
    );

    const selectRequestFilters = createSelector(
        selectVoidAnalysisState,
        (state: VoidAnalysisState): RequestFilterEntity[] => state.rankingPage.filtersRequest,
    );

    const selectScoreBounds = createSelector(
        selectVoidAnalysisState,
        (state: VoidAnalysisState): ScoreBounds | undefined => state.rankingPage.scoresBounds,
    );

    const selectMethodData = createSelector(
        selectVoidAnalysisState,
        (state: VoidAnalysisState): ReportMethodData => state.methodData.reportMethodData,
    );

    const selectMethodsInfo = createSelector(
        selectVoidAnalysisState,
        (state: VoidAnalysisState): MethodData => state.methodData.methodsInfo,
    );

    const selectIsAdvancedSettingsVisible = createSelector(
        selectVoidAnalysisState,
        (state: VoidAnalysisState): boolean => state.rankingPage.advancedSettingsVisible,
    );

    const selectReportMetadata = createSelector(
        selectPOIData,
        selectMethodData,
        (poi, methodData): VoidAnalysisReportMetadata | null => {
            if (poi) {
                return normalizeReportMetadata(poi, methodData);
            }

            return null;
        },
    );

    const selectAllRankingTableRecords = createSelector(
        selectVoidAnalysisState,
        (state: VoidAnalysisState) => ({
            currentRecords: state.rankingPage.table.records,
            totalRecords: state.rankingPage.table.totalRecords,
        }),
    );

    const selectCurrentPageTableRecords = createSelector(
        selectVoidAnalysisState,
        selectCurrentPage,
        (state: VoidAnalysisState, currentPage: number) => ({
            currentPageRecords: state.rankingPage.table.records[currentPage] as
                | PoiEntity[]
                | undefined,
            totalRecords: state.rankingPage.table.totalRecords,
        }),
    );

    const selectUiProperties = createSelector(
        selectVoidAnalysisState,
        (state: VoidAnalysisState): VoidAnalysisUi => state.ui,
    );

    const selectAdvancedSettings = createSelector(
        selectVoidAnalysisState,
        (state: VoidAnalysisState): FilterEntity[] => state.advancedSettings,
    );

    const allPoisSelector = createSelector(poiSelector, (pois): VoidAnalysisPoi[] => {
        //@ts-expect-error this error is due to overding server type(address) in class construction in venueAdmin.ts
        return Object.keys(pois).map((key) => {
            const { originalEntity } = pois[key];
            return {
                uid: originalEntity.uid,
                poiId: originalEntity.id,
                address: originalEntity.address,
                name: originalEntity.name,
                color: originalEntity.customData.color.regular,
                type: originalEntity.type,
                isCustomPoi: originalEntity.isCustom,
                isPurchased: originalEntity.isPurchased,
                stateCode: originalEntity.stateCode,
                category: originalEntity.category,
                subCategory: originalEntity.subCategory,
                originalShapeInfo: originalEntity.originalShape.info,
            };
        });
    });

    const selectCheckboxFilterClearSearch = createSelector(
        selectVoidAnalysisState,
        (state: VoidAnalysisState): boolean | undefined =>
            state.rankingPage.checkboxFilterClearSearch,
    );

    const selectMatchScoreCalculation = createSelector(
        selectVoidAnalysisState,
        (state: VoidAnalysisState): MatchScoreCalculation | undefined =>
            state.rankingPage.matchScoreCalculation,
    );

    const selectPrintCustomization = createSelector(
        selectVoidAnalysisState,
        (state: VoidAnalysisState): PrintCustomization => state.printCustomization,
    );

    const selectCategoriesOptions = createSelector(selectCategories, (categories) => {
        return categories.map((category) => ({
            id: category,
            name: category,
        }));
    });

    const selectShowClearAll = createSelector(
        selectVoidAnalysisState,
        (state: VoidAnalysisState): boolean | undefined => state.rankingPage.showClearAll,
    );

    const selectUseDefaultFilters = createSelector(
        selectVoidAnalysisState,
        (state: VoidAnalysisState): boolean | undefined => state.rankingPage.useDefaultFilters,
    );

    return {
        selectIsLoadingContent,
        selectIsRequestingData,
        selectIsChangingFilters,
        selectRecentReports,
        selectSharedWithMeReports,
        selectErrors,
        selectIsGeneralError,
        selectReportId,
        selectEntities,
        selectShareEntity,
        selectCreatedOrders,
        selectCurrentPage,
        selectVisibleColumns,
        selectRankingTableSortBy,
        selectEditTableColumnsSorted,
        selectAllFilters,
        selectChangedFiltersList,
        selectScoreBounds,
        selectRequestFilters,
        selectReportMetadata,
        selectAllRankingTableRecords,
        selectCurrentPageTableRecords,
        selectUiProperties,
        selectEntityData,
        selectAdvancedSettings,
        allPoisSelector,
        selectCheckboxFilterClearSearch,
        selectMatchScoreCalculation,
        selectPrintCustomization,
        selectCategoriesOptions,
        selectShowClearAll,
        selectUseDefaultFilters,
        selectMethodData,
        selectMethodsInfo,
        selectIsAdvancedSettingsVisible,
    };
};
