import { userAPI } from 'API/user-api';
import { SignupParameters } from 'store/auth/actions/auth-actions/auth-action-types';
import { checkIfUrlSafe } from 'store/auth/utils/utils';
import { getAuthError } from 'features/authentication/utils/get-auth-error';

export const authSignup = (userData: SignupParameters) => async () => {
    const next_safe_url =
        userData.next_url && checkIfUrlSafe(userData.next_url) ? userData.next_url : undefined;
    userData.next_url = next_safe_url;
    const response: Response = await userAPI.signup(userData);
    if (response.ok) {
        return response;
    } else {
        throw await getAuthError(response);
    }
};
