import React from 'react';
import styles from './search-results.module.scss';
import { OuterSearchInput } from 'ui-components/table/components/outer-search-input/outer-search-input';
import classNames from 'classnames';

type SearchResultsProps = {
    title: string;
    onSearchChange: (val: string) => void;
    displayType: 'standard' | 'large transparent';
    inputValue?: string;
};

export const SearchResults = ({
    title,
    onSearchChange,
    displayType,
    inputValue,
}: SearchResultsProps) => {
    return (
        <OuterSearchInput
            placeholder={title}
            onChange={onSearchChange}
            className={classNames(styles.search)}
            wrapperClassName={classNames(styles.wrapper, {
                [styles.largeTransparent]: displayType === 'large transparent',
            })}
            focusClassName={styles.focus}
            inputValue={inputValue}
        />
    );
};
