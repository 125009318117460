const isImageDownload = (data: Blob | Response): data is Blob => {
    return data.type.indexOf('image/') > -1;
};

export const downloadFile = async (
    data: Response | Blob,
    fileName: string,
    readyBlob: boolean = false,
) => {
    let fileBlob = data;

    if (!readyBlob) {
        if (!isImageDownload(data)) {
            fileBlob = await data.blob();
        }
    }

    const link = document.createElement('a');
    const url = URL.createObjectURL(fileBlob as Blob);

    link.download = fileName;
    link.rel = 'noopener';
    link.href = url;

    clickOnNode(link);

    setTimeout(() => {
        URL.revokeObjectURL(url);
    }, 100);
};

export const createDownloadFromObjectUrl = (url: string, fileName: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.style.visibility = 'hidden';
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

const clickOnNode = (node: HTMLAnchorElement) => {
    const event = new MouseEvent('click');
    node.dispatchEvent(event);
};
