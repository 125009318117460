import React, { Suspense } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/app-store-hooks';
import { Redirect } from 'react-router-dom';
import { userIsAnonymousSelector } from 'store/selectors/app-selectors';
import { getPublicRoutes } from 'router/routes';
import { renderRoute } from 'router/render-route';
import { ErrorRoutes } from 'router/errors/error-routes';
import { useNavigationAnalyticsEvents } from 'router/hooks/use-navigation-analytics-events';
import { PlSwitch, PathRewrite } from 'shared/router';
import { useIsFullPagePath } from 'router/hooks/use-is-full-page-path';
import { RedirectAuth } from 'features/authentication/components/redirect-anonymous/redirect-auth';
import { ASSIST_SIDEBAR_ID } from 'features/common/components/placer-assist/utils/dom-interactions';
import { isAssistBarOpenSignal } from 'ui-components/content-sidebar/assist-sidebar';
import { isMobileApp, shouldRenderMobileApp } from 'utils/detect-environment';
import { useCheckConnection } from 'features/mobile-report/hooks/use-check-connection';
import { getLoginCookie } from 'utils/cookies/utils';
import { SolutionsGuide } from 'features/onboarding/solutions-guide/solutions-guide';
import { getIsShowSolutionsGuide } from 'features/onboarding/solutions-guide/utils/utils';
import { useAuthProtectedRoutes } from 'router/routes/auth-protected-routes';
import { useIsFreemiumUser } from 'hooks/use-freemium-user/use-freemium-user';
import { useInitialStateQuery } from 'store/use-initial-state-query';

type DashboardRouterProps = {
    AppLoader: NonNullable<React.ReactNode>;
    RouteLoader: NonNullable<React.ReactNode>;
    Header?: NonNullable<React.ReactNode>;
    Initializers?: NonNullable<React.ReactNode>;
};

export const DashboardRouter = ({
    AppLoader,
    RouteLoader,
    Initializers,
    Header,
}: DashboardRouterProps) => {
    const publicRoutes = getPublicRoutes();
    const dispatch = useAppDispatch();
    const isFullPagePath = useIsFullPagePath();
    const isAnonymous = useSelector(userIsAnonymousSelector);
    const { isLoading, isError } = useInitialStateQuery();
    const isMobile = shouldRenderMobileApp();
    const { isOnline } = useCheckConnection();
    const isFreemiumUser = useIsFreemiumUser();
    const isShowSolutionsGuide = getIsShowSolutionsGuide(isFreemiumUser);
    useNavigationAnalyticsEvents();
    const authProtectedRoutes = useAuthProtectedRoutes();

    const showSolutionsGuide = isShowSolutionsGuide && !isFullPagePath && !isMobile;

    const containerClassNames = classNames('pl-app-container', {
        noBackground: isMobile,
    });
    const pageClassNames = classNames('pl-page', {
        'full-page': isMobile || isFullPagePath,
        'assist-is-open': !isMobile && isAssistBarOpenSignal.value,
    });

    if ((isAnonymous === false || !!getLoginCookie()) && isLoading) {
        return <>{AppLoader}</>;
    }

    return (
        <>
            {Initializers}
            {!isError && isAnonymous === false && Header}
            {showSolutionsGuide && <SolutionsGuide />}
            <div className={containerClassNames}>
                <Suspense fallback={RouteLoader}>
                    <div className={pageClassNames}>
                        <PathRewrite>
                            <PlSwitch>
                                {publicRoutes.map((route) => {
                                    return renderRoute(route, dispatch);
                                })}
                                {(isAnonymous !== false || !getLoginCookie()) && <RedirectAuth />}
                                {!isMobileApp() && !isOnline && <Redirect to="/offline" />}
                                {authProtectedRoutes}
                                <ErrorRoutes failBackUrl="/" />
                            </PlSwitch>
                        </PathRewrite>
                    </div>
                </Suspense>
                {/* Loaded with data using react portal */}
                {!isMobile && <div id={ASSIST_SIDEBAR_ID} />}
            </div>
        </>
    );
};
