import React, { createContext, Dispatch, PropsWithChildren, useReducer } from 'react';
import {
    ExploreActionTypes,
    initialState,
    reducer,
} from 'features/explore/context/explore-context-reducer';

export const ExploreContext = createContext(initialState);
export const ExploreActionContext = createContext<Dispatch<ExploreActionTypes>>(() => {});

export const ExploreUIStateContextProvider = ({ children }: PropsWithChildren<{}>) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <ExploreContext.Provider value={state}>
            <ExploreActionContext.Provider value={dispatch}>
                {children}
            </ExploreActionContext.Provider>
        </ExploreContext.Provider>
    );
};
