import { createSelector } from '@reduxjs/toolkit';

import { ChainEntityModel } from 'core/entities';
import { selectChainsEntityModelsSelectedForReportInSearch } from '../chains-report-search-selectors-factory';

export const selectSearchTooltipChains = createSelector(
    selectChainsEntityModelsSelectedForReportInSearch,
    (chains) => {
        const uniqueIds = [
            ...new Set(chains.map((item) => item.originalEntity.id)),
        ];

        const uniqueChains = uniqueIds.map((id) => {
            return chains.find((chain) => chain.originalEntity.id === id);
        }) as ChainEntityModel[];

        return uniqueChains;
    },
);
