import { useEffect, useRef } from 'react';

import type { ResolutionBreakpoints } from 'types/device-and-system';
import { getDeviceType } from 'utils/get-user-agent-data/utils';
import { appUiStore } from 'store/app-ui';
import {
    screenExtraSmallMax,
    screenSmallMax,
    screenMediumMax,
    screenLargeMax,
} from 'shared/constants/resolution-breakpoints';

function getBreakpoint(width: number): ResolutionBreakpoints {
    if (width <= screenExtraSmallMax) return 'extra-small';
    else if (width <= screenSmallMax) return 'small';
    else if (width <= screenMediumMax) return 'medium';
    else if (width <= screenLargeMax) return 'large';
    return 'extra-large';
}

/**
 * set global JS and CSS for handling resolution breakpoints
 */
export const useSetResponsiveness = () => {
    const appUi = appUiStore.useGet();
    const resolutionClass = useRef('');

    useEffect(() => {
        function onWindowResize() {
            const breakpoint = getBreakpoint(window.innerWidth);
            const body = document.body as HTMLBodyElement;

            if (resolutionClass.current) {
                body.classList.remove(resolutionClass.current);
            }
            resolutionClass.current = `resolution-${breakpoint}`;
            body.classList.add(resolutionClass.current);
            appUi.resolutionBreakpoint = breakpoint;
        }

        onWindowResize();
        window.addEventListener('resize', onWindowResize);

        return function cleanup() {
            window.removeEventListener('resize', onWindowResize);
        };
    }, [appUi]);

    useEffect(() => {
        const body = document.body as HTMLBodyElement;
        body.classList.add(`device-${getDeviceType()}`);
    }, []);
};
