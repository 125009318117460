import { useCallback, useContext, useMemo } from 'react';

import { isDevelopment } from 'core/constants/environment';
import { ErrorsContext } from './errors-manager-context';
import type { NativeError, PlacerErrorParams, ThrowPlacerErrorArgs } from './errors-manager-types';
import { PlacerError } from './placer-error';
import { filterErrorsFromList, isNativeError } from './errors-manager-helpers';
import { reportException } from 'core/exceptions';

type SearchOptions = Partial<PlacerErrorParams>;

/** find errors by type, section etc. */
export function useFindErrors(
    searchOptions: SearchOptions,
    ...moreSearches: SearchOptions[]
): PlacerError[] {
    const { errorsList } = useContext(ErrorsContext);

    return useMemo(() => {
        const allSearches = [searchOptions, ...moreSearches];
        const matchingErrors = filterErrorsFromList(errorsList, allSearches);
        return matchingErrors;
    }, [errorsList, searchOptions, moreSearches]);
}

/** add a new error to the context's errors pool */
export function useThrowError() {
    const {
        setErrorsList,
        page: defaultPage,
        tab: defaultTab,
        section: defaultSection,
        subSection: defaultSubSection,
    } = useContext(ErrorsContext);

    const throwError = useCallback(
        ({
            error,
            type,
            page,
            tab,
            section,
            subSection,
            logError = true,
        }: ThrowPlacerErrorArgs) => {
            // for when throwing an error that is not an object.
            // e.g. rejecting a promise with a string message and throwing the string as is.
            if (typeof error === 'string') {
                error = new Error(error);
            }

            if (isNativeError(error)) {
                const nativeError = error as NativeError; // just for type conversion
                const placerError = new PlacerError(nativeError.message, {
                    stack: nativeError.stack, // will show original error's name and trace
                    cause: nativeError.cause as NativeError['cause'],
                    type,
                    page: page || defaultPage,
                    tab: tab || defaultTab,
                    section: section || defaultSection,
                    subSection: subSection || defaultSubSection,
                });

                if (logError) {
                    reportException(placerError, {
                        payload: {
                            stack: nativeError.stack, // will show original error's name and trace
                            cause: nativeError.cause as NativeError['cause'],
                            type,
                            page: page || defaultPage,
                            tab: tab || defaultTab,
                            section: section || defaultSection,
                            subSection: subSection || defaultSubSection,
                        },
                    });

                    if (isDevelopment) {
                        const printableMetadata = `${nativeError.name}: ${placerError.message}
type: ${placerError.type}
subSection: ${placerError.subSection}
section: ${placerError.section}
tab: ${placerError.tab}
page: ${placerError.page} `;
                        console.log(
                            `%c ${printableMetadata}`,
                            'background-color: #fff0f0; color: #ff0000;',
                        );
                        console.error(placerError);
                    }
                }

                // push a new error to the accumulated errors list
                setErrorsList((prevErrorsList) => prevErrorsList.concat(placerError));
            } else {
                console.error(
                    'Can not throw error. the received argument is not a native error instance.',
                );
            }
        },
        [setErrorsList, defaultPage, defaultTab, defaultSection, defaultSubSection],
    );

    return throwError;
}

/** remove errors from the pool, meaning they have been handled */
export function useRemoveErrors() {
    const { setErrorsList } = useContext(ErrorsContext);

    const removeErrors = useCallback(
        (searchOptions: SearchOptions, ...moreSearches: SearchOptions[]) => {
            const allSearches = [searchOptions, ...moreSearches];
            setErrorsList((prevErrorsList) => {
                const unmatchingErrors = filterErrorsFromList(prevErrorsList, allSearches, true);
                return unmatchingErrors;
            });
        },
        [setErrorsList],
    );

    return removeErrors;
}
