import cloneDeep from 'lodash/cloneDeep';
import { getFeatureFlags } from 'core/flow-control';
import { getFremiumMonth } from 'store/auth/utils/utils';
import { APP_CONSTS } from 'core/constants/app-consts';
import {
    LENGTH_OF_STAY_ATTRIBUTE,
    LESS_THEN_FILTER_OPERATOR,
} from 'core/constants/filter-attributes';
import {
    FILTERS_BY_DURATION,
    LONG_VISITS,
    VISIT_LENGTH_BASELINE,
} from 'shared/constants/visit-duration';
import type { BackendAttributeFilter, PointOfInterestEntities } from '@placer-ui/types';
import type { ReportOptionsResponse } from 'API/report-options-api/report-options-api';
import type { UserConfiguration } from 'core/entities/user/user-configuration';
import type { DefaultPoiDates } from 'features/insights/types/dates-types';
import type { SettingsState } from 'features/settings/store/slice';
import type { FetchReportParams } from 'features/insights/store/actions/fetch-report/types';
import { shouldRenderMobileApp } from 'utils/detect-environment';

interface UpdateEntitiesFiltersArgs {
    entitiesFromURL: FetchReportParams['entitiesFromURL'];
    entitiesData: PointOfInterestEntities[];
    isFreemiumUser: boolean;
    userConfiguration: UserConfiguration | undefined;
    userSettings: SettingsState;
    defaultDates: DefaultPoiDates | undefined;
    reportOptions: ReportOptionsResponse;
}

export const updateEntitiesFilters = ({
    entitiesFromURL,
    entitiesData,
    isFreemiumUser,
    userConfiguration,
    userSettings,
    defaultDates,
    reportOptions,
}: UpdateEntitiesFiltersArgs) => {
    let isFilterUpdated = false;
    const { enable_legacy_builders_for_regions_ff } = getFeatureFlags();
    const freemiumMonth = getFremiumMonth(userConfiguration, defaultDates);
    const {
        time_range: { from_date: defaultStartDate, to_date: defaultEndDate },
        entities_data,
    } = reportOptions;

    let isFreemiumReport = false;

    if (isFreemiumUser || entitiesData.some(({ purchased }) => !purchased)) isFreemiumReport = true;

    for (const [index, entity] of entitiesFromURL.entries()) {
        const entityData = entitiesData[index];
        // entities_data comes from the report-options API and not necessarily in the same order
        const reportEntityData = entities_data.find(({ entity_id }) => entity_id === entity.id);

        if (isFreemiumReport && freemiumMonth) {
            entity.filter.date = freemiumMonth;
            isFilterUpdated = true;
        } else {
            if (!entity.filter.date) {
                isFilterUpdated = true;
                let dateKey = (userSettings.tabs?.platformConfiguration
                    ?.platform_config_report_date_range ?? '') as string;
                if (['last_12_months', 'last_6_months', 'last_3_months'].includes(dateKey)) {
                    dateKey = dateKey.replace('last', 'last_full');
                }
                if (
                    dateKey === APP_CONSTS.date.keys.LAST_7_DAYS ||
                    dateKey === APP_CONSTS.date.keys.PAST_7_DAYS
                ) {
                    const relativeDateItem = Object.values(defaultDates?.dates || {}).find(
                        (date) => date.key === APP_CONSTS.date.keys.PAST_7_DAYS,
                    );

                    entity.filter.date = {
                        start: relativeDateItem?.start || defaultStartDate,
                        end: relativeDateItem?.end || defaultEndDate,
                        chosenLabel:
                            relativeDateItem?.chosenLabel &&
                            `Relative|${relativeDateItem?.chosenLabel}`,
                    };
                } else {
                    const chosenLabel = Object.values(defaultDates?.dates || {}).find(
                        (date) =>
                            date.start === defaultStartDate &&
                            date.end === defaultEndDate &&
                            date.key === dateKey,
                    )?.key;

                    entity.filter.date = {
                        start: defaultStartDate,
                        end: defaultEndDate,
                        chosenLabel: chosenLabel && `Relative|${chosenLabel}`,
                    };
                }
            } else if (
                entity.filter.date &&
                entity.filter.date.chosenLabel &&
                entity.filter.date.chosenLabel !== 'Custom Range' &&
                defaultDates
            ) {
                let dateLabelFormat = entity.filter.date.chosenLabel || '';
                if (
                    ['Last 12 Months', 'Last 6 Months', 'Last 3 Months'].includes(dateLabelFormat)
                ) {
                    dateLabelFormat = dateLabelFormat.replace('Last', 'Last full');
                }
                const dateFilterByLabel = shouldRenderMobileApp()
                    ? Object.values(defaultDates.dates).find(
                          (poiDate) => (poiDate.key || '') === dateLabelFormat.split('|')[1],
                      )
                    : Object.values(defaultDates.dates).find(
                          (poiDate) =>
                              (poiDate.chosenLabel || '').toLowerCase() ===
                              dateLabelFormat.toString().toLowerCase(),
                      );

                const dateFilter =
                    dateFilterByLabel ||
                    Object.values(defaultDates.dates).find(
                        (poiDate) =>
                            poiDate.start === (entity.filter.date.start || '') &&
                            poiDate.end === (entity.filter.date.end || ''),
                    );

                if (dateFilter) {
                    entity.filter.date = {
                        name: dateFilter.name,
                        start: dateFilter.start,
                        end: dateFilter.end,
                        chosenLabel: dateFilter.chosenLabel,
                    };
                    isFilterUpdated = true;
                }
            }
        }

        let attributes: BackendAttributeFilter[] | undefined = ['all'];
        if (Array.isArray(entity.filter.attributes)) {
            attributes = cloneDeep<BackendAttributeFilter[]>(entity.filter.attributes);
        }
        const durationFilterIndex = attributes.findIndex(
            (filter) => filter[1] === LENGTH_OF_STAY_ATTRIBUTE,
        );
        const hasDurationFilter = durationFilterIndex !== -1;

        // do not include short visits
        if (enable_legacy_builders_for_regions_ff && reportEntityData?.is_visit_duration_limited) {
            if (!hasDurationFilter && entityData.default_profile_name !== LONG_VISITS) {
                attributes.push(FILTERS_BY_DURATION[LONG_VISITS] as BackendAttributeFilter);
                isFilterUpdated = true;
            } else if (
                hasDurationFilter &&
                (attributes[durationFilterIndex][0] === LESS_THEN_FILTER_OPERATOR ||
                    (attributes[durationFilterIndex][2] as number) < VISIT_LENGTH_BASELINE)
            ) {
                attributes.splice(
                    durationFilterIndex,
                    1,
                    FILTERS_BY_DURATION[LONG_VISITS] as BackendAttributeFilter,
                );
                isFilterUpdated = true;
            }
        }

        // freemium can see only defaults - removing the filter to get default
        if (
            (isFreemiumUser || !entityData.purchased || isFreemiumReport) &&
            entity.filter.attributes
        ) {
            if (hasDurationFilter) {
                attributes.splice(durationFilterIndex, 1);
                isFilterUpdated = true;
            }
        }

        if (attributes.length === 1 && attributes[0] === 'all') {
            attributes = undefined;
        }
        // it doesn't matter if isFilterUpdated was not updated in this scope because attributes are cloned
        if (isFilterUpdated) entity.filter.attributes = attributes;
    }

    return { isFilterUpdated };
};
