import { createAction } from '@reduxjs/toolkit';
import { ReportOptions } from 'core/entities';

const setMissingPermissionsCheckSuppressedAction =
    'chains/set-missing-permissions-check-suppressed';
export const setMissingPermissionsCheckSuppressed = createAction<boolean>(
    setMissingPermissionsCheckSuppressedAction,
);

const setStickyBarStateAction = 'chains/set-sticky-bar-state';
export const setStickyBarState = createAction<boolean>(setStickyBarStateAction);

const setDatePickerStateAction = 'chains/set-date-picker-state';
export const setDatePickerState = createAction<boolean>(setDatePickerStateAction);

const setCustomFilterModalStateAction = 'chains/set-custom-filter-modal-state';
export const setCustomFilterModalState = createAction<boolean>(setCustomFilterModalStateAction);

const setChainsReportOptionsAction = 'chains/set-chains-report-options';
export const setChainsReportOptions = createAction<ReportOptions>(setChainsReportOptionsAction);
