import React from 'react';
import styles from './solutions-guide-trigger.module.scss';
import { Button } from '@placer-ui/components';

type SolutionsGuideTriggerProps = {
    onClick: () => void;
};

export const SolutionsGuideTrigger = ({ onClick }: SolutionsGuideTriggerProps) => {
    return (
        <div className={styles.root}>
            <Button variant={'primary-brand'} onClick={onClick}>
                Solutions Guide
            </Button>
        </div>
    );
};
