import { fetchWrapper } from 'API/authentification/fetch-wrapper';
import { ResponseCreateEmptySpaceData, EmptySpaceOption } from 'features/empty-space/types/types';
import type { PlacerResponseData } from '@placer-ui/types';

export const emptySpaceApi = {
    createNewEntityByEmptySpace: (
        emptySpaceDetails: EmptySpaceOption,
    ): Promise<PlacerResponseData<ResponseCreateEmptySpaceData>> => {
        const url = '/empty_space';
        return fetchWrapper<PlacerResponseData<ResponseCreateEmptySpaceData>, EmptySpaceOption>({
            targetUrl: url,
            method: 'POST',
            body: emptySpaceDetails,
        });
    },
};
