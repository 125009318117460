import { SgAviationIcon } from 'features/onboarding/solutions-guide/icons/sg-aviation-icon';
import React from 'react';

export const AVIATION_SOLUTIONS_GUIDE = {
    icon: <SgAviationIcon />,
    solutions: [
        {
            title: 'Air Service Development',
            description:
                'Identify passenger origins and high-volume travelers to ' +
                'your airport to make stronger appeals for additional air service.',
            url: '/insights/complexes/90c7327e567d19f91011d3b6/trade-area',
        },
        {
            title: 'Network Planning',
            description:
                'Predict changes in air service requirements by ' +
                'analyzing population changes across the United States.',
            url: '/advanced-reports/migration',
        },
        {
            title: 'Concession Development',
            description:
                'Explore visitor demographics and favorite places to uncover ' +
                'passenger preferences and bring the right retailers to your airport.',
            url: '/insights/complexes/90c7327e567d19f91011d3b6/overview',
        },
        {
            title: 'Visitor Insights',
            description:
                'Uncover comprehensive demographic and psychographic insights about your airport visitors.',
            url: '/insights/complexes/90c7327e567d19f91011d3b6/audience',
        },
        {
            title: 'Operational Efficiency',
            description:
                'Analyze seasonal visitation and traffic within your ' +
                'airport to improve demand planning and optimize staffing.',
            url: '/insights/complexes/90c7327e567d19f91011d3b6/overview',
        },
    ],
};
