import _ from 'lodash/fp';
import type { Attributes, Chain, DateLabels, PlacerEntityWrapper } from '@placer-ui/types';
import { ChainEntityModel } from 'core/entities';

type ChainEntityModelMapperArgs = {
    entityWrapper: PlacerEntityWrapper<Chain>;
    startDate: string;
    endDate: string;
    date_label?: DateLabels;
    attributes?: Attributes;
    notInReport?: boolean;
};

export class ChainEntityModelMapper {
    static mapEntityWrapperToEntityModel({
        entityWrapper,
        startDate,
        endDate,
        date_label,
        attributes,
        notInReport,
    }: ChainEntityModelMapperArgs): ChainEntityModel {
        return {
            originalEntity: entityWrapper.info,
            uid: _.uniqueId('chain_'),
            overview: entityWrapper.overview || undefined,
            color: '',
            filters: {
                date_start: startDate,
                date_end: endDate,
                date_label,
                attributes,
            },
            notInReport,
        };
    }

    static createEntityModel(chain: ChainEntityModel, color: string) {
        const newEntity = _.cloneDeep(chain);

        return {
            ...newEntity,
            uid: _.uniqueId('chain_'),
            color,
        };
    }
}
