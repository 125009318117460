import { isNil } from 'lodash/fp';
import type { ResponseStatus } from 'API/common/types';
import { ATTEMPT_NUM_TO_DELAY_MS } from './constants';
import type { Dictionary } from '@placer-ui/types';

export function isFetchPollingError(error: any): boolean {
    if (
        !isNil(error) &&
        typeof error === 'object' &&
        !isNil(error.common) &&
        typeof error.common === 'object'
    ) {
        const common: ResponseStatus = error.common;

        if (typeof common.status === 'number' && typeof common.type === 'string') {
            return true;
        }
    }

    return false;
}

export const getDelayTimeOnErrorInProgress = (
    fetchID: string,
    attemptsCounterFetchIDs: Dictionary<number>,
) => {
    const fetchIdCounter = attemptsCounterFetchIDs[fetchID];

    return fetchIdCounter && ATTEMPT_NUM_TO_DELAY_MS[fetchIdCounter];
};
