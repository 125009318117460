import { useCallback } from 'react';
import type { ExternalVenueProviders, Venue } from '@placer-ui/types';
import { useOpenMultiplePropertyReport } from 'hooks/use-navigation-hooks/open-property-hooks';
import { PlacePOI } from 'core/entities';
import { useCloseSearchDialog } from 'components/search-options-dialog/v2/hooks/use-search-dialog-venue';
import { createCustomPOI } from 'utils/create-custom-poi/create-custom-poi';
import { setCustomUserSettingsRecentEntities } from 'store/auth/actions/set-custom-user-settings-recent-entities';
import { useAppDispatch } from 'store/app-store-hooks';
import { RecentEntitiesModel } from 'core/services/recent-entities-service/recent-entities-model';
import { PlaceWithFilters } from 'features/insights/store/poi/poi-types';
import { PlaceOverlay } from 'ui-components/google-map-layers/types/google-map-layers-types';
import { useExploreUIState } from 'features/explore/context/use-explore-ui-state';
import { useExplorePermissions } from 'features/explore/hooks/use-explore-permissions';
import { getDeviceType } from 'utils/get-user-agent-data/utils';
import { useSetExploreCreatePoiLoading } from 'features/explore/context/use-explore-ui-state-actions';
import { COORDINATES_ENTITY } from 'features/explore/constants';

export const useOpenExploreReportLink = () => {
    const redirectToReport = useOpenMultiplePropertyReport();
    const clearSearchDialog = useCloseSearchDialog();
    const {
        selectedOverlayResults: selectedResults,
        isCreatingPoi: isLoading,
        customPoiAddresses,
    } = useExploreUIState();
    const dispatch = useAppDispatch();
    const { hasOpenExploreReportSamePageInTablet } = useExplorePermissions();
    const setIsLoading = useSetExploreCreatePoiLoading();

    const openReport = useCallback(
        async (property?: PlaceOverlay) => {
            try {
                let propertiesToOpen: PlaceOverlay[] = property ? [property] : [];

                if (
                    !property ||
                    selectedResults.some(
                        (selected) =>
                            property.id === selected.id &&
                            property.isSelected === selected.isSelected,
                    )
                ) {
                    propertiesToOpen = selectedResults;
                }

                setIsLoading(true);
                const transformedProperties = propertiesToOpen.map(async (property) => {
                    if (
                        property.provider_data?.provider &&
                        property.provider_data.entity_id !== COORDINATES_ENTITY &&
                        !customPoiAddresses[property.provider_data.entity_id || property.id]
                    ) {
                        const { name, type, address, geojson, provider_data } = property as Venue;
                        const createdProperty = await createCustomPOI({
                            name,
                            type,
                            address,
                            category: PlacePOI.getPrimaryCategory(property) || 'Region',
                            sub_category: PlacePOI.getSubCategory(property) || 'Region',
                            geojson,
                            provider: provider_data?.provider as ExternalVenueProviders,
                        });
                        return {
                            ...createdProperty,
                            filter: property?.filter,
                        };
                    }

                    const customAddressId =
                        customPoiAddresses[property.provider_data?.entity_id || property.id];

                    const propertyToOpen = {
                        ...property,
                        id: customAddressId ? customAddressId : property.id,
                    };

                    dispatch(
                        setCustomUserSettingsRecentEntities(propertyToOpen as RecentEntitiesModel),
                    );

                    // Use the available overlay POI (enclosing complex/nearby activity radius).
                    // Otherwise, use the original entity
                    return propertyToOpen;
                });

                const resolvedProperties = await Promise.all(transformedProperties);

                (window as any).telemetryReport = true;

                const openInNewTab =
                    getDeviceType() === 'desktop' || !hasOpenExploreReportSamePageInTablet;

                redirectToReport({
                    entities: resolvedProperties as PlaceWithFilters[],
                    newTab: openInNewTab,
                    inlineSplunkEvent: false,
                });
                clearSearchDialog();
            } finally {
                setIsLoading(false);
            }
        },
        [
            clearSearchDialog,
            customPoiAddresses,
            dispatch,
            hasOpenExploreReportSamePageInTablet,
            redirectToReport,
            selectedResults,
            setIsLoading,
        ],
    );

    return {
        isLoading,
        openReport,
    };
};
