/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';
import { IconProps } from 'components/assets/Icons/Icons';

export const OfficeSuppliesIconSvg = (
    props: CustomIconComponentProps | SVGProps<SVGSVGElement>,
) => (
    <svg {...props} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_770_10567)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1 7.00006H11V10.0001C11 11.1046 10.1046 12.0001 9 12.0001H3C1.89543 12.0001 1 11.1046 1 10.0001V7.00006Z"
                fill="currentColor"
            />
            <path
                d="M2.35961 5.09767C2.97627 4.74165 3.19355 3.96352 2.84492 3.35967C2.49629 2.75583 1.71377 2.55493 1.09711 2.91096C0.480459 3.26699 0.263181 4.04512 0.61181 4.64896C0.96044 5.2528 1.74296 5.4537 2.35961 5.09767Z"
                stroke="currentColor"
                strokeWidth="0.85"
            />
            <path
                d="M6.3186 2.81197C6.93525 2.45594 7.15253 1.67781 6.8039 1.07397C6.45527 0.470124 5.67275 0.269228 5.0561 0.625255C4.43944 0.981281 4.22216 1.75941 4.57079 2.36325C4.91942 2.9671 5.70194 3.168 6.3186 2.81197Z"
                stroke="currentColor"
                strokeWidth="0.85"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.77727 6.99968L7.41564 6.99947L7.78241 7.63312C8.098 8.17974 7.91071 8.8787 7.36409 9.19429C6.81747 9.50988 6.11851 9.3226 5.80292 8.77597L4.77727 6.99968ZM5.11409 5.29718C5.66071 4.98158 6.35968 5.16887 6.67527 5.71549L6.98264 6.24949L4.54315 6.24871C4.55626 5.86771 4.75962 5.50183 5.11409 5.29718Z"
                fill="currentColor"
            />
            <path
                d="M3.28061 4.40717L4.92177 5.4759"
                stroke="currentColor"
                strokeWidth="0.85"
                strokeLinecap="square"
            />
            <path
                d="M5.2601 3.26428L5.37058 5.3227"
                stroke="currentColor"
                strokeWidth="0.85"
                strokeLinecap="square"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.78981 0.239728L11 2.00001H8.25L9.46019 0.239728C9.52277 0.148707 9.64728 0.125648 9.73831 0.188225C9.75848 0.202096 9.77594 0.219553 9.78981 0.239728Z"
                fill="currentColor"
            />
            <path d="M11 2.75H8.25V6.25H11V2.75Z" fill="currentColor" />
        </g>
        <defs>
            <clipPath id="clip0_770_10567">
                <rect width="12" height="12" fill="currentColor" />
            </clipPath>
        </defs>
    </svg>
);

export const OfficeSuppliesIcon = (props: IconProps) => (
    <Icon component={OfficeSuppliesIconSvg} {...props} />
);
