import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Heading, MenuText } from '@placer-ui/components';
import { openElevioComponent } from 'utils/elevio/elevio';
import { ErrorComponentsProps } from 'router/errors/error-types';
import imageUrl from './error-404.svg';
import styles from './error-404.module.scss';

export const Error404 = ({ failBackUrl, ctaText }: ErrorComponentsProps) => {
    const history = useHistory();
    const btnRef = useRef<HTMLButtonElement>(null);

    const handleRedirect = () => {
        history.replace(failBackUrl || '/explore');
    };

    const handleContactSupport = () => {
        openElevioComponent('3', 'module');
    };

    return (
        <div className={styles.container} data-testid="404-error-page">
            <div className={styles.error}>
                <img className={styles.hero} src={imageUrl} alt="404 Page Not Found" />
                <Heading variant="h2">This Page Cannot be Found</Heading>
                <MenuText color="secondary">
                    The link may be broken or this page may have been removed
                </MenuText>
                <div className={styles.buttons}>
                    <Button onClick={handleRedirect} fullWidth>
                        {ctaText || 'Go to Explore'}
                    </Button>
                    <Button ref={btnRef} variant="text" onClick={handleContactSupport} fullWidth>
                        Contact Support
                    </Button>
                </div>
            </div>
        </div>
    );
};
