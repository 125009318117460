import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';
import styles from 'components/assets/Icons/Icons.module.scss';

import { IconProps } from 'components/assets/Icons/Icons';

/* eslint-disable max-len */
const HelpOutlinedSvg = (props: CustomIconComponentProps | SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        viewBox="0 0 19 18"
        stroke={props.fill}
        className={styles.animation}
    >
        <g fillRule="evenodd">
            <rect
                width="17.325"
                height="17.325"
                x=".338"
                y=".338"
                stroke="inherit"
                fill="none"
                strokeWidth=".675"
                rx="1.8"
            />
            <path
                fill="inherit"
                stroke="none"
                fillRule="nonzero"
                d="M9.092 10.768v-.276c0-.333.065-.597.194-.794.13-.197.385-.445.766-.745.447-.348.762-.631.944-.849a2.34 2.34 0 00.41-.686c.09-.24.135-.513.135-.82 0-.694-.236-1.238-.708-1.631-.471-.394-1.11-.59-1.913-.59-.952 0-1.844.23-2.676.694l.517 1.083c.315-.156.64-.29.972-.4.332-.11.693-.166 1.082-.166.431 0 .761.092.991.277.23.185.344.45.344.794 0 .295-.07.55-.212.762-.141.214-.456.513-.944.899-.41.32-.697.63-.861.929-.165.3-.247.674-.247 1.126v.393h1.206zm-.553 2.91c.29 0 .515-.085.673-.255.158-.17.237-.4.237-.692 0-.295-.078-.524-.234-.686-.156-.162-.381-.243-.676-.243-.603 0-.905.31-.905.929 0 .3.078.532.234.698.156.166.38.25.67.25z"
            />
        </g>
    </svg>
);

export const HelpOutlinedIcon = (props: IconProps) => (
    <Icon component={HelpOutlinedSvg} {...props} />
);
