/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';
import { IconProps } from 'components/assets/Icons/Icons';

export const StadiumsAndEventHallsIconSvg = (
    props: CustomIconComponentProps | SVGProps<SVGSVGElement>,
) => (
    <svg {...props} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.625 1.5L5.62493 3.52795C4.42215 3.70891 3.5 4.74677 3.5 6C3.5 7.25323 4.42215 8.29109 5.62493 8.47205L5.625 10.5H1C0.447715 10.5 0 10.0523 0 9.5V8.5H0.5C1.01284 8.5 1.43551 8.11396 1.49327 7.61662L1.5 7.5V4.5C1.5 3.94772 1.05228 3.5 0.5 3.5H0V2.5C0 1.94772 0.447715 1.5 1 1.5H5.625ZM11 1.5C11.5523 1.5 12 1.94772 12 2.5V3.5H11.5C10.9872 3.5 10.5645 3.88604 10.5067 4.38338L10.5 4.5V7.5C10.5 8.05228 10.9477 8.5 11.5 8.5H12V9.5C12 10.0523 11.5523 10.5 11 10.5H6.375L6.37607 8.4719C7.52371 8.29876 8.41564 7.34545 8.49435 6.16945L8.5 6C8.5 4.74711 7.57836 3.70948 6.37607 3.5281L6.375 1.5H11ZM12 4.25V7.75H11.5L11.4427 7.7434C11.3322 7.71749 11.25 7.61835 11.25 7.5V4.5L11.2566 4.44268C11.2825 4.33223 11.3817 4.25 11.5 4.25H12ZM0.5 4.25L0.557323 4.2566C0.667767 4.28251 0.75 4.38165 0.75 4.5V7.5L0.743397 7.55732C0.717487 7.66777 0.618347 7.75 0.5 7.75H0V4.25H0.5ZM5.62492 4.29029V7.70971C4.83866 7.53801 4.25 6.83775 4.25 6C4.25 5.16225 4.83866 4.46199 5.62492 4.29029ZM6.37608 4.29051C7.16183 4.46258 7.75 5.1626 7.75 6C7.75 6.8374 7.16183 7.53742 6.37608 7.70949V4.29051Z"
            fill="currentColor"
        />
    </svg>
);

export const StadiumsAndEventHallsIcon = (props: IconProps) => (
    <Icon component={StadiumsAndEventHallsIconSvg} {...props} />
);
