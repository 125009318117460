/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';
import { IconProps } from 'components/assets/Icons/Icons';

export const GasStationsAndConvenienceStoresIconSvg = (
    props: CustomIconComponentProps | SVGProps<SVGSVGElement>,
) => (
    <svg {...props} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.08544 0C0.493492 0 0 0.490604 0 1.07909V11.4379C0 11.7359 0.242997 11.9774 0.54272 11.9775L7.54272 12C7.84244 12 8.08541 11.7584 8.08544 11.4605V9.03251C8.08544 8.93318 8.10389 8.83574 8.1408 8.74019C8.44696 8.74019 8.68352 8.97536 8.68352 9.27973V9.81928C8.68352 10.7068 9.4189 11.4379 10.3117 11.4379C11.2045 11.4379 12 10.7068 12 9.81928V2.80521C12 2.2962 11.6986 1.81549 11.289 1.51009L9.76896 0.161231C9.50381 -0.0364308 9.16488 0.0591761 9.00894 0.269772C8.83115 0.509875 8.87799 0.846735 9.11812 1.02534L9.76896 1.51009C9.90584 1.61202 9.98637 1.77213 9.98626 1.94215V3.29101C9.98626 3.78867 10.1202 4.26927 10.5862 4.39012C10.7309 4.42763 11 4.54462 11 4.69361V9.81928C11 10.1236 10.6178 10.3588 10.3117 10.3588C10.0055 10.3588 9.76896 10.1236 9.76896 9.81928V9.27973C9.76896 8.39218 9.03358 7.6611 8.1408 7.6611C8.10389 7.59561 8.08544 7.5132 8.08544 7.41388V1.10163C8.08544 0.513152 7.59195 0.0225473 7 0.0225473L1.08544 0ZM1.3 1.04801H6.7C6.8662 1.04801 7 1.16833 7 1.31778V4.71301C7 4.86247 6.8662 4.98279 6.7 4.98279H1.3C1.1338 4.98279 1 4.86247 1 4.71301V1.31778C1 1.16833 1.1338 1.04801 1.3 1.04801Z"
            fill="currentColor"
        />
    </svg>
);

export const GasStationsAndConvenienceStoresIcon = (props: IconProps) => (
    <Icon component={GasStationsAndConvenienceStoresIconSvg} {...props} />
);
