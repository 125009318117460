import type { BaseUserAuth } from '@placer-ui/types';

import { userCookieStorage, impersonateUserCookieStorage } from 'core/authentification';
import { httpClient } from 'shared/http-client';
import { removeLoginCookie } from 'store/auth/utils/utils';
import {
    decodeCookieValue,
    getCookieValueByName,
    loginCookieKey,
    loginImpersonateCookieKey,
} from 'utils/cookies/utils';

export const syncUserTokenFromCookie = () => {
    try {
        // very first thing on loading - get the user from the cookie and update the user instance
        const encodedCookieValue = getCookieValueByName(loginCookieKey);
        if (encodedCookieValue) {
            const cookieValue = decodeCookieValue(encodedCookieValue);
            const user = JSON.parse(cookieValue) as BaseUserAuth;
            userCookieStorage.setUser(user, true);
            httpClient.setToken(user.token);
        }
    } catch (err) {
        removeLoginCookie(loginCookieKey);
    }

    try {
        // get the impersonated user from the cookie and update the impersonate user instance
        const encodedCookieValue = getCookieValueByName(loginImpersonateCookieKey);
        if (encodedCookieValue) {
            const cookieValue = decodeCookieValue(encodedCookieValue);
            const user = JSON.parse(cookieValue) as BaseUserAuth;
            impersonateUserCookieStorage.setUser(user, true);
        }
    } catch (err) {
        removeLoginCookie(loginImpersonateCookieKey);
    }
};
