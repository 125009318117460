/* eslint-disable object-property-newline */
const ANALYTICS_BLACK_LIST: { path: string; startsWith?: boolean }[] = [
    { path: '/' },
    { path: '/my-zone/' },
    { path: '/insights/' },
    { path: '/advanced-reports/' },
    { path: '/chains/', startsWith: true },
    { path: '/industry/', startsWith: true },
];
/* eslint-enable object-property-newline */

export const isBlackListed = (pathname: string) =>
    ANALYTICS_BLACK_LIST.some(rule => {
        if (rule.startsWith) {
            return pathname.startsWith(rule.path);
        }
        return pathname === rule.path;
    });
