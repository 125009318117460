import {
    selectChainsSearchActiveChain,
    selectChainsSearchTopVenues,
} from '../chains-report-search-selectors-factory';
import { prettifyNumber } from 'utils/prettify-number/prettify-number';
import { createSelector } from '@reduxjs/toolkit';
import { random } from 'lodash';

import { getRegionFullName } from 'core/services/regions-service';

const BLURRED_TRAFFIC_PLACEHOLDER = random(348271, 858272);

export const selectChainsSearchActiveChainMapInfo = createSelector(
    selectChainsSearchActiveChain,
    selectChainsSearchTopVenues,
    (activeChain, topVenues) => {
        const dummyFoottraffic = prettifyNumber(BLURRED_TRAFFIC_PLACEHOLDER, 2);
        const monthlyFootTraffic =
            topVenues?.monthly_ft && prettifyNumber(topVenues?.monthly_ft, 2);
        const regionName = activeChain && getRegionFullName(activeChain?.area);

        return {
            name: activeChain?.name,
            monthlyFootTraffic: monthlyFootTraffic || dummyFoottraffic,
            region: regionName,
            hasFakeMonthlyFootTraffic: !monthlyFootTraffic,
        };
    },
);
