import _ from 'lodash';

export class LngLat {
    
    latitude = null;
    longitude = null;

    constructor(obj: any) {
        if (!obj) return;

        if (obj.length === 2) {
            this.longitude = obj[0];
            this.latitude = obj[1];
        } else if (_.isObject(obj)) {
            this.longitude = _.result(obj, 'lng');
            this.latitude = _.result(obj, 'lat');
        }
    }

    toArrayPoint() {
        return [this.longitude, this.latitude];
    }

    toObjectPoint() {
        return {
            lng: this.longitude,
            lat: this.latitude,
        };
    }
}
