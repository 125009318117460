/* eslint-disable max-len */
import type { SVGProps } from 'react';
import React from 'react';

export type CheckIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type CheckIconProps = SVGProps<SVGSVGElement> & CheckIconUniqueProps;

export const CheckIcon = function CheckIcon({
    width = 17,
    height = 17,
    color = '#028A00',
    ...props
}: CheckIconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            width={width}
            height={height}
            viewBox="0 0 16 16"
            color={color}
            {...props}
        >
            <g fill="none" fillRule="evenodd">
                <g transform="translate(-447 -435) translate(0 111) translate(447 324)">
                    <circle cx="8" cy="8" r="7.5" fill="transparent" stroke="currentColor" />
                    <g fillRule="nonzero">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M7.41861 11.9822L4.696 9.25943C4.43467 8.99809 4.43467 8.57676 4.696 8.31676C4.956 8.05543 5.37733 8.05543 5.63867 8.31676L7.41861 10.0969L11.3606 6.14812C11.6206 5.88813 12.0419 5.88813 12.3032 6.14812C12.5632 6.40946 12.5632 6.83079 12.3032 7.09079L7.41861 11.9822Z"
                            fill="#028A00"
                            transform="translate(-0.5,-0.8)"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};
CheckIcon.__tags = ['check'] as string[];
