import { isMobile as isMobileUA } from 'is-mobile';
import { MOBILE_MAX, screenLargeMin } from 'shared/constants/resolution-breakpoints';
import { getFeatureFlags } from 'core/flow-control';
import { userCookieStorage } from 'core/authentification/token-storage/token-storage';

export function isIOS(userAgent = window.navigator.userAgent) {
    return /iPad|iPhone|iPod/.test(userAgent);
}

export const isMobileApp = () =>
    window.__plIsMobileApp ?? window.navigator.userAgent.includes('PlacerApp');

export const isMobile = () => window.__plIsMobile ?? isMobileUA();

export function isMobileWeb() {
    return isMobileUA({ tablet: true }) && !isMobileApp();
}

export const isProduction = () => {
    return process.env.REACT_APP_ENV === 'production';
};

export const isStaging = () => {
    return process.env.REACT_APP_ENV === 'staging';
};

export const isDevelopment = () => {
    return process.env.REACT_APP_ENV === 'development';
};

export const isTouchEvent = (e: MouseEvent | TouchEvent): e is TouchEvent => {
    return window.TouchEvent && e instanceof window.TouchEvent;
};

export const isSafari = () => {
    const { navigator } = window;
    return (
        navigator.vendor &&
        navigator.vendor.indexOf('Apple') > -1 &&
        navigator.userAgent &&
        navigator.userAgent.indexOf('CriOS') === -1 &&
        navigator.userAgent.indexOf('FxiOS') === -1
    );
};

export const isUATablet =
    isMobileUA({
        tablet: true,
        featureDetect: true,
    }) && !isMobileUA();

export const getIsTablet = () => {
    return (window.innerWidth > MOBILE_MAX && window.innerWidth <= screenLargeMin) || isUATablet;
};

export const getIsTabletApp = () => {
    const { enable_ipads_on_app_ff, enable_ipad_web_app_ff } = getFeatureFlags();
    const isTablet = getIsTablet();

    return (enable_ipads_on_app_ff || enable_ipad_web_app_ff) && isTablet;
};

export const shouldRenderMobileApp = () => {
    const { enable_ipads_on_app_ff, enable_ipad_web_app_ff } = getFeatureFlags();

    const shouldRenderTablet =
        (isMobileApp() && enable_ipads_on_app_ff) || (enable_ipad_web_app_ff && !isMobileApp());
    const isUser = !!userCookieStorage.token;

    return isMobileUA({
        featureDetect: true,
        tablet: isUser ? shouldRenderTablet : true,
    });
};

export const isSupportedBrowser = () => {
    return !/(?:(?:MS)?IE|Trident)/.test(window.navigator.userAgent);
};

export const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === '[::1]' ||
        // 127.0.0.1/8 is considered localhost for IPv4.
        window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);

export const isBNMAutomationMachine = Boolean(
    window.location.hostname.match(/^bnm-automation.*\.placer\.team\/?$/),
);

export const isBNMPreProdENV = Boolean(
    window.location.hostname === 'preproduction-analytics.placer.team',
);
