export const addQueryParamsToPath = (path: string, queryParams: string): string => {
    if (path.includes('?')) {
        path += `&${queryParams}`;
    } else {
        path += `?${queryParams}`;
    }
    return path;
};

export const addNextUrlForQuery = (
    basePath: string,
    nextUrl: string | null,
    skipEncodingTokens: string[] = [],
) => {
    if (!nextUrl) return basePath;

    let encodedNextURL = encodeURIComponent(nextUrl);
    // replace encoded tokens with original tokens
    skipEncodingTokens.forEach((t) => {
        encodedNextURL = encodedNextURL.replaceAll(encodeURIComponent(t), t);
    });

    return addQueryParamsToPath(basePath, `next_url=${encodedNextURL}`);
};

export function getQueryParamValueFromUrlHash(urlPath: string, key: string) {
    const searchParams = urlPath.split('/?')[1];
    if (searchParams?.includes(key)) {
        return searchParams.split(`${key}=`)[1].split('&')[0];
    }
    return undefined;
}
