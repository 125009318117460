import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react';
import type { Place } from '@placer-ui/types';
import { useGetRecentSearchEntitiesEffect } from 'features/void-analysis/sections/add-new-report/hooks/add-new-report-hooks';
import type { PlacerEntityWrapper } from '@placer-ui/types';

export type ResultsType = 'recent reports' | 'search results';

type AddNewReportState = {
    results?: PlacerEntityWrapper<Place>[];
    resultsType: ResultsType;
    selectedPoi?: Place;
    isRequestingResults: boolean;
    setIsRequestingResults: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    onPoiSelected: (poi?: Place) => void;
    updateSearchResults: (results: PlacerEntityWrapper<Place>[] | undefined) => void;
};
const initialState = {
    isRequestingResults: false,
    resultsType: 'recent reports' as const,
    setIsRequestingResults: () => {},
    onPoiSelected: () => {},
    updateSearchResults: () => {},
};

export const AddNewReportStateContext = createContext<AddNewReportState>(initialState);

export const useAddNewReportContext = () => {
    return useContext(AddNewReportStateContext);
};

type RankingPageFiltersContextProps = {
    children?: ReactNode;
};

export const AddNewReportContext = ({ children }: RankingPageFiltersContextProps) => {
    const [recentEntities, setRecentEntities] = useState<PlacerEntityWrapper<Place>[]>();
    const [searchResults, setSearchResults] = useState<PlacerEntityWrapper<Place>[]>();
    const [selectedPoi, setSelectedPoi] = useState<Place | undefined>();
    const [isRequestingResults, setIsRequestingResults] = useState<boolean>(false);

    const onPoiSelected = useCallback((poi?: Place) => {
        setSelectedPoi(poi);
    }, []);

    useGetRecentSearchEntitiesEffect(setRecentEntities);

    const resultsToShow = searchResults || recentEntities;
    const resultsType: ResultsType = searchResults ? 'search results' : 'recent reports';

    const state = {
        results: resultsToShow,
        resultsType,
        isRequestingResults,
        setIsRequestingResults,
        selectedPoi,
        onPoiSelected,
        updateSearchResults: setSearchResults,
    };

    return (
        <AddNewReportStateContext.Provider value={state}>
            {children}
        </AddNewReportStateContext.Provider>
    );
};
