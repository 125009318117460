import type { VisitDurationType, Attribute } from '@placer-ui/types';
import { FilterConfig } from 'features/insights/types/filter-types';
import {
    LENGTH_OF_STAY_ATTRIBUTE,
    LESS_THEN_FILTER_OPERATOR,
    MORE_THEN_FILTER_OPERATOR,
} from 'core/constants/filter-attributes';
import { VisitDurationSegments } from 'types/visit-duration';

export const SHORT_VISITS = 'under_10_min';
export const LONG_VISITS = '10_min_or_longer';
export const ALL_VISITS = 'all_visits';
export const DEFAULT_VISITS = 'default';

export const DIFFERENT_TAG_FILTERS = 'different_tag_filters';
export const DIFFERENT_TAG_FILTERS_INFO = 'different_tag_filters_info';
export const DIFFERENT_INDUSTRY_FILTERS_INFO = 'different_industry_filters_info';
export const DIFFERENT_CHAINS_FILTERS = 'different_chains_filters';

export const DIFFERENT_CHAINS_FILTERS_INFO = 'different_chains_filters_info';
export const MIX_WITH_TAG_AND_DIFFERENT_CHAINS_FILTERS =
    'mix_with_tag_and_different_chains_filters';

export const MIX_WITH_TAG_AND_DIFFERENT_CHAINS_FILTERS_INFO =
    'mix_with_tag_and_different_chains_filters_info';

export const VISIT_LENGTH_BASELINE = 10;

export const VISIT_DURATION_OPTIONS = [SHORT_VISITS, LONG_VISITS, ALL_VISITS, DEFAULT_VISITS];

export const VISITS_DURATION_TITLE_DICT: Record<VisitDurationType, string> = {
    [SHORT_VISITS]: `Under ${VISIT_LENGTH_BASELINE} Min`,
    [LONG_VISITS]: `Over ${VISIT_LENGTH_BASELINE} Min`,
    [ALL_VISITS]: 'All Visit Durations',
    [DEFAULT_VISITS]: 'Default Visit Duration',
};

export const MOBILE_VISITS_DURATION_TITLE_DICT: Record<VisitDurationType, string> = {
    [SHORT_VISITS]: `Under ${VISIT_LENGTH_BASELINE} Min`,
    [LONG_VISITS]: `Over ${VISIT_LENGTH_BASELINE} Min`,
    [ALL_VISITS]: 'All Visit Durations',
    [DEFAULT_VISITS]: 'Default Duration',
};

export const FILTERS_BY_DURATION: Record<VisitDurationSegments, Attribute> = {
    [SHORT_VISITS]: [LESS_THEN_FILTER_OPERATOR, LENGTH_OF_STAY_ATTRIBUTE, VISIT_LENGTH_BASELINE],
    [LONG_VISITS]: [MORE_THEN_FILTER_OPERATOR, LENGTH_OF_STAY_ATTRIBUTE, VISIT_LENGTH_BASELINE],
    [ALL_VISITS]: [MORE_THEN_FILTER_OPERATOR, LENGTH_OF_STAY_ATTRIBUTE, 0],
};

export const getVisitDurationValue = (filterConfig: FilterConfig | undefined) => {
    if (
        filterConfig?.operator === MORE_THEN_FILTER_OPERATOR &&
        filterConfig?.value === VISIT_LENGTH_BASELINE
    ) {
        return LONG_VISITS;
    } else if (
        filterConfig?.operator === LESS_THEN_FILTER_OPERATOR &&
        filterConfig?.value === VISIT_LENGTH_BASELINE
    ) {
        return SHORT_VISITS;
    } else if (filterConfig?.operator === MORE_THEN_FILTER_OPERATOR && filterConfig?.value === 0) {
        return ALL_VISITS;
    } else if (filterConfig?.isDefaultFilter) {
        return DEFAULT_VISITS;
    }
};
