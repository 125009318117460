import { usePoiFiltersOptions } from 'features/explore/hooks/use-filters-options';
import { useFiltersConfiguration } from 'features/explore/hooks/use-explore-persistent-filter';
import { useExploreActions } from 'features/explore/store/actions';
import { useAppDispatch } from 'store/app-store-hooks';
import { setCustomUserSettingsExplore } from 'store/explore/actions/set-custom-user-settings-explore';
import classnames from 'classnames';
import styles from 'features/explore/components/explore-map-section/explore-map-legend/explore-map-legend.module.scss';
import { Switch } from 'ui-components/switch/switch';
import { getMapLayersPOIStyles } from 'ui-components/google-map-layers/utils/map-layers-entity-color';
import { BILLBOARD } from 'core/constants/report-type';
import { DefaultPinSvg } from 'components/assets/Icons/PinIcon';
import React from 'react';
import { MapLegend } from 'ui-components/map-legend/map-legend';
import { Button } from 'ui-components/button/button';
import { HelpOutlinedIcon } from 'components/assets/Icons/HelpOutlined';
import { Tooltip } from 'ui-components/tooltip/tooltip';
import { useIsFreemiumUser } from 'hooks/use-freemium-user/use-freemium-user';

type MapLegendProps = {
    withSwitches?: boolean;
};

export const ExploreLegend = ({ withSwitches }: MapLegendProps) => {
    const legendFilterItems = usePoiFiltersOptions();
    const { restrictions, type } = useFiltersConfiguration();
    const { setExploreSearchParameters } = useExploreActions();
    const isFreemiumUser = useIsFreemiumUser();

    const dispatch = useAppDispatch();

    const handleOnChange = (value: boolean, uid: string) => {
        setExploreSearchParameters({
            restrictions: {
                ...restrictions,
                [uid]: value,
            },
        });

        dispatch(
            setCustomUserSettingsExplore({
                ...restrictions,
                [uid]: value,
            }),
        );
    };

    if (type === BILLBOARD) {
        return null;
    }

    const legendItems = legendFilterItems.map(({ title, id, disabled, profile }, i) => {
        if (id === 'enable_custom_pois' && isFreemiumUser) {
            return null;
        }
        const switchColor = restrictions[id]
            ? getMapLayersPOIStyles(profile).fill
            : 'var(--medium-gray)';
        return (
            <div
                key={i}
                className={classnames(styles.legendItem, {
                    [styles.withSwitches]: withSwitches,
                })}
            >
                {withSwitches && (
                    <Switch
                        color={switchColor}
                        uid={id}
                        disabled={disabled}
                        checked={restrictions[id]}
                        onChange={handleOnChange}
                    />
                )}
                <div className={styles.icon}>
                    {!withSwitches && DefaultPinSvg({ style: getMapLayersPOIStyles(profile) })}
                </div>
                <span>{title}</span>
            </div>
        );
    });

    return (
        <MapLegend
            dataTestId={'explore-map-legend'}
            className={classnames(styles.legend, { [styles.withSwitches]: withSwitches })}
            disableMouseEvents={false}
            closeBtnClass={styles.closeBtn}
        >
            <Tooltip title="Learn More" placement="left" className={styles.tooltip}>
                <Button
                    data-testid={'legend-elvio'}
                    type="ghost"
                    className={styles.actButton}
                    data-elevio-inline="546"
                    data-elevio-style="nothing"
                    icon={<HelpOutlinedIcon />}
                />
            </Tooltip>
            {legendItems}
        </MapLegend>
    );
};
