import type { DeviceTypes } from '@placer-ui/types';

export const OS_TYPES = {
    WINDOWS: 'Windows',
    MAC_OS: 'MacOS',
    X11: 'UNIX',
    LINUX: 'Linux',
} as const;

export const BROWSER_NAMES = {
    CHROME: 'chrome',
    FIREFOX: 'firefox',
    SAFARI: 'safari',
    OPERA: 'opera',
    EDGE: 'edge',
} as const;

export const DEVICE_TYPES: Record<Uppercase<DeviceTypes>, DeviceTypes> = {
    DESKTOP: 'desktop',
    TABLET: 'tablet',
    'MOBILE-WEB': 'mobile-web',
    'MOBILE-APP': 'mobile-app',
} as const;
