/* eslint-disable max-len */
import type { SVGProps } from 'react';
import React from 'react';

type AddSiteIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type AddSiteIconProps = SVGProps<SVGSVGElement> & AddSiteIconUniqueProps;

export const AddSiteIcon = function AddSiteIcon({
    width = 16,
    height = 17,
    ...props
}: AddSiteIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M16 10.8057C16 13.8432 13.5376 16.3057 10.5 16.3057C7.46243 16.3057 5 13.8432 5 10.8057C5 7.7681 7.46243 5.30566 10.5 5.30566C13.5376 5.30566 16 7.7681 16 10.8057Z"
                fill="#D0D0D0"
            />
            <path
                d="M2 7.92565C2 7.71579 2.17013 7.54565 2.38 7.54565H4.62C4.82987 7.54565 5 7.71579 5 7.92565C5 8.13552 4.82987 8.30565 4.62 8.30565H2.38C2.17013 8.30565 2 8.13552 2 7.92565Z"
                fill="#49565D"
            />
            <path
                d="M2 9.92565C2 9.71579 2.17013 9.54565 2.38 9.54565H3.62C3.82987 9.54565 4 9.71579 4 9.92565C4 10.1355 3.82987 10.3057 3.62 10.3057H2.38C2.17013 10.3057 2 10.1355 2 9.92565Z"
                fill="#49565D"
            />
            <path
                d="M10.875 8.30566C10.875 8.09856 10.7071 7.93066 10.5 7.93066C10.2929 7.93066 10.125 8.09856 10.125 8.30566V10.4307H8C7.79289 10.4307 7.625 10.5986 7.625 10.8057C7.625 11.0128 7.79289 11.1807 8 11.1807H10.125V13.3057C10.125 13.5128 10.2929 13.6807 10.5 13.6807C10.7071 13.6807 10.875 13.5128 10.875 13.3057V11.1807H13C13.2071 11.1807 13.375 11.0128 13.375 10.8057C13.375 10.5986 13.2071 10.4307 13 10.4307H10.875V8.30566Z"
                fill="#49565D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 0.305664H13V5.90537C14.7808 6.81573 16 8.66832 16 10.8057C16 13.8432 13.5376 16.3057 10.5 16.3057C8.79206 16.3057 7.26595 15.5272 6.25716 14.3057H2C0.89543 14.3057 0 13.4102 0 12.3057V0.305664ZM4.24524 1.05566H0.75V3.80566C0.75 4.77216 1.5335 5.55566 2.5 5.55566C3.43743 5.55566 4.20271 4.81858 4.24789 3.89234L4.24524 3.88684V1.05566ZM12.25 3.59102V5.58993C11.7003 5.40556 11.1118 5.30566 10.5 5.30566C7.46243 5.30566 5 7.7681 5 10.8057C5 11.8075 5.26783 12.7467 5.7358 13.5557H2C1.30964 13.5557 0.75 12.996 0.75 12.3057V5.59102C1.20094 6.03308 1.81864 6.30566 2.5 6.30566C3.57382 6.30566 4.48952 5.62864 4.84353 4.67816C5.28459 5.06863 5.86461 5.30566 6.5 5.30566C7.57382 5.30566 8.48952 4.62864 8.84353 3.67816C9.28459 4.06863 9.86461 4.30566 10.5 4.30566C11.1814 4.30566 11.7991 4.03308 12.25 3.59102ZM12.25 1.80566V1.05566H9V2.70756C9.30622 3.21577 9.86342 3.55566 10.5 3.55566C11.4665 3.55566 12.25 2.77216 12.25 1.80566ZM8 1.05566H5V3.70756C5.30622 4.21577 5.86342 4.55566 6.5 4.55566C7.43743 4.55566 8.20271 3.81858 8.24789 2.89234L8.24521 2.88679C8.2458 2.87764 8.24609 2.86842 8.24609 2.85913V1.05615L8 1.05566ZM10.5 15.5557C13.1234 15.5557 15.25 13.429 15.25 10.8057C15.25 8.18231 13.1234 6.05566 10.5 6.05566C7.87665 6.05566 5.75 8.18231 5.75 10.8057C5.75 13.429 7.87665 15.5557 10.5 15.5557Z"
                fill="#49565D"
            />
        </svg>
    );
};
AddSiteIcon.__tags = ['entity, site, plus, add'] as string[];
