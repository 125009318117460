/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

type ArrowIconUniqueProps = {
    width?: number | string;
    height?: number | string;
    color?: string;
};

type ArrowIconProps = SVGProps<SVGSVGElement> & ArrowIconUniqueProps;

export const ArrowIcon = function ArrowIcon({
    width = 25,
    height = 25,
    color = '#49565D',
    ...props
}: ArrowIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_504_88903)">
                <path
                    d="M1.5 12.1128C1.08579 12.1128 0.75 12.4486 0.75 12.8628C0.75 13.277 1.08579 13.6128 1.5 13.6128L1.5 12.1128ZM24.0303 13.3931C24.3232 13.1002 24.3232 12.6254 24.0303 12.3325L19.2574 7.55949C18.9645 7.2666 18.4896 7.2666 18.1967 7.55949C17.9038 7.85239 17.9038 8.32726 18.1967 8.62015L22.4393 12.8628L18.1967 17.1054C17.9038 17.3983 17.9038 17.8732 18.1967 18.1661C18.4896 18.459 18.9645 18.459 19.2574 18.1661L24.0303 13.3931ZM1.5 13.6128L23.5 13.6128L23.5 12.1128L1.5 12.1128L1.5 13.6128Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_504_88903">
                    <rect width="24" height="24" fill="white" transform="translate(0.5 0.862793)" />
                </clipPath>
            </defs>
        </svg>
    );
};
ArrowIcon.__tags = [] as string[];
