import { SgCREIcon } from 'features/onboarding/solutions-guide/icons/sg-cre-icon';
import React from 'react';

export const REAL_ESTATE_SOLUTIONS_GUIDE = {
    icon: <SgCREIcon />,
    solutions: [
        {
            title: 'Acquisitions',
            description:
                'Leverage property and local market data to support ' +
                'investment theses and make more educated decisions.',
            url: '/insights/complexes/596765ea173f566905c19f07/overview',
        },
        {
            title: 'Leasing',
            description:
                'Instantly generate a list of best-fit tenants for any retail ' +
                'vacancy based on customizable scoring parameters and filters.',
            url: '/insights/complexes/596765ea173f566905c19f07/void-analysis-freemium',
        },
        {
            title: 'Market Trends',
            description:
                'Explore migration patterns and related demographic changes ' +
                'to understand the impact on local consumer demand.',
            url: '/advanced-reports/migration',
        },
        {
            title: 'Site Selection',
            description:
                'Easily identify ideal sites for a new chain locations based ' +
                'on customizable scoring parameters and filters.',
            url: '/advanced-reports/freemium/site-selection',
        },
        {
            title: 'Visitor Insights',
            description:
                'Learn more about a property’s visitors to inform ' +
                'marketing, site selection, or void analysis decisions.',
            url: '/insights/complexes/596765ea173f566905c19f07/audience',
        },
        {
            title: 'Asset Management',
            description:
                'Analyze visits to your retail center and its tenants to ' +
                'monitor performance and support rent negotiations.',
            url: '/insights/complexes/596765ea173f566905c19f07/overview',
        },
    ],
};
