import { FailureResponse } from 'store/auth/actions/auth-actions/auth-action-types';
import { AuthError } from 'features/authentication/utils/auth-error';
import {
    BACKEND_INACTIVE_ACCOUNT_ERROR_CODE,
    SSO_FORCE_ACCOUNT,
} from 'features/authentication/utils/constants';

export const getAuthError = async (response: Response): Promise<AuthError> => {
    const errorDataResponse: FailureResponse = await response.json();
    const shouldForceSso =
        response.status === BACKEND_INACTIVE_ACCOUNT_ERROR_CODE &&
        errorDataResponse.error_code === SSO_FORCE_ACCOUNT;

    return new AuthError({
        status: response.status,
        ...errorDataResponse,
        shouldForceSso,
    });
};
