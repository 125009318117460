import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { Popover } from 'antd';
import { noop } from 'lodash/fp';

import { SelectArrowIcon } from 'components/assets/Icons/Icons';

import styles from '../navigation-button.module.scss';

import { NavigationButtonProps, NavigationButton } from 'features/header/navigation/components';

type NavigationButtonPropsNoOnClick = Omit<NavigationButtonProps, 'onClick'>;

type NavigationButtonWithPopoverProps = NavigationButtonPropsNoOnClick & {
    popoverContent: React.ReactElement | null;
    isSelected?: boolean;
    onVisibleChange?: (isVisible: boolean) => void;
};

const pickAlignOffset = (isCentered: boolean) => ({
    offset: isCentered ? [0, -8] : [-15, -8],
});

export const NavigationButtonWithPopover = ({
    link,
    label,
    popoverContent,
    isSelected,
    onVisibleChange,
}: NavigationButtonWithPopoverProps) => {
    const [isMorePopoverVisible, setIsMorePopoverVisible] = useState<boolean>(false);

    const popoverButtonWrapperRef = useRef<HTMLSpanElement>(null);

    const toggleMorePopover = () => {
        setIsMorePopoverVisible(!isMorePopoverVisible);

        if (onVisibleChange) {
            onVisibleChange(isMorePopoverVisible);
        }
    };

    const buttonWrapperClasses = classNames(styles.navItemWithPopover, {
        [styles.isActive]: isMorePopoverVisible,
    });

    const shouldBePopoverCentered = window.innerWidth < 860;
    const placement = shouldBePopoverCentered ? 'bottom' : 'bottomLeft';

    const getPopupContainer = () => popoverButtonWrapperRef.current || document.body;

    const renderPopover = () => {
        if (!popoverContent) return null;

        const content = <div onClick={toggleMorePopover}>{popoverContent}</div>;

        return (
            <Popover
                content={content}
                trigger="click"
                placement={placement}
                getPopupContainer={getPopupContainer}
                visible={isMorePopoverVisible}
                onVisibleChange={toggleMorePopover}
                overlayClassName={classNames(styles.navigationPopover, styles.overlayPopover)}
                align={pickAlignOffset(shouldBePopoverCentered)}
                autoAdjustOverflow={false}
            >
                <span ref={popoverButtonWrapperRef} className={styles.popoverButtonWrapper}>
                    <SelectArrowIcon className={styles.buttonArrow} />
                </span>
            </Popover>
        );
    };

    return (
        <span className={buttonWrapperClasses}>
            <NavigationButton label={label} link={link} isSelected={isSelected} onClick={noop} />
            {renderPopover()}
        </span>
    );
};
