import type { PlacerEntityModel } from '@placer-ui/types';
import { EntityAccessLevel, EntityAccessLevelTypes } from 'core/constants';

export abstract class EntitiesAccessLevelService<T extends PlacerEntityModel<any>> {
    abstract getAccessLevelOfEntity(entity: T): EntityAccessLevelTypes;

    getEntitiesLowestAccessLevel(entities: T[]): EntityAccessLevelTypes {
        const accessLevelsOfEntities: EntityAccessLevelTypes[] = [];

        for (let i = 0; i < entities.length; i++) {
            const entityModel = entities[i];
            const accessLevelOfEntity = this.getAccessLevelOfEntity(entityModel);

            if (accessLevelOfEntity === EntityAccessLevel.MISSING_PERMISSIONS) {
                return EntityAccessLevel.MISSING_PERMISSIONS;
            }
            accessLevelsOfEntities.push(accessLevelOfEntity);
        }
        return accessLevelsOfEntities.includes(EntityAccessLevel.LIMITED)
            ? EntityAccessLevel.LIMITED
            : EntityAccessLevel.FULL;
    }
}
