/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';
import { IconProps } from 'components/assets/Icons/Icons';

export const BarsAndPubsIconSvg = (props: CustomIconComponentProps | SVGProps<SVGSVGElement>) => (
    <svg {...props} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.11334 10.9091H9.18505V12H1.11334V10.9091Z" fill="white" />
        <path
            d="M1.95813 5.36386V3.94393L2.33104 3.94337C2.87776 3.94263 3.37313 3.63487 3.59305 3.15953L3.69119 2.94756H9.06057V2.43485C9.06057 1.88531 8.58932 1.43821 8.01021 1.43821C7.98366 1.43821 7.95467 1.4395 7.9217 1.44208L7.67669 1.46146L7.56105 1.25558C7.24066 0.685005 6.61904 0.330552 5.93867 0.330552C5.58385 0.330552 5.23915 0.427171 4.94181 0.609888L4.68377 0.76852L4.47018 0.558672C4.10339 0.198405 3.61123 0 3.08445 0C2.27375 0 1.54485 0.486139 1.27066 1.20972L1.19022 1.42215L0.953191 1.44264C0.409775 1.48979 0 1.91632 0 2.43475C0 2.78266 0.18665 3.09973 0.499161 3.2831L0.67628 3.38691V5.36386C0.67628 5.69912 0.963793 5.9719 1.31725 5.9719C1.67061 5.9719 1.95813 5.69912 1.95813 5.36386Z"
            fill="currentColor"
        />
        <path
            d="M9.06056 3.96395L4.16291 3.95007C3.84768 4.45891 3.31321 4.81041 2.70501 4.91414V5.65774C2.70501 6.26108 1.72069 6.77056 1.13632 6.92569V10.0761H8.86652C10.5943 10.0761 12 8.74246 12 7.10308V6.22641C12 4.97886 10.9303 3.96395 9.61536 3.96395H9.06056ZM10.5061 6.99342C10.5061 7.78889 9.99787 8.4463 9.18503 8.53776V5.19691H9.61536C10.1065 5.19691 10.5061 5.576 10.5061 6.04193V6.99342Z"
            fill="currentColor"
        />
    </svg>
);

export const BarsAndPubsIcon = (props: IconProps) => (
    <Icon component={BarsAndPubsIconSvg} {...props} />
);
