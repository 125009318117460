import { createSelector } from '@reduxjs/toolkit';
import { HeaderState } from 'store/header/header-store';

export const selectTopSearch = (state: HeaderState) => {
    return state.search;
};

export const selectTopSearchEntities = createSelector(
    selectTopSearch,
    (search) => search.entities,
);

export const selectTopSearchLocations = createSelector(
    selectTopSearch,
    (search) => search.locations,
);

export const selectTopSearchTerm = createSelector(
    selectTopSearch,
    (search) => search.term,
);

export const selectTopSearchIsLoading = createSelector(
    selectTopSearch,
    (search) => search.isLoading,
);

export const selectTopSearchNoResults = createSelector(
    selectTopSearch,
    (search) => search.noResults,
);
