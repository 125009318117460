import React from 'react';
import { SgRetailIcon } from 'features/onboarding/solutions-guide/icons/sg-retail-icon';
import { CHAINS_ROUTE } from 'features/onboarding/solutions-guide/consts/constants';

export const RETAIL_SOLUTIONS_GUIDE = {
    icon: <SgRetailIcon />,
    solutions: [
        {
            title: 'Market Trends',
            description:
                'Find out how different chains are performing to ' +
                'benchmark your performance and identify growing trends.',
            url: CHAINS_ROUTE,
        },
        {
            title: 'Visitor Insights',
            description:
                'Uncover comprehensive demographic and psychographic ' +
                'insights about your visitors at the property or chain level.',
            url: '/insights/venues/f590e5e83642b2c393b461fa/audience',
        },
        {
            title: 'Site Selection & Expansion',
            description:
                'Easily identify ideal sites for a new chain locations ' +
                'based on customizable scoring parameters and filters.',
            url: '/advanced-reports/freemium/site-selection',
        },
        {
            title: 'Performance Monitoring',
            description:
                'Quantify, benchmark, explain, and showcase the performance of new and existing stores.',
            url: '/insights/venues/f590e5e83642b2c393b461fa/overview',
        },
        {
            title: 'Marketing & Advertising',
            description:
                'Better understand where visitors are coming from to ' +
                'optimize ad geotargeting to reach the highest potential areas.',
            url: '/insights/venues/f590e5e83642b2c393b461fa/overview',
        },
        {
            title: 'Competitive Intelligence',
            description:
                'Discover how sales, transactions, and ticket ' +
                'sizes are trending at countless retail chain locations.',
            url: '/insights/venues/f590e5e83642b2c393b461fa/retail-sales',
        },
    ],
};
