/* eslint-disable react/jsx-no-target-blank */
import React, { MouseEvent, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { useQueryParams } from 'hooks/use-query/use-query';
import { telemetryApiService } from 'API/telemetry-api/telemetry-api';
import styles from './placer-link.module.scss';

export type PathDetails = {
    pathname: string;
    search?: string;
};

type PlacerLinkProps = {
    to: PathDetails;
    className?: string;
    basename?: string;
    isAnchorTag?: boolean;
    onLinkClick?: (e: MouseEvent) => void;
    inherit?: boolean;
    testId?: string;
    openInNewTab?: boolean;
};

const OverviewTabText = 'Overview';

export const PlacerLink = ({
    to,
    children,
    className,
    onLinkClick,
    inherit = false,
    isAnchorTag = false,
    testId,
    basename = '',
    openInNewTab = false,
}: PropsWithChildren<PlacerLinkProps>) => {
    const { pathname, search } = to;
    const location = useLocation();
    const isReactLink = !pathname.includes('#!/admin');
    const queryParams = useQueryParams();
    const linkClassNames = classNames(styles.link, className);

    if (!pathname) {
        return <>{children}</>;
    }

    if (isReactLink) {
        const targetTo = {
            pathname: pathname,
            search: search || (inherit ? queryParams : ''),
            state: location?.state,
        };

        const handleLinkClick = (e: React.MouseEvent<HTMLElement>) => {
            if (
                document.location.href.includes('insights') &&
                e.currentTarget.innerHTML.includes(OverviewTabText)
            ) {
                telemetryApiService.reportStartEvent('switchSidePanelTabLoadingTime');
            }
        };

        if (isAnchorTag) {
            const { pathname, search } = targetTo;
            return (
                <a
                    data-testid={testId}
                    href={`${basename}${pathname}/${search}`}
                    className={linkClassNames}
                    onClick={handleLinkClick}
                >
                    {children}
                </a>
            );
        }

        return (
            <Link
                onClick={handleLinkClick}
                data-testid={testId}
                to={targetTo}
                target="_self"
                className={linkClassNames}
            >
                {children}
            </Link>
        );
    }

    const insightsPath = `${pathname}${search || (inherit ? '?' + queryParams : '')}`;

    const onClick = (e: MouseEvent) => {
        if (onLinkClick) {
            onLinkClick(e);
            return;
        }

        if (e.ctrlKey || e.metaKey) {
            return;
        }

        e.preventDefault();
        if (
            document.location.href.includes('insights') &&
            (e.target as any).innerHTML.includes('Overview')
        ) {
            telemetryApiService.reportStartEvent('switchSidePanelTabLoadingTime');
        }
        window.location.href = insightsPath;
    };

    return (
        <a
            className={linkClassNames}
            data-testid={testId}
            {...(openInNewTab
                ? {
                      rel: 'noreferrer',
                      target: '_blank',
                  }
                : {})}
            onClick={!openInNewTab ? onClick : undefined}
            href={insightsPath}
        >
            {children}
        </a>
    );
};
