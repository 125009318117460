import { createAsyncThunk } from '@reduxjs/toolkit';

import { ChainsReportState } from 'features/industry/store/chains-store';
import {
    openChainsSearchSection,
    selectChainsSearchIsOpen,
} from 'features/industry/store/chains-report-search-store';

export const focusSearchAutoComplete = createAsyncThunk<void, void, { state: ChainsReportState }>(
    'industry/focus-search-auto-complete',
    async (_, { dispatch, getState }) => {
        const state = getState();
        const searchIsOpened = selectChainsSearchIsOpen(state);

        if (!searchIsOpened) {
            dispatch(openChainsSearchSection());
        }
    },
);
