import queryString, { StringifiableRecord } from 'query-string';

import { fetchWrapper } from 'API/authentification/fetch-wrapper';
import { Region } from 'core/dto/region';
import type { Area, AreaType, PlacerArrayResponse } from '@placer-ui/types';

type RegionTypes = Exclude<AreaType, 'nationwide'>;

const responsesCache: Record<RegionTypes, any> = {
    cbsa: {},
    dma: {},
    state: {},
    custom: {},
};

export interface RegionsAPIParams extends StringifiableRecord {
    limit?: number;
    query?: string;
    type?: string[];
    skip?: number;
    return_geojson?: boolean;
}

export const regionsAPI = {
    async getRegions(params: RegionsAPIParams): PlacerArrayResponse<Region[]> {
        const queryParams = queryString.stringify(params, { arrayFormat: 'comma' });
        const url = `/regions?${queryParams}`;

        return await fetchWrapper({ targetUrl: url });
    },
    // consider using src/ui-components/state-selector/all-states-list.ts
    async getRegionData(regionType: RegionTypes, regionCode: string): PlacerArrayResponse<Area> {
        if (responsesCache[regionType][regionCode]) {
            return Promise.resolve(responsesCache[regionType][regionCode]);
        }

        const response = await fetchWrapper({ targetUrl: `/regions/${regionType}/${regionCode}` });
        responsesCache[regionType][regionCode] = response;

        return response;
    },
};
