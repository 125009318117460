import type { Place } from '@placer-ui/types';
import circleToPolygon from 'circle-to-polygon';
import { PlaceWithFilters } from 'features/insights/store/poi/poi-types';
import { feetToMeters } from 'ui-components/google-map/utils';

export const createPlaceWithNearbyResult = <T extends Place>(
    place: T,
    nearbyRadius = 250,
): PlaceWithFilters => {
    const geojson = circleToPolygon(
        //@ts-expect-error PLAC-47814
        [place.geolocation.lng, place.geolocation.lat],
        feetToMeters(nearbyRadius),
        64,
    );

    return {
        ...place,
        geojson,
        filter: {
            config: {
                ft_radius: nearbyRadius,
            },
        },
    };
};
