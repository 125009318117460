import { POI_COLORS, PLACE_COLORS, PRIMARY_COLOR } from 'core/constants/place-colors';

export const APP_CONSTS = {
    // TODO: rename LOCAL_STORAGE_KEY to LOCAL_STORAGE_USER_KEY in scope of PLAC-8606
    configuration: {
        DEFAULT_VIEW_DATE: '2017-09-01',
        MINIMAL_DATE: '2017-01-01',
        MINIMAL_AVERAGE_FOOT_TRAFFIC_FOR_DAILY_VIEW: 1000,
        LOCAL_STORAGE_KEY: 'PLAC_USER',
        WINDOW_HEIGHT_LIMIT: 800,
        WINDOW_WIDTH_LIMIT: 1400,
        MILE: 1609.344,
    },

    units: {
        SQ_FT: 10.764,
    },

    localStorage: {
        TAGS: 'PLAC_TAGS',
        SALT: 'PLAC_SALT',
    },
    printState: {
        WIDGET_READY: 'WIDGET_READY',
        storeKey: 'PL_PRINT_',
    },
    color: {
        venues: PLACE_COLORS.main,
        darker: {
            venues: PLACE_COLORS.darker,
        },
        lighter: {
            venues: PLACE_COLORS.lighter,
        },
        mapLegend: POI_COLORS,
        palettes: [
            ['#f567f5', '#fda467', '#676dfd'],
            ['#42eaf8', '#548e66', '#ef5771'],
            ['#f567f5', '#fda467', '#55b2a7'],
            ['#f567f5', '#548e66', '#ffbe5e'],
            ['#f567f5', '#548e66', '#4E5136'],
            ['#f567f5', '#fda467', '#B05420'],
            ['#f567f5', '#548e66', '#6FA2CE'],
            ['#f567f5', '#fda467', '#D6AC84'],
        ],
        neutralVenue: '#49565d',
        customPOI: POI_COLORS.custom,
        customPOIPolygon: '#ffffff',
        brandColor: PRIMARY_COLOR,
        darkBrandColor: PLACE_COLORS.darker[0],
        closedPOI: POI_COLORS.closed,
        flaggedPOI: POI_COLORS.flagged,
        focusedFlaggedPOI: '#ffffff',
        markerIcon: '#ffffff',
        markerIconInverted: '#000000',
        grey: '#49565d',
        legend: ['#6a71e5', '#48dc6b', '#ffd201', '#ffa500', '#ff4966'],
        segments: ['#B1B4F9', '#9A9DF3', '#8589EF', '#7176EC', '#5E63E4'],
        list: [
            '#DC3912',
            '#FF9900',
            '#109618',
            '#990099',
            '#DD4477',
            '#66AA00',
            '#B82E2E',
            '#994499',
            '#22AA99',
            '#AAAA11',
            '#6633CC',
            '#E67300',
            '#8B0707',
            '#329262',
            '#1DE9B6',
            '#76FF03',
            '#FFEA00',
            '#4E342E',
            '#00B0FF',
        ],
    },

    locationType: {
        PRIOR: 'PRIOR',
        POST: 'POST',
        FAVORITE: 'FAVORITE',
        ZIP_CODE: 'ZIP_CODE',
        TOP: 'TOP',
        COMPETITOR: 'COMPETITOR',
    },

    venues: {
        types: {
            VENUE: 'venue',
            COMPLEX: 'complex',
            BILLBOARD: 'billboard',
            CHAIN: 'chain',
            ZIP_CODE: 'zipcode',
        },
        collection: {
            VENUES: 'venues',
            BILLBOARDS: 'billboards',
            CHAINS: 'chains',
            COMPLEXES: 'complexes',
        },
        area: {
            types: {
                CITY: 'city',
                NATIONWIDE: 'nationwide',
            },
        },
        categories: {
            OTHER: 'other',
            HOME: 'home',
            WORK: 'work',
            BILLBOARD: 'Billboard',
        },
    },

    ethnicity: {
        AFRICAN_AMERICAN: 'African American',
        WHITE: 'White',
        HISPANIC: 'Hispanic',
        ASIAN: 'Asian',
    },

    brands: {
        FOOD: 'food',
        FASHION: 'fashion',
        CARS: 'autos',
    },

    date: {
        format: {
            FULL: 'YYYY-MM-DD',
            NAME: 'MMMM, YYYY',
            SHORT_NAME: 'MMM DD',
            MONTH_DOT_FULL_YEAR: 'MM.YYYY',
            DATEPICKER: 'MMM D, YYYY',
            MOMENT_GRAPH_TITLE: 'ddd, MMMM Do',
            MOMENT_GRAPH_X: 'MMM Do',
            PRETTY: 'MMM Do, YYYY',
            BASE_GRAPH: 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ [(IDT)]',
        },
        keys: {
            LAST_12_MONTHS: 'last_full_12_months',
            LAST_6_MONTHS: 'last_full_6_months',
            LAST_3_MONTHS: 'last_full_3_months',
            LAST_7_DAYS: 'last_7_days',
            PAST_7_DAYS: 'past_7_days',
            LAST_FULL_12_MONTHS: 'last_12_months',
            LAST_FULL_6_MONTHS: 'last_6_months',
            LAST_FULL_3_MONTHS: 'last_3_months',
            YEAR_TO_DATE: 'year_to_date',
        },
        labels: {
            LAST_12_MONTHS: 'Last full 12 months',
            LAST_6_MONTHS: 'Last full 6 months',
            LAST_3_MONTHS: 'Last full 3 months',
            LAST_7_DAYS: 'Past 7 days',
            PAST_7_DAYS: 'Past 7 days',
            LAST_FULL_12_MONTHS: 'Last full 12 months',
            LAST_FULL_6_MONTHS: 'Last full 6 months',
            LAST_FULL_3_MONTHS: 'Last full 3 months',
            YEAR_TO_DATE: 'Year to Date',
        },
    },

    filter: {
        venues: {
            ALL: 'all_venues',
            EXPLICIT: 'explicit',
        },
        attributions: {
            genderMap: {
                female: 'Female',
                male: 'Male',
            },
            keys: {
                AUDIENCE_TYPE: 'visits_type',
                DATA_SOURCE: 'data_source',
                DEMOGRAPHICS: 'demographics',
                GENDER: 'gender',
                AGE: 'age',
                HOUSEHOLD_INCOME: 'household_income',
                HOME_DISTANCE: 'home_distance',
                WORK_DISTANCE: 'work_distance',
                DAYS_OF_WEEK: 'days_of_week',
                LENGTH_OF_STAY: 'visit_duration',
                TIME_OF_DAY: 'time',
                VISIT_FREQUENCY: 'visit_frequency',
            },
            labels: {
                AUDIENCE_TYPE: 'Audience Type',
                DATA_SOURCE: 'Data Source',
                DEMOGRAPHICS: 'Demographics',
                DEMOGRAPHICS_V2: 'Demographics (CBG)',
                GENDER: 'Gender',
                AGE: 'Age',
                HOUSEHOLD_INCOME: 'Household Income',
                HOUSEHOLD_INCOME_V2: 'Median HH Income',
                HOME_DISTANCE: 'Home Distance',
                WORK_DISTANCE: 'Work Distance',
                LESS_THEN: 'less than',
                MORE_THEN: 'more than',
                BETWEEN: 'between',
                DAYS_OF_WEEK: 'Days of Week',
                LENGTH_OF_STAY: 'Length of Stay',
                VISIT_DURATION: 'Visit Duration',
                TIME_OF_DAY: 'Time of Day',
                VISIT_FREQUENCY: 'Visit Frequency',
            },
        },
        operators: {
            ALL: 'all',
            IN: 'in',
            EQUAL: '==',
            BETWEEN: 'between',
            LESS_THEN: '<',
            MORE_THEN: '>',
            LESS_THEN_OR_EQUAL: '<=',
            MORE_THEN_OR_EQUAL: '>=',
        },
    },

    historyVisits: {
        granule: {
            DAYS: 'day',
            WEEKS: 'week',
            MONTHS: 'month',
        },
    },

    broadcasts: {
        venues: {
            UPDATED: 'Venues:UPDATED',
            READY: 'Venues:READY',
            ERROR: 'Venues:ERROR',
            UPDATED_NEARBY: 'Venues:UPDATED_NEARBY',
            ERROR_NEARBY: 'Venues:ERROR_NEARBY',
            ERROR_NEARBY_FLOW: 'Venues:ERROR_NEARBY_FLOW',
            ERROR_CUSTOM_POI: 'Venues:ERROR_CUSTOM_POI',
            ERROR_CUSTOM_POI_RESTRICTED_FLOW: 'Venues:ERROR_CUSTOM_POI_RESTRICTED_FLOW',
            REMOVED: 'Venues:REMOVED',
            LOADING: 'Venues:LOADING',
        },
        visitDurationFilter: {
            openPropertyFiltersInDurationSection: 'open_custom_filters_modal',
            updateDurationFilterState: 'update_duration_filter_state',
        },
    },

    holidays: {
        '2016-09-05': 'Labor Day',
        '2016-11-23': 'Thanksgiving Eve',
        '2016-11-24': 'Thanksgiving Day',
        '2016-11-25': 'Black Friday / Day after Thanksgiving',
        '2016-12-24': 'Christmas Eve',
        '2016-12-25': 'Christmas Day',
        '2016-12-26': 'Day after Christmas',
        '2016-12-31': 'New Year\'s Eve',
        '2017-01-01': 'New Year\'s',
        '2017-02-09': 'February 2017 North American blizzard',
        '2017-03-14': 'March 2017 North American blizzard',
        '2017-04-16': 'Easter Sunday',
        '2017-05-14': 'Mother\'s Day',
        '2017-05-29': 'Memorial Day',
        '2017-06-18': 'Father\'s Day',
        '2017-07-04': 'Independence Day',
        '2017-09-04': 'Labor Day',
        '2017-11-11': 'Veterans Day',
        '2017-11-23': 'Thanksgiving Eve',
        '2017-11-24': 'Black Friday / Thanksgiving Day',
        '2017-11-27': 'Cyber Monday',
        '2017-12-24': 'Christmas Eve',
        '2017-12-25': 'Christmas Day',
    },

    mapbox: {
        SYMBOL_LABEL: 'waterway-label',
    },

    geoJson: {
        types: {
            POLYGON: 'Polygon',
            MULTI_POLYGON: 'MultiPolygon',
        },
    },

    state: {
        READY: 'ready',
        START: 'start',
        ERROR: 'error',
        WARNING: 'warning',
    },

    error: {
        INVALID_ARGUMENTS: 'INVALID_ARGUMENTS',
        PERMISSION_DENIED: 'PERMISSION_DENIED',
        INSUFFICIENT_DATA: 'INSUFFICIENT_DATA',
        RESTRICTED_INSUFFICIENT_DATA: 'RESTRICTED_INSUFFICIENT_DATA',
        NOT_FOUND: 'ERROR_NOT_FOUND',
        ERR_IN_PROGRESS: 'ERR_IN_PROGRESS',
        UNKNOWN_ERROR: 'UNKNOWN_ERROR',
        POLYGON_SIZE_SMALL: 'POLYGON_SIZE_SMALL',
        POLYGON_SIZE_BIG: 'POLYGON_SIZE_BIG',
        POLYGON_INTERSECTING: 'POLYGON_INTERSECTING',
        IGNORE_REJECT: 'IGNORE_REJECT',
        LOYALTY_FILTER_INVALID_PANEL_SIZE: 'LOYALTY_FILTER_INVALID_PANEL_SIZE',
        LOYALTY_FILTER_CHAINS_UNSUPPORTED: 'LOYALTY_FILTER_CHAINS_UNSUPPORTED',
        NEARBY_ACTIVITY_UNAVAILABLE: 'NEARBY_ACTIVITY_UNAVAILABLE',
        UNDELIVERABLE_ENTITY: 'UNDELIVERABLE_ENTITY',
    },

    unsupported: {
        CHAIN: 'CHAIN_IS_UNSUPPORTED',
        DATE: 'DATE_IS_UNSUPPORTED',
    },

    devices: {
        PHONE: 'phone',
        TABLET: 'tablet',
        DESKTOP: 'desktop',
    },

    data: {
        type: {
            PERCENTAGE: 'percentage',
            ABS_NUMBER: 'number',
        },
    },

    print: {
        components: {
            TOP_FLOW: 'top-flow',
            PRIOR_POST_LOCATIONS: 'prior_post_list',
            VISITS_TREND: 'visits-trend',
            DAYS_OF_WEEK: 'days-of-weak',
            HOURLY_VISITS: 'hourly-visits',
            HOUSEHOLD_INCOME: 'household-income',
            LENGTH_OF_STAY: 'length-of-stay',
            ROUTES: 'routes',
            TOP_TOOLBAR: 'top-toolbar',
            OVERVIEW_METRICS: 'overview_metrics',
            TRADE_AREA_HEATMAP: 'trade-area-heatmap',
            FAVORITE_LOCATIONS: 'favorite-locations',
            PRIOR_POST_LOCATIONS_MAP: 'prior-post-locations-map',
            RANKING_OVERVIEW: 'ranking-overview',
            RANKING_INDEX: 'ranking-index',
            PERCENTILE_RANK: 'percentile-rank',
            VISITOR_BY_ORIGIN: 'visitor-by-origin',
        },
        settingsModels: {
            SHOW_LOGO: 'logo',
            SHOW_DESCRIPTION: 'description',
            SHOW_VENUE_TITLE: 'venue_title',
            SHOW_MAP: 'show_map',
            SHOW_MAP_LEGEND: 'show_map_legend',
            SHOW_GENDER: 'show_gender',
            SHOW_ETHNICITY: 'show_ethnicity',
            SHOW_HOUSEHOLD_INCOME: 'show_household_income',
            SHOW_MARGIN_OF_ERROR: 'show_margin_of_error',
            SHOW_ZIP_CODES: 'show_zip_codes',
            SHOW_RANKING_NATIONWIDE: 'nationwide',
            SHOW_RANKING_STATE: 'state',
            SHOW_RANKING_LOCAL: 'local',
            SHOW_RANKING_SELECTED_REGION: 'region',
            SHOW_TOP_RANKED_VENUES: 'show_top_ranked_venues',
        },
    },

    views: {
        OVERVIEW: 'overview',
        VISITS: 'visits',
        FLOW: 'flow',
        AUDIENCE: 'audience',
        TRADE_AREA: 'trade-area',
        RANKING: 'ranking',
        LOYALTY: 'loyalty',
    },

    search: {
        types: {
            REGION: 'regions',
            POI: 'venue',
            BRAND: 'brands',
        },
    },

    sharedWith: {
        ACCOUNT: 'account',
        PUBLIC: 'public',
    },

    // copied controlPosition of Google maps
    map: {
        controlPosition: {
            TOP_LEFT: 1,
            TOP_CENTER: 2,
            TOP: 2,
            TOP_RIGHT: 3,
            LEFT_CENTER: 4,
            LEFT_TOP: 5,
            LEFT: 5,
            LEFT_BOTTOM: 6,
            RIGHT_TOP: 7,
            RIGHT: 7,
            RIGHT_CENTER: 8,
            RIGHT_BOTTOM: 9,
            BOTTOM_LEFT: 10,
            BOTTOM_CENTER: 11,
            BOTTOM: 11,
            BOTTOM_RIGHT: 12,
            CENTER: 13,
        },
        fitBounds: {
            ONCE: 'once',
        },
        styles: {
            GOOGLE: [
                {
                    featureType: 'poi',
                    elementType: 'all',
                    stylers: [{ visibility: 'on' }],
                },
            ],
            GOOGLE_CLEAN: [
                {
                    featureType: 'poi',
                    elementType: 'all',
                    stylers: [{ visibility: 'off' }],
                },
                {
                    featureType: 'road',
                    elementType: 'geometry.fill',
                    stylers: [
                        {
                            color: '#000000',
                        },
                    ],
                },
                {
                    featureType: 'road',
                    elementType: 'geometry.stroke',
                    stylers: [
                        {
                            color: '#ffffff',
                        },
                        {
                            visibility: 'on',
                        },
                    ],
                },
            ],
            PLACER: [
                {
                    featureType: 'administrative',
                    elementType: 'all',
                    stylers: [{ visibility: 'on' }],
                },
                {
                    featureType: 'administrative',
                    elementType: 'labels',
                    stylers: [{ weight: '1.00' }],
                },
                {
                    featureType: 'administrative',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#858383' }],
                },
                {
                    featureType: 'administrative.neighborhood',
                    elementType: 'labels',
                    stylers: [{ visibility: 'off' }],
                },
                {
                    featureType: 'landscape',
                    elementType: 'all',
                    stylers: [{ color: '#f3f3f3' }, { visibility: 'on' }],
                },
                {
                    featureType: 'landscape',
                    elementType: 'labels.text',
                    stylers: [{ visibility: 'on' }],
                },
                {
                    featureType: 'landscape',
                    elementType: 'labels.text.fill',
                    stylers: [{ visibility: 'on' }, { color: '#ffffff' }],
                },
                {
                    featureType: 'landscape',
                    elementType: 'labels.text.stroke',
                    stylers: [{ visibility: 'off' }],
                },
                {
                    featureType: 'poi.attraction',
                    elementType: 'all',
                    stylers: [{ visibility: 'off' }],
                },
                {
                    featureType: 'poi.attraction',
                    elementType: 'labels',
                    stylers: [{ visibility: 'off' }],
                },
                {
                    featureType: 'poi.business',
                    elementType: 'all',
                    stylers: [{ visibility: 'off' }],
                },
                {
                    featureType: 'poi.business',
                    elementType: 'labels',
                    stylers: [{ visibility: 'off' }],
                },
                {
                    featureType: 'poi.government',
                    elementType: 'all',
                    stylers: [{ visibility: 'off' }],
                },
                {
                    featureType: 'poi.government',
                    elementType: 'labels',
                    stylers: [{ visibility: 'off' }],
                },
                {
                    featureType: 'poi.medical',
                    elementType: 'all',
                    stylers: [{ visibility: 'off' }],
                },
                {
                    featureType: 'poi.medical',
                    elementType: 'labels',
                    stylers: [{ visibility: 'off' }],
                },
                {
                    featureType: 'poi.park',
                    elementType: 'geometry.fill',
                    stylers: [{ visibility: 'on' }, { color: '#d8e9d9' }],
                },
                {
                    featureType: 'poi.park',
                    elementType: 'labels',
                    stylers: [{ visibility: 'off' }],
                },
                {
                    featureType: 'poi.place_of_worship',
                    elementType: 'all',
                    stylers: [{ visibility: 'off' }],
                },
                {
                    featureType: 'poi.place_of_worship',
                    elementType: 'labels',
                    stylers: [{ visibility: 'off' }],
                },
                {
                    featureType: 'poi.school',
                    elementType: 'all',
                    stylers: [{ visibility: 'off' }],
                },
                {
                    featureType: 'poi.school',
                    elementType: 'labels',
                    stylers: [{ visibility: 'off' }],
                },
                {
                    featureType: 'poi.sports_complex',
                    elementType: 'all',
                    stylers: [{ visibility: 'off' }],
                },
                {
                    featureType: 'poi.sports_complex',
                    elementType: 'labels',
                    stylers: [{ visibility: 'off' }],
                },
                {
                    featureType: 'road',
                    elementType: 'all',
                    stylers: [{ saturation: -100 }, { lightness: 45 }],
                },
                {
                    featureType: 'road.highway',
                    elementType: 'all',
                    stylers: [{ visibility: 'simplified' }],
                },
                {
                    featureType: 'road.arterial',
                    elementType: 'labels.icon',
                    stylers: [{ visibility: 'off' }],
                },
                {
                    featureType: 'road.local',
                    elementType: 'all',
                    stylers: [{ visibility: 'on' }],
                },
                {
                    featureType: 'transit',
                    elementType: 'all',
                    stylers: [{ visibility: 'off' }],
                },
                {
                    featureType: 'water',
                    elementType: 'all',
                    stylers: [{ color: '#aacbff' }, { visibility: 'on' }],
                },
                {
                    featureType: 'water',
                    elementType: 'labels.text',
                    stylers: [{ visibility: 'on' }, { color: '#ffffff' }],
                },
            ],
            PLACER_HEATMAP: [
                {
                    featureType: 'administrative',
                    stylers: [
                        {
                            visibility: 'on',
                        },
                    ],
                },
                {
                    featureType: 'administrative',
                    elementType: 'labels',
                    stylers: [
                        {
                            weight: 1,
                        },
                    ],
                },
                {
                    featureType: 'administrative',
                    elementType: 'labels.text.fill',
                    stylers: [
                        {
                            color: '#333333',
                        },
                    ],
                },
                {
                    featureType: 'administrative.locality',
                    elementType: 'labels.text.fill',
                    stylers: [
                        {
                            color: '#333333',
                        },
                    ],
                },
                {
                    featureType: 'administrative.locality',
                    elementType: 'labels.text.stroke',
                    stylers: [
                        {
                            color: '#ffffff',
                        },
                    ],
                },
                {
                    featureType: 'landscape',
                    stylers: [
                        {
                            visibility: 'on',
                        },
                    ],
                },
                {
                    featureType: 'landscape',
                    elementType: 'labels.text',
                    stylers: [
                        {
                            visibility: 'on',
                        },
                    ],
                },
                {
                    featureType: 'landscape',
                    elementType: 'labels.text.fill',
                    stylers: [
                        {
                            color: '#ffffff',
                        },
                        {
                            visibility: 'on',
                        },
                    ],
                },
                {
                    featureType: 'landscape',
                    elementType: 'labels.text.stroke',
                    stylers: [
                        {
                            visibility: 'off',
                        },
                    ],
                },
                {
                    featureType: 'poi.attraction',
                    stylers: [
                        {
                            visibility: 'off',
                        },
                    ],
                },
                {
                    featureType: 'poi.business',
                    stylers: [
                        {
                            visibility: 'off',
                        },
                    ],
                },
                {
                    featureType: 'poi.business',
                    elementType: 'labels',
                    stylers: [
                        {
                            visibility: 'off',
                        },
                    ],
                },
                {
                    featureType: 'poi.government',
                    stylers: [
                        {
                            visibility: 'off',
                        },
                    ],
                },
                {
                    featureType: 'poi.medical',
                    stylers: [
                        {
                            visibility: 'off',
                        },
                    ],
                },
                {
                    featureType: 'poi.medical',
                    elementType: 'labels',
                    stylers: [
                        {
                            visibility: 'off',
                        },
                    ],
                },
                {
                    featureType: 'poi.park',
                    stylers: [
                        {
                            visibility: 'off',
                        },
                    ],
                },
                {
                    featureType: 'poi.park',
                    elementType: 'geometry.fill',
                    stylers: [
                        {
                            color: '#d8e9d9',
                        },
                    ],
                },
                {
                    featureType: 'poi.place_of_worship',
                    stylers: [
                        {
                            visibility: 'off',
                        },
                    ],
                },
                {
                    featureType: 'poi.place_of_worship',
                    elementType: 'labels',
                    stylers: [
                        {
                            visibility: 'off',
                        },
                    ],
                },
                {
                    featureType: 'poi.school',
                    stylers: [
                        {
                            visibility: 'off',
                        },
                    ],
                },
                {
                    featureType: 'poi.school',
                    elementType: 'labels',
                    stylers: [
                        {
                            visibility: 'off',
                        },
                    ],
                },
                {
                    featureType: 'poi.sports_complex',
                    stylers: [
                        {
                            visibility: 'off',
                        },
                    ],
                },
                {
                    featureType: 'poi.sports_complex',
                    elementType: 'labels',
                    stylers: [
                        {
                            visibility: 'off',
                        },
                    ],
                },
                {
                    featureType: 'road',
                    stylers: [
                        {
                            saturation: -100,
                        },
                        {
                            lightness: 45,
                        },
                    ],
                },
                {
                    featureType: 'road',
                    elementType: 'geometry.fill',
                    stylers: [
                        {
                            color: '#ffffff',
                        },
                    ],
                },
                {
                    featureType: 'road',
                    elementType: 'geometry.stroke',
                    stylers: [
                        {
                            color: '#b5b5b5',
                        },
                        {
                            visibility: 'on',
                        },
                    ],
                },
                {
                    featureType: 'road',
                    elementType: 'labels.text.fill',
                    stylers: [
                        {
                            color: '#636363',
                        },
                    ],
                },
                {
                    featureType: 'road.arterial',
                    elementType: 'labels.icon',
                    stylers: [
                        {
                            visibility: 'off',
                        },
                    ],
                },
                {
                    featureType: 'transit',
                    stylers: [
                        {
                            visibility: 'off',
                        },
                    ],
                },
                {
                    featureType: 'water',
                    stylers: [
                        {
                            color: '#aacbff',
                        },
                        {
                            visibility: 'on',
                        },
                    ],
                },
                {
                    featureType: 'water',
                    elementType: 'labels.text',
                    stylers: [
                        {
                            color: '#ffffff',
                        },
                        {
                            visibility: 'on',
                        },
                    ],
                },
            ],
        },
    },
    otherReports: {
        'admin-panel.property.insights.starred-reports': true,
        'admin-panel.property.insights.void-analysis-freemium': true,
        'admin-panel.property.insights.visual-templates': {
            showInsightsFullScreen: true,
        },
        'admin-panel.property.insights.infographics': {
            showInsightsFullScreen: true,
        },
    },
    startupPageByKey: {
        '00_explore': 'admin-panel.explore',
        '01_my_zone': 'admin-panel.my-zone-react',
        '02_property': 'admin-panel.property.insights.overview',
        '03_chains': 'admin-panel.chains',
        explore: 'admin-panel.explore',
        my_zone: 'admin-panel.my-zone-react',
        property: 'admin-panel.property.insights.overview',
        chains: 'admin-panel.chains',
    },
    // eslint-disable-next-line max-len
    hubspotUrl: `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.REACT_APP_HUBSPOT_PORTAL_ID}/b377922f-d01f-4b54-b9f5-2c64e618bdcb`,
};
