import type { ExternalVenueProviders, PlaceAddress, VenuePlaceTypes } from '@placer-ui/types';
import type { GeoJSON } from 'geojson';
import { createdPOIsAPI } from 'API/my-zone-api';

interface createCustomPOIProps {
    address: PlaceAddress;
    category: string;
    geojson?: GeoJSON;
    name: string;
    provider: ExternalVenueProviders;
    signal?: AbortSignal;
    sub_category: string;
    type: VenuePlaceTypes;
}

export const createCustomPOI = async ({
    address,
    category,
    geojson,
    name,
    provider,
    sub_category,
    type,
    signal,
}: createCustomPOIProps) => {
    const res = await createdPOIsAPI.createPOI({
        address,
        category,
        geojson,
        name,
        provider,
        sub_category,
        type,
        signal,
    });

    await createdPOIsAPI.statusPOI({
        id: res.id,
        type: res.type,
    });

    return res;
};
