export function createTimeOptions() {
    const options = [
        {
            value: '00:00',
            label: '12:00 am',
        },
    ];

    for (let ham = 1; ham < 12; ++ham) {
        const hour = ('0' + ham).substr(-2) + ':00';
        options.push({
            value: hour,
            label: hour + ' am',
        });
    }

    options.push({
        value: '12:00',
        label: '12:00 pm',
    });

    for (let hpm = 1; hpm < 12; ++hpm) {
        options.push({
            value: 12 + hpm + ':00',
            label: ('0' + hpm).substr(-2) + ':00 pm',
        });
    }

    options.push({
        value: '23:59',
        label: '11:59 pm',
    });

    return options;
}
