import type { Place } from '@placer-ui/types';

export const isAddressType = (poi: Pick<Place, 'provider_data'>) => {
    return poi.provider_data?.entity_type === 'address';
};

export const getPlacePoiProvider = (poi: Pick<Place, 'provider_data'>) => {
    return (poi.provider_data && poi.provider_data.provider) || 'placer';
};

export const isPlaceANonPlacerPoi = (poi: Pick<Place, 'provider_data'>) => {
    return getPlacePoiProvider(poi) !== 'placer';
};
