import { usePoiSelectionContext } from 'features/poi-selection-map/context/poi-selection-context';
import { useSearchPoiContext } from 'features/poi-selection-map/poi-selection-menu/menu-items/search-menu-item/context/search-poi-context';
import { useSetMapOptionsWithOffset } from 'features/poi-selection-map/hooks/poi-selection-map-hooks';
import { useCallback } from 'react';
import { defaultZoom } from 'ui-components/google-map/google-map-default-options';
import {
    useSearchApiRequest,
    useUpdateSearchApiFilters,
} from 'features/poi-selection-map/poi-selection-menu/menu-items/search-menu-item/hooks/search-poi-hooks';
import { PlacePois } from 'features/poi-selection-map/types/poi-selection-types';
import type { Geolocation, Venue } from '@placer-ui/types';

export const useOnSearchOptionSelected = () => {
    const { setPoiActiveId } = usePoiSelectionContext();
    const { setSelectedSearchSuggestion } = useSearchPoiContext();
    const apiRequest = useSearchApiRequest();
    const setMapConfigWithOffset = useSetMapOptionsWithOffset();
    const setSearchTermSearch = useSetSearchTermSearch();
    const setCenter = useCallback(
        (center: Geolocation) => {
            setMapConfigWithOffset({
                zoom: defaultZoom,
                center,
            });
        },
        [setMapConfigWithOffset],
    );
    const searchById = useCallback(
        async (id: string) => {
            const { data: searchInfo } = await apiRequest({
                term: id,
            });
            return searchInfo;
        },
        [apiRequest],
    );

    return useCallback(
        async (entity: Venue) => {
            const { id, geolocation } = entity;
            id && setPoiActiveId(id);

            try {
                if (!geolocation) {
                    const searchInfo = await searchById(id);
                    if (!searchInfo.length) {
                        // no venue info found, search for it explicitly
                        setSearchTermSearch(entity.name);
                        return;
                    }

                    const {
                        info: {
                            //@ts-expect-error PLAC-47814
                            geolocation: { lat, lng },
                        },
                    } = searchInfo[0];
                    setCenter({
                        lat,
                        lng,
                    });
                    setSelectedSearchSuggestion(searchInfo[0]);
                } else {
                    setCenter({
                        lat: geolocation.lat,
                        lng: geolocation.lng,
                    });
                    const searchInfo = await searchById(id);
                    setSelectedSearchSuggestion(searchInfo[0]);
                }
            } catch (error) {
                console.log(error);
            }
        },
        [searchById, setCenter, setPoiActiveId, setSearchTermSearch, setSelectedSearchSuggestion],
    );
};

export const useSetSelectedSearchSuggestionInResults = () => {
    const { selectedSearchSuggestion } = useSearchPoiContext();
    return useCallback(
        (results: PlacePois, resultsLimit: number): PlacePois => {
            if (selectedSearchSuggestion) {
                const idx = results.findIndex(
                    (poi) => poi.info.id === selectedSearchSuggestion.info.id,
                );
                if (idx > -1) {
                    //selectedSearchSuggestion was found in search results
                    results.splice(idx, 1);
                } else {
                    if (results.length === resultsLimit) {
                        results.pop();
                    }
                }
                //move selectedSearchSuggestion to first position
                results.unshift(selectedSearchSuggestion);
            }
            return results;
        },
        [selectedSearchSuggestion],
    );
};

export const useSetSearchTermSearch = () => {
    const { setCurrentSearchTerm } = useSearchPoiContext();
    const updateSearchApiFilters = useUpdateSearchApiFilters();

    return useCallback(
        (term: string) => {
            setCurrentSearchTerm(term);
            updateSearchApiFilters({
                term,
            });
        },
        [setCurrentSearchTerm, updateSearchApiFilters],
    );
};
