import { salesForecastSlice } from 'features/sales-forecast/store/slice';
import { siteSelectionSlice } from 'features/site-selection/store/slice';
import { voidAnalysisSlice } from 'features/void-analysis/store/slice';
import { RouteInitializerProps } from 'router';

export const advancedReportsReducer = {
    salesForecast: salesForecastSlice.reducer,
    siteSelection: siteSelectionSlice.reducer,
    voidAnalysis: voidAnalysisSlice.reducer,
};

export const advancedReportsStoreInitializer = ({ dispatch }: RouteInitializerProps) => {
    dispatch(salesForecastSlice.actions.resetState());
    dispatch(siteSelectionSlice.actions.resetState());
    dispatch(voidAnalysisSlice.actions.resetState());
};
