import type { MapLayerPOIType } from 'ui-components/google-map-layers/utils/map-layers-entity-color';

export const MAP_BOUNDS_CHANGE_DEBOUNCE = 500;

export const MAX_SEARCH_RESULTS = 100;

export const MAX_MAP_ZOOM = 22;

export const MAP_BOUNDS_PADDING = 50;

export enum POI_TYPE_LABELS {
    AVAILABLE = 'Available',
    CUSTOM = 'Custom POI',
    UNVERIFIED = 'Unverified',
    NEARBY_ACTIVITY = 'Nearby Activity',
    CLOSED = 'Closed',
}

export const ENTITY_PROFILES: MapLayerPOIType[] = ['available', 'nearby_activity', 'custom', 'closed'];
