import { useContext } from 'react';
import {
    ExploreActionContext,
    ExploreContext,
} from 'features/explore/context/explore-ui-state-provider';

export const useExploreUIState = () => {
    return useContext(ExploreContext);
};

export const useExploreUIStateDispatch = () => {
    return useContext(ExploreActionContext);
};
