import { telemetryApiService } from 'API/telemetry-api/telemetry-api';
import { startOpenTelemetrySpan } from 'tracing/tracing';

export const searchAPIMeasureWrapper = async (promise: Promise<any>, source?: string) => {
    const start = new Date().getTime();
    const openTelemetrySpan = startOpenTelemetrySpan('search.api.request');

    const ret = await promise;

    openTelemetrySpan?.end();

    const end = new Date().getTime();

    const pageName = telemetryApiService.pageName();
    const entities = ret?.data;

    telemetryApiService.sendBulkTelemetryLog({
        pageName,
        fullSection: `${pageName}`,
        loadTimeMs: end - start,
        eventType: telemetryApiService.getEventType('SEARCH_API_REQUEST_MEASUREMENT'),
        ...(source ? { source } : {}),
        ...(entities?.length ? { entityCount: entities.length } : {}),
    });

    return ret;
};
