import {
    useCloseSearchDialog,
    useSearchDialogVenue,
    useSyncDialogResult,
} from 'components/search-options-dialog/v2/hooks/use-search-dialog-venue';
import { ExploreSearchResult } from 'features/explore/types/types';
import { zoomToLocation } from 'ui-components/google-map/utils';
import { Dialog } from 'ui-components/dialog';
import styles from './search-options-dialog-content.module.scss';
import { ExploreDrawerComponent } from 'features/explore/components/explore-side-bar/components/explore-drawer/explore-drawer';
import { GoogleVectorMap } from 'ui-components/google-vector-map/google-vector-map';
import { GoogleMapLayers } from 'ui-components/google-map-layers/components/google-map-layers/google-map-layers';
import { ExploreMapLegend } from 'features/explore/components/explore-map-section/explore-map-legend/explore-map-legend';
import React from 'react';
import { SearchOptionsDialogHeader } from 'components/search-options-dialog/v2/components/search-options-dialog-header';
import { useGoogleMapLayersProps } from 'features/explore/hooks/use-google-map-layers-props';
import { GoogleMapLayersStateContextProvider } from 'ui-components/google-map-layers/context/google-map-layers-context';
import { ZOOM_TO_MARKER_TARGET } from 'shared/constants/google-map';
import { MAP_SPLUNK_EVENTS } from 'ui-components/google-map/consts';
import { getGoogleMapPermissions } from 'utils/get-google-map-permissions/get-google-map-permissions';

export const SearchOptionsDialogComponent = () => {
    const selectedPOI = useSearchDialogVenue();
    const onClose = useCloseSearchDialog();
    const mapLayersProps = useGoogleMapLayersProps();
    const { hasTopBarMapPermission } = getGoogleMapPermissions();
    useSyncDialogResult();

    const exploreResult: ExploreSearchResult = {
        info: selectedPOI,
    };

    const onMapLoad = (map: google.maps.Map) => {
        if (selectedPOI) {
            //@ts-expect-error PLAC-47814
            zoomToLocation(map, selectedPOI.geolocation, ZOOM_TO_MARKER_TARGET);
        }
    };

    return (
        <Dialog
            visible={!!selectedPOI}
            onCancel={onClose}
            maskClosable={true}
            destroyOnClose={true}
            closable={false}
            footer={null}
            width={960}
            title={<SearchOptionsDialogHeader />}
            className={styles.modal}
        >
            <div className={styles.info} data-testid={'search-options-content-dialog-v2'}>
                <ExploreDrawerComponent result={exploreResult} enableSelection={false} />
            </div>
            <div className={styles.map}>
                <GoogleVectorMap
                    source={MAP_SPLUNK_EVENTS.TOP_BAR}
                    onLoad={onMapLoad}
                    shouldRenderMap={hasTopBarMapPermission}
                >
                    <GoogleMapLayersStateContextProvider {...mapLayersProps}>
                        <GoogleMapLayers enableMarkerTooltip={false} alwaysShowPolygon={true} />
                        <ExploreMapLegend withSwitches={false} />
                    </GoogleMapLayersStateContextProvider>
                </GoogleVectorMap>
            </div>
        </Dialog>
    );
};
