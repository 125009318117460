import classnames from 'classnames';
import React, { PropsWithChildren } from 'react';
import styles from 'ui-components/loading/loading-component.module.scss';
import { LoadingBar } from 'ui-components/loading-bar/loading-bar';

export interface LoadingProps {
    show: boolean;
    customText?: string;
    className?: string;
    customLoader?: React.ReactNode;
}

export function Loading({
    show,
    customText = 'Loading...',
    className,
    customLoader,
    children,
}: PropsWithChildren<LoadingProps>) {
    return show ? (
        customLoader ? (
            <>{customLoader}</>
        ) : (
            <div
                className={classnames(styles.viewLoading, className)}
                data-testid="progressbar-loader"
            >
                {customText !== '' && <h1 className={styles.progressBarTitle}>{customText}</h1>}
                <LoadingBar className={styles.progressBar} />
            </div>
        )
    ) : (
        <>{children}</>
    );
}
