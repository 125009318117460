/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import styles from './search-input-autocomplete.module.scss';

import { AutoComplete } from 'ui-components/auto-complete/auto-complete';
import { useSearchPoiContext } from 'features/poi-selection-map/poi-selection-menu/menu-items/search-menu-item/context/search-poi-context';
import {
    useAutoCompleteSuggestions,
    useUpdateSearchApiFilters,
} from 'features/poi-selection-map/poi-selection-menu/menu-items/search-menu-item/hooks/search-poi-hooks';
import {
    useOnSearchOptionSelected,
    useSetSearchTermSearch,
} from 'features/poi-selection-map/poi-selection-menu/menu-items/search-menu-item/hooks/search-autocomplete-hooks';
import { useSplunkSearchCallback } from 'hooks/use-splunk-callback/use-splunk-callback';
import { getPlacePoiProvider } from 'utils/poi-provider/poi-provider';
import { isEqual } from 'lodash/fp';
import { SearchEventsData } from 'types/splunk/events';
import { subPageNameFromUrl } from 'utils/get-from-url/get-from-url';
import { v4 as uuidv4 } from 'uuid';
import type { Venue } from '@placer-ui/types';
import { SEARCH_SPLUNK_ACTIONS, SPLUNK_SEARCH_PLACEMENTS } from 'shared/constants/search-splunk';

type SearchInputAutocompleteProps = {
    title: string;
};

export const SearchInputAutocomplete = ({ title }: SearchInputAutocompleteProps) => {
    const {
        currentSearchTerm,
        setCurrentSearchTerm,
        searchId,
        isLoadingSearchSuggestions,
        setSearchApiSuggestions,
        setSelectedSearchSuggestion,
        setIsCnp,
    } = useSearchPoiContext();
    const onSearchOptionSelected = useOnSearchOptionSelected();
    const updateSearchApiFilters = useUpdateSearchApiFilters();
    const autoCompleteSuggestions = useAutoCompleteSuggestions();
    const setSearchTermSearch = useSetSearchTermSearch();
    const sendSearchSplunkEvent = useSplunkSearchCallback();
    const [mappedSuggestions, setMappedSuggestion] = useState(autoCompleteSuggestions);
    const [searchTerm, setSearchTerm] = useState(currentSearchTerm);

    // added an optionUniqueKey in suggestions
    const searchInputValue = currentSearchTerm.split('_')[0];

    const setSearchTerms = (term: string) => {
        setSearchTermSearch(term);
    };

    const sendSearchSplunkFunc = (events: SearchEventsData) => {
        sendSearchSplunkEvent({
            search_placement: SPLUNK_SEARCH_PLACEMENTS.poiSelectionMenu,
            view_name: `Advanced Reports ${subPageNameFromUrl()}`,
            search_term: searchTerm.split('_')[0],
            ...events,
        });
    };

    const onClear = () => {
        sendSearchSplunkFunc({
            action: SEARCH_SPLUNK_ACTIONS.search,
            num_of_results: mappedSuggestions.length,
            search_type: 'instant',
            search_id: searchId,
        });
        setSearchTerms('');
    };

    const onEnter = () => {
        sendSearchSplunkFunc({
            action: SEARCH_SPLUNK_ACTIONS.search,
            num_of_results: mappedSuggestions.length,
            search_type: 'enter',
            search_id: uuidv4(),
            CnP: false,
        });
        setSelectedSearchSuggestion(undefined);
        updateSearchApiFilters({
            term: currentSearchTerm,
        });
    };

    const onChange = (newValue: string) => {
        setSearchApiSuggestions(undefined);
        setCurrentSearchTerm(newValue);
    };

    useEffect(() => {
        if (
            !isEqual(autoCompleteSuggestions, mappedSuggestions) &&
            autoCompleteSuggestions.length
        ) {
            setSearchTerm(currentSearchTerm);
            setMappedSuggestion(autoCompleteSuggestions);
        }
    }, [autoCompleteSuggestions, mappedSuggestions, currentSearchTerm]);

    const onSelect = async (entity: Venue) => {
        const { provider_data, name, id } = entity;

        setSearchTerms(provider_data?.provider ? name : '');
        onSearchOptionSelected(entity);

        sendSearchSplunkFunc({
            action: SEARCH_SPLUNK_ACTIONS.selected,
            num_of_results: mappedSuggestions.length,
            search_type: 'instant',
            search_id: searchId,
            index_in_result: mappedSuggestions?.findIndex((opt) => opt.payload?.id === id),
            source_type: getPlacePoiProvider(entity),
            value: entity.name,
        });
    };

    return (
        <div className={styles.autocompleteContainer}>
            <AutoComplete
                id={'poi-selection-menu'}
                onCNP={() => setIsCnp?.(true)}
                value={searchInputValue}
                options={autoCompleteSuggestions}
                isLoading={isLoadingSearchSuggestions}
                onChange={onChange}
                onSelect={onSelect}
                onClear={onClear}
                onEnter={onEnter}
                className={styles.searchBar}
                autoCompleteWrapperClass={styles.autocompleteInputWrapper}
                dropdownClassName={styles.autocompleteDropdown}
                searchAndClearIconClassName={styles.searchAndClearIconClassName}
                placeholder={title}
                dropdownAlign={{ offset: [-12, 8] }}
                getPopupContainer={(triggerNode) => triggerNode?.parentElement || document.body}
            />
        </div>
    );
};
