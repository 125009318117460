import React, { ComponentProps, Ref } from 'react';
import { Button as AntButton } from 'antd';
import classNames from 'classnames';

import styles from './button.module.scss';

type AntButtonProps = ComponentProps<typeof AntButton>;

export type ButtonProps = Omit<AntButtonProps, 'type'> & {
    type?:
        | AntButtonProps['type']
        | 'transparent'
        | 'brand-primary'
        | 'brand-primary-full'
        | 'secondary'
        | 'link-gray';
};

export const Button = React.forwardRef((props: ButtonProps, ref: Ref<any>) => {
    const classes = classNames(styles.button, props.className, {
        [styles.linkGray]: props.type === 'link-gray',
    });

    const antdProps = { ...props } as AntButtonProps;
    if (antdProps.type === ('link-gray' as AntButtonProps['type'])) {
        antdProps.type = 'link';
    }

    return <AntButton {...antdProps} className={classes} ref={ref} />;
});
