import { defaultMapOptions, USACenterPoint } from 'ui-components/google-map/google-map-default-options';
import { placerMapStyles } from 'ui-components/google-map/placer-map-styles';
import * as React from 'react';
import { MAX_MAP_ZOOM } from 'features/poi-selection-map/constants/poi-selection-map-constants';
import { PoiSelectionActionButtonProps } from 'features/poi-selection-map/types/poi-selection-types';
import { BaseEventData } from 'types/splunk/events';
import { GESTURE_HANDLING_OPTIONS } from 'shared/constants/google-map';

export type PoiSelectionConfiguration = {
    allowPoiMultiSelect?: boolean;
    mapOptions?: {
        active: google.maps.MapOptions;
        inactive: google.maps.MapOptions;
    };
    // namespace which would be used to save and retrieve custom user settings
    userCustomSettingsNamespace: string;
    poiSelectionActionButton: (props: PoiSelectionActionButtonProps) => React.ReactNode;
    getCustomMapControllers?: (map?: google.maps.Map) => React.ReactNode;
    onEvent?: (details: BaseEventData) => void;
    allowReportAccessCheck?: boolean;
};

const activeMapOptions: google.maps.MapOptions = {
    ...defaultMapOptions,
    mapTypeControl: false,
    streetViewControl: false,
    zoomControl: false,
    minZoom: 3,
    maxZoom: MAX_MAP_ZOOM,
};

const inactiveMapOptions: google.maps.MapOptions = {
    mapTypeControl: false,
    zoomControl: false,
    streetViewControl: false,
    fullscreenControl: false,
    disableDefaultUI: false,
    controlSize: 30,
    center: USACenterPoint,
    styles: placerMapStyles,
    gestureHandling: GESTURE_HANDLING_OPTIONS.cooperative,
};

export const defaultPoiSelectionConfiguration: PoiSelectionConfiguration = {
    allowPoiMultiSelect: false,
    mapOptions: {
        active: activeMapOptions,
        inactive: inactiveMapOptions,
    },
    userCustomSettingsNamespace: '',
    poiSelectionActionButton: () => null,
    getCustomMapControllers: undefined,
    onEvent: undefined,
};
