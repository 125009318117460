import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'ui-components/button/button';
import { ButtonsFooter } from 'features/mobile-report/components/mobile-footer/components/buttons-footer/buttons-footer';
import { isMobile } from 'utils/detect-environment';

import styles from './refresh-page.module.scss';

export const RefreshPage = () => {
    const history = useHistory();

    const handleRefresh = () => {
        history.push({
            pathname: '/explore',
            state: {
                fromURL: {
                    pathname: history.location.pathname,
                },
            },
        });
        window.location.reload();
    };
    return (
        <div className={styles.root}>
            <div className={styles.content}>
                <p className={styles.message}>Refresh the app to view the latest data</p>
                <Button
                    type="link"
                    className={styles.button}
                    onClick={handleRefresh}
                    data-testid="app-refresh-button"
                >
                    Refresh
                </Button>
            </div>
            {isMobile() && <ButtonsFooter isDisabled />}
        </div>
    );
};
