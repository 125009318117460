import React from 'react';
import { Button as AntButton } from 'antd';
import styles from './select-and-clear-all.module.scss';
import classNames from 'classnames';

export type SelectAndClearAllType = 'select all' | 'clear all';

type SelectAndClearAllProps = {
    onClick: (type: SelectAndClearAllType) => void;
    disabledClearAll?: boolean;
    disabledSelectAll?: boolean;
    hideSelectAll?: boolean;
};

type LinkButtonProps = {
    type: SelectAndClearAllType;
    onClick: SelectAndClearAllProps['onClick'];
    disabled: boolean;
    label: string;
};

const LinkButton = ({ type, onClick, disabled, label }: LinkButtonProps) => (
    <AntButton
        className={classNames({
            [styles.activeButton]: !disabled,
            [styles.clearAll]: type === 'clear all',
            [styles.selectAll]: type === 'select all',
        })}
        onClick={() => onClick(type)}
        type="link"
        disabled={disabled}
    >
        {label}
    </AntButton>
);

export const SelectAndClearAll = ({
    onClick,
    disabledSelectAll = false,
    disabledClearAll = false,
    hideSelectAll = false,
}: SelectAndClearAllProps) => {
    return (
        <div
            className={classNames(styles.selectAllButtonsContainer, {
                [styles.onlyClearAll]: hideSelectAll,
            })}
        >
            {!hideSelectAll && (
                <LinkButton
                    type={'select all'}
                    onClick={onClick}
                    label={'Select All'}
                    disabled={disabledSelectAll}
                />
            )}
            <LinkButton
                type={'clear all'}
                onClick={onClick}
                disabled={disabledClearAll}
                label={'Clear'}
            />
        </div>
    );
};
