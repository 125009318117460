import React from 'react';

import { ColumnName } from 'ui-components/table/data-table/table-cells/column-name';
import { Tooltip } from 'ui-components/tooltip/tooltip';

import styles from './column-title-cell.module.scss';

type ColumnTitleCellProps = {
    title: string;
    columnKey: string;
};

export const ColumnTitleCell = ({ title, columnKey }: ColumnTitleCellProps) => {
    return (
        <ColumnName className={styles.columnTitleCell}>
            <Tooltip small title={title} placement="topLeft">
                <span data-testid={columnKey}>{title}</span>
            </Tooltip>
        </ColumnName>
    );
};
