import { useAppDispatch } from 'store/app-store-hooks';
import type { Dispatch } from '@reduxjs/toolkit';

import { createUser, UserData, VenuePOI } from 'core/entities';
import { UserConfiguration } from 'core/entities/user/user-configuration';
import { UserFeatureFlags } from 'core/entities/user/user-feature-flags';
import type { UserPermissions } from 'core/entities/user/user-permissions';
import type {
    UserPermissionsAndFeatureFlagsResponse,
    UserPluginsResponse,
    UserPreferencesResponse,
    UserSettings,
    UserSettingsResponse,
} from 'core/entities/user/user-settings';
import type {
    UserCustomSettings,
    UserCustomSettingsResponse,
} from 'core/entities/user/user-custom-settings';
import type { PreferencesFields } from 'features/settings/shared/models/settings-field';
import type {
    AccountSettingsResponse,
    ExportFields,
} from 'features/settings/shared/types/account-settings';
import type { PlacerEntityWrapper, Venue } from '@placer-ui/types';
import { httpClient } from 'shared/http-client';
import { initUserIntegrations } from 'shared/init-integrations';
import { initFlowControl } from 'core/flow-control';
import { userCookieStorage } from 'core/authentification';
import { getAttributes } from 'store/configuration/attributes/actions';
import { getCategories } from 'store/configuration/categories/actions';
import { getChainsByCategories } from 'store/configuration/chains-by-categories/actions';
import { buildDates, buildFullDates } from 'utils/dates-builder/utils';
import { APP_CONSTS } from 'core/constants/app-consts';
import { placeTypeToPlaceCollection } from 'utils/place-type-collection-conversion/place-type-collection-conversion';
import { getAppApiEndpoint, setDefaultEndpointUrl } from 'core/default-endpoint-url';
import { setUser } from 'store/auth/auth';
import { setReportDates } from 'features/insights/store/insights-slice';
import { settingsActions } from 'features/settings/store/slice';
import { setUserCustomSettingsActionCreator } from 'store/auth/actions/auth-action-creators';

export const useGetInitialState = () => {
    const dispatch = useAppDispatch();

    return async () => {
        const { setExportSettings, setPlatformConfiguration } = settingsActions;
        const [
            { configuration, settings, custom_settings, defaultVenue },
            { permissions, feature_flags },
            { preferences },
            { export_config },
        ] = await Promise.all([
            getUserSettings(),
            getUserPermissionsAndFeatureFlags(),
            getUserPreferences(true),
            getAccountSettings(true),
        ]);

        initFlowControl(feature_flags, permissions);
        setDefaultEndpointUrl();
        setExtraStoreData(dispatch, permissions, feature_flags);
        httpClient.setBaseUrl(getAppApiEndpoint());

        const limitedDates = buildDates(configuration, permissions);
        const extendedDates = buildFullDates(
            configuration.analytics_max_month,
            configuration.analytics_max_date,
        );
        const dates = {
            ...extendedDates.dates,
            ...limitedDates?.dates,
        };
        const propertyDates = {
            dates,
            maxDataDate: extendedDates.maxDataDate,
            maxDataMonth: extendedDates.maxDataMonth,
        };
        const userData: UserData = {
            email: userCookieStorage.user!.email,
            token: userCookieStorage.user!.token,
            username: userCookieStorage.user!.username,
            fullName: userCookieStorage.user!.fullName,
            permissions,
            feature_flags,
            configuration: configuration as any,
            settings: settings as any,
            defaultVenue: defaultVenue as any,
        };
        dispatch(setReportDates(propertyDates));

        const user = createUser(userData);
        initUserIntegrations(user);
        dispatch(setUser(userData));
        dispatch(setUserCustomSettingsActionCreator(custom_settings));

        export_config && dispatch(setExportSettings(export_config));
        preferences && dispatch(setPlatformConfiguration(preferences));

        return {
            export_config,
            preferences,
            propertyDates,
            user,
            custom_settings,
        };
    };
};

const getUserSettings = async (): Promise<{
    configuration: UserConfiguration;
    settings: UserSettings;
    custom_settings: UserCustomSettings;
    defaultVenue?: { id: string; collection: string };
}> => {
    const storedData = getWindowStoreData();
    if (storedData?.userData?.settings && storedData?.userData?.configuration) {
        return {
            configuration: storedData.userData.configuration as UserConfiguration,
            settings: storedData.userData.settings as UserSettings,
            custom_settings: storedData.customSettings as UserCustomSettings,
        };
    }

    const fetchUserConfigPromise = httpClient.get<UserSettingsResponse>({
        path: '/2/guru/user/config',
    });

    const fetchUserCustomSettingsPromise = httpClient.get<UserCustomSettingsResponse>({
        path: '/2/guru/user/custom_settings',
    });

    const fetchPermittedPluginsPromise = httpClient.get<UserPluginsResponse>({
        path: '/2/guru/user/permitted_plugins',
    });

    const [
        {
            data: { settings, configuration },
        },
        { data: customSettingsRes },
        {
            data: { permitted_plugins },
        },
    ] = await Promise.all([
        fetchUserConfigPromise,
        fetchUserCustomSettingsPromise,
        fetchPermittedPluginsPromise,
    ]);

    const custom_settings = customSettingsRes.custom_settings || {};

    (Object.keys(custom_settings) as Array<keyof UserCustomSettings>).forEach((value) => {
        try {
            custom_settings[value] =
                custom_settings[value] && JSON.parse(custom_settings[value] as string);
        } catch {
            return;
        }
    });

    const defaultVenue = await getUserDefaultVenue(custom_settings);

    return {
        configuration: {
            ...configuration,
            demo_entities: configuration.demo_entities?.map((demoEntity) => {
                return {
                    ...demoEntity,
                    collection: VenuePOI.typeToCollection(demoEntity.type),
                };
            }),
        },
        settings: {
            ...settings,
            permitted_plugins,
        },
        custom_settings,
        defaultVenue,
    };
};

const getUserPermissionsAndFeatureFlags = async (): Promise<{
    permissions: UserPermissions;
    feature_flags: UserFeatureFlags;
}> => {
    const storedData = getWindowStoreData();
    if (storedData?.userData?.permissions && storedData?.userData?.feature_flags) {
        return {
            permissions: storedData.userData.permissions as UserPermissions,
            feature_flags: storedData.userData.feature_flags as UserFeatureFlags,
        };
    }

    const res = (
        await httpClient.get<UserPermissionsAndFeatureFlagsResponse>({
            path: '/2/guru/user/permissions',
        })
    ).data;

    const permissions: Record<string, boolean> = {};
    res.permissions.forEach((permission) => {
        if (permission.indexOf('auth.') === 0) {
            permissions[permission.substring(5)] = true;
        } else {
            permissions[permission] = true;
        }
    });

    const feature_flags = res.feature_flags.reduce((result, featureName) => {
        result[featureName] = true;
        return result;
    }, {} as Record<string, boolean>);

    return {
        permissions: { ...(permissions as UserPermissions) },
        feature_flags: { ...(feature_flags as UserFeatureFlags) },
    };
};

const getUserPreferences = async (
    isEnabled: boolean,
): Promise<{
    preferences: PreferencesFields | undefined;
}> => {
    if (!isEnabled) {
        return { preferences: undefined };
    }

    const storedData = getWindowStoreData();
    if (storedData?.settings?.tabs?.platformConfiguration) {
        return {
            preferences: storedData.settings.tabs.platformConfiguration as PreferencesFields,
        };
    }

    const {
        data: { preferences },
    } = (
        await httpClient.get<UserPreferencesResponse>({
            path: '/2/guru/user/preferences',
        })
    ).data;
    return { preferences };
};

const getAccountSettings = async (
    isEnabled: boolean,
): Promise<{
    export_config: ExportFields | undefined;
}> => {
    if (!isEnabled) {
        return { export_config: undefined };
    }

    const storedData = getWindowStoreData();
    if (storedData?.settings?.tabs?.exportConfiguration) {
        return {
            export_config: storedData.settings.tabs.exportConfiguration as ExportFields,
        };
    }

    const {
        data: { export_config },
    } = (
        await httpClient.get<AccountSettingsResponse>({
            path: '/2/guru/account/custom_settings/export_config',
        })
    ).data;
    return { export_config };
};

const setExtraStoreData = (
    dispatch: Dispatch<any>,
    permissions: UserPermissions,
    feature_flags: UserFeatureFlags,
) => {
    dispatch(getAttributes());
    dispatch(
        getCategories({
            userPermissions: permissions,
            featuresFlags: feature_flags,
        }),
    );
    dispatch(getChainsByCategories());
};

const getWindowStoreData = () => {
    let windowOpenerData;
    try {
        windowOpenerData = window?.opener?.dataForUI;
    } catch (e) {
        console.debug('No valid window opener in userSettingsConfig.');
    }
    return window?.dataForUI || windowOpenerData;
};

const getIsRecentReportsInSettingsData = (customSettings: UserCustomSettings) => {
    return !!customSettings?.['insights' as keyof UserCustomSettings];
};

const getSearchEntityTypes = () => {
    return encodeURIComponent(
        `${APP_CONSTS.venues.collection.VENUES},${APP_CONSTS.venues.collection.COMPLEXES}`,
    );
};

const getUserDefaultVenue = async (customSettings: UserCustomSettings) => {
    let defaultVenue;
    const isRecentReportsAvailable = getIsRecentReportsInSettingsData(customSettings);
    if (!isRecentReportsAvailable) {
        const { data } = (
            await httpClient.get<Record<string, PlacerEntityWrapper<Venue>[]>>({
                path: '/2/guru/search',
                params: {
                    limit: 1,
                    purchased_only: true,
                    type: getSearchEntityTypes(),
                },
            })
        ).data;
        if (data.length) {
            defaultVenue = {
                id: data[0].info.id,
                collection: placeTypeToPlaceCollection(data[0].info.type),
            };
        } else {
            const { data } = (
                await httpClient.get<Record<string, PlacerEntityWrapper<Venue>[]>>({
                    path: '/2/guru/search',
                    params: {
                        limit: 1,
                        purchased_only: false,
                        type: getSearchEntityTypes(),
                    },
                })
            ).data;
            if (data.length) {
                defaultVenue = {
                    id: data[0].info.id,
                    collection: placeTypeToPlaceCollection(data[0].info.type),
                };
            }
        }
    }

    return defaultVenue;
};
