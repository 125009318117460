import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

import { IconProps } from 'components/assets/Icons/Icons';

/* eslint-disable max-len */
const EducationIconSvg = (props: CustomIconComponentProps | SVGProps<SVGSVGElement>) => (
    <svg {...props} viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g
                id="Chain-Compatability-Analysis-3"
                transform="translate(-512.000000, -1371.000000)"
                stroke="#49565D"
            >
                <g id="Group-3-Copy-2" transform="translate(40.000000, 845.000000)">
                    <g id="Group-66" transform="translate(472.000000, 524.000000)">
                        <g id="Icons_18/Blue/Education" transform="translate(0.000000, 2.000000)">
                            <path
                                d="M14.5792609,5.42073906 L14.5792609,12.6159043 C14.5792609,12.9079758 14.4986503,13.1881203 14.3535575,13.4295057 C14.2084647,13.6708911 13.9988897,13.8735172 13.7409609,14.0105519 L13.7409609,14.0105519 L9.75018199,16.1308089 C9.28761713,16.3765645 8.73329853,16.3769824 8.27036367,16.1319244 L8.27036367,16.1319244 L4.26114261,14.0096141 C4.00262979,13.8727685 3.7925289,13.6700772 3.64705622,13.4284725 C3.50158354,13.1868678 3.42073906,12.9063499 3.42073906,12.613851 L3.42073906,12.613851 L3.42073906,5.42073906 L14.5792609,5.42073906 Z"
                                id="Fill-1"
                                strokeWidth="0.841478112"
                            />
                            <path
                                d="M8.98912538,0.375419011 L17.200944,5.35306569 C17.4126142,5.48556943 17.5505378,5.69132533 17.6024179,5.91687285 C17.6542981,6.14242036 17.6201348,6.38775948 17.487631,6.59942965 C17.4114267,6.72116349 17.3073245,6.82299705 17.1839415,6.89650119 L17.1839415,6.89650119 L9.4639507,11.495604 C9.32136442,11.5805482 9.1612396,11.6230204 9.00111477,11.6230204 C8.84098995,11.6230204 8.68086513,11.5805482 8.53827885,11.495604 L8.53827885,11.495604 L0.816085875,6.89518925 C0.601548096,6.76738064 0.459125265,6.56471304 0.402289032,6.34036315 C0.345452799,6.11601326 0.374203163,5.86998108 0.502011775,5.6554433 C0.575345415,5.53234646 0.676879757,5.42843699 0.798248307,5.35227709 L0.798248307,5.35227709 L8.50894462,0.513743164 C8.65574988,0.421621495 8.82242509,0.37550984 8.98912538,0.375419011 L8.98912538,0.375419011 Z"
                                id="Combined-Shape"
                                strokeWidth="0.75"
                                fill="currentColor"
                            />
                            <line
                                x1="16.5"
                                y1="7.5"
                                x2="16.5"
                                y2="15.5"
                                id="Line-8"
                                strokeWidth="0.75"
                                strokeLinecap="round"
                            />
                            <circle
                                id="Oval"
                                strokeWidth="0.75"
                                fill="#676DFD"
                                cx="16.5"
                                cy="16.5"
                                r="1.125"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

/* eslint-disable max-len */
export const EducationIconSingleColorSvg = (
    props: CustomIconComponentProps | SVGProps<SVGSVGElement>,
) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 12 12">
        <g fill="none" fillRule="evenodd">
            <path
                fill="currentColor"
                d="M2 6.918l3.053 1.82a1.859 1.859 0 001.897 0L10 6.92v2.157c0 .493-.272.946-.707 1.177l-2.66 1.414c-.391.207-.86.208-1.25 0L2.71 10.255A1.335 1.335 0 012 9.076V6.918zM10 4v1.756L6.438 7.878a.854.854 0 01-.873 0L2 5.754V4h8z"
            />
            <path
                fill="currentColor"
                d="M.4 3.356L5.54.13a.856.856 0 01.906 0L11.6 3.357a.852.852 0 01-.016 1.455L6.438 7.878a.854.854 0 01-.873 0L.417 4.811A.852.852 0 01.4 3.356z"
            />
            <path stroke="currentColor" strokeLinecap="round" strokeWidth=".75" d="M11 5v5.333" />
            <circle cx="11" cy="11" r="1" fill="currentColor" />
        </g>
    </svg>
);

type EducationIconProps = IconProps & {
    singleColor?: boolean;
};

export const EducationIcon = ({ singleColor = false, ...props }: EducationIconProps) => (
    <Icon component={singleColor ? EducationIconSingleColorSvg : EducationIconSvg} {...props} />
);
