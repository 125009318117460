import { createSelector } from '@reduxjs/toolkit';

import { selectChainsReportSearchSection } from '.';
import { ChainsReportState } from '../../chains-store';
import { chainsPermissionService } from 'features/industry/common/services';

export const selectChainsSearchActiveChain = (state: ChainsReportState) => {
    const search = selectChainsReportSearchSection(state);
    return search.activeChain;
};

export const selectChainsSearchActiveIsElegible = createSelector(
    selectChainsSearchActiveChain,
    (activeChain) => {
        return {
            activeChain,
            isElegibleForTheReport: activeChain
                ? chainsPermissionService.chainIsEligibleForTheReport(activeChain)
                : false,
        };
    },
);

export const selectChainsSearchActiveChainId = createSelector(
    selectChainsSearchActiveChain,
    (activeChain) => activeChain?.id,
);

export const selectChainsSearchTopVenues = (state: ChainsReportState) => {
    const search = selectChainsReportSearchSection(state);

    return search.topVenues;
};

export const selectChainsSearchIsTagsLoading = (state: ChainsReportState) => {
    const search = selectChainsReportSearchSection(state);
    return search.isTagsLoading;
};

export const selectChainsSearchIsTopVenuesLoading = (state: ChainsReportState) => {
    const search = selectChainsReportSearchSection(state);
    return search.isTopVenuesLoading;
};

export const selectChainsReportSearchMyTagChains = (state: ChainsReportState) => {
    const search = selectChainsReportSearchSection(state);

    return search.myTagChains;
};

export const selectChainsReportSearchCompanyTagChains = (state: ChainsReportState) => {
    const search = selectChainsReportSearchSection(state);

    return search.companyTagChains;
};

export const selectMyTagQuery = (state: ChainsReportState) => {
    const search = selectChainsReportSearchSection(state);

    return search.myTagsQuery;
};

export const selectCompanyTagQuery = (state: ChainsReportState) => {
    const search = selectChainsReportSearchSection(state);

    return search.companyTagsQuery;
};

export const selectChainsEntityModelsSelectedForReportInSearch = (state: ChainsReportState) => {
    const search = selectChainsReportSearchSection(state);

    return search.selectedChainsEntityModels;
};

export const selectMyTagsHasMoreResults = (state: ChainsReportState) => {
    const search = selectChainsReportSearchSection(state);

    return search.potentiallyMoreMyTagResultsExist;
};

export const selectCompanyTagsHasMoreResults = (state: ChainsReportState) => {
    const search = selectChainsReportSearchSection(state);

    return search.potentiallyMoreCompanyTagResultsExist;
};
