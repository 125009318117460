import { createSelector } from '@reduxjs/toolkit';

import { selectChainsReportTotalCount } from '../chains-report-chains-selectors-factory';

export const selectCanRemoveChainReport = createSelector(
    selectChainsReportTotalCount,
    (reportChainsTotalCount): boolean => {
        return reportChainsTotalCount > 1;
    },
);
