import { singular } from 'pluralize';
import { reportException } from 'core/exceptions';
import { Venue } from 'core/entities/venue/venueAdmin';
import { EntityDates, getReportDateOptions } from 'API/report-options-api/report-options-api';
import { store } from 'store/store';
import { reportDateOptionsActions as actions } from 'store/configuration/report-date-options/slices/report-date-options-slice';
import type { PlaceType } from '@placer-ui/types';

export const initReportOptions = async (venues: Venue[]) => {
    try {
        const allPois = venues.map<EntityDates>((venue) => {
            const venueID = venue.id;
            const venueCollection = venue.collection;
            const venueFilterData = venue.customData?.filter;
            return {
                entity_id: venueID,
                entity_type: singular(venueCollection) as PlaceType,
                start_date: venueFilterData?.date?.start,
                end_date: venueFilterData?.date?.end,
            };
        });
        const reportConfig = await getReportDateOptions(allPois);
        store.dispatch(actions.setReportDateOptions(reportConfig));
    } catch (error) {
        reportException(error, {});
    }
    return venues;
};
