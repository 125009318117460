import type { PlacerEntityModel } from '@placer-ui/types';
import _ from 'lodash/fp';

export const getPrimaryEntity = (entities: PlacerEntityModel<any>[]) => {
    const primaryEntity = _.head(entities);

    return primaryEntity;
};

export const theSameDateFilterApplied = (entities: PlacerEntityModel<any>[]) => {
    const primaryEntity = getPrimaryEntity(entities);

    return entities.every((entity) => {
        return (
            entity.filters.date_start === primaryEntity?.filters?.date_start &&
            entity.filters.date_end === primaryEntity?.filters?.date_end
        );
    });
};
