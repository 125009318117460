import { throttle } from 'lodash/fp';
import { MetricsWithRanksKeys } from 'types/properties-data';
import { PropertyRanksType, PropertyMetricType } from 'types/property-ranks';
import { metricDictionary, providersTitles } from 'components/metric-selector/metrics-meta';
import { ranksDictionary } from 'core/constants/ranks-dictionary';
import {
    getBenchmarkByRanksType,
    getRegionByRankType,
} from 'components/metric-selector/utils/utils';
import { isDevelopment } from 'core/constants/environment';
import { MetricCategory } from 'components/metric-selector/types/types';

const throttleWarn = throttle(1000 * 60, (metricName: string) => {
    console.warn(`there is a missing key=${metricName} in metricDictionary`);
});

export const getMetricsAndRankFromKey = (str: string) => {
    const splitTxt = str.split('-');
    return {
        metric: splitTxt[0] as PropertyMetricType,
        rank: splitTxt[1] ? (splitTxt[1] as PropertyRanksType) : undefined,
    };
};

export const getMetricWithRankKey = (
    metric: PropertyMetricType,
    rank: PropertyRanksType | undefined,
): MetricsWithRanksKeys => {
    if (rank) {
        return (metric + '-' + rank) as MetricsWithRanksKeys;
    }
    return metric;
};

export const getMetricsData = (metricKey: string, label?: string) => {
    const { metric: metricName, rank: rankName } = getMetricsAndRankFromKey(metricKey);

    if (!metricDictionary[metricName]) {
        isDevelopment && throttleWarn(metricName);
        return null;
    }

    const title = label ?? metricDictionary[metricName].title;
    const rankType = rankName;
    const subtitle = rankName
        ? ranksDictionary[rankName]?.benchMarkTitle + ', ' + ranksDictionary[rankName].region
        : '';
    const benchmarkCategory = rankType ? ranksDictionary[rankType]?.benchmarkCategory : '';
    const region = getRegionByRankType(rankType);
    const benchmark = getBenchmarkByRanksType(rankType);
    const provider = metricDictionary[metricName].provider;
    const providerName = provider && providersTitles[provider];

    return {
        title,
        rankType,
        subtitle,
        benchmarkCategory,
        region,
        benchmark,
        provider,
        providerName,
        disableByPermissionKey: metricDictionary[metricName].disablePermission,
        category: metricDictionary[metricName].category as MetricCategory,
    };
};
