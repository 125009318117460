import React from 'react';
import { SgBidIcon } from 'features/onboarding/solutions-guide/icons/sg-bid-icon';

export const BID_DMO_SOLUTIONS_GUIDE = {
    icon: <SgBidIcon />,
    solutions: [
        {
            title: 'Events & Local Attractions',
            description:
                'Measure turnout and visitors’ origins to evaluate the ' +
                'success of your event or attraction, and adapt your strategy accordingly.',
            url: '/insights/complexes/641abff5b1f296002ab09009/overview',
        },
        {
            title: 'Retail Recruitment',
            description:
                'Instantly generate a list of best-fit tenants for any ' +
                'retail vacancy based on customizable scoring parameters and filters.',
            url: '/insights/venues/9c74952a885c3dc66320584a/void-analysis-freemium',
        },
        {
            title: 'Travel & Tourism',
            description:
                'Evaluate the economic impact and spending potential ' +
                'of short-term travel on any particular region.',
            url: '/advanced-reports/freemium/travel-tourism',
        },
        {
            title: 'Visitor Insights',
            description:
                'Uncover comprehensive demographic and psychographic insights about residents and visitors.',
            url: '/insights/complexes/641abff5b1f296002ab09009/audience',
        },
        {
            title: 'Sales Tax Estimations',
            description:
                'Leverage retail sales estimates, combined with ' +
                'visitation data, to improve the accuracy of sales tax forecasts.',
            url: '/insights/venues/88a92d9bd3288fde00c0c01e/retail-sales',
        },
        {
            title: 'Return-To-Office Trends',
            description:
                'Discover how employee visits to office buildings ' +
                'in major U.S. cities have recovered since COVID-19.',
            url: '/advanced-reports/external-iframe-report_return-to-office',
        },
    ],
};
