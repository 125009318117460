import React from 'react';
import classNames from 'classnames';
import styles from './loading-bar.module.scss';

interface LoadingBarProps {
    className?: string;
    dataTestId?: string;
}

export const LoadingBar = ({ className, dataTestId }: LoadingBarProps) => {
    const classes = classNames(styles.bar, className);

    return (
        <div className={classes} role="progressbar" data-testid={dataTestId}>
            <div className={styles.second} />
        </div>
    );
};
