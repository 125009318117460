export const parseHtmlText = (htmlText: string) => {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(htmlText, 'text/html');
    return htmlDoc.documentElement.innerText || htmlDoc.documentElement.textContent || '';
};

//parses a string containing either HTML or XML, returning an HTMLDocument or an XMLDocument.
export const parseHtmlSvgText = (htmlText: string) => {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(htmlText, 'image/svg+xml');
    return htmlDoc.documentElement;
};
