/**
 * @typedef {(DAYS|WEEK|MONTH)} AnalyticsPeriodsFilters
 * @typedef {{start: string|Date|Moment, end: string|Date|Moment}} DateServiceOptions
 **/
import _ from 'lodash';
import moment from 'moment';

export class DateService {
    appConstants: any;

    static defaultValues = {
        calendarTimeFormat: 'MMMM Do, YYYY',
        longDateFormat: 'ddd, MMMM Do, YYYY',
    };

    constructor(AppConstants: any) {
        this.appConstants = AppConstants;
    }

    simpleMonthYearFormat(date: any) {
        const formattedDate = this._createMomentInstance(date).format(
            DateService.defaultValues.calendarTimeFormat,
        );

        return formattedDate;
    }

    /**
     * @param {string|Date} date is a moment instance
     * @return {Object} formatted date range
     */
    createFullMonthsDate(date: any, max: any) {
        let end = this._createMomentInstance(date.end);
        const start = this._createMomentInstance(date.start);

        if (end.isSameOrAfter(max)) {
            end = max;
        }

        return {
            start: start.startOf('month'),
            end: end.endOf('month'),
        };
    }

    /**
     * @param  {Array<Object>} date
     *
     * @returns {Boolean} all placesh having same datename filters
     */
    datesAreSame(dates: any) {
        const baseDateName = _.head(dates)?.name;

        return dates.every((date: any) => date.name === baseDateName);
    }

    /**
     *
     * @param {string|Date} date is a moment instance
     * @return {string} formatted month
     *
     */
    buildFormattedMonth(date: any) {
        const formattedDate = this._createMomentInstance(date).format('MMMM YYYY');

        return formattedDate;
    }

    buildDatepickerFormat(date: any) {
        const dateFormat = this.appConstants.date.format;
        const start = this._createMomentInstance(date.start).format(dateFormat.DATEPICKER);
        const end = this._createMomentInstance(date.end).format(dateFormat.DATEPICKER);

        return `${start} - ${end}`;
    }

    /**
     *
     * @param {DateServiceOptions} dates is moment instance
     * @return {string} formatted month SHORT_NAME: 'MMM DD'
     *
     */
    buildFormattedRangeDate(dates: any) {
        const areDatePeriodFullMonth = this.isDatePeriodMonth(dates);
        if (areDatePeriodFullMonth) {
            return this.buildFormattedMonth(dates.start);
        }

        return this._buildFormattedDateCustomRange(dates, this.appConstants.date.format.SHORT_NAME);
    }

    /**
     *
     * @param {string|Date} date is a moment instance
     * @return {string} formatted month
     *
     */
    buildFormattedRangeWeek(date: any) {
        const REST_DAYS_IN_WEEK = 6;

        const start = this._createMomentInstance(date);
        const end = this._createMomentInstance(date).add(REST_DAYS_IN_WEEK, 'd');

        const formattedDate = this._buildFormattedDateCustomRange(
            {
                start,
                end,
            },
            this.appConstants.date.format.MOMENT_GRAPH_TITLE,
        );

        return formattedDate;
    }

    /**
     * @param  {Object} date
     *
     * @returns {String} date range
     */
    buildPrettyFormattedRange(date: any) {
        const dateStart = this._createMomentInstance(date.start).format(
            this.appConstants.date.format.PRETTY,
        );
        const dateEnd = this._createMomentInstance(date.end).format(
            this.appConstants.date.format.PRETTY,
        );

        return `${dateStart} - ${dateEnd}`;
    }

    /**
     *
     * @param {string|Date} date is a moment instance
     * @return {string} formatted month
     *
     */
    buildFormattedDay(date: any) {
        const DEFAULT_DATE_FORMAT = this._getDefaultValueFormat();
        const startDate = this._createMomentInstance(date).format(DEFAULT_DATE_FORMAT);

        const holiday = this.getHoliday(date);
        const formattedHoliday = holiday ? ` ( ${holiday} )` : '';

        const formattedDate = startDate + formattedHoliday;

        return formattedDate;
    }

    /**
     *
     * @param {string|Date} date is a moment instance
     * @return {string} formatted month
     *
     */
    getHoliday(date: any) {
        const CALENDAR_DATE_FORMAT = this.appConstants.date.format.FULL;
        const holidays = this.appConstants.holidays;

        const holidayDate = this._createMomentInstance(date).format(CALENDAR_DATE_FORMAT);

        const holiday = holidays[holidayDate];

        return holiday;
    }

    /**
     *
     * @param {DateServiceOptions} dates is moment instance
     * @return {boolean | void}
     *
     */
    isDatePeriodMonth({ start, end }: any) {
        const { FULL } = this.appConstants.date.format;
        const currentStartDay = this._createMomentInstance(start);
        const currentEndDay = this._createMomentInstance(end);

        const isMonthDifferent = currentStartDay.month() !== currentEndDay.month();

        const isYearDifferent = currentStartDay.year() !== currentEndDay.year();

        if (isMonthDifferent || isYearDifferent) {
            return;
        }

        const firstDayOfMonth = this._createMomentInstance(start).startOf('month');

        const lastDayOfMonth = this._createMomentInstance(end).endOf('month');

        const areDateFullMonth =
            firstDayOfMonth.format(FULL) === currentStartDay.format(FULL) &&
            lastDayOfMonth.format(FULL) === currentEndDay.format(FULL);

        return areDateFullMonth;
    }

    /**
     * @param  {String} date
     *
     * @returns {Boolean} is month start and end matches given filter params
     */
    areFiltersFullMonths({ start, end }: any) {
        const dateStart = this._createMomentInstance(start).startOf('month');
        const dateEnd = this._createMomentInstance(end).endOf('month');

        return dateStart.isSame(start, 'day') && dateEnd.isSame(end, 'day');
    }

    /**
     * @param  {Object} date
     * @param  {Object} max data month
     *
     * @returns {Boolean} the given date belongs or overcome max data month
     */
    isCurrentMonth(date: any, max: any) {
        return this._createMomentInstance(date.start).isSameOrAfter(max);
    }

    /**
     *
     * @param {DateServiceOptions} properties is a moment instance
     * @param {string} [format] is moment string format
     * @return {string} formatted month PRETTY: 'MMM Do, YYYY',
     *
     */
    _buildFormattedDateCustomRange({ start, end }: any, format: any) {
        const formatWithYear = `${format}, YYYY`;

        const startDateMoment = this._createMomentInstance(start);
        const endDateMoment = this._createMomentInstance(end);
        const startDateYear = startDateMoment.year();
        const endDateYear = endDateMoment.year();

        const startDateFormatted =
            endDateYear > startDateYear
                ? startDateMoment.format(formatWithYear)
                : startDateMoment.format(format);

        const endDateMomentFormatted = endDateMoment.format(formatWithYear);

        const formattedDate = `${startDateFormatted} - ${endDateMomentFormatted}`;

        return formattedDate;
    }

    /**
     *
     * @return {string} defaultValue
     *
     */
    _getDefaultValueFormat() {
        return DateService.defaultValues.longDateFormat;
    }

    /**
     *
     * @param {string|Date} date is a moment instance
     * @return {Moment} Moment
     *
     */
    _createMomentInstance(date: any) {
        if (date instanceof moment) {
            return (date as moment.Moment).clone();
        }
        return moment(date);
    }
}
