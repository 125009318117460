/* eslint-disable max-len */
import { useCallback, useMemo } from 'react';
import { cloneDeep } from 'lodash';

import { MultiSelectOption } from 'ui-components/multi-select/types';
import { getCategoriesAsMultiSelectOptions } from 'utils/get-categories-as-multi-select-options/get-categories-as-multi-select-options';
import { useSearchMenuPermissions } from 'features/poi-selection-map/poi-selection-menu/menu-items/search-menu-item/hooks/use-search-menu-permissions';
import { Options } from 'ui-components/select-menu/select-menu';
import { BILLBOARD, PROPERTY, ReportTypes } from 'core/constants/report-type';
import { mapPoiTypeToLabel } from 'features/poi-selection-map/hooks/poi-selection-hooks';
import { SwitchItem } from 'ui-components/dropdown-with-switches/dropdown-with-switches';
import {
    useSearchApiFilters,
    useUpdateSearchApiFilters,
} from 'features/poi-selection-map/poi-selection-menu/menu-items/search-menu-item/hooks/search-poi-hooks';
import { MapLayerPOIType } from 'ui-components/google-map-layers/utils/map-layers-entity-color';
import { RestrictionsType } from 'types/entity-search';
import { getCheckedItems } from 'ui-components/dropdown-with-switches/utils/utils';
import { defaultSearchApiFilters } from 'features/poi-selection-map/poi-selection-menu/menu-items/search-menu-item/context/initial-state';
import { useSearchPoiContext } from 'features/poi-selection-map/poi-selection-menu/menu-items/search-menu-item/context/search-poi-context';
import { usePoiSelectionContext } from 'features/poi-selection-map/context/poi-selection-context';
import { ENTITY_PROFILES } from 'features/poi-selection-map/constants/poi-selection-map-constants';

export const useMultipleCategoriesOptions = (selectedCategories: string[]): MultiSelectOption[] => {
    const { categories } = usePoiSelectionContext();

    const { hasRevampCategory } = useSearchMenuPermissions();

    return useMemo(() => {
        if (!hasRevampCategory) {
            return [];
        }
        return getCategoriesAsMultiSelectOptions({
            categories: categories || [],
            selectedCategories,
        });
    }, [categories, hasRevampCategory, selectedCategories]);
};

export const reportTypeToDisplayString: Partial<Record<ReportTypes, string>> = {
    [PROPERTY]: 'Properties',
    [BILLBOARD]: 'Traffic Pins',
};

export const usePoiTypeFilterOptions = () => {
    const { hasTrafficPinPermission } = useSearchMenuPermissions();

    return useMemo<Options[]>(() => {
        const typesOptions: Options[] = [
            {
                value: PROPERTY,
                label: reportTypeToDisplayString[PROPERTY],
            },
        ];

        hasTrafficPinPermission &&
            typesOptions.push({
                value: BILLBOARD,
                label: reportTypeToDisplayString[BILLBOARD],
            });

        return typesOptions;
    }, [hasTrafficPinPermission]);
};

export const useGetRestrictionByMapLayerPOIType = () => {
    return useCallback(
        (mapLayerPoiType: MapLayerPOIType): RestrictionsType =>
            mapLayerPOITypeToRestrictionsType[mapLayerPoiType]!,
        [],
    );
};

export const useRestrictionSwitchFilters = () => {
    const { restrictions } = useSearchApiFilters();
    const getRestrictionByMapLayerPOIType = useGetRestrictionByMapLayerPOIType();
    const getSwitchItemsState = useGetSwitchItemsState();
    return useMemo<SwitchItem[]>(() => {
        const items = ENTITY_PROFILES.map<SwitchItem>((enablePoiType) => {
            const restriction: RestrictionsType = getRestrictionByMapLayerPOIType(enablePoiType);

            return {
                id: enablePoiType,
                title: mapPoiTypeToLabel[enablePoiType] || '',
                checked: !!restrictions[restriction],
            };
        });
        return getSwitchItemsState(items);
    }, [getRestrictionByMapLayerPOIType, getSwitchItemsState, restrictions]);
};

export const useGetSwitchItemsState = () => {
    return useCallback((items: SwitchItem[]): SwitchItem[] => {
        const checkedItems = getCheckedItems(cloneDeep(items));
        return items.map((item) => {
            return {
                ...item,
                disabled: checkedItems.includes(item.id) && checkedItems.length === 1,
            };
        });
    }, []);
};

export const mapLayerPOITypeToRestrictionsType: Partial<Record<MapLayerPOIType, RestrictionsType>> =
    {
        available: 'enable_available_pois',
        custom: 'enable_custom_pois',
        nearby_activity: 'enable_nearby_activity_pois',
        closed: 'enable_closed_pois',
    };

export const useSearchApiRestrictionItems = () => {
    const items = useRestrictionSwitchFilters();
    const { type, restrictions } = useSearchApiFilters();
    const getRestrictionByMapLayerPOIType = useGetRestrictionByMapLayerPOIType();
    const updateSearchApiFilters = useUpdateSearchApiFilters();

    const onItemToggle = useCallback(
        (checked: boolean, id: string) => {
            const restriction: RestrictionsType = getRestrictionByMapLayerPOIType(
                id as MapLayerPOIType,
            );
            updateSearchApiFilters({
                restrictions: {
                    ...restrictions,
                    [restriction]: checked,
                },
            });
        },
        [getRestrictionByMapLayerPOIType, restrictions, updateSearchApiFilters],
    );

    return {
        items,
        type,
        onItemToggle,
    };
};

export const useRestSearchFilters = () => {
    const { setSearchApiFilters } = useSearchPoiContext();
    return useCallback(() => {
        setSearchApiFilters(defaultSearchApiFilters);
    }, [setSearchApiFilters]);
};
