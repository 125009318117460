import type { Dictionary } from '@placer-ui/types';
import { enrichSplunkEvent, saveMappings } from 'API/send-splunk/splunk-mapping';
import { getAppApiEndpoint } from 'core/default-endpoint-url';
import { reportException } from 'core/exceptions';
import { impersonateUserCookieStorage } from 'core/authentification';

const bulkTimeoutMs = 10000;
let bulkRequestItemsStorage: Dictionary<any>[] = [];

const sendSplunkBulk = (token: string | undefined) => {
    const originalUserData = impersonateUserCookieStorage.user;
    const reactAppApiEndpoint = getAppApiEndpoint();
    const url = `${reactAppApiEndpoint}/2/guru/splunk-bulk`;
    const headers: HeadersInit = {
        'Content-Type': 'application/json',
    };

    if (token) {
        headers['Authorization'] = `Token ${token}`;
    }

    saveMappings(bulkRequestItemsStorage);

    fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            messages: bulkRequestItemsStorage,
            client_send_timestamp_ms: Date.now(),
            ...(originalUserData ? { impersonating_user_name: originalUserData?.username } : {}),
        }),
    }).catch((response) => {
        reportException(response, {
            payload: {
                messages: bulkRequestItemsStorage,
                client_send_timestamp_ms: Date.now(),
                ...headers,
            },
            target_url: url,
            method: 'POST',
        });
    });
};

export const sendSplunk = (token: string | undefined, splunkData: Dictionary<any>) => {
    splunkData.client_timestamp_ms = Date.now();
    if (bulkRequestItemsStorage.length === 0) {
        window.setTimeout(() => {
            sendSplunkBulk(token);
            bulkRequestItemsStorage = [];
        }, bulkTimeoutMs);
    }
    splunkData = enrichSplunkEvent(splunkData);
    bulkRequestItemsStorage.push(splunkData);
};
