import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AccessInfo, Dictionary } from '@placer-ui/types';
import { ErrorType } from 'API/common/constants';

export type ReportAccess = {
    access: AccessInfo;
    error?: {
        status?: number;
        type?: string;
        info?: {
            details: string;
            error: ErrorType;
            error_code: number;
            request_id: string;
            result: string;
        };
    };
};

export type ReportAccessState = Dictionary<ReportAccess>;

export const initialReportAccessState: Dictionary<ReportAccess> = {};

export const reportAccessSlice = createSlice({
    name: 'reportAccess',
    initialState: initialReportAccessState,
    reducers: {
        setReportAccess: (state: ReportAccessState, action: PayloadAction<ReportAccessState>) => {
            return {
                ...state,
                ...action.payload,
            };
        },
    },
});

export const reportAccessActions = reportAccessSlice.actions;
