/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import classNames from 'classnames';
import styles from './alert-colored-icon.module.scss';
import React, { SVGProps } from 'react';
import { IconProps } from 'components/assets/Icons/Icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

const AlertColoredIconSvg = (props: CustomIconComponentProps | SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} width="16" height="16" viewBox="0 0 16 16">
        <g fill="none" fillRule="evenodd">
            <g transform="translate(-447 -435) translate(0 111) translate(447 324)">
                <circle cx="8" cy="8" r="7.5" fill="transparent" stroke="#FFF" />
                <g fillRule="nonzero">
                    <path
                        fill="#FFF"
                        d="M1.709 5.905L2 0H.023l.291 5.905H1.71zM1.006 9c.312 0 .556-.092.731-.275.175-.183.263-.434.263-.753 0-.33-.087-.584-.26-.761-.173-.177-.418-.266-.734-.266-.328 0-.577.087-.749.26-.171.173-.257.429-.257.767 0 .327.088.58.263.759.175.18.423.269.743.269z"
                        transform="translate(7 3.5)"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export const AlertColoredIcon = ({ className, ...props }: IconProps) => (
    <Icon
        component={AlertColoredIconSvg}
        {...props}
        className={classNames(styles.alertColored, className)}
    />
);
