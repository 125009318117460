import React from 'react';
import type { SVGProps } from 'react';

type SuggestionsIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type SuggestionsIconProps = SVGProps<SVGSVGElement> & SuggestionsIconUniqueProps;

export const SuggestionsIcon = function SuggestionsIcon({
    width = 20,
    height = 22,
    ...props
}: SuggestionsIconProps) {
    return (
        <svg {...props} width={width} height={height} viewBox="0 0 20 22">
            <g fill="none" fillRule="evenodd">
                <path
                    stroke="#49565D"
                    strokeWidth=".75"
                    d="M7.5 20v-6h5v6a1 1 0 0 1-1 1h-3a1 1 0 0 1-1-1z"
                />
                <path
                    stroke="#49565D"
                    strokeLinecap="round"
                    strokeWidth=".75"
                    d="M17 17l1.414 1.414M17 4.414L18.414 3M3 17l-1.414 1.414M3 4.414L1.586 3"
                />
                <path
                    fill="#D0D0D0"
                    d="M14.243 15.243a6 6 0 1 1-8.486-8.485 6 6 0 0 1 8.486 8.485"
                />
                <path
                    stroke="#49565D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth=".75"
                    d="M14.243 15.243a6 6 0 1 1-8.486-8.485 6 6 0 0 1 8.486 8.485z"
                />
                <path
                    stroke="#49565D"
                    strokeLinecap="round"
                    strokeWidth=".75"
                    d="M2 11H1M19 11h-1M10 2V1M7.5 19H11"
                />
            </g>
        </svg>
    );
};
SuggestionsIcon.__tags = ['lamp'] as string[];
