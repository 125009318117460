import React, { useMemo } from 'react';
import { PropsWithChildren } from 'react';
import { Route } from 'react-router-dom';
import { RouteProps } from 'react-router';

import { sanitizePath } from 'router/utils';
import { ErrorsManager } from 'components/errors/errors-manager/errors-manager';
import type { ErrorsManagerProps } from 'components/errors/errors-manager/errors-manager';

type PlRouteProps = PropsWithChildren<RouteProps> & {
    errorsManager?: Omit<ErrorsManagerProps, 'children'>;
};

export const PlRoute = ({ path, children, errorsManager, ...routeProps }: PlRouteProps) => {
    const pathGenerator = useMemo(() => {
        if (!path) return;
        return [...(typeof path === 'string' ? [path] : path)].map((url) => {
            return sanitizePath(url);
        });
    }, [path]);

    let content = children;

    if (errorsManager) {
        content = (
            <ErrorsManager
                uncaughtExceptionComponent={errorsManager.uncaughtExceptionComponent}
                page={errorsManager.page}
                tab={errorsManager.tab}
                section={errorsManager.section}
                subSection={errorsManager.subSection}
            >
                {children}
            </ErrorsManager>
        );
    }

    return (
        <Route path={pathGenerator} {...routeProps}>
            {content}
        </Route>
    );
};
