import React from 'react';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';
import { ToSupport } from 'ui-components/to-support/to-support';
import { Button } from 'ui-components/button/button';
import notAvailableImage from 'components/assets/Icons/mobile/not-available.webp';
import notAvailableImageFallback from 'components/assets/Icons/mobile/not-available.avif';
import { EmailIcon } from 'components/assets/Icons/IconsMobile';
import { useAuthActions } from 'store/auth/use-auth-actions';
import { LogoutIcon } from 'components/assets/Icons/LogoutIcon';
import { widgetHeaderHeightSignal } from 'features/mobile-report/components/analyze-page/state/analyze.signals';
import { ImgWithFallback } from 'features/mobile-report/shared/components/img-with-fallback/img-with-fallback';
import { useGoToExplore } from 'features/mobile-report/hooks/use-go-to-explore';

import styles from './unexpected-error.module.scss';

interface UnexpectedMobileErrorProps {
    isAnalyzePage?: boolean;
}

export const UnexpectedMobileError = ({ isAnalyzePage }: UnexpectedMobileErrorProps) => {
    const { logout } = useAuthActions();
    const goToExplore = useGoToExplore();
    const { pathname } = useLocation();

    const handleLogout = () => {
        logout();
    };

    const handleSearchClick = () => {
        pathname.includes('explore') ? window.location.reload() : goToExplore();
    };

    return (
        <div
            className={cn(styles.unexpectedErrorWrapper, { [styles.analyzeError]: isAnalyzePage })}
            data-testid={'mobile-error-page'}
            style={{ marginTop: isAnalyzePage ? widgetHeaderHeightSignal.value : 0 }}
        >
            <ImgWithFallback
                className={styles.unexpectedImage}
                src={notAvailableImage}
                fallbackSrc={notAvailableImageFallback}
                alt="unexpected error"
            />
            <div className={styles.title}>Unexpected error</div>
            <div className={styles.message}>
                Please contact <ToSupport inline />
            </div>
            <Button type="brand-primary-full" data-elevio-module={47} className={styles.button}>
                <EmailIcon /> Contact support
            </Button>
            <Button className={styles.button} onClick={handleSearchClick} type="link">
                Start a New Search
            </Button>
            <Button className={styles.logoutButton} onClick={handleLogout} type="secondary">
                <LogoutIcon className={styles.icon} />
                Log out
            </Button>
        </div>
    );
};
