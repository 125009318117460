import Color from 'color';
import { RGBAColor } from 'deck.gl';

export const PERMISSION = 'enable_trade_area_heatmap_v2';
export const ELEVIO_ID = '12';
export const SPLUNK_DATA = {
    component: 'trade-area-heatmap',
};

export const MAP_INITIAL_ZOOM = 8;
export const MAP_DOWNLOAD_OPTIONS = [
    {
        value: 'png',
        label: 'Image (PNG)',
    },
    {
        value: 'csv',
        label: 'Excel/CSV',
    },
    {
        value: 'shp',
        label: 'SHP',
    },
    {
        value: 'kmz',
        label: 'KMZ',
    },
];

export const LEGEND_COLORS = ['#6a71e5', '#48dc6b', '#ffd201', '#ffa500', '#ff4966'];
export const LEGEND_RGB: RGBAColor[] = LEGEND_COLORS.map(
    (hex) => new Color(hex).rgb().array() as RGBAColor,
);
