const elevio = (window as any)._elev;

export type ElevioPopupType = 'article' | 'popup' | 'module' | 'category' | 'component' | 'home';

/**
 * Normally we'd attach the id to an HTML element.
 * Use this utils in order to open an Elev.io component programmatically.
 * @param elevioId
 * @param type
 */
export const openElevioComponent = (
    elevioId: string | number,
    type: ElevioPopupType = 'article',
    refComponent?: HTMLDivElement | HTMLButtonElement | null,
) => {
    if (elevio) {
        switch (type) {
            case 'article':
                elevio.openArticle(elevioId);
                return;
            case 'module':
                elevio.openModule(elevioId);
                return;
            case 'popup':
                elevio.openPopup(elevioId);
                return;
            case 'category':
                elevio.openCategory(elevioId);
                return;
            case 'component':
                elevio
                    .popupComponent(refComponent, {
                        articleId: elevioId,
                    })
                    .insert();
                return;
            case 'home':
                elevio.openHome();
                return;
            default:
                elevio.open(elevioId);
                return;
        }
    }
};

export const setElevioLauncherVisible = (visible: boolean) =>
    elevio.setSettings({
        hideLauncher: !visible,
    });

export const ELEVIO_DEFAULT_TOOLTIP = 'Learn More';
