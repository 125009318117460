/* eslint-disable max-len */
import React from 'react';
import styles from 'features/void-analysis/sections/add-new-report/components/add-new-report-button/add-new-report-styles.module.scss';
import { Button } from 'ui-components/button/button';
import { AddNewReportLockButton } from 'features/void-analysis/sections/add-new-report/components/add-new-report-button/add-new-report-lock-button/add-new-report-lock-button';
import { ADD_NEW_REPORT_LABEL } from 'features/void-analysis/constants';

type AddNewReportButtonProps = {
    onClick: () => void;
    isLocked?: boolean;
};
export const AddNewReportButton = ({ onClick, isLocked }: AddNewReportButtonProps) => {
    return isLocked ? (
        <AddNewReportLockButton />
    ) : (
        <Button type="brand-primary-full" className={styles.addNewReportButton} onClick={onClick}>
            {ADD_NEW_REPORT_LABEL}
        </Button>
    );
};
