import React from 'react';
import { ExploreUIStateContextProvider } from 'features/explore/context/explore-ui-state-provider';
import { SearchOptionsDialogComponent } from 'components/search-options-dialog/v2/components/search-options-dialog-content';

export const SearchOptionsDialog = () => {
    return (
        <ExploreUIStateContextProvider>
            <SearchOptionsDialogComponent />
        </ExploreUIStateContextProvider>
    );
};
