import { MAX_COMPETITORS_QUANTITY } from 'features/industry/common/constants';
import { ChainsReportState } from 'features/industry/store/chains-store';
import { selectChainsEntityModelsSelectedForReportInSearch } from 'features/industry/store/chains-report-search-store';

export const selectNumberOfChainsSelectedForReportInSearch = (state: ChainsReportState) => {
    const selectedChainsEntityModels = selectChainsEntityModelsSelectedForReportInSearch(state);
    return selectedChainsEntityModels.length;
};

export const selectUserCanAddMoreChainsToReportFromSearch = (state: ChainsReportState) => {
    const numberOfChainsSelectedForReport = selectNumberOfChainsSelectedForReportInSearch(state);
    return numberOfChainsSelectedForReport < MAX_COMPETITORS_QUANTITY;
};

export const selectUserCanUpdateReportFromSearch = (state: ChainsReportState) => {
    const numberOfChainsSelectedForReport = selectNumberOfChainsSelectedForReportInSearch(state);
    return numberOfChainsSelectedForReport > 0;
};
