import { User } from 'core/entities';
import { initExceptionHandler, setExceptionHandlerUser } from 'core/exceptions';
import { installHeapAnalytics } from 'shared/extensions/heap-analytics/heap-analytics';
import { isAutomationTest } from 'shared/test-utils/automation-test';
import { segmentIdentify } from 'tracking/segment/segment-identify';
import { initElevio } from 'utils/elevio/init-elevio';
import { fullStoryInit } from 'utils/full-story/full-story';
import { initGoogleAnalytics } from 'utils/google-analytics/google-analytics';
import { heapIdentify } from 'utils/heap/heap';
import { version } from '../../package.json';
import { initSegmentAnalytics } from 'tracking/segment/segment';

initIntegrations();

function initIntegrations() {
    initExceptionHandler({
        release: version,
        dsn: process.env.REACT_APP_SENTRY_DSN!,
        env: process.env.REACT_APP_ENV,
    });

    initSegmentAnalytics();

    installHeapAnalytics();

    initGoogleAnalytics();
}

export const initUserIntegrations = (user: User) => {
    setExceptionHandlerUser({
        accountId: user.settings.account?.id,
        isAutomation: isAutomationTest(),
        user,
    });

    fullStoryInit(!!user.featureFlags.enable_full_story_ff, user.settings);

    initElevio(user);

    segmentIdentify(user);

    heapIdentify(user.email);
};
