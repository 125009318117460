import React from 'react';
import { Tooltip } from 'ui-components/tooltip/tooltip';
import { AlertColoredIcon } from 'components/assets/Icons/Icons';
import styles from './locked-status-indicator.module.scss';

type ExploreListItemStatusLockedType = {
    textClassName?: string;
};

export const LOCKED_POI_STATIC_DATA = {
    poiText: 'Locked. Premium account is required',
    poiTooltip:
        'Upgrade to a Premium Account to unlock insights for this property and millions more.',
};

export const LockedStatusIndicator = ({ textClassName }: ExploreListItemStatusLockedType) => {
    return (
        <Tooltip small title={LOCKED_POI_STATIC_DATA.poiTooltip} placement="top">
            <div className={styles.statusIndicator}>
                <AlertColoredIcon className={styles.alertIcon} />
                <span className={textClassName}>{LOCKED_POI_STATIC_DATA.poiText}</span>
            </div>
        </Tooltip>
    );
};
