/* eslint-disable max-len */
import React from 'react';

export const SgLeisureIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="22"
            viewBox="0 0 21 22"
            fill="none"
        >
            <rect
                x="1.875"
                y="8.375"
                width="17.25"
                height="13.25"
                fill="#E1E2FF"
                stroke="#5E63E5"
                strokeWidth="0.75"
            />
            <mask id="path-2-inside-1_3566_18550" fill="white">
                <rect x="3.5" y="10" width="3" height="3" rx="0.5" />
            </mask>
            <rect
                x="3.5"
                y="10"
                width="3"
                height="3"
                rx="0.5"
                fill="white"
                stroke="#5E63E5"
                strokeWidth="1.5"
                mask="url(#path-2-inside-1_3566_18550)"
            />
            <mask id="path-3-inside-2_3566_18550" fill="white">
                <rect x="3.5" y="15" width="3" height="3" rx="0.5" />
            </mask>
            <rect
                x="3.5"
                y="15"
                width="3"
                height="3"
                rx="0.5"
                fill="white"
                stroke="#5E63E5"
                strokeWidth="1.5"
                mask="url(#path-3-inside-2_3566_18550)"
            />
            <mask id="path-4-inside-3_3566_18550" fill="white">
                <rect x="14.5" y="15" width="3" height="3" rx="0.5" />
            </mask>
            <rect
                x="14.5"
                y="15"
                width="3"
                height="3"
                rx="0.5"
                fill="white"
                stroke="#5E63E5"
                strokeWidth="1.5"
                mask="url(#path-4-inside-3_3566_18550)"
            />
            <rect
                x="8.375"
                y="15.375"
                width="4.25"
                height="6.25"
                fill="white"
                stroke="#5E63E5"
                strokeWidth="0.75"
            />
            <mask id="path-6-inside-4_3566_18550" fill="white">
                <rect x="9" y="10" width="3" height="3" rx="0.5" />
            </mask>
            <rect
                x="9"
                y="10"
                width="3"
                height="3"
                rx="0.5"
                fill="white"
                stroke="#5E63E5"
                strokeWidth="1.5"
                mask="url(#path-6-inside-4_3566_18550)"
            />
            <mask id="path-7-inside-5_3566_18550" fill="white">
                <rect x="14.5" y="10" width="3" height="3" rx="0.5" />
            </mask>
            <rect
                x="14.5"
                y="10"
                width="3"
                height="3"
                rx="0.5"
                fill="white"
                stroke="#5E63E5"
                strokeWidth="1.5"
                mask="url(#path-7-inside-5_3566_18550)"
            />
            <path
                d="M10.5 1.61803L10.698 2.22746L10.8103 2.57295H11.1735H11.8143L11.2959 2.94959L11.002 3.16312L11.1143 3.50861L11.3123 4.11803L10.7939 3.74139L10.5 3.52786L10.2061 3.74139L9.6877 4.11803L9.88571 3.50861L9.99797 3.16312L9.70408 2.94959L9.18567 2.57295H9.82646H10.1897L10.302 2.22746L10.5 1.61803Z"
                fill="#5E63E5"
                stroke="#5E63E5"
            />
            <path
                d="M15.5 3.61803L15.5858 3.88197H15.8633L15.6388 4.04508L15.7245 4.30902L15.5 4.1459L15.2755 4.30902L15.3612 4.04508L15.1367 3.88197H15.4142L15.5 3.61803Z"
                fill="#5E63E5"
                stroke="#5E63E5"
            />
            <path
                d="M5.5 3.61803L5.58576 3.88197H5.86327L5.63876 4.04508L5.72451 4.30902L5.5 4.1459L5.27549 4.30902L5.36124 4.04508L5.13673 3.88197H5.41424L5.5 3.61803Z"
                fill="#5E63E5"
                stroke="#5E63E5"
            />
        </svg>
    );
};
