import type {
    RequestFilterEntity,
    FilterRequestTypeFilterSubstring,
} from 'features/void-analysis/common/types/request-filters';

export function removeEmptyFilters(filters: RequestFilterEntity[]): RequestFilterEntity[] {
    const cleanedFilters: RequestFilterEntity[] = [];
    filters.forEach((filter) => {
        if (
            (filter as FilterRequestTypeFilterSubstring).name === 'Chain Name Substring' &&
            !(filter as FilterRequestTypeFilterSubstring).string
        ) {
            return;
        }

        cleanedFilters.push(filter);
    });
    return cleanedFilters;
}
