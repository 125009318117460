import React from 'react';
import { PoiSelectionMenuItem } from 'features/poi-selection-map/types/poi-selection-types';
import { TagsSelectorMenu } from 'features/poi-selection-map/poi-selection-menu/menu-items/tags-menu-items/tags-selector-menu/tags-selector-menu';

export const COMPANY_TAGS_ITEM_ID = 'company menu item';

export const CompanyTagsMenuItem: PoiSelectionMenuItem = {
    id: COMPANY_TAGS_ITEM_ID,
    title: 'Company Tags',
    render: ({ visible }) => {
        return <>{visible && <TagsSelectorMenu type={'companyTags'} />}</>;
    },
};
