import { createAsyncThunk } from '@reduxjs/toolkit';

import { ChainEntityModel } from 'core/entities';
import { chainsAPI } from 'API/chains-api';
import { ChainsReportState } from 'features/chains/store/chains-store';

import {
    selectChainsReportSearchSearchTerm,
    selectChainsSearchSelectedCategories,
    selectChainsSearchResultsDateRange,
    selectChainsSearchSelectedRegionName,
} from '../../selectors';
import {
    setChainsSearchResults,
    setChainsSearchResultsPotentiallyMoreResultsExist,
} from 'features/chains/store/chains-report-search-store';
import { ChainEntityModelMapper } from 'features/chains/common/util/chain-placer-entity-model-mapper';
import { AREA_TYPE_NATIONWIDE } from 'core/services/regions-service/constants';
import { Region } from 'core/dto/region';
import { API } from 'API';
import { selectIsChainsReportLimited } from 'features/chains/store/chains-report-chains-store';

export const CHAINS_LIMIT = 12;

type AppendChainResultsForSearchTermActionProps = {
    chainsEntityModels: ChainEntityModel[];
    hasChainSubCategorySelectorPermission?: boolean;
};

const getSelectedRegionByName = async (selectedRegionName: string) => {
    if (selectedRegionName === AREA_TYPE_NATIONWIDE) {
        return { type: AREA_TYPE_NATIONWIDE } as Partial<Region>;
    }

    const { data: regionDataByName } = await API.regions.getRegions({
        query: selectedRegionName,
    });
    const selectedRegion = regionDataByName.find((region) =>
        selectedRegionName.includes(region.name),
    );

    if (selectedRegion) {
        return {
            type: selectedRegion.type,
            code: selectedRegion.code,
        } as Partial<Region>;
    }

    return selectedRegion;
};

const createAppendChainResultsForSearchTerm = (chainsApi: typeof chainsAPI) =>
    createAsyncThunk(
        'chains/getChainResultsForSearchTerm',
        async (
            {
                chainsEntityModels,
                hasChainSubCategorySelectorPermission,
            }: AppendChainResultsForSearchTermActionProps,
            { dispatch, getState },
        ) => {
            const state = getState() as ChainsReportState;

            const searchTerm = selectChainsReportSearchSearchTerm(state);
            const selectedCategories = selectChainsSearchSelectedCategories(state);
            const selectedRegionName = selectChainsSearchSelectedRegionName(state);
            const { startDate, endDate } = selectChainsSearchResultsDateRange(state);
            const selectedRegion = await getSelectedRegionByName(selectedRegionName);
            const isLimited = selectIsChainsReportLimited(state);

            if (
                !searchTerm &&
                (!selectedCategories || !selectedCategories.length) &&
                !selectedRegion
            ) {
                dispatch(setChainsSearchResults([]));

                return;
            }

            const { data: matchingChains } = await chainsApi.getChainsByTerm({
                term: searchTerm,
                skip: chainsEntityModels.length,
                limit: CHAINS_LIMIT,
                categories: selectedCategories,
                purchasedOnly: !isLimited,
                region_type: selectedRegion?.type,
                region_code: selectedRegion?.code,
                subCategoryPermission: hasChainSubCategorySelectorPermission,
            });

            const chainsInfos = matchingChains.map((chain) =>
                ChainEntityModelMapper.mapEntityWrapperToEntityModel({
                    entityWrapper: chain,
                    startDate,
                    endDate,
                }),
            );

            dispatch(
                setChainsSearchResultsPotentiallyMoreResultsExist(
                    chainsInfos.length === CHAINS_LIMIT,
                ),
            );
            dispatch(setChainsSearchResults([...chainsEntityModels, ...chainsInfos]));
        },
    );

export const getFilteredChainResults = createAppendChainResultsForSearchTerm(chainsAPI);
