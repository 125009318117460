import React, { useCallback, useMemo, useState } from 'react';
import { MethodSelectorModal } from 'features/void-analysis/sections/empty-space/components/method-selection/method-selector-modal/method-selector-modal';
import { ButtonWithTooltip } from 'ui-components/button-with-tooltip/button-with-tooltip';
import styles from './run-void-analysis-button.module.scss';
import { PoiEmptySpace } from 'features/void-analysis/sections/empty-space/types/run-void-analysis-button-types';
import classNames from 'classnames';
import { usePlacePoiToLocationOption } from 'features/void-analysis/sections/empty-space/hooks/use-method-selector-modal-hooks';
import {
    convertToSplunkFormat,
    createMethodSelectorSplunkData,
    useSendEntryPointSplunkEvent,
} from 'features/void-analysis/sections/entry-point-redirection/hooks/entry-point-tracking-hooks';
import { CardMethodNames } from 'features/void-analysis/sections/empty-space/types/method-card';
import { PoiSelectionSplunkData } from 'features/void-analysis/sections/entry-point-redirection/types/entry-point-tracking-types';
import { EMPTY_SPACE_COORDINATE_ID } from 'features/empty-space/constants/empty-space-constants';
import { GENERATE_CUSTOM_COORDINATE_REPORT } from 'shared/API/report-access-api/constants';
import { usePoiSelectionContext } from 'features/poi-selection-map/context/poi-selection-context';
import { getFeatureFlags } from 'core/flow-control';
import { getRunBtnTooltipText } from 'features/empty-space/utils/utils';

type RunVoidAnalysisButtonProps = {
    poi: PoiEmptySpace;
    show: boolean;
    className?: string;
    buttonClassName?: string;
    buttonContainerClassName?: string;
    modalContainer?: HTMLElement | null;
    splunkData?: PoiSelectionSplunkData;
};

export const RunVoidAnalysisForEmptySpaceButton = ({
    poi,
    show,
    className,
    buttonClassName,
    buttonContainerClassName,
    modalContainer,
    splunkData,
}: RunVoidAnalysisButtonProps) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const onModalClose = () => setShowModal(false);
    const { isOnlineReportSupported, tooltipContent, locationData } =
        usePlacePoiToLocationOption(poi);
    const { enable_sensitive_location_improvements_void_explore_ff } = getFeatureFlags();
    const sendEmptySpaceSplunkEvent = useSendEntryPointSplunkEvent();
    const { reportAccessError, isReportAccessLoading } = usePoiSelectionContext();

    const onClick = () => {
        if (splunkData && poi.id) {
            sendEmptySpaceSplunkEvent(convertToSplunkFormat(splunkData, poi.id));
        }

        setShowModal(true);
    };

    const onRunVoidReportFromModal = useCallback(
        (id?: string, method?: CardMethodNames) => {
            if (splunkData) {
                sendEmptySpaceSplunkEvent(
                    createMethodSelectorSplunkData({
                        poiId: id || '',
                        poiSelectionSplunkData: splunkData,
                        method,
                    }),
                );
            }
        },
        [sendEmptySpaceSplunkEvent, splunkData],
    );

    const runVoidBtnStatus = useMemo(() => {
        if (!enable_sensitive_location_improvements_void_explore_ff) {
            return {
                isLoading: false,
                disabledTooltipContent: tooltipContent,
            };
        }

        let disabledTooltipContent = tooltipContent;

        if (
            poi.id &&
            (reportAccessError?.[EMPTY_SPACE_COORDINATE_ID]?.info?.error ||
                reportAccessError?.[poi.id]?.info?.error)
        ) {
            const poiError = reportAccessError?.[poi.id]?.info?.error || '';
            disabledTooltipContent = getRunBtnTooltipText(poiError, poi.id);
        }

        return {
            isLoading: isReportAccessLoading,
            disabledTooltipContent,
        };
    }, [
        enable_sensitive_location_improvements_void_explore_ff,
        isReportAccessLoading,
        poi.id,
        reportAccessError,
        tooltipContent,
    ]);

    return (
        <div className={classNames(styles.container, className)}>
            <div className={classNames(styles.buttonContainer, { [styles.show]: show })}>
                <ButtonWithTooltip
                    tooltip={
                        runVoidBtnStatus.isLoading
                            ? GENERATE_CUSTOM_COORDINATE_REPORT
                            : runVoidBtnStatus.disabledTooltipContent
                    }
                    disabled={!isOnlineReportSupported}
                    buttonText={'Run Void Analysis'}
                    isLoading={runVoidBtnStatus.isLoading}
                    onClick={onClick}
                    buttonProps={{
                        className: classNames(styles.addNewReportButton, buttonClassName),
                        type: runVoidBtnStatus.isLoading ? 'default' : 'brand-primary-full',
                    }}
                    buttonContainerClassName={buttonContainerClassName}
                />
            </div>
            <MethodSelectorModal
                visible={showModal}
                onClose={onModalClose}
                locationOption={locationData}
                modalContainer={modalContainer}
                onRunVoidReport={onRunVoidReportFromModal}
            />
        </div>
    );
};
