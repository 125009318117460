import { PlacePOI } from '@advanced-mapping/shared-utils';
import { useCallback } from 'react';
import {
    getReportEntityClosingDate,
    getReportEntityFlag,
} from 'core/services/report-entities-service/report-entities-service';
import { dateFormats, getSimpleFormatDate } from 'utils/date/date';
import type { Complex } from '@placer-ui/types';
import {
    exploreEnclosingComplexesSignal,
    exploreOverlayPOIsSignal,
} from 'features/mobile-report/components/explore-mobile/state/explore.signals';
import { Place as PlaceAdvancedMappingType } from '@advanced-mapping/shared-types';

export const useCheckPoiForPropertyReport = () => {
    return useCallback(
        (poi: Complex) => {
            const { name, provider_data } = poi;
            const flag = getReportEntityFlag(poi);
            const closingDate = getReportEntityClosingDate(poi);
            const enclosingComplexes = exploreEnclosingComplexesSignal.value[poi.id];
            if (!flag && !closingDate && !enclosingComplexes && !provider_data?.provider) {
                return {
                    text: '',
                    disabled: false,
                };
            }

            if (
                enclosingComplexes &&
                enclosingComplexes.length > 0 &&
                exploreOverlayPOIsSignal.value[poi.id]
            ) {
                return {
                    text: `${name} is located within ${enclosingComplexes?.[0].name} and
    only supports a report for the enclosing property or Nearby Activity`,
                    disabled: true,
                };
            }
            if (flag === 'flagged_dense_area') {
                return {
                    text: `${name} is located within a dense area and only supports other report options.`,
                    disabled: true,
                };
            }
            if (closingDate) {
                return {
                    text: `${name} is closed and only supports property reports through ${getSimpleFormatDate(
                        closingDate!,
                        dateFormats.shortMonthYearWithNumericOrdinalContractionDayComma,
                    )} or a Nearby Activity report.`,
                    disabled: false,
                };
            }
            if (provider_data?.provider && provider_data?.entity_type === 'address') {
                return {
                    text: 'Property report is not available for addresses.',
                    disabled: true,
                };
            }
            if (
                provider_data?.provider &&
                PlacePOI.getPrimaryCategory(poi as PlaceAdvancedMappingType) === 'Region'
            ) {
                return {
                    text: 'This property only supports other report options.',
                    disabled: true,
                };
            }
            return {
                text: `${name} only supports a Nearby Activity report.`,
                disabled: true,
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [exploreEnclosingComplexesSignal.value, exploreOverlayPOIsSignal.value],
    );
};
