import { useCallback, useEffect } from 'react';
import {
    useSetActiveId,
    useSetCoordinatesError,
    useSetCoordinatesList,
    useSetCustomPoisAddresses,
    useSetDrawerResult,
    useSetOverlayPOI,
    useSetOverlayPOIOnce,
    useSetResults,
    useSetSuggestion,
} from 'features/explore/context/use-explore-ui-state-actions';
import { useNearbyActivityRadiusDefault } from 'hooks/use-nearby-activity-radius/use-nearby-activity-radius';
import { ExploreSearchResult } from 'features/explore/types/types';
import { createPlaceWithNearbyResult } from 'utils/create-place-with-nearby-result/create-place-with-nearby-result';
import { getNearbyPlaceOverlay } from 'ui-components/google-map-layers/utils/get-place-overlay';
import type { PlaceOverlay } from 'ui-components/google-map-layers/types/google-map-layers-types';
import { getFeatureFlags } from 'core/flow-control';
import { ADDRESS_ENTITY, COORDINATES_ENTITY } from 'features/explore/constants';
import {
    ADDRESS_IN_RESTRICTED_AREA,
    ADDRESS_NOT_DELIVERABLE_ERROR,
    COORDINATE_GENERAL_ERROR_TOOLTIP,
    COORDINATE_IN_RESTRICTED_AREA,
    COORDINATE_NOT_DELIVERABLE_ERROR,
    COORDINATE_OUTSIDE_OF_US_ERROR,
    COORDINATE_OUTSIDE_OF_US_TOOLTIP_TEXT,
    GENERATE_CUSTOM_COORDINATE_REPORT,
} from 'shared/API/report-access-api/constants';
import {
    createCustomPOIFromAddressCoordinate,
    isPoiRestrictedCoordinate,
    preCustomPOICreation,
} from 'features/explore/utils/utils';
import { useExploreUIState } from 'features/explore/context/use-explore-ui-state';
import { ERRORS } from 'API/common/constants';
import {
    type ExternalVenueProviders,
    PlacerEntityWrapper,
    Venue,
    type VenuePlaceTypes,
} from '@placer-ui/types';
import { EMPTY_SPACE_POI_TYPE } from 'features/empty-space/constants/empty-space-constants';
import { Complex } from '@placer-ui/types/src/entities/place';
import {
    checkReportAccessForCoordinate,
    ReportAccessCoordinate,
} from 'API/report-access-api/report-access-api';
import isEqual from 'lodash/isEqual';
import { batch } from 'react-redux';

export const useTransformNearbyActivity = () => {
    const setOverlayPOI = useSetOverlayPOIOnce();
    const defaultRadius = useNearbyActivityRadiusDefault();

    return useCallback(
        (results: ExploreSearchResult[]) => {
            batch(() => {
                results.forEach((result) => {
                    setOverlayPOI(
                        result.info.id,
                        getNearbyPlaceOverlay(
                            createPlaceWithNearbyResult(result.info, defaultRadius),
                        ),
                    );
                });
            });
        },
        [defaultRadius, setOverlayPOI],
    );
};

export const useCheckCoordinateForNearbyReport = () => {
    const { enable_explore_drop_pin_improvements_ff } = getFeatureFlags();
    const { coordinatesError } = useExploreUIState();

    return useCallback(
        (poi?: PlaceOverlay) => {
            const isCoordinateEmptySpace = isPoiRestrictedCoordinate(poi);
            if (isCoordinateEmptySpace && !!enable_explore_drop_pin_improvements_ff && poi) {
                let tooltipTextError;
                const errorInfo = coordinatesError?.info;
                if (errorInfo?.details === COORDINATE_OUTSIDE_OF_US_ERROR) {
                    tooltipTextError = COORDINATE_OUTSIDE_OF_US_TOOLTIP_TEXT;
                } else if (errorInfo?.error === ERRORS.POLYGON_TOO_SMALL) {
                    tooltipTextError =
                        poi.provider_data?.entity_id === ADDRESS_ENTITY
                            ? ADDRESS_IN_RESTRICTED_AREA
                            : COORDINATE_IN_RESTRICTED_AREA;
                } else if (errorInfo?.error === ERRORS.UNDELIVERABLE_ENTITY) {
                    tooltipTextError =
                        poi.provider_data?.entity_id === ADDRESS_ENTITY
                            ? ADDRESS_NOT_DELIVERABLE_ERROR
                            : COORDINATE_NOT_DELIVERABLE_ERROR;
                } else {
                    tooltipTextError = COORDINATE_GENERAL_ERROR_TOOLTIP;
                }
                return {
                    text:
                        poi.profile === 'not_available'
                            ? tooltipTextError
                            : GENERATE_CUSTOM_COORDINATE_REPORT,
                    disabled: true,
                    isLoading: poi.profile !== 'not_available',
                };
            }
            return {
                text: '',
                disabled: false,
                isLoading: false,
            };
        },
        [enable_explore_drop_pin_improvements_ff, coordinatesError],
    );
};

export const useSetNearbyPOIOverlay = () => {
    const setOverlayPOI = useSetOverlayPOI();
    const defaultRadius = useNearbyActivityRadiusDefault();

    return useCallback(
        (sourcePoi: PlacerEntityWrapper<Venue>) => {
            setOverlayPOI({
                sourcePoi: sourcePoi.info.id,
                overlayPoi: getNearbyPlaceOverlay(
                    createPlaceWithNearbyResult(sourcePoi.info, defaultRadius),
                ),
            });
        },
        [defaultRadius, setOverlayPOI],
    );
};

/**
 * In case the drawer (in the search panel) is displayed:
 * This effect will update the drawer after the selectedSuggestion is updated.
 * This will update the status of the action buttons inside the drawer
 */
const useUpdateSuggestedInDrawer = () => {
    const { selectedSuggestion, drawerResult, customPoiAddresses } = useExploreUIState();
    const setDrawerResult = useSetDrawerResult();

    useEffect(() => {
        const isSelectedSuggestionAnAddressPOI = selectedSuggestion?.info.provider_data?.entity_id;

        if (
            !isSelectedSuggestionAnAddressPOI ||
            !drawerResult ||
            isEqual(drawerResult, selectedSuggestion)
        )
            return;
        setDrawerResult(selectedSuggestion);
    }, [customPoiAddresses, drawerResult, selectedSuggestion, setDrawerResult]);
};

/**
 * This effect will update the result list after the selectedSuggestion is updated.
 * This will update the status of the action buttons within the map and in the exploration list
 */
const useUpdateSuggestedInResults = () => {
    const { results, selectedSuggestion } = useExploreUIState();
    const setResults = useSetResults();

    useEffect(() => {
        if (!selectedSuggestion) return;

        const suggestionIndex = results.findIndex(
            ({ info }) => info.id === selectedSuggestion?.info.id,
        );

        if (
            suggestionIndex !== -1 &&
            !isEqual(results[suggestionIndex].info, selectedSuggestion?.info)
        ) {
            const newResults = [...results];
            newResults.splice(suggestionIndex, 1, selectedSuggestion);
            setResults(newResults);
        }
    }, [results, selectedSuggestion, setResults]);
};

export const useHandleAddressPOI = () => {
    const defaultRadius = useNearbyActivityRadiusDefault();
    const setNearbyPoiOverlay = useSetNearbyPOIOverlay();
    const setCoordinatesError = useSetCoordinatesError();
    const setActiveId = useSetActiveId();
    const setSuggestion = useSetSuggestion();
    const setPoiAddresses = useSetCustomPoisAddresses();
    const { customPoiAddresses } = useExploreUIState();

    // These 2 next hooks update the action buttons on the side panel in response to the selectedSuggestion change
    useUpdateSuggestedInDrawer();
    useUpdateSuggestedInResults();

    return useCallback(
        async (poi: PlacerEntityWrapper<Venue>, signal?: AbortSignal) => {
            let customPoiProfile = 'nearby_activity';
            let poiType: VenuePlaceTypes = 'complex';

            const preCustomPOIdEntityInfo = preCustomPOICreation(poi);

            setNearbyPoiOverlay(poi);
            setActiveId(poi.info.id);
            setSuggestion({
                ...poi,
                info: {
                    ...poi.info,
                    type: EMPTY_SPACE_POI_TYPE,
                    provider_data: {
                        ...preCustomPOIdEntityInfo.provider_data,
                        entity_id: ADDRESS_ENTITY,
                    },
                    access: {
                        level: 'not_allowed',
                        warnings: [],
                    },
                },
            } as ExploreSearchResult);

            const entityProviderId = poi.info.id;

            try {
                if (!customPoiAddresses[entityProviderId]) {
                    const createdProperty = await createCustomPOIFromAddressCoordinate(
                        {
                            ...poi,
                            info: {
                                ...poi.info,
                                type: poiType,
                            },
                        },
                        defaultRadius,
                        signal,
                    );

                    setPoiAddresses({
                        ...customPoiAddresses,
                        [entityProviderId]: createdProperty.id,
                    });
                }
                // set context dictionary for entity_id: custom poi id
            } catch (error: any) {
                setCoordinatesError(error?.common || error);
                poiType = EMPTY_SPACE_POI_TYPE as VenuePlaceTypes;
                customPoiProfile = 'not_available';
            }

            let reportAccessWarning: ReportAccessCoordinate = {
                access: {
                    level: 'full',
                    warnings: [],
                },
            };
            if (poi.info.geolocation) {
                try {
                    const { data } = await checkReportAccessForCoordinate({
                        lat: poi.info.geolocation.lat,
                        lng: poi.info.geolocation.lng,
                    });
                    reportAccessWarning = data;
                } catch (e) {
                    console.error(e);
                }
            }

            const newCustomPoi = {
                info: {
                    ...poi.info,
                    ...preCustomPOIdEntityInfo,
                    type: poiType as VenuePlaceTypes,
                    profile: customPoiProfile,
                    is_custom_poi: customPoiProfile !== 'not_available',
                    provider_data: {
                        ...preCustomPOIdEntityInfo.provider_data,
                        entity_id:
                            customPoiProfile === 'not_available'
                                ? ADDRESS_ENTITY
                                : preCustomPOIdEntityInfo.provider_data.entity_id,
                    },
                    access: reportAccessWarning.access,
                },
            } as ExploreSearchResult;

            setNearbyPoiOverlay(newCustomPoi as PlacerEntityWrapper<Venue>);
            setSuggestion(newCustomPoi);
        },
        [
            customPoiAddresses,
            defaultRadius,
            setActiveId,
            setCoordinatesError,
            setNearbyPoiOverlay,
            setPoiAddresses,
            setSuggestion,
        ],
    );
};

export const useHandleDropPinPOI = () => {
    const { results, coordinatesPois } = useExploreUIState();
    const setResults = useSetResults();
    const setCoordinates = useSetCoordinatesList();
    const setCoordinatesError = useSetCoordinatesError();
    const setActivePoi = useSetActiveId();
    const setNearbyPoiOverlay = useSetNearbyPOIOverlay();
    const defaultRadius = useNearbyActivityRadiusDefault();

    return useCallback(
        async (poiInfo: Complex) => {
            const sharedEntityInfo = {
                name: `${poiInfo.geolocation?.lat.toFixed(5)}, ${poiInfo.geolocation?.lng.toFixed(
                    5,
                )}`,
                category: 'Address',
                sub_category: 'Address',
                provider_data: {
                    provider: 'mapbox' as ExternalVenueProviders,
                    entity_type: 'address',
                    entity_id: COORDINATES_ENTITY,
                },
                profile: 'nearby_activity',
                filter: {
                    config: {
                        ft_radius: 250,
                    },
                },
                purchased: true,
            };

            const emptySpacePoi = {
                info: {
                    ...poiInfo,
                    ...sharedEntityInfo,
                },
            } as ExploreSearchResult;

            const newResultWithEmptySpace = [emptySpacePoi, ...results];
            const newResultWithCoordinates = [emptySpacePoi, ...coordinatesPois];

            setResults(newResultWithEmptySpace);
            setCoordinates(newResultWithCoordinates);

            const { address } = poiInfo;

            let customPoiId = emptySpacePoi.info.id;
            let customPoiProfile = 'nearby_activity';
            let poiType = 'complex';
            try {
                setNearbyPoiOverlay(emptySpacePoi as PlacerEntityWrapper<Venue>);
                setActivePoi(emptySpacePoi.info.id);
                const createdProperty = await createCustomPOIFromAddressCoordinate(
                    {
                        info: {
                            ...poiInfo,
                            ...sharedEntityInfo,
                            address,
                            type: poiType as VenuePlaceTypes,
                        },
                    } as ExploreSearchResult,
                    defaultRadius,
                );
                customPoiId = createdProperty.id;
            } catch (error: any) {
                setCoordinatesError(error?.common || error);
                poiType = EMPTY_SPACE_POI_TYPE;
                customPoiProfile = 'not_available';
            }

            let reportAccessWarning: ReportAccessCoordinate = {
                access: {
                    level: 'full',
                    warnings: [],
                },
            };
            if (emptySpacePoi.info.geolocation) {
                try {
                    const { data } = await checkReportAccessForCoordinate({
                        lat: emptySpacePoi.info.geolocation.lat,
                        lng: emptySpacePoi.info.geolocation.lng,
                    });
                    reportAccessWarning = data;
                } catch (e) {
                    console.error(e);
                }
            }

            const newCustomPoi = {
                info: {
                    ...poiInfo,
                    ...sharedEntityInfo,
                    id: customPoiId,
                    type: poiType as VenuePlaceTypes,
                    profile: customPoiProfile,
                    access: reportAccessWarning.access,
                },
            } as ExploreSearchResult;

            const newResult = newResultWithEmptySpace.slice(0);
            const newCoordinatesResult = newResultWithCoordinates.slice(0);
            newResult.shift();
            newCoordinatesResult.shift();
            newResult.unshift(newCustomPoi);
            newCoordinatesResult.unshift(newCustomPoi);

            setResults(newResult);
            setCoordinates(newCoordinatesResult);

            setNearbyPoiOverlay(newCustomPoi as PlacerEntityWrapper<Venue>);
            setActivePoi(newCustomPoi.info.id);

            return newCustomPoi;
        },
        [
            coordinatesPois,
            defaultRadius,
            results,
            setActivePoi,
            setCoordinates,
            setCoordinatesError,
            setNearbyPoiOverlay,
            setResults,
        ],
    );
};
