import { createAsyncThunk } from '@reduxjs/toolkit';

import { API } from 'API';
import { StarredReport } from 'core/entities';

export const updateStarredReportLastViewedDate = createAsyncThunk(
    'starred-report/update-last-viewed-date',
    async (report: StarredReport, { rejectWithValue }) => {
        try {
            const now = Date.now().toString();
            await API.starReport.updateLastViewedDate({
                report,
                lastViewedDate: now,
            });

            report.last_viewed_date = now;

            return report;
        } catch {
            // TODO: star-report https://placer.atlassian.net/browse/PLAC-10694
            return rejectWithValue('TODO');
        }
    },
);
