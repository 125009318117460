import React, { ComponentProps, CSSProperties, ReactNode } from 'react';
import { Select } from 'antd';
import classNames from 'classnames';
import _ from 'lodash/fp';

import { LockIcon, SelectArrowIcon } from 'components/assets/Icons/Icons';
import type { Dictionary } from '@placer-ui/types';
import { useWidgetPrintMode } from 'extensions/widget/hooks/use-widget-context-hooks';
import './select-menu.scss';
import printSidebar from 'features/print-menu/components/print-sidebar/print-sidebar.module.scss';
import { Tooltip } from 'ui-components/tooltip/tooltip';
import { useAppUiActions } from 'store/app-ui/use-app-ui-actions';
import { UPGRADE_PLAN_SCREEN } from 'extensions/upgrade-your-plan-dialog';
import { TooltipPlacement } from 'antd/lib/tooltip';

const { Option } = Select;

export interface Options<ValueType extends string | number = string> {
    key?: string;
    value: ValueType;
    label: string | ReactNode;
    optionLabel?: string | ReactNode;
    optionLabelClassName?: string;
    prefixIcon?: ReactNode;
    details?: string;
    disabled?: boolean;
    forbidden?: boolean;
    style?: CSSProperties;
    tooltipText?: string | ReactNode;
    lockInLimitedMode?: boolean;
    tooltipClassName?: string;
    isLoading?: boolean;
}

export type SelectMenuProps<ValueType extends string | number> = {
    onSearch?: (value: string) => void;
    onChange?: (value: ValueType, option: Dictionary<any>) => void;
    onFocus?: (event: React.FocusEvent<HTMLElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLElement>) => void;
    options?: Options<ValueType>[];
    className?: string;
    arrowIcon?: ReactNode;
    value?: ValueType;
    dropdownClassName?: string;
    size?: ComponentProps<typeof Select>['size'];
    placeholder?: string;
    isSearchable?: boolean;
    notFoundContent?: ReactNode;
    defaultOptionIndex?: number;
    dropdownMatchSelectWidth?: boolean | number;
    listHeight?: number;
    onDropdownVisibleChange?: (isOpen: boolean) => void;
    optionStyle?: CSSProperties;
    descClassName?: string;
    disabled?: boolean;
    open?: boolean;
    hideSuffixIcon?: boolean;
    getPopupContainer?: (triggerNode?: HTMLElement) => HTMLElement;
    noDefaultValue?: boolean;
    testId?: string;
    withPrintModeStyle?: boolean;
    limitedMode?: boolean;
    appendToParent?: boolean;
    tooltip?: string;
    placement?: TooltipPlacement;
    dropdownAlign?: any;
};

function LabelContent({
    label,
    forbidden,
    className,
}: {
    label: string | ReactNode;
    forbidden?: boolean;
    className?: string;
}) {
    return (
        <div className={className}>
            {label}
            {forbidden && (
                <span className="lock-icon">
                    <LockIcon />
                </span>
            )}
        </div>
    );
}

export default function SelectMenu<ValueType extends string | number = string>({
    notFoundContent = '',
    onBlur = _.noop,
    onFocus = _.noop,
    onChange = _.noop,
    onSearch = _.noop,
    options,
    className,
    arrowIcon,
    value,
    dropdownClassName,
    dropdownMatchSelectWidth = 150,
    listHeight,
    size = 'middle',
    placeholder = '',
    isSearchable = false,
    defaultOptionIndex = 0,
    optionStyle,
    descClassName,
    onDropdownVisibleChange,
    open,
    disabled = false,
    hideSuffixIcon = false,
    getPopupContainer,
    noDefaultValue = false,
    testId,
    withPrintModeStyle = true,
    limitedMode = false,
    appendToParent = false,
    tooltip = '',
    placement = 'bottom',
    dropdownAlign,
}: SelectMenuProps<ValueType>) {
    const defaultOption = noDefaultValue
        ? undefined
        : options && options.filter((option) => !option.forbidden)[defaultOptionIndex];
    const dropdownClasses = classNames('pl-select-menu-dropdown', dropdownClassName);
    const isPrintMode = useWidgetPrintMode();
    const { openUpgradePlanPopup } = useAppUiActions();

    function handleFocus(event: React.FocusEvent<HTMLElement>) {
        onFocus(event);
    }

    function handleSearch(value: string) {
        onSearch(value);
    }

    function getSearchableProps() {
        if (!isSearchable) {
            return {};
        }

        return {
            showSearch: true,
            onSearch: handleSearch,
            optionFilterProp: 'children',
        };
    }

    let suffixIcon: ReactNode = null;
    if (!hideSuffixIcon) {
        suffixIcon = arrowIcon ? (
            arrowIcon
        ) : (
            <SelectArrowIcon
                style={{
                    pointerEvents: 'none',
                }}
            />
        );
    }

    return (
        <Tooltip small placement={placement} title={tooltip}>
            <Select
                open={open}
                value={value}
                defaultValue={defaultOption?.value}
                onChange={
                    limitedMode ? () => openUpgradePlanPopup(UPGRADE_PLAN_SCREEN.default) : onChange
                }
                onFocus={handleFocus}
                onBlur={onBlur}
                className={classNames({
                    [printSidebar.printSelect]: isPrintMode && withPrintModeStyle,
                    [`pl-select-menu ${className}`]: !isPrintMode || !withPrintModeStyle,
                })}
                bordered={isPrintMode && withPrintModeStyle}
                dropdownClassName={dropdownClasses}
                dropdownMatchSelectWidth={dropdownMatchSelectWidth}
                suffixIcon={suffixIcon}
                size={size}
                placeholder={placeholder}
                notFoundContent={notFoundContent}
                style={optionStyle}
                listHeight={listHeight}
                onDropdownVisibleChange={onDropdownVisibleChange}
                disabled={disabled}
                optionLabelProp="label"
                getPopupContainer={
                    appendToParent
                        ? (triggerNode) => triggerNode?.parentElement || document.body
                        : getPopupContainer
                }
                data-testid={testId}
                dropdownAlign={dropdownAlign}
                {...getSearchableProps()}
            >
                {options?.map((option) => {
                    const {
                        value: optionValue,
                        label,
                        optionLabel,
                        prefixIcon,
                        details,
                        disabled,
                        forbidden,
                        style,
                        key,
                        tooltipText = '',
                        optionLabelClassName,
                        tooltipClassName,
                    } = option;
                    const content = optionLabel ?? label;

                    const isForbiddenOption = forbidden || (limitedMode && optionValue !== value);
                    return (
                        <Option
                            key={key || optionValue}
                            value={optionValue}
                            label={label}
                            disabled={disabled || forbidden}
                            style={style}
                            data-testid={optionValue}
                        >
                            <Tooltip small title={tooltipText} className={tooltipClassName}>
                                {!prefixIcon ? (
                                    <LabelContent
                                        className={optionLabelClassName}
                                        label={content}
                                        forbidden={isForbiddenOption}
                                    />
                                ) : (
                                    <div className="option-wrapper">
                                        <div className="prefix-icon">{prefixIcon}</div>
                                        <LabelContent
                                            className={optionLabelClassName}
                                            label={content}
                                            forbidden={isForbiddenOption}
                                        />
                                    </div>
                                )}
                                {details && (
                                    <div
                                        className={classNames(
                                            'pl-select-option-details',
                                            descClassName,
                                        )}
                                    >
                                        {details}
                                    </div>
                                )}
                            </Tooltip>
                        </Option>
                    );
                })}
            </Select>
        </Tooltip>
    );
}
