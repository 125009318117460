export const attributes = {
    genderLabels: {
        FEMALE: 'Female',
        MALE: 'Male',
    },
    filterLabels: {
        AUDIENCE_TYPE: 'Audience Type',
        DATA_SOURCE: 'Data Source',
        DEMOGRAPHICS: 'Demographics',
        GENDER: 'Gender',
        AGE: 'Age',
        HOUSEHOLD_INCOME: 'Household Income',
        HOME_DISTANCE: 'Home Distance',
        WORK_DISTANCE: 'Work Distance',
        LESS_THEN: 'less than',
        MORE_THEN: 'more than',
        DAYS_OF_WEEK: 'Days of Week',
        LENGTH_OF_STAY: 'Length of Stay',
        TIME_OF_DAY: 'Time of Day',
        NUMBER_OF_VISITS: 'Number of Visits',
        MIN: 'Min',
        MAX: 'Max',
    },
    shortFilterLabels: {
        HOUSEHOLD_INCOME: 'HHI',
        DAYS_OF_WEEK: 'Days',
    },
};

export const audienceType = {
    VISITORS: 'visitors',
    EMPLOYEES: 'employees',
    RESIDENTS: 'residents',
};

export const AUDIENCE_TYPE_ATTRIBUTE = 'visits_type';

export const LENGTH_OF_STAY_ATTRIBUTE = 'visit_duration';

export const LESS_THEN_FILTER_OPERATOR = '<';
export const MORE_THEN_FILTER_OPERATOR = '>=';
