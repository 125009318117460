import { useCallback, RefObject } from 'react';

export const useScrollToTopOnVisibilityChange = (elementRef: RefObject<HTMLElement>) => {
    return useCallback(
        (isVisible: boolean) => {
            if (!elementRef?.current || !isVisible) {
                return;
            }

            elementRef.current.scrollTop = 0;
        },
        [elementRef],
    );
};
