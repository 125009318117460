/* eslint-disable max-len */
import React from 'react';

export function WeatherHeavyRainIcon() {
    return (
        <svg width="70" height="54" viewBox="0 0 70 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M26.646 36.9158L60.3979 37H60.3997C64.6023 36.999 67.9999 33.602 67.9999 29.423C67.9999 25.2428 64.6012 21.8455 60.3979 21.8455H57.6612L57.5014 20.0199C56.6348 10.1227 48.2969 2 38.253 2C31.468 2 25.4995 5.48523 22.0502 10.7643C25.6429 11.9082 28.7151 14.2214 30.8214 17.2551C31.1364 17.7088 31.0239 18.3319 30.5703 18.6469C30.1166 18.9619 29.4935 18.8494 29.1785 18.3958C26.9639 15.2059 23.5448 12.9235 19.5835 12.2086C18.7228 12.0546 17.8352 11.9739 16.927 11.9739C15.3544 11.9739 13.839 12.2162 12.4161 12.6654C6.38919 14.5768 2.01589 20.2206 2 26.8931C2.00925 30.757 3.49312 34.2731 5.92549 36.9158H26.646Z" fill="#D0D0D0"/>
<path d="M15.5908 39.6213C16.3522 39.9476 16.7049 40.8295 16.3786 41.5909L13.3786 48.5909C13.0523 49.3523 12.1705 49.7051 11.409 49.3787C10.6476 49.0524 10.2948 48.1706 10.6212 47.4091L13.6212 40.4091C13.9475 39.6477 14.8293 39.295 15.5908 39.6213Z" fill="#6FA2CE"/>
<path d="M29.5908 39.6213C30.3522 39.9476 30.7049 40.8295 30.3786 41.5909L27.3786 48.5909C27.0523 49.3523 26.1705 49.7051 25.409 49.3787C24.6476 49.0524 24.2948 48.1706 24.6212 47.4091L27.6212 40.4091C27.9475 39.6477 28.8293 39.295 29.5908 39.6213Z" fill="#6FA2CE"/>
<path d="M36.5908 39.6213C37.3522 39.9476 37.7049 40.8295 37.3786 41.5909L32.8786 52.0909C32.5523 52.8523 31.6705 53.2051 30.909 52.8787C30.1476 52.5524 29.7948 51.6706 30.1212 50.9091L34.6212 40.4091C34.9475 39.6477 35.8293 39.295 36.5908 39.6213Z" fill="#6FA2CE"/>
<path d="M44.3786 41.5909C44.7049 40.8295 44.3522 39.9476 43.5908 39.6213C42.8293 39.295 41.9475 39.6477 41.6212 40.4091L38.6212 47.4091C38.2948 48.1706 38.6476 49.0524 39.409 49.3787C40.1705 49.7051 41.0523 49.3523 41.3786 48.5909L44.3786 41.5909Z" fill="#6FA2CE"/>
<path d="M50.5908 39.6213C51.3522 39.9476 51.7049 40.8295 51.3786 41.5909L46.8786 52.0909C46.5523 52.8523 45.6705 53.2051 44.909 52.8787C44.1476 52.5524 43.7948 51.6706 44.1212 50.9091L48.6212 40.4091C48.9475 39.6477 49.8293 39.295 50.5908 39.6213Z" fill="#6FA2CE"/>
<path d="M58.3786 41.5909C58.7049 40.8295 58.3522 39.9476 57.5908 39.6213C56.8293 39.295 55.9475 39.6477 55.6212 40.4091L52.6212 47.4091C52.2948 48.1706 52.6476 49.0524 53.409 49.3787C54.1705 49.7051 55.0523 49.3523 55.3786 48.5909L58.3786 41.5909Z" fill="#6FA2CE"/>
<path d="M23.3786 41.5909C23.7049 40.8295 23.3522 39.9476 22.5908 39.6213C21.8293 39.295 20.9475 39.6477 20.6212 40.4091L16.1212 50.9091C15.7948 51.6706 16.1476 52.5524 16.909 52.8787C17.6705 53.2051 18.5523 52.8523 18.8786 52.0909L23.3786 41.5909Z" fill="#6FA2CE"/>
</svg>

    );
}
WeatherHeavyRainIcon.__tags = [] as string[];
