/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

type ArrowsThreeFromIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type ArrowsThreeFromIconProps = SVGProps<SVGSVGElement> & ArrowsThreeFromIconUniqueProps;

export const ArrowsThreeFromIcon = function ArrowsThreeFromIcon({
    width = 52,
    height = 147,
    ...props
}: ArrowsThreeFromIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 52 147"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M39.9202 72.3926C12.1426 76.757 48.2535 133.728 1.64893 133.728"
                stroke="#767676"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1.64899 72.3922C15.5909 72.3922 49.7187 72.3924 49.7187 72.3924M49.7187 72.3924L45.7188 76.3926M49.7187 72.3924L45.7188 68.3926"
                stroke="#767676"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M39.9167 72.3926C10.2495 67.6581 44.4209 10.4292 1.64893 11.1973"
                stroke="#767676"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
ArrowsThreeFromIcon.__tags = [] as string[];
