import { isEqual } from 'lodash/fp';
import moment from 'moment';

import {
    ChainRecentReportsOldestShape,
    ChainRecentReportsOldShape,
    ChainReport,
} from 'core/entities/user/user-settings';
import { ChainEntityModel } from 'core/entities';
import { isRegionNationwide, getRegionFullName } from '../regions-service';
import { safeJSONParse } from 'utils/json-parse/json-parse';
import type {
    Place,
    PropertyRecentReportsOldShape,
    PropertyReport,
    PropertyReportEntity,
} from '@placer-ui/types';

export const HISTORY_LIMIT = 10;

export const updatePropertyRecentReports = (
    newReportEntity: PropertyReportEntity,
    allRecentReports?: PropertyReport[] | PropertyRecentReportsOldShape,
) => {
    const newReport = createReport(newReportEntity);

    return updateRecentReports(newReport, allRecentReports);
};

export const updateChainRecentReports = (
    newReportRawEntities: ChainEntityModel[],
    allRecentReports?: ChainReport[] | ChainRecentReportsOldShape | ChainRecentReportsOldestShape,
) => {
    const entities = newReportRawEntities.map((item) => ({
        ...item.filters,
        id: item.originalEntity.id,
        name: item.originalEntity.name,
        locationLabel: isRegionNationwide(item.originalEntity.area)
            ? 'Nationwide'
            : getRegionFullName(item.originalEntity.area),
        notInReport: item.notInReport,
        chainType: item.originalEntity.chain_type,
        logoImg: item.originalEntity.logo_img,
    }));

    const newReport = createReport(entities);
    return updateRecentReports(newReport, allRecentReports);
};

export function mapPropertyReportsToStoreShape(
    reports: {
        timestamp: Pick<PropertyReport, 'timestamp'>;
        data: PropertyRecentReportsOldShape | PropertyReportEntity;
    }[],
) {
    return reports.map((r) => {
        const report = {
            timestamp: r.timestamp,
            data: {
                ...r.data,
            },
        };

        if (typeof report.data.competitor === 'string') {
            report.data.competitor = safeJSONParse(report.data.competitor, []);
        }

        if (typeof report.data.filter === 'string') {
            report.data.filter = safeJSONParse(report.data.filter, []);
        }

        return report;
    });
}

export function parsePropertyReport(venues: Place[], report?: PropertyRecentReportsOldShape) {
    if (!report || !report.id) {
        return null;
    }

    const partialCompetitorsData: Place[] = safeJSONParse(report.competitor, []);
    const venue = getVenueFullData(report.id, venues);
    const competitor = partialCompetitorsData.map(({ id }) => {
        return getVenueFullData(id, venues);
    });

    if (!venue) {
        return null;
    }

    return {
        ...venue,
        filter: report.filter,
        competitor: JSON.stringify(competitor),
    };
}

function createReport<T>(data: T) {
    return {
        timestamp: moment().format(),
        data,
    };
}

function updateRecentReports<T extends ChainReport | PropertyReport>(
    newReport: T,
    allRecentReports?:
        | T[]
        | ChainReport[]
        | ChainRecentReportsOldShape
        | PropertyRecentReportsOldShape
        | ChainRecentReportsOldestShape,
) {
    if (!allRecentReports || !Array.isArray(allRecentReports)) {
        return [newReport];
    }

    const recentReports = [...allRecentReports];
    const lastViewedReport = recentReports[0];
    const shouldUpdateLastViewedReport = isEqual(newReport.data, lastViewedReport.data);

    if (shouldUpdateLastViewedReport) {
        recentReports.splice(0, 1, newReport);

        return recentReports;
    }

    if (recentReports.length >= HISTORY_LIMIT) {
        const deleteCount = recentReports.length - HISTORY_LIMIT + 1;
        recentReports.splice(-deleteCount, deleteCount);
    }

    return [newReport, ...recentReports];
}

function getVenueFullData(venueId: string, venues: Place[]) {
    const venue = venues.find((venue) => venue.id === venueId);

    if (!venue) {
        return;
    }

    return {
        id: venue.id,
        name: venue.name,
        collection: venue.collection,
        locationLabel:
            venue.info.address.street_name ??
            venue.info.address.short_formatted_address ??
            (venue.collection === 'chains' ? venue.address : ''),
    };
}
