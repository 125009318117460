import React from 'react';
import { VenueFlags } from 'core/entities';
import type { BasePlaceEntity } from '@placer-ui/types';
import { VenueStatusIndicatorV1 } from './v1/venue-status-indicator-v1';
import { VenueStatusIndicatorV2 } from './v2/venue-status-indicator-v2';
import { VenueStatusIndicatorV3 } from './v3/venue-status-indicator-v3';

export type FlaggedVenueIndicatorProps = {
    flag?: VenueFlags;
    closingDate?: string | null;
    custom?: string;
    textClassName?: string;
    shortFlag?: boolean;
    name?: string;
    hasEnclosingComplexes?: boolean;
    isRestricted?: boolean;
    version?: 'v1' | 'v2' | 'v3';
    poi?: BasePlaceEntity;
};

export const VenueStatusIndicator = ({ version, ...props }: FlaggedVenueIndicatorProps) => {
    if (version === 'v2') return <VenueStatusIndicatorV2 {...props} />;
    if (version === 'v3') return <VenueStatusIndicatorV3 {...props} />;
    return <VenueStatusIndicatorV1 {...props} />;
};
