import { createSlice } from '@reduxjs/toolkit';

import { setTopSearchTerm } from 'store/header/search/actions/top-search-action-creators';
import { topSearchGetEntities } from 'store/header/search/actions/top-search-get-entities';
import { topSearchSearchByTerm } from 'store/header/search/actions/top-search-search-by-term';
import { createTopSearchState } from 'store/header/search/models/top-search-state';

const initialState = createTopSearchState();

export const topSearchSlice = createSlice({
    name: 'topSearchSlice',
    initialState,
    reducers: {
        resetState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(topSearchGetEntities.fulfilled, (state, { payload }) => {
                state.noResults = payload.entities.length === 0;
                state.entities = payload.entities;
                state.isLoading = false;
            })
            .addCase(topSearchSearchByTerm.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(setTopSearchTerm, (state, action) => {
                state.isLoading = true;
                state.term = action.payload;
            });
    },
});
