import { numberFormat } from 'utils/number/number';

export const NOT_APPLICABLE_NUMBER = 'N/A';

export const numberFormatWithNullSupport = (
    number: number | null,
    maximumFractionDigits?: number,
    shorten = true,
    formatThousands = false,
) => {
    if (number === null) {
        return NOT_APPLICABLE_NUMBER;
    }
    return numberFormat(number, maximumFractionDigits, shorten, formatThousands);
};
