import { useSelector } from 'react-redux';
import { WarningBarLineProps } from 'features/insights/components/custom-poi-warning-bar/custom-poi-warning-bar';
import { selectReportAccess } from 'store/configuration/report-access/selectors';

export const HEAVY_RISKY_FT_REMOVED = 'heavy_risky_ft_removed';
export const LIGHT_RISKY_FT_REMOVED = 'light_risky_ft_removed';

export const HEAVY_RISK_TEXT =
    'widely overlaps with restricted locations. Visitation data for\n' +
    'these locations was automatically excluded.';

export const LIGHT_RISK_OR_INSUFFICIENT_DATA_TEXT = 'is adjusted to exclude restricted locations.';

export const useCustomPoiWarningMessage = ({ entityData, responseError }: WarningBarLineProps) => {
    const reportAccess = useSelector(selectReportAccess);

    const reportAccessByUID = reportAccess[entityData.uid]?.access;
    const isHeavyRiskyFTRemoved =
        reportAccessByUID?.warnings.includes(HEAVY_RISKY_FT_REMOVED) ||
        responseError?.info === HEAVY_RISKY_FT_REMOVED;
    const isLightRiskyFTRemoved =
        reportAccessByUID?.warnings.includes(LIGHT_RISKY_FT_REMOVED) ||
        responseError?.info === LIGHT_RISKY_FT_REMOVED;
    const isCPOIInsufficientData = responseError?.error;

    const dot = '.';
    let messageText = `is a Custom-POI which wasn't 'verified' by Placer${dot}`;

    if (isHeavyRiskyFTRemoved) {
        messageText = HEAVY_RISK_TEXT;
    } else if (isLightRiskyFTRemoved || isCPOIInsufficientData) {
        messageText = LIGHT_RISK_OR_INSUFFICIENT_DATA_TEXT;
    }

    return {
        isLightRiskyFTRemoved,
        isHeavyRiskyFTRemoved,
        isCPOIInsufficientData,
        messageText,
    };
};
