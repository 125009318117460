import React, { useMemo } from 'react';
import { TableColumn } from 'ui-components/table/types';
import { UserLoginAsType } from './user-loginas';
import { UserLoginAsTableItemType } from './user-loginas-table';

export const useLoginAsTableContent = (users: UserLoginAsType[]) => {
    const columns: TableColumn<UserLoginAsTableItemType>[] = [
        {
            title: 'User Name',
            key: 'userName',
            render: (item: UserLoginAsTableItemType) => {
                return <div key={item.key}>{item.userName}</div>;
            },
        },
        {
            title: 'Account Name',
            key: 'account',
            render: (item: UserLoginAsTableItemType) => {
                return <div key={item.key}>{item.account}</div>;
            },
        },
        {
            key: 'radio',
            render: (item: UserLoginAsTableItemType) => {
                return <input type="radio" id={item.userName} />;
            },
        },
    ];

    const usersTableData: UserLoginAsTableItemType[] = useMemo(
        () =>
            users.map((userItem: UserLoginAsType, i) => {
                return {
                    key: i,
                    userName: userItem.username,
                    account: userItem.account,
                };
            }),
        [users],
    );

    return {
        columns,
        usersTableData,
    };
};
