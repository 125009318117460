import _ from 'lodash/fp';
import { RecentEntitiesModel } from 'core/services/recent-entities-service/recent-entities-model';
import {
    getReportEntityClosingDate,
    getReportEntityFlag,
    getReportEntityLocationLabel,
    getReportEntityProfile,
} from 'core/services/report-entities-service/report-entities-service';
import { chainTypeProperty, VenueFlags } from 'core/entities';
import {
    AccessInfo,
    ChainType,
    Geolocation,
    Place,
    PlaceType,
    EntityProfile,
} from '@placer-ui/types';

export interface RecentEntity {
    id: string;
    name: string;
    locationLabel: string;
    type: PlaceType;
    timestamp: number;
    isRecent: boolean;
    purchased: boolean;
    closingDate?: string | null;
    flag?: VenueFlags;
    profile?: EntityProfile;
    geolocation?: Geolocation;
    parent_chain_id?: string | null;
    access?: AccessInfo;
    store_id?: string;
    logo_img?: string;
    [chainTypeProperty]?: ChainType;
}

export const createRecentEntity = (entity: RecentEntitiesModel): RecentEntity => {
    const entityAsPlace = entity as Place;

    const recentEntity = {
        id: entity.id,
        name: entity.name,
        locationLabel: getReportEntityLocationLabel(entityAsPlace),
        type: entity.type as PlaceType,
        timestamp: _.now(),
        isRecent: true,
        purchased: entityAsPlace.purchased,
        closingDate: getReportEntityClosingDate(entityAsPlace),
        flag: getReportEntityFlag(entityAsPlace),
        profile: getReportEntityProfile(entityAsPlace),
        geolocation: entityAsPlace.geolocation,
        parent_chain_id: entityAsPlace?.parent_chain_id,
        access: entityAsPlace?.access,
        store_id: entityAsPlace?.store_id,
        logo_img: entityAsPlace?.logo_img,
    } as RecentEntity;

    if (entity.type === 'chain' && chainTypeProperty in entity) {
        recentEntity[chainTypeProperty] = entity[chainTypeProperty];
    }

    return recentEntity;
};

export const isRecentEntity = (entity: any): entity is RecentEntity => {
    return entity.isRecent;
};

export const isRestrictedArea = (entity: RecentEntity) => {
    return (
        entity?.access?.level === 'not_allowed' &&
        entity?.access?.warnings?.includes('restricted_area')
    );
};
