import type {
    ConfigurationCategoriesDataGroupCategory,
    ConfigurationCategoriesDTO,
} from '@placer-ui/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { ConfigurationCategoriesData } from '@placer-ui/types';

import { getCategories } from 'store/configuration/categories/actions';

type StateType = Partial<ConfigurationCategoriesDTO> & {
    categoriesWithChains?: ConfigurationCategoriesDataGroupCategory[];
};

const initialState: StateType = {
    category_data: [],
    categoriesWithChains: [],
};

export const categoriesSlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {
        setCategoriesData: (
            state: StateType,
            action: PayloadAction<ConfigurationCategoriesData[]>,
        ) => {
            state.category_data = action.payload;
        },
        setCategoriesWithChains: (
            state: StateType,
            action: PayloadAction<ConfigurationCategoriesDataGroupCategory[]>,
        ) => {
            state.categoriesWithChains = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCategories.fulfilled, (state, action) => {
            return action.payload;
        });
    },
});

export const configurationCategoriesActions = categoriesSlice.actions;
