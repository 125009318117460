import { createSelector } from '@reduxjs/toolkit';

import { selectConfigurationAttributesStates } from '../configuration-attributes-selectors';

export const selectStatesNames = createSelector(
    selectConfigurationAttributesStates,
    (states) => {
        return states.reduce<string[]>((statesNames, { state }) => {
            if (state) {
                return [
                    ...statesNames,
                    state,
                ];
            }

            return statesNames;
        }, []);
    },
);
