import { UserPermissions } from 'core/entities/user/user-permissions';
import type {
    Dictionary,
    DeviceTypes,
    PropertyRecentReportsOldShape,
    BaseUserAuth,
} from '@placer-ui/types';
import { parseDataRecursive } from 'utils/parse-json-recursive/parse-json-recursive';
import { UserConfiguration, DemoEntity } from 'core/entities/user/user-configuration';
import { UserSettings } from 'core/entities/user/user-settings';
import { FeatureFlags, UserFeatureFlags } from 'core/entities/user/user-feature-flags';
import { getDeviceType } from 'utils/get-user-agent-data/utils';
export type AllPermissions = UserPermissions & FeatureFlags;

export type AllUserPermissionsKeys = keyof AllPermissions;

export interface UserData {
    email: string;
    token: string;
    username: string;
    permissions: UserPermissions;
    feature_flags: UserFeatureFlags;
    configuration: Dictionary<string>;
    settings: Dictionary<string>;
    defaultVenue: DemoEntity;
    fullName?: string | undefined;
}

export interface User {
    id: string;
    email: string;
    token: string;
    username: string;
    device: DeviceTypes;
    screen: Screen;
    isAnonymous: boolean;
    isNew: boolean;

    permissions: UserPermissions;
    featureFlags: UserFeatureFlags;
    configuration: UserConfiguration;
    settings: UserSettings;
    defaultVenue: Pick<PropertyRecentReportsOldShape, 'id' | 'collection'>;
    fullName?: string | undefined;
}

function isRefererFromPlacerWebsite(referrer: string) {
    if (!referrer) return false;

    return new RegExp('^(?:https?://)?(?:www.)?placer.(?:io|ai)/?$', 'g').test(referrer);
}

function parseSettings(userData: UserData) {
    const settings = parseDataRecursive(userData.settings) as UserSettings;

    return settings;
}

const device = getDeviceType();
const isNew = isRefererFromPlacerWebsite(window.document.referrer);

export function createUserFromCookieStorage(cookieStorageUser: BaseUserAuth) {
    return {
        username: cookieStorageUser.username,
        token: cookieStorageUser.token,
        email: (cookieStorageUser.email || cookieStorageUser.username || '').toLowerCase(),
        device,
        isNew,
        screen: window.screen,
        isAnonymous: !cookieStorageUser.token,
        fullName: cookieStorageUser.fullName,
    } as User;
}

export function createUser(userData: UserData): User {
    return {
        id: userData.settings?.user_id,
        username: userData.username,
        token: userData.token,
        email: (userData.email || userData.username || '').toLowerCase(),
        device,
        isNew,
        screen: window.screen,
        isAnonymous: !userData.token,
        permissions: userData.permissions,
        featureFlags: userData.feature_flags,
        configuration: parseDataRecursive(userData.configuration) as UserConfiguration,
        settings: parseSettings(userData),
        fullName: userData.fullName,
        defaultVenue: userData.defaultVenue,
    };
}
