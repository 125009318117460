import { ParsePrintEntitiesProps } from 'features/export/dynamic-feature-context-provider';
import { useCallback } from 'react';
import { useInitializeReportEntities } from 'features/export/hooks/use-initialize-entities';
import { setReportChainsAtChains } from 'features/chains/store/chains-report-chains-store/actions/chains-report-chains-action-creators';

export const useGetChainsPrintEntitiesParser = () => {
    const initialize = useInitializeReportEntities();
    return useCallback(({ data }: ParsePrintEntitiesProps) => {
        initialize(data, setReportChainsAtChains);
    }, [initialize]);
};
