import { useQueryParams, useGetUrlParamsByKey } from 'hooks/use-query/use-query';
import { useHistory } from 'react-router-dom';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { userCustomSettingsExploreSelector } from 'store/selectors/app-selectors';
import { useMapConfiguration } from 'features/explore/hooks/use-map-configuration';
import { useFiltersConfiguration } from 'features/explore/hooks/use-explore-persistent-filter';
import { getExploreFilterType } from 'utils/get-explore-filter-type/get-explore-filter-type';
import { useSetExploreUIContext } from 'features/explore/context/use-explore-ui-state-actions';
import { useExploreUIState } from 'features/explore/context/use-explore-ui-state';
import { isAddressType } from 'utils/poi-provider/poi-provider';
import { reportException } from 'core/exceptions';

export const useExploreUrlParams = () => {
    const userCustomSettings = useSelector(userCustomSettingsExploreSelector);
    const query = useQueryParams();
    const params = useGetUrlParamsByKey(query, 'params');
    return useMemo(() => {
        try {
            return JSON.parse(params ?? '""');
        } catch (error) {
            reportException(error, {
                payload: {
                    params,
                },
            });

            return userCustomSettings;
        }
    }, [params, userCustomSettings]);
};

export const useExploreUpdateUrlParams = () => {
    const history = useHistory();
    const { type } = useFiltersConfiguration();
    const { sw, ne, center, zoom } = useMapConfiguration();
    const { activeId, selectedSuggestion } = useExploreUIState();

    return useCallback(() => {
        const encodedParams = encodeURI(
            JSON.stringify({
                type: getExploreFilterType(type),
                center,
                ne: ne.join(','),
                sw: sw.join(','),
                zoom,
            }),
        );
        let search = `?params=${encodedParams}`;

        const shouldAddAddressDeepLink =
            selectedSuggestion &&
            activeId === selectedSuggestion.info.id &&
            isAddressType(selectedSuggestion.info);

        if (shouldAddAddressDeepLink) {
            search += `&address=${selectedSuggestion?.info.name}`;
        }
        history?.replace({
            pathname: '',
            search,
        });
    }, [history, center, ne, sw, type, zoom, selectedSuggestion, activeId]);
};

export const useInitExploreContextFromUrl = () => {
    const setExploreUIContext = useSetExploreUIContext();
    const urlParams = useExploreUrlParams();

    useEffect(() => {
        if (urlParams?.uiContextParams) {
            setExploreUIContext({
                ...urlParams.uiContextParams,
            });
        }
    }, [setExploreUIContext, urlParams]);
};
