/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

export type ExpandMobileIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type ExpandMobileIconProps = SVGProps<SVGSVGElement> & ExpandMobileIconUniqueProps;

export const ExpandMobileIcon = function ExpandMobileIcon({
    width = 21,
    height = 21,
    ...props
}: ExpandMobileIconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 21 21"
            fill="none"
            {...props}
        >
            <path
                d="M17.5 4.34135V8.51232C17.5 8.69816 17.4262 8.87639 17.2948 9.00781C17.1634 9.13922 16.9851 9.21304 16.7993 9.21304C16.6134 9.21304 16.4352 9.13922 16.3038 9.00781C16.1724 8.87639 16.0986 8.69816 16.0986 8.51232V6.03243L12.8638 9.26721C12.7309 9.39098 12.5552 9.45837 12.3737 9.45516C12.1922 9.45196 12.019 9.37842 11.8906 9.25004C11.7622 9.12165 11.6887 8.94845 11.6855 8.76691C11.6823 8.58538 11.7496 8.40969 11.8734 8.27685L15.1082 5.04207L12.6283 5.04207C12.4425 5.04207 12.2642 4.96825 12.1328 4.83683C12.0014 4.70542 11.9276 4.52719 11.9276 4.34135C11.9276 4.1555 12.0014 3.97727 12.1328 3.84586C12.2642 3.71445 12.4425 3.64063 12.6283 3.64062L16.7993 3.64062C16.9851 3.64062 17.1634 3.71445 17.2948 3.84586C17.4262 3.97727 17.5 4.1555 17.5 4.34135ZM8.13623 12.014L4.90145 15.2488L4.90145 12.7689C4.90145 12.5831 4.82762 12.4049 4.69621 12.2734C4.5648 12.142 4.38657 12.0682 4.20072 12.0682C4.01488 12.0682 3.83665 12.142 3.70524 12.2734C3.57383 12.4049 3.5 12.5831 3.5 12.7689L3.5 16.9399C3.5 17.1257 3.57383 17.304 3.70524 17.4354C3.83665 17.5668 4.01488 17.6406 4.20072 17.6406H8.37169C8.55754 17.6406 8.73577 17.5668 8.86718 17.4354C8.99859 17.304 9.07242 17.1257 9.07242 16.9399C9.07242 16.7541 8.99859 16.5758 8.86718 16.4444C8.73577 16.313 8.55754 16.2392 8.37169 16.2392H5.8918L9.12658 13.0044C9.25036 12.8716 9.31774 12.6959 9.31454 12.5143C9.31134 12.3328 9.2378 12.1596 9.10941 12.0312C8.98103 11.9028 8.80782 11.8293 8.62629 11.8261C8.44475 11.8229 8.26906 11.8903 8.13623 12.014Z"
                fill="#49565D"
            />
        </svg>
    );
};
ExpandMobileIcon.__tags = [] as string[];
