import React, { ComponentType, forwardRef, IframeHTMLAttributes, Ref } from 'react';
import withLoading, { WithLoadingProps } from 'components/with-loading/with-loading';

type IframeWithLoadingProps = IframeHTMLAttributes<HTMLIFrameElement> & WithLoadingProps;
const IframeComponent: ComponentType<IframeWithLoadingProps> =
    forwardRef(({ title = 'Default Iframe Title', ...rest}, ref) => {
      return <iframe title={title} ref={ref as Ref<HTMLIFrameElement>} {...rest} />;
    });

const IframeWithLoading = withLoading<IframeWithLoadingProps>(IframeComponent);

export default IframeWithLoading;
