import { createSelector } from '@reduxjs/toolkit';

import { getAnalyticsRegionNameFormat } from 'core/services/regions-service';
import {
    selectChainsReportAllChainsEntityModels,
} from 'features/chains/store/chains-report-chains-store/selectors/chains-report-chains-selectors-factory';

export const selectChainsReportsMappedToAnalyticsModels = createSelector(
    selectChainsReportAllChainsEntityModels,
    (chainsEntityModels) => {
        return chainsEntityModels.reduce((mappedData, { originalEntity }, i) => {
            const index = i + 1;
            const { id, name: chainName, area, category, sub_category: subCategory, type } = originalEntity;
            const { view_name } = mappedData;
            const regionName = getAnalyticsRegionNameFormat(area);
            const slashOrEmptyStr = i !== chainsEntityModels.length - 1 ? ' /' : '';
            const entityName = `${chainName} / ${regionName}`;
            const viewName = `${view_name} ${chainName} ${regionName}${slashOrEmptyStr}`;

            return {
                ...mappedData,
                [`e${index}_id`]: id,
                [`e${index}_name`]: entityName,
                [`e${index}_category`]: category,
                [`e${index}_sub_category`]: subCategory,
                [`e${index}_type`]: type,
                view_name: viewName,
            };
        }, { view_name: '' });
    },
);
