import { useCallback } from 'react';
import { voidAnalysisApi } from 'API/void-analysis-api';
import { voidAnalysisActions as actions } from 'features/void-analysis/store/slice';
import { useAppDispatch } from 'store/app-store-hooks';
import { useNormalizeAdvancedSettingsResponse } from 'features/void-analysis/sections/ranking-page/components/report-content/components/report-actions/components/advanced-settings-modal/hooks/use-normalize-advanced-settings-response';

export const useFetchAdvancedSettingsData = () => {
    const normalizeAdvancedSettingsData = useNormalizeAdvancedSettingsResponse();
    const dispatch = useAppDispatch();

    return useCallback(
        async (reportId: string) => {
            const advancedSettingsResponse = await voidAnalysisApi.getAdvancedSettings(reportId);
            dispatch(
                actions.setAdvancedSettings(
                    normalizeAdvancedSettingsData(advancedSettingsResponse),
                ),
            );
        },
        [dispatch, normalizeAdvancedSettingsData],
    );
};
