import { combineReducers } from 'redux';
import { chainsReportSearchReducer } from './chains-report-search-store';
import { chainsReducer } from './chains-report-chains-store';
import { chainsReportUiStateReducer } from './chains-report-ui-state-store';

const industryUIReducer = combineReducers({
    search: chainsReportSearchReducer,
    reportUiState: chainsReportUiStateReducer,
});

export const industryReportReducer = {
    industryUi: industryUIReducer,
    industry: chainsReducer,
};
