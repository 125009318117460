import React from 'react';
import { Table } from 'antd';
import type { TableProps as AntdTableProps } from 'antd/lib/table';

import styles from './data-table.module.scss';
import classNames from 'classnames';

export type TableProps<T> = AntdTableProps<T> & { className?: string };

export const DataTable = <T extends {}>({
    pagination = false,
    className,
    ...antdTableProps
}: TableProps<T>) => {
    return (
        <Table
            className={classNames(styles.dataTableWrapper, className)}
            pagination={pagination}
            {...antdTableProps}
        />
    );
};
