import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useAppDispatch } from 'store/app-store-hooks';

import type {
    LoginParameters,
    SignupParameters,
    PlatformSignInParameters,
    EmailDataFormParameters,
    ResetPasswordTokenCheckParameters,
    SetNewPasswordParameters,
} from 'store/auth/actions/auth-actions/auth-action-types';
import {
    authLogout,
    impersonateAuthLogout as impersonateAuthLogoutDispatcher,
} from 'store/auth/actions/auth-logout/auth-logout';
import { getUserSettings } from 'store/auth/actions/get-user-settings/get-user-settings';
import { setCustomUserSettingsRecentEntities } from 'store/auth/actions/set-custom-user-settings-recent-entities';
import {
    authLogin,
    mobileAuthLogin,
    setAndRedirectAuthenticatedUser,
} from './actions/auth-login/auth.login';
import { authForgotPassword } from './actions/auth-forgot-password/auth-forgot-password';
import { authSignup } from './actions/auth-signup/auth-signup';
import { authPlatformSignin } from './actions/auth-google-signin/auth-google-signin';
import { FunctionFirstParamType } from 'types/typescript-utils';
import { fetchAnonymousUserPermissions } from 'store/auth/actions/fetch-anonymous-user-permissions/fetch-anonymous-user-permissions';
import type { BaseUserAuth } from '@placer-ui/types';
import {
    authCheckResetPasswordToken,
    authSetNewPassword,
} from './actions/auth-reset-password/auth-reset-password';
import { useHasAnonymousUserPermissions } from 'store/selectors/hooks/anonymous-user-permissions';

export const useAuthActions = () => {
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();
    const hasGoogleAuthFullSignupPermission = useHasAnonymousUserPermissions(
        'enable_google_auth_full_signup',
    );

    const logout = useCallback(() => {
        dispatch(authLogout(queryClient));
    }, [dispatch, queryClient]);

    const impersonateAuthLogout = useCallback(() => {
        dispatch(impersonateAuthLogoutDispatcher(queryClient));
    }, [dispatch, queryClient]);

    const getSettings = useCallback(() => {
        dispatch(getUserSettings());
    }, [dispatch]);

    const fetchAnonymousPermissions = useCallback(() => {
        dispatch(fetchAnonymousUserPermissions());
    }, [dispatch]);

    const setRecentEntities = useCallback(
        (poi: FunctionFirstParamType<typeof setCustomUserSettingsRecentEntities>) => {
            dispatch(setCustomUserSettingsRecentEntities(poi));
        },
        [dispatch],
    );

    const login = async (userData: LoginParameters, next_url: string | null) => {
        return dispatch(authLogin(userData, next_url));
    };

    const loginAuthenticatedUser = async (userData: BaseUserAuth, next_url: string | null) => {
        return dispatch(setAndRedirectAuthenticatedUser(userData, next_url));
    };

    const loginMobile = async (userData: BaseUserAuth) => {
        return dispatch(mobileAuthLogin(userData));
    };

    const signup = async (userData: SignupParameters) => {
        return dispatch(authSignup(userData));
    };

    const sendForgotPasswordEmail = async (data: EmailDataFormParameters) => {
        return dispatch(authForgotPassword(data));
    };

    const checkResetPasswordToken = useCallback(async (data: ResetPasswordTokenCheckParameters) => {
        return authCheckResetPasswordToken(data);
    }, []);

    const setNewPassword = useCallback(async (data: SetNewPasswordParameters) => {
        return authSetNewPassword(data);
    }, []);

    const platformSignIn = useCallback(
        async (data: PlatformSignInParameters, next_url: string) => {
            return dispatch(authPlatformSignin(data, next_url, hasGoogleAuthFullSignupPermission));
        },
        [dispatch, hasGoogleAuthFullSignupPermission],
    );

    return {
        logout,
        impersonateAuthLogout,
        getSettings,
        fetchAnonymousPermissions,
        setRecentEntities,
        login,
        loginAuthenticatedUser,
        loginMobile,
        signup,
        sendForgotPasswordEmail,
        platformSignIn,
        checkResetPasswordToken,
        setNewPassword,
    };
};
