/* eslint-disable max-len */
import React from 'react';

export function WeatherPossibleRainWithThunderIcon() {
    return (
        <svg width="70" height="54" viewBox="0 0 70 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M25.591 40.6213C26.3525 40.9476 26.7052 41.8295 26.3789 42.5909L23.3789 49.5909C23.0525 50.3523 22.1707 50.7051 21.4093 50.3787C20.6478 50.0524 20.2951 49.1706 20.6214 48.4091L23.6214 41.4091C23.9478 40.6477 24.8296 40.295 25.591 40.6213ZM39.591 40.6213C40.3525 40.9476 40.7052 41.8295 40.3789 42.5909L37.3789 49.5909C37.0525 50.3523 36.1707 50.7051 35.4093 50.3787C34.6478 50.0524 34.2951 49.1706 34.6214 48.4091L37.6214 41.4091C37.9478 40.6477 38.8296 40.295 39.591 40.6213ZM33.3789 42.5909C33.7052 41.8295 33.3525 40.9476 32.591 40.6213C31.8296 40.295 30.9478 40.6477 30.6214 41.4091L26.1214 51.9091C25.7951 52.6706 26.1478 53.5524 26.9093 53.8787C27.6707 54.2051 28.5525 53.8523 28.8789 53.0909L33.3789 42.5909Z" fill="#6FA2CE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M49.3216 29C48.534 29 47.8197 29.4622 47.4971 30.1807L44.4442 36.9792C43.5528 38.9642 45.005 41.2082 47.1809 41.2082H47.5577C48.7886 41.2082 49.7274 42.3095 49.5325 43.5249L48.3819 50.6997C48.2725 51.3819 48.7994 52 49.4902 52C49.8981 52 50.2739 51.7788 50.4718 51.4221L56.6533 40.2817C57.3929 38.9487 56.4289 37.3114 54.9044 37.3114H53.5631C52.1135 37.3114 51.1454 35.8174 51.7377 34.4943L53.5664 30.4085C53.8625 29.747 53.3785 29 52.6537 29H49.3216Z" fill="#FFD89E"/>
<path d="M14.74 36.4929C6.85497 34.5794 1 27.4735 1 19C1 9.05888 9.05887 1 19 1C26.0429 1 32.141 5.04484 35.0981 10.9382C35.3815 10.7623 35.6704 10.5944 35.9644 10.4347C32.8326 4.24411 26.4119 0 19 0C8.50659 0 0 8.50659 0 19C0 28.274 6.64437 35.9961 15.4324 37.6656C15.1829 37.2873 14.9517 36.896 14.74 36.4929Z" fill="#FFD89E"/>
<path d="M13.7682 34.1251C7.50086 31.9575 3 26.0044 3 19C3 10.1634 10.1634 3 19 3C25.3678 3 30.8667 6.71988 33.4423 12.1051C31.8479 13.3741 30.4833 14.9183 29.4207 16.6655C28.6095 16.5167 27.7735 16.4389 26.9192 16.4389C19.3308 16.4389 13.1792 22.5745 13.1792 30.1431C13.1792 31.528 13.3852 32.8649 13.7682 34.1251Z" fill="#FFD89E"/>
<path d="M42.1823 37.9501L34.8004 37.9316H18.1558C16.3883 35.9568 15.2851 33.3855 15.1865 30.5577C15.2286 30.4473 15.2516 30.3274 15.2516 30.2022C15.2516 23.6102 20.3201 18.6241 26.9155 18.6241C31.0335 18.6241 34.5884 20.3268 36.7099 23.3825C37.0248 23.8361 37.6479 23.9486 38.1016 23.6336C38.5553 23.3186 38.6677 22.6955 38.3527 22.2419C36.6568 19.7991 34.2224 18.119 31.4049 17.2688C34.1397 13.0957 38.8625 10.3428 44.2303 10.3428C52.1762 10.3428 58.7921 16.7752 59.4795 24.6263L59.6393 26.4518H62.2058C65.4107 26.4518 67.9999 29.0419 67.9999 32.2262C67.9999 35.4091 65.4121 37.9987 62.2083 38H62.2058L58.682 37.9912C58.1486 36.4809 56.7268 35.3114 54.9046 35.3114H53.5633L55.3921 31.2256C56.2804 29.241 54.8283 27 52.6539 27H49.3218C47.7466 27 46.318 27.9245 45.6728 29.3614L42.6199 36.1599C42.3546 36.7508 42.2138 37.3554 42.1823 37.9501Z" fill="#D0D0D0"/>
</svg>

    );
}
WeatherPossibleRainWithThunderIcon.__tags = [] as string[];
