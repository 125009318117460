import React, { MouseEvent, useState, useMemo, useEffect, useContext } from 'react';
import { Moment } from 'moment';
import styles from './relative-panel.module.scss';

import { DatePickerList } from 'ui-components/date-picker/date-picker-list/date-picker-list';
import { useRelativePreset } from 'ui-components/date-picker/date-hooks/use-relative-preset';
import { DateContext } from 'ui-components/date-picker/date-picker';
import { convertPresetToDateSource } from 'ui-components/date-picker/date-utils/date-utils';
import { RangeData } from '../date-types/RangeData';

interface RelativePanelProps {
    onSelect: (value: RangeData<Moment>) => void;
    selectedKeyLabel?: string;
}

export const RelativePanel = ({ onSelect, selectedKeyLabel }: RelativePanelProps) => {
    const [activePreset, setActivePreset] = useState(selectedKeyLabel ?? '');
    const date = useContext(DateContext);
    const { toMaxDate, fullPeriod, passPeriod } = useRelativePreset();

    useEffect(() => {
        setActivePreset(selectedKeyLabel ?? '');
    }, [selectedKeyLabel, date]);

    const toMaxDateSource = useMemo(() => {
        return convertPresetToDateSource(toMaxDate);
    }, [toMaxDate]);

    const fullPeriodSource = useMemo(() => {
        return convertPresetToDateSource(fullPeriod);
    }, [fullPeriod]);

    const passPeriodSource = useMemo(() => {
        return convertPresetToDateSource(passPeriod);
    }, [passPeriod]);

    const onPreventDefault = (event: MouseEvent) => {
        event.preventDefault();
    };

    const onSelectPreset = (key: string) => {
        const preset = toMaxDate[key] || fullPeriod[key] || passPeriod[key];

        setActivePreset(key);
        onSelect({
            ...preset,
            presetSection: 'relative',
        });
    };

    return (
        <div className={styles.container} onMouseDown={onPreventDefault}>
            <div className={styles.column} data-testid="toMaxDate">
                <DatePickerList
                    className={styles.list}
                    dataSource={toMaxDateSource}
                    activeItem={activePreset}
                    onSelect={onSelectPreset}
                />
            </div>

            <div className={styles.column} data-testid="fullPeriod">
                <DatePickerList
                    className={styles.list}
                    dataSource={fullPeriodSource}
                    activeItem={activePreset}
                    onSelect={onSelectPreset}
                />
            </div>

            <div className={styles.column} data-testid="passPeriod">
                <DatePickerList
                    className={styles.list}
                    dataSource={passPeriodSource}
                    activeItem={activePreset}
                    onSelect={onSelectPreset}
                />
            </div>
        </div>
    );
};
