import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'API';
import { StarredReport } from 'core/entities';

export const deleteStarredReports = 'deleteAllStarredReports';

export const deleteStarredReport = createAsyncThunk(
    'starred-reports/delete',
    async ({ report }: { report: StarredReport }) => {
        try {
            await API.starReport.delete(report.name);

            return report.name;
        } catch {
            // TODO: star-report https://placer.atlassian.net/browse/PLAC-10694
        }
    },
);

export const deleteAllStarredReports = createAction(deleteStarredReports);
