import React from 'react';
import { CustomInfoWindowProps } from 'ui-components/google-map/components/custom-info-window/custom-info-window';

import { useMap } from 'react-map-gl';

export const OverlayViewContainer = ({
    position,
    children,
    showAnchor,
    offset = [0, 0],
}: CustomInfoWindowProps) => {
    const mapInstance = useMap();
    if (mapInstance.current) {
        const width = mapInstance.current?.getContainer().clientWidth;
        const centroid = mapInstance.current?.project([position.lng, position.lat]);

        const newX = centroid.x - width;
        return (
            <div
                style={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    transform: `translate(50%, -100%) translate(${newX}px, ${centroid.y}px)`,
                }}
            >
                {children}
            </div>
        );
    }
    return null;
};
