import { useEffect, useRef } from 'react';

type UseDocumentTitleProps = {
    title: string;
    prevailOnUnmount?: boolean;
};

export const useDocumentTitle = ({ title, prevailOnUnmount = false }: UseDocumentTitleProps) => {
    const defaultTitle = useRef(document.title);

    useEffect(() => {
        document.title = title;
    }, [title]);

    useEffect(
        () => () => {
            if (!prevailOnUnmount) {
                document.title = defaultTitle.current;
            }
        },
        [prevailOnUnmount],
    );
};
