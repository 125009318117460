import React from 'react';
import { Tooltip } from 'ui-components/tooltip/tooltip';

import styles from './store-id-badge.module.scss';
import classNames from 'classnames';

type StoreIdBadgeProps = {
    storeId: string;
    dataTestId?: string;
    className?: string;
    inline?: boolean;
};

export const StoreIdBadge = ({
    storeId,
    dataTestId,
    className,
    inline = false,
}: StoreIdBadgeProps) => {
    // bug in BE - recent report saved as an empty object
    if (typeof storeId !== 'string') return null;

    return (
        <Tooltip small placement="top" title={`Store ID ${storeId}`}>
            <div
                data-testid={dataTestId}
                className={classNames(
                    styles.storeIdBadgeWrapper,
                    { [styles.isInline]: inline },
                    className,
                )}
            >
                <span className={styles.text}># {storeId}</span>
            </div>
        </Tooltip>
    );
};
