import moment, { Moment } from 'moment';
import { RangeValue } from 'ui-components/date-picker/date-types/RangeValue';
import type { DateLabels, Dictionary } from '@placer-ui/types';
import { CacheDateOption } from 'core/entities/user/user-configuration';
import { selectCacheDates } from 'store/selectors/app-selectors';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';

const getLastFullOfUnit = (
    date: Moment,
    subtractUnit: 'month' | 'week',
    unitOfTime: 'month' | 'isoWeek',
) => {
    const endMonthOfCurrentUnit = date.clone().endOf(unitOfTime).date();
    const currentDay = date.date();

    if (currentDay === endMonthOfCurrentUnit) {
        return date.clone().startOf(unitOfTime);
    }

    return date.clone().subtract(1, subtractUnit).startOf(unitOfTime);
};
const getRangePastOfUnit = (
    date: Moment,
    amount: number,
    unit: 'days' | 'months',
): RangeValue<Moment> => {
    return [date.clone().subtract(amount, unit).add(1, 'day'), date.clone()];
};
const getRangeToDate = (
    date: Moment,
    unit: 'month' | 'quarter' | 'year',
    presetsDates?: CacheDateOption,
): RangeValue<Moment> => {
    if (presetsDates) {
        return [moment(presetsDates.start), moment(presetsDates.end)];
    }
    return [date.clone().startOf(unit), date.clone()];
};
export const getRangeOfFullUnit = (
    date: Moment,
    amount: number,
    unit: 'month' | 'week',
    presetsDates?: CacheDateOption,
): RangeValue<Moment> => {
    if (presetsDates) {
        return [moment(presetsDates.start), moment(presetsDates.end)];
    }
    const unitEndOfTime = unit === 'week' ? 'isoWeek' : 'month';
    const end = getLastFullOfUnit(date, unit, unitEndOfTime);
    return [end.clone().subtract(amount - 1, unit), end.endOf(unitEndOfTime)];
};

export const dateLabels = [
    'Month to Date',
    'Quarter to Date',
    'Year to Date',
    'Last full 4 weeks',
    'Last full 6 weeks',
    'Last full 3 months',
    'Last full 6 months',
    'Last full 12 months',
    'Last 3 Months', // in angular
    'Last 6 Months', // in angular
    'Last 12 Months', // in angular
    'Past 7 days',
    'Past 30 days',
    'Past 60 days',
    'Past 90 days',
    'Past 6 months',
    'Past 12 months',
    'Custom Range',
] as const;

let relativeDatesObj: Dictionary<RangeValue<Moment>> = {};
let latestMaxDate: Moment;

export const createRelativeDatesObj = (
    maxDate: Moment,
    presetsDates?: Dictionary<CacheDateOption>,
) => {
    return {
        'Month to Date': getRangeToDate(maxDate, 'month'),
        'Quarter to Date': getRangeToDate(maxDate, 'quarter'),
        'Year to Date': getRangeToDate(maxDate, 'year', presetsDates?.['year_to_date']),
        //
        'Last full 4 weeks': getRangeOfFullUnit(maxDate, 4, 'week'),
        'Last full 6 weeks': getRangeOfFullUnit(maxDate, 6, 'week'),
        'Last full 3 months': getRangeOfFullUnit(
            maxDate,
            3,
            'month',
            presetsDates?.['last-3-months'],
        ),
        'Last full 6 months': getRangeOfFullUnit(
            maxDate,
            6,
            'month',
            presetsDates?.['last-6-months'],
        ),
        'Last full 12 months': getRangeOfFullUnit(
            maxDate,
            12,
            'month',
            presetsDates?.['last-12-months'],
        ),
        //
        'Last 3 Months': getRangeOfFullUnit(maxDate, 3, 'month', presetsDates?.['last-3-months']),
        'Last 6 Months': getRangeOfFullUnit(maxDate, 6, 'month', presetsDates?.['last-6-months']),
        'Last 12 Months': getRangeOfFullUnit(
            maxDate,
            12,
            'month',
            presetsDates?.['last-12-months'],
        ),
        //
        'Past 7 days': getRangePastOfUnit(maxDate, 7, 'days'),
        'Past 30 days': getRangePastOfUnit(maxDate, 30, 'days'),
        'Past 60 days': getRangePastOfUnit(maxDate, 60, 'days'),
        'Past 90 days': getRangePastOfUnit(maxDate, 90, 'days'),
        'Past 6 months': getRangePastOfUnit(maxDate, 6, 'months'),
        'Past 12 months': getRangePastOfUnit(maxDate, 12, 'months'),
    };
};

//// getPresetByDate -> if there is a relevant preset date - return the first preset found
// otherwise return the input date range string
const getPresetByDate = (
    startDate: Moment,
    endDate: Moment,
    presetsDates?: Dictionary<CacheDateOption>,
): string => {
    const relativeDatesObj = createRelativeDatesObj(endDate, presetsDates);
    const relativePresets = Object.entries(relativeDatesObj).find(([, [date_start, date_end]]) => {
        return moment(date_start).isSame(startDate) && moment(date_end).isSame(endDate);
    });

    if (relativePresets) {
        return relativePresets[0];
    }

    return startDate.format('YYYY-MM-DD') + ' - ' + endDate.format('YYYY-MM-DD');
};

export const isDateRangePreset = (startDate: Moment, endDate: Moment): boolean =>
    Object.entries(createRelativeDatesObj(endDate)).some(
        ([, [date_start, date_end]]) =>
            moment(date_start).isSame(startDate) && moment(date_end).isSame(endDate),
    );

export const getRelativeDateLabelIndication = (dateLabel: string | undefined) => {
    if (dateLabel && isDateLabel(dateLabel)) {
        return `${dateLabel} (Relative)`;
    }
};

export const getDateLabelFromString = (dateLabel: string | undefined) => {
    if (dateLabel && isDateLabel(dateLabel)) {
        return { date_label: dateLabel as DateLabels };
    }
};

export const isDateLabel = (label: string) => !!dateLabels.find((dateLabel) => dateLabel === label);

export const getRelativeDateByLabel = (
    label: DateLabels,
    maxDate: Moment = moment(),
): RangeValue<Moment> => {
    if (!latestMaxDate || !latestMaxDate.isSame(maxDate)) {
        latestMaxDate = maxDate;
        relativeDatesObj = createRelativeDatesObj(maxDate);
    }
    return relativeDatesObj[label];
};

export const useGetPresetByDateCallback = () => {
    const datePresets = useSelector(selectCacheDates);
    return useCallback(
        (startDate: Moment, endDate: Moment) => {
            return getPresetByDate(startDate, endDate, datePresets);
        },
        [datePresets],
    );
};
