import styles from './explore-list-item-metrics.module.scss';
import React from 'react';
import { numberFormat } from 'utils/number/number';

export type ExploreMetric = {
    label: string;
    value: number;
    type: 'number' | 'currency';
};

export type ExploreListItemMetricsProps = {
    metrics?: ExploreMetric[];
};

export const ExploreListItemMetrics = ({ metrics }: ExploreListItemMetricsProps) => {
    if (!metrics || metrics.length === 0) return null;

    return (
        <div data-testid={'explore-list-item-metrics'} className={styles.metricsContainer}>
            {metrics.map(({ label, value, type }) => (
                <div key={label} className={styles.metric}>
                    {type === 'currency' && '$'}
                    {numberFormat(value, 1, true, true)} {label}
                </div>
            ))}
        </div>
    );
};
