import React from 'react';
import { useSelector } from 'react-redux';
import { userIsAnonymousSelector } from 'store/selectors/app-selectors';
import { SignedInGeneralAndInitializersMemo } from 'features/general-and-initializers/signed-in-general-and-initializers';

/**
 * this UI-less component is for initializing global data for react
 * and also initializing global components like dialogs.
 * NOTE: do not use this component to render actual elements on the page.
 */
export const GeneralAndInitializers = () => {
    const userIsAnonymous = useSelector(userIsAnonymousSelector);

    if (userIsAnonymous === false) {
        return <SignedInGeneralAndInitializersMemo />;
    } else {
        return null;
    }
};

export const GeneralAndInitializersMemo = React.memo(GeneralAndInitializers);
GeneralAndInitializersMemo.displayName = 'MemoizedGeneralAndInitializers - memo';
