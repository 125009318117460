/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

type BarChartWithUpArrowIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type BarChartWithUpArrowIconProps = SVGProps<SVGSVGElement> & BarChartWithUpArrowIconUniqueProps;

export const BarChartWithUpArrowIcon = function BarChartWithUpArrowIcon({
    width = 27,
    height = 26,
    ...props
}: BarChartWithUpArrowIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 27 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g id="Icon" clipPath="url(#clip0_2990_190110)">
                <path
                    id="Vector 238"
                    d="M2.66699 10.8332L9.88921 5.05545L15.667 8.66656L25.0559 1.44434"
                    stroke="#49565D"
                    strokeMiterlimit="16"
                    strokeLinecap="round"
                />
                <g id="Rectangle 1098">
                    <mask id="path-2-inside-1_2990_190110" fill="white">
                        <rect x="0.5" y="14.4443" width="6.5" height="11.5556" rx="0.5" />
                    </mask>
                    <rect
                        x="0.5"
                        y="14.4443"
                        width="6.5"
                        height="11.5556"
                        rx="0.5"
                        stroke="#49565D"
                        strokeWidth="2"
                        mask="url(#path-2-inside-1_2990_190110)"
                    />
                </g>
                <g id="Rectangle 1099">
                    <mask id="path-3-inside-2_2990_190110" fill="white">
                        <rect x="10.25" y="11.5557" width="6.5" height="14.4444" rx="0.5" />
                    </mask>
                    <rect
                        x="10.25"
                        y="11.5557"
                        width="6.5"
                        height="14.4444"
                        rx="0.5"
                        stroke="#49565D"
                        strokeWidth="2"
                        mask="url(#path-3-inside-2_2990_190110)"
                    />
                </g>
                <path
                    id="Vector 246 (Stroke)"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.6025 0.541667C21.6025 0.242512 21.8451 0 22.1442 0H25.8136C26.1926 0 26.4998 0.307183 26.4998 0.686111V4.23922C26.4998 4.53837 26.2572 4.78089 25.9581 4.78089C25.6589 4.78089 25.4164 4.53837 25.4164 4.23922V1.08333H22.1442C21.8451 1.08333 21.6025 0.840821 21.6025 0.541667Z"
                    fill="#49565D"
                />
                <g id="Rectangle 1101">
                    <mask id="path-5-inside-3_2990_190110" fill="white">
                        <rect x="20" y="8.6665" width="6.5" height="17.3333" rx="0.5" />
                    </mask>
                    <rect
                        x="20"
                        y="8.6665"
                        width="6.5"
                        height="17.3333"
                        rx="0.5"
                        fill="#D0D0D0"
                        stroke="#49565D"
                        strokeWidth="2"
                        mask="url(#path-5-inside-3_2990_190110)"
                    />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_2990_190110">
                    <rect width="26" height="26" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};
BarChartWithUpArrowIcon.__tags = [
    'bar',
    'chart',
    'diagram',
    'up',
    'increase',
    'increasing',
    'incline',
    'arrow',
] as string[];
