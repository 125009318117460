/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

type PerformingArtsIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type PerformingArtsIconProps = SVGProps<SVGSVGElement> & PerformingArtsIconUniqueProps;

export const PerformingArtsIcon = function PerformingArtsIcon({
    width = 24,
    height = 24,
    ...props
}: PerformingArtsIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M10.1048 10.5182C10.1048 11.6278 8.96372 12.7165 7.30245 12.7165C5.64118 12.7165 4.50013 11.6278 4.50013 10.5182V9.9868H3.46633V10.5182C3.46633 12.4237 5.29742 13.7792 7.30245 13.7792C9.30748 13.7792 11.1386 12.4237 11.1386 10.5182V9.9868H10.1048V10.5182Z"
                fill="#5E63E5"
            />
            <path
                d="M4.48073 5.57049C4.02245 5.57049 3.65093 5.95241 3.65093 6.42352H2.65516C2.65516 5.38707 3.4725 4.54685 4.48073 4.54685C5.48897 4.54685 6.3063 5.38707 6.3063 6.42352H5.31054C5.31054 5.95241 4.93902 5.57049 4.48073 5.57049Z"
                fill="#5E63E5"
            />
            <path
                d="M9.29391 6.42352C9.29391 5.95241 9.66543 5.57049 10.1237 5.57049C10.582 5.57049 10.9535 5.95241 10.9535 6.42352H11.9493C11.9493 5.38707 11.132 4.54685 10.1237 4.54685C9.11548 4.54685 8.29815 5.38707 8.29815 6.42352H9.29391Z"
                fill="#5E63E5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.38922 0.0435231C0.704726 -0.1579 0 0.364756 0 1.09667V9.90321C0 14.0117 3.30562 17.3424 7.30228 17.3424C11.2989 17.3424 14.6046 14.0117 14.6046 9.90321V1.09667C14.6046 0.364757 13.8998 -0.1579 13.2153 0.0435231C11.7355 0.479001 9.33141 1.07789 7.30228 1.07789C5.27315 1.07789 2.86909 0.479001 1.38922 0.0435231ZM13.5708 9.90321V1.09667C13.5708 1.0933 13.5704 1.09241 13.5706 1.09305C13.5707 1.09354 13.5709 1.09368 13.5706 1.09305C13.5697 1.0914 13.5657 1.08491 13.556 1.07761C13.5462 1.07026 13.5353 1.06564 13.5258 1.06377C13.5179 1.0622 13.5101 1.06221 13.4998 1.06522C12.0051 1.50508 9.48423 2.14062 7.30228 2.14062C5.12033 2.14062 2.59948 1.50508 1.10472 1.06522C1.09448 1.06221 1.08665 1.0622 1.07873 1.06377C1.06924 1.06564 1.05836 1.07026 1.04858 1.07761C1.03889 1.08491 1.03494 1.09134 1.03409 1.09298C1.03376 1.09362 1.03397 1.09347 1.03409 1.09298C1.03425 1.09234 1.0338 1.0933 1.0338 1.09667V9.90321C1.0338 13.4124 3.86439 16.2796 7.30228 16.2796C10.7402 16.2796 13.5708 13.4124 13.5708 9.90321Z"
                fill="#5E63E5"
            />
            <path
                d="M19.85 14.6185C19.6971 15.0025 19.2704 15.1863 18.8969 15.0291C18.5234 14.872 18.3445 14.4333 18.4974 14.0493L17.6859 13.7078C17.3495 14.5525 17.743 15.5176 18.5647 15.8634C19.3864 16.2092 20.3252 15.8047 20.6616 14.96L19.85 14.6185Z"
                fill="#5E63E5"
            />
            <path
                d="M12.0832 18.8597C12.5826 18.2416 13.6683 17.9666 14.7826 18.4355C15.9127 18.9111 16.4981 19.9088 16.4149 20.7154L16.3604 21.2438L17.3885 21.3558L17.443 20.8274C17.5871 19.4297 16.609 18.0558 15.1742 17.452C13.757 16.8556 12.1479 17.1161 11.2879 18.1808L10.9576 18.5896L11.753 19.2685L12.0832 18.8597Z"
                fill="#5E63E5"
            />
            <path
                d="M8.82464 18.2615C9.12096 20.5295 10.5559 22.5764 12.7638 23.5055C16.0507 24.8887 19.806 23.2708 21.1515 19.8919L23.9218 12.9348C24.1916 12.2575 23.7318 11.4988 23.0245 11.4146C21.671 11.2533 19.6271 10.9166 18.0423 10.2497C17.236 9.91043 16.4033 9.42455 15.6382 8.91572V9.90316C15.6382 9.9914 15.6369 10.0793 15.6342 10.1669C16.2848 10.5714 16.9721 10.9477 17.6507 11.2332C19.3729 11.9579 21.5343 12.3069 22.9055 12.4703C22.9168 12.4716 22.9251 12.475 22.9329 12.4803C22.942 12.4864 22.9511 12.4958 22.9579 12.5072C22.9646 12.5184 22.9662 12.5286 22.9662 12.5286L22.9651 12.5322L20.1948 19.4893C19.0656 22.325 15.914 23.6828 13.1555 22.522C11.2635 21.7258 10.0478 19.9516 9.8351 18.0006C9.50632 18.1085 9.16899 18.1959 8.82464 18.2615Z"
                fill="#5E63E5"
            />
            <path
                d="M16.5701 13.2604C16.2378 14.095 15.3175 14.4998 14.503 14.176C14.6532 13.9143 14.7902 13.6437 14.9129 13.3652C15.2575 13.45 15.6202 13.2664 15.7586 12.9189L16.5701 13.2604Z"
                fill="#5E63E5"
            />
        </svg>
    );
};
PerformingArtsIcon.__tags = ['arts', 'masks'] as string[];
