import React from 'react';
import { Key } from 'rc-tree/lib/interface';
import type { MultiSelectOption } from 'ui-components/multi-select/types';
import { CheckboxTree } from 'features/mobile-report/shared/components/checkbox-tree/checkbox-tree';
import type { FilterConfig, OnFilterChange } from 'features/insights/types/filter-types';
import type { FilterType } from '@placer-ui/types';

import styles from './mobile-filters.module.scss';

export const workDistanceValueOptions: MultiSelectOption[] = [
    {
        value: '</5/mi',
        name: 'Under 5 mi',
        selected: false,
    },
    {
        value: '5/25/mi',
        name: '5-25 mi',
        selected: false,
    },
    {
        value: '25/50/mi',
        name: '25-50 mi',
        selected: false,
    },
    {
        value: '50/100/mi',
        name: '50-100 mi',
        selected: false,
    },
    {
        value: '>/100/mi',
        name: 'Over 100 mi',
        selected: false,
    },
];

const FILTER_TYPE: FilterType = 'work_distance';

export const WorkDistance = ({
    activeFilter,
    defaultFilter,
    onChange,
}: {
    activeFilter?: FilterConfig;
    defaultFilter: FilterConfig;
    onChange: OnFilterChange;
}) => {
    const selected = (activeFilter?.value || defaultFilter?.value || []) as string[];

    const handleOptionsChange = (keys: Key[], removeItem: boolean) => {
        if (removeItem) {
            onChange(FILTER_TYPE, {
                operator: 'in',
                value: selected.filter((key) => !keys.includes(key)),
            });
        } else {
            onChange(FILTER_TYPE, {
                operator: 'in',
                value: [...selected, ...(keys as string[])],
            });
        }
    };

    return (
        <div className={styles.checkboxFilter}>
            <CheckboxTree
                options={workDistanceValueOptions}
                treeCheckedKeys={selected}
                onOptionsChange={handleOptionsChange}
                isSearchHidden
                isSelectAndClearAllHidden
                shouldSetHeight={false}
            />
        </div>
    );
};
