export const INSIGHTS_BASE_PATH = '/insights';
export const SECTION_ROUTE_SUFFIX_PATH = '/:collection/:id/:section';
export const INSIGHTS_EXTRA_SUFFIX_PATH = '/placer-xtra/';
export const EXTERNAL_IFRAME_REPORTS_SUFFIX_PATH = '/external-iframe-reports';
export const INSIGHTS_REPORT_PATH = INSIGHTS_BASE_PATH + SECTION_ROUTE_SUFFIX_PATH;
export const INSIGHTS_EXTRA_PATH = INSIGHTS_BASE_PATH + INSIGHTS_EXTRA_SUFFIX_PATH;
export const INSIGHTS_INFOGRAPHICS_PATH = INSIGHTS_BASE_PATH + SECTION_ROUTE_SUFFIX_PATH;
export const INSIGHTS_INFOGRAPHICS_TEMPLATE_PATH = '/infographics/';
export const INSIGHTS_EXTERNAL_IFRAME_REPORTS_PATH = INSIGHTS_BASE_PATH + EXTERNAL_IFRAME_REPORTS_SUFFIX_PATH;

