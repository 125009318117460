import { default as isNil } from 'lodash/isNil';
import { BaseGroupCategory } from 'core/entities/category/types';
import {
    CategoryDisplayInfo,
    groupCategoriesIcons,
    primaryCategoriesIcons,
} from 'core/entities/category/category-icons';
import { getReportEntityProfile } from 'core/services/report-entities-service/report-entities-service';
import { Place, Geolocation } from '@placer-ui/types';

export function isGeolocation(location: any): location is Geolocation {
    if (
        !isNil(location) &&
        typeof location === 'object' &&
        typeof location.lat === 'number' &&
        typeof location.lng === 'number'
    ) {
        return true;
    }

    return false;
}

export function isPlace(place: any): place is Place {
    if (
        !isNil(place) &&
        typeof place === 'object' &&
        typeof place.id === 'string' &&
        typeof place.type === 'string' &&
        typeof place.name === 'string' &&
        typeof place.purchased === 'boolean' &&
        typeof place.address === 'object' &&
        typeof place.category === 'string' &&
        typeof place.sub_category === 'string' &&
        isGeolocation(place.geolocation) &&
        typeof place.logo_img === 'string' &&
        typeof place.number_of_venues === 'number'
    ) {
        return true;
    }

    return false;
}

export class PlacePOI {
    static getGroupCategory = (poi: Place) => {
        return poi.category_info?.group ?? poi.category;
    };

    static getPrimaryCategory = (poi: Place) => {
        return poi.category_info?.primary ?? poi.category;
    };

    static getSubCategory = (poi: Place) => {
        return poi.category_info?.sub_category ?? poi.sub_category;
    };

    static getParentChainId = (poi: Place) => poi.parent_chain_id;

    static getParentChainName = (poi: Place) => poi.parent_chain_name;

    static getCategoryIcon = (poi: Place): CategoryDisplayInfo | undefined => {
        const primary = primaryCategoriesIcons[PlacePOI.getPrimaryCategory(poi)];
        const group = groupCategoriesIcons[PlacePOI.getGroupCategory(poi) as BaseGroupCategory];

        return primary ?? group;
    };

    static isNearbyActivity = (poi: Place) => getReportEntityProfile(poi) === 'nearby_activity';
}
