import { getPallettesByCategoryAndBucket } from '@placer-ui/components';
import {
    TrafficLayerConfiguration,
    TrafficLayerViewState,
} from 'features/site-selection/sections/top-matches-page/types';
import { TrafficYear, NUM_OF_BINS } from 'components/traffic-layer/constants';
import { getHistogramValues } from 'components/traffic-layer/utils';
import type { BinOption } from 'components/traffic-layer/types';

const initialCarsPerDayHistogram = getHistogramValues([]);

const initialTrafficLayerBins: BinOption[] = [];
for (let i = 0; i < NUM_OF_BINS; i++) {
    initialTrafficLayerBins.push({
        id: String(i),
        selected: true,
        from: i,
        size: 1,
    });
}

export const TRAFFIC_COLOR_PALETTE_OPTIONS = getPallettesByCategoryAndBucket('traffic-volume', 4);

export const defaultTrafficLayerConfigs: TrafficLayerConfiguration = {
    filters: {
        year: {
            value: 'v3',
            label: TrafficYear['v3'],
        },
        roadTypes: [1, 2, 3, 4, 5],
        carsPerDay: {
            type: 'histogram',
            values: initialCarsPerDayHistogram.values,
            min_value: initialCarsPerDayHistogram.min,
            max_value: initialCarsPerDayHistogram.max,
            selected_min_value: initialCarsPerDayHistogram.min,
            selected_max_value: initialCarsPerDayHistogram.max,
            unit: 'number',
        },
        userSelectedCarsPerDay: {
            selected_min_value: 0,
            // do not use Infinity (or minus Infinity) because JSON.stringify will convert it to `null`
            selected_max_value: 9e12,
        },
        bins: initialTrafficLayerBins,
    },
    style: {
        palette: TRAFFIC_COLOR_PALETTE_OPTIONS[0],
    },
};

export const defaultTrafficLayerViewState: TrafficLayerViewState = {
    visible: false,
    year: defaultTrafficLayerConfigs.filters.year,
    roadTypes: defaultTrafficLayerConfigs.filters.roadTypes,
    userSelectedCarsPerDay: defaultTrafficLayerConfigs.filters.userSelectedCarsPerDay,
    style: defaultTrafficLayerConfigs.style,
};
