import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

import { IconProps } from 'components/assets/Icons/Icons';

/* eslint-disable max-len */
export const GroceriesIconSvg = (props: CustomIconComponentProps | SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 17 16">
        <g fill="currentColor" fillRule="evenodd">
            <path d="M14.742 2.667l1.62 2.666v9.334c0 .736-.596 1.333-1.332 1.333h-2.734a1.333 1.333 0 01-1.333-1.333V5.333l1.62-2.666h2.16zm-1.08 4c-.97 0-1.755.785-1.755 1.755v1.822a1.755 1.755 0 103.51 0V8.422c0-.97-.786-1.755-1.755-1.755zM2.188 4.334C2.188 3.044 3.247 2 4.551 2c.501 0 .966.155 1.35.419a2.366 2.366 0 011.35-.42c1.303 0 2.361 1.046 2.361 2.334zM12.313.667h2.7v1h-2.7zm-2.7 4.667V16H2.888a1.334 1.334 0 01-1.32-1.138L.161 5.332h9.45zm-2.87 3.065a.503.503 0 00-.506.5v3.333l.01.1c.047.229.252.4.496.4.28 0 .506-.223.506-.5V8.9l-.01-.1a.504.504 0 00-.496-.4zm-2.7 0a.503.503 0 00-.506.5v3.333l.01.1c.047.229.252.4.496.4.28 0 .507-.223.507-.5V8.9l-.01-.1a.506.506 0 00-.497-.4z" />
            <path
                fillRule="nonzero"
                d="M5.125.152c.63.194 1.05.639 1.105 1.347l.007.168h-.675c0-.493-.223-.75-.638-.878A1.891 1.891 0 004.396.71L4.19.719 4.11.057 4.184.05c.29-.02.62.004.94.102z"
            />
        </g>
    </svg>
);

export const GroceriesIcon = (props: IconProps) => <Icon component={GroceriesIconSvg} {...props} />;
