import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

import { IconProps } from 'components/assets/Icons/Icons';

/* eslint-disable max-len */
const CannibalizationIconSvg = (props: CustomIconComponentProps | SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
        <path
            d="M13.8333 10.4049C15.122 10.4049 16.1667 9.36028 16.1667 8.07161C16.1667 6.78295 15.122 5.73828 13.8333 5.73828C12.5447 5.73828 11.5 6.78295 11.5 8.07161C11.5 9.36028 12.5447 10.4049 13.8333 10.4049Z"
            fill="currentColor"
            stroke="#49565D"
            strokeWidth="0.75"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.52271 0.40625C10.4667 0.40625 12.2422 1.12558 13.596 2.31158L8.08789 7.80384L13.5847 13.8441C12.2323 15.0243 10.4613 15.7396 8.52271 15.7396C6.39945 15.7396 4.47723 14.8815 3.08581 13.4944C1.69401 12.1069 0.833252 10.1901 0.833252 8.07292C0.833252 5.95571 1.69401 4.03889 3.08581 2.6514C4.47723 1.26429 6.39945 0.40625 8.52271 0.40625Z"
            fill="white"
            stroke="#49565D"
            strokeWidth="0.75"
            strokeLinejoin="round"
        />
    </svg>
);

export const CannibalizationIcon = (props: IconProps) => (
    <Icon component={CannibalizationIconSvg} {...props} />
);
CannibalizationIcon.__tags = ['cannibalization', 'pacman', 'eating'] as string[];
