import React from 'react';

import styles from './user-loginas-dialog.module.scss';
import { Dialog } from 'ui-components/dialog';
import { UserLoginAs } from './user-loginas';

type UserLoginAsModalProps = {
    visible?: boolean;
    onClose: () => void;
};

export const UserLoginAsModal = ({ visible = false, onClose }: UserLoginAsModalProps) => {
    return (
        <Dialog
            visible={visible}
            onCancel={onClose}
            maskClosable={false}
            destroyOnClose
            width="45vw"
            closable={false}
            footer={null}
            className={styles.loginasDialogWrapper}
        >
            <UserLoginAs onClose={onClose} />
        </Dialog>
    );
};
