import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import type { BackendFilter } from 'features/insights/types/filter-types';
import type { CreatePOIProps } from 'API/my-zone-api';
import type { CreatorInfo, PlaceType } from '@placer-ui/types';
import { selectPropertyDefaultReportURL } from 'store/header/navigation';
import { analyzeButtonDisabledSignal } from 'features/mobile-report/state/mobile-report.signals';
import { useUpdateUrlByEntities } from 'features/insights/hooks/use-update-url-by-entities';

export type AnalyzePoi = {
    id: string;
    type: PlaceType;
    filter: BackendFilter;
    creator?: CreatorInfo;
    pinId?: string;
} & CreatePOIProps;

export const useGoToAnalyze = () => {
    const history = useHistory();
    const defaultPropertyUrl = useSelector(selectPropertyDefaultReportURL);
    const updateUrl = useUpdateUrlByEntities();
    return useCallback(
        async (pois?: AnalyzePoi[], addCompetitor?: boolean) => {
            if (pois?.length) {
                analyzeButtonDisabledSignal.value = false;

                updateUrl({
                    entities: pois,
                    addCompetitor,
                    section: 'overview',
                });
            } else {
                if (defaultPropertyUrl.includes('undefined/undefined')) {
                    return;
                } else {
                    const [pathname, search] = defaultPropertyUrl.split('?');
                    history.push({
                        pathname,
                        search,
                    });
                }
            }
        },
        [defaultPropertyUrl, history, updateUrl],
    );
};
