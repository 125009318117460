/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

type ExpoIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type ExpoIconProps = SVGProps<SVGSVGElement> & ExpoIconUniqueProps;

export const ExpoIcon = function ExpoIcon({ width = 24, height = 24, ...props }: ExpoIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <circle cx="5.5" cy="15.5" r="2" fill="#E1E2FF" stroke="#5E63E5" />
            <path d="M5.5 24V17" stroke="#5E63E5" />
            <circle cx="18.5" cy="15.5" r="2" fill="#E1E2FF" stroke="#5E63E5" />
            <path d="M18.5 24V17" stroke="#5E63E5" />
            <path
                d="M5.5 17.5C7.34908 18.714 9 20.5 12 20.5C15 20.5 16.6509 18.714 18.5 17.5"
                stroke="#5E63E5"
            />
            <path d="M5.5 17.5C3.86724 18.572 2.5 20.4304 0 20.4304" stroke="#5E63E5" />
            <path d="M18.5 17.5C20.1328 18.572 21.5 20.4304 24 20.4304" stroke="#5E63E5" />
            <rect x="8.5" y="0.5" width="7" height="11" rx="0.5" stroke="#5E63E5" />
            <rect x="10" y="2" width="4" height="8" fill="white" />
            <rect x="20" y="4" width="2" height="4" fill="white" />
            <rect x="18.5" y="2.5" width="5" height="7" rx="0.5" stroke="#5E63E5" />
            <rect x="2" y="4" width="2" height="4" fill="white" />
            <rect x="0.5" y="2.5" width="5" height="7" rx="0.5" stroke="#5E63E5" />
            <path d="M3.5 23.5H7.5" stroke="#5E63E5" strokeLinecap="round" />
            <path d="M16.5 23.5H20.5" stroke="#5E63E5" strokeLinecap="round" />
        </svg>
    );
};
ExpoIcon.__tags = ['expo', 'exposition'] as string[];
