export type SearchSplunkComponent =
    | 'myZonePortfolio'
    | 'myZoneBillboard'
    | 'myZoneVenues'
    | 'myZoneChains'
    | 'myZoneTags'
    | 'myZoneCreatedPoi'
    | 'myZoneSharedPoi'
    | 'exploreSearchPanel'
    | 'exploreEntities'
    | 'topBar'
    | 'poiSelectionMenu'
    | 'poiSelectionEntities'
    | 'propertyCompareBar'
    | 'propertySuggestionPois'
    | 'propertySuggestionNearby'
    | 'createPoiSearchPanel'
    | 'createPoiMap'
    | 'placerXtra';

export const SPLUNK_SEARCH_PLACEMENTS: Record<SearchSplunkComponent, string> = {
    myZonePortfolio: 'portfolio',
    myZoneBillboard: 'my-billboard',
    myZoneVenues: 'purchased-poi-venues',
    myZoneChains: 'purchased-poi-chains',
    myZoneTags: 'my-tags',
    myZoneCreatedPoi: 'created_poi_my_poi',
    myZoneSharedPoi: 'created_poi_shared_poi',
    exploreSearchPanel: 'explore-search-panel',
    exploreEntities: 'explore-entities',
    topBar: 'top-bar',
    poiSelectionMenu: 'poi-map-selection-searchbar',
    poiSelectionEntities: 'poi-selection-map-entities',
    propertyCompareBar: 'property-compare-bar',
    propertySuggestionPois: 'property-suggestion-poi',
    propertySuggestionNearby: 'property-suggestion-nearby',
    createPoiSearchPanel: 'create-poi-search-panel',
    createPoiMap: 'create-poi-map',
    placerXtra: 'placer-xtra-search-panel',
};

export const SEARCH_SPLUNK_ACTIONS = {
    search: 'search',
    selected: 'search-result-clicked',
};
