import React, { ComponentProps } from 'react';
import classnames from 'classnames';

import { Checkbox as AntdCheckbox, Tooltip as AntdTooltip } from 'antd';
import { MultiSelectOption, MultiSelectSubOption } from 'ui-components/multi-select/types';

import styles from './checkbox.module.scss';
import { Button } from 'ui-components/button/button';
import { PlusIcon } from 'components/assets/Icons/Icons';
import { MinusOutlined } from '@ant-design/icons';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { CheckboxList } from 'ui-components/multi-select/checkbox-list-v1/checkbox-list';
import { BodyText } from '@placer-ui/components';
import { Tooltip } from 'ui-components/tooltip/tooltip';

type CheckboxProps = MultiSelectOption & {
    onChange: (option: MultiSelectOption) => void;
    expanded?: boolean;
    onExpandChange?: (expanded: boolean) => void;
    className?: string;
    disabled?: boolean;
    indeterminateState?: boolean;
    testId?: string;
    tooltipText?: string;
    placement?: ComponentProps<typeof AntdTooltip>['placement'];
};

export const Checkbox = ({
    name,
    selected,
    value,
    subOptions,
    onChange,
    expanded,
    onExpandChange,
    className,
    disabled = false,
    indeterminateState = false,
    testId = 'checkbox',
    tooltipText,
    placement,
}: CheckboxProps) => {
    const handleChange = ({ target: { value, checked } }: CheckboxChangeEvent) => {
        const newSubOptions = subOptions?.map((option) => ({
            ...option,
            selected: checked,
        }));

        const newOption = {
            name,
            value,
            selected: checked,
            ...(newSubOptions && { subOptions: newSubOptions }),
        };
        onChange(newOption);
    };

    const handleSubOptionChange = (
        options: MultiSelectSubOption[],
        changedOption: MultiSelectSubOption,
    ) => {
        const newSubOptions = [...subOptions!];
        const index = newSubOptions.findIndex(({ value }) => value === changedOption.value);
        newSubOptions[index] = changedOption;
        const { total, checked } = getSubOptionsState(newSubOptions);
        const newSelectedState = total === checked;

        onChange({
            name,
            value,
            selected: newSelectedState,
            subOptions: newSubOptions,
        } as MultiSelectSubOption);
    };

    const getSubOptionsState = (subOptions: MultiSelectSubOption[]) =>
        subOptions.reduce(
            (acc, { selected }) => {
                acc.total++;
                selected ? acc.checked++ : acc.unchecked++;
                return acc;
            },
            {
                total: 0,
                checked: 0,
                unchecked: 0,
            },
        );

    const subOptionState = subOptions?.length ? getSubOptionsState(subOptions) : null;

    const isIndeterminate = () => {
        if (!subOptions || !subOptionState) return false;
        const { checked, unchecked } = subOptionState;
        return checked > 0 && unchecked > 0;
    };

    const hasSelected = !subOptions || !subOptionState ? selected : subOptionState.checked > 0;
    const indeterminate = isIndeterminate() || indeterminateState;

    return (
        <Tooltip small title={tooltipText} placement={placement} useParentContainer>
            <div className={classnames(styles.checkboxWrapper, className)}>
                <AntdCheckbox
                    checked={hasSelected || indeterminate}
                    indeterminate={indeterminate}
                    value={value}
                    onChange={handleChange}
                    data-testid={testId}
                    disabled={disabled}
                >
                    {!disabled ? (
                        name
                    ) : (
                        <BodyText size="small" color="disabled">
                            {name}
                        </BodyText>
                    )}
                </AntdCheckbox>
                {!!subOptions?.length && onExpandChange && (
                    <>
                        <Button
                            className={classnames(styles.expandButton, { expand: !expanded })}
                            onClick={() => onExpandChange(!expanded)}
                            type="ghost"
                            size="small"
                            icon={expanded ? <MinusOutlined /> : <PlusIcon />}
                            ghost={true}
                            data-testid={'expand-change-button'}
                        />
                        {expanded && (
                            <div className={styles.subOptionsWrapper}>
                                <CheckboxList
                                    className={styles.subOptionsList}
                                    options={subOptions}
                                    onChange={handleSubOptionChange}
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
        </Tooltip>
    );
};
