import React, { ReactNode } from 'react';
import styles from './empty-data-message.module.scss';
import { Button } from 'ui-components/button/button';
import wastelandPlacerOwlSvg from 'shared/images/wasteland-placer-owl.svg';
import { ElevioParams } from 'ui-components/table/types';
import classNames from 'classnames';

type EmptyDataMessageProps = {
    title: string;
    description?: string | ReactNode;
    actionButton?: {
        label: string;
        onClick: () => void;
        elevioConfig?: ElevioParams;
    };
    dynamicWidth?: boolean;
    wrapClassName?: string;
};

export const EmptyDataMessage = ({
    title,
    description,
    actionButton,
    dynamicWidth,
    wrapClassName,
}: EmptyDataMessageProps) => {
    return (
        <div className={classNames(styles.emptyPageWrapper, wrapClassName)}>
            <div
                className={classNames(styles.emptyStateWrapper, {
                    [styles.fixedWidth]: !dynamicWidth,
                })}
            >
                <img src={wastelandPlacerOwlSvg} className={styles.illustration} alt="No results" />
                <div className={styles.contentWrapper}>
                    <h4 className={styles.header}>{title}</h4>
                    {description && (
                        <span data-testid={'empty-data-description'} className={styles.message}>
                            {description}
                        </span>
                    )}
                    {actionButton && (
                        <Button
                            type="brand-primary"
                            className={styles.actionButton}
                            onClick={actionButton.onClick}
                            {...actionButton.elevioConfig}
                        >
                            {actionButton.label}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};
