import { usePoiSelectionContext } from 'features/poi-selection-map/context/poi-selection-context';
import { useCallback } from 'react';
import { PlaceWithFilters } from 'features/insights/store/poi/poi-types';
import { PlaceOverlay } from 'ui-components/google-map-layers/types/google-map-layers-types';
import { getIsOverlaySelected } from 'ui-components/google-map-layers/utils/get-place-overlay';

export const useGetOverlayPois = () => {
    const { overlayPois } = usePoiSelectionContext();
    return useCallback((poi: PlaceWithFilters): PlaceOverlay[] => overlayPois[poi.id], [
        overlayPois,
    ]);
};

export const useIsIdSelected = () => {
    const getOverlayPois = useGetOverlayPois();

    return useCallback(
        (selectedOverlay: PlaceOverlay): boolean =>
            getIsOverlaySelected(getOverlayPois(selectedOverlay)),
        [getOverlayPois],
    );
};
