/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';
import { IconProps } from 'components/assets/Icons/Icons';

export const HobbiesAndGiftsAndCraftsIconSvg = (
    props: CustomIconComponentProps | SVGProps<SVGSVGElement>,
) => (
    <svg {...props} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.2174 2.33091H10.3439C10.4591 2.14824 10.5351 1.94321 10.5568 1.72636C10.6261 1.03334 10.2617 0.412097 9.64147 0.13667C9.07133 -0.116499 8.43062 -0.0130058 7.96925 0.406181L6.86628 1.40754C6.65148 1.17219 6.34269 1.02428 5.99999 1.02428C5.65668 1.02428 5.3474 1.17266 5.13254 1.40874L4.02817 0.406063C3.56606 -0.0131232 2.9256 -0.116218 2.35572 0.136811C1.73561 0.412262 1.37121 1.03372 1.44068 1.72671C1.4624 1.94339 1.53839 2.14834 1.65358 2.33091H0.7826C0.350367 2.33091 0 2.68191 0 3.11488V3.49154C0 3.70801 0.175195 3.98068 0.391312 3.98068H11.6087C11.8248 3.98068 12 3.70804 12 3.49154V3.11488C12 2.68191 11.6496 2.33091 11.2174 2.33091ZM4.82608 2.20025V2.33091H2.98523C2.49885 2.33091 2.11607 1.87669 2.23755 1.36941C2.29106 1.14601 2.45203 0.95558 2.66022 0.859177C2.94611 0.726804 3.2628 0.769301 3.50292 0.987018L4.8264 2.1887C4.82633 2.19257 4.82608 2.19638 4.82608 2.20025ZM9.78031 1.62228C9.75115 2.0297 9.38377 2.33096 8.97603 2.33096H7.1739V2.2003C7.1739 2.19567 7.17362 2.19109 7.17357 2.18647C7.47338 1.91421 8.1238 1.32368 8.47655 1.00336C8.67704 0.821329 8.95756 0.73636 9.21687 0.81384C9.58899 0.925034 9.80752 1.24204 9.78031 1.62228Z"
            fill="currentColor"
        />
        <path
            d="M0.99646 5.01056V11.2813C0.99646 11.6782 1.31137 12 1.69986 12H3.98242V5.01056H0.99646Z"
            fill="currentColor"
        />
        <path
            d="M4.97546 5.01056V12H10.258C10.6465 12 10.9614 11.6782 10.9614 11.2813V5.01056H4.97546Z"
            fill="currentColor"
        />
    </svg>
);

export const HobbiesAndGiftsAndCraftsIcon = (props: IconProps) => (
    <Icon component={HobbiesAndGiftsAndCraftsIconSvg} {...props} />
);
