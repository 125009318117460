import React from 'react';

import { sendHubspotForm } from 'API/hubspot-api/hubspot-api';
import { HubspotFormFields } from 'API/hubspot-api/types';
import { reportException } from 'core/exceptions';
import { Button } from '@placer-ui/components';
import { userCookieStorage } from 'core/authentification';

type RequestDemoFormProps = {
    onFormSubmit: () => void;
};

type RequestDemoFormFields = 'email';

export const RequestDemoForm: React.FC<RequestDemoFormProps> = ({ onFormSubmit }) => {
    const email = userCookieStorage.user?.email;

    const handleFormSubmit = () => {
        const HS_FORM_ID = '8841fb1e-4c0b-4c9c-afc2-f250fac93d42';
        // eslint-disable-next-line max-len
        const targetUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.REACT_APP_HUBSPOT_PORTAL_ID}/${HS_FORM_ID}`;

        const fields: HubspotFormFields<RequestDemoFormFields> = [
            {
                name: 'email',
                value: email || '',
            },
        ];

        sendHubspotForm<RequestDemoFormFields>(HS_FORM_ID, fields)
            .catch((error) => {
                reportException(error, {
                    payload: {
                        HS_FORM_ID,
                        ...fields,
                    },
                    method: 'POST',
                    target_url: targetUrl,
                });
            })
            .finally(() => onFormSubmit());
    };

    return (
        <Button variant="primary-brand" onClick={handleFormSubmit} testId="schedule-demo-form">
            Schedule a demo
        </Button>
    );
};
