import React, { ComponentProps } from 'react';
import { Button } from 'antd';
import classNames from 'classnames';
import { Tooltip } from 'ui-components/tooltip/tooltip';

import styles from './to-support.module.scss';

type ToSupportProps = {
    inline?: boolean;
    tooltipPlacement?: ComponentProps<typeof Tooltip>['placement'];
};

export const ToSupport = ({ inline = false, tooltipPlacement = 'bottom' }: ToSupportProps) => {
    return (
        <div
            className={classNames(styles.toSupport, {
                [styles.inline]: inline,
            })}
        >
            <Tooltip small title="Mail to support" placement={tooltipPlacement}>
                <Button type="link" href="mailto:support@placer.ai" className={styles.link}>
                    support@placer.ai
                </Button>
            </Tooltip>
        </div>
    );
};
