import { ReactNode } from 'react';
import { AVIATION_SOLUTIONS_GUIDE } from 'features/onboarding/solutions-guide/consts/aviation';
import { CIVIC_SOLUTIONS_GUIDE } from 'features/onboarding/solutions-guide/consts/civic';
import { CPG_SOLUTIONS_GUIDE } from 'features/onboarding/solutions-guide/consts/cpg';
import { REAL_ESTATE_SOLUTIONS_GUIDE } from 'features/onboarding/solutions-guide/consts/real-estate';
import { ENTERTAINMENT_SOLUTIONS_GUIDE } from 'features/onboarding/solutions-guide/consts/entertainment';
import { FINANCE_SOLUTIONS_GUIDE } from 'features/onboarding/solutions-guide/consts/finance';
import { ADVERTISING_SOLUTIONS_GUIDE } from 'features/onboarding/solutions-guide/consts/advertising';
import { RESTAURANTS_SOLUTIONS_GUIDE } from 'features/onboarding/solutions-guide/consts/restaurants';
import { RETAIL_SOLUTIONS_GUIDE } from 'features/onboarding/solutions-guide/consts/retail';
import { LEISURE_SOLUTIONS_GUIDE } from 'features/onboarding/solutions-guide/consts/leisure';
import { HEALTHCARE_SOLUTIONS_GUIDE } from 'features/onboarding/solutions-guide/consts/healthcare';
import { NON_PROFIT_SOLUTIONS_GUIDE } from 'features/onboarding/solutions-guide/consts/non-profit';
import { EDUCATION_SOLUTIONS_GUIDE } from 'features/onboarding/solutions-guide/consts/education';
import { CONSULTING_AND_RESEARCH_SOLUTIONS_GUIDE } from 'features/onboarding/solutions-guide/consts/consulting-and-research';
import { BID_DMO_SOLUTIONS_GUIDE } from 'features/onboarding/solutions-guide/consts/bid-dmo';
import { OTHER_SOLUTIONS_GUIDE } from 'features/onboarding/solutions-guide/consts/other';

export type Solution = {
    title: string;
    description: string;
    url: string;
};

type SolutionsGuide = Record<
    string,
    {
        solutions: Solution[];
        icon: ReactNode;
    }
>;

export const SOLUTIONS_GUIDE: SolutionsGuide = {
    Aviation: AVIATION_SOLUTIONS_GUIDE,
    Civic: CIVIC_SOLUTIONS_GUIDE,
    CPG: CPG_SOLUTIONS_GUIDE,
    'Real Estate': REAL_ESTATE_SOLUTIONS_GUIDE,
    Entertainment: ENTERTAINMENT_SOLUTIONS_GUIDE,
    Finance: FINANCE_SOLUTIONS_GUIDE,
    'Marketing & Advertising': ADVERTISING_SOLUTIONS_GUIDE,
    Restaurants: RESTAURANTS_SOLUTIONS_GUIDE,
    Retail: RETAIL_SOLUTIONS_GUIDE,
    'Hotels & Leisure': LEISURE_SOLUTIONS_GUIDE,
    Healthcare: HEALTHCARE_SOLUTIONS_GUIDE,
    'Non-Profit': NON_PROFIT_SOLUTIONS_GUIDE,
    Education: EDUCATION_SOLUTIONS_GUIDE,
    'Consulting & Research': CONSULTING_AND_RESEARCH_SOLUTIONS_GUIDE,
    'BID/DMO': BID_DMO_SOLUTIONS_GUIDE,
    Other: OTHER_SOLUTIONS_GUIDE,
};

export const solutionsGuideIndustries = Object.keys(SOLUTIONS_GUIDE);
