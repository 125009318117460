import React, { PropsWithChildren, useMemo } from 'react';
import { generatePath, RouteProps, Switch } from 'react-router-dom';
import { useBasepath } from 'router/hooks/use-basepath';

export type PlSwitchProps = {
    overrideBasepath?: `:${string}`;
};

const isValidRoute = (basepath: string, path: RouteProps['path']) =>
    basepath === '/' || !path || (typeof path === 'string' && !path.startsWith('/'));

export const PlSwitch = ({ overrideBasepath, children }: PropsWithChildren<PlSwitchProps>) => {
    const { basepath } = useBasepath();

    const newChildren = useMemo(
        () =>
            React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    const path = child.props.path;

                    if (isValidRoute(basepath, path)) {
                        return child;
                    }

                    const paths = [...(typeof path === 'string' ? [path] : path)].map((url) => {
                        if (overrideBasepath) {
                            const key = overrideBasepath.replace(':', '');
                            return generatePath(basepath, { [key]: url.replace('/', '') });
                        }

                        if (basepath.indexOf(url) > -1) {
                            return basepath;
                        } else if (url === '/') {
                            return basepath;
                        } else {
                            return `${basepath}${url}`;
                        }
                    });

                    return React.cloneElement(child, {
                        ...child.props,
                        path: paths,
                    });
                }
            }),
        [basepath, children, overrideBasepath],
    );
    return <Switch>{newChildren}</Switch>;
};
