import { useContext, useMemo } from 'react';
import { PrintContext } from '../context/print-context';
import {
    PrintWidgetImages,
    PrintWidgetImagesMapping,
    WidgetConfigType,
} from 'features/print-menu/context/types';

export const usePrintContext = () => {
    return useContext(PrintContext);
};

export type ActiveWidget = {
    id: string;
    type: string;
    poiId?: string;
    originalWidget: WidgetConfigType;
};

export type GeneralWidget = ActiveWidget;

const useZippedWidgets = () => {
    const { widgets } = usePrintContext();

    return useMemo(() => {
        return widgets.map((widget) => ({
            id: widget.id,
            type: widget.type,
            originalWidget: widget,
        }));
    }, [widgets]);
};

export const useAllWidgets = (): GeneralWidget[] => {
    const { groupOrder } = usePrintContext();
    const zippedWidgets = useZippedWidgets();

    return useMemo(() => {
        const widgetsFlat = groupOrder.flatMap(
            (group) => zippedWidgets.filter((widget) => widget.type === group.id) || [],
        );

        return widgetsFlat.map((widget) => ({
            id: widget.id,
            type: widget.type,
            poiId:
                widget.originalWidget.venues && widget.originalWidget.venues.length === 1
                    ? widget.originalWidget.venues[0].uid
                    : undefined,
            originalWidget: widget.originalWidget,
        }));
    }, [groupOrder, zippedWidgets]);
};

export const useActiveWidgets = (): ActiveWidget[] => {
    const { groupOrder } = usePrintContext();
    const zippedWidgets = useZippedWidgets();

    return useMemo(() => {
        const activeGroups = groupOrder.filter((group) => group.visible);

        const activeWidgetsFlat = activeGroups.flatMap(
            (activeGroup) =>
                zippedWidgets.filter(
                    (widget) => widget.type === activeGroup.id && widget.originalWidget?.visible,
                ) || [],
        );

        return activeWidgetsFlat.map((widget) => ({
            id: widget.id,
            type: widget.type,
            poiId:
                widget.originalWidget.venues && widget.originalWidget.venues.length === 1
                    ? widget.originalWidget.venues[0].uid
                    : undefined,
            originalWidget: widget.originalWidget,
        }));
    }, [groupOrder, zippedWidgets]);
};

export const useActiveWidgetsImages = (): PrintWidgetImages[] => {
    const activeWidgets = useActiveWidgets();

    return useMemo(() => {
        return activeWidgets
            .filter((activeWidget) => activeWidget.originalWidget.widgetImages)
            .map((activeWidget) => activeWidget.originalWidget.widgetImages!);
    }, [activeWidgets]);
};

export const useActiveWidgetsImagesMapping = (): PrintWidgetImagesMapping[] => {
    const activeWidgets = useActiveWidgets();

    return useMemo(() => {
        return activeWidgets
            .filter((activeWidget) => activeWidget.originalWidget.widgetImages)
            .map((activeWidget) => ({
                urls: activeWidget.originalWidget.widgetImages!.flat(),
                type: activeWidget.originalWidget.type,
                id: activeWidget.originalWidget.id,
                startNewPage:
                    !!activeWidget.originalWidget?.widgetModel?.print?.alwaysStartOnNewPage,
            }));
    }, [activeWidgets]);
};

export const useAllWidgetsExportJobIds = (): string[] => {
    const allWidgets = useAllWidgets();

    return useMemo(() => {
        return allWidgets
            .filter((widget) => widget.originalWidget.exportJobId)
            .map((widget) => widget.originalWidget.exportJobId!);
    }, [allWidgets]);
};

export const useIsActiveWidgetsImagesLoaded = (): boolean => {
    const activeWidgets = useActiveWidgets();

    return useMemo(() => {
        return activeWidgets.every((activeWidget) => {
            return (
                activeWidget.originalWidget.widgetImages &&
                activeWidget.originalWidget.widgetImages.length > 0
            );
        });
    }, [activeWidgets]);
};

export const useGetActiveWidgetsImagesLoaded = (): ActiveWidget[] => {
    const activeWidgets = useActiveWidgets();

    return useMemo(() => {
        return activeWidgets.filter((activeWidget) => {
            return (
                activeWidget.originalWidget.widgetImages &&
                activeWidget.originalWidget.widgetImages.length > 0
            );
        });
    }, [activeWidgets]);
};
