import { useEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash';
import { useLocation, useParams } from 'react-router-dom';
import type { Dictionary } from '@placer-ui/types';

export function useRouteParam(key: string) {
    const location = useLocation();
    const locationState = (location.state || {}) as Dictionary<string>;
    if (locationState && key in locationState) {
        return locationState[key];
    } else {
        const urlParamsKeys = new URLSearchParams(location.search);
        return urlParamsKeys.get(key);
    }
}

export const useGetUrlParamsByKey = (urlParams: string | undefined, key: string) => {
    return useMemo(() => {
        const searchParams = new URLSearchParams(urlParams);
        return searchParams.get(key);
    }, [key, urlParams]);
};

export function useQuery(key: string) {
    const location = useLocation();
    return useGetUrlParamsByKey(location.search, key);
}

export const useEncodedQuery = (key: string) => {
    const query = useQuery(key) || '';
    return encodeURIComponent(query);
};

export const useQueryParams = () => {
    const location = useLocation();
    return location.search;
};

export const useImmutableParams = <Params extends { [K in keyof Params]?: string }>() => {
    const params = useParams<Params>();
    const [paramsState, setParamsState] = useState<Params>(params);

    useEffect(() => {
        if (!isEqual(paramsState, params)) {
            setParamsState(params);
        }
    }, [params, paramsState]);

    return paramsState;
};
