import React, { useMemo } from 'react';
import classnames from 'classnames';
import type {
    BasePlaceEntity,
    ExternalVenueType,
    Place,
    PlaceType,
    Complex,
} from '@placer-ui/types';
import styles from './poi-cell.module.scss';

import { PlacePOI } from 'core/entities';
import {
    VenueStatusIndicator,
    FlaggedVenueIndicatorProps,
} from 'ui-components/venue-status-indicator/venue-status-indicator';
import {
    getReportEntityClosingDate,
    getReportEntityFlag,
} from 'core/services/report-entities-service/report-entities-service';
import { isRecentEntity, RecentEntity } from 'core/entities/recent-entity/recent-entity';
import { isAddressType } from 'utils/poi-provider/poi-provider';
import { ADDRESS_COORDINATE_TITLE, ADDRESS_ENTITY, ADDRESS_TITLE } from 'core/constants/address';
import { SquareLogo } from 'ui-components/square-logo/square-logo';
import { StoreIdBadge } from 'components/store-id-badge/store-id-badge';
import { getCategoryStaticIconUrl, getCategoryIconUrl } from 'core/entities/category/utils';
import { useSelectAvailableCategoriesData } from 'store/configuration/categories/selectors/selectors-hooks';
import {
    EMPTY_SPACE_COORDINATE_ID,
    EMPTY_SPACE_POI_TYPE,
} from 'features/empty-space/constants/empty-space-constants';
import { COORDINATES_ENTITY } from 'features/explore/constants';

const defaultVisibleInfo = {
    logo: true,
    address: true,
    category: true,
    statusIndicator: true,
};

type PoiCellProps = {
    poi: Place | RecentEntity;
    statusIndicatorPosition?: 'top' | 'bottom';
    customStatusIndication?: string;
    visibleInfo?: {
        logo?: boolean;
        address?: boolean;
        category?: boolean;
        statusIndicator?: boolean;
    };
    bodyClass?: string;
    fullAddress?: string;
    isPrintMode?: boolean;
    onClick?: () => void;
    newCategoryPermission?: boolean;
    statusIndicatorVersion?: FlaggedVenueIndicatorProps['version'];
    hasEnclosingComplexes?: boolean;
    storeId?: string;
    storeIdClassName?: string;
};

export const PoiCell = ({
    poi,
    visibleInfo = defaultVisibleInfo,
    statusIndicatorPosition = 'top',
    customStatusIndication,
    fullAddress,
    isPrintMode = false,
    onClick,
    newCategoryPermission,
    statusIndicatorVersion,
    hasEnclosingComplexes,
    storeId,
    bodyClass,
    storeIdClassName,
}: PoiCellProps) => {
    const categoriesIconData = useSelectAvailableCategoriesData();

    const poiInfo = poi as Place;

    const { name } = poi;
    const flag = getReportEntityFlag(poi);
    const closingDate = getReportEntityClosingDate(poi);
    let address, logoImg, category;
    let isAddressTypePoi = false;
    let poiType: PlaceType | ExternalVenueType = poi.type;
    if (isRecentEntity(poi)) {
        address = poi.locationLabel;
    } else {
        address = fullAddress || poi.address?.formatted_address || poi.address?.address;
        logoImg = poi.logo_img;
        category = newCategoryPermission ? PlacePOI.getPrimaryCategory(poi) : poi.category;
        isAddressTypePoi = isAddressType(poi);
        if (isAddressTypePoi) poiType = ADDRESS_ENTITY as ExternalVenueType;
    }

    const logoImgUrl = getCategoryIconUrl({
        categoriesIconData,
        info: poiInfo,
    });
    const categoryIcon = !logoImgUrl
        ? getCategoryStaticIconUrl({
              info: poiInfo,
          })
        : undefined;

    const visibleFields = {
        ...defaultVisibleInfo,
        ...visibleInfo,
    };

    const statusIndicator =
        visibleFields.statusIndicator && (flag || closingDate || customStatusIndication) ? (
            <>
                <div className={styles.separator} />
                <VenueStatusIndicator
                    version={statusIndicatorVersion}
                    flag={flag}
                    closingDate={closingDate}
                    custom={customStatusIndication}
                    name={name}
                    hasEnclosingComplexes={hasEnclosingComplexes}
                    poi={poi as BasePlaceEntity}
                />
            </>
        ) : null;

    const printModeCss = { [styles.printMode]: isPrintMode };

    const poiName = useMemo<string>(() => {
        if (
            (poi.type === EMPTY_SPACE_POI_TYPE ||
                (poi as Complex)?.provider_data?.entity_id === COORDINATES_ENTITY) &&
            poi.id !== EMPTY_SPACE_COORDINATE_ID &&
            (poi as Complex)?.provider_data?.entity_id !== ADDRESS_ENTITY
        ) {
            return name;
        }
        if (isAddressTypePoi) {
            return !poi.name ? ADDRESS_COORDINATE_TITLE : ADDRESS_TITLE;
        }
        return name;
    }, [isAddressTypePoi, name, poi]);

    return (
        <div
            className={classnames(styles.wrapper, { [styles.clickable]: onClick })}
            onClick={onClick}
        >
            {visibleFields.logo && (
                <SquareLogo
                    logoUrl={logoImg}
                    name={poi.name}
                    placeType={poiType}
                    className={styles.logoWrapper}
                    icon={logoImgUrl || categoryIcon}
                />
            )}
            <div className={classnames(styles.body, bodyClass)}>
                <div className={styles.topRow}>
                    <span className={classnames(styles.name, printModeCss)}>{poiName}</span>
                    {visibleFields.category && (
                        <>
                            <div className={styles.separator} />
                            <span className={classnames(styles.category, printModeCss)}>
                                {category}
                            </span>
                        </>
                    )}
                    {statusIndicatorPosition === 'top' && !isPrintMode && statusIndicator}
                    {storeId && <StoreIdBadge storeId={storeId} className={storeIdClassName} />}
                </div>
                <div className={styles.bottomRow}>
                    {visibleFields.address && (
                        <span className={classnames(styles.address, printModeCss)}>{address}</span>
                    )}
                    {statusIndicatorPosition === 'bottom' && !isPrintMode && statusIndicator}
                </div>
            </div>
        </div>
    );
};
