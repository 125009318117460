import { SIDEBAR_DIALOG_EVENT_TYPE } from 'ui-components/sidebar-dialog/sidebar-dialog-constants-and-types';
import type { SidebarState } from 'ui-components/sidebar-dialog/sidebar-dialog-constants-and-types';

export function generateUniqueId() {
    let uniqueNumber = 0;
    while (uniqueNumber < 1e10) {
        uniqueNumber += Math.ceil(Math.random() * 1e11);
    }
    return uniqueNumber.toString(36);
}

export function getEventType(uid: string) {
    return `${SIDEBAR_DIALOG_EVENT_TYPE}_${uid}`;
}

export function openSidebarDialog(uid: string) {
    const eventType = getEventType(uid);
    const event = new CustomEvent<SidebarState>(eventType, {
        detail: 'open',
    });
    document.body.dispatchEvent(event);
}

export function closeSidebarDialog(uid: string) {
    const eventType = getEventType(uid);
    const event = new CustomEvent<SidebarState>(eventType, {
        detail: 'close',
    });
    document.body.dispatchEvent(event);
}
