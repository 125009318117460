import React from 'react';
import { PoiSelectionMenuItem } from 'features/poi-selection-map/types/poi-selection-types';
import { PortfolioPoiSelection } from 'features/poi-selection-map/poi-selection-menu/menu-items/portfolio-menu-item/porfolio-poi-selection';

export const PORTFOLIO_ITEM_ID = 'portfolio menu item';

export const PortfolioMenuItem: PoiSelectionMenuItem = {
    id: PORTFOLIO_ITEM_ID,
    title: 'Portfolio',
    render: ({ visible }) => {
        return <>{visible && <PortfolioPoiSelection />}</>;
    },
};
