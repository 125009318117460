import type { PlaceType, PlacerArrayResponse } from '@placer-ui/types';
import { fetchWrapper } from 'API/authentification/fetch-wrapper';
import { Complex } from '@placer-ui/types/src/entities/place';

type RequestBody = {
    entity_ids: {
        entity_id: string;
        entity_type: string;
    }[];
    app_key?: string;
};

export type GetEntityByIdType = {
    id: string;
    type: PlaceType;
};

export const getEntitiesById = async <T extends Complex>(
    entities: GetEntityByIdType[],
    appKey?: string,
): PlacerArrayResponse<T[]> => {
    const body: RequestBody = {
        entity_ids: entities.map((entity) => ({
            entity_id: entity.id,
            entity_type: entity.type,
        })),
    };

    if (appKey) body.app_key = appKey;

    return await fetchWrapper({
        targetUrl: '/entities',
        method: 'POST',
        body,
    });
};
