import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { ExperianMosaicState } from 'shared/store/configuration/experian-mosaic/types';

const initialState: ExperianMosaicState = {
    data: {},
    tree: [],
    isLoaded: false,
    datasetVersion: '',
};

export const experianMosaicSlice = createSlice({
    name: 'experian_mosaic',
    initialState: initialState,
    reducers: {
        resetState: () => {
            return initialState;
        },
        setIsLoaded: (state: ExperianMosaicState, action: PayloadAction<boolean>) => {
            state.isLoaded = action.payload;
        },
        setData: (
            state: ExperianMosaicState,
            action: PayloadAction<ExperianMosaicState['data']>,
        ) => {
            state.data = action.payload;
        },
        setHierarchicalTree: (
            state: ExperianMosaicState,
            action: PayloadAction<ExperianMosaicState['tree']>,
        ) => {
            state.tree = action.payload;
        },
        setDatasetVersion: (
            state: ExperianMosaicState,
            action: PayloadAction<ExperianMosaicState['datasetVersion']>,
        ) => {
            state.datasetVersion = action.payload;
        },
    },
});

export const experianMosaicReducer = experianMosaicSlice.reducer;

export const experianMosaicActions = experianMosaicSlice.actions;
