import { BILLBOARD, CHAIN, PROPERTY, ReportTypes } from 'core/constants/report-type';
import { UserPermissions } from 'core/entities/user/user-permissions';

export const getAvailableReportTypes = (permissions: UserPermissions = {} as UserPermissions) => {
    const hasBillboardPermission = permissions.show_billboards;

    const REPORT_TYPES = [PROPERTY, CHAIN] as ReportTypes[];

    if (hasBillboardPermission) {
        REPORT_TYPES.push(BILLBOARD);
    }

    return REPORT_TYPES;
};
