import type { Chain, Place, PlacerEntityModel, Tag } from '@placer-ui/types';
import { isRegionNationwide, getRegionFullName } from 'core/services/regions-service';

export type TagEntityModel = PlacerEntityModel<Tag>;

export class TagPOI {
    constructor(properties: Partial<Chain>) {
        Object.assign(this, properties);
    }

    static isChain(poi: Place): poi is Chain {
        if (!poi) {
            return false;
        }
        return poi.type === 'chain';
    }

    static isTag(poi: Place | Chain): poi is Tag {
        if (!poi) {
            return false;
        }
        return poi.type === 'tag';
    }

    static getLocationLabel(chain: Chain | Tag): string {
        if (!chain?.area) {
            return '';
        }
        if (isRegionNationwide(chain.area)) {
            return 'Nationwide';
        }

        return getRegionFullName(chain.area) || '';
    }
}
