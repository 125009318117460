import {
    mapPropertyReportsToStoreShape,
    parsePropertyReport,
    updatePropertyRecentReports,
} from 'core/services/recent-reports';
import { setUserCustomSettings } from 'store/auth/actions/set-user-custom-settings/set-user-custom-settings';
import type { UserCustomSettings } from 'core/entities/user/user-custom-settings';
import type { Venue } from 'core/entities/venue/venueAdmin';
import type { AppDispatch } from 'store/store';

export const saveRecentReport =
    (venues: Venue[], customSettings?: UserCustomSettings) => (dispatch: AppDispatch) => {
        //this is temporary development, in the future we will set the types proeprly
        const stateParams = {
            collection: venues[0].collection,
            id: venues[0].id,
            filter: JSON.stringify(venues.map((venue) => venue.customData.filter)),
            competitor:
                venues.length > 1
                    ? JSON.stringify(
                          venues.slice(1, venues.length).map((competitor) => {
                              return {
                                  id: competitor.id,
                                  name: competitor.name,
                                  collection: competitor.collection,
                                  locationLabel:
                                      competitor.info.address.street_name ??
                                      competitor.info.address.short_formatted_address ??
                                      (competitor.collection === 'chains'
                                          ? competitor.address
                                          : ''),
                              };
                          }),
                      )
                    : '',
        };
        //@ts-expect-error PLAC-47814
        const newReportData: any = parsePropertyReport(venues, stateParams);

        if (!newReportData) {
            return;
        }

        const updatedReports: any = updatePropertyRecentReports(
            newReportData,
            customSettings?.insights,
        );

        const updatedReportsStoreShape = mapPropertyReportsToStoreShape(updatedReports);

        const newCustomSettings: any = {
            insights: updatedReportsStoreShape,
        };
        const setCustomSettings = setUserCustomSettings(newCustomSettings);

        dispatch(setCustomSettings);
    };
