import { isMobile } from 'utils/detect-environment';
import { User } from 'core/entities';
import { getIsShowSolutionsGuide } from 'features/onboarding/solutions-guide/utils/utils';

const createGroups = (user?: User) => {
    const groups = ['logged-user'];
    if (isMobile()) {
        groups.push('mobile');
    }

    if (user?.settings?.account?.name) {
        Array.prototype.push.call(groups, 'Has-Account', user.settings.account.name);
    }

    return groups;
};

export const _initElev = (_elev: any, user?: User) => {
    const isFreemiumUser = !user?.settings?.account || user.settings?.account?.is_active === false;

    _elev.setUser({
        email: user?.username,
        user_hash: user?.settings?.elevio_email_hash,
        groups: createGroups(user),
    });
    _elev.setTranslations({
        modules: {
            support: {
                thankyou: 'Thank you, your feedback is valuable',
                delayed_appearance: ' ',
            },
        },
    });
    _elev.setSettings({
        // hide launcher on mobile | when solutions guide appear
        hideLauncher: isMobile() || getIsShowSolutionsGuide(isFreemiumUser),
    });
};

export const initElevio = (user: User) => {
    window._elev.on('load', function(_elev) {
        _initElev(_elev, user);
    });
};
