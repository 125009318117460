import React from 'react';
import classNames from 'classnames';
import { useIsFullPagePath } from 'router/hooks/use-is-full-page-path';
import { useMapMessageEventAction } from 'hooks/use-map-message-event-action/use-map-message-event-action';
import { updateExistingDismissCookie } from 'ui-components/dismissable/utils';
import { HeaderTopPanel } from 'features/header/header-top-panel/header-top-panel';
import { Navigation } from 'features/header/navigation/navigation';
import { useMonitorPageLoading } from '../../shared/telemetry/hooks/use-monitor-page-loading';
import styles from './header.module.scss';

const classes = classNames('header-no-print', styles.headerWrapper);

export const Header = () => {
    useMonitorPageLoading();
    useMapMessageEventAction();
    updateExistingDismissCookie();
    const isHidden = useIsFullPagePath();

    if (isHidden) {
        return null;
    }

    return (
        <header className={classes}>
            <HeaderTopPanel />
            <Navigation />
        </header>
    );
};
