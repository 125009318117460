import { useSelector } from 'react-redux';
import {
    ConfigurationCategoriesData,
    ConfigurationCategoriesDataGroupCategory,
} from '@placer-ui/types';

import {
    selectConfigurationCategories,
    selectCategoriesData,
    selectCategoriesWithChains,
    selectPrimaryCategoriesData,
    selectAvailableCategoriesData,
    selectAvailableCategoriesWithChains,
    selectAvailableSubCategoryNames,
    selectAvailablePrimaryCategories,
    selectIsCategoriesInitialized,
} from './configuration-categories-selectors';

export const useSelectConfigurationCategories = () => useSelector(selectConfigurationCategories);
export const useSelectCategoriesData = () =>
    useSelector(selectCategoriesData) as ConfigurationCategoriesData[];
export const useSelectCategoriesWithChains = () =>
    useSelector(selectCategoriesWithChains) as ConfigurationCategoriesDataGroupCategory[];
export const useSelectPrimaryCategoriesData = () =>
    useSelector(selectPrimaryCategoriesData) as ConfigurationCategoriesData[];
export const useSelectAvailableCategoriesData = () =>
    useSelector(selectAvailableCategoriesData) as ConfigurationCategoriesData[];
export const useSelectAvailableCategoriesWithChains = () =>
    useSelector(selectAvailableCategoriesWithChains) as ConfigurationCategoriesDataGroupCategory[];
export const useSelectAvailableSubCategoryNames = () =>
    useSelector(selectAvailableSubCategoryNames) as string[];
export const useSelectAvailablePrimaryCategories = () =>
    useSelector(selectAvailablePrimaryCategories);
export const useSelectIsCategoriesInitialized = () => useSelector(selectIsCategoriesInitialized);
