import { useCallback } from 'react';
import { useWidgetPrintMode } from 'extensions/widget/hooks/use-widget-context-hooks';
import { useSplunkCallback } from 'hooks/use-splunk-callback/use-splunk-callback';

export const MAPBOX = 'mapbox';
export const GOOGLE = 'google';
export type mapProvider = 'mapbox' | 'google';

export const useSendSplunkOnMapLoad = (source: string = '', mapProvider: mapProvider = GOOGLE) => {
    const sendEvent = useSplunkCallback();
    const isPrintMode = useWidgetPrintMode();
    return useCallback(
        () =>
            sendEvent({
                action: 'map loaded',
                component: `${source}${isPrintMode ? ' - Print' : ''}`,
                mapProvider,
                subdomain: window.location.hostname,
            }),
        [isPrintMode, sendEvent, source, mapProvider],
    );
};
