import { getAppApiEndpoint } from 'core/default-endpoint-url';

const localhost = 'http://localhost:9000';
const env = process.env.REACT_APP_API_ENDPOINT as string;
const defaultSource = 'placer-dashboard';
let targetOrigin = window.location.origin === localhost ? localhost : env;

/**
 * Sending string or object data via postMessage
 * https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
 * @param {data} type of string or object
 * @param {source} type of string, unique identifier of the sender
 */
export const sendMessage = async (data?: string, source: string = defaultSource) => {
    if (!targetOrigin.includes('localhost')){
        targetOrigin = getAppApiEndpoint();
    }
    const json = {
        source: source,
        payload: data,
    };

    try {
        window.postMessage(json, targetOrigin);
    } catch (error) {
        console.error(`Could not post message to ${targetOrigin}`, error);
    }
};

/**
 * Listener for incoming messages from the matching source and origin.
 * @param {source} type of string, unique identifier of the sender
 * @param {handleMessages} function to handle the incoming message event on the receiver end
 * @Important: Receiver is expected to call the cleanup function after the message was received
 * i.e. received = waitForMessages(source, handleMessages) - call received() when done to cleanup
 */

export const waitForMessages = (
    source: string = defaultSource,
    handleMessages: (event: MessageEvent) => void,
) => {
    const listener = (event: MessageEvent) => {
        if (event.data.source === source) {
            handleMessages(event);
        }
    };

    window.addEventListener('message', listener);

    return () => {
        window.removeEventListener('message', listener);
    };
};
