import React from 'react';
import { SgEducationIcon } from 'features/onboarding/solutions-guide/icons/sg-education-icon';

export const EDUCATION_SOLUTIONS_GUIDE = {
    icon: <SgEducationIcon />,
    solutions: [
        {
            title: 'Student Recruitment',
            description:
                'Explore regional audience demographics to ' +
                'identify areas with high concentrations of potential students.',
            url: '/insights/complexes/6310bfd2566d68006a9a3d90/audience',
        },
        {
            title: 'Student Insights',
            description:
                'Uncover comprehensive insights about your students, based on their online and offline behavior.',
            url: '/advanced-reports/freemium/persona-live',
        },
        {
            title: 'Campus Operations',
            description:
                'Identify facility areas with the highest need ' +
                'for resources to ensure they are distributed accordingly.',
            url: '/insights/complexes/6310bfd2566d68006a9a3d90/overview',
        },
        {
            title: 'Transportation Planning',
            description:
                'Optimize school bus routes and transportation ' +
                'logistics based on the distribution of student home locations.',
            url: '/insights/complexes/6310bfd2566d68006a9a3d90/trade-area',
        },
        {
            title: 'Local Partnerships',
            description:
                'Identify and map potential community partners, such ' +
                'as local businesses, non-profits, and other educational institutions.',
            url: '/insights/complexes/6310bfd2566d68006a9a3d90/overview',
        },
    ],
};
