import { numberFormat } from 'utils/number/number';
import { RankingTableColumn } from '../types/ranking-table';
import { ScoreBoundsKeys } from '../types/server-responses';
import {
    formatDistance,
    formatPercentage,
    formatQuantity,
    formatScore,
    formatVenueSizeRange,
    parseValueForDisplay,
    parseValueIfNull,
} from './value-formatters';
import { STRING_FOR_NULL_DATA_TO_DISPLAY } from 'components/advanced-reports-analysis/widgets/constants/constants';
import {
    CardTypes,
    CardValues,
    MinMax,
} from 'components/advanced-reports-analysis/match-analysis-page/types/types';

export type MetricType = RankingTableColumn | CardTypes | ScoreBoundsKeys;
export type ValueType = CardValues;

export const parseMetricValue = (
    value: ValueType,
    key: MetricType,
    supportNewChainFlow?: boolean | null,
) => {
    const valueParse = parseValueIfNull(value);
    if (typeof valueParse === 'string' && key !== 'state_expansion_rate') return valueParse;

    switch (key) {
        case 'typical_size':
        case 'store_size':
            const { min, max } = valueParse as MinMax;
            if (max === null || min === null) return STRING_FOR_NULL_DATA_TO_DISPLAY;
            return formatVenueSizeRange(min, max);
        case 'cannibalization_score':
        case 'cannibalization':
            return formatPercentage(valueParse as number, 2);
        case 'nearest_location':
        case 'nearest_venue':
            return `${formatDistance(valueParse as number)} mi`;
        case 'avg_monthly_ft':
            return formatQuantity(valueParse as number, 1, true);
        case 'number_of_venues':
            return numberFormat(valueParse as number, 0, true, true);
        case 'household_characteristics':
        case 'total_match_score':
            return formatScore(valueParse as number);
        case 'expansion_rate':
        case 'state_expansion_rate':
            const valueDisplay = parseValueForDisplay(value as number | null, supportNewChainFlow);
            if (typeof valueDisplay === 'string') return valueDisplay;
            return formatPercentage(value as number, 2);
        case 'median_dvt':
            return formatQuantity(valueParse as number, 0, false);
        default:
            return formatQuantity(valueParse as number, 0, true);
    }
};
