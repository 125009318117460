import React, { useMemo } from 'react';
import { Modal } from 'antd';
import { CloseIcon } from 'components/assets/Icons/Icons';
import { PoiItem } from 'features/void-analysis/sections/entry-point-redirection/void-analysis-reports-modal/poi-item';
import { Button } from 'ui-components/button/button';
import { VoidAnalysisPoi } from 'features/void-analysis/common/types/entities';
import styles from './void-analysis-reports-modal.module.scss';

type VoidAnalysisReportsModalProps = {
    pois: VoidAnalysisPoi[];
    visible: boolean;
    onClose: () => void;
};

const usePoisList = (poiList: VoidAnalysisPoi[]) => {
    return useMemo(() => {
        return poiList.map((poi) => <PoiItem poi={poi} key={poi.uid} />);
    }, [poiList]);
};

export const VoidAnalysisReportsModal = ({
    visible,
    pois,
    onClose,
}: VoidAnalysisReportsModalProps) => {
    const poiList = usePoisList(pois);

    return (
        <Modal
            centered
            destroyOnClose
            closable={false}
            footer={false}
            visible={visible}
            onCancel={onClose}
            wrapClassName={styles.modalWrapper}
            width={600}
        >
            <div className={styles.container}>
                <div className={styles.header}>
                    <span className={styles.title}>Void Analysis reports</span>
                    <Button type={'ghost'} onClick={onClose} className={styles.closeButton}>
                        <CloseIcon className={styles.closeIcon} />
                    </Button>
                </div>
                <div className={styles.poiItemsContainer}>
                    <div className={styles.scroller}>{poiList}</div>
                </div>
            </div>
        </Modal>
    );
};
