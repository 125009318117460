import type { PlaceCollection, PlaceType } from '@placer-ui/types';

export const PROPERTY = 'Property' as const;
export const COMPLEX = 'Complex' as const;
export const VENUE = 'Venue' as const;
export const REGION = 'Region' as const;
export const CHAIN = 'Chain' as const;
export const BILLBOARD = 'Billboard' as const;
export const TRAFFIC_PIN = 'Traffic Pin' as const;
export const TAG = 'Tag' as const;
export const PROPERTY_AND_BILLBOARDS = 'Property and Billboard' as const;
export const INDUSTRY = 'Industry' as const;

export const REPORT_TYPE_TO_ENTITY_TYPE = {
    [PROPERTY]: ['venue', 'complex'] as PlaceType[],
    [COMPLEX]: ['complex'] as PlaceType[],
    [VENUE]: ['venue'] as PlaceType[],
    [REGION]: ['region'] as PlaceType[],
    [CHAIN]: ['chain'] as PlaceType[],
    [BILLBOARD]: ['billboard'] as PlaceType[],
    [TRAFFIC_PIN]: ['billboard'] as PlaceType[],
    [TAG]: ['tag'] as PlaceType[],
    [PROPERTY_AND_BILLBOARDS]: ['venue', 'complex', 'billboard'] as PlaceType[],
    [INDUSTRY]: ['industry'] as PlaceType[],
};

export const REPORT_TYPE_TO_PLACE_COLLECTIONS = {
    [PROPERTY]: ['venues', 'complexes'] as PlaceCollection[],
    [COMPLEX]: ['complexes'] as PlaceCollection[],
    [VENUE]: ['venues'] as PlaceCollection[],
    [REGION]: ['regions'] as PlaceCollection[],
    [CHAIN]: ['chains'] as PlaceCollection[],
    [BILLBOARD]: ['billboards'] as PlaceCollection[],
    [TRAFFIC_PIN]: ['billboards'] as PlaceCollection[],
    [TAG]: ['tags'] as PlaceCollection[],
    [PROPERTY_AND_BILLBOARDS]: ['venues', 'complexes', 'billboards'] as PlaceCollection[],
    [INDUSTRY]: ['industries'] as PlaceCollection[],
};
export type ReportTypeToEntityType = typeof REPORT_TYPE_TO_ENTITY_TYPE;

export type ReportTypes = keyof typeof REPORT_TYPE_TO_ENTITY_TYPE;

export const PROPERTY_TYPE = 'venues,complexes' as const;
export const COMPLEX_TYPE = 'complexes' as const;
export const REGION_TYPE = 'regions' as const;
export const BILLBOARD_TYPE = 'billboards' as const;

export const REPORT_ENTITY_TYPE_TO_TYPE = {
    [PROPERTY_TYPE]: PROPERTY,
    [COMPLEX_TYPE]: COMPLEX,
    [REGION_TYPE]: REGION,
    [BILLBOARD_TYPE]: BILLBOARD,
};
export type ReportEntityTypes = keyof typeof REPORT_ENTITY_TYPE_TO_TYPE;

export const PLACE_TYPE_TO_REPORT_TYPE: { [key in PlaceType]?: string } = {
    venue: PROPERTY,
    complex: COMPLEX,
    chain: CHAIN,
    billboard: TRAFFIC_PIN,
    tag: TAG,
};
