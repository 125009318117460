import { useContext } from 'react';
import { MapContext } from 'ui-components/google-map-with-context/context/google-map-with-context-provider';

const useGoogleMapWithContextState = () => {
    return useContext(MapContext);
};

export const useMapContext = () => {
    const { map } = useGoogleMapWithContextState();
    return map;
};

export const useSetMapContext = () => {
    const { setMap } = useGoogleMapWithContextState();
    return setMap;
};
