import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

import { IconProps } from 'components/assets/Icons/Icons';

/* eslint-disable max-len */
const BillboardIconSvg = (props: CustomIconComponentProps | SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18px"
        height="18px"
        viewBox="0 0 18 18"
        version="1.1"
        {...props}
    >
        <title>Icons_18/Blue/billboard</title>
        <g
            id="Icons_18/Blue/billboard"
            stroke="none"
            strokeWidth="1"
            fill="inherit"
            fillRule="evenodd"
        >
            <line
                x1="5.5"
                y1="-2.95582309e-12"
                x2="5.5"
                y2="18"
                id="Line-4"
                stroke="#49565D"
                strokeWidth="0.75"
            ></line>
            <line
                x1="12.5"
                y1="-2.95582309e-12"
                x2="12.5"
                y2="18"
                id="Line-4-Copy"
                stroke="#49565D"
                strokeWidth="0.75"
            ></line>
            <line
                x1="4"
                y1="17.63"
                x2="7"
                y2="17.63"
                id="Line-4"
                stroke="#49565D"
                strokeWidth="0.75"
                strokeLinecap="round"
            ></line>
            <line
                x1="11"
                y1="17.63"
                x2="14"
                y2="17.63"
                id="Line-4-Copy-2"
                stroke="#49565D"
                strokeWidth="0.75"
                strokeLinecap="round"
            ></line>
            <rect
                id="Rectangle"
                stroke="#49565D"
                strokeWidth="0.75"
                fill="#FFFFFF"
                x="0.375"
                y="1.375"
                width="17.25"
                height="11.25"
                rx="1"
            ></rect>
            <rect id="Rectangle" fill="inherit" x="2" y="3" width="14" height="8" rx="1"></rect>
        </g>
    </svg>
);

export const BillboardIcon = (props: IconProps) => <Icon component={BillboardIconSvg} {...props} />;
