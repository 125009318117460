import { createSelector } from '@reduxjs/toolkit';

import { getAvailableReportTypes } from 'core/services/permissions-service/permissions-service';

import { userPermissionsSelector } from 'store/selectors/app-selectors';

export const selectTopSearchReportTypes = createSelector(
    userPermissionsSelector,
    getAvailableReportTypes,
);
