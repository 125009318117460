import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { destroyStore } from 'store/destroy-store';
import { advancedReportsReducer } from 'features/advanced-reports/store/advanced-reports-store';
import { appUiReducer } from 'store/app-ui/app-ui-reducer';
import { authReducer } from 'store/auth/auth';
import { chainsReportReducer } from 'features/chains/store/chains-store';
import { configurationReducer } from 'store/configuration/configuration-reducer';
import { exploreReducer } from 'features/explore/store/slice';
import { externalIframeReportsReducer } from 'features/external-iframe-reports/store/slice';
import { industryReportReducer } from 'features/industry/store/chains-store';
import { insightsReducer } from 'features/insights/store/insights-store';
import { marketplaceReducer } from 'features/marketplace/store/slice';
import { marketReducer } from 'features/market/store/slice';
import { myZoneReducer } from 'features/my-zone/store/slice';
import { offlineReportsReducer } from './offline-reports/offline-reports-store';
import { recoveryDashboardReducer } from 'features/recovery-dashboard/store/recovery-dashboard-store';
import { settingsReducer } from 'features/settings/store/slice';
import { starReportReducer } from './star-report/star-report-store';
import { topSearchReducer } from './header/header-store';
import { trendsReducer } from 'features/trends/store/trends-store';
import { widgetsReducer } from 'extensions/widget/store/widget-reducer';

const appReducers = {
    ...advancedReportsReducer,
    ...appUiReducer,
    ...authReducer,
    ...chainsReportReducer,
    ...configurationReducer,
    ...exploreReducer,
    ...externalIframeReportsReducer,
    ...industryReportReducer,
    ...insightsReducer,
    ...marketplaceReducer,
    ...marketReducer,
    ...myZoneReducer,
    ...offlineReportsReducer,
    ...recoveryDashboardReducer,
    ...settingsReducer,
    ...starReportReducer,
    ...topSearchReducer,
    ...trendsReducer,
    ...widgetsReducer,
};

const combinedStaticReducers = combineReducers(appReducers);

const rootStaticReducer = (state: any, action: any) => {
    return combinedStaticReducers(action.type === destroyStore.type ? undefined : state, action);
};

const configureAppStore = () => {
    // TODO:
    //  Redux recommendation is don't store class instances to the state as well as doing mutation on that class.
    //  Right now because we have a dependency on legacy angularjs classes (venuAdmin & venueAdminFilter),
    //  which mutate and are saved to the state - we are disabling this checks
    //  We are not included thunk directly, because configureStore provides better types for us
    const store = configureStore({
        reducer: rootStaticReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false,
                immutableCheck: false,
            }),
    });

    if (process.env.REACT_APP_DEBUG === 'true') {
        (window as any).__store = store;
    }

    return store;
};

export function registerReducer<PassedReducers>(reducerToInject: PassedReducers) {
    if (!store) {
        throw new Error('Store should be initialized before register reducer');
    }

    const combineReducer = combineReducers({
        ...appReducers,
        ...reducerToInject,
    });

    const nextReducer = (state: any, action: any) => {
        return combineReducer(action.type === destroyStore.type ? undefined : state, action);
    };

    /**
     * We do it for correct type infer from reducer for features.
     * Because store is cross-cutting component in our project there is no way to intersect
     * initial AppState and feature State
     * But we can correctly infer type from returned reducer for features
     */
    store.replaceReducer(nextReducer);

    return nextReducer;
}

export const store = configureAppStore();

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;
