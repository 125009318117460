import { MultiSelectOption, TreeNode } from 'ui-components/multi-select/types';
import React, { Key, useMemo } from 'react';
import { getLeavesOptionsKeys } from 'ui-components/multi-select/checkbox-list-v4/utils/util';
import { TreeTitle } from 'ui-components/multi-select/checkbox-list-v4/option-title/option-title';

type TreeOptionsData = {
    options: MultiSelectOption[];
    searchValue: string;
    isDropMenu: boolean;
    titleClass?: string;
};

export const useTreeOptionsData = ({
    options,
    searchValue,
    isDropMenu,
    titleClass,
}: TreeOptionsData): TreeNode[] => {
    return useMemo(() => {
        const treeOption = (node: MultiSelectOption): TreeNode => {
            let subOptions;
            if (node.subOptions) {
                subOptions = node.subOptions.map((subOp) => {
                    const subOpTree = treeOption(subOp);

                    return {
                        title: (
                            <TreeTitle
                                title={subOpTree.name}
                                searchValue={searchValue}
                                isDropMenu={isDropMenu}
                                titleClass={titleClass}
                            />
                        ),
                        disabled: subOpTree.disabled,
                        key: subOpTree.key,
                        name: subOpTree.name,
                        value: subOpTree.value,
                        ...(subOpTree.children && { children: subOpTree.children }),
                    };
                });
            }

            return {
                title: (
                    <TreeTitle
                        title={node.name}
                        searchValue={searchValue}
                        isDropMenu={isDropMenu}
                        titleClass={titleClass}
                    />
                ),
                disabled: node.disabled,
                key: node.key ?? node.value,
                name: node.name,
                value: node.value,
                ...(subOptions && { children: subOptions }),
            };
        };

        return options.map((op) => {
            return treeOption(op);
        });
    }, [isDropMenu, options, searchValue, titleClass]);
};

export const getConvertOptionsToValues = (
    selectedKeys: Key[],
    options: MultiSelectOption[],
    enableTreeCheckboxList?: boolean,
) => {
    if (!enableTreeCheckboxList) return [];

    const allOptionsLeaves = getLeavesOptionsKeys(options);

    const selectedValues: MultiSelectOption[] = [];
    allOptionsLeaves.forEach((op) => {
        if (op.key && selectedKeys.includes(op.key)) {
            selectedValues.push(op);
        }
    });

    return selectedValues.map((item) => ({
        ...item,
        selected: true,
    }));
};
