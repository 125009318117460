import { useCallback } from 'react';
import { useGoToVoidAnalysis } from 'router/go-to-routes/go-to-void-analysis';
import { API_VERSION } from 'API/void-analysis-api';

export const useGotoRankingPage = () => {
    const goToVoidAnalysis = useGoToVoidAnalysis();

    return useCallback(
        (id: string | number) => {
            goToVoidAnalysis(`${API_VERSION}/${id}/ranking`);
        },
        [goToVoidAnalysis],
    );
};
