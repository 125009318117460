/* eslint-disable max-len */
import React from 'react';

export function WeatherBlizzardIcon() {
    return (
        <svg width="70" height="54" viewBox="0 0 70 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.4323 27.3693C16.029 28.0678 15.9167 28.8544 16.0578 29.5889H4.9764C3.20876 27.6139 2.10553 25.0424 2.00708 22.2144C2.04898 22.1041 2.07191 21.9844 2.07191 21.8594C2.07191 15.2674 7.14046 10.2813 13.7358 10.2813C17.8538 10.2813 21.4087 11.984 23.5302 15.0397C23.8452 15.4933 24.4683 15.6058 24.9219 15.2908C25.3756 14.9758 25.488 14.3527 25.1731 13.8991C23.4772 11.4564 21.0429 9.77635 18.2254 8.92607C20.9603 4.75298 25.683 2 31.0509 2C38.9968 2 45.6127 8.43239 46.3001 16.2835L46.4599 18.109H49.0264C52.2312 18.109 54.8205 20.6991 54.8205 23.8834C54.8205 27.0663 52.2327 29.6559 49.0288 29.6572H49.0264L31.9371 29.6146C32.0858 28.8728 31.9756 28.0759 31.5676 27.3693C30.689 25.8474 28.7574 25.3126 27.2245 26.1536C27.1864 24.4054 25.7574 23 24.0001 23C22.2427 23 20.8137 24.4055 20.7757 26.1537C19.2427 25.3125 17.3109 25.8474 16.4323 27.3693Z" fill="#D0D0D0"/>
<path d="M22.7749 26.2252C22.7749 25.5485 23.3235 25 24.0001 25C24.6767 25 25.2253 25.5485 25.2253 26.2252V29.6163L28.162 27.9208C28.748 27.5825 29.4973 27.7833 29.8356 28.3693C30.1739 28.9553 29.9731 29.7045 29.3872 30.0429L26.4503 31.7385L29.3872 33.4341C29.9731 33.7724 30.1739 34.5217 29.8356 35.1077C29.4973 35.6937 28.748 35.8944 28.162 35.5561L25.2253 33.8606V37.2516C25.2253 37.9283 24.6767 38.4768 24.0001 38.4768C23.3235 38.4768 22.7749 37.9283 22.7749 37.2516V33.8604L19.8379 35.5561C19.252 35.8944 18.5027 35.6936 18.1643 35.1077C17.826 34.5217 18.0268 33.7724 18.6128 33.4341L21.5496 31.7385L18.6128 30.0429C18.0268 29.7046 17.826 28.9553 18.1643 28.3693C18.5027 27.7833 19.252 27.5825 19.838 27.9208L22.7749 29.6165V26.2252Z" fill="#D0D0D0"/>
<path d="M9.97926 38.021C9.97926 37.4571 10.4364 37 11.0003 37C11.5641 37 12.0213 37.4571 12.0213 38.021V40.7316L14.3688 39.3762C14.8571 39.0943 15.4816 39.2616 15.7635 39.7499C16.0454 40.2383 15.8781 40.8627 15.3898 41.1446L13.0422 42.5L15.3896 43.8553C15.878 44.1372 16.0453 44.7617 15.7633 45.25C15.4814 45.7383 14.857 45.9057 14.3686 45.6237L12.0213 44.2685V46.979C12.0213 47.5429 11.5641 48 11.0003 48C10.4364 48 9.97926 47.5429 9.97926 46.979V44.2684L7.63192 45.6236C7.14358 45.9056 6.51915 45.7383 6.23721 45.2499C5.95527 44.7616 6.12259 44.1372 6.61092 43.8552L8.95822 42.5L6.61076 41.1447C6.12243 40.8628 5.95512 40.2383 6.23706 39.75C6.519 39.2617 7.14343 39.0943 7.63176 39.3763L9.97926 40.7316V38.021Z" fill="#D0D0D0"/>
<path d="M58.7534 32.5C58.7534 30.2577 60.5203 28.5 62.6267 28.5C64.7331 28.5 66.5 30.2577 66.5 32.5C66.5 34.7423 64.7331 36.5 62.6267 36.5H36C35.1716 36.5 34.5 37.1716 34.5 38C34.5 38.8284 35.1716 39.5 36 39.5H62.6267C66.4555 39.5 69.5 36.3328 69.5 32.5C69.5 28.6672 66.4555 25.5 62.6267 25.5C58.798 25.5 55.7534 28.6672 55.7534 32.5C55.7534 33.3284 56.425 34 57.2534 34C58.0819 34 58.7534 33.3284 58.7534 32.5Z" fill="#D0D0D0"/>
<path d="M49.0635 50.5C47.7405 50.5 46.6271 49.4034 46.6271 48C46.6271 47.1716 45.9555 46.5 45.1271 46.5C44.2986 46.5 43.6271 47.1716 43.6271 48C43.6271 51.0148 46.0385 53.5 49.0635 53.5C52.0886 53.5 54.5 51.0148 54.5 48C54.5 44.9852 52.0886 42.5 49.0635 42.5L22 42.5C21.1716 42.5 20.5 43.1716 20.5 44C20.5 44.8284 21.1716 45.5 22 45.5L49.0635 45.5C50.3866 45.5 51.5 46.5966 51.5 48C51.5 49.4034 50.3866 50.5 49.0635 50.5Z" fill="#D0D0D0"/>
</svg>

    );
}
WeatherBlizzardIcon.__tags = [] as string[];
