import React from 'react';
import classNames from 'classnames';

import { SmallSelectArrowIcon } from 'components/assets/Icons/Icons';

import styles from './arrow-icon-button.module.scss';

type ArrowIconButtonProps = {
    arrowDirection?: ArrowDirection;
};

export type ArrowDirection = 'down' | 'up' | 'left' | 'right';

export const ArrowIconButton = ({ arrowDirection = 'down' }: ArrowIconButtonProps) => {
    return (
        <div
            className={classNames(styles.arrow, {
                [styles.up]: arrowDirection === 'up',
                [styles.left]: arrowDirection === 'left',
                [styles.right]: arrowDirection === 'right',
            })}
        >
            <SmallSelectArrowIcon />
        </div>
    );
};
