import type {
    Attribute,
    Attributes,
    FilterOperator,
    FilterType,
    FilterValue,
} from '@placer-ui/types';
import { FilterInsights } from 'core/entities/filter';
import { milesToMeters, metersToMiles } from 'ui-components/google-map/utils';

export const convertDistanceFilterData = (
    filterValues: string[],
    type: string,
    backendFilter: FilterInsights,
) => {
    const rangeFilters: any[] = ['any'];

    filterValues.forEach((val) => {
        const values = val.split('/');
        const result: (string | number)[] = [];
        const betweenResult: (string | number)[] = [];
        const rangeFilter: any[] = ['all'];

        if (values[0] === '<') {
            result.push('<');
            result.push(type);
            result.push(Math.round(milesToMeters(parseInt(values[1]))));
            rangeFilter.push(result);

            if (filterValues.length === 1) {
                backendFilter.attributes?.push(result as Attribute);
            } else {
                rangeFilters.push(rangeFilter);
            }
        } else if (values[0] === '>') {
            result.push('>');
            result.push(type);
            result.push(Math.round(milesToMeters(parseInt(values[1]))));
            rangeFilter.push(result);

            if (filterValues.length === 1) {
                backendFilter.attributes?.push(result as Attribute);
            } else {
                rangeFilters.push(rangeFilter);
            }
        } else {
            result.push('>');
            result.push(type);
            result.push(Math.floor(milesToMeters(parseInt(values[0]))));

            rangeFilter.push(result);

            betweenResult.push('<');
            betweenResult.push(type);
            betweenResult.push(Math.floor(milesToMeters(parseInt(values[1]))));

            rangeFilter.push(betweenResult);
            rangeFilters.push(rangeFilter);
        }
    });

    if (rangeFilters.length > 1) {
        backendFilter.attributes?.push(rangeFilters as any);
    }

    return;
};

const findSimilarFilterRangeIndex = (backendFilter: FilterInsights, type: string) => {
    let backendFilterIndex = 0;

    backendFilter.attributes?.forEach((attribute, index) => {
        if (typeof attribute !== 'string') {
            if (attribute[0] === 'any' && attribute?.[1]?.[1]?.[1] === type) {
                backendFilterIndex = index;
            }
        }
    });
    return backendFilterIndex;
};

export const convertDistanceFilterDataFromUrl = (
    filterValues: FilterType,
    operator?: FilterOperator,
    filterValue?: FilterValue,
) => {
    if (Array.isArray(filterValues) && filterValues.length === 3) {
        const lowerRangeValue = filterValues[1];
        const upperRangeValue = filterValues[2];

        const [, lowerFilterName, lowerValue] = lowerRangeValue;
        const [, upperFilterName, upperValue] = upperRangeValue;

        let filterValue = '';
        if (lowerFilterName === upperFilterName) {
            const lowerValueInMiles = metersToMiles(Number(lowerValue)).toFixed(0);
            const upperValueInMiles = metersToMiles(Number(upperValue)).toFixed(0);

            filterValue = `${lowerValueInMiles}/${upperValueInMiles}/mi`;
            return [lowerFilterName, filterValue];
        }
        return [];
    } else if (filterValues.length === 2) {
        const [edgeOperator, edgeFilterName, edgeValue] = filterValues[1];
        const valueInMiles = metersToMiles(Number(edgeValue)).toFixed(0);

        return [edgeFilterName, `${edgeOperator}/${valueInMiles}/mi`];
    } else {
        const valueInMiles = metersToMiles(Number(filterValue)).toFixed(0);

        return `${operator}/${valueInMiles}/mi`;
    }
};

export const convertTimeFilterData = (
    filterValues: string[],
    type: string,
    backendFilter: FilterInsights,
) => {
    filterValues.forEach((val) => {
        const values = val.split('-');
        const result = [];
        const betweenResult = [];

        const rangeFilters = [];
        const rangeFilter = [];

        rangeFilters.push('any');
        rangeFilter.push('all');

        result.push('between');
        result.push(type);
        betweenResult.push(values[0]);
        betweenResult.push(values[1]);
        result.push(betweenResult);

        rangeFilter.push(result);

        const backendFilterIndex = findSimilarFilterRangeIndex(backendFilter, type);

        if (backendFilterIndex === 0) {
            rangeFilters.push(rangeFilter);

            backendFilter.attributes?.push(rangeFilters as any);
        } else {
            (backendFilter.attributes?.[backendFilterIndex] as Array<string>).push(
                rangeFilter as any,
            );
        }
    });

    return;
};

export const convertTimeFilterDataFromUrl = (filterValues: Attributes) => {
    const [, filterRangeValue] = filterValues;
    const [, , rangeValue] = filterRangeValue;

    return ['time', (rangeValue as string[]).join('-')];
};
