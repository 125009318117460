import { createAsyncThunk } from '@reduxjs/toolkit';
import type {
    Billboard,
    Chain,
    Dictionary,
    PlacerEntityResponse,
    PlacerEntityWrapper,
    Venue,
} from '@placer-ui/types';
import { API } from 'API';
import { TopSearchApiOptions } from 'store/header/search/models/top-search-api-options';
import {
    BILLBOARD,
    CHAIN,
    COMPLEX,
    REGION,
    PROPERTY,
    TRAFFIC_PIN,
    PROPERTY_AND_BILLBOARDS,
} from 'core/constants/report-type';
import { MAX_NUMBER_OF_TOP_SEARCH_ITEMS } from 'store/header/search/constants';
import {
    searchPropertyAndBillboard,
    searchBillboards,
    searchChains,
    searchComplex,
    SearchParams,
    searchProperty,
} from 'API/search/search-api';
import { SEARCH_SPLUNK_ACTIONS, SPLUNK_SEARCH_PLACEMENTS } from 'shared/constants/search-splunk';

export const searchReportTypeToSearchAPI: Dictionary<
    (
        {
            term,
            skip,
            limit,
            purchasedOnly,
            region,
            category,
            fallbackGooglePOIs,
            appendGoogleAddress,
            appKey,
        }: SearchParams,
        authorized?: boolean,
        signal?: AbortSignal,
    ) => PlacerEntityResponse<(Venue | Chain | Billboard)[]>
> = {
    [PROPERTY]: searchProperty,
    [COMPLEX]: searchComplex,
    [REGION]: searchProperty,
    [CHAIN]: searchChains,
    [BILLBOARD]: searchBillboards,
    [TRAFFIC_PIN]: searchBillboards,
    [PROPERTY_AND_BILLBOARDS]: searchPropertyAndBillboard,
};

export const topSearchGetEntities = createAsyncThunk(
    'top-search/get-entities',
    async ({
        term,
        searchReportType,
        appendGoogleAddress,
        fallbackGooglePOIs,
        source,
        sendSplunkCallback,
        searchId,
        purchasedOnly = false,
    }: TopSearchApiOptions) => {
        let api;
        if (Array.isArray(searchReportType)) {
            api = API.search.searchPOI(searchReportType);
        } else {
            api = searchReportTypeToSearchAPI[searchReportType];
        }

        const searchParam = {
            limit: MAX_NUMBER_OF_TOP_SEARCH_ITEMS,
            term,
            purchasedOnly,
            appendGoogleAddress,
            fallbackGooglePOIs,
            source,
            showPoisInCriticalArea: true,
        };

        const { data } = await api(searchParam);

        const entities = (data as PlacerEntityWrapper<Billboard | Chain | Venue>[]).map(
            (poi) => poi.info,
        );

        sendSplunkCallback?.({
            num_of_results: entities.length,
            action: SEARCH_SPLUNK_ACTIONS.search,
            search_placement: SPLUNK_SEARCH_PLACEMENTS.topBar,
            search_term: term,
            search_id: searchId,
            search_query_params: searchParam,
        });

        return { entities };
    },
);
