import React from 'react';
import IframeWithLoading from 'components/with-loading/iframe-with-loading';
import styles from './youtube-video.module.scss';

interface YouTubeVideoProps {
    videoID: string;
    autoplay?: boolean;
    loop?: boolean;
    width?: number;
    height?: number;
}

export const YouTubeVideo: React.FC<YouTubeVideoProps> = ({
    videoID,
    autoplay,
    loop,
    width,
    height,
}) => {
    const loopParam = loop ? `&loop=1&playlist=${videoID}` : '';
    const autoplayParam = autoplay ? '&autoplay=1' : '';
    const src = `https://www.youtube.com/embed/${videoID}?rel=0&showinfo=0${autoplayParam}${loopParam}`;

    return (
        <div className={styles.videoWrapper}>
            <IframeWithLoading
                data-testid='youtube-video'
                width={width}
                height={height}
                src={src}
                title='YouTube video player'
                frameBorder='0'
                // eslint-disable-next-line max-len
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            ></IframeWithLoading>
        </div>
    );
};
