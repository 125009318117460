/* eslint-disable max-len */
import React from 'react';
import { VenueFlags } from 'core/entities';
import { Tooltip } from 'ui-components/tooltip/tooltip';
import { AlertColoredIcon } from 'components/assets/Icons/Icons';
import { dateFormats, getSimpleFormatDate } from 'utils/date/date';
import styles from './venue-status-indicator.module.scss';
import classNames from 'classnames';
import { FlaggedVenueIndicatorProps } from 'ui-components/venue-status-indicator/venue-status-indicator';

const LIMITED_DATA_AVAILABLE = 'Limited data available';

export const flagsMap: Record<VenueFlags, string> = {
    flagged_indoor_mall: 'Indoor Venue',
    flagged_multistory_building: 'Multistory Building',
    ft_abnormality: 'Unusual Visits Volume',
    flagged_dense_area: 'Dense Area',
    privacy_concerns: 'This is a restricted location. Contact support for access.',
    unknown_flag: LIMITED_DATA_AVAILABLE,
    nearby_activity: LIMITED_DATA_AVAILABLE,
    not_available: LIMITED_DATA_AVAILABLE,
};

const fallbackText = 'Unverified Property. Limited report available.';

export const venueFlagInfoPublicPOIText: Record<VenueFlags, string> = {
    flagged_indoor_mall: 'Indoor Venue. Report on enclosing venue.',
    flagged_multistory_building: 'Multistory Building. Report on enclosing venue.',
    ft_abnormality: 'Unverified Property. Limited report available.',
    flagged_dense_area: 'High location density. Limited report available.',
    privacy_concerns: 'This is a restricted location. Contact support for access.',
    unknown_flag: fallbackText,
    nearby_activity: fallbackText,
    not_available: fallbackText,
};

export function getVenueFlagInfoPublicPOITooltip(
    flag?: VenueFlags,
    name?: string,
    hasEnclosingComplexes?: boolean,
) {
    switch (flag) {
        case 'flagged_indoor_mall': {
            return hasEnclosingComplexes
                ? 'This is an enclosed property and only supports reports on the enclosing property.'
                : 'This is an enclosed property and only supports a Nearby Activity report.';
        }
        case 'flagged_multistory_building': {
            return hasEnclosingComplexes
                ? 'This property is located inside a multistory building and only supports reports on the enclosing property.'
                : 'This property is located inside a multistory building and only supports a Nearby Activity report.';
        }
        case 'ft_abnormality': {
            return name
                ? `${name} only supports a Nearby Activity report.`
                : 'This property presents visits abnormalities and only supports a limited report.';
        }
        case 'flagged_dense_area': {
            return name
                ? `${name} only supports a Nearby Activity report.`
                : 'This property is located in a dense area and only supports a limited report.';
        }
        case 'privacy_concerns': {
            return 'Reports for sensitive venues are not supported. Contact support for access.';
        }
        case 'unknown_flag': {
            return name
                ? `${name} only supports a Nearby Activity report.`
                : 'This property presents visits abnormalities and only supports a limited report.';
        }
        case 'nearby_activity': {
            return 'This property hasn’t been verified by Placer, and only supports a Nearby Activity report.';
        }
    }
    return '';
}

export const getVenueStatus = ({
    flag,
    closingDate,
    fullClosingDateAlert,
    name,
    hasEnclosingComplexes,
}: {
    flag?: VenueFlags;
    closingDate?: string | null;
    fullClosingDateAlert?: boolean;
    name?: string;
    hasEnclosingComplexes?: boolean;
}) => {
    if (flag) return getVenueFlagInfoPublicPOITooltip(flag, name, hasEnclosingComplexes);

    const closingDateFormatted = getSimpleFormatDate(
        closingDate!,
        dateFormats.shortMonthYearWithNumericOrdinalContractionDayComma,
    );
    return fullClosingDateAlert
        ? `This property is marked as closed. Data available until ${closingDateFormatted}`
        : `Closed. Data available until ${closingDateFormatted}`;
};

export const VenueStatusIndicatorV2 = ({
    flag,
    closingDate,
    custom,
    textClassName,
    shortFlag,
    name,
    hasEnclosingComplexes,
    isRestricted,
}: FlaggedVenueIndicatorProps) => {
    if (!flag && !closingDate && !custom && !isRestricted) {
        return null;
    }

    const isFlagged = !!flag;
    const isClosed = !!closingDate;
    const isPrivacyFlag = flag === 'privacy_concerns';

    const icon = (
        <AlertColoredIcon
            data-testid="venue-status-indicator"
            className={classNames({
                [styles.isClosedIcon]: isClosed,
                [styles.isPrivacyFlagIcon]: isPrivacyFlag,
                [styles.isFlaggedIcon]: isFlagged,
                [styles.isRestrictedIcon]: isRestricted,
            })}
        />
    );

    let tooltipText = getVenueStatus({
        name,
        flag,
        closingDate,
        fullClosingDateAlert: true,
        hasEnclosingComplexes,
    });

    if (custom) {
        if (tooltipText) {
            tooltipText = `${tooltipText}. ${custom}`;
        } else {
            tooltipText = custom;
        }
    }
    const flagDict = shortFlag ? flagsMap : venueFlagInfoPublicPOIText;

    if (isRestricted) {
        tooltipText = '';
    }

    const getIndicatorText = () => {
        if (isRestricted) {
            return (
                <span>
                    Venue is part of a restricted area. Data is not available.{' '}
                    <span
                        data-elevio-style="nothing"
                        data-elevio-inline={551}
                        className={styles.elevioIdLink}
                    >
                        Learn more
                    </span>
                </span>
            );
        } else if (isFlagged) {
            return flagDict[flag!];
        } else if (isClosed) {
            return getVenueStatus({
                flag,
                closingDate,
                hasEnclosingComplexes,
            });
        }
    };

    return (
        <Tooltip small title={tooltipText} placement="top">
            <div
                className={classNames(styles.statusIndicator, {
                    [styles.isClosed]: isClosed,
                    [styles.isPrivacyFlag]: isPrivacyFlag,
                    [styles.isFlagged]: isFlagged,
                    [styles.isRestricted]: isRestricted,
                })}
            >
                {icon}
                <span
                    className={classNames(textClassName, styles.indicatorText, {
                        [styles.bold]: (flag || closingDate) && !isRestricted,
                    })}
                >
                    {getIndicatorText()}
                </span>
            </div>
        </Tooltip>
    );
};
