import { useMemo, useContext } from 'react';
import moment, { Moment } from 'moment';
import { DateContext } from 'ui-components/date-picker/date-picker';
import type { Dictionary } from '@placer-ui/types';
import { Preset } from 'ui-components/date-picker/date-types/Preset';
import { dateFormats } from 'utils/date/date';

const LABEL_MONTH_FORMAT = 'MMMM YYYY';
const LABEL_QUARTER_FORMAT = '[Q]Q YYYY';

const getLastFullOfUnit = (date: Moment, unit: 'quarter' | 'year') => {
    const endMonthOfCurrentUnit = date.clone().endOf(unit).month();
    const currentMonth = date.month();

    if (currentMonth === endMonthOfCurrentUnit) {
        return date.clone().startOf(unit);
    }

    return date.clone().subtract(1, unit).startOf(unit);
};

export const createMonthPresets = (maxMonth: Moment = moment(), maxMonths: number = 6) => {
    const preset = {} as Dictionary<Preset>;
    const end = maxMonth.clone().startOf('month');

    for (let i = 0; i < maxMonths; ++i) {
        const label = end.format(LABEL_MONTH_FORMAT);
        preset[label] = {
            label: label,
            value: [end.clone(), end.clone().endOf('month')],
        };

        end.subtract(1, 'month');
    }

    return preset;
};

export const createQuarterPresets = (maxMonth: Moment = moment(), maxQuarters: number = 6) => {
    const preset = {} as Dictionary<Preset>;
    const end = getLastFullOfUnit(maxMonth, 'quarter');

    for (let i = 0; i < maxQuarters; ++i) {
        const label = end.format(LABEL_QUARTER_FORMAT);
        preset[label] = {
            label: label,
            value: [end.clone(), end.clone().endOf('quarter')],
        };

        end.subtract(1, 'quarter');
    }

    return preset;
};

export const createYearsPresets = (
    maxMonth: Moment = moment(),
    minDate?: Moment,
    maxYears: number = 6,
) => {
    const preset = {} as Dictionary<Preset>;
    const end = getLastFullOfUnit(maxMonth, 'year');

    for (let i = 0; i < maxYears; ++i) {
        if (minDate?.isAfter(end)) break;

        const label = end.format(dateFormats.year);
        preset[label] = {
            label: label,
            value: [end.clone(), end.clone().endOf('year')],
        };

        end.subtract(1, 'year');
    }

    return preset;
};

export const useFixedPreset = () => {
    const { maxMonth = moment(), minDate } = useContext(DateContext);

    return useMemo(() => {
        const maxYears = minDate ? maxMonth.year() - minDate.year() + 1 : undefined;

        return {
            months: createMonthPresets(maxMonth),
            quarters: createQuarterPresets(maxMonth),
            years: createYearsPresets(maxMonth, minDate, maxYears),
        };
    }, [maxMonth, minDate]);
};
