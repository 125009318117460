/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

type DrawerCloseIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type DrawerCloseIconProps = SVGProps<SVGSVGElement> & DrawerCloseIconUniqueProps;

export const DrawerCloseIcon = function DrawerCloseIcon({
    width = 20,
    height = 20,
    fill = '#E5E5E5',
    ...props
}: DrawerCloseIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect width="20" height="20" rx="10" fill={fill} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.1444 14.2674C13.4545 14.5775 13.9573 14.5775 14.2674 14.2674C14.5775 13.9573 14.5775 13.4545 14.2674 13.1444L11.1231 9.99999L14.2674 6.85564C14.5775 6.54552 14.5775 6.04271 14.2674 5.73259C13.9573 5.42247 13.4545 5.42247 13.1444 5.73259L10 8.87694L6.85566 5.73259C6.54553 5.42247 6.04273 5.42247 5.7326 5.73259C5.42248 6.04271 5.42248 6.54552 5.7326 6.85564L8.87696 9.99999L5.73259 13.1444C5.42247 13.4545 5.42247 13.9573 5.73259 14.2674C6.04271 14.5775 6.54552 14.5775 6.85564 14.2674L10 11.123L13.1444 14.2674Z"
                fill="#49565D"
            />
        </svg>
    );
};
DrawerCloseIcon.__tags = [] as string[];
