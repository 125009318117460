import { createAsyncThunk } from '@reduxjs/toolkit';

import type { Tag } from '@placer-ui/types';
import { chainsAPI } from 'API/industry-api/industry-api';
import { ChainsReportState } from 'features/industry/store/chains-store';
import { setChainsAutoCompleteSearchResultsAreLoading } from 'features/industry/store/chains-report-search-store';

const CHAINS_SEARCH_LIMIT = 6;
const REQUEST_ONLY_PURCHASED_CHAINS = true;

type GetChainsSearchAutoCompleteResultsActionProps = {
    searchTerm: string;
    ignoreLoadingIndication: boolean;
};

const createGetSearchMatchingChains = (chainsApi: typeof chainsAPI) =>
    createAsyncThunk<
        Tag[],
        GetChainsSearchAutoCompleteResultsActionProps,
        { state: ChainsReportState }
    >(
        'industry/getSearchMatchingChains',
        async (
            { searchTerm, ignoreLoadingIndication }: GetChainsSearchAutoCompleteResultsActionProps,
            { dispatch },
        ) => {
            if (!searchTerm) {
                return [];
            }

            if (!ignoreLoadingIndication) {
                dispatch(setChainsAutoCompleteSearchResultsAreLoading(true));
            }

            const params = {
                term: searchTerm,
                limit: CHAINS_SEARCH_LIMIT,
                purchasedOnly: REQUEST_ONLY_PURCHASED_CHAINS,
            };

            const { data: matchingChains } = await chainsApi.getChainsByTerm(params);

            const mappedMatchingChains = matchingChains.map((chain) => chain.info);

            return mappedMatchingChains;
        },
    );

export const getSearchMatchingChains = createGetSearchMatchingChains(chainsAPI);
