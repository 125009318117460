import moment, { Moment } from 'moment';
import { useMemo, useContext } from 'react';
import { DateContext } from 'ui-components/date-picker/date-picker';

export const MONTH_FORMAT = 'MM';
export const YEAR_FORMAT = 'YYYY';
const LABEL_YEAR_FORMAT = 'YYYY';
const LABEL_MONTH_FORMAT = 'MMMM';

interface RangeOption {
    value: string;
    label: string;
    disabled?: boolean;
}

const monthRange = (current: Moment, min?: Moment, max?: Moment) => {
    const range: RangeOption[] = [];
    const start = current.clone().startOf('year');

    for (let i = 0; i < 12; ++i) {
        range.push({
            disabled: min?.isAfter(start) || max?.isBefore(start),
            value: start.format(MONTH_FORMAT),
            label: start.format(LABEL_MONTH_FORMAT),
        });

        start.add(1, 'month');
    }

    return range;
};

const yearRange = (min?: Moment, max?: Moment) => {
    const range: RangeOption[] = [];
    const start = moment(min).startOf('year');

    while (start.isSameOrBefore(max)) {
        range.push({
            value: start.format(YEAR_FORMAT),
            label: start.format(LABEL_YEAR_FORMAT),
        });

        start.add(1, 'year');
    }

    return range;
};

export const useMonthsRange = (current: Moment) => {
    const { maxDate, minDate } = useContext(DateContext);

    return useMemo(() => {
        return monthRange(current, minDate, maxDate);
    }, [current, minDate, maxDate]);
};

export const useYearsRange = () => {
    const { maxDate, minDate } = useContext(DateContext);

    return useMemo(() => {
        return yearRange(minDate, maxDate);
    }, [minDate, maxDate]);
};
