/* eslint-disable max-len */
import React, { useState } from 'react';
import classNames from 'classnames';
import styles from './categories-filters.module.scss';

import { Button } from 'ui-components/button/button';
import { ChevronIcon } from 'components/assets/Icons/Icons';
import {
    useSearchApiFilters,
    useUpdateSearchApiFilters,
} from 'features/poi-selection-map/poi-selection-menu/menu-items/search-menu-item/hooks/search-poi-hooks';
import { useMultipleCategoriesOptions } from 'features/poi-selection-map/poi-selection-menu/menu-items/search-menu-item/hooks/search-poi-filter-hooks';
import { getMultiSelectLabel } from 'core/services/category-service/category-service';
import { DropdownMultiSelect } from 'ui-components/dropdown-multi-select/dropdown-multi-select';
import { BILLBOARD } from 'core/constants/report-type';
import { MultiSelectOption } from 'ui-components/multi-select/types';
import { getSelectedSubOptionsKeys } from 'ui-components/dropdown-multi-select/utils/utils';
import { selectAndClearConfiguration } from 'ui-components/multi-select/checkbox-list-v3/select-and-clear-all/select-and-clear-all';
import { useGetOnEvent } from 'features/poi-selection-map/hooks/poi-selection-hooks';

export const CategoriesFilters = () => {
    const searchApiFilters = useSearchApiFilters();
    const { categories, type } = searchApiFilters;
    const updateSearchApiFilters = useUpdateSearchApiFilters();
    const multipleCategoriesOptions = useMultipleCategoriesOptions(categories);
    const label = getMultiSelectLabel(multipleCategoriesOptions);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const isBillboardType = type === BILLBOARD;
    const onEvent = useGetOnEvent();

    const onMultipleCategoriesChange = (options: MultiSelectOption[]) => {
        const selectedCategories = getSelectedSubOptionsKeys(options);
        updateSearchApiFilters({
            categories: selectedCategories,
        });
        onEvent &&
            onEvent({
                component: 'dropdown-multi-select',
                action: 'category selection',
                value: selectedCategories.join(','),
            });
    };

    const onVisibilityChange = (visible: boolean) => {
        setIsVisible(visible);
    };

    return (
        <div className={styles.container}>
            <DropdownMultiSelect
                title={'Select a Category'}
                allText={'All Categories'}
                searchPlaceholder={'Search'}
                options={multipleCategoriesOptions}
                onApply={onMultipleCategoriesChange}
                disabled={isBillboardType}
                align={{
                    offset: [-1, 9],
                }}
                onVisibilityChange={onVisibilityChange}
                optionsWrapperClassName={styles.optionsWrapper}
                className={styles.dropdown}
                footerClassname={styles.footerClassname}
                selectedOptionClassName={styles.selectedOption}
                selectAllConfiguration={selectAndClearConfiguration}
            >
                <Button
                    className={classNames(styles.dropdownButton, {
                        [styles.isSelected]: isVisible,
                    })}
                >
                    {label} <ChevronIcon rotate={-90} />
                </Button>
            </DropdownMultiSelect>
        </div>
    );
};
