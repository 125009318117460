import React from 'react';
import { Key } from 'rc-tree/lib/interface';
import { FilterConfig, OnFilterChange } from 'features/insights/types/filter-types';
import { CheckboxTree } from 'features/mobile-report/shared/components/checkbox-tree/checkbox-tree';
import type { FilterType } from '@placer-ui/types';

import styles from './mobile-filters.module.scss';

export const timeOfDayValueOptions = [
    {
        value: '05:00-12:00',
        name: 'Morning (5am-12pm)',
        selected: false,
    },
    {
        value: '12:00-17:00',
        name: 'Afternoon (12pm-5pm)',
        selected: false,
    },
    {
        value: '17:00-21:00',
        name: 'Evening (5pm-9pm)',
        selected: false,
    },
    {
        value: '21:00-23:59',
        name: 'Night (9pm-12am)',
        selected: false,
    },
    {
        value: '00:00-05:00',
        name: 'Late Night (12am-5am)',
        selected: false,
    },
];

const FILTER_TYPE: FilterType = 'time';

export const TimeOfDay = ({
    activeFilter,
    defaultFilter,
    onChange,
}: {
    activeFilter?: FilterConfig;
    defaultFilter: FilterConfig;
    onChange: OnFilterChange;
}) => {
    const selected = (activeFilter?.value || defaultFilter?.value || []) as string[];

    const handleOptionsChange = (keys: Key[], removeItem: boolean) => {
        if (removeItem) {
            onChange(FILTER_TYPE, {
                operator: 'between',
                value: selected.filter((key) => !keys.includes(key)),
            });
        } else {
            onChange(FILTER_TYPE, {
                operator: 'between',
                value: [...selected, ...(keys as string[])],
            });
        }
    };

    return (
        <div className={styles.checkboxFilter} data-testid="filters-time-of-day-option">
            <CheckboxTree
                options={timeOfDayValueOptions}
                treeCheckedKeys={selected}
                onOptionsChange={handleOptionsChange}
                isSearchHidden
                isSelectAndClearAllHidden
                shouldSetHeight={false}
            />
        </div>
    );
};
