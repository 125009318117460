import { ResponseStatus } from 'API/common/types';
import type { Dictionary } from '@placer-ui/types';
import { Primitive } from 'types/primitive';

export const WIDGET_ACTIONS = {
    SET_DUPLICATE_WIDGET_DATA: 'widgets/set-duplicate-widget-data',
    SET_WIDGET_FIELD: 'widgets/set-widget-field',
    SET_WIDGET_FIELDS: 'widgets/set-widget-fields',
    SET_WIDGET_POIS: 'widgets/set-widget-pois',
    SET_WIDGET_API_CONTENT: 'widgets/set-widget-api-content',
    SET_WIDGET_API_INFO: 'widgets/set-widget-api-info',
    SET_WIDGET_METADATA: 'widgets/set-widget-metadata',
    CLEAN_WIDGET_CONTENT: 'widgets/clean-widget-content',
    MARK_WIDGET_CONTENT_AS_DIRTY: 'widgets/mark-widget-content-as-dirty',
    CLEAN_ALL_WIDGETS_FILTERS: 'widgets/clean-all-widgets-filter',
    CLEAN_ALL_WIDGETS_BY_POI: 'widgets/clean-all-widgets-by-poi',
    CLEAN_ALL_WIDGETS: 'widgets/clean-all-widgets',
    CLEAN_WIDGETS_BY_IDS: 'widgets/clean-widgets-by-id',
    SET_ALL_WIDGETS_STATUS: 'widgets/set-all-status',
    CLEAN_WIDGET_SHOW_VENUE_LEGEND: 'widgets/clean-widget-show-venue-legend',
    SET_WIDGETS_BATCH_DATA: 'widgets/set-widgets-batch-data',
} as const;

// TODO rename it to something more logic. Widget fields for example
export const WIDGET_TYPES = {
    visualFilter: 'visualFilter',
    fetchFilter: 'fetchFilter',
    venueSelector: 'venueSelector',
    content: 'content',
    info: 'info',
    metadata: 'metadata',
    status: 'status',
    showVenueLegend: 'showVenueLegend',
    showDataDescription: 'showDataDescription',
    waitForEvent: 'waitForEvent',
    widgetHasMap: 'widgetHasMap',
    singleViewPort: 'singleViewPort',
} as const;

export type WidgetTypes = keyof typeof WIDGET_TYPES;
export type WidgetTypesPerID = Exclude<WidgetTypes, typeof WIDGET_TYPES.status>;
export type WidgetTypesWithoutID = Exclude<WidgetTypes, WidgetTypesPerID>;

export type WidgetStatus = 'PENDING' | 'START';
export type FilterType = 'fetchFilter' | 'visualFilter';

export interface ErrorContent extends ResponseStatus {
    isError: boolean;
}

export type DataApiResponse<ContentType> = ContentType | ErrorContent | null;
export type WidgetApiContent<ApiContentType = any> = Dictionary<DataApiResponse<ApiContentType>>;
export type WidgetApisContentMap<WidgetApiId extends string = string> = {
    [apiId in WidgetApiId]: WidgetApiContent;
};
export type WidgetApisInfoPayload = {
    cached?: boolean;
};
export type WidgetApisInfo = Dictionary<WidgetApisInfoPayload>;
export type WidgetApisInfoMap<WidgetApiId extends string = string> = {
    [apiId in WidgetApiId]: WidgetApisInfo;
};

export type Widget<ContentType extends WidgetApisContentMap = any, MetaData = any> = {
    [WIDGET_TYPES.content]?: ContentType;
    [WIDGET_TYPES.info]?: WidgetApisInfoMap;
    [WIDGET_TYPES.metadata]?: DataApiResponse<MetaData>;
    [WIDGET_TYPES.visualFilter]?: Dictionary<Primitive>;
    [WIDGET_TYPES.fetchFilter]?: Dictionary<Primitive>;
    [WIDGET_TYPES.venueSelector]?: Dictionary<boolean>;
    [WIDGET_TYPES.status]?: WidgetStatus;
    [WIDGET_TYPES.showVenueLegend]?: boolean;
    [WIDGET_TYPES.showDataDescription]?: boolean;
    [WIDGET_TYPES.waitForEvent]?: boolean;
    [WIDGET_TYPES.widgetHasMap]?: boolean;
    [WIDGET_TYPES.singleViewPort]?: boolean;
};
