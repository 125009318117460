export function isPathnameStartsWithPath(
    pathname: string,
    path: string | readonly string[] | undefined,
): boolean {
    if (Array.isArray(path)) {
        return path.some((p) => pathname.startsWith(p));
    } else if (typeof path === 'string') {
        return pathname.startsWith(path);
    }
    return false;
}

export function testPathnameWithPath(
    pathname: string,
    path: string | readonly string[] | undefined,
): boolean {
    if (Array.isArray(path)) {
        const combinedRegex = path.map((p) => `^${p.replace(/:\w+/g, '\\w+')}`).join('|');
        const pathRegex = new RegExp(combinedRegex);
        return pathRegex.test(pathname);
    } else if (typeof path === 'string') {
        const pathRegex = new RegExp(`^${path.replace(/:\w+/g, '\\w+')}`);
        return pathRegex.test(pathname);
    }
    return false;
}
