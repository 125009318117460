import { createSlice } from '@reduxjs/toolkit';
import type { Dictionary } from '@placer-ui/types';
import { PluginEntity, PluginSnippetEntity } from 'features/marketplace/common/api/entities';
import { reducers } from 'features/marketplace/store/reducers';

export interface MarketplaceState {
    snippets: Dictionary<PluginSnippetEntity>;
    plugins: Dictionary<PluginEntity>;
    requestedPluginIds: string[];
    error: boolean;
}

export const initialMarketplaceState: MarketplaceState = {
    snippets: {},
    plugins: {},
    requestedPluginIds: [],
    error: false,
};

const marketplaceSlice = createSlice({
    name: 'marketplace',
    initialState: initialMarketplaceState,
    reducers,
});

export const marketplaceReducer = {
    marketplace: marketplaceSlice.reducer,
};

export const marketplaceActions = marketplaceSlice.actions;
