import { createSelector } from '@reduxjs/toolkit';
import {
    selectChainDefaultReport,
    userCustomSettingsSelector,
} from 'store/selectors/app-selectors';
import { getQueryParamValueFromUrlHash } from 'utils/url-query-params/url-query-params';
import { allFeatureFlagsSelector } from 'store/selectors/feature-flags';
import { Primitive } from 'types/primitive';

export interface ChainUrlData {
    id: string;
    date_start?: string;
    date_end?: string;
    notInReport?: boolean;
}

export const buildChainURL = (
    urlPath: string,
    searchState?: Array<ChainUrlData>,
    featureFlags?: Record<string, Primitive>,
) => {
    const mode = getQueryParamValueFromUrlHash(urlPath, 'mode');
    // TODO: mode needs to be defaulted initially by guru configuration / user settings

    const hasModeFF = featureFlags?.enable_new_compare_bar_chains_ff
        ? `mode=${mode ?? 'property'}&`
        : '';

    const basePath = '/overview?' + hasModeFF;

    if (searchState) {
        return encodeURI(`${basePath}state=${JSON.stringify(searchState)}`);
    }
    return '/overview';
};

const selectUserChainState = createSelector(
    userCustomSettingsSelector,
    allFeatureFlagsSelector,
    (_: any, urlPath: string) => urlPath,
    (custom_settings, allFeatureFlags, urlPath) => {
        const chainRecentReports = custom_settings?.chainReport;
        if (!chainRecentReports) {
            return null;
        }
        if (Array.isArray(chainRecentReports)) {
            return chainRecentReports[0].data.map(
                ({ id, date_start, date_end, date_label, notInReport }) => ({
                    id,
                    date_start,
                    date_end,
                    date_label,
                    notInReport,
                }),
            );
        }

        if (typeof chainRecentReports === 'string') {
            return null;
        }

        // TODO: mode needs to be defaulted initially by guru configuration / user settings
        const mode = getQueryParamValueFromUrlHash(urlPath, 'mode');

        const searchParams = decodeURI(chainRecentReports.search);

        const hasModeFF = allFeatureFlags?.enable_new_compare_bar_chains_ff
            ? `mode=${mode ?? 'property'}&`
            : '';

        const state = searchParams.substr(`?${hasModeFF}state=`.length);

        return state ? JSON.parse(state) : null;
    },
);

export const selectChainsDefaultReportURL = createSelector(
    selectUserChainState,
    selectChainDefaultReport,
    allFeatureFlagsSelector,
    (_: any, urlPath: string) => urlPath,
    (userChain, freemiumChains, allFeatureFlags, urlPath) => {
        return userChain
            ? buildChainURL(urlPath, userChain, allFeatureFlags)
            : buildChainURL(urlPath, freemiumChains, allFeatureFlags);
    },
);
