 /* eslint-disable max-len */
import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';
import { IconProps } from 'components/assets/Icons/Icons';

export const VenueDisabledIconIconSvg = (props: CustomIconComponentProps | SVGProps <SVGSVGElement>) => (<svg {...props} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="1.5" y="0.424988" width="15" height="17.2" stroke="#D0D0D0" strokeWidth="0.75"/>
<mask id="path-2-inside-1_961_35684" fill="white">
<path d="M4.5 10.625C4.5 10.3489 4.72386 10.125 5 10.125H8.5C8.77614 10.125 9 10.3489 9 10.625V18H4.5V10.625Z"/>
</mask>
<path d="M4.5 10.625C4.5 10.3489 4.72386 10.125 5 10.125H8.5C8.77614 10.125 9 10.3489 9 10.625V18H4.5V10.625Z" stroke="#D0D0D0" strokeWidth="1.5" mask="url(#path-2-inside-1_961_35684)"/>
<path d="M14.0625 12.9375H10.6875" stroke="#D0D0D0" strokeWidth="0.75" strokeLinecap="round"/>
<path fillRule="evenodd" clipRule="evenodd" d="M3.375 7.45312C1.74713 7.45312 0.421875 6.12787 0.421875 4.5V0.421875H6.32812V4.5C6.32812 6.12787 5.00287 7.45312 3.375 7.45312Z" fill="white"/>
<path d="M3.375 7.45312C1.74713 7.45312 0.421875 6.12787 0.421875 4.5V0.421875H6.32812V4.5C6.32812 6.12787 5.00287 7.45312 3.375 7.45312" stroke="#D0D0D0" strokeWidth="0.75"/>
<path fillRule="evenodd" clipRule="evenodd" d="M9 7.45312C7.37213 7.45312 6.04688 6.12787 6.04688 4.5V0.421875H11.9531V4.5C11.9531 6.12787 10.6279 7.45312 9 7.45312Z" fill="white"/>
<path d="M9 7.45312C7.37213 7.45312 6.04688 6.12787 6.04688 4.5V0.421875H11.9531V4.5C11.9531 6.12787 10.6279 7.45312 9 7.45312" stroke="#D0D0D0" strokeWidth="0.75"/>
<path fillRule="evenodd" clipRule="evenodd" d="M14.625 7.45312C12.9971 7.45312 11.6719 6.12787 11.6719 4.5V0.421875H17.5781V4.5C17.5781 6.12787 16.2529 7.45312 14.625 7.45312Z" fill="white"/>
<path d="M14.625 7.45312C12.9971 7.45312 11.6719 6.12787 11.6719 4.5V0.421875H17.5781V4.5C17.5781 6.12787 16.2529 7.45312 14.625 7.45312" stroke="#D0D0D0" strokeWidth="0.75"/>
<path d="M14.0625 10.6875H10.6875" stroke="#D0D0D0" strokeWidth="0.75" strokeLinecap="round"/>
</svg>
);

export const VenueDisabledIconIcon = (props: IconProps) => (<Icon component={VenueDisabledIconIconSvg} {...props} />);