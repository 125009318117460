import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';

type FullscreenContextProps = {
    isFullscreen: boolean;
    toggleFullscreen: () => void;
};

/**
 * These types are required for representing the compatibility
 * prefixes for Safari (for tablet use-cases)
 */
type FullScreenBrowserCompatible<HTMLElementType> = HTMLElementType & {
    webkitRequestFullScreen?: () => void;
    webkitExitFullscreen?: () => void;
};

type FullScreenBrowserCompatibleDocumentType = FullScreenBrowserCompatible<Document> & {
    webkitFullscreenElement: HTMLElement | null;
};

type FullscreenProviderProps = {
    children: React.ReactNode;
};

const FullscreenContext = React.createContext<FullscreenContextProps>({
    isFullscreen: false,
    toggleFullscreen: () => {},
});

const getFullScreenElement = (document: FullScreenBrowserCompatibleDocumentType) =>
    document.fullscreenElement || document.webkitFullscreenElement;

const requestFullScreen = (element: FullScreenBrowserCompatible<HTMLDivElement>) => {
    element.requestFullscreen?.() || element.webkitRequestFullScreen?.();
};

const exitFullScreen = () => {
    const docElement = document as FullScreenBrowserCompatibleDocumentType;
    const fullScreenElement = getFullScreenElement(docElement);

    if (!fullScreenElement) return;

    if (docElement.exitFullscreen) {
        docElement.exitFullscreen();
    } else if (docElement.webkitExitFullscreen) {
        docElement.webkitExitFullscreen();
    }
};

export const useFullscreenContext = () => useContext(FullscreenContext);

export const FullscreenContextProvider = ({ children }: FullscreenProviderProps) => {
    const [isFullscreen, setIsFullscreen] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const docElement = document as FullScreenBrowserCompatibleDocumentType;

    useEffect(() => {
        if (!ref.current) return;
        const fullScreenElement = getFullScreenElement(docElement);
        if (isFullscreen) {
            requestFullScreen(ref.current);
        } else if (fullScreenElement) {
            exitFullScreen();
        }
    }, [docElement, isFullscreen]);

    useLayoutEffect(() => {
        const setFullscreen = () => {
            const fullScreenElement = getFullScreenElement(docElement);
            setIsFullscreen(!!fullScreenElement);
        };

        docElement.addEventListener('fullscreenchange', setFullscreen);
        return () => {
            docElement.removeEventListener('fullscreenchange', setFullscreen);
        };
    }, [docElement]);

    const toggleFullscreen = () => {
        setIsFullscreen(!isFullscreen);
    };

    return (
        <FullscreenContext.Provider
            value={{
                isFullscreen,
                toggleFullscreen,
            }}
        >
            <div ref={ref} style={{ height: '100%' }}>
                {children}
            </div>
        </FullscreenContext.Provider>
    );
};
