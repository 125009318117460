import React, { useCallback, useEffect, useState } from 'react';
import styles from './explore-poi-actions-button.module.scss';

import { ExploreSearchResult } from 'features/explore/types/types';
import { getReportEntityFlag } from 'core/services/report-entities-service/report-entities-service';
import { Button } from 'ui-components/button/button';
import {
    useSetDrawerResult,
    useSetOverlayPOI,
} from 'features/explore/context/use-explore-ui-state-actions';
import { useOpenExploreReportLink } from 'features/explore/hooks/use-explore-report-link';
import {
    useIsLockedPoi,
    useOnLockedButtonClick,
} from 'hooks/use-locked-poi-hooks/use-locked-poi-hooks';
import { GrayLockIcon } from 'components/assets/Icons/GrayLockIcon';
import {
    DropdownMenuButton,
    DropdownOption,
} from 'ui-components/dropdown-menu-button/dropdown-menu-button';
import {
    BillboardIcon,
    ChevronIcon,
    CreatePoiIcon,
    DotsMoreIcon,
    VenueIcon,
} from 'components/assets/Icons/Icons';
import { GisIcon } from 'components/assets/Icons/GisIcon';
import { BillboardPOI } from 'core/entities';
import { useGetEnclosingComplexOptions } from 'features/explore/hooks/use-explore-enclosing-complex';
import { useExplorePermissions } from 'features/explore/hooks/use-explore-permissions';
import { useGetOverlayPOI } from 'features/explore/hooks/use-explore-overlay-poi';
import { VenueDisabledIconIcon } from 'components/assets/Icons/VenueDisabledIconIcon';
import { createPlaceWithNearbyResult } from 'utils/create-place-with-nearby-result/create-place-with-nearby-result';
import { useNearbyActivityRadiusDefault } from 'hooks/use-nearby-activity-radius/use-nearby-activity-radius';
import { useSendExploreSplunkEvent } from 'features/explore/hooks/use-explore-tracking';
import { useIsFreemiumUser } from 'hooks/use-freemium-user/use-freemium-user';
import { getSelectedPoiOverlay } from 'features/explore/utils/utils';
import { getDeviceType } from 'utils/get-user-agent-data/utils';
import { getPlacePoiProvider } from 'utils/poi-provider/poi-provider';
import { useCommonMapTypeId } from 'ui-components/google-map/hooks/get-map-id';
import { useCheckPoiForPropertyReport } from 'features/explore/hooks/use-check-for-property-report';
import { useExploreUIState } from 'features/explore/context/use-explore-ui-state';
import { EMPTY_SPACE_POI_TYPE } from 'features/empty-space/constants/empty-space-constants';
import { goToPoiEditor } from 'router/go-to-routes/go-to-poi-editor';
import { getFeatureFlags } from 'core/flow-control';
import { ADDRESS_ENTITY, COORDINATES_ENTITY } from 'features/explore/constants';
import { useCheckCoordinateForNearbyReport } from 'features/explore/hooks/use-explore-nearby-activity';
import { useExploreVoidOpenReport } from 'features/explore/hooks/use-explore-void-open-report';
import { useFullscreenContext } from 'utils/fullscreen-provider/fullscreen-provider';
import classNames from 'classnames';
import { isAnEnclosingComplexFlag } from 'utils/enclosing-complexes-filter/enclosing-complexes-filter';
import { isLoadingExploreEnclosingComplexesSignal } from 'features/explore/store/explore-signals/explore-encloasing-complexes-signal';

type ExplorePoiActionsButtonProps = {
    poi: ExploreSearchResult;
    splunkComponent?: string;
};

const LockedButton = () => {
    const { hasFreemiumExpFF } = useExplorePermissions();
    const onLockedButtonClick = useOnLockedButtonClick();

    const text = hasFreemiumExpFF ? 'Report Options' : 'Upgrade Your Plan';

    return (
        <Button
            className={styles.lockedButton}
            onClick={onLockedButtonClick}
            data-testid="report-options-button-locked"
        >
            <div className={styles.lockedButtonInfo}>
                <GrayLockIcon className={styles.lockedButtonIcon} />
                <span className={styles.lockedButtonText}>{text}</span>
            </div>
        </Button>
    );
};

export const ExplorePoiActionsButton = ({ poi, splunkComponent }: ExplorePoiActionsButtonProps) => {
    const setDrawerItem = useSetDrawerResult();
    const { isLoading, openReport } = useOpenExploreReportLink();
    const [hasNewOverlay, setHasNewOverlay] = useState(false);
    const isLockedPoi = useIsLockedPoi();
    const checkPoiForPropertyReport = useCheckPoiForPropertyReport();
    const enclosingComplexes = useGetEnclosingComplexOptions()(poi.info.id);
    const { hasUnlockNearbyActivity, hasFreemiumExpFF } = useExplorePermissions();
    const defaultNearbyActivityRadius = useNearbyActivityRadiusDefault();
    const isLocked = isLockedPoi(poi.info);
    const { activeId, customPoiAddresses } = useExploreUIState();
    const { enable_explore_drop_pin_ff } = getFeatureFlags();
    const { isFullscreen } = useFullscreenContext();

    const voidAnalysisButton = useExploreVoidOpenReport();
    const sendSplunkEvent = useSendExploreSplunkEvent();
    const setOverlay = useSetOverlayPOI();
    const isFreemiumUser = useIsFreemiumUser();
    const flag = getReportEntityFlag(poi.info);
    const overlay = useGetOverlayPOI()(poi.info);
    const checkCoordinateForNearbyReport = useCheckCoordinateForNearbyReport();
    const isFreemiumAvailablePoi = isFreemiumUser && !flag;
    const freemiumWithAvailablePoiAndFreeExp = isFreemiumAvailablePoi && hasFreemiumExpFF;
    const isLoadingEnclosingComplexes = isLoadingExploreEnclosingComplexesSignal.value;

    const mapTypeId = useCommonMapTypeId(true)();

    const onOpenReport = useCallback(
        (dropdownOption: string, overlayId: string) => {
            if (splunkComponent) {
                sendSplunkEvent({
                    component: splunkComponent,
                    action: 'open report',
                    value: dropdownOption,
                    selectedSource: getPlacePoiProvider(poi.info),
                    selectedCategory:
                        poi.info.type === EMPTY_SPACE_POI_TYPE ||
                        poi.info.provider_data?.entity_id === COORDINATES_ENTITY
                            ? 'Address-coordinate'
                            : poi.info.category,
                    mapProvider: 'mapbox',
                    mapViewType: mapTypeId,
                });
            }
            if (overlayId !== 'customPoi') {
                const selectedOverlay = getSelectedPoiOverlay(overlay, overlayId) ?? poi.info;
                openReport(selectedOverlay);
            }
        },
        [openReport, overlay, poi.info, sendSplunkEvent, splunkComponent, mapTypeId],
    );

    const onOpenReportNearby = useCallback(() => {
        setOverlay({
            sourcePoi: poi.info.id,
            overlayPoi: {
                ...createPlaceWithNearbyResult(poi.info, defaultNearbyActivityRadius),
                overlayID: 'nearby',
            },
        });
        setHasNewOverlay(true); // will trigger the open-report in the next cycle
    }, [defaultNearbyActivityRadius, poi.info, setOverlay]);

    // an effect to open the nearby report
    useEffect(() => {
        if (hasNewOverlay) {
            onOpenReport('Nearby Activity', 'nearby');
            setHasNewOverlay(false);
        }
    }, [hasNewOverlay, onOpenReport]);

    if (isLocked) {
        return <LockedButton />;
    }

    const isTrafficPin = BillboardPOI.isBillboard(poi.info);
    const { text: propertyTooltip, disabled: propertyDisabled } = checkPoiForPropertyReport(
        poi.info,
    );

    const {
        text: nearbyTooltip,
        disabled: nearbyDisabled,
        isLoading: isNearbyLoading,
    } = checkCoordinateForNearbyReport(poi.info);

    const isLoadingEnclosingPOI = isAnEnclosingComplexFlag(poi.info) && isLoadingEnclosingComplexes;

    const lockedOptions =
        !hasUnlockNearbyActivity || freemiumWithAvailablePoiAndFreeExp
            ? {
                  isLocked: true,
                  tooltip: 'Locked. Premium account is needed',
              }
            : {};

    const pinTitle = isTrafficPin ? 'Traffic Pin' : 'Property';

    const dropdownOptions: DropdownOption[] = [
        {
            key: 'poi',
            icon: isTrafficPin ? (
                <BillboardIcon />
            ) : propertyDisabled ? (
                <VenueDisabledIconIcon />
            ) : (
                <VenueIcon overrideInheritedStyle={false} />
            ),
            label: pinTitle,
            visible: !(
                ((poi.info.type === EMPTY_SPACE_POI_TYPE ||
                    poi.info.provider_data?.entity_id === COORDINATES_ENTITY) &&
                    enable_explore_drop_pin_ff) ||
                poi.info.provider_data?.entity_id === ADDRESS_ENTITY ||
                customPoiAddresses[poi.info.provider_data?.entity_id || poi.info.id]
            ),
            tooltip: propertyTooltip,
            onClick: () => onOpenReport(pinTitle, 'poi'),
            disabled: propertyDisabled,
            useTooltipParentContainer: isFullscreen,
        },
        {
            key: 'enclosing',
            icon: <VenueIcon overrideInheritedStyle={false} />,
            label: 'Enclosing Property',
            visible: (enclosingComplexes && enclosingComplexes.length > 0) || isLoadingEnclosingPOI,
            disabled: isLoadingEnclosingPOI,
            tooltip: isLoadingEnclosingPOI ? 'Loading enclosing properties' : '',
            onClick: () => onOpenReport('Enclosing Property', 'complex'),
            ...lockedOptions,
        },
        {
            key: 'nearbyActivity',
            icon: <GisIcon />,
            label: 'Nearby Activity',
            visible: !isTrafficPin,
            tooltip: nearbyTooltip,
            disabled: nearbyDisabled,
            onClick: () => onOpenReportNearby(),
            useTooltipParentContainer: isFullscreen,
            ...lockedOptions,
        },
        voidAnalysisButton(poi.info, splunkComponent),
        {
            key: 'moreOptions',
            icon: <DotsMoreIcon className={styles.dotsIconContainer} />,
            label: 'More Options',
            visible: !isTrafficPin,
            onClick: () => setDrawerItem(poi),
            ...lockedOptions,
        },
    ];

    if (isLoading && poi.info.id === activeId && poi.info.provider_data?.provider) {
        return (
            <Button type="brand-primary-full" block disabled>
                Loading...
            </Button>
        );
    }

    if (isFreemiumAvailablePoi && !hasFreemiumExpFF) {
        return (
            <Button
                data-testid={'open-report-button'}
                type="brand-primary-full"
                block
                onClick={() => onOpenReport(pinTitle, 'poi')}
            >
                Open Report
            </Button>
        );
    }

    const shouldHaveCreatePoiOption =
        poi.info.type === EMPTY_SPACE_POI_TYPE ||
        poi.info.provider_data?.entity_id === ADDRESS_ENTITY ||
        customPoiAddresses[poi.info.provider_data?.entity_id || poi.info.id] ||
        (enable_explore_drop_pin_ff && poi.info.provider_data?.entity_id === COORDINATES_ENTITY);

    if (shouldHaveCreatePoiOption) {
        dropdownOptions.splice(dropdownOptions.length - 1, 0, {
            key: 'customPoi',
            icon: <CreatePoiIcon className={styles.createPoiIcon} />,
            label: 'Create POI',
            visible: true,
            tooltip:
                'Draw a specific polygon around this location \n' +
                'to save it for analysis at any time',
            useTooltipParentContainer: isFullscreen,
            onClick: () => {
                onOpenReport('Create POI', 'customPoi');

                goToPoiEditor({
                    state: {
                        zoom: 18,
                        center: poi?.info.geolocation!,
                        query: '',
                    },
                    target: '_blank',
                });
            },
        });
    }

    return (
        <DropdownMenuButton
            trigger={getDeviceType() === 'desktop' ? ['hover'] : ['hover', 'click']}
            options={dropdownOptions}
            lockedClass={styles.lockedItem}
            tooltipPlacement={'right'}
            getPopupContainer={(triggerNode) => triggerNode.parentElement!}
            menuContainerClass={classNames(styles.dropdownMenuContainer, {
                [styles.fullScreen]: isFullscreen,
            })}
        >
            <Button
                className={styles.overrideLoading}
                loading={isNearbyLoading || isLoadingEnclosingPOI}
                data-testid={'open-report-button'}
                type="brand-primary-full"
                block
            >
                Open Report <ChevronIcon rotate={-90} />
            </Button>
        </DropdownMenuButton>
    );
};
