/* eslint-disable max-len */
import React from 'react';

export function WeatherPartlyCloudyIcon() {
    return (
        <svg width="70" height="54" viewBox="0 0 70 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M30.3331 51.9229L61.221 51.9999H61.2228C64.9708 51.999 67.9999 48.9694 67.9999 45.2439C67.9999 41.5171 64.9698 38.4872 61.221 38.4872H58.5618L58.4019 36.6617C57.617 27.696 50.063 20.3428 40.9742 20.3428C34.8795 20.3428 29.5124 23.4485 26.3782 28.1653C29.5987 29.2373 32.3514 31.3353 34.25 34.0701C34.565 34.5237 34.4526 35.1468 33.9989 35.4618C33.5452 35.7768 32.9221 35.6643 32.6072 35.2107C30.8617 32.6966 28.293 30.8031 25.2938 29.9213L23.9326 29.6842C23.1375 29.5384 22.3167 29.4619 21.4761 29.4619C14.0285 29.4619 8 35.4822 8 42.8976C8 46.3716 9.32024 49.5358 11.493 51.9229H30.3331Z" fill="#D0D0D0"/>
<path d="M0.999999 24C0.999999 29.6713 3.05262 34.8631 6.45548 38.8732C6.34848 39.2685 6.25662 39.6701 6.18054 40.0772C2.33898 35.822 0 30.1842 0 24C0 10.7452 10.7452 0 24 0C35.7325 0 45.4987 8.41873 47.5873 19.5448C47.2271 19.4095 46.8619 19.2848 46.4922 19.1712C44.2736 8.78708 35.0462 1 24 1C11.2975 1 0.999999 11.2975 0.999999 24Z" fill="#FFD89E"/>
<path d="M21.4715 27.3737C15.1187 27.3737 9.6572 31.189 7.24056 36.6559C4.57881 33.1366 3 28.7527 3 24C3 12.402 12.402 3 24 3C33.7458 3 41.9409 9.63876 44.31 18.6407C43.226 18.4451 42.1115 18.3428 40.9742 18.3428C33.9361 18.3428 27.769 22.0623 24.3415 27.6386C23.4113 27.4647 22.452 27.3737 21.4715 27.3737Z" fill="#FFD89E"/>
</svg>

    );
}
WeatherPartlyCloudyIcon.__tags = [] as string[];
