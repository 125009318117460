/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

type EmptySpacePoiIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type EmptySpacePoiIconProps = SVGProps<SVGSVGElement> & EmptySpacePoiIconUniqueProps;

export const EmptySpacePoiIcon = function EmptySpacePoiIcon({
    width = 41,
    height = 41,
    ...props
}: EmptySpacePoiIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 41 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect x="1" y="1.27856" width="39" height="39" rx="3.5" fill="white" />
            <rect x="4.5" y="4.77856" width="32" height="32" rx="3" fill="#F9F8F7" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.5119 12.1536C23.8192 12.1536 26.5 14.8353 26.5 18.1416C26.5 19.6859 25.9776 20.9447 24.95 22.1509C23.9243 23.3561 20.4995 26.3597 20.4995 26.3597C20.4995 26.3597 17.0748 23.3561 16.0491 22.1509C15.0224 20.9447 14.5 19.6859 14.5 18.1416C14.5 14.8353 17.1808 12.1536 20.4881 12.1536H20.5119Z"
                fill="white"
                stroke="#49565D"
                strokeWidth="0.75"
                strokeLinecap="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23 18.1791C23 19.56 21.8804 20.6796 20.4995 20.6796C19.1196 20.6796 18 19.56 18 18.1791C18 16.7992 19.1196 15.6786 20.4995 15.6786C21.8804 15.6786 23 16.7992 23 18.1791Z"
                fill="white"
                stroke="#49565D"
                strokeWidth="0.75"
                strokeLinecap="round"
            />
            <line x1="12.5" y1="26.2786" x2="16.5" y2="26.2786" stroke="#676DFD" />
            <line x1="20.5" y1="27.7786" x2="20.5" y2="29.7786" stroke="#676DFD" />
            <line x1="24.5" y1="26.2786" x2="28.5" y2="26.2786" stroke="#676DFD" />
            <rect x="1" y="1.27856" width="39" height="39" rx="3.5" stroke="#E5E5E5" />
        </svg>
    );
};
EmptySpacePoiIcon.__tags = ['empty space poi added site'] as string[];
