/**
 * Created by ofirlemel on 21/09/2016.
 */

import _ from 'lodash';
import { getReportEntityFlag } from 'core/services/report-entities-service/report-entities-service';
import { ChainPOI } from 'core/entities';
import { GeoPolygon } from 'core/entities/venue/geoPolygon';
import { GeoMultiPolygon } from 'core/entities/venue/geoMultiPolygon';
import { APP_CONSTS } from 'core/constants/app-consts';
import { PoiByIdState } from 'features/insights/store/poi/poi-types';
import { PoiDatesRecord } from 'features/insights/types/dates-types';

export type VenueClassDependencies = { entities: PoiByIdState; dates: PoiDatesRecord | undefined };

const VENUE_TYPES = Object.values(APP_CONSTS.venues.types);
const TRIMMED_LENGTH = 10;

function title(name: any) {
    return name.split(' ').map(_.capitalize).join(' ');
}

function isAllHaveVisitsAndImpressions(entities: any) {
    return Object.values(entities || {}).every((poi) => {
        const { originalEntity } = poi as any;

        let hasVisits = false;
        let hasImpressions = false;
        const dataSource = originalEntity?.data_type_source;

        if (!dataSource) {
            return false;
        }

        for (let i = 0; i < dataSource.length; i++) {
            if (dataSource[i].type === 'impressions') {
                hasImpressions = true;
            }
            if (dataSource[i].type === 'visits') {
                hasVisits = true;
            }
        }

        return hasVisits && hasImpressions;
    });
}

export class Venue {

    originalShape: any;
    changeCounter: any;
    customData: any;
    allHaveVisitsAndImpressions: any;
    info: any;
    overriddenFields: any;
    isPurchased: any;
    type: any;
    collection: any;
    id: any;

    isCustom: any;
    access: any;
    category: any;
    subCategory: any;
    isClosed: any;
    closedAt: any;
    name: any;
    subTitle: any;
    dataTitle: any;
    address: any;
    fullAddress: any;
    shortFormattedAddress: any;
    flag: any;
    stateCode: any;
    zipcode: any;
    customers: any;
    flow: any;
    rating: any;
    locationType: any;
    parentVenue: any;
    uid: any;
    overview: any;
    childrenVenues: any;
    competitorVenues: any;
    visits: any;

    constructor(venueData: any, entities?: PoiByIdState) {
        this.originalShape = _.cloneDeep(venueData);
        this.changeCounter = 0;
        this.customData = {};
        this.generateUID();
        this.allHaveVisitsAndImpressions = isAllHaveVisitsAndImpressions(entities);
        Object.assign(this, venueData);

        for (var key in venueData) {
            if (key === 'info') {
                this.setInfo(venueData['info']);
                continue;
            }
            if (key === 'overview') {
                this.setOverview(venueData['overview']);
                continue;
            }
            if (key === 'is_purchased') {
                this.isPurchased = venueData[key];
            }
        }
    }
    static getDistanceFromLatLonInMeters(lat1: any, lon1: any, lat2: any, lon2: any) {
        const point1 = new google.maps.LatLng(lat1, lon1);
        const point2 = new google.maps.LatLng(lat2, lon2);

        const distanceBetween = google.maps.geometry.spherical.computeDistanceBetween(
            point1,
            point2,
        );

        return Math.round(distanceBetween);
    }
    static roundFloat(i: any, digits: number) {
        if (isNaN(i) || !i) {
            return 0;
        }
        return Number(i.toFixed(digits));
    }
    static typeToCollection(type: string) {
        switch (type) {
            case APP_CONSTS.venues.types.COMPLEX:
                return APP_CONSTS.venues.collection.COMPLEXES;
            case APP_CONSTS.venues.types.VENUE:
                return APP_CONSTS.venues.collection.VENUES;
            case APP_CONSTS.venues.types.BILLBOARD:
                return APP_CONSTS.venues.collection.BILLBOARDS;
            case APP_CONSTS.venues.types.CHAIN:
                return APP_CONSTS.venues.collection.CHAINS;
            default:
                return type + 's';
        }
    }

    typeToCollection(type: string) {
        return Venue.typeToCollection(type);
    }

    static hasType(type: string) {
        return VENUE_TYPES.includes(type);
    }

    setInfo(venueInfo: any) {
        this.info = {};
        for (let key in venueInfo) {
            this['info'][key] = venueInfo[key];
        }

        if (this.info.custom_tags) {
            this.info.customTags = _.reduce(
                this.info.custom_tags,
                function(tags: any, pair: any) {
                    tags[pair.key] = pair.value;
                    return tags;
                },
                {},
            );
        }

        if (this.info.name) {
            //FIX VENUE NAMES SERVER ISSUE
            var words = this.info.name.split(' ');
            if (['at&t', 'mgm'].indexOf(words[0].toLowerCase()) >= 0) {
                words[0] = words[0].toUpperCase();
            }
            this.info.name = words.join(' ');

            //TODO: SERVER BUG FIX
            if (this.info.name.toLowerCase() === 'serendipity 3') {
                this.info.name = 'Stripside Café & Bar';
            }
        }

        this.overriddenFields = this.info.overriddenFields;
        this.isPurchased = this.info.purchased;
        this.type = this.info.type;
        this.collection = this.typeToCollection(this.type);
        this.info.collection = this.collection;
        this.id = this.info.id || _.uniqueId('venue_');

        this.isCustom = this.info.is_custom_poi;
        this.access = this.info.access;
        this.category = this.info.category;
        this.subCategory = this.info.sub_category;
        this.isClosed = this.getIsClosed();
        this.closedAt = this.getClosedAt();

        const flag = getReportEntityFlag(this.info);
        this.flag = flag;

        this.info.sharedWith = this.isCustom
            ? APP_CONSTS.sharedWith.ACCOUNT
            : APP_CONSTS.sharedWith.PUBLIC;

        //THIS.NAME
        this.name = '';
        this.subTitle = '';
        this.dataTitle = '';
        this.address = this.getAddress();
        this.fullAddress = this.getFullAddress();
        this.shortFormattedAddress = this.getShortFormattedAddress();

        if (this.info.name) {
            this.name = this.info.name;
        } else if (this.info.type) {
            this.name = this.info.category;
            if (this.info.sub_category) {
                this.subTitle = this.info.sub_category;
            }
        }

        if (this.info.address?.state_code) {
            this.stateCode = this.info.address.state_code;
        }

        //BILLBOARD FIXES
        try {
            if (this.info.sub_category.toLocaleLowerCase() === 'billboard') {
                this.name = 'Kiosk @ ' + this.name;
            }
            if (this.info.sub_category.toLocaleLowerCase() === 'polygon') {
                this.info.logo_img = null;
            }
        } catch (e) {
            _.noop();
        }

        if (this.type === 'venue' || this.type === 'complex') {
            this.subTitle = this.address;
            this.dataTitle = '/ ' + this.getStreetName();
        } else if (this.type === 'billboard') {
            this.subTitle = this.address;
            this.dataTitle = '/ ' + this.subTitle;
        } else if (this.info.type === 'chain') {
            if (this.info.area) {
                if (this.info.area.type === 'nationwide') {
                    this.subTitle = 'Nationwide';
                    this.dataTitle = ' (' + this.subTitle + ')';
                } else {
                    this.subTitle =
                        title(this.info.area.name) + ' (' + title(this.info.area.type) + ')';
                    this.dataTitle = '/ ' + this.subTitle;
                }
            }
        } else if (this.info.type === 'zipcode') {
            this.name = this.info.category;
            this.subTitle = 'ZIP ' + this.getZipCode();
            this.dataTitle = '/ ' + this.subTitle;
            this.zipcode = this.getZipCode();
        }

        if (this.info.polygon && !this.info.geolocation) {
            try {
                if (this.type === 'billboard') {
                    const lats = _.cloneDeep(this.info.polygon.lat).sort();
                    const lons = _.cloneDeep(this.info.polygon.lon).sort();

                    const lat = lats[0] + (lats[lats.length - 1] - lats[0]) / 2;
                    const lng = parseFloat(lons[0] + (lons[lons.length - 1] - lons[0]) / 2);
                    this.info.geolocation = {
                        lat: lat,
                        lng: lng,
                    };
                } else {
                    var polygon = this.info.polygon[0];
                    if (polygon.length < 2) {
                        polygon = polygon[0];
                    }
                    let lats: number[] = [];
                    let lngs: number[] = [];
                    for (var iCoor in polygon) {
                        lats.push(polygon[iCoor][1]);
                        lngs.push(polygon[iCoor][0]);
                    }

                    lats = lats.sort();
                    lngs = lngs.sort();
                    const lat = lats[0] + (lats[lats.length - 1] - lats[0]) / 2;
                    const lng = parseFloat((lngs[0] + (lngs[lngs.length - 1] - lngs[0]) / 2).toString());
                    this.info.geolocation = {
                        lat: lat,
                        lng: lng,
                    };
                }
            } catch (e) {
                console.error('Venue polygons issue', e);
            }
        }

        if (_.get(venueInfo, 'geojson')) {
            this.info.polygon = createPolygon(_.get(venueInfo, 'geojson'));
            this.info.geojson = null; // Remove temporary data
        }

        this.changeCounter++;

        function createPolygon(geojson: any) {
            switch (geojson.type) {
                case APP_CONSTS.geoJson.types.MULTI_POLYGON:
                    return new GeoMultiPolygon(geojson.coordinates);
                case APP_CONSTS.geoJson.types.POLYGON:
                    return new GeoPolygon(geojson.coordinates);
                default:
                    return;
            }
        }
    }
    touchIt(isTouched: any) {
        _.set(this, 'customData.isTouched', isTouched);
    }
    setVenueIndex(index: any) {
        _.set(this, 'customData.venueIndex', index);
    }
    setFilter(filter: any) {
        _.set(this, 'customData.filter', filter);
    }
    isValid() {
        return _.get(this, 'customData.selectText') === this.name;
    }
    setSelectText(text: any) {
        _.set(this, 'customData.selectText', text);
    }
    setCensusProfileData(data: any) {
        _.set(this, 'censusProfileData', data);
    }
    setCustomers(customerProfile: any) {
        this.customers = _.assign(this.customers, customerProfile);
    }
    setFlow(flowData: any) {
        _.set(this, 'flow.visits', {
            prior: [],
            post: [],
        });

        for (let i = 0; i < flowData.visits.prior.length; ++i) {
            this.flow.visits.prior[i] = new Venue(flowData.visits.prior[i]);
            this.flow.visits.prior[i].setParent(this);
            this.flow.visits.prior[i].setRating(i + 1);
            this.flow.visits.prior[i].setLocationType(APP_CONSTS.locationType.PRIOR);
            this.flow.visits.prior[i].setColor(APP_CONSTS.color.neutralVenue);
        }
        for (let i = 0; i < flowData.visits.post.length; ++i) {
            this.flow.visits.post[i] = new Venue(flowData.visits.post[i]);
            this.flow.visits.post[i].setParent(this);
            this.flow.visits.post[i].setRating(i + 1);
            this.flow.visits.post[i].setLocationType(APP_CONSTS.locationType.POST);
            this.flow.visits.post[i].setColor(APP_CONSTS.color.neutralVenue);
        }
        this.changeCounter++;
    }
    setTopFlow(topFlowData: any) {
        _.set(this, 'flow.top', {
            prior: [],
            post: [],
        });

        for (let i = 0; i < topFlowData.visits.prior.length; ++i) {
            this.flow.top.prior[i] = new Venue(topFlowData.visits.prior[i]);
            this.flow.top.prior[i].setParent(this);
            this.flow.top.prior[i].setLocationType(APP_CONSTS.locationType.PRIOR);
            this.flow.top.prior[i].setColor(APP_CONSTS.color.neutralVenue);
        }
        for (let i = 0; i < topFlowData.visits.post.length; ++i) {
            this.flow.top.post[i] = new Venue(topFlowData.visits.post[i]);
            this.flow.top.post[i].setParent(this);
            this.flow.top.post[i].setLocationType(APP_CONSTS.locationType.POST);
            this.flow.top.post[i].setColor(APP_CONSTS.color.neutralVenue);
        }
    }
    setRating(rating: any) {
        this.rating = rating;
    }
    setLocationType(type: any) {
        this.locationType = type;
    }
    setParent(venue: any) {
        this.parentVenue = venue;
    }
    generateUID() {
        this.uid = _.uniqueId('poi_');
    }
    setColor(regularColor: any, lightColor: any, darkColor: any) {
        this.setRegularColor(regularColor);
        this.setLightColor(lightColor);
        this.setDarkColor(darkColor);
    }
    setRegularColor(color: any) {
        _.set(this, 'customData.color.regular', color);
    }
    getRegularColor() {
        return _.get(this, 'customData.color.regular');
    }
    setLightColor(color: any) {
        _.set(this, 'customData.color.light', color);
    }
    setDarkColor(color: any) {
        _.set(this, 'customData.color.dark', color);
    }
    setPalette(color: any) {
        _.set(this, 'customData.color.palette', color);
    }
    setHighlighted(isHighlighted: any) {
        _.set(this, 'customData.isHighlighted', isHighlighted);
    }
    setIcon(icon: any) {
        _.set(this, 'customData.icon', icon);
    }
    setCoverage(coverageData: any) {
        _.set(this, 'coverage', coverageData);
    }
    setOverview(overview: any) {
        this.overview = {};
        if (!overview) {
            return;
        }
        for (var key in overview) {
            this['overview'][key] = overview[key];
        }

        this.overview.estimatedFootTrafficMoe = {
            value: this.getEstVisitsPerMonth(),
            moe: this.getEstVisitsUpperBounds()
                ? this.getEstVisitsUpperBounds() - this.getEstVisitsPerMonth()
                : 'N/A',
        };
        this.overview.estimatedCustomerMoe = {
            value: this.getAvgCustomersPerMonth(),
            moe: this.getAvgCustomersPerMonthUpperBounds()
                ? this.getAvgCustomersPerMonthUpperBounds() - this.getAvgCustomersPerMonth()
                : 'N/A',
        };
        this.overview.avgVisitsPerCustomer = this.getAvgVisitsPerCustomer();
        this.overview.estimatedVisitsPerSq = this.getEstimatedVisitsPerSq();
        this.overview.estimatedFootTraffic = this.getEstFootTraffic();

        this.changeCounter++;
    }
    setChildren(venues: any) {
        this.childrenVenues = [];

        for (let i = 0; i < venues.length; ++i) {
            this.childrenVenues[i] = new Venue(venues[i]);
            this.childrenVenues[i].setParent(this);
            this.childrenVenues[i].setRating(i + 1);
            this.childrenVenues[i].setLocationType(APP_CONSTS.locationType.TOP);
            this.childrenVenues[i].setColor(APP_CONSTS.color.neutralVenue);
            this.childrenVenues[i].visitsPerMonth =
                this.childrenVenues[i].getEstVisitsPerMonth() || 0;
            this.childrenVenues[i].info.logo_img = this.info.logo_img;
        }
    }
    setCompetitors(venues: any) {
        this.competitorVenues = [];

        for (let i = 0; i < venues.length; ++i) {
            this.competitorVenues[i] = new Venue(venues[i]);
            this.competitorVenues[i].setParent(this);
            this.competitorVenues[i].setRating(i + 1);
            this.competitorVenues[i].setLocationType(APP_CONSTS.locationType.COMPETITOR);
            this.competitorVenues[i].setColor(APP_CONSTS.color.neutralVenue);
            this.competitorVenues[i].visitsPerMonth =
                this.competitorVenues[i].getEstVisitsPerMonth() || 0;
            this.competitorVenues[i].distanceInMeters = Venue.getDistanceFromLatLonInMeters(
                _.get(this, 'info.geolocation.lat'),
                _.get(this, 'info.geolocation.lng'),
                _.get(this.competitorVenues[i], 'info.geolocation.lat'),
                _.get(this.competitorVenues[i], 'info.geolocation.lng'),
            );
        }
    }
    hasVisitsAndImpressions() {
        let hasVisits = false;
        let hasImpressions = false;
        const hasDataSource = this.info?.data_type_source;

        if (!hasDataSource) {
            return false;
        }

        for (let i = 0; i < hasDataSource.length; i++) {
            if (hasDataSource[i].type === 'impressions') {
                hasImpressions = true;
            }
            if (hasDataSource[i].type === 'visits') {
                hasVisits = true;
            }
        }

        return hasVisits && hasImpressions;
    }
    setVisits(visits: any) {
        /**
         * Removing first two entries in case all current POIs,
         * are not having visists + impressions in data_type_source
         * additional checking for already trimmed bins
         */
        const _shouldBeTrimmed = () => {
            const hasVisitsBins = this.visits.length?.bins ?? false;
            const hasEstimatedFoottraffic = this.visits.length?.estimated_foottraffic ?? false;
            const hasBinsAndFoottrffic = hasVisitsBins && hasEstimatedFoottraffic;

            if (
                !this.allHaveVisitsAndImpressions &&
                hasVisitsBins.length > TRIMMED_LENGTH &&
                hasBinsAndFoottrffic
            ) {
                return true;
            }

            return false;
        };

        if (!this['visits']) {
            this['visits'] = {};
        }

        for (var key in visits) {
            if (!visits[key] || typeof visits[key] !== 'object') {
                continue;
            }

            visits[key].totalVisits = 0;
            var rawData = _.get(visits, '[' + key + '].estimated_foottraffic');
            const venueAreaSqft = this.info.area_sqft;

            for (let i in rawData) {
                if (key === 'history') {
                    visits[key]['estimated_sqft_foottraffic'] = rawData.map((ftVal: any) => {
                        const visitsSqft = ftVal / venueAreaSqft;
                        return visitsSqft ?? null;
                    });
                }
                visits[key].totalVisits += rawData[i];
            }
            this['visits'][key] = visits[key];
        }

        if (_shouldBeTrimmed()) {
            this.visits.length.bins.splice(0, 2);
            this.visits.length.estimated_foottraffic.splice(0, 2);
        }

        this.changeCounter++;
    }
    setHistoryVisits(visits: any, granularity: any) {
        _.set(this, 'visits.granulatedHistory[' + granularity + ']', visits.history);
    }
    setTradeAreaDistance(value: any, type: any) {
        _.set(this, 'tradeArea.distance[' + type + ']', value);
    }
    rankRegionTypes(region: any, data: any) {
        _.set(this, 'customData.rankRegionTypes[' + region + ']', data);
    }
    getAddressForOverview() {
        try {
            var address = '';
            if (this.info.address.address) {
                address = this.info.address.address;
            }
            if (this.info.address.city) {
                address += ', ' + this.info.address.city;
            }
            if (this.info.address.state_code) {
                address += ', ' + this.info.address.state_code;
            }

            return address;
        } catch (except) {
            // console.warn("Missing address value", except);
        }
    }
    getName() {
        try {
            return this.name || this.info.name;
        } catch (except) {
            console.warn('Missing value', except, this);
        }
    }
    getStreetName() {
        try {
            if (!this.info.address) {
                return '';
            }
            var streetName = this.info.address.address;
            if (!streetName) {
                if (this.info.address.formatted_address) {
                    streetName = this.info.address.formatted_address;
                } else if (this.getZipCode()) {
                    streetName = this.getZipCode();
                    if (this.info.address.state_code) {
                        streetName = this.info.address.state_code + streetName;
                    }
                    return streetName;
                } else if (this.info.address.city) {
                    streetName = this.info.address.city;
                    return streetName;
                }
            }
            if (!streetName) {
                return '';
            }
            streetName = streetName.split(',')[0];
            streetName = streetName.replace(/^\s*[0-9]+\s+/, '');
            return streetName;
        } catch (except) {
            console.warn('Missing value', except, this);
        }
    }
    getFullAddress() {
        let address;
        const type = this.type;
        if (type === 'chain') {
            address = ChainPOI.getLocationLabel(_.get(this, 'info'));
            return address;
        } else {
            address = _.get(this, 'info.address');
            let fullAddress: string[] = [];

            if (!address) {
                return null;
            }
            if (address.address) {
                fullAddress.push(address.address.replace(/[0-9]/g, ''));
            }
            if (address.city) {
                fullAddress.push(address.city);
            }

            if (address.state_code) {
                fullAddress.push(address.state_code);
            }
            return fullAddress.join(', ');
        }
    }
    getShortFormattedAddress() {
        if (this.type === 'chain') {
            return ChainPOI.getLocationLabel(_.get(this, 'info'));
        }
        const address = _.get(this, 'info.address');
        if (!address) {
            return null;
        }
        return address.short_formatted_address;
    }
    getAddress() {
        try {
            if (!_.get(this, 'info.address')) {
                console.warn('Missing address', this);
                return '';
            }

            let address = '';
            if (this.type === 'chain') {
                address = ChainPOI.getLocationLabel(_.get(this, 'info'));
            } else {
                address += ', ' + _.get(this, 'info.address.address');
                if (_.get(this, 'info.address.city')) {
                    address += ', ' + _.get(this, 'info.address.city');
                }
                if (_.get(this, 'info.address.state_code')) {
                    address += ', ' + _.get(this, 'info.address.state_code');
                }
                if (this.getZipCode()) {
                    address += ' ' + this.getZipCode();
                }
            }

            if (address.length > 0 && this.type !== 'chain') {
                address = address.substr(2, address.length);
            }

            return address;
        } catch (except) {
            console.warn('Missing value', except, this);
        }
    }
    getAvgCustomersPerMonth() {
        return _.get(this, 'overview.estimated_customer_count');
    }
    getAvgCustomersPerMonthUpperBounds() {
        return _.get(this, 'overview.estimated_customer_count_upper_bound');
    }
    getAvgCustomersPerMonthLowerBounds() {
        return _.get(this, 'overview.estimated_customer_count_lower_bound');
    }
    getPanelVisits() {
        return _.get(this, 'overview.panel_visits');
    }
    getPanelUsers() {
        return _.get(this, 'overview.panel_devices');
    }
    getEstVisitsPerMonth() {
        return Venue.roundFloat(_.get(this, 'overview.estimated_foottraffic', 0), 0);
    }
    getEstFootTraffic() {
        var ft = _.get(this, 'overview.estimated_foottraffic', 0);

        if (ft < 100000) {
            return '<100K';
        }

        return ft;
    }
    getEstVisitsUpperBounds() {
        return Venue.roundFloat(_.get(this, 'overview.estimated_foottraffic_upper_bound', 0), 0);
    }
    getEstVisitsLowerBounds() {
        return Venue.roundFloat(_.get(this, 'overview.estimated_foottraffic_lower_bound', 0), 0);
    }
    getAvgVisitsPerCustomer() {
        try {
            return Venue.roundFloat(
                this.getEstVisitsPerMonth() / this.getAvgCustomersPerMonth(),
                2,
            );
        } catch (except) {
            console.warn('getAvgVisitsPerCustomer', except);
        }
    }
    getEstimatedVisitsPerSq() {
        if (!_.get(this, 'overview.square_feet')) return;
        return (
            (_.get(this, 'overview.estimated_foottraffic', 0) /
                _.get(this, 'overview.square_feet')) *
            1000
        );
    }
    getRankList() {
        try {
            return this.overview.ranks;
        } catch (except) {
            // console.warn("Missing address value", except);
            return [];
        }
    }
    getZipCode() {
        try {
            return (
                this.info.address.zip_code || this.info.address.zip || this.info.address.postal_code
            );
        } catch (except) {
            return null;
            // console.warn("Missing address value", except);
        }
    }
    getPolygon() {
        try {
            return this.info.polygon;
        } catch (except) {
            return null;
            // console.warn("Missing address value", except);
        }
    }
    getNumberOfVenues() {
        try {
            return this.info.number_of_venues;
        } catch (except) {
            return null;
            // console.warn("Missing address value", except);
        }
    }
    getIsClosed() {
        var operatingList = this.info.operating_info;

        if (!operatingList || !operatingList.length) return false;

        var lastStatus = operatingList[operatingList.length - 1];

        return lastStatus.status === 'closed';
    }
    getClosedAt() {
        var operatingList = this.info.operating_info;

        if (!operatingList || !operatingList.length) return;

        var lastStatus = operatingList[operatingList.length - 1];

        if (lastStatus.status === 'closed') {
            return lastStatus.closing_date;
        }
    }
}

// Venue.prototype.go = function () {
//     history.push(`/admin/insights/${this.collection}/${this.id}/overview?competitor&filter`);
// $state.go(
//     'admin-panel.property.insights.overview',
//     {
//         collection: this.collection,
//         id: this.id,
//     },
//     { inherit: false, reload: 'admin-panel.property.insights' },
// );
// };
