import React, { createContext, PropsWithChildren, useLayoutEffect, useMemo, useState } from 'react';
import moment, { Moment } from 'moment';
import { Dropdown, DropDownProps } from 'antd';
import classNames from 'classnames';
import { noop } from 'lodash/fp';
import styles from './date-picker.module.scss';

import { Button } from 'ui-components/button/button';
import { DatePickerMenu } from 'ui-components/date-picker/date-picker-menu/date-picker-menu';
import { CalendarIcon, ArrowOutlinedSvgIcon } from 'components/assets/Icons/Icons';
import { labelSuggestion } from 'utils/date/date';
import { RangeData } from './date-types/RangeData';
import { ListItem } from 'ui-components/date-picker/date-picker-list/date-picker-list';
import { useFixedPreset } from 'ui-components/date-picker/date-hooks/use-fixed-preset';
import { useRelativePreset } from 'ui-components/date-picker/date-hooks/use-relative-preset';

export const DateContext = createContext<{
    minDate?: Moment;
    maxDate?: Moment;
    maxMonth?: Moment;
    startDate?: Moment;
    endDate?: Moment;
    hideNavigation?: boolean;
}>({});

interface DatePickerProps {
    maxDate?: string | Moment;
    minDate?: string | Moment;
    maxMonth?: string | Moment;
    startDate?: string | Moment;
    endDate?: string | Moment;
    onAdvanced?: () => void;
    onChange?: (rangeData: RangeData<Moment>) => void;
    displayText?: string;
    pickerClassName?: string;
    triggerClassName?: string;
    overlayClassName?: string;
    getPopupContainer?: (triggerNode?: HTMLElement) => HTMLElement;
    dropdownTrigger?: Array<'click' | 'hover'>;
    visible?: boolean;
    onVisibleChange?: (visible: boolean) => void;
    hideNavigation?: boolean;
    segments?: Readonly<Array<ListItem<string>>>;
    defaultPanel?: string;
    showMaxDate?: boolean;
    panelSide?: 'left' | 'right';
}

export const DatePicker = ({
    maxDate,
    minDate,
    maxMonth,
    startDate,
    endDate,
    children,
    onAdvanced,
    onChange = noop,
    displayText,
    pickerClassName,
    overlayClassName,
    getPopupContainer,
    dropdownTrigger = ['click'],
    visible,
    triggerClassName,
    onVisibleChange,
    hideNavigation,
    segments,
    defaultPanel,
    showMaxDate,
    align,
    placement,
    panelSide = 'right',
}: PropsWithChildren<DatePickerProps & Pick<DropDownProps, 'placement' | 'align'>>) => {
    const mxDate = maxDate ? moment(maxDate) : undefined;
    const mnDate = minDate ? moment(minDate) : undefined;
    const mxMonth = maxMonth ? moment(maxMonth) : undefined;
    const fromDate = startDate ? moment(startDate) : undefined;
    const toDate = endDate ? moment(endDate) : undefined;
    const [isOpen, setIsOpen] = useState(false);
    const [label, setLabel] = useState(labelSuggestion(fromDate, toDate));
    const isVisible = visible !== undefined ? visible : isOpen;

    const fixedDates = useFixedPreset();
    const relativeDates = useRelativePreset();

    useLayoutEffect(() => {
        setLabel(labelSuggestion(fromDate, toDate));
    }, [fromDate, toDate]);

    const defaultPanelSelect = useMemo(() => {
        const isRelativeDate = Object.values(relativeDates)
            .flatMap((fixDate) => Object.keys(fixDate))
            .includes(displayText ?? '');

        const isFixDate = Object.values(fixedDates)
            .flatMap((fixDate) => Object.keys(fixDate))
            .includes(displayText ?? '');

        if (!isFixDate && !isRelativeDate) {
            return;
        }
        return isFixDate ? 'fixed' : 'relative';
    }, [displayText, fixedDates, relativeDates]);

    const onApply = (rangeData: RangeData<Moment>) => {
        setIsOpen(false);
        onVisibleChange && onVisibleChange(false);
        setLabel(labelSuggestion(rangeData.value[0], rangeData.value[1]));
        onChange(rangeData);
    };

    const onCancel = () => {
        setIsOpen(false);
        onVisibleChange && onVisibleChange(false);
    };

    const onTriggerClick = (isVisible: boolean) => {
        setIsOpen(isVisible);
        onVisibleChange && onVisibleChange(isVisible);
    };

    return (
        <Dropdown
            getPopupContainer={getPopupContainer}
            overlay={
                <DateContext.Provider
                    value={{
                        minDate: mnDate,
                        maxDate: mxDate,
                        maxMonth: mxMonth,
                        startDate: fromDate,
                        endDate: toDate,
                        hideNavigation: hideNavigation,
                    }}
                >
                    <DatePickerMenu
                        displayText={displayText}
                        onApply={onApply}
                        onCancel={onCancel}
                        onAdvanced={
                            onAdvanced
                                ? () => {
                                      setIsOpen(false);
                                      onAdvanced();
                                  }
                                : undefined
                        }
                        segments={segments}
                        defaultPanel={defaultPanelSelect ?? defaultPanel}
                        showMaxDate={showMaxDate}
                        panelSide={panelSide}
                    />
                </DateContext.Provider>
            }
            visible={isVisible}
            trigger={dropdownTrigger}
            onVisibleChange={onTriggerClick}
            overlayClassName={classNames(styles.overlay, overlayClassName)}
            placement={placement}
            align={align}
        >
            <span
                className={classNames(styles.trigger, triggerClassName, {
                    [styles.open]: isVisible,
                })}
            >
                {children || (
                    <Button
                        type="transparent"
                        className={classNames(styles.picker, pickerClassName)}
                        tabIndex={-1}
                    >
                        <CalendarIcon className={styles.calendar} />
                        <span className={styles.label}>{displayText || label}</span>
                        <ArrowOutlinedSvgIcon
                            rotate={isVisible ? -180 : 0}
                            className={styles.arrow}
                        />
                    </Button>
                )}
            </span>
        </Dropdown>
    );
};
