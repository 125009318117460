import React, { ComponentProps } from 'react';
import styles from './map-type-controller.module.scss';
import {
    RadioButtonGroup,
    RadioButtonItem,
} from 'ui-components/radio-button-group/radio-button-group';
import { MapController } from 'ui-components/google-map/components/map-controller/map-controller';
import { MapTypeId } from 'ui-components/google-map/types/types';
import { useMapRenderingType } from 'ui-components/google-map/hooks/use-map-rendering-type';
import classNames from 'classnames';

const labels: Record<VectorMapTypeOption, string> = {
    roadmap: 'Map',
    hybrid: 'Hybrid',
    satellite: 'Satellite',
    terrain: 'Terrain',
    '3d': '3D',
};

const getTypeOptions = (options: VectorMapTypeOption[], isRaster?: boolean) =>
    options.map<RadioButtonItem>((value) => ({
        value,
        label: labels[value],
        disabled: value === '3d' && isRaster,
        disabledDescription: 'Select map mode to enter 3D mode',
    }));

export type VectorMapTypeOption = MapTypeId | '3d';

type MapTypeControllerProps = {
    value: VectorMapTypeOption;
    onChange: (value: VectorMapTypeOption) => void;
    position?: ComponentProps<typeof MapController>['position'];
    options?: VectorMapTypeOption[];
    className?: string;
};

export const MapTypeController = ({
    value,
    onChange,
    position = 'LEFT_BOTTOM',
    options = ['roadmap', '3d'],
    className,
}: MapTypeControllerProps) => {
    const renderingType = useMapRenderingType();
    const controllerOptions = getTypeOptions(options, renderingType === 'RASTER');

    return (
        <MapController position={position}>
            <RadioButtonGroup
                value={value}
                options={controllerOptions}
                onChange={(value) => onChange(value as VectorMapTypeOption)}
                size="small"
                className={classNames(styles.radioGroup, className)}
            />
        </MapController>
    );
};
