import { VenueFlags } from 'core/entities';
import { MyPropertiesEvent } from 'features/my-zone-properties/types/tracking';
import { setKeyValue } from 'store/app-ui/actions';
import { SEARCH_OPTION_POI_STORE_KEY } from 'components/search-options-dialog/consts';
import { useAppDispatch } from 'store/app-store-hooks';
import { useCallback } from 'react';
import { useSplunkCallback } from 'hooks/use-splunk-callback/use-splunk-callback';
import { openPropertyReport } from 'utils/navigation/navigation';
import { goToPropertyPage } from 'router/go-to-routes/go-to-property-page';
import { PlaceOverlay } from 'ui-components/google-map-layers/types/google-map-layers-types';
import { useSelectorWithProps } from 'utils/structured-selector/structured-selector';
import { selectHasUserPermission } from 'store/selectors/app-selectors';
import { OVERVIEW_REPORT_NAME, CUSTOM_REPORT_NAME } from 'utils/navigation/navigation-consts';
import type { Place } from '@placer-ui/types';

export const useOpenPropertyReport = (defaultSplunkEvent?: { state: string }) => {
    const dispatch = useAppDispatch();
    const sendEvent = useSplunkCallback<MyPropertiesEvent>(defaultSplunkEvent);

    return useCallback(
        (info: Place, flag?: VenueFlags) => {
            if (flag) {
                dispatch(
                    setKeyValue({
                        key: SEARCH_OPTION_POI_STORE_KEY,
                        value: info,
                    }),
                );
            } else {
                openPropertyReport(info);
                sendEvent &&
                    sendEvent({
                        action: 'button-click',
                        btn_name: 'property-report',
                        value: info.id,
                    });
            }
        },
        [dispatch, sendEvent],
    );
};

type OpenPropertyPageProps = {
    entities: PlaceOverlay[];
    newTab?: boolean;
    inlineSplunkEvent?: boolean;
};

export const useOpenMultiplePropertyReport = (defaultSplunkEvent?: { state: string }) => {
    const sendEvent = useSplunkCallback<MyPropertiesEvent>(defaultSplunkEvent);
    const hasEnableCustomPropertyOverview = useSelectorWithProps(
        { permission: 'enable_custom_property_overview' },
        selectHasUserPermission,
    );
    return useCallback(
        ({ entities, newTab, inlineSplunkEvent = true }: OpenPropertyPageProps) => {
            sendEvent &&
                inlineSplunkEvent &&
                entities.forEach((property) => {
                    sendEvent({
                        action: 'button-click',
                        btn_name: 'property-report',
                        value: property.id,
                    });
                });

            goToPropertyPage({
                entities,
                section: hasEnableCustomPropertyOverview
                    ? CUSTOM_REPORT_NAME
                    : OVERVIEW_REPORT_NAME,
                newTab,
            });
        },
        [sendEvent, hasEnableCustomPropertyOverview],
    );
};
