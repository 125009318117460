/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { isLocationPermissionApprovedSignal } from '../state/mobile-report.signals';

export interface IGeolocationPositionError {
    readonly code: number;
    readonly message: string;
    readonly PERMISSION_DENIED: number;
    readonly POSITION_UNAVAILABLE: number;
    readonly TIMEOUT: number;
}

export interface IGeolocationResponse {
    readonly coords: {
        latitude: number;
        longitude: number;
        altitude: number | null;
        accuracy: number;
        altitudeAccuracy: number | null;
        heading: number | null;
        speed: number | null;
    };
    readonly timestamp: number;
}
export interface GeoLocationSensorState {
    loading: boolean;
    lat: number | undefined;
    lng: number | undefined;
    error?: Error | IGeolocationPositionError;
}

const GET_LOCATION_INTERVAL = 60 * 1000 * 3;

export const useUserLocation = (options?: PositionOptions) => {
    const [state, setState] = useState<GeoLocationSensorState>({
        loading: true,
        lat: undefined,
        lng: undefined,
        error: undefined,
    });
    let mounted = true;

    const setPermissions = (result: string) => {
        if (result === 'granted') isLocationPermissionApprovedSignal.value = true;
        if (result === 'blocked') isLocationPermissionApprovedSignal.value = false;
    };

    useEffect(() => {
        if (window.placerMobile?.permissions)
            return window.placerMobile?.permissions.check('geolocation', setPermissions);

        navigator.permissions?.query({ name: 'geolocation' }).then((result) => {
            if (result.state === 'granted') isLocationPermissionApprovedSignal.value = true;
            if (result.state === 'denied') isLocationPermissionApprovedSignal.value = false;
        });
    }, []);

    const onEvent = (event: IGeolocationResponse) => {
        if (mounted) {
            setState({
                loading: false,
                lat: event.coords.latitude,
                lng: event.coords.longitude,
            });
        }
    };
    const onEventError = (error: IGeolocationPositionError) =>
        mounted &&
        setState((oldState) => ({
            ...oldState,
            loading: false,
            error,
        }));

    const getPosition = () => {
        navigator.geolocation.getCurrentPosition(onEvent, onEventError, options);
    };

    useEffect(() => {
        // https://github.com/michalchudziak/react-native-geolocation/issues/300
        // React Native Geolocation lib has a bug with watchPosition on iOS,
        // so we use getCurrentPosition with interval instead

        let interval: NodeJS.Timeout | undefined = undefined;

        if (isLocationPermissionApprovedSignal.value) {
            !interval && getPosition();

            interval = setInterval(getPosition, GET_LOCATION_INTERVAL);
        }
        return () => {
            if (isLocationPermissionApprovedSignal.value) {
                mounted = false;
                clearInterval(interval);
            }
        };
    }, [isLocationPermissionApprovedSignal.value]);

    return {
        lat: state.lat,
        lng: state.lng,
        error: state.error,
    };
};
