export type AudienceType = 'visitors' | 'employees' | 'residents' | 'default_audience';

export const VISITORS = 'visitors';
export const EMPLOYEES = 'employees';
export const RESIDENTS = 'residents';
export const DEFAULT_AUDIENCE = 'default_audience';

export const AUDIENCE_TYPE_DICT: Record<AudienceType, string> = {
    [VISITORS]: 'Visitors',
    [EMPLOYEES]: 'Employees',
    [RESIDENTS]: 'Residents',
    [DEFAULT_AUDIENCE]: 'Default Audience',
};
