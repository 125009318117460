import React from 'react';

import logoUrl from 'shared/images/placer-logo-ai.svg';
import { UserSection } from './user-section/user-section';
import { SearchSection } from 'features/header/header-top-panel/search-section/search-section';
import { HeaderButtonGroupComponent } from './header-button-group/header-button-group';
import { NotificationsButton } from 'features/header/header-top-panel/notifications-button/notifications-button';
import { StarredReportsButton } from 'features/header/header-top-panel/starred-reports-button/starred-reports-button';

import styles from './header-top-panel.module.scss';

export const HeaderTopPanel = () => {
    return (
        <div className={styles.headerTopPanelWrapper}>
            <div className={styles.panelLeftSection}>
                <img className={styles.logo} src={logoUrl} alt="placer ai logo" />
                <SearchSection />
            </div>
            <HeaderButtonGroupComponent />
            <StarredReportsButton />
            <NotificationsButton />
            <UserSection />
        </div>
    );
};
