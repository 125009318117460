import { UserCustomSettingsExplore } from 'core/entities/user/user-custom-settings-explore';
import { goTo } from 'router/go-to';
import { useExploreActions } from 'features/explore/store/actions';
import { useCallback } from 'react';
import { ExploreConfiguration, ExploreUIStateType } from 'features/explore/types/types';
import { getExploreFilterType } from 'utils/get-explore-filter-type/get-explore-filter-type';
import { PROPERTY } from 'core/constants/report-type';
import {
    getBoundingBoxByRadius,
    getCoordinates,
} from 'utils/bounding-box-by-radius/bounding-box-by-radius';
import { calcDistanceInMiles } from 'ui-components/google-map/utils';
import { defaultMapConfiguration } from 'features/explore/hooks/use-map-configuration';
import { history } from 'router/history';

export const useGoToExplorePage = () => {
    const { setExploreSearchParameters } = useExploreActions();

    return useCallback(
        (
            params: Partial<ExploreConfiguration & UserCustomSettingsExplore>,
            uiContextParams?: Partial<ExploreUIStateType>,
        ) => {
            const { lat, lng } = params?.center || defaultMapConfiguration.center;
            const zoom = params.zoom || defaultMapConfiguration.zoom;

            const miles = calcDistanceInMiles(lat, zoom);
            const boundingBox = getBoundingBoxByRadius(miles, lng, lat);
            const { ne, sw } = getCoordinates(boundingBox);

            const exploreParams: Partial<UserCustomSettingsExplore> = {
                type: params.reportType || getExploreFilterType(PROPERTY),
                center: {
                    lat,
                    lng,
                },
                zoom,
                ne: ne.join(','),
                sw: sw.join(','),
            };
            setExploreSearchParameters({
                restrictions: {
                    ...params.restrictions,
                },
                zoom,
                center: {
                    lat,
                    lng,
                },
                sw,
                ne,
                term: params.term,
                reportType: params.reportType,
            });

            const path = `/explore/?params=${JSON.stringify({
                ...exploreParams,
                uiContextParams: uiContextParams,
            })}`;

            goTo(path);
        },
        [setExploreSearchParameters],
    );
};

export const goToExplore = (options: Partial<UserCustomSettingsExplore>) => {
    history.replace({
        pathname: '/explore',
        search: `?params=${JSON.stringify(options)}`,
    });
};
