import React, { DetailedHTMLProps, ImgHTMLAttributes, SyntheticEvent } from 'react';

// <img> element with fallback to AVIF format, which should be:
//  - less then 8kb size (to be inlined)

// You can use https://squoosh.app/ to create AVIF images.

type ImgWithFallbackProps = DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
> & { fallbackSrc: string };

export const ImgWithFallback = ({ src, alt, fallbackSrc, ...props }: ImgWithFallbackProps) => (
    <img
        src={src}
        alt={alt}
        {...props}
        onError={(e: SyntheticEvent<HTMLImageElement, Event>) => {
            e.currentTarget.src = fallbackSrc;
        }}
    />
);
