import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

import { IconProps } from 'components/assets/Icons/Icons';

/* eslint-disable max-len */
export const MedicalAndHealthIconSvg = (
    props: CustomIconComponentProps | SVGProps<SVGSVGElement>,
) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 12 12">
        <g fill="none" fillRule="evenodd">
            <g>
                <g>
                    <g>
                        <g>
                            <path
                                fill="currentColor"
                                d="M10 3c1.105 0 2 .895 2 2v5c0 1.105-.895 2-2 2H2c-1.105 0-2-.895-2-2V5c0-1.105.895-2 2-2h8zM6.5 5h-1l-.001 1.999L3.5 7v1l1.999-.001L5.5 10h1l-.001-2.001L8.5 8V7l-2.001-.001L6.5 5z"
                                transform="translate(-1031 -340) translate(0 111) translate(1027 225) translate(4 4)"
                            />
                            <path
                                stroke="currentColor"
                                d="M8 .5l.5 2.543-4.583.417L3.5.966 8 .5z"
                                transform="translate(-1031 -340) translate(0 111) translate(1027 225) translate(4 4) rotate(6)"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export const MedicalAndHealthIcon = (props: IconProps) => (
    <Icon component={MedicalAndHealthIconSvg} {...props} />
);
