import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'API';
import { AppState } from 'store/store';
import { Awaited } from 'types/awaited';
import { chainsByCategoriesSelector } from 'store/configuration/chains-by-categories/selectors';
import { UserPermissions } from 'core/entities/user/user-permissions';

type GetChainsByCategoriesResponseType = Awaited<
    ReturnType<typeof API.configuration.getChainsByCategoriesApi>
>;

type GetChainsBySubCategoriesResponseType = Awaited<
    ReturnType<typeof API.configuration.getChainsBySubCategoriesApi>
>;

export const getChainsByCategories = createAsyncThunk<
    GetChainsByCategoriesResponseType['data'] | GetChainsBySubCategoriesResponseType['data'],
    UserPermissions | undefined,
    { state: AppState }
>(
    'configuration/get-chains-by-category',
    async () => {
        const storeData = (window as any)?.dataForUI || (window as any)?.opener?.dataForUI;
        const existingChainsByCategories = storeData?.configuration?.chainsByCategories;

        if (existingChainsByCategories) return existingChainsByCategories;

        const { data } = await API.configuration.getChainsBySubCategoriesApi();
        return data;
    },
    {
        condition(
            _params: UserPermissions | undefined | void,
            thunkAPI: { getState: () => AppState },
        ) {
            const state = thunkAPI.getState();

            const chainsByCategoriesInitialized = chainsByCategoriesSelector(state);

            return !Object.keys(chainsByCategoriesInitialized).length;
        },
    },
);
