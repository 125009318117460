/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

type HolidaysSportIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type HolidaysSportIconProps = SVGProps<SVGSVGElement> & HolidaysSportIconUniqueProps;

export const HolidaysSportIcon = function HolidaysSportIcon({
    width = 32,
    height = 32,
    ...props
}: HolidaysSportIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M0 4C0 2.89543 0.895431 2 2 2H15.5V10.0205C12.4202 10.2746 10 12.8547 10 16C10 19.1453 12.4202 21.7254 15.5 21.9795V30H2C0.895431 30 0 29.1046 0 28V23H5V9H0V4Z"
                fill="#A2D4CF"
            />
            <path d="M0 10V22H4V10H0Z" fill="#A2D4CF" />
            <path
                d="M16.5 30V21.9795C19.5798 21.7254 22 19.1453 22 16C22 12.8547 19.5798 10.2746 16.5 10.0205V2H30C31.1046 2 32 2.89543 32 4V9H27V23H32V28C32 29.1046 31.1046 30 30 30H16.5Z"
                fill="#A2D4CF"
            />
            <path d="M32 10V22H28V10H32Z" fill="#A2D4CF" />
            <path d="M4 10H0V22H4V10Z" fill="#5E63E5" />
            <path d="M28 10H32V22H28V10Z" fill="#5E63E5" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 21C18.7614 21 21 18.7614 21 16C21 13.2386 18.7614 11 16 11C13.2386 11 11 13.2386 11 16C11 18.7614 13.2386 21 16 21ZM15.5 17.5C15.5 17.7761 15.7239 18 16 18C16.2761 18 16.5 17.7761 16.5 17.5V14.5C16.5 14.2239 16.2761 14 16 14C15.7239 14 15.5 14.2239 15.5 14.5V17.5Z"
                fill="#5E63E5"
            />
        </svg>
    );
};
HolidaysSportIcon.__tags = ['holiday', 'sport', 'football'] as string[];
