import { Venue } from '@placer-ui/types';

export const getIsCoordinate = (poi: Venue) => {
    const overriddenFieldName = poi?.overriddenFields?.name?.split(', ');
    return (
        overriddenFieldName &&
        overriddenFieldName[0]?.includes(poi?.geolocation?.lat.toFixed(1)) &&
        overriddenFieldName[1]?.includes(poi?.geolocation?.lng.toFixed(1))
    );
};
