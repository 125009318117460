import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReportNotAvailableError } from 'components/errors/report-not-available-error/report-not-available-error';

export const InsufficientDataError = () => {
    const history = useHistory();

    const goToExplore = () => {
        history.push('/explore');
    };

    return (
        <ReportNotAvailableError
            title="Insufficient Data"
            primaryText="Start a new Search"
            onPrimaryClick={goToExplore}
            showPrimaryButton
        >
            <p>Try changing filter parameters or increasing query date range.</p>
        </ReportNotAvailableError>
    );
};
