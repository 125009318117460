import { createSlice } from '@reduxjs/toolkit';

import { deleteAllStarredReports, deleteStarredReport } from 'store/star-report/actions/delete-starred-report';
import { getStarredReports } from 'store/star-report/actions/get-starred-reports';
import { saveStarredReport } from 'store/star-report/actions/save-starred-report';
import { updateStarredReport } from 'store/star-report/actions/update-starred-report';
import { updateStarredReportLastViewedDate } from 'store/star-report/actions/update-starred-report-last-viewed-date';

import { StarReportStateModel } from 'store/star-report/models/star-report-state-model';

const starReportInitialState = {
    processingApiAction: null,
} as StarReportStateModel;

export const starReportSlice = createSlice({
    name: 'starReport',
    initialState: starReportInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getStarredReports.pending, (draft) => {
                draft.processingApiAction = 'loading';
            })
            .addCase(getStarredReports.fulfilled, (draft, { payload }) => {
                draft.processingApiAction = null;
                draft.reports = payload;
            })
            .addCase(getStarredReports.rejected, (draft) => {
                draft.processingApiAction = null;
            })
            .addCase(deleteStarredReport.fulfilled, (draft, { payload }) => {
                if (!draft.reports) {
                    return;
                }

                const index = draft.reports.findIndex((report) => report.name === payload);

                draft.reports.splice(index, 1);
            })
            .addCase(saveStarredReport.pending, (draft) => {
                draft.processingApiAction = 'saving';
            })
            .addCase(saveStarredReport.fulfilled, (draft, { payload }) => {
                if (!draft.reports) {
                    draft.reports = [];
                }

                draft.reports.push(payload);
                draft.processingApiAction = null;
            })
            .addCase(saveStarredReport.rejected, (draft) => {
                draft.processingApiAction = null;
            })
            .addCase(updateStarredReportLastViewedDate.pending, (draft, { payload }) => {
                draft.processingApiAction = 'updating';
            })
            .addCase(updateStarredReportLastViewedDate.fulfilled, (draft, { payload }) => {
                draft.processingApiAction = null;

                if (!draft.reports) {
                    return;
                }

                const index = draft.reports.findIndex((report) => report.name === payload.name);

                draft.reports[index] = payload;
            })
            .addCase(updateStarredReportLastViewedDate.rejected, (draft) => {
                draft.processingApiAction = null;
            })
            .addCase(updateStarredReport.pending, (draft) => {
                draft.processingApiAction = 'updating';
            })
            .addCase(updateStarredReport.fulfilled, (draft, { payload }) => {
                draft.processingApiAction = null;

                if (!draft.reports) {
                    return;
                }

                const { oldReportName, ...report } = payload;
                const index = draft.reports.findIndex((report) => report.name === oldReportName);

                draft.reports[index] = report;
            })
            .addCase(updateStarredReport.rejected, (draft) => {
                draft.processingApiAction = null;
            })
            .addCase(deleteAllStarredReports, (draft) => {
                draft.reports = undefined;
            });
    },
});
