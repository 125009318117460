import { useEffect, useMemo } from 'react';
import { useAppDispatch } from 'store/app-store-hooks';
import { bindActionCreators } from 'redux';
import { shortVisitsConfigSliceActions } from 'store/configuration/short-visits-config/short-visits-config-slice';
import { simpleFetchNoErrorHandling } from 'API/fetch/simple-fetch-no-error-handling';
import type { PlacerResponseData, ShortVisitsConfigResp } from '@placer-ui/types';
import { getAppApiEndpoint } from 'core/default-endpoint-url';

export const useFetchShortVisitsConfigAction = () => {
    const dispatch = useAppDispatch();

    const connectedAction = useMemo(
        () => bindActionCreators(shortVisitsConfigSliceActions, dispatch),
        [dispatch],
    );

    useEffect(() => {
        const fetchShortVisitsConfigAction = async () => {
            try {
                const reactAppApiEndpoint = getAppApiEndpoint();
                const response = await simpleFetchNoErrorHandling<
                    PlacerResponseData<ShortVisitsConfigResp>,
                    ShortVisitsConfigResp
                >({
                    targetUrl: '/short-visits-config',
                    method: 'GET',
                    endPoint: `${reactAppApiEndpoint}`,
                });

                if (response.error_code === 0) {
                    connectedAction.setShortVisitsImpactedCategories(response.data);
                } else {
                    throw new Error(response.result);
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchShortVisitsConfigAction();
    }, [connectedAction]);
};
