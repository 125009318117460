import { UserCustomSettingsExplore } from 'core/entities/user/user-custom-settings-explore';

export const goToExploreCommonParams: Partial<UserCustomSettingsExplore> = {
    enable_closed_pois: true,
    enable_flagged_pois: true,
    zoom: 15,
    input: '',
    query: '',
};

export const editPopupElevioId = '2';
export const requestATrafficPinElevioId = '53';
export const comingSoonLearnMoreElevioId = '439';

export const groupViewSplunkDefaultData = {
    state: 'admin-panel.myzone.properties.group-view',
};

export const tenantViewDefaultEventData = {
    state: 'admin-panel.myzone.properties.tenant',
};
