import { useCallback } from 'react';

import type { Dictionary } from '@placer-ui/types';
import { SEARCH_ITEM_ID } from 'features/poi-selection-map/poi-selection-menu/menu-items/search-menu-item/search-menu-item';
import { PORTFOLIO_ITEM_ID } from 'features/poi-selection-map/poi-selection-menu/menu-items/portfolio-menu-item/portfolio-menu-item';
import { MY_TAGS_ITEM_ID } from 'features/poi-selection-map/poi-selection-menu/menu-items/tags-menu-items/my-tags-menu-item/my-tags-menu-item';
import { COMPANY_TAGS_ITEM_ID } from 'features/poi-selection-map/poi-selection-menu/menu-items/tags-menu-items/company-tags-menu-item/company-tags-menu-item';
import {
    PoiSelectionSplunkData,
    PartialSplunkDataShape,
    VoidAnalysisOpenReportSplunkData,
} from 'features/void-analysis/sections/entry-point-redirection/types/entry-point-tracking-types';
import { CardMethodNames } from 'features/void-analysis/sections/empty-space/types/method-card';
import { useSplunkCallback } from 'hooks/use-splunk-callback/use-splunk-callback';

const menuItemIdToSplunkString: Dictionary<string> = {
    [SEARCH_ITEM_ID]: 'Search List',
    [PORTFOLIO_ITEM_ID]: 'Portfolio',
    [MY_TAGS_ITEM_ID]: 'Tags List',
    [COMPANY_TAGS_ITEM_ID]: 'Company Tags',
};

export const convertToSplunkFormat = (
    poiSelectionSplunkData: PoiSelectionSplunkData,
    poiId: string,
): PartialSplunkDataShape => {
    const isMapOrigin = poiSelectionSplunkData.origin === 'Map Info Window';
    const componentName = menuItemIdToSplunkString[poiSelectionSplunkData.component];
    return {
        state: 'admin-panel.advanced-reports.void-mini-explore',
        view_name: 'Void Mini Explore',
        component: isMapOrigin ? 'Property info card' : componentName,
        report_config: {
            ...poiSelectionSplunkData.report_config,
            poi_id: poiId,
            ...(isMapOrigin && {
                type: componentName,
            }),
        },
    };
};

export const addPoiIdToReportConfig = (
    poiSelectionSplunkData: VoidAnalysisOpenReportSplunkData,
    poiId: string,
): VoidAnalysisOpenReportSplunkData => {
    return {
        ...poiSelectionSplunkData,
        report_config: {
            ...poiSelectionSplunkData.report_config,
            poi_id: poiId,
        },
    };
};

export const cardNameToSplunkFormat: Record<CardMethodNames, string> = {
    tta: 'True Trade Area',
    drive_time_ta: 'Drive Time',
    nearby_entity_ta: 'Nearby property',
    tta_nearby_combination: 'Nearby property',
};

type CreateMethodSelectorSplunkDataProps = {
    poiSelectionSplunkData: PoiSelectionSplunkData;
    poiId: string;
    method?: CardMethodNames;
};

export const createMethodSelectorSplunkData = ({
    poiSelectionSplunkData,
    poiId,
    method,
}: CreateMethodSelectorSplunkDataProps): PartialSplunkDataShape => {
    const splunkShape = convertToSplunkFormat(poiSelectionSplunkData, poiId);
    return {
        ...splunkShape,
        view_name: 'Void Method Popup',
        component: 'Void Method Popup',
        report_config: {
            ...splunkShape.report_config,
            ...(method && { method: cardNameToSplunkFormat[method] }),
        },
    };
};

export const useSendEntryPointSplunkEvent = () => {
    const sendSplunk = useSplunkCallback<PartialSplunkDataShape>();

    return useCallback(
        (splunkData: PartialSplunkDataShape) => {
            sendSplunk({
                action: 'open report',
                value: 'run-void-analysis',
                ...splunkData,
            });
        },
        [sendSplunk],
    );
};
