export const ASSIST_SIDEBAR_ID = 'assist-side-bar';
export const ASSIST_STATE_CHANGE_EVENT = 'assist-bar-state-change';
const ASSIST_CONTAINER_SELECTOR = '.pl-app-container';
const ASSIST_ACTIVE_CLASS_NAME = 'assist-side-bar-active';

export const getAssistBarContainer = () => window.document.getElementById(ASSIST_SIDEBAR_ID);

export const addAssistBarElement = () => {
    const container = window.document.querySelector(ASSIST_CONTAINER_SELECTOR);
    const assistSideBarElement = getAssistBarContainer();
    if (container && !assistSideBarElement) {
        const assistSideBar = document.createElement('div');
        assistSideBar.id = ASSIST_SIDEBAR_ID;
        // set assist side bar y scroll position to be the same as the main container
        container.appendChild(assistSideBar);
        container.classList.add(ASSIST_ACTIVE_CLASS_NAME);
        document.dispatchEvent(new Event(ASSIST_STATE_CHANGE_EVENT));
    }
};

export const removeAssistBarElement = () => {
    const scrollTo = window.document.querySelector('.admin-panel')!?.scrollTop;
    const container = window.document.querySelector('#admin-panel-container');
    const assistSideBarElement = getAssistBarContainer();
    if (container && assistSideBarElement) {
        container.classList.remove(ASSIST_ACTIVE_CLASS_NAME);
        container.removeChild(assistSideBarElement);
        document.dispatchEvent(new Event(ASSIST_STATE_CHANGE_EVENT));
    }

    const HtmlElement = window.document.querySelector('html') as HTMLHtmlElement;
    if (HtmlElement) {
        HtmlElement.scrollTo(0, scrollTo);
    }
};
