import { useCallback } from 'react';
import { userAPI } from 'API/user-api';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/app-store-hooks';

import { userCustomSettingsSelector } from 'store/selectors/app-selectors';
import { updateUserCustomSettingsActionCreator } from 'store/auth/actions/auth-action-creators';
import {
    RankingsSortType,
    RequestUserSettings,
    RequestUserSettingsFilterArray,
    RequestUserSettingsObject,
    RequestUserSettingForReportId,
} from '../types/ranking-table';

const defaultSorting: RankingsSortType = {
    order_by_field: 'expansion_in_country',
    order: 'asc',
};

export const useSettingsCallback = () => {
    const dispatch = useAppDispatch();
    const customUserSettings = useSelector(userCustomSettingsSelector);

    return useCallback(
        (id: string, userSettings: RequestUserSettings) => {
            const settings = userSettings as RequestUserSettingsObject;
            const requestFilters =
                settings.requestFilters || (settings as RequestUserSettingsFilterArray);
            const useDefaultFilters = settings.useDefaultFilters;
            const showClearAll = settings.showClearAll;
            const requestSorting = settings?.requestSorting || defaultSorting;

            const objectInfoForId: RequestUserSettingForReportId = {
                requestFilters,
                useDefaultFilters,
                showClearAll,
            };

            const voidAnalysis = {
                ...customUserSettings?.voidAnalysis,
                [id]: {
                    ...objectInfoForId,
                },
                columnsInfo: {
                    requestColumns: settings?.requestColumns,
                    editTableColumnsSorted: settings?.editTableColumnsSorted,
                },
                sortBy: { ...requestSorting },
            };

            userAPI.setUserCustomSettings({ voidAnalysis });

            dispatch(
                updateUserCustomSettingsActionCreator({
                    key: 'voidAnalysis',
                    value: voidAnalysis,
                }),
            );
        },
        [dispatch, customUserSettings?.voidAnalysis],
    );
};

export const useVoidRequest = (reportId: string) => {
    const customUserSettings = useSelector(userCustomSettingsSelector);
    return customUserSettings?.voidAnalysis?.[reportId];
};

export const useVoidRequestColumnsInfo = () => {
    const customUserSettings = useSelector(userCustomSettingsSelector);
    return customUserSettings?.voidAnalysis?.columnsInfo;
};

export const useVoidRequestRankingsTableSortBy = () => {
    const customUserSettings = useSelector(userCustomSettingsSelector);
    return customUserSettings?.voidAnalysis?.sortBy;
};
