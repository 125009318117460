import styles from './table-scroll-arrows.module.scss';
import { ChevronCircleIcon } from 'components/assets/Icons/Icons';
import React from 'react';
import classnames from 'classnames';

export interface PoiScrollPosition {
    start: number;
    end: number;
}

type TableScrollArrowsProps = {
    columnsCount: number;
    scrollPosition?: PoiScrollPosition;
    onScrollRight?: () => void;
    onScrollLeft?: () => void;
    className?: string;
};

export const TableScrollArrows = ({
    columnsCount,
    scrollPosition,
    onScrollRight,
    onScrollLeft,
    className,
}: TableScrollArrowsProps) => {
    return (
        <div className={classnames(styles.scrollButtonsWrapper, className)}>
            {scrollPosition && scrollPosition.start > 0 && (
                <div className={styles.leftScrollButton}>
                    <ChevronCircleIcon
                        data-testid="table-scroll-arrow-left"
                        onClick={onScrollLeft}
                    />
                </div>
            )}

            {scrollPosition && scrollPosition.end < columnsCount && (
                <div className={styles.rightScrollButton}>
                    <ChevronCircleIcon
                        data-testid="table-scroll-arrow-right"
                        onClick={onScrollRight}
                    />
                </div>
            )}
        </div>
    );
};
