import React from 'react';

import { PoiSelectionMenuItem } from 'features/poi-selection-map/types/poi-selection-types';
import { SearchPoiContext } from 'features/poi-selection-map/poi-selection-menu/menu-items/search-menu-item/context/search-poi-context';
import { SearchPoi } from 'features/poi-selection-map/poi-selection-menu/menu-items/search-menu-item/search-poi';

export const SEARCH_ITEM_ID = 'search menu item';
export const SEARCH_BAR_TEXT = 'Search by name or address';

export const SearchMenuItem: PoiSelectionMenuItem = {
    id: SEARCH_ITEM_ID,
    title: 'Search',
    render: ({ visible }) => {
        return <SearchPoiContext>{visible && <SearchPoi />}</SearchPoiContext>;
    },
    config: {
        searchBarText: SEARCH_BAR_TEXT,
    },
};
