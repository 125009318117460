import { createSelector } from '@reduxjs/toolkit';

import { selectChainsUiState } from 'features/industry/store/common/selectors';

import {
    CHAIN_SEARCH_TABS,
    ChainSearchTabsTypes,
} from 'features/industry/store/chains-report-search-store/models/chains-search-tabs';
import { userPermissionsSelector } from 'store/selectors/app-selectors';

export const selectChainsReportSearchSection = createSelector(
    selectChainsUiState,
    (uiState) => uiState.search,
);

export const selectChainsSearchIsOpen = createSelector(
    selectChainsReportSearchSection,
    (chains) => chains.isOpen,
);

const CLOSED_SEARCH_TABS = [CHAIN_SEARCH_TABS.ADD_CHAIN];
export const selectChainsSearchTabs = createSelector(
    selectChainsSearchIsOpen,
    userPermissionsSelector,
    (searchIsOpen) => {
        if (!searchIsOpen) {
            return CLOSED_SEARCH_TABS;
        }
        const tabs: ChainSearchTabsTypes[] = [CHAIN_SEARCH_TABS.ADD_CHAIN];

        return tabs;
    },
);

export const selectChainsReportSearchSuggestedBrands = createSelector(
    selectChainsReportSearchSection,
    (search) => search.suggestedBrands,
);

export const selectChainsReportSearchAutoCompleteValue = createSelector(
    selectChainsReportSearchSection,
    (search) => search.autoCompleteValue,
);

export const selectChainsReportSearchSearchTerm = createSelector(
    selectChainsReportSearchSection,
    (search) => search.term,
);

export const selectPotentiallyMoreSearchResultsExist = createSelector(
    selectChainsReportSearchSection,
    (search) => search.potentiallyMoreSearchResultsExist,
);

export const selectChainsReportSearchResultsEntityModels = createSelector(
    selectChainsReportSearchSection,
    (search) => search.chainResultsEntityModels,
);

export const selectChainsReportOptions = createSelector(
    selectChainsUiState,
    (uiState) => uiState.reportUiState.reportOptions,
);
