import { selectorType, useSelectorWithProps } from 'utils/structured-selector/structured-selector';

interface PropsWithID {
    id: string;
}

/**
 * Added an ability to pass props to the selector
 * @typeparam P - type for input - props
 * @typeparam R - type for result
 */
export const useSelectorWithWidgetId = <R = any>({id}: PropsWithID, selector: selectorType<PropsWithID, R>) => {
    return useSelectorWithProps({id}, selector);
};
