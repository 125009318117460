import React from 'react';
import styles from './entity-dropdown-select.module.scss';
import { Select } from 'antd';
import type { Place } from '@placer-ui/types';
import classNames from 'classnames';
import { formatFullAddress } from 'utils/address-formatter/address-formatter';

const Option = Select.Option;

type EntityDropdownSelectProps = {
    value: Place;
    options: Place[];
    onChange: (entity: Place) => void;
    multiLine?: boolean;
};

export const EntityDropdownSelect = ({
    value,
    options,
    onChange,
    multiLine = true,
}: EntityDropdownSelectProps) => {
    const onEntityChange = (entityId: string) => {
        const selectedPlace = options.find(({ id }) => id === entityId)!;
        onChange(selectedPlace);
    };

    return (
        <div
            className={classNames(styles.selectContainer, {
                [styles.multiLine]: multiLine,
            })}
        >
            <Select
                onChange={onEntityChange}
                dropdownClassName={styles.dropdown}
                value={value.id}
                dropdownMatchSelectWidth={false}
                defaultValue={options[0].id}
            >
                {options.map((place) => (
                    <Option value={place.id} key={place.id} className={styles.option}>
                        <div data-testid={`option-${place.id}`} className={styles.optionContainer}>
                            <div
                                className={classNames(styles.optionTitle, {
                                    [styles.multiLine]: multiLine,
                                })}
                            >
                                <div className={styles.entityName}>{place.name}</div>
                                {formatFullAddress(place.type, place) && (
                                    <div>{`${!multiLine ? ' / ' : ''}${formatFullAddress(
                                        place.type,
                                        place,
                                    )}`}</div>
                                )}
                            </div>
                        </div>
                    </Option>
                ))}
            </Select>
        </div>
    );
};
