// todo: remove file when placer-ui-components is ready and imported

const DEFAULT_ICON_SIZE = '18px';
const ICONS_COLOR_MAPPING: Record<MainIconColors, string> = {
    blue: 'var(--light-primary)',
    grey: 'var(--graph-lines)',
    outline: 'var(--graph-lines)',
    disabled: 'var(--disabled)',
    white: 'var(--basic-white)',
    'primary-brand': 'var(--brand-color-primary)',
    'icon-outline': 'var(--primary-gray)',
};
export const MAIN_ICONS_FILL_COLORS = ['blue', 'grey', 'outline'];
export const MAIN_ICONS_OUTLINE_COLORS = ['white', 'disabled', 'primary-brand', 'icon-outline'];

type MainIconOutlineColors = typeof MAIN_ICONS_OUTLINE_COLORS[number];
type MainIconFillColors = typeof MAIN_ICONS_FILL_COLORS[number];
type MainIconColors = MainIconFillColors | MainIconOutlineColors;
export type MainIconProps = {
    color?: MainIconFillColors;
    outline?: MainIconOutlineColors;
    size?: string;
};

export function getMainIconProps({
    color: fillColor,
    outline: outlineColor,
    size = DEFAULT_ICON_SIZE,
}: MainIconProps) {
    const outline =
        fillColor === 'outline' && outlineColor
            ? ICONS_COLOR_MAPPING[outlineColor]
            : ICONS_COLOR_MAPPING['icon-outline'];
    const fill = fillColor && fillColor !== 'outline' ? ICONS_COLOR_MAPPING[fillColor] : undefined;

    return {
        outline,
        fill,
        size,
    };
}
