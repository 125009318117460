import React, { useState } from 'react';
import { SearchIcon } from 'components/assets/Icons/Icons';
import { Input } from 'antd';
import styles from './search-input.module.scss';
import { useOnSearchTermChanged } from 'features/void-analysis/sections/add-new-report/hooks/add-new-report-hooks';

const placeholderText = 'Search by Name, Address or Coordinates…';

export const SearchInput = () => {
    const onSearchTermChange = useOnSearchTermChanged();
    const [inputVal, setInputVal] = useState<string>('');

    const handleChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        setInputVal(value);
        onSearchTermChange(value);
    };

    return (
        <div className={styles.wrapper}>
            <Input
                type="text"
                className={styles.searchInput}
                bordered={false}
                allowClear={true}
                suffix={!inputVal && <div className={styles.icon}>{<SearchIcon />}</div>}
                value={inputVal}
                onChange={handleChange}
                placeholder={placeholderText}
                aria-label="search-input"
            />
        </div>
    );
};
