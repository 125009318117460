import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { Input } from 'antd';

import styles from './outer-search-input.module.scss';
import { ClearSearchIcon, SearchIcon } from 'components/assets/Icons/Icons';
import { useDebouncedCallback } from 'use-debounce';

type OuterSearchInputProps = {
    onChange: (value: string) => void;
    onCNP?: () => void;
    placeholder?: string;
    debounceMs?: number;
    className?: string;
    wrapperClassName?: string;
    focusClassName?: string;
    suffixClass?: string;
    inputValue?: string;
    onFocus?: () => void;
    disabled?: boolean;
};

export const OuterSearchInput = ({
    onChange,
    onCNP,
    placeholder = 'Search...',
    debounceMs = 0,
    className,
    wrapperClassName,
    focusClassName,
    inputValue,
    suffixClass,
    onFocus,
    disabled,
}: OuterSearchInputProps) => {
    const [value, setValue] = useState<string>();
    const [hasFocus, setHasFocus] = useState(false);

    useEffect(() => {
        setValue(inputValue);
    }, [inputValue]);

    const debouncedOnChange = useDebouncedCallback(onChange, debounceMs);

    const handleChange = (searchValue: string) => {
        setValue(searchValue);
        debouncedOnChange(searchValue);
    };

    if (hasFocus && onFocus) {
        onFocus();
    }

    const clearSearchIconOnClick = () => {
        handleChange('');
    };

    return (
        <div
            className={classnames(styles.wrapper, wrapperClassName, {
                [styles.disabled]: disabled,
            })}
        >
            <Input
                data-testid="outer-search-input-test-id"
                type="text"
                role="search-bar"
                className={classnames(styles.searchInput, className, {
                    [styles.focus]: hasFocus && !focusClassName,
                    ...(focusClassName && { [focusClassName]: hasFocus }),
                })}
                disabled={disabled}
                bordered={false}
                suffix={
                    <div className={classnames(styles.icon, suffixClass)}>
                        {value ? (
                            <ClearSearchIcon
                                className={styles.clearSearchIcon}
                                onClick={clearSearchIconOnClick}
                                data-testid="outer-search-input-clear-search-icon-test-id"
                            />
                        ) : (
                            <SearchIcon className={styles.searchIcon} />
                        )}
                    </div>
                }
                onChange={({ target: { value } }) => handleChange(value)}
                placeholder={placeholder}
                value={value}
                onFocus={() => setHasFocus(true)}
                onBlur={() => setHasFocus(false)}
                onPaste={onCNP}
                onPasteCapture={onCNP}
            />
        </div>
    );
};
