import { history } from 'router/history';
import { VenueFilter } from 'core/entities/venue/venueAdminFilter';
import { placeTypeToPlaceCollection } from 'utils/place-type-collection-conversion/place-type-collection-conversion';
import type { FetchReportParams } from 'features/insights/store/actions/fetch-report/types';
import type { PoiDatesRecord } from 'features/insights/types/dates-types';

export const updateURL = (
    entitiesFromURL: FetchReportParams['entitiesFromURL'],
    withoutCompetitor: FetchReportParams['withoutCompetitor'],
    defaultDates: PoiDatesRecord | undefined,
) => {
    const search = new URLSearchParams();

    let competitor = withoutCompetitor ? '[]' : undefined;
    if (entitiesFromURL.length > 1) {
        const [, ...competitors] = entitiesFromURL;
        competitor = JSON.stringify(
            competitors.map(({ id, type }) => ({
                id,
                collection: placeTypeToPlaceCollection(type),
            })),
        );
    }

    if (competitor) {
        search.append('competitor', competitor);
    }

    VenueFilter.dates = defaultDates!;
    const filter = JSON.stringify(entitiesFromURL.map(({ filter }) => new VenueFilter(filter)));
    search.append('filter', filter);
    history.replace({
        search: search.toString(),
        state: history.location.state,
    });
};
