/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import { TagsSelectionTableItem } from 'features/poi-selection-map/types/poi-selection-types';
import { StyledTable } from 'features/poi-selection-map/poi-selection-menu/shared-components/styled-table/styled-table';
import { usePoiSelectionContext } from 'features/poi-selection-map/context/poi-selection-context';
import type { Tag } from '@placer-ui/types';
import { TagSelectorType } from 'features/poi-selection-map/poi-selection-menu/menu-items/tags-menu-items/tags-selector-menu/tags-selector-menu';
import {
    getTagPropertyCount,
    useTagsSelectionColumns,
} from 'features/poi-selection-map/poi-selection-menu/menu-items/tags-menu-items/my-tags-menu-item/hooks/tag-selection-hooks';
import { SearchResults } from 'features/poi-selection-map/poi-selection-menu/shared-components/search-results/search-results';
import { PoiSelectionLoader } from 'features/poi-selection-map/poi-selection-menu/shared-components/poi-selection-loader/poi-selection-loader';
import type { Count } from 'types/tag';

type TagSelectorTableProps = {
    type: TagSelectorType;
    onTagSelected: (tagId: string) => void;
    visible: boolean;
    className?: string;
};
export const TagsSelectorTable = ({
    type,
    onTagSelected,
    className,
    visible,
}: TagSelectorTableProps) => {
    const { sharedState } = usePoiSelectionContext();
    const columnsConfiguration = useTagsSelectionColumns();
    const [searchTerm, setSearchTerm] = useState<string>('');
    const tags: Tag[] | undefined = sharedState[type];

    const dataSource = useMemo(
        () =>
            tags?.reduce<TagsSelectionTableItem[]>((acc, tag) => {
                const count = getTagPropertyCount(tag.count as Count);
                const isSearchTermFiltered = searchTerm
                    ? tag.name.toLowerCase().includes(searchTerm.toLowerCase())
                    : true;
                if (count > 0 && isSearchTermFiltered) {
                    acc.push({
                        title: tag.name,
                        /* disabled until tags API returns true # of properties */
                        // subtitle: `${getTagCount(tag.count).toString()} Properties`,
                        id: tag.id,
                        key: tag.id,
                    });
                }
                return acc;
            }, []),
        [searchTerm, tags],
    );

    const onRowMouseEvents = ({ id }: TagsSelectionTableItem, index?: number) => {
        return {
            onClick: () => {
                onTagSelected(id);
            },
            index,
        };
    };

    useEffect(() => {
        if (!visible) {
            setSearchTerm('');
        }
    }, [visible]);

    return (
        <div className={classNames(className)}>
            <PoiSelectionLoader show={!dataSource}>
                <SearchResults
                    onSearchChange={setSearchTerm}
                    title={'Search tag name'}
                    displayType={'large transparent'}
                    inputValue={searchTerm}
                />
                <StyledTable<TagsSelectionTableItem>
                    dataSource={dataSource!}
                    columns={columnsConfiguration}
                    pagination={false}
                    onRow={onRowMouseEvents}
                    emptyState={{
                        title: 'No Tags Found',
                        description: ' ',
                    }}
                />
            </PoiSelectionLoader>
        </div>
    );
};
