import { SwitchItem } from 'ui-components/dropdown-with-switches/dropdown-with-switches';

export const getCheckedItems = (items: SwitchItem<string>[]) => {
    return items.reduce<string[]>((acc, item) => {
        if (item.checked) {
            acc.push(item.id);
        }
        return acc;
    }, []);
};
