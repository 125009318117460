import { BBox } from 'geojson';

export const getBoundingBoxByRadius = (miles: number, lng: number, lat: number): BBox => {
    const radiusInMeters = miles * 1609.34;
    const center = new google.maps.LatLng(lat, lng);
    const y1 = google.maps.geometry.spherical.computeOffset(center, radiusInMeters, 0).lat();
    const y2 = google.maps.geometry.spherical.computeOffset(center, radiusInMeters, 180).lat();

    const x1 = google.maps.geometry.spherical.computeOffset(center, radiusInMeters, -90).lng();
    const x2 = google.maps.geometry.spherical.computeOffset(center, radiusInMeters, 90).lng();

    return [Math.min(x1, x2), Math.min(y1, y2), Math.max(x1, x2), Math.max(y1, y2)];
};

export const convertBoundingBoxToBounds = (boundingBox: BBox) => ({
    south: boundingBox[1],
    north: boundingBox[3],
    west: boundingBox[0],
    east: boundingBox[2],
});

export const getCoordinates = (boundingBox: BBox) => {
    const { north, south, west, east } = convertBoundingBoxToBounds(boundingBox);

    return {
        ne: [north, east],
        sw: [south, west],
    };
};

export const getCoordinatesAsStrings = (boundingBox: BBox) => {
    const { ne, sw } = getCoordinates(boundingBox);
    return {
        ne: ne.join(','),
        sw: sw.join(','),
    };
};
