import { createAsyncThunk } from '@reduxjs/toolkit';

import { API } from 'API';
import { UpdateReportArgs } from 'API/star-report-api';

export const updateStarredReport = createAsyncThunk(
    'starred-report/update',
    async (report: UpdateReportArgs, { rejectWithValue }) => {
        try {
            await API.starReport.update(report);

            return report;
        } catch {
            // TODO: star-report https://placer.atlassian.net/browse/PLAC-10694
            return rejectWithValue('TODO');
        }
    },
);
