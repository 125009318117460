import { FailureResponse } from 'store/auth/actions/auth-actions/auth-action-types';
import { BACKEND_ERROR_CODES_TO_REPORT, BACKEND_UNKNOWN_ERROR_CODE } from 'features/authentication/utils/constants';

export class AuthError extends Error {
    readonly status: Response['status'];
    readonly error_code: number;
    readonly details: { account: string };
    readonly result: string;
    readonly shouldForceSso: boolean;

    constructor({
        status,
        error_code,
        details,
        result,
        error,
        shouldForceSso,
    }: FailureResponse & { status: Response['status']; shouldForceSso: boolean }) {
        super(error);
        this.name = 'AuthError';
        this.status = status;
        this.error_code = error_code;
        this.details = details;
        this.result = result;
        this.shouldForceSso = shouldForceSso;
    }

    shouldReportToSentry() {
        return BACKEND_ERROR_CODES_TO_REPORT.includes(this.status);
    }

    static fromUnknownError(error: unknown) {
        if (error instanceof AuthError) {
            return error;
        }

        return new AuthError({
            status: BACKEND_UNKNOWN_ERROR_CODE,
            error_code: 0,
            details: { account: '' },
            result: '',
            error: error instanceof Error ? error.message : String(error),
            shouldForceSso: false,
        });
    }
}
