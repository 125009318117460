import { SerializedError } from '@reduxjs/toolkit';
import {
    PROPERTY,
    REPORT_TYPE_TO_ENTITY_TYPE,
    ReportTypes,
    ReportTypeToEntityType,
    INDUSTRY,
} from 'core/constants/report-type';
import { ReportEntity } from 'core/entities/report/report-entity';
import { PoiDatesRecord } from 'features/insights/types/dates-types';

export interface StarredReport {
    name: string;
    type: ReportTypes;
    section: string;
    last_viewed_date: string;
    entities: ReportEntity[];
}

export type ReportState = {
    isLoading: boolean;
    dates?: PoiDatesRecord;
    error?: SerializedError;
};

export const isPropertyReport = (report: StarredReport) => report.type === PROPERTY;

export const isIndustryReport = (report: StarredReport) => report.type === INDUSTRY;

export const getEntityTypesByReportType = <ReportType extends keyof ReportTypeToEntityType>(
    reportType: ReportType,
) => {
    return REPORT_TYPE_TO_ENTITY_TYPE[reportType];
};
