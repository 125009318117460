import { createSelector } from '@reduxjs/toolkit';

import { selectChainsSearchSelectedCategory } from '../select-chains-search-selected-category';
import { selectChainsSearchSelectedRegionName } from '../select-chains-search-selected-region-name';
import { selectChainsReportSearchSearchTerm } from '../select-chains-search-section';

export const selectChainsSearchCriteriaIsEmtpy = createSelector(
    selectChainsSearchSelectedRegionName,
    selectChainsSearchSelectedCategory,
    selectChainsReportSearchSearchTerm,
    (region, category, term) => !region && !category && !term,
);
