/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

type CoverageIconUniqueProps = {
    variant: 'check' | 'exclamation';
    width?: number | string;
    height?: number | string;
    strokeColor?: string;
    shieldColor?: string;
    symbolColor?: string;
};

type CoverageIconProps = SVGProps<SVGSVGElement> & CoverageIconUniqueProps;

export const CoverageIcon = function CoverageIcon({
    variant,
    width = 18,
    height = 18,
    strokeColor = 'var(--primary-gray)',
    shieldColor = 'var(--primary-gray)',
    symbolColor = 'white',
    ...props
}: CoverageIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.6636e-06 10.8639V6.34058L5.99999 4L12 6.34058V10.8055C12.0011 12.152 12.0055 15.5553 5.99999 18C-0.00549603 15.5553 -0.00109145 12.152 9.6636e-06 10.8639Z"
                fill={shieldColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.2496 13.9961C15 13.8647 18 10.7829 18 7.00049C18 3.1345 14.866 0.000488281 11 0.000488281C7.97056 0.000488281 5.3906 1.9249 4.41589 4.61794L5.3926 4.23693C6.15014 2.70277 7.51875 1.52431 9.18054 1.01945C8.49887 1.85068 7.97045 3.13495 7.69985 4.66309L6.00005 4L3.16914e-05 6.34058L3.08694e-05 10.8648C-0.00107125 12.1537 -0.00398064 15.5559 6.00005 18C8.99889 16.7793 10.4992 15.3195 11.2496 13.9961ZM11.689 13.0191C12.0576 12.7765 12.4467 12.3295 12.804 11.6148C13.3361 10.5507 13.6978 9.0629 13.7448 7.37512H12.0001V10.8066C12.0005 11.3098 12.0011 12.0996 11.689 13.0191ZM12.0001 6.62512V6.34058L8.41322 4.94137C8.57537 3.9512 8.84956 3.07888 9.19592 2.38615C9.78944 1.19913 10.4706 0.750488 11 0.750488C11.5294 0.750488 12.2105 1.19913 12.804 2.38615C13.336 3.45009 13.6977 4.93764 13.7448 6.62512H12.0001ZM14.4951 7.37512C14.4324 9.75147 13.7772 11.8136 12.8194 12.9815C15.2693 12.2372 17.082 10.0289 17.2389 7.37512H14.4951ZM17.2389 6.62512H14.495C14.4323 4.24909 13.7771 2.18721 12.8194 1.01945C15.2691 1.76368 17.0817 3.97169 17.2389 6.62512ZM1.39946 13.6274C0.751437 12.4882 0.749507 11.4825 0.750036 10.8645V6.85305L6.00005 4.80505L11.2501 6.85305V10.8061C11.2506 11.4558 11.2481 12.4756 10.5998 13.6216C9.97477 14.7264 8.6942 16.0392 6.00005 17.1876C3.30587 16.0392 2.02458 14.7262 1.39946 13.6274Z"
                fill={strokeColor}
            />
            {variant === 'exclamation' ? (
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.87012 7.40002L6.61512 12.2235H5.39512L5.14012 7.40002H6.87012ZM6.64012 14.5267C6.48678 14.6764 6.27345 14.7513 6.00012 14.7513C5.72012 14.7513 5.50345 14.6781 5.35012 14.5316C5.19678 14.3851 5.12012 14.1786 5.12012 13.912C5.12012 13.6355 5.19512 13.4265 5.34512 13.285C5.49512 13.1435 5.71345 13.0727 6.00012 13.0727C6.27678 13.0727 6.49095 13.1451 6.64262 13.2899C6.79428 13.4348 6.87012 13.6421 6.87012 13.912C6.87012 14.172 6.79345 14.3769 6.64012 14.5267Z"
                    fill={symbolColor}
                />
            ) : (
                <path
                    d="M9.12362 9.4536C9.31885 9.25831 9.3188 8.94173 9.12351 8.74649C8.92822 8.55126 8.61164 8.5513 8.41641 8.7466L5.33996 11.8239L4.05357 10.5375C3.8583 10.3423 3.54172 10.3423 3.34646 10.5375C3.1512 10.7328 3.1512 11.0494 3.34646 11.2447L5.34006 13.2383L9.12362 9.4536Z"
                    fill={symbolColor}
                />
            )}
        </svg>
    );
};
CoverageIcon.__tags = ['globe', 'shield', 'check', 'exclamation'] as string[];
