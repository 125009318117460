import React from 'react';
import styles from './tag-member-selector-table.module.scss';

import { PoiSelectionTable } from 'features/poi-selection-map/poi-selection-menu/shared-components/poi-selection-table/poi-selection-table';
import classNames from 'classnames';
import { SelectArrowIcon } from 'components/assets/Icons/Icons';

type TagMemberSelectorTableProps = {
    onBackNavigation: () => void;
    selectedTagName: string;
    visible: boolean;
    className?: string;
};

export const TagMemberSelectorTable = ({
    className,
    onBackNavigation,
    visible,
    selectedTagName,
}: TagMemberSelectorTableProps) => {
    return (
        <div className={classNames(className)}>
            <div className={styles.backNavigation} onClick={onBackNavigation}>
                <SelectArrowIcon className={styles.arrowIcon} />
                {selectedTagName}
            </div>
            {visible && (
                <PoiSelectionTable
                    tableProps={{
                        emptyState: {
                            title: 'No Tags Found',
                            description:' ',
                        },
                    }}
                />
            )}
        </div>
    );
};
