import React from 'react';
import classNames from 'classnames';
import styles from '../v2/venue-status-indicator.module.scss';
import { Tooltip } from 'ui-components/tooltip/tooltip';
import { AlertColoredIcon } from 'components/assets/Icons/Icons';
import { FlaggedVenueIndicatorProps } from 'ui-components/venue-status-indicator/venue-status-indicator';
import { useCheckVenueStatus } from 'features/mobile-report/components/explore-mobile/hooks/use-check-venue-status';
import { useIsRestrictedPoi } from 'hooks/use-restricted-poi/use-restricted-poi';
import {
    getReportEntityFlag,
    getReportEntityClosingDate,
} from 'core/services/report-entities-service/report-entities-service';
import { useCheckPoiForPropertyReport } from 'features/mobile-report/components/explore-mobile/hooks/use-check-for-property-report';

type VenueStatusIndicatorV3Props = Pick<FlaggedVenueIndicatorProps, 'poi'>;

export const VenueStatusIndicatorV3 = ({ poi }: VenueStatusIndicatorV3Props) => {
    const checkVenueStatus = useCheckVenueStatus();
    const isRestrictedPoiCallback = useIsRestrictedPoi();
    const checkPoiForPropertyReport = useCheckPoiForPropertyReport();

    if (!poi) return null;

    const { text, disabled } = checkPoiForPropertyReport(poi);
    const status = checkVenueStatus(poi);

    const isRestricted = isRestrictedPoiCallback(poi);
    const flag = getReportEntityFlag(poi);
    const closingDate = getReportEntityClosingDate(poi);

    if (!flag && !closingDate && !isRestricted && !disabled) {
        return null;
    }

    const isFlagged = !!flag;
    const isClosed = !!closingDate;
    const isPrivacyFlag = flag === 'privacy_concerns';

    const icon = (
        <AlertColoredIcon
            data-testid="venue-status-indicator"
            className={classNames({
                [styles.isClosedIcon]: isClosed,
                [styles.isPrivacyFlagIcon]: isPrivacyFlag,
                [styles.isFlaggedIcon]: isFlagged,
                [styles.isRestrictedIcon]: isRestricted,
            })}
        />
    );

    let tooltipText = text;
    if (status) tooltipText = `${status} - ${text}`;

    return (
        <Tooltip small title={tooltipText} placement="top">
            <div
                className={classNames(styles.statusIndicator, {
                    [styles.isClosed]: isClosed,
                    [styles.isPrivacyFlag]: isPrivacyFlag,
                    [styles.isFlagged]: isFlagged,
                    [styles.isRestricted]: isRestricted,
                })}
            >
                {icon}
            </div>
        </Tooltip>
    );
};
