import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectHouseholdIncomeAttributesFilterMappedToOptions } from 'store/selectors/app-selectors';

export const useCreateHouseholdIncomeOptions = () => {
    const hiRanges = useSelector(selectHouseholdIncomeAttributesFilterMappedToOptions);

    return useMemo(() => {
        return Object.entries(hiRanges).map(([value, name]) => {
            return {
                value,
                name,
                selected: false,
            };
        });
    }, [hiRanges]);
};
