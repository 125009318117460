import React from 'react';
import cn from 'classnames';
import { useLocation, useHistory } from 'react-router-dom';
import {
    AnalyticsIcon,
    IconProps,
    MapViewMobileIcon,
    SettingsMobileIcon,
} from 'components/assets/Icons/IconsMobile';
import type { RoutesType } from 'features/mobile-report/types/types';
import { footerStateDirectionSignal } from 'features/mobile-report/state/mobile-report.signals';
import { showAnalyzeBackArrowSignal } from 'features/mobile-report/components/analyze-page/state/analyze.signals';
import { useGoToExplore } from 'features/mobile-report/hooks/use-go-to-explore';
import { getActiveRoute } from 'features/mobile-report/utils/route-utils';
import { useGoToAnalyze } from 'features/mobile-report/components/analyze-page/hooks/use-go-to-analyze';

import styles from './buttons-footer.module.scss';
import globalStyles from 'src/react.module.scss';

interface IFooterItem {
    icon: (props: IconProps) => JSX.Element;
    text: string;
    route: RoutesType;
    callback: () => void;
    disabled?: boolean;
}

interface ButtonsFooterProps {
    isDisabled?: boolean;
}

export const ButtonsFooter = ({ isDisabled }: ButtonsFooterProps) => {
    const location = useLocation();
    const activeRoute = getActiveRoute(location.pathname);
    const history = useHistory();
    const goToExplore = useGoToExplore();
    const goToAnalyze = useGoToAnalyze();

    const handleExploreClick = () => {
        showAnalyzeBackArrowSignal.value = true;
        goToExplore();
    };

    const handleAnalyzeClick = () => {
        showAnalyzeBackArrowSignal.value = false;
        goToAnalyze();
    };

    const handleSettingsClick = () => {
        history.push('/settings');
    };

    const items: IFooterItem[] = [
        {
            icon: MapViewMobileIcon,
            text: 'Explore',
            route: 'explore',
            callback: handleExploreClick,
        },
        {
            icon: AnalyticsIcon,
            text: 'Analyze',
            route: 'insights',
            callback: handleAnalyzeClick,
        },
        {
            icon: SettingsMobileIcon,
            text: 'Settings',
            route: 'settings',
            callback: handleSettingsClick,
        },
    ];

    return (
        <div
            className={cn(styles.footer, {
                [styles.hidden]:
                    activeRoute === 'insights' && footerStateDirectionSignal.value === 'down',
            })}
        >
            <nav>
                <ul className={styles.list}>
                    {items.map((item) => {
                        const isActive = activeRoute === item.route && !isDisabled;
                        return (
                            <li
                                className={cn(styles.listItem, {
                                    [styles.active]: isActive,
                                    [styles.disabled]: item.disabled,
                                })}
                                key={item.text}
                                onClick={
                                    isActive || isDisabled ? undefined : () => item.callback?.()
                                }
                            >
                                <item.icon
                                    className={styles.icon}
                                    fill={
                                        isActive ? globalStyles.lightBlue : globalStyles.lightGray2
                                    }
                                />
                                <span
                                    className={cn(styles.text, {
                                        [styles.active]: activeRoute === item.route,
                                    })}
                                >
                                    {item.text}
                                </span>
                            </li>
                        );
                    })}
                    {!isDisabled && <li className={styles.sliderAnimation} />}
                </ul>
            </nav>
        </div>
    );
};
