/* eslint-disable quotes */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Select, Input } from 'antd';

import './consult-with-sales-form.scss';
import { userSelector } from 'store/selectors/app-selectors';
import { sendHubspotForm } from 'API/hubspot-api/hubspot-api';
import { useSplunkCallback } from 'hooks/use-splunk-callback/use-splunk-callback';
import { HubspotFormFields } from 'API/hubspot-api/types';
import { useSegmentCallback } from 'hooks/use-segment-callback/use-segment-callback';
import { reportException } from 'core/exceptions';

type ConsultWithSalesFormProps = {
    onSent: () => void;
    onCancel: () => void;
};

type OptionShape = {
    key: string;
    label: string;
};

type ContactSalesFormFields = 'email' | 'contact_sales_reason' | 'contact_sales_form_text';

const { Option } = Select;
const { TextArea } = Input;
const contactOptions = [
    {
        key: 'want-purchase-full-report',
        label: "I'd like to purchase the full report",
    },
    {
        key: 'want-upgrade-plan',
        label: "I'd like to upgrade my plan",
    },
    {
        key: 'want-to-learn-more',
        label: "I'd like to learn more about the Placer platform",
    },
    {
        key: 'other',
        label: 'Other',
    },
];

const useConsultWithSalesForm = () => {
    return {
        sendHubspotForm,
        user: useSelector(userSelector),
    };
};

export const ConsultWithSalesForm = ({ onSent, onCancel }: ConsultWithSalesFormProps) => {
    const [selectedOption, setSelectedOption] = useState<OptionShape>({
        key: 'empty',
        label: 'empty',
    });
    const [userText, setUserText] = useState('');
    const { user, sendHubspotForm } = useConsultWithSalesForm();
    const sendEvent = useSplunkCallback();
    const { track } = useSegmentCallback();

    const onHandleSelected = (value: string) => {
        const selected = contactOptions.find((item) => item.key === value);

        selected && setSelectedOption(selected);
    };

    const onSendForm = () => {
        const HS_FORM_ID = '824ecbbc-f500-466c-8082-1c149107a7fe';
        const text = userText.length ? userText : 'Not provided';
        const fields: HubspotFormFields<ContactSalesFormFields> = [
            {
                name: 'email',
                value: user?.email || '',
            },
            {
                name: 'contact_sales_reason',
                value: selectedOption.label,
            },
            {
                name: 'contact_sales_form_text',
                value: text,
            },
        ];

        sendHubspotForm<ContactSalesFormFields>(HS_FORM_ID, fields)
            .catch((error) => {
                reportException(error, {
                    payload: {
                        HS_FORM_ID,
                        ...fields,
                    },
                    method: 'POST',
                    // eslint-disable-next-line max-len
                    target_url: `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.REACT_APP_HUBSPOT_PORTAL_ID}/${HS_FORM_ID}`,
                });
            })
            .finally(onSent);

        track({
            category: 'Popup',
            action: 'Submit upgrade',
        });
        sendEvent({
            component: 'contact-sales-popup',
            action: 'button-click',
            btn_name: 'submit-sales-request',
            value: selectedOption.label,
        });
    };

    const onHandleText = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const {
            target: { value },
        } = event;

        setUserText(value);
    };

    const isValid = () => {
        if (selectedOption.key === 'empty') {
            return false;
        }

        if (selectedOption.key === 'other' && !userText.trim().length) {
            return false;
        }

        return true;
    };

    return (
        <div className="consult-with-sales-content">
            <section className="header-notice">
                <p>Hey {user?.fullName || user?.username || ''},</p>
                <p>For more features please upgrade your plan</p>
                <p>What would you like to talk about?</p>
            </section>

            <section className="upgrade-plan-dialog-body">
                <Select
                    placeholder="Select an option"
                    onChange={onHandleSelected}
                    className="consult-with-sales-options"
                    dropdownClassName="consult-with-sales-dropdown"
                >
                    {contactOptions.map((option) => (
                        <Option key={option.key} value={option.key}>
                            {option.label}
                        </Option>
                    ))}
                </Select>

                {selectedOption.key === 'other' && (
                    <TextArea
                        rows={4}
                        onChange={onHandleText}
                        className="consult-with-sales-user-text"
                        placeholder="Please write what would you like to talk about?"
                    />
                )}
            </section>

            <footer className="upgrade-plan-dialog-footer">
                <Button
                    className="dialog-confirm-button"
                    onClick={onSendForm}
                    disabled={!isValid()}
                >
                    Done
                </Button>
                <Button className="dialog-cancel-button" onClick={onCancel}>
                    Maybe Later
                </Button>
            </footer>
        </div>
    );
};
