/* eslint-disable max-len */
import React from 'react';

export function WeatherLightRainIcon() {
    return (
        <svg width="70" height="54" viewBox="0 0 70 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M26.646 36.9158L60.3979 37H60.3997C64.6023 36.999 67.9999 33.602 67.9999 29.423C67.9999 25.2428 64.6012 21.8455 60.3979 21.8455H57.6612L57.5014 20.0199C56.6348 10.1227 48.2969 2 38.253 2C31.468 2 25.4995 5.48523 22.0502 10.7643C25.6429 11.9082 28.7151 14.2214 30.8214 17.2551C31.1364 17.7088 31.0239 18.3319 30.5703 18.6469C30.1166 18.9619 29.4935 18.8494 29.1785 18.3958C26.9639 15.2059 23.5448 12.9235 19.5835 12.2086C18.7228 12.0546 17.8352 11.9739 16.927 11.9739C15.3544 11.9739 13.839 12.2162 12.4161 12.6654C6.38919 14.5768 2.01589 20.2206 2 26.8931C2.00925 30.757 3.49312 34.2731 5.92549 36.9158H26.646Z" fill="#D0D0D0"/>
<path d="M23.5908 39.6213C24.3522 39.9476 24.7049 40.8294 24.3786 41.5909L21.3786 48.5909C21.0523 49.3523 20.1705 49.705 19.409 49.3787C18.6476 49.0524 18.2948 48.1706 18.6212 47.4091L21.6212 40.4091C21.9475 39.6477 22.8293 39.2949 23.5908 39.6213Z" fill="#6FA2CE"/>
<path d="M38.3786 41.5909C38.7049 40.8294 38.3522 39.9476 37.5908 39.6213C36.8293 39.2949 35.9475 39.6477 35.6212 40.4091L32.6212 47.4091C32.2948 48.1706 32.6476 49.0524 33.409 49.3787C34.1705 49.705 35.0523 49.3523 35.3786 48.5909L38.3786 41.5909Z" fill="#6FA2CE"/>
<path d="M45.3786 41.5909C45.7049 40.8294 45.3522 39.9476 44.5908 39.6213C43.8293 39.2949 42.9475 39.6477 42.6212 40.4091L38.1212 50.9091C37.7948 51.6706 38.1476 52.5524 38.909 52.8787C39.6705 53.205 40.5523 52.8523 40.8786 52.0909L45.3786 41.5909Z" fill="#6FA2CE"/>
<path d="M31.3786 41.5909C31.7049 40.8294 31.3522 39.9476 30.5908 39.6213C29.8293 39.2949 28.9475 39.6477 28.6212 40.4091L24.1212 50.9091C23.7948 51.6706 24.1476 52.5524 24.909 52.8787C25.6705 53.205 26.5523 52.8523 26.8786 52.0909L31.3786 41.5909Z" fill="#6FA2CE"/>
</svg>

    );
}
WeatherLightRainIcon.__tags = [] as string[];
