/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

export type BlueCheckIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type BlueCheckIconProps = SVGProps<SVGSVGElement> & BlueCheckIconUniqueProps;

export const BlueCheckIcon = function BlueCheckIcon({
    width = 18,
    height = 19,
    ...props
}: BlueCheckIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.15905 14L3.218 10.0589C2.90194 9.74289 2.90194 9.23333 3.218 8.91888C3.53245 8.60282 4.04201 8.60282 4.35807 8.91888L7.15905 11.7199L13.6431 5.23583C13.9575 4.92139 14.4671 4.92139 14.7832 5.23583C15.0976 5.55189 15.0976 6.06146 14.7832 6.3759L7.15905 14Z"
                fill="#5E63E5"
            />
        </svg>
    );
};
BlueCheckIcon.__tags = [] as string[];
