import { VerifiedBadgeIcon } from 'components/assets/Icons/VerifiedBadgeIcon';
import { ManuallyInspectedBadgeIcon } from 'components/assets/Icons/ManuallyInspectedBadgeIcon';
import { HasPolygonBadgeIcon } from 'components/assets/Icons/HasPolygonBadgeIcon';
import React, { ReactNode } from 'react';
import { Tooltip } from 'ui-components/tooltip/tooltip';
import { dateFormats, getSimpleFormatDate } from 'utils/date/date';

export type VenueQualityBadgesTypes = 'isVerified' | 'manualInspection' | 'hasPolygon';

const VENUE_QUALITY_BADGE_TOOLTIP: Record<VenueQualityBadgesTypes, Function> = {
    isVerified: () => 'Verified',
    manualInspection: (date?: string) =>
        `Manually Inspected${
            date
                ? `, ${getSimpleFormatDate(
                      date,
                      dateFormats.shortMonthYearWithNumericOrdinalContractionDayComma,
                  )}`
                : ''
        }`,
    hasPolygon: () => 'Has Polygon',
};

const VENUE_QUALITY_BADGE_ICON: Record<VenueQualityBadgesTypes, ReactNode> = {
    isVerified: <VerifiedBadgeIcon />,
    manualInspection: <ManuallyInspectedBadgeIcon />,
    hasPolygon: <HasPolygonBadgeIcon />,
};

export const VENUE_QUALITY_BADGE: Record<VenueQualityBadgesTypes, VenueQualityBadgesTypes> = {
    isVerified: 'isVerified',
    manualInspection: 'manualInspection',
    hasPolygon: 'hasPolygon',
};

type VenueQualityBadgesProps = {
    badges: VenueQualityBadgesTypes[];
    manualInspectionDate?: string;
};

export const VenueQualityBadges = ({ badges, manualInspectionDate }: VenueQualityBadgesProps) => {
    return (
        <>
            {badges.map((badge, index) => {
                const tooltip = VENUE_QUALITY_BADGE_TOOLTIP[badge](manualInspectionDate);
                const icon = VENUE_QUALITY_BADGE_ICON[badge];
                return (
                    <Tooltip small placement={'top'} key={badge} title={tooltip}>
                        <span data-testid={badge}>{icon}</span>
                    </Tooltip>
                );
            })}
        </>
    );
};
