import { useSelectorWithProps } from 'utils/structured-selector/structured-selector';
import { selectHasUserPermission } from 'store/selectors/app-selectors';

export const usePoiSelectionPermissions = () => {
    return {
        hasUnlockNearbyActivity: useSelectorWithProps(
            {
                permission: 'unlock_nearby_activity',
            },
            selectHasUserPermission,
        ),
    };
};
