/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

type SoccerGameIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type SoccerGameIconProps = SVGProps<SVGSVGElement> & SoccerGameIconUniqueProps;

export const SoccerGameIcon = function SoccerGameIcon({
    width = 24,
    height = 22,
    ...props
}: SoccerGameIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M12.5 9.5C12.5 9.22386 12.2761 9 12 9C11.7239 9 11.5 9.22386 11.5 9.5V12.5C11.5 12.7761 11.7239 13 12 13C12.2761 13 12.5 12.7761 12.5 12.5V9.5Z"
                fill="#5E63E5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1 0C0.447715 0 0 0.447716 0 1V21C0 21.5523 0.447715 22 1 22H23C23.5523 22 24 21.5523 24 21V1C24 0.447715 23.5523 0 23 0H1ZM23 16V21H12.5V15.4725C14.75 15.2238 16.5 13.3163 16.5 11C16.5 8.68372 14.75 6.77619 12.5 6.52746V1H23V6H19V16H23ZM1 21H11.5V15.4725C9.25002 15.2238 7.5 13.3163 7.5 11C7.5 8.68372 9.25002 6.77619 11.5 6.52746V1H1V6H5V16H1V21ZM4 15H1V7H4V15ZM20 15H23V7H20V15ZM8.5 11C8.5 9.067 10.067 7.5 12 7.5C13.933 7.5 15.5 9.067 15.5 11C15.5 12.933 13.933 14.5 12 14.5C10.067 14.5 8.5 12.933 8.5 11Z"
                fill="#5E63E5"
            />
        </svg>
    );
};
SoccerGameIcon.__tags = ['sports', 'socccer', 'game', 'match'] as string[];
