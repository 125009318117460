import React from 'react';
import { InsufficientDataError } from 'features/insights/errors/insufficient-data/insufficient-data';
import { PermissionDeniedError } from 'features/insights/errors/permission-denied/permission-denied';
import { PropertyNotFoundError } from 'features/insights/errors/property-not-found/property-not-found';
import { ReportUnavailableError } from 'features/insights/errors/report-unavailable/report-unavailable';
import { UnexpectedError } from 'components/errors/unexpected-error/unexpected-error';
import { UnknownIdError } from 'features/insights/errors/unknown-id/unknown-id';
import { NonDeliverablePoi } from 'features/insights/errors/non-deliverable-poi/non-deliverable-poi';
import type { ResponseError, ResponseStatus, ResponseTypes } from 'API/common/types';
import { ERRORS } from 'API/common/constants';
import { MobileError } from 'features/mobile-report/types/types';

type ErrorCode = 204 | 244 | 400 | 403 | 404 | 500;

const errorComponentAdapter: Record<
    ErrorCode,
    JSX.Element | Partial<Record<ResponseTypes, JSX.Element>>
> = {
    204: <InsufficientDataError />,
    244: <ReportUnavailableError />,
    400: <UnknownIdError />,
    403: {
        [ERRORS.PERMISSION_DENIED]: <PermissionDeniedError />,
        [ERRORS.RESTRICTED_INSUFFICIENT_DATA]: <ReportUnavailableError />,
        [ERRORS.NEARBY_ACTIVITY_UNAVAILABLE]: <ReportUnavailableError />,
        [ERRORS.POLYGON_TOO_SMALL]: <ReportUnavailableError />,
        [ERRORS.UNDELIVERABLE_ENTITY]: <NonDeliverablePoi />,
    },
    404: <PropertyNotFoundError />,
    500: <UnexpectedError />,
};

export const getErrorComponent = (
    reportError: ResponseStatus | MobileError | ResponseError,
): JSX.Element => {
    const status =
        ('common' in reportError && reportError.common?.status) ||
        ('status' in reportError && reportError.status);
    const type =
        ('common' in reportError && reportError.common?.type) ||
        ('type' in reportError && reportError.type);

    const errorElement = status && errorComponentAdapter[status as ErrorCode];

    if (!errorElement) {
        return <UnexpectedError />;
    }

    if (React.isValidElement(errorElement)) {
        return errorElement;
    }

    return errorElement[type as keyof typeof errorElement] ?? <UnexpectedError />;
};

export const newApiErrorType = (apiError: any) => {
    const error = new Error(`${apiError.error.status} - ${apiError.error.type}`);
    (error as any).status = apiError.error.status;
    (error as any).type = apiError.error.type;
    (error as any).info = apiError.error?.info;
    return error;
};
