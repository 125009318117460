import type { Dictionary } from '@placer-ui/types';
import { getFeatureFlags } from 'core/flow-control';

const SPLUNK_MAPPING_KEY = 'splunk_mapping';

export const enrichSplunkEvent = (event: any) => {
    event.react_source_url = event.source_url || window.location.href.split('?')[0];
    return event;
};

export const saveMappings = (events: Dictionary<any>[]) => {
    const { enable_monitor_splunk_url_mapping } = getFeatureFlags();
    if (!enable_monitor_splunk_url_mapping) {
        return;
    }

    if (events.length > 0) {
        const oldData = JSON.parse(localStorage.getItem(SPLUNK_MAPPING_KEY) || '{}');
        events.forEach((item) => {
            if (item.react_source_url && item.state) {
                if (!(item.react_source_url in oldData)) {
                    oldData[item.react_source_url] = [item.state];
                } else {
                    oldData[item.react_source_url] =
                        [...new Set([...(oldData[item.react_source_url] || []), item.state])] || [];
                }
                delete item.react_source_url;
            }
        });
        localStorage.setItem(SPLUNK_MAPPING_KEY, JSON.stringify(oldData));
    }
};
