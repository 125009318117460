import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import styles from '../checkbox-list-v4/checkbox-list.module.scss';

import { MultiSelectOption, TreeNode } from 'ui-components/multi-select/types';
import { OuterSearchInput } from 'ui-components/table/components/outer-search-input/outer-search-input';
import {
    SelectAndClearAll,
    SelectAndClearAllType,
} from 'ui-components/multi-select/checkbox-list-v4/select-and-clear-all/select-and-clear-all';
import { useTreeOptionsData } from 'ui-components/multi-select/checkbox-list-v4/hooks/checkbox-list-hooks';
import { Tree } from 'antd';
import { DataNode, EventDataNode, Key } from 'rc-tree/lib/interface';
import { ArrowIconButton } from 'ui-components/arrow-icon-button/arrow-icon-button';
import { getLeafNodes } from 'ui-components/multi-select/checkbox-list-v4/utils/util';
import { isSubstring } from 'utils/substrings/substrings';

export type CheckboxListProps = {
    options: MultiSelectOption[];
    title?: string;
    searchPlaceholder?: string;
    className?: string;
    titleClassName?: string;
    inputWrapperClassName?: string;
    isSearchDisabled?: boolean;
    onOptionsChange?: (keys: Key[], removeItem: boolean, totalOptionsAmount?: number) => void;
    checkItem?: (checkedKeysValue: Key) => void;
    uncheckItem?: (checkedKeysValue: Key) => void;
    treeCheckedKeys: Key[];
    isDropMenu?: boolean;
    titleClass?: string;
};

export const CheckboxList = ({
    options,
    title,
    searchPlaceholder,
    className,
    onOptionsChange,
    titleClassName,
    inputWrapperClassName,
    isSearchDisabled,
    treeCheckedKeys,
    isDropMenu = false,
    titleClass,
}: CheckboxListProps) => {
    const [searchValue, setSearchValue] = useState<string>('');
    const [checkedKeys, setCheckedKeys] = useState<Key[]>(treeCheckedKeys);
    const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
    const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);
    const [isOpenDropMenu, setIsOpenDropMenu] = useState(false);
    const wrapperRef = useRef<HTMLDivElement>(null);

    const treeOptionsConverted = useTreeOptionsData({
        options,
        searchValue,
        isDropMenu,
        titleClass,
    });

    const [treeOptions, setTreeOptions] = useState<TreeNode[]>(treeOptionsConverted);

    const wrapperClasses = classNames(styles.checkboxList, className);

    const allLeaves = getLeafNodes(treeOptionsConverted);

    const allLeavesKeys = allLeaves.map((checkboxOption) => checkboxOption.key);

    const filter = (treeOptions: TreeNode[], text: string) => {
        const getNodes = (result: TreeNode[], node: TreeNode) => {
            if (isSubstring(node.name, text)) {
                result.push(node);
                return result;
            }
            if (Array.isArray(node.children)) {
                const childrenNodes = (node.children as TreeNode[]).reduce(getNodes, []);
                if (childrenNodes.length)
                    result.push({
                        ...node,
                        children: childrenNodes as TreeNode[],
                    });
            }
            return result;
        };

        return treeOptions.reduce(getNodes, []);
    };

    const checkboxItems: { key: Key; title: string }[] = useMemo(() => [], []);

    const getAllNodesList = useCallback(
        (data: TreeNode[]) => {
            for (let i = 0; i < data.length; i++) {
                const node = data[i];
                const { key, name } = node;
                checkboxItems.push({
                    key,
                    title: name,
                });
                if (node.children) {
                    getAllNodesList(node.children as TreeNode[]);
                }
            }
        },
        [checkboxItems],
    );

    const getParentKey = useCallback((key: Key, tree: DataNode[]): Key => {
        let parentKey: Key;
        tree.forEach((treeNode) => {
            if (treeNode.children) {
                if (treeNode.children.some((item) => item.key === key)) {
                    parentKey = treeNode.key;
                } else if (getParentKey(key, treeNode.children)) {
                    parentKey = getParentKey(key, treeNode.children);
                }
            }
        });
        return parentKey!;
    }, []);

    useEffect(() => {
        // 1st set filtered tree options by search value
        const filterSearch = filter(treeOptionsConverted, searchValue);
        getAllNodesList(filterSearch);
        setTreeOptions(filterSearch);

        // set expanded keys and expand parent after options were set only when search is applied
        if (searchValue) {
            const newExpandedKeys = checkboxItems
                .map((item) => {
                    if (isSubstring(item.title, searchValue)) {
                        return getParentKey(item.key, filterSearch);
                    }
                    return null;
                })
                .filter((item, i, self) => item && self.indexOf(item) === i) as Key[];

            setExpandedKeys(newExpandedKeys);
            setAutoExpandParent(true);
        }
    }, [getAllNodesList, searchValue, treeOptionsConverted, checkboxItems, getParentKey]);

    const onTreeSearchChange = (value: string) => {
        setSearchValue(value);
    };

    const onExpand = (newExpandedKeys: Key[]) => {
        setExpandedKeys(newExpandedKeys);
        setAutoExpandParent(false);
    };

    const toggleCheckbox = (
        checkedKeys:
            | {
                  checked: Key[];
                  halfChecked: Key[];
              }
            | Key[],
        event?: {
            node: EventDataNode;
            checked: boolean;
        },
    ) => {
        if (!event) return;

        const checkedNode = event.node;
        const allCheckedLeaves = (checkedKeys as Key[]).filter((key) =>
            allLeavesKeys.includes(key),
        );
        setCheckedKeys(allCheckedLeaves);
        const checkedNodeLeaves = getLeafNodes(checkedNode.children as TreeNode[]);
        const checkedNodeLeavesKeys = checkedNodeLeaves.map((node) => node.key);
        const optionsToCheck =
            checkedNodeLeavesKeys.length > 0 ? checkedNodeLeavesKeys : [checkedNode.key];
        const isUncheckAction = !event?.checked;
        onOptionsChange?.(optionsToCheck, isUncheckAction, allLeaves.length);
    };

    const onSelectAll = (type: SelectAndClearAllType) => {
        const totalOptions = allLeaves.length;
        const isClearAction = type === 'clear all';
        onOptionsChange?.(allLeavesKeys, isClearAction, totalOptions);
    };

    const onSelect = (
        keys: Key[],
        info: {
            node: EventDataNode;
        },
    ) => {
        const selectedNode = info.node;
        const selectedNodeLeaves = selectedNode.children
            ? getLeafNodes(selectedNode.children as TreeNode[])
            : [selectedNode];
        const selectedKeys = selectedNodeLeaves?.map((node) => node.key);
        const isUncheckAction = selectedNode.checked;
        onOptionsChange?.(selectedKeys, isUncheckAction, allLeaves.length);
    };

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setIsOpenDropMenu(false);
                setSearchValue('');
            }
        };

        if (isDropMenu) {
            document.addEventListener('mousedown', handleClickOutside);
        }
        return () => {
            if (isDropMenu) {
                document.removeEventListener('mousedown', handleClickOutside);
            }
        };
    }, [wrapperRef, setIsOpenDropMenu, isDropMenu]);

    useEffect(() => {
        setCheckedKeys(treeCheckedKeys);
    }, [treeCheckedKeys]);

    const isAllSelected = checkedKeys.length === allLeavesKeys.length;

    const list = (
        <>
            <OuterSearchInput
                inputValue={searchValue}
                onChange={onTreeSearchChange}
                className={classNames(styles.searchBoxWrapper, inputWrapperClassName)}
                placeholder={searchPlaceholder}
                disabled={isSearchDisabled}
            />
            {(isOpenDropMenu || !isDropMenu) && (
                <div
                    data-testid="checkbox-list-items-wrapper"
                    className={classNames({ [styles.dropdownMenu]: isOpenDropMenu })}>
                    {!isDropMenu && (
                        <SelectAndClearAll
                            onClick={onSelectAll}
                            disabledSelectAll={isAllSelected}
                            disabledClearAll={!checkedKeys.length}
                        />
                    )}
                    <Tree
                        treeData={treeOptions}
                        onExpand={onExpand}
                        autoExpandParent={autoExpandParent}
                        expandedKeys={expandedKeys}
                        checkedKeys={checkedKeys}
                        onSelect={onSelect}
                        multiple
                        checkable
                        height={226}
                        onCheck={toggleCheckbox}
                        switcherIcon={<ArrowIconButton arrowDirection={'down'} />}
                        className={classNames(styles.treeNodes, { [styles.dropdown]: isDropMenu })}
                    />
                </div>
            )}
        </>
    );

    return (
        <div className={classNames(styles.wrapper, wrapperClasses)}>
            <div
                ref={wrapperRef}
                onClick={() => !isSearchDisabled && isDropMenu && setIsOpenDropMenu(true)}
            >
                <div className={classNames(styles.listHeader, titleClassName)}>
                    <span>{title}</span>
                </div>
                {list}
            </div>
        </div>
    );
};
