import pluralize from 'pluralize';
import { generatePath } from 'react-router-dom';
import { FilterInsights } from 'core/entities/filter';
import { history } from 'router/history';
import { PlaceOverlay } from 'ui-components/google-map-layers/types/google-map-layers-types';
import { SECTION_ROUTE_SUFFIX_PATH } from 'features/insights/consts/routes';

type GoToPropertyPageProps = {
    entities: PlaceOverlay[];
    section: string;
    newTab?: boolean;
    inherit?: boolean;
};

export const goToPropertyPage = ({
    entities,
    section,
    newTab,
    inherit = false,
}: GoToPropertyPageProps) => {
    const filtersMap = entities?.reduce<Partial<FilterInsights>[]>((acc, { filter }) => {
        const date = filter
            ? filter.date && {
                  start: filter.date?.start,
                  end: filter.date?.end,
                  ...(filter.date?.chosenLabel && {
                      chosenLabel: filter.date.chosenLabel,
                  }),
              }
            : undefined;
        const filterParams = {
            attributes: filter?.attributes,
            config: filter?.config,
        };
        acc.push({
            date,
            ...filterParams,
        });
        return acc;
    }, []);

    const [primary, ...competitor] = entities.flat().map(({ id, type }) => ({
        id: id,
        collection: pluralize(type),
    }));

    const options = {
        ...primary,
        ...(filtersMap?.length && { filter: JSON.stringify(filtersMap) }),
        ...(competitor.length && { competitor: JSON.stringify(competitor) }),
    };

    const search = new URLSearchParams();

    if (options.competitor) {
        search.set('competitor', options.competitor);
    }
    if (options.filter) {
        search.set('filter', options.filter);
    }

    const insightsUrl = generatePath(`/insights${SECTION_ROUTE_SUFFIX_PATH}`, {
        section,
        ...primary,
    });

    if (newTab) {
        window.open(`${insightsUrl}?${search.toString()}`);
    } else {
        history.push(`${insightsUrl}?${search.toString()}`);
    }
};
