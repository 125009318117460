import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

import { IconProps } from 'components/assets/Icons/Icons';

/* eslint-disable max-len */
export const ShopAndServiceIconSvg = (
    props: CustomIconComponentProps | SVGProps<SVGSVGElement>,
) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 17 15">
        <g fill="currentColor" fillRule="evenodd">
            <path d="M11.476 5.414a3.372 3.372 0 003.537 1.518v6.734c0 .737-.597 1.334-1.333 1.334H8.262v-4.667a.667.667 0 00-.667-.667H4.88a.667.667 0 00-.667.667V15H2.845a1.333 1.333 0 01-1.333-1.334V6.933a3.366 3.366 0 003.535-1.518 4.046 4.046 0 003.215 1.586 4.04 4.04 0 003.213-1.586zm1.686 6.252h-3.05a.5.5 0 000 1h3.05a.5.5 0 000-1zm0-2h-3.05a.5.5 0 000 1h3.05a.5.5 0 000-1zM.162.333h4.05v3.308a2.025 2.025 0 11-4.05 0V.333zm12.15 0h4.05v3.308a2.025 2.025 0 11-4.05 0V.333z" />
            <path d="M5.563.333h5.4v2.633a2.7 2.7 0 11-5.4 0V.333z" />
        </g>
    </svg>
);

export const ShopAndServiceIcon = (props: IconProps) => (
    <Icon component={ShopAndServiceIconSvg} {...props} />
);
