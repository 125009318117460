import { NearbyActivityRadius } from 'core/entities/user/user-configuration';
import { useSelector } from 'react-redux';
import { selectNearbyActivityRadius } from 'store/selectors/app-selectors';

export const useNearbyActivityRadius = (): NearbyActivityRadius | undefined =>
    useSelector(selectNearbyActivityRadius);

export const useNearbyActivityRadiusOptions = (): number[] | undefined => {
    const nearbyActivityRadius = useNearbyActivityRadius();
    return nearbyActivityRadius?.options;
};

export const useNearbyActivityRadiusDefault = (): number => {
    const nearbyActivityRadius = useNearbyActivityRadius();
    return nearbyActivityRadius?.default || 250;
};
