import { createSelector } from '@reduxjs/toolkit';

import { getAnalyticsRegionNameFormat } from 'core/services/regions-service';
// eslint-disable-next-line max-len
import { selectChainsReportAllChainsEntityModels } from 'features/industry/store/chains-report-chains-store/selectors/chains-report-chains-selectors-factory';

export const selectChainsReportsMappedToAnalyticsModels = createSelector(
    selectChainsReportAllChainsEntityModels,
    (chainsEntityModels) => {
        return chainsEntityModels.reduce(
            (mappedData, { originalEntity }, i) => {
                const { id, name: chainName, area } = originalEntity;
                const { view_name } = mappedData;
                const regionName = getAnalyticsRegionNameFormat(area);
                const slashOrEmptyStr = i !== chainsEntityModels.length - 1 ? ' /' : '';
                const entityName = `${chainName} / ${regionName}`;
                const viewName = `${view_name} ${chainName} ${regionName}${slashOrEmptyStr}`;

                return {
                    ...mappedData,
                    [`e${i + 1}_id`]: id,
                    [`e${i + 1}_name`]: entityName,
                    view_name: viewName,
                };
            },
            { view_name: '' },
        );
    },
);
