import _ from 'lodash/fp';
import { createSelector } from '@reduxjs/toolkit';

import { selectConfiguration } from 'store/configuration/common/configuration-selectors';
import { AppState } from 'store/store';

export const selectConfigurationAttributes = (state: AppState) => {
    const configuration = selectConfiguration(state);

    return configuration.attributes;
};

export const selectConfigurationAttributesFilters = (state: AppState) => {
    const attributes = selectConfigurationAttributes(state);

    return attributes.filters;
};

export const selectConfigurationAttributesStates = (state: AppState) => {
    const attributes = selectConfigurationAttributes(state);

    return attributes.states;
};

export const selectIsAttributesInitialized = createSelector(
    selectConfigurationAttributesFilters,
    selectConfigurationAttributesStates,
    (filters, states) => {
        const hasFilters = !_.isEmpty(filters);
        const hasStates = !_.isEmpty(states);

        return hasFilters && hasStates;
    },
);
