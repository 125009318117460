export const LOCATION_ELEVIO_IDS = {
    tta: {
        complex: '534',
        venue: '534',
    },
    drive_time_ta: '535',
    nearby_entity_ta: '536',
};

export const locationIsNotSupported =
    'Currently Void Analysis does not support large areas such as cities or zip codes';