import { lazy } from 'react';

export const AccountActivationPage = lazy(
    () => import('features/authentication/components/account-activation/account-activation'),
);

export const AuthPages = lazy(() => import('features/authentication/auth-page'));

export const SignupPage = lazy(() => import('features/authentication/signup/signup-page'));

export const SsoLandingPage = lazy(
    () => import('features/authentication/sso/components/sso-landing-page/sso-landing-page'),
);

export const ArcGISRedirectPage = lazy(
    () => import('extensions/arc-gis-export/arc-gis-redirect-page/arc-gis-redirect-page'),
);

export const ResetPassword = lazy(
    () => import('features/authentication/reset-password/reset-password'),
);

export const ExpiredResetPassword = lazy(
    () => import('features/authentication/expired-reset-password/expired-reset-password'),
);
