/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';
import { IconProps } from 'components/assets/Icons/Icons';

export const ShoppingDistrictIconSvg = (
    props: CustomIconComponentProps | SVGProps<SVGSVGElement>,
) => (
    <svg {...props} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5 0C6.20314 0 7.18754 0.9443 7.26749 2.13983L7.27273 2.29714L7.272 3.67475L9.09091 3.67542L9.31258 5.01815C9.25607 5.01409 9.19913 5.012 9.14183 5.01194C7.57555 5.01361 6.00172 6.53499 6 8.06011C6 8.9742 6.7464 10.1967 7.35619 11.028L1.81818 11.0263C0.814546 11.0263 0 10.203 0 9.18855L0.909091 3.67542L2.727 3.67475L2.72727 2.29714C2.72727 1.08107 3.66154 0.0861012 4.84437 0.00529824L5 0ZM5 0.918855C4.29241 0.918855 3.70883 1.46663 3.64262 2.1646L3.63636 2.29714V3.67542H6.36364V2.29714C6.36364 1.53724 5.75182 0.918855 5 0.918855Z"
            fill="currentColor"
        />
        <path
            d="M9.5 5.91406C8.11992 5.91558 7.00152 7.03665 7 8.42003C7 9.72313 9.025 11.6814 9.25357 11.9033C9.3927 12.0322 9.6073 12.0322 9.74643 11.9033C9.975 11.6814 12 9.72313 12 8.42003C11.9985 7.03665 10.8801 5.91558 9.5 5.91406ZM9.5 9.49402C8.90827 9.49402 8.42857 9.01318 8.42857 8.42003C8.42857 7.82688 8.90827 7.34604 9.5 7.34604C10.0917 7.34604 10.5714 7.82688 10.5714 8.42003C10.5697 9.01246 10.091 9.49229 9.5 9.49402Z"
            fill="currentColor"
        />
    </svg>
);

export const ShoppingDistrictIcon = (props: IconProps) => (
    <Icon component={ShoppingDistrictIconSvg} {...props} />
);
