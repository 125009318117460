interface Heap {
    identify: (identity: string) => void;
    track: (event: string, properties?: Object) => void;
    identity: string | null;
    loaded: boolean;
}

// heap is installed only on production env
type WindowWithHeapFunction = {
    heap?: Heap;
};

export const isHeapLoaded = (): boolean => {
    return (window as WindowWithHeapFunction).heap?.loaded || false;
};

export const heapIdentify = (identity: string) => {
    if ((window as WindowWithHeapFunction).heap) {
        (window as WindowWithHeapFunction).heap?.identify(identity);
    }
};

export const heapTrack = (...args: Parameters<Heap['track']>) => {
    if ((window as WindowWithHeapFunction).heap) {
        (window as WindowWithHeapFunction).heap?.track(...args);
    }
};
