import { GeoPolygon } from './geoPolygon';
import _ from 'lodash';

export class GeoMultiPolygon {
    
    geoPolygons: any;

    constructor(geoJSONArray: any) {
        if (!_.isArray(geoJSONArray)) return;

        this.geoPolygons = this.createLngLat(geoJSONArray);
    }

    createLngLat(geoJSONArray: any) {
        return _.map(geoJSONArray, (geoPolygon: any) => {
            return new GeoPolygon(geoPolygon);
        });
    }

    toGeoJson() {
        return _.map(this.geoPolygons, (geoPolygon: any) => {
            return geoPolygon.toGeoJson();
        });
    }

    toGooglePath() {
        let points: any = [];
        let totalPolygons: any = [];

        // Iterate through each polygon in the multipolygon
        _.forEach(this.geoPolygons, (polygonObj: any) => {
            const polygon = polygonObj.polygons;

            _.forEach(polygon[0], (coordinate: any) => {
                points.push(
                    new window.google.maps.LatLng(coordinate.latitude, coordinate.longitude),
                );
            });
            totalPolygons.push(points);
            points = [];
        });

        return totalPolygons;
    }
}
