import React, { useEffect, useMemo, useRef, useState } from 'react';
import { isEqual } from 'lodash/fp';
import { Button as AntButton } from 'antd';
import classNames from 'classnames';
import type { Dictionary } from '@placer-ui/types';
import styles from './checkbox-list.module.scss';

import { MultiSelectOption } from 'ui-components/multi-select/types';
import { Checkbox } from 'ui-components/multi-select/checkbox-list-v2/checkbox/checkbox';
import { Collapse } from 'ui-components/collapse/collapse';
import { Tooltip } from 'ui-components/tooltip/tooltip';
import { OuterSearchInput } from 'ui-components/table/components/outer-search-input/outer-search-input';
import { isSubstring } from 'utils/substrings/substrings';

const applyOptionAndSubOptions = (option: MultiSelectOption, checked: boolean) => {
    option.selected = checked;
    option.subOptions && option.subOptions.forEach((subOption) => (subOption.selected = checked));
};

export type DisplayType = 'vertical' | 'horizontal';
export type CheckboxListProps = {
    options: MultiSelectOption[];
    onChange: (options: MultiSelectOption[], changedOption: MultiSelectOption) => void;
    onSelectAllChange?: (options: MultiSelectOption[], checked: boolean) => void;
    enableSelectAllAsText?: boolean;
    title?: string;
    searchable?: boolean;
    searchPlaceholder?: string;
    collapsible?: boolean;
    collapsedByDefault?: boolean;
    className?: string;
    checkboxClassname?: string;
    checkboxContentClassName?: string;
    checkboxWrapperClassName?: string;
    resultsSize?: number;
    allText?: string;
    enforceResultSize?: boolean;
    displayType?: DisplayType;
    clearSearchValue?: boolean;
    onSearchChange?: (value: string) => void;
    disableAllElementsUnselect?: boolean;
    allowExpansion?: boolean;
    expandOptionWhenSubOptionFound?: boolean;
    titleClassName?: string;
    inputWrapperClassName?: string;
    optionsClassName?: string;
    optionClassName?: string;
    subOptionsListClassName?: string;
    checkboxNameClass?: string;
    isSubOption?: boolean;
    addHover?: boolean;
    addSeparator?: boolean;
    subScroller?: boolean;
    addTooltip?: boolean;
    showTooltipOnlyInCaseOfEllipsis?: boolean;
};

export const CheckboxList = ({
    options,
    onChange,
    onSelectAllChange,
    enableSelectAllAsText,
    title,
    searchable,
    searchPlaceholder,
    collapsible,
    collapsedByDefault,
    className,
    checkboxClassname,
    checkboxContentClassName,
    checkboxWrapperClassName,
    resultsSize = 0,
    allText = '(All)',
    displayType = 'vertical',
    enforceResultSize,
    clearSearchValue = false,
    onSearchChange,
    disableAllElementsUnselect = false,
    allowExpansion = true,
    expandOptionWhenSubOptionFound = false,
    titleClassName,
    inputWrapperClassName,
    optionsClassName,
    subOptionsListClassName,
    checkboxNameClass,
    isSubOption = false,
    addHover = false,
    addSeparator = false,
    subScroller = true,
    addTooltip = true,
    showTooltipOnlyInCaseOfEllipsis = true,
}: CheckboxListProps) => {
    const [expandedValues, setExpandedValues] = useState<Dictionary<boolean>>({});
    const [searchValue, setSearchValue] = useState<string | undefined>();
    const [showAllResults, setShowAllResults] = useState<boolean>(false);
    const showMoreResults = useRef<boolean>(false);
    const showLessResults = useRef<boolean>(false);
    const topElementRef = useRef<HTMLDivElement>(null);
    const orgOptions = useRef<MultiSelectOption[]>(options);

    useEffect(() => {
        if (enforceResultSize) {
            setShowAllResults(false);
        }
    }, [enforceResultSize]);

    useEffect(() => {
        if (clearSearchValue) {
            setSearchValue(undefined);
        }
    }, [clearSearchValue]);

    const filteredOptionsBySearch = useMemo(() => {
        showMoreResults.current = false;
        showLessResults.current = false;

        if (!isEqual(orgOptions.current, options)) {
            //passed options prop have changed, update ref
            orgOptions.current = options;
        }

        const optionsToUse =
            options.length === orgOptions.current.length ? options : orgOptions.current;
        const results = searchValue
            ? optionsToUse.reduce<MultiSelectOption[]>((filteredOptions, option) => {
                  const isTopLevelItemMatch = isSubstring(option.name, searchValue);
                  const filteredSubOptions = option.subOptions?.filter((subOption) =>
                      isSubstring(subOption.name, searchValue),
                  );

                  if (isTopLevelItemMatch) {
                      filteredOptions.push(option);
                  } else if (filteredSubOptions?.length) {
                      const itemWithFilteredSubOptions: MultiSelectOption = {
                          ...option,
                          subOptions: filteredSubOptions,
                      };
                      filteredOptions.push(itemWithFilteredSubOptions);
                  }

                  return filteredOptions;
              }, [])
            : optionsToUse;

        if (resultsSize && results.length > resultsSize) {
            if (!showAllResults) {
                showMoreResults.current = true;
            } else {
                showLessResults.current = true;
            }
        }

        return results;
    }, [options, resultsSize, searchValue, showAllResults]);

    useEffect(() => {
        if (searchValue && expandOptionWhenSubOptionFound) {
            const optionsToUse =
                options.length === orgOptions.current.length ? options : orgOptions.current;

            const newExpandedValues = optionsToUse.reduce<Dictionary<boolean>>(
                (expandedValues, option) => {
                    const isSubOptionsItemMatch = option.subOptions?.some(({ name }) =>
                        isSubstring(name, searchValue),
                    );

                    if (isSubOptionsItemMatch) {
                        expandedValues[option.value] = true;
                    }
                    return expandedValues;
                },
                {},
            );

            const newMergedExpandedValues = {
                ...expandedValues,
                ...newExpandedValues,
            };

            setExpandedValues(newMergedExpandedValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expandOptionWhenSubOptionFound, options, searchValue]);

    const slicedOptionsBySize = showMoreResults.current
        ? filteredOptionsBySearch.slice(0, resultsSize)
        : filteredOptionsBySearch;

    const allSelected: boolean = slicedOptionsBySize.every(({ selected, subOptions }) => {
        const allSubOptionsSelected = subOptions
            ? subOptions.every(({ selected }) => selected)
            : true;
        return selected && allSubOptionsSelected;
    });

    const onExpandChange = (value: string, expanded: boolean) => {
        setExpandedValues({
            ...expandedValues,
            [value]: expanded,
        });
    };

    const setOptionChange = (newOption: MultiSelectOption) => {
        const orgOptionIndex = orgOptions.current.findIndex(
            (option) => option.value === newOption.value,
        );
        orgOptions.current[orgOptionIndex].selected = newOption.selected;
        if (newOption.subOptions) {
            newOption.subOptions.forEach((subOption) => {
                const orgSubOption = orgOptions.current[orgOptionIndex].subOptions?.find(
                    (orgItem) => orgItem.value === subOption.value,
                );
                Object.assign(orgSubOption as any, {
                    selected: subOption.selected,
                });
            });
        }
    };

    const selectAllChange = (checked: boolean) => {
        const newOptions = filteredOptionsBySearch.map<MultiSelectOption>((option) => {
            applyOptionAndSubOptions(option, checked);
            return option;
        });
        newOptions.forEach((newOption) => {
            setOptionChange(newOption);
            //apply 'checked' if orgOption is part of displayed options
            applyOptionAndSubOptions(newOption, newOption ? checked : false);
        });
        onSelectAllChange && onSelectAllChange(orgOptions.current, checked);
    };

    const onTurnAllOnButtonClick = () => {
        selectAllChange(true);
    };

    const onOptionChange = (newOption: MultiSelectOption) => {
        setOptionChange(newOption);
        onChange(orgOptions.current, newOption);
    };

    const onShowMoreResultsClick = () => {
        setShowAllResults(true);
    };

    const onShowLessResultsClick = () => {
        setShowAllResults(false);

        const topElementRefElement = topElementRef?.current;
        if (topElementRefElement) {
            topElementRefElement.scrollTop = 0;
        }
    };

    const onSearchBoxChange = (value: string) => {
        setShowAllResults(false);
        setSearchValue(value);
        onSearchChange && onSearchChange(value);
    };

    const disableCheckbox = (selected: boolean) => {
        if (!disableAllElementsUnselect) return false;
        return selected && orgOptions.current.filter((option) => option.selected).length === 1;
    };

    const wrapperClasses = classNames(styles.checkboxList, className);
    const getSelectAllComponent = () => {
        if (slicedOptionsBySize.length && onSelectAllChange) {
            if (enableSelectAllAsText) {
                return (
                    <AntButton
                        onClick={onTurnAllOnButtonClick}
                        type="link"
                        className={styles.showAllOrLessButton}
                    >
                        Select All
                    </AntButton>
                );
            } else {
                return (
                    <Checkbox
                        name={allText}
                        value="all"
                        selected={allSelected}
                        disabled={disableAllElementsUnselect && allSelected}
                        onChange={(option) => selectAllChange(option.selected)}
                        className={classNames(styles.checkboxItem, checkboxClassname)}
                        contentClassName={checkboxContentClassName}
                        checkboxNameClass={checkboxNameClass}
                        addHover={addHover}
                        addSeparator={addSeparator}
                        subScroller={subScroller}
                        testId={allSelected ? 'checkbox-selected' : 'checkbox-unselected'}
                        tooltipText={addTooltip ? allText : undefined}
                        showTooltipOnlyInCaseOfEllipsis={showTooltipOnlyInCaseOfEllipsis}
                    />
                );
            }
        }
    };

    const list = (
        <>
            {searchable && (
                <OuterSearchInput
                    inputValue={searchValue}
                    onChange={onSearchBoxChange}
                    className={classNames(styles.searchBoxWrapper, inputWrapperClassName)}
                    placeholder={searchPlaceholder}
                />
            )}
            <div className={styles.optionsWrapper}>
                <div
                    ref={topElementRef}
                    className={classNames(
                        optionsClassName,
                        {
                            [styles.listItemsWrapperVertical]: displayType === 'vertical',
                            [styles.listItemsWrapperHorizontal]: displayType === 'horizontal',
                            [styles.listItemsWrapperExpanded]: showAllResults,
                            [styles.allowVerticalScroll]: !isSubOption || subScroller,
                        },
                        checkboxWrapperClassName,
                    )}
                    data-testid={'checkbox-list-items-wrapper'}
                >
                    {getSelectAllComponent()}
                    {slicedOptionsBySize.map(
                        (
                            { name, value, selected, subOptions, disabled = false, checkedStyles },
                            idx,
                        ) => {
                            const isDisabled = disableCheckbox(selected);

                            return (
                                <Tooltip
                                    small
                                    title={
                                        isDisabled ? 'Minimum 1 parameter has to be selected' : ''
                                    }
                                    placement={'top'}
                                    key={`${name}+${idx}`}
                                >
                                    <Checkbox
                                        key={`${name}+${idx}`}
                                        className={classNames(
                                            styles.checkboxItem,
                                            checkboxClassname,
                                        )}
                                        contentClassName={checkboxContentClassName}
                                        subOptionsListClassName={subOptionsListClassName}
                                        checkboxNameClass={checkboxNameClass}
                                        name={name}
                                        value={value}
                                        selected={selected}
                                        subOptions={subOptions}
                                        onChange={onOptionChange}
                                        {...(allowExpansion && {
                                            expanded: expandedValues[value],
                                            onExpandChange: (expanded: boolean) =>
                                                onExpandChange(value, expanded),
                                        })}
                                        disabled={disableCheckbox(selected) || disabled}
                                        isSubOption={isSubOption}
                                        addHover={addHover}
                                        addSeparator={addSeparator}
                                        subScroller={subScroller}
                                        testId={
                                            selected ? 'checkbox-selected' : 'checkbox-unselected'
                                        }
                                        tooltipText={addTooltip ? name : undefined}
                                        showTooltipOnlyInCaseOfEllipsis={
                                            showTooltipOnlyInCaseOfEllipsis
                                        }
                                        checkedStyles={checkedStyles}
                                    />
                                    {/* a second child is needed to show the tooltip title */}
                                    {''}
                                </Tooltip>
                            );
                        },
                    )}
                    {showMoreResults.current && (
                        <AntButton
                            data-testid={'show-all-button'}
                            onClick={onShowMoreResultsClick}
                            type="link"
                            className={styles.showAllOrLessButton}
                        >
                            Show All
                        </AntButton>
                    )}
                    {showLessResults.current && (
                        <AntButton
                            data-testid={'show-less-button'}
                            onClick={onShowLessResultsClick}
                            type="link"
                            className={styles.showAllOrLessButton}
                        >
                            Show Less
                        </AntButton>
                    )}
                </div>
            </div>
        </>
    );

    if (!title) {
        return <div className={wrapperClasses}>{list}</div>;
    }

    return (
        <div className={wrapperClasses}>
            {collapsible ? (
                <Collapse title={title} collapsedByDefault={collapsedByDefault}>
                    {list}
                </Collapse>
            ) : (
                <>
                    <div className={classNames(styles.listHeader, titleClassName)}>
                        <span>{title}</span>
                    </div>
                    {list}
                </>
            )}
        </div>
    );
};
