import { fetchWrapper } from 'API/authentification/fetch-wrapper';
import { StarredReport } from 'core/entities';
import type { PlacerListResponse, PlacerResponse } from '@placer-ui/types';

export type UpdateReportArgs = StarredReport & {
    oldReportName: string;
};

type UpdateLastViewedDateProps = {
    report: StarredReport;
    lastViewedDate: string;
};

export const starReportAPI = {
    getReports(): PlacerResponse<PlacerListResponse<StarredReport[]>> {
        return fetchWrapper({ targetUrl: '/user/starred-reports' });
    },
    save(report: StarredReport) {
        return fetchWrapper({
            targetUrl: '/user/starred-reports/add',
            method: 'POST',
            body: report,
        });
    },
    delete(reportName: string) {
        return fetchWrapper({
            targetUrl: `/user/starred-reports/${reportName}/remove`,
            method: 'DELETE',
        });
    },
    update(report: UpdateReportArgs) {
        return fetchWrapper({
            targetUrl: `/user/starred-reports/${report.oldReportName}/name`,
            method: 'PUT',
            body: { name: report.name },
        });
    },
    updateLastViewedDate({ report, lastViewedDate }: UpdateLastViewedDateProps) {
        return fetchWrapper({
            targetUrl: `/user/starred-reports/${report.name}/last-viewed?last_viewed_date=${lastViewedDate}`,
            method: 'PATCH',
        });
    },
};
