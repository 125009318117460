import { getCookieValueByName, setCookie } from 'utils/cookies/utils';
import { getPlacerCurrentDomain } from 'store/auth/utils/utils';
import { solutionsGuideIndustries } from 'features/onboarding/solutions-guide/consts/solutions-guide';
import {
    FALLBACK_VALUES,
    ONBOARDING_INDUSTRY_COOKIE_NAME,
    ONBOARDING_SOLUTION_COOKIE_NAME,
} from 'features/onboarding/solutions-guide/consts/constants';

export const getIsShowSolutionsGuide = (isFreemiumUser: boolean) => {
    const showSolutionsGuideCookie = getCookieValueByName('show-solutions-guide');

    return isFreemiumUser && showSolutionsGuideCookie === 'show';
};

export const getDefaultIndustry = () => {
    const industryCookieValue = getCookieValueByName(ONBOARDING_INDUSTRY_COOKIE_NAME);

    /* set Industry and Solution fallback for invalid industry value */
    if (!solutionsGuideIndustries.includes(industryCookieValue)) {
        setIndustryCookie(FALLBACK_VALUES.industry);
        setSolutionCookie(FALLBACK_VALUES.solution);
        return FALLBACK_VALUES.industry;
    }

    return industryCookieValue;
};

export const getDefaultSolution = () => {
    return getCookieValueByName(ONBOARDING_SOLUTION_COOKIE_NAME) || '';
};

export const setSolutionCookie = (solutionTitle: string) => {
    setCookie({
        key: ONBOARDING_SOLUTION_COOKIE_NAME,
        value: solutionTitle,
        domain: getPlacerCurrentDomain(),
        secure: true,
        path: '/',
    });
};

export const setIndustryCookie = (industry: string) => {
    setCookie({
        key: ONBOARDING_INDUSTRY_COOKIE_NAME,
        value: industry,
        domain: getPlacerCurrentDomain(),
        secure: true,
        path: '/',
    });
};
