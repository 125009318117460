import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import styles from './dropdown-multi-select.module.scss';

import { Dropdown } from 'antd';
import classnames from 'classnames';
import { MultiSelectOption, SelectAllConfiguration } from 'ui-components/multi-select/types';

import { Button } from 'ui-components/button/button';
import {
    getSelectedPrimaryOptionsKeys,
    getSelectedSubOptionsKeys,
} from 'ui-components/dropdown-multi-select/utils/utils';
import { DropDownProps } from 'antd/lib/dropdown';
import { CheckboxList as CheckboxListV2 } from 'ui-components/multi-select/checkbox-list-v2/checkbox-list';
import { CheckboxList as CheckboxListV3 } from 'ui-components/multi-select/checkbox-list-v3/checkbox-list';
import { CheckboxList as CheckboxListV4 } from 'ui-components/multi-select/checkbox-list-v4/checkbox-list';
import {
    OptionsSelectedData,
    useEnableCheckboxListFiltersV3,
} from 'ui-components/multi-select/checkbox-list-v3/hooks/checkbox-list-hooks';

import { getSelectedOptionsElement } from 'ui-components/multi-select/checkbox-list-v3/options-selected/options-selected';
import { getSelectedOptionsElement as getSelectedOptionsElementV4 } from 'ui-components/multi-select/checkbox-list-v4/options-selected/options-selected';
import { Key } from 'rc-tree/lib/interface';
import { getConvertOptionsToValues } from 'ui-components/multi-select/checkbox-list-v4/hooks/checkbox-list-hooks';
import { cloneDeep } from 'utils/clone-deep/clone-deep';

export type OptionsSelected = {
    checked: Key[];
    total: number;
};

type DropdownMultiSelectProps = Omit<
    DropDownProps,
    'onVisibleChange' | 'overlay' | 'overlayClassName'
> & {
    children?: ReactNode;
    appendDropdownToParent?: boolean;
    containerRef?: HTMLElement | null;
    menuClassname?: string;
    optionsWrapperClassName?: string;
    footerClassname?: string;
    selectedOptionClassName?: string;
    isVisible?: boolean;
    title?: string;
    searchable?: boolean;
    searchPlaceholder?: string;
    options: MultiSelectOption[];
    expandOptionWhenSubOptionFound?: boolean;
    onApply: (options: MultiSelectOption[]) => void;
    onVisibilityChange?: (isVisible: boolean) => void;
    allText?: string;
    addTooltip?: boolean;
    allowExpansion?: boolean;
    showTooltipOnlyInCaseOfEllipsis?: boolean;
    selectAllConfiguration?: SelectAllConfiguration;
    enableTreeCheckboxList?: boolean;
    addSeparator?: boolean;
    callbackCloseOnOutsideClick?: () => void;
    isSelectAllBtn?: boolean;
    forceActiveApplyBtn?: boolean;
};

export const DropdownMultiSelect = ({
    children,
    appendDropdownToParent = true,
    containerRef,
    trigger = ['click'],
    className,
    menuClassname,
    optionsWrapperClassName,
    footerClassname,
    selectedOptionClassName,
    isVisible = false,
    title,
    searchable = true,
    searchPlaceholder = 'Search',
    options,
    expandOptionWhenSubOptionFound = true,
    onApply,
    onVisibilityChange,
    allText = 'All',
    addTooltip = true,
    allowExpansion = true,
    disabled,
    showTooltipOnlyInCaseOfEllipsis = true,
    selectAllConfiguration,
    enableTreeCheckboxList,
    callbackCloseOnOutsideClick,
    isSelectAllBtn = true,
    forceActiveApplyBtn = false,
    ...rest
}: DropdownMultiSelectProps) => {
    const [dropdownVisible, setDropdownVisible] = useState(isVisible);
    const [currentOptions, setCurrentOptions] = useState<MultiSelectOption[]>(cloneDeep(options));
    const [optionsSelected, setOptionsSelected] = useState<OptionsSelectedData>();
    const [optionsTreeSelected, setOptionsTreeSelected] = useState<OptionsSelected>({
        checked: [],
        total: 0,
    });

    const enableCheckboxListFiltersV3 = useEnableCheckboxListFiltersV3();

    const wrapperRef = useRef<HTMLDivElement>(null);

    const convertToOptions = getConvertOptionsToValues(
        optionsTreeSelected.checked,
        cloneDeep(options),
        enableTreeCheckboxList,
    );

    useEffect(() => {
        setCurrentOptions(cloneDeep(options));
    }, [options]);

    const onApplyClick = () => {
        onApply(currentOptions);
        handleVisibleChange(false);
    };

    const CheckboxList = enableCheckboxListFiltersV3 ? CheckboxListV3 : CheckboxListV2;

    const handleVisibleChange = (isVisible: boolean) => {
        setDropdownVisible(isVisible);
        onVisibilityChange && onVisibilityChange(isVisible);
    };

    const filterSelectedOptions = (options: MultiSelectOption[]) => {
        if (getSelectedSubOptionsKeys(options).length) {
            return getSelectedSubOptionsKeys(options);
        }
        return getSelectedPrimaryOptionsKeys(options);
    };

    const onChange = (
        options: MultiSelectOption[],
        changedOption: MultiSelectOption,
        optionsSelected?: OptionsSelectedData,
    ) => {
        setOptionsSelected(optionsSelected);
        setCurrentOptions(cloneDeep(options));
    };

    const onSelectAllChange = (
        options: MultiSelectOption[],
        checked: boolean,
        optionsSelected?: OptionsSelectedData,
    ) => {
        setOptionsSelected(optionsSelected);

        setCurrentOptions(cloneDeep(options));
    };

    const onOptionsChange = (keys: Key[], removeItem: boolean, totalOptionsAmount: number = 0) => {
        const alreadySelectedKeys = convertToOptions.map((option) => option.key as Key);
        let updatedSelectedKeys = alreadySelectedKeys.concat(keys);
        if (removeItem) {
            updatedSelectedKeys = alreadySelectedKeys.filter((key) => !keys.includes(key));
        } else if (keys.length === totalOptionsAmount) {
            updatedSelectedKeys = keys;
        }

        setOptionsTreeSelected({
            checked: updatedSelectedKeys,
            total: totalOptionsAmount,
        });
    };

    const onApplyTreeClick = () => {
        onApply(convertToOptions);
        handleVisibleChange(false);
    };

    const checkForCheckedKeys = useCallback(() => {
        // check for all selected options parent and children, if all of them are selected
        // all checked items are empty

        const allOptionsSelected =
            cloneDeep(options).filter(({ selected }) => selected)?.length === options.length;
        const allSubOptions = options.flatMap((opt) => opt?.subOptions) ?? [];
        const allSubOptionsSelected =
            allSubOptions.filter((sub) => (sub as MultiSelectOption)?.selected)?.length ===
            allSubOptions.length;

        return allOptionsSelected && allSubOptionsSelected;
    }, [options]);

    useEffect(() => {
        const isEmptyCheckedKeys = checkForCheckedKeys();
        if (isEmptyCheckedKeys) {
            setOptionsTreeSelected({
                checked: [],
                total: 0,
            });
        }
    }, [checkForCheckedKeys]);

    useEffect(() => {
        if (callbackCloseOnOutsideClick) {
            const handleClickOutside = (event: any) => {
                if (wrapperRef.current) {
                    if (!wrapperRef.current.contains(event.target)) {
                        callbackCloseOnOutsideClick();
                        setCurrentOptions(cloneDeep(options));
                    }
                }
            };

            document.addEventListener('mousedown', handleClickOutside);

            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }
    }, [wrapperRef, callbackCloseOnOutsideClick, options]);

    const isApplyBtnDisabled = forceActiveApplyBtn
        ? false
        : filterSelectedOptions(currentOptions).length === 0;

    const content = enableTreeCheckboxList ? (
        <>
            <CheckboxListV4
                treeCheckedKeys={optionsTreeSelected.checked}
                options={currentOptions}
                onOptionsChange={onOptionsChange}
                searchPlaceholder={searchPlaceholder}
                title={title}
                titleClassName={styles.title}
                inputWrapperClassName={styles.inputWrapper}
            />
            <div
                className={classnames(styles.footer, {
                    [styles.footerWithSelectionDisplay]:
                        enableCheckboxListFiltersV3 &&
                        selectAllConfiguration?.enableSelectedOptionsDisplay,
                })}
            >
                {getSelectedOptionsElementV4(optionsTreeSelected)}
                <div className={classnames(footerClassname)}>
                    <Button
                        data-testid={'apply-multiple-categories'}
                        type="primary"
                        className={styles.apply}
                        onClick={onApplyTreeClick}
                    >
                        Apply
                    </Button>
                </div>
            </div>
        </>
    ) : (
        <>
            <CheckboxList
                options={currentOptions}
                onChange={onChange}
                onSelectAllChange={onSelectAllChange}
                searchable={searchable}
                searchPlaceholder={searchPlaceholder}
                collapsedByDefault={false}
                title={title}
                expandOptionWhenSubOptionFound={expandOptionWhenSubOptionFound}
                titleClassName={styles.title}
                inputWrapperClassName={styles.inputWrapper}
                optionsClassName={classnames(styles.options, optionsWrapperClassName)}
                checkboxClassname={styles.option}
                subOptionsListClassName={styles.subOptionsListClassName}
                checkboxNameClass={styles.checkboxNameClass}
                allText={allText}
                addHover
                allowExpansion={allowExpansion}
                addSeparator
                subScroller={false}
                addTooltip={addTooltip}
                showTooltipOnlyInCaseOfEllipsis={showTooltipOnlyInCaseOfEllipsis}
                setOptionsSelected={setOptionsSelected}
                selectAllConfiguration={selectAllConfiguration}
                isSelectAllBtn={isSelectAllBtn}
            />
            <div
                className={classnames(styles.footer, footerClassname, {
                    [styles.footerWithSelectionDisplay]:
                        enableCheckboxListFiltersV3 &&
                        selectAllConfiguration?.enableSelectedOptionsDisplay,
                })}
            >
                {enableCheckboxListFiltersV3 &&
                    selectAllConfiguration?.enableSelectedOptionsDisplay &&
                    getSelectedOptionsElement(
                        optionsSelected,
                        classnames(styles.selectedOption, selectedOptionClassName),
                    )}
                <Button
                    data-testid={'apply-multiple-categories'}
                    type="primary"
                    className={styles.apply}
                    onClick={onApplyClick}
                    disabled={isApplyBtnDisabled}
                >
                    Apply
                </Button>
            </div>
        </>
    );

    return (
        <div ref={wrapperRef}>
            <Dropdown
                getPopupContainer={(triggerNode) =>
                    appendDropdownToParent
                        ? triggerNode.parentElement!
                        : containerRef || document.body
                }
                trigger={trigger}
                overlayClassName={styles.dropdown}
                disabled={disabled}
                overlay={
                    <div
                        className={classnames(styles.dropdownMenuContainer, className)}
                        data-testid={'dropdown-multi-select-test-id'}
                    >
                        <div className={classnames(styles.menu, menuClassname)}>{content}</div>
                    </div>
                }
                onVisibleChange={handleVisibleChange}
                visible={isVisible || dropdownVisible}
                /*
        Using the ignore as a result of typescript bug in antd
        where destroyPopupOnHide is not present as part of possible prop
        */
                // @ts-ignore
                destroyPopupOnHide
                {...rest}
            >
                {/*
            The use of <div /> if for workaround for using this component in angularJS code
            because I did not find a way to to pass children to react component in angularJS
            */}
                {children ? children : <div />}
            </Dropdown>
        </div>
    );
};
