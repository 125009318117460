import React, { useState } from 'react';
import { SolutionsGuideTrigger } from 'features/onboarding/solutions-guide/components/solutions-guide-trigger/solutions-guide-trigger';
import { SolutionsGuidePopover } from 'features/onboarding/solutions-guide/components/solutions-guide-popover/solutions-guide-popover';
import { SolutionsGuideMain } from 'features/onboarding/solutions-guide/components/solutions-guide-main/solutions-guide-main';
import { getDefaultSolution } from 'features/onboarding/solutions-guide/utils/utils';

export const SolutionsGuide = () => {
    const isDefaultOpen = Boolean(getDefaultSolution());
    const [open, setOpen] = useState(isDefaultOpen);

    return (
        <div>
            <SolutionsGuideTrigger onClick={() => setOpen(true)} />
            {open && (
                <SolutionsGuidePopover setOpen={setOpen}>
                    <SolutionsGuideMain setOpen={setOpen} />
                </SolutionsGuidePopover>
            )}
        </div>
    );
};
