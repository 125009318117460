import React, { useCallback, useMemo } from 'react';
import {
    EntityOption,
    LocationOption,
    PoiEmptySpace,
    VoidReportParams,
} from 'features/void-analysis/sections/empty-space/types/run-void-analysis-button-types';
import { methodSelectorModalApi } from 'features/void-analysis/sections/empty-space/api/method-selector-modal-api';
import {
    ElevioData,
    EntityData,
    Method,
    MethodData,
    MethodsName,
    NearbyEntityOption,
    SelectorModalState,
} from 'features/void-analysis/sections/empty-space/types/methods-selector-modal-types';
import { Select } from 'antd';
import styles from 'features/void-analysis/sections/empty-space/components/method-selection/method-selector-modal/components/nearby-selector/nearby-selector.module.scss';
import {
    adjustmentEmptySpaceCoordinate,
    creatEmptySpaceName,
} from 'features/empty-space/utils/utils';
import { useIsPoiSupportedForReport } from 'features/void-analysis/sections/add-new-report/hooks/add-new-report-hooks';
import {
    CreateJobConfig,
    IsOnlineReportSupportedType,
    onlyForPurchasedPropertiesText,
    onlyInContinentalUSText,
    useGetReportOrderId,
} from 'features/void-analysis/sections/entry-point-redirection/hooks/entry-point-redirect-hooks';
import {
    LOCATION_ELEVIO_IDS,
    locationIsNotSupported,
} from 'features/void-analysis/sections/empty-space/utils/method-selection/constants';
import queryString from 'query-string';
import { goTo, goToInANewTab } from 'router/go-to';
import { VOID_ANALYSIS_BASE_PATH } from 'router/go-to-routes/go-to-void-analysis';
import type { Place, PlaceType, PlacerResponseData } from '@placer-ui/types';
import { VOID_UNSUPPORTED_ENTITY_GROUP_CATEGORIES } from 'features/void-analysis/sections/empty-space/constants/empty-space-constants';
import {
    EMPTY_SPACE_COORDINATE_ID,
    EMPTY_SPACE_POI_TYPE,
    EMPTY_SPACE_SUPPORTED_ENTITIES,
} from 'features/empty-space/constants/empty-space-constants';
import { numberFormat } from 'utils/number/number';
import { milesToFeet } from 'ui-components/google-map/utils';
import { useVoidAnalysisActions } from 'features/void-analysis/store/actions';
import { useEmptySpacePermissions } from 'features/void-analysis/sections/empty-space/hooks/use-empty-space-permissions';
import { CardMethodOptions } from 'features/void-analysis/sections/empty-space/types/method-card';
import { emptySpaceApi } from 'features/empty-space/api/empty-space-api';
import { EmptySpaceOption } from 'features/empty-space/types/types';
import type { ProviderData } from '@placer-ui/types';
import {
    ADDRESS_IN_RESTRICTED_AREA,
    COORDINATE_IN_RESTRICTED_AREA,
} from 'shared/API/report-access-api/constants';
import { getFeatureFlags } from 'core/flow-control';

const Option = Select.Option;

export const useGetMethodsAndVerifyOrderId = () => {
    const getReportOrderId = useGetReportOrderId();
    return useCallback(
        async (locationOption: LocationOption): Promise<SelectorModalState> => {
            let entityInfo: EntityOption = {
                entity_id: '',
                entity_type: 'complex',
                name: '',
                address: locationOption?.address,
            };

            try {
                if (locationOption.entity_type === EMPTY_SPACE_POI_TYPE) {
                    const { entity_id, entity_type, name } =
                        await createEmptySpaceEntityFromAddress(locationOption as EmptySpaceOption);

                    entityInfo.entity_id = entity_id;
                    entityInfo.entity_type = entity_type;
                    entityInfo.name = name;
                } else {
                    // entity option
                    const entity = locationOption as EntityOption;
                    entityInfo.entity_id = entity.entity_id;
                    entityInfo.entity_type = entity.entity_type;
                    entityInfo.name = entity.name;
                }
                const { redirected } = await getReportOrderId(entityInfo);
                if (!redirected) {
                    const methodsInfo = await getMethodsInfo(entityInfo);
                    return {
                        ...methodsInfo,
                        locationRequest: entityInfo,
                    };
                }
            } catch (e) {
                return { msg: 'Error' };
            }
        },
        [getReportOrderId],
    );
};

export const adjustmentEmptySpaceData = (
    emptySpaceLocation: EmptySpaceOption,
): EmptySpaceOption => {
    const adjustmentCoordinateEmptySpace = adjustmentEmptySpaceCoordinate(emptySpaceLocation);
    return {
        ...adjustmentCoordinateEmptySpace,
        name: creatEmptySpaceName(adjustmentCoordinateEmptySpace),
        entity_type: emptySpaceLocation.entity_type ?? EMPTY_SPACE_POI_TYPE,
    };
};

export const distanceNumberFormat = (
    val: number,
): {
    unit: 'mi' | 'ft';
    value: string;
} => {
    return val < 0.1
        ? {
              value: numberFormat(milesToFeet(val), 0),
              unit: 'ft',
          }
        : {
              value: numberFormat(val, 2),
              unit: 'mi',
          };
};

export const useNearbyOption = (entityOptions: EntityData[] | undefined, entityName: string) => {
    return useMemo(() => {
        return entityOptions?.map((entity) => {
            if (entity.dist) {
                const { unit, value } = distanceNumberFormat(entity.dist.calculated);
                const location = `Located ${value} ${unit} from ${entityName}`;
                const title = `${entity.name} ${
                    entity.formatted_address ? `/ ${entity.formatted_address}` : ''
                }`;
                return (
                    <Option key={entity.id} value={entity.id} className={styles.option}>
                        <div className={styles.optionContainer}>
                            <div className={styles.optionTitle} title={title}>
                                <span className={styles.entityName}>{entity.name}</span>
                                {entity.formatted_address && (
                                    <span>{` / ${entity.formatted_address}`}</span>
                                )}
                            </div>
                            <div className={styles.entityLocation}>{location}</div>
                        </div>
                    </Option>
                );
            }
            return (
                <Option key={entity.id} value={entity.id} className={styles.option}>
                    <div className={styles.optionContainer}>
                        <div className={styles.optionTitle}>
                            <span className={styles.customEntityName}>{entity.name}</span>
                        </div>
                    </div>
                </Option>
            );
        });
    }, [entityOptions, entityName]);
};

export const getMethodsInfo = async (entityDetails: LocationOption) => {
    const { data: responseMethodData }: PlacerResponseData<MethodData> =
        await methodSelectorModalApi.getMethodsInfo(entityDetails);

    return {
        methods: responseMethodData.methods,
        default: responseMethodData.default,
        name: entityDetails.name!,
    };
};

type GetDefaultMethodFromApiProps = {
    id: string;
    type: PlaceType;
    name?: string;
};

export const useGetDefaultMethodFromApi = () => {
    const getDefaultMethod = useGetDefaultMethod();
    return useCallback(
        async ({ id, type, name }: GetDefaultMethodFromApiProps) => {
            const { methods, default: defaultMethod } = await getMethodsInfo({
                entity_id: id,
                entity_type: type,
                name: name || '',
            });
            return getDefaultMethod(methods, defaultMethod);
        },
        [getDefaultMethod],
    );
};

export const useGetTaInfoValuesFromApi = () => {
    const { getSharedReportById, setSharedEntity } = useVoidAnalysisActions();
    return useCallback(
        async (shareId: string, poiId: string) => {
            setSharedEntity({
                shareId,
                poiId,
            });
            const sharedReport = await getSharedReportById(shareId);
            if (sharedReport) {
                const { ta_method, ta_entity_id, ta_entity_type } =
                    sharedReport.fe_payload?.ta_methods ?? {};
                if (ta_method) {
                    return {
                        ta_method,
                        ta_entity_id,
                        ta_entity_type,
                    };
                }
            }
            return {
                ta_method: '',
                ta_entity_id: '',
                ta_entity_type: '',
            };
        },
        [getSharedReportById, setSharedEntity],
    );
};

export const useGetDefaultMethod = () => {
    return useCallback((methods: Method[], defaultMethod: MethodsName) => {
        let nearbyOptionId: string | undefined = undefined;
        let nearbyOptionType: string | undefined = undefined;
        const defaultSelectedMethod: Method =
            methods.find((method) => method.name === defaultMethod) || methods[0];
        if (defaultSelectedMethod.name === 'nearby_entity_ta' && defaultSelectedMethod.options) {
            nearbyOptionId = defaultSelectedMethod.options[0].id;
            nearbyOptionType = defaultSelectedMethod.options[0].type;
        }
        return {
            methodName: defaultSelectedMethod.name,
            nearbyOptionId,
            nearbyOptionType,
        };
    }, []);
};

export const useCreateEmptySpaceVoidJobUrlAndRedirect = () => {
    return useCallback(
        async (poi: VoidReportParams, { verifyOrderIdExists, openInNewTab }: CreateJobConfig) => {
            const {
                entity_id,
                entity_type,
                ta_entity_type,
                ta_entity_id,
                ta_method,
                name,
                geolocation,
                address,
            } = poi;
            const url = queryString.stringify({
                poiId: entity_id,
                type: entity_type,
                ta_method,
                ta_entity_id,
                ta_entity_type,
                name,
                ...(verifyOrderIdExists && { verify_order_id: true }),
                ...(entity_type === EMPTY_SPACE_POI_TYPE && {
                    ...geolocation,
                    address,
                }),
            });

            const path = `getId/?${url}`;
            openInNewTab
                ? goToInANewTab(path, VOID_ANALYSIS_BASE_PATH)
                : goTo(path, VOID_ANALYSIS_BASE_PATH);
        },
        [],
    );
};

export const isPlaceAnEmptySpaceType = (poi: Pick<Place, 'provider_data'>) => {
    return (
        poi.provider_data &&
        (poi.provider_data.provider === 'google' || poi.provider_data.provider === 'mapbox')
    );
};

const isCategorySupported = (
    subCategory: string,
    groupCategory: string,
    providerData?: ProviderData,
): boolean => {
    return (
        isPlaceAnEmptySpaceType({ provider_data: providerData }) ||
        isGroupCategorySupported(subCategory, groupCategory)
    );
};

const isGroupCategorySupported = (subCategory: string, groupCategory: string): boolean => {
    return (
        !VOID_UNSUPPORTED_ENTITY_GROUP_CATEGORIES.includes(groupCategory) ||
        subCategory === 'Address'
    );
};

export const createEmptySpaceEntityFromAddress = async (options: EmptySpaceOption) => {
    const { address, lat, lng } = options;
    return await createEmptySpaceEntity({
        name: creatEmptySpaceName(options),
        lat,
        lng,
        address,
        entity_type: EMPTY_SPACE_POI_TYPE,
    });
};

export const isOnlineReportEmptySpaceSupported = ({
    type,
    isPurchased,
    stateCode,
    providerData,
    groupInfo,
}: IsOnlineReportSupportedType) =>
    isPurchased &&
    EMPTY_SPACE_SUPPORTED_ENTITIES.includes(type) &&
    isCategorySupported(groupInfo.subCategory, groupInfo.group, providerData) &&
    !!stateCode;

export const unsupportedEmptySpaceEntityText = ({
    type,
    isPurchased,
    stateCode,
    providerData,
    groupInfo,
}: IsOnlineReportSupportedType) => {
    if (
        !EMPTY_SPACE_SUPPORTED_ENTITIES.includes(type) ||
        !isCategorySupported(groupInfo.subCategory, groupInfo.group, providerData)
    ) {
        return locationIsNotSupported;
    }

    if (!stateCode) {
        return onlyInContinentalUSText;
    }

    if (!isPurchased) {
        return onlyForPurchasedPropertiesText;
    }

    return '';
};

export const createEmptySpaceEntity = async (emptySpaceOption: EmptySpaceOption) => {
    const {
        data: { id, type, name },
    } = await emptySpaceApi.createNewEntityByEmptySpace(emptySpaceOption);
    return {
        entity_id: id,
        entity_type: type,
        name: name,
    } as EntityOption;
};

export const usePlacePoiToLocationOption = (poi: PoiEmptySpace) => {
    const isPoiSupportedForReport = useIsPoiSupportedForReport();
    const { enable_sensitive_location_improvements_void_explore_ff } = getFeatureFlags();
    return useMemo(() => {
        let locationData: LocationOption;
        let isOnlineReportSupported: boolean;
        let tooltipContent: string;

        const { type, id, name, address, geolocation, provider_data } = poi;

        if (!isPlaceAnEmptySpaceType({ provider_data })) {
            const reportSupportedDetails = isPoiSupportedForReport(poi as Place);
            isOnlineReportSupported = reportSupportedDetails.isOnlineReportSupported;
            tooltipContent = reportSupportedDetails.tooltipContent;

            locationData = {
                entity_type: type,
                entity_id: id,
                name: name,
                address: poi?.address?.address,
            } as EntityOption;
        } else {
            isOnlineReportSupported = true;
            tooltipContent = '';

            locationData = adjustmentEmptySpaceData({
                address: address?.address,
                lat: geolocation?.lat!,
                lng: geolocation?.lng!,
                name: name,
                entity_type: EMPTY_SPACE_POI_TYPE,
            });

            if (
                enable_sensitive_location_improvements_void_explore_ff &&
                (poi.access?.level === 'not_allowed' || !!poi.access?.warnings.length)
            ) {
                isOnlineReportSupported = false;
                tooltipContent = onlyForPurchasedPropertiesText;

                if (
                    poi.access?.warnings.includes('heavy_risky_ft_removed') ||
                    poi.access?.warnings.includes('restricted_area')
                ) {
                    if (poi.id === EMPTY_SPACE_COORDINATE_ID) {
                        tooltipContent = COORDINATE_IN_RESTRICTED_AREA;
                    } else {
                        tooltipContent = ADDRESS_IN_RESTRICTED_AREA;
                    }
                }
            }
        }
        return {
            isOnlineReportSupported,
            tooltipContent,
            locationData,
        };
    }, [poi, isPoiSupportedForReport, enable_sensitive_location_improvements_void_explore_ff]);
};

export const useGetElevioData = (locationType: PlaceType): ElevioData =>
    useMemo(() => {
        return {
            tta:
                locationType === 'complex'
                    ? LOCATION_ELEVIO_IDS.tta.complex
                    : LOCATION_ELEVIO_IDS.tta.venue,
            drive_time_ta: LOCATION_ELEVIO_IDS.drive_time_ta,
            nearby_entity_ta: LOCATION_ELEVIO_IDS.nearby_entity_ta,
            tta_nearby_combination: LOCATION_ELEVIO_IDS.nearby_entity_ta,
        };
    }, [locationType]);

export const useGetDefaultMethodNameAndNearbyOption = () => {
    const getDefaultMethod = useGetDefaultMethod();
    return useCallback(
        (methods: Method[], defaultMethod: MethodsName) => {
            const { methodName, nearbyOptionId } = getDefaultMethod(methods, defaultMethod);
            const defaultNearbyOption: EntityData | undefined = nearbyOptionId
                ? methods.find((method) => method.name === methodName)?.options?.[0]
                : undefined;
            const nearbyEntityOption: NearbyEntityOption | undefined = defaultNearbyOption
                ? {
                      ta_entity_type: defaultNearbyOption.type,
                      ta_entity_id: defaultNearbyOption.id,
                  }
                : undefined;
            return {
                methodName,
                nearbyEntityOption,
            };
        },
        [getDefaultMethod],
    );
};
export const useGetCustomMethodsAndNearbyOption = () => {
    const { hasVoidAnalysisCustomTaMethodPermission } = useEmptySpacePermissions();

    return useCallback(
        (methods: Method[]) => {
            const customMethods: CardMethodOptions[] = [];
            let ttaFound = false;
            let nearbyEntityTaFound = false;
            let customOptions: EntityData[] | undefined = [];
            if (!hasVoidAnalysisCustomTaMethodPermission) {
                return methods as CardMethodOptions[];
            }

            ['tta', 'drive_time_ta', 'nearby_entity_ta'].forEach((methodName: string) => {
                const foundMethod = methods.find((method) => method.name === methodName);

                if (foundMethod && methodName === 'drive_time_ta') {
                    customMethods.push({
                        name: 'drive_time_ta',
                    });
                }
                if (foundMethod && methodName === 'nearby_entity_ta') {
                    customOptions = Object.assign([], foundMethod?.options);
                    customOptions?.unshift({
                        id: '0',
                        name: 'Select nearby property instead',
                    } as EntityData);
                    customMethods.push({
                        name: 'tta_nearby_combination',
                        options: customOptions,
                    });

                    nearbyEntityTaFound = true;
                }
                if (foundMethod && methodName === 'tta') {
                    ttaFound = true;
                }
            });
            if (!ttaFound || !nearbyEntityTaFound) {
                return methods as CardMethodOptions[];
            }

            return customMethods;
        },
        [hasVoidAnalysisCustomTaMethodPermission],
    );
};
