import { createContext, useContext } from 'react';

export type TrackingContextType = {
    viewName?: string;
    reportConfig?: object;
};

export const TrackingContext = createContext<TrackingContextType>({});

export const useTrackingContext = () => {
    return useContext(TrackingContext);
};
