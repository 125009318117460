import type { GoogleMapLayersStateType } from 'ui-components/google-map-layers/types/google-map-layers-types';
import type { PlaceOverlay } from 'ui-components/google-map-layers/types/google-map-layers-types';

export const GoogleMapLayersInitialState: GoogleMapLayersStateType = {
    allPois: [],
    selectedPois: [],
    hoverId: undefined,
    activeId: undefined,
    setHoverId: (id?: string) => {},
    setActiveId: (id?: string) => {},
    getOverlayPOI: (poi: PlaceOverlay): PlaceOverlay[] => [],
    getEnclosingComplexOptions: (venueId: string): PlaceOverlay[] | undefined => undefined,
    markerTooltipComponent: () => null,
    markerTooltipCheckboxConfig: {
        showCheckbox: false,
    },
    disablePolyOnHoverForNearby: true,
    mapboxBeta: true,
};
