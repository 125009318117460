import React, { ReactNode, useEffect, useRef, useState } from 'react';
import styles from './inner-search-input.module.scss';
import { SearchIcon } from 'components/assets/Icons/Icons';
import { Button } from 'ui-components/button/button';
import { Input } from 'antd';
import { useDebouncedCallback } from 'use-debounce';
import classNames from 'classnames';
import { getElementWidthProps } from 'utils/get-element-width-props/get-element-width-props';

type InnerSearchInputProps = {
    onChange: (value: string) => void;
    placeholder?: string;
    debounceMs?: number;
    title?: ReactNode;
    onSearchModeChange?: (isSearchMode: boolean) => void;
    isSearchOnCellClick?: boolean;
    searchButtonClass?: string;
    isInitialInSearchModeState?: boolean;
    initialSearchTerm?: string;
    className?: string;
};

export const InnerSearchInput = ({
    title,
    onChange,
    placeholder = 'Search...',
    debounceMs = 0,
    onSearchModeChange,
    isSearchOnCellClick = true,
    searchButtonClass,
    isInitialInSearchModeState = false,
    initialSearchTerm,
    className,
}: InnerSearchInputProps) => {
    const [isSearching, setIsSearching] = useState<boolean>(isInitialInSearchModeState);
    const [isGlobalSearchHitArea, setIsGlobalSearchHitArea] = useState<boolean>(false);
    const [searchButtonWidth, setSearchButtonWidth] = useState<string>('');
    const innerSearchInputRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (innerSearchInputRef.current && !isSearching) {
            const parentHeader = innerSearchInputRef.current.closest('th');
            if (parentHeader) {
                const columnSorters = parentHeader.querySelector('.ant-table-column-sorters');
                if (columnSorters) {
                    const parentHeaderWidthElements = getElementWidthProps(parentHeader);
                    const columnSorterWidthElements = getElementWidthProps(columnSorters);
                    const widthStyle =
                        parentHeaderWidthElements.padding + columnSorterWidthElements.totalWidth;
                    setSearchButtonWidth(`calc(100% - ${widthStyle}px`);
                } else {
                    setIsGlobalSearchHitArea(true);
                }
            }
        }
    }, [isSearching]);

    const onCellClick = (e: React.MouseEvent) => {
        if (isSearching) {
            e.stopPropagation();
        } else {
            if (isSearchOnCellClick) {
                setIsSearching(true);
                onSearchModeChange && onSearchModeChange(true);
            }
        }
    };

    const handleChange = useDebouncedCallback((searchValue: string) => {
        onChange(searchValue);
    }, debounceMs);

    const handleBlur = (searchValue: string) => {
        if (!searchValue) {
            setIsSearching(false);
            onSearchModeChange && onSearchModeChange(false);
        }
    };

    return (
        <div
            className={classNames(styles.wrapper, className, {
                [styles.enableHitArea]: isGlobalSearchHitArea,
            })}
            onClick={onCellClick}
            ref={innerSearchInputRef}
        >
            {isSearching ? (
                <Input
                    type="text"
                    role="search-bar"
                    className={styles.searchInput}
                    bordered={false}
                    autoFocus={true}
                    allowClear={true}
                    onBlur={({ target: { value } }) => handleBlur(value)}
                    onChange={({ target: { value } }) => handleChange(value)}
                    placeholder={placeholder}
                    defaultValue={initialSearchTerm}
                />
            ) : (
                <>
                    <span>{title}</span>
                    <div
                        className={classNames(styles.searchIconHitArea, searchButtonClass)}
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsSearching(true);
                            onSearchModeChange && onSearchModeChange(true);
                        }}
                        style={{ width: searchButtonWidth }}
                    >
                        <Button
                            type="ghost"
                            role="search-button"
                            className={styles.icon}
                            icon={<SearchIcon />}
                        />
                    </div>
                </>
            )}
        </div>
    );
};
