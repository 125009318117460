import type { Dictionary } from '@placer-ui/types';
import { SectionType } from 'types/section';
import { DynamicOpenUpgradePlanPopupProps, SidePanelExpandedState } from 'store/app-ui/actions';

export interface AppUiState extends Dictionary<unknown> {
    sidebarExpanded: Partial<Record<SectionType, SidePanelExpandedState>>;
    upgradeYourPlanPopupScreen?: string;
    upgradeYourPlanPopupParams?: Omit<DynamicOpenUpgradePlanPopupProps, 'screen'>;
}

export const createApplicationState = (): AppUiState => ({
    upgradeYourPlanPopupScreen: undefined,
    sidebarExpanded: {
        'Advanced Reports': 'expanded',
    },
});
