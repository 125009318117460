/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';
import { IconProps } from 'components/assets/Icons/Icons';

export const RestaurantsIconSvg = (props: CustomIconComponentProps | SVGProps<SVGSVGElement>) => (
    <svg {...props} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.90909 1C5.17687 1 5.39957 1.19295 5.44576 1.44741L5.45455 1.54545V4.81818C5.45455 6.13697 4.51725 7.2379 3.27284 7.49082L3.27273 11.4545C3.27273 11.7558 3.02852 12 2.72727 12C2.4595 12 2.23679 11.807 2.19061 11.5526L2.18182 11.4545L2.18106 7.49068C0.936965 7.23752 0 6.13674 0 4.81818V1.54545C0 1.24421 0.244208 1 0.545455 1C0.813229 1 1.03594 1.19295 1.08212 1.44741L1.09091 1.54545L1.09 4.272H2.181L2.18182 1.54545C2.18182 1.24421 2.42603 1 2.72727 1C2.99505 1 3.21775 1.19295 3.26394 1.44741L3.27273 1.54545L3.272 4.272H4.363L4.36364 1.54545C4.36364 1.24421 4.60784 1 4.90909 1ZM6.54545 3.72727C6.54545 2.22166 7.76712 1 9.27273 1C10.7783 1 12 2.22166 12 3.72727V4.81818C12 6.13674 11.063 7.23752 9.81894 7.49068L9.81818 11.4545C9.81818 11.7558 9.57397 12 9.27273 12C9.00495 12 8.78225 11.807 8.73606 11.5526L8.72727 11.4545L8.72716 7.49082C7.48275 7.2379 6.54545 6.13697 6.54545 4.81818V3.72727ZM10.8142 5.36364H7.73018L7.75081 5.42012C7.97236 5.97917 8.49322 6.38753 9.11523 6.44705L9.27273 6.45455C9.96335 6.45455 10.5546 6.02576 10.7946 5.42012L10.8142 5.36364Z"
            fill="currentColor"
        />
    </svg>
);

export const RestaurantsIcon = (props: IconProps) => (
    <Icon component={RestaurantsIconSvg} {...props} />
);
