import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FilterInsightsWithUID } from 'core/entities/filter';
import { PoiByIdState, VenueWithFilters } from 'features/insights/store/poi/poi-types';
import { fetchReport } from 'features/insights/store/actions/fetch-report';
import { ResponseStatus } from 'API/common/types';
import { DefaultPoiDates } from 'features/insights/types/dates-types';
import { MobileError } from 'features/mobile-report/types/types';

export type InsightsState = {
    entities: PoiByIdState;
    ids: string[];
    isLoading: boolean;
    isInitialized: boolean;
    error?: ResponseStatus | MobileError;
    defaultDates?: DefaultPoiDates;
};

const initialState: InsightsState = {
    entities: {},
    ids: [],
    isLoading: false,
    isInitialized: false,
};

export const insightsSlice = createSlice({
    name: 'insights',
    initialState,
    reducers: {
        setIsReportLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setReportError: (state, action: PayloadAction<InsightsState['error']>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        setReportDates: (state, action: PayloadAction<DefaultPoiDates>) => {
            state.defaultDates = action.payload;
        },
        setPOIs: (state, action: PayloadAction<VenueWithFilters[]>) => {
            const poisPayload = action.payload;
            const allIds = poisPayload.map(({ uid }) => uid);

            poisPayload.forEach((poiData) => {
                const poiFilters = { ...poiData.customData?.filter };

                state.entities[poiData.uid] = {
                    originalEntity: poiData,
                    filter: poiFilters,
                };
            });

            // clean up store from removed POIs
            state.ids.forEach((draftId) => {
                if (!allIds.includes(draftId)) {
                    delete state.entities[draftId];
                }
            });

            state.ids = allIds;
        },
        setFilters: (state, action: PayloadAction<VenueWithFilters[]>) => {
            const filters = action.payload.map<FilterInsightsWithUID>((venue) => ({
                uid: venue.uid,
                ...venue.customData?.filter,
            }));

            filters.forEach(({ uid, ...filter }) => {
                state.entities[uid].filter = filter;
            });
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchReport.pending, (state) => {
                state.error = undefined;
                state.isLoading = true;
                state.isInitialized = true;
            })
            .addCase(fetchReport.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(fetchReport.rejected, (state, action) => {
                state.isLoading = false;
                if (action.error?.name !== 'AbortError') {
                    state.error = action.payload as ResponseStatus;
                }
            });
    },
});

export const { setIsReportLoading, setReportError, setReportDates, setPOIs, setFilters } =
    insightsSlice.actions;
