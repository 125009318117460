import { createAsyncThunk } from '@reduxjs/toolkit';

import { API } from 'API';
import { selectIsAttributesInitialized } from 'store/configuration/attributes/selectors';
import { AppState } from 'store/store';
import { Awaited } from 'types/awaited';

type GetAttributesResponseType = Awaited<ReturnType<typeof API.configuration.getAttributes>>;

export const getAttributes = createAsyncThunk<
    GetAttributesResponseType['data'],
    void,
    { state: AppState }
>(
    'configuration/get-attributes',
    async () => {
        const storeData = (window as any)?.dataForUI || (window as any)?.opener?.dataForUI;
        const existingAttributes = storeData?.configuration?.attributes;

        if (existingAttributes) return existingAttributes;

        const { data: attributes } = await API.configuration.getAttributes();

        return attributes;
    },
    {
        condition(_params: void, thunkAPI: { getState: () => AppState }) {
            const state = thunkAPI.getState();

            const isAttributesInitialized = selectIsAttributesInitialized(state);

            return !isAttributesInitialized;
        },
    },
);
