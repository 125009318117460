import { useHistory } from 'react-router-dom';
import {
    drawerTypeSignal,
    selectedEntityInfoSignal,
} from 'features/mobile-report/state/mobile-report.signals';
import {
    mlLegendOpenSignal,
    tempAnalyzeVenuesSignal,
} from 'features/mobile-report/components/analyze-page/state/analyze.signals';
import { useExploreActions } from 'features/explore/store/actions';
import {
    exploreActiveIdSignal,
    propertiesListTypeSignal,
} from 'features/mobile-report/components/explore-mobile/state/explore.signals';

export const useGoToExplore = () => {
    const history = useHistory();
    const resetExplore = useResetExplore();

    return () => {
        resetExplore();
        history.push({
            pathname: '/explore',
            state: {
                fromURL: {
                    pathname: history.location.pathname,
                },
            },
        });
    };
};

export const useResetExplore = () => {
    const { setExploreSearchParameters } = useExploreActions();

    return () => {
        propertiesListTypeSignal.value = 'map';
        exploreActiveIdSignal.value = undefined;
        drawerTypeSignal.value = undefined;
        mlLegendOpenSignal.value = false;
        selectedEntityInfoSignal.value = undefined;
        tempAnalyzeVenuesSignal.value = undefined;

        setExploreSearchParameters({ term: '' });
    };
};
