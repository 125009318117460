import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/app-store-hooks';
import {
    CreateEntityProps,
    ParsePrintEntitiesProps,
} from 'features/export/dynamic-feature-context-provider';
import { Venue, VenueClassDependencies } from 'core/entities/venue/venueAdmin';
import { VenueFilter } from 'core/entities/venue/venueAdminFilter';
import { setVenuesAndCleanWidgets } from 'features/insights/store/poi/poi-actions';
import {
    poiSelector,
    selectReportDefaultDates,
} from 'features/insights/store/selectors/insights-selectors';
import { initReportOptions } from 'features/insights/utils/init-report-options/init-report-options';

const createNewInsightsVenue = (
    venueClassDependencies: VenueClassDependencies,
    { entityData, entityParams }: CreateEntityProps,
) => {
    const venue = new Venue(
        {
            info: entityData,
            uid: entityParams.uid,
        },
        venueClassDependencies.entities,
    );

    const filters = {
        ...entityParams.filters,
        date: {
            start: entityParams.filters.date_start,
            end: entityParams.filters.date_end,
        },
    };
    VenueFilter.dates = venueClassDependencies.dates!;
    venue.setFilter(new VenueFilter(filters));
    venue.setRegularColor(entityParams.color);

    return venue;
};

export const useGetInsightsPrintEntitiesParser = () => {
    const dispatch = useAppDispatch();
    const entities = useSelector(poiSelector);
    const defaultDates = useSelector(selectReportDefaultDates);

    return useCallback(
        async ({ data }: ParsePrintEntitiesProps) => {
            const venueClassDependencies = {
                entities,
                dates: defaultDates?.dates,
            };
            const insightsEntities = data.map((entity) =>
                createNewInsightsVenue(venueClassDependencies, entity),
            );
            const reportOptionsTransformed = await initReportOptions(insightsEntities);
            //@ts-expect-error PLAC-47814
            dispatch(setVenuesAndCleanWidgets(reportOptionsTransformed));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [defaultDates, dispatch],
    );
};
