export enum EVENT_TRIGGER {
    EXPLORE_OPEN_NEW_TAB_LOADING_TIME = 'exploreOpenNewTabLoadingTime',
    INITIAL_LOADING_TIME = 'initialLoadingTime',
    PAGE_CONTENT_LOADING_TIME = 'pageContentLoadingTime',
}

export type EVENT_TYPE_KEY =
    | 'ENDPOINT'
    | 'COMPONENT_LOAD'
    | 'BOOTSTRAP'
    | 'EXPLORE_LIST_SEARCH_EXPERIENCE'
    | 'EXPLORE_LIST_MAP_TRIGGER_EXPERIENCE'
    | 'SEARCH_API_REQUEST_MEASUREMENT';

export type EVENT_TYPE =
    | 'BE_Endpoint'
    | 'ComponentLoad'
    | 'Bootstrap'
    | 'EXPLORE_LIST_SEARCH_EXPERIENCE'
    | 'EXPLORE_LIST_MAP_TRIGGER_EXPERIENCE'
    | 'SEARCH_API_REQUEST_MEASUREMENT';

export type BOOTSTRAP_TYPE = 'app' | 'report';
