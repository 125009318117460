import produce from 'immer';
import {
    toLower,
    findIndex,
    compose,
    values,
    pick,
    flatten,
    reverse,
    sortBy,
    size,
    last,
    pullAllBy,
} from 'lodash/fp';

import { createRecentEntity, RecentEntity } from 'core/entities/recent-entity/recent-entity';
import { RecentEntitiesModel } from 'core/services/recent-entities-service/recent-entities-model';
import type { PlaceType } from '@placer-ui/types';

export const getRecentEntitiesByTerm = (recentEntities: RecentEntity[], term: string) => {
    const matchedEntities = recentEntities.filter((recentEntity) => {
        const matchedValues = getFieldsValuesToCheckTermMatch(recentEntity);

        const hasMatch = matchedValues.some((matchedValue) => {
            return toLower(matchedValue).includes(term);
        });

        return hasMatch;
    });

    return sortByTimestamp(matchedEntities);
};

export const getRecentEntitiesByType = (recentEntities: RecentEntity[], entity: RecentEntity) => {
    return recentEntities.filter((recentEntity) => {
        return entity.type === recentEntity.type;
    });
};

export const getRecentEntitiesByReportType = (
    recentEntities: RecentEntity[] = [],
    entitiesTypes: PlaceType[],
) => {
    const recentEntitiesFilteredByReportType = recentEntities.reduce(
        (filteredEntities, recentPoi) => {
            if (entitiesTypes.includes(recentPoi.type)) {
                filteredEntities.push(recentPoi);
            }

            return filteredEntities;
        },
        [] as RecentEntity[],
    );

    const sortedRecentEntities = sortByTimestamp(recentEntitiesFilteredByReportType);

    return sortedRecentEntities;
};

export const prepareRecentEntitiesToSave = (
    recentEntities: RecentEntity[],
    entity: RecentEntitiesModel,
) => {
    const recentEntity = createRecentEntity(entity);
    const entityIndex = findIndex(
        {
            id: recentEntity.id,
            type: recentEntity.type,
        },
        recentEntities,
    );
    const isEntityAlreadySaved = entityIndex !== -1;

    if (isEntityAlreadySaved) {
        const newRecentEntities = produce(recentEntities, (draft) => {
            draft[entityIndex] = recentEntity;
            return draft;
        });

        const sortedRecentEntities = sortByTimestamp(newRecentEntities);

        return sortedRecentEntities;
    }

    if (shouldReplaceOldestEntity(recentEntities, recentEntity)) {
        const newEntities = replaceOldestRecentEntity(recentEntities, recentEntity);

        return newEntities;
    }

    const newRecentEntities = [...recentEntities, recentEntity];

    return newRecentEntities;
};

const getMatchFieldsValues = compose(values, pick(['id', 'name', 'locationLabel']));

const getFieldsValuesToCheckTermMatch = compose(flatten, getMatchFieldsValues);

export const sortByTimestamp = compose(reverse, sortBy(['timestamp']));

const MAX_AMOUNT_OF_RECENT_POI_ITEMS = 20;

export const shouldReplaceOldestEntity = (
    recentEntities: RecentEntity[],
    recentEntity: RecentEntity,
) => {
    const entitiesByType = getRecentEntitiesByType(recentEntities, recentEntity);

    return size(entitiesByType) > MAX_AMOUNT_OF_RECENT_POI_ITEMS;
};

export const replaceOldestRecentEntity = (
    recentEntities: RecentEntity[],
    recentEntity: RecentEntity,
) => {
    const recentEntitiesByType = getRecentEntitiesByType(recentEntities, recentEntity);
    const sortedEntities = sortByTimestamp(recentEntitiesByType);
    const theLastItem = last(sortedEntities);
    const entityWithoutLastItem = pullAllBy('id', [theLastItem], sortedEntities) as RecentEntity[];

    const newRecentEntities = [...entityWithoutLastItem, recentEntity];

    return newRecentEntities;
};
