import { Button } from 'ui-components/button/button';
import classNames from 'classnames';
import styles from 'features/explore/components/explore-side-bar/components/explore-drawer/explore-drawer-option/option-body/explore-drawer-option-body.module.scss';
import { CheckMarkIcon } from 'components/assets/Icons/Icons';
import React from 'react';
import {
    useEnableSelectionCheckbox,
    useToggleSelectedId,
} from 'features/explore/hooks/use-explore-selection';
import { PlaceOverlay } from 'ui-components/google-map-layers/types/google-map-layers-types';
import { useGetActiveOverlay } from 'features/explore/hooks/use-explore-overlay-poi';
import { Tooltip } from 'ui-components/tooltip/tooltip';
import { isPoiRestrictedCoordinate } from 'features/explore/utils/utils';
import { Spinner } from '@placer-ui/components';
import { useCheckCoordinateForNearbyReport } from 'features/explore/hooks/use-explore-nearby-activity';
import { useFullscreenContext } from 'utils/fullscreen-provider/fullscreen-provider';

type ToggleCompareButtonProps = {
    poi: PlaceOverlay;
};

export const ToggleCompareButton = ({ poi }: ToggleCompareButtonProps) => {
    const enableMultiselect = useEnableSelectionCheckbox();
    const toggleId = useToggleSelectedId();
    const activeOverlay = useGetActiveOverlay()(poi);
    const { text: tooltipCheckbox, isLoading: isCoordinateLoading } =
        useCheckCoordinateForNearbyReport()(poi);
    const { isFullscreen } = useFullscreenContext();

    const isRestrictedCoordinate = isPoiRestrictedCoordinate(activeOverlay);

    const isSelected = activeOverlay?.isSelected;
    const shouldEnableMultiselect = enableMultiselect({
        withHover: false,
        poi,
        activeOverlay,
    });

    return (
        <>
            <Tooltip
                small
                placement={'top'}
                title={tooltipCheckbox}
                useParentContainer={isFullscreen}
            >
                <Button
                    data-testid={'add-compare-button'}
                    disabled={!shouldEnableMultiselect && !isRestrictedCoordinate}
                    onClick={() => !isRestrictedCoordinate && toggleId({ poi })}
                    size="large"
                    block={true}
                    className={classNames(styles.multiselectAction, {
                        [styles.isSelected]: isSelected,
                        [styles.disabled]: isRestrictedCoordinate,
                    })}
                >
                    <div
                        className={classNames(styles.checkmark, {
                            [styles.isLoading]: isCoordinateLoading,
                        })}
                    >
                        {isCoordinateLoading ? <Spinner variant={'brand'} /> : <CheckMarkIcon />}
                    </div>
                    Add to Compare List
                </Button>
            </Tooltip>
        </>
    );
};
