import { ReactElement, useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
    Chain,
    GisNavItem,
    Industry,
    NavigationButton,
    NavigationButtonProps,
    Property,
} from 'features/header/navigation/components';
import { userPermissionsSelector } from 'store/selectors/app-selectors';
import { AvailableUserPermission, UserPermissions } from 'core/entities/user/user-permissions';
import { SectionType } from 'types/section';
import { MARKETPLACE_EXTERNAL_URL } from 'shared/constants/marketplace';
import { useFeatureFlags } from 'hooks/feature-flags/use-feature-flags';
import { FeatureFlagsKeys, UserFeatureFlags } from 'core/entities/user/user-feature-flags';
import { CREATE_POI_PATH } from 'features/create-poi/consts';
import { ADVANCED_MAPS_URL } from 'shared/constants/advanced-maps';

export interface NavigationItem {
    label: SectionType;
    link: string;
    Component: (props: NavigationButtonProps) => ReactElement | null;
    permission?: AvailableUserPermission;
    featureFlag?: FeatureFlagsKeys;
}

type NavigationItemWithDynamicLink = Omit<NavigationItem, 'link'> & {
    link:
        | string
        | ((
              userPermissions: UserPermissions | undefined,
              userFeatureFlags: UserFeatureFlags,
          ) => string);
};

const getMyZoneLink = () => '/my-zone/';

const getMarketplaceLink = (userPermissions: UserPermissions | undefined) => {
    if (userPermissions?.enable_marketplace_new_website) {
        return MARKETPLACE_EXTERNAL_URL;
    } else {
        return '/#!/admin/marketplace/';
    }
};

const getExploreLink = () => '/explore';

export const navigationItems: NavigationItemWithDynamicLink[] = [
    {
        label: 'My-Zone',
        link: getMyZoneLink,
        Component: NavigationButton,
    },
    {
        label: 'Explore',
        link: getExploreLink,
        Component: NavigationButton,
    },
    {
        label: 'Property',
        link: '/insights',
        Component: Property,
    },
    {
        label: 'Chains',
        link: '/chains/',
        Component: Chain,
    },
    {
        label: 'Markets',
        link: '/markets/',
        Component: NavigationButton,
        featureFlag: 'enable_market_section',
    },
    {
        label: 'Industry',
        link: '/industry/',
        Component: Industry,
        permission: 'show_industry_section',
        featureFlag: 'extended_bg_image_upsell_ff',
    },
    {
        label: 'Advanced Reports',
        link: '/advanced-reports/',
        Component: NavigationButton,
    },
    {
        label: 'Map Studio',
        link: ADVANCED_MAPS_URL,
        Component: NavigationButton,
        featureFlag: 'enable_advancedmaps_header',
    },
    {
        label: 'Panel Visits',
        link: '/panel',
        Component: NavigationButton,
        permission: 'show_panel_tool',
    },
    {
        label: 'GIS',
        link: '/gis',
        Component: GisNavItem,
        permission: 'enable_gis',
    },
    {
        label: 'Create POI',
        link: CREATE_POI_PATH,
        Component: NavigationButton,
        permission: 'enable_custom_poi',
        featureFlag: 'extended_bg_image_upsell_ff',
    },
    {
        label: 'Labs',
        link: '/labs/',
        Component: NavigationButton,
    },
    {
        label: 'Academy',
        link: '/academy/',
        Component: NavigationButton,
    },
    {
        label: 'Marketplace',
        link: getMarketplaceLink,
        Component: NavigationButton,
        permission: 'enable_marketplace_tab',
    },
    {
        label: 'Synapse',
        link: '/synapse/',
        Component: NavigationButton,
        featureFlag: 'enable_chat_agent_access',
    },
];

export const useGetNavItems = (
    userPermissions: UserPermissions | undefined,
    userFeatureFlags: UserFeatureFlags,
) => {
    return useMemo(() => {
        return navigationItems.map<NavigationItem>((navItem) => ({
            ...navItem,
            link:
                typeof navItem.link === 'function'
                    ? navItem.link(userPermissions, userFeatureFlags)
                    : navItem.link,
        }));
    }, [userFeatureFlags, userPermissions]);
};

export const useNavigationItems = () => {
    const userPermissions = useSelector(userPermissionsSelector);
    const featureFlags = useFeatureFlags();
    const navItems = useGetNavItems(userPermissions, featureFlags);

    const menuItemsAccordinglyToPermissions = useMemo(
        () =>
            navItems.filter((navItem) => {
                let hasNeededPermission = true,
                    hasNeededFeatureFlag = true;
                if (navItem.featureFlag) {
                    hasNeededFeatureFlag = !!featureFlags[navItem.featureFlag];
                    if (hasNeededFeatureFlag) return true;
                }
                if (navItem.permission) {
                    hasNeededPermission = userPermissions?.[navItem.permission] ?? false;
                    if (hasNeededPermission) return true;
                }
                return hasNeededPermission && hasNeededFeatureFlag;
            }),
        [userPermissions, navItems, featureFlags],
    );

    return { navigationItems: menuItemsAccordinglyToPermissions };
};
