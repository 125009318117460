import complexOptionStyles from './select-complex-option-body.module.scss';
import React, { useEffect } from 'react';
import { EntityDropdownSelect } from 'ui-components/entity-dropdown/entity-dropdown-select';
import { useSetOverlayPOI } from 'features/explore/context/use-explore-ui-state-actions';
import { useGetActiveOverlay } from 'features/explore/hooks/use-explore-overlay-poi';
import { useIsIdSelected } from 'features/explore/hooks/use-explore-selection';
import { RegularOptionBody } from 'features/explore/components/explore-side-bar/components/explore-drawer/explore-drawer-option/option-body/regular-option-body';
import { PointOfInterestFilters } from 'core/entities/point-of-interest-entities';
import { PlaceOverlay } from 'ui-components/google-map-layers/types/google-map-layers-types';
import { getComplexPlaceOverlay } from 'ui-components/google-map-layers/utils/get-place-overlay';
import type { Place } from '@placer-ui/types';

type MultiselectOptionProps = {
    poi: PointOfInterestFilters;
    enableSelection?: boolean;
    complexes: Place[];
};

export const SelectComplexOptionBody = ({
    poi,
    complexes,
    enableSelection,
}: MultiselectOptionProps) => {
    const setOverlayPOI = useSetOverlayPOI();
    const getIsSelected = useIsIdSelected();
    const getActiveOverlay = useGetActiveOverlay();

    const overlayPoi: PlaceOverlay = {
        ...poi,
        overlayID: 'complex',
    };
    const selectedComplex = getActiveOverlay(overlayPoi);
    const isSelected = getIsSelected(overlayPoi);

    // if there was no overlay selected, select the first complex
    useEffect(() => {
        !selectedComplex &&
            setOverlayPOI({
                sourcePoi: poi.id,
                overlayPoi: {
                    ...complexes[0],
                    overlayID: 'complex',
                },
            });
    }, [complexes, isSelected, poi, selectedComplex, setOverlayPOI]);

    if (!selectedComplex) return null;

    const onEntityDropdownChange = (complex: Place) => {
        setOverlayPOI({
            sourcePoi: poi.id,
            overlayPoi: getComplexPlaceOverlay(complex),
        });
    };

    // return the selected complex

    return (
        <div>
            <div className={complexOptionStyles.dropdown}>
                <EntityDropdownSelect
                    options={complexes || complexes[0]}
                    onChange={onEntityDropdownChange}
                    value={selectedComplex}
                />
            </div>
            <RegularOptionBody poi={overlayPoi} enableSelection={enableSelection} />
        </div>
    );
};
