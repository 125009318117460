/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

export type FiltersIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type FiltersIconProps = SVGProps<SVGSVGElement> & FiltersIconUniqueProps;

export const FiltersIcon = function FiltersIcon({
    width = 16,
    height = 16,
    ...props
}: FiltersIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <line y1="2.22804" x2="16" y2="2.22804" stroke="#49565D" strokeWidth="0.75" />
            <line y1="8.01069" x2="16" y2="8.01069" stroke="#49565D" strokeWidth="0.75" />
            <line y1="13.7933" x2="16" y2="13.7933" stroke="#49565D" strokeWidth="0.75" />
            <circle
                cx="11.2354"
                cy="8"
                r="1.875"
                fill="#D0D0D0"
                stroke="#49565D"
                strokeWidth="0.75"
            />
            <circle
                cx="5.25439"
                cy="2.25"
                r="1.875"
                fill="#D0D0D0"
                stroke="#49565D"
                strokeWidth="0.75"
            />
            <circle
                cx="5.25439"
                cy="13.75"
                r="1.875"
                fill="#D0D0D0"
                stroke="#49565D"
                strokeWidth="0.75"
            />
        </svg>
    );
};
FiltersIcon.__tags = ['filters', 'toggles', 'lines dots'] as string[];
