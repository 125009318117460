import {
    useGetActiveOverlay,
    useGetOverlayPOI,
} from 'features/explore/hooks/use-explore-overlay-poi';
import { useSetOverlayPOI } from 'features/explore/context/use-explore-ui-state-actions';
import { useNearbyActivityRadiusDefault } from 'hooks/use-nearby-activity-radius/use-nearby-activity-radius';
import React, { useMemo } from 'react';
import { createPlaceWithNearbyResult } from 'utils/create-place-with-nearby-result/create-place-with-nearby-result';
import { ExploreDrawerOption } from 'features/explore/components/explore-side-bar/components/explore-drawer/explore-drawer-option/explore-drawer-option';
import { SelectRadiusOptionBody } from 'features/explore/components/explore-side-bar/components/explore-drawer/explore-drawer-option/option-body/select-radius-option-body';
import { POIActionProps } from 'features/explore/components/explore-side-bar/components/explore-drawer/drawer-options';
import { getSelectedPoiOverlay } from 'features/explore/utils/utils';
import { PlaceOverlay } from 'ui-components/google-map-layers/types/google-map-layers-types';
import { getNearbyPlaceOverlay } from 'ui-components/google-map-layers/utils/get-place-overlay';

export const NearbyActivityOption = ({ poi, enableSelection }: POIActionProps) => {
    const setOverlay = useSetOverlayPOI();
    const defaultRadius = useNearbyActivityRadiusDefault();
    const getActiveOverlay = useGetActiveOverlay();
    const getOverlayPOI = useGetOverlayPOI();
    const overlayPoi: PlaceOverlay = useMemo(
        () => ({
            ...poi,
            overlayID: 'nearby',
        }),
        [poi],
    );
    const overlay = useMemo(
        () => getActiveOverlay(overlayPoi),

        [getActiveOverlay, overlayPoi],
    );

    const isActive = useMemo(() => {
        return !!overlay?.filter?.config?.ft_radius && !!overlay.isActive;
    }, [overlay]);

    const onActivate = () => {
        const poiOverlays = getOverlayPOI(overlayPoi);
        const nearbyOverlay = getSelectedPoiOverlay(poiOverlays, 'nearby');

        setOverlay({
            sourcePoi: poi.id,
            overlayPoi: getNearbyPlaceOverlay(
                createPlaceWithNearbyResult(
                    poi,
                    nearbyOverlay?.filter?.config?.ft_radius ?? defaultRadius,
                ),
            ),
        });
    };

    const subtitle = `Open a report based on visits to ${
        overlay?.filter?.config?.ft_radius || defaultRadius
    } ft radius.`;

    return (
        <ExploreDrawerOption
            isActive={isActive}
            onActivate={onActivate}
            poi={poi}
            title={'Open Nearby Activity Report'}
            subtitle={subtitle}
            testId={'nearby-report-flow-option'}
        >
            <SelectRadiusOptionBody poi={poi} enableSelection={enableSelection} />
        </ExploreDrawerOption>
    );
};
