import { useHistory } from 'react-router-dom';
import { ReportNotAvailableError } from 'components/errors/report-not-available-error/report-not-available-error';
import React from 'react';

export const NonDeliverablePoi = () => {
    const history = useHistory();

    const goToExplore = () => {
        history.push('/explore');
    };

    return (
        <ReportNotAvailableError
            title="Report Unavailable"
            primaryText="Start a New Search"
            onPrimaryClick={goToExplore}
            showPrimaryButton
        >
            Due to our privacy policy, this report is not available.
        </ReportNotAvailableError>
    );
};
