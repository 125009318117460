import {
    cleanWidgetsOnlyByPOIs,
    wrapActionWithWidgetStatusUpdate,
} from 'extensions/widget/store/widget-actions';
import { VenueWithFilters } from './poi-types';
import { setPOIs, setFilters } from 'features/insights/store/insights-slice';
import { AppDispatch, AppState } from 'store/store';
import { selectIds } from 'features/insights/store/selectors/insights-selectors';

const setFiltersAndCleanWidgetsLogic = (venues: VenueWithFilters[]) => (dispatch: AppDispatch) => {
    dispatch(cleanWidgetsOnlyByPOIs(venues.map((venue) => venue.uid)));
    dispatch(setFilters(venues));
};

export const setFiltersAndCleanWidgets =
    (venues: VenueWithFilters[]) => (dispatch: AppDispatch) => {
        dispatch(wrapActionWithWidgetStatusUpdate(setFiltersAndCleanWidgetsLogic(venues)));
    };

export const getVenuesToDelete = (newVenues: VenueWithFilters[], currentVenuesUIDs: string[] = []) => {
    const newVenuesUIDs = newVenues.map((venue) => venue.uid);
    const intersection = newVenuesUIDs.filter((uid) => currentVenuesUIDs.includes(uid));
    return currentVenuesUIDs.filter((uid: string) => !intersection.includes(uid));
};

const cleanWidgetsLogic =
    (venues: VenueWithFilters[]) => (dispatch: AppDispatch, getState: () => AppState) => {
        const currentVenuesUIDs = selectIds(getState()) || [];
        const venuesToDelete = getVenuesToDelete(venues, currentVenuesUIDs);

        dispatch(cleanWidgetsOnlyByPOIs(venuesToDelete));
    };

const setVenuesAndCleanWidgetsLogic =
    (venues: VenueWithFilters[]) => (dispatch: AppDispatch, getState: () => AppState) => {
        cleanWidgetsLogic(venues)(dispatch, getState);
        dispatch(setPOIs(venues));
    };

export const setVenuesAndCleanWidgets = (venues: VenueWithFilters[]) => (dispatch: AppDispatch) => {
    dispatch(wrapActionWithWidgetStatusUpdate(setVenuesAndCleanWidgetsLogic(venues)));
};

export const setVenuesFiltersAndCleanWidgets =
    (venues: VenueWithFilters[]) => (dispatch: AppDispatch) => {
        dispatch(wrapActionWithWidgetStatusUpdate(setVenuesAndCleanWidgetsLogic(venues)));
        dispatch(wrapActionWithWidgetStatusUpdate(setFiltersAndCleanWidgetsLogic(venues)));
    };

export const cleanWidgetsByIds = (ids: string[]) => (dispatch: AppDispatch) => {
    dispatch(wrapActionWithWidgetStatusUpdate((dispatch: AppDispatch) => {
        dispatch(cleanWidgetsOnlyByPOIs(ids));
    }));
};

export { setPOIs, setFilters };
