import { createSelector } from '@reduxjs/toolkit';

import { selectChainsReportAllChainsEntityModels } from '../chains-report-chains-selectors-factory';

export const FIRST_DROPDOWN_ITEM_INDEX = 4;

export const selectStickyBarRowChainsEntityModels = createSelector(
    selectChainsReportAllChainsEntityModels,
    (chainsEntityModels) => {
        return chainsEntityModels.slice(0, FIRST_DROPDOWN_ITEM_INDEX);
    },
);

export const selectStickyBarDropdownChainsEntityModels = createSelector(
    selectChainsReportAllChainsEntityModels,
    (chainsEntityModels) => {
        return chainsEntityModels.slice(
            FIRST_DROPDOWN_ITEM_INDEX,
            chainsEntityModels.length,
        );
    },
);
