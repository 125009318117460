import React, { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { debounce } from 'lodash/fp';
import { useSelector } from 'react-redux';

import { useNavigationItems } from 'features/header/navigation/hooks/navigation-items';
import { NavigationMoreButton } from 'features/header/navigation/components';
import { RequestDemoButton } from 'features/header/navigation/components';
import { useIsFreemiumUser } from 'hooks/use-freemium-user/use-freemium-user';
import { usePopstate } from 'hooks/use-popstate';
import { useRelativeUrl } from 'hooks/use-relative-url';
import {
    userCustomSettingsChainSelector,
    userCustomSettingsIndustrySelector,
    userCustomSettingsInsightsSelector,
} from 'store/selectors/app-selectors';

import styles from './navigation.module.scss';
import { MARKETPLACE_EXTERNAL_URL } from 'shared/constants/marketplace';
import { getPageFromPath } from 'utils/get-from-url/get-from-url';
import { usePageRefresh } from 'features/header/navigation/hooks/use-reload-reports-page';

type underlinePropsType = {
    width: number;
    left: number;
    opacity: number;
};

const defaultUnderlineProps = {
    width: 0,
    left: 0,
    opacity: 0,
};

const WRAPPER_EXTRA_PADDING = 100;
const newTabNavItemsNamespace = ['marketplace', 'advanced-maps'];

export const Navigation = () => {
    const urlPath = useRelativeUrl();
    const { navigationItems } = useNavigationItems();
    const [navItems, setNavItems] = useState(navigationItems);
    const [moreMenuItems, setMoreMenuItems] = useState<typeof navigationItems>([]);
    const [wrapperWidth, setWrapperWidth] = useState(0);
    const [selectedItemIndex, setSelectedItemIndex] = useState(0);
    const [underlinePosition, setUnderlinePosition] = useState(defaultUnderlineProps);

    const navWrapperRef = useRef<HTMLDivElement>(null);
    const navItemsListRef = useRef<HTMLUListElement>(null);
    const navItemsListSizeRef = useRef<HTMLUListElement>(null);
    const moreButtonRef = useRef<HTMLLIElement>(null);
    const navWrapperResizeObserverRef = useRef<ResizeObserver>(
        new ResizeObserver(
            debounce(100, (entry) => {
                const targetElem = entry[0].target;
                setWrapperWidth(targetElem.clientWidth);
            }),
        ),
    );
    const chainRecentReports = useSelector(userCustomSettingsChainSelector);
    const propertyRecentReports = useSelector(userCustomSettingsInsightsSelector);
    const industryRecentReports = useSelector(userCustomSettingsIndustrySelector);

    const { current: widgetContentObserver } = navWrapperResizeObserverRef;

    const getCurrentPointerPosition = useCallback(() => {
        let currentItem = -1;
        let total = 0;
        const { current: sizeRefElem } = navItemsListSizeRef;
        if (!sizeRefElem) return currentItem;

        const { current: moreButtonRefElem } = moreButtonRef;
        const moreButtonWidth = moreButtonRefElem?.clientWidth || 0;
        const navItemsCount = navItems?.length - 1;

        for (let i = 0; navItems.length > i; i++) {
            const currentItemWidth = (sizeRefElem?.childNodes[i] as Element).clientWidth;
            if (!currentItemWidth) return currentItem;

            total += currentItemWidth;
            const wrapperWidthWithPadding = wrapperWidth - moreButtonWidth - WRAPPER_EXTRA_PADDING;

            if (total > wrapperWidthWithPadding) {
                currentItem = i;
                break;
            }
        }

        const isCurrentItemOutOfOrder = currentItem > -1 && navItemsCount > currentItem;

        // resizing window too fast - disprders the items
        // making sure we keep the correct order
        if (isCurrentItemOutOfOrder) {
            return navItemsCount;
        }

        return currentItem;
    }, [navItems, wrapperWidth]);

    const moveItemToMoreMenu = useCallback(
        (currentBreakPoint: number) => {
            const nextState = [...navItems];
            const hiddenItem = nextState.splice(currentBreakPoint, 1)[0];

            hiddenItem && setMoreMenuItems([hiddenItem, ...moreMenuItems]);
            setNavItems(nextState);
        },
        [moreMenuItems, navItems],
    );

    const updateOpacity = (params: underlinePropsType) => {
        setTimeout(() => {
            setUnderlinePosition(params);
        }, 200);
    };

    const setActiveUnderline = useCallback(
        (index) => {
            const { current: sizeRefElem } = navItemsListSizeRef;

            if (!sizeRefElem) {
                return null;
            }

            const selectedItemDOM = sizeRefElem.childNodes[index] as HTMLElement;
            const width = selectedItemDOM?.clientWidth;
            const left = selectedItemDOM?.offsetLeft;

            if (navItems[selectedItemIndex]?.link === MARKETPLACE_EXTERNAL_URL) {
                return null;
            }

            setUnderlinePosition({
                width,
                left,
                opacity: underlinePosition.opacity,
            });

            if (underlinePosition.opacity === 0) {
                updateOpacity({
                    width,
                    left,
                    opacity: 1,
                });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedItemIndex, chainRecentReports, propertyRecentReports, industryRecentReports],
    );

    const moveItemsToNavbar = useCallback(() => {
        const { current: sizeRefElem } = navItemsListSizeRef;
        const { current: itemslistRefElem } = navItemsListRef;
        if (!sizeRefElem) return;

        const nextState = [...moreMenuItems];
        const hiddenItem = nextState.shift();

        // not showing single item in More menu
        if (!nextState.length) {
            hiddenItem && setNavItems([...navItems, hiddenItem]);
            setMoreMenuItems(nextState);
            return;
        }

        const navItemsListElemWidth = itemslistRefElem?.clientWidth || 0;

        const nextItemWidth = (sizeRefElem?.childNodes[navItems.length - 1] as Element)
            ?.clientWidth;

        const isGotPlaceForNextItem =
            navItemsListElemWidth + nextItemWidth + WRAPPER_EXTRA_PADDING < wrapperWidth;

        if (isGotPlaceForNextItem) {
            hiddenItem && setNavItems([...navItems, hiddenItem]);
            setMoreMenuItems(nextState);
        }
    }, [moreMenuItems, navItems, wrapperWidth]);

    const setSelectedIndexWithUnderline = useCallback(() => {
        setActiveUnderline(selectedItemIndex);
    }, [selectedItemIndex, setActiveUnderline]);

    useEffect(() => {
        const currentPage = getPageFromPath(urlPath);
        const currentIndex = navItems.findIndex((navItem) => {
            return navItem.link.indexOf(currentPage) > -1;
        });

        setSelectedItemIndex(currentIndex);
    }, [navItems, urlPath]);

    usePopstate(setSelectedIndexWithUnderline);

    usePageRefresh();

    useEffect(() => {
        if (!wrapperWidth) return;

        const currentBreakPoint = getCurrentPointerPosition();

        if (currentBreakPoint >= 0) {
            moveItemToMoreMenu(currentBreakPoint);

            return;
        }

        if (moreMenuItems.length) {
            moveItemsToNavbar();
        }

        setActiveUnderline(selectedItemIndex);
    }, [
        getCurrentPointerPosition,
        moreMenuItems.length,
        moveItemToMoreMenu,
        moveItemsToNavbar,
        wrapperWidth,
        setActiveUnderline,
        selectedItemIndex,
    ]);

    const resetNavigationState = useCallback(() => {
        setNavItems(navigationItems);
        setMoreMenuItems([]);
    }, [navigationItems]);

    const handleComponentClick = (index: number, link: string) => {
        if (!newTabNavItemsNamespace.some((str) => link.includes(str))) setSelectedItemIndex(index);
    };

    useEffect(() => {
        const { current: navWrapperElem } = navWrapperRef;
        if (!navWrapperElem) return;

        setNavItems(navigationItems);
        widgetContentObserver.observe(navWrapperElem);

        return () => {
            widgetContentObserver.unobserve(navWrapperElem);
            resetNavigationState();
        };
    }, [navigationItems, resetNavigationState, widgetContentObserver]);

    const renderNavigationItems = useCallback(() => {
        if (!navItems.length) return null;

        return navItems.map((item, index) => {
            const { Component, label, link } = item;

            return (
                <li
                    key={index}
                    data-testid={'nav-item'}
                    className={styles.navItem}
                    data-navid={index}
                >
                    <Component
                        key={index}
                        label={label}
                        link={link}
                        isSelected={selectedItemIndex === index}
                        onClick={() => handleComponentClick(index, link)}
                    />
                </li>
            );
        });
    }, [navItems, selectedItemIndex]);

    const { width, left, opacity } = underlinePosition;
    const underlineClasses = classNames(styles.navItem, styles.underline);
    const navigationActiveUnderline = underlinePosition.width ? (
        <ul className={styles.listNoStyle}>
            <li
                className={underlineClasses}
                style={{
                    left,
                    width,
                    opacity,
                }}
            />
        </ul>
    ) : null;

    const sizeRefElementClasses = classNames(styles.navItemsList, styles.hiddenSizeRefElem);

    const handleItemClick = (e: SyntheticEvent<EventTarget>) => {
        if (!(e.target instanceof HTMLAnchorElement)) return;

        const selectedItemIndex = Number(e.target?.closest('li')?.dataset.navid);
        if (selectedItemIndex == null) return;

        if (
            newTabNavItemsNamespace.some((str) => navItems[selectedItemIndex]?.link.includes(str))
        ) {
            return null;
        }

        if (selectedItemIndex < navItems.length) {
            setSelectedItemIndex(selectedItemIndex);
            return;
        }

        setUnderlinePosition(defaultUnderlineProps);
    };

    const handleMoreMenuItemClick = () => {
        setSelectedItemIndex(-1);
        setUnderlinePosition(defaultUnderlineProps);
    };

    const isFreemium = useIsFreemiumUser();

    return (
        <div className={styles.navWrapper} ref={navWrapperRef}>
            <ul
                data-testid="nav-item-list"
                className={styles.navItemsList}
                ref={navItemsListRef}
                onClick={handleItemClick}
            >
                {renderNavigationItems()}

                <NavigationMoreButton
                    label="More"
                    popoverItems={moreMenuItems}
                    buttonRef={moreButtonRef}
                    handleMoreMenuItemClick={handleMoreMenuItemClick}
                />
                {navigationActiveUnderline}
            </ul>
            <ul className={sizeRefElementClasses} ref={navItemsListSizeRef}>
                {renderNavigationItems()}
            </ul>

            {isFreemium && <RequestDemoButton />}
        </div>
    );
};
