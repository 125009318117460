import type {
    ResponseGroup,
    ResponseTemplate,
    MosaicMetadataResponse,
    MosaicNodeData,
    ExperianMosaicState,
    MosaicTreeNode,
    MosaicAttribute,
} from 'store/configuration/experian-mosaic/types';
import { mosaicAttributes } from 'store/configuration/experian-mosaic/constants';

export function getAttributeFromId(id: string): MosaicAttribute | undefined {
    for (let attr of mosaicAttributes) {
        if (attr.idRegex.test(id)) return attr.attrName;
    }
    return undefined;
}

export function getAttributeFromGroup(group: ResponseGroup): MosaicAttribute | undefined {
    for (let attr of mosaicAttributes) {
        if (group.measure_title === attr.attrName) return attr.attrName;
    }
    return undefined;
}

/** generate custom ID for a group that didn't originally have data in the fields response */
export function generateGroupId(group: ResponseGroup) {
    const groupAttribute = getAttributeFromGroup(group) ?? 'unknown';
    return `${group.label}$${groupAttribute}`;
}

/**
 * parse and normalize the fields into node data map
 * so later the tree will point to this data.
 */
export function parseFields(
    responseFields: MosaicMetadataResponse['fields'],
): Record<string, MosaicNodeData> {
    const dataMap: Record<string, MosaicNodeData> = {};
    Object.entries(responseFields).forEach(([fieldId, responseField]) => {
        let attribute = getAttributeFromId(fieldId);

        let type: MosaicNodeData['type'] = 'field';
        if (fieldId.includes('_group')) type = 'group';

        dataMap[fieldId] = {
            label: responseField.label,
            unit: responseField.unit,
            description: responseField.desc,
            isSupportWeight: responseField.is_support_weight,
            type,
            attribute,
        };
    });
    return dataMap;
}

function getGroupId(
    dataMap: Record<string, MosaicNodeData>,
    group: ResponseGroup,
): string | undefined {
    const groupAttribute = getAttributeFromGroup(group);
    const groupId = generateGroupId(group);

    for (let [id, nodeData] of Object.entries(dataMap)) {
        if (nodeData.label === group.label && nodeData.attribute === groupAttribute) {
            // first we wanna check for original field data from the server response.
            return id;
        } else if (id === groupId) {
            // second we check for our own generated node data.
            return id;
        }
    }

    return undefined;
}

function addGroupsToDataMap(dataMap: Record<string, MosaicNodeData>, groups: ResponseGroup[]) {
    groups.forEach((group) => {
        const existingGroup = getGroupId(dataMap, group);

        if (!existingGroup) {
            const groupId = generateGroupId(group);
            const groupAttribute = getAttributeFromGroup(group);

            dataMap[groupId] = {
                label: group.label,
                isSupportWeight: true,
                unit: '',
                description: '',
                type: 'group',
                attribute: groupAttribute,
            };
        }
    });
}

function addTemplatesToDataMap(
    dataMap: Record<string, MosaicNodeData>,
    templates: ResponseTemplate[],
) {
    templates.forEach((template) => {
        let attribute = getAttributeFromId(template.id);

        dataMap[template.id] = {
            label: template.label,
            isSupportWeight: template.is_support_weight,
            unit: '',
            description: '',
            type: 'template',
            attribute,
        };

        addGroupsToDataMap(dataMap, template.groups);
    });
}

function buildCategoriesTree(
    templates: ResponseTemplate[],
    dataMap: Record<string, MosaicNodeData>,
): MosaicTreeNode[] {
    const tree: MosaicTreeNode[] = [];

    templates.forEach((template) => {
        // #1 level - the template is the top level
        const node: MosaicTreeNode = {
            id: template.id,
            children: [],
        };

        template.groups.forEach((group) => {
            const groupId = getGroupId(dataMap, group);
            if (!groupId) {
                console.warn(
                    // eslint-disable-next-line quotes
                    "Missing group node-data.\nAt this point the group's node-data should have been generated.",
                    group,
                );
                return;
            }

            // #2 level - the groups are the second level
            const groupNode: MosaicTreeNode = {
                id: groupId,
                // #3 level - the group's child-fields are the third level
                children: group.fields.map((fieldId) => ({ id: fieldId })),
            };

            node.children!.push(groupNode);
        });

        tree.push(node);
    });

    return tree;
}

export const parseMosaicMetadataResponse = (
    data: MosaicMetadataResponse,
): Pick<ExperianMosaicState, 'data' | 'tree'> => {
    const dataMap = parseFields(data.fields);
    addTemplatesToDataMap(dataMap, data.templates);
    const tree = buildCategoriesTree(data.templates, dataMap);

    return {
        data: dataMap,
        tree,
    };
};
