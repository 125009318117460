import { createAction } from '@reduxjs/toolkit';
import { UserData, createUser, createUserFromCookieStorage } from 'core/entities';
import { UserConfiguration } from 'core/entities/user/user-configuration';
import {
    UserPermissionsAndFeatureFlags,
    UserPlugin,
    UserSettings,
} from 'core/entities/user/user-settings';
import type {
    UserCustomSettingsKeys,
    UserCustomSettings,
} from 'core/entities/user/user-custom-settings';
import type { Dictionary, BaseUserAuth } from '@placer-ui/types';

export const setUserFromLocalStorageActionType = 'auth/set-user-from-local-storage';
export const setUserFromCookieStorageActionCreator = createAction(
    setUserFromLocalStorageActionType,
    (cookieStorageUser: BaseUserAuth) => ({
        payload: {
            user: createUserFromCookieStorage(cookieStorageUser),
        },
    }),
);

export const setUserActionType = 'auth/set-user';
export const setUserActionCreator = createAction(setUserActionType, (userData: UserData) => ({
    payload: {
        user: createUser(userData),
    },
}));

const setUserSettingsActionType = 'auth/set-user-settings';
export const setUserSettingsActionCreator = createAction<
    UserSettings,
    typeof setUserSettingsActionType
>(setUserSettingsActionType);

const setUserPermissionsAndFfActionType = 'auth/set-user-permissions-feature-flags';
/**
 * @deprecated use `initFlowControl` method
 */
export const setUserPermissionsAndFfActionCreator = createAction<
    UserPermissionsAndFeatureFlags,
    typeof setUserPermissionsAndFfActionType
>(setUserPermissionsAndFfActionType);

const setUserConfigActionType = 'auth/set-user-congiguration';
export const setUserConfigActionCreator = createAction<
    UserConfiguration,
    typeof setUserConfigActionType
>(setUserConfigActionType);

const setUserCustomSettingsActionType = 'auth/set-user-custom-settings';
export const setUserCustomSettingsActionCreator = createAction<
    UserCustomSettings,
    typeof setUserCustomSettingsActionType
>(setUserCustomSettingsActionType);

const updateUserCustomSettingsActionType = 'auth/update-user-custom-settings';
export const updateUserCustomSettingsActionCreator = createAction<
    {
        key: UserCustomSettingsKeys;
        value: Dictionary<any> | string;
    },
    typeof updateUserCustomSettingsActionType
>(updateUserCustomSettingsActionType);

const setUserPermittedPluginsActionType = 'auth/set-user-permitted-plugins';
export const setUserPermittedPluginsActionCreator = createAction<
    UserPlugin[],
    typeof setUserPermittedPluginsActionType
>(setUserPermittedPluginsActionType);
