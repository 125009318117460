import React from 'react';
import styles from './search-options-dialog-header.module.scss';
import { CloseIcon } from 'components/assets/Icons/CloseIcon';
import { Button } from 'ui-components/button/button';
import {
    useCloseSearchDialog,
    useSearchDialogVenue,
} from 'components/search-options-dialog/v2/hooks/use-search-dialog-venue';
import { useGoToExplorePage } from 'router/go-to-routes/go-to-explore';
import { ExpandOutlinedIcon } from 'components/assets/Icons/ExpandOutlined';
import { ZOOM_TO_MARKER_TARGET } from 'shared/constants/google-map';

export const SearchOptionsDialogHeader = () => {
    const onClose = useCloseSearchDialog();
    const gotoExplore = useGoToExplorePage();
    const selectedPOI = useSearchDialogVenue();

    const onExploreClick = () => {
        gotoExplore(
            {
                center: selectedPOI.geolocation,
                zoom: ZOOM_TO_MARKER_TARGET,
            },
            {
                activeId: selectedPOI.id,
            },
        );
        onClose();
    };

    return (
        <div className={styles.searchOptionsDialogHeader}>
            <div className={styles.title}>Alternative Report Options</div>
            <div className={styles.actions}>
                <Button
                    onClick={onExploreClick}
                    type={'ghost'}
                    icon={<ExpandOutlinedIcon />}
                    className={styles.exploreAction}
                />
                <i className={styles.divider} />
                <Button
                    onClick={onClose}
                    type={'ghost'}
                    icon={<CloseIcon />}
                    className={styles.closeAction}
                />
            </div>
        </div>
    );
};
