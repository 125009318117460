import { createAction } from '@reduxjs/toolkit';
import { TagEntityModel } from 'core/entities';

const setReportChainsAtChainsAction = 'industry/set-report-chains';
export const setReportChainsAtChains = createAction<
    TagEntityModel[],
    typeof setReportChainsAtChainsAction
>(setReportChainsAtChainsAction);

const removeReportChainsByIdsAction = 'industry/remove-chains-by-ids';
export const removeReportChainsByIds = createAction<string[], typeof removeReportChainsByIdsAction>(
    removeReportChainsByIdsAction,
);
