export const getElementWidthProps = (element: Element) => {
    const style = window.getComputedStyle(element);
    const width = element.getBoundingClientRect().width;
    const margin = parseFloat(style.marginLeft) + parseFloat(style.marginRight);
    const padding = parseFloat(style.paddingLeft) + parseFloat(style.paddingRight);
    const border = parseFloat(style.borderLeftWidth) + parseFloat(style.borderRightWidth);
    const totalWidth = width + margin + border;

    return {
        width,
        margin,
        padding,
        border,
        totalWidth,
    };
};
