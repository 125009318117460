import { HubspotFormBody, HubspotFormFields, HubspotRequestContext } from 'API/hubspot-api/types';
import { fetchErrorMiddleware } from 'API/common';

type HubspotErrorBody = {
    correlationId: string;
    errors: Array<{
        message: string;
        errorType: 'BLOCKED_EMAIL' | 'RATE_LIMIT';
    }>;
    message: string;
    status: 'error';
};

/**
 * Hubspot API docs: https://legacydocs.hubspot.com/docs/methods/forms/submit_form
 * @link PORTAL_ID - Placer's Hubspot account identifier
 */
const HUBSPOT_API_URL = 'https://api.hsforms.com/submissions/v3/integration/submit';
const PORTAL_ID = process.env.REACT_APP_HUBSPOT_PORTAL_ID;

/**
 * Composes the current browser location.
 */
const resolveContext = (): HubspotRequestContext => {
    const { location } = window;
    const { title } = document;

    return {
        pageUri: location.href,
        pageName: title,
    };
};

/**
 * Sends the submission HTTP request. In case of error,
 * extract the actual error object from HubSpot error response
 * @param passedURL
 * @param options
 */
const hubspotFetchWrapper = (passedURL: string, options: RequestInit = {}): Promise<Response> => {
    return fetch(passedURL, options)
        .then(fetchErrorMiddleware)
        .then((response) => response.json())
        .catch((errorRes) => {
            return errorRes
                .json()
                .then(({ errors: [hubspotError] }: HubspotErrorBody) => {
                    return Promise.reject(hubspotError);
                })
                .catch((error: any) => {
                    return Promise.reject(error);
                });
        });
};

/**
 * Composes and sends the final HTTP request to Hubspot API.
 * @param formId - Hubspot identifier for the specific form to submit
 * @param fields - form data (user inputs)
 */
export const sendHubspotForm = <T>(
    formId: string,
    fields: HubspotFormFields<T>,
): Promise<Response> => {
    const requestUrl = `${HUBSPOT_API_URL}/${PORTAL_ID}/${formId}`;
    const formBody: HubspotFormBody<T> = {
        fields,
        context: resolveContext(),
    };

    return hubspotFetchWrapper(requestUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(formBody),
    });
};
