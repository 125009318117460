import { useExploreFiltersNormalizer } from 'features/explore/hooks/use-explore-filters-normalizer';
import { useFiltersConfiguration } from 'features/explore/hooks/use-explore-persistent-filter';
import { TrackingContextType } from 'tracking/context';
import { REPORT_TYPE_TO_PLACE_COLLECTIONS } from 'core/constants/report-type';
import { useCallback, useMemo } from 'react';
import { useSplunkCallback } from 'hooks/use-splunk-callback/use-splunk-callback';
import { BaseEventData } from 'types/splunk/events';
import { useExploreUIState } from 'features/explore/context/use-explore-ui-state';
import { useMapConfiguration } from 'features/explore/hooks/use-map-configuration';
import { VoidAnalysisOpenReportSplunkData } from 'features/void-analysis/sections/entry-point-redirection/types/entry-point-tracking-types';

const useSelectedReportTypesArray = () => {
    const { restrictions } = useExploreFiltersNormalizer();

    return useMemo(() => {
        let selectedReportTypes: string[] = [];
        if (restrictions.enableAvailablePOIs) {
            selectedReportTypes.push('available');
        }
        if (restrictions.enableNearbyActivityPOIs) {
            selectedReportTypes.push('nearby');
        }
        if (restrictions.enableClosedPOIs) {
            selectedReportTypes.push('closed');
        }
        if (restrictions.enableCustomPOIs) {
            selectedReportTypes.push('custom');
        }

        return selectedReportTypes;
    }, [restrictions]);
};

export const useExploreTrackingContext = (): TrackingContextType => {
    const { type, term } = useFiltersConfiguration();
    const { ne, sw, zoom, center } = useMapConfiguration();
    const selectedReportTypes = useSelectedReportTypesArray();
    const { selectedSuggestion } = useExploreUIState();

    return useMemo(
        () => ({
            viewName: term || selectedSuggestion?.info.name,
            reportConfig: {
                type: REPORT_TYPE_TO_PLACE_COLLECTIONS[type],
                zoom,
                property_report_types: selectedReportTypes,
                center,
                ne: {
                    lat: ne?.[0],
                    lng: ne?.[1],
                },
                sw: {
                    lat: sw?.[0],
                    lng: sw?.[1],
                },
            },
        }),
        [term, selectedSuggestion?.info.name, type, zoom, selectedReportTypes, center, ne, sw],
    );
};

export type ExploreEvent = BaseEventData & {
    component: string;
    state?: string;
    searchTerm?: string;
    selectedSource?: string;
    selectedCategory?: string;
    mapProvider?: string;
    mapViewType?: string;
};

export const useSendExploreSplunkEvent = () => {
    const sendSplunk = useSplunkCallback<ExploreEvent>();

    return useCallback(
        ({
            component,
            action,
            value,
            searchTerm,
            selectedSource,
            selectedCategory,
            mapProvider,
            mapViewType,
        }: ExploreEvent) => {
            sendSplunk({
                state: 'explore2',
                component,
                action,
                value,
                searchTerm,
                selectedSource,
                selectedCategory,
                mapProvider,
                mapViewType,
            });
        },
        [sendSplunk],
    );
};

export const useSplunkDataForVoidAnalysis = () => {
    const exploreTrackingContext = useExploreTrackingContext();
    return useCallback(
        (splunkComponent?: string): VoidAnalysisOpenReportSplunkData => {
            return {
                state: 'explore2',
                component: splunkComponent || 'Property info card',
                view_name: '',
                report_config: exploreTrackingContext.reportConfig,
            };
        },
        [exploreTrackingContext],
    );
};
