import { isRegionNationwide, getRegionFullName } from 'core/services/regions-service';
import type {
    Chain,
    ChainPlaceTypes,
    Place,
    PlacerEntityModel,
    VisitDurationType,
} from '@placer-ui/types';
import { RestrictedRegions } from 'hooks/use-mhmd-hooks/use-chains-legislation-type';
import { CoverageReason } from 'API/chains-api';

export type ChainEntityModel = PlacerEntityModel<Chain>;

export type ReportOption = {
    entity_id: string;
    entity_type: ChainPlaceTypes;
    default_profile_name: VisitDurationType;
    restricted_regions?: RestrictedRegions | null;
    has_posie_entities?: boolean;
};

export type ReportOptions = {
    entitiesData: ReportOption[];
    maxDate?: string;
};

export type CoverageEntity = {
    restrictedRegions: RestrictedRegions | null;
    reasons: CoverageReason[] | null;
    totalVenues: number;
    coverage: number;
    totalOpenVenues: number;
    coverageDate: string;
};

export type ReportOptionsPayload = {
    entity_id: string;
    entity_type: ChainPlaceTypes;
}[];

export const chainTypeProperty = 'chain_type';

export class ChainPOI {
    constructor(properties: Partial<Chain>) {
        Object.assign(this, properties);
    }

    static isChain(poi: Place): poi is Chain {
        if (!poi) {
            return false;
        }
        return poi.type === 'chain';
    }

    static isTag(poi: Place): poi is Chain {
        if (!poi) {
            return false;
        }
        return poi.type === 'tag';
    }

    static getLocationLabel(chain: Chain): string {
        if (chain.type === 'tag') {
            return 'Custom Chain';
        }

        if (chain.area && isRegionNationwide(chain.area)) {
            return 'Nationwide';
        }

        return chain.area && getRegionFullName(chain.area) ? getRegionFullName(chain.area) : '';
    }

    static toLocationMaxWidth(chain: Chain, width: number = 200): string {
        const input = ChainPOI.getLocationLabel(chain).replace(/ Metro Area| Micro Area/i, '');
        const FONT_SIZE = 13;
        const COMMA = ', ';
        const textWidth = width / FONT_SIZE;
        const split = input.split(',');
        const suffix = split[split.length - 1];
        const maxLength = textWidth - suffix.length + COMMA.length;

        const transformed = input.replace(/^(.*? - [^-]*?)([^,]*).*/, (_, group1, group2) => {
            const truncatedText = group2.substring(0, maxLength);
            return `${group1}${truncatedText}...`;
        });

        const ouput = transformed.endsWith(suffix) ? transformed : transformed + COMMA + suffix;

        return input.length < textWidth ? input : ouput;
    }
}
