import { getReportEntityProfile } from 'core/services/report-entities-service/report-entities-service';
import type {
    OperatingInfo,
    Place,
    PlaceCollection,
    PlaceType,
    Venue,
    EntityProfile,
} from '@placer-ui/types';
import { startCase } from 'lodash/fp';
import { APP_CONSTS } from 'core/constants/app-consts';

export type VenueFlags =
    | 'flagged_indoor_mall'
    | 'flagged_multistory_building'
    | 'ft_abnormality'
    | 'nearby_activity'
    | 'flagged_dense_area'
    | 'privacy_concerns'
    | 'unknown_flag'
    | 'not_available';

type ExcludeFromProfile = 'available';

type FlaggedTypes = Exclude<EntityProfile, ExcludeFromProfile>;

export const entityVenueFlagMap: FlaggedTypes[] = ['nearby_activity'];

const venueTypes: PlaceType[] = ['venue', 'complex'];

export class VenuePOI {
    static isVenue(poi: Place): poi is Venue {
        return venueTypes.includes(poi.type);
    }

    static getLocationLabel(poi: Place) {
        return poi?.address?.formatted_address || '';
    }

    static getClosingInfo(venue?: Venue): OperatingInfo | undefined {
        if (!venue?.operating_info || !venue.operating_info.length) return;

        const lastStatus = venue.operating_info[venue.operating_info.length - 1];
        return lastStatus.status === 'closed' ? lastStatus : undefined;
    }

    static getReportEntityFlag(venue: Venue): VenueFlags | undefined {
        const flags = venue.verified?.flag_keys;
        if (flags?.includes('privacy_concerns')) {
            return 'privacy_concerns';
        }

        if (venue.verified?.is_flagged) {
            if (flags?.includes('flagged_indoor_mall')) {
                return 'flagged_indoor_mall';
            }
            if (flags?.includes('flagged_multistory_building')) {
                return 'flagged_multistory_building';
            }
            if (flags?.includes('flagged_dense_area')) {
                return 'flagged_dense_area';
            }
            if (flags?.includes('ft_abnormality')) {
                return 'ft_abnormality';
            }
            if (flags?.length) {
                return 'unknown_flag';
            }
        }

        const propertyProfile = getReportEntityProfile(venue) as FlaggedTypes;
        if (propertyProfile && entityVenueFlagMap.includes(propertyProfile)) {
            return propertyProfile;
        }
    }

    static getReportEntityProfile(venue: Venue): EntityProfile | undefined {
        return venue?.profile;
    }

    static isRestrictedArea(venue: Venue) {
        return (
            venue?.access?.level === 'not_allowed' &&
            venue?.access?.warnings?.includes('restricted_area')
        );
    }

    static generateVenueAcronym(name: string) {
        return startCase(name)
            .split(' ')
            .slice(0, 2)
            .map((word: string) => word[0])
            .join('');
    }

    static typeToCollection(type: string) {
        switch (type) {
            case APP_CONSTS.venues.types.COMPLEX:
                return APP_CONSTS.venues.collection.COMPLEXES as PlaceCollection;
            case APP_CONSTS.venues.types.VENUE:
                return APP_CONSTS.venues.collection.VENUES as PlaceCollection;
            case APP_CONSTS.venues.types.BILLBOARD:
                return APP_CONSTS.venues.collection.BILLBOARDS as PlaceCollection;
            case APP_CONSTS.venues.types.CHAIN:
                return APP_CONSTS.venues.collection.CHAINS as PlaceCollection;
            default:
                return (type + 's') as PlaceCollection;
        }
    }
}
