import React from 'react';
import { isEqual } from 'lodash/fp';
import wastelandPlacerOwlSvg from 'shared/images/wasteland-placer-owl.svg';
import styles from './search-poi-empty-state.module.scss';

import { SearchPoiClearButton } from 'features/poi-selection-map/poi-selection-menu/menu-items/search-menu-item/search-poi-empty-state/search-poi-clear-button/search-poi-clear-button';
import { useSearchApiFilters } from 'features/poi-selection-map/poi-selection-menu/menu-items/search-menu-item/hooks/search-poi-hooks';
import { PROPERTY } from 'core/constants/report-type';
import { defaultSearchApiFilters } from 'features/poi-selection-map/poi-selection-menu/menu-items/search-menu-item/context/initial-state';
import { useRestSearchFilters } from 'features/poi-selection-map/poi-selection-menu/menu-items/search-menu-item/hooks/search-poi-filter-hooks';
import { useChangeCurrentZoom } from 'features/poi-selection-map/hooks/poi-selection-map-hooks';
import { useSearchPoiContext } from 'features/poi-selection-map/poi-selection-menu/menu-items/search-menu-item/context/search-poi-context';
import { EMPTY_STATE_TITLES } from 'features/explore/constants';

export const SearchPoiEmptyState = () => {
    const { isLoadingPois } = useSearchPoiContext();
    const { restrictions, type, categories } = useSearchApiFilters();
    const restSearchFilters = useRestSearchFilters();
    const changeCurrentZoom = useChangeCurrentZoom();
    const title = EMPTY_STATE_TITLES[type]!;
    const hasCategoryFilterChanged = categories.length > 0;
    const hasRestrictionsFilterChanged = !isEqual(
        restrictions,
        defaultSearchApiFilters.restrictions,
    );
    const showClearFilters =
        type === PROPERTY && (hasCategoryFilterChanged || hasRestrictionsFilterChanged);

    if (isLoadingPois) return null;

    return (
        <div className={styles.container}>
            <div className={styles.title}>{title}</div>
            <div className={styles.subtitle}>
                Make sure your search is spelled correctly <br />
                Change map zoom and bounds to include additional areas
            </div>
            <div className={styles.buttonsContainer}>
                {showClearFilters && (
                    <SearchPoiClearButton onClick={restSearchFilters} showCloseIcon={true}>
                        Clear Filters
                    </SearchPoiClearButton>
                )}
                <SearchPoiClearButton onClick={changeCurrentZoom}>Reset Map</SearchPoiClearButton>
            </div>
            <img src={wastelandPlacerOwlSvg} className={styles.placerOwl} alt="No results" />
        </div>
    );
};
