import { createSelector } from '@reduxjs/toolkit';
import { selectChainsReportSearchSection } from '../select-chains-search-section';
import { selectChainsEntityModelsSelectedForReportInSearch } from '..';
import { TagEntityModel } from 'core/entities';

export const selectDeselectedChains = createSelector(
    selectChainsReportSearchSection,
    (chainReportSearch) => chainReportSearch.deselectedSearchChains,
);

export const selectCheckedChainsInPopup = createSelector(
    selectChainsEntityModelsSelectedForReportInSearch,
    selectDeselectedChains,
    (selectedChains, deselectedChains) => {
        return selectedChains.filter((chain: TagEntityModel) => {
            return !deselectedChains.find(
                (deSelected) =>
                    deSelected.originalEntity.id === chain.originalEntity.id,
            );
        });
    },
);
