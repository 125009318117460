import React from 'react';
import { MapController } from 'ui-components/google-map/components/map-controller/map-controller';
import { ExploreLegend } from 'features/explore/components/explore-map-section/explore-map-legend/explore-legend/explore-legend';

type ExploreMapLegendProps = {
    withSwitches?: boolean;
};

export const ExploreMapLegend = ({ withSwitches }: ExploreMapLegendProps) => {
    return (
        <MapController position="LEFT_BOTTOM">
            <ExploreLegend withSwitches={withSwitches} />
        </MapController>
    );
};
