import React, { ComponentProps, PropsWithChildren, ReactNode } from 'react';
import { Dropdown, Menu } from 'antd';
import { Button } from 'ui-components/button/button';
import { ChevronIcon } from 'components/assets/Icons/Icons';
import { Tooltip } from 'ui-components/tooltip/tooltip';
import { DropDownProps } from 'antd/lib/dropdown';
import classnames from 'classnames';
import { DefaultEventData } from 'types/splunk/events';
import { LockedFunctionalityOverlay } from 'extensions/locked-functionality-overlay';
import { MenuInfo } from 'rc-menu/lib/interface';
import styles from './dropdown-menu-button.module.scss';
import { GrayLockIcon } from 'components/assets/Icons/GrayLockIcon';
import classNames from 'classnames';

export type DropdownOption = {
    key: string;
    icon?: ReactNode;
    label?: string;
    disabled?: boolean;
    visible?: boolean;
    tooltip?: string;
    onClick?: (info?: MenuInfo) => void;
    isLocked?: boolean;
    tooltipPlacement?: ComponentProps<typeof Tooltip>['placement'];
    lockedClass?: string;
    useTooltipParentContainer?: boolean;
};

type DropdownMenuButtonProps = Partial<DropDownProps> & {
    options: DropdownOption[];
    disableTransitions?: boolean;
    defaultEventData?: DefaultEventData;
    tooltipPlacement?: ComponentProps<typeof Tooltip>['placement'];
    menuContainerClass?: string;
    lockedClass?: string;
};

const renderItem = ({
    key,
    icon,
    label,
    disabled,
    tooltip,
    onClick,
    isLocked,
    visible,
    tooltipPlacement,
    lockedClass,
    useTooltipParentContainer,
}: DropdownOption) => {
    if (!visible) {
        return null;
    }

    let item = (
        <div className={styles.menuItem} data-testid={key}>
            <span className={styles.icon}>{icon}</span>
            {label}
        </div>
    );

    if (isLocked) {
        item = (
            <div className={classNames(styles.menuItem, lockedClass)} data-testid={`${key}-locked`}>
                <LockedFunctionalityOverlay
                    isLocked={isLocked}
                    className={styles.lockedFunctionalityOverlay}
                >
                    <GrayLockIcon className={styles.lock} />
                    <span className={styles.icon}>{icon}</span>
                    {label}
                </LockedFunctionalityOverlay>
            </div>
        );
    }

    return (
        <Menu.Item key={key} disabled={disabled} onClick={onClick}>
            <Tooltip
                small
                title={tooltip}
                placement={tooltipPlacement}
                useParentContainer={useTooltipParentContainer}
            >
                {item}
            </Tooltip>
        </Menu.Item>
    );
};

const defaultButton = (
    <Button
        type="brand-primary-full"
        className={styles.triggerButton}
        data-testid="open-report-dropdown-btn"
    >
        Open Report <ChevronIcon rotate={-90} />
    </Button>
);

export const DropdownMenuButton = ({
    options,
    disableTransitions = false,
    defaultEventData,
    trigger = ['click'],
    children,
    tooltipPlacement,
    menuContainerClass,
    lockedClass,
    ...dropdownProps
}: PropsWithChildren<DropdownMenuButtonProps>) => {
    const overlay = (
        <Menu>
            {options.map((option) =>
                renderItem({
                    ...option,
                    tooltipPlacement,
                    lockedClass,
                }),
            )}
        </Menu>
    );

    return (
        <Dropdown
            {...dropdownProps}
            trigger={trigger}
            overlayClassName={classnames(styles.menuContainer, menuContainerClass, {
                [styles.disableTransitions]: disableTransitions,
            })}
            overlay={overlay}
            placement="bottomRight"
            arrow={dropdownProps?.arrow ?? true}
            align={{ offset: [8, -1] }}
        >
            {children || defaultButton}
        </Dropdown>
    );
};
