import React from 'react';
import { Tooltip } from 'ui-components/tooltip/tooltip';
import classNames from 'classnames';
import { getCategoryStaticIconUrl, getCategoryIconUrl } from 'core/entities/category/utils';
import { useSelectAvailableCategoriesData } from 'store/configuration/categories/selectors/selectors-hooks';
import { SquareLogo } from 'ui-components/square-logo/square-logo';
import styles from './chain-cell.module.scss';

type ChainCellProps = {
    name: string;
    category: string;
    subCategory: string;
    logoUrl?: string;
    className?: string;
};

export const ChainCell = ({ name, category, subCategory, logoUrl, className }: ChainCellProps) => {
    const categoryAndSubCategory = `${category}, ${subCategory}`;
    const categoriesIconData = useSelectAvailableCategoriesData();

    const logoImgUrl = getCategoryIconUrl({
        categoriesIconData,
        primaryPoiCategory: category,
        subPoiCategory: subCategory,
    });

    const groupCategory = categoriesIconData.find((group) =>
        group.children?.find((primary) => primary?.label === category),
    );

    const categoryIcon = !logoImgUrl
        ? getCategoryStaticIconUrl({
              primaryPoiCategory: category,
              subPoiCategory: subCategory,
              groupPoiCategory: groupCategory?.label,
          })
        : undefined;

    return (
        <div className={classNames(styles.cellWrapper, className)}>
            <SquareLogo name={name} logoUrl={logoUrl} icon={logoImgUrl || categoryIcon} />
            <div className={styles.metadata}>
                <Tooltip
                    placement="top"
                    overlay={
                        <div data-testid={'chain-name-tooltip'}>
                            <span>{name}</span>
                            <br />
                            <span>{categoryAndSubCategory}</span>
                        </div>
                    }
                >
                    <div data-testid={'chain-cell-name-and-category'}>
                        <div className={styles.name}>{name}</div>
                        <div className={styles.categories}>{categoryAndSubCategory}</div>
                    </div>
                </Tooltip>
            </div>
        </div>
    );
};
