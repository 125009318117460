import { customBaseUrl } from './default-endpoint-url.state';

export const setDefaultEndpointUrl = () => {
    const { REACT_APP_API_ENDPOINT, REACT_APP_GATEWAY } = process.env;

    customBaseUrl.beApiEndpoint = REACT_APP_API_ENDPOINT as string;
    customBaseUrl.apigwEndpoint = REACT_APP_GATEWAY as string;
};

export const getAppApiEndpoint = () => customBaseUrl.beApiEndpoint;
export const getAppGatewayEndpoint = () => customBaseUrl.apigwEndpoint;
