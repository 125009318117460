import { useEffect, useRef } from 'react';
import { useRelativeUrl } from 'hooks/use-relative-url';
import { getPageFromPath } from 'utils/get-from-url/get-from-url';

const REFRESH_INTERVAL_HOURS = 12;
const REFRESH_INTERVAL_MS = REFRESH_INTERVAL_HOURS * 60 * 60 * 1000;
const REPORT_PAGES = ['insights', 'chains', 'industry'];

export const usePageRefresh = () => {
    const urlPath = useRelativeUrl();
    const timeoutIdRef = useRef<NodeJS.Timeout>();

    const currentPage = getPageFromPath(urlPath);

    useEffect(() => {
        const openTimeKey = 'tabOpenTime';

        const storeOpenTime = () => {
            localStorage.setItem(openTimeKey, Date.now().toString());
        };

        const checkAndRefresh = () => {
            const storedTime = parseInt(localStorage.getItem(openTimeKey) || '0');
            const currentTime = Date.now();
            const elapsedTime = currentTime - storedTime;

            if (elapsedTime > REFRESH_INTERVAL_MS) {
                window.location.reload();
            } else {
                timeoutIdRef.current = setTimeout(
                    checkAndRefresh,
                    REFRESH_INTERVAL_MS - elapsedTime,
                );
            }
        };

        if (REPORT_PAGES.includes(currentPage)) {
            if (!localStorage.getItem(openTimeKey)) {
                storeOpenTime();
            }
            const initialTimeout =
                REFRESH_INTERVAL_MS -
                (Date.now() - parseInt(localStorage.getItem(openTimeKey) || '0'));
            timeoutIdRef.current = setTimeout(checkAndRefresh, initialTimeout);
        } else {
            localStorage.removeItem(openTimeKey);
            if (timeoutIdRef.current) {
                clearTimeout(timeoutIdRef.current);
            }
        }

        const removeItem = () => {
            localStorage.removeItem(openTimeKey);
        };

        window.addEventListener('beforeunload', removeItem);

        return () => {
            if (timeoutIdRef.current) {
                clearTimeout(timeoutIdRef.current);
            }
            window.removeEventListener('beforeunload', removeItem);
            localStorage.removeItem(openTimeKey);
        };
    }, [currentPage]);
};
