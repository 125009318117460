import { PlacerEntityModels } from 'core/entities';
import { ContentWithPOIs } from 'extensions/widget/hooks/use-widget-hooks';
import { VarianceCompareContentType } from 'extensions/widgets/variance-compare-widget/content/variance-compare-content-hooks';
import type {
    Attribute,
    Attributes,
    BasePlaceEntity,
    Complex,
    Dictionary,
    FilterDates,
    PlacerEntityModel,
    Venue,
} from '@placer-ui/types';
import { WidgetPoi } from 'extensions/widget/models/widget-poi-provider-type';
import { useSelector } from 'react-redux';
import { selectReportAccess } from 'store/configuration/report-access/selectors';
import { VenueWithFilters } from 'features/insights/store/poi/poi-types';
import { get, set } from 'lodash';
import { FilterType } from '@placer-ui/types/src/entities/filter';
import { cloneDeep } from 'utils/clone-deep/clone-deep';
import {
    HEAVY_RISKY_FT_REMOVED,
    LIGHT_RISKY_FT_REMOVED,
} from 'features/insights/hooks/use-privacy-related';
import { getFeatureFlags } from 'core/flow-control';

export function getDatesFromWidgetsData(
    widgetsData?: ContentWithPOIs<VarianceCompareContentType>[],
): FilterDates[] {
    if (widgetsData) {
        const widgets = Object.values(widgetsData!);
        return widgets?.map((widget) => ({
            date_start: widget.filters.date_start,
            date_end: widget.filters.date_end,
        }));
    }

    return [];
}

export function getDatesFromReportEntities(
    reportEntities?: Dictionary<PlacerEntityModels>,
): FilterDates[] {
    if (reportEntities) {
        const placerEntityModels = Object.values(reportEntities!);
        return placerEntityModels?.map((placerEntityModel) => ({
            date_start: placerEntityModel.filters.date_start,
            date_end: placerEntityModel.filters.date_end,
        }));
    }

    return [];
}

export const isNearbyActivity = (poi: WidgetPoi | PlacerEntityModel<Complex>) => {
    return !!poi.filters?.config?.ft_radius;
};

export const isSalesEnabledForPoi = (poi: PlacerEntityModel<BasePlaceEntity>) => {
    const { enable_performance_improvements_skip_availability_ff } = getFeatureFlags();
    if ( !enable_performance_improvements_skip_availability_ff ) {
        return true;
    }
    return !!poi.originalEntity.dataSourcesAvailability?.retail_sales;
};

export const useIsReportAccessRestrictedArea = (poi: PlacerEntityModel<Venue>[]) => {
    const reportAccess = useSelector(selectReportAccess);
    return !!poi.find((entity) => {
        const reportAccessByUID = reportAccess[entity.uid]?.access;
        return reportAccessByUID?.warnings?.includes('restricted_area');
    });
};

export const useIsReportAccessLightRisk = (poi: PlacerEntityModel<Venue>[]) => {
    const reportAccess = useSelector(selectReportAccess);
    return !!poi.find((entity) => {
        const reportAccessByUID = reportAccess[entity.uid]?.access;
        return reportAccessByUID?.warnings?.includes(LIGHT_RISKY_FT_REMOVED);
    });
};

export const useIsReportAccessHeavyRisk = (poi: PlacerEntityModel<Venue>[]) => {
    const reportAccess = useSelector(selectReportAccess);
    return !!poi.find((entity) => {
        const reportAccessByUID = reportAccess[entity.uid]?.access;
        return reportAccessByUID?.warnings?.includes(HEAVY_RISKY_FT_REMOVED);
    });
};

export const updateVenueAttribute = (venue: VenueWithFilters, attribute: Attribute) => {
    const updateVenue = cloneDeep(venue);
    const attributes = get(updateVenue, 'customData.filter.attributes', ['all']) as Attribute[];

    const indexOfFilterToReplace = attributes.findIndex((attr) => attr[1] === attribute[1]);

    if (indexOfFilterToReplace !== -1) {
        attributes.splice(indexOfFilterToReplace, 1, attribute);
    } else {
        attributes.push(attribute);
    }

    set(updateVenue, 'customData.filter.attributes', attributes);

    return updateVenue;
};

export const removeVenueAttribute = (venue: VenueWithFilters, filterType: FilterType) => {
    const updateVenue = cloneDeep(venue);
    const attributes = get(updateVenue, 'customData.filter.attributes', undefined) as (
        | Attribute
        | 'all'
    )[];

    const indexOfFilterToReplace = attributes?.findIndex((attr) => attr[1] === filterType);

    if (indexOfFilterToReplace) {
        attributes.splice(indexOfFilterToReplace, 1);
        if (attributes.length === 1 && attributes[0] === 'all') {
            delete updateVenue.customData.filter.attributes;
        } else {
            set(updateVenue, 'customData.filter.attributes', attributes);
        }
    }

    return updateVenue;
};

export const updatePOIAttribute = <T extends { filters: { attributes?: Attributes } }>(
    poi: T,
    attribute: Attribute,
) => {
    const updateChain = cloneDeep(poi);
    const attributes = get(updateChain, 'filters.attributes', ['all']) as Attributes;

    const indexOfFilterToReplace = attributes.findIndex((attr) => attr[1] === attribute[1]);

    if (indexOfFilterToReplace !== -1) {
        attributes.splice(indexOfFilterToReplace, 1, attribute);
    } else {
        attributes.push(attribute);
    }

    set(updateChain, 'filters.attributes', attributes);

    return updateChain;
};

export const removePOIAttribute = <T extends { filters: { attributes?: Attributes } }>(
    poi: T,
    filterType: FilterType,
) => {
    const updateChain = cloneDeep(poi);
    const attributes = get(updateChain, 'filters.attributes', undefined) as Attributes;

    const indexOfFilterToReplace = attributes?.findIndex((attr) => attr[1] === filterType);

    if (indexOfFilterToReplace) {
        attributes.splice(indexOfFilterToReplace, 1);
        if (attributes.length === 1 && attributes[0] === 'all') {
            delete updateChain.filters.attributes;
        } else {
            set(updateChain, 'filters.attributes', attributes);
        }
    }

    return updateChain;
};
