/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

type HolidaysObservanceIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type HolidaysObservanceIconProps = SVGProps<SVGSVGElement> & HolidaysObservanceIconUniqueProps;

export const HolidaysObservanceIcon = function HolidaysObservanceIcon({
    width = 32,
    height = 32,
    ...props
}: HolidaysObservanceIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_146_69)">
                <path
                    d="M15.3292 7.24657C15.5281 6.58024 16.4717 6.58024 16.6707 7.24657L18.3862 12.9926C18.4765 13.2951 18.7581 13.4997 19.0737 13.4921L25.0686 13.3481C25.7638 13.3314 26.0554 14.2288 25.4832 14.6239L20.5485 18.0311C20.2887 18.2104 20.1811 18.5415 20.2859 18.8393L22.2754 24.4963C22.5062 25.1523 21.7428 25.707 21.1902 25.2848L16.4248 21.6446C16.174 21.4529 15.8258 21.4529 15.575 21.6446L10.8097 25.2848C10.2571 25.707 9.49366 25.1523 9.72438 24.4963L11.7139 18.8393C11.8187 18.5415 11.7111 18.2104 11.4513 18.0311L6.51663 14.6239C5.94439 14.2288 6.23598 13.3314 6.93117 13.3481L12.9261 13.4921C13.2417 13.4997 13.5233 13.2951 13.6137 12.9926L15.3292 7.24657Z"
                    fill="#5E63E5"
                />
                <path
                    d="M17 1C17 0.447715 16.5523 0 16 0C15.4477 0 15 0.447715 15 1V3C15 3.55228 15.4477 4 16 4C16.5523 4 17 3.55228 17 3V1Z"
                    fill="#B0CCE4"
                />
                <path
                    d="M31 17C31.5523 17 32 16.5523 32 16C32 15.4477 31.5523 15 31 15H29C28.4477 15 28 15.4477 28 16C28 16.5523 28.4477 17 29 17H31Z"
                    fill="#B0CCE4"
                />
                <path
                    d="M4 16C4 16.5523 3.55228 17 3 17H1C0.447715 17 0 16.5523 0 16C0 15.4477 0.447715 15 1 15H3C3.55228 15 4 15.4477 4 16Z"
                    fill="#B0CCE4"
                />
                <path
                    d="M5.39333 6.80769C5.78385 7.19822 6.41702 7.19822 6.80754 6.80769C7.19807 6.41717 7.19807 5.784 6.80754 5.39348L5.39333 3.97927C5.0028 3.58874 4.36964 3.58874 3.97911 3.97927C3.58859 4.36979 3.58859 5.00295 3.97911 5.39348L5.39333 6.80769Z"
                    fill="#B0CCE4"
                />
                <path
                    d="M25.1923 6.80779C24.8018 6.41726 24.8018 5.7841 25.1923 5.39357L26.6065 3.97936C26.997 3.58883 27.6302 3.58883 28.0207 3.97936C28.4113 4.36988 28.4113 5.00305 28.0207 5.39357L26.6065 6.80779C26.216 7.19831 25.5828 7.19831 25.1923 6.80779Z"
                    fill="#B0CCE4"
                />
                <path
                    d="M8.78116 24.1646L9.54245 22H0V32H32V22H22.4576L23.2189 24.1646C23.7792 25.7578 21.9253 27.1048 20.5832 26.0796L16 22.5784L11.4169 26.0796C10.0748 27.1048 8.22085 25.7578 8.78116 24.1646Z"
                    fill="#B0CCE4"
                />
            </g>
            <defs>
                <clipPath id="clip0_146_69">
                    <rect width="32" height="32" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
HolidaysObservanceIcon.__tags = ['holiday', 'event', 'star'] as string[];
