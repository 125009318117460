/* eslint-disable max-len */
import React from 'react';

export const SgRetailIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
        >
            <rect x="5" y="12" width="5" height="7" fill="white" />
            <path
                d="M3.75 8.28125C1.94125 8.28125 0.46875 6.80875 0.46875 5V0.46875H19.5312V5C19.5312 6.80875 18.0588 8.28125 16.25 8.28125C14.7902 8.28125 13.5494 7.32209 13.125 6.00112C12.7006 7.32209 11.4598 8.28125 10 8.28125C8.54019 8.28125 7.29941 7.32209 6.875 6.00112C6.45059 7.32209 5.20981 8.28125 3.75 8.28125Z"
                fill="#E1E2FF"
            />
            <path
                d="M1.94444 7.82538V19.1667H18.0556V7.82538C18.371 7.63855 18.6526 7.40042 18.8889 7.12258V20.0001H1.10766L1.11111 7.12258C1.34738 7.40042 1.62902 7.63855 1.94444 7.82538Z"
                fill="#5E63E5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 0V5C0 7.03887 1.71113 8.69792 3.75 8.69792C5.06313 8.69792 6.21872 8.00974 6.875 6.97514C7.53129 8.00974 8.68687 8.69792 10 8.69792C11.3131 8.69792 12.4687 8.00974 13.125 6.97514C13.7813 8.00974 14.9369 8.69792 16.25 8.69792C18.2889 8.69792 20 7.03887 20 5V0H0ZM12.5521 0.837674H7.13542V5C7.13542 6.57863 8.42137 7.86458 10 7.86458C11.2301 7.86458 12.2825 7.08375 12.6872 5.99205C12.5992 5.67623 12.5521 5.34351 12.5521 5V0.837674ZM6.30208 5V0.837674H0.833333V5C0.833333 6.57863 2.17137 7.86458 3.75 7.86458C4.98012 7.86458 6.03252 7.08375 6.43724 5.99206C6.34918 5.67623 6.30208 5.34351 6.30208 5ZM13.3854 5V0.837674H19.1623V5C19.1623 6.57863 17.8286 7.86458 16.25 7.86458C14.6714 7.86458 13.3854 6.57863 13.3854 5Z"
                fill="#5E63E5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.55556 12.0833V19.1667H9.16667V12.0833H5.55556ZM5.27778 11.25C4.97095 11.25 4.72222 11.4987 4.72222 11.8056V20H10V11.8056C10 11.4987 9.75127 11.25 9.44444 11.25H5.27778Z"
                fill="#5E63E5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.4583 14.8611C11.4583 14.631 11.6449 14.4444 11.875 14.4444H15.625C15.8551 14.4444 16.0417 14.631 16.0417 14.8611C16.0417 15.0912 15.8551 15.2778 15.625 15.2778H11.875C11.6449 15.2778 11.4583 15.0912 11.4583 14.8611Z"
                fill="#5E63E5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.4583 12.6389C11.4583 12.4088 11.6449 12.2222 11.875 12.2222H15.625C15.8551 12.2222 16.0417 12.4088 16.0417 12.6389C16.0417 12.869 15.8551 13.0556 15.625 13.0556H11.875C11.6449 13.0556 11.4583 12.869 11.4583 12.6389Z"
                fill="#5E63E5"
            />
        </svg>
    );
};
