import { createSelector } from '@reduxjs/toolkit';

import {
    createSelectEntitiesHasTheSameDateFilter,
    createSelectHasNotPurchasedEntity,
} from 'store/entities';
import { chainsReportChainsEntityAdapter } from '../chains-report-chains-entity-adapter';
import { selectIndustry } from 'features/industry/store/common';
import { createSelectChainsEntitiesAPIModel } from './select-chains-entity-api-model';
import {
    selectChainsLimitedReportDefaultDateRage,
    selectDateLimits,
} from 'store/selectors/app-selectors';
import { getRegionFullName, isRegionNationwide } from 'core/services/regions-service';
import { areDatesEqual } from 'utils/date/date';
import { EntityAccessLevel } from 'core/constants';
import { TagEntityModel } from 'core/entities';
import { chainsAccessLevelService } from 'features/industry/services/chains-access-levels-service';
import { ChainReportEntity } from 'core/entities/user/user-settings';
import { WidgetPoiProviderType } from 'extensions/widget/models/widget-poi-provider-type';
import { useGetIndustryPrintEntitiesParser } from 'features/industry/store/parse-industry-print-entities';
import { selectChainsReportOptions } from 'features/industry/store/chains-report-search-store/selectors/select-chains-search-section';

export const chainsEntitySelectors = chainsReportChainsEntityAdapter.getSelectors(selectIndustry);

export const {
    selectAll: selectChainsReportAllChainsEntityModels,
    selectById: selectChainsReportChainsEntityModelsByUId,
    selectIds: selectChainsReportChainsEntityModelsUids,
    selectTotal: selectChainsReportTotalCount,
    selectEntities: selectChainsReportEntities,
} = chainsEntitySelectors;

export const selectChainsEntityDates = createSelector(
    selectChainsReportAllChainsEntityModels,
    (chains) =>
        chains.map(({ uid, filters, color, originalEntity }) => {
            return {
                uid,
                filters,
                name: originalEntity.name,
                color,
                id: originalEntity.id,
                region: isRegionNationwide(originalEntity.area)
                    ? originalEntity.area.type
                    : getRegionFullName(originalEntity.area),
            };
        }),
);

export const selectEntitiesIsTheSameDateFilter = createSelectEntitiesHasTheSameDateFilter(
    selectChainsReportAllChainsEntityModels,
);

export const selectChainsDateAndState = createSelector(
    selectDateLimits,
    selectChainsEntityDates,
    selectChainsReportEntities,
    selectEntitiesIsTheSameDateFilter,
    selectChainsReportOptions,
    (dateLimits, chains, chainsEntities, isSameDateFilter, { maxDate }) => ({
        dateLimits: maxDate
            ? {
                  ...dateLimits,
                  maxDate,
              }
            : dateLimits,
        chains,
        chainsEntities,
        startDate: chains[0]?.filters.date_start,
        endDate: chains[0]?.filters.date_end,
        dateLabel: chains[0]?.filters.date_label,
        isSameDateFilter,
    }),
);

export const selectChainsReportIsLimitedView = createSelectHasNotPurchasedEntity(
    selectChainsReportChainsEntityModelsByUId,
);

export const selectChainsEntitiesAPIModel = createSelectChainsEntitiesAPIModel(
    selectChainsReportEntities,
);

export const chainsWidgetEntitySelectors: WidgetPoiProviderType = {
    adapterType: 'industry',
    ...chainsEntitySelectors,
    selectEntitiesAPIModel: selectChainsEntitiesAPIModel,
    selectRawEntities: selectChainsReportAllChainsEntityModels,
    useGetInitializeEntityStoreCallback: useGetIndustryPrintEntitiesParser,
};

type AccessLevelToChainsMap = {
    [EntityAccessLevel.FULL]: TagEntityModel[];
    [EntityAccessLevel.LIMITED]: TagEntityModel[];
    [EntityAccessLevel.MISSING_PERMISSIONS]: TagEntityModel[];
};
export const selectAccessLevelToChainsMap = createSelector(
    selectChainsReportAllChainsEntityModels,
    (chainsEntityModels) => {
        const uiModeToChainsMap: AccessLevelToChainsMap = {
            [EntityAccessLevel.FULL]: [],
            [EntityAccessLevel.LIMITED]: [],
            [EntityAccessLevel.MISSING_PERMISSIONS]: [],
        };

        chainsEntityModels.forEach((chainEntityModel) => {
            if (chainEntityModel) {
                const uiModeOfAChain =
                    chainsAccessLevelService.getAccessLevelOfEntity(chainEntityModel);
                uiModeToChainsMap[uiModeOfAChain].push(chainEntityModel);
            }
        });

        return uiModeToChainsMap;
    },
);

export const selectChainsReportHasFreemiumDefaultDaterange = createSelector(
    selectChainsReportAllChainsEntityModels,
    selectChainsLimitedReportDefaultDateRage,
    (chainsReportEntities, { limitedDefaultStartDate, limitedDefaultEndDate }) => {
        return chainsReportEntities.every(({ originalEntity, filters }) => {
            const { purchased, enable_limited_report } = originalEntity;

            if (purchased) {
                return true;
            }

            if (!purchased && !enable_limited_report) {
                return false;
            }

            const startDate = filters?.date_start;
            const endDate = filters?.date_end;

            return (
                areDatesEqual(limitedDefaultStartDate, startDate) &&
                areDatesEqual(limitedDefaultEndDate, endDate)
            );
        });
    },
);

export const selectAccessLevelToNumberOfChainsMap = createSelector(
    selectAccessLevelToChainsMap,
    (accessLevelToChainsMap) => {
        return {
            [EntityAccessLevel.MISSING_PERMISSIONS]:
                accessLevelToChainsMap[EntityAccessLevel.MISSING_PERMISSIONS].length,
            [EntityAccessLevel.LIMITED]: accessLevelToChainsMap[EntityAccessLevel.LIMITED].length,
            [EntityAccessLevel.FULL]: accessLevelToChainsMap[EntityAccessLevel.FULL].length,
        };
    },
);

export const selectChainsReportAccessLevel = createSelector(
    selectAccessLevelToNumberOfChainsMap,
    (accessLevelToNumberOfChainsMap) => {
        const numberOfChainsWithMissingPermissionsAL =
            accessLevelToNumberOfChainsMap[EntityAccessLevel.MISSING_PERMISSIONS];
        if (numberOfChainsWithMissingPermissionsAL > 0) {
            return EntityAccessLevel.MISSING_PERMISSIONS;
        }

        const numberOfChainsWithLimitedAL =
            accessLevelToNumberOfChainsMap[EntityAccessLevel.LIMITED];
        if (numberOfChainsWithLimitedAL > 0) {
            return EntityAccessLevel.LIMITED;
        }

        return EntityAccessLevel.FULL;
    },
);

export const selectIsChainsReportLimitedWithDefaultDaterange = createSelector(
    selectChainsReportHasFreemiumDefaultDaterange,
    selectChainsReportAccessLevel,
    (chainsReportHasFreemiumDefaultDaterange, reportAccessLevel) => {
        if (
            reportAccessLevel === EntityAccessLevel.LIMITED &&
            chainsReportHasFreemiumDefaultDaterange
        ) {
            return true;
        }

        return false;
    },
);

export const selectIsChainsReportLimited = createSelector(
    selectChainsReportAccessLevel,
    (reportAccessLevel) => {
        return reportAccessLevel === EntityAccessLevel.LIMITED;
    },
);

export const selectChainsReportState = createSelector(
    selectChainsReportAllChainsEntityModels,
    (allEntities): ChainReportEntity[] =>
        allEntities.map((entity) => {
            return {
                id: entity.originalEntity.id,
                date_start: entity.filters.date_start,
                date_end: entity.filters.date_end,
                ...(entity.filters.date_label && { date_label: entity.filters.date_label }),
                ...(entity.filters.attributes ? { attributes: entity.filters.attributes } : {}),
            };
        }),
);
