export const MARKETPLACE_EXTERNAL_URL = process.env.REACT_APP_MARKETPLACE_URL as string;
export const MARKETPLACE_CATALOG_PATH = 'catalogs/applications';
// NOTE:
// adding a new app path requires
// `?hide_back=true`
// parameter to block navigation in the iframe.
export const MARKETPLACE_SITE_SELECTION_PATH = 'app/site-selection?hide_back=true';
export const MARKETPLACE_RETAIL_SALES_PATH = 'app/retail-sales-report?hide_back=true';
export const MARKETPLACE_SALES_FORECAST_PATH = 'app/sales-forecasting?hide_back=true';
export const MARKETPLACE_CONTACT_INFO_PATH = 'app/contacts?hide_back=true';
export const MARKETPLACE_SALES_FORECAST_CUSTOM_MODELS_PATH =
    'app/sales-forecasting-custom-models?hide_back=true';
export const MARKETPLACE_CHAIN_DEMOGRAPHICS_PATH = 'app/chains-reports-expanded?hide_back=true';
export const MARKETPLACE_EVENTS_PATH = 'app/events?hide_back=true';
export const MARKETPLACE_RETAIL_LEASE_TRAC = 'app/retailer-contact-info?hide_back=true';
export const MARKETPLACE_FRANDATA = 'app/franchisor-and-franchisee-contact-info?hide_back=true';
export const MARKETPLACE_SIS_REGION_CHAIN_EXPANSION =
    'app/site-selection-region-and-chain-expansion?hide_back=true';
