import { combineReducers } from 'redux';
import { chainsReportSearchReducer } from './chains-report-search-store';
import { chainsReducer } from './chains-report-chains-store';
import { chainsReportUiStateReducer } from './chains-report-ui-state-store';

const chainsUIReducer = combineReducers({
    search: chainsReportSearchReducer,
    reportUiState: chainsReportUiStateReducer,
});

export const chainsReportReducer = {
    chainsUi: chainsUIReducer,
    chains: chainsReducer,
};
