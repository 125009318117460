import { API } from 'API';
import { RecentEntitiesModel } from 'core/services/recent-entities-service/recent-entities-model';
import { prepareRecentEntitiesToSave } from 'core/services/recent-entities-service/recent-entities-service';
import { updateUserCustomSettingsActionCreator } from 'store/auth/actions/auth-action-creators';
import {
    userCustomSettingsSelector,
    userCustomSettingsRecentPoiIdsSelector,
} from 'store/selectors/app-selectors';
import { AppDispatch, AppState } from 'store/store';

export const setCustomUserSettingsRecentEntities =
    (poi: RecentEntitiesModel) => (dispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();
        const customSettings = userCustomSettingsSelector(state);

        if (!customSettings) {
            return;
        }

        const recentPois = userCustomSettingsRecentPoiIdsSelector(state);
        const recentEntitiesToSave = prepareRecentEntitiesToSave(recentPois, poi);

        const newCustomSettings = {
            recent_poi_ids: recentEntitiesToSave,
        };

        API.user.setUserCustomSettings(newCustomSettings);

        dispatch(
            updateUserCustomSettingsActionCreator({
                key: 'recent_poi_ids',
                value: recentEntitiesToSave,
            }),
        );
    };
