import React from 'react';
import styles from './benefit.module.scss';
import { MenuText } from '@placer-ui/components';
import { BenefitIcon } from 'components/freemium/icons/benefit-icon/benefit-icon';

type BenefitProps = {
    label: string;
};

export const Benefit = ({ label }: BenefitProps) => {
    return (
        <div className={styles.root}>
            <BenefitIcon />
            <MenuText>{label}</MenuText>
        </div>
    );
};
