import { merge } from 'lodash/fp';
import { ErrorContent, WidgetApiContent } from 'extensions/widget/store/widget-constants';
import {
    useWidgetApiContent,
    useWidgetApiIds,
    useWidgetApis,
    useWidgetBlockingApiIds,
    useWidgetEntireContent,
    useWidgetPOIsList,
} from 'extensions/widget/hooks/use-widget-hooks';
import { ERRORS } from 'API/common/constants';
import type { Dictionary } from '@placer-ui/types';
import { useSelectorWithProps } from 'utils/structured-selector/structured-selector';
import { selectHasUserPermission } from 'store/selectors/app-selectors';
import { DEFAULT_API_ID } from 'extensions/widget/models/widget-apis';
import { useIsWidgetInitializationLoading } from 'extensions/widget/hooks/use-widget-loading-hooks';
import { useMemo } from 'react';

/**
 * These error types will be ignored by the widget top-level error wrapper
 */
const silentErrors: { [error: string]: boolean } = {
    [ERRORS.INSUFFICIENT_DATA]: true,
    [ERRORS.NO_DATA]: true,
    [ERRORS.NO_CHAIN_COVERAGE]: true,
};

/**
 * These error types will block the widget content
 */
const contentBlockingErrors: { [error: string]: boolean } = {
    [ERRORS.INSUFFICIENT_DATA]: true,
};

const getApiErrorsByPoi = (
    apiContent: WidgetApiContent,
    pois: string[],
    includeSilentErrors: boolean = true,
) => {
    return pois.reduce<Dictionary<ErrorContent>>((apiErrors, poiId) => {
        const error = apiContent[poiId] as ErrorContent | undefined;
        if (error?.isError && (!silentErrors[error?.type] || includeSilentErrors)) {
            apiErrors[poiId] = apiContent[poiId];
        }
        return apiErrors;
    }, {});
};

export const useIsAllPOIsHaveError = (): ErrorContent | undefined => {
    const widgetPOIsList = useWidgetPOIsList();
    const widgetEntireContent = useWidgetEntireContent();
    const apiIds = useWidgetApiIds();

    const everyPoiHaveSilentError = useMemo(() => {
        if (!widgetEntireContent) return;
        return apiIds.every((apiId) =>
            widgetPOIsList.every((poiId) => {
                const poiContent = widgetEntireContent[apiId]?.[poiId] as ErrorContent;

                return poiContent?.isError && silentErrors[poiContent.type];
            }),
        );
    }, [apiIds, widgetEntireContent, widgetPOIsList]);

    return everyPoiHaveSilentError && widgetEntireContent
        ? (widgetEntireContent[apiIds[0]]?.[widgetPOIsList[0]] as ErrorContent)
        : undefined;
};

export const useWidgetBlockingError = (): ErrorContent | undefined => {
    const widgetPOIsList = useWidgetPOIsList();
    const widgetEntireContent = useWidgetEntireContent();
    const blockingApis = useWidgetBlockingApiIds();
    const commonSilentError = useIsAllPOIsHaveError();

    const blockingErrors = blockingApis.reduce<ErrorContent[]>((errors, apiId) => {
        const apiContent = widgetEntireContent?.[apiId];
        if (apiContent) {
            const apiErrorsByPoi = getApiErrorsByPoi(apiContent, widgetPOIsList, false);
            errors.push(...Object.values(apiErrorsByPoi));
        }
        return errors;
    }, []);

    return blockingErrors[0] || commonSilentError;
};

export const useAllBlockingApisErrorsByPoiId = (
    includeNonBlockingAPIs?: boolean,
): Dictionary<ErrorContent> => {
    const widgetPOIsList = useWidgetPOIsList();
    const widgetEntireContent = useWidgetEntireContent();
    const blockingApis = useWidgetBlockingApiIds();
    const allApis = useWidgetApiIds();

    return useMemo(
        () =>
            blockingApis
                .concat(includeNonBlockingAPIs ? allApis : [])
                .reduce<Dictionary<ErrorContent>>((errorsByPoi, apiId) => {
                    const apiContent = widgetEntireContent?.[apiId];
                    if (apiContent) {
                        const apiErrorsByPoi = getApiErrorsByPoi(apiContent, widgetPOIsList);
                        errorsByPoi = merge(errorsByPoi, apiErrorsByPoi);
                    }
                    return errorsByPoi;
                }, {}),
        [blockingApis, widgetEntireContent, widgetPOIsList, includeNonBlockingAPIs, allApis],
    );
};

export const useIsWidgetApiContentError = (apiId: string) => {
    const apis = useWidgetApis();
    const apiContent = useWidgetApiContent(apis ? apiId : DEFAULT_API_ID);
    const widgetPOIsList = useWidgetPOIsList();
    const errorPoiId = widgetPOIsList.find(
        (poiId) => (apiContent?.[poiId] as ErrorContent)?.isError,
    );

    if (apiContent && errorPoiId) return apiContent[errorPoiId] as ErrorContent;
};

/**
 * The hooks below can be deprecated once all widgets are migrated to v2
 * and we remove the error permission
 */

/** For backward compatibility with permission */
export const useShouldHideEntireWidgetError = () =>
    useSelectorWithProps(
        {
            permission: 'hide_entire_widget_blocking_error',
        },
        selectHasUserPermission,
    );

/** For backward compatibility with permission */
export const useEntireWidgetError = () => {
    const error = useWidgetBlockingError();
    const hideEntireWidgetError = useShouldHideEntireWidgetError();

    return hideEntireWidgetError ? undefined : error;
};

/** For backward compatibility of non-blocking errors
 * Previous implementation addressed INSUFFICIENT_DATA specifically as an error
 * that should block the widget content
 */
export const useContentBlockingError = (): ErrorContent | undefined => {
    const hideEntireWidgetError = useShouldHideEntireWidgetError();
    const widgetBlockingError = useWidgetBlockingError();
    const widgetPOIsList = useWidgetPOIsList();
    const widgetEntireContent = useWidgetEntireContent();
    const isLoading = useIsWidgetInitializationLoading();
    const blockingApis = useWidgetBlockingApiIds();

    if (hideEntireWidgetError) {
        return widgetBlockingError;
    } else if (!widgetEntireContent || isLoading) {
        return;
    } else {
        const errors = blockingApis.reduce<ErrorContent[]>((errors, apiId) => {
            const apiErrors = getApiErrorsByPoi(widgetEntireContent[apiId], widgetPOIsList);
            const contentBlockingError = Object.values(apiErrors).find(
                (error) => contentBlockingErrors[error?.type],
            );
            if (contentBlockingError) {
                errors.push(contentBlockingError);
            }
            return errors;
        }, []);

        return errors[0];
    }
};
