import React, { ComponentProps, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import {
    RecentEntitiesModel,
    RecentEntitiesModelWithFreeTextOption,
} from 'core/services/recent-entities-service/recent-entities-model';
import { ReportTypes } from 'core/constants/report-type';
import type { PlaceCollection } from '@placer-ui/types';
import {
    selectTopSearchIsLoading,
    selectTopSearchNoResults,
    selectTopSearchTerm,
} from 'store/header/search/selectors/top-search-selectors-factory';
import { useHeaderActions } from 'store/header/use-header-actions';
import { useSearchAutocompleteSuggestions } from './hooks/use-search-autocomplete-suggestions';
import {
    AutoComplete,
    AutoCompleteMenuOptionData,
} from 'ui-components/auto-complete/auto-complete';

import styles from './search-autocomplete.module.scss';
import { RecentIcon } from 'components/assets/Icons/Icons';
import classnames from 'classnames';
import { useSelectorWithProps } from 'utils/structured-selector/structured-selector';
import { selectHasUserPermission } from 'store/selectors/app-selectors';
import { useGoToExplorePage } from 'router/go-to-routes/go-to-explore';
import {
    AUTOCOMPLETE_FALLBACK_GOOGLE_POIS_LIMIT,
    AUTOCOMPLETE_MAX_AMOUNT_GOOGLE_GEOCODES,
} from 'hooks/use-entity-search/consts';
import { useSegmentCallback } from 'hooks/use-segment-callback/use-segment-callback';
import { SegmentEventActionType } from 'hooks/use-segment-callback/types/segment-event-types';
import { SearchAutocompleteSuggestion } from 'components/search-autocomplete-suggestion/search-autocomplete-suggestion';
import { RequestAVenueAutocompleteOption } from 'components/request-a-venue-autocomplete-option/request-a-venue-autocomplete-option';
import { useSplunkSearchCallback } from 'hooks/use-splunk-callback/use-splunk-callback';
import { v4 as uuidv4 } from 'uuid';
import { SearchEventsData } from 'types/splunk/events';
import { selectTopSearchResultsByTerm } from 'store/header/search/selectors/select-top-search-results-by-term';
import { SEARCH_SPLUNK_ACTIONS, SPLUNK_SEARCH_PLACEMENTS } from 'shared/constants/search-splunk';
import { sendHubspotForm } from 'API/hubspot-api/hubspot-api';
import { useUserEmail } from 'hooks/use-user-email/use-user-email';
import { getLastSearchedPoiText } from 'utils/hubspot/hubspot-utils';
import { upgradeYourPlanModalSignal } from 'features/insights/components/compare-bar/compare-bar-signals';
import { getFeatureFlags } from 'core/flow-control';
import { useIsFreemiumUser } from 'hooks/use-freemium-user/use-freemium-user';

const listHeight = 348;

const useSearchAutocompleteOriginal = () => {
    const { setTerm, setSearchLoading, search, onKeyboardEnter } = useHeaderActions();

    return {
        search,
        setTerm,
        setSearchLoading,
        onKeyboardEnter,
        value: useSelector(selectTopSearchTerm),
        isLoading: useSelector(selectTopSearchIsLoading),
        noResults: useSelector(selectTopSearchNoResults),
    };
};

type SearchAutocompleteProps = {
    selectedEntityType: ReportTypes | PlaceCollection[];
    incomingPlaceholder?: string;
    incomingOnClear?: () => void;
    onSelection?: (entity: RecentEntitiesModelWithFreeTextOption) => void;
    useSearchAutoComplete?: typeof useSearchAutocompleteOriginal;
    allowFreeText?: boolean;
    freeTextComponent?: (value: string) => JSX.Element;
    requestAVenueAutocompleteOption?: boolean;
    recents?: Array<{ id: string; address: string; name: string }>;
    autoCompleteWrapperClassName?: string;
    getIsDisabledOption?: (data: RecentEntitiesModel) => boolean;
    flaggedEntityTooltipText?: string;
    logSelectedResult?: boolean;
};

const dropdownAlign = {
    offset: [0, 0],
};

const searchGoogleProviderConfig = {
    fallbackGooglePOIs: AUTOCOMPLETE_FALLBACK_GOOGLE_POIS_LIMIT,
    appendGoogleAddress: AUTOCOMPLETE_MAX_AMOUNT_GOOGLE_GEOCODES,
};

const entityTypeActionStringAdapter: Partial<Record<ReportTypes, SegmentEventActionType>> = {
    Billboard: 'Search By Traffic Pin',
    Property: 'Search By Property',
    Chain: 'Search By Chain',
};

export const SearchAutocomplete = ({
    selectedEntityType,
    incomingPlaceholder,
    onSelection,
    useSearchAutoComplete = useSearchAutocompleteOriginal,
    allowFreeText = false,
    freeTextComponent,
    requestAVenueAutocompleteOption = true,
    incomingOnClear,
    recents,
    autoCompleteWrapperClassName,
    getIsDisabledOption,
    flaggedEntityTooltipText,
    logSelectedResult,
}: SearchAutocompleteProps) => {
    const { search, setTerm, value, isLoading, noResults, onKeyboardEnter } =
        useSearchAutoComplete();
    const [isCnp, setIsCnp] = useState<boolean>(false);
    const [isFocusOnce, setIsFocusOnce] = useState<boolean>(false);
    const anchorRef = useRef<HTMLDivElement | null>(null);
    const sendSearchSplunkEvent = useSplunkSearchCallback();
    const goToExploreNewPage = useGoToExplorePage();
    const [isSuggestionSelected, setIsSuggestionSelected] = useState(false);
    const [searchId, setSearchId] = useState<string>(uuidv4());
    const userEmail = useUserEmail();
    const entities = useSelectorWithProps(
        { reportType: selectedEntityType },
        selectTopSearchResultsByTerm,
    );
    const { enable_focused_search_api } = getFeatureFlags();
    const entitiesRef = useRef(entities);

    if (entities.length > 0 || noResults) {
        entitiesRef.current = entities;
    }

    const isFreemium = useIsFreemiumUser();

    let suggestions: AutoCompleteMenuOptionData<RecentEntitiesModel>[] =
        useSearchAutocompleteSuggestions({
            reportType: selectedEntityType,
            getIsDisabledOption,
            flaggedEntityTooltipText,
            searchId,
        });

    const onSendSearchSplunkFunc = (events: SearchEventsData) => {
        sendSearchSplunkEvent({
            search_id: searchId,
            search_placement: SPLUNK_SEARCH_PLACEMENTS.topBar,
            search_term: value.split('__')[0],
            view_name: document.title,
            ...events,
        });
    };

    const onClear = (afterSelection: boolean = false) => {
        if (!afterSelection) {
            onSendSearchSplunkFunc({
                action: SEARCH_SPLUNK_ACTIONS.search,
                search_type: 'instant',
            });
        }
        setTerm('');
        callOnChange('');
        incomingOnClear?.();
    };

    const performSelection = (entity: RecentEntitiesModel) => {
        let address;
        if ('address' in entity) {
            address = entity.address.formatted_address || '';
        } else {
            address = entity.locationLabel;
        }

        if (
            (('forceLockIconState' in entity && entity.forceLockIconState === true) ||
                entity.purchased === false)
        ) {
            upgradeYourPlanModalSignal.value = {
                isOpen: !isFreemium,
                venueName: entity.name,
                venueSubTitle: address,
            };
        }
        if (logSelectedResult && userEmail) {
            sendHubspotForm('4cb709ee-e396-4b01-acf4-7fe318356019', [
                {
                    name: 'email',
                    value: userEmail,
                },
                {
                    name: 'last_poi_searched_raw',
                    value: getLastSearchedPoiText(entity.name, address),
                },
            ]);
        }
        onSendSearchSplunkFunc({
            action: SEARCH_SPLUNK_ACTIONS.selected,
            search_type: 'instant',
            value: entity.name,
            num_of_results: entitiesRef.current.length,
            index_in_result: entitiesRef.current.findIndex((opt) => opt.id === entity.id),
            source_type: 'placer',
        });
        setIsSuggestionSelected(true);
        onClear(true);
        onSelection?.(entity);
    };

    const onEnter: ComponentProps<typeof AutoComplete>['onInputKeyDown'] = (event) => {
        const target = event.currentTarget as HTMLInputElement;
        const noItemsSelected = target.getAttribute('aria-activedescendant')?.includes('-1');
        if (noItemsSelected && event.key === 'Enter') {
            if (Array.isArray(selectedEntityType)) {
                return;
            }
            onKeyboardEnter({
                reportType: selectedEntityType,
                value: event.currentTarget.value,
                goToExploreNewPage,
            });
            onSendSearchSplunkFunc({
                action: SEARCH_SPLUNK_ACTIONS.search,
                search_type: 'enter',
                CnP: false,
                search_id: uuidv4(),
            });
            (document.activeElement as HTMLElement).blur();
        }
    };

    const hasNewBillboardsTextPermission = useSelectorWithProps(
        { permission: 'enable_new_billboards_text_change' },
        selectHasUserPermission,
    );

    const placeHolderTextChange =
        hasNewBillboardsTextPermission && selectedEntityType === 'Billboard'
            ? 'traffic pin'
            : (selectedEntityType as string);

    const placeholder = Array.isArray(selectedEntityType)
        ? incomingPlaceholder
            ? incomingPlaceholder
            : ''
        : `Search ${placeHolderTextChange.toLowerCase()} name`;

    const { track } = useSegmentCallback();
    const getSuggestedEntities = useCallback(
        (value: string) => {
            const newSearchId = uuidv4();
            setSearchId(newSearchId);
            setIsCnp(false);

            search({
                term: value,
                searchReportType: selectedEntityType,
                ...searchGoogleProviderConfig,
                sendSplunkCallback: (eventData) => {
                    sendSearchSplunkEvent({
                        ...eventData,
                        CnP: isCnp,
                    });
                },
                searchId: newSearchId,
            });
        },
        [isCnp, search, selectedEntityType, sendSearchSplunkEvent],
    );

    const segmentTrackSearchOnChange = (value: string) => {
        if (
            Array.isArray(selectedEntityType) ||
            !entityTypeActionStringAdapter[selectedEntityType] ||
            isLoading ||
            !value ||
            isSuggestionSelected
        ) {
            if (isSuggestionSelected) {
                setIsSuggestionSelected(false);
            }
            return;
        }
        track({
            category: 'Search',
            action: entityTypeActionStringAdapter[selectedEntityType]!,
            label: value,
            value: noResults ? 0 : suggestions?.length,
        });
    };

    const callOnChange = useDebouncedCallback(getSuggestedEntities, 500);
    const callSegmentTrackOnChange = useDebouncedCallback(segmentTrackSearchOnChange, 2000);

    const onChange = (value: string) => {
        setTerm(value);
        callOnChange(value);
        callSegmentTrackOnChange(value);
    };

    if (!isLoading && noResults && requestAVenueAutocompleteOption) {
        suggestions = [
            {
                value,
                component: <RequestAVenueAutocompleteOption />,
            },
        ];
    }

    useEffect(() => {
        if ((isFocusOnce && enable_focused_search_api) || !enable_focused_search_api) {
            callOnChange('');
        }
    }, [callOnChange, isFocusOnce, enable_focused_search_api]);

    const onFocus = useCallback(() => {
        if (!isFocusOnce && enable_focused_search_api) {
            setIsFocusOnce(true);
        }
    }, [enable_focused_search_api, isFocusOnce]);

    return (
        <div
            className={classnames(styles.autoCompleteWrapper, autoCompleteWrapperClassName)}
            data-testid="top-panel-search-bar"
            ref={anchorRef}
        >
            <AutoComplete
                id={'top-panel-search-bar'}
                onCNP={() => setIsCnp(true)}
                onInputKeyDown={onEnter}
                value={value}
                placeholder={placeholder}
                isLoading={isLoading}
                dropdownClassName={styles.autoCompleteDropdown}
                listHeight={listHeight}
                options={
                    allowFreeText && freeTextComponent
                        ? [
                              ...(recents && !value
                                  ? recents.map((recent) => ({
                                        value: recent.name,
                                        payload: recent,
                                        component: (
                                            <SearchAutocompleteSuggestion
                                                key={recent.id}
                                                Icon={RecentIcon}
                                                name={recent.name}
                                                location={recent.address}
                                                termToHighlight={''}
                                            />
                                        ),
                                    }))
                                  : []),
                              ...suggestions,
                              ...(value.length > 0
                                  ? [
                                        {
                                            value,
                                            payload: value,
                                            component: freeTextComponent(value),
                                        },
                                    ]
                                  : []),
                          ]
                        : suggestions
                }
                getPopupContainer={() => anchorRef.current!}
                onClear={onClear}
                onChange={onChange}
                onSelect={performSelection}
                dropdownAlign={dropdownAlign}
                onFocus={onFocus}
            />
        </div>
    );
};
