import type { Place } from '@placer-ui/types';
import { VenueFlags } from 'core/entities';
import { getReportEntityFlag } from 'core/services/report-entities-service/report-entities-service';

export const ENCLOSING_COMPLEX_FLAGS: VenueFlags[] = [
    'flagged_indoor_mall',
    'flagged_multistory_building',
];

export const isAnEnclosingComplexFlag = (info: Place) => {
    const flag = getReportEntityFlag(info);
    return flag && ENCLOSING_COMPLEX_FLAGS.includes(flag);
};

export const flagIsNotAnEnclosingComplex = (info: Place) => {
    const flag = getReportEntityFlag(info);
    return flag && !ENCLOSING_COMPLEX_FLAGS.includes(flag);
};
