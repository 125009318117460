import type { PlacerErrorType, PlacerErrorParams } from './errors-manager-types';
import { ERROR_NAMES } from './errors-manager-constants';

export class PlacerError extends Error {
    type: PlacerErrorParams['type'];
    page?: PlacerErrorParams['page'];
    tab?: PlacerErrorParams['tab'];
    section?: PlacerErrorParams['section'];
    subSection?: PlacerErrorParams['subSection'];

    constructor(message: string, options: Omit<PlacerErrorType, 'message' | 'name'>) {
        const nativeErrorOptions = {
            cause: options.cause,
        };
        // @ts-ignore
        super(message, nativeErrorOptions);
        this.name = ERROR_NAMES.PlacerError;
        if (options.stack) {
            this.stack = options.stack;
        }
        this.type = options.type;
        this.page = options.page;
        this.tab = options.tab;
        this.section = options.section;
        this.subSection = options.subSection;
    }
}
