/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

export type PhoneIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type PhoneIconProps = SVGProps<SVGSVGElement> & PhoneIconUniqueProps;

export const PhoneIcon = function PhoneIcon({
    width = 16,
    height = 16,
    color = '#D0D0D0',
    ...props
}: PhoneIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            color={color}
            {...props}
        >
            <path
                d="M11.5 13C11.5 14.3807 10.3807 15.5 9 15.5C7.61929 15.5 6.5 14.3807 6.5 13C6.5 11.6193 7.61929 10.5 9 10.5C10.3807 10.5 11.5 11.6193 11.5 13Z"
                fill="currentColor"
            />
            <path
                d="M5.02891 6H2C1.44772 6 1 5.55229 1 5V3.55921C1 2.92696 1.21327 2.4555 1.63754 1.98975C2.14903 1.42826 2.84682 1.07581 3.57002 0.843675C7.09083 -0.286437 10.9042 -0.278153 14.4274 0.85017C15.132 1.07584 15.8117 1.4159 16.3196 1.9539C16.7754 2.43662 17 2.92224 17 3.57839V5C17 5.55228 16.5523 6 16 6H12.9989C12.6312 6 12.2931 5.79817 12.1186 5.47447L11.4401 4.26277C11.3529 4.10091 11.1839 4 11 4H7C6.81952 4 6.65304 4.09727 6.56442 4.2545L5.90007 5.49101C5.72283 5.80547 5.38988 6 5.02891 6Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.5 17.25H16.5C16.9142 17.25 17.25 16.9142 17.25 16.5V14.166C17.25 14.0316 17.2138 13.8996 17.1453 13.7839L14.382 9.11783C14.247 8.88983 14.0017 8.75001 13.7367 8.75001H4.28864C4.02466 8.75001 3.78011 8.88879 3.64475 9.11543L0.856104 13.7845C0.786665 13.9007 0.75 14.0336 0.75 14.169V16.5C0.75 16.9142 1.08579 17.25 1.5 17.25ZM1.5 18H16.5C17.3284 18 18 17.3284 18 16.5V14.166C18 13.8971 17.9277 13.6331 17.7906 13.4017L15.0273 8.73566C14.7573 8.27966 14.2666 8.00001 13.7367 8.00001H4.28864C3.76067 8.00001 3.27158 8.27758 3.00085 8.73086L0.212208 13.3999C0.0733305 13.6324 0 13.8982 0 14.169V16.5C0 17.3284 0.671573 18 1.5 18Z"
                fill="#49565D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 14.75C9.9665 14.75 10.75 13.9665 10.75 13C10.75 12.0335 9.9665 11.25 9 11.25C8.0335 11.25 7.25 12.0335 7.25 13C7.25 13.9665 8.0335 14.75 9 14.75ZM9 15.5C10.3807 15.5 11.5 14.3807 11.5 13C11.5 11.6193 10.3807 10.5 9 10.5C7.61929 10.5 6.5 11.6193 6.5 13C6.5 14.3807 7.61929 15.5 9 15.5Z"
                fill="#49565D"
            />
            <path
                d="M7.63022 6.82208C7.65178 6.78085 7.69446 6.75501 7.74098 6.75501H10.2161C10.262 6.75501 10.3042 6.78019 10.326 6.82059L10.7606 7.62501H11.6131L10.9859 6.46409C10.8331 6.18127 10.5375 6.00501 10.2161 6.00501H7.74098C7.41529 6.00501 7.11656 6.1859 6.96563 6.4745L6.36392 7.62501H7.2103L7.63022 6.82208Z"
                fill="#49565D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.90733 3.89285L5.91106 3.88624C6.1326 3.49317 6.54879 3.25 7 3.25H11C11.4572 3.25 11.8777 3.49957 12.0968 3.90038L12.7789 5.11857C12.8226 5.1995 12.907 5.25 12.9989 5.25H16C16.1381 5.25 16.25 5.13807 16.25 5V3.57839C16.25 3.14156 16.1191 2.83396 15.7743 2.46879C15.3856 2.05711 14.8371 1.76891 14.1986 1.56443C10.8231 0.483381 7.16981 0.475901 3.79924 1.55779C3.14297 1.76844 2.58171 2.067 2.19198 2.49482C1.87447 2.84337 1.75 3.14029 1.75 3.55921V5C1.75 5.13807 1.86193 5.25 2 5.25H5.02891C5.11746 5.25 5.19927 5.20317 5.24417 5.12714L5.90733 3.89285ZM5.90007 5.49101C5.72283 5.80547 5.38988 6 5.02891 6H2C1.44772 6 1 5.55229 1 5V3.55921C1 2.92696 1.21327 2.4555 1.63754 1.98975C2.14903 1.42826 2.84682 1.07581 3.57002 0.843675C7.09083 -0.286437 10.9042 -0.278153 14.4274 0.85017C15.132 1.07584 15.8117 1.4159 16.3196 1.9539C16.7754 2.43662 17 2.92224 17 3.57839V5C17 5.55228 16.5523 6 16 6H12.9989C12.6312 6 12.2931 5.79817 12.1186 5.47447L11.4401 4.26277C11.3529 4.10091 11.1839 4 11 4H7C6.81952 4 6.65304 4.09727 6.56442 4.2545L5.90007 5.49101Z"
                fill="#49565D"
            />
        </svg>
    );
};
PhoneIcon.__tags = [
    'phone',
    'telephone',
    'mobile',
    'mobilephone',
    'mobile-phone',
    'mobile phone',
] as string[];
