import React, { PropsWithChildren } from 'react';

import { CustomInfoWindowContainer } from 'ui-components/google-map/components/custom-info-window/custom-info-window-container';
import { CustomInfoWindowAnchor } from 'ui-components/google-map/components/custom-info-window/custom-info-window-anchor';
import { OverlayViewContainer as GoogleOverlayViewContainer } from 'ui-components/google-map/components/overlay-view-container';
import { OverlayViewContainer as MapboxOverlayViewContainer } from 'ui-components/mapbox-map/components/overlay-view-container';

export type CustomInfoWindowProps = PropsWithChildren<{
    position: google.maps.LatLngLiteral;
    offset?: [number, number];
    showAnchor?: boolean;
    animation?: boolean;
    className?: string;
    anchorClassName?: string;
    backgroundColor?: string;
    borderColor?: string;
    boxShadow?: string;
    mapboxBeta?: boolean;
    width?: number | string;
}>;

export const CustomInfoWindow = (props: CustomInfoWindowProps) => {
    const {
        className,
        animation = true,
        backgroundColor,
        children,
        showAnchor = true,
        borderColor,
        boxShadow,
        width,
        mapboxBeta,
    } = props;
    const OverlayViewContainer = mapboxBeta
        ? MapboxOverlayViewContainer
        : GoogleOverlayViewContainer;
    return (
        <OverlayViewContainer {...props}>
            <CustomInfoWindowContainer
                className={className}
                animation={animation}
                backgroundColor={backgroundColor}
                borderColor={borderColor}
                boxShadow={boxShadow}
                width={width}
            >
                {children}
                {showAnchor && <CustomInfoWindowAnchor {...props} />}
            </CustomInfoWindowContainer>
        </OverlayViewContainer>
    );
};
