import { createSlice } from '@reduxjs/toolkit';
import { createApplicationState } from 'store/app-ui/state/app-ui-state';
import {
    closeUpgradeYourPlanPopup,
    openDynamicUpgradeYourPlanPopup,
    openUpgradeYourPlanPopup,
    setIsSidePanelExpanded,
    setKeyValue,
} from '../actions';

const initialState = createApplicationState();

export const appUiSlice = createSlice({
    name: 'app-ui',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(openUpgradeYourPlanPopup, (state, action) => {
                state.upgradeYourPlanPopupScreen = action.payload;
            })
            .addCase(openDynamicUpgradeYourPlanPopup, (state, action) => {
                const { screen, ...params } = action.payload;
                state.upgradeYourPlanPopupScreen = screen;
                state.upgradeYourPlanPopupParams = params;
            })
            .addCase(closeUpgradeYourPlanPopup, (state, _) => {
                state.upgradeYourPlanPopupScreen = undefined;
            })
            .addCase(setIsSidePanelExpanded, (state, action) => {
                state.sidebarExpanded[action.payload.section] = action.payload.expandedState;
            })
            .addCase(setKeyValue, (state, action) => {
                const { key, value } = action.payload;
                state[key] = value;
            });
    },
});
