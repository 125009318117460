/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

type LocationPinWithShadowIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type LocationPinWithShadowIconProps = SVGProps<SVGSVGElement> &
    LocationPinWithShadowIconUniqueProps;

export const LocationPinWithShadowIcon = function LocationPinWithShadowIcon({
    width = 30,
    height = 40,
    ...props
}: LocationPinWithShadowIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 30 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g id="Frame 35056">
                <g id="Group">
                    <path
                        id="Vector"
                        d="M15.1256 29.962C15.1149 29.95 15.1039 29.9377 15.0928 29.9252C14.7777 29.5713 14.3267 29.0578 13.7851 28.4213C12.7016 27.1476 11.2582 25.3847 9.81623 23.4248C8.37259 21.4627 6.93964 19.3156 5.87023 17.2726C4.79051 15.2099 4.125 13.3333 4.125 11.8891C4.125 5.82471 9.07003 0.875 15.1256 0.875C21.2522 0.875 26.1262 5.82213 26.1262 11.8891C26.1262 13.3333 25.4607 15.2099 24.381 17.2726C23.3116 19.3156 21.8786 21.4627 20.435 23.4248C18.993 25.3847 17.5497 27.1476 16.4661 28.4213C15.9246 29.0578 15.4736 29.5713 15.1584 29.9252C15.1473 29.9377 15.1364 29.95 15.1256 29.962Z"
                        fill="#676DFD"
                        stroke="#333333"
                        strokeWidth="1.25"
                    />
                    <path
                        id="Vector_2"
                        d="M15.1239 17.0457C17.9278 17.0457 20.2009 14.77 20.2009 11.9628C20.2009 9.15557 17.9278 6.87988 15.1239 6.87988C12.3199 6.87988 10.0469 9.15557 10.0469 11.9628C10.0469 14.77 12.3199 17.0457 15.1239 17.0457Z"
                        fill="white"
                    />
                </g>
                <path
                    id="Vector_3"
                    d="M15.1258 39.7505C23.2078 39.7505 29.7595 37.768 29.7595 35.3225C29.7595 32.877 23.2078 30.8945 15.1258 30.8945C7.0439 30.8945 0.492188 32.877 0.492188 35.3225C0.492188 37.768 7.0439 39.7505 15.1258 39.7505Z"
                    fill="#DEE6FD"
                />
            </g>
        </svg>
    );
};
LocationPinWithShadowIcon.__tags = ['location', 'pin', 'shadow'] as string[];
