import { createSelector } from '@reduxjs/toolkit';
import {
    selectAvailableCategoriesData,
    selectAvailablePrimaryCategories,
} from 'store/configuration/categories/selectors';

export const selectGroupsCategoriesOptions = createSelector(
    selectAvailableCategoriesData,
    (categories) => {
        return categories.map((category) => ({
            id: category.label,
            name: category.label,
        }));
    },
);

export const selectPrimariesCategoriesOptions = createSelector(
    selectAvailablePrimaryCategories,
    (categories) => {
        return categories.map((category) => ({
            id: category.label,
            name: category.label,
        }));
    },
);
