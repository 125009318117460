import { useCallback } from 'react';
import { goTo } from 'router/go-to';

export const VOID_ANALYSIS_BASE_PATH = '/advanced-reports/void-analysis';

export const useGoToVoidAnalysis = () => {
    return useCallback((subPath: string = '') => {
        goTo(`/advanced-reports/void-analysis/${subPath}`);
    }, []);
};
