import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Location } from 'history';
import { userIsAnonymousSelector, userIsNewSelector } from 'store/selectors/app-selectors';
import { useSplunkCallback } from 'hooks/use-splunk-callback/use-splunk-callback';
import { useSegmentCallback } from 'hooks/use-segment-callback/use-segment-callback';
import { getPathStateName } from 'tracking/get-path-state-name';
import { getViewNameFromState } from 'tracking/get-view-name-from-state';
import { isBlackListed } from 'tracking/is-black-listed';
import { sendGoogleAnalyticsEvent } from 'utils/google-analytics/google-analytics';
import { getUserAgentTechnicalData } from 'utils/get-user-agent-data/get-user-agent-data';

const SPLUNK_ACTION = 'state-track';

export const useNavigationAnalyticsEvents = () => {
    const toLocation = useLocation();
    const fromLocationRef = useRef<Location | undefined>();
    const userIsAnonymous = useSelector(userIsAnonymousSelector);
    const userIsNew = useSelector(userIsNewSelector);
    const sendSplunkEvent = useSplunkCallback();
    const { track, page } = useSegmentCallback();

    useEffect(() => {
        if (
            userIsAnonymous === false &&
            toLocation.pathname !== fromLocationRef.current?.pathname &&
            !isBlackListed(toLocation.pathname)
        ) {
            const state = getPathStateName(toLocation.pathname + toLocation.search);
            window._elev?.closeAllPopups?.();

            sendGoogleAnalyticsEvent({
                category: 'Page View',
                action: 'view',
                label: state,
            });

            if (state.includes('placer-xtra')) {
                sendSplunkEvent({
                    action: SPLUNK_ACTION,
                    state: 'admin-panel.placer-xtra',
                    view_name: 'Placer XTRA / Property',
                });
            } else {
                sendSplunkEvent({
                    action: SPLUNK_ACTION,
                    state,
                    view_name: getViewNameFromState(state),
                    technical_data: getUserAgentTechnicalData(),
                });
            }

            if (fromLocationRef.current?.pathname.includes('/auth/')) {
                track({
                    category: 'User',
                    action: `Signed ${userIsNew ? 'Up' : 'In'}`,
                });
            }

            page({
                category: 'User',
                name: toLocation.pathname,
                properties: {
                    url: window.location.href,
                    search: toLocation.search,
                    path: toLocation.pathname,
                    title: state,
                    referrer: fromLocationRef.current?.pathname
                        ? fromLocationRef.current.pathname
                        : '',
                },
            });

            fromLocationRef.current = toLocation;
        }
    }, [page, sendSplunkEvent, toLocation, track, userIsAnonymous, userIsNew]);
};
