import React, { forwardRef, Ref } from 'react';

import { Tooltip } from 'ui-components/tooltip/tooltip';
import { RecentReportEntity, RecentReportUIShape } from 'store/header/navigation/models';
import { getDeviceType } from 'utils/get-user-agent-data/utils';

import styles from './recent-reports.module.scss';
import { RecentReportLink } from './components/recent-report-link';

type RecentReportsProps = {
    recentReportsList: RecentReportUIShape[];
    reportsFor: string;
    onClick: (e: React.MouseEvent) => void;
};

const pickTooltip = (
    primaryEntity: RecentReportEntity,
    competitor: RecentReportUIShape['competitor'],
) => {
    let tooltip = `${primaryEntity.name} / ${primaryEntity.locationLabel}`;

    if (competitor) {
        tooltip += ` Vs ${competitor.name} / ${competitor.locationLabel}`;
    }

    return tooltip;
};

const VISIBLE_ENTITIES_QUANTITY = 2;

const RecentReport = ({
    timestamp,
    primaryEntity,
    competitor,
    entitiesNumber,
}: RecentReportUIShape) => {
    return (
        <>
            <div className={styles.reportEntities}>
                <div className={styles.firstItem}>
                    <div className={styles.name}>{primaryEntity.name}</div>
                    &nbsp;/&nbsp;
                    <div className={styles.location}>{primaryEntity.locationLabel}</div>
                </div>

                {competitor && (
                    <div className={styles.secondItem}>
                        <div className={styles.versus}>&nbsp;vs&nbsp;</div>
                        <div className={styles.name}>{competitor.name}</div>
                        &nbsp;/&nbsp;
                        <div className={styles.location}>{competitor.locationLabel}</div>
                    </div>
                )}

                {entitiesNumber > VISIBLE_ENTITIES_QUANTITY && (
                    <div>&nbsp;+{entitiesNumber - VISIBLE_ENTITIES_QUANTITY}</div>
                )}
            </div>
            <div className={styles.timestamp}>{timestamp}</div>
        </>
    );
};

export const RecentReports = forwardRef(
    ({ recentReportsList }: RecentReportsProps, ref: Ref<HTMLUListElement>) => {
        const device = getDeviceType();
        return (
            <div className={styles.wrapper}>
                <h4>recent reports</h4>
                <ul ref={ref}>
                    {recentReportsList.map((report, i) => {
                        const recentReportListItem = (
                            <li>
                                <RecentReportLink {...report}>
                                    <RecentReport {...report} />
                                </RecentReportLink>
                            </li>
                        );

                        if (device !== 'desktop') {
                            return recentReportListItem;
                        }

                        return (
                            <Tooltip
                                small
                                key={i}
                                placement="top"
                                className={styles.tooltip}
                                title={pickTooltip(report.primaryEntity, report.competitor)}
                            >
                                {recentReportListItem}
                            </Tooltip>
                        );
                    })}
                </ul>
            </div>
        );
    },
);
