import {
    FEATURE_FLAGS_DEFAULTS,
    FeatureFlagsKeys,
    UserFeatureFlags,
} from 'core/entities/user/user-feature-flags';
import { UserPermissions, AvailableUserPermission } from 'core/entities/user/user-permissions';
import { state } from './flow-control.state';

export const initFlowControl = (featureFlags: UserFeatureFlags, permissions: UserPermissions) => {
    if (!state.isInitialized) {
        state.featureFlags = featureFlags;
        state.permissions = permissions;
        state.isInitialized = true;
    }
};

export const destroyFlowControl = () => {
    state.featureFlags = FEATURE_FLAGS_DEFAULTS;
    state.permissions = {} as UserPermissions;
    state.isInitialized = false;
};

export const getFeatureFlags = () => state.featureFlags;
export const hasFeatureFlags = (featureFlags: Array<FeatureFlagsKeys>) =>
    featureFlags.every((featureFlag) => getFeatureFlags()[featureFlag]);

export const getPermissions = () => state.permissions;
export const hasPermissions = (permissions: Array<AvailableUserPermission>) =>
    permissions.every((permission) => getPermissions()[permission]);
