import { useGetActiveOverlay } from 'features/explore/hooks/use-explore-overlay-poi';
import { useSetOverlayPOI } from 'features/explore/context/use-explore-ui-state-actions';
import React, { useMemo } from 'react';
import { ExploreDrawerOption } from 'features/explore/components/explore-side-bar/components/explore-drawer/explore-drawer-option/explore-drawer-option';
import { RegularOptionBody } from 'features/explore/components/explore-side-bar/components/explore-drawer/explore-drawer-option/option-body/regular-option-body';
import { POIActionProps } from 'features/explore/components/explore-side-bar/components/explore-drawer/drawer-options';
import { PlaceOverlay } from 'ui-components/google-map-layers/types/google-map-layers-types';
import { getPoiPlaceOverlay } from 'ui-components/google-map-layers/utils/get-place-overlay';

export const VerifiedReportOption = ({ poi, enableSelection }: POIActionProps) => {
    const setOverlay = useSetOverlayPOI();
    const getActiveOverlay = useGetActiveOverlay();
    const overlayPoi: PlaceOverlay = useMemo(
        () => ({
            ...poi,
            overlayID: 'poi',
        }),
        [poi],
    );

    const isActive = useMemo(() => {
        const overlay = getActiveOverlay(overlayPoi);
        const isEqualOverlay = !!(overlay?.overlayID === 'poi' && overlay?.isActive);
        return !overlay || isEqualOverlay;
    }, [getActiveOverlay, overlayPoi]);

    const onActivate = () => {
        setOverlay({
            sourcePoi: poi.id,
            overlayPoi: getPoiPlaceOverlay(overlayPoi),
        });
    };

    return (
        <ExploreDrawerOption
            isActive={isActive}
            onActivate={onActivate}
            poi={poi}
            title={`Open a Property Report for ${poi.name}`}
            subtitle={
                poi.is_custom_poi
                    ? 'Visitation data is based on this Custom POI’s polygon'
                    : 'Visitation data is based on a verified building polygon'
            }
            testId={'verified-poi-option'}
        >
            <RegularOptionBody poi={overlayPoi} enableSelection={enableSelection} />
        </ExploreDrawerOption>
    );
};
