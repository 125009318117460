import type { DeviceTypes } from '@placer-ui/types';
import { isMobileApp, isMobileWeb, isUATablet } from 'utils/detect-environment';
import { BROWSER_NAMES, OS_TYPES } from 'shared/constants/device-and-system';

export const getBrowserName = () => {
    const { userAgent } = navigator;
    if (userAgent.match(/edg/i)) return BROWSER_NAMES.EDGE;
    if (userAgent.match(/chrome|chromium|crios/i)) return BROWSER_NAMES.CHROME;
    if (userAgent.match(/firefox|fxios/i)) return BROWSER_NAMES.FIREFOX;
    if (userAgent.match(/safari/i)) return BROWSER_NAMES.SAFARI;
    if (userAgent.match(/opr\//i)) return BROWSER_NAMES.OPERA;
    if (userAgent.match(/iPhone/i)) return 'iPhone'; // native iOS has no detection for browser
    if (userAgent.match(/iPad/i)) return 'iPad'; // native iOS has no detection for browser

    return 'Undetectable browser';
};

export const getOSName = () => {
    const { userAgent } = navigator;
    if (userAgent.includes('Win')) return OS_TYPES.WINDOWS;
    if (userAgent.includes('Mac')) return OS_TYPES.MAC_OS;
    if (userAgent.includes('X11')) return OS_TYPES.X11;
    if (userAgent.includes('Linux')) return OS_TYPES.LINUX;
    return 'Undetectable os';
};

export const getBrowserVersion = () => {
    const ua = navigator.userAgent;
    let tem;
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return tem[1] || '';
    }
    if (M[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null) return tem[2];
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return M[1];
};

export function getDeviceType(): DeviceTypes {
    if (isUATablet) {
        return 'tablet';
    }
    if (isMobileApp()) {
        return 'mobile-app';
    }

    if (isMobileWeb()) {
        return 'mobile-web';
    }

    return 'desktop';
}

export function getDeviceMemory() {
    return (navigator as any)?.deviceMemory || 'unknown';
}

export function getDeviceCores() {
    return (navigator as any)?.hardwareConcurrency || 'unknown';
}
