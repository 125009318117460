import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import styles from './collapse.module.scss';
import { Collapse as AntdCollapse } from 'antd';
import { ChevronIcon } from 'components/assets/Icons/Icons';
import classNames from 'classnames';

const { Panel } = AntdCollapse;

type CollapseProps = {
    children: ReactNode;
    title: string | ReactNode;
    collapsedByDefault?: boolean;
    contentClassname?: string;
    containerClassName?: string;
    iconClassName?: string;
    forceCollapse?: boolean;
    id?: string;
    onCollapseChange?: (expanded: boolean, id?: string) => void;
    disabled?: boolean;
    isSidePanelClosed?: boolean;
};

const collapseKey = 'collapse';

export const Collapse = ({
    children,
    title,
    collapsedByDefault = true,
    containerClassName,
    contentClassname,
    iconClassName,
    forceCollapse,
    onCollapseChange,
    id,
    disabled = false,
    isSidePanelClosed = false,
}: CollapseProps) => {
    const [key, setKey] = useState<string | string[]>(collapsedByDefault ? collapseKey : []);
    const collapsible = useMemo(() => {
        return disabled ? 'disabled' : undefined;
    }, [disabled]);

    useEffect(() => {
        if (isSidePanelClosed) {
            setKey([]);
        }
    }, [isSidePanelClosed]);

    return (
        <AntdCollapse
            className={classNames(styles.collapse, containerClassName)}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
                <div className={iconClassName}>
                    <ChevronIcon rotate={isActive ? 90 : 270} />
                </div>
            )}
            defaultActiveKey={collapsedByDefault ? key : []}
            onChange={(key: string | string[]) => {
                setKey(key);
                onCollapseChange && onCollapseChange(!!key.length, id);
            }}
            activeKey={forceCollapse ? collapseKey : key}
        >
            <Panel
                header={title}
                collapsible={collapsible}
                key={collapseKey}
                className={styles.panel}
            >
                <div className={contentClassname}>{children}</div>
            </Panel>
        </AntdCollapse>
    );
};
