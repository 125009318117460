import { createSlice, Dictionary, PayloadAction } from '@reduxjs/toolkit';
import { getChainsByCategories } from 'store/configuration/chains-by-categories/actions';

type ChainCategoryItem = {
    id: string;
    name: string;
}

const chainsByCategoriesSlice = createSlice({
    name: 'chainsByCategories',
    initialState: {},
    reducers: {
        setChainsByCategoriesData: (state: Dictionary<Dictionary<ChainCategoryItem>[]>,
            action: PayloadAction<Dictionary<Dictionary<ChainCategoryItem>[]>>) => {
            return {...action.payload};
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getChainsByCategories.fulfilled, (state, action) => action.payload);
    },
});

export const chainsByCategoriesReducer = {
    chainsByCategories: chainsByCategoriesSlice.reducer,
};

export const configurationChainsByCategoriesActions = chainsByCategoriesSlice.actions;