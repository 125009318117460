import {
    VENUE_QUALITY_BADGE,
    VenueQualityBadges,
    VenueQualityBadgesTypes,
} from 'ui-components/venue-quality-badge/venue-quality-badges';
import React from 'react';
import { useExplorePermissions } from 'features/explore/hooks/use-explore-permissions';
import type { PointOfInterestEntities, Venue } from '@placer-ui/types';

type ExploreListItemBadgesProps = {
    poi: PointOfInterestEntities;
};
export const ExploreListItemBadges = ({ poi }: ExploreListItemBadgesProps) => {
    const badges: VenueQualityBadgesTypes[] = [];
    const { hasBadgesPermission } = useExplorePermissions();

    const verifiedData = (poi as Venue)?.verified;
    if (!verifiedData || !hasBadgesPermission) {
        return null;
    }

    if (verifiedData.is_verified) {
        badges.push(VENUE_QUALITY_BADGE.isVerified);
    }
    if (verifiedData.has_polygon) {
        badges.push(VENUE_QUALITY_BADGE.hasPolygon);
    }
    if (verifiedData.manual_inspection) {
        badges.push(VENUE_QUALITY_BADGE.manualInspection);
    }

    return (
        <VenueQualityBadges
            badges={badges}
            manualInspectionDate={verifiedData.manual_inspection_date || undefined}
        />
    );
};
