import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ExploreSearchResult, ExploreUIStateType } from 'features/explore/types/types';
import type { Dictionary } from '@placer-ui/types';
import { getSelectedPoiOverlay } from 'features/explore/utils/utils';
import { PlaceOverlay } from 'ui-components/google-map-layers/types/google-map-layers-types';
import type { ResponseStatus } from 'API/common/types';

export const initialState: ExploreUIStateType = {
    results: [],
    coordinatesPois: [],
    enclosingComplexes: undefined,
    customPoiAddresses: {},
    overlayPOIs: {},
    overlayPOIsInitialState: {},
    loading: false,
    error: undefined,
    hoverId: undefined,
    activeId: undefined,
    selectedResults: [],
    selectedOverlayResults: [],
    drawerResult: undefined,
    exploreMultiselectDrawerOpen: false,
    selectedSuggestion: undefined,
    isCreatingPoi: false,
    isDropPinMode: false,
    dropPinCoordinate: undefined,
};

const exploreContextSlice = createSlice({
    name: 'exploreUIContext',
    initialState,
    reducers: {
        cleanup: (state) => {
            state = initialState;
        },
        setSuggestion: (state, action: PayloadAction<ExploreSearchResult | undefined>) => {
            state.selectedSuggestion = action.payload;
        },
        setResults: (state, action: PayloadAction<ExploreSearchResult[]>) => {
            state.results = action.payload;
        },
        setCoordinates: (state, action: PayloadAction<ExploreSearchResult[]>) => {
            state.coordinatesPois = action.payload;
        },
        setCoordinatesError: (state, action: PayloadAction<ResponseStatus>) => {
            state.coordinatesError = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean | undefined>) => {
            state.loading = action.payload;
        },
        setError: (state, action: PayloadAction<boolean | undefined>) => {
            state.error = action.payload;
        },
        setEnclosingComplexes: (state, action: PayloadAction<Dictionary<PlaceOverlay[]>>) => {
            state.enclosingComplexes = action.payload;
        },
        setCustomPoiAddresses: (state, action: PayloadAction<Dictionary<string>>) => {
            state.customPoiAddresses = action.payload;
        },
        setOverlayPOIOnce: (
            state,
            {
                payload: { sourcePoi, overlayPoi },
            }: PayloadAction<{
                sourcePoi: string;
                overlayPoi: PlaceOverlay;
            }>,
        ) => {
            if (!state.overlayPOIsInitialState[sourcePoi]) {
                const newOverlayPoi: PlaceOverlay = { ...overlayPoi };
                state.overlayPOIsInitialState[sourcePoi] = newOverlayPoi;
                state.overlayPOIs[sourcePoi] = [newOverlayPoi];
            }
        },
        setDropPinCoordinate: (state, action: PayloadAction<mapboxgl.LngLat | undefined>) => {
            state.dropPinCoordinate = action.payload;
        },
        setIsDropPinMode: (state, action: PayloadAction<boolean>) => {
            state.isDropPinMode = action.payload;
        },
        setOverlayPOI: (
            state,
            {
                payload: { sourcePoi, overlayPoi },
            }: PayloadAction<{
                sourcePoi: string;
                overlayPoi: PlaceOverlay;
            }>,
        ) => {
            // if the initial state is undefined, save a copy of it
            if (!state.overlayPOIsInitialState[sourcePoi]) {
                state.overlayPOIsInitialState[sourcePoi] = overlayPoi;
            }
            // if the new overlay is undefined, reset to the initial state
            if (!overlayPoi) {
                state.overlayPOIs[sourcePoi] = [
                    state.overlayPOIsInitialState[sourcePoi] as PlaceOverlay,
                ];
            } else {
                // otherwise, set the new overlay
                const newOverlay = { ...overlayPoi };
                const overlayIndex = state.overlayPOIs[sourcePoi]?.findIndex(
                    (item) => item.overlayID === overlayPoi.overlayID,
                );

                if (overlayIndex !== undefined && overlayIndex !== -1) {
                    newOverlay.isSelected = !!(state.overlayPOIs[sourcePoi] as PlaceOverlay[])[
                        overlayIndex
                    ]?.isSelected;
                    (state.overlayPOIs[sourcePoi] as PlaceOverlay[])[overlayIndex] = newOverlay;
                } else {
                    // if the poi id overlay is exist, push the new overlay, otherwise, create it
                    if (state.overlayPOIs[sourcePoi]) {
                        state.overlayPOIs[sourcePoi]?.push(newOverlay);
                    } else {
                        state.overlayPOIs[sourcePoi] = [newOverlay];
                    }
                }

                state.overlayPOIs[sourcePoi]?.forEach((item) => {
                    item.isActive = item.overlayID === overlayPoi.overlayID && overlayPoi.isActive;
                });
            }
        },
        setHoverId: (state, action: PayloadAction<string | undefined>) => {
            state.hoverId = action.payload;
        },
        setUIContext: (state, action: PayloadAction<Partial<ExploreUIStateType> | undefined>) => {
            return {
                ...state,
                ...action.payload,
            };
        },
        setActiveId: (state, action: PayloadAction<string | undefined>) => {
            state.activeId = action.payload;
        },
        addSelectedResult: (
            state,
            action: PayloadAction<{ result: PlaceOverlay; poiSearchResultId: string }>,
        ) => {
            const existingResult = getSelectedPoiOverlay(
                state.overlayPOIs[action.payload.poiSearchResultId],
                action.payload.result?.overlayID,
            );
            if (existingResult) {
                existingResult.isSelected = true;
                state.selectedOverlayResults.push(existingResult);
            }
        },
        setDrawerResult: (state, action: PayloadAction<ExploreSearchResult | undefined>) => {
            state.drawerResult = action.payload;
        },
        removeSelectedId: (
            state,
            action: PayloadAction<{
                id: string;
                selectedOverlay: PlaceOverlay;
            }>,
        ) => {
            const existingResult = getSelectedPoiOverlay(
                state.overlayPOIs[action.payload.id],
                action.payload.selectedOverlay?.overlayID,
            );
            if (existingResult) {
                existingResult.isSelected = false;
                const existingIndex = state.selectedOverlayResults.findIndex(
                    (overlay) =>
                        overlay.overlayID === existingResult.overlayID &&
                        overlay.id === existingResult.id,
                );
                state.selectedOverlayResults.splice(existingIndex, 1);
            }
        },
        removeAllOverlays: (
            state,
            action: PayloadAction<{
                id: string;
                selectedOverlay: PlaceOverlay;
            }>,
        ) => {
            state.overlayPOIs[action.payload.id]?.forEach((overlay) => {
                overlay.isSelected = false;
                const existingIndex = state.selectedOverlayResults.findIndex(
                    (existOverlay) =>
                        existOverlay.overlayID === overlay.overlayID &&
                        existOverlay.id === overlay.id,
                );
                state.selectedOverlayResults.splice(existingIndex, 1);
            });
        },
        clearSelectedResults: (state) => {
            Object.values(state.overlayPOIs)?.forEach((overlayPoi) => {
                if (overlayPoi) {
                    overlayPoi.forEach((overlay) => {
                        if (overlay) {
                            overlay.isSelected = false;
                        }
                    });
                }
            });
            state.selectedOverlayResults = [];
        },
        setExploreMultiselectDrawerVisibility: (state, action: PayloadAction<boolean>) => {
            state.exploreMultiselectDrawerOpen = action.payload;
        },
        setExploreCreateReport: (state, action: PayloadAction<boolean>) => {
            state.isCreatingPoi = action.payload;
        },
    },
});

type ActionsType<T> = {
    [K in keyof T]: T[K] extends (...args: any[]) => infer A ? A : never;
}[keyof T];

export type ExploreActionTypes = ActionsType<typeof exploreContextSlice.actions>;
export const actions = exploreContextSlice.actions;
export const reducer = exploreContextSlice.reducer;
