import React, { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';

import { ToSupport } from 'ui-components/to-support/to-support';
import { Button } from 'ui-components/button/button';
import { ErrorComponentsProps } from 'router/errors/error-types';
import unexpectedErrorUrl from 'shared/images/unexpected-error.svg';

import styles from './unexpected-error.module.scss';

type UnexpectedErrorProps = ErrorComponentsProps & {
    showActionButton?: boolean;
    className?: string;
    message?: ReactNode;
    title?: ReactNode;
    classNameMap?: {
        title?: string;
        messageWrapper?: string;
        message?: string;
        unexpectedImage?: string;
    };
    testId?: string;
    showLink?: boolean;
    onClick?: () => void;
};

export const UnexpectedError = ({
    failBackUrl = '',
    showActionButton = true,
    className,
    title = 'Unexpected Error',
    message = 'An unexpected error occurred, if the issue persists please contact ',
    classNameMap,
    testId = 'unexpected-error-page',
    showLink = true,
    ctaText,
    onClick,
}: UnexpectedErrorProps) => {
    const history = useHistory();

    const handleClick = () => {
        onClick?.();

        history.replace(failBackUrl);
    };

    return (
        <div className={classnames(styles.unexpectedErrorWrapper, className)} data-testid={testId}>
            <img
                className={classnames(styles.unexpectedImage, classNameMap?.unexpectedImage)}
                src={unexpectedErrorUrl}
                alt="unexpected error"
            />
            <div className={classnames(styles.errorMessageWrapper, classNameMap?.messageWrapper)}>
                <div className={classnames(styles.title, classNameMap?.title)}>{title}</div>
                <div className={classnames(styles.message, classNameMap?.message)}>
                    {message}
                    {showLink && <ToSupport inline />}
                </div>
                {showActionButton && (
                    <Button
                        type="brand-primary-full"
                        className={styles.button}
                        size="large"
                        onClick={handleClick}
                    >
                        {ctaText || 'Start a New Search'}
                    </Button>
                )}
            </div>
        </div>
    );
};
