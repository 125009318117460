import { GetFieldsType } from 'features/insights/utils/report-options-reducer/report-options-reducer';
import { EntitiesDataOptionsType } from 'API/report-options-api/report-options-api';
import { GeoJSON } from 'geojson';
import type { AccessInfo } from '@placer-ui/types';

type EntityOptions = {
    name: string;
    area_sqft: number;
    geojson: GeoJSON;
    store_id?: string;
};

export const getEntityFtRadiusOptions: GetFieldsType<EntitiesDataOptionsType> = ({
    entity,
    options,
    entityFilters,
}) => {
    if (!entityFilters) {
        return {};
    }

    const selectedFtRadius = options?.ft_radius?.find(
        ({ value }) => value === entityFilters.config?.ft_radius,
    );
    if (!selectedFtRadius) {
        return {};
    }

    const entityOptions: EntityOptions = {
        name: `${entity.name} (Nearby Activity ${entityFilters.config?.ft_radius} ft)`,
        area_sqft: selectedFtRadius.area_sqft,
        geojson: selectedFtRadius.geojson,
        //override attribute for nearby_activity in order to hide StoreIDBadge
        ...(entity?.store_id ? { store_id: '' } : {}),
    };

    return entityOptions;
};

export const getEntityReportAvailability: GetFieldsType<EntitiesDataOptionsType> = ({
    options,
}) => {
    if (!options || !options.data_sources_availability) {
        return {};
    }

    return {
        dataSourcesAvailability: options.data_sources_availability,
    };
};

export const getEntityIsVisitDurationLimited: GetFieldsType<EntitiesDataOptionsType> = ({
    options,
}) => {
    if (!options || !options.is_visit_duration_limited) {
        return {};
    }

    return {
        isLimitedShortVisits: options.is_visit_duration_limited,
    };
};

export const getEntityAccessInfo: GetFieldsType<AccessInfo> = ({ entity, options }: any) => {
    if (!options) {
        return {};
    }
    return {
        access: options,
    };
};

const getIsPOSIEEntity: GetFieldsType<EntitiesDataOptionsType> = ({ options }) => {
    if (!options || !options.is_posie) {
        return {};
    }

    return {
        isPosie: options.is_posie,
    };
};

export const fieldGetters: GetFieldsType<any>[] = [
    getEntityFtRadiusOptions,
    getEntityReportAvailability,
    getEntityIsVisitDurationLimited,
    getIsPOSIEEntity,
];
