import { ERRORS, ErrorType } from 'API/common/constants';
import type { Dictionary } from '@placer-ui/types';
import { POLLING_STATUS } from 'API/common/poll-request/constants';
import { ResponseStatus } from 'API/common/types';

const pollingStatus = Object.keys(POLLING_STATUS);

const supportedErrors: { [error: string]: ErrorType } = {
    NO_SALES_ESTIMATION_DATA: ERRORS.NO_DATA,
    API_NOT_SUPPORTED: ERRORS.NO_DATA,
    NO_CHAIN_COVERAGE: ERRORS.NO_CHAIN_COVERAGE,
    INVALID_DATE_RANGE: ERRORS.INVALID_DATE_RANGE,
    ERR_INSUFFICIENT_DATA: ERRORS.INSUFFICIENT_DATA,
    NEARBY_ACTIVITY_UNAVAILABLE: ERRORS.NEARBY_ACTIVITY_UNAVAILABLE,
};

export function getErrorOrStatus(status: number, data: Dictionary<any>): ResponseStatus {
    switch (status) {
        case 401:
            return {
                status: 401,
                type: data.details,
                info: data.details,
            };
        case 209:
            if (supportedErrors[data.error]) {
                return {
                    status: 209,
                    type: supportedErrors[data.error],
                    info: data.details,
                };
            }

            return {
                status: 209,
                type: ERRORS.UNKNOWN_ERROR,
            };
        case 204:
            return {
                status: 204,
                type: ERRORS.INSUFFICIENT_DATA,
            };
        case 244:
            return {
                status: 244,
                type: ERRORS.RESTRICTED_INSUFFICIENT_DATA,
                info: data.details,
            };
        case 202:
            return {
                status: 202,
                type: POLLING_STATUS.ACCEPTED,
            };
        case 404:
            return {
                status: 404,
                type: ERRORS.ERROR_NOT_FOUND,
                info: data,
            };
        case 400:
            if (supportedErrors[data.error]) {
                return {
                    status: 400,
                    type: supportedErrors[data.error],
                    info: data.details,
                };
            }

            return {
                status: 400,
                type: ERRORS.INVALID_ARGUMENTS,
                info: data,
            };
        case 403:
            if (data.error === ERRORS.RESTRICTED_AREA && data.error_code === 1016) {
                return {
                    status: 403,
                    type: ERRORS.NEARBY_ACTIVITY_UNAVAILABLE,
                    info: data,
                };
            } else if (data.error === ERRORS.UNDELIVERABLE_ENTITY) {
                return {
                    status: 403,
                    type: ERRORS.UNDELIVERABLE_ENTITY,
                    info: data,
                };
            }
            return {
                status: 403,
                type: ERRORS.PERMISSION_DENIED,
                info: data,
            };
        case 500:
            if (data.error === ERRORS.ERR_IN_PROGRESS || pollingStatus.includes(data.error)) {
                return {
                    status: 444,
                    type: data.error,
                    info: data,
                };
            }

            if (data.error_code === 6002 && supportedErrors[data.error]) {
                return {
                    status: 400,
                    type: supportedErrors[data.error],
                    info: data,
                };
            }

            return {
                status: 500,
                type: supportedErrors[data.error] ?? ERRORS.UNKNOWN_ERROR,
                info: data,
            };
        case 502:
        case 503:
        case 504:
            return {
                status: status,
                type: ERRORS.UNKNOWN_ERROR,
            };
        default:
            return {
                status: 500,
                type: ERRORS.UNKNOWN_ERROR,
                info: data,
            };
    }
}

export function fetchErrorMiddleware(response: Response): Response | Promise<Response> {
    if (response.status === 200) {
        return response;
    }
    return Promise.reject(response);
}

export function parseJsonMiddleware(response: Response) {
    return response.json().then(
        (json) => json,
        () =>
            Promise.reject(
                getErrorOrStatus(500, {
                    details: 'Broken JSON from server',
                }),
            ),
    );
}
