import { createAsyncThunk } from '@reduxjs/toolkit';
import { ConfigurationCategoriesData } from '@placer-ui/types';

import { API } from 'API';
import { AppState } from 'store/store';
import { Awaited } from 'types/awaited';
import { selectIsCategoriesInitialized } from 'store/configuration/categories/selectors';
import { UserPermissions } from 'core/entities/user/user-permissions';
import { UserFeatureFlags } from 'core/entities/user/user-feature-flags';
import { sortStrings } from 'utils/strings-sorter/strings-sorter';

function sortCategoriesRecursive(categories: ConfigurationCategoriesData[]) {
    return categories
        .slice(0)
        .sort((A, B) => sortStrings(A.label, B.label))
        .map((category) => {
            const clonedCategory: ConfigurationCategoriesData = { ...category };
            if (Array.isArray(category.children) && category.children.length) {
                clonedCategory.children = sortCategoriesRecursive(category.children);
            } else {
                delete (clonedCategory as any).children;
            }
            return clonedCategory;
        });
}

type GetCategoriesResponseType = Awaited<ReturnType<typeof API.configuration.getCategories>>;

type PermissionsPayload = {
    userPermissions?: UserPermissions;
    featuresFlags?: UserFeatureFlags;
};

export const getCategories = createAsyncThunk<
    GetCategoriesResponseType['data'],
    PermissionsPayload | undefined,
    { state: AppState }
>(
    'configuration/get-categories',
    async (permissions?: PermissionsPayload) => {
        const hasSubcategoryPermission =
            permissions?.userPermissions?.enable_subcategory_dropdown_ff;
        const hasRemoveEmptyCategoriesFF =
            permissions?.featuresFlags?.chain_category_picker_remove_empty_categories_ff;

        let storeData;
        try {
            storeData = (window as any)?.dataForUI || (window as any)?.opener?.dataForUI;
        } catch (ignore) {
            /* empty */
        }
        const existingCategories = storeData?.configuration?.categories;
        if (existingCategories) return existingCategories;

        const { data: categories } = await API.configuration.getCategories(
            hasSubcategoryPermission,
            hasRemoveEmptyCategoriesFF,
        );
        await API.configuration.getCategoriesPathUrl(categories.category_data);
        const sortedCategoryData = sortCategoriesRecursive(categories.category_data ?? []);
        categories.category_data = sortedCategoryData;
        return categories;
    },
    {
        condition(
            _params: PermissionsPayload | undefined | void,
            thunkAPI: { getState: () => AppState },
        ) {
            const state = thunkAPI.getState();

            const isCategoriesInitialized = selectIsCategoriesInitialized(state);

            return !isCategoriesInitialized;
        },
    },
);
