import { CreatePoiState } from 'features/create-poi/types';
import { history } from 'router/history';

interface GoToPoiEditorParams {
    state: CreatePoiState;
    target: '_blank' | '_self';
}

export const goToPoiEditor = ({ state, target }: GoToPoiEditorParams) => {
    const pathname = '/custom-poi';
    const search = `?data=${JSON.stringify(state)}`;
    if (target === '_blank') {
        window.open(pathname + search);
    } else {
        history.push({
            pathname,
            search,
        });
    }
};
