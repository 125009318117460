import React, { ReactNode } from 'react';
import styles from './solution.module.scss';
import { BodyText } from '@placer-ui/components';
import classNames from 'classnames';

type SolutionProps = {
    title: string;
    description: string;
    icon: ReactNode;
    onClick: () => void;
    isActive?: boolean;
};

export const Solution = ({ title, description, icon, onClick, isActive }: SolutionProps) => {
    return (
        <div
            className={classNames(styles.root, {
                [styles.active]: isActive,
            })}
            onClick={onClick}
        >
            <div className={styles.content}>
                <div className={styles.title}>
                    <div className={styles.iconWrapper}>{icon}</div>
                    <BodyText variant={'bold'}>{title}</BodyText>
                </div>
                <BodyText color={'secondary'}>{description}</BodyText>
            </div>
        </div>
    );
};
