/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

import { IconProps } from 'components/assets/Icons/Icons';

const ManuallyInspectedBadgeSvg = (props: CustomIconComponentProps | SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 14 16">
        <g fill="none" fillRule="evenodd">
            <path
                stroke="#55B18E"
                strokeWidth=".75"
                d="M7 .402L.375 2.94v4.914c-.002 1.902.407 3.309 2.016 4.914C3.463 13.838 4.977 14.79 7 15.597c2.023-.807 3.537-1.76 4.609-2.829 1.609-1.605 2.018-3.012 2.016-4.914V2.94L7 .402z"
            />
            <path
                fill="#55B18E"
                fillRule="nonzero"
                d="M4.649 11V7.875c0-.697-.025-1.444-.075-2.24h.035L6.473 11H7.4l1.93-5.357h.034c-.011.108-.025.419-.041.932-.017.512-.025.928-.025 1.248V11h1.011V4.575H8.824L6.987 9.686H6.96l-1.784-5.11h-1.48V11h.953z"
            />
        </g>
    </svg>
);

export const ManuallyInspectedBadgeIcon = (props: IconProps) => (
    <Icon component={ManuallyInspectedBadgeSvg} {...props} />
);
