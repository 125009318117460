import type { BaseUserAuth } from '@placer-ui/types';
import { isJest } from 'core/constants/environment';
import { createLoginCookie, removeLoginCookie } from 'store/auth/utils/utils';
import { loginCookieKey, loginImpersonateCookieKey } from 'utils/cookies/utils';

export const savedTagStorageKey = 'PLAC_TAGS';

export type TagStates =
    | 'admin-panel.my-zone.poi'
    | 'admin-panel.my-zone.list'
    | 'admin-panel.my-zone.tags';

export interface LocalStorageTag {
    state: TagStates;
    params: {
        type: 'my-poi' | 'shared-poi' | 'venues' | 'chains' | 'billboards' | 'personal' | 'shared';
        id?: string;
    };
}

/**
 * this is an interface to the logged-in user data with cookie storage behind the scenes.
 * no need to turn directly to cookies.
 */
export class UserCookieStorage {
    user?: BaseUserAuth;
    /** the key of the cookie */
    cookieName: string = '';

    constructor(cookieName: string, user?: BaseUserAuth) {
        this.cookieName = cookieName;
        this.user = user;
    }

    setUser(user: BaseUserAuth, skipCreateCookie?: boolean) {
        if (!skipCreateCookie) createLoginCookie(user, this.cookieName);
        this.user = user;
    }

    removeUser() {
        removeLoginCookie(this.cookieName);
        this.user = undefined;
    }

    get token() {
        if (this.user) {
            return this.user.token;
        } else {
            !isJest && console.warn('Tried to retrieve an empty user token');
            return '';
        }
    }
}

export const userCookieStorage = new UserCookieStorage(loginCookieKey);
export const impersonateUserCookieStorage = new UserCookieStorage(loginImpersonateCookieKey);

export class TagTokenStorage {
    get tag() {
        return this.parseTag();
    }

    setToken(tag: LocalStorageTag) {
        window.localStorage.setItem(savedTagStorageKey, JSON.stringify(tag));
    }

    removeToken() {
        window.localStorage.removeItem(savedTagStorageKey);
    }

    private parseTag() {
        const savedTag = window.localStorage.getItem(savedTagStorageKey);

        if (!savedTag) {
            return undefined;
        }

        try {
            const parsedTag = JSON.parse(savedTag) as LocalStorageTag;

            return parsedTag;
        } catch (e) {
            return undefined;
        }
    }
}

export const TagStorage = new TagTokenStorage();
