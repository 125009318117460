import { reportException } from 'core/exceptions';
import { sendHubspotForm } from 'API/hubspot-api/hubspot-api';

const UPGRADE_MODAL_FORM_ID = '824ecbbc-f500-466c-8082-1c149107a7fe';

export const submitUpgradeModalHubspotForm = (email: string) => {
    const fields = [
        {
            name: 'email',
            value: email,
        },
        {
            name: 'contact_sales_reason',
            value: '',
        },
        {
            name: 'contact_sales_form_text',
            value: '',
        },
    ];

    sendHubspotForm(UPGRADE_MODAL_FORM_ID, fields).catch((error) => {
        reportException(error, {
            payload: {
                UPGRADE_MODAL_FORM_ID,
                ...fields,
            },
            method: 'POST',
            // eslint-disable-next-line max-len
            target_url: `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.REACT_APP_HUBSPOT_PORTAL_ID}/${UPGRADE_MODAL_FORM_ID}`,
        });
    });
};
