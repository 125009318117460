import { MultiSelectOption, TreeNode } from 'ui-components/multi-select/types';

export const getLeafNodes = (nodes?: TreeNode[], result: TreeNode[] = []) => {
    nodes?.forEach((node, idx) => {
        if (!nodes[idx].children?.length) {
            result.push(nodes[idx]);
        } else {
            result = getLeafNodes(nodes[idx].children as TreeNode[], result);
        }
    });

    return result;
};

export const getLeavesOptionsKeys = (nodes: MultiSelectOption[], result: MultiSelectOption[] = []) => {
    nodes?.forEach((node, idx) => {
        if (!nodes[idx].subOptions?.length) {
            result.push(nodes[idx]);
        } else {
            result = getLeavesOptionsKeys(nodes[idx].subOptions ?? [], result);
        }
    });

    return result;
};
