/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

type LampIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type LampIconProps = SVGProps<SVGSVGElement> & LampIconUniqueProps;

export const LampIcon = function LampIcon({
    width = 12,
    height = 12,
    ...props
}: LampIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <mask id="path-2-inside-1_866_51413" fill="currentColor">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16ZM12 9.625C11.7929 9.625 11.625 9.79289 11.625 10C11.625 10.2071 11.7929 10.375 12 10.375C12.8975 10.375 13.625 11.1025 13.625 12C13.625 12.2071 13.7929 12.375 14 12.375C14.2071 12.375 14.375 12.2071 14.375 12C14.375 10.6883 13.3117 9.625 12 9.625Z"
                />
            </mask>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16ZM12 9.625C11.7929 9.625 11.625 9.79289 11.625 10C11.625 10.2071 11.7929 10.375 12 10.375C12.8975 10.375 13.625 11.1025 13.625 12C13.625 12.2071 13.7929 12.375 14 12.375C14.2071 12.375 14.375 12.2071 14.375 12C14.375 10.6883 13.3117 9.625 12 9.625Z"
                fill="currentColor"
            />
            <path
                d="M15.25 12C15.25 13.7949 13.7949 15.25 12 15.25V16.75C14.6234 16.75 16.75 14.6234 16.75 12H15.25ZM12 8.75C13.7949 8.75 15.25 10.2051 15.25 12H16.75C16.75 9.37665 14.6234 7.25 12 7.25V8.75ZM8.75 12C8.75 10.2051 10.2051 8.75 12 8.75V7.25C9.37665 7.25 7.25 9.37665 7.25 12H8.75ZM12 15.25C10.2051 15.25 8.75 13.7949 8.75 12H7.25C7.25 14.6234 9.37665 16.75 12 16.75V15.25ZM12.375 10C12.375 10.2071 12.2071 10.375 12 10.375V8.875C11.3787 8.875 10.875 9.37868 10.875 10H12.375ZM12 9.625C12.2071 9.625 12.375 9.79289 12.375 10H10.875C10.875 10.6213 11.3787 11.125 12 11.125V9.625ZM14.375 12C14.375 10.6883 13.3117 9.625 12 9.625V11.125C12.4832 11.125 12.875 11.5168 12.875 12H14.375ZM14 11.625C14.2071 11.625 14.375 11.7929 14.375 12H12.875C12.875 12.6213 13.3787 13.125 14 13.125V11.625ZM13.625 12C13.625 11.7929 13.7929 11.625 14 11.625V13.125C14.6213 13.125 15.125 12.6213 15.125 12H13.625ZM12 10.375C12.8975 10.375 13.625 11.1025 13.625 12H15.125C15.125 10.2741 13.7259 8.875 12 8.875V10.375Z"
                fill="currentColor"
                mask="url(#path-2-inside-1_866_51413)"
            />
            <path d="M12 6.5L12 7" stroke="currentColor" strokeWidth="0.75" strokeLinecap="round" />
            <path d="M17.5 12L17 12" stroke="currentColor" strokeWidth="0.75" strokeLinecap="round" />
            <path d="M7 12L6.5 12" stroke="currentColor" strokeWidth="0.75" strokeLinecap="round" />
            <path
                d="M15.889 15.8891L15.5355 15.5355"
                stroke="currentColor"
                strokeWidth="0.75"
                strokeLinecap="round"
            />
            <path
                d="M8.46448 8.46446L8.11092 8.11091"
                stroke="currentColor"
                strokeWidth="0.75"
                strokeLinecap="round"
            />
            <path
                d="M15.889 8.11096L15.5355 8.46452"
                stroke="currentColor"
                strokeWidth="0.75"
                strokeLinecap="round"
            />
            <path
                d="M8.46448 15.5355L8.11092 15.8891"
                stroke="currentColor"
                strokeWidth="0.75"
                strokeLinecap="round"
            />
            <path
                d="M10.375 15.375H12H13.625V16.5C13.625 17.1213 13.1213 17.625 12.5 17.625H11.5C10.8787 17.625 10.375 17.1213 10.375 16.5V15.375Z"
                stroke="currentColor"
                strokeWidth="0.75"
            />
        </svg>
    );
};
LampIcon.__tags = ['lamp', 'light', 'insight'] as string[];
