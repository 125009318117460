/* eslint-disable max-len */
import React from 'react';

export const SgBidIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
        >
            <g clipPath="url(#clip0_3566_18694)">
                <path
                    d="M9.50368 7.68458L7.63265 0.666504H1.18734C0.899689 0.666504 0.666504 0.899689 0.666504 1.18734V10.6781L9.14004 8.32052C9.41504 8.24401 9.57722 7.96039 9.50368 7.68458Z"
                    fill="#E1E2FF"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.50368 7.68458C9.57722 7.96039 9.41504 8.24401 9.14004 8.32052L0.666504 10.6781V1.18734C0.666504 0.899689 0.899689 0.666504 1.18734 0.666504H7.63265L9.50368 7.68458ZM1.44775 1.44775H7.03239L8.68244 7.63691L1.44775 9.64979V1.44775Z"
                    fill="#5E63E5"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.5651 7.75771C11.2871 7.83243 11.0011 7.66737 10.9268 7.3892L9.13004 0.666504H16.8123C17.1 0.666504 17.3332 0.899689 17.3332 1.18734V6.20759L11.5651 7.75771ZM16.5519 5.60858L11.6142 6.93555L10.1475 1.44775H16.5519V5.60858Z"
                    fill="#5E63E5"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.03714 10.7672C5.75964 10.8419 5.59508 11.1272 5.6694 11.4048L7.25653 17.3332H16.8124C17.1 17.3332 17.3332 17.1 17.3332 16.8124V7.72675L6.03714 10.7672ZM6.49133 11.454L7.85614 16.552H16.552V8.74608L6.49133 11.454Z"
                    fill="#5E63E5"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.63722 11.403C3.91456 11.3302 4.19858 11.4953 4.27262 11.7723L5.7591 17.3333H1.18734C0.899689 17.3333 0.666504 17.1001 0.666504 16.8124V12.1828L3.63722 11.403ZM1.44775 16.552V12.7854L3.58482 12.2245L4.74159 16.552H1.44775Z"
                    fill="#5E63E5"
                />
            </g>
            <defs>
                <clipPath id="clip0_3566_18694">
                    <rect
                        width="16.6667"
                        height="16.6667"
                        fill="white"
                        transform="translate(0.666504 0.666504)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
