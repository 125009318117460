/* eslint-disable max-len */
import type { SVGProps } from 'react';
import React from 'react';

type AddedSitePoiIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type AddedSitePoiIconProps = SVGProps<SVGSVGElement> & AddedSitePoiIconUniqueProps;

export const AddedSitePoiIcon = function AddedSitePoiIcon({
    width = 12,
    height = 20,
    ...props
}: AddedSitePoiIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 12 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_5524_391893)">
                <mask
                    id="mask0_5524_391893"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="5"
                    width="12"
                    height="12"
                >
                    <path d="M12 5H0V17H12V5Z" fill="white" />
                </mask>
                <g mask="url(#mask0_5524_391893)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0 4.6001H3V6.23646C3 7.1402 2.32843 7.87283 1.5 7.87283C0.671573 7.87283 0 7.1402 0 6.23646V4.6001Z"
                        fill="#9D9D9D"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9 4.80005H12V6.23644C12 7.14018 11.3284 7.87281 10.5 7.87281C9.67157 7.87281 9 7.14018 9 6.23644V4.80005Z"
                        fill="#9D9D9D"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4 5H8V5.69091C8 6.89589 7.10457 7.87273 6 7.87273C4.89543 7.87273 4 6.89589 4 5.69091V5Z"
                        fill="#9D9D9D"
                    />
                </g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.37951 7.68164C8.8214 8.45159 9.60588 8.96364 10.5 8.96364C10.6714 8.96364 10.8387 8.94483 11.0004 8.90899L11 15.9091C11 16.5116 10.5523 17 10 17H2C1.44772 17 1 16.5116 1 15.9091L1.00048 8.90919C1.16188 8.9449 1.32894 8.96364 1.5 8.96364C2.39412 8.96364 3.1786 8.45159 3.61869 7.68172C4.16706 8.46116 5.02974 8.96364 6 8.96364C6.97026 8.96364 7.83294 8.46116 8.37951 7.68164ZM5.8999 10.3C6.17604 10.3 6.3999 10.5239 6.3999 10.8V12.2H7.8C8.07614 12.2 8.3 12.4239 8.3 12.7C8.3 12.9762 8.07614 13.2 7.8 13.2H6.3999V14.6C6.3999 14.8762 6.17604 15.1 5.8999 15.1C5.62376 15.1 5.3999 14.8762 5.3999 14.6V13.2H4C3.72386 13.2 3.5 12.9762 3.5 12.7C3.5 12.4239 3.72386 12.2 4 12.2H5.3999V10.8C5.3999 10.5239 5.62376 10.3 5.8999 10.3Z"
                    fill="#9D9D9D"
                />
            </g>
            <defs>
                <clipPath id="clip0_5524_391893">
                    <rect width="12" height="12" fill="white" transform="translate(0 5)" />
                </clipPath>
            </defs>
        </svg>
    );
};
AddedSitePoiIcon.__tags = ['site poi empty_space'] as string[];
