import {
    getBrowserName,
    getBrowserVersion,
    getDeviceCores,
    getDeviceMemory,
    getDeviceType,
    getOSName,
} from './utils';
import { isJest } from 'core/constants/environment';

export type TechnicalData = {
    browser: string;
    browser_version: string;
    device: string;
    os: string;
    window_width: string;
    window_height: string;
    device_memory?: string;
    cores?: number;
    connection_download?: number;
};

export const getUserAgentTechnicalData = (): TechnicalData => ({
    browser: getBrowserName(),
    browser_version: getBrowserVersion(),
    device: getDeviceType(),
    os: getOSName(),
    window_width: `${window.innerWidth}px`,
    window_height: `${window.innerHeight}px`,
    ...(!isJest
        ? {
              device_memory: `${getDeviceMemory()}GB`,
              cores: getDeviceCores(),
              connection_download: (navigator.connection as any)?.downlink || 0,
          }
        : {}),
});
