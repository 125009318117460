import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DEFAULT_FILTER_BY_TYPE } from 'features/insights/consts/filters/default-filters';
import type { ConfigFilterValue, FilterType } from '@placer-ui/types';
import {
    poisFiltersSelector,
    poisFiltersWithDefaultsSelector,
} from 'features/insights/store/selectors/insights-selectors';
import { useReportPlaces } from 'extensions/widget/hooks/use-poi-hooks';
import { usePrivacyRestricted } from 'features/insights/hooks/use-privacy-restricted';
import { useGetFilterDescription } from 'features/insights/hooks/use-filter-description';
import { useEntitiesDefaultFilters } from 'features/insights/hooks/use-entities-default-filters';
import { fromBackendFilters } from 'features/insights/utils/convert-filters/convert-filters';
import { LENGTH_OF_STAY_ATTRIBUTE } from 'core/constants/filter-attributes';

export type InsightsQuickFilter = {
    type: FilterType | 'radiusList';
    text: string;
};

export const useQuickFilters = (isMobile = false): Array<InsightsQuickFilter[]> => {
    const filtersWithDefaults = useSelector(poisFiltersWithDefaultsSelector);
    const backendFilters = useSelector(poisFiltersSelector);
    const oldFilters = fromBackendFilters(backendFilters);
    const isSomePoiPrivacyRestricted = usePrivacyRestricted();
    const getFilterDescription = useGetFilterDescription();
    const { all } = useReportPlaces();
    const { areDefaultFilter } = useEntitiesDefaultFilters();

    const isRadiusFilterEnabled = useMemo(
        () =>
            !isSomePoiPrivacyRestricted &&
            !!all.length &&
            all.some((entity) => entity.filters?.config?.ft_radius),
        [all, isSomePoiPrivacyRestricted],
    );

    // returns array of quick filters (for each poi)
    return useMemo(() => {
        if (isMobile) {
            if (!filtersWithDefaults?.length) {
                return [];
            }
        } else {
            if (!oldFilters?.length) {
                return [];
            }
        }

        const filters = isMobile ? filtersWithDefaults : oldFilters;
        const poisQuickFiltersArr: Array<InsightsQuickFilter[]> = [];

        filters.forEach((filter, index) => {
            const { config, ...attributes } = filter;
            const quickFiltersArr: InsightsQuickFilter[] = [];

            if (isMobile && isRadiusFilterEnabled && index === 0 && config) {
                const { ft_radius } = config.value as ConfigFilterValue;
                quickFiltersArr.push({
                    type: 'config',
                    text: `Radius: ${ft_radius} ft`,
                });
            }

            for (const key of Object.keys(attributes)) {
                const type = key as FilterType;

                if (isMobile && all.length > 1 && areDefaultFilter(filters, type)) {
                    quickFiltersArr.push({
                        text: DEFAULT_FILTER_BY_TYPE[type]!,
                        type,
                    });
                } else {
                    quickFiltersArr.push({
                        text: getFilterDescription(type, filter[type], { isCompact: true }),
                        type,
                    });
                }
            }

            // Default Visit duration filter Should be visible to user if visit duration report selector value is mixed
            // Chip will be filtered in useActiveFilters hook
            if (!attributes[LENGTH_OF_STAY_ATTRIBUTE] && !isMobile) {
                quickFiltersArr.push({
                    text: getFilterDescription(
                        'visit_duration',
                        filtersWithDefaults[index][LENGTH_OF_STAY_ATTRIBUTE],
                        { isCompact: true },
                    ),
                    type: LENGTH_OF_STAY_ATTRIBUTE,
                });
            }

            poisQuickFiltersArr.push(quickFiltersArr);
        });

        return poisQuickFiltersArr;
    }, [
        isMobile,
        filtersWithDefaults,
        oldFilters,
        isRadiusFilterEnabled,
        all.length,
        areDefaultFilter,
        getFilterDescription,
    ]);
};

export const useMobileQuickFilters = () => {
    const quickFilters = useQuickFilters(true);
    return quickFilters[0] || [];
};
