import { userAPI } from 'API/user-api';
import { history } from 'router/history';
import { SIGNUP_FULL_FORM_STEP_ROUTE } from 'features/authentication/signup/constants/signup-links';
import { BaseUserAuth } from '@placer-ui/types';

const setUserAsGoogleAuthenticated = async (token: string) => {
    const { custom_settings } = await userAPI.getCustomSettings(token);
    if (!custom_settings?.isGoogleAuthenticatedUser) {
        await userAPI.setUserCustomSettings(
            {
                isGoogleAuthenticatedUser: true,
            },
            token,
        );
    }
};

export const isNewGoogleUser = async (token: string): Promise<boolean> => {
    const { custom_settings } = await userAPI.getCustomSettings(token);
    const isNewUser = !custom_settings?.isGoogleAuthenticatedUser;

    await setUserAsGoogleAuthenticated(token);

    return isNewUser;
};

export const saveUserInLocalStorage = (cookieUser: BaseUserAuth) => {
    localStorage.setItem('user', JSON.stringify(cookieUser));
};

export const navigateToFullSignupForm = (userEmail: string, nextUrl: string | null) => {
    const params = new URLSearchParams();
    params.append('email', userEmail);
    params.append('google_auth', 'true');
    if (nextUrl) params.append('next_url', nextUrl);
    history.push({
        pathname: SIGNUP_FULL_FORM_STEP_ROUTE,
        search: params.toString(),
    });
};
