import React from 'react';

import styles from './user-loginas-dialog.module.scss';
import { Dialog } from 'ui-components/dialog';
import { Loading } from 'ui-components/loading/loading-component';

type UserExitLoginAsModalProps = {
    visible: boolean;
};

export const UserExitLoginAsModal = ({ visible }: UserExitLoginAsModalProps) => {
    return (
        <Dialog
            visible={visible}
            maskClosable={false}
            destroyOnClose
            width="45vw"
            closable={false}
            footer={null}
            className={styles.loginasDialogWrapper}
        >
            <Loading className={styles.commentsLoading} show={true} />
        </Dialog>
    );
};
