import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReportNotAvailableError } from 'components/errors/report-not-available-error/report-not-available-error';
import { useReportPlaces } from 'extensions/widget/hooks/use-poi-hooks';
import { insightsEntityAdapter } from 'features/insights/store/selectors/insights-selectors';
import { FeatureContext } from 'context/FeatureContext';
import { isNearbyActivity } from 'utils/poi-filter/poi-filter';
import { hasPermissions } from 'core/flow-control';
import type { BackendFilter } from 'features/insights/types/filter-types';
import styles from './report-unavailable.module.scss';

const ELEVIO_ID = '551';

export const ReportUnavailableError = () => {
    const history = useHistory();
    const { all } = useReportPlaces();
    const nearbyActivityPOIs = all.filter((poi) => isNearbyActivity(poi));
    const hasPermission = hasPermissions(['lock_restricted_nearby_activity_report']);

    if (!hasPermission) {
        return null;
    }

    const handleRemoveNearbyClick = () => {
        const searchParams = new URLSearchParams(history.location.search);
        const filterParam = searchParams.get('filter');
        if (filterParam) {
            const filters = JSON.parse(filterParam) as BackendFilter[];
            const filtersWithoutNearby = filters.map((filter) => {
                const { config: _, ...restOfFilter } = filter;
                return restOfFilter;
            });
            searchParams.set('filter', JSON.stringify(filtersWithoutNearby));
            history.push({
                pathname: history.location.pathname,
                state: history.location.state,
                search: searchParams.toString(),
            });
        }
    };

    return (
        <FeatureContext.Provider value={insightsEntityAdapter}>
            <div className={styles.container}>
                <ReportNotAvailableError
                    primaryText="Remove Nearby POI"
                    showPrimaryButton={all.length !== nearbyActivityPOIs.length}
                    onPrimaryClick={handleRemoveNearbyClick}
                >
                    <p>
                        This Nearby Activity Report is not available because it contains at least
                        one restricted area.
                        <br />
                        Please adjust the ring radius size or contact our{' '}
                        <a href="mailto:support@placer.ai">support</a> team for assistance.{' '}
                        <span
                            data-elevio-style="nothing"
                            data-elevio-inline={ELEVIO_ID}
                            className={styles.learnMoreLink}
                        >
                            Learn more
                        </span>
                    </p>
                </ReportNotAvailableError>
            </div>
        </FeatureContext.Provider>
    );
};
