import AntIcon from '@ant-design/icons';
import React, { ComponentProps, ReactElement } from 'react';
import Highlighter from 'react-highlight-words';
import { LockedFunctionalityOverlay } from 'extensions/locked-functionality-overlay';
import { Tooltip } from 'ui-components/tooltip/tooltip';
import { GrayLockIcon } from 'components/assets/Icons/GrayLockIcon';
import { StoreIdBadge } from 'components/store-id-badge/store-id-badge';

import styles from './search-autocomplete-suggestion.module.scss';

type WarningInfo = {
    Icon: React.FC<ComponentProps<typeof AntIcon>>;
    tooltipText: string;
};

type SearchAutocompleteSuggestionProps = {
    Icon?: React.FC<ComponentProps<typeof AntIcon>>;
    name: string;
    location?: string;
    isLocked?: boolean;
    termToHighlight: string;
    displayUpgradeYourPlan?: boolean;
    warningInfo?: WarningInfo;
    noUnlockNearbyActivity?: boolean;
    dataTestId?: string;
    storeId?: string;
    rightSideIcon?: ReactElement;
};

export const SearchAutocompleteSuggestion = ({
    Icon,
    name,
    location = '',
    isLocked = false,
    displayUpgradeYourPlan = true,
    termToHighlight,
    warningInfo,
    noUnlockNearbyActivity,
    dataTestId,
    storeId,
    rightSideIcon,
}: SearchAutocompleteSuggestionProps) => {
    return (
        <div data-testid={dataTestId} className={styles.suggestionContentWrapper}>
            {displayUpgradeYourPlan && <LockedFunctionalityOverlay isLocked={isLocked} />}
            {Icon && <Icon className={styles.poiIcon} />}

            <div className={styles.suggestionDetailsWrapper}>
                <div className={styles.titleWrapper}>
                    <div className={styles.suggestedPoiName}>
                        <Highlighter
                            highlightClassName={styles.poiNameTermMatchingPiece}
                            searchWords={[termToHighlight]}
                            autoEscape={true}
                            textToHighlight={name}
                        />
                    </div>
                    {storeId && (
                        <StoreIdBadge
                            storeId={`${storeId}`}
                            dataTestId={`store-id-${dataTestId}`}
                        />
                    )}
                </div>
                <div className={styles.suggestedPoiLocation}>
                    <Highlighter
                        highlightClassName={styles.poiLocationTermMatchingPiece}
                        searchWords={[termToHighlight]}
                        autoEscape={true}
                        textToHighlight={location}
                    />
                </div>
            </div>

            {warningInfo && (
                <Tooltip
                    small
                    title={warningInfo.tooltipText}
                    placement={'right'}
                    arrowPointAtCenter
                >
                    <warningInfo.Icon className={styles.sideIcon} />
                </Tooltip>
            )}
            {isLocked && !noUnlockNearbyActivity && <GrayLockIcon className={styles.sideIcon} />}
            {rightSideIcon && <div className={styles.sideIcon}>{rightSideIcon}</div>}
        </div>
    );
};
