import moment, { Moment } from 'moment';

import { Granularity } from 'core/constants/granularity';
import type { Granularity as DateGranularity } from 'components/date-compare-select/date-compare-select-types';
import { CacheDateOption } from 'core/entities/user/user-configuration';

export const dateFormats = {
    monthHour: 'MMM D hh:mm a',
    monthDay: 'MMM DD',
    monthDayAndYear: 'MMM DD, YYYY',
    monthDayNoLeadingZero: 'MMM D',
    monthYear: 'MMM, YYYY',
    monthYearNoSeparator: 'MMM YYYY',
    monthDotYear: 'MM.YYYY',
    fullMonthDayYear: 'ddd, MMMM DD, YYYY',
    dayfullMonthDay: 'ddd, MMMM DD',
    monthDateDayHour: 'MMM DD, ddd hh:mm a',
    fullMonthYear: 'MMMM, YYYY',
    fullMonthYearNoSeparator: 'MMMM YYYY',
    shortDayMonthYear: 'DD.MM.YY',
    LongDayMonthYear: 'DD.MM.YYYY',
    monthDayYear: 'MM.DD.YYYY',
    monthDayYearComma: 'MMM D, YYYY',
    shortMonthWithNumericOrdinalContractionDay: 'MMM Do',
    shortMonthYearWithNumericOrdinalContractionDay: 'MMM Do YYYY',
    shortMonthYearWithNumericOrdinalContractionDayComma: 'MMM Do, YYYY',
    dashedFullDate: 'YYYY-MM-DD',
    yearMonthDayCompact: 'YYYYMMDD',
    dashedYearMonth: 'YYYY-MM',
    hour: 'hh:mm a',
    shortHour: 'ha',
    year: 'YYYY',
    fullDateHour: 'MMMM Do YYYY, h:mm:ss a',
    month: 'MMMM',
    internationalHour: 'HH:mm',
    numericDayMonthYear: 'DD-MM-YYYY',
    shortMonthNameLongYear: 'MMM DD YYYY',
    monthDate: 'MMM DD',
    fullDayOfWeek: 'dddd',
    shortDayOfWeek: 'ddd',
};

export const getNumericDayMonthYearFormatDate = (
    date: Moment | string,
    format: string = dateFormats.numericDayMonthYear,
) => {
    return moment(date).format(format);
};

export const getShortMonthShortYearFormatDate = (
    date: Moment | string,
    format: string = dateFormats.shortMonthNameLongYear,
) => {
    return moment(date).format(format);
};

export const getSimpleFormatDate = (
    date: Moment | string,
    format: string = dateFormats.monthYear,
) => {
    return moment(date).format(format);
};

export const getSimpleFormatRange = (
    start: Moment | string,
    end: Moment | string,
    format: string = dateFormats.LongDayMonthYear,
) => {
    const dateStart = getSimpleFormatDate(start, format);
    const dateEnd = getSimpleFormatDate(end, format);

    return `${dateStart} - ${dateEnd}`;
};

export const getSameYearFormatRange = (
    start: Moment | string,
    end: Moment | string,
    format: string = dateFormats.monthDay,
    fullDateFormat: string = dateFormats.monthDayYearComma,
) => {
    if (!isSameYear(start, end)) {
        return getSimpleFormatRange(start, end, fullDateFormat);
    } else {
        const year = getSimpleFormatDate(start, dateFormats.year);
        const dateStart = getSimpleFormatDate(start, format);
        const dateEnd = getSimpleFormatDate(end, format);

        return `${dateStart} - ${dateEnd}, ${year}`;
    }
};

export const getSameMonthFormatRange = (start: Moment | string, end: Moment | string) => {
    const dateStartMoment = moment(start);
    const dateEndMoment = moment(end);
    if (
        dateStartMoment.isSame(dateEndMoment, 'year') &&
        dateStartMoment.isSame(dateEndMoment, 'month') &&
        dateStartMoment.date() === 1 &&
        dateEndMoment.date() === moment(dateEndMoment).endOf('month').date()
    ) {
        return dateEndMoment.format('MMMM YYYY');
    } else {
        return null;
    }
};

/**
 * Creates a full month range for a date range that is not a full month range.
 * Exists becuase chain reports only support full month date ranges.
 * For date ranges that are not full months, this function gets the most recent full month
 *
 * @param {{
 *  start: Moment | string,
 *  end: Moment | string,
 * }} dates - The start and end date of the range that is not a complete month
 * @param {string} maxReportMonth - The maximum valid month for a report
 *
 * @return {{
 *  startDate: string,
 *  endDate: string,
 * }}
 *
 * @example
 * const dates = createFullMonthsDate({
 *   start: '2021-05-15',
 *   end: '2022-04-14'
 * }, '2022-05');
 */
export const createFullMonthsDate = (
    dates: {
        start: string | Moment;
        end: string | Moment;
    },
    maxReportMonth: string | Moment,
) => {
    let end = moment(dates.end);
    const start = moment(dates.start);
    const max = moment(maxReportMonth).endOf('month');

    if (end.isSameOrAfter(max)) {
        end = max;
    }

    return {
        startDate: getSimpleFormatDate(start.startOf('month'), dateFormats.dashedFullDate),
        endDate: getSimpleFormatDate(end.endOf('month'), dateFormats.dashedFullDate),
    };
};

/**
 * Checks if a particular date set is the start and end of a month, i.e a full month
 * @return {boolean}
 */
export const isFullMonth = (startDate: Moment | string, endDate: Moment | string) => {
    const isSameMonth = moment(startDate).isSame(endDate, 'month');
    const isStartOfMonth = moment(startDate).clone().startOf('month').isSame(startDate, 'day');
    const isEndOfMonth = moment(endDate).clone().endOf('month').isSame(endDate, 'day');

    return isSameMonth && isStartOfMonth && isEndOfMonth;
};

/**
 * Checks if a particular date set is the start of any month and end of any month
 * @return {boolean}
 */
export const isFullMonthRange = (startDate: Moment | string, endDate: Moment | string) => {
    const isStartOfMonth = moment(startDate).clone().startOf('month').isSame(startDate, 'day');
    const isEndOfMonth = moment(endDate).clone().endOf('month').isSame(endDate, 'day');

    return isStartOfMonth && isEndOfMonth;
};

export const isFullYear = (startDate: Moment | string, endDate: Moment | string) => {
    const isSameMonth = moment(startDate).isSame(endDate, 'year');
    const isStartOfMonth = moment(startDate).clone().startOf('year').isSame(startDate, 'day');
    const isEndOfMonth = moment(endDate).clone().endOf('year').isSame(endDate, 'day');

    return isSameMonth && isStartOfMonth && isEndOfMonth;
};

/**
 * Checks if a particular date set is full months, not have to be the same month as prev method
 * @return {boolean}
 */
export const isFullMonths = (startDate: Moment | string, endDate: Moment | string) => {
    const isStartOfMonth = moment(startDate).clone().startOf('month').isSame(startDate, 'day');
    const isEndOfMonth = moment(endDate).clone().endOf('month').isSame(endDate, 'day');
    return isStartOfMonth && isEndOfMonth;
};

export const isSameYear = (startDate: Moment | string, endDate: Moment | string) => {
    return moment(startDate).isSame(endDate, 'year');
};

export const isSameMonth = (startDate: Moment | string, endDate: Moment | string) => {
    return moment(startDate).isSame(endDate, 'month');
};

export const isSameDay = (date1: Moment | string, date2: Moment | string) => {
    return moment(date1).isSame(date2, 'day');
};

export const isToday = (date: Moment | string) => {
    return isSameDay(moment(), date);
};

type LessThenFunctionArgs = {
    startDate?: Moment | string;
    endDate?: Moment | string;
    number: number;
};
export const isLessThanFullWeeksNumber = ({ startDate, endDate, number }: LessThenFunctionArgs) => {
    const startDateMoment = moment(startDate);
    const endDateMoment = moment(endDate);
    const startDateSetToNextMonday = startDateMoment
        .clone()
        .add(1, !startDateMoment.day() ? 'day' : 'week')
        .day('Monday');
    const endDateSetToMonday = endDateMoment.clone().day('Monday');
    const endDateIncreasedIn1Day = endDateMoment.clone().add(1, 'day');

    const isStartDayTheFirstDayOfWeek = startDateMoment.day() === 1;
    if (isStartDayTheFirstDayOfWeek) {
        return endDateIncreasedIn1Day.diff(startDateMoment, 'week') < number;
    }

    const isEndDayTheLastDayOfWeek = endDateMoment.day() === 0;
    if (isEndDayTheLastDayOfWeek) {
        return endDateIncreasedIn1Day.diff(startDateSetToNextMonday, 'week') < number;
    }

    return endDateSetToMonday.diff(startDateSetToNextMonday, 'week') < number;
};

export const isLessThanFullMonthsNumber = ({
    startDate,
    endDate,
    number,
}: LessThenFunctionArgs) => {
    const originalStartDateMoment = moment(startDate);
    const originalEndDateMoment = moment(endDate);
    const firstDayOfStartDateMonth = originalStartDateMoment.clone().startOf('month');
    const firstDayOfEndDateMonth = originalEndDateMoment.clone().startOf('month');
    const firstDayOfMonthNextAfterStartDateMonth = originalStartDateMoment
        .clone()
        .add(1, 'month')
        .startOf('month');
    const lastDayOfEndDateMonth = originalEndDateMoment.clone().endOf('month');
    const endDateIncreasedIn1Day = originalEndDateMoment.clone().add(1, 'day');

    if (endDateIncreasedIn1Day.diff(originalStartDateMoment, 'month') < 1) {
        return true;
    }

    const isStartDateTheFirstDayOfTheMonth = firstDayOfStartDateMonth.isSame(
        originalStartDateMoment,
        'day',
    );
    if (isStartDateTheFirstDayOfTheMonth) {
        return endDateIncreasedIn1Day.diff(originalStartDateMoment, 'month') < number;
    }

    const isEndDateTheLastDayOfTheMonth = lastDayOfEndDateMonth.isSame(
        originalEndDateMoment,
        'day',
    );
    if (isEndDateTheLastDayOfTheMonth) {
        return (
            endDateIncreasedIn1Day.diff(firstDayOfMonthNextAfterStartDateMonth, 'month') < number
        );
    }

    return firstDayOfEndDateMonth.diff(firstDayOfMonthNextAfterStartDateMonth, 'month') < number;
};

export const labelSuggestion = (
    startDate: Moment | string = moment(),
    endDate: Moment | string = moment(),
    shortDate = false,
) => {
    const dateFormat = shortDate ? 'MMM DD' : 'MMM DD, YYYY';

    if (isFullMonth(startDate, endDate)) {
        return moment(startDate).format('MMMM YYYY');
    }

    if (isFullYear(startDate, endDate)) {
        return `Jan 01 - Dec 31, ${moment(startDate).format('YYYY')}`;
    }

    const end = moment(endDate).format(dateFormat);

    if (isSameYear(startDate, endDate)) {
        const start = moment(startDate).format('MMM DD');
        return `${start} - ${end}`;
    }

    const start = moment(startDate).format(dateFormat);
    return `${start} - ${end}`;
};

export const dateLabelSuggestion = (date: Moment | string) => {
    if (isToday(date)) {
        return moment(date).format(dateFormats.hour);
    }

    return moment(date).format('MMM DD, YYYY');
};

export const chartBinsLabels = (bin: number, granularity: string, shortFormat: boolean = false) => {
    switch (granularity) {
        case 'hour':
            const tickData = new Date(2015, 1, 1, bin, 0, 0);
            return moment(tickData).format(shortFormat ? dateFormats.shortHour : dateFormats.hour);
        case 'day':
            return moment(bin).format(
                shortFormat ? dateFormats.shortDayOfWeek : dateFormats.monthDay,
            );
        case 'week':
            return moment(bin).format(dateFormats.monthDay);
        case 'month':
            return moment(bin).format(dateFormats.monthYear);
        case 'year':
            return moment(bin).format(dateFormats.year);
        default:
            return '';
    }
};

export const chartTooltipTitleLabels = (date: string, granularity: Granularity) => {
    const momentDate = moment(date);
    const nextWeekDate = momentDate.clone().add(6, 'days');

    switch (granularity) {
        case 'day':
            return momentDate.format(dateFormats.fullMonthDayYear);
        case 'week':
            const isSameYearDates = isSameYear(date, nextWeekDate.format('YYYY-MM-DD'));
            const startDate = isSameYearDates
                ? momentDate.format(dateFormats.dayfullMonthDay)
                : momentDate.format(dateFormats.fullMonthDayYear);

            const endDate = nextWeekDate.format(dateFormats.fullMonthDayYear);

            return `${startDate} - ${endDate}`;
        case 'month':
            return momentDate.format(dateFormats.fullMonthYear);
        case 'year':
            return momentDate.format(dateFormats.year);
        default:
            return '';
    }
};

export const chartTooltipShortDateLabel = (date: string, granularity: Granularity) => {
    const mDate = moment(date);

    switch (granularity) {
        case 'week': {
            const next = mDate.clone().add(6, 'days');
            const format = next.isSame(mDate, 'year') ? 'MMM DD' : 'MMM DD, YYYY';

            return `${mDate.format(format)} - ${next.format('MMM DD, YYYY')}`;
        }
        case 'month':
            return mDate.format('MMMM, YYYY');
        default:
            return '';
    }
};

export const getStartEndDateOfMonth = (month: string, format = 'YYYY-MM-DD') => {
    const momentDate = moment(month);

    const startOfMonth = momentDate.startOf('month').format(format);
    const endOfMonth = momentDate.endOf('month').format(format);

    return {
        startOfMonth,
        endOfMonth,
    };
};

export const areDatesEqual = (...dates: string[]) => {
    const dateToCompareWith = dates[0];

    return dates.every((date) => moment(date).isSame(dateToCompareWith));
};

export const getYearMonthDayOfDate = (date: Moment | string) => {
    const momentDate = moment(date);

    return {
        day: momentDate.format('D'),
        month: momentDate.format('M'),
        year: momentDate.format('YYYY'),
    };
};

export const generateTwentyFourHoursOptions = () => {
    const options = [
        {
            value: '00:00',
            label: '12:00 am',
        },
    ];

    for (let ham = 1; ham < 12; ++ham) {
        const hour = ('0' + ham).substr(-2) + ':00';
        options.push({
            value: hour,
            label: hour + ' am',
        });
    }

    options.push({
        value: '12:00',
        label: '12:00 pm',
    });

    for (let hpm = 1; hpm < 12; ++hpm) {
        options.push({
            value: 12 + hpm + ':00',
            label: ('0' + hpm).substr(-2) + ':00 pm',
        });
    }

    options.push({
        value: '23:59',
        label: '11:59 pm',
    });

    return options;
};

type FilterLabelByGranularityProps = {
    granularity: DateGranularity;
    dateOptions: CacheDateOption;
    year?: string;
};

export const getFilterLabelByGranularity = ({
    granularity,
    dateOptions,
    year,
}: FilterLabelByGranularityProps) => {
    switch (granularity) {
        case 'year': {
            if (year) {
                return year;
            }
            return `${getSameYearFormatRange(dateOptions.start, dateOptions.end)} (YTD)`;
        }
        case 'last-12-months':
            return 'the last 12 months';
        case 'last-6-months':
            return 'the last 6 months';
        case 'last-3-months':
        case 'quarter_year':
            return 'the last 3 months';
        case 'month':
        case 'previous_month':
            return moment(dateOptions.start).format('MMMM YYYY');
        default:
            return getSameYearFormatRange(dateOptions.start, dateOptions.end);
    }
};
