import React from 'react';
import styles from './poi-item.module.scss';

import { LocationIcon } from 'components/assets/Icons/Icons';
import { VoidAnalysisPoi } from 'features/void-analysis/common/types/entities';
import { ButtonWithTooltip } from 'ui-components/button-with-tooltip/button-with-tooltip';
import { useIsPoiSupportedForReport } from 'features/void-analysis/sections/add-new-report/hooks/add-new-report-hooks';
import { useOpenVoidAnalysisReportFromPropertySection } from 'features/void-analysis/sections/entry-point-redirection/hooks/entry-point-redirect-hooks';

type PoiItemProps = {
    poi: VoidAnalysisPoi;
};

export const PoiItem = ({ poi }: PoiItemProps) => {
    const openVoidAnalysisReportFromPropertySection =
        useOpenVoidAnalysisReportFromPropertySection();
    const isPoiSupportedForReport = useIsPoiSupportedForReport();
    const { isOnlineReportSupported, tooltipContent } = isPoiSupportedForReport(
        poi.originalShapeInfo,
    );

    return (
        <div className={styles.poiItem}>
            <div className={styles.content}>
                <div className={styles.detailsContainer}>
                    <span className={styles.name}>{poi.name}</span>
                    <div className={styles.addressContainer}>
                        <LocationIcon
                            className={styles.locationIcon}
                            style={{ color: poi.color }}
                        />
                        <span>{poi.address}</span>
                    </div>
                </div>
                <ButtonWithTooltip
                    disabled={!isOnlineReportSupported}
                    buttonText={'Run Report'}
                    tooltip={!isOnlineReportSupported ? tooltipContent : ''}
                    onClick={() => openVoidAnalysisReportFromPropertySection(poi.originalShapeInfo)}
                    buttonProps={{ className: styles.button }}
                />
            </div>

            <div className={styles.separator} />
        </div>
    );
};
