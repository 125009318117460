import React from 'react';
import { Button as AntButton } from 'antd';

export type ShowResultsButtonType = 'show all' | 'show less';

type ShowResultsButtonProps = {
    type: ShowResultsButtonType;
    onClick: (type: ShowResultsButtonType) => void;
};

export const ShowResultsButton = ({ type, onClick }: ShowResultsButtonProps) => {
    const buttonText = type === 'show all' ? 'Show All' : 'Show Less';
    const testId = type === 'show all' ? 'show-all-button' : 'show-less-button';
    return (
        <AntButton data-testid={testId} onClick={() => onClick(type)} type="link">
            {buttonText}
        </AntButton>
    );
};
