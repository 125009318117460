import React, { ReactNode, useRef } from 'react';
import classnames from 'classnames';
import { Button } from 'ui-components/button/button';
import { Tab } from 'ui-components/side-panel/components/side-panel-section';
import styles from './side-panel-button.module.scss';
import { Tooltip } from 'ui-components/tooltip/tooltip';

type SidePanelButtonProps = {
    isActive?: boolean;
    tab: Tab;
    children?: ReactNode;
    textClassName?: string;
    isSidePanelClosed?: boolean;
    shouldShowTooltipOnTrim?: boolean;
};

export const SidePanelButton = ({
    isActive,
    tab,
    children,
    textClassName,
    isSidePanelClosed,
    shouldShowTooltipOnTrim,
}: SidePanelButtonProps) => {
    const reportNameSpanRef = useRef<HTMLSpanElement>(null);
    const tabHasTooltip = Boolean(tab.tooltip);
    const forceShowTooltip = isSidePanelClosed || tabHasTooltip;
    const tooltipText = tabHasTooltip ? tab.tooltip : tab.title;

    return (
        <Tooltip
            small
            title={tab.isBeta ? `${tooltipText} - Beta` : tooltipText}
            placement="right"
            ellipsisElementRef={reportNameSpanRef}
            showOnlyInCaseOfEllipsis={!forceShowTooltip}
        >
            <Button
                data-testid={tab.testId}
                type="ghost"
                className={classnames(styles.button, {
                    [styles.active]: isActive,
                    [styles.disabled]: tab.disabled,
                    [styles.comingSoon]: tab.comingSoon,
                    [styles.highlighted]: tab.isHighlighted,
                })}
                onClick={tab.onClick}
            >
                <span className={styles.iconWrap}>
                    <span
                        className={classnames(styles.icon, {
                            [styles.withHoverIcon]: Boolean(tab.hoverIcon),
                        })}
                    >
                        {tab.icon}
                    </span>
                    {tab.hoverIcon && (
                        <span className={classnames(styles.hoverIcon, styles.icon)}>
                            {tab.hoverIcon}
                        </span>
                    )}
                </span>

                <span
                    className={classnames(styles.text, textClassName)}
                    ref={shouldShowTooltipOnTrim ? reportNameSpanRef : undefined}
                >
                    {tab.isBeta ? `${tab.title} - Beta` : tab.title}
                </span>

                {tab.rightIcon && <span className={styles.rightIcon}>{tab.rightIcon}</span>}
                {children}
            </Button>
        </Tooltip>
    );
};
