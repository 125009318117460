import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

import { IconProps } from 'components/assets/Icons/Icons';

/* eslint-disable max-len */
export const FitnessIconSvg = (props: CustomIconComponentProps | SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 23 22">
        <g fill="none" fillRule="evenodd">
            <g fill="currentColor">
                <g transform="translate(-82.000000, -2937.000000) translate(40.000000, 2202.000000) translate(32.000000, 508.000000) translate(0.000000, 217.000000) translate(10.413699, 10.151199) translate(10.848801, 10.848801) rotate(-45.000000) translate(-10.848801, -10.848801) translate(-2, 5) scale(1.3)">
                    <rect width="2.025" height="6.667" x="2.025" y="2" rx="1" />
                    <rect width="1.35" height="2.667" y="4" rx=".675" />
                    <rect width="2.025" height="10.667" x="4.725" rx="1" />
                    <rect
                        width="2.025"
                        height="6.667"
                        x="15.968"
                        y="2"
                        rx="1"
                        transform="translate(16.980877, 5.333333) scale(-1, 1) translate(-16.980877, -5.333333)"
                    />
                    <rect
                        width="1.35"
                        height="2.667"
                        x="18.668"
                        y="4"
                        rx=".675"
                        transform="translate(19.343377, 5.333333) scale(-1, 1) translate(-19.343377, -5.333333)"
                    />
                    <rect
                        width="2.025"
                        height="10.667"
                        x="13.268"
                        rx="1"
                        transform="translate(14.280877, 5.333333) scale(-1, 1) translate(-14.280877, -5.333333)"
                    />
                    <path
                        fillRule="nonzero"
                        d="M12.709 4.333L12.709 6.333 7.309 6.333 7.309 4.333z"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export const FitnessIcon = (props: IconProps) => <Icon component={FitnessIconSvg} {...props} />;
