/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';
import { IconProps } from 'components/assets/Icons/Icons';

export const CoffeeAndBakeriesAndDessertShopsIconSvg = (
    props: CustomIconComponentProps | SVGProps<SVGSVGElement>,
) => (
    <svg {...props} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.63968 2.6154C3.63968 2.76172 3.48071 2.99085 3.37448 3.09851C3.22796 3.24445 3.35035 3.48091 3.49687 3.62686C3.64338 3.7728 3.88078 3.7728 4.0273 3.62686C4.07711 3.57759 4.51231 3.12917 4.51231 2.61543C4.51231 2.10168 4.07714 1.65324 4.0284 1.60507C3.92106 1.49634 3.76209 1.2672 3.76209 1.12089C3.76209 0.974571 3.92106 0.745433 4.0284 0.636705C4.17381 0.490763 4.17346 0.255049 4.0273 0.109456C3.88078 -0.0364855 3.521 -0.0364855 3.37448 0.109456C3.32467 0.158726 2.88947 0.607138 2.88947 1.12089C2.88947 1.63463 3.32464 2.08307 3.37338 2.13125C3.48071 2.23995 3.63968 2.46909 3.63968 2.6154Z"
            fill="currentColor"
        />
        <path
            d="M5.76995 2.6154C5.76995 2.76172 5.61098 2.99085 5.50475 3.09851C5.35823 3.24445 5.48061 3.48091 5.62713 3.62686C5.77365 3.7728 6.01104 3.7728 6.15756 3.62686C6.20737 3.57759 6.64258 3.12917 6.64258 2.61543C6.64258 2.10168 6.2074 1.65324 6.15866 1.60507C6.05133 1.49634 5.89236 1.2672 5.89236 1.12089C5.89236 0.974571 6.05133 0.745433 6.15866 0.636705C6.30408 0.490763 6.30373 0.255049 6.15756 0.109456C6.01104 -0.0364855 5.65126 -0.0364855 5.50475 0.109456C5.45493 0.158726 5.01973 0.607138 5.01973 1.12089C5.01973 1.63463 5.45491 2.08307 5.50365 2.13125C5.61098 2.23995 5.76995 2.46909 5.76995 2.6154Z"
            fill="currentColor"
        />
        <path
            d="M11.9986 6.47965C11.9643 5.48911 11.3042 4.79698 10.3934 4.79698C10.1677 4.79698 9.94166 4.83817 9.72868 4.9042C9.7371 4.79177 9.74597 4.6991 9.75563 4.67139C9.86861 4.55885 9.90254 4.38987 9.84124 4.24274C9.78032 4.09565 9.6361 4 9.47647 4H0.789735C0.63011 4 0.472775 4.07916 0.411467 4.22586C0.350555 4.37295 0.370982 4.52506 0.483962 4.63759C0.53832 4.74667 0.5603 5.20182 0.578042 5.56746C0.664383 7.35653 0.843698 11.1056 5.13384 11.1056C6.9744 11.1056 8.05537 10.3928 8.70061 9.46926C10.9205 9.23784 12.045 7.81622 11.9986 6.47965ZM11.0164 6.54083C11.045 7.37427 10.6543 8.0368 9.24434 8.35599C9.63134 7.38942 9.63199 6.78199 9.67118 6C9.85966 5.88427 10.124 5.80689 10.3698 5.80689C10.9043 5.87851 11.0021 6.30013 11.0164 6.72252V6.54083Z"
            fill="currentColor"
        />
        <path
            d="M0.394852 12H9.87129C10.0895 12 10.2661 11.7764 10.2661 11.5C10.2661 11.2236 10.0895 11 9.87129 11H0.394852C0.176604 11 0 11.2236 0 11.5C0 11.7764 0.176604 12 0.394852 12Z"
            fill="currentColor"
        />
    </svg>
);

export const CoffeeAndBakeriesAndDessertShopsIcon = (props: IconProps) => (
    <Icon component={CoffeeAndBakeriesAndDessertShopsIconSvg} {...props} />
);
