import { createAction } from '@reduxjs/toolkit';
import { ChainEntityModel } from 'core/entities';

const setReportChainsAtChainsAction = 'chains/set-report-chains';
export const setReportChainsAtChains = createAction<
    ChainEntityModel[],
    typeof setReportChainsAtChainsAction
>(setReportChainsAtChainsAction);

const removeReportChainsByIdsAction = 'chains/remove-chains-by-ids';
export const removeReportChainsByIds = createAction<
    string[],
    typeof removeReportChainsByIdsAction
>(removeReportChainsByIdsAction);
