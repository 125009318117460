import styles from './options-selected.module.scss';
import React from 'react';
import classNames from 'classnames';
import { Key } from 'rc-tree/lib/interface';

export const getSelectedOptionsElement = (
    optionsSelectedData?: {
        checked: Key[];
        total: number;
    },
    className?: string,
) => {
    let text = '';

    if (!optionsSelectedData?.checked.length) {
        text = 'No Filters Applied';
    } else {
        const checkedOptions = optionsSelectedData?.checked?.length;
        const total = optionsSelectedData?.total;

        text = `${checkedOptions}/${total} Selected`;
    }
    return <div className={classNames(styles.optionsSelectedData, className)}>{text}</div>;
};
