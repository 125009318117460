import { useSelector } from 'react-redux';
import { userCustomSettingsSelector } from 'store/selectors/app-selectors';
import { ComparisonDates } from 'types/properties-data';
import { Granularity, useDateOptions } from 'components/date-compare-select';

const defaultGranularity: Granularity = 'week';

// format: 'Granularity-GranularityYear' || 'Granularity'
export const useDefaultDatesGranularity = () => {
    const userCustomSettings = useSelector(userCustomSettingsSelector);
    return userCustomSettings?.myProperties?.dates?.selectedDateGranularity ?? defaultGranularity;
};

// format: 'Granularity-GranularityYear' || 'Granularity'
export const useDefaultCompareDatesGranularity = () => {
    const userCustomSettings = useSelector(userCustomSettingsSelector);
    return userCustomSettings?.myProperties?.dates?.selectedCompareDateGranularity?.toString();
};

export const useDefaultComparisonDates = (): ComparisonDates | undefined => {
    const dateOptions = useDateOptions();
    const selectedGranularity = useDefaultDatesGranularity();
    const selectedCompareGranularity = useDefaultCompareDatesGranularity();

    const defaultDateOption = dateOptions.find((option) => option.key === selectedGranularity);

    if (!defaultDateOption) {
        return;
    }

    const compareDateOptions = defaultDateOption.compareOptions || [];
    const defaultComparedDate =
        compareDateOptions.find((option) => option.key === selectedCompareGranularity) ||
        compareDateOptions[0];

    return {
        start_date: defaultDateOption.startDate,
        end_date: defaultDateOption.endDate,
        compared_start_date: defaultComparedDate.startDate,
        compared_end_date: defaultComparedDate.endDate,
    };
};
