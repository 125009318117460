import { useEffect, useState } from 'react';

import type { SidebarState } from 'ui-components/sidebar-dialog/sidebar-dialog-constants-and-types';
import { getEventType } from 'ui-components/sidebar-dialog/sidebar-dialog-utils';

// the animation speed is defined both, here and in the css.
// it can't be injected by JS because dialog::backdrop doesn't support inherited css-variables.
const OPEN_ANIMATION_SPEED = 200;

export const useListenToToggleEvent = (uid: string) => {
    const [shouldOpen, setShouldOpen] = useState(false);
    const eventType = getEventType(uid);

    useEffect(() => {
        const listener = ((event: CustomEvent<SidebarState>) => {
            const state = event.detail;
            if (state === 'open') {
                setShouldOpen(true);
            } else {
                setShouldOpen(false);
            }
        }) as EventListener;

        document.body.addEventListener(eventType, listener);

        return function cleanup() {
            document.body.removeEventListener(eventType, listener);
        };
    }, [eventType]);

    return shouldOpen;
};

export const useReactToToggles = (dialog: HTMLDialogElement | null, shouldOpen: boolean) => {
    const [isOpened, setIsOpened] = useState(false);

    useEffect(() => {
        if (dialog) {
            if (shouldOpen && !dialog.open) {
                // opening
                dialog.showModal();
                document.body.classList.add('no-scroll');

                setTimeout(() => {
                    // let react render and only then add the class so the animation will run
                    dialog?.classList.add('opened');
                }, 1);

                setTimeout(() => {
                    setIsOpened(true);
                }, OPEN_ANIMATION_SPEED);
            } else if (!shouldOpen && dialog.open) {
                // closing
                dialog.classList.remove('opened');

                // wait for the animation to finish before letting react remove the element
                setTimeout(() => {
                    dialog?.close();
                    setIsOpened(false);
                    document.body.classList.remove('no-scroll');
                    (document.activeElement as HTMLElement)?.blur();
                }, OPEN_ANIMATION_SPEED);
            }
        }
    }, [shouldOpen, dialog]);

    return isOpened;
};
