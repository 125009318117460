import React, { useEffect, useRef } from 'react';
import { useGetAllProperties } from 'features/poi-selection-map/poi-selection-menu/menu-items/portfolio-menu-item/hooks/portfoilio-poi-hooks';
import { PoiSelectionTable } from 'features/poi-selection-map/poi-selection-menu/shared-components/poi-selection-table/poi-selection-table';

export const PortfolioPoiSelection = () => {
    const getAllProperties = useGetAllProperties();
    const hasRequestedProperties = useRef<boolean>(false);

    useEffect(() => {
        if (hasRequestedProperties.current) return;
        hasRequestedProperties.current = true;
        getAllProperties();
    }, [getAllProperties]);

    return (
        <PoiSelectionTable
            tableProps={{
                emptyState: {
                    title: 'No Portfolio Items Found',
                    description: ' ',
                },
            }}
        />
    );
};
