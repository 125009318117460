import { userCookieStorage } from 'core/authentification';

export const isAutomationTest = (): boolean => {
    const urlParams = new URLSearchParams(window.location.search);
    const automationCookie = document.cookie.match(/(^|;\s*)stop-beamer=([^;]+)/);
    return (
        window.navigator.webdriver ||
        isAutomationUserName() ||
        urlParams.get('automation') === 'yes' ||
        (!!automationCookie && automationCookie[2] === '1')
    );
};

export const isAutomationUserName = (): boolean => {
    const username = userCookieStorage.user?.username;

    return !!(username?.match(/@.*mailosaur/) || username?.startsWith('support_test'));
};
