import { isNil } from 'lodash/fp';
import type { SimpleFetchError } from 'API/fetch/types';

export function isSimpleFetchError(error: unknown): error is SimpleFetchError {
    if (typeof error === 'object' && error !== null) {
        if (
            typeof (error as any).status === 'number' &&
            typeof (error as any).statusText === 'string' &&
            typeof (error as any).errorName === 'string' &&
            !isNil((error as any).body)
        ) {
            return true;
        }
    }

    return false;
}
