import { useSelector } from 'react-redux';
import React from 'react';

import { ReportTypes } from 'core/constants/report-type';
import { selectTopSearchReportTypes } from 'store/header/search/selectors/select-top-search-report-types';
import { EntityTypeDropdownOption } from './components';
import { useSelectorWithProps } from 'utils/structured-selector/structured-selector';
import { selectHasUserPermission } from 'store/selectors/app-selectors';

import styles from './entity-type-dropdown-options-overlay.module.scss';

type EntityTypeDropdownOptionsOverlayProps = {
    selectedEntityType: ReportTypes;
    selectEntityType: (entityType: ReportTypes) => void;
};

export const EntityTypeDropdownOptionsOverlay = ({
    selectedEntityType,
    selectEntityType,
}: EntityTypeDropdownOptionsOverlayProps) => {
    const reportTypes = useSelector(selectTopSearchReportTypes);
    const hasNewBillboardsTextPermission = useSelectorWithProps(
        { permission: 'enable_new_billboards_text_change' },
        selectHasUserPermission,
    );

    return (
        <div className={styles.optionsOverlay}>
            {reportTypes.map((entityType) => {
                const isSelected = entityType === selectedEntityType;

                const labelDropDown = entityType === 'Billboard' && hasNewBillboardsTextPermission
                    ? 'Traffic Pin' : entityType;

                return (
                    <EntityTypeDropdownOption
                        key={entityType}
                        isSelected={isSelected}
                        label={labelDropDown}
                        selectEntityType={() => selectEntityType(entityType)}
                    />
                );
            })}
        </div>
    );
};
