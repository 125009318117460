import type { Dictionary, PlaceType, PlacerArrayResponse } from '@placer-ui/types';
import { fetchWrapper } from 'API/authentification/fetch-wrapper';
import { ReportName, SharedReport } from 'core/entities/share-report/share-report';

export type ShareReportParams = {
    recipient_emails: string[];
    message: string;
    component_name: ReportName;
    entity_id: string;
    entity_name: string;
    entity_type: PlaceType;
    relative_path: string;
    fe_payload?: Dictionary<any>;
};

const targetUrl = '/share/';

export const shareReportApi = {
    createSharedReport: (params: ShareReportParams) => {
        return fetchWrapper({
            targetUrl,
            method: 'POST',
            body: params,
        });
    },
    getSharedReportById: (shareId: string): PlacerArrayResponse<SharedReport> => {
        return fetchWrapper({
            targetUrl: `/share/${shareId}`,
            method: 'GET',
        });
    },
    getSharedReports: (componentName: ReportName): PlacerArrayResponse<SharedReport[]> => {
        return fetchWrapper({
            targetUrl: `/share/recipient?component_name=${componentName}`,
            method: 'GET',
        });
    },
};
