import { ConfigurationAttributesFilters } from '@placer-ui/types/src/dto/configuration/configuration-attributes-filters';
import { ConfigurationAttributionState } from '@placer-ui/types/src/dto/configuration/configuration-attribution-state';

export type ConfigurationAttributesDTO = {
    filters: ConfigurationAttributesFilters;
    states: ConfigurationAttributionState[];
};
export interface ConfigurationAttributesState extends ConfigurationAttributesDTO {}

export const createConfigurationAttributesState = (): ConfigurationAttributesState => ({
    filters: {
        household_income: [],
    },
    states: [],
});
