import React from 'react';
import { TableColumn } from 'ui-components/table/types';
import styles from 'ui-components/table/table.module.scss';
import { LoadingBar } from 'ui-components/loading-bar/loading-bar';

export const defaultExpandColumnConfig = {
    columnWidth: 25,
};

export const DEFAULT_VISIBLE_COLUMNS_NUM = 5;

export const defaultColumnConfig: Partial<TableColumn<any>> = {
    width: '15%',
    ellipsis: true,
    showSorterTooltip: false,
};

export const dotsMenuColumnConfig = {
    key: 'row_dots_menu',
    title: null,
    className: styles.dotsMenuColumn,
    width: 40,
};

export const defaultLoadingConfig = {
    indicator: <LoadingBar className={styles.progressBar} />,
    className: styles.loading,
    wrapperClassName: styles.loadingWrapper,
};

export const defaultEmptyState = {
    title: 'No Data',
    description: 'Try changing the search term',
};
