import { useSelectorWithProps } from 'utils/structured-selector/structured-selector';
import { selectSubCategoriesFromMultipleCategories } from 'store/selectors/app-selectors';

const AVAILABLE_CATEGORIES = [
    'Shopping Centers',
    'Super-Regional Malls',
    'Strip/Convenience',
    'Regional Malls',
    'Power Centers',
    'Neighborhood Centers',
    'Lifestyle Centers',
    'Factory Outlets',
    'Community Centers',
];

export const SHOPPING_CENTERS_CATEGORY = 'Shopping Centers';

export const useSubCategoriesForShoppingCenters = () => {
    return useSelectorWithProps(
        { categories: AVAILABLE_CATEGORIES },
        selectSubCategoriesFromMultipleCategories,
    );
};
