import React from 'react';
import { SgEntertainmentIcon } from 'features/onboarding/solutions-guide/icons/sg-entertainment-icon';
import { CHAINS_ROUTE } from 'features/onboarding/solutions-guide/consts/constants';

export const ENTERTAINMENT_SOLUTIONS_GUIDE = {
    icon: <SgEntertainmentIcon />,
    solutions: [
        {
            title: 'Competitive Intelligence',
            description:
                'Find out how competing chains are performing to benchmark ' +
                'your performance and identify growing trends.',
            url: CHAINS_ROUTE,
        },
        {
            title: 'Visitor Insights',
            description:
                'Uncover comprehensive demographic and psychographic ' +
                'insights about your visitors at the property or chain level.',
            url: '/insights/venues/f91a485c6ed7212171ff9d99/audience',
        },
        {
            title: 'Sponsorships & Partnerships',
            description:
                'Attract ideal sponsors based on attendee demographics, cross-visitation, and brand affinity.',
            url: '/insights/venues/f91a485c6ed7212171ff9d99/overview',
        },
        {
            title: 'Site Selection & Expansion',
            description:
                'Easily identify ideal sites for a new venues ' +
                'based on customizable scoring parameters and filters.',
            url: '/advanced-reports/freemium/site-selection',
        },
        {
            title: 'Marketing & Advertising',
            description:
                'Better understand where visitors are coming from to optimize ' +
                'ad geotargeting to reach the highest potential areas.',
            url: '/insights/venues/f91a485c6ed7212171ff9d99/trade-area',
        },
        {
            title: 'Campaign Impact Analysis',
            description:
                'Measure event or marking campaign success, lift, or ' +
                'incrementality, with accurate offline foot traffic data.',
            url: '/insights/venues/f91a485c6ed7212171ff9d99/overview',
        },
    ],
};
