import { useEffect, useState } from 'react';
import { DEFAULT_VISIBLE_COLUMNS_NUM } from 'ui-components/table/constants';
import { BaseRowDataType, TableColumn } from 'ui-components/table/types';

export interface PoiScrollPosition {
    start: number;
    end: number;
}

export const useScrollPosition = <RowDataType extends BaseRowDataType>(
    columnVisibleCount: number = DEFAULT_VISIBLE_COLUMNS_NUM,
    columnsList: TableColumn<RowDataType>[],
) => {
    const columnsListCount = columnsList.length;

    if (!columnVisibleCount) {
        columnVisibleCount = DEFAULT_VISIBLE_COLUMNS_NUM;
    }

    const { position, columnsCount, scrollRight, scrollLeft } = useScrollPositionHook(
        columnVisibleCount,
        columnsListCount,
    );

    return {
        position,
        columnsCount,
        scrollRight,
        scrollLeft,
    };
};

export const useScrollPositionHook = (columnVisibleCount: number, columnsCount: number) => {
    const [position, setPosition] = useState<PoiScrollPosition>({
        start: 0,
        end: columnVisibleCount,
    });

    useEffect(() => {
        setPosition({
            start: 0,
            end: columnVisibleCount,
        });
    }, [columnVisibleCount, columnsCount]);

    const scrollRight = () => {
        if (position.end < columnsCount) {
            setPosition({
                start: position.start + 1,
                end: position.start + 1 + columnVisibleCount,
            });
        }
    };

    const scrollLeft = () => {
        if (position.start > 0) {
            setPosition({
                start: position.start - 1,
                end: position.start - 1 + columnVisibleCount,
            });
        }
    };

    return {
        position,
        columnsCount,
        scrollRight,
        scrollLeft,
    };
};
