import { placerMapStyles } from './placer-map-styles';
import { BBox } from 'geojson';

export const defaultZoom = 15;
export const USABoundingBox: BBox = [-172, 3, -36, 76];
export const USACenterPoint = {
    lat: 39.5,
    lng: -115,
};

export const fortWorthBoundingBox: BBox = [-97.4678454, 32.694993, -97.2206533, 32.815312];
export const fortWorthCenterPoint = {
    lat: 32.7551301,
    lng: -97.3439059,
};

export const USANorthBorderLat = 50;

export const USAWestBorderLng = -126;

export const defaultMapContainerStyle = {
    width: '100%',
    height: '100%',
};

export const defaultMapOptions: google.maps.MapOptions &
    Required<
        Pick<
            google.maps.MapOptions,
            | 'mapTypeControl'
            | 'mapTypeControlOptions'
            | 'zoom'
            | 'fullscreenControl'
            | 'disableDefaultUI'
            | 'controlSize'
            | 'styles'
            | 'center'
            | 'scaleControl'
        >
    > = {
    mapTypeControl: true,
    mapTypeControlOptions: {
        mapTypeIds: ['roadmap', 'hybrid'],
        position: 6,
    },
    zoom: 5,
    fullscreenControl: true,
    disableDefaultUI: false,
    controlSize: 28,
    styles: placerMapStyles,
    center: USACenterPoint,
    scaleControl: true,
};

export const defaultVectorMapOptions: google.maps.MapOptions &
    Required<
        Pick<
            google.maps.MapOptions,
            | 'mapTypeControl'
            | 'zoom'
            | 'minZoom'
            | 'fullscreenControl'
            | 'fullscreenControlOptions'
            | 'disableDefaultUI'
            | 'controlSize'
            | 'center'
            | 'clickableIcons'
        >
    > = {
    minZoom: 4,
    zoom: 5,
    clickableIcons: false,
    disableDefaultUI: false,
    mapTypeControl: false,
    controlSize: 30,
    center: USACenterPoint,
    fullscreenControl: true,
    fullscreenControlOptions: {
        position: 3,
    },
};

export const defaultTiltingOptions: Partial<google.maps.MapOptions> = {
    tilt: 0,
    heading: 0,
    zoom: 15,
};

export const default3dOptions: Partial<google.maps.MapOptions> = {
    tilt: 60,
    heading: 0,
    zoom: 17,
};

export const mapWithoutControlsOptions = {
    ...defaultMapOptions,
    disableDefaultUI: true,
    scaleControl: false,
    zoomControl: false,
    fullscreenControl: false,
    mapTypeControl: false,
};
