import { isNil } from 'lodash/fp';

import type { Dictionary } from '@placer-ui/types';

const exponentialNumberNotationRegex = /^-?(0|[1-9]\d*)(e-?(0|[1-9]\d*))$/;

export function parseDataRecursive(data: string | Dictionary<any>): object | string | undefined {
    if (isNil(data)) return;

    switch (typeof data) {
        case 'string': {
            try {
                return exponentialNumberNotationRegex.test(data)
                    ? data
                    : parseDataRecursive(JSON.parse(data));
            } catch (ignore) {
                return data;
            }
        }
        case 'object': {
            try {
                Object.keys(data).forEach((key) => {
                    data[key] = parseDataRecursive(data[key]);
                });
            } catch (ignore) {
                return data;
            }

            return data;
        }
        default: {
            return data;
        }
    }
}
