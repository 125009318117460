/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

export type UnorderedListIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type UnorderedListIconProps = SVGProps<SVGSVGElement> & UnorderedListIconUniqueProps;

export const UnorderedListIcon = function UnorderedListIcon({
    width = 17,
    height = 16,
    ...props
}: UnorderedListIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <line
                x1="5.06982"
                y1="3.47266"
                x2="15.0698"
                y2="3.47266"
                stroke="currentColor"
                strokeLinecap="round"
            />
            <rect x="1.56982" y="2.49805" width="2" height="2" rx="1" fill="currentColor" />
            <line
                x1="5.06982"
                y1="7.98438"
                x2="15.0698"
                y2="7.98438"
                stroke="currentColor"
                strokeLinecap="round"
            />
            <rect x="1.56982" y="6.99805" width="2" height="2" rx="1" fill="currentColor" />
            <line
                x1="5.06982"
                y1="12.5"
                x2="15.0698"
                y2="12.5"
                stroke="currentColor"
                strokeLinecap="round"
            />
            <rect x="1.56982" y="11.5" width="2" height="2" rx="1" fill="currentColor" />
        </svg>
    );
};
UnorderedListIcon.__tags = ['list unordered bullets'] as string[];
