import React, { createContext } from 'react';

export const PrintWidgetContext = createContext(false);

type PrintWidgetContextWrapperProps = {
    children?: React.ReactNode;
};

export const PrintWidgetContextWrapper = ({ children }: PrintWidgetContextWrapperProps) => {
    return <PrintWidgetContext.Provider value={true}>{children}</PrintWidgetContext.Provider>;
};
