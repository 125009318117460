import { AnalyticsBrowser } from '@segment/analytics-next';
import { reportException } from 'core/exceptions';

// TODO: add pre-production .env file and move this key to it -> PLAC-48894
const REACT_APP_PRE_PROD_SEGMENT_WRITE_KEY = 'vgoER9HhEW2iFjX238vTTh0xnrBNa8EH';

export const getSegmentAnalyticsIntance = (): AnalyticsBrowser | undefined => {
    return window.segmentAnalytics;
};

export const initSegmentAnalytics = (): void => {
    if (window.location.hostname.includes('preproduction-analytics')) {
        loadSegmentAnalytics(REACT_APP_PRE_PROD_SEGMENT_WRITE_KEY);
    } else if (process.env.REACT_APP_SEGMENT_WRITE_KEY) {
        loadSegmentAnalytics(process.env.REACT_APP_SEGMENT_WRITE_KEY);
    }
    return undefined;
};

function loadSegmentAnalytics(writeKey: string): AnalyticsBrowser | undefined {
    try {
        window.segmentAnalytics = AnalyticsBrowser.load({
            writeKey,
        });
    } catch (error) {
        console.error('React Segment analytics is set to undefined due to: ', error);
        reportException(error, {});
        return undefined;
    }
}
