import React from 'react';
import { Button } from 'antd';

import {
    ChainsIconRound,
    CalendarIconRound,
    ReportsIconRound,
} from 'components/assets/Icons/Icons';
import { DialogListItem } from '../dialog-list-item/dialog-list-item';
import { useSplunkCallback } from 'hooks/use-splunk-callback/use-splunk-callback';

type DialogBenefitsScreenProps = {
    onSetDialogView: () => void;
};

const moreChains = {
    title: 'More Chains',
    description: 'Foot-traffic data for hundreds of chains',
};

const historicalData = {
    title: 'Historical Data',
    description: 'Data going back to January 2017',
};

const additionalReports = {
    title: 'Additional Reports',
    description: 'Deeper analysis with more metrics, insights and views',
};

export const DialogBenefitsScreen = ({
    onSetDialogView,
}: DialogBenefitsScreenProps) => {
    const sendEvent = useSplunkCallback();

    const handleOnClick = () => {
        onSetDialogView();
        sendEvent({
            component: 'upgrade-your-plan-popup',
            action: 'button-click',
            btn_name: 'consult-with-sales',
        });
    };

    return (
        <>
            <section className="header-notice">
                <h3>Get access to:</h3>
            </section>
            <section className="upgrade-plan-dialog-body">
                <DialogListItem listItem={moreChains}>
                    <ChainsIconRound className="dialog-item-icon" />
                </DialogListItem>
                <DialogListItem listItem={historicalData}>
                    <CalendarIconRound className="dialog-item-icon" />
                </DialogListItem>
                <DialogListItem listItem={additionalReports}>
                    <ReportsIconRound className="dialog-item-icon" />
                </DialogListItem>
            </section>
            <footer className="upgrade-plan-dialog-footer">
                <Button
                    className="dialog-consult-button"
                    onClick={handleOnClick}
                >
                    Consult with Sales
                </Button>
            </footer>
        </>
    );
};
