import type { Dictionary } from '@placer-ui/types';
import { Preset } from 'ui-components/date-picker/date-types/Preset';

export const convertPresetToDateSource = (presets: Dictionary<Preset>) => {
    return Object.values(presets).map((preset) => {
        return {
            key: preset.label,
            label: preset.label,
        };
    });
};
