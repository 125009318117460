export const SEGMENTS = [
    {
        key: 'relative',
        label: 'Relative',
        isPanel: true,
    },
    {
        key: 'fixed',
        label: 'Fixed Dates',
        isPanel: true,
    },
    {
        key: 'custom',
        label: 'Custom Range',
        isPanel: true,
    },
] as const;
