import { ERRORS } from 'API/common/constants';
import { EntityAccessLevel, EntityAccessLevelTypes } from 'core/constants/entity-access-level';
import { getRegionFullName } from 'core/services/regions-service';
import { getReportEntityLocationLabel } from 'core/services/report-entities-service/report-entities-service';
import { useWidgetContext } from 'extensions/widget/context/WidgetContext';
import {
    Widget,
    WidgetApiContent,
    WidgetApisContentMap,
    WidgetApisInfoMap,
} from 'extensions/widget/store/widget-constants';
import { usePrintContext } from 'features/print-menu/hooks/use-print-context';
import { capitalize } from 'lodash/fp';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/app-store-hooks';
import type {
    Area,
    Dictionary,
    Filter,
    Geolocation,
    PlaceAddress,
    PlacerEntityModel,
} from '@placer-ui/types';
import { NoUndefinedField } from 'types/typescript-utils';
import { isLessThanFullMonthsNumber, isLessThanFullWeeksNumber } from 'utils/date/date';
import { setDuplicateWidgetData, setWidgetError } from '../store/widget-actions';
import { selectWidgetById } from '../store/widget-selectors';
import { useReportDateRange, useReportPlaces } from './use-poi-hooks';
import { useSelectorWithWidgetId } from './use-selector-with-widget-id';
import { usePlatformConfiguration } from 'features/settings/store/selectors';
import { cloneDeep, uniqueId } from 'lodash';
import { AppStateWidgets } from 'extensions/widget/store/widget-reducer';
import { MapStateVisualFilter } from 'ui-components/google-map/types/types';
import { MAP_VISUAL_FILTER_KEYS_TO_CLEAN } from 'ui-components/google-map/consts';
import { useWidgetConfigContext } from 'extensions/widget/context/WidgetConfigContext';
import { FetchFilter, PrintConfig } from 'extensions/widget/models/widget-model';
import { DEFAULT_API_ID } from 'extensions/widget/models/widget-apis';
import { usePrintEntityTogglePermission } from 'features/print-menu/hooks/use-print-permissions';
import { useAllBlockingApisErrorsByPoiId } from 'extensions/widget/hooks/use-widget-error-hooks';
import { WidgetPoi } from 'extensions/widget/models/widget-poi-provider-type';
import { isNearbyActivity } from 'utils/poi-filter/poi-filter';
import { userPermissionsSelector } from 'store/selectors/app-selectors';
import { useFeatureFlags } from 'hooks/feature-flags/use-feature-flags';
import { getFeatureFlags } from 'core/flow-control';
import { useWidgetPrintMode } from './use-widget-context-hooks';

export const PRINT_WIDGET_DELIMITER = '_';

/** ---------------------------------------
 * Widget, settings and configurations hooks
 * --------------------------------------- */
export const useWidgetModel = useWidgetContext;

export const useWidgetUniqueId = () => {
    const { uniqueId } = useWidgetModel();

    return uniqueId;
};

export const useWidgetType = () => {
    const { type } = useWidgetModel();

    return type;
};

export const useWidget = <
    ApisContentMap extends WidgetApisContentMap,
    MetaDataType = unknown,
>() => {
    const id = useWidgetUniqueId();

    return useSelectorWithWidgetId<Widget<ApisContentMap, MetaDataType>>({ id }, selectWidgetById);
};

export const useWidgetMetaData = <
    ApisContentMap extends WidgetApisContentMap,
    MetaDataType = unknown,
>() => {
    const widget = useWidget<ApisContentMap>();

    if (!widget.metadata) return;
    return widget.metadata as MetaDataType;
};

export const useGetWidgetState = () => {
    const widgetState = useSelector((state: AppStateWidgets) => state.widgets);
    return useMemo(() => widgetState, [widgetState]);
};

export const useGetWidgetStateById = () => {
    const widgetState = useSelector((state: AppStateWidgets) => state.widgets);
    return useCallback((id: string) => widgetState[id], [widgetState]);
};

export const useWidgetSettings = <CustomSettings = undefined>() => {
    const { settings } = useWidgetModel();

    type RawNonNullableSettings = NoUndefinedField<typeof settings>;
    type Settings = Omit<RawNonNullableSettings, 'customSettings'> & {
        customSettings: CustomSettings;
    };

    return settings as unknown as Settings;
};

export const useWidgetDefaultPOIsSize = () => {
    const { all } = useReportPlaces();
    const { defaultPOIsSize } = useWidgetModel();
    const { defaultPOIsSize: configContextDefaultPOIsSize } = useWidgetConfigContext();

    return configContextDefaultPOIsSize ?? (defaultPOIsSize || all.length);
};

export const useWidgetStatus = () => {
    const widget = useWidget();

    return widget.status;
};

export const useWidgetApis = <
    WidgetApiId extends string = string,
    FetchFilterType = FetchFilter,
>() => {
    const { APIs } = useWidgetConfigContext<WidgetApiId, FetchFilterType>();

    return APIs;
};

export const useWidgetApiIds = <WidgetApiId extends string = string>() => {
    const { APIs } = useWidgetConfigContext<WidgetApiId>();

    return useMemo(() => (APIs ? Object.keys(APIs) : [DEFAULT_API_ID]) as WidgetApiId[], [APIs]);
};

export const useWidgetBlockingApiIds = () => {
    const apis = useWidgetApis();

    return useMemo(() => {
        if (!apis) {
            return [DEFAULT_API_ID];
        }

        return Object.entries(apis)
            .filter(([apiId, apiConfig]) => apiId === DEFAULT_API_ID || apiConfig.isBlocking)
            .map(([apiId]) => apiId);
    }, [apis]);
};

export const useWidgetNonBlockingApiIds = () => {
    const apis = useWidgetApis();

    return useMemo(() => {
        if (!apis) {
            return [];
        }

        return Object.entries(apis)
            .filter(([apiId, apiConfig]) => !apiConfig.isBlocking)
            .map(([apiId]) => apiId);
    }, [apis]);
};

const useWidgetDefaultShowVenueLegend = () => {
    const { showVenueLegend } = useWidgetModel();
    return showVenueLegend;
};

const useWidgetSelectedShowVenueLegend = () => {
    const widget = useWidget();
    return widget.showVenueLegend;
};

export const useWidgetShowVenueLegend = () => {
    const defaultShowVenueLegend = useWidgetDefaultShowVenueLegend();
    const selectedShowVenueLegend = useWidgetSelectedShowVenueLegend();
    const platformSetting = usePlatformConfiguration();
    const userSettingDefaultLegend = !!platformSetting.platform_config_entity_legend;

    return selectedShowVenueLegend ?? userSettingDefaultLegend ?? defaultShowVenueLegend ?? false;
};

/** -------------
 * Print hooks
 * ------------- */

export const usePrintWidgetVenueSelector = () => {
    const id = useWidgetUniqueId();
    const isPrintMode = useWidgetPrintMode();
    const { widgets } = usePrintContext();
    const { print } = useWidgetModel();
    const useSourceWidgetVenueSelector = print?.useOriginalVenueSelector;
    const userPermissions = useSelector(userPermissionsSelector);
    const featureFlags = useFeatureFlags();

    const duplicatePerPoiWidget = print?.duplicatePerPOI;
    const shouldDuplicatePerPoiWidget = duplicatePerPoiWidget?.(userPermissions, featureFlags);
    const enableEntityToggle = usePrintEntityTogglePermission();

    return useMemo(() => {
        if (
            isPrintMode &&
            (shouldDuplicatePerPoiWidget || (!useSourceWidgetVenueSelector && !enableEntityToggle))
        ) {
            let generatedVenueSelectorPrint: Dictionary<boolean> = {};

            const selectedIds =
                widgets.find((widget) => widget.id === id)?.venues?.map(({ uid }) => uid) || [];
            selectedIds.forEach((id) => {
                generatedVenueSelectorPrint[id] = true;
            });
            return {
                selectedIds,
                generatedVenueSelectorPrint,
            };
        }
        return {};
    }, [
        shouldDuplicatePerPoiWidget,
        enableEntityToggle,
        id,
        isPrintMode,
        useSourceWidgetVenueSelector,
        widgets,
    ]);
};

export type DuplicateWidgetData = {
    widgetUniqueId: string;
    poiId?: string;
    print?: PrintConfig;
};

export const useDuplicateWidgetData = () => {
    const dispatch = useAppDispatch();
    const getWidgetById = useGetWidgetStateById();
    const { ids } = useReportPlaces();
    const userPermissions = useSelector(userPermissionsSelector);
    const featureFlags = useFeatureFlags();

    return useCallback(
        ({ widgetUniqueId, poiId, print }: DuplicateWidgetData) => {
            const copyWidgetUniqueId = uniqueId(`${widgetUniqueId}${PRINT_WIDGET_DELIMITER}`);

            // get widget obj if already exists on Redux store
            const widget = getWidgetById(widgetUniqueId);

            if (widget) {
                if (poiId) {
                    const uniquePoiId = uniqueId(
                        `${widgetUniqueId}${PRINT_WIDGET_DELIMITER}${poiId}${PRINT_WIDGET_DELIMITER}`,
                    );
                    const poiWidget = cloneDeep(widget);

                    if (widget.content) {
                        // 'filter' widget content to contain only the relevant poi
                        Object.entries(poiWidget.content).forEach(([apiId, content]) => {
                            const contentDict = content as Dictionary<unknown>;
                            const contentPois = Object.keys(contentDict);
                            contentPois.forEach((contentPoi) => {
                                if (contentPoi !== poiId) {
                                    delete contentDict[contentPoi];
                                }
                            });

                            if (Object.keys(poiWidget.content[apiId]).length === 0) {
                                delete poiWidget.content[apiId];
                            }
                        });
                    }

                    const duplicatePerPoiWidget = print?.duplicatePerPOI;
                    const shouldDuplicatePerPoiWidget = duplicatePerPoiWidget?.(
                        userPermissions,
                        featureFlags,
                    );

                    // clear irrelevant visual filters
                    const selectedPoiUid =
                        Object.keys(poiWidget.venueSelector || {}).find(
                            (uid) => poiWidget.venueSelector[uid],
                        ) ?? ids[0];

                    if (selectedPoiUid !== poiId && poiWidget.visualFilter) {
                        MAP_VISUAL_FILTER_KEYS_TO_CLEAN.forEach(
                            (key) => delete poiWidget.visualFilter[key],
                        );
                    }

                    if (
                        shouldDuplicatePerPoiWidget &&
                        selectedPoiUid !== poiId &&
                        poiWidget &&
                        print?.fetchFilterToClean
                    ) {
                        print?.fetchFilterToClean.forEach(
                            (key) => delete poiWidget?.fetchFilter?.[key],
                        );
                    }

                    if (
                        shouldDuplicatePerPoiWidget &&
                        selectedPoiUid !== poiId &&
                        poiWidget &&
                        print?.visualFilterToClean
                    ) {
                        print?.visualFilterToClean.forEach(
                            (key) => delete poiWidget.visualFilter[key],
                        );
                    }

                    dispatch(setDuplicateWidgetData(uniquePoiId, poiWidget));
                    return uniquePoiId;
                }
                // if widget exist on store
                // -> duplicate the original data to store with new key
                dispatch(setDuplicateWidgetData(copyWidgetUniqueId, widget));
            }
            return copyWidgetUniqueId;
        },
        [dispatch, featureFlags, getWidgetById, ids, userPermissions],
    );
};

/** -------------
 * Content hooks
 * ------------- */
export const useWidgetEntireContent = <ApisContentMap extends WidgetApisContentMap>() => {
    const widget = useWidget<ApisContentMap>();

    if (!widget.content) return;
    return widget.content as ApisContentMap;
};

export const useWidgetEntireInfo = <ApisContentMap extends WidgetApisInfoMap>() => {
    const widget = useWidget<ApisContentMap>();

    if (!widget.info) return;
    return widget.info as ApisContentMap;
};

export const useWidgetApiContent = <ApiContentType = any>(apiId: string) => {
    const content = useWidgetEntireContent();

    if (!content?.[apiId]) return;
    return content?.[apiId] as WidgetApiContent<ApiContentType>;
};

export const useWidgetApiCombinedContent = <ApiContentType>(apiId: string) => {
    const content = useWidgetApiContent<ApiContentType>(apiId);

    return content?.common;
};

export interface ContentWithPOIs<ContentType> {
    id: string;
    poiID: string;
    color: string;
    name: string;
    fullAddress: string;
    shortAddress?: string;
    addressFields: PlaceAddress;
    filters: Filter;
    content: ContentType;
    area: Area;
    areaSqFt?: number;
    purchased: boolean;
    geolocation: Geolocation;
    store_id?: string;
}

export const useWidgetContentWithSelectedPOI = <ApiContentType>(apiId: string = DEFAULT_API_ID) => {
    const apiContent = useWidgetApiContent<ApiContentType>(apiId);
    const { originalEntity, filters, color, uid } = useWidgetSingleVenueSelector();

    return useMemo((): ContentWithPOIs<ApiContentType | null> => {
        const content = apiContent ? (apiContent[uid] as ApiContentType) : null;
        return {
            id: uid,
            poiID: originalEntity.id,
            area: originalEntity.area,
            areaSqFt: originalEntity.area_sqft,
            color: color,
            name: originalEntity.name,
            filters: filters,
            fullAddress: getFullAddress(originalEntity),
            shortAddress: getShortAddress(originalEntity),
            addressFields: originalEntity.address,
            content,
            purchased: originalEntity.purchased,
            geolocation: originalEntity.geolocation,
        };
    }, [apiContent, originalEntity, filters, color, uid]);
};

export const useWidgetContentWithEnabledPOIs = <ApiContentType>(apiId: string = DEFAULT_API_ID) => {
    const apiContent = useWidgetApiContent<ApiContentType>(apiId);
    const checkedEntities = useWidgetAllToggledEntities();

    return useMemo(
        () =>
            checkedEntities.map((poi): ContentWithPOIs<ApiContentType> => {
                const content = ((apiContent && apiContent[poi.uid]) || {}) as ApiContentType;
                const { originalEntity, uid, filters, color } = poi;

                return {
                    id: uid,
                    poiID: originalEntity.id,
                    store_id: originalEntity.store_id,
                    area: originalEntity.area,
                    areaSqFt: originalEntity.area_sqft,
                    color,
                    name: originalEntity.name,
                    filters,
                    fullAddress: getFullAddress(originalEntity),
                    shortAddress: getShortAddress(originalEntity),
                    addressFields: originalEntity.address,
                    content,
                    purchased: originalEntity.purchased,
                    geolocation: originalEntity.geolocation,
                };
            }),
        [apiContent, checkedEntities],
    );
};

/**
 * @deprecated use the {@link useWidgetEntireContent} or {@link useWidgetApiContent} hooks
 */
export const useWidgetContent = <ContentType>() => {
    return useWidgetApiContent<ContentType>(DEFAULT_API_ID);
};

/** -------------
 * Widget POIs
 * ------------- */
export const useWidgetVenueSelector = () => {
    const widget = useWidget();
    const { entities } = useReportPlaces();
    const minSize = useWidgetDefaultPOIsSize();
    const { selectedIds: printIds, generatedVenueSelectorPrint } = usePrintWidgetVenueSelector();

    const selectedIds = printIds || (widget.venueSelector ? Object.keys(widget.venueSelector) : []);

    // if selected venues are not valid, ignore them
    return selectedIds.length &&
        selectedIds.length >= minSize &&
        selectedIds.every((poiId) => entities[poiId])
        ? generatedVenueSelectorPrint || widget.venueSelector
        : undefined;
};

export const useWidgetPOIsList = () => {
    const { ids } = useReportPlaces();
    const venueSelector = useWidgetVenueSelector();
    const size = useWidgetDefaultPOIsSize();

    return useMemo(() => {
        if (venueSelector) {
            return Object.keys(venueSelector);
        }

        return ids.slice(0, size);
    }, [venueSelector, ids, size]);
};

export const useWidgetSingleVenueSelector = <EntityType = any>(): EntityType => {
    const venueSelector = useWidgetVenueSelector();
    const { entities, all } = useReportPlaces();

    if (!venueSelector) {
        return all[0];
    }

    const selectedPoiID = Object.keys(venueSelector).find((poiID) => {
        return venueSelector![poiID];
    });

    return selectedPoiID ? entities[selectedPoiID] : all[0];
};

export const useWidgetAllToggledEntities = () => {
    const { entities } = useReportPlaces();

    const venuesSelector = useWidgetVenueSelector();
    const orderedEntities = useWidgetPOIsList();

    return useMemo<PlacerEntityModel<any>[]>(() => {
        return orderedEntities
            .filter((poiID) => (venuesSelector ? venuesSelector[poiID] : true))
            .map((poiID) => entities[poiID]);
    }, [orderedEntities, venuesSelector, entities]);
};

export const useWidgetValidPOIsList = () => {
    const { allEntitiesWidgetShape } = useReportPlaces();
    const errorsByPoiId = useAllBlockingApisErrorsByPoiId();

    return useMemo(
        () =>
            allEntitiesWidgetShape.filter((entityWidgetShape) => {
                return !errorsByPoiId[entityWidgetShape.uid];
            }),
        [allEntitiesWidgetShape, errorsByPoiId],
    );
};

/** -------------
 * Filters hooks
 * ------------- */
const useWidgetSelectedVisualFilter = () => {
    const widget = useWidget();

    return widget.visualFilter;
};

const useWidgetSelectedFetchFilter = () => {
    const widget = useWidget();

    return widget.fetchFilter;
};

const useWidgetDefaultFetchFilters = () => {
    const { fetchFilter, fetchFilterInLimitedMode } = useWidgetModel();

    return useIsWidgetInLimitedMode() ? fetchFilterInLimitedMode || fetchFilter : fetchFilter;
};

export const useWidgetFetchFilters = <T = NoUndefinedField<typeof actualFastFilters>>() => {
    const modeDependentFetchFilter = useWidgetDefaultFetchFilters();

    const widgetFastFilter = useWidgetSelectedFetchFilter();
    const actualFastFilters = useMemo(
        () => ({
            ...modeDependentFetchFilter,
            ...widgetFastFilter,
        }),
        [modeDependentFetchFilter, widgetFastFilter],
    );

    return actualFastFilters as T;
};

export const useWidgetVisualFilters = <T = NoUndefinedField<typeof actualVisualFilters>>() => {
    const { visualFilter, visualFilterInLimitedMode } = useWidgetModel();

    const modeDependentVisualFilter = useIsWidgetInLimitedMode()
        ? visualFilterInLimitedMode || visualFilter
        : visualFilter;

    const widgetVisualFilter = useWidgetSelectedVisualFilter();
    const actualVisualFilters = useMemo(
        () => ({
            ...modeDependentVisualFilter,
            ...widgetVisualFilter,
        }),
        [modeDependentVisualFilter, widgetVisualFilter],
    );

    return actualVisualFilters as T;
};

export const useMapStateVisualFilters = () => {
    const { mapTypeId, mapZoom, mapFitBounds } = useWidgetVisualFilters<MapStateVisualFilter>();

    return {
        mapTypeId,
        mapZoom,
        mapFitBounds,
    };
};

/** -------------
 * Utilities hooks
 * ------------- */
export const useIsWidgetMinWeeksQuantityConstraintsSatisfied = () => {
    const reportDateRange = useReportDateRange();
    const { settings } = useWidgetModel();
    const minWeeksQuantity = settings?.widgetConstraints?.minWeeksReportDateRange;

    if (!reportDateRange || !minWeeksQuantity) {
        return true;
    }

    return !isLessThanFullWeeksNumber({
        startDate: reportDateRange.startDate,
        endDate: reportDateRange.endDate,
        number: minWeeksQuantity,
    });
};
export const useIsWidgetMinMonthsSatisfied = () => {
    const reportDateRange = useReportDateRange();
    const { settings } = useWidgetModel();
    const minMonths = settings?.widgetConstraints?.minMonthsReportDateRange;

    if (!reportDateRange || !minMonths) {
        return true;
    }

    return !isLessThanFullMonthsNumber({
        startDate: reportDateRange.startDate,
        endDate: reportDateRange.endDate,
        number: minMonths,
    });
};

export const useValidateWidgetConstrainsEffect = () => {
    const dispatch = useAppDispatch();

    const { type: widgetId } = useWidgetModel();
    const [selectedChainUID] = useWidgetPOIsList();
    const { allEntitiesWidgetShape } = useReportPlaces();
    const isWidgetMinWeeksQuantityConstraintsSatisfied =
        useIsWidgetMinWeeksQuantityConstraintsSatisfied();
    const isWidgetMinMonthsSatisfied = useIsWidgetMinMonthsSatisfied();
    const blockingApis = useWidgetBlockingApiIds();

    useEffect(() => {
        if (isWidgetMinWeeksQuantityConstraintsSatisfied && isWidgetMinMonthsSatisfied) {
            return;
        }

        allEntitiesWidgetShape.forEach(({ uid }) => {
            blockingApis.forEach((apiId) => {
                dispatch(
                    setWidgetError(
                        widgetId,
                        uid,
                        {
                            type: ERRORS.UNSUPPORTED_DATE_RANGE,
                        },
                        apiId,
                    ),
                );
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        allEntitiesWidgetShape,
        dispatch,
        isWidgetMinWeeksQuantityConstraintsSatisfied,
        widgetId,
        selectedChainUID,
        isWidgetMinMonthsSatisfied,
    ]);
};

export const useIsSelectedVenuesLimited = () => {
    const pois = useWidgetContentWithEnabledPOIs();
    return pois.some((poi) => !poi.purchased);
};

export const useWidgetUiMode = (): EntityAccessLevelTypes => {
    const { getWidgetUiMode } = useWidgetModel();
    const checkedEntities = useWidgetAllToggledEntities();

    return useMemo(() => {
        return getWidgetUiMode ? getWidgetUiMode(checkedEntities) : EntityAccessLevel.FULL;
    }, [checkedEntities, getWidgetUiMode]);
};

export const useIsWidgetInLimitedMode = (): boolean => {
    const widgetUiMode = useWidgetUiMode();

    return useMemo(() => {
        return widgetUiMode === EntityAccessLevel.LIMITED;
    }, [widgetUiMode]);
};

// TODO: remove these formatters and align to main address formatter
//  https://placer.atlassian.net/browse/PLAC-24114
export const getFullAddress = (entity: any) => {
    if (entity.area) {
        return getRegionFullName(entity.area) || capitalize(entity.area.type);
    }

    return getReportEntityLocationLabel(entity);
};

const getShortAddress = (entity: any) => {
    const { address } = entity;

    return address?.short_formatted_address || address?.formatted_address;
};

export const usePrintWidgetById = (widgetId?: string) => {
    const { widgets } = usePrintContext();
    return useMemo(() => widgets.find(({ id }) => id === widgetId), [widgetId, widgets]);
};

export const usePrintSelectedWidget = () => {
    const { selectedCustomizeWidgetId } = usePrintContext();
    return usePrintWidgetById(selectedCustomizeWidgetId);
};

export const useDisableNearbyActivityPoiSelector = () => {
    return useCallback((poi: WidgetPoi) => {
        if (isNearbyActivity(poi)) {
            return {
                disabled: true,
                tooltip: 'This property is not supported',
            };
        }

        return {
            disabled: false,
        };
    }, []);
};

export const useIsDisabledPOIByNewCustomPOI = () => {
    const { entities } = useReportPlaces();
    const { enable_short_visit_cpoi_property_ff } = getFeatureFlags();

    return useCallback(
        ({ uid }: WidgetPoi) => {
            const entityByUID = entities[uid]?.originalEntity;
            if (
                enable_short_visit_cpoi_property_ff &&
                entityByUID?.is_new_poi &&
                entityByUID?.is_custom_poi
            ) {
                return {
                    disabled: true,
                };
            }

            return {
                disabled: false,
            };
        },
        [enable_short_visit_cpoi_property_ff, entities],
    );
};

export const useIsWidgetMultipleApiContentLoading = (apisArr?: string[]) => {
    const widgetEntireContent = useWidgetEntireContent();
    const widgetPOIsList = useWidgetPOIsList();
    return useMemo(() => {
        if (!apisArr) return true;
        return !apisArr.every((apiId) => {
            const apiContent = widgetEntireContent?.[apiId];
            return widgetPOIsList.some(
                (poiId) => apiContent?.[poiId] !== undefined && apiContent?.[poiId] !== null,
            );
        });
    }, [apisArr, widgetEntireContent, widgetPOIsList]);
};
