import { useSelector } from 'react-redux';
import { allFeatureFlagsSelector } from 'store/selectors/feature-flags';
import { FeatureFlags } from 'core/entities/user/user-feature-flags';

/**
 * @deprecated use `getFeatureFlags` or `hasFeatureFlags` flow-control methods
 */
export const useFeatureFlags = (): FeatureFlags => {
    return useSelector(allFeatureFlagsSelector) as FeatureFlags;
};
