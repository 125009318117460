import { ChainReportEntity } from 'core/entities/user/user-settings';
import { generateChainsLinkParams } from 'router/go-to-routes';
import pluralize from 'pluralize';
import { TableEntity } from 'ui-components/table/data-table/models';
import { useSelectorWithProps } from 'utils/structured-selector/structured-selector';
import { selectHasUserPermission } from 'store/selectors/app-selectors';
import { OVERVIEW_REPORT_NAME, CUSTOM_REPORT_NAME } from 'utils/navigation/navigation-consts';
import { useWidgetPrintMode } from 'extensions/widget/hooks/use-widget-context-hooks';
import type { Competitor, Filter, PropertyReportEntity } from '@placer-ui/types';
import { useCallback } from 'react';

type generatePropertyLinkArgs = {
    id: PropertyReportEntity['id'];
    collection: PropertyReportEntity['collection'];
    competitor?: PropertyReportEntity['competitor'];
    filter?: PropertyReportEntity['filter'];
    section?: string;
};
export const generatePropertyLink = ({
    id,
    collection,
    competitor,
    filter,
    section = 'overview',
}: generatePropertyLinkArgs) => {
    const path = `/insights/${collection}/${id}/${section}`;
    const search = getSearchParams(competitor, filter);

    if (search) {
        return {
            pathname: path,
            search,
        };
    }

    return { pathname: path };
};

export const generateChainLink = (reportEntities: ChainReportEntity[], section = 'overview') => {
    const entities = reportEntities.map(
        ({ id, date_start, date_end, date_label, notInReport }) => ({
            id,
            date_start,
            date_end,
            date_label,
            notInReport,
        }),
    );

    return {
        pathname: `/${section}`,
        search: `?state=${encodeURI(JSON.stringify(entities))}`,
    };
};

export const useGetGenerateLinkToEntity = () => {
    const hasEnableCustomPropertyOverview = useSelectorWithProps(
        { permission: 'enable_custom_property_overview' },
        selectHasUserPermission,
    );
    const isPrintMode = useWidgetPrintMode();

    const getGenerateLinkToEntity = useCallback(
        (entity: TableEntity) =>
            ['chain', 'tag'].includes(entity.type)
                ? generateChainsLinkParams({ entities: [entity] })
                : generatePropertyLink({
                      id: entity.id,
                      collection: pluralize(entity.type),
                      section: hasEnableCustomPropertyOverview
                          ? CUSTOM_REPORT_NAME
                          : OVERVIEW_REPORT_NAME,
                  }),
        [hasEnableCustomPropertyOverview],
    );

    if (isPrintMode) {
        return () => ({
            pathname: '',
        });
    } else {
        return getGenerateLinkToEntity;
    }
};

function getSearchParams(competitor?: Competitor[], filter?: Filter[]) {
    let search = '';

    if (competitor) {
        search += `competitor=${encodeURIComponent(JSON.stringify(competitor))}`;
    }
    if (filter) {
        search += `&filter=${encodeURIComponent(JSON.stringify(filter))}`;
    }

    return search ? `?${search}` : null;
}
