import React from 'react';
import styles from './privacy-disclaimer.module.scss';
import classnames from 'classnames';
import { Alert } from 'ui-components/alert/alert';

interface PrivacyDisclaimerProps {
    className?: string;
    linkClassName?: string;
}

const PRIVACY_DISCLAIMER_URL = 'https://www.placer.ai/privacy-policy';
export const PrivacyDisclaimer = ({ className, linkClassName }: PrivacyDisclaimerProps) => {
    return (
        <Alert type={'info'} className={classnames(styles.privacyDisclaimer, className)}>
            Subject to Placer’s
            <a
                href={PRIVACY_DISCLAIMER_URL}
                className={classnames(styles.privacyDisclaimerLink, linkClassName)}
                target="_blank"
                rel="noreferrer"
            >
                privacy policy
            </a>
        </Alert>
    );
};
