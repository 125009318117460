import { MarketplaceState } from 'features/marketplace/store/slice';
import { CreateSliceOptions } from '@reduxjs/toolkit';

import type { Dictionary } from '@placer-ui/types';
import { PluginEntity, PluginSnippetEntity } from 'features/marketplace/common/api/entities';
import { Action } from 'redux';

interface MarketplaceAction<T> extends Action {
    payload: T;
}

export const reducers: CreateSliceOptions['reducers'] = {
    setPluginList: (
        state: MarketplaceState,
        action: MarketplaceAction<Dictionary<PluginSnippetEntity>>,
    ) => {
        state.snippets = action.payload;
    },
    setPluginDetails: (
        state: MarketplaceState,
        action: MarketplaceAction<{ pluginId: string; details: PluginEntity }>,
    ) => {
        const { pluginId, details } = action.payload;
        state.plugins[pluginId] = details;
    },
    markPluginAsRequested: (state: MarketplaceState, action: MarketplaceAction<string>) => {
        state.requestedPluginIds = [...state.requestedPluginIds, action.payload];
    },
    setMarketplaceError: (state: MarketplaceState, action: MarketplaceAction<boolean>) => {
        state.error = action.payload;
    },
};
