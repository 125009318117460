import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { identity } from 'lodash/fp';
import { Popover } from 'antd';
import styles from './user-section.module.scss';
import userIconUrl from 'shared/images/user-icon.svg';
import classnames from 'classnames';
import { userSelector } from 'store/selectors/app-selectors';
import { UserSettingsMenu } from './user-settings-menu/user-setting-menu';
import { useFeatureFlags } from 'hooks/feature-flags/use-feature-flags';
import { UserLoginAsModal } from 'features/header/header-top-panel/user-section/user-loginas-dialog/user-loginas-dialog';
import { UserExitLoginAsModal } from 'features/header/header-top-panel/user-section/user-loginas-dialog/user-exit-loginas-dialog';

export const UserSection = () => {
    const user = useSelector(userSelector);
    const [isPopoverVisible, setIsPopoverVisible] = useState<boolean>(false);
    const { enable_privacy_link_in_account_window_ff } = useFeatureFlags();
    const [impersonateModalOpen, setImpersonateModalOpen] = useState(false);
    const [exitImpersonateModeModalOpen, setExitImpersonateModeModalOpen] = useState(false);
    const togglePopover = () => setIsPopoverVisible(!isPopoverVisible);
    const onClose = () => {
        setImpersonateModalOpen(false);
    };
    return (
        <>
            <div
                className={classnames(styles.userSectionWrapper, {
                    [styles.noArrow]: enable_privacy_link_in_account_window_ff,
                })}
                onClick={togglePopover}
                data-testid="user-section-button"
            >
                <Popover
                    content={
                        <UserSettingsMenu
                            setImpersonateModalOpen={setImpersonateModalOpen}
                            setExitImpersonateModeModalOpen={setExitImpersonateModeModalOpen}
                        />
                    }
                    trigger="click"
                    placement="bottomRight"
                    visible={isPopoverVisible}
                    onVisibleChange={togglePopover}
                    overlayClassName={classnames(styles.userSectionPopover, {
                        [styles.noArrow]: enable_privacy_link_in_account_window_ff,
                    })}
                    getPopupContainer={identity}
                    align={{
                        offset: [18, 3],
                    }}
                >
                    <div className={styles.userSectionContent}>
                        <img src={userIconUrl} className={styles.userIcon} alt="user icon" />
                        <div className={styles.userName}>{user?.username}</div>
                    </div>
                </Popover>
            </div>
            {impersonateModalOpen && (
                <UserLoginAsModal visible={impersonateModalOpen} onClose={onClose} />
            )}
            {exitImpersonateModeModalOpen && (
                <UserExitLoginAsModal visible={exitImpersonateModeModalOpen} />
            )}
        </>
    );
};
