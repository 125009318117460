/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

type CreatePoiIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type CreatePoiIconProps = SVGProps<SVGSVGElement> & CreatePoiIconUniqueProps;

export const CreatePoiIcon = function CreatePoiIcon({
    width = 18,
    height = 18,
    ...props
}: CreatePoiIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M3.15018 6.3C4.88988 6.3 6.30018 4.8897 6.30018 3.15C6.30018 2.9972 6.2893 2.84694 6.26828 2.69995H11.7321C11.7111 2.84694 11.7002 2.9972 11.7002 3.15C11.7002 4.8897 13.1105 6.3 14.8502 6.3C15.003 6.3 15.1532 6.28912 15.3002 6.2681V11.7319C15.1532 11.7108 15.003 11.7 14.8502 11.7C13.1105 11.7 11.7002 13.1103 11.7002 14.85C11.7002 15.0027 11.7111 15.153 11.7321 15.3H6.26828C6.28931 15.153 6.30018 15.0027 6.30018 14.85C6.30018 13.1103 4.88988 11.7 3.15018 11.7C2.9974 11.7 2.84716 11.7108 2.7002 11.7318V6.2681C2.84716 6.28912 2.9974 6.3 3.15018 6.3Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.15 5.55C4.47548 5.55 5.55 4.47548 5.55 3.15C5.55 1.82452 4.47548 0.75 3.15 0.75C1.82452 0.75 0.75 1.82452 0.75 3.15C0.75 4.47548 1.82452 5.55 3.15 5.55ZM3.15 6.3C4.8897 6.3 6.3 4.8897 6.3 3.15C6.3 1.4103 4.8897 0 3.15 0C1.4103 0 0 1.4103 0 3.15C0 4.8897 1.4103 6.3 3.15 6.3Z"
                fill="#49565D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.55 6.2859C13.0498 6.14416 11.8558 4.95021 11.7141 3.44995H6.2859C6.29523 3.35122 6.3 3.25117 6.3 3.15C6.3 2.9972 6.28912 2.84694 6.26809 2.69995H11.7319C11.9503 1.17344 13.2631 0 14.85 0C16.5897 0 18 1.4103 18 3.15C18 4.73691 16.8265 6.04975 15.3 6.2681V11.7319C16.8265 11.9502 18 13.2631 18 14.85C18 16.5897 16.5897 18 14.85 18C13.2631 18 11.9503 16.8265 11.7319 15.3H6.2681C6.28912 15.153 6.3 15.0027 6.3 14.85C6.3 14.7488 6.29523 14.6487 6.2859 14.55H11.7141C11.8559 13.0497 13.0498 11.8558 14.55 11.7141V6.2859ZM17.25 3.15C17.25 4.47548 16.1755 5.55 14.85 5.55C13.5245 5.55 12.45 4.47548 12.45 3.15C12.45 1.82452 13.5245 0.75 14.85 0.75C16.1755 0.75 17.25 1.82452 17.25 3.15ZM17.25 14.85C17.25 16.1755 16.1755 17.25 14.85 17.25C13.5245 17.25 12.45 16.1755 12.45 14.85C12.45 13.5245 13.5245 12.45 14.85 12.45C16.1755 12.45 17.25 13.5245 17.25 14.85Z"
                fill="#49565D"
            />
            <path
                d="M3.45001 11.7141V6.2859C3.35126 6.29523 3.25119 6.3 3.15 6.3C2.99722 6.3 2.84698 6.28912 2.70001 6.2681V11.7318C2.84698 11.7108 2.99722 11.7 3.15 11.7C3.25119 11.7 3.35126 11.7047 3.45001 11.7141Z"
                fill="#49565D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.15 17.25C4.47548 17.25 5.55 16.1755 5.55 14.85C5.55 13.5245 4.47548 12.45 3.15 12.45C1.82452 12.45 0.75 13.5245 0.75 14.85C0.75 16.1755 1.82452 17.25 3.15 17.25ZM3.15 18C4.8897 18 6.3 16.5896 6.3 14.85C6.3 13.1103 4.8897 11.7 3.15 11.7C1.4103 11.7 0 13.1103 0 14.85C0 16.5897 1.4103 18 3.15 18Z"
                fill="#49565D"
            />
        </svg>
    );
};
CreatePoiIcon.__tags = [] as string[];
