/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';
import { IconProps } from 'components/assets/Icons/Icons';

export const BanksAndFinancialServicesIconSvg = (
    props: CustomIconComponentProps | SVGProps<SVGSVGElement>,
) => (
    <svg {...props} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0ZM6.10398 2.00018L5.79701 2C5.57285 2.00967 5.53082 2.04835 5.52615 2.28524V2.60432C5.52148 2.91857 5.52148 2.91373 5.23194 3.02493C4.53144 3.286 4.09713 3.78396 4.05043 4.57682C4.0084 5.27784 4.36332 5.75646 4.91905 6.09971C5.26463 6.31243 5.6429 6.43813 6.00716 6.60251C6.14726 6.66536 6.28269 6.74271 6.40411 6.84424C6.75436 7.14398 6.68898 7.64194 6.27335 7.83049C6.05386 7.93201 5.81569 7.95619 5.57752 7.92718C5.20392 7.87883 4.849 7.77731 4.51276 7.59843C4.31662 7.49207 4.25591 7.51624 4.19053 7.73863C4.13449 7.93201 4.08312 8.12056 4.03175 8.31394C3.96637 8.57018 3.98972 8.63303 4.21855 8.74422C4.5221 8.89893 4.84433 8.97628 5.1759 9.02946C5.43742 9.07297 5.44209 9.08748 5.44676 9.36305C5.45143 9.48874 5.45143 9.61444 5.45143 9.74014C5.45143 9.89968 5.52615 9.99154 5.68493 9.99637C5.86239 10.0012 6.04452 10.0012 6.22198 9.99637C6.37142 9.99154 6.44614 9.90935 6.44614 9.75465C6.44614 9.5806 6.45548 9.41139 6.44614 9.23735C6.4368 9.0633 6.51152 8.97628 6.67497 8.92793C7.05324 8.82157 7.3708 8.61369 7.61831 8.30428C8.30013 7.44856 8.03861 6.19157 7.07192 5.63559C6.7637 5.46155 6.44147 5.32618 6.12391 5.19081C5.93711 5.11346 5.75965 5.0216 5.60554 4.89107C5.29732 4.63484 5.35803 4.2239 5.71762 4.05953C5.82036 4.01118 5.9231 3.99668 6.03518 3.99184C6.45548 3.96767 6.85243 4.04986 7.2307 4.2384C7.4175 4.33026 7.47821 4.30125 7.54359 4.0982C7.61364 3.88548 7.66968 3.66793 7.73039 3.45037C7.76775 3.30533 7.72105 3.20864 7.58562 3.14579C7.34278 3.0346 7.09527 2.95724 6.83375 2.91373C6.49284 2.86055 6.49284 2.85572 6.49284 2.50279C6.49284 2 6.49284 2 6.00716 2L6.10398 2.00018Z"
            fill="currentColor"
        />
    </svg>
);

export const BanksAndFinancialServicesIcon = (props: IconProps) => (
    <Icon component={BanksAndFinancialServicesIconSvg} {...props} />
);
