import { USCenter } from 'core/constants/coordinates';
import { BILLBOARD, PROPERTY, ReportTypes } from 'core/constants/report-type';
import {
    focusSearchAutoComplete,
    getFilteredChainResults,
    setChainsSearchAutoCompleteValue,
    setChainsSearchSearchTerm,
} from 'features/chains/store/chains-report-search-store';
import { goToChainsPage } from 'router/go-to-routes';
import { goToExplore } from 'router/go-to-routes/go-to-explore';
import { setCustomUserSettingsExplore } from 'store/explore/actions/set-custom-user-settings-explore';
import {
    setTopSearchLoading,
    setTopSearchTerm,
} from 'store/header/search/actions/top-search-action-creators';
import { selectTopSearchTerm } from 'store/header/search/selectors/top-search-selectors-factory';
import { selectChainDefaultReport } from 'store/selectors/app-selectors';
import { AppDispatch, AppState } from 'store/store';
import { getExploreFilterType } from 'utils/get-explore-filter-type/get-explore-filter-type';
import { ExploreConfiguration } from 'features/explore/types/types';
import { UserCustomSettingsExplore } from 'core/entities/user/user-custom-settings-explore';

export const topSearchOpenReportByEnterClick =
    ({
        reportType,
        value,
        goToExploreNewPage,
    }: {
        value: string;
        reportType: ReportTypes;
        goToExploreNewPage?: (
            exploreConfiguration: Partial<ExploreConfiguration & UserCustomSettingsExplore>,
        ) => void;
    }) =>
    (dispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();
        dispatch(setTopSearchTerm(''));
        dispatch(setTopSearchLoading(false));

        if (reportType === PROPERTY || reportType === BILLBOARD) {
            dispatch(setCustomUserSettingsExplore({ query: value }));
            const type = getExploreFilterType(reportType);

            if (goToExploreNewPage) {
                goToExploreNewPage({
                    reportType: type,
                    term: value,
                    center: USCenter,
                    zoom: 4,
                });
            } else {
                goToExplore({
                    query: value,
                    input: value,
                    center: USCenter,
                    zoom: 5,
                    type,
                });
            }
            return;
        }

        const searchTerm = selectTopSearchTerm(state);

        const defaultChains = selectChainDefaultReport(state);
        const primaryChain = defaultChains && defaultChains[0];
        const entities = primaryChain ? [{ id: primaryChain.id }] : [];
        goToChainsPage({ entities: entities });

        dispatch(setChainsSearchAutoCompleteValue(searchTerm));
        dispatch(setChainsSearchSearchTerm(searchTerm));
        dispatch(focusSearchAutoComplete());
        dispatch(getFilteredChainResults({ chainsEntityModels: [] }));
    };
