import { FilterInsights } from 'core/entities/filter';
import { isEqual } from 'lodash/fp';
import { fieldGetters } from 'features/insights/utils/report-options-reducer/report-options-getters';
import { cloneDeep } from 'lodash';
import type { Venue } from '@placer-ui/types';

export type ReduceReportOptionsProps<T extends object> = {
    entity: Venue;
    options: T;
    entityFilters?: FilterInsights;
    fieldsTransformationFunctions?: GetFieldsType<T>[];
};

export type GetFieldsType<T extends object> = (
    props: ReduceReportOptionsProps<T>,
) => Partial<Venue>;

export const getAggregatedOptionsVenue = <T extends object>({
    entity,
    options,
    entityFilters,
    fieldsTransformationFunctions = fieldGetters,
}: ReduceReportOptionsProps<T>) => {
    // get all new fields that have a value
    const newFields = fieldsTransformationFunctions.reduce((acc, getFields) => {
        const newFields = getFields({
            entity,
            options,
            entityFilters,
        });

        return {
            ...acc,
            ...Object.entries(newFields).reduce(
                (acc, [key, value]) =>
                    value !== undefined
                        ? {
                              ...acc,
                              [key]: value,
                          }
                        : acc,
                {},
            ),
        };
    }, {} as Partial<Venue>);

    // if no new fields, return the original entity
    if (Object.keys(newFields).length === 0) {
        return entity;
    }

    // get all diffs of the entity from the new fields
    const oldFieldsDiff = Object.entries(newFields).reduce((acc, [key, value]) => {
        if (!isEqual(entity[key as keyof Venue], value)) {
            return {
                ...acc,
                [key]: entity[key as keyof Venue],
            };
        }
        return acc;
    }, {} as Partial<Venue>);

    const draftEntity = Object.assign(cloneDeep(entity), newFields);
    draftEntity.overriddenFields = oldFieldsDiff;
    return draftEntity;
};

// revert the changes made by the getAggregatedOptionsVenue function
export const revertAggregatedOptionsVenue = (entity: Venue) => {
    if (entity.overriddenFields) {
        const draftEntity = Object.assign(cloneDeep(entity), entity.overriddenFields);
        draftEntity.overriddenFields = undefined;
        return draftEntity;
    }
    return entity;
};
