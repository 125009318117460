import React from 'react';
import classnames from 'classnames';
import styles from 'ui-components/google-map/components/custom-info-window/custom-info-window.module.scss';

import { CustomInfoWindowProps } from 'ui-components/google-map/components/custom-info-window/custom-info-window';

export const CustomInfoWindowAnchor = ({
    anchorClassName,
    backgroundColor,
}: CustomInfoWindowProps) => {
    return (
        <div
            className={classnames(styles.anchor, anchorClassName)}
            style={backgroundColor ? { backgroundColor } : {}}
        />
    );
};
