import { ReactNode, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { setElevioLauncherVisible } from 'utils/elevio/elevio';
import { useWidgetPrintMode } from 'extensions/widget/hooks/use-widget-context-hooks';
import { getAssistBarContainer } from 'features/common/components/placer-assist/utils/dom-interactions';
import { signal } from '@preact/signals-react';

interface ContentSidebarProps {
    width?: number;
    getContainer?: () => HTMLElement | null;
    children: ReactNode;
}

const setAssistSidebarWidth = (width: number) => {
    const container = document.documentElement;
    if (container) {
        container.style.setProperty('--assist-sidebar-width', `${width}px`);
    }
};

export const isAssistBarOpenSignal = signal(false);

export const AssistSidebar = ({
    children,
    width = 380,
    getContainer = getAssistBarContainer,
}: ContentSidebarProps) => {
    const container = getContainer();
    const isPrintMode = useWidgetPrintMode();
    const containerScroll = useRef<number | undefined>();
    useEffect(() => {
        if (!isPrintMode) {
            containerScroll.current = window.document.querySelector('html')!?.scrollTop;
            setElevioLauncherVisible(false);
            isAssistBarOpenSignal.value = true;
        }

        return () => {
            setElevioLauncherVisible(true);
            isAssistBarOpenSignal.value = false;
        };
    }, [isPrintMode]);

    useEffect(() => {
        if (isAssistBarOpenSignal.value && containerScroll.current) {
            const adminPanel = window.document.querySelector('.assist-is-open') as HTMLDivElement;
            // get current y scroll position of the html element and set it to the admin panel
            if (adminPanel) {
                adminPanel.scrollTo(0, containerScroll.current);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAssistBarOpenSignal.value]);

    useEffect(() => {
        !isPrintMode && setAssistSidebarWidth(width);
        return () => setAssistSidebarWidth(0);
    }, [width, isPrintMode]);

    return container && !isPrintMode ? createPortal(children, container) : null;
};
