/* eslint-disable max-len */
import React from 'react';

export function WeatherSunnyIcon() {
    return (
        <svg width="70" height="54" viewBox="0 0 70 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M9 27C9 41.3594 20.6406 53 35 53C49.3594 53 61 41.3594 61 27C61 12.6406 49.3594 1 35 1C20.6406 1 9 12.6406 9 27ZM8 27C8 41.9117 20.0883 54 35 54C49.9117 54 62 41.9117 62 27C62 12.0883 49.9117 0 35 0C20.0883 0 8 12.0883 8 27Z" fill="#FFBE5E" fillOpacity="0.4"/>
<path d="M14 27C14 38.598 23.402 48 35 48C46.598 48 56 38.598 56 27C56 15.402 46.598 6 35 6C23.402 6 14 15.402 14 27Z" fill="#FFD89E"/>
<path fillRule="evenodd" clipRule="evenodd" d="M35 51C21.7452 51 11 40.2548 11 27C11 13.7452 21.7452 3 35 3C48.2548 3 59 13.7452 59 27C59 40.2548 48.2548 51 35 51ZM35 50C22.2975 50 12 39.7025 12 27C12 14.2975 22.2975 4 35 4C47.7025 4 58 14.2975 58 27C58 39.7025 47.7025 50 35 50Z" fill="#FFD89E"/>
</svg>

    );
}
WeatherSunnyIcon.__tags = [] as string[];
