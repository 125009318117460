import { useGoogleMap } from '@react-google-maps/api';
import { useMap } from 'react-map-gl';
import { useCallback, useEffect, useState } from 'react';
import { useMapContext } from 'ui-components/google-map-with-context/hooks/google-map-with-context-hooks';

export const useMapTypeId = () => {
    const mapInstance = useGoogleMap();
    const [mapTypeId, setMapTypeId] = useState('');

    const setMapProperty = useCallback(() => {
        if (mapInstance) {
            setMapTypeId(mapInstance.getMapTypeId());
        }
    }, [mapInstance]);

    useEffect(() => {
        mapInstance && mapInstance.addListener('tilesloaded', setMapProperty);
        mapInstance && mapInstance.addListener('idle', setMapProperty);

        return () => {
            mapInstance && mapInstance.unbind('tilesloaded');
            mapInstance && mapInstance.unbind('idle');
        };
    }, [mapInstance, setMapProperty]);

    return mapTypeId;
};

export const useMapboxMapTypeId = () => {
    const mapInstance = useMap();
    if (mapInstance.current?.isStyleLoaded()) {
        switch (mapInstance.current.getStyle().name) {
            case 'Streets':
                return 'map';
            case 'Satellite Streets':
                return 'hybrid';
            default:
                return 'map';
        }
    }
    return 'map';
};

export const useGoogleMapContextTypeId = () => {
    const mapInstance = useMapContext();
    return mapInstance?.getMapTypeId();
};

export const useCommonMapTypeId = (mapboxBeta: boolean) => {
    return mapboxBeta ? useMapboxMapTypeId : useGoogleMapContextTypeId;
};
