/* eslint-disable max-len */
import React from 'react';

export function WeatherFogIcon() {
    return (
        <svg width="70" height="54" viewBox="0 0 70 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M60.3979 37L6.00359 37C3.5249 34.348 2.00934 30.7981 2 26.8931C2.00071 26.5932 2.01023 26.2955 2.0283 26H20.5667C21.3951 26 22.0667 25.3284 22.0667 24.5C22.0667 23.6716 21.3951 23 20.5667 23H2.52014C3.84905 18.094 7.60589 14.1909 12.4161 12.6654C13.839 12.2162 15.3544 11.9739 16.927 11.9739C17.8352 11.9739 18.7228 12.0546 19.5835 12.2086C23.5448 12.9235 26.9639 15.2059 29.1785 18.3958C29.4935 18.8494 30.1166 18.9619 30.5703 18.6469C31.0239 18.3319 31.1364 17.7088 30.8214 17.2551C28.7151 14.2214 25.6429 11.9082 22.0502 10.7643C25.4995 5.48523 31.468 2 38.253 2C44.0973 2 49.3641 4.75024 52.9173 9H47C46.1715 9 45.5 9.67157 45.5 10.5C45.5 11.3284 46.1715 12 47 12H54.5C54.659 12 54.8123 11.9752 54.9561 11.9294C55.6874 13.2079 56.2819 14.5725 56.7186 16H42C41.1715 16 40.5 16.6716 40.5 17.5C40.5 18.3284 41.1715 19 42 19H57.3849C57.4325 19.3377 57.4714 19.6777 57.5014 20.0199L57.6612 21.8455H60.3979C64.6012 21.8455 67.9999 25.2428 67.9999 29.423C67.9999 33.6026 64.6013 37 60.3979 37ZM8.49996 31.5C8.49996 30.6716 9.17153 30 9.99996 30H36.0667C36.8951 30 37.5667 30.6716 37.5667 31.5C37.5667 32.3284 36.8951 33 36.0667 33H9.99996C9.17153 33 8.49996 32.3284 8.49996 31.5Z" fill="#D0D0D0"/>
<path d="M41.0665 44C41.8949 44 42.5665 43.3284 42.5665 42.5C42.5665 41.6716 41.8949 41 41.0665 41L9.99976 41C9.17133 41 8.49976 41.6716 8.49976 42.5C8.49976 43.3284 9.17133 44 9.99976 44L41.0665 44Z" fill="#D0D0D0"/>
<path d="M14.5001 49.5C14.5001 48.6716 15.1717 48 16.0001 48H26.0668C26.8953 48 27.5668 48.6716 27.5668 49.5C27.5668 50.3284 26.8953 51 26.0668 51H16.0001C15.1717 51 14.5001 50.3284 14.5001 49.5Z" fill="#D0D0D0"/>
<path d="M32.0001 48C31.1717 48 30.5001 48.6716 30.5001 49.5C30.5001 50.3284 31.1717 51 32.0001 51H63.0668C63.8953 51 64.5668 50.3284 64.5668 49.5C64.5668 48.6716 63.8953 48 63.0668 48H32.0001Z" fill="#D0D0D0"/>
<path d="M58.5665 42.5C58.5665 43.3284 57.8949 44 57.0665 44H46.9998C46.1713 44 45.4998 43.3284 45.4998 42.5C45.4998 41.6716 46.1713 41 46.9998 41H57.0665C57.8949 41 58.5665 41.6716 58.5665 42.5Z" fill="#D0D0D0"/>
</svg>

    );
}
WeatherFogIcon.__tags = [] as string[];
