/* eslint-disable max-len */
import React from 'react';

export function WeatherSnowIcon() {
    return (
        <svg width="70" height="54" viewBox="0 0 70 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M53.4488 36.7385C53.5723 36.8134 53.689 36.8953 53.7987 36.9835L60.3979 37H60.3997C64.6023 36.999 67.9999 33.602 67.9999 29.423C67.9999 25.2428 64.6012 21.8455 60.3979 21.8455H57.6612L57.5014 20.0199C56.6348 10.1227 48.2969 2 38.253 2C31.468 2 25.4995 5.48523 22.0502 10.7643C25.6429 11.9082 28.7151 14.2214 30.8214 17.2551C31.1364 17.7088 31.0239 18.3319 30.5703 18.6469C30.1166 18.9619 29.4935 18.8494 29.1785 18.3958C26.9639 15.2059 23.5448 12.9235 19.5835 12.2086C18.7228 12.0546 17.8352 11.9739 16.927 11.9739C15.3544 11.9739 13.839 12.2162 12.4161 12.6654C6.38919 14.5768 2.01589 20.2206 2 26.8931C2.00925 30.757 3.49312 34.2731 5.92549 36.9158H10.3188C9.88119 36.0389 9.87799 34.966 10.4047 34.0537C11.1448 32.7718 12.6825 32.2375 14.0291 32.7158C14.2881 31.3105 15.5197 30.2458 16.9999 30.2458C18.4802 30.2458 19.7117 31.3104 19.9708 32.7157C21.3173 32.2374 22.8551 32.7717 23.5952 34.0536C24.1219 34.966 24.1187 36.0389 23.681 36.9158H26.646L40.2441 36.9497L40.2426 36.951C40.3402 36.8751 40.4431 36.8041 40.5512 36.7385C39.0563 35.8314 38.5537 33.8911 39.4323 32.3693C40.311 30.8474 42.2428 30.3125 43.7758 31.1537C43.8137 29.4055 45.2428 28 47.0001 28C48.7574 28 50.1865 29.4054 50.2245 31.1536C51.7575 30.3126 53.6891 30.8474 54.5677 32.3693C55.4463 33.8911 54.9437 35.8314 53.4488 36.7385Z" fill="#D0D0D0"/>
<path d="M47.0001 30C46.3235 30 45.7749 30.5485 45.7749 31.2252V34.6165L42.838 32.9208C42.252 32.5825 41.5027 32.7833 41.1643 33.3693C40.826 33.9553 41.0268 34.7046 41.6128 35.0429L44.5496 36.7385L41.6128 38.4341C41.0268 38.7724 40.826 39.5217 41.1643 40.1077C41.5027 40.6936 42.252 40.8944 42.8379 40.5561L45.7749 38.8604V42.2516C45.7749 42.9283 46.3235 43.4768 47.0001 43.4768C47.6767 43.4768 48.2253 42.9283 48.2253 42.2516V38.8606L51.162 40.5561C51.748 40.8944 52.4973 40.6937 52.8356 40.1077C53.1739 39.5217 52.9731 38.7724 52.3872 38.4341L49.4503 36.7385L52.3871 35.0429C52.9731 34.7045 53.1739 33.9553 52.8356 33.3693C52.4973 32.7833 51.748 32.5825 51.162 32.9208L48.2253 34.6163V31.2252C48.2253 30.5485 47.6767 30 47.0001 30Z" fill="#D0D0D0"/>
<path d="M16.9999 32.2458C16.436 32.2458 15.9789 32.703 15.9789 33.2668V36.0931L13.5314 34.68C13.0431 34.3981 12.4187 34.5654 12.1367 35.0537C11.8548 35.5421 12.0221 36.1665 12.5104 36.4484L14.9578 37.8614L12.5105 39.2744C12.0222 39.5563 11.8549 40.1807 12.1368 40.6691C12.4188 41.1574 13.0432 41.3247 13.5315 41.0428L15.9789 39.6298V42.4558C15.9789 43.0197 16.436 43.4768 16.9999 43.4768C17.5638 43.4768 18.0209 43.0197 18.0209 42.4558V39.6299L20.4683 41.0429C20.9566 41.3249 21.5811 41.1575 21.863 40.6692C22.1449 40.1809 21.9776 39.5564 21.4893 39.2745L19.0418 37.8614L21.4894 36.4483C21.9777 36.1664 22.145 35.5419 21.8631 35.0536C21.5812 34.5653 20.9567 34.398 20.4684 34.6799L18.0209 36.093V33.2668C18.0209 32.703 17.5638 32.2458 16.9999 32.2458Z" fill="#D0D0D0"/>
<path d="M30.9791 43.021C30.9791 42.4571 31.4362 42 32.0001 42C32.564 42 33.0211 42.4571 33.0211 43.021V45.8471L35.4686 44.434C35.957 44.1521 36.5814 44.3194 36.8633 44.8078C37.1453 45.2961 36.978 45.9205 36.4896 46.2025L34.042 47.6156L36.4895 49.0286C36.9779 49.3106 37.1452 49.935 36.8632 50.4234C36.5813 50.9117 35.9569 51.079 35.4685 50.7971L33.0211 49.384V52.2099C33.0211 52.7738 32.564 53.2309 32.0001 53.2309C31.4362 53.2309 30.9791 52.7738 30.9791 52.2099V49.3839L28.5318 50.7969C28.0434 51.0789 27.419 50.9116 27.1371 50.4232C26.8551 49.9349 27.0224 49.3105 27.5108 49.0285L29.958 47.6156L27.5107 46.2026C27.0223 45.9206 26.855 45.2962 27.137 44.8079C27.4189 44.3195 28.0433 44.1522 28.5317 44.4342L30.9791 45.8472V43.021Z" fill="#D0D0D0"/>
</svg>

    );
}
WeatherSnowIcon.__tags = [] as string[];
