import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';

import type { HttpClientError } from '@placer-ui/http-client';
import type { InitialStateData } from 'store/initial-state/types';
import { useGetInitialState } from 'store/initial-state/index';
import { userIsAnonymousSelector } from 'store/selectors/app-selectors';
import { getLoginCookie } from 'utils/cookies/utils';

const INIT_QUERY_KEY = 'get-initial-state';

export const useInitialStateQuery = () => {
    const userIsAnonymous = useSelector(userIsAnonymousSelector);
    const getInitialState = useGetInitialState();

    return useQuery<InitialStateData, HttpClientError>({
        queryKey: [INIT_QUERY_KEY],
        queryFn: getInitialState,
        initialData: undefined,
        refetchOnWindowFocus: false,
        enabled: userIsAnonymous === false && !!getLoginCookie(),
        meta: {
            cache: false,
        },
        retry(failureCount, error) {
            return error.status !== 401 && failureCount < 3;
        },
    });
};
