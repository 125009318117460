/* eslint-disable max-len */
import React from 'react';

export const SgHealthcareIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
        >
            <path d="M0.5 20H20.5" stroke="#5E63E5" strokeWidth="0.75" />
            <rect
                x="2.875"
                y="5.75586"
                width="15.25"
                height="14.25"
                fill="#E1E2FF"
                stroke="#5E63E5"
                strokeWidth="0.75"
            />
            <rect
                x="8.375"
                y="12.7559"
                width="4.25"
                height="7.25"
                fill="white"
                stroke="#5E63E5"
                strokeWidth="0.75"
            />
            <mask id="path-4-inside-1_3566_18632" fill="white">
                <rect x="7.5" y="2.5" width="6" height="6" rx="0.5" />
            </mask>
            <rect
                x="7.5"
                y="2.5"
                width="6"
                height="6"
                rx="0.5"
                fill="white"
                stroke="#5E63E5"
                strokeWidth="1.5"
                mask="url(#path-4-inside-1_3566_18632)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.9285 4H10.0714V5.07153H9V5.92868H10.0714V7H10.9285V5.92868H12V5.07153H10.9285V4Z"
                fill="#5E63E5"
            />
        </svg>
    );
};
