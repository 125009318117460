/* eslint-disable max-len */
import React from 'react';
import Icon from '@ant-design/icons';
import { IconProps } from 'components/assets/Icons/Icons';

const DesertedIslandComponent = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="244"
            height="100"
            viewBox="0 0 244 100"
        >
            <g fill="none" fillRule="evenodd">
                <path
                    fill="#D0D0D0"
                    d="M45.83 99c-6.726 0-12.177-5.452-12.177-12.176 0-.178.003-.354.01-.53H22.236C9.955 86.294 0 76.34 0 64.06s9.955-22.235 22.235-22.235h21.321c-.189-.852-.288-1.738-.288-2.648C43.268 32.452 48.719 27 55.444 27h169.738c10.234 0 18.53 8.296 18.53 18.53 0 10.233-8.296 18.529-18.53 18.529h-15.45c0 12.28-9.955 22.235-22.235 22.235h-38.671c.007.176.011.352.011.53 0 6.724-5.452 12.176-12.176 12.176H45.829z"
                    transform="translate(0 1)"
                />
                <path
                    fill="#5E63E5"
                    d="M218.508 21.333c10.234 0 18.53 8.296 18.53 18.53 0 10.233-8.296 18.53-18.53 18.53h-15.45c0 12.28-9.955 22.234-22.235 22.234h-38.671c.007.176.011.353.011.53 0 6.725-5.451 12.176-12.176 12.176h-79.81C43.452 93.333 38 87.882 38 81.157c0-.177.004-.354.012-.53h-11.43c-12.28 0-22.234-9.955-22.234-22.235s9.955-22.235 22.235-22.235h21.32c-.188-.852-.288-1.738-.288-2.647 0-6.725 5.452-12.177 12.177-12.177h158.716z"
                    transform="translate(0 1)"
                />
                <path
                    fill="#858AFD"
                    d="M217.84 18c10.234 0 18.53 8.296 18.53 18.53 0 10.233-8.296 18.529-18.53 18.529h-15.45c0 12.28-9.955 22.235-22.235 22.235h-38.892l.002.216c0 6.725-5.452 12.176-12.177 12.176h-79.81c-6.725 0-12.176-5.451-12.176-12.176l.002-.216h-11.19c-12.28 0-22.235-9.955-22.235-22.235s9.955-22.235 22.235-22.235h21.321c-.189-.852-.288-1.738-.288-2.648C46.947 23.452 52.398 18 59.123 18H217.84z"
                    transform="translate(0 1)"
                />
                <g stroke="#333" strokeLinecap="square" strokeWidth="2">
                    <path
                        d="M13.807 30L13.807 0M.807 30L.807 4.97M14.807 13.485L21 6.307M1.578 16.843L5.894 11.351"
                        transform="translate(0 1) translate(71.193 37)"
                    />
                </g>
                <g stroke="#333" strokeWidth="2">
                    <path
                        strokeLinecap="square"
                        d="M21.976 41.969L21.976 7.636M1.247 41.969L1.247.864"
                        transform="translate(0 1) matrix(-1 0 0 1 164.107 .333)"
                    />
                    <path
                        d="M21.976 24.327L29.102 11.537M1.344 21.499L7.43 12.653"
                        transform="translate(0 1) matrix(-1 0 0 1 164.107 .333)"
                    />
                </g>
                <path
                    fill="#FBD485"
                    d="M55.5 60c4.142 0 7.5 3.358 7.5 7.5 0 .168-.006.335-.016.5H48.016c-.01-.165-.016-.332-.016-.5 0-4.142 3.358-7.5 7.5-7.5zM111.5 49c5.799 0 10.5 4.701 10.5 10.5 0 .168-.004.335-.012.5h-20.976c-.008-.165-.012-.332-.012-.5 0-5.799 4.701-10.5 10.5-10.5z"
                    transform="translate(0 1)"
                />
            </g>
        </svg>
    );
};

export const DesertedIslandSVG = (props: IconProps) => {
    return <Icon component={DesertedIslandComponent} {...props} />;
};
