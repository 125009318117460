import React, { createContext } from 'react';

export const WidgetHideLoadingContext = createContext(false);

type WidgetHideLoadingContextWrapperProps = {
    children?: React.ReactNode;
};

/*
Hide Loading - Wrap a widget component in this context to disable it's loading bar UI.
The widget will render once loading is done.
 */
export const WidgetHideLoadingContextWrapper = ({ children }: WidgetHideLoadingContextWrapperProps) => {
    return <WidgetHideLoadingContext.Provider value={true}>{children}</WidgetHideLoadingContext.Provider>;
};
