/* eslint-disable max-len */
import React from 'react';

export function WeatherCloudyIcon() {
    return (
        <svg width="70" height="54" viewBox="0 0 70 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M38.2532 15C36.4424 15 34.6844 15.2251 33.0056 15.6488L33.0101 14.5915C33.5695 8.65032 38.5741 4 44.6626 4C48.2637 4 51.4851 5.62443 53.6349 8.18757C52.0529 9.03009 50.6964 10.2394 49.6793 11.7011C49.3638 12.1545 49.4756 12.7777 49.929 13.0931C50.3823 13.4086 51.0055 13.2968 51.321 12.8435C52.2541 11.5024 53.5324 10.4212 55.0267 9.72806L56.2142 9.34195C57.0889 9.05756 58.0245 8.90305 59.0001 8.90305C62.4316 8.90305 65.4145 10.8234 66.9328 13.6481C67.6082 14.9219 67.9916 16.3835 68 17.9478C67.9863 20.7551 66.6889 23.2592 64.6576 24.903H56.0615C52.2471 19.005 45.6954 15 38.2532 15Z" fill="#D0D0D0"/>
<path d="M60.3979 52L26.646 51.9158H5.92549C3.49312 49.2731 2.00925 45.757 2 41.8931C2.01589 35.2206 6.38919 29.5768 12.4161 27.6654C13.839 27.2162 15.3544 26.9739 16.927 26.9739C17.8352 26.9739 18.7228 27.0546 19.5835 27.2086C23.5448 27.9235 26.9639 30.2059 29.1785 33.3958C29.4935 33.8494 30.1166 33.9619 30.5703 33.6469C31.0239 33.3319 31.1364 32.7088 30.8214 32.2551C28.7151 29.2214 25.6429 26.9082 22.0502 25.7643C25.4995 20.4852 31.468 17 38.253 17C48.2969 17 56.6348 25.1227 57.5014 35.0199L57.6612 36.8455H60.3979C64.6012 36.8455 67.9999 40.2428 67.9999 44.423C67.9999 48.602 64.6023 51.999 60.3997 52H60.3979Z" fill="#D0D0D0"/>
</svg>

    );
}
WeatherCloudyIcon.__tags = [] as string[];
