import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
// eslint-disable-next-line max-len
import { selectHeaderStarredReportsByTypeAndTerm } from 'store/header/starred-reports/selectors/select-header-starred-reports';

import { useStarReportActions } from 'store/star-report/use-star-report-actions';
import {
    STARRED_REPORT_TABS,
    StarredReportsTabs,
    StarredReportsTabToReportTypeMap,
    StarredReportTabsTypes,
} from 'features/star-report/components/starred-reports-tabs/starred-reports-tabs';
import styles from './starred-reports-dialog.module.scss';
import { StarredReportsList } from './components/starred-reports-list/starred-reports-list';
// eslint-disable-next-line max-len
import { StarredReportsSearch } from 'features/star-report/components/starred-reports-search/starred-reports-search';
import { useSelectorWithProps } from 'utils/structured-selector/structured-selector';
import { useSelector } from 'react-redux';
import { selectStarReportIsLoading } from 'store/star-report/selectors/star-report-selectors-factory';
import { LoadingThreeDotIndicator } from 'ui-components/loading-three-dots-indicator/loading-three-dots-indicator';
import { selectPropertyDefaultReportURL } from 'store/header/navigation';
import { selectChainsDefaultReportURL } from 'features/chains/store/chains-report-chains-store';
import { chainsBasename } from 'features/chains/router/chains-router';
import { useRelativeUrl } from 'hooks/use-relative-url';

type StarredReportsDialogProps = {
    onClose: () => void;
};

export const StarredReportsDialog = ({ onClose }: StarredReportsDialogProps) => {
    const [currentSearch, setCurrentSearch] = useState('');
    const [selectedTab, onSelectTab] = useState<StarredReportTabsTypes>(STARRED_REPORT_TABS.ALL);
    const { getReports } = useStarReportActions();
    const reportType = StarredReportsTabToReportTypeMap[selectedTab];
    const starredReportsOptions = useMemo(
        () => ({
            term: currentSearch,
            filter: reportType,
        }),
        [currentSearch, reportType],
    );
    const reportList = useSelectorWithProps(
        starredReportsOptions,
        selectHeaderStarredReportsByTypeAndTerm,
    );
    const noReports = !currentSearch && reportList.length === 0;
    const isLoading = useSelector(selectStarReportIsLoading);
    const urlPath = useRelativeUrl();
    const defaultChainsReportURL = useSelector((state: any) =>
        selectChainsDefaultReportURL(state, urlPath),
    );
    const defaultPropertyReportURL = useSelector(selectPropertyDefaultReportURL);
    let url =
        selectedTab === STARRED_REPORT_TABS.PROPERTY
            ? defaultPropertyReportURL
            : `${chainsBasename}${defaultChainsReportURL}`;
    url = url.replace('/overview', '/starred-reports');

    useEffect(getReports, [getReports]);

    const tabsClassName = classNames(styles.tabsWrapper, {
        [styles.hide]: selectedTab === STARRED_REPORT_TABS.ALL && noReports,
    });
    const searchClassName = classNames(styles.searchWrapper, {
        [styles.remove]: noReports,
    });
    const linkClassName = classNames(styles.viewAllLinkWrapper, {
        [styles.remove]: noReports || selectedTab === STARRED_REPORT_TABS.ALL,
    });

    const onClick = () => {
        window.location.href = url;
        onClose();
    };

    return (
        <div className={styles.dialogContentWrapper}>
            <h1>Starred Reports</h1>
            <LoadingThreeDotIndicator className={styles.loadingIndicator} show={isLoading}>
                <StarredReportsTabs
                    className={tabsClassName}
                    selectedTab={selectedTab}
                    onSelectTab={onSelectTab}
                />
                <StarredReportsSearch
                    className={searchClassName}
                    value={currentSearch}
                    placeholder={'Search Starred Reports'}
                    onChange={setCurrentSearch}
                />
                <StarredReportsList
                    className={styles.listWrapper}
                    reportList={reportList}
                    searchTerm={currentSearch}
                    reportType={reportType}
                    onClose={onClose}
                />
                <div className={linkClassName}>
                    <a className={styles.viewAllLink} onClick={onClick} href={url}>
                        View All Starred Reports
                    </a>
                </div>
            </LoadingThreeDotIndicator>
        </div>
    );
};
