import { createAsyncThunk } from '@reduxjs/toolkit';

import { ChainEntityModel } from 'core/entities';
import { ChainsReportState } from 'features/chains/store/chains-store';
import {
    selectChainsEntityModelsSelectedForReportInSearch,
    selectUserCanAddMoreChainsToReportFromSearch,
    setSelectedChainsEntityModels,
} from 'features/chains/store/chains-report-search-store';

type ToggleChainSelectionStatusActionProps = {
    chainEntityModel: ChainEntityModel;
};

export const toggleChainSelectionStatus = createAsyncThunk<
    void,
    ToggleChainSelectionStatusActionProps,
    { state: ChainsReportState }
>(
    'chains/toggle-chain-selection-status-action',
    async (
        { chainEntityModel }: ToggleChainSelectionStatusActionProps,
        { dispatch, getState },
    ) => {
        const state = getState();
        const userCanAddMoreChainsToReportFromSearch = selectUserCanAddMoreChainsToReportFromSearch(
            state,
        );

        const selectedChainsEntityModels = selectChainsEntityModelsSelectedForReportInSearch(
            state,
        );
        const selectedChainsEntityModelsIds = selectedChainsEntityModels.map(
            (model) => model.originalEntity.id,
        );
        const entityShouldBeDeselected = selectedChainsEntityModelsIds.includes(
            chainEntityModel.originalEntity.id,
        );

        if (entityShouldBeDeselected) {
            const newSelectedChainsEntityModels = selectedChainsEntityModels.filter(
                (model) =>
                    model.originalEntity.id !==
                    chainEntityModel.originalEntity.id,
            );

            dispatch(
                setSelectedChainsEntityModels(newSelectedChainsEntityModels),
            );
        } else if (userCanAddMoreChainsToReportFromSearch) {
            const newSelectedChainsEntityModels = [
                ...selectedChainsEntityModels,
                chainEntityModel,
            ];

            dispatch(
                setSelectedChainsEntityModels(newSelectedChainsEntityModels),
            );
        }
    },
);
