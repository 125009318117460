import React from 'react';
import styles from './goto-icon-wrapper.module.scss';
import { VoidAnalysisGotoIcon } from 'components/assets/Icons/Icons';

export const GotoIconWrapper = () => {
    return (
        <span className={styles.gotoIcon}>
            <VoidAnalysisGotoIcon />
        </span>
    );
};
