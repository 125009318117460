import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { Market, MarketEntityModel } from 'core/entities';
import { WidgetFactoryClass } from 'extensions/widget/factories/widget-components-factory';
import { MAX_COMPARE_MARKETS } from 'features/market/common/constants/market';
import { PLACE_COLORS } from 'core/constants/place-colors';
import { RouteInitializerProps } from 'router';
import type { Filter } from '@placer-ui/types';

export interface MarketState {
    ids: string[];
    entities: Record<string, MarketEntityModel>;
}

export const initialState: MarketState = {
    ids: [],
    entities: {},
};

export const marketSlice = createSlice({
    name: 'market',
    initialState: initialState,
    reducers: {
        resetState: () => {
            return initialState;
        },
        setMarketEntities: (state: MarketState, action: PayloadAction<Market[]>) => {
            updateEntityState(state, action.payload);
        },
        addMarketEntity: (state: MarketState, action: PayloadAction<Market>) => {
            if (state.ids.length < MAX_COMPARE_MARKETS) {
                const newEntity = action.payload;
                const newEntities = Object.values(state.entities).map(
                    (entity) => entity.originalEntity,
                );
                newEntities.push(newEntity);
                updateEntityState(state, newEntities);
            }
        },
        removeMarketEntity: (state: MarketState, action: PayloadAction<string>) => {
            const removeIndex = state.ids.indexOf(action.payload);
            if (removeIndex > -1) {
                const newEntities = Object.values(state.entities).map(
                    (entity) => entity.originalEntity,
                );
                newEntities.splice(removeIndex, 1);
                updateEntityState(state, newEntities);
            }
        },
        updateMarketFilters: (state: MarketState, action: PayloadAction<Filter>) => {
            Object.values(state.entities).forEach(
                (entity) => (entity.filters = { ...action.payload }),
            );
        },
    },
});

const updateEntityState = (state: MarketState, markets: Market[]) => {
    const filters = state.entities.length ? Object.values(state.entities)[0].filters : {};
    const marketEntities: MarketEntityModel[] = markets.map(
        (market, index) =>
            ({
                originalEntity: market,
                filters: { ...filters },
                color: PLACE_COLORS.main[index],
                uid: `market_${index}`,
            } as MarketEntityModel),
    );
    state.ids = marketEntities.map((entity) => entity.uid);
    state.entities = marketEntities.reduce<Record<string, MarketEntityModel>>(
        (entities, entity) => {
            entities[entity.uid] = entity;
            return entities;
        },
        {},
    );
};

export const marketReducer = {
    market: marketSlice.reducer,
};

export const marketActions = marketSlice.actions;

export const marketStoreInitializer = ({ dispatch }: RouteInitializerProps) => {
    dispatch(marketSlice.actions.resetState());
};

export const marketWidgetFactory = new WidgetFactoryClass();
