import memoize from 'lodash/memoize';
import { getFeatureFlags, getPermissions, hasFeatureFlags } from 'core/flow-control';
import { UserPermissions } from 'core/entities/user/user-permissions';
import { UserFeatureFlags } from 'core/entities/user/user-feature-flags';
import { DESKTOP_ROUTES } from 'router/routes/desktop.routes';
import { MOBILE_ROUTES } from 'router/routes/mobile.routes';
import { shouldRenderMobileApp } from 'utils/detect-environment';

const getFilteredRoutes = memoize(
    (
        featureFlags: UserFeatureFlags,
        userPermissions: UserPermissions,
        shouldRenderMobileApp: boolean,
    ) => {
        const routes = shouldRenderMobileApp ? MOBILE_ROUTES : DESKTOP_ROUTES;
        return routes.filter((route) => {
            if (route.path === '/industry/' && hasFeatureFlags(['extended_bg_image_upsell_ff'])) {
                return true;
            }

            // Check feature flag if it exists
            if (route.featureFlag && featureFlags && !featureFlags[route.featureFlag]) {
                return false;
            }

            // Check permission if it exists
            if (route.permission && userPermissions && !userPermissions[route.permission]) {
                return false;
            }

            // Include route if no feature flag or permission are set, or if both checks have passed
            return true;
        });
    },
);

export const getDashboardRoutes = () =>
    getFilteredRoutes(getFeatureFlags(), getPermissions(), shouldRenderMobileApp());
