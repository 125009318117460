import React, { CSSProperties, useRef } from 'react';
import classnames from 'classnames';

import { Checkbox as AntdCheckbox } from 'antd';
import {
    MultiSelectOption,
    MultiSelectSubOption,
    SelectAllConfiguration,
} from 'ui-components/multi-select/types';

import styles from './checkbox.module.scss';
import { Button } from 'ui-components/button/button';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { ArrowIconButton } from 'ui-components/arrow-icon-button/arrow-icon-button';
import { Tooltip } from 'ui-components/tooltip/tooltip';
import { CheckboxList as CheckboxListV2 } from 'ui-components/multi-select/checkbox-list-v2/checkbox-list';
import { CheckboxList as CheckboxListV3 } from 'ui-components/multi-select/checkbox-list-v3/checkbox-list';
import { useEnableCheckboxListFiltersV3 } from 'ui-components/multi-select/checkbox-list-v3/hooks/checkbox-list-hooks';
import Highlighter from 'react-highlight-words';

export type CheckedCheckboxStyle = {
    color: string;
    border: string;
};

type CheckboxProps = MultiSelectOption & {
    onChange: (option: MultiSelectOption) => void;
    expanded?: boolean;
    onExpandChange?: (expanded: boolean) => void;
    className?: string;
    disabled?: boolean;
    indeterminateState?: boolean;
    testId?: string;
    contentClassName?: string;
    subOptionsListClassName?: string;
    checkboxNameClass?: string;
    isSubOption?: boolean;
    addHover?: boolean;
    addSeparator?: boolean;
    subScroller?: boolean;
    tooltipText?: string;
    showTooltipOnlyInCaseOfEllipsis?: boolean;
    isChildCheckboxList?: boolean;
    selectAllConfiguration?: SelectAllConfiguration;
    userInput?: string;
    checkedStyles?: CheckedCheckboxStyle;
};

export const Checkbox = ({
    name,
    selected,
    value,
    subOptions,
    onChange,
    expanded,
    onExpandChange,
    className,
    disabled = false,
    indeterminateState = false,
    testId = 'checkbox',
    contentClassName,
    subOptionsListClassName,
    checkboxNameClass,
    isSubOption,
    addHover = false,
    addSeparator = false,
    subScroller = true,
    tooltipText,
    showTooltipOnlyInCaseOfEllipsis = false,
    isChildCheckboxList,
    selectAllConfiguration,
    userInput,
    checkedStyles,
}: CheckboxProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const enableCheckboxListFiltersV3 = useEnableCheckboxListFiltersV3();

    const CheckboxList = enableCheckboxListFiltersV3 ? CheckboxListV3 : CheckboxListV2;

    const colorClassStyle: CSSProperties = {
        ['--checkbox-checked-background' as string]: checkedStyles?.color,
        ['--checkbox-checked-border' as string]: checkedStyles?.border,
    };

    const handleChange = ({ target: { value, checked } }: CheckboxChangeEvent) => {
        const newSubOptions = subOptions?.map((option) => ({
            ...option,
            selected: checked,
        }));

        const newOption = {
            name,
            value,
            selected: checked,
            ...(newSubOptions && { subOptions: newSubOptions }),
        };
        onChange(newOption);
    };

    const handleSubOptionChange = (
        options: MultiSelectSubOption[],
        changedOption: MultiSelectSubOption,
    ) => {
        const newSubOptions = [...subOptions!];
        const index = newSubOptions.findIndex(({ value }) => value === changedOption.value);
        newSubOptions[index] = changedOption;
        const { total, checked } = getSubOptionsState(newSubOptions);
        const newSelectedState = total === checked;

        onChange({
            name,
            value,
            selected: newSelectedState,
            subOptions: newSubOptions,
        } as MultiSelectSubOption);
    };

    const getSubOptionsState = (subOptions: MultiSelectSubOption[]) =>
        subOptions.reduce(
            (acc, { selected }) => {
                acc.total++;
                selected ? acc.checked++ : acc.unchecked++;
                return acc;
            },
            {
                total: 0,
                checked: 0,
                unchecked: 0,
            },
        );

    const subOptionState = subOptions?.length ? getSubOptionsState(subOptions) : null;

    const isIndeterminate = () => {
        if (!subOptions || !subOptionState) return false;
        const { checked, unchecked } = subOptionState;
        return checked > 0 && unchecked > 0;
    };

    const hasSelected = !subOptions || !subOptionState ? selected : subOptionState.checked > 0;
    const indeterminate = isIndeterminate() || indeterminateState;

    return (
        <div className={classnames(styles.checkboxWrapper, className)}>
            <Tooltip
                title={tooltipText}
                placement="right"
                showOnlyInCaseOfEllipsis={showTooltipOnlyInCaseOfEllipsis}
                ellipsisElementRef={containerRef}
            >
                <div style={colorClassStyle}>
                    <AntdCheckbox
                        checked={hasSelected || indeterminate}
                        indeterminate={indeterminate}
                        value={value}
                        onChange={handleChange}
                        data-testid={testId}
                        disabled={disabled}
                        aria-checked={indeterminate ? 'mixed' : hasSelected?.toString()}
                        className={classnames(styles.checkboxContentClassName, contentClassName, {
                            addHover,
                            isSubOption,
                            addSeparator,
                            [styles.parentsCheckboxContent]: !isChildCheckboxList,
                        })}
                    >
                        <div
                            ref={containerRef}
                            className={classnames(styles.checkboxName, checkboxNameClass)}
                        >
                            <Highlighter
                                autoEscape
                                searchWords={[userInput ?? '']}
                                textToHighlight={name}
                                highlightClassName={styles.highlight}
                                className="highlightTextWrapper"
                            />
                        </div>
                    </AntdCheckbox>
                </div>
            </Tooltip>
            {!!subOptions?.length && onExpandChange && (
                <>
                    <Button
                        className={classnames(styles.expandButton, { expand: !expanded })}
                        onClick={() => onExpandChange(!expanded)}
                        type="ghost"
                        size="small"
                        icon={<ArrowIconButton arrowDirection={expanded ? 'up' : 'down'} />}
                        ghost={true}
                        data-testid={'expand-change-button'}
                    />
                    {expanded && (
                        <div className={styles.subOptionsWrapper}>
                            <CheckboxList
                                className={classnames(
                                    styles.subOptionsList,
                                    subOptionsListClassName,
                                )}
                                checkboxNameClass={checkboxNameClass}
                                options={subOptions}
                                onChange={handleSubOptionChange}
                                isSubOption
                                addHover={addHover}
                                addSeparator={addSeparator}
                                subScroller={subScroller}
                                addTooltip={!!tooltipText}
                                showTooltipOnlyInCaseOfEllipsis={!!showTooltipOnlyInCaseOfEllipsis}
                                isChildCheckboxList={true}
                                selectAllConfiguration={selectAllConfiguration}
                                initSearchValue={userInput}
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};
