/* eslint-disable max-len */
import React from 'react';
import styles from './poi-type-filters.module.scss';

import SelectMenu, { Options } from 'ui-components/select-menu/select-menu';
import { usePoiTypeFilterOptions } from 'features/poi-selection-map/poi-selection-menu/menu-items/search-menu-item/hooks/search-poi-filter-hooks';
import { ReportTypes } from 'core/constants/report-type';
import {
    useSearchApiFilters,
    useUpdateSearchApiFilters,
} from 'features/poi-selection-map/poi-selection-menu/menu-items/search-menu-item/hooks/search-poi-hooks';

export const PoiTypeFilters = () => {
    const typesFilterOptions: Options[] = usePoiTypeFilterOptions();
    const searchApiFilters = useSearchApiFilters();
    const updateSearchApiFilters = useUpdateSearchApiFilters();

    const onChangeHandler = (selectedOption: string) => {
        updateSearchApiFilters({
            type: selectedOption as ReportTypes,
        });
    };

    return (
        <div className={styles.container}>
            <SelectMenu
                appendToParent={true}
                value={searchApiFilters.type}
                options={typesFilterOptions}
                onChange={onChangeHandler}
                dropdownClassName={styles.dropdown}
                dropdownAlign={{ offset: [-16, 10] }}
            />
        </div>
    );
};
