import React, { memo } from 'react';
import classNames from 'classnames';

import { StarredReport } from 'core/entities';
import { useStarReportActions } from 'store/star-report/use-star-report-actions';
import { StarredReportsItem } from './components/starred-reports-item/starred-reports-item';
import styles from './starred-reports-list.module.scss';
import { StarredReportsEmpty } from './components/starred-reports-empty/starred-reports-empty';
import { useSplunkCallback } from 'hooks/use-splunk-callback/use-splunk-callback';
import {
    selectAttributeCount,
    selectReportDates,
} from 'store/star-report/selectors/star-report-selectors-factory';
import { StarredReportsEvent } from 'features/star-report/types';

const starredReportListEventData = {
    component: 'quick-access',
};

type StarredReportsListProps = {
    reportList: StarredReport[];
    searchTerm: string;
    reportType?: string;
    className: string;
    onClose: () => void;
};

const NoReportsFound = memo(
    ({
        searchTerm,
        className,
        reportType,
    }: {
        searchTerm: string;
        className: string;
        reportType?: string;
    }) => {
        if (searchTerm) {
            return (
                <div className={className}>
                    <p className={styles.noMatch}>No matches were found for "{searchTerm}"</p>
                </div>
            );
        }

        return <StarredReportsEmpty className={className} reportType={reportType} />;
    },
);

export const StarredReportsList = ({
    reportList,
    searchTerm,
    reportType,
    className,
    onClose,
}: StarredReportsListProps) => {
    const { openReport } = useStarReportActions();
    const sendEvent = useSplunkCallback<StarredReportsEvent>(starredReportListEventData);
    const trimmedSearchTerm = searchTerm?.trim();

    if (reportList.length === 0) {
        return (
            <NoReportsFound
                className={className}
                reportType={reportType}
                searchTerm={trimmedSearchTerm}
            />
        );
    }

    const listClassName = classNames(className, styles.reportListWrapper);
    return (
        <ul className={listClassName}>
            {reportList.map((report, index) => {
                const onReportClick = createOnReportClick(report);

                return (
                    <li key={index}>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a className={styles.link} onClick={onReportClick}>
                            <StarredReportsItem
                                report={report}
                                termToHighlight={trimmedSearchTerm}
                            />
                        </a>
                    </li>
                );
            })}
        </ul>
    );

    function createOnReportClick(report: StarredReport) {
        return (event: React.MouseEvent<HTMLAnchorElement>) => {
            event.preventDefault();
            const newTab = event.shiftKey || event.ctrlKey || event.metaKey;
            onClose();

            const { isSameDay } = selectReportDates(report.entities);
            sendEvent({
                action: 'open-starred-report',
                url: window.location.href,
                custom_dates: !isSameDay,
                filters: selectAttributeCount(report.entities),
            });

            openReport({
                report,
                newTab,
            });
        };
    }
};
