import { MutableRefObject, useEffect, useState } from 'react';

export const useDivComponentWidth = (
    divRef: MutableRefObject<HTMLDivElement | null>,
    padding: number = 0,
): number | null => {
    const [divWidth, setDivWidth] = useState<number | null>(null);

    useEffect(() => {
        if (divRef.current) {
            const handleResize = () => {
                setDivWidth(divRef.current!.getBoundingClientRect().width);
            };
            handleResize();

            window.addEventListener('resize', handleResize);

            // Clean up event listener on component unmount
            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }
    }, [divRef]);

    if (!divWidth) return null;

    return divWidth - padding;
};
