import { BASE_URL, FetchProps, fetchWrapper } from 'API/authentification/fetch-wrapper';
import { ReportAccessState } from 'store/configuration/report-access/slices/report-access-slice';
import { URLEntity } from 'features/insights/types/url-types';
import { placeTypeToPlaceCollection } from 'utils/place-type-collection-conversion/place-type-collection-conversion';
import type { Venue } from 'core/entities/venue/venueAdmin';
import { reportException } from 'core/exceptions';
import type { AccessInfo, PlacerResponseData } from '@placer-ui/types';
import pluralize from 'pluralize';
import { simplePollRequest } from 'API/fetch/simple-fetch-no-error-handling';
import { getAppApiEndpoint } from 'core/default-endpoint-url';
import { getFeatureFlags } from 'core/flow-control';

type WithCompetitorsEndpoint = 'fullpage-wait' | 'report-access' | 'parent_entity_coverage';

export type NearbyEntityStatus = {
    id: string;
    type: string;
    ft_radius: number;
};

type NearbyReportStatusResponse = {
    result: string;
    error_code: number;
    request_id: string;
};

const getResponseMapper = ({
    res,
    properties,
}: {
    res: PromiseSettledResult<PlacerResponseData<unknown>>[];
    properties: URLEntity[];
}) =>
    res.map((resByIndex, index) => {
        const urlEntity = properties[index];
        if (resByIndex.status === 'rejected') {
            const errorByUid = resByIndex.reason;
            reportException(new Error(`fetch error, errorType ${errorByUid?.common?.type}`), {
                payload: {
                    urlEntity,
                },
                target_url: BASE_URL,
                method: 'POST',
                level: 'error',
                message: errorByUid?.common?.status + ' - ' + errorByUid?.common?.type,
            });
            throw errorByUid;
        }
        return resByIndex.value;
    });

export const reportsApi = {
    checkSufficientData: async (properties: URLEntity[], options?: { signal?: AbortSignal }) => {
        const promises = withCompetitorsPromises(properties, 'fullpage-wait', options);
        let lastError: unknown;
        let resolved;
        for (const promise of promises) {
            try {
                await promise;
                resolved = true;
                return;
            } catch (error) {
                lastError = error;
            }
        }

        if (!resolved) {
            throw lastError;
        }
    },
    getReportAccess: async (
        properties: URLEntity[],
        venues: Venue[],
        options?: { signal?: AbortSignal },
    ) => {
        const promises = withCompetitorsPromises<{ access: AccessInfo }>(
            properties,
            'report-access',
            options,
        );
        const reportAccess = await Promise.allSettled(promises);
        return Object.assign(
            {},
            ...reportAccess.map((res, index) => {
                const venue = venues[index];
                if (res.status === 'rejected') {
                    return {
                        [venue.uid]: {
                            error: {
                                status: res.reason?.common?.status,
                                type: res.reason?.common?.type,
                                info: res.reason?.common.info,
                            },
                        },
                    };
                }
                return {
                    [venue.uid]: res.value.data,
                };
            }),
        ) as ReportAccessState;
    },
    getChainCoverage: async (properties: URLEntity[], options?: { signal?: AbortSignal }) => {
        const promises = withCompetitorsPromises(properties, 'parent_entity_coverage', options);
        const res = await Promise.allSettled(promises);
        try {
            return getResponseMapper({
                res,
                properties,
            });
        } catch (e) {
            return Promise.reject(e);
        }
    },
    getNearbyReportStatus: async (poi: NearbyEntityStatus, options?: { signal?: AbortSignal }) => {
        await simplePollRequest<NearbyReportStatusResponse, {}>({
            targetUrl: `/nearby-activity/${pluralize(poi.type)}/${poi.id}?ft_radius=${
                poi.ft_radius
            }`,
            method: 'GET',
            signal: options?.signal,
            endPoint: getAppApiEndpoint(),
        });
    },
};

function withCompetitorsPromises<T>(
    properties: URLEntity[],
    endpoint: WithCompetitorsEndpoint,
    options: Omit<FetchProps<any>, 'targetUrl'> & { race?: boolean } = {},
) {
    const { enable_remove_competitors_params_from_api_ff } = getFeatureFlags();

    const place = properties[0];
    return properties.map(({ id, type, filter }, index) => {
        const baseUrl = `/${placeTypeToPlaceCollection(type)}/${id}/${endpoint}`;
        const params = new URLSearchParams();
        if (index > 0 && !enable_remove_competitors_params_from_api_ff) {
            params.append(`competitor_${type}_id`, place.id);
            params.append('competitor_start_date', place.filter.date.start);
            params.append('competitor_end_date', place.filter.date.end);
        }

        params.append('start_date', filter.date.start);
        params.append('end_date', filter.date.end);

        options.body = {
            config: filter.config,
            filter: filter.attributes,
        };

        const targetUrl = `${baseUrl}?${params.toString()}`;
        return fetchWrapper<PlacerResponseData<T>>({
            targetUrl,
            method: 'POST',
            ...options,
        });
    });
}
