import React, { PropsWithChildren, ComponentProps, useRef } from 'react';
import { Button } from 'antd';
import { Tooltip } from 'ui-components/tooltip/tooltip';
import { HelpIcon } from 'components/assets/Icons/Icons';
import styles from './help-button.module.scss';
import classNames from 'classnames';
import { useFullscreenContext } from 'utils/fullscreen-provider/fullscreen-provider';

export type ElevioPopup = {
    insert: () => void;
    _append: () => void;
    _destroy: () => void;
    _toString: () => string;
    _node: HTMLElement;
};

interface HelpButtonProps {
    /**
     * elev.io popup identifier to correspond to this button
     */
    elevioDataId: string | number;
    toolTipText?: string;
    toolTipPlacement?: ComponentProps<typeof Tooltip>['placement'];
    className?: string;
    exitFullscreenOnClick?: boolean;
    popupRef?: React.RefObject<ElevioPopup | undefined>;
    as?: 'textButton';
}

export const HelpButton = ({
    elevioDataId,
    toolTipText,
    toolTipPlacement = 'left',
    className,
    exitFullscreenOnClick,
    children,
    popupRef,
    as,
    ...restProps
}: PropsWithChildren<HelpButtonProps>) => {
    const ref = useRef<HTMLButtonElement>(null);
    const { isFullscreen, toggleFullscreen } = useFullscreenContext();

    const openForceRefresh = () => {
        const popup: ElevioPopup = (window as any)._elev.popupComponent(ref.current, {
            articleId: elevioDataId,
        });
        popup.insert();

        setTimeout(() => {
            // Elevio sometimes opens the popup without any listener firing,
            // so we force it to fire using a timeout and a manual scroll.
            window.scrollBy(0, 1);
            window.scrollBy(0, 0);

            // attach event handlers to the elevio wrapper element
            const elevioClass = popup._node.className.split(' ')[0];
            const wrapperElm = document.querySelector(`._elevio_popup:has(.${elevioClass})`);

            if (wrapperElm) {
                const closeBtn = wrapperElm.querySelector('button[type="button"][title="Close"]');

                wrapperElm.addEventListener('pointerdown', (e) => {
                    const event = e as MouseEvent;
                    const target = e.target as HTMLElement;
                    event.preventDefault();
                    event.stopPropagation();

                    if (event.target && closeBtn && closeBtn.contains(target)) {
                        popup._destroy();
                    }
                });
            }
        }, 120);

        if (popupRef) {
            // @ts-ignore
            popupRef.current = popup;
        }

        if (exitFullscreenOnClick && isFullscreen) {
            toggleFullscreen();
        }
    };

    return (
        <div
            className={classNames(
                as === 'textButton' ? styles.textButton : styles.helpButton,
                'no-print',
                className,
            )}
            {...restProps}
        >
            <Tooltip small title={toolTipText} placement={toolTipPlacement}>
                <Button
                    type="link"
                    ref={ref}
                    onClick={openForceRefresh}
                    data-elevio-style="nothing"
                >
                    {children ?? <HelpIcon />}
                </Button>
            </Tooltip>
        </div>
    );
};
