import { OrderManagementRequest } from './../../core/entities/offline-reports/offline-reports';
import { SahringTargetOptions } from 'core/entities';

import { createSlice } from '@reduxjs/toolkit';
import {
    getOfflineReports,
    getOfflineReportDetails,
    submitOfflineReportRequest,
    getOfflineReportsConfigurations,
    getOfflineReportsRequests,
    getOfflineReportsRequestsComments,
    postOfflineReportsRequestsComment,
    clearOfflineReportsRequestComments,
    clearOfflineReportsSubmitStatus,
    updateOfflineReportsOrderRequestReadReceipt,
    setIsSubmitLoadingPopupVisible,
    openInsufficientCreditsPopup,
    clearReportDetails,
    getOfflineReportsCreditsBalance,
    getOfflineReportsSharedWithMe,
    getOfflineReportsUserAccountInfo,
    updateClosedReportSharedAndTargetValues,
} from 'store/offline-reports/actions/get-offline-reports';
import { OfflineReportsStateModel } from 'store/offline-reports/models/offline-reports-state-model';

const closedRequestsPossibleStatus = ['closed', 'solved'];

const offlineReportsInitialState = {
    reports: [],
    reportDetails: null,
    submitStatus: null,
    configuarations: {
        most_popular_reports_count: 0,
    },
    openRequests: [],
    closedRequests: [],
    sharedWithMeRequests: [],
    comments: [],
    commentsLoading: false,
    requestsLoading: true,
    submitRequestLoading: false,
    availableCredits: 0,
    isSubmitLoadingPopupVisible: false,
    sharedWithMeRequestsLoading: true,
    requestsUserAccountInfoLoading: true,
    userAccountInfo: null,
} as OfflineReportsStateModel;

const possibleInsufficientFundsErrors = new Set<string>([
    'ACCOUNT_NOT_ENOUGH_CREDITS',
    'NOT_ENOUGH_CREDITS',
    'REACHED_WEEKLY_CREDIT_LIMIT',
]);

export const offlineReportsSlice = createSlice({
    name: 'offlineReports',
    initialState: offlineReportsInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getOfflineReports.fulfilled, (draft, { payload }) => {
                draft.reports =
                    payload?.slice()?.sort((a, b) => b.popularity_score - a.popularity_score) ?? [];
            })
            .addCase(getOfflineReportDetails.fulfilled, (draft, { payload }) => {
                draft.reportDetails = payload ?? null;
            })
            .addCase(getOfflineReportDetails.pending, (draft) => {
                draft.reportDetails = null;
            })
            .addCase(submitOfflineReportRequest.fulfilled, (draft, action) => {
                const payload = action.payload as any;
                if (
                    payload?.common?.info?.error &&
                    possibleInsufficientFundsErrors.has(payload.common.info.error)
                ) {
                    draft.submitStatus = 'Insufficient';
                } else if (payload.result === 'SUCCESS') {
                    draft.submitStatus = 'OK';
                } else if (payload.ok === false) {
                    draft.submitStatus = 'Error';
                } else {
                    draft.submitStatus = 'Error';
                }
                draft.submitRequestLoading = false;
            })
            .addCase(submitOfflineReportRequest.pending, (draft) => {
                draft.submitRequestLoading = true;
            })
            .addCase(submitOfflineReportRequest.rejected, (draft) => {
                draft.submitStatus = 'Error';
            })
            .addCase(getOfflineReportsConfigurations.fulfilled, (draft, { payload }) => {
                draft.configuarations = payload ?? {
                    most_popular_reports_count: 0,
                };
            })
            .addCase(getOfflineReportsRequests.fulfilled, (draft, { payload }) => {
                const openRequests: OrderManagementRequest[] = [];
                const closedRequests: OrderManagementRequest[] = [];
                payload?.requests?.forEach((request) => {
                    if (closedRequestsPossibleStatus.includes(request.status)) {
                        closedRequests.push(request);
                    } else {
                        openRequests.push(request);
                    }
                });

                draft.openRequests = openRequests;
                draft.closedRequests = closedRequests;
                draft.requestsLoading = false;
            })
            .addCase(getOfflineReportsSharedWithMe.fulfilled, (draft, { payload }) => {
                const sharedWithMeRequests: OrderManagementRequest[] = [];

                payload?.requests?.forEach((request) => {
                    sharedWithMeRequests.push(request);
                });
                draft.sharedWithMeRequests = sharedWithMeRequests;
                draft.sharedWithMeRequestsLoading = false;
            })
            .addCase(getOfflineReportsUserAccountInfo.fulfilled, (draft, { payload }) => {
                draft.userAccountInfo = payload ?? null;
                draft.requestsUserAccountInfoLoading = false;
            })
            .addCase(getOfflineReportsRequestsComments.fulfilled, (draft, { payload }) => {
                draft.comments = payload?.comments ?? [];
                draft.commentsLoading = false;
            })
            .addCase(getOfflineReportsRequestsComments.pending, (draft) => {
                draft.commentsLoading = true;
            })
            .addCase(postOfflineReportsRequestsComment.fulfilled, (draft, { payload }) => {
                draft.comments = payload?.comments ?? [];
                draft.commentsLoading = false;
            })
            .addCase(postOfflineReportsRequestsComment.pending, (draft) => {
                draft.commentsLoading = true;
            })
            .addCase(
                updateOfflineReportsOrderRequestReadReceipt.fulfilled,
                // eslint-disable-next-line
                (draft, { payload }) => {},
            )
            // eslint-disable-next-line
            .addCase(clearOfflineReportsRequestComments, (draft) => {
                draft.comments = [];
            })
            // eslint-disable-next-line
            .addCase(clearOfflineReportsSubmitStatus, (draft) => {
                draft.submitStatus = null;
            })
            .addCase(setIsSubmitLoadingPopupVisible, (draft, { payload }) => {
                draft.isSubmitLoadingPopupVisible = payload;
            })
            .addCase(clearReportDetails, (draft) => {
                draft.reportDetails = null;
            })
            .addCase(openInsufficientCreditsPopup, (draft) => {
                draft.submitStatus = 'Insufficient';
            })
            .addCase(getOfflineReportsCreditsBalance.fulfilled, (draft, { payload }) => {
                draft.availableCredits = payload?.credit_balance ?? 0;
                draft.accountCreditBalance = payload?.account_credit_balance ?? 0;
                draft.userWeeklyBalance = payload?.user_weekly_balance;
            })
            .addCase(updateClosedReportSharedAndTargetValues, (draft, { payload }) => {
                const indx = draft.closedRequests.findIndex((req) => req.id === payload.id);

                draft.closedRequests[indx].sharedWith = payload.sharedWith;
                draft.closedRequests[indx].target = payload.target as SahringTargetOptions;
            });
    },
});
