import type { RGBColor } from '@deck.gl/core/utils/color';
import type { Bins } from 'utils/bins';

export const BASE_SEGMENT_WIDTH = 2; // 2 pixels
export const SELECTED_SEGMENT_WIDTH = BASE_SEGMENT_WIDTH * 2;
export const SELECTED_SEGMENT_WHITE_WIDTH = BASE_SEGMENT_WIDTH;
export const STROKED_LINE_WIDTH = BASE_SEGMENT_WIDTH * 1.4;
export const SELECTED_STROKED_LINE_WIDTH = STROKED_LINE_WIDTH * 2;

export const HOVER_COLOR: RGBColor = [157, 157, 157];
export const SELECTED_FILL_COLOR: RGBColor = [255, 255, 255];

export enum TrafficYear {
    'v0' = 2020,
    'v1' = 2021,
    'v2' = 2019,
    'v3' = 2022,
    'v4' = 2023,
}

export const NUM_OF_BINS = 4;
export const TOTAL_HISTOGRAM_BINS = 20;

export const INITIAL_BINS_VALUES: Bins = [];
// initialize the INITIAL_BINS_VALUES
for (let i = 0; i < NUM_OF_BINS; i++) {
    INITIAL_BINS_VALUES.push([i, i + 1]);
}
