import React, { createContext, PropsWithChildren, useState } from 'react';
import {
    addCookieValueToObject,
    getCookieValueByName,
    getParsedCookieValue,
    setCookie,
} from 'utils/cookies/utils';
import { getPlacerCurrentDomain } from 'store/auth/utils/utils';

type DismissableProviderProps = {
    dismissId: string;
    children?: React.ReactNode;
};

type DismissableContextType = {
    onDismiss: () => void;
};

const initState = {
    onDismiss: () => {},
};

export const DismissableContext = createContext<DismissableContextType>(initState);

export const COOKIE_KEY = 'dismiss';
export const TEN_YEARS = 60 * 60 * 24 * 365 * 10;

export const DismissableContextProvider = ({
    dismissId,
    children,
}: PropsWithChildren<DismissableProviderProps>) => {
    let dismissCookieValue = getCookieValueByName(COOKIE_KEY);

    let existingIds: string[] = [];
    let isVisible = true;

    if (dismissCookieValue) {
        existingIds = getParsedCookieValue(dismissCookieValue);
        isVisible = !existingIds.includes(dismissId);
    }

    const [visible, setVisible] = useState<boolean>(isVisible);

    const onDismiss = () => {
        setCookie({
            key: COOKIE_KEY,
            value: addCookieValueToObject(existingIds, dismissId),
            maxAge: TEN_YEARS,
            domain: getPlacerCurrentDomain(),
            secure: true,
        });
        setVisible(false);
    };

    if (!visible) return null;

    return (
        <DismissableContext.Provider value={{ onDismiss }}>{children}</DismissableContext.Provider>
    );
};
