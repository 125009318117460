import { createAsyncThunk } from '@reduxjs/toolkit';
import type { Chain } from '@placer-ui/types';
import { chainsAPI } from 'API/chains-api';
import { ChainsReportState } from 'features/chains/store/chains-store';
import { setChainsAutoCompleteSearchResultsAreLoading } from 'features/chains/store/chains-report-search-store';
import { selectIsChainsReportLimited } from 'features/chains/store/chains-report-chains-store';

const CHAINS_SEARCH_LIMIT = 6;

type GetChainsSearchAutoCompleteResultsActionProps = {
    searchTerm: string;
    ignoreLoadingIndication: boolean;
};

const createGetSearchMatchingChains = (chainsApi: typeof chainsAPI) =>
    createAsyncThunk<
        Chain[],
        GetChainsSearchAutoCompleteResultsActionProps,
        { state: ChainsReportState }
    >(
        'chains/getSearchMatchingChains',
        async (
            { searchTerm, ignoreLoadingIndication }: GetChainsSearchAutoCompleteResultsActionProps,
            { dispatch, getState },
        ) => {
            if (!searchTerm) {
                return [];
            }

            if (!ignoreLoadingIndication) {
                dispatch(setChainsAutoCompleteSearchResultsAreLoading(true));
            }
            const state = getState() as ChainsReportState;

            const isLimited = selectIsChainsReportLimited(state);

            const params = {
                term: searchTerm,
                limit: CHAINS_SEARCH_LIMIT,
                purchasedOnly: !isLimited,
            };

            const { data: matchingChains } = await chainsApi.getChainsByTerm(params);

            const mappedMatchingChains = matchingChains.map((chain) => chain.info);

            return mappedMatchingChains;
        },
    );

export const getSearchMatchingChains = createGetSearchMatchingChains(chainsAPI);
