import styles from 'src/shared/styles/responsive/breakpoints.module.scss';

export const screenExtraSmallMax = Number(styles.screenExtraSmallMax);
export const screenSmallMin = Number(styles.screenSmallMin);
export const screenSmallMax = Number(styles.screenSmallMax);
export const screenMediumMin = Number(styles.screenMediumMin);
export const screenMediumMax = Number(styles.screenMediumMax);
export const screenLargeMin = Number(styles.screenLargeMin);
export const screenLargeMax = Number(styles.screenLargeMax);
export const screenExtraLargeMin = Number(styles.screenExtraLargeMin);
export const MOBILE_MAX = Number(styles.mobileScreenMax);
