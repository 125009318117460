import { isEqual } from 'lodash/fp';
import {
    useWidget,
    useWidgetAllToggledEntities,
    useWidgetApiContent,
    useWidgetApis,
    useWidgetBlockingApiIds,
    useWidgetEntireContent,
    useWidgetNonBlockingApiIds,
    useWidgetPOIsList,
} from 'extensions/widget/hooks/use-widget-hooks';
import { ErrorContent, WidgetApiContent } from 'extensions/widget/store/widget-constants';
import { DEFAULT_API_ID } from 'extensions/widget/models/widget-apis';
import { useReportPlaces } from 'extensions/widget/hooks/use-poi-hooks';
import type { Dictionary } from '@placer-ui/types';
import { useCallback } from 'react';

const getMissingContentPoiIds = (
    widgetPoiIds: string[],
    content?: WidgetApiContent,
    combinedContent?: boolean,
) => {
    if (combinedContent) {
        return isEqual(widgetPoiIds, content?.ids) && !content?.dirty ? [] : widgetPoiIds;
    } else {
        return widgetPoiIds.filter((poiId) => !content?.[poiId] || content?.dirty);
    }
};

export const useIsBlockingApisLoading = () => {
    const widgetPOIsList = useWidgetPOIsList();
    const widgetEntireContent = useWidgetEntireContent();
    const widgetApis = useWidgetApis();
    const blockingApis = useWidgetBlockingApiIds();

    const loadingBlockingApi = blockingApis.find((apiId) => {
        if (!widgetEntireContent?.[apiId]) {
            return true;
        }

        const apiContent = widgetEntireContent[apiId];
        const combinedContent = widgetApis?.[apiId]?.combinedContent;
        const missingContentPoiIds = getMissingContentPoiIds(
            widgetPOIsList,
            apiContent,
            combinedContent,
        );

        if (apiContent?.dirty) return false;

        if (combinedContent) {
            const isError = !!Object.values(apiContent).find(
                (content) => (content as ErrorContent)?.isError,
            );

            return isError ? false : isEqual(missingContentPoiIds, widgetPOIsList);
        } else {
            return missingContentPoiIds.length > 0;
        }
    });

    return !!loadingBlockingApi;
};

export const useIsWidgetApiContentLoading = (apiId: string, ignoreMobileLoading = false) => {
    const apis = useWidgetApis();
    const apiContent = useWidgetApiContent(apis ? apiId : DEFAULT_API_ID);
    const combinedContent = apis?.[apiId]?.combinedContent;
    const widgetPOIsList = useWidgetPOIsList();
    const { venueSelector } = useWidget();

    const filteredPOIsList = ignoreMobileLoading
        ? widgetPOIsList.filter((poiId) => venueSelector?.[poiId])
        : widgetPOIsList;

    const missingContentPoiIds = getMissingContentPoiIds(
        filteredPOIsList,
        apiContent,
        combinedContent,
    );

    if (apiContent?.dirty) return false;

    if (combinedContent && apiContent) {
        const isError = !!Object.values(apiContent).find(
            (content) => (content as ErrorContent)?.isError,
        );
        return isError ? false : isEqual(missingContentPoiIds, widgetPOIsList);
    } else {
        return missingContentPoiIds.length > 0;
    }
};

export const useGetWidgetApiMissingContentPoiIds = (apiId: string) => {
    const apis = useWidgetApis();
    const apiContent = useWidgetApiContent(apis ? apiId : DEFAULT_API_ID);
    const combinedContent = apis?.[apiId]?.combinedContent;
    const widgetPOIsList = useWidgetPOIsList();
    const missingContentPoiIds = getMissingContentPoiIds(
        widgetPOIsList,
        apiContent,
        combinedContent,
    );

    return missingContentPoiIds;
};

export const useIsWidgetInitializationLoading = () => {
    const apis = useWidgetApis();
    const widgetEntireContent = useWidgetEntireContent();
    const widgetPOIsList = useWidgetPOIsList();
    const blockingApis = useWidgetBlockingApiIds();
    const nonBlockingApisConfig = useWidgetNonBlockingApiIds();

    // boolean var to check if there are non blocking API for the widget
    const isWidgetNonBlockingApiOnly = !blockingApis.length;

    const blockingApiContentMissing = blockingApis.some((apiId) => {
        // checking if there is content for this api
        const apiContent = widgetEntireContent?.[apiId];
        if (!apiContent) {
            return true;
        }

        if (apis?.[apiId]?.combinedContent) {
            return (
                apiContent.common === undefined &&
                Object.values(apiContent).every((content) => !content?.isError)
            );
        }

        return widgetPOIsList.some((poiId) => apiContent[poiId] === undefined);
    });

    // check for at least one API that have data for the some POIs
    const nonBlockingApiContentMissing =
        isWidgetNonBlockingApiOnly &&
        !nonBlockingApisConfig.some((apiId) => {
            // checking if there is content for this api
            const apiContent = widgetEntireContent?.[apiId];

            if (apiContent && apis?.[apiId]?.combinedContent) {
                /*
                 check if theres a single API content with data or error to set the loading state to false
                 */
                return (
                    apiContent.common !== undefined ||
                    Object.values(apiContent).some((content) => content?.isError)
                );
            }

            // check for at least one POI that have data for the API
            return widgetPOIsList.some((poiId) => apiContent?.[poiId] !== undefined);
        });

    return !widgetEntireContent || blockingApiContentMissing || nonBlockingApiContentMissing;
};

export const useWidgetLoadingPOIsPerAPIs = () => {
    const { all, entitiesWidgetShape } = useReportPlaces();
    const apis = useWidgetApis();
    const content = useWidgetEntireContent();
    const toggledPois = useWidgetAllToggledEntities();

    return apis
        ? Object.entries(apis).reduce<Dictionary<string[]>>(
              (loadingAPIs, [apiID, { shouldCallApi }]) => {
                  const loadingPOIs: string[] = [];
                  all.filter((poi) =>
                      toggledPois.map((togglePoi) => togglePoi.uid).includes(poi.uid),
                  ).forEach((poi) => {
                      const { uid } = poi;
                      const widgetPoi = entitiesWidgetShape[uid];
                      const poiContent = content?.[apiID]?.[uid];

                      if (
                          (!poiContent || content?.[apiID]?.dirty) &&
                          (!shouldCallApi || shouldCallApi?.(widgetPoi))
                      ) {
                          loadingPOIs.push(uid);
                      }
                  });

                  loadingAPIs[apiID] = loadingPOIs;
                  return loadingAPIs;
              },
              {},
          )
        : {};
};

export const useGetIsLoadingByPOIAndAPIKey = () => {
    const loadingPOIsPerAPI = useWidgetLoadingPOIsPerAPIs();

    return useCallback(
        (apiID: string, poiID: string) => loadingPOIsPerAPI[apiID]?.includes(poiID),
        [loadingPOIsPerAPI],
    );
};

/** @deprecated - use the {@link useIsBlockingApisLoading} hook */
export const useIsWidgetContentLoading = useIsBlockingApisLoading;
