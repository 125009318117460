import { RankingResultsResponse } from 'features/void-analysis/common/types/server-responses';
import { VoidAnalysisRankings } from 'features/void-analysis/common/types/entities';

export const rankingResultsToVoidAnalysisRankings = (
    results: RankingResultsResponse,
): VoidAnalysisRankings => {
    return {
        filters: results.filters,
        records: results.records,
        totalRecords: results.records_count,
        scoresBounds: results.scores_bounds,
        matchScoreCalculation: results.match_score_calculation,
    };
};
