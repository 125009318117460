import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

import { IconProps } from 'components/assets/Icons/Icons';

/* eslint-disable max-len */
const GisIconSvg = (props: CustomIconComponentProps | SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 16 19">
        <g fill="none" fillRule="evenodd">
            <path
                fill="currentColor"
                d="M8 18.625c-4.493 0-7.625-1.513-7.625-2.87 0-1.359 3.132-2.87 7.625-2.87s7.625 1.511 7.625 2.87c0 1.357-3.132 2.87-7.625 2.87"
            />
            <path
                fill="currentColor"
                stroke="#49565D"
                strokeWidth=".2"
                d="M8 12.509c-4.419 0-8 1.453-8 3.245C0 17.547 3.581 19 8 19c4.418 0 8-1.453 8-3.246 0-1.792-3.582-3.245-8-3.245m0 .75c4.426 0 7.25 1.478 7.25 2.495 0 1.018-2.824 2.496-7.25 2.496S.75 16.772.75 15.754c0-1.017 2.824-2.495 7.25-2.495"
            />
            <path
                fill="#FFF"
                d="M8.013.375a6.27 6.27 0 0 1 6.27 6.27c0 1.617-.547 2.935-1.623 4.198C11.586 12.104 8 15.25 8 15.25s-3.586-3.146-4.661-4.407C2.265 9.58 1.717 8.263 1.717 6.645a6.27 6.27 0 0 1 6.27-6.27h.026z"
            />
            <path
                stroke="#49565D"
                strokeLinecap="round"
                strokeWidth=".75"
                d="M8.013.375a6.27 6.27 0 0 1 6.27 6.27c0 1.617-.547 2.935-1.623 4.198C11.586 12.104 8 15.25 8 15.25s-3.586-3.146-4.661-4.407C2.265 9.58 1.717 8.263 1.717 6.645a6.27 6.27 0 0 1 6.27-6.27h.026z"
            />
            <path
                stroke="#49565D"
                strokeLinecap="round"
                strokeWidth=".75"
                d="M10.392 6.56a2.393 2.393 0 1 1-4.786 0 2.393 2.393 0 0 1 4.786 0z"
            />
        </g>
    </svg>
);

export const GisIcon = (props: IconProps) => <Icon component={GisIconSvg} {...props} />;
