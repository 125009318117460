import moment from 'moment';
import { dateFormats } from 'utils/date/date';
import { FileTitleFormatter } from 'utils/file-title-formatter/use-file-title';

/**
 * Creates a default file title formatter based on the report date range and places
 * @param {string} title - an optional title to prepend to the file title
 * @param {boolean} withDates - whether to include the date range in the file title, will hide
 * if multiple dates are selected
 * @param dateFormat - the date format to use
 * @returns {FileTitleFormatter} - a function that returns a file title
 * @example
 * const fileTitle = useFileTitle(defaultFileTitleFormatter('My title'));
 * // will result in "My title - POI 1, POI 2, and 2 more - Dec 20, 2020 - Dec 21, 2021"
 */
export const defaultFileTitleFormatter =
    (
        title?: string,
        withDates: boolean = true,
        dateFormat = dateFormats.monthDayYearComma,
    ): FileTitleFormatter =>
    ({ reportEntities, start_date, end_date }) => {
        const entityNames = reportEntities.map(({ name }) => name);

        let filename = '';

        if (title) {
            filename = title + ' - ';
        }

        if (entityNames.length <= 2) {
            filename += entityNames.join(', ');
        } else {
            filename += entityNames.slice(0, 2).join(', ') + ` and ${entityNames.length - 2} more`;
        }

        if (withDates) {
            if (start_date && end_date) {
                const startDate = moment(start_date).format(dateFormat);
                const endDate = moment(end_date).format(dateFormat);
                filename += ' - ' + startDate + ' - ' + endDate;
            }
        }

        return filename;
    };
