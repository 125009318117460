const valueToHex = (val: number) => val.toString(16);

export const rgbArrayToHex = (rgb: number[]) => {
    return `#${valueToHex(rgb[0])}${valueToHex(rgb[1])}${valueToHex(rgb[2])}`;
};

export const rgbaToHex = (rgba: [number, number, number, number]) => {
    const [red, green, blue, alpha] = rgba;

    const redHex = red.toString(16).padStart(2, '0');
    const greenHex = green.toString(16).padStart(2, '0');
    const blueHex = blue.toString(16).padStart(2, '0');
    const alphaHex = Math.round(alpha).toString(16).padStart(2, '0');

    return `#${redHex}${greenHex}${blueHex}${alphaHex}`;
};
export const hexAlphaToRgba = (hexCode: string): number[] => {
    const red = parseInt(hexCode.slice(1, 3), 16);
    const green = parseInt(hexCode.slice(3, 5), 16);
    const blue = parseInt(hexCode.slice(5, 7), 16);
    const alpha = isNaN(parseInt(hexCode.slice(7, 9), 16))
        ? 255
        : parseInt(hexCode.slice(7, 9), 16);

    return [red, green, blue, alpha];
};
