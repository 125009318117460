import { APP_CONSTS } from 'core/constants/app-consts';
import { PoiDatesRecord } from 'features/insights/types/dates-types';
import moment, { Moment } from 'moment';
import { createMonthPresets, createQuarterPresets, createYearsPresets } from 'ui-components/date-picker/date-hooks/use-fixed-preset';
import { createFullPeriodPresets, createPassPeriodPresets, createToMaxDatePresets } from 'ui-components/date-picker/date-hooks/use-relative-preset';

type RawDate = {
    label: string;
    value: [Moment, Moment];
}

export const buildDates = (configuration: any, permissions: any) => {
    if (!configuration) return;

    function _createMomentInstance(date: any) {
        if (Object.keys(date).length > 0) {
            return date.clone();
        }
        return moment(date);
    }
    function _buildFormattedMonth(date: any) {
        const formattedDate = _createMomentInstance(date).format(
            'MMMM YYYY',
        );

        return formattedDate;
    }
    let maxDataDate = moment(APP_CONSTS.configuration.DEFAULT_VIEW_DATE); //const, only getter
    let maxDataMonth = moment(APP_CONSTS.configuration.DEFAULT_VIEW_DATE); //const, only getter
    const dates: PoiDatesRecord = {};
    const countOfMonths = 2;
    const dashBoardMaxDate = configuration.dashboard_max_date;
    const analyticsMaxDate = configuration.analytics_max_date;

    maxDataDate = moment(dashBoardMaxDate || analyticsMaxDate || maxDataDate);

    const dashboardMaxMonth = configuration.dashboard_max_month;
    const analyticsMaxMonth = configuration.analytics_max_month;

    maxDataMonth = moment(dashboardMaxMonth || analyticsMaxMonth || maxDataMonth);

    if (permissions.show_qa_features) {
        const qaMaxDate = configuration.qa_max_date;
        maxDataDate = moment(qaMaxDate || maxDataDate);

        const qaMaxMonth = configuration.qa_max_month;
        maxDataMonth = moment(qaMaxMonth || maxDataMonth);
    }

    var startDate = maxDataMonth.clone();
    const dateMap = configuration.cache_dates;

    dates[APP_CONSTS.date.keys.YEAR_TO_DATE] = {
        key: APP_CONSTS.date.keys.YEAR_TO_DATE,
        name: APP_CONSTS.date.labels.YEAR_TO_DATE,
        end:
            dateMap?.year?.end ??
            moment(configuration.analytics_max_date).format(
                APP_CONSTS.date.format.FULL,
            ),
        start:
            dateMap?.year?.start ??
            moment(configuration.analytics_max_date)
                .startOf('year')
                .format(APP_CONSTS.date.format.FULL),
    };

    dates[APP_CONSTS.date.keys.LAST_3_MONTHS] = {
        key: APP_CONSTS.date.keys.LAST_3_MONTHS,
        name: APP_CONSTS.date.labels.LAST_3_MONTHS,
        chosenLabel: APP_CONSTS.date.labels.LAST_3_MONTHS,
        end:
            dateMap?.['last-3-months']?.end ??
            startDate.endOf('month').format(APP_CONSTS.date.format.FULL),
        start:
            dateMap?.['last-3-months']?.start ??
            startDate
                .startOf('month')
                .clone()
                .subtract(2, 'months')
                .format(APP_CONSTS.date.format.FULL),
    };

    dates[APP_CONSTS.date.keys.LAST_6_MONTHS] = {
        key: APP_CONSTS.date.keys.LAST_6_MONTHS,
        name: APP_CONSTS.date.labels.LAST_6_MONTHS,
        chosenLabel: APP_CONSTS.date.labels.LAST_6_MONTHS,
        end:
            dateMap?.['last-6-months']?.end ??
            startDate.endOf('month').format(APP_CONSTS.date.format.FULL),
        start:
            dateMap?.['last-6-months']?.start ??
            startDate
                .startOf('month')
                .clone()
                .subtract(5, 'months')
                .format(APP_CONSTS.date.format.FULL),
    };

    if (permissions.enable_last_year_report) {
        dates[APP_CONSTS.date.keys.LAST_12_MONTHS] = {
            key: APP_CONSTS.date.keys.LAST_12_MONTHS,
            name: APP_CONSTS.date.labels.LAST_12_MONTHS,
            chosenLabel: APP_CONSTS.date.labels.LAST_12_MONTHS,
            end:
                dateMap?.['last-12-months']?.end ??
                startDate.endOf('month').format(APP_CONSTS.date.format.FULL),
            start:
                dateMap?.['last-12-months']?.start ??
                startDate
                    .startOf('month')
                    .clone()
                    .subtract(11, 'months')
                    .format(APP_CONSTS.date.format.FULL),
        };
    }

    configuration.dashboard_freemium_month ? moment(configuration.dashboard_freemium_month) : maxDataMonth.clone();
    // the freemium month
    const freemiumMonth = configuration.dashboard_freemium_month ?
     moment(configuration.dashboard_freemium_month) : maxDataMonth.clone();
    const freemiumName = _buildFormattedMonth(freemiumMonth);
    dates[freemiumMonth.format(APP_CONSTS.date.format.FULL)] = {
        key: freemiumMonth.format(APP_CONSTS.date.format.FULL),
        name: freemiumName,
        end: freemiumMonth.endOf('month').format(APP_CONSTS.date.format.FULL),
        start: freemiumMonth.startOf('month').format(APP_CONSTS.date.format.FULL),
        isFreemium: true,
    };

    for (var i = 0; i < countOfMonths; ++i) {
        const name = _buildFormattedMonth(startDate);

        dates[startDate.format(APP_CONSTS.date.format.FULL)] = {
            key: startDate.format(APP_CONSTS.date.format.FULL),
            name,
            end: startDate.endOf('month').format(APP_CONSTS.date.format.FULL),
            start: startDate.startOf('month').format(APP_CONSTS.date.format.FULL),
        };

        startDate = startDate.subtract(1, 'months');
    }

    return {
        dates,
        maxDataDate,
        maxDataMonth,
    };
};

export const buildFullDates = (maxMonthStr: string, maxDateStr: string) => {
    const maxDate = moment(maxDateStr, 'YYYY-MM-DD');
    const maxMonth = moment(maxMonthStr, 'YYYY-MM-DD');
    const minDate = moment('2017-01-01', 'YYYY-MM-DD');

    const dates = formatDateItem({
        ...createMonthPresets(maxMonth),
        ...createQuarterPresets(maxMonth),
        ...createYearsPresets(maxMonth, minDate),
        ...createToMaxDatePresets(maxDate),
        ...createFullPeriodPresets(maxDate),
        ...createPassPeriodPresets(maxDate),
    });
    return {
        dates,
        maxDataDate: maxDate,
        maxDataMonth: maxMonth,
    };
};

const formatDateItem = (rawDates: Record<string, RawDate>) => {
    const dates: PoiDatesRecord = {};

    Object.entries(rawDates).forEach(([date, dateData]) => {
        const key = date.replaceAll(' ', '_').toLocaleLowerCase();

        dates[key] = {
            key,
            name: date,
            chosenLabel: date,
            end: moment(dateData.value[1]).format('YYYY-MM-DD'),
            start: moment(dateData.value[0]).format('YYYY-MM-DD'),
        };

    });

    return dates;
};