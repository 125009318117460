import React from 'react';
import { useSelector } from 'react-redux';

import styles from './settings-menu.module.scss';
import { userSelector } from 'store/selectors/app-selectors';
import { PlacerLink } from 'ui-components/placer-link/placer-link';
import { useSegmentCallback } from 'hooks/use-segment-callback/use-segment-callback';
import { useFeatureFlags } from 'hooks/feature-flags/use-feature-flags';
import { usePermission } from 'hooks/use-permission/use-permission';
import { SquareLogo } from 'ui-components/square-logo/square-logo';
import { BuildingIcon, LogoutIcon, SettingsIcon, LoginAsIcon } from 'components/assets/Icons/Icons';
import { impersonateUserCookieStorage } from 'core/authentification';
import { useChangeUserInLoginasMode } from 'features/header/header-top-panel/user-section/user-loginas-dialog/utils';
import { useAuthActions } from 'store/auth/use-auth-actions';

const PLACER_PRIVCAY_POLICY_LINK =
    'https://www.placer.ai/privacy-policy/platform-services-privacy-policy';

type UserSettingsMenuProps = {
    setImpersonateModalOpen: (value: boolean) => void;
    setExitImpersonateModeModalOpen: (value: boolean) => void;
};
export const UserSettingsMenu = ({
    setImpersonateModalOpen,
    setExitImpersonateModeModalOpen,
}: UserSettingsMenuProps) => {
    const { enable_impersonate } = useFeatureFlags();
    const changeUserInLoginasMode = useChangeUserInLoginasMode();
    const { logout } = useAuthActions();
    const { track } = useSegmentCallback();
    const user = useSelector(userSelector);
    const { enable_privacy_link_in_account_window_ff } = useFeatureFlags();
    const hasSettings = usePermission('enable_settings');
    const originalUser = impersonateUserCookieStorage.user;

    // if is an imporsonated user already then it means client has permissions to further impersonate
    const canImpersonate = enable_impersonate || originalUser;

    const onLogOutClicked = () => {
        track({
            category: 'User',
            action: 'Signed Out',
        });
        logout();
    };

    const exitLoginasMode = () => {
        setExitImpersonateModeModalOpen(true);
        if (originalUser) {
            changeUserInLoginasMode(originalUser, true);
        }
    };

    const userName = !!user?.fullName && <div data-testid="user-fullname">{user.fullName}</div>;
    const userEmail = !!user?.email && <div data-testid="user-email">{user.email}</div>;
    const userAccount = !!user?.settings?.account?.name && (
        <div className={styles.userAccountName} data-testid="user-account">
            {user.settings.account.name}
        </div>
    );
    const originalUsername = !!originalUser && (
        <div className={styles.originalUserEmail} data-testid="user-originalemail">
            ({originalUser.email})
        </div>
    );

    if (enable_privacy_link_in_account_window_ff) {
        return (
            <div
                className={styles.userAccountDropdownContainer}
                data-testid="user-settings-account-dropdown"
            >
                <div className={styles.userInfoContainer}>
                    <div className={styles.userAccountIcon}>
                        {user?.settings?.account?.logo_img ? (
                            <SquareLogo
                                className={styles.accountLogo}
                                name={user?.settings?.account?.name}
                                logoUrl={user?.settings?.account?.logo_img}
                            />
                        ) : (
                            <span className={styles.iconWrapper}>
                                <BuildingIcon />
                            </span>
                        )}
                    </div>
                    <div className={styles.userInfoDetailsContainer}>
                        {userName}
                        {userEmail}
                        {userAccount}
                        {originalUsername}
                    </div>
                </div>
                <ul className={styles.listWrapper}>
                    {hasSettings && (
                        <li className={styles.listItem}>
                            <PlacerLink
                                to={{ pathname: '/settings/' }}
                                className={styles.itemWrapper}
                            >
                                <span>
                                    <SettingsIcon colorBottom="var(--color-bottom)" />
                                </span>
                                Account & User Settings
                            </PlacerLink>
                        </li>
                    )}
                    <li className={styles.listItem}>
                        <span className={styles.itemWrapper} onClick={onLogOutClicked}>
                            <span>
                                <LogoutIcon />
                            </span>
                            Log Out
                        </span>
                    </li>
                    {!!originalUser && (
                        <li className={styles.listItem}>
                            <span
                                className={styles.itemWrapper}
                                data-testid="user-exit-login-as-button"
                                onClick={exitLoginasMode}
                            >
                                <span>
                                    <LogoutIcon />
                                </span>
                                Exit 'Login As'
                            </span>
                        </li>
                    )}
                    {canImpersonate && (
                        <li className={styles.listItem}>
                            <span
                                className={styles.itemWrapper}
                                data-testid="user-login-as-button"
                                onClick={() => setImpersonateModalOpen(true)}
                            >
                                <span>
                                    <LoginAsIcon />
                                </span>
                                Login As...
                            </span>
                        </li>
                    )}
                </ul>
                <div className={styles.userAccountDropdownFooter}>
                    <a href={PLACER_PRIVCAY_POLICY_LINK} target="_blank" rel="noreferrer">
                        Privacy Policy
                    </a>
                </div>
            </div>
        );
    }
    return (
        <ul className={styles.listWrapper} data-testid="settings-dropdown">
            {hasSettings && (
                <li className={styles.listItem}>
                    <PlacerLink
                        to={{ pathname: '/#!/admin/settings/' }}
                        className={styles.itemWrapper}
                    >
                        Settings
                    </PlacerLink>
                </li>
            )}
            <li className={styles.listItem}>
                <span className={styles.itemWrapper} onClick={onLogOutClicked}>
                    Logout
                </span>
            </li>
        </ul>
    );
};
