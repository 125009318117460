import Icon from '@ant-design/icons';
import React, { SVGProps } from 'react';
import { IconProps } from 'components/assets/Icons/Icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

const CloseIconSvg = (props: CustomIconComponentProps | SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 14 14">
        <g
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth="1.5"
        >
            <path d="M11.662 11.662L0 0M0 11.662L11.662 0" transform="translate(1 1)" />
        </g>
    </svg>
);

export const CloseIcon = (props: IconProps) => <Icon component={CloseIconSvg} {...props} />;
