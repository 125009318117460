export enum ERROR_NAMES {
    Error = 'Error',
    EvalError = 'EvalError',
    RangeError = 'RangeError',
    ReferenceError = 'ReferenceError',
    SyntaxError = 'SyntaxError',
    TypeError = 'TypeError',
    URIError = 'URIError',
    AggregateError = 'AggregateError',
    PlacerError = 'PlacerError',
}

/** top menu items */
export enum ERROR_PAGES {
    myZone = 'My Zone',
    explore = 'Explore',
    property = 'Property',
    chains = 'Chains',
    industry = 'Industry',
    advancedReports = 'Advanced Reports',
    createPoi = 'Create POI',
}
/** side menu items */
export enum ERROR_TABS {
    customReport = 'Custom Report',
    overview = 'Overview',
    propertyInfo = 'Property Info',
    visits = 'Visits',
    retailSales = 'Retail Sales',
    variance = 'Variance',
    visitorsJourney = 'Visitors Journey',
    demographics = 'Demographics',
    tradeArea = 'Trade Area',
    areaAnalysis = 'Area Analysis',
    ranking = 'Ranking',
    loyalty = 'Loyalty',
    infographics = 'Infographics',
    reports = 'arcGisReports',
    visualTemplates = 'Visual Templates',
    portfolio = 'Portfolio',
    xtra = 'XTRA',
    purchasedPois = 'Purchased POIs',
    createdPois = 'Created POIs',
    tags = 'Tags',
}
