import { createAction } from '@reduxjs/toolkit';

import { ChainEntityModel } from 'core/entities';

const setChainsSearchSearchOpenStatusAction = 'chains/set-search-open-status';
export const setChainsSearchSearchOpenStatus = createAction<boolean>(
    setChainsSearchSearchOpenStatusAction,
);

const setChainsSearchSelectedCategoriesAction =
    'chains/set-search-selected-category';
export const setChainsSearchSelectedCategories = createAction<string[]>(
    setChainsSearchSelectedCategoriesAction,
);

const setChainsSearchSelectedRegionAction = 'chains/set-search-selected-region';
export const setChainsSearchSelectedRegion = createAction<string>(
    setChainsSearchSelectedRegionAction,
);

const setChainsSearchResultsAction = 'chains/set-search-results';
export const setChainsSearchResults = createAction<ChainEntityModel[]>(
    setChainsSearchResultsAction,
);

const setChainsSearchAutoCompleteValueAction =
    'chains/set-search-autocomplete-value';
export const setChainsSearchAutoCompleteValue = createAction<string>(
    setChainsSearchAutoCompleteValueAction,
);

const setChainsSearchMyTagsQueryValueAction =
    'chains/set-search-my-tags-query-value';
export const setChainsSearchMyTagsQueryValue = createAction<string>(
    setChainsSearchMyTagsQueryValueAction,
);

const setChainsSearchCompanyTagsQueryValueAction =
    'chains/set-search-company-tags-query-value';
export const setChainsSearchCompanyTagsQueryValue = createAction<string>(
    setChainsSearchCompanyTagsQueryValueAction,
);

const setMyTagsSearchResultsPotentiallyMoreResultsExistAction =
    'chains/set-my-tags-results-has-more';
export const setMyTagsSearchResultsPotentiallyMoreResultsExist = createAction<
    boolean
>(setMyTagsSearchResultsPotentiallyMoreResultsExistAction);

const setCompanyTagsSearchResultsPotentiallyMoreResultsExistAction =
    'chains/set-company-tags-results-has-more';
export const setCompanyTagsSearchResultsPotentiallyMoreResultsExist = createAction<
    boolean
>(setCompanyTagsSearchResultsPotentiallyMoreResultsExistAction);

const setChainsSearchResultsPotentiallyMoreResultsExistAction =
    'chains/set-search-results-has-more';
export const setChainsSearchResultsPotentiallyMoreResultsExist = createAction<
    boolean
>(setChainsSearchResultsPotentiallyMoreResultsExistAction);

const setChainsSearchResultsAreLoadingAction =
    'chains/set-search-results-are-loading';
export const setChainsSearchResultsAreLoading = createAction<boolean>(
    setChainsSearchResultsAreLoadingAction,
);

const setChainsAutoCompleteSearchResultsAreLoadingAction =
    'chains/set-autocomplete-search-results-are-loading';
export const setChainsAutoCompleteSearchResultsAreLoading = createAction<
    boolean
>(setChainsAutoCompleteSearchResultsAreLoadingAction);

const setSelectedChainsEntityModelsAction =
    'chains/set-selected-chains-entity-models';
export const setSelectedChainsEntityModels = createAction<ChainEntityModel[]>(
    setSelectedChainsEntityModelsAction,
);

const resetActiveChainAction = 'chains/reset-active-chain';
export const resetActiveChain = createAction(resetActiveChainAction);

const setDeselectedChainsAction = 'chains/set-deselected-chains';
export const setDeselectedChainsState = createAction<ChainEntityModel[]>(
    setDeselectedChainsAction,
);
