import { prefetchLazyWithReload } from 'router/utils/lazy-with-reload/lazy-with-reload';

export const DesktopAcademyPage = prefetchLazyWithReload(
    () => import('features/academy/academy-page'),
);

export const DesktopAdvancedMapsPage = prefetchLazyWithReload(
    () => import('features/advanced-maps/advanced-maps-map-page'),
);

export const DesktopMarketsSectionPage = prefetchLazyWithReload(
    () => import('features/markets-section/markets-section-page'),
);

export const DesktopGISPage = prefetchLazyWithReload(() => import('features/gis/gis-page'));

export const DesktopAdvancedReportsPage = prefetchLazyWithReload(
    () => import('features/advanced-reports/advanced-reports-page'),
);

export const DesktopChainsPage = prefetchLazyWithReload(
    () => import('features/chains/chains-page'),
);

export const DesktopCustomPOIPage = prefetchLazyWithReload(
    () => import('features/create-poi/create-poi-page'),
);

export const DesktopExplorePage = prefetchLazyWithReload(
    () => import('features/explore/explore-page'),
);

export const DesktopExportPage = prefetchLazyWithReload(
    () => import('features/export/export-page-route'),
);

export const DesktopExportWidgetPage = prefetchLazyWithReload(
    () => import('features/export/export-route'),
);

export const DesktopIndustryPage = prefetchLazyWithReload(
    () => import('features/industry/industry-page'),
);

export const DesktopInsightsPage = prefetchLazyWithReload(
    () => import('features/insights/insights-page'),
);

export const DesktopMyZonePage = prefetchLazyWithReload(
    () => import('features/my-zone/my-zone-page'),
);

export const DesktopSettingsPage = prefetchLazyWithReload(
    () => import('features/settings/settings-page'),
);

export const DesktopSynapsePage = prefetchLazyWithReload(
    () => import('features/synapse/synapse-page'),
);

export const DesktopTrendsPage = prefetchLazyWithReload(() => import('features/trends/trends'));

export const DesktopVoidAnalysisPage = prefetchLazyWithReload(
    () => import('features/void-analysis/void-analysis-page'),
);

export const BrowserSupportPage = prefetchLazyWithReload(
    () => import('features/browser-support/browser-support'),
);
