import queryString from 'query-string';
import type {
    Chain,
    PlacerArrayResponse,
    PlacerEntityWrapper,
    BaseUserAuth,
} from '@placer-ui/types';
import { fetchWithBasePath, fetchWrapper, FetchProps } from 'API/authentification/fetch-wrapper';
import { UserConfigSettingsResponse, UserSettingsResponse } from 'core/entities/user/user-settings';
import type {
    UserCustomSettings,
    UserCustomSettingsResponse,
} from 'core/entities/user/user-custom-settings';
// eslint-disable-next-line max-len
import { TagsSectionNameType } from 'features/chains/regular-view/components/search/components/common/tags-section-body/tags-section-body';
import type {
    LoginParameters,
    SignupParameters,
    PlatformSignInParameters,
    EmailDataFormParameters,
    SsoLandingPageParameters,
    SsoParameters,
    ImpersonateUserLoginParameters,
    AccountActivationParameters,
    ResetPasswordTokenCheckParameters,
    SetNewPasswordParameters,
} from 'store/auth/actions/auth-actions/auth-action-types';
import { UserLoginAsType } from 'features/header/header-top-panel/user-section/user-loginas-dialog/user-loginas';
import { impersonateUserCookieStorage } from 'core/authentification';
import { getAppApiEndpoint } from 'core/default-endpoint-url';
import { getAppGatewayEndpoint } from 'core/default-endpoint-url/default-endpoint-url';

export type GetChainsSearchTagsProps = {
    searchQuery?: string;
    sectionType?: TagsSectionNameType;
    skip?: number;
};

type ImpersonateUserResponse = Omit<BaseUserAuth, 'email' | 'fullName'> & {
    email?: string;
    full_name: string;
};

export const TAGS_LIMIT = 12;
const { REACT_APP_LOGIN_DOMAIN, REACT_APP_SIGNIN_AS_API_ENDPOINT } = process.env;
const loginBaseUrl = `${REACT_APP_LOGIN_DOMAIN}`;
const loginAsBaseUrl = `${REACT_APP_SIGNIN_AS_API_ENDPOINT}`;

const basicTagsQueryParams = {
    limit: TAGS_LIMIT,
};

export const userAPI = {
    getChainsSearchTags({
        searchQuery,
        skip,
        sectionType,
    }: GetChainsSearchTagsProps): PlacerArrayResponse<PlacerEntityWrapper<Chain>[]> {
        const url = `/tags?${queryString.stringify(
            {
                ...basicTagsQueryParams,
                term: searchQuery,
                skip: skip,
                filter_mode: sectionType === 'my-tags' ? 'my' : 'company',
            },
            {
                skipEmptyString: true,
            },
        )}`;

        return fetchWrapper({
            targetUrl: url,
            basePath: `${process.env.REACT_APP_CHAINS_SERVICE_PATH}`,
            endpoint: getAppGatewayEndpoint(),
        });
    },
    getSettings(): Promise<UserSettingsResponse> {
        const url = '/user/settings';

        return fetchWrapper({ targetUrl: url });
    },
    getCustomSettings(token?: string): Promise<UserCustomSettingsResponse> {
        const url = '/user/custom_settings';
        const requestParams: FetchProps<{}> = {
            targetUrl: url,
        };

        if (token) requestParams['customToken'] = token;

        return fetchWrapper(requestParams);
    },
    setUserCustomSettings(customSettings: UserCustomSettings, token?: string) {
        const url = '/user/custom_settings';
        const method = 'POST';

        const requestParams: FetchProps<{}> = {
            targetUrl: url,
            body: customSettings,
            method,
        };

        if (token) requestParams['customToken'] = token;

        return fetchWrapper(requestParams);
    },
    getSettingsConfig(): Promise<UserConfigSettingsResponse> {
        const url = '/user/config';

        return fetchWrapper({ targetUrl: url });
    },
    logout() {
        return fetchWrapper({
            targetUrl: '/logout/',
            method: 'POST',
            basePath: '/2/dashboard',
        });
    },
    login(userData: LoginParameters) {
        const url = '/login/';
        const basePath = '/2/dashboard';
        const options = {
            method: 'POST',
            body: JSON.stringify(userData),
        };

        return fetchWithBasePath({
            passedURL: url,
            options,
            basePath,
            isTokenRequired: false,
        })
            .then((response) => response)
            .catch((error) => error);
    },
    signup(userData: SignupParameters) {
        const url = '/register/';
        const basePath = '/2/dashboard';
        const options = {
            method: 'POST',
            body: JSON.stringify(userData),
        };

        return fetchWithBasePath({
            passedURL: url,
            options,
            basePath,
            isTokenRequired: false,
        })
            .then((response) => response)
            .catch((error) => error);
    },
    async updateCompanyName(companyName: string) {
        const url = '/user/update_user_data';
        const basePath = '/2/dashboard';

        const options = {
            method: 'POST',
            body: JSON.stringify({
                company: companyName,
            }),
        };

        return fetchWithBasePath({
            passedURL: url,
            options,
            basePath,
        })
            .then((response) => response)
            .catch((error) => error);
    },
    sendForgotPasswordEmail(userData: EmailDataFormParameters) {
        const url = '/reset_password/';
        const basePath = '/2/dashboard';
        const options = {
            method: 'POST',
            body: JSON.stringify(userData),
        };
        return fetchWithBasePath({
            passedURL: url,
            options,
            basePath,
            isTokenRequired: false,
        });
    },
    checkResetPasswordToken(userData: ResetPasswordTokenCheckParameters) {
        const url = '/reset_password_token/status';
        const basePath = '/2/dashboard';
        const options = {
            method: 'POST',
            body: JSON.stringify(userData),
        };

        return fetchWithBasePath({
            passedURL: url,
            options,
            basePath,
            isTokenRequired: false,
        })
            .then((response) => response)
            .catch((error) => error);
    },
    setNewPassword(userData: SetNewPasswordParameters) {
        const url = '/set_new_password';
        const basePath = '/2/dashboard';
        const options = {
            method: 'POST',
            body: JSON.stringify(userData),
        };

        return fetchWithBasePath({
            passedURL: url,
            options,
            basePath,
            isTokenRequired: false,
        })
            .then((response) => response)
            .catch((error) => error);
    },
    sendSsoEmail(userData: SsoParameters) {
        const url = `/saml/sso/saml_req?email=${encodeURIComponent(
            userData.email,
        )}&next_url=${encodeURIComponent(userData.next_url)}`;
        const options = {
            method: 'GET',
        };

        return fetchWithBasePath({
            passedURL: url,
            options,
            isTokenRequired: false,
            baseUrl: loginBaseUrl,
        });
    },
    getAnonymousUserPermissions() {
        const url = '/public-feature-flags';
        const options = {
            method: 'GET',
        };

        return fetchWithBasePath({
            passedURL: url,
            options,
            isTokenRequired: false,
        });
    },
    sendSsoToken(token: SsoLandingPageParameters) {
        const url = '/saml/sso/complete_login';

        const options = {
            method: 'POST',
            body: JSON.stringify(token),
        };

        return fetchWithBasePath({
            passedURL: url,
            options,
            isTokenRequired: false,
            baseUrl: loginBaseUrl,
        });
    },
    platformSignIn(userData: PlatformSignInParameters) {
        const url = `/${userData.platform}/login/`;
        const basePath = '/2/dashboard';
        const options = {
            method: 'POST',
            body: JSON.stringify(userData),
        };

        return fetchWithBasePath({
            passedURL: url,
            options,
            basePath,
            isTokenRequired: false,
        })
            .then((response) => response)
            .catch((error) => error);
    },

    getImpersonateUsers() {
        const url = '/admin/impersonate/users';

        return fetchWrapper<PlacerArrayResponse<UserLoginAsType[]>>({
            basePath: '',
            targetUrl: url,
            endpoint: loginAsBaseUrl,
            // if is impersonating user then use the original user token,
            // because this is the one that has permissions to impersonate.
            customToken: impersonateUserCookieStorage.token
                ? impersonateUserCookieStorage.token
                : undefined,
        });
    },

    loginImpersonateUsers(userData: ImpersonateUserLoginParameters) {
        const url = '/admin/impersonate/login';

        return fetchWrapper<PlacerArrayResponse<ImpersonateUserResponse>>({
            basePath: '',
            targetUrl: url,
            endpoint: loginAsBaseUrl,
            method: 'POST',
            body: userData,
            customToken: impersonateUserCookieStorage.token
                ? impersonateUserCookieStorage.token
                : undefined,
        });
    },

    confirmEmailActivationCode(activationData: AccountActivationParameters) {
        const reactAppApiEndpoint = getAppApiEndpoint();
        const { userName, activationCode } = activationData;
        const targetUrl = `${reactAppApiEndpoint}/2/dashboard/activate/`;
        const fetchConfig: Partial<RequestInit> = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username: userName,
                activation_code: activationCode,
            }),
        };

        return fetch(targetUrl, fetchConfig);
    },
};
