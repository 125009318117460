import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import styles from './expandable-table.module.scss';

import { ExpandableConfig } from 'rc-table/lib/interface';
import { Key } from 'antd/lib/table/interface';
import { SelectArrowIcon } from 'components/assets/Icons/Icons';
import { DataTable, TableProps } from 'ui-components/table/data-table/data-table';
import { CSSTransition } from 'react-transition-group';
import { getIsTablet } from 'utils/detect-environment';

type TableDataBase = {
    key: Key;
};

export type ExpandableTableProps<T> = TableProps<T> & {
    className?: string;
    expandedParentRowClassName?: string;
    expandable: ExpandableConfig<T> & {
        animateOnExpand?: boolean;
    };
    singleExpandedRow?: boolean;
};

export const ExpandableTable = <T extends TableDataBase>({
    className,
    expandedParentRowClassName,
    expandable: {
        expandedRowRender: expandedRowRenderProps,
        onExpand: onExpandProps,
        defaultExpandedRowKeys = [],
        animateOnExpand = true,
        ...expandableProps
    },
    singleExpandedRow = false,
    columns,
    onRow: onRowProps,
    ...props
}: ExpandableTableProps<T>) => {
    const [expandedRowKeys, setExpandedRowKeys] = useState<Key[]>(defaultExpandedRowKeys as Key[]);

    const onRow = useCallback(
        (record: T) => {
            const baseObj = onRowProps ? onRowProps(record) : {};
            const baseClassName = baseObj?.className;

            return expandedRowKeys.includes(record.key)
                ? {
                      ...baseObj,
                      className: classNames(
                          styles.expandedParentRow,
                          baseClassName,
                          expandedParentRowClassName,
                      ),
                  }
                : baseObj;
        },
        [expandedParentRowClassName, expandedRowKeys, onRowProps],
    );

    const onExpand = useCallback(
        (isExpanded: boolean, record: T) => {
            onExpandProps && onExpandProps(isExpanded, record);

            if (isExpanded) {
                if (singleExpandedRow) {
                    setExpandedRowKeys([record.key]);
                } else {
                    setExpandedRowKeys([...expandedRowKeys, record.key]);
                }
            } else {
                if (singleExpandedRow) {
                    setExpandedRowKeys([]);
                } else {
                    setExpandedRowKeys(expandedRowKeys.filter((key) => key !== record.key));
                }
            }
        },
        [expandedRowKeys, onExpandProps, singleExpandedRow],
    );

    const expandedRowRender = useCallback(
        (record: T, index: number, indent: number, expanded: boolean) =>
            animateOnExpand ? (
                <CSSTransition
                    classNames={{ ...styles }}
                    timeout={300}
                    in={expanded}
                    appear={expanded}
                    mountOnEnter
                >
                    {expandedRowRenderProps &&
                        expandedRowRenderProps(record, index, indent, expanded)}
                </CSSTransition>
            ) : (
                <>
                    {expandedRowRenderProps &&
                        expandedRowRenderProps(record, index, indent, expanded)}
                </>
            ),
        [animateOnExpand, expandedRowRenderProps],
    );

    const expandable = useMemo(
        () => ({
            expandIconColumnIndex: columns ? columns.length + 1 : 0,
            expandIcon: expandableProps.expandIcon
                ? expandableProps.expandIcon
                : () => (
                      <SelectArrowIcon
                          className={classNames(styles.expandIcon, {
                              [styles.tablet]: getIsTablet(),
                          })}
                      />
                  ),
            expandRowByClick: true,
            indentSize: 0,
            ...expandableProps,
            expandedRowKeys,
            onExpand,
            expandedRowRender,
        }),
        [columns, expandableProps, expandedRowKeys, expandedRowRender, onExpand],
    );

    return (
        <DataTable
            className={classNames(styles.expandableTableWrapper, className)}
            expandable={expandable}
            columns={columns}
            {...props}
            onRow={onRow}
        />
    );
};
