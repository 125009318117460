import React from 'react';

type DialogListItemProps = {
    listItem: {
        title: string;
        description: string;
    };

    children?: React.ReactNode;
};

export const DialogListItem = ({
    children = null,
    listItem = {
        title: '',
        description: '',
    },
}: DialogListItemProps) => {

    return (
        <div className="dialog-item" key={listItem.title}>
            {children}
            <div className="dialog-item-content">
                <h4 className="dialog-item-title">{listItem.title}</h4>
                <p className="dialog-item-description">{listItem.description}</p>
            </div>
        </div>
    );
};
