import type { FilterConfig, Filter } from 'features/insights/types/filter-types';
import type { FilterValue } from '@placer-ui/types';
import type { FilterType } from '@placer-ui/types';
import { DEFAULT_VISITS } from 'shared/constants/visit-duration';
import { DEFAULT_AUDIENCE } from 'shared/constants/audience-type';

export const removeDefaultKeysFromFilter = (filter: Filter) => {
    const newItem: { [key: string]: FilterConfig<FilterValue> } = { ...filter };

    return Object.keys(newItem).reduce((acc, key) => {
        let sortedItem = newItem[key];

        if (Array.isArray(newItem[key].value)) {
            const value = [...(newItem[key].value as [])] as string[] | number[];

            sortedItem = {
                ...newItem[key],
                value: value.sort((a, b) => a.toLocaleString().localeCompare(b.toLocaleString())),
            };
        }

        const { isDefaultFilter, ...rest } = sortedItem;
        acc[key] = rest;

        return acc;
    }, {} as { [key: string]: FilterConfig<FilterValue> });
};

export const removeDefaultKeysFromFilters = (filters: Filter[]) => {
    return filters.map((item) => removeDefaultKeysFromFilter(item));
};

export const formatDefaultValue: Partial<Record<FilterType, string | string[]>> = {
    visits_type: [DEFAULT_AUDIENCE],
    visit_duration: DEFAULT_VISITS,
};
