import type { Dictionary } from '@placer-ui/types';

import {
    VoidAnalysisReportMetadata,
    VoidAnalysisReport,
    VoidEntity,
} from 'features/void-analysis/common/types/entities';
import { FilterEntity } from 'features/void-analysis/common/types/filters';
import {
    EntityData,
    RecentReport,
    ReportMetadata,
} from 'features/void-analysis/common/types/server-responses';
import { PRIMARY_COLOR } from 'core/constants/place-colors';
import { ReportMethodData } from 'features/void-analysis/common/types/report-method-metadata';

export const createFiltersDictionaryById = (filters: FilterEntity[]) => {
    return filters.reduce<Dictionary<FilterEntity | undefined>>((acc, currentFilter) => {
        acc[currentFilter.name] = currentFilter;
        return acc;
    }, {});
};

export const normalizeUserReport = (response: RecentReport): VoidAnalysisReport => ({
    id: response.id,
    entity: {
        id: response.entity.id,
        name: response.entity.name,
        type: response.entity.type,
        formattedAddress: response.entity.formatted_address,
    },
    creationDate: response.creation_date,
    lastViewedDate: response.last_viewed,
    order_status: response.order_status,
    report_status: response.report_status,
});

export const normalizeReportMetadata = (
    entity: VoidEntity,
    methodData: ReportMethodData,
): VoidAnalysisReportMetadata => ({
    entity: {
        address: entity.address ?? null,
        id: entity.id,
        name: entity.name,
        formattedAddress: entity.formatted_address!,
        geojson: entity.geojson!,
        geolocation: entity.geolocation!,
        state: entity.state!,
        purchased: entity.purchased!,
        originalEntity: entity.originalEntity!,
        indicators: entity.indicators || [],
    },
    reportDates: {
        startDate: entity.filters!.date_start,
        endDate: entity.filters!.date_end,
    },
    methodData: methodData,
    warnings: [],
});

export const ReportMetadataToVoidEntity = (reportArea: ReportMetadata): VoidEntity => {
    const entity: VoidEntity = {
        ...reportArea.entity,
        type: reportArea.entity.type,
        filters: {
            date_start: reportArea.report_dates.start_date,
            date_end: reportArea.report_dates.end_date,
        },
        color: PRIMARY_COLOR,
        originalEntity: {
            id: reportArea.entity.id,
            uid: reportArea.entity.id,
            type: reportArea.entity.type,
            name: reportArea.entity.name,
            address: {
                formatted_address: reportArea.entity.formatted_address || '',
            },
            geolocation: reportArea.entity.geolocation,
            geojson: reportArea.entity.geojson,
        },
    };
    return entity;
};

export const chainAndChainDataToVoidEntity = (
    chain: VoidEntity,
    chainData: EntityData,
): VoidEntity => ({
    ...chain,
    originalEntity: {
        id: chainData.id,
        uid: chainData.id,
        type: chain.type,
        address: {
            formatted_address: chain.formatted_address || '',
        },
        name: chain.name,
        category: chainData.category,
        sub_category: chainData.sub_category,
        logo_img: chainData.logo_img,
    },
    median_dvt: chainData.median_dvt,
    brand_type: chainData.brand_type,
});
