import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/app-store-hooks';
import { useCallback, useEffect, useRef } from 'react';
import { isEqual } from 'lodash/fp';
import {
    ErrorInfo,
    ErrorSections,
    voidAnalysisActions as actions,
    VoidAnalysisState,
} from 'features/void-analysis/store/slice';
import {
    ApiFetchIDs,
    CreateReportProps,
    RankingsRequestBody,
    voidAnalysisApi,
} from 'API/void-analysis-api';
import { API } from 'API';
import {
    RankingsSortType,
    RankingTableColumn,
    RequestUserSettings,
} from 'features/void-analysis/common/types/ranking-table';
import { useVoidAnalysisSelectors } from 'features/void-analysis/store/selectors';
import { VoidAnalysisUi } from 'features/void-analysis/common/types/ui';
import { useSettingsCallback } from 'features/void-analysis/common/hooks/use-settings-callback';
import {
    CreatedOrder,
    SharedEntity,
    VoidEntity,
} from 'features/void-analysis/common/types/entities';
import {
    AdvancedSettingsRequestBody,
    EntityIdAndType,
    RankingResultsResponse,
    ReportMetadata,
} from 'features/void-analysis/common/types/server-responses';
import { rankingResultsToVoidAnalysisRankings } from 'features/void-analysis/common/utils/data-transformation';
import {
    CardsKpisSelectedTypes,
    DfsSimilarityParamSelectedTypes,
} from 'features/void-analysis/common/types/print-customization';
import { shareReportApi } from 'API/share-report-api/share-report-api';
import { useHandleError } from 'features/void-analysis/common/hooks/api-error-wrapper';
import {
    GetRankingsType,
    UpdateRankingResultsType,
} from 'features/void-analysis/common/types/actions';
import type { PlacerResponseData } from '@placer-ui/types';
import { PoiEntity } from 'features/void-analysis/common/types/chains';
import {
    chainAndChainDataToVoidEntity,
    ReportMetadataToVoidEntity,
} from 'features/void-analysis/store/normalizers';
import { LocationOption } from 'features/void-analysis/sections/empty-space/types/run-void-analysis-button-types';
import { MethodData } from 'features/void-analysis/sections/empty-space/types/methods-selector-modal-types';
import { methodSelectorModalApi } from 'features/void-analysis/sections/empty-space/api/method-selector-modal-api';
import { useScrollToHeaderPosition } from 'components/advanced-reports-analysis/hooks/scroll-to-header-position';
import { STICKY_HEADER_POSITION_THRESHOLD } from 'features/void-analysis/constants';
import { removeEmptyFilters } from 'features/void-analysis/common/utils/filters';
import { modifyLogoUrlForChainData } from 'features/void-analysis/common/utils/update-logo-url-for-chain';
import { useFetchAdvancedSettingsData } from 'features/void-analysis/common/hooks/use-fetch-advanced-settings-data';
import { useReportId } from 'features/void-analysis/common/hooks/hooks';
import { makeErrorFromSimpleFetchErrorResponse } from 'components/errors/errors-manager/errors-manager-helpers';
import { ERROR_TYPES } from 'features/site-selection/common/constants/errors';
import { useThrowError } from 'components/errors/errors-manager/errors-manager-hooks';
import { RESPONSE_STATUSES } from 'API/authentification/constants';
import { useGoToVoidAnalysis } from 'router/go-to-routes/go-to-void-analysis';
import { reportAccessActions } from 'store/configuration/report-access/slices/report-access-slice';
import type { ReportAccess } from 'store/configuration/report-access/slices/report-access-slice';

type GetRankingsParams = {
    id: string;
    page: number;
    page_size: number;
    requestData: RankingsRequestBody;
};

export const useGetReportDataAndSetEntities = () => {
    const dispatch = useAppDispatch();
    const getEntityData = useGetEntityData();
    const { getReportMetadata } = useVoidAnalysisActions();

    const setVoidEntities = useSetVoidEntities();
    return useCallback(
        async (reportId: string, entity?: VoidEntity) => {
            const reportMetadata = await getReportMetadata(reportId);
            const entityDataResponse = entity
                ? await getEntityData(
                      {
                          id: entity.id,
                          type: entity.type,
                      },
                      reportId,
                  )
                : undefined;
            let entityDataToUse: VoidEntity | undefined = entity;

            if (reportMetadata) {
                dispatch(actions.setMethodData(reportMetadata.method));
                dispatch(actions.setMatavVersionId(reportMetadata.matav_version_id!));

                if (reportMetadata.warnings.includes('light_risky_ft_removed')) {
                    const reportAccess: ReportAccess = {
                        access: {
                            level: 'limited',
                            warnings: ['light_risky_ft_removed'],
                        },
                    };
                    dispatch(
                        reportAccessActions.setReportAccess({
                            [reportMetadata.entity.id]: reportAccess,
                        }),
                    );
                }

                if (entityDataResponse && entity) {
                    const entityData = await modifyLogoUrlForChainData(entityDataResponse);
                    entityDataToUse = chainAndChainDataToVoidEntity(entity, entityData);
                }
                setVoidEntities(ReportMetadataToVoidEntity(reportMetadata), entityDataToUse);
            }
        },
        [getEntityData, getReportMetadata, setVoidEntities, dispatch],
    );
};

const useGetReportMetadata = () =>
    useCallback(async (id: string) => {
        const metadata = await voidAnalysisApi.getReportMetadata(id);
        if (!metadata.warnings) metadata.warnings = [];
        return metadata;
    }, []);

const useGetEntityData = () =>
    useCallback(async (entity: EntityIdAndType, reportId: string) => {
        try {
            return await voidAnalysisApi.getEntityData(reportId, entity);
        } catch (error) {
            console.log(error);
        }
    }, []);

const useSetVoidEntities = () => {
    const dispatch = useAppDispatch();

    return useCallback(
        (reportEntity: VoidEntity, chain?: VoidEntity) => {
            const entities: VoidEntity[] = [reportEntity];

            if (chain) {
                entities.unshift(chain);
            }

            dispatch(
                actions.setVoidEntity(
                    entities.map((entity) => ({
                        ...entity,
                        uid: entity.id,
                    })),
                ),
            );
        },
        [dispatch],
    );
};

export const useSetReportId = () => {
    const dispatch = useAppDispatch();

    return useCallback(
        (id: string) => {
            dispatch(actions.setReportId(id));
        },
        [dispatch],
    );
};

export const useVoidAnalysisActions = () => {
    const fetchAdvancedSettingsData = useFetchAdvancedSettingsData();
    const goToVoidAnalysis = useGoToVoidAnalysis();
    const dispatch = useAppDispatch();
    const { selectAllFilters, selectIsRequestingData, selectIsChangingFilters } =
        useVoidAnalysisSelectors();
    const allFilters = useSelector(selectAllFilters);
    const isRequestingData = useSelector(selectIsRequestingData);
    const isChangingFilters = useSelector(selectIsChangingFilters);
    const isChangingFiltersRef = useRef<boolean>(false);
    const rankingsResultToKeep = useRef<GetRankingsParams>();
    const updateUserSettings = useSettingsCallback();
    const handleError = useHandleError();
    const scrollToHeader = useScrollToHeaderPosition();
    const getEntityData = useGetEntityData();
    const getReportMetadataApi = useGetReportMetadata();
    const setVoidEntities = useSetVoidEntities();
    const setReportId = useSetReportId();
    const reportId = useReportId();
    const throwError = useThrowError();

    useEffect(() => {
        isChangingFiltersRef.current = isChangingFilters;
    }, [isChangingFilters]);

    const setLoading = useCallback(
        (loading: boolean) => {
            dispatch(actions.setIsLoadingContent(loading));
        },
        [dispatch],
    );

    const setIsRequestingData = useCallback(
        (loading: boolean) => {
            dispatch(actions.setIsRequestingData(loading));
        },
        [dispatch],
    );

    const setIsChangingFilters = useCallback(
        (val: boolean) => {
            dispatch(actions.setIsChangingFilters(val));
        },
        [dispatch],
    );

    const setError = useCallback(
        (payload: { section: ErrorSections; errorInfo: string }) => {
            dispatch(actions.setError(payload));
        },
        [dispatch],
    );

    const clearError = useCallback(
        (section: ErrorSections) => {
            dispatch(actions.clearError(section));
        },
        [dispatch],
    );

    const setGeneralError = useCallback(
        (error: ErrorInfo) => {
            dispatch(actions.setGeneralError(error));
        },
        [dispatch],
    );

    const setSharedEntity = useCallback(
        (sharedEntity: SharedEntity) => {
            dispatch(actions.setSharedEntity(sharedEntity));
        },
        [dispatch],
    );

    const removeSharedEntity = useCallback(() => {
        dispatch(actions.removeSharedEntity());
    }, [dispatch]);

    const setCreatedOrder = useCallback(
        (createdOrder: CreatedOrder) => {
            dispatch(actions.setCreatedOrder(createdOrder));
        },
        [dispatch],
    );

    const fetchRecentReportsList = useCallback(async () => {
        try {
            const reports = await voidAnalysisApi.getRecentReports();
            dispatch(actions.setRecentReportsList(reports));
        } catch (error) {
            handleError(error as any);
        }
    }, [dispatch, handleError]);

    const getSharedWithMeReportsList = useCallback(async () => {
        try {
            const { data: reports } = await shareReportApi.getSharedReports('void_analysis');
            dispatch(actions.setSharedWithMeReportsList(reports));
            dispatch(actions.clearError('sharedWithMe'));
        } catch (error: any) {
            dispatch(
                actions.setError({
                    section: 'sharedWithMe',
                    errorInfo: error?.message ?? 'An Error Occured',
                }),
            );
        }
    }, [dispatch]);

    const getAdvancedSettings = useCallback(async () => {
        try {
            reportId && (await fetchAdvancedSettingsData(reportId));
        } catch (error) {
            console.error(error);
        }
    }, [reportId, fetchAdvancedSettingsData]);

    const updateAdvancedSettings = useCallback(
        async (reportId, requestData: AdvancedSettingsRequestBody) => {
            try {
                await voidAnalysisApi.updateAdvancedSettings(reportId, requestData);
                await getReportStatusWithPolling(reportId);
            } catch (error) {
                dispatch(
                    actions.setGeneralError({
                        isError: true,
                    }),
                );
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch],
    );

    const setIsLoading = useCallback(
        (val: boolean) => {
            dispatch(actions.setIsLoadingContent(val));
            dispatch(actions.setIsRequestingData(val));
        },
        [dispatch],
    );

    const getReportStatusWithPolling = useCallback(
        async (reportId: string) => {
            try {
                let reportStatusResponse = await voidAnalysisApi.getReportStatus(reportId);
                if (reportStatusResponse?.report_status !== RESPONSE_STATUSES.SUCCESS) {
                    throw new Error('no response received');
                }
                return reportStatusResponse;
            } catch (error) {
                throwError({
                    error: makeErrorFromSimpleFetchErrorResponse(error),
                    type: ERROR_TYPES.reportCreationApi,
                    section: ERROR_TYPES.reportCreationSection,
                });
            }
        },
        [throwError],
    );

    const rebuildReportWithPolling = useCallback(
        async (reportId: string) => {
            try {
                await voidAnalysisApi.rebuildRankingsReport(reportId);
                return await getReportStatusWithPolling(reportId);
            } catch (error) {
                throwError({
                    error: makeErrorFromSimpleFetchErrorResponse(error),
                    type: ERROR_TYPES.reportCreationApi,
                    section: ERROR_TYPES.reportCreationSection,
                });
            }
        },
        [throwError, getReportStatusWithPolling],
    );

    const createRankingReportWithPolling = useCallback(
        async (createReportProps: CreateReportProps) => {
            try {
                const { id: reportId, report_status: reportStatus } =
                    await voidAnalysisApi.createReport(createReportProps);

                if (reportStatus === 'EXPIRED' || reportStatus === 'FAILED') {
                    goToVoidAnalysis(`rebuild/${reportId}`);
                } else {
                    return await getReportStatusWithPolling(reportId);
                }
            } catch (error) {
                throwError({
                    error: makeErrorFromSimpleFetchErrorResponse(error),
                    type: ERROR_TYPES.reportCreationApi,
                    section: ERROR_TYPES.reportCreationSection,
                });
            }
        },
        [throwError, getReportStatusWithPolling, goToVoidAnalysis],
    );

    const updateRankingResults = useCallback(
        ({ rankingsProps, rankingResults }: UpdateRankingResultsType) => {
            const {
                id,
                columns,
                requestData,
                editTableColumnsSorted,
                useDefaultFilters,
                showClearAll,
            } = rankingsProps;

            const { records, filters, records_count, scores_bounds, match_score_calculation } =
                rankingResults;

            const { filters: changedFilters, sort } = requestData;

            const filtersWithChanged = filters.map((filter) => {
                let isChanged = false;
                if (changedFilters?.find((changedFilter) => filter.name === changedFilter.name)) {
                    isChanged = true;
                }
                return {
                    ...filter,
                    changed: isChanged,
                };
            });

            const cleanFilters = removeEmptyFilters(changedFilters);
            dispatch(actions.setRequestFilters(cleanFilters));
            dispatch(
                actions.setRankingsAndFilters(
                    rankingResultsToVoidAnalysisRankings({
                        filters: filtersWithChanged,
                        records,
                        records_count,
                        scores_bounds,
                        match_score_calculation,
                    }),
                ),
            );
            const requestUserSettings: RequestUserSettings = {
                requestFilters: changedFilters,
                requestColumns: columns,
                editTableColumnsSorted,
                requestSorting: sort,
                useDefaultFilters,
                showClearAll,
            };
            updateUserSettings(id, requestUserSettings);
        },
        [dispatch, updateUserSettings],
    );

    const getRankings = useCallback(
        async (rankingsProps: GetRankingsType): Promise<RankingResultsResponse | undefined> => {
            const {
                id,
                page,
                page_size,
                requestData,
                updateStoreAndSettings = true,
            } = rankingsProps;

            try {
                if (isRequestingData) {
                    rankingsResultToKeep.current = {
                        id,
                        requestData,
                        page,
                        page_size,
                    };
                }
                const { filters: changedFilters } = requestData;
                if (!Object.keys(allFilters).length) {
                    dispatch(actions.setIsLoadingContent(true));
                } else {
                    dispatch(actions.setIsRequestingData(true));
                }
                const cleanFilters = removeEmptyFilters(changedFilters);
                dispatch(actions.setRequestFilters(cleanFilters));
                const rankingResults = await voidAnalysisApi.getRankingResults({
                    id,
                    page,
                    page_size,
                    requestParams: requestData,
                });

                scrollToHeader(STICKY_HEADER_POSITION_THRESHOLD);

                //if user is in the process of changing filters, ignore current rankingResults
                if (isChangingFiltersRef.current) {
                    rankingsResultToKeep.current = undefined;
                    return rankingResults;
                }
                //if current rankingResults is not rankingsResultToKeep, ignore it
                if (rankingsResultToKeep.current) {
                    const requestObj = {
                        id,
                        requestData,
                        page,
                        page_size,
                    };
                    if (!isEqual(rankingsResultToKeep.current, requestObj)) {
                        return rankingResults;
                    }
                }
                rankingsResultToKeep.current = undefined;

                if (rankingResults && updateStoreAndSettings) {
                    updateRankingResults({
                        rankingsProps,
                        rankingResults,
                    });
                }

                setIsLoading(false);
                return rankingResults;
            } catch (error) {
                handleError(error as any);
            }
        },
        [
            isRequestingData,
            allFilters,
            dispatch,
            scrollToHeader,
            setIsLoading,
            updateRankingResults,
            handleError,
        ],
    );

    const getReportMetadata = useCallback(
        async (id: string) => {
            try {
                return await getReportMetadataApi(id);
            } catch (error) {
                handleError(error as any);
            }
        },
        [getReportMetadataApi, handleError],
    );

    const getReportDataAndSetEntities = useCallback(
        async (reportId: string, entity?: VoidEntity) => {
            const reportMetadata: ReportMetadata | undefined = await getReportMetadata(reportId);
            if (reportMetadata?.report_status === 'EXPIRED')
                return goToVoidAnalysis(`rebuild/${reportId}`);
            const chainDataRes = entity
                ? await getEntityData(
                      {
                          id: entity.id,
                          type: entity.type,
                      },
                      reportId,
                  )
                : undefined;
            let chainDataToUse: VoidEntity | undefined = entity;

            if (reportMetadata) {
                dispatch(actions.setMethodData(reportMetadata.method));

                if (reportMetadata.warnings.includes('light_risky_ft_removed')) {
                    const reportAccess: ReportAccess = {
                        access: {
                            level: 'limited',
                            warnings: ['light_risky_ft_removed'],
                        },
                    };
                    reportAccess.access.level = 'limited';
                    dispatch(
                        reportAccessActions.setReportAccess({
                            [reportMetadata.entity.id]: reportAccess,
                        }),
                    );
                }

                if (chainDataRes && entity) {
                    chainDataToUse = chainAndChainDataToVoidEntity(entity, chainDataRes);
                }
                setVoidEntities(ReportMetadataToVoidEntity(reportMetadata), chainDataToUse);
            }
        },
        [getReportMetadata, goToVoidAnalysis, getEntityData, dispatch, setVoidEntities],
    );

    const setMethodsInfoReport = useCallback(
        async (locationOption: LocationOption) => {
            const { data: responseMethodData }: PlacerResponseData<MethodData> =
                await methodSelectorModalApi.getMethodsInfo(locationOption);

            dispatch(actions.setMethodsInfo(responseMethodData));
        },
        [dispatch],
    );

    const setAdvancedSettingsVisible = useCallback(
        (advancedSettingsVisible: boolean) => {
            dispatch(actions.setAdvancedSettingsVisible(advancedSettingsVisible));
        },
        [dispatch],
    );

    const setCurrentPage = useCallback(
        (currentPage: number) => {
            dispatch(actions.setCurrentPage(currentPage));
        },
        [dispatch],
    );

    const setVisibleColumns = useCallback(
        (columns: RankingTableColumn[]) => {
            dispatch(actions.setVisibleColumns(columns));
        },
        [dispatch],
    );

    const setRankingsTableSortBy = useCallback(
        (sort: RankingsSortType) => {
            dispatch(actions.setRankingsTableSortBy(sort));
        },
        [dispatch],
    );

    const clearAllChangedFilters = useCallback(() => {
        dispatch(actions.clearAllChangedFilters());
    }, [dispatch]);

    const setCheckboxFilterClearSearch = useCallback(
        (checkboxFilterClearSearch: boolean) => {
            dispatch(actions.setCheckboxFilterClearSearch(checkboxFilterClearSearch));
        },
        [dispatch],
    );

    const clearRankingContent = useCallback(() => {
        dispatch(actions.clearRankingContent());
    }, [dispatch]);

    const setUiProperties = useCallback(
        (uiProps: Partial<VoidAnalysisUi>) => {
            dispatch(actions.setUiProperties(uiProps));
        },
        [dispatch],
    );

    const stopFetchingRequest = useCallback((actionName: ApiFetchIDs) => {
        voidAnalysisApi.stopFetching(actionName);
    }, []);

    const setPrintCustomizationCardKpiSelection = useCallback(
        (cardSelected: CardsKpisSelectedTypes) => {
            dispatch(actions.setPrintCustomizationCardKpiSelection(cardSelected));
        },
        [dispatch],
    );

    const setPrintCustomizationCardsKpiSelections = useCallback(
        (cards: CardsKpisSelectedTypes[]) => {
            dispatch(actions.setPrintCustomizationCardsKpiSelections(cards));
        },
        [dispatch],
    );

    const setPrintDfsSimilarityParamSelection = useCallback(
        (similarityParamSelected: DfsSimilarityParamSelectedTypes) => {
            dispatch(actions.setPrintDfsSimilarityParamSelection(similarityParamSelected));
        },
        [dispatch],
    );

    const setPrintDfsSimilarityParamSelections = useCallback(
        (similarityParamsSelected: DfsSimilarityParamSelectedTypes[]) => {
            dispatch(actions.setPrintDfsSimilarityParamSelections(similarityParamsSelected));
        },
        [dispatch],
    );

    const setPrintDfsPopulationOverviewSelection = useCallback(
        (selected: boolean) => {
            dispatch(actions.setPrintDfsPopulationOverviewSelection(selected));
        },
        [dispatch],
    );

    const getSharedReportById = useCallback(async (shareId: string) => {
        try {
            const { data } = await API.shareReport.getSharedReportById(shareId);
            return data;
        } catch (error) {
            console.error(error);
        }
    }, []);

    const setShowClearAll = useCallback(
        (showClearAll?: boolean) => {
            dispatch(actions.setShowClearAll(showClearAll));
        },
        [dispatch],
    );

    const setUseDefaultFilters = useCallback(
        (useDefaultFilters?: boolean) => {
            dispatch(actions.setUseDefaultFilters(useDefaultFilters));
        },
        [dispatch],
    );

    const setRankingPageTableRecords = useCallback(
        (records: PoiEntity[][]) => {
            dispatch(actions.setRankingPageTableRecords(records));
        },
        [dispatch],
    );

    const setVoidAnalysisData = useCallback(
        (voidAnalysis: VoidAnalysisState) => {
            dispatch(actions.setVoidAnalysisData(voidAnalysis));
        },
        [dispatch],
    );

    return {
        setLoading,
        setError,
        clearError,
        setGeneralError,
        setIsRequestingData,
        setIsChangingFilters,
        setReportId,
        fetchRecentReportsList,
        getSharedWithMeReportsList,
        setSharedEntity,
        removeSharedEntity,
        setCreatedOrder,
        getAdvancedSettings,
        updateAdvancedSettings,
        getRankings,
        createRankingReportWithPolling,
        rebuildReportWithPolling,
        getReportMetadata,
        getEntityData,
        getReportDataAndSetEntities,
        getSharedReportById,
        setCurrentPage,
        setVisibleColumns,
        setRankingsTableSortBy,
        clearAllChangedFilters,
        clearRankingContent,
        setUiProperties,
        stopFetchingRequest,
        setCheckboxFilterClearSearch,
        setPrintCustomizationCardKpiSelection,
        setPrintCustomizationCardsKpiSelections,
        setPrintDfsSimilarityParamSelection,
        setPrintDfsSimilarityParamSelections,
        setPrintDfsPopulationOverviewSelection,
        setShowClearAll,
        setUseDefaultFilters,
        setRankingPageTableRecords,
        setMethodsInfoReport,
        setAdvancedSettingsVisible,
        getReportStatusWithPolling,
        setVoidAnalysisData,
    };
};
