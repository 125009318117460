/* eslint-disable max-len */
import React, { useMemo } from 'react';
import classNames from 'classnames';
import styles from './run-void-analysis-button.module.scss';

import type { Place } from '@placer-ui/types';
import {
    useIsPoiSupportedForReport,
    useRunVoidReport,
} from 'features/void-analysis/sections/add-new-report/hooks/add-new-report-hooks';
import { ButtonWithTooltip } from 'ui-components/button-with-tooltip/button-with-tooltip';

type RunVoidAnalysisButtonProps = {
    poi: Place;
    show: boolean;
};
export const RunVoidAnalysisButton = ({ poi, show }: RunVoidAnalysisButtonProps) => {
    const infoPoiSupportedForReport = useIsPoiSupportedForReport();
    const runVoidReport = useRunVoidReport();

    const { isDisabled, tooltipContent } = useMemo(() => {
        const isPoiSupportedForReport = infoPoiSupportedForReport(poi);
        const isDisabled =
            !isPoiSupportedForReport || !isPoiSupportedForReport.isOnlineReportSupported;
        const tooltipContent = isPoiSupportedForReport?.tooltipContent || '';

        return {
            isDisabled,
            tooltipContent,
        };
    }, [infoPoiSupportedForReport, poi]);

    const onClick = () => {
        if (!isDisabled && poi) {
            runVoidReport(
                {
                    entity_id: poi.id,
                    entity_type: poi.type,
                    name: poi.name,
                },
                {
                    openInNewTab: false,
                },
            );
        }
    };

    return (
        <div className={classNames(styles.buttonContainer, { [styles.show]: show })}>
            <ButtonWithTooltip
                tooltip={tooltipContent}
                disabled={isDisabled}
                buttonText={'Run Void Analysis'}
                onClick={onClick}
                buttonProps={{
                    className: styles.button,
                    type: 'brand-primary-full',
                }}
            />
        </div>
    );
};
