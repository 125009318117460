import { useExplorePermissions } from 'features/explore/hooks/use-explore-permissions';
import { SwitchItem } from 'ui-components/dropdown-with-switches/dropdown-with-switches';
import { MultiSelectOption } from 'ui-components/multi-select/types';
import { useSelectAvailableCategoriesData } from 'store/configuration/categories/selectors/selectors-hooks';
import { useMemo } from 'react';
import { BILLBOARD, COMPLEX, PROPERTY, REGION } from 'core/constants/report-type';
import {
    categoriesToExclude,
    categoriesToKeep,
    FILTER_TYPE_LABELS,
    hiddenCategories,
    RestrictedItems,
} from 'features/explore/constants';
import { cloneDeep } from 'lodash';
import { getCheckedItems } from 'ui-components/dropdown-with-switches/utils/utils';
import { useFiltersConfiguration } from 'features/explore/hooks/use-explore-persistent-filter';
import { getExploreFilterType } from 'utils/get-explore-filter-type/get-explore-filter-type';
import { Options } from 'ui-components/select-menu/select-menu';
import { ExploreLegendItem } from 'features/explore/types/types';
import { RestrictionsType } from 'types/entity-search';
import { MapLayerPOIType } from 'ui-components/google-map-layers/utils/map-layers-entity-color';
import {
    getCategoriesAsMultiSelectOptions,
    getCategoriesAsTreeOptions,
    getRegionCategoriesAsMultiSelectOptions,
} from 'utils/get-categories-as-multi-select-options/get-categories-as-multi-select-options';
import type { ConfigurationCategoriesData } from '@placer-ui/types';

export const useTypesFilterOptions = () => {
    const {
        hasTrafficPinPermission,
        hasQAFeaturesPermission,
        hasRegionCategoryFilteringPermission,
    } = useExplorePermissions();

    const typesOptions: Options[] = [
        {
            value: getExploreFilterType(PROPERTY),
            label: FILTER_TYPE_LABELS[PROPERTY],
        },
    ];

    if (hasRegionCategoryFilteringPermission) {
        typesOptions.push({
            value: getExploreFilterType(REGION),
            label: FILTER_TYPE_LABELS[REGION],
        });
    }

    if (hasTrafficPinPermission) {
        typesOptions.push({
            value: getExploreFilterType(BILLBOARD),
            label: FILTER_TYPE_LABELS[BILLBOARD],
        });
    }

    if (hasQAFeaturesPermission) {
        typesOptions.push({
            value: getExploreFilterType(COMPLEX),
            label: FILTER_TYPE_LABELS[COMPLEX],
        });
    }

    return typesOptions;
};

const getFilterOption = (
    id: RestrictionsType,
    title: string,
    checked = true,
    profile: MapLayerPOIType,
) => {
    return {
        id,
        title,
        checked,
        label: title,
        profile,
    };
};

export const usePoiFiltersOptions = () => {
    const { restrictions } = useFiltersConfiguration();

    return useMemo(() => {
        const filterOptions: (SwitchItem<RestrictionsType> & ExploreLegendItem)[] = [
            getFilterOption(
                'enable_available_pois',
                RestrictedItems.ENABLE_AVAILABLE_POIS,
                restrictions['enable_available_pois'],
                'available',
            ),
        ];

        filterOptions.push(
            getFilterOption(
                'enable_nearby_activity_pois',
                RestrictedItems.ENABLE_NEARBY_ACTIVITY_POIS,
                restrictions['enable_nearby_activity_pois'],
                'nearby_activity',
            ),
        );

        filterOptions.push(
            getFilterOption(
                'enable_custom_pois',
                RestrictedItems.ENABLE_CUSTOM_POIS,
                restrictions['enable_custom_pois'],
                'custom',
            ),
        );

        filterOptions.push(
            getFilterOption(
                'enable_closed_pois',
                RestrictedItems.ENABLE_CLOSED_POIS,
                restrictions['enable_closed_pois'],
                'closed',
            ),
        );

        const checkedItems = getCheckedItems(cloneDeep(filterOptions));

        return filterOptions.map((item) => {
            return {
                ...item,
                disabled: checkedItems.includes(item.id) && checkedItems.length === 1,
            };
        });
    }, [restrictions]);
};

export const useAvailableCategories = () => {
    const categories = useSelectAvailableCategoriesData();

    const { hasRegionCategoryFilteringPermission } = useExplorePermissions();

    return useMemo(() => {
        if (!hasRegionCategoryFilteringPermission) return categories;

        return categories.reduce<ConfigurationCategoriesData[]>((acc, group) => {
            if (!categoriesToExclude.includes(group.label)) {
                acc.push(group);
            } else {
                acc.push({
                    ...group,
                    children: group.children!.filter((primary) =>
                        categoriesToKeep.includes(primary.label),
                    ),
                });
            }
            return acc;
        }, []);
    }, [hasRegionCategoryFilteringPermission, categories]);
};

export const useRegionCategories = () => {
    const categories = useSelectAvailableCategoriesData();
    const { hasSubCategorySelectionPermission } = useExplorePermissions();
    const { type } = useFiltersConfiguration();

    return useMemo(() => {
        if (type !== REGION) return categories;

        return categories.reduce<ConfigurationCategoriesData[]>((acc, group) => {
            if (group.label === 'Region') {
                acc.push({
                    ...group,
                    children: hasSubCategorySelectionPermission
                        ? group.children!.filter(
                              (primary) => !hiddenCategories.includes(primary.label),
                          )
                        : group.children,
                });
            }
            return acc;
        }, []);
    }, [type, categories, hasSubCategorySelectionPermission]);
};

export const useGetExcludedCategories = () => {
    const categories = useSelectAvailableCategoriesData();
    const { type } = useFiltersConfiguration();

    const { hasRegionCategoryFilteringPermission } = useExplorePermissions();

    return useMemo(() => {
        if (!hasRegionCategoryFilteringPermission) return [];

        return categories.reduce<string[]>((result, group) => {
            if (categoriesToExclude.includes(group.label)) {
                result = [
                    ...result,
                    ...group
                        .children!.filter((primary) => {
                            if (type === PROPERTY && primary.label === 'Address') {
                                return false;
                            }
                            return !categoriesToKeep.includes(primary.label);
                        })
                        .map((primary) => primary.label),
                ];
            }
            return result;
        }, []);
    }, [hasRegionCategoryFilteringPermission, categories, type]);
};

export const useGetRegionSubCategories = () => {
    const categories = useSelectAvailableCategoriesData();
    const { hasRegionCategoryFilteringPermission } = useExplorePermissions();

    return useMemo(() => {
        if (!hasRegionCategoryFilteringPermission) return [];

        return categories.reduce<string[]>((result, group) => {
            if (categoriesToExclude.includes(group.label)) {
                result = [
                    ...result,
                    ...group
                        .children!.filter((primary) => !hiddenCategories.includes(primary.label))
                        .map((primary) => primary.label),
                ];
            }
            return result;
        }, []);
    }, [hasRegionCategoryFilteringPermission, categories]);
};

export const useMultipleCategoriesOptions = (selectedCategories: string[]): MultiSelectOption[] => {
    const categories = useAvailableCategories();
    const regionCategories = useRegionCategories();
    const { type } = useFiltersConfiguration();
    const { hasRevampCategory, hasSubCategorySelectionPermission } = useExplorePermissions();

    return useMemo(() => {
        if (!hasRevampCategory) {
            return [];
        }

        if (type === REGION) {
            return getRegionCategoriesAsMultiSelectOptions({
                categories: regionCategories,
                selectedCategories,
                hasSubCategorySelectionPermission,
            });
        }

        if (hasSubCategorySelectionPermission) {
            return getCategoriesAsTreeOptions({
                categories,
                selectedCategories,
            });
        }

        return getCategoriesAsMultiSelectOptions({
            categories,
            selectedCategories,
        });
    }, [
        categories,
        hasRevampCategory,
        hasSubCategorySelectionPermission,
        regionCategories,
        selectedCategories,
        type,
    ]);
};
