import type { Tag } from '@placer-ui/types';

class ChainsPermissionService {
    chainIsEligibleForTheReport(chain: Tag): boolean {
        if (chain.purchased || chain.enable_limited_report) {
            return true;
        }

        return false;
    }

    checkIsReportLimited = (chains: Tag[]) => {
        return chains.some((chain) => !chain.purchased && chain.enable_limited_report);
    };
}

export const chainsPermissionService = new ChainsPermissionService();
