/* eslint-disable max-len */
import { useCallback } from 'react';
import type { PlacerResponseData } from '@placer-ui/types';
import { ErrorInfo, voidAnalysisActions as actions } from 'features/void-analysis/store/slice';
import { useAppDispatch } from 'store/app-store-hooks';
import { useCreateJobUrlAndRedirect } from 'features/void-analysis/sections/entry-point-redirection/hooks/entry-point-redirect-hooks';
import { useGoToVoidAnalysis } from 'router/go-to-routes/go-to-void-analysis';
import { useCreatedOrders } from 'features/void-analysis/common/hooks/hooks';
import { ERRORS } from 'API/common/constants';

type ResponseWithDetails = {
    common: {
        status: number;
        info: {
            details: string;
            error: string;
            error_code: number;
            result: string;
        };
    };
};
type VoidAnalysisErrorResponse<T> = PlacerResponseData<T> & ResponseWithDetails;

export const FORBIDDEN_STATUS = 403;

const useSetError = () => {
    const dispatch = useAppDispatch();
    return useCallback(
        (
            errorInfo: ErrorInfo = {
                isError: true,
            },
        ) => {
            dispatch(actions.setGeneralError(errorInfo));
        },
        [dispatch],
    );
};

export const useHandleError = () => {
    const createJobUrlAndRedirect = useCreateJobUrlAndRedirect();
    const errorFunc = useSetError();
    const createdOrders = useCreatedOrders();
    const dispatch = useAppDispatch();

    return useCallback(
        <T>(error: VoidAnalysisErrorResponse<T>) => {
            try {
                if (error.common?.info && error.common?.status) {
                    const {
                        status,
                        info: { details, error: errorInfo },
                    } = error.common;
                    if (status === FORBIDDEN_STATUS && details) {
                        const parsedDetails = JSON.parse(details);
                        if (parsedDetails.entity_id && parsedDetails.entity_type) {
                            const {
                                entity_id,
                                entity_type,
                                ta_method,
                                ta_entity_id,
                                ta_entity_type,
                            } = parsedDetails;
                            if (createdOrders[entity_id] === entity_type) {
                                //do not create the same job more than once
                                return;
                            }
                            createJobUrlAndRedirect({
                                poiId: entity_id,
                                type: entity_type,
                                taMethod: ta_method,
                                taEntityId: ta_entity_id,
                                taEntityType: ta_entity_type,
                                config: {
                                    openInNewTab: false,
                                },
                            });
                            return;
                        }
                    } else if (errorInfo === ERRORS.INSUFFICIENT_DATA) {
                        errorFunc({
                            isError: true,
                            info: errorInfo,
                        });
                        dispatch(actions.setReportId(''));
                        return;
                    }
                }
                errorFunc();
            } catch (e) {
                errorFunc();
            } finally {
                dispatch(actions.setIsLoadingContent(false));
            }
        },
        [errorFunc, createdOrders, createJobUrlAndRedirect, dispatch],
    );
};

export const useRedirectToReportsPageByErrorStatus = () => {
    const goToVoidAnalysis = useGoToVoidAnalysis();
    return useCallback(
        <T>(error: VoidAnalysisErrorResponse<T>) => {
            try {
                if (error.common && error.common.status) {
                    if (error.common.status === FORBIDDEN_STATUS) {
                        goToVoidAnalysis('/reports');
                        return;
                    }
                }
                return Promise.reject();
            } catch (e) {
                return Promise.reject();
            }
        },
        [goToVoidAnalysis],
    );
};
