import React from 'react';

import styles from './add-new-report-search-content.module.scss';
import { PoiBox } from 'features/void-analysis/sections/add-new-report/components/poi-box/poi-box';
import { useAddNewReportContext } from 'features/void-analysis/context/add-new-report-context';
import { SearchInput } from 'features/void-analysis/sections/add-new-report/components/search-input/search-input';
import { EmptyDataMessage } from 'ui-components/empty-data-message/empty-data-message';
import { LoadingBar } from 'ui-components/loading-bar/loading-bar';

const noResultsFoundContent = {
    recent: {
        title: 'No Recently Viewed Properties',
        description: 'Try Searching',
    },
    search: {
        title: 'No properties found',
        description: 'Try changing the search term',
    },
};

export const AddNewReportSearchContent = () => {
    const { results, selectedPoi, isRequestingResults, resultsType } = useAddNewReportContext();
    const showResults = !isRequestingResults && results?.length;
    const noResultsFound = results?.length === 0 || !results;
    const showNoResultsFound = noResultsFound && !isRequestingResults;
    const noResultsTextContent =
        resultsType === 'recent reports'
            ? noResultsFoundContent.recent
            : noResultsFoundContent.search;

    return (
        <div className={styles.container}>
            <SearchInput />
            {isRequestingResults && (
                <div className={styles.progressBarContainer}>
                    <span className={styles.progressBarTitle}>Loading...</span>
                    <LoadingBar className={styles.progressBar} />
                </div>
            )}
            {!!showResults && (
                <>
                    {resultsType === 'recent reports' && (
                        <span className={styles.smallText}>Recently Viewed</span>
                    )}
                    <div className={styles.topResultsContainer}>
                        {results?.map(({ info }, idx) => (
                            <PoiBox
                                poi={info}
                                key={`${info.name}-${idx}`}
                                isSelected={selectedPoi?.id === info.id}
                            />
                        ))}
                    </div>
                </>
            )}
            {showNoResultsFound && (
                <EmptyDataMessage
                    title={noResultsTextContent.title}
                    description={noResultsTextContent.description}
                    dynamicWidth={true}
                />
            )}
        </div>
    );
};
