import React, { Component, PropsWithChildren, ReactNode } from 'react';

import { useThrowError } from 'components/errors/errors-manager/errors-manager-hooks';
import { UnexpectedError } from 'components/errors/unexpected-error/unexpected-error';
import { reportException } from 'core/exceptions';

type ErrorBoundaryProps = PropsWithChildren<{
    uncaughtExceptionComponent?: ReactNode;
    throwError?: ReturnType<typeof useThrowError>;
}>;
type ErrorBoundaryState = {
    hasError: boolean;
};
/**
 * Note - react did not port error boundaries to functional components.
 * we must use class components for this.
 */
export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        reportException(error, {
            payload: errorInfo,
        });

        this.props.throwError?.({
            error,
            type: 'unexpected-error',
        });
    }

    render() {
        if (this.state.hasError) {
            return (
                this.props.uncaughtExceptionComponent || (
                    <UnexpectedError
                        onClick={() => { this.setState({ hasError: false }); }}
                    />
                )
            );
        }
        return this.props.children;
    }
}

/**
 * A mediator functional component just for the sake of passing props from hooks to a class components.
 */
type ErrorWrapperProps = PropsWithChildren<{
    uncaughtExceptionComponent?: ReactNode;
}>;
export function ErrorWrapper({ children, uncaughtExceptionComponent }: ErrorWrapperProps) {
    const throwError = useThrowError();

    return (
        <ErrorBoundary
            uncaughtExceptionComponent={uncaughtExceptionComponent}
            throwError={throwError}
        >
            {children}
        </ErrorBoundary>
    );
}
