import type { Place } from '@placer-ui/types';
import { useCallback } from 'react';
import { useIsFreemiumUser } from 'hooks/use-freemium-user/use-freemium-user';
import { useAppUiActions } from 'store/app-ui/use-app-ui-actions';
import { UPGRADE_PLAN_SCREEN } from 'extensions/upgrade-your-plan-dialog';
import { getReportEntityFlag } from 'core/services/report-entities-service/report-entities-service';
import { useSelectorWithProps } from 'utils/structured-selector/structured-selector';
import { selectHasUserPermission } from 'store/selectors/app-selectors';

export const useIsLockedPoi = () => {
    const isFreemiumUser = useIsFreemiumUser();
    const hasUnlockNearbyActivity = useSelectorWithProps(
        {
            permission: 'unlock_nearby_activity',
        },
        selectHasUserPermission,
    );

    return useCallback(
        (place: Place) => {
            const flag = getReportEntityFlag(place);

            return (isFreemiumUser && flag) || (!hasUnlockNearbyActivity && flag);
        },
        [hasUnlockNearbyActivity, isFreemiumUser],
    );
};

export const useOnLockedButtonClick = () => {
    const { openUpgradePlanPopup } = useAppUiActions();

    return useCallback(
        (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            e.stopPropagation();
            openUpgradePlanPopup(UPGRADE_PLAN_SCREEN.default);
        },
        [openUpgradePlanPopup],
    );
};
