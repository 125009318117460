import { ExternalIframeSectionName } from 'API/external-iframe-reports-api';
import { ChainsSectionMetricsType } from 'features/chains/common/shared-chain-types';

export const MAX_COMPETITORS_QUANTITY = 8;

// TODO: get rid of this
export const DATE_FORMAT = 'YYYY-MM-DD';

export const CHAINS_SECTION_NAME: ExternalIframeSectionName = 'chains';

export const CHAIN_OVERVIEW_ROUTE = 'overview';
export const CHAIN_VARIANCE_ROUTE = 'variance';
export const CHAIN_VISITOR_JOURNEY_ROUTE = 'visitor-journey';

export const CHAIN_LOYALTY_ROUTE = 'loyalty';
export const CHAIN_DEMOGRAPHICS_ROUTE = 'demographics';
export const CHAIN_INFO_ROUTE = 'chain-info';
export const CHAIN_CONTACTS_ROUTE = 'contacts';

export const CHAIN_SECTION_METRICS: Record<ChainsSectionMetricsType, string> = {
    ft: 'Visits',
    percent_ft: '% Visits',
    panel: 'Panel Visits',
    visitors: 'Visitors',
    percent_visitors: '% Visitors',
    ft_per_sqft: 'Visits / sq ft',
};
