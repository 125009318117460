import * as Sentry from '@sentry/react';
import { Breadcrumb, BreadcrumbHint, Integration } from '@sentry/types';
import { User } from 'core/entities';
import { filterBreadcrumbs } from 'core/exceptions/exception-handler/utils';
import { getDeviceType } from 'utils/get-user-agent-data/utils';

type InitExceptionHandlerProps = {
    release: string;
    dsn: string;
    integrations?: Integration[];
    sentryEnv?: string;
    env?: string;
};

type ExceptionHandlerUserProps = {
    accountId?: string;
    isAutomation?: boolean;
    user: User;
};

export const initExceptionHandler = (props: InitExceptionHandlerProps) => {
    const sentryInitProps = getSentryInitProps(props);
    Sentry.init(sentryInitProps);
    Sentry.setTag('platform', getDeviceType());
};

export const setExceptionHandlerUser = ({
    accountId,
    isAutomation,
    user,
}: ExceptionHandlerUserProps) => {
    Sentry.setUser({ id: user.id });
    Sentry.setTag('accountId', accountId);
    isAutomation && Sentry.setTag('isAutomation', true);

    if (!user.featureFlags.enable_sentry_performance_monitor_ff) {
        Sentry.getClient()!.getOptions().integrations = Sentry.getClient()!
            .getOptions()
            .integrations.filter(
                (i) => i.name !== 'BrowserTracing' && i.name !== 'BrowserProfiling',
            );
    }
};

const getSentryInitProps = (props: InitExceptionHandlerProps) => {
    const { release, dsn, sentryEnv, env, integrations } = props;
    const isDevelopEnv = env === 'development';
    const defaultInitProps: any = {
        release,
        dsn,
        ignoreErrors: [/^ResizeObserver loop/, /(^UnhandledRejection).*value: Timeout/],
        allowUrls: [/placer\.io/, /placer\.ai/],
        tracesSampleRate: 0.1,
        integrations: [Sentry.captureConsoleIntegration({ levels: ['error'] })],
        beforeBreadcrumb(breadcrumb: Breadcrumb, hint?: BreadcrumbHint) {
            return breadcrumb?.data?.status_code !== 200
                ? filterBreadcrumbs(breadcrumb)
                : breadcrumb;
        },
    };

    if (isDevelopEnv) {
        defaultInitProps.environment = 'local';
        defaultInitProps.allowUrls.push(/localhost/);
    } else if (sentryEnv) {
        defaultInitProps.environment = sentryEnv;
    }

    defaultInitProps.integrations = integrations || [
        Sentry.browserTracingIntegration(),
        Sentry.browserProfilingIntegration(),
    ];

    return defaultInitProps;
};
