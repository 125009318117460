import produce, { Draft } from 'immer';
import { Action } from 'redux';
import type { Dictionary } from '@placer-ui/types';
import {
    Widget,
    WidgetStatus,
    WidgetTypes,
    WidgetTypesPerID,
    WidgetTypesWithoutID,
    WIDGET_ACTIONS,
    WIDGET_TYPES,
    WidgetApisContentMap,
    WidgetApiContent,
    WidgetApisInfoMap,
    WidgetApisInfo,
} from './widget-constants';
import { cloneDeep } from 'lodash/fp';
import { AppState } from 'store/store';

export interface WidgetState {
    [widgetName: string]: Widget;
}

export interface AppStateWidgets extends AppState {
    widgets: WidgetState;
}

export interface DuplicateWidgetData
    extends Action<typeof WIDGET_ACTIONS.SET_DUPLICATE_WIDGET_DATA> {
    uniqueId: string;
    widgetData: Widget;
    poiId?: string;
}

export interface CleanAllWidgetsAction extends Action<typeof WIDGET_ACTIONS.CLEAN_ALL_WIDGETS> {
    poiIdsExceptionList: string[];
}

export interface CleanAllWidgetsByPOIAction
    extends Action<typeof WIDGET_ACTIONS.CLEAN_ALL_WIDGETS_BY_POI> {
    poiIds: string[];
}

export interface CleanWidgetContentAction
    extends Action<typeof WIDGET_ACTIONS.CLEAN_WIDGET_CONTENT> {
    widgetID: string;
    apiIds?: string[];
}

export interface MarkWidgetContentAsDirty
    extends Action<typeof WIDGET_ACTIONS.MARK_WIDGET_CONTENT_AS_DIRTY> {
    widgetID: string;
    apiIds?: string[];
}

export interface CleanWidgetsByIdsAction
    extends Action<typeof WIDGET_ACTIONS.CLEAN_WIDGETS_BY_IDS> {
    widgetIDs: string[];
}
export interface CleanWidgetShowVenueLegend
    extends Action<typeof WIDGET_ACTIONS.CLEAN_WIDGET_SHOW_VENUE_LEGEND> {
    widgetID: string;
}

export interface SetFieldAction extends Action<typeof WIDGET_ACTIONS.SET_WIDGET_FIELD> {
    widgetField: WidgetTypes;
    widgetID: string;
    payload?: boolean | WidgetStatus | Dictionary<any>;
}

export interface SetFieldsAction extends Action<typeof WIDGET_ACTIONS.SET_WIDGET_FIELDS> {
    widgetID: string;
    payload: Partial<Widget>;
}

export interface SetPOIsAction extends Action<typeof WIDGET_ACTIONS.SET_WIDGET_POIS> {
    widgetField: 'venueSelector';
    widgetID: string;
    payload?: Dictionary<boolean>;
}

export interface SetWidgetApiContentAction
    extends Action<typeof WIDGET_ACTIONS.SET_WIDGET_API_CONTENT> {
    widgetID: string;
    apiId: string;
    payload?: WidgetApiContent;
}

export interface SetWidgetApiInfoAction extends Action<typeof WIDGET_ACTIONS.SET_WIDGET_API_INFO> {
    widgetID: string;
    apiId: string;
    payload?: WidgetApisInfo;
}

export interface SetWidgetsBatchDataAction
    extends Action<typeof WIDGET_ACTIONS.SET_WIDGETS_BATCH_DATA> {
    payload?: WidgetApiContent;
}

export interface SetMetaDataAction extends Action<typeof WIDGET_ACTIONS.SET_WIDGET_METADATA> {
    widgetField: 'metadata';
    widgetID: string;
    payload: unknown;
}

export interface SetAllWidgetsStatusAction
    extends Action<typeof WIDGET_ACTIONS.SET_ALL_WIDGETS_STATUS> {
    status: WidgetStatus;
}

export type CleanWidgetsFetchFiltersAction = Action<
    typeof WIDGET_ACTIONS.CLEAN_ALL_WIDGETS_FILTERS
>;

export type WidgetActionsType =
    | CleanAllWidgetsAction
    | SetFieldAction
    | SetPOIsAction
    | SetWidgetApiContentAction
    | SetWidgetApiInfoAction
    | SetMetaDataAction
    | CleanWidgetContentAction
    | CleanWidgetsFetchFiltersAction
    | CleanAllWidgetsByPOIAction
    | SetAllWidgetsStatusAction
    | CleanWidgetShowVenueLegend
    | CleanWidgetsByIdsAction
    | DuplicateWidgetData
    | SetFieldsAction
    | SetWidgetsBatchDataAction
    | MarkWidgetContentAsDirty;

const initialState = {} as WidgetState;

const reducer = (state = initialState, action: WidgetActionsType) => {
    return produce(state, (draft: Draft<WidgetState>) => {
        switch (action.type) {
            case WIDGET_ACTIONS.CLEAN_ALL_WIDGETS: {
                const { poiIdsExceptionList } = action;
                Object.keys(draft).forEach((widgetID) => {
                    // clean poi from apis content
                    if (draft[widgetID].content) {
                        Object.keys(draft[widgetID].content).forEach((apiId) => {
                            Object.keys(draft[widgetID].content[apiId]).forEach((poiId) => {
                                if (!poiIdsExceptionList.includes(poiId)) {
                                    delete draft[widgetID].content![apiId][poiId];
                                }
                            });
                        });
                    }

                    // remove poi from venue selector
                    if (draft[widgetID].venueSelector) {
                        Object.keys(draft[widgetID].venueSelector!).forEach((poiId) => {
                            if (!poiIdsExceptionList.includes(poiId)) {
                                delete draft[widgetID].venueSelector![poiId];
                            }
                        });
                    }

                    delete draft[widgetID].metadata;

                    // don't leave an empty venueSelector object
                    if (
                        draft[widgetID][WIDGET_TYPES.venueSelector] &&
                        !Object.keys(draft[widgetID][WIDGET_TYPES.venueSelector]!).length
                    ) {
                        delete draft[widgetID][WIDGET_TYPES.venueSelector];
                    }
                });
                break;
            }
            case WIDGET_ACTIONS.SET_DUPLICATE_WIDGET_DATA: {
                const { uniqueId, widgetData } = action;

                draft[uniqueId] = cloneDeep(widgetData);
                break;
            }
            case WIDGET_ACTIONS.CLEAN_WIDGETS_BY_IDS: {
                const { widgetIDs } = action;
                widgetIDs.forEach((widgetID) => {
                    delete draft[widgetID];
                });
                break;
            }
            case WIDGET_ACTIONS.CLEAN_ALL_WIDGETS_BY_POI:
                const { poiIds } = action;
                Object.keys(draft).forEach((widgetID) => {
                    poiIds.forEach((poiId) => {
                        // clean poi from apis content
                        if (draft[widgetID].content) {
                            Object.keys(draft[widgetID].content).forEach((apiId) => {
                                delete draft[widgetID].content![apiId][poiId];

                                // clear combined content APIs if any of the
                                // removed POIs is part of its content
                                if (
                                    draft[widgetID].content![apiId]?.ids?.some((id: string) =>
                                        poiIds.includes(id),
                                    )
                                ) {
                                    delete draft[widgetID].content![apiId].common;
                                    delete draft[widgetID].content![apiId].ids;
                                }
                            });

                            if (
                                draft[widgetID].content &&
                                !Object.values(draft[widgetID].content).some(
                                    (apiContent: any) =>
                                        apiContent && Object.values(apiContent).length,
                                )
                            ) {
                                delete draft[widgetID].content;
                            }
                        }

                        // remove poi from venue selector
                        if (draft[widgetID].venueSelector) {
                            delete draft[widgetID].venueSelector![poiId];
                        }

                        if (draft[widgetID].info) {
                            Object.keys(draft[widgetID].info as WidgetApisInfoMap).forEach(
                                (apiId) => {
                                    delete draft[widgetID].info![apiId][poiId];
                                },
                            );
                        }
                    });

                    delete draft[widgetID].metadata;

                    // don't leave an empty venueSelector object
                    if (
                        draft[widgetID][WIDGET_TYPES.venueSelector] &&
                        !Object.keys(draft[widgetID][WIDGET_TYPES.venueSelector]!).length
                    ) {
                        delete draft[widgetID][WIDGET_TYPES.venueSelector];
                    }
                });
                break;

            case WIDGET_ACTIONS.SET_WIDGET_FIELD: {
                const { widgetID, widgetField, payload } = action;

                if (draft[widgetID]) {
                    if (typeof payload === 'object') {
                        if (draft[widgetID][widgetField]) {
                            Object.keys(payload as Dictionary<any>).forEach(
                                (key) =>
                                    ((draft[widgetID] as Required<Widget>)[
                                        widgetField as WidgetTypesPerID
                                    ][key] = (payload as Dictionary<any>)[key]),
                            );
                        } else {
                            draft[widgetID][widgetField as WidgetTypesPerID] = payload;
                        }
                    } else {
                        draft[widgetID][widgetField as WidgetTypesWithoutID] =
                            payload as WidgetStatus; // it's not really right, need reducer refactoring
                    }
                } else {
                    draft[widgetID] = { [widgetField]: payload };
                }

                break;
            }

            case WIDGET_ACTIONS.SET_WIDGET_FIELDS: {
                const { widgetID, payload } = action;

                if (draft[widgetID]) {
                    if (typeof payload === 'object') {
                        draft[widgetID] = {
                            ...draft[widgetID],
                            ...payload,
                        };
                    }
                } else {
                    draft[widgetID] = payload;
                }

                break;
            }

            case WIDGET_ACTIONS.SET_WIDGET_API_CONTENT: {
                const { widgetID, apiId, payload } = action;

                if (!draft[widgetID]) {
                    draft[widgetID] = { content: { [apiId]: payload } as WidgetApisContentMap };
                } else if (!draft[widgetID].content) {
                    draft[widgetID].content = { [apiId]: payload };
                } else if (!draft[widgetID].content[apiId]) {
                    draft[widgetID].content[apiId] = payload;
                } else if (payload) {
                    Object.keys(payload).forEach((poiId) => {
                        const apiContent = draft[widgetID].content[apiId];
                        if (apiContent.dirty) {
                            delete apiContent.dirty;
                        }
                        apiContent[poiId] = payload[poiId];
                    });
                }

                break;
            }

            case WIDGET_ACTIONS.SET_WIDGET_API_INFO: {
                const { widgetID, apiId, payload } = action;

                if (!draft[widgetID]) {
                    draft[widgetID] = { info: { [apiId]: payload } as WidgetApisInfoMap };
                } else if (!draft[widgetID].info) {
                    draft[widgetID].info = { [apiId]: payload as WidgetApisInfo };
                } else if (!draft[widgetID].info?.[apiId]) {
                    draft[widgetID].info![apiId] = payload as WidgetApisInfo;
                } else if (payload) {
                    Object.keys(payload).forEach((poiId) => {
                        const apiInfo = (draft[widgetID].info as WidgetApisInfoMap)[apiId];
                        apiInfo[poiId] = payload[poiId];
                    });
                }
                break;
            }

            case WIDGET_ACTIONS.SET_WIDGETS_BATCH_DATA: {
                const { payload } = action;

                if (payload) {
                    Object.keys(payload).forEach((widgetId) => {
                        draft[widgetId] = payload[widgetId];
                    });
                }
                break;
            }

            case WIDGET_ACTIONS.CLEAN_WIDGET_CONTENT: {
                const { widgetID, apiIds } = action;

                if (draft[widgetID]?.content) {
                    const apisToClean = apiIds ?? Object.keys(draft[widgetID].content!);
                    apisToClean.forEach((apiId) => {
                        if (draft[widgetID].content![apiId]) {
                            Object.keys(draft[widgetID].content![apiId]).forEach((poiID) => {
                                draft[widgetID].content![apiId][poiID] = null;
                            });
                        }
                    });
                }

                if (draft[widgetID]?.metadata) {
                    Object.keys(draft[widgetID]!.metadata).forEach((metaID) => {
                        if (draft[widgetID].metadata![metaID].content) {
                            delete draft[widgetID].metadata![metaID].content;
                        }
                    });
                }

                break;
            }

            case WIDGET_ACTIONS.MARK_WIDGET_CONTENT_AS_DIRTY: {
                const { widgetID, apiIds } = action;

                if (draft[widgetID]?.content) {
                    const apisToMark = apiIds ?? Object.keys(draft[widgetID].content!);
                    apisToMark.forEach((apiId) => {
                        if (draft[widgetID].content![apiId]) {
                            Object.keys(draft[widgetID].content![apiId]).forEach((poiID) => {
                                const apiContent = draft[widgetID].content[apiId];
                                if (apiIds) {
                                    apiContent.dirty = true;
                                }
                            });
                        }
                    });
                }
                break;
            }

            case WIDGET_ACTIONS.CLEAN_WIDGET_SHOW_VENUE_LEGEND: {
                const { widgetID } = action;

                if (draft[widgetID]?.showVenueLegend) {
                    delete draft[widgetID].showVenueLegend;
                }

                break;
            }

            case WIDGET_ACTIONS.CLEAN_ALL_WIDGETS_FILTERS: {
                Object.keys(draft).forEach((widgetId) => {
                    const widget = draft[widgetId];

                    [WIDGET_TYPES.fetchFilter, WIDGET_TYPES.visualFilter].forEach((widgetField) => {
                        if (widget[widgetField]) {
                            Object.keys(widget[widgetField]!).forEach((filter) => {
                                delete widget[widgetField]![filter];
                            });
                        }
                    });

                    if (widget.venueSelector) {
                        Object.keys(widget.venueSelector).forEach((entityUID) => {
                            widget.venueSelector![entityUID] = true;
                        });
                    }
                });

                break;
            }

            case WIDGET_ACTIONS.SET_WIDGET_POIS: {
                const { widgetID, payload } = action;

                if (!draft[widgetID]) {
                    draft[widgetID] = {};
                }

                draft[widgetID].venueSelector = payload;

                // insert empty content for new venues
                const content = draft[widgetID].content;
                if (payload && content) {
                    Object.keys(payload).forEach((uid) => {
                        Object.keys(content).forEach((apiId) => {
                            if (content[apiId] && !content[apiId][uid]) {
                                content[apiId][uid] = null;
                            }
                        });
                    });
                }

                break;
            }

            case WIDGET_ACTIONS.SET_WIDGET_METADATA: {
                const { widgetID, payload } = action;

                if (!draft[widgetID]) {
                    draft[widgetID] = {};
                }

                draft[widgetID].metadata = payload;
                break;
            }

            case WIDGET_ACTIONS.SET_ALL_WIDGETS_STATUS: {
                Object.keys(draft).forEach((widgetId) => {
                    const { status } = action;
                    draft[widgetId].status = status;
                });

                break;
            }
        }
    });
};

export const widgetsReducer = {
    widgets: reducer,
};
