import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ExternalIframeReport, ExternalIframeSectionName } from 'API/external-iframe-reports-api';
import { RouteInitializerProps } from 'router';
import type { Dictionary } from '@placer-ui/types';

export type ExternalIframeReportsInfo = {
    reports?: ExternalIframeReport[];
    title?: string;
};

export interface ExternalIframeReportsState {
    reportsPerSection: Dictionary<ExternalIframeReportsInfo | undefined>;
}

export const externalIframeReportsInitialState: ExternalIframeReportsState = {
    reportsPerSection: {},
};

export const externalIframeReportsSlice = createSlice({
    name: 'external-iframe-reports',
    initialState: externalIframeReportsInitialState,
    reducers: {
        resetState: () => {
            return externalIframeReportsInitialState;
        },
        setReportsInfo: (
            state: ExternalIframeReportsState,
            action: PayloadAction<{
                reportId: ExternalIframeSectionName;
                reportsInfo: ExternalIframeReportsInfo;
            }>,
        ) => {
            state.reportsPerSection[action.payload.reportId] = action.payload.reportsInfo;
        },
    },
});

export const externalIframeReportsReducer = {
    externalIframeReports: externalIframeReportsSlice.reducer,
};

export const externalIframeReportsStoreInitializer = ({ dispatch }: RouteInitializerProps) => {
    dispatch(externalIframeReportsSlice.actions.resetState());
};

export const externalIframeReportsActions = externalIframeReportsSlice.actions;
