import { useSelectorWithProps } from 'utils/structured-selector/structured-selector';
import { selectHasUserPermission } from 'store/selectors/app-selectors';

export const useSearchMenuPermissions = () => ({
    hasTrafficPinPermission: useSelectorWithProps(
        { permission: 'show_billboards' },
        selectHasUserPermission,
    ),
    hasRevampCategory: useSelectorWithProps(
        { permission: 'enable_revamp_category_front_end' },
        selectHasUserPermission,
    ),
    hasPurchasedVenuesPermission: useSelectorWithProps(
        { permission: 'show_only_purchased_venues' },
        selectHasUserPermission,
    ),
});
