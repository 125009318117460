import { signal } from '@preact/signals-react';
import type { DrawerType, PoiWithDistance } from 'features/mobile-report/types/types';
import type { GeoLocationSensorState } from 'features/mobile-report/hooks/use-user-location';
import { DEFAULT_WIDGETS_ORDER } from 'features/mobile-report/consts';

export const drawerTypeSignal = signal<DrawerType>(undefined);

export const micListeningSignal = signal(false);

export const speechAvailableSignal = signal(false);

export const compareItemToReplaceSignal = signal<{ id: string; color?: string } | undefined>(
    undefined,
);

export const imageSignal = signal<string | undefined>(undefined);

export const isLocationPermissionApprovedSignal = signal<boolean | null>(null);

export const isMicPermissionApprovedSignal = signal<boolean | null>(null);

export const footerStateDirectionSignal = signal<'up' | 'down'>('up');

export const enclosedParentComplexesSignal = signal<
    Array<{ enclosedParentName: string; enclosedPropertyName: string }> | undefined
>(undefined);

export const userLocationSignal = signal<Omit<GeoLocationSensorState, 'loading'>>({
    lat: undefined,
    lng: undefined,
    error: undefined,
});

export const analyzeButtonDisabledSignal = signal<boolean>(false);

export const customizeViewOpenSignal = signal(false);

export const disabledWidgetsSignal = signal<string[]>([]);

export const widgetsOrderSignal = signal<string[]>(DEFAULT_WIDGETS_ORDER);

export const selectedEntityInfoSignal = signal<
    (PoiWithDistance & { hiddenNewReport?: boolean }) | undefined
>(undefined);
