import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import { RecentReports } from 'features/header/navigation/components/recent-reports';
import {
    selectPropertyDefaultReportURL,
    selectPropertyRecentReports,
} from 'store/header/navigation';
import { NavigationButtonProps, NavigationButtonWithPopover } from '../common';
import { useScrollToTopOnVisibilityChange } from 'features/header/navigation/hooks/navigation-hooks';

export const Property = ({
    link,
    label,
    isSelected,
    onClick,
}: Required<NavigationButtonProps>) => {
    const recentReports = useSelector(selectPropertyRecentReports);
    const propertyUrl = useSelector(selectPropertyDefaultReportURL);
    const elementRef = useRef<HTMLUListElement>(null);
    const onVisibleChange = useScrollToTopOnVisibilityChange(elementRef);

    const popoverContent = recentReports ? (
        <RecentReports
            recentReportsList={recentReports}
            reportsFor="property"
            onClick={onClick}
            ref={elementRef}
        />
    ) : null;

    return (
        <NavigationButtonWithPopover
            link={propertyUrl}
            label={label}
            popoverContent={popoverContent}
            isSelected={isSelected}
            onVisibleChange={onVisibleChange}
        />
    );
};
