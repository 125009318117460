import { createAsyncThunk } from '@reduxjs/toolkit';

import { TagPOI } from 'core/entities';
import { chainsAPI } from 'API/industry-api/industry-api';

const createGetSearchSuggestedBrands = (chainsApi: typeof chainsAPI) =>
    createAsyncThunk('industry/getSuggestedBrands', async () => {
        const { data: suggestedBrandsApi } = await chainsApi.getSuggestedBrands();

        const suggestedBrands = suggestedBrandsApi.map(
            (suggestedBrandsApi) => new TagPOI(suggestedBrandsApi),
        );

        return suggestedBrands;
    });

export const getSearchSuggestedBrands = createGetSearchSuggestedBrands(chainsAPI);
