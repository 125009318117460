import { useGoogleMap } from '@react-google-maps/api';
import { useCallback, useEffect, useState } from 'react';

type GoogleMapRenderingType = 'UNINITIALIZED' | 'RASTER' | 'VECTOR';
const renderingTypeAttribute = 'renderingType';

/**
 * This hook uses Google's internal renderingType prop to keep track on the map instance's state
 */
export const useMapRenderingType = () => {
    const mapInstance = useGoogleMap();
    const [renderingType, setRenderingType] = useState<GoogleMapRenderingType>('UNINITIALIZED');

    const setMapRenderingType = useCallback(() => {
        if (mapInstance) {
            setRenderingType(mapInstance.get(renderingTypeAttribute));
        }
    }, [mapInstance]);

    useEffect(() => {
        mapInstance && mapInstance.addListener('renderingtype_changed', setMapRenderingType);

        return () => {
            mapInstance && mapInstance.unbind('renderingtype_changed');
        };
    }, [mapInstance, setMapRenderingType]);

    return renderingType;
};
