import React, { ComponentProps, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Modal } from 'antd';

import { ScrollTrap } from 'extensions/scroll-trap/scroll-trap';

import './dialog.scss';

const maskColors = {
    dark: 'rgba(38, 41, 97, 0.7)',
    light: 'rgba(38, 41, 97, 0.3)',
};

type DialogPropTypes = ComponentProps<typeof Modal> & {
    colorMaskOverlay?: boolean;
    maskColor?: 'dark' | 'light';
};

export const Dialog = ({
    colorMaskOverlay = false,
    maskColor = 'light',
    ...props
}: DialogPropTypes) => {
    const [isOpen, setIsOpen] = useState(false);
    const dialogClassNames = classNames('placer-dialog', props.className);

    const afterClose = () => {
        props.afterClose && props.afterClose();
        setIsOpen(false);
    };

    useEffect(() => {
        props.visible && setIsOpen(true);
    }, [props.visible]);

    return (
        <ScrollTrap stopScroll={isOpen}>
            <Modal
                {...props}
                maskStyle={colorMaskOverlay ? { backgroundColor: maskColors[maskColor] } : {}}
                className={dialogClassNames}
                afterClose={afterClose}
            />
        </ScrollTrap>
    );
};
