import React, { useState } from 'react';
import styles from './request-demo.module.scss';
import successImg from 'shared/images/form-success-img.svg';
import checkmarkIcon from 'shared/images/icons/checkmark.svg';
import { Button, Modal } from '@placer-ui/components';
import { YouTubeVideo } from 'shared/components/youtube-video/youtube-video';
import { RequestDemoForm } from './components/request-demo-form/request-demo-form';

export const RequestDemoButton: React.FC = () => {
    const [isFormSubmitted, setFormSubmitted] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);

    const handleModalOpenChange = (isOpen: boolean) => {
        setModalOpen(isOpen);
        if (!isOpen) {
            setFormSubmitted(false); // Reset form submission state when modal is closed
        }
    };

    const benefits = [
        'Learn how to leverage Placer\'s insights for your specific industry',
        'Access a full history of foot traffic down to the day and hour',
        'Analyze visitor demographics and psychographics',
        'Receive enhanced chains and industry level visitation insights',
    ];

    return (
        <div className={styles.requestDemoBtn}>
            <Modal
                hasBackdrop
                open={isModalOpen}
                onOpenChange={handleModalOpenChange}
                trigger={
                    <Button variant='primary-brand' data-testid='request-demo-button'>
                        Get a Demo
                    </Button>
                }
            >
                <Modal.Header>
                    <Modal.Header.Info headerVariant='first' title='Request a Demo Session' />
                    <Modal.Header.Actions hasCloseButton />
                </Modal.Header>
                <Modal.Content>
                    {!isFormSubmitted ? (
                        <div className={styles.contentWrapper}>
                            <YouTubeVideo
                                videoID='jyDNFfAuW3g'
                                autoplay={true}
                                loop={true}
                                width={468}
                                height={262.5}
                            />
                            <ul className={styles.list}>
                                {benefits.map((benefit, index) => (
                                    <li key={index} className={styles.listItem}>
                                        <img src={checkmarkIcon} alt={`Benefit ${index + 1}`} />
                                        <div className={styles.listItemText}>{benefit}</div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ) : (
                        <div className={styles.messageWrapper}>
                            <img src={successImg} alt='form success icon'></img>
                            <div className={styles.header}>We got your request</div>
                            <div className={styles.text}>
                                One of our experts will contact you
                                <br />
                                shortly to schedule a demo session
                            </div>
                        </div>
                    )}
                </Modal.Content>
                {!isFormSubmitted && (
                    <Modal.Footer>
                        <Modal.Footer.RightControls>
                            <RequestDemoForm onFormSubmit={() => setFormSubmitted(true)} />
                        </Modal.Footer.RightControls>
                    </Modal.Footer>
                )}
            </Modal>
        </div>
    );
};
