import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReportNotAvailableError } from 'components/errors/report-not-available-error/report-not-available-error';
import { ToSupport } from 'ui-components/to-support/to-support';

export const PropertyNotFoundError = () => {
    const history = useHistory();

    const goToExplore = () => {
        history.push('/explore');
    };

    return (
        <ReportNotAvailableError
            title="Property Not Found"
            primaryText="Start a New Search"
            onPrimaryClick={goToExplore}
            showPrimaryButton
        >
            <p>
                This POI has been removed. Try a new search or contact <ToSupport inline />
            </p>
        </ReportNotAvailableError>
    );
};
