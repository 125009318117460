import React, { useState, PropsWithChildren, ReactNode } from 'react';
import classNames from 'classnames';
import styles from './map-legend.module.scss';
import classnames from 'classnames';

interface MapLegendProps {
    containerWidth?: number;
    containerHeight?: number;
    className?: string;
    disableMouseEvents?: boolean;
    isCollapsible?: boolean;
    isInitiallyCollapsed?: boolean;
    closeBtnClass?: string;
    dataTestId?: string;
    closeIcon?: ReactNode;
    maxWidth?: boolean;
    onChange?: (isCollapsed: boolean) => void;
}

export const MapLegend = ({
    children,
    containerWidth,
    containerHeight,
    className,
    disableMouseEvents = true,
    isCollapsible = true,
    isInitiallyCollapsed = false,
    closeBtnClass,
    dataTestId,
    closeIcon,
    maxWidth,
    onChange,
}: PropsWithChildren<MapLegendProps>) => {
    const initialCollapse = !isCollapsible ? false : !!isInitiallyCollapsed;
    const [isCollapsed, setIsCollapsed] = useState(initialCollapse);
    const classes = classNames(
        styles.legend,
        {
            [styles.collapsed]: isCollapsed,
            [styles.disableMouseEvents]: disableMouseEvents,
        },
        className,
    );

    const onClick = () => {
        const newState = !isCollapsed;
        setIsCollapsed(newState);
        onChange?.(newState);
    };

    const width = isCollapsed ? 24 : containerWidth ? 37 + containerWidth : undefined;

    return (
        <div
            data-testid={dataTestId}
            className={classnames(classes, isCollapsed ? 'no-print' : '')}
            style={{
                // add the padding to height and width
                height: isCollapsed ? 30 : containerHeight ? 22 + containerHeight : undefined,
                width,
                maxWidth: maxWidth ? width : undefined,
            }}
        >
            {isCollapsible && (
                <div
                    data-no-print
                    className={classnames(styles.closeBtn, closeBtnClass, {
                        [styles.collapse]: isCollapsed && closeIcon,
                    })}
                    onClick={onClick}
                >
                    {closeIcon}
                </div>
            )}

            <div className={classnames(styles.content)}>{children}</div>
        </div>
    );
};
