/* eslint-disable react/jsx-no-target-blank */
import React, { useCallback, useState } from 'react';
import type { PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';
import classnames from 'classnames';
import styles from './sidebar-dialog.module.scss';

import {
    generateUniqueId,
    openSidebarDialog,
    closeSidebarDialog,
} from 'ui-components/sidebar-dialog/sidebar-dialog-utils';
import {
    useListenToToggleEvent,
    useReactToToggles,
} from 'ui-components/sidebar-dialog/sidebar-dialog-hooks';
import type { SidebarState } from 'ui-components/sidebar-dialog/sidebar-dialog-constants-and-types';

/**
 * TODO:
 * this is technically a regular dialog, styled as a sidebar.
 * it needs to be turned into a generic placer dialog
 * and the sidebar should just implement this generic dialog with its own styling.
 */

type SidebarComponentProps = PropsWithChildren<{
    className?: string;
}>;

export class SidebarDialog {
    open() {}
    close() {}
    toggle() {}
    SidebarComponent({ className, children }: SidebarComponentProps) {
        return null as JSX.Element | null;
    }

    constructor() {
        const uid = generateUniqueId();
        let state: SidebarState = 'close';

        const instance: SidebarDialog = {
            open: function open() {
                state = 'open';
                openSidebarDialog(uid);
            },

            close: function close() {
                state = 'close';
                closeSidebarDialog(uid);
            },

            toggle: function toggle() {
                if (state === 'close') instance.open();
                else instance.close();
            },

            SidebarComponent: function SidebarComponent({
                className,
                children,
            }: SidebarComponentProps) {
                const [dialog, setDialog] = useState<HTMLDialogElement | null>(null);
                const shouldOpen = useListenToToggleEvent(uid);
                const isOpened = useReactToToggles(dialog, shouldOpen);

                const dialogRef = useCallback((node: HTMLDialogElement | null) => {
                    setDialog(node);
                }, []);

                const onBackdropClick = useCallback<React.MouseEventHandler<HTMLDialogElement>>(
                    (event) => {
                        if (event.target instanceof HTMLDialogElement && event.target === dialog) {
                            // clicked on the backdrop, not on the content
                            instance.close();
                        }
                    },
                    [dialog],
                );

                const onDialogKeyDown = useCallback<React.KeyboardEventHandler<HTMLDialogElement>>(
                    (event) => {
                        if (event.key === 'Escape') {
                            instance.close();
                        }
                    },
                    [],
                );

                if (!shouldOpen && !isOpened) {
                    // mostly redundant because the browser handles `dialog.open/close()` natively.
                    // is useful only in case of preventing rendering iframes when the dialog is closed.
                    return null;
                }

                return createPortal(
                    <dialog
                        ref={dialogRef}
                        className={classnames(styles.sidebar, className)}
                        onClick={onBackdropClick}
                        onKeyDown={onDialogKeyDown}
                    >
                        <div className="dialog-content">{children}</div>
                    </dialog>,
                    document.body,
                );
            },
        };

        // a hack to overwrite `new Class()` behaviour.
        // we return our own object literal instead of the generated class instance.
        // this way all methods and properties are in a closure thus will work without `this`,
        // which allows for passing the methods references around without worrying
        // about losing the context of `this` and turning it undefined.
        return instance;
    }
}
