import React from 'react';
import styles from './dynamic-modal-content.module.scss';
import { Button, Heading } from '@placer-ui/components';
import { Benefit } from 'components/freemium/benefit/benefit';
import { YouTubeVideo } from 'components/youtube-video/youtube-video';

export type DynamicModalContentProps = {
    title?: string;
    bullets?: string[];
    videoId?: string;
    closeModal: () => void;
    onSubmit: () => void;
};

const DEFAULT_BULLETS = [
    'Learn how to leverage Placer’s insights for your industry',
    'Access more advanced filters and historical data since 2017',
    'Reveal comprehensive insights for any property, chain, or industry',
];

export const DynamicModalContent = ({
    videoId,
    title = 'Upgrade to Premium',
    bullets = DEFAULT_BULLETS,
    closeModal,
    onSubmit,
}: DynamicModalContentProps) => {
    return (
        <div className={styles.root}>
            <div className={styles.header}>
                {videoId && <YouTubeVideo videoID={videoId} width={640} height={310} />}
                {!videoId && <div className={styles.image} />}
            </div>
            <div className={styles.content}>
                <Heading variant={'h1'}>{title}</Heading>
                <div className={styles.benefits}>
                    {bullets.map((bullet) => (
                        <Benefit key={bullet} label={bullet} />
                    ))}
                </div>
            </div>
            <div className={styles.footer}>
                <Button size={'large'} variant={'secondary-blue'} fullWidth onClick={closeModal}>
                    Cancel
                </Button>
                <Button size={'large'} variant={'primary-brand'} fullWidth onClick={onSubmit}>
                    Request an Upgrade
                </Button>
            </div>
        </div>
    );
};
