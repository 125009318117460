import produce, { Draft } from 'immer';
import { Action } from 'redux';
import { TrendsTitlesType } from 'features/trends/utils/models';
import { ExternalIframeReportIcon, IframeInfo } from 'API/external-iframe-reports-api';

export interface Record {
    order: number;
    title: TrendsTitlesType;
    iframe: IframeInfo;
    elevio_id?: number;
    icon?: ExternalIframeReportIcon;
    link: string;
}

export interface Trends {
    reports: Record[];
    title: string;
}

interface SetRecordsAction extends Action {
    payload: Trends;
}

export interface TrendRecordsState {
    records: Trends;
}

const SET_RECORDS = 'SET_RECORDS';

const initialState = {
    records: {
        reports: [],
        title: '',
    },
} as TrendRecordsState;

export const setRecordsAction = (records: Trends): SetRecordsAction => ({
    type: SET_RECORDS,
    payload: records,
});

export const recordsReducer = (state = initialState, action: SetRecordsAction) => {
    return produce(state, (draft: Draft<TrendRecordsState>) => {
        switch (action.type) {
            case SET_RECORDS:
                draft.records = action.payload;
                break;
        }
    });
};
