import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

import { IconProps } from 'components/assets/Icons/Icons';

/* eslint-disable max-len */
export const SuperstoresIconSvg = (props: CustomIconComponentProps | SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 17 16">
        <path
            d="M1.174 8v3.667H3.2V8h1.012v3.667h2.025V8h1.012v3.667h2.026V8h1.012v8H.162V8h1.012zm15.188-8v7H.162V2a2 2 0 012-2h14.2zM2.187 2c-.56 0-1.012.453-1.012 1.013V6H3.2V3.013C3.2 2.453 2.745 2 2.187 2zm3.038 0c-.56 0-1.013.453-1.013 1.013V6h2.025V3.013C6.237 2.453 5.784 2 5.224 2zm3.037 0c-.56 0-1.012.453-1.012 1.013V6h2.025V3.013C9.275 2.453 8.821 2 8.262 2zm5.57 0c-.84 0-1.52.68-1.52 1.519V6h3.037V3.519C15.35 2.68 14.67 2 13.831 2zm2.523 6v8H15.35v-4.481a1.52 1.52 0 00-1.38-1.513L13.832 10c-.839 0-1.519.68-1.519 1.519V16H11.3V8h5.056z"
            fill="currentColor"
            fillRule="evenodd"
        />
    </svg>
);

export const SuperstoresIcon = (props: IconProps) => (
    <Icon component={SuperstoresIconSvg} {...props} />
);
