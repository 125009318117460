import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from 'store/app-store-hooks';

import { getDashboardRoutes } from 'router/routes';
import { useInitialStateQuery } from 'store/use-initial-state-query';
import { renderRoute } from 'router/render-route';

export const useAuthProtectedRoutes = () => {
    const dashboardRoutes = getDashboardRoutes();
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { isError } = useInitialStateQuery();

    useEffect(() => {
        if (isError) {
            history.push('/unexpected-error');
        }
    }, [isError, history]);

    return dashboardRoutes.map((route) => renderRoute(route, dispatch));
};
