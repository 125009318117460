import type { Place, PlacerEntityWrapper } from '@placer-ui/types';
import { ExternalIframeSectionName } from 'API/external-iframe-reports-api';
import { ChainPOI } from 'core/entities';
import { capitalize } from 'lodash';
import { SearchEventsData } from 'types/splunk/events';

export const getPathFromKey = (key: string) => {
    return `/${key}`;
};

const getZipCode = (place: PlacerEntityWrapper<Place>): string | null => {
    return (
        place.info.address?.zip_code ||
        (place.info.address as any)?.zip ||
        (place.info.address as any)?.postal_code
    );
};

const getAddress = (place: PlacerEntityWrapper<Place>) => {
    let address = '';
    const zipCode = getZipCode(place);
    if (place.info?.address?.address) {
        address += ', ' + place.info.address.address;
    }
    if (place.info?.address?.city) {
        address += ', ' + place.info.address.city;
    }
    if (zipCode) {
        address += ', ' + zipCode;
    }
    if (place.info?.address?.state_code) {
        address += ', ' + place.info.address.state_code;
    }

    if (address.length > 0) {
        address = address.substr(2, address.length);
    }

    return address;
};

export const getFullAddress = (
    place: PlacerEntityWrapper<Place>,
    flags?: {
        includeStateDescriptor?: boolean;
    },
): string => {
    const type = place.info.type;

    if (type === 'venue' || type === 'complex' || type === 'billboard') {
        return getAddress(place);
    } else if (type === 'chain' && ChainPOI.isChain(place.info)) {
        if (place.info.area.type === 'nationwide') {
            return 'Nationwide';
        } else if (place.info.area.type === 'cbsa' || place.info.area.type === 'dma') {
            return `${place.info.area.type.toLocaleUpperCase()} ,
            ${place.info.area.code} - ${splitAndCapitalize(place.info.area.name)}`;
        }

        if (!flags?.includeStateDescriptor) {
            return splitAndCapitalize(place.info.area.name);
        }

        return (
            splitAndCapitalize(place.info.area.name) +
            ' (' +
            splitAndCapitalize(place.info.area.type) +
            ')'
        );
    } else if (type === 'zipcode') {
        return 'ZIP ' + getZipCode(place);
    } else {
        return getAddress(place);
    }
};

const splitAndCapitalize = (name: string = '') => {
    return name.split(' ').map(capitalize).join(' ');
};

export const EXTERNAL_IFRAME_REPORTS_SECTION_NAME: ExternalIframeSectionName = 'my_zone';

const EXTERNAL_IFRAME_REPORTS_SUFFIX_PATH = '/external-iframe-report';

export const getExternalReportsPath = (key: string) => {
    return `${EXTERNAL_IFRAME_REPORTS_SUFFIX_PATH}/${key}`;
};

export const SPLUNK_SEARCH_SHARED_PARAMS: Partial<SearchEventsData> = {
    search_type: 'instant',
    source_type: 'placer',
    view_name: 'My Zone',
};
