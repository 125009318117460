/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';
import { IconProps } from 'components/assets/Icons/Icons';

export const RegionIconSvg = (props: CustomIconComponentProps | SVGProps<SVGSVGElement>) => (
    <svg {...props} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.6989 8.51561C4.72259 8.54519 4.74585 8.5781 4.77715 8.62351C4.9607 8.8664 5.14045 9.10471 5.31682 9.35343L5.65517 9.82421C5.73447 9.93447 5.86312 10 6.00029 10C6.13746 10 6.26612 9.93447 6.34541 9.82421L6.83941 9.13804C6.9629 8.96764 7.09106 8.79724 7.23443 8.61559C7.25643 8.58393 7.27926 8.55477 7.3131 8.51227C7.80413 7.88109 8.33492 7.2795 8.98033 6.56958C10.0588 5.38257 10.3058 3.6728 9.60632 2.23657C8.90687 0.800339 7.39852 -0.0799313 5.78375 0.00573431C3.64479 0.138367 1.98376 1.89418 2.00012 4.00528C2.01342 5.00859 2.41807 5.9684 3.13022 6.68581C3.69239 7.26199 4.21637 7.87318 4.6989 8.51561ZM6.00029 2.29214C6.70973 2.2913 7.34976 2.71173 7.62164 3.35722C7.89353 4.0027 7.74368 4.74597 7.24203 5.24012C6.74037 5.73428 5.98583 5.88189 5.33055 5.61407C4.67527 5.34624 4.24846 4.71579 4.24932 4.01695C4.25048 3.06484 5.03374 2.29329 6.00029 2.29214Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 8V12H0V8L2.95756 8.00029C3.01292 8.06376 3.07048 8.12564 3.13022 8.18581C3.69239 8.76199 4.21637 9.37318 4.6989 10.0156C4.72259 10.0452 4.74585 10.0781 4.77715 10.1235C4.9607 10.3664 5.14045 10.6047 5.31682 10.8534L5.65517 11.3242C5.73447 11.4345 5.86312 11.5 6.00029 11.5C6.13746 11.5 6.26612 11.4345 6.34541 11.3242L6.83941 10.638C6.9629 10.4676 7.09106 10.2972 7.23443 10.1156C7.25643 10.0839 7.27926 10.0548 7.3131 10.0123C7.80413 9.38109 8.33492 8.7795 8.98033 8.06958L9.04 8H12Z"
            fill="currentColor"
        />
        <path
            d="M5.99767 5C6.54995 5.00129 6.99871 4.55462 7 4.00233C7.00128 3.45005 6.55461 3.00129 6.00233 3C5.45005 2.99872 5.00129 3.44539 5 3.99767C4.99948 4.54964 5.44571 4.99794 5.99767 5Z"
            fill="currentColor"
        />
    </svg>
);

export const RegionIcon = (props: IconProps) => <Icon component={RegionIconSvg} {...props} />;
