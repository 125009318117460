import { AnalyticsBrowser } from '@segment/analytics-next';
import { useCallback } from 'react';
import {
    SegmentEventProperties,
    SegmentPage,
} from 'hooks/use-segment-callback/types/segment-methods-types';
import { reportException } from 'core/exceptions';
import { getSegmentAnalyticsIntance } from 'tracking/segment/segment';
import { isEmailBlockedForSegmentEvents } from './utils';
import { userCookieStorage } from 'core/authentification';

declare global {
    interface Window {
        segmentAnalytics: AnalyticsBrowser;
    }
}

type TrackSegmentEventProps = SegmentEventProperties & { emailInput?: string };

export const useSegmentCallback = () => {
    const email = userCookieStorage.user?.email;

    const page = useCallback(
        (segmentPage: SegmentPage) => {
            const segmentAnalytics = getSegmentAnalyticsIntance();

            if (!segmentAnalytics || !email || isEmailBlockedForSegmentEvents(email)) {
                return;
            }
            segmentAnalytics
                .page(segmentPage.category, segmentPage.name, segmentPage.properties)
                .catch((response) => {
                    reportException(response, {
                        payload: {
                            ...segmentPage,
                        },
                    });
                });
        },
        [email],
    );

    const track = useCallback(
        ({ emailInput, ...segmentEventProperties }: TrackSegmentEventProps) => {
            const segmentAnalytics = getSegmentAnalyticsIntance();

            const emailValue = emailInput ?? email;
            if (!segmentAnalytics || !emailValue || isEmailBlockedForSegmentEvents(emailValue)) {
                return;
            }

            segmentAnalytics
                .track(
                    `${segmentEventProperties.category} ${segmentEventProperties.action}`,
                    segmentEventProperties,
                )
                .catch((response) => {
                    reportException(response, {
                        payload: {
                            emailValue,
                            ...segmentEventProperties,
                        },
                    });
                });
        },
        [email],
    );

    return {
        // identify,
        page,
        track,
    };
};
