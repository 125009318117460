import { isMobileApp, isMobileWeb, shouldRenderMobileApp } from 'utils/detect-environment';
import { userAPI } from 'API/user-api';
import { LoginParameters } from 'store/auth/actions/auth-actions/auth-action-types';
import { AppDispatch } from 'store/store';
import {
    convertLoginResponseToLocalStorageType,
    redirectToDashboard,
    saveSignedInUser,
} from 'store/auth/utils/utils';
import { getAuthError } from 'features/authentication/utils/get-auth-error';
import {
    setUserActionCreator as setUser,
    setUserFromCookieStorageActionCreator,
} from 'store/auth/actions/auth-action-creators';
import { UserData } from 'core/entities';
import type { BaseUserAuth } from '@placer-ui/types';
import { isAutomationTest } from 'shared/test-utils/automation-test';
import { goToAdminExplore, goToOnBoardingPage } from 'router/go-to';

export const authLogin =
    (userData: LoginParameters, next_url: string | null) => async (dispatch: AppDispatch) => {
        const response: Response = await userAPI.login(userData);
        if (response.ok) {
            const cookieUser = await convertLoginResponseToLocalStorageType(response);
            saveSignedInUser(cookieUser);

            if (isMobileWeb() && !isAutomationTest()) {
                //@ts-ignore
                window.location = `placer://?token=${JSON.stringify(cookieUser)}`;
            }
            dispatch(setUserFromCookieStorageActionCreator(cookieUser));

            if (shouldRenderMobileApp() || isMobileApp()) {
                goToOnBoardingPage('explore');
            } else {
                redirectToDashboard(next_url || '/');
            }
        } else {
            throw await getAuthError(response);
        }
    };

export const mobileAuthLogin = (userData: BaseUserAuth) => async (dispatch: AppDispatch) => {
    saveSignedInUser(userData);
    dispatch(setUser(userData as UserData));

    if (shouldRenderMobileApp() || isMobileApp()) {
        goToOnBoardingPage('explore');
    } else {
        goToAdminExplore();
    }
};

export const setAndRedirectAuthenticatedUser =
    (userData: BaseUserAuth, nextUrl: string | null = '') =>
    async (dispatch: AppDispatch) => {
        saveSignedInUser(userData);
        dispatch(setUser(userData as UserData));
        redirectToDashboard(nextUrl);
    };
