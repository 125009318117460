import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash/fp';

import { AvailableUserPermission } from 'core/entities/user/user-permissions';
import { AppState } from '../store';
import { User } from 'core/entities/user/user';
import { getStartEndDateOfMonth } from 'utils/date/date';
import { selectConfigurationAttributesFilters } from 'store/configuration/attributes/selectors';
import * as H from 'history';
import {
    selectAvailableCategoriesData,
    selectAvailablePrimaryCategories,
    selectCategoriesData,
} from 'store/configuration/categories/selectors';
import { UserPlugin } from 'core/entities/user/user-settings';
import { UserDatasetsPreferences } from 'core/entities/user/user-datasets-preferences';
import { CHAINS_USER_SETTINGS_FIELD } from 'features/chains/widgets/chains-audience-profile-widget/consts';
import type { DataSource, Dictionary } from '@placer-ui/types';

export const DEFAULT_MAX_MONTH = '2020-04';

export interface PermissionSelectorProps {
    permission?: AvailableUserPermission;
}

const permissionPropSelector = (state: AppState, props: PermissionSelectorProps) => {
    return props.permission;
};

export const authSelector = (state: AppState) => state.auth;

export const userSelector = createSelector(authSelector, (auth) => {
    if (auth.user) {
        return auth.user as User;
    }

    return undefined;
});

export const userEmailSelector = createSelector(userSelector, (user) => user?.email);
export const userIsAnonymousSelector = createSelector(userSelector, (user) => user?.isAnonymous);
export const userIsNewSelector = createSelector(userSelector, (user) => user?.isNew);

export const anonymousUserPermissionsSelector = createSelector(authSelector, (auth) => {
    if (auth.anonymousUserPermissions) {
        return auth.anonymousUserPermissions;
    }

    return undefined;
});

export const selectUserSettings = createSelector(userSelector, (user) => user?.settings);

export const selectAuthUserAccount = createSelector(selectUserSettings, (settings) => {
    return settings?.account;
});

export const selectIsUserFreemium = createSelector(
    selectUserSettings,
    selectAuthUserAccount,
    (settings, account) => {
        if (_.isNil(settings)) {
            return false;
        }
        return _.isNil(account) || account?.is_active === false;
    },
);

export const userPermissionsSelector = createSelector(userSelector, (user) => user?.permissions);

export const userAllPermissionsSelector = createSelector(userSelector, (user) => ({
    ...user?.permissions,
    ...user?.featureFlags,
}));

export const selectHasUserPermission = createSelector(
    userPermissionsSelector,
    permissionPropSelector,
    (permissions, permission) => {
        return !!(permissions && permission && permissions[permission]);
    },
);

export const selectSmallScreen = createSelector(userSelector, (user) =>
    user ? user.screen.width <= 1060 : false,
);

export const selectUserConfiguration = createSelector(userSelector, (user) => user?.configuration);

export const selectReportsDataSources = createSelector(selectUserConfiguration, (configuration) => {
    return configuration?.data_sources_mapping;
});

export const selectDateLimits = createSelector(
    userPermissionsSelector,
    selectUserConfiguration,
    (permission, configuration) => {
        const maxDate =
            configuration?.dashboard_max_date || configuration?.analytics_max_date || '2019-09-01';

        const maxMonth =
            configuration?.dashboard_max_month ||
            configuration?.analytics_max_month ||
            DEFAULT_MAX_MONTH;

        if (permission?.show_qa_features) {
            return {
                maxMonth: configuration?.qa_max_month || maxMonth,
                maxDate: configuration?.qa_max_date || maxDate,
                minDate: '2017-01-01',
            };
        }

        return {
            maxDate,
            minDate: '2017-01-01',
            maxMonth,
        };
    },
);

export const selectCacheDates = createSelector(
    selectUserConfiguration,
    (configuration) => configuration?.cache_dates,
);

export const selectChainsLimitedReportDefaultDateRage = createSelector(
    selectUserConfiguration,
    (configuration) => {
        const { startOfMonth, endOfMonth } = getStartEndDateOfMonth(
            configuration?.dashboard_freemium_month || '',
        );

        return {
            limitedDefaultStartDate: startOfMonth,
            limitedDefaultEndDate: endOfMonth,
        };
    },
);

export const selectChainsFullReportDefaultDateRage = createSelector(
    selectUserConfiguration,
    (configuration) => {
        return {
            fullDefaultStartDate: configuration?.chains_default_start_date || '',
            fullDefaultEndDate: configuration?.chains_default_end_date || '',
        };
    },
);

export const selectChainDefaultReport = createSelector(
    selectUserConfiguration,
    (configuration) => configuration?.freemium_chains,
);

export const selectUserMaxCompetitorsInReport = createSelector(
    selectUserConfiguration,
    (configuration) => configuration?.max_competitors_in_a_report || 8,
);

export const selectEntitiesFlags = createSelector(
    selectUserConfiguration,
    (configuration) => configuration?.entity_flags || [],
);

export const selectEntitiesFlagsWithKeys = createSelector(selectEntitiesFlags, (entityFlags) => {
    return entityFlags.map((group) => {
        return {
            ...group,
            flagKeyNames: group?.children.map((flag) => flag.label) ?? [],
        };
    });
});

export const userCustomSettingsSelector = createSelector(authSelector, (auth) => {
    return auth.custom_settings;
});

export const userCustomSettingsInsightsSelector = createSelector(
    userCustomSettingsSelector,
    (custom_settings) => {
        const recentReports = custom_settings?.insights;
        if (!recentReports || !Array.isArray(recentReports)) {
            return null;
        }

        return recentReports;
    },
);

export const userCustomSettingsChainSelector = createSelector(
    userCustomSettingsSelector,
    (custom_settings) => {
        const recentReports = custom_settings?.chainReport;

        if (!recentReports || !Array.isArray(recentReports)) {
            return null;
        }

        return recentReports;
    },
);

export const userCustomSettingsIndustrySelector = createSelector(
    userCustomSettingsSelector,
    (custom_settings) => {
        const recentReports = custom_settings?.industryReport;

        if (!recentReports || !Array.isArray(recentReports)) {
            return null;
        }

        return recentReports;
    },
);

export const userCustomSettingsExploreSelector = createSelector(
    userCustomSettingsSelector,
    (custom_settings) => {
        if (!custom_settings) {
            return null;
        }

        return custom_settings.explore;
    },
);

export const userCustomSettingsMarketLandscapeSelector = createSelector(
    userCustomSettingsSelector,
    (custom_settings) => {
        if (!custom_settings) {
            return null;
        }
        return custom_settings.marketLandscape;
    },
);

export const userCustomSettingsAudienceOverviewSelector = createSelector(
    userCustomSettingsSelector,
    (custom_settings) => {
        if (!custom_settings) {
            return null;
        }
        return custom_settings.audienceOverview;
    },
);

export const userCustomSettingsChainsAudienceOverviewSelector = createSelector(
    userCustomSettingsSelector,
    (custom_settings) => {
        if (!custom_settings) {
            return null;
        }
        return custom_settings.chainsAudienceOverview;
    },
);

export const userCustomSettingsDemographicWeightSelector = createSelector(
    userCustomSettingsSelector,
    (custom_settings) => {
        if (!custom_settings) {
            return null;
        }
        return custom_settings.demographicWeight;
    },
);

export const userCustomSettingsChainsDemographicWeightSelector = createSelector(
    userCustomSettingsSelector,
    (custom_settings) => {
        if (!custom_settings) {
            return null;
        }
        return custom_settings.chainsDemographicWeight;
    },
);

export const userCustomSettingsRecentPoiIdsSelector = createSelector(
    userCustomSettingsSelector,
    (custom_settings) => {
        return (custom_settings?.recent_poi_ids && custom_settings?.recent_poi_ids) || [];
    },
);

export const userCustomSettingsMyZoneSelector = createSelector(
    userCustomSettingsSelector,
    (custom_settings) => {
        return custom_settings?.myZone;
    },
);

export const userCustomSettingsPrintPreferencesSelector = createSelector(
    userCustomSettingsSelector,
    (custom_settings) => custom_settings?.printSettings,
);

export const userCustomSettingsVisitorJourneySelector = createSelector(
    userCustomSettingsSelector,
    (custom_settings) => custom_settings?.visitorJourney,
);

export const userCustomSettingsSalesForecastRecentReportChainsSelector = createSelector(
    userCustomSettingsSelector,
    (custom_settings) => {
        return custom_settings?.salesForecastReportChains || [];
    },
);

export const selectDatasets = createSelector(selectUserSettings, (userSettings) => {
    return userSettings?.permitted_plugins;
});

export const selectCustomUserSettingsDatasetsPreferences = createSelector(
    userCustomSettingsSelector,
    selectDatasets,
    (customSettings, permittedPlugins) => {
        return getCustomUserSettingsDatasetsPreferences(
            permittedPlugins,
            customSettings?.datasetsPreferences,
        );
    },
);

export const selectCustomUserSettingsChainsDatasetsPreferences = createSelector(
    userCustomSettingsSelector,
    selectDatasets,
    (customSettings, permittedPlugins) => {
        return getCustomUserSettingsDatasetsPreferences(
            permittedPlugins,
            customSettings?.[CHAINS_USER_SETTINGS_FIELD],
        );
    },
);

const getCustomUserSettingsDatasetsPreferences = (
    permittedPlugins?: UserPlugin[],
    datasetsPreferences?: UserDatasetsPreferences,
) => {
    if (datasetsPreferences) {
        const permittedPluginIds = permittedPlugins?.map(({ plugin_id }) => plugin_id) || [];
        const { activeDatasets, selectedDataset } = datasetsPreferences;

        const invalidUserSettings =
            !permittedPluginIds.includes(selectedDataset) ||
            activeDatasets.some((id) => !permittedPluginIds.includes(id));

        return invalidUserSettings ? undefined : datasetsPreferences;
    }
};

export const selectHouseholdIncomeAttributesFilterMappedToOptions = createSelector(
    selectConfigurationAttributesFilters,
    (configurationAttributesFilters) => {
        const householdIncomeFilter = configurationAttributesFilters.household_income;

        if (!householdIncomeFilter) {
            return {};
        }

        return householdIncomeFilter.reduce<{
            [key: string]: string;
        }>((householdIncomeOptions, item, i) => {
            const nextItem = householdIncomeFilter[i + 1];

            if (nextItem) {
                if (item === 0) {
                    householdIncomeOptions[item] = `Under $${nextItem / 1000}K`;

                    return householdIncomeOptions;
                }

                householdIncomeOptions[item] = `$${item / 1000}K - $${nextItem / 1000}K`;

                return householdIncomeOptions;
            }

            householdIncomeOptions[item] = `Over $${item / 1000}K`;

            return householdIncomeOptions;
        }, {});
    },
);

export const selectDataSourceAttributesFilterMappedToOptions = createSelector(
    selectConfigurationAttributesFilters,
    (configurationAttributesFilters) => {
        const dataSourceFilter = configurationAttributesFilters.data_source;

        if (!dataSourceFilter) {
            return {};
        }

        return Object.keys(dataSourceFilter).reduce<{
            [key: string]: keyof DataSource;
        }>((dataSourceOptions, key) => {
            const valueAsKey = dataSourceFilter[key as keyof DataSource];
            const keyAsValue = key as keyof DataSource;

            dataSourceOptions[valueAsKey] = keyAsValue;

            return dataSourceOptions;
        }, {});
    },
);

export const selectAgeAttributesFilterMappedToOptions = createSelector(
    selectConfigurationAttributesFilters,
    (configurationAttributesFilters) => {
        const age = configurationAttributesFilters.age;

        if (!age) {
            return {};
        }

        return age.reduce<{ [key: string]: string }>((ageOptions, item, i) => {
            const nextItem = age[i + 1];

            if (nextItem) {
                if (item === 0) {
                    ageOptions[item] = `< ${nextItem}`;

                    return ageOptions;
                }
                ageOptions[item] = `${item} - ${nextItem - 1}`;

                return ageOptions;
            }

            ageOptions[item] = `> ${item - 1}`;

            return ageOptions;
        }, {});
    },
);

export type ConfigurationAttributesFiltersOptions = {
    householdIncome: { [key: string]: string };
    age: { [key: string]: string };
    gender: ['male', 'female'];
    dataSource: { [key: string]: keyof DataSource };
};

export const selectAttributesFilterDataMappedToOptions = createSelector(
    selectConfigurationAttributesFilters,
    selectAgeAttributesFilterMappedToOptions,
    selectDataSourceAttributesFilterMappedToOptions,
    selectHouseholdIncomeAttributesFilterMappedToOptions,
    (
        attributesFilters,
        ageAttributesFilter,
        dataSourceAttributeFilter,
        householdIncomeAttributesFilter,
    ): ConfigurationAttributesFiltersOptions => {
        return {
            gender: attributesFilters.gender || ['male', 'female'],
            age: ageAttributesFilter,
            dataSource: dataSourceAttributeFilter,
            householdIncome: householdIncomeAttributesFilter,
        };
    },
);

export const selectSectionFromLocation = (state: AppState, location: H.Location) => {
    const path = location.pathname.replace(/\/$/, '');
    const index = path.lastIndexOf('/');
    return path.substring(index + 1);
};

export const selectCategoriesMap = createSelector(
    selectAvailableCategoriesData,
    selectAvailablePrimaryCategories,
    (categories, primaryCategories) => {
        return categories.reduce<Dictionary<string[]>>((acc, group) => {
            acc[group.label] = group.children!.reduce<string[]>((bcc, primary) => {
                bcc = [
                    ...bcc,
                    ...[
                        primary.label,
                        ...primary.children!.map((subCategory) => subCategory.label),
                    ],
                ];
                return bcc;
            }, []);
            primaryCategories.forEach((primary) => {
                acc[primary.label] = primary.children!.map((subCategory) => subCategory.label);
            });

            return acc;
        }, {});
    },
);

export const selectCategories = createSelector(selectCategoriesData, (categories) => {
    return categories.map((group) => group.label);
});

const selectCategoriesNames = (state: AppState, { categories }: { categories: string[] }) =>
    categories;

export const selectSubCategoriesFromMultipleCategories = createSelector(
    selectCategoriesMap,
    selectCategoriesNames,
    (categories, categoriesNames) => {
        // return all sub categories from a listed categories
        return categoriesNames.reduce((acc: string[], categoryName) => {
            // return all sub categories from a single category
            return [...acc, ...(categories[categoryName] || [])];
        }, []);
    },
);

export const selectNearbyActivityRadius = createSelector(
    selectUserConfiguration,
    (configuration) => configuration?.nearby_activity_radius,
);
