/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';
import { IconProps } from 'components/assets/Icons/Icons';

export const AttractionsIconSvg = (props: CustomIconComponentProps | SVGProps<SVGSVGElement>) => (
    <svg {...props} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.00865 1C7.13127 1 7.24478 1.0646 7.30753 1.17004L7.94895 1.99294H11.1254C11.6084 1.99294 12 2.64032 12 3.1237V9.99922C12 10.4826 11.6084 11 11.1254 11H0.874578C0.391543 11 0 10.4826 0 9.99922V3.1237C0 2.64032 0.391543 1.99294 0.874617 1.99294H1.02613V2.01075C1.02613 1.81852 1.18181 1.46252 1.37394 1.46252H2.66087C2.85295 1.46252 3.00868 1.81852 3.00868 2.01075V1.99294H4.05101L4.69243 1.17004C4.75514 1.0646 4.86869 1 4.99131 1H7.00865ZM6 2.99372C4.34315 2.99372 3 4.33792 3 5.99608C3 7.65423 4.34315 8.99843 6 8.99843C7.65685 8.99843 9 7.65423 9 5.99608C9 4.33792 7.65685 2.99372 6 2.99372ZM10.5 2.99372H9.5C9.22386 2.99372 9 3.21776 9 3.49412C9 3.77047 9.22386 3.99451 9.5 3.99451H10.5C10.7761 3.99451 11 3.77047 11 3.49412C11 3.21776 10.7761 2.99372 10.5 2.99372Z"
            fill="currentColor"
        />
        <path
            d="M6 7.99764C7.10457 7.99764 8 7.10151 8 5.99608C8 4.89064 7.10457 3.99451 6 3.99451C4.89543 3.99451 4 4.89064 4 5.99608C4 7.10151 4.89543 7.99764 6 7.99764Z"
            fill="currentColor"
        />
    </svg>
);

export const AttractionsIcon = (props: IconProps) => (
    <Icon component={AttractionsIconSvg} {...props} />
);
