import React, { useCallback, useMemo } from 'react';

import { TagsSelectionTableItem } from 'features/poi-selection-map/types/poi-selection-types';
import { PoiSelectionListItem } from 'features/poi-selection-map/poi-selection-menu/shared-components/poi-selection-list-item/poi-selection-list-item';
import { Count } from 'types/tag';
import { useGetTagsLists } from 'hooks/use-get-tags-lists/use-get-tags-lists';
import { usePoiSelectionContext } from 'features/poi-selection-map/context/poi-selection-context';
import { PoiSelectionApi } from 'features/poi-selection-map/poi-selection-menu/menu-items/tags-menu-items/api/poi-selection-api';
import { COMPANY_TAGS_ITEM_ID } from 'features/poi-selection-map/poi-selection-menu/menu-items/tags-menu-items/company-tags-menu-item/company-tags-menu-item';
import { MY_TAGS_ITEM_ID } from 'features/poi-selection-map/poi-selection-menu/menu-items/tags-menu-items/my-tags-menu-item/my-tags-menu-item';

export const useTagsSelectionColumns = () => {
    return useMemo(
        () => [
            {
                key: 'tags',
                title: 'Tags',
                width: '100%',
                render: (rowData: TagsSelectionTableItem) => {
                    return (
                        <PoiSelectionListItem title={rowData.title} subtitle={rowData.subtitle} />
                    );
                },
            },
        ],
        [],
    );
};

export const getTagPropertyCount = (count: Count): number =>
    Object.entries(count).reduce<number>((acc, [key, value]) => {
        if (key !== 'chain') {
            return (acc += value);
        }
        return acc;
    }, 0);

export const useFetchAndSetTags = () => {
    const getTagsLists = useGetTagsLists();
    const { sharedState, setSharedState } = usePoiSelectionContext();
    return useCallback(async () => {
        const allTags = await PoiSelectionApi.getAllTags();
        const { personalTags, companyTags } = getTagsLists(allTags);

        setSharedState({
            ...sharedState,
            personalTags,
            companyTags,
        });
    }, [getTagsLists, setSharedState, sharedState]);
};

export const useFetchAndSetAllPlacesForTag = () => {
    const { reportDates, setPoisList, resultsPagination, setResultsPagination } =
        usePoiSelectionContext();
    return useCallback(
        async (tagId: string) => {
            const places = await PoiSelectionApi.getAllPlacesForTag(tagId, reportDates);
            const validPlaces = places.filter((place) => !!place.info.geolocation);
            setResultsPagination({
                ...resultsPagination,
                totalResults: validPlaces.length,
            });
            setPoisList({
                pois: validPlaces,
                menuItemId: [COMPANY_TAGS_ITEM_ID, MY_TAGS_ITEM_ID],
            });
        },
        [reportDates, resultsPagination, setPoisList, setResultsPagination],
    );
};
