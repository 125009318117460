import { PropertyMetricType } from 'types/property-ranks';

export const RETAIL_SALES_API_FILTERED_OUT_METRICS: PropertyMetricType[] = [
    'sales',
    'sales_sq_ft',
    'transactions',
];

export const RANKING_SALES_FILTERED_OUT_METRICS: PropertyMetricType[] = [
    'SALES_EST',
    'SALES_SQ_FT_EST',
    'TRANSACTIONS_EST',
    //'TRANSACTIONS_SQ_FT_EST',
];
