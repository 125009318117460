import { useFiltersConfiguration } from 'features/explore/hooks/use-explore-persistent-filter';
import { useExplorePermissions } from 'features/explore/hooks/use-explore-permissions';
import { useMemo } from 'react';
import {
    useGetExcludedCategories,
    useGetRegionSubCategories,
} from 'features/explore/hooks/use-filters-options';
import { BILLBOARD, PROPERTY, REGION } from 'core/constants/report-type';

export const useExploreFiltersNormalizer = () => {
    const { type, restrictions, categories } = useFiltersConfiguration();
    const excludedCategories = useGetExcludedCategories();

    const regionExcludedSubCategories = useGetRegionSubCategories();

    const { hasSubCategorySelectionPermission } = useExplorePermissions();

    return useMemo(() => {
        const restrictionsByPermission = {
            enableAvailablePOIs: type === 'Billboard' || !!restrictions.enable_available_pois,
            enableNearbyActivityPOIs:
                type === 'Billboard' || !!restrictions.enable_nearby_activity_pois,
        };

        let categoriesParams: string[] = [];
        let excludeCategories: string[] = [];

        const encodedCategories = categories.map((item) => encodeURIComponent(item));
        const encodedExcludedCategories = excludedCategories.map((item) =>
            encodeURIComponent(item),
        );
        const encodedRegionSubCategories = regionExcludedSubCategories.map((item) =>
            encodeURIComponent(item),
        );

        const isBillboard = type === BILLBOARD;

        if (!isBillboard) {
            if (type === PROPERTY) {
                excludeCategories = encodedExcludedCategories;
                categoriesParams = encodedCategories;
            } else if (type === REGION) {
                if (!categories.length) {
                    if (hasSubCategorySelectionPermission) {
                        categoriesParams = encodedRegionSubCategories;
                    } else {
                        categoriesParams = encodedExcludedCategories;
                    }
                } else {
                    categoriesParams = encodedCategories;
                }
            } else {
                categoriesParams = encodedCategories;
            }
        }

        return {
            categories: categoriesParams,
            restrictions: {
                enableClosedPOIs: isBillboard || !!restrictions?.enable_closed_pois,
                enableCustomPOIs: isBillboard || !!restrictions?.enable_custom_pois,
                ...restrictionsByPermission,
            },
            excludeCategories,
        };
    }, [
        type,
        restrictions,
        categories,
        excludedCategories,
        regionExcludedSubCategories,
        hasSubCategorySelectionPermission,
    ]);
};
