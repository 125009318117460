import React, { useEffect, useState } from 'react';
import { Button, Popover } from 'antd';
import classNames from 'classnames';
import styles from './starred-reports-button.module.scss';
import { StarOutlineIcon } from 'components/assets/Icons/Icons';
import { StarredReportsDialog } from './starred-reports-dialog/starred-reports-dialog';
import { useSelectorWithProps } from 'utils/structured-selector/structured-selector';
import { selectHasUserPermission } from 'store/selectors/app-selectors';
import { useStarReportActions } from 'store/star-report/use-star-report-actions';

const TOOLTIP_ALIGN = {
    offset: [0, -3],
};

export const StarredReportsButton = () => {
    const [isDialogVisible, onSetIsDialogVisible] = useState(false);
    const { getReports } = useStarReportActions();
    const isEnabled = useSelectorWithProps(
        { permission: 'enable_starred_reports' },
        selectHasUserPermission,
    );
    const hideButton = useSelectorWithProps(
        { permission: 'hide_starred_reports_quick_access' },
        selectHasUserPermission,
    );
    const hasPermission = isEnabled && !hideButton;

    const btnClassNames = classNames(styles.starredReportsButton, {
        [styles.addTriggerActive]: isDialogVisible,
    });
    const iconClassName = classNames({
        [styles.starActive]: isDialogVisible,
        [styles.starInactive]: !isDialogVisible,
    });

    const onClosePopover = () => {
        onSetIsDialogVisible(false);
    };

    const onVisibleChange = () => {
        onSetIsDialogVisible(!isDialogVisible);
    };

    useEffect(() => {
        getReports();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return hasPermission ? (
        <Popover
            content={<StarredReportsDialog onClose={onClosePopover} />}
            trigger="click"
            placement="bottom"
            destroyTooltipOnHide
            visible={isDialogVisible}
            autoAdjustOverflow={false}
            onVisibleChange={onVisibleChange}
            overlayClassName={styles.starredReportsOverlay}
            align={TOOLTIP_ALIGN}
        >
            <div className={btnClassNames}>
                <Button icon={<StarOutlineIcon className={iconClassName} />} />
            </div>
        </Popover>
    ) : null;
};
