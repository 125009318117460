import { useAppDispatch } from 'store/app-store-hooks';
import { useCallback } from 'react';
import { ExploreConfiguration } from 'features/explore/types/types';
import { defaultInitialExploreState, exploreActions as actions } from './slice';
import { useExploreConfiguration } from 'features/explore/store/selectors';
import { isEqual } from 'lodash';
import { MapConfiguration } from 'ui-components/google-map/types/types';
import { useMap } from 'react-map-gl';

export const useExploreActions = () => {
    const { ne, sw } = useExploreConfiguration();
    const dispatch = useAppDispatch();

    const setExploreConfiguration = useCallback(
        (exploreConfiguration: Partial<ExploreConfiguration>) => {
            dispatch(actions.setExploreConfiguration(exploreConfiguration));
        },
        [dispatch],
    );

    const setExploreSearchParameters = useCallback(
        (exploreConfiguration: Partial<ExploreConfiguration>) => {
            dispatch(
                actions.setExploreConfiguration({
                    ...exploreConfiguration,
                    skip: 0,
                }),
            );
        },
        [dispatch],
    );

    const setExploreMapConfiguration = useCallback(
        (mapConfig: Partial<MapConfiguration>) => {
            if (!isEqual(ne, mapConfig.ne) || !isEqual(sw, mapConfig.sw)) {
                dispatch(
                    actions.setExploreConfiguration({
                        ...mapConfig,
                        skip: 0,
                    }),
                );
            }
        },
        [dispatch, ne, sw],
    );

    return {
        setExploreConfiguration,
        setExploreSearchParameters,
        setExploreMapConfiguration,
    };
};

export const useResetFilters = () => {
    const { setExploreConfiguration } = useExploreActions();

    return useCallback(
        () =>
            setExploreConfiguration({
                restrictions: defaultInitialExploreState.restrictions,
                categories: defaultInitialExploreState.categories,
            }),
        [setExploreConfiguration],
    );
};

export const useSetZoom = () => {
    const { exploreMapbox } = useMap();

    return useCallback(
        (zoom: number) => {
            exploreMapbox?.setZoom(zoom);
        },
        [exploreMapbox],
    );
};
