import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
    HEAVY_RISKY_FT_REMOVED,
    LIGHT_RISKY_FT_REMOVED,
} from 'features/insights/components/custom-poi-warning-bar/hooks/use-custom-poi-warning-message';
import { useReportPlaces } from 'extensions/widget/hooks/use-poi-hooks';
import { selectReportError } from 'features/insights/store/selectors/insights-selectors';
import { selectReportAccess } from 'store/configuration/report-access/selectors';

export const usePrivacyRestricted = () => {
    const { all } = useReportPlaces();
    const reportError = useSelector(selectReportError);
    const reportAccess = useSelector(selectReportAccess);

    const isSomePoiPrivacyRestricted = useMemo(() => {
        return !!all.find((entity) => {
            const reportAccessByUID = reportAccess[entity.uid]?.access;
            const isReportAccessRestrictedArea =
                reportAccessByUID?.warnings?.includes('restricted_area');
            const isHeavyRiskyFTRemoved =
                reportAccessByUID?.warnings.includes(HEAVY_RISKY_FT_REMOVED);
            const isLightRiskyFTRemoved =
                reportAccessByUID?.warnings.includes(LIGHT_RISKY_FT_REMOVED);
            const isInsufficientData = reportError?.info?.error;
            return (
                isHeavyRiskyFTRemoved ||
                isLightRiskyFTRemoved ||
                isReportAccessRestrictedArea ||
                isInsufficientData
            );
        });
    }, [all, reportAccess, reportError]);

    return isSomePoiPrivacyRestricted;
};
