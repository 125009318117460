import moment from 'moment';
import type { BaseUserAuth } from '@placer-ui/types';

import { APP_CONSTS } from 'core/constants/app-consts';
import { UserConfiguration } from 'core/entities/user/user-configuration';
import { httpClient } from 'shared/http-client';
import { goToAdminRedirect, goToHref } from 'router/go-to';
import { LoginSuccessResponse } from 'store/auth/actions/auth-actions/auth-action-types';
import { DefaultPoiDates } from 'features/insights/types/dates-types';
import { deleteCookie, encodeCookieValue, loginCookiePath, setCookie } from 'utils/cookies/utils';
import { userCookieStorage } from 'core/authentification/token-storage/token-storage';

const placerDomainsToAllowedHosts: Record<string, RegExp> = {
    '.placer.team': /^.*?\.placer\.team$/,
    '.placer.ai': /^.*?\.placer\.ai$/,
};

export const getPlacerCurrentDomain = (): string | undefined =>
    Object.keys(placerDomainsToAllowedHosts).find(
        (domain) => !!window.location.host.match(placerDomainsToAllowedHosts[domain]),
    );

export const createLoginCookie = (user: BaseUserAuth, key: string) => {
    const SIX_MONTHS_SEC = 6 * 30 * 24 * 60 * 60;
    const encodedCookieValue = encodeCookieValue(JSON.stringify(user));

    setCookie({
        key,
        value: encodedCookieValue,
        maxAge: SIX_MONTHS_SEC,
        domain: getPlacerCurrentDomain(),
        secure: true,
        path: loginCookiePath,
    });
};

export const saveSignedInUser = (user: BaseUserAuth) => {
    httpClient.setToken(user.token);
    userCookieStorage.setUser(user);
};

export const removeLoginCookie = (key: string) => {
    deleteCookie({
        key,
        domain: getPlacerCurrentDomain(),
        path: loginCookiePath,
    });
};

export const convertLoginResponseToLocalStorageType = async (
    response: Response,
): Promise<BaseUserAuth> => {
    const data = await response.json();
    const successResponse = data as LoginSuccessResponse;

    return {
        username: successResponse.username,
        email: successResponse.username,
        token: successResponse.token,
        fullName: successResponse.full_name,
    };
};

export const redirectToDashboard = (next_url: string | null | undefined) => {
    next_url && checkIfUrlSafe(next_url) ? goToHref(next_url) : goToAdminRedirect();
};

const safeNextUrlRegex = /^(https:\/\/)?([\w\d-]+\.)?((placer\.ai)|(placer\.team))(\/(.+)?)?$/;

export const checkIfUrlSafe = (next_url: string) => {
    return safeNextUrlRegex.test(next_url);
};

export const getFremiumMonth = (
    userConfiguration: UserConfiguration | undefined,
    defaultDates: DefaultPoiDates | undefined,
) => {
    const freemiumMonthSetting = userConfiguration?.dashboard_freemium_month;
    const freemiumMonth = freemiumMonthSetting
        ? moment(freemiumMonthSetting)
        : defaultDates?.maxDataMonth;
    const freemiumMonthFull = freemiumMonth?.format(APP_CONSTS.date.format.FULL);
    return freemiumMonthFull ? defaultDates?.dates[freemiumMonthFull] : undefined;
};
