import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    CreatedPoiPayloadAction,
    MappedPlacesForTag,
    PurchasedPoiPayloadAction,
} from 'features/my-zone/shared/types/types';
import { PropertiesGroup } from 'features/my-zone-properties/types/groups';
import { sortBy } from 'lodash';
import { ComparisonDates, PropertyData } from 'types/properties-data';
import { RouteInitializerProps } from 'router';
import type {
    Billboard,
    Chain,
    CustomEntity,
    Place,
    PlacerEntityWrapper,
    Tag,
    Venue,
} from '@placer-ui/types';

export interface MyZoneState {
    entities: {};
    ids: string[];
    venues: PlacerEntityWrapper<Venue>[];
    chains: PlacerEntityWrapper<Chain>[];
    billboards: PlacerEntityWrapper<Billboard>[];
    placesForTag: MappedPlacesForTag;
    comparedPlacesForTag: MappedPlacesForTag;
    venueSuggestions: PlacerEntityWrapper<Place>[];
    isVenueSuggestionsLoading: boolean;
    isVenueSuggestionsError: boolean;
    allPois: PlacerEntityWrapper<CustomEntity>[];
    isLoadingContent: boolean;
    isCreatingTag: boolean;
    isUpdatingTag: boolean;
    isError: boolean;
    tagsDates?: ComparisonDates;
    portfolioDates?: ComparisonDates;
    isRefetchingPlaceTagData: boolean;
    isInitialPlaceTagDataLoadingDone: boolean;
    isPlacesForTagLoading: boolean;
    isPlacesForTagError: boolean;
    allTags: Tag[];
    isAllTagsLoading: boolean;
    isAllTagsError: boolean;
    groups: PropertiesGroup[];
    tagsProperties?: PropertyData[];
    portfolioProperties?: PropertyData[];
}

const initialMyZoneState: MyZoneState = {
    entities: {},
    ids: [],
    venues: [],
    chains: [],
    billboards: [],
    placesForTag: {},
    venueSuggestions: [],
    allPois: [],
    isLoadingContent: false,
    isCreatingTag: false,
    isUpdatingTag: false,
    isError: false,
    tagsDates: undefined,
    portfolioDates: undefined,
    comparedPlacesForTag: {},
    isRefetchingPlaceTagData: false,
    isInitialPlaceTagDataLoadingDone: false,
    allTags: [],
    isAllTagsLoading: false,
    isAllTagsError: false,
    isPlacesForTagLoading: false,
    isPlacesForTagError: false,
    isVenueSuggestionsError: false,
    isVenueSuggestionsLoading: false,
    groups: [],
};

export const myZoneSlice = createSlice({
    name: 'my-zone',
    initialState: initialMyZoneState,
    reducers: {
        resetState: () => {
            return initialMyZoneState;
        },
        setIsLoadingContent: (state: MyZoneState, action: PayloadAction<boolean>) => {
            state.isLoadingContent = action.payload;
        },
        setError: (state: MyZoneState, action: PayloadAction<boolean>) => {
            state.isError = action.payload;
        },
        setAllVenuesList: (
            state: MyZoneState,
            action: PayloadAction<PurchasedPoiPayloadAction<Venue>>,
        ) => {
            if (action.payload.refreshState) {
                state.venues = action.payload.poiList;
            } else {
                state.venues = state.venues.concat(action.payload.poiList);
            }
        },
        setAllChainsList: (
            state: MyZoneState,
            action: PayloadAction<PurchasedPoiPayloadAction<Chain>>,
        ) => {
            if (action.payload.refreshState) {
                state.chains = action.payload.poiList;
            } else {
                state.chains = state.chains.concat(action.payload.poiList);
            }
        },
        setAllBillboardsList: (
            state: MyZoneState,
            action: PayloadAction<PurchasedPoiPayloadAction<Billboard>>,
        ) => {
            if (action.payload.refreshState) {
                state.billboards = action.payload.poiList;
            } else {
                state.billboards = state.billboards.concat(action.payload.poiList);
            }
        },
        setPlacesForTags: (state: MyZoneState, action: PayloadAction<MappedPlacesForTag>) => {
            state.placesForTag = action.payload;
        },
        setComparedPlacesForTags: (
            state: MyZoneState,
            action: PayloadAction<MappedPlacesForTag>,
        ) => {
            state.comparedPlacesForTag = action.payload;
        },
        setMyTagComparisonDates: (
            state: MyZoneState,
            action: PayloadAction<ComparisonDates | undefined>,
        ) => {
            state.tagsDates = action.payload;
        },
        setPortfolioComparisonDates: (
            state: MyZoneState,
            action: PayloadAction<ComparisonDates | undefined>,
        ) => {
            state.portfolioDates = action.payload;
        },
        setIsRefetchingPlaceTagData: (state: MyZoneState, action: PayloadAction<boolean>) => {
            state.isRefetchingPlaceTagData = action.payload;
        },
        setIsInitialPlaceTagDataLoadingDone: (
            state: MyZoneState,
            action: PayloadAction<boolean>,
        ) => {
            state.isInitialPlaceTagDataLoadingDone = action.payload;
        },
        setIsPlacesForTagError: (state: MyZoneState, action: PayloadAction<boolean>) => {
            state.isPlacesForTagError = action.payload;
        },
        setIsPlacesForTagLoading: (state: MyZoneState, action: PayloadAction<boolean>) => {
            state.isPlacesForTagLoading = action.payload;
        },
        setVenueSuggestions: (
            state: MyZoneState,
            action: PayloadAction<PlacerEntityWrapper<Place>[]>,
        ) => {
            state.venueSuggestions = action.payload;
        },
        setIsVenueSuggestionsLoading: (state: MyZoneState, action: PayloadAction<boolean>) => {
            state.isVenueSuggestionsLoading = action.payload;
        },
        setIsVenueSuggestionsError: (state: MyZoneState, action: PayloadAction<boolean>) => {
            state.isVenueSuggestionsError = action.payload;
        },
        clearVenueSuggestions: (state: MyZoneState) => {
            state.venueSuggestions = [];
        },
        setIsCreatingTag: (state: MyZoneState, action: PayloadAction<boolean>) => {
            state.isCreatingTag = action.payload;
        },
        setIsUpdatingTag: (state: MyZoneState, action: PayloadAction<boolean>) => {
            state.isUpdatingTag = action.payload;
        },
        setAllTags: (state: MyZoneState, action: PayloadAction<Tag[]>) => {
            state.allTags = action.payload;
        },
        setIsAllTagsLoading: (state: MyZoneState, action: PayloadAction<boolean>) => {
            state.isAllTagsLoading = action.payload;
        },
        setIsAllTagsError: (state: MyZoneState, action: PayloadAction<boolean>) => {
            state.isAllTagsError = action.payload;
        },
        setAllPOIs: (
            state: MyZoneState,
            action: PayloadAction<CreatedPoiPayloadAction<CustomEntity>>,
        ) => {
            if (action.payload.refreshState) {
                state.allPois = action.payload.poiList;
            } else {
                state.allPois = state.allPois.concat(action.payload.poiList);
            }
        },
        setGroupList: (state: MyZoneState, action: PayloadAction<PropertiesGroup[]>) => {
            state.groups = sortBy(action.payload, (group: PropertiesGroup) =>
                group.name.toLowerCase(),
            );
        },
        addGroup: (state: MyZoneState, action: PayloadAction<PropertiesGroup>) => {
            const newGroups = state.groups.concat(action.payload);
            state.groups = sortBy(newGroups, (group: PropertiesGroup) => group.name.toLowerCase());
        },
        deleteGroup: (state: MyZoneState, action: PayloadAction<string>) => {
            state.groups = state.groups.filter((group) => group.id !== action.payload);
        },
        updateGroupName: (state: MyZoneState, action: PayloadAction<PropertiesGroup>) => {
            const index = state.groups.findIndex((group) => group.id === action.payload.id);
            if (index > -1) {
                const newGroups = [...state.groups];
                newGroups[index] = action.payload;
                state.groups = sortBy(newGroups, (group: PropertiesGroup) =>
                    group.name.toLowerCase(),
                );
            }
        },
        setPortfolioProperties: (state: MyZoneState, action: PayloadAction<PropertyData[]>) => {
            state.portfolioProperties = action.payload;
        },
        setTagsProperties: (state: MyZoneState, action: PayloadAction<PropertyData[]>) => {
            state.tagsProperties = action.payload;
        },
        removeProperties: (state: MyZoneState, action: PayloadAction<string>) => {
            const propertyId = action.payload;
            const filterFunc = ({ info: { id } }: PropertyData) => id !== propertyId;

            state.tagsProperties = state.tagsProperties?.filter(filterFunc);
            state.portfolioProperties = state.portfolioProperties?.filter(filterFunc);
        },
    },
});

export const myZoneReducer = {
    myZone: myZoneSlice.reducer,
};

export const myZoneActions = myZoneSlice.actions;

export const myZoneStoreInitializer = ({ dispatch }: RouteInitializerProps) => {
    dispatch(myZoneSlice.actions.resetState());
};
