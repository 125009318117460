import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'store/store';
import { useSelector } from 'react-redux';
import { ExportFields } from 'features/settings/shared/types/account-settings';

const settingsSelector = (state: AppState) => state.settings;

const platformSelector = createSelector(
    settingsSelector,
    (settings) => settings?.tabs.platformConfiguration || {},
);

const exportSelector = createSelector(
    settingsSelector,
    (settings) => settings?.tabs.exportConfiguration || {},
);

export const usePlatformConfiguration = () => useSelector(platformSelector);

export const useExportStoreConfiguration = (): ExportFields => useSelector(exportSelector);
