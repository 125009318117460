import React, { PropsWithChildren } from 'react';
import classnames from 'classnames';
import styles from './alert.module.scss';
import { AlertIcon } from 'components/assets/Icons/Icons';

interface AlertProps {
    type?: 'error' | 'warning' | 'info';
    className?: string;
    textClassName?: string;
    colorText?: boolean;
}

export const Alert = ({
    children,
    type = 'info',
    className,
    textClassName,
    colorText,
}: PropsWithChildren<AlertProps>) => {
    const classNames = classnames(styles.alert, styles[type], className, {
        [styles.colorText]: colorText,
    });

    return (
        <div className={classNames}>
            <span className={styles.icon}>
                <AlertIcon />
            </span>
            <span className={classnames(styles.text, textClassName)}>{children}</span>
        </div>
    );
};
