/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';
import { IconProps } from 'components/assets/Icons/Icons';

export const FashionAccessoriesIconSvg = (
    props: CustomIconComponentProps | SVGProps<SVGSVGElement>,
) => (
    <svg {...props} viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.0042 6H1.88012L0.00424194 7.75972V7.96274C0.00424194 8.29767 0.0890195 8.55508 0.256233 8.72826C0.565366 9.04841 1.01267 8.97395 1.63188 8.87105C2.60705 8.70846 4.08082 8.46305 5.67427 9.76541C6.55553 10.4857 7.44944 10.8951 8.33094 10.9827C8.44733 10.9941 8.56326 11 8.67825 11C9.3122 11 9.92227 10.8257 10.4597 10.4888C11.3834 9.90959 12.0042 8.89447 12.0042 7.96274V7.74494L10.0042 6Z"
            fill="currentColor"
        />
        <rect x="2.00424" y="4" width="8" height="1" fill="currentColor" />
        <path
            d="M2.00424 3C2.00424 1.34315 3.34739 0 5.00424 0H7.00424C8.6611 0 10.0042 1.34315 10.0042 3H2.00424Z"
            fill="currentColor"
        />
        <path
            d="M11.0042 4L11.0042 3L12.0042 3C12.0042 3.55228 11.5565 4 11.0042 4Z"
            fill="currentColor"
        />
        <path
            d="M12.0042 2L12.0042 3L11.0042 3C11.0042 2.44772 11.452 2 12.0042 2Z"
            fill="currentColor"
        />
        <path
            d="M12.0042 5L11.0042 5L11.0042 4C11.5565 4 12.0042 4.44772 12.0042 5Z"
            fill="currentColor"
        />
        <path d="M11.0042 5H12.0042V6C11.452 6 11.0042 5.55228 11.0042 5Z" fill="currentColor" />
    </svg>
);

export const FashionAccessoriesIcon = (props: IconProps) => (
    <Icon component={FashionAccessoriesIconSvg} {...props} />
);
