import { useExploreConfiguration } from 'features/explore/store/selectors';
import { userCustomSettingsExploreSelector } from 'store/selectors/app-selectors';
import { useSelector } from 'react-redux';
import {
    BILLBOARD,
    COMPLEX,
    PROPERTY,
    REGION,
    ReportEntityTypes,
} from 'core/constants/report-type';
import { ExploreFiltersConfiguration } from 'features/explore/types/types';
import { Restrictions } from 'types/entity-search';
import { useMemo } from 'react';
import { getExploreDecodedType } from 'utils/get-explore-filter-type/get-explore-filter-type';
import { defaultInitialExploreState } from 'features/explore/store/slice';
import { useExplorePermissions } from 'features/explore/hooks/use-explore-permissions';
import { useExploreUrlParams } from 'features/explore/hooks/use-explore-url-params';
import { shouldRenderMobileApp } from 'utils/detect-environment';

export const useFiltersConfiguration = (): ExploreFiltersConfiguration => {
    const {
        hasTrafficPinPermission,
        hasQAFeaturesPermission,
        hasRegionCategoryFilteringPermission,
    } = useExplorePermissions();

    const isMobile = shouldRenderMobileApp();
    const sessionParams = useExploreConfiguration();
    const userCustomSettings = useSelector(userCustomSettingsExploreSelector);
    const urlParams = useExploreUrlParams();

    return useMemo(() => {
        const customPois = {
            enable_custom_pois:
                sessionParams?.restrictions.enable_custom_pois ??
                userCustomSettings?.enable_custom_pois ??
                defaultInitialExploreState.restrictions.enable_custom_pois,
        };

        const unverifiedPois = {
            enable_nearby_activity_pois:
                sessionParams?.restrictions.enable_nearby_activity_pois ??
                userCustomSettings?.enable_nearby_activity_pois ??
                defaultInitialExploreState.restrictions.enable_nearby_activity_pois,
        };

        const closedPois = {
            enable_closed_pois:
                sessionParams?.restrictions.enable_closed_pois ??
                userCustomSettings?.enable_closed_pois ??
                defaultInitialExploreState.restrictions.enable_closed_pois,
        };

        const verifiedPois = {
            enable_available_pois:
                sessionParams?.restrictions.enable_available_pois ??
                userCustomSettings?.enable_available_pois ??
                defaultInitialExploreState.restrictions.enable_available_pois,
        };

        const userSettingsRestrictions: Restrictions = {
            ...verifiedPois,
            ...customPois,
            ...unverifiedPois,
            ...closedPois,
        };

        const areAllRestrictionsOff = Object.values(userSettingsRestrictions).every(
            (restriction) => !restriction,
        );

        if (areAllRestrictionsOff) {
            userSettingsRestrictions['enable_available_pois'] = true;
            userSettingsRestrictions['enable_verified_pois'] = true;
        }

        const reportType = getExploreDecodedType(
            sessionParams?.reportType ||
                urlParams?.type ||
                (userCustomSettings?.type as ReportEntityTypes) ||
                defaultInitialExploreState.reportType,
        );

        let type: 'Property' | 'Complex' | 'Billboard' | 'Region' = reportType;

        const mobileSupportedTypes: string[] = [PROPERTY];

        mobileSupportedTypes.push(BILLBOARD);
        mobileSupportedTypes.push(REGION);

        if (
            !reportType ||
            (!hasQAFeaturesPermission && reportType === COMPLEX) ||
            (!hasRegionCategoryFilteringPermission && reportType === REGION) ||
            (!hasTrafficPinPermission && reportType === BILLBOARD) ||
            (isMobile && !mobileSupportedTypes.includes(reportType))
        ) {
            type = PROPERTY;
        }

        return {
            type,
            restrictions: userSettingsRestrictions,
            categories: sessionParams?.categories ?? defaultInitialExploreState.categories,
            chains: sessionParams?.chains ?? defaultInitialExploreState.chains,
            term: sessionParams?.term || defaultInitialExploreState.term,
            region: sessionParams?.region || {},
        };
    }, [
        sessionParams?.restrictions.enable_custom_pois,
        sessionParams?.restrictions.enable_nearby_activity_pois,
        sessionParams?.restrictions.enable_closed_pois,
        sessionParams?.restrictions.enable_available_pois,
        sessionParams?.reportType,
        sessionParams?.categories,
        sessionParams?.chains,
        sessionParams?.term,
        sessionParams?.region,
        userCustomSettings?.enable_custom_pois,
        userCustomSettings?.enable_nearby_activity_pois,
        userCustomSettings?.enable_closed_pois,
        userCustomSettings?.enable_available_pois,
        userCustomSettings?.type,
        urlParams?.type,
        hasQAFeaturesPermission,
        hasRegionCategoryFilteringPermission,
        hasTrafficPinPermission,
        isMobile,
    ]);
};
