/* eslint-disable max-len */
import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/app-store-hooks';
import { isEqual } from 'lodash/fp';
import { useDebouncedCallback } from 'use-debounce';
import {
    FilterValue,
    SorterResult,
    SortOrder,
    TablePaginationConfig,
} from 'antd/lib/table/interface';
import {
    RankingsSortType,
    RankingTableColumn,
    RankingTableRowData,
    ShortSortOrder,
    SortRankingsOrderByField,
} from 'features/void-analysis/common/types/ranking-table';
import { ANTD_SORTING } from 'ui-components/table/data-table/pagination/constants/sorting';
import { useVoidAnalysisSelectors } from 'features/void-analysis/store/selectors';
import { useVoidRequestRankingsTableSortBy } from 'features/void-analysis/common/hooks/use-settings-callback';
import { voidAnalysisActions as actions } from 'features/void-analysis/store/slice';
import {
    FILTER_CHANGE_DEBOUNCE_TIME,
    MEDIAN_DRIVE_TIME_COLUMN,
} from 'features/void-analysis/constants';
import {
    useGetRankingResultsWithLastFilters,
    useReportId,
    useRequestedColumns,
    useResetTableRecordsAndCurrentPage,
} from 'features/void-analysis/common/hooks/hooks';
import { useWidgetPrintMode } from 'extensions/widget/hooks/use-widget-context-hooks';
import { useEmptySpacePermissions } from 'features/void-analysis/sections/empty-space/hooks/use-empty-space-permissions';

const sortDirections: SortOrder[] = ['descend', 'ascend', 'descend'];

export const tableColumnToRankingsSortType: Partial<
    Record<RankingTableColumn, SortRankingsOrderByField>
> = {
    chain_name: 'name',
    typical_size: 'min_size_sq_ft',
    nearest_location: 'nearest_venue',
    number_of_venues: 'venues_in_country',
    expansion_rate: 'expansion_in_country',
    total_match_score: 'total_score',
    demographic_fit_score: 'demographics_score',
    cannibalization_score: 'cannibalization_score',
    avg_monthly_ft: 'avg_monthly_ft_score',
    natural_co_tenants: 'natural_co_tenants_score',
    median_dvt: 'median_dvt',
};

export const rankingSortTypeToTableColumn: Partial<
    Record<SortRankingsOrderByField, RankingTableColumn>
> = {
    name: 'chain_name',
    min_size_sq_ft: 'typical_size',
    nearest_venue: 'nearest_location',
    venues_in_country: 'number_of_venues',
    expansion_in_country: 'expansion_rate',
    total_score: 'total_match_score',
    demographics_score: 'demographic_fit_score',
    cannibalization_score: 'cannibalization_score',
    avg_monthly_ft_score: 'avg_monthly_ft',
    natural_co_tenants_score: 'natural_co_tenants',
    median_dvt: 'median_dvt',
};

export const sortOrderToShortSortOrder: Record<string, ShortSortOrder> = {
    ascend: 'asc',
    descend: 'desc',
};

export const defaultRankingsTableSorting: RankingsSortType = {
    order_by_field: 'total_score',
    order: 'desc',
};

export const useSetRankingsTableSortBy = () => {
    const dispatch = useAppDispatch();
    return useCallback(
        (sortBy: RankingsSortType) => {
            dispatch(actions.setRankingsTableSortBy(sortBy));
        },
        [dispatch],
    );
};

const useDefineSortByDriveTime = () => {
    const { hasEmptySpacePermission } = useEmptySpacePermissions();

    return useCallback(
        (sortByInStore?: RankingsSortType, userSettingsSortBy?: RankingsSortType) => {
            if (
                (!sortByInStore || sortByInStore?.order_by_field === MEDIAN_DRIVE_TIME_COLUMN) &&
                !hasEmptySpacePermission
            ) {
                if (
                    userSettingsSortBy &&
                    (userSettingsSortBy as RankingsSortType).order_by_field !==
                        MEDIAN_DRIVE_TIME_COLUMN
                ) {
                    return userSettingsSortBy as RankingsSortType;
                } else {
                    return defaultRankingsTableSorting;
                }
            }
            return false;
        },
        [hasEmptySpacePermission],
    );
};

export const useGetRankingTableSortBy = (): RankingsSortType => {
    const { selectRankingTableSortBy } = useVoidAnalysisSelectors();
    const sortByInStore = useSelector(selectRankingTableSortBy);
    const userSettingsSortBy = useVoidRequestRankingsTableSortBy();
    const defineSortByDriveTime = useDefineSortByDriveTime();

    // user has settings to sort by median_dvt but has no appropriate permission
    const sortBy = defineSortByDriveTime(sortByInStore, userSettingsSortBy as RankingsSortType);
    if (sortBy) {
        return sortBy;
    }

    return (sortByInStore as RankingsSortType) || userSettingsSortBy || defaultRankingsTableSorting;
};

export const useGetSortingProperties = () => {
    const { order, order_by_field } = useGetRankingTableSortBy();
    const isPrintMode = useWidgetPrintMode();
    return useCallback(
        (key: RankingTableColumn) => {
            const columnKey = rankingSortTypeToTableColumn[order_by_field];
            const sortOrder = order === 'asc' ? ANTD_SORTING.ASCENDING : ANTD_SORTING.DESCENDING;

            return isPrintMode
                ? {}
                : {
                      sortDirections,
                      sorter: () => 0, //no actual sorting logic in client, property is necessary to show sorting UI
                      defaultSortOrder: columnKey === key ? sortOrder : false,
                  };
        },
        [isPrintMode, order, order_by_field],
    );
};

export const useOnTableSortByChange = () => {
    const setRankingsTableSortBy = useSetRankingsTableSortBy();
    return useCallback(
        (
            pagination: TablePaginationConfig,
            filters: Record<string, FilterValue | null>,
            sorter: SorterResult<RankingTableRowData> | SorterResult<RankingTableRowData>[],
        ) => {
            if (!Array.isArray(sorter)) {
                const { columnKey, order } = sorter;
                const keyName = tableColumnToRankingsSortType[columnKey as RankingTableColumn];
                if (keyName && order) {
                    setRankingsTableSortBy({
                        order_by_field: keyName,
                        order: sortOrderToShortSortOrder[order],
                    });
                }
            }
        },
        [setRankingsTableSortBy],
    );
};

export const useChangeSortingWhenColumnNotVisible = () => {
    const rankingTableSortBy = useGetRankingTableSortBy();
    const setRankingsTableSortBy = useSetRankingsTableSortBy();
    const columnKey = rankingSortTypeToTableColumn[rankingTableSortBy.order_by_field];
    const reportId = useReportId();
    const requestColumns = useRequestedColumns(reportId);
    useEffect(() => {
        if (columnKey && !requestColumns.includes(columnKey)) {
            //column which was sorted by is no longer visible, default to available column
            const defaultSortBy =
                rankingSortTypeToTableColumn[defaultRankingsTableSorting.order_by_field]!;
            const newSortBy = requestColumns.includes(defaultSortBy)
                ? defaultSortBy
                : requestColumns[requestColumns.length - 1];

            setRankingsTableSortBy({
                order_by_field: tableColumnToRankingsSortType[newSortBy]!,
                order: sortOrderToShortSortOrder[defaultRankingsTableSorting.order],
            });
        }
    }, [columnKey, setRankingsTableSortBy, requestColumns]);
};

export const useRankingsTableSortByWithDebounce = () => {
    const rankingTableSortBy = useGetRankingTableSortBy();
    const sortByRef = useRef<RankingsSortType>(rankingTableSortBy);
    const getRankingResults = useGetRankingResultsWithLastFilters();
    const resetTableRecordsAndCurrentPage = useResetTableRecordsAndCurrentPage();
    const onSortingChangedCallback = () => {
        if (!isEqual(rankingTableSortBy, sortByRef.current)) {
            sortByRef.current = rankingTableSortBy;
            resetTableRecordsAndCurrentPage();
            getRankingResults();
        }
    };
    const callOnChange = useDebouncedCallback(
        onSortingChangedCallback,
        FILTER_CHANGE_DEBOUNCE_TIME,
    );
    useEffect(() => {
        if (!isEqual(rankingTableSortBy, sortByRef.current)) {
            callOnChange();
        }
    }, [callOnChange, getRankingResults, rankingTableSortBy]);
};

export const useRankingTableSortingEffects = () => {
    useRankingsTableSortByWithDebounce();
    useChangeSortingWhenColumnNotVisible();
};
