/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

export type LegendIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type LegendIconProps = SVGProps<SVGSVGElement> & LegendIconUniqueProps;

export const LegendIcon = function LegendIcon({
    width = 32,
    height = 33,
    ...props
}: LegendIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 32 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect y="0.662109" width="32" height="32" rx="4" fill="white" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.282 12.6689C22.282 10.1109 20.208 8.03687 17.65 8.03687H17.631C15.073 8.03687 13 10.1109 13 12.6689C13 13.8629 13.404 14.8379 14.198 15.7699C14.991 16.7029 17.641 19.0259 17.641 19.0259C17.641 19.0259 20.29 16.7029 21.084 15.7699C21.878 14.8379 22.282 13.8629 22.282 12.6689ZM17.6403 14.3741C18.6163 14.3741 19.4073 13.5831 19.4073 12.6071C19.4073 11.6301 18.6163 10.8391 17.6403 10.8391C16.6653 10.8391 15.8743 11.6301 15.8743 12.6071C15.8743 13.5831 16.6653 14.3741 17.6403 14.3741Z"
                fill="#676DFD"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7 10.0369C7 8.72576 8.06389 7.66187 9.375 7.66187H10.75V9.4646H14.2868C14.0676 9.69341 13.8716 9.94468 13.7027 10.2146H10.75V21.4119H9.375C8.47811 21.4119 7.75 22.14 7.75 23.0369V23.1616C7.75 24.0594 8.47798 24.7866 9.375 24.7866H23.625C23.9701 24.7866 24.25 24.5072 24.25 24.1616V10.8396C24.25 10.4947 23.9699 10.2146 23.625 10.2146H21.579C21.41 9.94468 21.214 9.69341 20.9947 9.4646H23.625C24.3841 9.4646 25 10.0805 25 10.8396V24.1616C25 24.9219 24.3839 25.5366 23.625 25.5366H9.375C8.06402 25.5366 7 24.4738 7 23.1616V10.0369ZM7.75 21.3052C8.17491 20.9063 8.74656 20.6619 9.375 20.6619H10V8.41187H9.375C8.47811 8.41187 7.75 9.13997 7.75 10.0369V21.3052Z"
                fill="#49565D"
            />
            <path d="M15.6407 21.4119H19.6407V20.6619H15.6407V21.4119Z" fill="#49565D" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.65 8.03687C20.208 8.03687 22.282 10.1109 22.282 12.6689C22.282 13.8629 21.878 14.8379 21.084 15.7699C20.29 16.7029 17.641 19.0259 17.641 19.0259C17.641 19.0259 14.991 16.7029 14.198 15.7699C13.404 14.8379 13 13.8629 13 12.6689C13 10.1109 15.092 8.03687 17.65 8.03687Z"
                stroke="#49565D"
                strokeWidth="0.75"
                strokeLinecap="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.4073 12.6071C19.4073 13.5831 18.6163 14.3741 17.6403 14.3741C16.6653 14.3741 15.8743 13.5831 15.8743 12.6071C15.8743 11.6301 16.6653 10.8391 17.6403 10.8391C18.6163 10.8391 19.4073 11.6301 19.4073 12.6071Z"
                stroke="#49565D"
                strokeWidth="0.75"
                strokeLinecap="round"
            />
        </svg>
    );
};
LegendIcon.__tags = [] as string[];
