import {
    CardsKpisSelectedTypes,
    PrintCustomization,
} from 'features/void-analysis/common/types/print-customization';
import {
    MatchScoreCalculation,
    ScoreBounds,
} from 'features/void-analysis/common/types/server-responses';
// eslint-disable-next-line
import { scoreBoundsKeys } from 'features/void-analysis/sections/ranking-page/components/report-content/components/match-score-breakdown/hooks/use-score-params-configuration';

export const initialScoreBounds = scoreBoundsKeys.reduce<ScoreBounds>((acc, curr) => {
    acc[curr] = {
        min: 0,
        max: 10,
        calculated_in_match_score: true,
    };
    return acc;
}, {} as ScoreBounds);

export const initialMatchScoreCalculation: MatchScoreCalculation = {
    is_calculated: true,
    min_results: 10,
};

export const printCustomizationInitialState: PrintCustomization = {
    reportsPage: undefined,
    rankingPage: undefined,
    matchAnalysisPage: {
        cardsKpiSelected: [
            {
                key: 'store_size',
                selected: true,
            },
            {
                key: 'natural_co_tenants',
                selected: true,
            },
            {
                key: 'demographics',
                selected: true,
            },
            {
                key: 'cannibalization',
                selected: true,
            },
            {
                key: 'state_expansion_rate',
                selected: true,
            },
            {
                key: 'num_in_state',
                selected: true,
            },
            {
                key: 'nearest_venue',
                selected: true,
            },
            {
                key: 'avg_monthly_ft',
                selected: true,
            },
        ] as CardsKpisSelectedTypes[],
        dfsSimilarityParamSelected: [],
        dfsPopulationOverviewSelected: true,
    },
};
