import { useEffect } from 'react';

type MapEventListenerProps = {
    event: keyof google.maps.MapHandlerMap;
    callback: google.maps.MVCEventHandler<google.maps.Map, any>;
    map: google.maps.Map | undefined;
    unbindFromMap?: boolean;
};
export const useMapEventListener = ({
    event,
    callback,
    map,
    unbindFromMap = false,
}: MapEventListenerProps) => {
    useEffect(() => {
        let listener: google.maps.MapsEventListener | undefined;
        if (map) {
            listener = map.addListener(event, callback);
        }
        return () => {
            listener?.remove();
            map && unbindFromMap && map.unbind(event);
        };
    }, [callback, event, map, unbindFromMap]);
};
