import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { API } from 'API';
import {
    NewCommentPayload,
    SubmitOfflineReportRequestPayload,
    SetSharedClosedReportDataProps,
} from 'core/entities';

export const getOfflineReports = createAsyncThunk(
    'offline-reports/get-offline-reports',
    async () => {
        try {
            const data = await API.offlineReports.getOfflineReports();

            return data;
        } catch (error) {
            console.error('error', error);
        }
    },
);

export const getOfflineReportsSharedWithMe = createAsyncThunk(
    'offline-reports/get-offline-reports-shared-with-me',
    async ({
        enable_salesforce_data,
        ff_xtra_marketplace_embedded,
    }: {
        enable_salesforce_data: boolean;
        ff_xtra_marketplace_embedded?: boolean;
    }) => {
        try {
            const data = await API.offlineReports.getOfflineReportsSharedWithMe(
                enable_salesforce_data,
                ff_xtra_marketplace_embedded,
            );

            return data;
        } catch (error) {
            console.error('error', error);
        }
    },
);

export const getOfflineReportsUserAccountInfo = createAsyncThunk(
    'offline-reports/get-offline-reports-user-account-info',
    async () => {
        try {
            const data = await API.offlineReports.getOfflineReportsUserAccountInfo();

            return data;
        } catch (error) {
            console.error('error', error);
        }
    },
);

export const getOfflineReportDetails = createAsyncThunk(
    'offline-reports/get-offline-report-details',
    async (id: string) => {
        try {
            const data = await API.offlineReports.getOfflineReportDetails(id);
            return data;
        } catch (error) {
            console.error('error', error);
        }
    },
);

export const submitOfflineReportRequest = createAsyncThunk(
    'offline-reports/submit-offline-report-request',
    async ({
        payload,
        enable_salesforce_data,
    }: {
        payload: SubmitOfflineReportRequestPayload;
        enable_salesforce_data: boolean;
    }) => {
        try {
            const data = await API.offlineReports.submitOfflineReportRequest(
                payload,
                enable_salesforce_data,
            );

            return data;
        } catch (error) {
            console.error('error', error);
            return error;
        }
    },
);

export const getOfflineReportsConfigurations = createAsyncThunk(
    'offline-reports/get-offline-reports-configurations',
    async () => {
        try {
            const data = await API.offlineReports.getOfflineReportsConfigurations();
            return data;
        } catch (error) {
            console.error('error', error);
        }
    },
);

export const getOfflineReportsRequests = createAsyncThunk(
    'offline-reports/get-offline-reports-requests',
    async ({
        enable_salesforce_data,
        ff_xtra_marketplace_embedded,
    }: {
        enable_salesforce_data: boolean;
        ff_xtra_marketplace_embedded?: boolean;
    }) => {
        try {
            const data = await API.offlineReports.getOfflineReportsRequests(
                enable_salesforce_data,
                ff_xtra_marketplace_embedded,
            );
            return data;
        } catch (error) {
            console.error('error', error);
        }
    },
);

export const getOfflineReportsRequestsComments = createAsyncThunk(
    'offline-reports/get-offline-reports-requests-comments',
    async ({
        requestId,
        enable_salesforce_data,
    }: {
        requestId: string;
        enable_salesforce_data: boolean;
    }) => {
        try {
            const data = await API.offlineReports.getOfflineReportsRequestsComments(
                requestId,
                enable_salesforce_data,
            );
            return data;
        } catch (error) {
            console.error('error', error);
        }
    },
);

export const postOfflineReportsRequestsComment = createAsyncThunk(
    'offline-reports/post-offline-reports-requests-comment',
    async ({
        requestId,
        payload,
        enable_salesforce_data,
    }: {
        requestId: string;
        payload: NewCommentPayload;
        enable_salesforce_data: boolean;
    }) => {
        try {
            const data = await API.offlineReports.postOfflineReportsComment(
                requestId,
                payload,
                enable_salesforce_data,
            );
            return data;
        } catch (error) {
            console.error('error', error);
        }
    },
);

export const updateOfflineReportsOrderRequestReadReceipt = createAsyncThunk(
    'offline-reports/update-offline-reports-order-request-read-receipt',
    async ({
        requestId,
        flag,
        enable_salesforce_data,
    }: {
        requestId: string;
        flag: boolean;
        enable_salesforce_data: boolean;
    }) => {
        try {
            const data = await API.offlineReports.updateReadReceipt(
                requestId,
                flag,
                enable_salesforce_data,
            );
            return data;
        } catch (error) {
            console.error('error', error);
        }
    },
);

export const getOfflineReportsCreditsBalance = createAsyncThunk(
    'offline-reports/get-offline-reports-credits-balance',
    async () => {
        try {
            const data = await API.offlineReports.getCreditsBalance();
            return data;
        } catch (error) {
            console.error('error', error);
        }
    },
);

export const clearOfflineReportsRequestComments = createAction(
    'offline-reports/post-offline-reports-requests-clear-comments',
);

export const clearOfflineReportsSubmitStatus = createAction(
    'offline-reports/submit-offline-report-request-clear-submit-status',
);

export const openInsufficientCreditsPopup = createAction(
    'offline-reports/open-insufficient-credits-popup',
);

const setIsSubmitLoadingPopupVisibleAction = 'offline-reports/set-is-submit-loading-popup-visible';
export const setIsSubmitLoadingPopupVisible = createAction<boolean>(
    setIsSubmitLoadingPopupVisibleAction,
);

const setSharedDataClosedReportsAction = 'offline-reports/set-shared-data-closed-reports';
export const updateClosedReportSharedAndTargetValues = createAction<SetSharedClosedReportDataProps>(
    setSharedDataClosedReportsAction,
);

const clearReportDetailsAction = 'offline-reports/clear-report-details';
export const clearReportDetails = createAction(clearReportDetailsAction);
