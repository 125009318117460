import { isArray } from 'lodash';
import { createSelector } from '@reduxjs/toolkit';
import mapValues from 'lodash/mapValues';
import { FILTERS_BY_DURATION } from 'shared/constants/visit-duration';
import { fromBackendFilters } from 'features/insights/utils/convert-filters/convert-filters';
import { AppState } from 'store/store';
import { VisitDurationSegments } from 'types/visit-duration';
import { PoiByIdState } from 'features/insights/store/poi/poi-types';
import { PoiWithDistance } from 'features/mobile-report/types/types';
import type { Attributes, DateLabels, FilterType, PlacerEntityModel } from '@placer-ui/types';
import {
    WidgetPoiProviderType,
    WidgetPOIsDictionary,
} from 'extensions/widget/models/widget-poi-provider-type';
import { mapEntityWrapperToEntityModel } from 'core/entities';
import { createSelectHasNotPurchasedEntity } from 'store/entities/selectors';
import { useGetInsightsPrintEntitiesParser } from 'features/insights/store/parse-insights-print-entities';
import { areDatesEqual } from 'utils/date/date';

const selectPoi = (state: AppState) => state.poi;
export const selectIds = createSelector(selectPoi, (poi) => poi?.ids);

export const selectIsReportLoading = createSelector(selectPoi, (poi) => poi.isLoading);

export const selectIsReportInitialized = createSelector(selectPoi, (poi) => poi.isInitialized);

export const selectReportError = createSelector(selectPoi, (poi) => poi.error);

export const selectReportDefaultDates = createSelector(selectPoi, (poi) => poi.defaultDates);

export const poiSelector = createSelector(selectPoi, (poi) => {
    return poi?.entities;
});

export const selectAllPoisDateReportEquals = createSelector(poiSelector, (poisMap) => {
    const poisDateRanges = Object.keys(poisMap).map((id) => poisMap[id].filter.date);
    if (poisDateRanges.length) {
        const referenceRange = poisDateRanges[0];
        return !poisDateRanges.some(
            ({ start, end }) =>
                !(
                    areDatesEqual(start, referenceRange.start) &&
                    areDatesEqual(end, referenceRange.end)
                ),
        );
    }
    return true;
});

export const selectInsightsById = (id: string | number) =>
    createSelector(poiSelector, (pois) => {
        return pois[id];
    });

export const allPoisSelectors = createSelector(poiSelector, selectIds, (pois, allIds) => {
    return allIds.map((poiID) => pois[poiID]);
});

export const allVenuesDataSelector = createSelector(allPoisSelectors, (poisSubState) =>
    poisSubState.map((poiMap) => {
        return poiMap.originalEntity;
    }),
);

export const allVenuesOriginalShapeSelector = createSelector(allPoisSelectors, (poisSubState) =>
    poisSubState.map((poiMap) => {
        return poiMap.originalEntity.originalShape.info as PoiWithDistance;
    }),
);

export const poisFiltersSelector = createSelector(allPoisSelectors, (poisSubState) =>
    poisSubState.map((poiMap) => {
        return poiMap.filter;
    }),
);

export const poisFiltersWithDefaultsSelector = createSelector(allPoisSelectors, (poisSubState) =>
    poisSubState.map((poiMap) => {
        const defaultFiltersAttributes = (poiMap.originalEntity.info?.default_filters ||
            []) as Attributes;
        const defaultDurationFilter = poiMap.originalEntity.info
            ?.default_profile_name as VisitDurationSegments;
        const defaultVisitDurationAttributes = defaultDurationFilter
            ? [FILTERS_BY_DURATION[defaultDurationFilter]]
            : [];

        const defaultAttributes = [
            ...defaultFiltersAttributes,
            ...defaultVisitDurationAttributes,
        ] as Attributes;

        if (defaultAttributes[0] !== 'all') {
            defaultAttributes.unshift('all');
        }
        const defaultFilters = fromBackendFilters([
            {
                attributes: defaultAttributes,
            },
        ])[0];

        const filters = fromBackendFilters([poiMap.filter])[0];

        for (const key in defaultFilters) {
            const type = key as FilterType;
            const attributeExists = type in filters;
            if (!attributeExists) {
                filters[type] = {
                    ...defaultFilters[type]!,
                    value: isArray(defaultFilters[type]?.value)
                        ? (defaultFilters[type]!.value as string[]).sort()
                        : defaultFilters[type]!.value,
                    isDefaultFilter: true,
                };
            }
        }
        return filters;
    }),
);

export const isLimitedViewSelector = createSelectHasNotPurchasedEntity(allVenuesDataSelector);

export const selectInsightsTotal = createSelector(selectIds, (pois) => {
    return pois.length;
});

const selectEntitiesAPIModel = createSelector(poiSelector, (pois): WidgetPOIsDictionary => {
    //@ts-expect-error PLAC-47814
    return mapValues(pois, (poi) => {
        const filterConfig = poi.filter.config
            ? {
                  config: poi.filter.config,
              }
            : undefined;

        return {
            uid: poi.originalEntity.uid,
            poiId: poi.originalEntity.id,
            type: poi.originalEntity.type,
            color: poi.originalEntity.customData.color.regular,
            filters: {
                date_start: poi.filter.date.start,
                date_end: poi.filter.date.end,
                attributes: poi.filter.attributes,
                ...filterConfig,
            },
            fullAddress: poi.originalEntity.fullAddress,
            shortAddress: poi.originalEntity.shortFormattedAddress,
            name: poi.originalEntity.name,
            address: poi.originalEntity.address,
            subCategory: poi.originalEntity.originalShape.info.sub_category,
            categoryInfo: poi.originalEntity.originalShape.info.category_info,
            storeId: poi.originalEntity.originalShape.info.store_id,
            defaultProfileName: poi.originalEntity.originalShape.info.default_profile_name,
            isPosie: poi.originalEntity.originalShape.info.isPosie,
        };
    });
});

const mapRawEntitiesToEntitiesModel = (entities: PoiByIdState) => {
    return Object.keys(entities).reduce<{
        [uid: string]: PlacerEntityModel<any>;
    }>((entitiesModel, uid) => {
        const rawEntity = entities[uid];

        entitiesModel[uid] = mapEntityWrapperToEntityModel({
            uid,
            entityWrapper: rawEntity.originalEntity.originalShape,
            startDate: rawEntity.originalEntity.customData.filter.date.start,
            endDate: rawEntity.originalEntity.customData.filter.date.end,
            dateLabel: rawEntity.filter.date.chosenLabel as DateLabels,
            color: rawEntity.originalEntity.customData.color.regular,
            attributes: rawEntity.originalEntity.customData.filter.attributes,
            config: rawEntity.originalEntity.customData.filter.config,
            coverage: rawEntity.originalEntity.coverage,
        });

        return entitiesModel;
    }, {});
};

const selectEntities = createSelector(poiSelector, (pois) => {
    return mapRawEntitiesToEntitiesModel(pois);
});

const selectAll = createSelector(selectIds, selectEntities, (ids, entities) => {
    return ids.map((id) => entities[id]);
});

export const createInsightsEntityAdapter = (): WidgetPoiProviderType => {
    return {
        adapterType: 'insights',
        selectAll,
        selectIds,
        selectEntities,
        selectTotal: selectInsightsTotal,
        selectById: selectInsightsById,
        selectEntitiesAPIModel,
        selectRawEntities: selectPoi,
        useGetInitializeEntityStoreCallback: useGetInsightsPrintEntitiesParser,
    };
};

export const insightsEntityAdapter = createInsightsEntityAdapter();
