import queryString from 'query-string';
import { PlacerEntityWrapper, Tag, Place, PlacerResponseData } from '@placer-ui/types';
import type {
    CreateTagPayload,
    CreateTagResponse,
    UpdateTagPayload,
    UpdateTagResponse,
} from 'features/my-zone/tags/create-new-tag/types/server-responses';
import { fetchWrapper } from 'API/authentification/fetch-wrapper';
import { GetPlacesForTagResponse, GetAllTags } from 'types/tag';
import { UserPermissions } from 'core/entities/user/user-permissions';
import { hasFeatureFlags } from 'core/flow-control';
import { searchAnyPoi } from 'API/search';

export type GetPlacesForTagQuery = {
    id: string;
    startDate: string;
    endDate: string;
    compareStartDate: string;
    compareEndDate: string;
    appKey?: string;
    enable_not_purchased?: boolean;
};

export type DeleteMemberFromTagRequest = {
    memberId: string;
    tagId: string;
};

export type GetPlaceForTagQueryWithoutComparisonData = Omit<
    GetPlacesForTagQuery,
    'compareStartDate' | 'compareEndDate' | 'visitDuration'
>;

const searchVenueSuggestions = async (userPermissions?: UserPermissions, query?: string) => {
    const hasNearbyInTags = hasFeatureFlags(['enable_nearby_in_tags_and_portfolio_ff']);

    return searchAnyPoi({
        term: query,
        source: 'tags',
        enableClosedPOIs: true,
        enableCustomPOIs: true,
        enableNearbyActivityPOIs: hasNearbyInTags,
        enableFlaggedPOIs: hasNearbyInTags,
    });
};

export const tagsApi = {
    getVenueSuggestions: async (
        query: string,
        userPermissions?: UserPermissions,
    ): Promise<PlacerEntityWrapper<Place>[]> => {
        const searchTerm = query.trim() === '' ? undefined : query;
        const response = searchVenueSuggestions(userPermissions, searchTerm);
        let venuesList: PlacerEntityWrapper<Place>[] = [];
        await response
            .then(({ data }) => {
                venuesList = data as PlacerEntityWrapper<Place>[];
            })
            .catch(() => {});
        return Promise.resolve(venuesList);
    },

    createTag: async (
        payload: CreateTagPayload,
    ): Promise<PlacerResponseData<CreateTagResponse>> => {
        return fetchWrapper({
            targetUrl: '/tags/create',
            method: 'POST',
            body: payload,
        });
    },

    updateTag: async ({
        id,
        ...body
    }: UpdateTagPayload): Promise<PlacerResponseData<UpdateTagResponse>> => {
        return fetchWrapper({
            targetUrl: `/tags/${id}/update`,
            method: 'POST',
            body,
        });
    },

    getAllTags: async (): Promise<Tag[]> => {
        return fetchWrapper<GetAllTags>({
            targetUrl: '/tags',
        }).then((res) => {
            return res.data as Tag[];
        });
    },

    getPlacesForTag: async (
        query: GetPlacesForTagQuery,
    ): Promise<[PlacerEntityWrapper<Place>[], PlacerEntityWrapper<Place>[]]> => {
        const fetchPrevious = fetchWrapper<GetPlacesForTagResponse>({
            // eslint-disable-next-line max-len
            targetUrl: `/tags/${query.id}/?end_date=${query.compareEndDate}&start_date=${query.compareStartDate}`,
        }).then((res) => {
            return res.data as PlacerEntityWrapper<Place>[];
        });

        const fetchCurrent = fetchWrapper<GetPlacesForTagResponse>({
            // eslint-disable-next-line max-len
            targetUrl: `/tags/${query.id}/?end_date=${query.endDate}&start_date=${query.startDate}`,
        }).then((res) => {
            return res.data as PlacerEntityWrapper<Place>[];
        });

        const res = Promise.all([fetchCurrent, fetchPrevious]);

        return res;
    },

    deleteTag: async (
        tagId: string,
    ): Promise<[PlacerEntityWrapper<Place>[], PlacerEntityWrapper<Place>[]]> => {
        return fetchWrapper({
            targetUrl: `/tags/${tagId}/delete`,
            method: 'DELETE',
        });
    },

    deleteMemberFromTag: async ({
        memberId,
        tagId,
    }: DeleteMemberFromTagRequest): Promise<
        [PlacerEntityWrapper<Place>[], PlacerEntityWrapper<Place>[]]
    > => {
        return fetchWrapper({
            targetUrl: `/tags/delete_member?member_id=${memberId}&tag_id=${tagId}`,
            method: 'POST',
        });
    },

    getPlacesForTagWithoutComparisonData: async (
        query: GetPlaceForTagQueryWithoutComparisonData,
    ): Promise<PlacerEntityWrapper<Place>[]> => {
        const { endDate, startDate, appKey } = query;
        let targetUrl = `/tags/${query.id}/?${queryString.stringify({
            start_date: startDate,
            end_date: endDate,
            ...(appKey
                ? {
                      app_key: appKey,
                      enable_not_purchased: true,
                  }
                : {}),
        })}`;

        return fetchWrapper<GetPlacesForTagResponse>({
            targetUrl,
        }).then((res) => {
            return res.data as PlacerEntityWrapper<Place>[];
        });
    },
};
