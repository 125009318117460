import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';

import { dateFormats } from 'utils/date/date';
import { RecentReportUIShape } from '../models';
import { generateChainLink, generatePropertyLink } from 'router/links';
import {
    userCustomSettingsChainSelector,
    userCustomSettingsIndustrySelector,
    userCustomSettingsInsightsSelector,
    userSelector,
} from 'store/selectors/app-selectors';
import type { PropertyReportEntity } from '@placer-ui/types';

const pickTime = (timestamp: string) => {
    const today = moment();
    const yesterday = moment().subtract(1, 'day').endOf('day');
    const timestampMoment = moment(timestamp);

    if (timestampMoment.isSame(today, 'day')) {
        return timestampMoment.format('hh:mm a');
    }

    if (timestampMoment.isSame(yesterday, 'day')) {
        return 'Yesterday';
    }

    if (timestampMoment.isSame(today, 'week')) {
        return timestampMoment.format('dddd');
    }

    return timestampMoment.format(dateFormats.monthHour);
};

export const selectPropertyDefaultReport = createSelector(userSelector, (user) => {
    return user?.defaultVenue;
});

export const selectPropertyRecentReports = createSelector(
    userCustomSettingsInsightsSelector,
    (recentReports): RecentReportUIShape[] | null => {
        if (!recentReports) {
            return null;
        }

        return recentReports
            .filter(({ data }) => data)
            .map(({ timestamp, data }) => {
                const { id, name, locationLabel, competitor: competitors, filter } = data;

                const competitor = competitors?.length ? competitors[0] : null;
                const entitiesNumber = competitors ? competitors.length + 1 : 1;

                return {
                    filter,
                    competitor,
                    timestamp: pickTime(timestamp),
                    link: generatePropertyLink(data),
                    primaryEntity: {
                        id,
                        name,
                        locationLabel,
                    },
                    entitiesNumber,
                    data,
                };
            });
    },
);

export const selectPropertyDefaultReportParams = createSelector(
    selectPropertyRecentReports,
    selectPropertyDefaultReport,
    (recentReports, defaultReport): Partial<PropertyReportEntity> => {
        const lastViewedReportData = recentReports && recentReports[0]?.data;

        if (lastViewedReportData) {
            return lastViewedReportData;
        }

        return {
            id: defaultReport?.id || 'error',
            collection: defaultReport?.collection || 'error',
        };
    },
);

export const selectPropertyDefaultReportURL = createSelector(
    selectPropertyRecentReports,
    selectPropertyDefaultReport,
    (recentReports, defaultReport) => {
        const lastViewedReportLink = recentReports && recentReports[0]?.link;
        return lastViewedReportLink
            ? lastViewedReportLink.pathname + lastViewedReportLink.search
            : `/insights/${defaultReport?.collection}/${defaultReport?.id}/overview`;
    },
);

export const selectChainRecentReports = createSelector(
    userCustomSettingsChainSelector,
    (recentReports) => {
        if (!recentReports) {
            return null;
        }

        return recentReports.map(({ timestamp, data }) => {
            return {
                timestamp: pickTime(timestamp),
                link: generateChainLink(data),
                basename: '/chains',
                primaryEntity: data[0],
                competitor: data[1] || null,
                entitiesNumber: data.length,
            };
        });
    },
);

export const selectIndustryRecentReports = createSelector(
    userCustomSettingsIndustrySelector,
    (recentReports) => {
        if (!recentReports) {
            return null;
        }

        return recentReports.map(({ timestamp, data }) => {
            return {
                timestamp: pickTime(timestamp),
                link: generateChainLink(data),
                basename: '/industry',
                primaryEntity: data[0],
                competitor: data[1] || null,
                entitiesNumber: data.length,
            };
        });
    },
);
