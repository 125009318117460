/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';
import { IconProps } from 'components/assets/Icons/Icons';

export const TheatersAndMusicVenuesIconSvg = (
    props: CustomIconComponentProps | SVGProps<SVGSVGElement>,
) => (
    <svg {...props} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.783 5.03036C11.7621 4.94707 11.7069 4.87674 11.6314 4.83612C11.5561 4.79577 11.467 4.78892 11.3863 4.81735C10.6781 5.06659 9.58535 5.22698 8.3564 5.22698C8.05762 5.22698 7.76966 5.21555 7.49047 5.19756C7.34611 5.85092 7.11543 6.45083 6.8208 6.9792C6.83512 6.97861 6.84881 6.97627 6.86327 6.97627C7.24516 6.97627 7.55466 6.94436 7.55466 7.20205C7.55466 7.45975 7.24514 7.90953 6.86327 7.90953C6.64082 7.90953 6.44497 7.83742 6.3186 7.72729C5.93693 8.20196 5.49105 8.57625 5 8.82376C5.55364 10.6905 6.84803 12 8.3564 12C10.3687 12 12 9.66959 12 6.79481C12 6.17469 11.9227 5.58129 11.783 5.03036ZM9.80844 10.4802C9.80198 10.5076 9.78161 10.53 9.75484 10.5388C9.72801 10.5477 9.6985 10.5421 9.67695 10.5238C9.39311 10.2849 8.91027 10.1262 8.35643 10.1262C7.80253 10.1262 7.31969 10.2849 7.03587 10.5238C7.01431 10.5419 6.98477 10.5476 6.95799 10.5388C6.93119 10.5297 6.91085 10.5076 6.90439 10.4802C6.8809 10.38 6.86644 10.2767 6.86644 10.1693C6.86644 9.345 7.53358 8.67676 8.35645 8.67676C9.17933 8.67676 9.84641 9.34503 9.84641 10.1693C9.84636 10.2767 9.83191 10.38 9.80844 10.4802ZM9.84951 7.90951C9.46762 7.90951 9.15812 7.45972 9.15812 7.20203C9.15812 6.94434 9.46764 6.97625 9.84951 6.97625C10.2314 6.97625 10.5409 7.18518 10.5409 7.44288C10.5409 7.70057 10.2314 7.90951 9.84951 7.90951Z"
            fill="currentColor"
        />
        <path
            d="M7 1.97888C7 1.36373 6.92575 0.774996 6.79159 0.228442C6.77149 0.145815 6.71849 0.0759875 6.64594 0.0357469C6.60309 0.0120419 6.55554 0 6.50761 0C6.47489 0 6.44202 0.00557915 6.41047 0.0171161C5.73017 0.264391 4.68057 0.42346 3.50002 0.42346C2.31946 0.42346 1.26985 0.264391 0.589552 0.0171161C0.558172 0.00557915 0.525475 0 0.492777 0C0.444878 0 0.397101 0.0121681 0.354058 0.0359489C0.281709 0.0761894 0.228539 0.145992 0.208409 0.228442C0.0742521 0.775021 0 1.36373 0 1.97888C0 4.83098 1.56696 7.14297 3.50002 7.14297C5.43304 7.14297 7 4.83098 7 1.97888ZM1.40157 2.62071C1.40157 2.365 1.69889 2.15777 2.06574 2.15777C2.43256 2.15777 2.72988 2.60395 2.72988 2.85965C2.72988 3.11531 2.43256 3.08365 2.06574 3.08365C1.69889 3.08365 1.40157 2.87636 1.40157 2.62071ZM3.50002 6.1667C2.70956 6.1667 2.06876 5.50371 2.06876 4.68598C2.06876 4.57942 2.08265 4.47692 2.10519 4.37756C2.11139 4.3504 2.13096 4.32841 2.15668 4.31942C2.18245 4.31068 2.2108 4.31631 2.23149 4.33421C2.50415 4.57124 2.96796 4.72872 3.5 4.72872C4.03204 4.72872 4.49585 4.57124 4.76848 4.33421C4.7892 4.31611 4.81755 4.31051 4.8433 4.31942C4.86904 4.3282 4.88859 4.3504 4.89478 4.37756C4.91733 4.47692 4.93124 4.57942 4.93124 4.68598C4.93124 5.50371 4.29044 6.1667 3.50002 6.1667ZM4.27012 2.85965C4.27012 2.60395 4.56744 2.15777 4.93429 2.15777C5.30111 2.15777 5.59843 2.365 5.59843 2.62071C5.59843 2.87636 5.30111 3.08365 4.93429 3.08365C4.56744 3.08365 4.27012 3.11531 4.27012 2.85965Z"
            fill="currentColor"
        />
    </svg>
);

export const TheatersAndMusicVenuesIcon = (props: IconProps) => (
    <Icon component={TheatersAndMusicVenuesIconSvg} {...props} />
);
