import React, { useState, ComponentType, ReactEventHandler, forwardRef } from 'react';
import { Loading } from 'ui-components/loading/loading-component';
import styles from './with-loading.module.scss';

/**
 * A Higher-Order Component that adds a loading indicator to the wrapped component.
 * It displays a loading spinner until the wrapped component triggers its `onLoad` event,
 * indicating that it has finished loading its content.
 *
 * @param WrappedComponent - The component to be wrapped with the loading functionality.
 * @returns A new component with integrated loading behavior.
 */

export type WithLoadingProps = {
    onLoad?: ReactEventHandler<HTMLIFrameElement>;
}
const withLoading = <P extends object>(WrappedComponent: ComponentType<P & WithLoadingProps>) => {
  return forwardRef((props: P & WithLoadingProps, ref) => {
    const [loading, setLoading] = useState(true);

    const handleLoad: ReactEventHandler<HTMLIFrameElement> = (event) => {
      setLoading(false);
      if (props.onLoad) {
        props.onLoad(event);
      }
    };

    const combinedProps = {
      ...props,
      onLoad: handleLoad,
    };

    return (
      <>
        {loading && <Loading show={loading} className={styles.loadingPage} />}
        <WrappedComponent ref={ref} {...combinedProps} />
      </>
    );
  });
};

export default withLoading;
