import { userCookieStorage } from 'core/authentification/token-storage';
import { requestDataWithStatus } from 'API/common/poll-request/poll-request';
import generateID from 'utils/generate-id/generate-id';
import type { Dictionary } from '@placer-ui/types';
import { reportException } from 'core/exceptions';
import { getAppApiEndpoint } from 'core/default-endpoint-url';
import { getFeatureFlags } from 'core/flow-control';
import { isFetchReportCachedSignal } from 'features/insights/store/signals/insights.signals';

const methodsUsedWithJSON = ['POST', 'PUT', 'PATCH'];

export interface FetchProps<BodyType> {
    targetUrl: string;
    basePath?: string;
    method?: 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE';
    fetchID?: string;
    endpoint?: string;
    body?: BodyType;
    headers?: Dictionary<string>;
    retryMsDelay?: number;
    signal?: AbortSignal;
    customToken?: string;
}

interface FetchWithBasePath {
    passedURL: string;
    options?: RequestInit;
    basePath?: string;
    isTokenRequired?: boolean;
    baseUrl?: string;
}

export const { REACT_APP_API_ENDPOINT } = process.env;
export const BASE_URL = `${REACT_APP_API_ENDPOINT}`;
export const DEFAULT_BASE_PATH = '/2/guru';

export class UserHasNoTokenError extends Error {
    constructor() {
        super('User has no token');
        this.name = 'UserHasNoTokenError';
    }
}

export async function fetchWrapper<DataType = any, BodyType = unknown>(
    {
        method = 'GET',
        basePath = DEFAULT_BASE_PATH,
        targetUrl,
        fetchID,
        body,
        headers,
        endpoint = getAppApiEndpoint(),
        retryMsDelay,
        signal,
        customToken,
    }: FetchProps<BodyType>,
    isTokenRequired: boolean = true,
): Promise<DataType> {
    const token = !customToken ? userCookieStorage.token : customToken;
    const baseUrl = endpoint + basePath;
    const url = baseUrl + targetUrl;
    const { enable_property_page_loading_time } = getFeatureFlags();

    if (isTokenRequired && !token) {
        throw new UserHasNoTokenError();
    }
    const onApiRequestStatus = (isCached: boolean = true) => {
        if (!enable_property_page_loading_time) return;

        if (targetUrl.includes('/fullpage-wait')) {
            isFetchReportCachedSignal.value = isCached;
        }
    };

    const requestParams = {
        targetUrl: url,
        method,
        token,
        body,
        headers,
        fetchID: fetchID || generateID(),
        retryMsDelay,
        signal,
        onApiRequestStatus,
    };

    try {
        return await requestDataWithStatus(requestParams);
    } catch (error) {
        const errorByUid = error as any;
        reportException(new Error(`fetch error, errorType ${errorByUid?.common?.type}`), {
            payload: {
                ...requestParams,
                request_id: errorByUid?.common?.info?.request_id,
                status: errorByUid?.common?.status || errorByUid?.status,
                type: errorByUid?.common?.type || errorByUid?.type,
                details: errorByUid?.common?.info?.details,
            },
            target_url: url,
            method,
            level: 'error',
            message: errorByUid?.common?.status + ' - ' + errorByUid?.common?.type,
        });

        return Promise.reject(errorByUid);
    }
}

export const fetchWithBasePath = async ({
    passedURL,
    options = {},
    basePath = DEFAULT_BASE_PATH,
    isTokenRequired = true,
    baseUrl = getAppApiEndpoint(),
}: FetchWithBasePath) => {
    const token = userCookieStorage.token;
    const baseUrlFetch = baseUrl + basePath;

    const headers: HeadersInit = {};

    if (isTokenRequired) {
        if (!token) {
            throw new UserHasNoTokenError();
        }
        headers['Authorization'] = `Token ${token}`;
    }

    const URL = baseUrlFetch + passedURL;

    if (options.method && methodsUsedWithJSON.includes(options.method)) {
        headers['Content-Type'] = 'application/json';
    }

    const response = await fetch(URL, {
        ...options,
        headers: {
            ...headers,
            ...options.headers,
        },
    });

    if (response.ok || response.status === 500) {
        if (response.status === 500) {
            reportException(response, {
                payload: {
                    ...options,
                    ...headers,
                    ...options.headers,
                },
                method: options.method,
                status_code: 500,
                target_url: URL,
            });
        }
        return response;
    }
    reportException(response, {
        payload: {
            ...options,
            ...headers,
            ...options.headers,
        },
        method: options.method,
        status_code: response.status,
        target_url: URL,
    });
    throw response;
};
