import { fetchWithBasePath, fetchWrapper } from 'API/authentification/fetch-wrapper';
import type { Dictionary, PlacerResponseData, PlaceType, Venue } from '@placer-ui/types';
import { RequestFilterEntity } from 'features/void-analysis/common/types/request-filters';
import {
    CreateIdResponse,
    ReportMetadata,
    RecentReport,
    EntityData,
    AdvancedSettingsResponse,
    AdvancedSettingsRequestBody,
    ReportsStatusResponse,
    RankingResultsResponse,
} from 'features/void-analysis/common/types/server-responses';
import { RankingsSortType } from 'features/void-analysis/common/types/ranking-table';
import { stopFetchByIDs } from 'API/common/poll-request/poll-request';
import generateID from 'utils/generate-id/generate-id';
import { simplePollRequest } from 'API/fetch/simple-fetch-no-error-handling';
import { getAppApiEndpoint, getAppGatewayEndpoint } from 'core/default-endpoint-url';
import queryString from 'querystring';
import { BBox } from 'geojson';
import { Primitive } from 'types/primitive';

export type RankingsRequestBody = {
    filters: RequestFilterEntity[];
    sort?: RankingsSortType;
    use_defaults?: boolean;
};

export type AdvancedFiltersRequestBodyDeprecated = {
    advanced_settings: RequestFilterEntity[];
};

//add any api action name to any call which passes fetchID to fetchWrapper()
const fetchIds: { [key: string]: string } = {
    getRankingResults: generateID(),
};

export type ApiFetchIDs = 'getRankingResults';

export const API_VERSION = 'v1';
export const API_V2 = 'v2';
export const REPORTS_ROUTE = `void-analysis/${API_V2}/reports`;

export type GetRankingResultType = {
    id: string;
    page: number;
    page_size: number;
    requestParams: RankingsRequestBody;
};

export type GetRankingResultCsvType = {
    reportId: string;
    /** how to filter */
    filters?: RequestFilterEntity[];
    /** should use default filters */
    use_defaults?: boolean;
    /** include filters inside the CSV */
    filters_data_state: 'no_filters' | 'single_file' | 'separate_file';
    /** include contact info inside the CSV */
    include_contact_info: boolean;
    filename?: string;
};

type Entity = {
    id: string;
    type: string;
};

export type CreateReportProps = {
    entity: Entity;
    name: string;
    ta_method?: string;
    ta_entity: {
        id?: string;
        type?: string;
    };
};

export const voidAnalysisApi = {
    getRecentReports: async (): Promise<RecentReport[]> => {
        return fetchWrapper({
            endpoint: getAppGatewayEndpoint(),
            basePath: '/void-analysis',
            targetUrl: '/reports',
        });
    },
    getReportMetadata: async (id: string): Promise<ReportMetadata> => {
        return fetchWrapper({
            endpoint: getAppGatewayEndpoint(),
            basePath: '/void-analysis',
            targetUrl: `/reports/${id}`,
        });
    },
    getEntityData: (reportId: string, entity: Entity): Promise<EntityData> => {
        const targetUrl = `/reports/${reportId}/brand/${entity.type}/${entity.id}`;

        return fetchWrapper({
            endpoint: getAppGatewayEndpoint(),
            basePath: '/void-analysis',
            targetUrl,
        });
    },
    fetchChainVenues: (
        chainId: string,
        boundingBox: BBox,
        type?: PlaceType,
    ): Promise<PlacerResponseData<Venue[]>> => {
        const queryParams: Dictionary<Primitive> = {
            type: 'venues',
            ne: `${boundingBox[0]}, ${boundingBox[1]}`,
            sw: `${boundingBox[2]}, ${boundingBox[3]}`,
            limit: 200,
            date_preset: 'last-12-months',
            enable_closed_pois: false,
            source: 'void_match_analysis_cannibalization_map',
        };

        if (type === 'venue') {
            queryParams.query = chainId;
        } else {
            queryParams.chain_ids = chainId;
        }

        return fetchWrapper({
            endpoint: getAppApiEndpoint(),
            basePath: '/2/guru/search?',
            targetUrl: `${queryString.stringify(queryParams)}`,
        });
    },
    getRankingResults: async ({
        id,
        page,
        page_size,
        requestParams,
    }: GetRankingResultType): Promise<RankingResultsResponse> => {
        const requestBody = {
            ...requestParams,
            pagination: {
                page,
                page_size,
            },
        };

        return fetchWrapper({
            endpoint: getAppGatewayEndpoint(),
            basePath: '/void-analysis',
            targetUrl: `/reports/${id}/top-chains`,
            method: 'POST',
            body: requestBody,
            fetchID: fetchIds['getRankingResults'],
        });
    },
    rebuildRankingsReport: async (
        reportId: string,
    ): Promise<PlacerResponseData<CreateIdResponse>> => {
        return fetchWrapper({
            endpoint: getAppGatewayEndpoint(),
            basePath: '/void-analysis',
            targetUrl: `/reports/${reportId}/rebuild`,
            method: 'POST',
        });
    },
    getReportStatus: (reportId: string): Promise<ReportsStatusResponse> => {
        return simplePollRequest({
            endPoint: getAppGatewayEndpoint(),
            basePath: '/void-analysis',
            targetUrl: `/reports/${reportId}/status`,
            method: 'GET',
        });
    },
    getReportStatusDeprecated: (
        reportId: string,
    ): Promise<PlacerResponseData<ReportsStatusResponse>> => {
        return simplePollRequest({
            targetUrl: `/${REPORTS_ROUTE}/${reportId}/status`,
            method: 'GET',
        });
    },
    getRankingsResultCsv: ({
        reportId,
        filters,
        use_defaults,
        filters_data_state,
        include_contact_info,
        filename,
    }: GetRankingResultCsvType): Promise<Response> => {
        return fetchWithBasePath({
            passedURL: `/void-analysis/orders/${reportId}/export-to-csv`,
            options: {
                method: 'POST',
                body: JSON.stringify({
                    filters,
                    use_defaults,
                    filters_data_state,
                    include_contact_info,
                    filename,
                }),
            },
        });
    },
    getAdvancedSettings: async (reportId: string): Promise<AdvancedSettingsResponse> => {
        return fetchWrapper({
            endpoint: getAppGatewayEndpoint(),
            basePath: '/void-analysis',
            targetUrl: `/reports/${reportId}/score-config`,
        });
    },
    updateAdvancedSettings: async (
        reportId: string,
        requestParams: AdvancedSettingsRequestBody,
    ): Promise<AdvancedSettingsResponse> => {
        return fetchWrapper({
            endpoint: getAppGatewayEndpoint(),
            basePath: '/void-analysis',
            targetUrl: `/reports/${reportId}/score-config`,
            body: requestParams,
            method: 'POST',
        });
    },
    createReport: (reportInfo: CreateReportProps): Promise<CreateIdResponse> => {
        return fetchWrapper({
            endpoint: getAppGatewayEndpoint(),
            basePath: '/void-analysis',
            targetUrl: '/reports',
            method: 'POST',
            body: reportInfo,
        });
    },
    stopFetching: (actionName: ApiFetchIDs) => {
        stopFetchByIDs([fetchIds[actionName]]);
    },
};
