import moment from 'moment';
import { CreatePOIProps } from 'API/my-zone-api';
import { PoiWithDistance } from 'features/mobile-report/types/types';
import { PlaceOverlay } from 'ui-components/google-map-layers/types/google-map-layers-types';
import { PlacePOI } from 'core/entities';
import { ChartData } from 'chart.js';
import { Granularity } from 'core/constants/granularity';
import type { ExternalVenueProviders, VenuePlaceTypes } from '@placer-ui/types';

export const isMac = /iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent);

export const pinColors = [
    '#5e63e5',
    '#ef5771',
    '#55b1a7',
    '#ffbe5e',
    '#4e5136',
    '#b05420',
    '#6fa2ce',
    '#d6ac84',
];

export const DAY_OF_THE_WEEK_TO_BIN: Record<string, number> = {
    6: 0,
    0: 1,
    1: 2,
    2: 3,
    3: 4,
    4: 5,
    5: 6,
};

export const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

export const checkNamesLength = (list: string[]) => !list.some((name) => name.length > 10);

export const fullPageDrawerHeight = ({ isFullScreen }: { isFullScreen: boolean }) => {
    const windowHeight = window.innerHeight;
    const percentageHeight = isFullScreen ? 1 : 0.9;
    return windowHeight * percentageHeight;
};

export const convertGooglePoiToPoi = (poi: PoiWithDistance | PlaceOverlay): CreatePOIProps => {
    return {
        type: poi.type as VenuePlaceTypes,
        name: poi.name,
        address: poi.address,
        category: PlacePOI.getPrimaryCategory(poi) || 'Region',
        sub_category: PlacePOI.getSubCategory(poi) || 'Region',
        geojson: poi.geojson,
        provider: poi.provider_data?.provider as ExternalVenueProviders,
    };
};

export const updateChartDatasets = (
    data: ChartData,
    granularity: Granularity,
    filteredDays: string[],
) => {
    return data.datasets.map((dt) => {
        if (granularity === 'day' && filteredDays.length) {
            const filteredIndexDays = filteredDays.map((day) => DAY_OF_THE_WEEK_TO_BIN[day]);
            const newPointData = dt.data.map((point: any) => {
                if (!filteredIndexDays.includes(moment(point.date).day())) {
                    return {
                        ...point,
                        y: 0.0e-10,
                        // we are using 0.0e-10 which is almost zero because it needs to distinguished from
                        // an actual zero but be shown near to zero
                    };
                }
                return point;
            });
            return {
                ...dt,
                data: newPointData,
            };
        }

        return dt;
    });
};

export const moveArrayElement = <T>(array: T[], fromIndex: number, toIndex: number) => {
    if (
        fromIndex === toIndex ||
        fromIndex < 0 ||
        toIndex < 0 ||
        fromIndex >= array.length ||
        toIndex >= array.length
    ) {
        return array;
    }

    const newArray = [...array];
    const element = newArray.splice(fromIndex, 1)[0];
    newArray.splice(toIndex, 0, element);

    return newArray;
};
