/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

type LocationPinIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type LocationPinIconProps = SVGProps<SVGSVGElement> & LocationPinIconUniqueProps;

export const LocationPinIcon = function LocationPinIcon({
    width = 36,
    height = 45,
    ...props
}: LocationPinIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 36 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g filter="url(#filter0_d_23025_543976)">
                <circle cx="18" cy="16" r="16" fill="white" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18 31C21.1377 31 24.0505 30.0366 26.4585 28.3894C26.641 28.2646 26.8206 28.1358 26.9971 28.0032C28.2204 27.0849 29.2987 25.9839 30.1915 24.7408C31.9592 22.2796 33 19.2614 33 16C33 7.71572 26.2843 0.999999 18 0.999999C9.71573 0.999999 3 7.71572 3 16C3 19.3983 4.13011 22.5327 6.03502 25.0479C6.86177 26.1395 7.83447 27.1144 8.92406 27.9436C8.93105 27.949 8.93806 27.9543 8.94506 27.9596C9.73142 28.5559 10.5784 29.0764 11.4751 29.5103C13.4479 30.4648 15.6614 31 18 31ZM18 32C26.8366 32 34 24.8365 34 16C34 7.16344 26.8366 0 18 0C9.16344 0 2 7.16344 2 16C2 24.8365 9.16344 32 18 32Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M26.4585 28.3894C26.641 28.2646 26.8206 28.1358 26.9971 28.0032C28.2204 27.0849 29.2987 25.9839 30.1915 24.7408C31.9592 22.2796 33 19.2614 33 16C33 7.71572 26.2843 0.999999 18 0.999999C9.71573 0.999999 3 7.71572 3 16C3 19.3983 4.13011 22.5327 6.03502 25.0479C6.86177 26.1395 7.83447 27.1144 8.92406 27.9436C8.93105 27.949 8.93806 27.9543 8.94506 27.9596C9.73142 28.5559 10.5784 29.0764 11.4751 29.5103C13.4479 30.4648 15.6614 31 18 31C21.1377 31 24.0505 30.0366 26.4585 28.3894ZM9.11909 29.4556C11.5253 31.4856 13.9512 33.5322 15.4765 36.2559L17.6723 40.1771C17.7472 40.3108 17.8695 40.3904 18 40.3904C18.1305 40.3904 18.2528 40.3108 18.3277 40.1771L20.5235 36.2559C22.0488 33.5322 24.4747 31.4856 26.8809 29.4557C27.4398 28.9842 27.9976 28.5136 28.5428 28.0356C31.8879 25.103 34 20.7982 34 16C34 7.16344 26.8366 0 18 0C9.16344 0 2 7.16344 2 16C2 20.7982 4.11207 25.103 7.45723 28.0356C8.00243 28.5136 8.56023 28.9842 9.11909 29.4556Z"
                    fill="white"
                />
                <path
                    d="M18 39.7389L15.9127 36.0116C14.343 33.2085 11.8582 31.1123 9.47574 29.1024L9.4415 29.0735C8.88202 28.6015 8.32787 28.1339 7.78684 27.6596C4.54537 24.818 2.5 20.6481 2.5 16C2.5 7.43958 9.43959 0.5 18 0.5C26.5604 0.5 33.5 7.43958 33.5 16C33.5 20.6481 31.4546 24.818 28.2132 27.6596C27.6721 28.1339 27.118 28.6015 26.5585 29.0735L26.5242 29.1024C24.1418 31.1123 21.657 33.2085 20.0873 36.0116L18 39.7389Z"
                    stroke="white"
                />
                <rect x="5" y="3" width="26" height="26" rx="13" fill="#49565D" />
                <path
                    d="M22.5649 18.7541L22.5648 18.7542C22.0132 19.4009 20.7953 20.6141 19.6968 21.6819C19.1514 22.2121 18.6414 22.7008 18.2676 23.057C18.1682 23.1518 18.0784 23.2372 18.0002 23.3114C17.922 23.2372 17.8322 23.1518 17.7328 23.057C17.3591 22.7008 16.8491 22.2121 16.3037 21.6819C15.2054 20.6141 13.9874 19.4009 13.4354 18.7541L13.4353 18.754C12.3612 17.4959 11.8335 16.2125 11.8335 14.636C11.8335 11.2483 14.5875 8.5 17.9863 8.5H18.0135C21.4129 8.5 24.1668 11.2484 24.1668 14.636C24.1668 16.2124 23.6386 17.4958 22.5649 18.7541Z"
                    fill="white"
                    stroke="#49565D"
                />
                <ellipse cx="18.0003" cy="14.6668" rx="2.33333" ry="2.33333" fill="#49565D" />
            </g>
            <defs>
                <filter
                    id="filter0_d_23025_543976"
                    x="0"
                    y="0"
                    width="36"
                    height="44.3906"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_23025_543976"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_23025_543976"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
};
LocationPinIcon.__tags = [] as string[];
