import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { findIndex } from 'lodash/fp';
import { chainTypeProperty, getEntityTypesByReportType } from 'core/entities';
import {
    RecentEntitiesModel,
    RecentEntitiesModelAutocompleteEnriched,
} from 'core/services/recent-entities-service/recent-entities-model';
import { RecentEntity } from 'core/entities/recent-entity/recent-entity';
import { searchReportTypeToSearchAPI } from 'store/header/search/actions/top-search-get-entities';
import {
    userCustomSettingsRecentPoiIdsSelector,
    selectHasUserPermission,
} from 'store/selectors/app-selectors';
import {
    getRecentEntitiesByReportType,
    getRecentEntitiesByTerm,
} from 'core/services/recent-entities-service/recent-entities-service';
import { tagsApi } from 'API/tags-api';
import { REPORT_TYPE_TO_PLACE_COLLECTIONS, ReportTypes } from 'core/constants/report-type';
import { mapAutoCompleteSuggestion } from 'utils/map-auto-complete-suggestion/map-auto-complete-suggestion';
import { SearchParams, searchPOI } from 'API/search';
import { useSelectorWithProps } from 'utils/structured-selector/structured-selector';
import { RequestAVenueAutocompleteOption } from 'components/request-a-venue-autocomplete-option/request-a-venue-autocomplete-option';
import type { PlaceTypeEntitiesNoTag } from 'core/entities/place-type-entities';
import type {
    Billboard,
    Chain,
    ChainType,
    PlacerEntityWrapper,
    PlaceType,
    PointOfInterestEntities,
    Tag,
    Venue,
} from '@placer-ui/types';

export const MAX_RECENT_ENTITIES = 10;
export const AUTO_SUGGEST_LIMIT = 6;
export const MAX_AMOUNT_OF_BRANDS = 3;
export const AUTOCOMPLETE_MAX_AMOUNT_GOOGLE_GEOCODES = 2;
export const AUTOCOMPLETE_FALLBACK_GOOGLE_POIS_LIMIT = 5;
export const RESULTS_FALLBACK_GOOGLE_POIS_LIMIT = 20;

const getUniqueValues = (searchResults: RecentEntitiesModel[]) => {
    const uniqValues = searchResults.filter((searchResult, index) => {
        const firstVenueIndex = findIndex(
            {
                id: searchResult.id,
                type: searchResult?.type,
            },
            searchResults,
        );

        return firstVenueIndex === index;
    });
    return uniqValues;
};

type SearchRecentEntitiesProps = {
    term: string;
    type: ReportTypes;
    limit?: number;
};

/**
 * This function get all sorted recent entities by term and type
 */
export const useSearchRecentEntities = ({
    term,
    type,
    limit = AUTO_SUGGEST_LIMIT,
}: SearchRecentEntitiesProps): RecentEntity[] => {
    const userRecentEntities = useSelector(userCustomSettingsRecentPoiIdsSelector);
    const entityTypes = getEntityTypesByReportType(type);

    return useMemo((): RecentEntity[] => {
        const sortedRecentEntities: RecentEntity[] = getRecentEntitiesByReportType(
            userRecentEntities,
            entityTypes,
        );

        return getRecentEntitiesByTerm(sortedRecentEntities, term).slice(0, limit);
    }, [entityTypes, term, userRecentEntities, limit]);
};

type UseSearchRecentChainEntitiesProps = SearchRecentEntitiesProps & {
    chainType?: ChainType;
};

export const useSearchRecentChainEntities = ({
    term,
    type,
    limit = AUTO_SUGGEST_LIMIT,
    chainType,
}: UseSearchRecentChainEntitiesProps) => {
    const recentEntities = useSearchRecentEntities({
        term,
        type,
        limit,
    });

    return useMemo((): RecentEntity[] => {
        return recentEntities.filter(
            (recentEntity) => recentEntity[chainTypeProperty] === chainType,
        );
    }, [chainType, recentEntities]);
};

export type AutocompleteSuggestionsParams = {
    apiSearchResults: (Billboard | Chain | Venue | Tag)[];
    recentEntities: RecentEntitiesModel[];
    isLoading: boolean;
    termSearch: string;
    flaggedEntityTooltipText?: string;
    hasUnlockNearbyActivity?: boolean;
    hasRestrictPermission?: boolean;
    hasStoreIdBadgeAutocompleteSearchPermission?: boolean;
    noResultsComponent?: JSX.Element;
    isFreemiumUser?: boolean;
};

/**
 * This function get all entities API data and the recent entities and combine them together
 */
export const aggregateSuggestions = ({
    apiSearchResults,
    recentEntities,
    isLoading,
    termSearch,
    flaggedEntityTooltipText,
    hasUnlockNearbyActivity,
    hasRestrictPermission,
    noResultsComponent = <RequestAVenueAutocompleteOption />,
    hasStoreIdBadgeAutocompleteSearchPermission,
    isFreemiumUser,
}: AutocompleteSuggestionsParams) => {
    /**
     * This Util map all the possible dropdown list items with it relevant icon,
     * name and flag tooltip
     */
    const suggestionsMapper = (entity: RecentEntitiesModelAutocompleteEnriched) => {
        const isEntityAvailable = isFreemiumUser && entity.profile === 'available';
        let forceLockIconState = entity.forceLockIconState;
        if (forceLockIconState === undefined && isEntityAvailable) {
            forceLockIconState = 'hide';
        }
        const enrichedEntity: RecentEntitiesModelAutocompleteEnriched = {
            ...entity,
            forceLockIconState,
        };

        return mapAutoCompleteSuggestion({
            data: enrichedEntity,
            term: termSearch,
            flaggedEntityTooltipText,
            hasUnlockNearbyActivity,
            hasRestrictPermission,
            hasStoreIdBadgeAutocompleteSearchPermission,
        });
    };

    const recentEntitiesCapped = recentEntities.slice(0, MAX_RECENT_ENTITIES);
    const combineResults = getUniqueValues([...recentEntitiesCapped, ...apiSearchResults]);

    let suggestions = combineResults.map(suggestionsMapper);

    /**
     * In case there is no data'
     */
    if (!isLoading && !apiSearchResults.length) {
        suggestions = [
            {
                value: termSearch,
                component: noResultsComponent,
            },
        ];
    }

    return suggestions;
};

type FetchDataProps = {
    term: string;
    reportType: ReportTypes;
    source: string;
    purchasedOnly?: boolean;
    customParams?: SearchParams;
    searchPoiApi?: boolean;
    enableNearbyActivityPOIs?: boolean;
    enableNotApprovedCPOIs?: boolean;
    appKey?: string;
};

export const fetchSearchFullData = async ({
    reportType,
    customParams,
    searchPoiApi,
    term,
    purchasedOnly,
    enableNearbyActivityPOIs,
    enableNotApprovedCPOIs,
    source,
    appKey,
}: FetchDataProps) => {
    let api = searchReportTypeToSearchAPI[reportType];
    const additionalParams = customParams || {};

    if (searchPoiApi) {
        api = searchPOI(REPORT_TYPE_TO_PLACE_COLLECTIONS[reportType]);
    }

    const { data } = await api({
        limit: AUTO_SUGGEST_LIMIT,
        term,
        purchasedOnly,
        enableNearbyActivityPOIs,
        enableNotApprovedCPOIs,
        source,
        appKey,
        ...additionalParams,
    });

    return data;
};

/**
 * This function call the search API with relevant params
 */
export const useSearchFetchData = () => {
    return useCallback(
        async ({
            term,
            reportType,
            ...args
        }: FetchDataProps): Promise<Tag[] | PlaceTypeEntitiesNoTag[]> => {
            // no try-catch because parent function should handle the error
            switch (reportType) {
                case 'Tag': {
                    const allTags = await tagsApi.getAllTags();
                    const tagsWithTerm = allTags.filter((tag: Tag) => {
                        const tagName = tag?.name ? tag.name.toLowerCase() : '';
                        return tagName.includes(term.toLowerCase()) && tagName !== '';
                    });
                    return tagsWithTerm.map((tag: Tag) => {
                        return {
                            ...tag,
                            purchased: true,
                            type: 'tag' as PlaceType,
                        } as Tag;
                    });
                }
                default: {
                    const data = await fetchSearchFullData({
                        term,
                        reportType,
                        ...args,
                    });

                    return data.map(({ info }) => info);
                }
            }
        },
        [],
    );
};

type SearchPoiProps = {
    searchParams: SearchParams;
    reportType: ReportTypes;
};

export const useSearchPoiWithAdditionalParams = () => {
    const hasPurchasedVenuesPermission = useSelectorWithProps(
        { permission: 'show_only_purchased_venues' },
        selectHasUserPermission,
    );

    return useCallback(
        async ({ searchParams, reportType }: SearchPoiProps, signal?: AbortSignal) => {
            const apiRequest = searchReportTypeToSearchAPI[reportType];
            const isBillboardType = reportType === 'Billboard';
            const additionalParams: Partial<SearchParams> = {
                sortBy: 'foot_traffic',
                appendGoogleAddress: !isBillboardType
                    ? AUTOCOMPLETE_MAX_AMOUNT_GOOGLE_GEOCODES
                    : undefined,
                fallbackGooglePOIs: !isBillboardType
                    ? RESULTS_FALLBACK_GOOGLE_POIS_LIMIT
                    : undefined,
                showPoisInCriticalArea: true,
                purchasedOnly: hasPurchasedVenuesPermission,
            };
            const { data: results } = await apiRequest(
                {
                    ...additionalParams,
                    ...searchParams,
                },
                true,
                signal,
            );
            results.forEach((item) => {
                if (item.info.provider_data?.entity_id) {
                    item.info.id = item.info.provider_data?.entity_id;
                }
            });
            return results as PlacerEntityWrapper<PointOfInterestEntities>[];
        },
        [hasPurchasedVenuesPermission],
    );
};

export const getExploreParams = (reportType: ReportTypes): SearchParams => {
    return {
        appendChains: reportType !== 'Billboard' ? MAX_AMOUNT_OF_BRANDS : undefined,
        appendGoogleAddress:
            reportType !== 'Billboard' ? AUTOCOMPLETE_MAX_AMOUNT_GOOGLE_GEOCODES : undefined,
        fallbackGooglePOIs:
            reportType !== 'Billboard' ? AUTOCOMPLETE_FALLBACK_GOOGLE_POIS_LIMIT : undefined,
    };
};
