/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

type ManInMarkerIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type ManInMarkerIconProps = SVGProps<SVGSVGElement> & ManInMarkerIconUniqueProps;

export const ManInMarkerIcon = function ManInMarkerIcon({
    width = 56,
    height = 56,
    ...props
}: ManInMarkerIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect y="-0.000244141" width="56" height="56" rx="3" fill="#F3F3FD" />
            <g clipPath="url(#clip0_3637_91744)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M33.785 40.9999H22.2109V39.9148H33.785V40.9999Z"
                    fill="#5E63E5"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M27.9803 16.0014C23.2047 16.0014 19.3336 19.8737 19.3336 24.648C19.3336 26.8729 20.0788 28.6836 21.5806 30.4479C22.3399 31.3402 24.0147 32.9258 25.5243 34.3084C26.2741 34.995 26.9752 35.6245 27.489 36.0824C27.6902 36.2617 27.8625 36.4146 27.9978 36.5342C28.133 36.4146 28.3054 36.2617 28.5065 36.0824C29.0203 35.6245 29.7215 34.995 30.4712 34.3084C31.9809 32.9258 33.6556 31.3402 34.415 30.448C35.918 28.6837 36.6634 26.8729 36.6634 24.648C36.6634 19.8737 32.7923 16.0014 28.0167 16.0014H27.9803ZM27.9978 37.202C27.6676 37.5785 27.6675 37.5785 27.6675 37.5784L27.6078 37.526L27.4384 37.3766C27.2918 37.2471 27.0807 37.0602 26.8226 36.8302C26.3065 36.3702 25.6019 35.7376 24.8478 35.047C23.3499 33.6752 21.6254 32.046 20.8178 31.0971C19.1829 29.1762 18.332 27.1412 18.332 24.648C18.332 19.3207 22.6514 14.9998 27.9803 14.9998H28.0167C33.3456 14.9998 37.665 19.3207 37.665 24.648C37.665 27.1412 36.8142 29.1762 35.1777 31.0971C34.3702 32.046 32.6456 33.6752 31.1477 35.047C30.3937 35.7376 29.689 36.3702 29.1729 36.8302C28.9148 37.0602 28.7038 37.2471 28.5572 37.3766L28.3877 37.526L28.3283 37.5783C28.3282 37.5783 28.328 37.5785 27.9978 37.202ZM27.9978 37.202L28.3283 37.5783L27.9978 37.8681L27.6675 37.5784L27.9978 37.202Z"
                    fill="#5E63E5"
                />
                <path
                    d="M30.7462 18.7983C31.1021 18.7983 31.3906 19.0869 31.3906 19.4428V21.472C31.3906 22.9447 30.1967 24.1386 28.724 24.1386H27.2702C25.7975 24.1386 24.6036 22.9447 24.6036 21.472V20.7428C24.6036 19.6689 25.4741 18.7983 26.548 18.7983H30.7462Z"
                    fill="white"
                    stroke="#5E63E5"
                />
                <path
                    d="M30.8906 21.6919H31.3906V22.1919V23.6387C31.3906 25.5129 29.8713 27.0322 27.9971 27.0322C26.1229 27.0322 24.6036 25.5128 24.6036 23.6387V22.1919V21.6919H25.1036H30.8906Z"
                    fill="white"
                    stroke="#5E63E5"
                />
                <path
                    d="M24.8052 30.5314L24.6661 30.0446C24.4937 29.4413 24.9467 28.8407 25.5742 28.8407L26.8328 28.8407L29.1663 28.8407L30.4249 28.8407C31.0524 28.8407 31.5054 29.4413 31.333 30.0446L31.1939 30.5314C31.1822 30.5723 31.159 30.6091 31.1271 30.6372L28.1464 33.2602C28.0624 33.3341 27.9367 33.3341 27.8527 33.2602L24.8721 30.6372C24.8401 30.6091 24.8169 30.5723 24.8052 30.5314Z"
                    fill="#E1E2FF"
                    stroke="#5E63E5"
                />
            </g>
            <defs>
                <clipPath id="clip0_3637_91744">
                    <rect width="26" height="26" fill="white" transform="translate(15 14.9998)" />
                </clipPath>
            </defs>
        </svg>
    );
};
ManInMarkerIcon.__tags = [] as string[];
