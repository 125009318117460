import { createSlice } from '@reduxjs/toolkit';

import { createReportChainsUiState } from 'features/industry/store/chains-report-ui-state-store/models';
import {
    setStickyBarState,
    setMissingPermissionsCheckSuppressed,
    setDatePickerState,
    setCustomFilterModalState,
} from 'features/industry/store/chains-report-ui-state-store/actions';
import { setChainsReportOptions } from 'features/industry/store/chains-report-ui-state-store';

const initialState = createReportChainsUiState();

export const industryReportUiStateSlice = createSlice({
    name: 'report',
    initialState: initialState,
    reducers: {
        resetState: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(setMissingPermissionsCheckSuppressed, (state, action) => {
            state.isMissingPermissionsCheckSuppressed = action.payload;
        });

        builder.addCase(setChainsReportOptions, (state, action) => {
            state.reportOptions = action.payload;
        });

        builder.addCase(setStickyBarState, (state, action) => {
            state.isStickyBarOpen = action.payload;
        });

        builder.addCase(setDatePickerState, (state, action) => {
            state.isDatePickerOpen = action.payload;
        });

        builder.addCase(setCustomFilterModalState, (state, action) => {
            state.isCustomFilterModalOpen = action.payload;
        });
    },
});

export const chainsReportUiStateReducer = industryReportUiStateSlice.reducer;
