import type {
    DateYear,
    Granularity,
} from 'components/date-compare-select/date-compare-select-types';

export const splitGranularityWithYear = (granularityWithYear: Granularity) => {
    const regexp = /^(\w+)-(\d{4})$/;
    const match = granularityWithYear.match(regexp);
    if (match) {
        return {
            granularity: match[1] as Granularity,
            year: match[2] as DateYear,
        };
    }

    return {
        granularity: granularityWithYear as Granularity,
    };
};

export const getGranularityWithYear = (granularity: Granularity, dateYear?: DateYear) => {
    const fullGranularity = dateYear ? `${granularity}-${dateYear}` : granularity;
    return fullGranularity as Granularity;
};
