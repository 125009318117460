import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { getPublicRoutes, getDashboardRoutes } from 'router/routes';
import { isPathnameStartsWithPath, testPathnameWithPath } from 'router/utils';
import { shouldRenderMobileApp } from 'utils/detect-environment';
import { getMobileReportPageRoutes } from 'router/routes/mobile.routes';

export const useIsFullPagePath = () => {
    const { pathname } = useLocation();
    const fullPagePaths = useMemo(() => {
        const routes = [
            ...getPublicRoutes(),
            ...getDashboardRoutes(),
            ...(shouldRenderMobileApp() ? getMobileReportPageRoutes() : []),
        ];
        return routes.filter(({ isFullPage }) => isFullPage).map(({ path }) => path);
    }, []);

    const isFullPagePath = useMemo(
        () =>
            fullPagePaths.some((path) => {
                const isDynamicPath = path?.includes(':');
                if (!isDynamicPath) {
                    return isPathnameStartsWithPath(pathname, path);
                } else {
                    return testPathnameWithPath(pathname, path);
                }
            }),
        [fullPagePaths, pathname],
    );

    return isFullPagePath;
};
