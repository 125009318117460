import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Button } from 'antd';

import { useAppUiActions } from 'store/app-ui/use-app-ui-actions';
import {
    selectUpgradeYourPlanPopupIsVisible,
    selectUpgradeYourPlanPopupParams,
    selectUpgradeYourPlanPopupScreen,
} from 'store/app-ui/selectors/application-selectors-factory';
import { Dialog } from 'ui-components/dialog/dialog';
import { UpgradeYourPlanHeader } from './components/dialog-header/dialog-header';
import { DialogBenefitsScreen } from './components/dialog-benefits-screen/dialog-benefits-screen';
import { ConsultWithSalesForm } from './components/consult-with-sales-form/consult-with-sales-form';

import './upgrade-your-plan-dialog.scss';
import { useSegmentCallback } from 'hooks/use-segment-callback/use-segment-callback';
import { Modal, Toast } from '@placer-ui/components';
import { DynamicModalContent } from 'extensions/upgrade-your-plan-dialog/components/dynamic-modal-content/dynamic-modal-content';
import { submitUpgradeModalHubspotForm } from 'extensions/upgrade-your-plan-dialog/utils/submit-upgrade-modal-hubspot';
import { userCookieStorage } from 'core/authentification';
import { heapTrack } from 'utils/heap/heap';

type UpgradeYourPlanDialogProps = {
    overrideAntdModalProps?: {
        width?: number;
        closable?: boolean;
        destroyOnClose?: boolean;
        footer?: JSX.Element | Array<JSX.Element> | null;
    };
};

export const UPGRADE_PLAN_SCREEN = {
    dynamic: 'dynamic',
    default: 'default',
    consultForm: 'consultForm',
} as const;

const defaultClassName = 'upgrade-your-plan-dialog';
const formScreenClassName = 'sales-form-screen';
const defaultScreenWidth = 540;
const formScreenWidth = 450;

const useUpgradeYourPlanDialog = () => {
    const { openUpgradePlanPopup, closeUpgradePlanPopup } = useAppUiActions();
    return {
        openUpgradePlanPopup,
        closeUpgradePlanPopup,
        upgradeYourPlanPopupScreen: useSelector(selectUpgradeYourPlanPopupScreen),
        upgradeYourPlanPopupVisible: useSelector(selectUpgradeYourPlanPopupIsVisible),
        upgradeYourPlanPopupParams: useSelector(selectUpgradeYourPlanPopupParams),
    };
};

export const UpgradeYourPlanDialog = ({
    overrideAntdModalProps = {
        closable: false,
        destroyOnClose: true,
        footer: null,
    },
}: UpgradeYourPlanDialogProps) => {
    const [openToast, setOpenToast] = useState(false);
    const [modalWidth, setModalWidth] = useState(defaultScreenWidth);
    const [wrapperClassName, setWrapperClassName] = useState(defaultClassName);
    const {
        openUpgradePlanPopup,
        closeUpgradePlanPopup,
        upgradeYourPlanPopupScreen,
        upgradeYourPlanPopupVisible,
        upgradeYourPlanPopupParams,
    } = useUpgradeYourPlanDialog();
    const { track } = useSegmentCallback();
    const email = userCookieStorage.user?.email;
    const isDynamicModal = upgradeYourPlanPopupScreen === UPGRADE_PLAN_SCREEN.dynamic;

    useEffect(() => {
        const isDefaultScreen = upgradeYourPlanPopupScreen === UPGRADE_PLAN_SCREEN.default;

        setModalWidth(isDefaultScreen ? defaultScreenWidth : formScreenWidth);
        setWrapperClassName(
            classNames(defaultClassName, {
                [formScreenClassName]: !isDefaultScreen,
            }),
        );
    }, [upgradeYourPlanPopupScreen]);

    useEffect(() => {
        if (upgradeYourPlanPopupScreen === UPGRADE_PLAN_SCREEN.default) {
            track({
                category: 'Popup',
                action: 'Opened upgrade',
            });
        }
    }, [upgradeYourPlanPopupScreen, track]);

    const onClose = () => {
        closeUpgradePlanPopup();
    };

    const onSubmit = () => {
        if (isDynamicModal) heapTrack('dynamic upgrade popup submit');
        submitUpgradeModalHubspotForm(email || '');
        setOpenToast(true);
        closeUpgradePlanPopup();
    };

    if (isDynamicModal) {
        return (
            <Modal
                hasBackdrop
                open={upgradeYourPlanPopupVisible}
                onOpenChange={() => closeUpgradePlanPopup()}
                trigger={<></>}
            >
                <DynamicModalContent
                    title={upgradeYourPlanPopupParams?.title}
                    bullets={upgradeYourPlanPopupParams?.bullets}
                    videoId={upgradeYourPlanPopupParams?.videoId}
                    closeModal={closeUpgradePlanPopup}
                    onSubmit={onSubmit}
                />
            </Modal>
        );
    }

    return (
        <>
            <Dialog
                onCancel={onClose}
                visible={upgradeYourPlanPopupVisible}
                className={wrapperClassName}
                width={modalWidth}
                {...overrideAntdModalProps}
            >
                <>
                    <UpgradeYourPlanHeader screen={upgradeYourPlanPopupScreen} />

                    {upgradeYourPlanPopupScreen === UPGRADE_PLAN_SCREEN.default && (
                        <DialogBenefitsScreen
                            onSetDialogView={() =>
                                openUpgradePlanPopup(UPGRADE_PLAN_SCREEN.consultForm)
                            }
                        />
                    )}

                    {upgradeYourPlanPopupScreen === UPGRADE_PLAN_SCREEN.consultForm && (
                        <ConsultWithSalesForm onCancel={onClose} onSent={onClose} />
                    )}

                    {upgradeYourPlanPopupScreen === UPGRADE_PLAN_SCREEN.default && (
                        <footer className="upgrade-plan-dialog-footer">
                            <Button className="dialog-cancel-button" onClick={onClose}>
                                Close
                            </Button>
                        </footer>
                    )}
                </>
            </Dialog>
            <Toast
                open={openToast}
                onOpenChange={setOpenToast}
                icon="approve"
                description={'Thanks for reaching out!'}
                loadingState={'One of our experts will be in touch soon'}
                duration={3000}
            >
                <Toast.CtaButton onClick={() => setOpenToast(false)}>Close</Toast.CtaButton>
            </Toast>
        </>
    );
};
