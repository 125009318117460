export const DEFAULT_PATH = '/explore';

export const STAND_ALONE_PATH = 'standAlone';

export const ON_BOARDING_ROUTE = 'on-boarding';

export const NO_VALUE_TEXT = '--';

export const DEFAULT_MOBILE_RADIUS = 250;

export const DEFAULT_MOBILE_ZOOM = 15;

export const DEFAULT_WIDGETS_ORDER = [
    'metrics',
    'visit-trends',
    'sales-trends',
    'tenants-overview',
    'market-landscape',
    'audience-overview',
    'ranking-overview',
    'hourly-visits',
    'daily-visits',
    'visit-duration',
    'favorite-places',
    'visitor-journey',
];
