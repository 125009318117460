import { history } from 'router/history';
import { isAbsoluteUrl } from 'utils/is-absolute-url/is-absolute.url';
import { getDeviceType } from 'utils/get-user-agent-data/utils';
import { ROUTES } from 'features/authentication/expired-reset-password/constants';
import { SIGNUP_ROUTE } from 'features/authentication/signup/constants/signup-links';

export const SIGN_IN_PATH = '/auth/signin';

const navigate = (url: string) => {
    if (isAbsoluteUrl(url)) {
        window.location.href = url;
    } else {
        history.push(url);
    }
};

export const goTo = (path: string, basePath?: string) => {
    navigate(basePath ? `${basePath}/${path}` : path);
};

export const goToInANewTab = (path: string, basePath?: string) => {
    const url = `${basePath || ''}/${path}`;
    if (getDeviceType() === 'tablet' || getDeviceType() === 'mobile-web') {
        // a hack to try and fix safari/iOS not oprening in a new tab
        // according to: https://stackoverflow.com/a/70463940/4227104
        setTimeout(() => {
            window.open(url, '_blank');
        }, 0);
    } else {
        window.open(url, '_blank');
    }
};

const appendNextUrlParam = (basePath: string, nextUrl?: string) => {
    return !nextUrl ? basePath : `${basePath}?next_url=${nextUrl}`;
};

type GoToOptions = {
    /** navigate by loading a new page, instead of just changing the history state */
    forcePageReload?: boolean;
    nextUrl?: string;
};

const goToAuthPage = (baseRoute: string, nextUrl?: string) => {
    const path = appendNextUrlParam(baseRoute, nextUrl);
    navigate(path);
};

export const goToSignIn = (options?: GoToOptions) => {
    const signInPath = appendNextUrlParam(SIGN_IN_PATH, options?.nextUrl);
    if (options?.forcePageReload) window.open(signInPath, '_self');
    else navigate(signInPath);
};

export const goToSignUp = (options?: GoToOptions) => {
    goToAuthPage(SIGNUP_ROUTE, options?.nextUrl);
};

export const goToForgotPasswordPage = (options?: GoToOptions) => {
    goToAuthPage(ROUTES.FORGOT_PASSWORD, options?.nextUrl);
};

export const goToAdminExplore = () => {
    navigate('/explore');
};

export const goToOnBoardingPage = (page: string) => {
    navigate(`/on-boarding/${page}`);
};

export const goToAdminRedirect = () => {
    goTo('/redirect');
};

export const goToPageNotFound = () => {
    goTo('/page-not-found');
};

export const goToHref = (href: string) => {
    navigate(href);
};
