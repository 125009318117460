/* eslint-disable no-extra-label */
import type { PlacerErrorParams } from './errors-manager-types';
import { PlacerError } from './placer-error';
import { isSimpleFetchError } from 'API/fetch/utils';
import { SimpleFetchError } from 'API/fetch/types';

/** is native error instance or inherits from native error class */
export function isNativeError(error: unknown): error is Error {
    if (typeof error === 'object' && error !== null) {
        if (error instanceof Error) {
            // is instance of some native error class like Error, ReferenceError, TypeError
            return true;
        } else if (
            typeof (error as any).message === 'string' &&
            (typeof (error as any).stack === 'string' || Boolean((error as any).stack))
        ) {
            // special check for react's error which is constructed by Error but isn't an instance of the class
            return true;
        }
    }
    return false;
}

/**
 * filters all errors found by search criteria
 * or all errors not matching search creteria if using opposite
 */
export function filterErrorsFromList(
    errorsList: PlacerError[],
    allSearches: Partial<PlacerErrorParams>[],
    opposite: boolean = false,
): PlacerError[] {
    const matchedErrors = errorsList.filter((err) => {
        for (const searchOptions of allSearches) {
            const { type, page, tab, section, subSection } = searchOptions;
            // if a search criterion was not defined it is considered as a match
            if (
                (type === undefined || type === err.type) &&
                (page === undefined || page === err.page) &&
                (tab === undefined || tab === err.tab) &&
                (section === undefined || section === err.section) &&
                (subSection === undefined || subSection === err.subSection)
            ) {
                return !opposite;
            }
        }

        return opposite;
    });

    return matchedErrors;
}

export const makeErrorFromSimpleFetchErrorResponse = (error: unknown) => {
    if (isSimpleFetchError(error)) {
        return new Error(JSON.stringify((error as SimpleFetchError).body));
    }
    return error;
};
