import type { AccessWarning, PlacerEntityModel, Venue } from '@placer-ui/types';
import { ResponseError } from 'features/insights/components/custom-poi-warning-bar/custom-poi-warning-bar';
import { useFeatureFlags } from 'hooks/feature-flags/use-feature-flags';
import {
    useIsReportAccessHeavyRisk,
    useIsReportAccessLightRisk,
} from 'utils/poi-filter/poi-filter';
import { useSelector } from 'react-redux';
import { selectReportError } from 'features/insights/store/selectors/insights-selectors';
import { ResponseStatus } from 'API/common/types';

type NearbyActivityWarningBarProps = {
    entitiesMapped: PlacerEntityModel<Venue>[];
    responseError?: ResponseError;
};

export const HEAVY_RISKY_FT_REMOVED: AccessWarning = 'heavy_risky_ft_removed';
export const LIGHT_RISKY_FT_REMOVED: AccessWarning = 'light_risky_ft_removed';

export const DISCLAIMER_MESSAGE =
    // eslint-disable-next-line max-len
    'Home locations are obfuscated for privacy and randomly placed within a census block. They do not represent actual home addresses.';

export const usePrivacyRelated = ({
    entitiesMapped,
    responseError,
}: NearbyActivityWarningBarProps) => {
    const reportError = useSelector(selectReportError);
    const { nearby_activity_risk_banner_ff } = useFeatureFlags();
    const isReportAccessLightRisk = useIsReportAccessLightRisk(entitiesMapped);
    const isReportAccessHeavyRisk = useIsReportAccessHeavyRisk(entitiesMapped);

    const isLightRiskyFTRemoved =
        nearby_activity_risk_banner_ff &&
        (isReportAccessLightRisk || responseError?.info === LIGHT_RISKY_FT_REMOVED);

    const isHeavyRiskyFTRemoved =
        nearby_activity_risk_banner_ff &&
        (isReportAccessHeavyRisk || responseError?.info === HEAVY_RISKY_FT_REMOVED);

    const isRestrictedInsufficientData =
        nearby_activity_risk_banner_ff &&
        (responseError?.error === 'RESTRICTED_INSUFFICIENT_DATA' ||
            (reportError as ResponseStatus)?.type === 'RESTRICTED_INSUFFICIENT_DATA');

    return {
        isLightRiskyFTRemoved,
        isHeavyRiskyFTRemoved,
        isRestrictedInsufficientData,
    };
};
