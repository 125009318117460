import { usePlatformConfiguration } from 'features/settings/store/selectors';
import { useMemo } from 'react';
import { getFeatureFlags } from 'core/flow-control';
export const useRetailSalesPermissions = () => {
    const platformSettings = usePlatformConfiguration();

    return useMemo(() => {
        const retailSalesSupportAccess = (platformSettings?.platform_config_retail_sales_access ??
            true) as boolean;

        const {
            hide_retail_sales,
            show_property_retail_sales,
            add_sales_metric_ranking_report_ff,
            unlock_retail_sales,
            add_sales_metric_ranking_tenants_ff,
            enable_retail_sales_customers_metrics_ff,
            enable_retail_sales_print_ff,
            retail_sales_metric_pdf_export,
            retail_sales_estimation_pdf_export,
            retail_sales_avg_ticket_size_pdf_export,
            retail_sales_ranking_overview_pdf_export,
            retail_sales_day_of_week_pdf_export,
            retail_sales_transaction_trend_pdf_export,
        } = getFeatureFlags();
        return {
            retail_sales_metric_pdf_export,
            retail_sales_estimation_pdf_export,
            retail_sales_avg_ticket_size_pdf_export,
            retail_sales_ranking_overview_pdf_export,
            retail_sales_day_of_week_pdf_export,
            retail_sales_transaction_trend_pdf_export,
            hideRetailSales: hide_retail_sales,
            hasRetailSalesDataPermission: show_property_retail_sales && retailSalesSupportAccess,
            hasRetailSalesPermission:
                show_property_retail_sales && !hide_retail_sales && retailSalesSupportAccess,
            hasRetailSalesCustomersPermission:
                enable_retail_sales_customers_metrics_ff && retailSalesSupportAccess,
            hasTenantsSalesRankingMetric:
                add_sales_metric_ranking_tenants_ff &&
                !hide_retail_sales &&
                unlock_retail_sales &&
                retailSalesSupportAccess,
            hasRetailSalesMetricRankingFF:
                add_sales_metric_ranking_report_ff &&
                !hide_retail_sales &&
                retailSalesSupportAccess,
            hasUnlockRetailSales: unlock_retail_sales && retailSalesSupportAccess,
            hasRetailSalesExportPDF: enable_retail_sales_print_ff && retailSalesSupportAccess,
        };
    }, [platformSettings?.platform_config_retail_sales_access]);
};
