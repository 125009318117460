/* eslint-disable max-len */
import React from 'react';

export const SgAviationIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
        >
            <path
                d="M10.3377 5.1762L12.7213 7.93523L8.6895 7.97835L7.04795 3.93404C7.02143 3.86869 7.06917 3.79703 7.13975 3.79628L7.27379 3.79484C8.44933 3.78227 9.56987 4.28747 10.3377 5.1762Z"
                fill="#E1E2FF"
                stroke="#5E63E5"
                strokeWidth="0.75"
            />
            <path
                d="M4.25261 8.02543C5.06246 8.01717 12.8184 7.9395 18.0293 7.9395C18.6511 7.9395 19.3269 8.31981 19.3361 9.36937C19.3453 10.4189 19.1477 11.053 18.0293 11.1364C16.932 11.1364 14.2123 11.1823 13.7579 11.1901C13.7158 11.1908 13.6777 11.2102 13.6512 11.243L11.086 14.4162C10.1899 15.5247 8.846 16.1756 7.42202 16.1908C7.36298 16.1915 7.32192 16.1325 7.34296 16.0773L9.11555 11.4267C9.15046 11.3352 9.08458 11.2367 8.98669 11.2342L6.28394 11.1634C3.78079 11.0978 1.61813 9.40281 0.957409 6.9886L0.639728 5.82782C0.622418 5.76457 0.669706 5.70195 0.735308 5.70125L1.98343 5.68791C2.30901 5.68443 2.6156 5.83968 2.80517 6.10404L4.14059 7.9662C4.16754 8.00378 4.20633 8.0259 4.25261 8.02543Z"
                fill="white"
                stroke="#5E63E5"
                strokeWidth="0.75"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.6745 9.18772C17.7491 9.26859 17.7916 9.3789 17.7926 9.49439L17.0048 9.50281C17.0038 9.38733 17.0443 9.27613 17.1175 9.19368C17.1906 9.11123 17.2904 9.06429 17.3949 9.06317C17.4994 9.06205 17.5999 9.10686 17.6745 9.18772ZM17.794 9.49461L17.0062 9.50304L17.01 9.93847L17.7978 9.93004L17.794 9.49461Z"
                fill="#5E63E5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.0998 9.18919C16.1744 9.27006 16.2169 9.38037 16.2179 9.49585L15.4301 9.50428C15.4291 9.38879 15.4696 9.2776 15.5428 9.19515C15.6159 9.1127 15.7157 9.06575 15.8202 9.06463C15.9246 9.06352 16.0252 9.10832 16.0998 9.18919ZM16.2192 9.49601L15.4314 9.50444L15.4352 9.93987L16.223 9.93144L16.2192 9.49601Z"
                fill="#5E63E5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.6662 9.22972C14.7408 9.31059 14.7833 9.4209 14.7843 9.53638L13.9965 9.54481C13.9955 9.42932 14.036 9.31812 14.1092 9.23568C14.1823 9.15323 14.2821 9.10628 14.3866 9.10516C14.4911 9.10404 14.5916 9.14885 14.6662 9.22972ZM14.7856 9.53667L13.9978 9.54509L14.0016 9.98052L14.7894 9.9721L14.7856 9.53667Z"
                fill="#5E63E5"
            />
        </svg>
    );
};
