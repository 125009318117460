import { MutableRefObject, useEffect, useState } from 'react';
import { hasTextEllipsis } from 'utils/has-text-ellipsis/has-text-ellipsis';

export type EllipsisElementRefType = MutableRefObject<HTMLElement | null> | undefined;

export const useContainsTextEllipsis = (ref: EllipsisElementRefType) => {
    const [containsTextEllipsis, setContainsTextEllipsis] = useState<boolean>(false);
    useEffect(() => {
        if (ref?.current) {
            setContainsTextEllipsis(hasTextEllipsis(ref.current));
        }
    }, [ref]);
    return containsTextEllipsis;
};
