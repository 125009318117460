import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import styles from './poi-selection-table.module.scss';

import { PoiMenuTableItem } from 'features/poi-selection-map/types/poi-selection-types';
import { StyledTable } from 'features/poi-selection-map/poi-selection-menu/shared-components/styled-table/styled-table';
import { usePoiSelectionContext } from 'features/poi-selection-map/context/poi-selection-context';
import { SearchResults } from 'features/poi-selection-map/poi-selection-menu/shared-components/search-results/search-results';
import { usePoiSelectionColumns } from 'features/poi-selection-map/hooks/poi-selection-hooks';
import { usePoiButtonWithId } from 'features/poi-selection-map/hooks/use-poi-selection-action-button';
import { PoiSelectionLoader } from 'features/poi-selection-map/poi-selection-menu/shared-components/poi-selection-loader/poi-selection-loader';
import { isPlaceOutsourceMapPoi } from 'features/poi-selection-map/utils/poi-selection-map-utils';
import { ADDRESS_COORDINATE_TITLE, ADDRESS_TITLE } from 'core/constants/address';
import { isAddressType } from 'utils/poi-provider/poi-provider';
import { PoiSelectionPagination } from 'features/poi-selection-map/poi-selection-menu/shared-components/poi-selection-pagination/poi-selection-pagination';
import { TableProps } from 'ui-components/table/table';
import type { PointOfInterestEntities } from '@placer-ui/types';

type TagSelectorTableProps = {
    className?: string;
    searchPlaceholder?: string;
    showSearchBar?: boolean;
    isLoading?: boolean;
    tableProps?: Partial<TableProps<PoiMenuTableItem>>;
};

const getPoiName = (poiInfo: PointOfInterestEntities) => {
    if (isPlaceOutsourceMapPoi(poiInfo) && !poiInfo.name) {
        return ADDRESS_COORDINATE_TITLE;
    } else if (isAddressType(poiInfo)) {
        return ADDRESS_TITLE;
    }
    return poiInfo.name;
};

export const PoiSelectionTable = ({
    className,
    searchPlaceholder = 'Search',
    showSearchBar = true,
    isLoading = false,
    tableProps,
}: TagSelectorTableProps) => {
    const { poisListPerCurrentPage, setPoiActiveId, poiActiveId, poiHoverId, setPoiHoverId } =
        usePoiSelectionContext();
    const columnsConfiguration = usePoiSelectionColumns();
    const poiSelectionActionButton = usePoiButtonWithId();
    const [searchTerm, setSearchTerm] = useState<string>('');

    const dataSource = useMemo(
        () =>
            poisListPerCurrentPage?.reduce<PoiMenuTableItem[]>((acc, curr) => {
                const { overview, info } = curr;
                const isSearchTermFiltered = searchTerm
                    ? info.name.toLowerCase().includes(searchTerm.toLowerCase())
                    : true;
                if (isSearchTermFiltered) {
                    acc.push({
                        title: getPoiName(info),
                        subtitle: info.address.formatted_address || '',
                        visits: overview?.estimated_foottraffic || null,
                        id: info.id,
                        key: info.id + Math.random().toString(),
                    });
                }
                return acc;
            }, []),
        [poisListPerCurrentPage, searchTerm],
    );

    const onRowMouseEvents = ({ id }: PoiMenuTableItem, index?: number) => {
        return {
            onClick: () => {
                setPoiActiveId(id);
            },
            onMouseEnter: () => {
                setPoiHoverId(id);
            },
            onMouseLeave: () => {
                setPoiHoverId(undefined);
            },
            index,
        };
    };

    useEffect(() => {
        return () => {
            setSearchTerm('');
        };
    }, []);

    const onRowClassName = (record: PoiMenuTableItem, index: number, indent: number) => {
        const isActive = record.id === poiActiveId || record.id === poiHoverId;
        return isActive ? styles.active : '';
    };

    return (
        <div className={classNames(styles.container, className)}>
            <PoiSelectionLoader show={!dataSource || isLoading}>
                {showSearchBar && (
                    <div className={styles.searchBar}>
                        <SearchResults
                            onSearchChange={setSearchTerm}
                            title={searchPlaceholder}
                            displayType={'standard'}
                            inputValue={searchTerm}
                        />
                    </div>
                )}
                <StyledTable<PoiMenuTableItem>
                    className={styles.table}
                    dataSource={dataSource!}
                    columns={columnsConfiguration}
                    pagination={false}
                    onRow={onRowMouseEvents}
                    rowHoverRender={({ id }) => poiSelectionActionButton(id)}
                    rowClassName={onRowClassName}
                    {...tableProps}
                />
            </PoiSelectionLoader>
            {poisListPerCurrentPage && <PoiSelectionPagination />}
        </div>
    );
};
