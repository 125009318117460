import { Context, createContext, useContext } from 'react';
import { WidgetConfiguration } from 'extensions/widget/models/widget-config';
import { FetchFilter } from 'extensions/widget/models/widget-model';

export const WidgetConfigContext = createContext<WidgetConfiguration<string, any>>({});

export const useWidgetConfigContext = <
    WidgetApiId extends string = string,
    FetchFilterType = FetchFilter
>() => {
    return useContext(
        WidgetConfigContext as Context<WidgetConfiguration<WidgetApiId, FetchFilterType>>,
    );
};
