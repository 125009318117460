import { useDocumentTitle } from 'components/document-title/useDocumentTitle';
import { ReactElement } from 'react';

type DocumentTitleProps = {
    title?: string;
    children: ReactElement | null;
    defaultTitle?: string;
};

export const DocumentTitle = ({ title, children, defaultTitle = 'Placer' }: DocumentTitleProps) => {
    useDocumentTitle({ title: title ? `${title} - ${defaultTitle}` : defaultTitle });
    return children;
};
