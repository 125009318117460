import type { ConfigurationReportsOptions } from '@placer-ui/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
    ConfigurationReportDateOptionState,
    createConfigurationReportDateOptionsState,
} from 'store/configuration/report-date-options/models/configuration-report-date-options-state';

const initialState = createConfigurationReportDateOptionsState();

export const reportDateOptionsSlice = createSlice({
    name: 'report-date-options',
    initialState,
    reducers: {
        setReportDateOptions: (
            state: ConfigurationReportDateOptionState,
            action: PayloadAction<ConfigurationReportsOptions>,
        ) => {
            state.entities_data = action.payload.entities_data;
        },
    },
});

export const reportDateOptionsActions = reportDateOptionsSlice.actions;
