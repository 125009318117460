import { useGetActiveOverlay } from 'features/explore/hooks/use-explore-overlay-poi';
import {
    useNearbyActivityRadiusDefault,
    useNearbyActivityRadiusOptions,
} from 'hooks/use-nearby-activity-radius/use-nearby-activity-radius';
import { useSetOverlayPOI } from 'features/explore/context/use-explore-ui-state-actions';
import React from 'react';
import { RegularOptionBody } from 'features/explore/components/explore-side-bar/components/explore-drawer/explore-drawer-option/option-body/regular-option-body';
import { Select } from 'antd';
import { createPlaceWithNearbyResult } from 'utils/create-place-with-nearby-result/create-place-with-nearby-result';
import style from './select-radius-option-body.module.scss';
import { POIActionProps } from 'features/explore/components/explore-side-bar/components/explore-drawer/drawer-options';
import { PlaceOverlay } from 'ui-components/google-map-layers/types/google-map-layers-types';
import { getNearbyPlaceOverlay } from 'ui-components/google-map-layers/utils/get-place-overlay';
import { Tooltip } from 'ui-components/tooltip/tooltip';
import { isPoiRestrictedCoordinate } from 'features/explore/utils/utils';
import { useCheckCoordinateForNearbyReport } from 'features/explore/hooks/use-explore-nearby-activity';
import { useFullscreenContext } from 'utils/fullscreen-provider/fullscreen-provider';

export const SelectRadiusOptionBody = ({ poi, enableSelection }: POIActionProps) => {
    const defaultRadius = useNearbyActivityRadiusDefault();
    const radiusOptions = useNearbyActivityRadiusOptions();
    const { text: tooltipCheckbox, isLoading } = useCheckCoordinateForNearbyReport()(poi);
    const { isFullscreen } = useFullscreenContext();

    const setOverlay = useSetOverlayPOI();
    const overlayPoi: PlaceOverlay = {
        ...poi,
        overlayID: 'nearby',
    };
    const overlay = useGetActiveOverlay()(overlayPoi);
    const value = overlay?.filter?.config?.ft_radius || defaultRadius;

    const onChangeOverlay = (value: number) => {
        setOverlay({
            sourcePoi: poi.id,
            overlayPoi: getNearbyPlaceOverlay(createPlaceWithNearbyResult(poi, value)),
        });
    };

    const isRestrictedCoordinate = isPoiRestrictedCoordinate(overlay);

    return (
        <>
            <div className={style.selectRingRadiusContainer}>
                <div>Select ring radius</div>
                <Tooltip
                    small
                    placement={'top'}
                    title={tooltipCheckbox}
                    useParentContainer={isFullscreen}
                >
                    <Select
                        disabled={isRestrictedCoordinate || isLoading}
                        data-testid="select-ring-radius"
                        options={radiusOptions?.map((option) => ({
                            label: `${option} ft`,
                            value: `${option}`,
                        }))}
                        value={`${value}`}
                        onChange={(value) => onChangeOverlay(Number(value))}
                        className={style.selectRingRadius}
                        dropdownClassName={style.dropdown}
                    />
                </Tooltip>
            </div>

            <RegularOptionBody poi={overlayPoi} enableSelection={enableSelection} />
        </>
    );
};
