import { createSlice } from '@reduxjs/toolkit';
import {
    PlatformFields,
    PreferencesFields,
    UserInformationFields,
} from 'features/settings/shared/models/settings-field';
import { Action } from 'redux';
import { ExportFields } from 'features/settings/shared/types/account-settings';

export interface SettingsState {
    tabs: {
        platformConfiguration?: PlatformFields;
        userInformation?: UserInformationFields;
        exportConfiguration?: ExportFields;
    };
}

export const initialSettingsState: SettingsState = {
    tabs: {},
};

interface SettingsAction<T> extends Action {
    payload: T;
}

const settingsSlice = createSlice({
    name: 'settings',
    initialState: initialSettingsState,
    reducers: {
        setPlatformConfiguration: (
            state: SettingsState,
            action: SettingsAction<PlatformFields>,
        ) => {
            state.tabs.platformConfiguration = {
                ...state.tabs.platformConfiguration,
                ...action.payload,
            };
        },
        setUserPreferences: (state: SettingsState, action: SettingsAction<PreferencesFields>) => {
            const {
                // User Information
                account_name,
                email,
                person_name_two_fields,
                member_since,
                ...platformConfiguration
            } = action.payload;

            state.tabs.platformConfiguration = { ...platformConfiguration };

            state.tabs.userInformation = {
                account_name,
                email,
                person_name_two_fields,
                member_since,
            };
        },
        setExportSettings: (state: SettingsState, action: SettingsAction<ExportFields>) => {
            state.tabs.exportConfiguration = {
                ...state.tabs.exportConfiguration,
                ...action.payload,
            };
        },
    },
});

export const settingsReducer = {
    settings: settingsSlice.reducer,
};

export const settingsActions = settingsSlice.actions;
