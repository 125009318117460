import { ReportOptions } from 'core/entities';

export interface ChainsReportUiState {
    isStickyBarOpen: boolean;
    isDatePickerOpen: boolean;
    isCustomFilterModalOpen: boolean;
    isMissingPermissionsCheckSuppressed: boolean;
    reportOptions: ReportOptions;
}

export const createReportChainsUiState = (): ChainsReportUiState => ({
    isStickyBarOpen: false,
    isDatePickerOpen: false,
    isCustomFilterModalOpen: false,
    isMissingPermissionsCheckSuppressed: false,
    reportOptions: {
        entitiesData: [],
    },
});
