import { useSelectorWithProps } from 'utils/structured-selector/structured-selector';
import { SEARCH_OPTION_POI_STORE_KEY } from 'components/search-options-dialog/consts';
import { selectKeyValue } from 'store/app-ui/selectors/application-selectors-factory';
import { useCallback, useEffect } from 'react';
import { setKeyValue } from 'store/app-ui/actions';
import { useAppDispatch } from 'store/app-store-hooks';
import { useSetResults } from 'features/explore/context/use-explore-ui-state-actions';
import { useFetchEnclosingComplexes } from 'features/explore/hooks/use-explore-enclosing-complex';
import { ExploreSearchResult } from 'features/explore/types/types';
import type { Venue } from '@placer-ui/types';

export const useSearchDialogVenue = () =>
    useSelectorWithProps(SEARCH_OPTION_POI_STORE_KEY, selectKeyValue) as Venue;

export const useCloseSearchDialog = () => {
    const dispatch = useAppDispatch();

    return useCallback(() => {
        dispatch(
            setKeyValue({
                key: SEARCH_OPTION_POI_STORE_KEY,
                value: null,
            }),
        );
    }, [dispatch]);
};

export const useSyncDialogResult = () => {
    const venue = useSearchDialogVenue();
    const setResults = useSetResults();
    const fetchEnclosingComplex = useFetchEnclosingComplexes();

    useEffect(() => {
        const exploreResult: ExploreSearchResult = {
            info: venue,
        };

        if (venue) {
            setResults([exploreResult]);
            fetchEnclosingComplex([venue]);
        }
    }, [venue, setResults, fetchEnclosingComplex]);
};
