import { useAppDispatch } from 'store/app-store-hooks';
import { useCallback } from 'react';
import { PayloadActionCreator } from '@reduxjs/toolkit';
import { CreateEntityProps } from 'features/export/dynamic-feature-context-provider';
import type { Place, PlacerEntityModel } from '@placer-ui/types';

export const useInitializeReportEntities = () => {
    const dispatch = useAppDispatch();
    return useCallback(
        async <T extends Place>(
            data: CreateEntityProps[],
            action: PayloadActionCreator<PlacerEntityModel<T>[]>,
        ) => {
            const entities = data.map(({ entityData, entityParams }) => ({
                filters: entityParams.filters,
                originalEntity: entityData as T,
                uid: entityParams.uid,
                color: entityParams.color,
            }));

            dispatch(action(entities as PlacerEntityModel<T>[]));
        },
        [dispatch],
    );
};
