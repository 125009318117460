/* eslint-disable max-len */
import React from 'react';

export const SgEntertainmentIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
        >
            <g clipPath="url(#clip0_3566_18502)">
                <path
                    d="M10.3907 7.81875C10.5118 7.55851 10.7699 7.39126 11.056 7.41357C11.7714 7.46933 13.2315 7.67678 14.8991 8.4426C16.4715 9.16474 17.7111 10.2363 18.2883 10.7866C18.5079 10.996 18.5585 11.3204 18.4305 11.5955L16.4667 15.8154C15.4318 18.0393 12.7901 19.0032 10.5662 17.9683C8.34232 16.9334 7.37846 14.2916 8.41336 12.0677L10.3907 7.81875Z"
                    fill="#E1E2FF"
                    stroke="#5E63E5"
                    strokeWidth="0.938874"
                />
                <path
                    d="M13.3206 15.9008C13.3672 15.9235 13.4238 15.9041 13.4428 15.8559C13.503 15.7033 13.5327 15.5389 13.5304 15.3691C13.5278 15.1669 13.4799 14.961 13.3895 14.7634C13.2991 14.5657 13.168 14.3801 13.0037 14.217C12.8393 14.054 12.645 13.9168 12.4317 13.8132C12.2184 13.7096 11.9904 13.6417 11.7607 13.6133C11.5309 13.585 11.3039 13.5967 11.0927 13.6479C10.8815 13.699 10.6901 13.7886 10.5295 13.9116C10.3947 14.0148 10.2838 14.1398 10.2011 14.2815C10.1749 14.3262 10.1947 14.3826 10.2413 14.4053L11.781 15.153L13.3206 15.9008Z"
                    fill="#5E63E5"
                    stroke="#5E63E5"
                    strokeWidth="0.938874"
                />
                <path
                    d="M15.5165 13.0892C15.5524 13.1266 15.6126 13.1281 15.6437 13.0866C15.6819 13.0356 15.712 12.978 15.7331 12.9151C15.7647 12.8213 15.7758 12.7177 15.7656 12.6103C15.7555 12.5028 15.7244 12.3935 15.6741 12.2887C15.6238 12.1839 15.5553 12.0856 15.4725 11.9995C15.3896 11.9133 15.2941 11.8409 15.1914 11.7865C15.0887 11.7321 14.9807 11.6967 14.8738 11.6823C14.7668 11.6679 14.6628 11.6748 14.5679 11.7026C14.5042 11.7213 14.4454 11.7491 14.3929 11.7852C14.3502 11.8146 14.3494 11.8748 14.3853 11.9122L14.9509 12.5007L15.5165 13.0892Z"
                    fill="#5E63E5"
                    stroke="#5E63E5"
                    strokeWidth="0.938874"
                />
                <path
                    d="M12.6768 11.0528C12.7283 11.0592 12.7759 11.0224 12.7738 10.9706C12.7713 10.9069 12.7583 10.8432 12.7351 10.7811C12.7004 10.6884 12.6436 10.6011 12.5679 10.5241C12.4922 10.4471 12.3992 10.382 12.294 10.3324C12.1889 10.2828 12.0737 10.2498 11.9551 10.2352C11.8364 10.2206 11.7167 10.2247 11.6027 10.2473C11.4886 10.2699 11.3825 10.3105 11.2904 10.3669C11.1984 10.4232 11.1221 10.4942 11.066 10.5757C11.0284 10.6303 11.0003 10.689 10.9824 10.7501C10.9678 10.7999 11.0051 10.8471 11.0566 10.8534L11.8667 10.9531L12.6768 11.0528Z"
                    fill="#5E63E5"
                    stroke="#5E63E5"
                    strokeWidth="0.938874"
                />
                <path
                    d="M1.20513 5.16827C1.14765 4.86798 1.26647 4.56271 1.52761 4.40373C2.18039 4.00631 3.57104 3.27606 5.48885 2.89895C7.29723 2.54335 9.0357 2.69701 9.87717 2.81294C10.1973 2.85704 10.4431 3.10578 10.5038 3.42319L11.4358 8.29236C11.9269 10.8583 10.2449 13.3366 7.67894 13.8278C5.11296 14.3189 2.63466 12.6369 2.14352 10.0709L1.20513 5.16827Z"
                    fill="white"
                    stroke="#5E63E5"
                />
                <path
                    d="M5.03999 9.77515C4.98587 9.78617 4.95056 9.83912 4.96568 9.89224C5.0135 10.0602 5.09482 10.2186 5.20662 10.3608C5.33973 10.5301 5.51344 10.6732 5.71782 10.7819C5.9222 10.8906 6.15325 10.9628 6.39779 10.9944C6.64232 11.026 6.89554 11.0163 7.143 10.9659C7.39045 10.9154 7.6273 10.8253 7.84 10.7006C8.05271 10.5759 8.23711 10.4191 8.38269 10.2391C8.52826 10.0591 8.63215 9.85949 8.68843 9.65159C8.7357 9.47699 8.74861 9.29944 8.7269 9.12612C8.72003 9.07132 8.66682 9.0364 8.61271 9.04742L6.82635 9.41129L5.03999 9.77515Z"
                    fill="#E1E2FF"
                    stroke="#E1E2FF"
                />
                <path
                    d="M8.92714 6.04911C8.98236 6.0499 9.02858 6.0055 9.02043 5.95088C9.01041 5.88377 8.98932 5.81779 8.95757 5.75468C8.91019 5.66052 8.84001 5.57457 8.75103 5.50175C8.66205 5.42892 8.55601 5.37065 8.43897 5.33025C8.32192 5.28986 8.19617 5.26813 8.06888 5.26631C7.94159 5.26449 7.81527 5.28261 7.69712 5.31964C7.57897 5.35667 7.4713 5.41189 7.38027 5.48214C7.28925 5.55239 7.21664 5.63629 7.16659 5.72906C7.13304 5.79124 7.11008 5.85658 7.09814 5.92338C7.08842 5.97774 7.13336 6.02345 7.18858 6.02424L8.05786 6.03667L8.92714 6.04911Z"
                    fill="#E1E2FF"
                    stroke="#E1E2FF"
                />
                <path
                    d="M5.1489 6.52572C5.19979 6.50427 5.2243 6.44505 5.1949 6.39829C5.15878 6.34085 5.11298 6.28889 5.05856 6.24384C4.97736 6.17662 4.87858 6.12608 4.76785 6.09511C4.65712 6.06414 4.5366 6.05334 4.41319 6.06333C4.28977 6.07332 4.16587 6.10391 4.04857 6.15334C3.93126 6.20278 3.82284 6.27009 3.72949 6.35145C3.63615 6.4328 3.55971 6.52659 3.50454 6.62748C3.44938 6.72836 3.41656 6.83436 3.40797 6.93941C3.40221 7.00983 3.40741 7.0789 3.42329 7.14486C3.43622 7.19856 3.49572 7.22238 3.54662 7.20093L4.34776 6.86332L5.1489 6.52572Z"
                    fill="#E1E2FF"
                    stroke="#E1E2FF"
                />
                <path d="M13.3321 3.06419L13.157 4.5377" stroke="#5E63E5" strokeLinecap="round" />
                <path d="M16.2515 3.35371L14.7438 5.02279" stroke="#5E63E5" strokeLinecap="round" />
                <path d="M17.0427 6.50795L15.4607 6.46302" stroke="#5E63E5" strokeLinecap="round" />
            </g>
            <defs>
                <clipPath id="clip0_3566_18502">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
