/* eslint-disable max-len */
import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import type { Place } from '@placer-ui/types';
import styles from './poi-box.module.scss';
import { RunVoidAnalysisForEmptySpaceButton } from 'features/void-analysis/sections/empty-space/components/method-selection/run-void-analysis-button/run-void-analysis-button';
import { useEmptySpacePermissions } from 'features/void-analysis/sections/empty-space/hooks/use-empty-space-permissions';
import { RunVoidAnalysisButton } from 'features/void-analysis/sections/add-new-report/components/run-void-analysis-button/run-void-analysis-button';

type PoiBoxProps = {
    poi: Place;
    isSelected: boolean;
};
export const PoiBox = ({ poi, isSelected }: PoiBoxProps) => {
    const [isMouseOver, setIsMouseOver] = useState<boolean>(false);
    const { hasEmptySpacePermission } = useEmptySpacePermissions();

    const onMouseOver = () => {
        setIsMouseOver(true);
    };
    const onMouseOut = () => {
        setIsMouseOver(false);
    };

    const runVoidAnalysisButton = useMemo(() => {
        return hasEmptySpacePermission ? (
            <RunVoidAnalysisForEmptySpaceButton poi={poi} show={isMouseOver} />
        ) : (
            <RunVoidAnalysisButton poi={poi} show={isMouseOver} />
        );
    }, [hasEmptySpacePermission, poi, isMouseOver]);

    return (
        <div
            className={classNames(styles.boxContainer, { [styles.isSelected]: isSelected })}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
        >
            <div>
                <div className={styles.title}>{poi.name}</div>
                <div>{poi.address?.formatted_address}</div>
            </div>
            {runVoidAnalysisButton}
        </div>
    );
};
