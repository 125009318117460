/* eslint-disable max-len */
import React from 'react';

export function WeatherSnowWithThunderIcon() {
    return (
        <svg width="70" height="54" viewBox="0 0 70 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M42.3394 23C41.5509 23 40.836 23.4632 40.5139 24.1829L35.4351 35.5291C34.5468 37.5138 35.9989 39.7548 38.1733 39.7548H41.0001C42.2306 39.7548 43.1693 40.8554 42.9751 42.0705L41.2681 52.7487C41.1631 53.4053 41.6703 54 42.3352 54C42.7112 54 43.0602 53.8046 43.2566 53.484L53.3487 37.016C54.1653 35.6834 53.2064 33.971 51.6434 33.971H48.0416C46.593 33.971 45.6249 32.4789 46.2152 31.1561L49.8542 23H42.3394Z" fill="#FFD89E"/>
<path fillRule="evenodd" clipRule="evenodd" d="M13.9791 34.021C13.9791 33.4571 14.4362 33 15.0001 33C15.564 33 16.0211 33.4571 16.0211 34.021V36.8471L18.4686 35.434C18.957 35.1521 19.5814 35.3194 19.8633 35.8078C20.1453 36.2961 19.978 36.9205 19.4896 37.2025L17.042 38.6156L19.4895 40.0286C19.9779 40.3106 20.1452 40.935 19.8632 41.4234C19.5813 41.9117 18.9569 42.079 18.4685 41.7971L16.0211 40.384V43.21C16.0211 43.7738 15.564 44.2309 15.0001 44.2309C14.4362 44.2309 13.9791 43.7738 13.9791 43.21V40.3839L11.5318 41.7969C11.0434 42.0789 10.419 41.9116 10.1371 41.4232C9.85512 40.9349 10.0224 40.3105 10.5108 40.0285L12.9581 38.6156L10.5107 37.2026C10.0223 36.9206 9.85502 36.2962 10.137 35.8079C10.4189 35.3195 11.0433 35.1522 11.5317 35.4342L13.9791 36.8472V34.021Z" fill="#D0D0D0"/>
<path fillRule="evenodd" clipRule="evenodd" d="M26.9793 42.021C26.9793 41.4571 27.4364 41 28.0003 41C28.5641 41 29.0213 41.4571 29.0213 42.021V44.7316L31.3688 43.3762C31.8571 43.0943 32.4816 43.2616 32.7635 43.7499C33.0454 44.2383 32.8781 44.8627 32.3898 45.1446L30.0422 46.5L32.3896 47.8553C32.878 48.1372 33.0453 48.7617 32.7633 49.25C32.4814 49.7383 31.857 49.9057 31.3686 49.6237L29.0213 48.2685V50.979C29.0213 51.5429 28.5641 52 28.0003 52C27.4364 52 26.9793 51.5429 26.9793 50.979V48.2684L24.6319 49.6236C24.1436 49.9056 23.5192 49.7383 23.2372 49.2499C22.9553 48.7616 23.1226 48.1372 23.6109 47.8552L25.9582 46.5L23.6108 45.1447C23.1224 44.8628 22.9551 44.2383 23.2371 43.75C23.519 43.2617 24.1434 43.0943 24.6318 43.3763L26.9793 44.7316V42.021Z" fill="#D0D0D0"/>
<path d="M55.517 36.9878C55.517 36.9878 55.517 36.9878 55.517 36.9878L60.3979 37H60.3997C64.6023 36.999 67.9999 33.602 67.9999 29.423C67.9999 25.2428 64.6012 21.8455 60.3979 21.8455H57.6612L57.5014 20.0199C56.6348 10.1227 48.2969 2 38.253 2C31.468 2 25.4995 5.48523 22.0502 10.7643C25.6429 11.9082 28.7151 14.2214 30.8214 17.2551C31.1364 17.7088 31.0239 18.3319 30.5703 18.6469C30.1166 18.9619 29.4935 18.8494 29.1785 18.3958C26.9639 15.2059 23.5448 12.9235 19.5835 12.2086C18.7228 12.0546 17.8352 11.9739 16.927 11.9739C15.3544 11.9739 13.839 12.2162 12.4161 12.6654C6.38919 14.5768 2.01589 20.2206 2 26.8931C2.00925 30.757 3.49312 34.2731 5.92549 36.9158H8.0596C7.91888 36.2197 8.02167 35.4713 8.40471 34.8079C9.14481 33.526 10.6825 32.9917 12.0291 33.47C12.2881 32.0646 13.5197 31 14.9999 31C16.4802 31 17.7117 32.0646 17.9708 33.4699C19.3173 32.9915 20.8551 33.5258 21.5952 34.8078C21.9783 35.4712 22.081 36.2196 21.9403 36.9158H26.646L33.1706 36.9321C33.1444 36.2014 33.2808 35.4461 33.6095 34.712L38.6882 23.3658C39.3325 21.9265 40.7623 21 42.3392 21H49.854C51.3026 21 52.2707 22.4921 51.6804 23.8149L48.0414 31.971H51.6432C54.3611 31.971 56.1658 34.56 55.517 36.9878Z" fill="#D0D0D0"/>
</svg>

    );
}
WeatherSnowWithThunderIcon.__tags = [] as string[];
