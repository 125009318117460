/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

export type UncheckedToggleIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type UncheckedToggleIconProps = SVGProps<SVGSVGElement> & UncheckedToggleIconUniqueProps;

export const UncheckedToggleIcon = function UncheckedToggleIcon({
    width = 8,
    height = 8,
    ...props
}: UncheckedToggleIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.42981 4.02891C7.42981 4.36028 7.16065 4.62891 6.82863 4.62891L1.03099 4.62891C0.698967 4.62891 0.42981 4.36028 0.42981 4.02891C0.42981 3.69754 0.698967 3.42891 1.03099 3.42891L6.82863 3.42891C7.16065 3.42891 7.42981 3.69754 7.42981 4.02891Z"
                fill="#49565D"
            />
        </svg>
    );
};
UncheckedToggleIcon.__tags = [] as string[];
