import { WidgetFactoryClass } from 'extensions/widget/factories/widget-components-factory';
import { recoveryReducer } from 'features/recovery-dashboard/store/recovery-reducer';

export const recoveryDashboardReducer = {
    recovery: recoveryReducer,
};

export type RecoveryDashboardState = {
    recovery: ReturnType<typeof recoveryReducer>;
};

export const RecoveryDashboardWidgetFactory = new WidgetFactoryClass();
