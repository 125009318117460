import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { ReportOptionsResponse } from 'API/report-options-api/report-options-api';

export const initialReportOptionsState: ReportOptionsResponse = {
    time_range: {
        from_date: '',
        to_date: '',
    },
    entities_data: [],
};

export const reportOptionsSlice = createSlice({
    name: 'reportOptions',
    initialState: initialReportOptionsState,
    reducers: {
        setReportOptions: (
            state: ReportOptionsResponse,
            action: PayloadAction<ReportOptionsResponse>,
        ) => {
            return {
                ...state,
                ...action.payload,
            };
        },
    },
});

export const reportOptionsActions = reportOptionsSlice.actions;
