/* eslint-disable max-len */
import { cloneDeep } from 'lodash/fp';
import {
    FilterEntity,
    FilterTypeCheckboxes,
    FilterTypeSubstring,
} from 'features/void-analysis/common/types/filters';
import {
    FilterRequestTypeFilterTypeCheckboxes,
    RequestFilterEntity,
} from 'features/void-analysis/common/types/request-filters';
import { selectPOIData, useVoidAnalysisSelectors } from 'features/void-analysis/store/selectors';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/app-store-hooks';
import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useVoidAnalysisActions } from 'features/void-analysis/store/actions';
import { voidAnalysisActions as actions } from 'features/void-analysis/store/slice';
import { useDebouncedCallback } from 'use-debounce';
import {
    FILTER_CHANGE_DEBOUNCE_TIME,
    MEDIAN_DRIVE_TIME_COLUMN,
    TABLE_PAGE_SIZE,
} from 'features/void-analysis/constants';
import type { Dictionary } from '@placer-ui/types';
import { ScoreBounds, ScoreBoundsKeys } from 'features/void-analysis/common/types/server-responses';
import {
    useSettingsCallback,
    useVoidRequest,
    useVoidRequestColumnsInfo,
} from 'features/void-analysis/common/hooks/use-settings-callback';
import {
    GetRankingResultCsvType,
    RankingsRequestBody,
    voidAnalysisApi,
} from 'API/void-analysis-api';
import { downloadFile } from 'API/common/download-file-service/download-file-service';
import {
    RankingTableColumn,
    RequestUserSettings,
    RequestUserSettingsObject,
    RequestUserSettingsObjectKeys,
} from '../types/ranking-table';
import { parseMetricValue } from '../utils/metric-value-formatter';
import { useSplunkCallback } from 'hooks/use-splunk-callback/use-splunk-callback';
import { VoidAnalysisEventData } from 'types/splunk/events';
import { dateFormats, getSameYearFormatRange } from 'utils/date/date';
import { useSelectorWithProps } from 'utils/structured-selector/structured-selector';
import { selectHasUserPermission } from 'store/selectors/app-selectors';
import { useGetRankingTableSortBy } from 'features/void-analysis/sections/ranking-page/components/report-content/components/void-analysis-ranking-table/hooks/table-sorting';
import { spacesToUnderscore } from 'utils/spaces-to-underscore/spaces-to-underscore';
import { VoidAnalysisUi } from 'features/void-analysis/common/types/ui';
import { useEmptySpacePermissions } from 'features/void-analysis/sections/empty-space/hooks/use-empty-space-permissions';
import { TABLE_MAX_COLUMNS } from 'features/void-analysis/constants';
import { useVisibleValueColumnsList } from 'features/void-analysis/sections/ranking-page/components/report-content/components/void-analysis-ranking-table/hooks/ranking-table-columns';
import { useHeaderContent } from 'features/void-analysis/common/components/header/header-hooks';
import { sortedCheckboxFilterOptions } from 'components/advanced-reports-analysis/filters/checkbox-list-filter/checkbox-list-filter-utils';
import type { FiltersDataType } from 'features/void-analysis/sections/ranking-page/components/report-content/components/report-actions/components/export-modal/constants';
import { removeEmptyFilters } from 'features/void-analysis/common/utils/filters';
import { isEmpty } from 'lodash';
import { useDivComponentWidth } from 'features/void-analysis/common/hooks/use-div-component-width';

type SharedUserSettings = {
    filters: RequestFilterEntity[];
    showClearAll: boolean;
    useDefaultFilters: boolean;
};

const getSortedCheckboxFilterOptions = (
    filter: FilterTypeCheckboxes,
    lastRequest?: FilterRequestTypeFilterTypeCheckboxes,
) => {
    const preferInclude = (checkedCounter: number, uncheckedCounter: number) =>
        lastRequest?.action === 'include' ||
        checkedCounter <= uncheckedCounter ||
        checkedCounter < 50;

    return sortedCheckboxFilterOptions(filter.options, preferInclude);
};

const useRequestUserSettingsObject = (reportId: string) => {
    const bodyRequest = useVoidRequest(reportId);
    if (!bodyRequest) {
        return undefined;
    } else {
        let convertedRequest: RequestUserSettingsObject = {};
        // there can be two different formats for bodyRequest
        if (Array.isArray(bodyRequest)) {
            convertedRequest.requestFilters = bodyRequest;
        } else {
            convertedRequest = { ...bodyRequest };
        }
        convertedRequest.requestFilters = removeEmptyFilters(convertedRequest.requestFilters ?? []);
        return convertedRequest;
    }
};

export const useRequestedFilters = (reportId: string): RequestFilterEntity[] => {
    const { selectRequestFilters } = useVoidAnalysisSelectors();
    const requestedFilters = useSelector(selectRequestFilters);
    const requestUserSettingsObject = useRequestUserSettingsObject(reportId);
    if (requestedFilters.length) {
        return requestedFilters;
    } else {
        return requestUserSettingsObject?.requestFilters ?? [];
    }
};

export const useRequestedColumns = (reportId: string): RankingTableColumn[] => {
    const visibleColumns = useTableVisibleColumns();
    return useRequestedColumnProperty(reportId, visibleColumns, 'requestColumns');
};

export const useRequestedEditTableColumnsSorted = (reportId: string): RankingTableColumn[] => {
    const editTableColumnsSorted = useEditTableColumnsSorted();
    return useRequestedColumnProperty(reportId, editTableColumnsSorted, 'editTableColumnsSorted');
};

const useRequestedColumnProperty = (
    reportId: string,
    currentColumnProperty: RankingTableColumn[],
    userSettingsObjectName: RequestUserSettingsObjectKeys,
): RankingTableColumn[] => {
    const columnsInfo = useVoidRequestColumnsInfo() as RequestUserSettingsObject;
    const bodyRequest = useVoidRequest(reportId);
    const driveTimeFilter = useFilterDriveTimeByPermission();

    if (columnsInfo) {
        return driveTimeFilter(
            columnsInfo[userSettingsObjectName] as RankingTableColumn[],
            userSettingsObjectName,
        );
    }

    if (bodyRequest) {
        if (!Array.isArray(bodyRequest)) {
            const requestUserSettingsObject = bodyRequest as RequestUserSettingsObject;
            const requestUserSettingsColumnValue = requestUserSettingsObject[
                userSettingsObjectName
            ] as RankingTableColumn[] | undefined;
            return requestUserSettingsColumnValue
                ? driveTimeFilter(requestUserSettingsColumnValue, userSettingsObjectName)
                : driveTimeFilter(currentColumnProperty, userSettingsObjectName);
        }
    }
    return driveTimeFilter(currentColumnProperty, userSettingsObjectName);
};

const useFilterDriveTimeByPermission = () => {
    const { hasEmptySpacePermission } = useEmptySpacePermissions();
    const columnsList = useVisibleValueColumnsList();
    const editTableColumnsSorted = useEditTableColumnsSorted();
    const columnsInfo = useVoidRequestColumnsInfo() as RequestUserSettingsObject;
    const filterChainName = (column: RankingTableColumn) => column !== 'chain_name';

    return useCallback(
        (
            tableColumn: RankingTableColumn[],
            userSettingsObjectName?: RequestUserSettingsObjectKeys,
        ) => {
            if (hasEmptySpacePermission && !tableColumn.includes(MEDIAN_DRIVE_TIME_COLUMN)) {
                switch (userSettingsObjectName) {
                    case 'editTableColumnsSorted':
                        if (!editTableColumnsSorted.includes(MEDIAN_DRIVE_TIME_COLUMN)) {
                            if (!tableColumn.length) {
                                return [
                                    'chain_name' as RankingTableColumn,
                                    MEDIAN_DRIVE_TIME_COLUMN,
                                    ...columnsList
                                        .filter(
                                            (col) =>
                                                col.key !== 'chain_name' &&
                                                col.key !== 'median_dvt',
                                        )
                                        .map((col) => col.key),
                                ];
                            }
                            return [
                                'chain_name' as RankingTableColumn,
                                MEDIAN_DRIVE_TIME_COLUMN,
                                ...tableColumn.filter(filterChainName),
                            ];
                        }
                        break;
                    case 'requestColumns':
                        if (
                            !editTableColumnsSorted.includes(MEDIAN_DRIVE_TIME_COLUMN) &&
                            (!columnsInfo ||
                                !columnsInfo['editTableColumnsSorted']?.includes(
                                    MEDIAN_DRIVE_TIME_COLUMN,
                                ))
                        ) {
                            if (tableColumn.length === TABLE_MAX_COLUMNS) {
                                return [
                                    'chain_name' as RankingTableColumn,
                                    MEDIAN_DRIVE_TIME_COLUMN,
                                    ...tableColumn.filter(filterChainName).slice(1),
                                ];
                            }
                            return [
                                'chain_name' as RankingTableColumn,
                                MEDIAN_DRIVE_TIME_COLUMN,
                                ...tableColumn.filter(filterChainName),
                            ];
                        }
                }
            }
            return hasEmptySpacePermission
                ? tableColumn
                : tableColumn.filter((column) => column !== MEDIAN_DRIVE_TIME_COLUMN);
        },
        [hasEmptySpacePermission, columnsList, editTableColumnsSorted, columnsInfo],
    );
};

export const useRequestedScoreBounds = (): ScoreBounds | undefined => {
    const { selectScoreBounds } = useVoidAnalysisSelectors();
    const scoreBounds = useSelector(selectScoreBounds);

    return scoreBounds;
};

export const useCheckboxFilterClearSearch = (): boolean | undefined => {
    const { selectCheckboxFilterClearSearch } = useVoidAnalysisSelectors();
    return useSelector(selectCheckboxFilterClearSearch);
};

export const useShowClearAll = (): boolean => {
    const reportId = useReportId();
    const { selectShowClearAll } = useVoidAnalysisSelectors();
    const showClearAll = useSelector(selectShowClearAll);
    const requestUserSettingsObject = useRequestUserSettingsObject(reportId);
    return showClearAll ?? requestUserSettingsObject?.showClearAll ?? true;
};

export const useAreVoidEntitiesInitialized = (): boolean => {
    const { selectEntities } = useVoidAnalysisSelectors();
    const entities = useSelector(selectEntities);

    return !isEmpty(entities);
};

export const useIsUsedDefaultFilters = (): boolean => {
    const reportId = useReportId();
    const { selectUseDefaultFilters } = useVoidAnalysisSelectors();
    const useDefaultFilters = useSelector(selectUseDefaultFilters);
    const requestUserSettingsObject = useRequestUserSettingsObject(reportId);
    return useDefaultFilters ?? requestUserSettingsObject?.useDefaultFilters ?? true;
};

export const useSelectAllFilters = (): Dictionary<FilterEntity | undefined> => {
    const { selectAllFilters } = useVoidAnalysisSelectors();
    return useSelector(selectAllFilters);
};

export const useFindFilterByName = (name: string): FilterEntity | undefined => {
    const allFilters = useSelectAllFilters();
    return allFilters[name];
};

export const useChangedFilters = (): FilterEntity[] => {
    const allFilters = useSelectAllFilters();
    return useMemo(
        () =>
            Object.entries(allFilters)
                .filter(([key, value]) => value?.changed)
                .map<FilterEntity>((filterEntryList) => filterEntryList[1]!),
        [allFilters],
    );
};

export const useUpdateRecentReportsIfNecessary = () => {
    const recentReports = useRecentReports();
    const { fetchRecentReportsList } = useVoidAnalysisActions();
    return useCallback(
        (id: string) => {
            if (
                recentReports &&
                !recentReports.find((report) => report.id.toString() === id.toString())
            ) {
                fetchRecentReportsList();
            }
        },
        [recentReports, fetchRecentReportsList],
    );
};

export const useRecentReports = () => {
    const { selectRecentReports } = useVoidAnalysisSelectors();
    return useSelector(selectRecentReports);
};

export const useReportId = () => {
    const { selectReportId } = useVoidAnalysisSelectors();
    return useSelector(selectReportId);
};

export const useRankingContentIsLoading = () => {
    const { selectIsLoadingContent } = useVoidAnalysisSelectors();
    return useSelector(selectIsLoadingContent);
};

export const useIsRequestingRankingData = () => {
    const { selectIsRequestingData } = useVoidAnalysisSelectors();
    return useSelector(selectIsRequestingData);
};

export const useRankingPageHasError = () => {
    const { selectIsGeneralError } = useVoidAnalysisSelectors();
    return useSelector(selectIsGeneralError);
};

export const useGetErrors = () => {
    const { selectErrors } = useVoidAnalysisSelectors();
    return useSelector(selectErrors);
};

export const useCurrentPage = () => {
    const { selectCurrentPage } = useVoidAnalysisSelectors();
    return useSelector(selectCurrentPage);
};

export const useMatchScoreCalculation = () => {
    const { selectMatchScoreCalculation } = useVoidAnalysisSelectors();
    return useSelector(selectMatchScoreCalculation);
};

export const usePrintCustomization = () => {
    const { selectPrintCustomization } = useVoidAnalysisSelectors();
    return useSelector(selectPrintCustomization);
};

export const useReportMetadata = () => {
    const { selectReportMetadata } = useVoidAnalysisSelectors();
    return useSelector(selectReportMetadata);
};

export const useRequestAdvancedSettingsWithVersion = () => {
    const { getAdvancedSettings } = useVoidAnalysisActions();
    return useCallback(() => {
        return getAdvancedSettings();
    }, [getAdvancedSettings]);
};

export const useHasVoidHouseholdsPermission = () =>
    useSelectorWithProps({ permission: 'void_enable_households' }, selectHasUserPermission);

export const useGetAdvancedSettings = () => {
    const { selectAdvancedSettings } = useVoidAnalysisSelectors();

    return useSelector(selectAdvancedSettings);
};

export const useGetMethodsInfo = () => {
    const { selectMethodsInfo } = useVoidAnalysisSelectors();
    return useSelector(selectMethodsInfo);
};

export const useIsAdvancedSettingsVisible = () => {
    const { selectIsAdvancedSettingsVisible } = useVoidAnalysisSelectors();
    return useSelector(selectIsAdvancedSettingsVisible);
};

export const useEntityData = () => {
    const { selectEntityData } = useVoidAnalysisSelectors();
    return useSelector(selectEntityData);
};

export const usePOIData = () => {
    return useSelector(selectPOIData);
};

export const useCurrentPageTableRecords = () => {
    const { selectCurrentPageTableRecords } = useVoidAnalysisSelectors();
    return useSelector(selectCurrentPageTableRecords);
};

export const useTableRecords = () => {
    const tableRecords = useCurrentPageTableRecords();

    const recordsWithRoundedParamValues = useMemo(() => {
        const copy = cloneDeep(tableRecords);
        copy.currentPageRecords?.forEach((pageRecord) => {
            Object.keys(pageRecord.match_score.params).forEach((paramKey) => {
                const params = pageRecord.match_score.params[paramKey as ScoreBoundsKeys];

                // if the value is null, then the breakdown has null in values too
                if (params && params.value !== null && params.breakdown) {
                    Object.keys(params.breakdown).forEach((breakdownKey) => {
                        const value = params.breakdown![breakdownKey] as number;
                        params.breakdown![breakdownKey] = parseMetricValue(
                            value,
                            paramKey as ScoreBoundsKeys,
                        );
                    });
                }
            });
        });
        return copy;
    }, [tableRecords]);

    return recordsWithRoundedParamValues;
};

export const useTableVisibleColumns = () => {
    const { selectVisibleColumns } = useVoidAnalysisSelectors();
    return useSelector(selectVisibleColumns);
};

export const useEditTableColumnsSorted = () => {
    const { selectEditTableColumnsSorted } = useVoidAnalysisSelectors();
    return useSelector(selectEditTableColumnsSorted);
};

export const useGetUiProperties = () => {
    const { selectUiProperties } = useVoidAnalysisSelectors();
    return useSelector(selectUiProperties);
};

export const useSetUiPropertyWidth = (
    ref: MutableRefObject<HTMLDivElement | null>,
    uiPropertyKey: keyof VoidAnalysisUi,
    padding: number = 0,
) => {
    const { setUiProperties } = useVoidAnalysisActions();
    const divWidth = useDivComponentWidth(ref, padding);

    useEffect(() => {
        setUiProperties({ [uiPropertyKey]: divWidth });
    }, [setUiProperties, ref, uiPropertyKey, divWidth]);
};

export const useSetHeaderMapState = () => {
    const { setUiProperties } = useVoidAnalysisActions();
    return useCallback(
        (val: boolean) => {
            setUiProperties({
                reportsPage: {
                    headerMapExpanded: val,
                },
            });
        },
        [setUiProperties],
    );
};

export const useUserSettingsProperties = () => {
    const reportId = useReportId();
    const visibleColumns = useRequestedColumns(reportId);
    const editTableColumnsSorted = useRequestedEditTableColumnsSorted(reportId);
    const rankingTableSortBy = useGetRankingTableSortBy();
    return {
        visibleColumns,
        editTableColumnsSorted,
        rankingTableSortBy,
    };
};
export const useGetRankingsRequestWithParams = () => {
    const { getRankings, removeSharedEntity } = useVoidAnalysisActions();
    const updateRecentReportsIfNecessary = useUpdateRecentReportsIfNecessary();
    const { editTableColumnsSorted, rankingTableSortBy, visibleColumns } =
        useUserSettingsProperties();

    const sendGetRankingsRequest = useCallback(
        async ({
            reportId,
            changedFilters,
            currentPage,
            useDefaultFilters,
            showClearAll,
        }: {
            reportId: string;
            changedFilters: RequestFilterEntity[];
            currentPage: number;
            useDefaultFilters: boolean;
            showClearAll: boolean;
        }) => {
            const requestData: RankingsRequestBody = {
                filters: changedFilters,
                sort: rankingTableSortBy,
                use_defaults: useDefaultFilters,
            };

            try {
                await getRankings({
                    id: reportId,
                    page: currentPage,
                    page_size: TABLE_PAGE_SIZE,
                    requestData,
                    columns: visibleColumns,
                    editTableColumnsSorted,
                    useDefaultFilters,
                    showClearAll,
                });
                updateRecentReportsIfNecessary(reportId);
                removeSharedEntity();
            } catch (error) {
                console.error(error);
            }
        },
        [
            rankingTableSortBy,
            getRankings,
            visibleColumns,
            editTableColumnsSorted,
            updateRecentReportsIfNecessary,
            removeSharedEntity,
        ],
    );

    return sendGetRankingsRequest;
};

export const useGetRequestFilters = () => {
    const reportId = useReportId();
    return useRequestedFilters(reportId);
};

export const useGetRankingResultsWithLastFilters = () => {
    const reportId = useReportId();
    const getRankingResults = useGetRankingsRequestWithParams();
    const lastRequestFilters = useRequestedFilters(reportId);
    const showClearAll = useShowClearAll();
    const useDefaultFilters = useIsUsedDefaultFilters();
    return useCallback(
        (currentPage: number = 1) => {
            return getRankingResults({
                reportId,
                changedFilters: lastRequestFilters,
                currentPage,
                useDefaultFilters,
                showClearAll,
            });
        },
        [getRankingResults, lastRequestFilters, reportId, showClearAll, useDefaultFilters],
    );
};

const useShouldRequestSharedProperties = () => {
    const reportId = useReportId();
    const sharedEntity = useSharedEntity();
    const bodyRequest = useVoidRequest(reportId);
    return !bodyRequest && !!sharedEntity;
};

const useDispatchSharedSettings = () => {
    const dispatch = useAppDispatch();

    return useCallback(
        ({ filters, showClearAll, useDefaultFilters }: SharedUserSettings) => {
            const cleanFilters = removeEmptyFilters(filters);
            dispatch(actions.setRequestFilters(cleanFilters));
            dispatch(actions.setShowClearAll(showClearAll));
            dispatch(actions.setUseDefaultFilters(useDefaultFilters));
        },
        [dispatch],
    );
};

const useRequestSharedPropertiesById = () => {
    const sharedEntity = useSharedEntity();
    const dispatchSharedSettings = useDispatchSharedSettings();
    const { getSharedReportById, removeSharedEntity } = useVoidAnalysisActions();
    const isRequestingSharedReport = useRef<boolean>(false);
    return useCallback(async () => {
        if (!sharedEntity || isRequestingSharedReport.current) return [];
        isRequestingSharedReport.current = true;
        try {
            if (sharedEntity.filters && sharedEntity.showClearAll !== undefined) {
                dispatchSharedSettings(sharedEntity as SharedUserSettings);
            } else {
                const sharedReport = await getSharedReportById(sharedEntity.shareId);
                if (sharedReport) {
                    dispatchSharedSettings(sharedReport.fe_payload as SharedUserSettings);
                }
            }
        } catch (error) {
            console.error(error);
        }
        removeSharedEntity();
    }, [sharedEntity, removeSharedEntity, dispatchSharedSettings, getSharedReportById]);
};

export const useRequestRankingsOnPageChange = () => {
    const reportId = useReportId();
    const allFilters = useSelectAllFilters();
    const { currentPageRecords } = useTableRecords();
    const currentPage = useCurrentPage();
    const hasFilters = !!Object.keys(allFilters).length;
    const shouldRequestSharedProperties = useShouldRequestSharedProperties();
    const requestSharedPropertiesById = useRequestSharedPropertiesById();
    const getRankingResults = useGetRankingResultsWithLastFilters();
    const isRequestingRankingData = useIsRequestingRankingData();
    const rankingContentIsLoading = useRankingContentIsLoading();
    const isRequestingData = isRequestingRankingData || rankingContentIsLoading;

    useEffect(() => {
        if (reportId) {
            const shouldSendRequest = !hasFilters || currentPage !== 1;
            if (!currentPageRecords && !isRequestingData && shouldSendRequest) {
                if (shouldRequestSharedProperties) {
                    requestSharedPropertiesById();
                    return;
                }
                getRankingResults(currentPage);
            }
        }
    }, [
        currentPage,
        currentPageRecords,
        getRankingResults,
        hasFilters,
        isRequestingData,
        isRequestingRankingData,
        reportId,
        requestSharedPropertiesById,
        shouldRequestSharedProperties,
    ]);
};

export const getRequestFiltersStructure = (
    changedFilters: FilterEntity[],
    lastRequestFilters?: RequestFilterEntity[],
) => {
    return changedFilters.map<RequestFilterEntity>((filter) => {
        const { name } = filter;
        switch (filter.type) {
            case 'histogram':
                return {
                    name,
                    selected_min_value: filter.selected_min_value,
                    selected_max_value: filter.selected_max_value,
                };
            case 'checkboxes':
                const lastRequest = lastRequestFilters?.find(
                    (lastRequestFilter) => lastRequestFilter.name === filter.name,
                );
                const { options, action } = getSortedCheckboxFilterOptions(
                    filter,
                    lastRequest as FilterRequestTypeFilterTypeCheckboxes,
                );
                return {
                    name,
                    action,
                    options,
                };
            case 'toggle': {
                return {
                    name,
                    checked: filter.checked,
                };
            }
            case 'tag_selector': {
                return {
                    name,
                    options: filter.options,
                };
            }
            case 'slider': {
                return {
                    name,
                    value: filter.value,
                };
            }
            case 'radio': {
                return {
                    name,
                    value: filter.value,
                };
            }
            case 'substring': {
                return {
                    name,
                    string: filter.string,
                };
            }
        }
        return filter;
    });
};

export const useResetTableRecordsAndCurrentPage = () => {
    const dispatch = useAppDispatch();
    const { setCurrentPage } = useVoidAnalysisActions();
    return useCallback(() => {
        dispatch(actions.setIsRequestingData(true));
        dispatch(actions.clearTableRecords());
        setCurrentPage(1);
    }, [dispatch, setCurrentPage]);
};

export const useChangeFilterState = () => {
    const dispatch = useAppDispatch();
    const resetTableRecordsAndCurrentPage = useResetTableRecordsAndCurrentPage();
    return useCallback(
        (filter: FilterEntity) => {
            dispatch(actions.changeFilterState(filter));
            dispatch(actions.setIsChangingFilters(true));
            resetTableRecordsAndCurrentPage();
        },
        [dispatch, resetTableRecordsAndCurrentPage],
    );
};

export const useChangeFilterStateWithSplunkEvent = () => {
    const changeFilterState = useChangeFilterState();
    const sendEvent = useSplunkCallback();
    return useCallback(
        (filter: FilterEntity, splunkEventInfo?: VoidAnalysisEventData) => {
            changeFilterState(filter);
            sendEvent(splunkEventInfo);
        },
        [changeFilterState, sendEvent],
    );
};

export const useClearAllFilters = () => {
    const dispatch = useAppDispatch();
    const {
        clearAllChangedFilters,
        setCurrentPage,
        setCheckboxFilterClearSearch,
        setShowClearAll,
        setUseDefaultFilters,
    } = useVoidAnalysisActions();
    const reportId = useReportId();
    const sendRequest = useGetRankingsRequestWithParams();

    const request = useCallback(
        (clearAllClick: boolean) => {
            const showClearAll = !clearAllClick;
            const useDefaultFilters = !clearAllClick;
            setCheckboxFilterClearSearch(true);
            setShowClearAll(showClearAll);
            setUseDefaultFilters(useDefaultFilters);
            clearAllChangedFilters();
            dispatch(actions.clearTableRecords());
            setCurrentPage(1);
            sendRequest({
                reportId,
                changedFilters: [],
                currentPage: 1,
                useDefaultFilters,
                showClearAll,
            });
        },
        [
            setCheckboxFilterClearSearch,
            setShowClearAll,
            clearAllChangedFilters,
            dispatch,
            setCurrentPage,
            sendRequest,
            reportId,
            setUseDefaultFilters,
        ],
    );
    return request;
};

export const useSearchFilter = (filterName: string) => {
    const chainNameSubstringFilter =
        (useFindFilterByName(filterName) as FilterTypeSubstring | undefined)?.string ?? '';
    const [searchText, setSearchText] = useState<string>(chainNameSubstringFilter);

    useEffect(() => {
        setSearchText(chainNameSubstringFilter);
    }, [chainNameSubstringFilter]);

    return {
        searchText,
        setSearchText,
    };
};

export const useResendGetRankingResults = () => {
    const resetTableRecordsAndCurrentPage = useResetTableRecordsAndCurrentPage();
    const getRankingResults = useGetRankingResultsWithLastFilters();
    return useCallback(() => {
        resetTableRecordsAndCurrentPage();
        getRankingResults(1);
    }, [getRankingResults, resetTableRecordsAndCurrentPage]);
};

export const useFiltersChangeWithDebounce = () => {
    const { setShowClearAll } = useVoidAnalysisActions();
    const dispatch = useAppDispatch();
    const { selectChangedFiltersList } = useVoidAnalysisSelectors();
    const changedFiltersList = useSelector(selectChangedFiltersList);
    const reportId = useReportId();
    const sendRequest = useGetRankingsRequestWithParams();
    const changedFilters = useChangedFilters();
    const lastRequestFilters = useRequestedFilters(reportId);
    const currentPage = useCurrentPage();
    const useDefaultFilters = useIsUsedDefaultFilters();

    const newFiltersChanged = changedFilters.filter((changedFilter) =>
        changedFiltersList.find((filterName) => changedFilter.name === filterName),
    );
    const lastFiltersChanged = lastRequestFilters.filter(
        (requestFilter) =>
            !newFiltersChanged.find((newFilter) => newFilter.name === requestFilter.name),
    );
    const requestFiltersStructure = [
        ...lastFiltersChanged,
        ...getRequestFiltersStructure(newFiltersChanged, lastRequestFilters),
    ];

    const callOnChangeCallBack = () => {
        dispatch(actions.setIsChangingFilters(false));
        setShowClearAll(true);
        if (newFiltersChanged.length) {
            dispatch(actions.clearChangedFiltersList());
            sendRequest({
                reportId,
                changedFilters: requestFiltersStructure,
                currentPage,
                useDefaultFilters,
                showClearAll: true,
            });
        } else {
            dispatch(actions.setIsRequestingData(false));
        }
    };

    const callOnChangeWithDebounce = useDebouncedCallback(
        callOnChangeCallBack,
        FILTER_CHANGE_DEBOUNCE_TIME,
    );

    useEffect(() => {
        if (newFiltersChanged.length) {
            callOnChangeWithDebounce();
        }
    }, [callOnChangeWithDebounce, newFiltersChanged]);
};

export const useMakeCsvFilename = () => {
    const metadata = useReportMetadata();
    const hideVaDateRangePermission = useHideVaDateRangePermission();

    return useCallback(
        (includeFilters: boolean) => {
            const filterTypeIndication = includeFilters ? 'with_filters' : 'all_chains';
            if (metadata) {
                const { entity, reportDates } = metadata;
                let fileName = `${spacesToUnderscore(entity.name)}`;
                if (!hideVaDateRangePermission) {
                    fileName += `_${reportDates.startDate}_${reportDates.endDate}`;
                }
                fileName += `_${filterTypeIndication}`;
                return fileName;
            }
            return '';
        },
        [hideVaDateRangePermission, metadata],
    );
};

export const useDownloadCsv = () => {
    const reportId = useReportId();
    const lastRequestFilters = useRequestedFilters(reportId);
    const useDefaultFilters = useIsUsedDefaultFilters();
    const makeCsvFilename = useMakeCsvFilename();

    const downloadCsv = useCallback(
        async (
            includeFilters: boolean, // fetch the filtered chains list
            includeFiltersData: FiltersDataType, // append the filters to the downloaded file
            includeContactInfo: boolean,
            customFilename?: string,
        ) => {
            try {
                const filters = includeFilters ? lastRequestFilters : [];
                let filtersDataState: GetRankingResultCsvType['filters_data_state'] = 'no_filters';
                if (includeFiltersData === 'include_in_same_file') filtersDataState = 'single_file';
                if (includeFiltersData === 'include_as_separate_file')
                    filtersDataState = 'separate_file';

                const filename = customFilename || makeCsvFilename(includeFilters);

                const requestBody: GetRankingResultCsvType = {
                    reportId,
                    filters,
                    use_defaults: includeFilters ? useDefaultFilters : false,
                    filters_data_state: filtersDataState,
                    include_contact_info: includeContactInfo,
                    filename,
                };

                const response = await voidAnalysisApi.getRankingsResultCsv(requestBody);
                if (response.status !== 200) {
                    return;
                }

                let extension = 'csv';
                const contentType = response.headers.get('Content-Type');
                if (contentType === 'text/csv') {
                    extension = 'csv';
                } else if (contentType === 'application/zip') {
                    extension = 'zip';
                } else if (filtersDataState === 'separate_file' && filters.length) {
                    // if we couldn't read the Content-Type header, we need to predict the extension.
                    // for a 2-files request, the server will respond with a zip of all files.
                    extension = 'zip';
                }

                const filenameWithExtension = `${filename}.${extension}`;
                await downloadFile(response, filenameWithExtension);
            } catch (e) {
                console.error(e);
            }
        },
        [makeCsvFilename, lastRequestFilters, reportId, useDefaultFilters],
    );

    return downloadCsv;
};

export const useSetVisibleAndEditTableColumnsSorted = () => {
    const dispatch = useAppDispatch();
    const updateUserSettings = useSettingsCallback();
    const reportId = useReportId();
    const lastRequestFilters = useRequestedFilters(reportId);
    const lastRequestSorting = useGetRankingTableSortBy();
    const setEditTableColumnsSorted = useCallback(
        (changedColumns: RankingTableColumn[], editTableColumnsSorted: RankingTableColumn[]) => {
            const requestUserSettings: RequestUserSettings = {
                requestFilters: lastRequestFilters,
                requestSorting: lastRequestSorting,
                requestColumns: changedColumns,
                editTableColumnsSorted,
            };
            dispatch(actions.setVisibleColumns(changedColumns));
            dispatch(actions.editTableColumnsSorted(editTableColumnsSorted));
            updateUserSettings(reportId, requestUserSettings);
        },
        [lastRequestFilters, lastRequestSorting, dispatch, updateUserSettings, reportId],
    );

    return setEditTableColumnsSorted;
};

export const useFormattedReportDates = () => {
    const metadata = useReportMetadata();
    if (metadata) {
        const { startDate, endDate } = metadata.reportDates;
        return getSameYearFormatRange(
            startDate,
            endDate,
            dateFormats.monthDayNoLeadingZero,
            dateFormats.monthDayYearComma,
        );
    }
    return '';
};

export const useSharedEntity = () => {
    const { selectShareEntity } = useVoidAnalysisSelectors();
    return useSelector(selectShareEntity);
};

export const useCreatedOrders = () => {
    const { selectCreatedOrders } = useVoidAnalysisSelectors();
    return useSelector(selectCreatedOrders);
};

export const useVaEnableLocksPermission = () =>
    useSelectorWithProps({ permission: 'va_enable_locks' }, selectHasUserPermission);

export const useHideVaDateRangePermission = () =>
    useSelectorWithProps({ permission: 'hide_void_analysis_date_range' }, selectHasUserPermission);

export const useHasShareExpirationPermission = () =>
    useSelectorWithProps(
        { permission: 'enable_void_share_expiration_date' },
        selectHasUserPermission,
    );

export const useHasNumberOfVenuesFilterPermission = () =>
    useSelectorWithProps(
        { permission: 'void_enable_number_of_venues_filter' },
        selectHasUserPermission,
    );

export const useIsSingleTenant = () => {
    const metadata = useReportMetadata();

    return useMemo(() => {
        return metadata?.entity.originalEntity.type === 'venue';
    }, [metadata]);
};

export const useShortAddressForSingleTenant = () => {
    const { address } = useHeaderContent();
    const isSingleTenant = useIsSingleTenant();
    return useMemo(() => {
        return address?.short_formatted_address && isSingleTenant
            ? address.short_formatted_address
            : null;
    }, [address?.short_formatted_address, isSingleTenant]);
};
