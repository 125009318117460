import { computed, signal } from '@preact/signals-react';

import type { Dictionary } from '@placer-ui/types';
import type { ResponseStatus } from 'API/common/types';
import type { ExploreSearchResult } from 'features/explore/types/types';
import type { PlaceOverlay } from 'ui-components/google-map-layers/types/google-map-layers-types';
import type { PoiWithDistance } from 'features/mobile-report/types/types';
import { pinColors } from 'features/mobile-report/utils/utils';

export const exploreActiveIdSignal = signal<string | undefined>(undefined);
export const exploreSearchResultSignal = signal<ExploreSearchResult | undefined>(undefined);

export const exploreActivePinSignal = signal<ExploreSearchResult | undefined>(undefined);
export const explorePinErrorsSignal = signal<{ [id: string]: ResponseStatus }>({});

export const exploreOverlayPOIsSignal = signal<Dictionary<PlaceOverlay[]>>({});
export const exploreInitOverlayPOIsSignal = signal<Dictionary<PlaceOverlay>>({});

export const exploreEnclosingComplexesSignal = signal<Dictionary<PlaceOverlay[]>>({});

export const propertiesListTypeSignal = signal<'list' | 'map'>('map');

export const compareListSignal = signal<PoiWithDistance[]>([]);

export const compareListWithColorsSignal = computed(() =>
    compareListSignal.value.map((item, index) => ({
        ...item,
        color: pinColors[index],
    })),
);

export const exploreSearchOpenSignal = signal(false);

export const exploreNearbyNoteSignal = signal(false);

export const analyzeAsBackButtonSignal = signal(false);
