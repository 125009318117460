import React, { PropsWithChildren } from 'react';
import styles from './search-poi-clear-button.module.scss';

import { Button } from 'ui-components/button/button';
import { CloseIcon } from 'components/assets/Icons/CloseIcon';

type SearchPoiClearButtonProps = {
    onClick: () => void;
    showCloseIcon?: boolean;
};

export const SearchPoiClearButton = ({
    children,
    onClick,
    showCloseIcon,
}: PropsWithChildren<SearchPoiClearButtonProps>) => {
    return (
        <Button type={'text'} onClick={onClick} className={styles.button}>
            {showCloseIcon && (
                <span className={styles.icon}>
                    <CloseIcon />
                </span>
            )}
            {children}
        </Button>
    );
};
