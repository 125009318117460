import { BaseSyntheticEvent, ReactNode } from 'react';
import { valueof } from 'types/typescript-utils';
import { FORM_PLACEHOLDERS } from 'features/authentication/utils/constants';

export enum AuthValue {
    Login = '0',
    SignUp = '1',
}

export enum FormErrorMessages {
    EmailRequired = 'Email address is required',
    EmailInvalid = 'Email address is invalid',
    PasswordRequired = 'Password is required',
    FirstNameRequired = 'First name is required',
    LastNameRequired = 'Last name is required',
    FullNameRequired = 'Full name is required',
    PhoneRequired = 'Phone is required',
    CompanyNameRequired = 'Company name is required',
    MinPasswordLength = 'Password cannot be less than 10 characters',
    MinPasswordComplexity = 'Password must contain at least one uppercase letter,' +
        ' one lowercase letter, one number, and a special character.',
    RegularFieldLength = 'Character limit exceeded (max 128)',
    PasswordFieldLength = 'Character limit exceeded (max 50)',
    ConfirmPasswordMatch = 'Passwords do not match',
}
export type DisplayEmailFormText = {
    title: string;
    subTitle: ReactNode;
    emailInputPlaceholders: FormPlaceholders;
    buttonDisplayText: string;
    returnLoginDescription: string;
    returnLoginButton: string;
};

export type FormPlaceholders = valueof<typeof FORM_PLACEHOLDERS>;

export interface LoginValues {
    email?: string;
    password?: string;
}

export interface LoginValuesToValidate {
    email?: boolean;
    password?: boolean;
}

export interface LoginErrors {
    email?: string;
    password?: string;
}

export interface SignupValues {
    firstName?: string;
    lastName?: string;
    companyName?: string;
    email?: string;
    password?: string;
}

export interface SignupValuesToValidate extends LoginValuesToValidate {
    firstName?: boolean;
    lastName?: boolean;
    companyName?: boolean;
}

export interface SignupErrors {
    firstName?: string;
    lastName?: string;
    companyName?: string;
    email?: string;
    password?: string;
}

export interface EmailValues {
    email?: string;
}

export interface EmailValuesToValidate {
    email?: boolean;
}

export interface EmailErrors {
    email?: string;
}

export interface PasswordValues {
    newPassword?: string;
    confirmPassword?: string;
    email?: string;
}

export interface PasswordValuesToValidate {
    newPassword?: boolean;
    confirmPassword?: boolean;
    email?: boolean;
}

export type FormValues = LoginValues | SignupValues | EmailValues | PasswordValues;

export type FormValuesToValidate =
    | LoginValuesToValidate
    | SignupValuesToValidate
    | EmailValuesToValidate
    | PasswordValuesToValidate;

export type FormErrors = LoginErrors | SignupErrors | EmailErrors;

export interface CustomErrorInputProps {
    id: string;
    type?: string;
    value: string;
    name: string;
    onChange: (event: BaseSyntheticEvent) => void;
    onBlur: (valToValidate: FormValuesToValidate) => void;
    placeholder: string;
    errorMessage?: string;
    required?: boolean;
    readOnly?: boolean;
}

export interface UseFormProps {
    callback: () => void;
    validate: (values: LoginValues) => LoginErrors;
}

export interface LoginTabsProps {
    authValue: AuthValue;
    emailConfirmationCallback?: (email: string) => void;
}

export interface LoginCardsProps extends LoginTabsProps {}

export interface LoginProps {
    switchTo: (activeKey: string) => void;
    currentValue: string;
}

export interface SignupProps extends LoginProps {
    signUpCallback?: (email: string) => void;
    venue?: string;
}

export interface SignupEmailConfirmationProps {
    signupEmail: string;
}

export enum SocialConnectAction {
    Login = 'login',
    Signup = 'sign up',
}

export interface SocialConnectProps {
    actionText: SocialConnectAction;
    onSuccessCallback?: (platform: string) => void;
    onFailureCallback?: (error: unknown) => void;
}
export interface WebSightsData {
    WS_companyName?: string;
    WS_employeeCount?: number;
    WS_industries?: string;
}