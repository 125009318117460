import { Feature, LineString } from 'geojson';
import {
    BaseTradeLayerFetchType,
    BaseTradeLayerVisualType,
    NearbyFetchFilter,
} from '@advanced-mapping/pl-mapbox';
import {
    CascadingItem,
    CssColor,
    PaletteOption,
    getPallettesByCategoryAndBucket,
} from '@placer-ui/components';
import { AccessInfo, Dictionary, EntityProfile } from '@placer-ui/types';
// import { PropertyRanksType } from 'types/property-ranks';

import { GeoJSON } from 'geojson';

// *************************
//
//  TYPES FROM SUMMARY
//
// *************************

// This is what we can have in a "Pill"
type PoiTypesForSummary = 'Chains' | 'Properties';

// These are all different types we can have for POIs as standalone entities.
type PoiTypes = PoiTypesForSummary | 'Addresses';

// This is the type above plus the specia keywork "All" which serves as a placeholder to mean "everything we have right
// now plus everything we might include in the future"
type AllPoiTypes = 'All' | PoiTypes;

export type DemographicLayers =
    | 'Median Household Income'
    | 'Population Density'
    | 'Median Age'
    | 'Dominant Ethnicity'
    | 'Predominant Ethnicity'
    | 'Bachelors Degree and Higher'
    | 'Population Growth (2021-2023)'
    | 'Population Growth (2023-2028)'
    | 'Predominant PersonaLive Families'
    | 'Predominant Mosaic Segment'
    | 'Dominant Mosaic Segment';

export type TradeAreas = 'True Trade Area' | 'Walk Time' | 'Drive Time' | 'Ring Radius';

type AllTradeAreas = 'All' | TradeAreas;

export type RankBasedCategories = 'Shopping Centers';

export type AllRankBasedCategories = 'All' | RankBasedCategories;

export type RankBasedBy = 'Chain' | 'Category Group' | 'Category';

export type AllRankBasedBy = 'All' | RankBasedBy;

type TemplateThumbnail = {
    url: string;
};

export type VtvConfig = {};

export type PoiConfig = {
    availableTypes: AllPoiTypes[];
    limit: number;
    editCopy: string;
    selectCopy: string;
};

export type DemographicConfig = {
    configurable: boolean;
    defaultLayers: DemographicLayers[];
    copy: string;
};

export type TradeAreasConfig = {
    supportedTradeAreas: AllTradeAreas;
    defaultTradeAreas: TradeAreas[];
    configurable: boolean;
};

export type RankBasedConfig = {
    title: string;
    copy: string;
    availableCategories: AllRankBasedCategories[];
    rankBasedBy: AllRankBasedBy[];
};

export type WizardConfig = {
    poi: PoiConfig;
    demographics?: DemographicConfig;
    rankBased?: RankBasedConfig;
    tradeAreas?: TradeAreasConfig;
    vtv?: VtvConfig;
};

export type MapTemplate = {
    id: string;
    description: string;
    shortDescription: string;
    title: string;
    createdBy: string;
    image: TemplateThumbnail;
    carouselImages: TemplateThumbnail[];
    useCases: string[];
    config: WizardConfig;
};

export type MapTemplateSummary = Pick<
    MapTemplate,
    'id' | 'description' | 'shortDescription' | 'title' | 'createdBy' | 'image'
> & {
    pills?: PoiTypesForSummary[];
};

export type TemplatePreview = Pick<
    MapTemplate,
    'title' | 'description' | 'carouselImages' | 'useCases'
>;

// *************************
//
//  TYPES FROM STORE
//
// *************************

type Metadata = {
    createdByUserName: string;
    createdByUserId: string;
    createdAt: string;
    updatedAt: string;
    deletedAt?: string;
};

type Organization = {
    accountId: string;
    organization: string;
    orgDisplayName?: string;
};

export enum BasemapType {
    map = 'map',
    hybrid = 'hybrid',
    arcGisImagery = 'arcGisImagery',
    arcGisStreets = 'arcGisStreets',
    arcGisTerrain = 'arcGisTerrain',
}

type PaddingOptions = {
    top: number;
    bottom: number;
    left: number;
    right: number;
};

export type MapViewState = {
    latitude: number;
    longitude: number;
    zoom: number;
    bearing: number;
    pitch: number;
    padding: PaddingOptions;
};

type ControllerConfig = {
    basemapController: boolean;
    fullscreenController: boolean;
    drawTool: boolean;
};

type MapConfig = {
    basemap: BasemapType;
    viewState: MapViewState;
    controllers: ControllerConfig;
};

export type LayerGroupRenderTypeKey =
    | 'poi-group'
    | 'nearby'
    | 'nearmap'
    | 'vtv'
    | 'static-geojson'
    | 'thematic';

export type VehicleTrafficVolumesType = 'vtv';

export type SubLayerRenderTypeKey =
    | 'poi-callout'
    | 'chain-venues'
    | TradeLayerType
    | VehicleTrafficVolumesType;

export type SubLayersConfig = { [subLayerId in string]: BaseLayerConfig<SubLayerRenderTypeKey> };

export type LayerGroupConfig = BaseLayerConfig<LayerGroupRenderTypeKey> & {
    subLayerConfig: SubLayersConfig;
};

export type LayersConfig = {
    [x: string]: LayerGroupConfig;
};

export type MapDocument = {
    id: string;

    title: string;
    description?: string;
    thumbnailImgs: string[];

    mapConfig: MapConfig;
    layerConfig: LayersConfig;
} & Metadata &
    Organization;

export type LayerConfig = MapDocument['layerConfig'];

export type TradeLayerType =
    | 'tta'
    | 'tta_heat_map'
    | 'distance_in_miles'
    | 'drive_time'
    | 'walk_time';

export type TradeAreaConfig = Partial<TrueTradeAreaFetchFilter> &
    Partial<WalkTimeFetchFilter> &
    Partial<DistanceInMilesFetchFilter> &
    Partial<TTAHeatMapFetchFilter> & {
        taType?: TradeLayerType;
        palette?: TrueTradeAreaVisualFilter['palette'];
    };

export const TradeVTVLayerKeyTypeToTradeName: Record<VehicleTrafficVolumesType, string> = {
    vtv: 'Vehicle Traffic Volumes',
};
export const TradeAreaLayerKeyTypeToTradeName: Record<TradeAreaLayerType, string> = {
    tta: 'True Trade Area',
    tta_heat_map: 'True Trade Area Heat Map',
    distance_in_miles: 'Distance in Miles',
    drive_time: 'Drive Time',
    walk_time: 'Walk Time',
};
export const TradeLayerKeyTypeToTradeName: Record<TradeLayerType | 'tta_dot_density', string> = {
    ...TradeAreaLayerKeyTypeToTradeName,
    ...TradeVTVLayerKeyTypeToTradeName,
    tta_dot_density: 'Dot density',
};

export type TemplateConfig = {
    poi?: {
        selectedPOIs?: Place[];
    };
    demographics?: {
        selectedLayers?: DemographicLayers[];
    };
    rankBased?: {
        spatialType?: NearbyFetchFilter['within']['spatialType'];
        spatialDistance?: number;
        limit?: number;
        categories?: CascadingItem[];
        selectedCategories?: string[];
        selectedSubCategories?: string[];
        // FIXFIXFIX REVISAR SI NO EMPIEZA A FALLAR TODO. TIPO COMENTADO ARRIBA DEL TODO
        metricsRankType?: RankScopeType;
    };
    tradeAreas?: {
        [key in string]: TradeAreaConfig;
    };
    vtv?: {
        numRoadLabels?: string;
        isVisible?: boolean;
    };
};

export type ElementsLocationData = {
    geolocation: Geolocation;
    geojson?: GeoJSON;
};

export type CreatePOIProps = Pick<
    Place,
    | 'name'
    | 'type'
    | 'address'
    | 'category'
    | 'sub_category'
    | 'geojson'
    | 'parent_chain_id'
    | 'parent_chain_name'
    | 'profile'
> & {
    tag_id?: string;
    check_duplication?: boolean;
    sharedWith?: string;
    provider?: string;
};

export type LinearGradient =
    `linear-gradient(180deg, #${string} 0%, #${string} 35.94%, #${string} 68.23%, #${string} 100%)`;

export type Filters = { [filterKey in string]: any } | undefined;

export type GradientIndicator = { gradient: 'default' | LinearGradient | string; range?: never };
export type ColorIndicator = { gradient?: never; range: CssColor[] | CssColor };

export type LayerColorIndicatorProps = (GradientIndicator | ColorIndicator) & {
    disabled?: boolean;
    testId?: string;
};

export type BaseLayerConfig<RenderType> = {
    renderType: RenderType;
    name: string | undefined;
    description: string | undefined;
    layerColor?: LayerColorIndicatorProps['range'];
    layerGradient?: LayerColorIndicatorProps['gradient'];
    visible: boolean;
    fetchFilter: Filters;
    visualFilter: Filters;
};

export type CreateCustomPOI = {
    layerGroupId: string;
    data: CreatePOIProps;
    geolocation: { lat: number; lng: number };
};

export type TradeAreaLayer = {
    layerGroupId: string;
    subLayerConfig: BaseLayerConfig<TradeLayerType>;
};

export type TemplateConfigStore = {
    layerConfig: LayerConfig;
    elements?: ElementsLocationData[];
    tradeAreaLayers: TradeAreaLayer[];
    customPOIs?: CreateCustomPOI[];
    editConfig: TemplateConfig;
    templateConfig: TemplateConfig;
    title: string;
};

// *************************
//
//  MISC MAP STUDIO
//
// *************************

export const DEFAULT_COLOR_VTV_PALETTE_COLORS = getPallettesByCategoryAndBucket(
    'traffic-volume',
    6,
);

export type VtvFetchFilter = {
    year: string;
};

export const vehicleTrafficVolumeDefaultFetchFilter: VtvFetchFilter = {
    year: '2022',
};

export const vehicleTrafficVolumeDefaultVisualFilter = {
    additionalStyleFont: 'Open Sans',
    additionalStyleFontWeight: '400',
    additionalStyleSize: '12',
    additionalStyleFontColor: '#FFFFFF',
    additionalStyleFontColorOpacity: 100,
    vtvPalette: DEFAULT_COLOR_VTV_PALETTE_COLORS[0],
    roadLabelType: 'CPD',
    roadLabelCustomText: '',
    vtvOpacity: 100,
    bgFillColorOpacity: 80,
    dropShadowColorOpacity: 80,
    bgFillColor: '#2F3C44',
    dropShadowColor: '#2F3C44',
    dropShadowHardness: '2',
    bgCornerRadius: 12,
    showRoadLabelNames: true,
    roadLabelShowLabelsFor: '3',
    bgStroke: false,
    bgEnabled: true,
    vtvEnabled: true,
    dropShadowEnabled: true,
    roadLabelEnable: true,
    inEditMode: true,
    includeLayer: true,
    legendTitle: 'Cars Per Day',
    items: [],
    roadType: [1, 2, 3, 4, 5],
    userInputRange: [0, 1],
    minTrafficRange: 0,
    maxTrafficRange: 1,
    histogram: [],
    userAddedFeatures: {},
    userRemovedFeatures: {},
    userLockedFeatures: {},
    roadLabelDisplay: {},
    offsetMap: {},
    tempRoadLabelId: '',
};

// *************************
//
//  MISC PLACER
//
// *************************

export interface Geolocation {
    lat: number;
    lng: number;
}

export type CategoryInfo = {
    group: string;
    sub_category: string;
    primary: string;
};

export type ExternalVenueProviders = 'google' | 'mapbox';

export type ExternalVenueType = 'poi' | 'address';

export type ProviderData = {
    provider: ExternalVenueProviders | null;
    entity_type?: ExternalVenueType | null;
    entity_id?: string | null;
};

export interface PlaceAddress {
    address: string;
    city: string;
    country: string;
    country_code: string;
    formatted_address: string | null;
    short_formatted_address: string | null;
    state: string | null;
    state_code: string | null;
    street_name: string | null;
    zip_code: string | null;
}

export type PlaceType =
    | 'venue'
    | 'complex'
    | 'chain'
    | 'billboard'
    | 'zipcode'
    | 'tag'
    | 'industry'
    | 'empty_space'
    | 'region'
    | 'market';

export interface Place {
    id: string;
    type: PlaceType;
    name: string;
    purchased: boolean;
    address: PlaceAddress;
    area_sqft?: number | null;
    category: string;
    sub_category: string;
    geolocation: Geolocation;
    logo_img: string;
    geojson?: GeoJSON;
    parent_chain_name?: string | null;
    parent_chain_id?: string | null;
    number_of_venues: number;
    is_coming_soon?: boolean;
    is_custom_poi?: boolean;
    is_closed?: boolean;
    parent_chain_is_verified?: boolean | null;
    category_info: CategoryInfo;
    provider_data?: ProviderData;
    overriddenFields?: Partial<Place>;
    store_id?: string;
    access?: AccessInfo;
    profile?: EntityProfile;
    verified?: {
        is_verified?: boolean;
        is_flagged?: boolean;
        has_polygon?: boolean;
        flag_keys?: string[];
        area_verified?: boolean;
    };
    estimated_foottraffic?: number;
    visits?: number;
}

export type DemographicsOptions =
    | 'medianHouseholdIncome'
    | 'populationDensity'
    | 'maidenAge' // typo should be medianage
    | 'bachelorsDegreeAndHigher'
    | 'ethnicity' // expend in the future
    | 'populationGrowth20212023'
    | 'populationGrowth20232028';

export type PsychographicsOptions = 'mosaic' | 'personaLive';

export type AreaType = 'state' | 'nationwide' | 'cbsa' | 'dma' | 'custom';

export interface Area {
    code: string;
    name: string;
    type: AreaType;
    state?: string;
    parent_states?: string[];
    _id?: string;
}

export type ChainTypes = 'chain' | 'tag';
export interface Chain extends Place {
    type: ChainTypes;
    name: string;
    category: string;
    sub_category: string;
    region: Pick<Area, 'name' | 'type'>;
    coverage: null;
    area: Area;
    parent_chain_name?: null;
    parent_chain_id?: null;
    number_of_venues: number;
    logo_img: string;
    purchased: boolean;
    enable_limited_report?: boolean;
    address: PlaceAddress;
    id: string;
    count?: Dictionary<number>;
    have_minimum_pois?: boolean;
    region_type?: string;
    region_code?: string;
    nationwide_chain_id?: string;
}

export type TrueTradeAreaVisualizationTypeFilter = 'choropleth' | 'dot_density';

export type TradeAreaLayerType =
    | 'tta'
    | 'tta_heat_map'
    | 'distance_in_miles'
    | 'drive_time'
    | 'walk_time';

export type ChainVenuesType = 'chain-venues';

export type TaTypes =
    | TradeLayerType
    | VehicleTrafficVolumesType
    | TrueTradeAreaVisualizationTypeFilter
    | ChainVenuesType
    | 'thematic';

export type TradeLayerDisplayOptions = {
    tradeType: TradeLayerType;
    isDisabled: boolean;
};

export type RankScopeType =
    | 'chain.country'
    | 'chain.state'
    | 'chain.dma'
    | 'chain.cbsa'
    | 'primary.country'
    | 'primary.state'
    | 'primary.dma'
    | 'primary.cbsa'
    | 'group.country'
    | 'group.state'
    | 'group.dma'
    | 'group.cbsa';

export type ConfigurationCategoriesData = {
    children?: ConfigurationCategoriesData[];
    id: string;
    label: string;
    type: 'sub_category' | 'primary' | 'group';
    path?: string;
    icon_small_path?: string;
};

export type IPosition = {
    x: number;
    y: number;
};

export type SortByKeys = 'foot_traffic' | 'ft_per_sqft';

export type CalloutBoxConfig = {
    showHeader: boolean;

    showLogo: boolean;
    showPropertyName: boolean;
    showPropertyAddress: boolean;
    showMetrics: boolean;

    headerContainerColor: string;
    headerContainerOpacity: number;

    propertyNameFontFamily: string;
    propertyNameFontWeight: number;
    propertyNameFontSize: number;
    propertyNameFontColor: string;
    propertyNameFontOpacity: number;

    propertyAddressFontFamily: string;
    propertyAddressFontWeight: number;
    propertyAddressFontSize: number;
    propertyAddressFontColor: string;
    propertyAddressFontOpacity: number;

    showMetricsVisits: boolean;
    showMetricsVisitsRanking: boolean;
    metricsRankShowAs: RankShowAsType;
    metricsRankType: RankScopeType;
    metricsDisplayAsNA: boolean;
};
export type RankShowAsType = 'percentile' | 'rank';

export type POICalloutFetchFilter = {
    date_preset: string;
    sort_by: SortByKeys;
    includeTenants: boolean;
    nearbyActivityRadius?: number;
};
export type POICalloutVisualFilter = {
    isGrouped: boolean;
    tenantsCalloutBoxSubLayerId?: string;
    metricsInitiallyLoading?: boolean;
    poiCalloutBoxConfig: CalloutBoxConfig;
    offsetMap: {
        [poiId in string]: IPosition;
    };
};

export type LayerCreationProps<
    RenderType extends LayerGroupRenderTypeKey | SubLayerRenderTypeKey,
    FetchFilter,
    VisualFilter,
> = Pick<BaseLayerConfig<RenderType>, 'layerColor'> & {
    layerGroupId: string;
    subLayerId: string;
    visible: boolean;
    fetchFilter: FetchFilter;
    visualFilter: VisualFilter;
};

type Cfg1 = { includeLayer?: boolean; legendTitle?: string };

type Cfg2 = { palette: PaletteOption; opacity: number };
type CommonWithStrokeConfig = Cfg2 & { stroke?: number };

export type BinItem = {
    id?: string;
    color: string;
    value: string;
    ranges?: [number, number]; // [ min , max ]
    visible?: boolean;
    categoricalLookup?: string[] | undefined;
};

export type BinConfig = { items?: BinItem[] };

export type TTALegendConfig = Cfg1 & BinConfig;
export type DotDensityLegendConfig = { dotDensityLegendTitle?: string };
export type HeatMapLegendConfig = Cfg1;
export type MileRingLegendConfig = Cfg1 & BinConfig;
export type DriveTimeLegendConfig = Cfg1 & BinConfig;
export type WalkTimeLegendConfig = Cfg1 & BinConfig;
export type vtvLegendConfig = Cfg1 & BinConfig;

export type WalkTimeStyleConfig = CommonWithStrokeConfig;

export type WalkTimeFetchFilter = BaseTradeLayerFetchType<{
    timeSelected: number[];
}>;

export type WalkTimeVisualFilter = BaseTradeLayerVisualType<
    WalkTimeLegendConfig & WalkTimeStyleConfig
>;

export type WalkTimeLayerProps = LayerCreationProps<
    'walk_time',
    WalkTimeFetchFilter,
    WalkTimeVisualFilter
>;

export type DistanceInMilesFetchFilter = BaseTradeLayerFetchType<{
    ringRadius: number[];
}>;

export type MileRingStyleConfig = CommonWithStrokeConfig & {
    showAs: string;
    includeLabel: boolean;
};

export type DistanceInMilesVisualFilter = BaseTradeLayerVisualType<
    MileRingLegendConfig & MileRingStyleConfig
>;

export type DistanceInMilesLayerProps = LayerCreationProps<
    'distance_in_miles',
    DistanceInMilesFetchFilter,
    DistanceInMilesVisualFilter
>;

export type VisitorsOriginFilter = 'home' | 'work';
export type MetricFilter = 'visits' | 'visitors';

export type TTAHeatMapFetchFilter = BaseTradeLayerFetchType<{
    minVisits: number;
    visitorsOrigin: VisitorsOriginFilter;
    metric: MetricFilter;
}>;

export type HeatMapShowAsOptions = 'gradient' | 'hexagon';

export type HeatMapStyleConfig = Cfg2 & { showAs?: HeatMapShowAsOptions };

export type TTAHeatMapVisualFilter = BaseTradeLayerVisualType<
    HeatMapStyleConfig & HeatMapLegendConfig
>;

export type TTAHeatMapLayerProps = LayerCreationProps<
    'tta_heat_map',
    TTAHeatMapFetchFilter,
    TTAHeatMapVisualFilter
>;

export type BoundaryType = 'polygon' | 'cbgs';

export type ChoroplethFilters = {
    boundaryType: BoundaryType;
    numberOfVisitsSelected: number[];
    polygonMiRadius?: number;
};

export type TTAStyleConfig = CommonWithStrokeConfig;

type SharedVisualFilter = BaseTradeLayerVisualType<{}>;

export type DotDensityStyleConfig = { dotRadius: number; dotOpacity: number };

export type TrueTradeAreaChoroplathVisualFilter = SharedVisualFilter &
    TTAStyleConfig &
    TTALegendConfig;

export type TrueTradeAreaDotDensityVisualFilter = SharedVisualFilter &
    DotDensityStyleConfig &
    DotDensityLegendConfig;

export type TrueTradeAreaVisualFilter = TrueTradeAreaChoroplathVisualFilter &
    TrueTradeAreaDotDensityVisualFilter;

export type DotDensityFilters = {
    minVisits: number;
    dotEqualsNumber?: number;
    visitorsOrigin: 'home' | 'work';
    metric: 'visits' | 'visitors';
};
export type TrueTradeAreaFetchFilter = BaseTradeLayerFetchType<
    DotDensityFilters &
        ChoroplethFilters & {
            visualizationType: TrueTradeAreaVisualizationTypeFilter;
        }
>;

export type TradeLayersFetchFilters =
    | DistanceInMilesFetchFilter
    | DriveTimeFetchFilter
    | TrueTradeAreaFetchFilter
    | TTAHeatMapFetchFilter
    | VtvFetchFilter
    | WalkTimeFetchFilter;

export type DriveTimeFetchFilter = BaseTradeLayerFetchType<{
    timeSelected: number[];
}>;

export type VtvRoadLabelStyleConfig = {
    additionalStyleFont: string;
    additionalStyleFontColor: string;
    additionalStyleFontColorOpacity: number;
    additionalStyleFontWeight: string;
    additionalStyleSize: string;
    bgCornerRadius: number;
    bgEnabled: boolean;
    bgFillColor: string;
    bgFillColorOpacity: number;
    bgStroke: boolean;
    dropShadowColor: string;
    dropShadowColorOpacity: number;
    dropShadowEnabled: boolean;
    dropShadowHardness: string;
    roadLabelType: string;
    roadLabelCustomText: string;
    showRoadLabelNames: boolean;
};

export type vtvStyleConfig = VtvRoadLabelStyleConfig & {
    roadLabelEnable: boolean;
    roadLabelShowLabelsFor: number;
    vtvEnabled: boolean;
    vtvOpacity: number;
    vtvPalette: PaletteOption;
};

export type VtvVisualFilter = BaseTradeLayerVisualType<
    vtvLegendConfig & vtvStyleConfig & VtvVisualFilterRoadInfo & { dataSource?: string }
>;
export type TrafficMetric = 'a-to-b' | 'b-to-a' | 'a+b';
export type RoadLabelDrawType = 'rounded' | 'rectangle';
export type VtvVisualFilterRoadInfo = {
    roadType: number[];
    userInputRange: number[];
    maxTrafficRange: number;
    minTrafficRange: number;
    histogram: number[];

    userAddedFeatures: {
        [key in string]: Feature<LineString>;
    };
    userRemovedFeatures: {
        [key in string]: Feature<LineString>;
    };
    userLockedFeatures: {
        [key in string]: Feature<LineString>;
    };
    roadLabelDisplay: {
        [key in string]: {
            trafficVolumeMetric: TrafficMetric;
            cornerStyle: RoadLabelDrawType;
        };
    };

    offsetMap: {
        [poiId in string]: IPosition;
    };

    tempRoadLabelId?: string;
};

export type TradeLayersVisualFilters =
    | DistanceInMilesVisualFilter
    | DriveTimeVisualFilter
    | TrueTradeAreaVisualFilter
    | TTAHeatMapVisualFilter
    | VtvVisualFilter
    | WalkTimeVisualFilter;

export type DriveTimeVisualFilter = BaseTradeLayerVisualType<
    DriveTimeLegendConfig & DriveTimeStyleConfig
>;
export type DriveTimeStyleConfig = CommonWithStrokeConfig;
