/* eslint-disable max-len */
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import { useVoidAnalysisSelectors } from 'features/void-analysis/store/selectors';
import {
    useGoToVoidAnalysis,
    VOID_ANALYSIS_BASE_PATH,
} from 'router/go-to-routes/go-to-void-analysis';
import { useQuery } from 'hooks/use-query/use-query';
import { API_VERSION } from 'API/void-analysis-api';
import { goTo, goToInANewTab } from 'router/go-to';
import { Separator } from 'features/insights/components/insights-side-panel/components/separator';
import { PuzzleIcon } from 'components/assets/Icons/Icons';
import { GotoIconWrapper } from 'components/link-icon-component/goto-icon-wrapper';
import { SidePanelButton } from 'ui-components/side-panel/components/side-panel-button/side-panel-button';
import { Tab } from 'ui-components/side-panel/components/side-panel-section';
import { TabElement } from 'ui-components/side-panel/components/tab-element/tab-element';
import { VoidAnalysisReportsModal } from 'features/void-analysis/sections/entry-point-redirection/void-analysis-reports-modal/void-analysis-reports-modal';
import { useVoidAnalysisActions } from 'features/void-analysis/store/actions';
import { isLimitedViewSelector } from 'features/insights/store/selectors/insights-selectors';
import { useRedirectToReportsPageByErrorStatus } from 'features/void-analysis/common/hooks/api-error-wrapper';
import { useVaEnableLocksPermission } from 'features/void-analysis/common/hooks/hooks';
import { useSubCategoriesForShoppingCenters } from 'hooks/use-select-sub-categories-from-multiple-categories/use-select-sub-categories-from-multiple-categories';
import { MethodsName } from 'features/void-analysis/sections/empty-space/types/methods-selector-modal-types';
import {
    useIsPoiSupportedForReport,
    useRunVoidReport,
} from 'features/void-analysis/sections/add-new-report/hooks/add-new-report-hooks';
import type { Place, PlaceType, ProviderData } from '@placer-ui/types';
import { DropdownOption } from 'ui-components/dropdown-menu-button/dropdown-menu-button';
import { useGotoRankingPage } from 'features/void-analysis/sections/ranking-page/hooks/use-goto-ranking-page';
import { EntityOption } from 'features/void-analysis/sections/empty-space/types/run-void-analysis-button-types';
import { methodSelectorModalApi } from 'features/void-analysis/sections/empty-space/api/method-selector-modal-api';
import { useAppUiActions } from 'store/app-ui/use-app-ui-actions';
import { UPGRADE_PLAN_SCREEN } from 'extensions/upgrade-your-plan-dialog';
import { useEmptySpacePermissions } from 'features/void-analysis/sections/empty-space/hooks/use-empty-space-permissions';
import {
    adjustmentEmptySpaceData,
    createEmptySpaceEntityFromAddress,
    isPlaceAnEmptySpaceType,
    useGetDefaultMethodFromApi,
    useGetTaInfoValuesFromApi,
} from 'features/void-analysis/sections/empty-space/hooks/use-method-selector-modal-hooks';
import { EMPTY_SPACE_POI_TYPE } from 'features/empty-space/constants/empty-space-constants';
import {
    addPoiIdToReportConfig,
    useSendEntryPointSplunkEvent,
} from 'features/void-analysis/sections/entry-point-redirection/hooks/entry-point-tracking-hooks';
import {
    PartialSplunkDataShape,
    VoidAnalysisOpenReportSplunkData,
} from 'features/void-analysis/sections/entry-point-redirection/types/entry-point-tracking-types';
import { getPlacePoiProvider } from 'utils/poi-provider/poi-provider';
import { useCommonMapTypeId } from 'ui-components/google-map/hooks/get-map-id';
import { ReportsStatusResponse } from 'features/void-analysis/common/types/server-responses';
import { emptySpaceApi } from 'features/empty-space/api/empty-space-api';
import { convertEmptySpaceToPlaceType } from 'utils/convert-empty-space-type/convert-empty-space-type';

export const complexIsNotShoppingCenterText =
    'Currently Void Analysis is only available for shopping centers.';

export const customPoiText = 'Currently Void Analysis does not support custom POIs.';

export const onlyInContinentalUSText =
    'Currently Void Analysis is only available for continental U.S.';

export const onlyForPurchasedPropertiesText =
    'Void Analysis reports in this area are unavailable in your subscription.';

export const useGetAllPois = () => {
    const { allPoisSelector } = useVoidAnalysisSelectors();
    return useSelector(allPoisSelector);
};

const maxPoisToOpenInTabs = 1;

export type IsOnlineReportSupportedType = {
    isCustomPoi?: boolean;
    type: PlaceType;
    subCategory: string;
    stateCode: string | null;
    isPurchased: boolean;
    groupInfo: {
        group: string;
        subCategory: string;
    };
    category: string;
    providerData?: ProviderData;
};

type CreateJobFromPoiType = {
    poiId: string;
    type: PlaceType;
    name?: string;
    taMethod?: MethodsName;
    taEntityId?: string;
    taEntityType?: string;
};

export type CreateJobConfig = {
    openInNewTab: boolean;
    verifyOrderIdExists?: boolean;
};

export type CreateJobAndRedirect = CreateJobFromPoiType & { config: CreateJobConfig };

export const useIsOnlineReportSupported = () => {
    const acceptedSubCategories = useSubCategoriesForShoppingCenters();
    return useCallback(
        ({
            isCustomPoi,
            type,
            subCategory,
            stateCode,
            isPurchased,
        }: IsOnlineReportSupportedType) => {
            return (
                type === 'complex' &&
                acceptedSubCategories.includes(subCategory) &&
                !isCustomPoi &&
                !!stateCode &&
                isPurchased
            );
        },
        [acceptedSubCategories],
    );
};

export const useUnsupportedEntityText = () => {
    const acceptedSubCategories = useSubCategoriesForShoppingCenters();
    return useCallback(
        ({
            isCustomPoi,
            type,
            subCategory,
            stateCode,
            isPurchased,
        }: IsOnlineReportSupportedType) => {
            if (type !== 'complex' || !acceptedSubCategories.includes(subCategory)) {
                return complexIsNotShoppingCenterText;
            }

            if (isCustomPoi) {
                return customPoiText;
            }

            if (!stateCode) {
                return onlyInContinentalUSText;
            }

            if (!isPurchased) {
                return onlyForPurchasedPropertiesText;
            }

            return '';
        },
        [acceptedSubCategories],
    );
};

const useOnlineReportEntities = (supported: boolean = true) => {
    const pois = useGetAllPois();
    const isPoiSupportedForReport = useIsPoiSupportedForReport();

    return useMemo(() => {
        return pois
            .map((poi) => poi.originalShapeInfo)
            .filter((originalShapeInfo) => {
                const { isOnlineReportSupported } = isPoiSupportedForReport(originalShapeInfo);
                return supported ? isOnlineReportSupported : !isOnlineReportSupported;
            });
    }, [isPoiSupportedForReport, pois, supported]);
};

export const useOnEntryPointRedirectionInNewTab = () => {
    const openVoidAnalysisReportFromPropertySection =
        useOpenVoidAnalysisReportFromPropertySection();

    return useCallback(
        (supportedPois: Place[]) => {
            supportedPois.forEach((poi) => {
                openVoidAnalysisReportFromPropertySection(poi);
            });
        },
        [openVoidAnalysisReportFromPropertySection],
    );
};

export const useVoidAnalysisEntryPointButton = () => {
    const isLimited = useSelector(isLimitedViewSelector);
    const pois = useGetAllPois();
    const supportedPois = useOnlineReportEntities();
    const unsupportedPois = useOnlineReportEntities(false);
    const isPoiSupportedForReport = useIsPoiSupportedForReport();
    const openReportInNewTab = useOnEntryPointRedirectionInNewTab();
    const [showPoisModal, setShowPoisModal] = useState<boolean>(false);
    const onPoisModalClose = () => setShowPoisModal(false);
    const enabled = Object.keys(supportedPois).length > 0;
    const tooltipText = unsupportedPois.length
        ? isPoiSupportedForReport(unsupportedPois[0]).tooltipContent
        : undefined;
    const onClick = useCallback(() => {
        return pois.length <= maxPoisToOpenInTabs
            ? openReportInNewTab(supportedPois)
            : setShowPoisModal(true);
    }, [openReportInNewTab, pois.length, supportedPois]);

    return useMemo(() => {
        const tabProps: Tab = {
            disabled: !enabled && !isLimited,
            key: 'void-analysis',
            icon: <PuzzleIcon />,
            title: 'Void Analysis',
            tooltip: enabled ? undefined : tooltipText,
            isLocked: isLimited,
        };

        return (
            <div key={'void-analysis'}>
                <VoidAnalysisReportsModal
                    visible={showPoisModal}
                    pois={pois}
                    onClose={onPoisModalClose}
                />
                <Separator />
                <TabElement tab={tabProps} onItemClick={onClick}>
                    <SidePanelButton tab={tabProps}>
                        <GotoIconWrapper />
                    </SidePanelButton>
                </TabElement>
            </div>
        );
    }, [enabled, onClick, pois, showPoisModal, tooltipText, isLimited]);
};

export const useGetCreateJobUrl = () => {
    return useCallback(
        ({
            type,
            poiId,
            name,
            taMethod,
            taEntityId,
            taEntityType,
            config: { verifyOrderIdExists },
        }: CreateJobAndRedirect) => {
            const url = queryString.stringify({
                poiId,
                type,
                ...(taMethod && { ta_method: taMethod }),
                ...(taEntityId && { ta_entity_id: taEntityId }),
                ...(taEntityType && { ta_entity_type: taEntityType }),
                ...(name && { name: name }),
                ...(verifyOrderIdExists && { verify_order_id: true }),
            });
            return `getId/?${url}`;
        },
        [],
    );
};

export const useCreateJobUrlAndRedirect = () => {
    const getCreateJobLink = useGetCreateJobUrl();
    return useCallback(
        (jobData: CreateJobAndRedirect) => {
            const path = getCreateJobLink(jobData);
            jobData.config.openInNewTab
                ? goToInANewTab(path, VOID_ANALYSIS_BASE_PATH)
                : goTo(path, VOID_ANALYSIS_BASE_PATH);
        },
        [getCreateJobLink],
    );
};

export const useGetReportOrderId = () => {
    const goToRankingPage = useGotoRankingPage();
    const goToVoidAnalysis = useGoToVoidAnalysis();

    return useCallback(
        async (entity: EntityOption): Promise<{ redirected: boolean }> => {
            try {
                const { id, report_status: status } =
                    (await methodSelectorModalApi.getReportOrderId(
                        entity,
                    )) as ReportsStatusResponse;

                let reportId = id;
                let reportStatus = status;

                if (reportId !== null && reportStatus !== 'FAILED') {
                    //report for this entity already exists, redirect
                    if (reportStatus === 'EXPIRED') {
                        // Navigate to RebuildPage
                        goToVoidAnalysis(`rebuild/${reportId}`);
                    } else {
                        goToRankingPage(reportId);
                    }
                    return Promise.resolve({ redirected: true });
                }
                return Promise.resolve({ redirected: false });
            } catch (error) {
                console.log(error);
                return Promise.reject(error);
            }
        },
        [goToRankingPage, goToVoidAnalysis],
    );
};

export const useCreateIdAndRedirectToRankingsPage = () => {
    const poiId = useQuery('poiId')!;
    const type = useQuery('type') as PlaceType;
    const name = useQuery('name');
    const ta_method = useQuery('ta_method');
    const ta_entity_id = useQuery('ta_entity_id');
    const ta_entity_type = useQuery('ta_entity_type');
    const verifyOrderId = useQuery('verify_order_id');
    const address = useQuery('address');
    const lat = useQuery('lat');
    const lng = useQuery('lng');

    const shareId = useQuery('share_id');
    const { hasEmptySpacePermission } = useEmptySpacePermissions();
    const goToVoidAnalysis = useGoToVoidAnalysis();
    const { setReportId, setCreatedOrder } = useVoidAnalysisActions();
    const redirectToReportsPageByErrorStatus = useRedirectToReportsPageByErrorStatus();
    const getReportOrderId = useGetReportOrderId();
    const getDefaultMethodFromApi = useGetDefaultMethodFromApi();
    const { setGeneralError } = useVoidAnalysisActions();
    const getTaMethodValuesFromApi = useGetTaInfoValuesFromApi();
    const { createRankingReportWithPolling } = useVoidAnalysisActions();

    return useCallback(async () => {
        let id = poiId;
        let poiName = name;
        let selectedMethodName = ta_method || undefined;
        let nearbyEntityId = ta_entity_id || undefined;
        let nearbyEntityType = ta_entity_type || undefined;
        try {
            if (!ta_method && type === EMPTY_SPACE_POI_TYPE && lat && lng) {
                const { entity_id, name } = await createEmptySpaceEntityFromAddress(
                    adjustmentEmptySpaceData({
                        address: address || undefined,
                        lat: Number(lat),
                        lng: Number(lng),
                        name: poiName ?? undefined,
                    }),
                );
                id = entity_id;
                poiName = name;
            }
            if (verifyOrderId) {
                //automatic redirection if VA report exists for this entity
                const { redirected } = await getReportOrderId({
                    entity_id: id,
                    entity_type: type,
                    name: poiName ?? '',
                });
                if (redirected) return;
            }
            if (hasEmptySpacePermission && !ta_method && !shareId) {
                //automatically add selectedMethodName
                const { methodName, nearbyOptionId, nearbyOptionType } =
                    await getDefaultMethodFromApi({
                        id,
                        type,
                        name: poiName ?? undefined,
                    });
                selectedMethodName = methodName;
                nearbyEntityId = nearbyOptionId;
                nearbyEntityType = nearbyOptionType;
            }
        } catch (error) {
            //ignore error for this request
            console.log(error);
        }
        try {
            //set created order ID to prevent multiple creation of same POI;
            setCreatedOrder({
                poiId: id,
                type,
            });
            if (shareId) {
                const { ta_method, ta_entity_id, ta_entity_type } = await getTaMethodValuesFromApi(
                    shareId,
                    poiId,
                );
                if (ta_method !== '') {
                    selectedMethodName = ta_method;
                    nearbyEntityId = ta_entity_id;
                    nearbyEntityType = ta_entity_type;
                }
            }

            let taEntityId = nearbyEntityId;
            let taEntityType = nearbyEntityType;

            // if selected method is not nearby ta_entity is the same as the original entity
            if (selectedMethodName !== 'nearby_entity_ta') {
                taEntityId = `${id}`;
                taEntityType = `${type}`;
            }

            const createReportProps = {
                entity: {
                    id,
                    type,
                },
                name: name || '',
                ta_method: selectedMethodName,
                ta_entity: {
                    id: taEntityId,
                    type: taEntityType,
                },
            };
            const createReportResponse = await createRankingReportWithPolling(createReportProps);
            let rankingReportId = createReportResponse?.id;

            if (rankingReportId) {
                setReportId(rankingReportId);
                goToVoidAnalysis(`${API_VERSION}/${rankingReportId}/ranking`);
            }

            return Promise.reject();
        } catch (e) {
            try {
                await redirectToReportsPageByErrorStatus(e as any);
            } catch (e) {
                setGeneralError({
                    isError: true,
                });
            }
        }
    }, [
        poiId,
        name,
        ta_method,
        ta_entity_id,
        ta_entity_type,
        type,
        lat,
        lng,
        verifyOrderId,
        hasEmptySpacePermission,
        shareId,
        address,
        getReportOrderId,
        getDefaultMethodFromApi,
        setCreatedOrder,
        getTaMethodValuesFromApi,
        setReportId,
        goToVoidAnalysis,
        redirectToReportsPageByErrorStatus,
        createRankingReportWithPolling,
        setGeneralError,
    ]);
};

export const useOpenVoidAnalysisReportFromPropertySection = () => {
    const sendEntryPointSplunkEvent = useSendEntryPointSplunkEvent();
    const openVoidAnalysisReport = useOpenVoidAnalysisReport();

    return useCallback(
        (poi: Place) => {
            sendEntryPointSplunkEvent({
                component: 'Side-Bar',
                view_name: 'Property Side Bar',
                report_config: {
                    poi_id: poi.id,
                    type: poi.type,
                },
            });
            openVoidAnalysisReport(poi);
        },
        [openVoidAnalysisReport, sendEntryPointSplunkEvent],
    );
};

export const useOpenVoidAnalysisReport = () => {
    const runVoidReport = useRunVoidReport();

    return useCallback(
        (
            { type, id, name, address, geolocation, provider_data }: Place,
            openInNewTab?: boolean,
        ) => {
            const isEmptySpacePoi = isPlaceAnEmptySpaceType({ provider_data });
            runVoidReport(
                {
                    entity_id: id,
                    // assign type as empty space for internal use
                    entity_type: isEmptySpacePoi ? EMPTY_SPACE_POI_TYPE : type,
                    name,
                    address: address.formatted_address || undefined,
                    geolocation,
                },
                {
                    openInNewTab: openInNewTab ?? true,
                },
            );
        },
        [runVoidReport],
    );
};

type OpenVoidDropdownOption = {
    poi: Place;
    splunkData?: VoidAnalysisOpenReportSplunkData;
    openInNewTab?: boolean;
    isEmptySpace?: boolean;
};

export const useOpenVoidAnalysisAsDropdownOption = () => {
    const isPoiSupportedForReport = useIsPoiSupportedForReport();
    const enableLocksInVoidAnalysis = useVaEnableLocksPermission();
    const { openUpgradePlanPopup } = useAppUiActions();
    const openVoidAnalysisReport = useOpenVoidAnalysisReport();
    const sendEntryPointSplunkEvent = useSendEntryPointSplunkEvent();

    const onLockedItemClick = useCallback(() => {
        openUpgradePlanPopup(UPGRADE_PLAN_SCREEN.default);
    }, [openUpgradePlanPopup]);
    const mapTypeId = useCommonMapTypeId(true)();

    return useCallback(
        ({
            poi,
            splunkData,
            openInNewTab,
            isEmptySpace,
        }: OpenVoidDropdownOption): DropdownOption => {
            const { isOnlineReportSupported, tooltipContent } = isPoiSupportedForReport(poi);
            const isLocked = !poi.purchased && enableLocksInVoidAnalysis;
            return {
                key: 'voidAnalysis',
                icon: <PuzzleIcon />,
                label: 'Void Analysis',
                visible: true,
                onClick: async () => {
                    if (!isLocked) {
                        let poiToOpen = poi;
                        if (isEmptySpace) {
                            const emptySpaceRes = await emptySpaceApi.createNewEntityByEmptySpace({
                                lat: poi.geolocation!.lat,
                                lng: poi.geolocation!.lng,
                                entity_type: EMPTY_SPACE_POI_TYPE,
                                name: `${poi.geolocation!.lat.toFixed(
                                    5,
                                )}, ${poi.geolocation!.lng.toFixed(5)}`,
                                address: `${poi.geolocation!.lat.toFixed(
                                    5,
                                )}, ${poi.geolocation!.lng.toFixed(5)}`,
                            });
                            poiToOpen = convertEmptySpaceToPlaceType(emptySpaceRes.data);
                        }
                        if (splunkData) {
                            const splunkToSend = addPoiIdToReportConfig(splunkData, poiToOpen.id);
                            sendEntryPointSplunkEvent({
                                ...splunkToSend,
                                selectedSource: getPlacePoiProvider(poiToOpen),
                                selectedCategory: poiToOpen.category,
                                mapProvider: 'mapbox',
                                mapViewType: mapTypeId,
                            } as PartialSplunkDataShape);
                        }
                        (window as any).telemetryReport = true;
                        openVoidAnalysisReport(poiToOpen, openInNewTab);
                    } else {
                        onLockedItemClick();
                    }
                },
                disabled: !isOnlineReportSupported && !isLocked,
                tooltip: tooltipContent,
                isLocked,
            };
        },
        [
            enableLocksInVoidAnalysis,
            isPoiSupportedForReport,
            onLockedItemClick,
            openVoidAnalysisReport,
            sendEntryPointSplunkEvent,
            mapTypeId,
        ],
    );
};
