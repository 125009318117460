import { createAsyncThunk } from '@reduxjs/toolkit';

import { ChainsReportState } from 'features/chains/store/chains-store';
import {
    openChainsSearchSection,
    selectChainsSearchIsOpen,
} from 'features/chains/store/chains-report-search-store';

export const focusSearchAutoComplete = createAsyncThunk<
    void,
    void,
    { state: ChainsReportState }
>('chains/focus-search-auto-complete', async (_, { dispatch, getState }) => {
    const state = getState();
    const searchIsOpened = selectChainsSearchIsOpen(state);

    if (!searchIsOpened) {
        dispatch(openChainsSearchSection());
    }
});
