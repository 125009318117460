import { useCallback } from 'react';
import { captureException } from '@sentry/browser';
import { useAppDispatch } from 'store/app-store-hooks';

import { AppDispatch, AppState } from 'store/store';
import { API } from 'API';
import type { UserCustomSettings } from 'core/entities/user/user-custom-settings';
import { setUserCustomSettingsActionCreator } from 'store/auth/actions/auth-action-creators';
import { userCustomSettingsSelector } from 'store/selectors/app-selectors';

/**
 * Use this action to update user's custom settings both remotely (via user-api)
 * and locally (Redux store) - to avoid the need to re-fetch the user settings.
 */
export const setUserCustomSettings =
    (newCustomSettings: Partial<UserCustomSettings>) =>
    async (dispatch: AppDispatch, getState: () => AppState) => {
        const userCustomSettings = userCustomSettingsSelector(getState());

        API.user
            .setUserCustomSettings(newCustomSettings)
            .then(() => {
                dispatch(
                    setUserCustomSettingsActionCreator({
                        ...(userCustomSettings || {}),
                        ...newCustomSettings,
                    }),
                );
            })
            .catch(captureException);
    };

export const useSetUserCustomSettings = () => {
    const dispatch = useAppDispatch();
    return useCallback(
        (newCustomSettings: Partial<UserCustomSettings>) =>
            dispatch(setUserCustomSettings(newCustomSettings)),
        [dispatch],
    );
};
