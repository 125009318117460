import { createAsyncThunk } from '@reduxjs/toolkit';

import {
    setChainsSearchSelectedCategory,
    setChainsSearchSelectedRegion,
} from '../chains-report-search-action-creator';
import { getFilteredChainResults } from 'features/industry/store/chains-report-search-store';

export const resetSearchFilters = createAsyncThunk(
    'industry/reset-search-filters',
    async (_: void, { dispatch }) => {
        dispatch(setChainsSearchSelectedCategory(''));
        dispatch(setChainsSearchSelectedRegion(''));
        dispatch(getFilteredChainResults({ chainsEntityModels: [] }));
    },
);
