import {
    RecentEntitiesModel,
    RecentEntitiesModelAutocompleteEnriched,
} from 'core/services/recent-entities-service/recent-entities-model';
import { useSelector } from 'react-redux';
import { ReportTypes } from 'core/constants/report-type';
import type { PlaceCollection } from '@placer-ui/types';
import { selectTopSearchResultsByTerm } from 'store/header/search/selectors/select-top-search-results-by-term';
import { selectTopSearchTerm } from 'store/header/search/selectors/top-search-selectors-factory';

import { useSelectorWithProps } from 'utils/structured-selector/structured-selector';
import { mapAutoCompleteSuggestion } from 'utils/map-auto-complete-suggestion/map-auto-complete-suggestion';
import { useExplorePermissions } from 'features/explore/hooks/use-explore-permissions';
import { useHasRestrictPoisIfPartOfCriticalPermission } from 'hooks/use-restricted-poi/use-restricted-poi';
import { useIsFreemiumUser } from 'hooks/use-freemium-user/use-freemium-user';

type SearchAutocompleteSuggestions = {
    reportType: ReportTypes | PlaceCollection[];
    getIsDisabledOption?: (data: RecentEntitiesModel) => boolean;
    searchId: string;
    flaggedEntityTooltipText?: string;
};
export const useSearchAutocompleteSuggestions = ({
    reportType,
    getIsDisabledOption,
    flaggedEntityTooltipText,
    searchId,
}: SearchAutocompleteSuggestions) => {
    const term = useSelector(selectTopSearchTerm);
    const entities = useSelectorWithProps({ reportType }, selectTopSearchResultsByTerm);
    const {
        hasUnlockNearbyActivity,
        hasNearbyPropertyVenueIndicatorFF,
        hasStoreIdBadgeAutocompleteSearchPermission,
    } = useExplorePermissions();
    const isFreemiumUser = useIsFreemiumUser();

    const hasRestrictPoisIfPartOfCriticalPermission =
        useHasRestrictPoisIfPartOfCriticalPermission();

    const suggestionsMapper = (data: RecentEntitiesModel) => {
        const isEntityAvailable = isFreemiumUser && data.profile === 'available';
        const enrichedData: RecentEntitiesModelAutocompleteEnriched = {
            ...data,
            forceLockIconState: isEntityAvailable ? 'hide' : undefined,
        };

        return mapAutoCompleteSuggestion({
            data: enrichedData,
            term,
            getIsDisabledOption,
            flaggedEntityTooltipText,
            hasUnlockNearbyActivity,
            hasNearbyPropertyVenueIndicatorFF,
            hasRestrictPermission: hasRestrictPoisIfPartOfCriticalPermission,
            hasStoreIdBadgeAutocompleteSearchPermission,
        });
    };

    return entities.map(suggestionsMapper);
};
