import React, { createContext, Dispatch, PropsWithChildren, useReducer } from 'react';
import { initialState, PrintActionTypes, reducer } from './print-context-reducer';
import { PrintContextState } from 'features/print-menu/context/types';

export const PrintContext = createContext(initialState);
export const PrintActionContext = createContext<Dispatch<PrintActionTypes>>(() => {});

type PrintContextWrapperProps = {
    initialStateOverrides?: Partial<PrintContextState>;
};

export const PrintContextWrapper = ({
    children,
    initialStateOverrides,
}: PropsWithChildren<PrintContextWrapperProps>) => {
    const [state, dispatch] = useReducer(reducer, {
        ...initialState,
        ...initialStateOverrides,
    });

    return (
        <PrintContext.Provider value={state}>
            <PrintActionContext.Provider value={dispatch}>{children}</PrintActionContext.Provider>
        </PrintContext.Provider>
    );
};
