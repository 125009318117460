import React, { useMemo } from 'react';
import classNames from 'classnames';
import styles from './marker-tooltip.module.scss';
import { CustomInfoWindow } from 'ui-components/google-map/components/custom-info-window/custom-info-window';
import { Checkbox } from 'ui-components/checkbox/checkbox';
import { PoiCell } from 'ui-components/table/components/cells/poi-cell/poi-cell';
import { NumberCell } from 'ui-components/table/components/cells/number-cell/number-cell';
import { ZoomInIcon } from 'components/assets/Icons/Icons';
import { Button } from 'ui-components/button/button';
import {
    getReportEntityClosingDate,
    getReportEntityFlag,
} from 'core/services/report-entities-service/report-entities-service';
import { useIsLockedPoi } from 'hooks/use-locked-poi-hooks/use-locked-poi-hooks';
import { LockedStatusIndicator } from 'ui-components/locked-status-indicator/locked-status-indicator';
import { VenueStatusIndicatorV2 } from 'ui-components/venue-status-indicator/v2/venue-status-indicator-v2';
import { useGoogleMap } from '@react-google-maps/api';
import type { MapRef } from 'react-map-gl';
import { useMap } from 'react-map-gl';
import { zoomToLocation } from 'ui-components/google-map/utils';
import { useGoogleMapLayersContextState } from 'ui-components/google-map-layers/context/google-map-layers-context';
import { getLabelByMetric } from 'ui-components/google-map/hooks/get-label-by-metric';
import { PoiEntity } from 'ui-components/google-map-layers/types/google-map-layers-types';
import type { Geolocation } from '@placer-ui/types';
import { useIsRestrictedPoi } from 'hooks/use-restricted-poi/use-restricted-poi';
import { useIsIdSelected } from 'ui-components/google-map-layers/hooks/use-map-layers';
import { ZOOM_TO_MARKER_TARGET } from 'shared/constants/google-map';
import { PrivacyDisclaimer } from 'ui-components/disclamiers/privacy-disclaimer';
import { ZOOM_TO_MARKER_TARGET_MAPBOX } from 'ui-components/mapbox-map/constants';
import { NewCpoiCallout } from 'ui-components/NewCpoiCallout/NewCpoiCallout';

export type MarkerTooltipProps = {
    markerTooltipActionButton: (entity: PoiEntity) => React.ReactNode;
    onZoomToMarker?: (location: Geolocation, zoom: number) => void;
    showStoreId?: boolean;
};

export const MarkerTooltipComponent = ({
    markerTooltipActionButton: actionButton,
    onZoomToMarker,
    showStoreId,
}: MarkerTooltipProps) => {
    const {
        activeId,
        allPois,
        markerTooltipCheckboxConfig,
        getEnclosingComplexOptions,
        mapboxBeta,
    } = useGoogleMapLayersContextState();
    const isLockedPoiCallback = useIsLockedPoi();
    const isRestrictedPoiCallback = useIsRestrictedPoi();
    const isOverlayIdSelected = useIsIdSelected();
    const commonUsemap = mapboxBeta ? useMap : useGoogleMap;
    const map = commonUsemap();

    const activeMarker = useMemo(
        () => allPois.find(({ info }) => info.id === activeId),
        [activeId, allPois],
    );

    if (!activeMarker || !map) return null;

    const zoomToMarker = () => {
        if (onZoomToMarker) {
            //@ts-expect-error PLAC-47814
            onZoomToMarker(activeMarker.info.geolocation, ZOOM_TO_MARKER_TARGET);
        } else {
            if (mapboxBeta) {
                const mapboxMap = map as { current: MapRef };
                //@ts-expect-error PLAC-47814
                const { lat, lng } = activeMarker.info.geolocation;
                mapboxMap.current.flyTo({
                    center: [lng, lat],
                    zoom: ZOOM_TO_MARKER_TARGET_MAPBOX + 2,
                });
            } else {
                zoomToLocation(
                    map as google.maps.Map,
                    //@ts-expect-error PLAC-47814
                    activeMarker.info.geolocation,
                    ZOOM_TO_MARKER_TARGET,
                );
            }
        }
    };

    const { id, name } = activeMarker.info;
    const { estimated_foottraffic } = activeMarker.overview || {};
    const { enableSelectionCheckbox, tooltipCheckbox, showCheckbox, onPoiToggleSelection } =
        markerTooltipCheckboxConfig;
    const flag = getReportEntityFlag(activeMarker.info);
    const isRestricted = isRestrictedPoiCallback(activeMarker.info);
    const closingDate = getReportEntityClosingDate(activeMarker.info);
    const validFlag = flag;
    const showMetrics = !validFlag && !closingDate && !isRestricted;
    const isPrivacy = flag === 'privacy_concerns';
    const isPoiAddress = activeMarker.info?.provider_data?.entity_type === 'address';
    const checkboxTooltip = tooltipCheckbox
        ? tooltipCheckbox(activeMarker.info)
        : {
              text: '',
              disabled: false,
              isLoading: false,
          };
    const shouldEnableMultiselect = enableSelectionCheckbox
        ? enableSelectionCheckbox({
              withHover: false,
              poi: activeMarker.info,
          })
        : false;
    const isIdSelected = isOverlayIdSelected(activeMarker.info);
    const isLockedPoi = isLockedPoiCallback(activeMarker.info);
    const metricLabel = getLabelByMetric(activeMarker);
    const storeId = showStoreId ? activeMarker.info.store_id : '';
    const markerInfo = activeMarker.info;
    const isMarkerNew = markerInfo.is_new_poi;
    const isMarkerCustom = markerInfo.is_custom_poi;

    let detailsComponent;
    if (isPoiAddress) {
        // If address is locked, show privacy disclaimer. Safety measure in case it will not be
        // available when not locked.
        detailsComponent = isLockedPoi ? <PrivacyDisclaimer /> : null;
    } else if (isLockedPoi) {
        detailsComponent = <LockedStatusIndicator textClassName={styles.statusIndicatorText} />;
    } else if (showMetrics) {
        detailsComponent = (
            <>
                <span>{metricLabel}</span>
                <NumberCell
                    value={estimated_foottraffic}
                    formatterFraction={1}
                    valueClassName={styles.metricValue}
                />
            </>
        );
    } else if (validFlag || closingDate || isRestricted) {
        detailsComponent = (
            <VenueStatusIndicatorV2
                flag={flag}
                name={name}
                hasEnclosingComplexes={
                    getEnclosingComplexOptions ? !!getEnclosingComplexOptions(id) : false
                }
                closingDate={closingDate}
                textClassName={classNames(styles.statusIndicatorText, {
                    [styles.bold]: (flag || closingDate) && !isRestricted,
                })}
                shortFlag
                isRestricted={isRestricted}
            />
        );
    }
    if (isMarkerNew) {
        detailsComponent = <NewCpoiCallout state="created" isCustom={isMarkerCustom} />;
    }
    if (!isMarkerNew && markerInfo.is_data_outdated) {
        detailsComponent = <NewCpoiCallout state="updated" isCustom={isMarkerCustom} />;
    }

    return (
        <div className={styles.tooltipContent}>
            <div className={classNames(styles.tooltipHeader, { [styles.noMargin]: isPrivacy })}>
                <div className={styles.title}>
                    <PoiCell
                        poi={markerInfo}
                        visibleInfo={{
                            statusIndicator: false,
                            category: false,
                        }}
                        storeId={storeId}
                        storeIdClassName={styles.storeId}
                    />
                </div>
                {!isPrivacy && !isLockedPoi && showCheckbox && !isRestricted && (
                    <Checkbox
                        name=""
                        disabled={!shouldEnableMultiselect}
                        value={id}
                        selected={isIdSelected}
                        onChange={() =>
                            onPoiToggleSelection &&
                            onPoiToggleSelection({
                                poi: markerInfo,
                                uncheckPoiOverlay: true,
                            })
                        }
                        tooltipText={checkboxTooltip.text}
                        placement={'topRight'}
                        className={styles.checkbox}
                    />
                )}
            </div>
            {!isPrivacy && (
                <>
                    {detailsComponent && <div className={styles.details}>{detailsComponent}</div>}
                    {!isRestricted && (
                        <div className={styles.tooltipActions}>
                            <Button
                                className={styles.zoomBtn}
                                icon={<ZoomInIcon />}
                                onClick={zoomToMarker}
                            />
                            <>{actionButton(activeMarker)}</>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export const MarkerTooltip = ({
    markerTooltipActionButton: actionButton,
    onZoomToMarker,
    showStoreId,
}: MarkerTooltipProps) => {
    const { activeId, allPois, mapboxBeta } = useGoogleMapLayersContextState();

    const commonUsemap = mapboxBeta ? useMap : useGoogleMap;
    const map = commonUsemap();

    const activeMarker = useMemo(
        () => allPois.find(({ info }) => info.id === activeId),
        [activeId, allPois],
    );

    if (!activeMarker || !map) return null;

    const position = activeMarker.info.geolocation;

    return (
        <CustomInfoWindow
            key={activeId}
            //@ts-expect-error PLAC-47814
            position={position}
            offset={[0, -33]}
            className={styles.customTooltip}
            mapboxBeta={mapboxBeta}
        >
            <MarkerTooltipComponent
                markerTooltipActionButton={actionButton}
                onZoomToMarker={onZoomToMarker}
                showStoreId={showStoreId}
            />
        </CustomInfoWindow>
    );
};
