import type { Billboard, Chain, PlaceCollection, Venue } from '@placer-ui/types';

type TopSearchState = {
    entities: (Billboard | Chain | Venue)[] | PlaceCollection[];
    locations: (Billboard | Chain | Venue)[];
    term: string;
    isLoading: boolean;
    noResults: boolean;
};

export const createTopSearchState = (): TopSearchState => ({
    entities: [],
    locations: [],
    term: '',
    isLoading: true,
    noResults: false,
});
