import React from 'react';
import { VenueFlags } from 'core/entities';
import { Tooltip } from 'ui-components/tooltip/tooltip';
import { AlertColoredIcon } from 'components/assets/Icons/Icons';
import { dateFormats, getSimpleFormatDate } from 'utils/date/date';
import styles from './venue-status-indicator.module.scss';
import classNames from 'classnames';
import { FlaggedVenueIndicatorProps } from 'ui-components/venue-status-indicator/venue-status-indicator';

const fallbackText = 'Reports for limited data venues are not supported';
export const venueFlagInfo: Record<VenueFlags, string> = {
    flagged_indoor_mall:
        'The venue is located entirely inside a larger enclosed structure, and therefore is not available',
    flagged_multistory_building:
        'This venue is located in a multistory building, with other tenants above/below, ' +
        'and therefore is not available',
    ft_abnormality: 'Reports for venues with visits abnormalities are not supported',
    flagged_dense_area:
        'The location is in a highly dense area with a large number of venues in close proximity, and therefore ' +
        'is not available',
    privacy_concerns: 'Reports for sensitive venues are not supported. Contact support for access',
    unknown_flag: fallbackText,
    nearby_activity: fallbackText,
    not_available: fallbackText,
};

export const VenueStatusIndicatorV1 = ({
    flag,
    closingDate,
    custom,
}: FlaggedVenueIndicatorProps) => {
    if (!flag && !closingDate && !custom) {
        return null;
    }

    const icon = (
        <AlertColoredIcon
            data-testid="venue-status-indicator"
            className={classNames({
                [styles.orange]: flag,
                [styles.red]: !flag && closingDate,
                [styles.gray]: !flag && !closingDate && custom,
            })}
        />
    );

    let tooltipText;
    if (flag) {
        tooltipText = venueFlagInfo[flag];
    } else if (closingDate) {
        tooltipText = `Closed - Data available until ${getSimpleFormatDate(
            closingDate!,
            dateFormats.shortMonthYearWithNumericOrdinalContractionDayComma,
        )}`;
    }

    if (custom) {
        if (tooltipText) {
            tooltipText = `${tooltipText}. ${custom}`;
        } else {
            tooltipText = custom;
        }
    }

    return (
        <Tooltip small title={tooltipText} placement="top">
            {icon}
        </Tooltip>
    );
};
