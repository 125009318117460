/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

export type CheckMobileIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type CheckMobileIconProps = SVGProps<SVGSVGElement> & CheckMobileIconUniqueProps;

export const CheckMobileIcon = function CheckMobileIcon({
    width = 8,
    height = 9,
    ...props
}: CheckMobileIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 8 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.42644 1.81005C7.65777 2.04731 7.65297 2.42718 7.4157 2.65851L3.14165 6.8257L0.775714 4.45979C0.541398 4.22548 0.541396 3.84558 0.775709 3.61126C1.01002 3.37695 1.38992 3.37694 1.62424 3.61126L3.15232 5.13932L6.57798 1.79931C6.81525 1.56798 7.19512 1.57279 7.42644 1.81005Z"
                fill="currentColor"
            />
        </svg>
    );
};
CheckMobileIcon.__tags = [] as string[];
