import type { MosaicAttribute } from 'store/configuration/experian-mosaic/types';

// supported attributes
export const mosaicAttributes: Array<{
    attrName: MosaicAttribute;
    idRegex: RegExp;
}> = [
    {
        attrName: 'Households',
        idRegex: /^hh_|_hh_|_hh$/i,
    },
    {
        attrName: 'Population',
        idRegex: /^pop_|_pop_|_pop$/i,
    },
];
