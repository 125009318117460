import React, { useState } from 'react';
import { Popover, Button } from 'antd';
import classNames from 'classnames';

import {
    CreatePoi,
    ChainIcon,
    PlusIconCreate,
    PlusIconCreateActive,
} from 'components/assets/Icons/Icons';
import { useSplunkCallback } from 'hooks/use-splunk-callback/use-splunk-callback';
import { useSelectorWithProps } from 'utils/structured-selector/structured-selector';
import { selectHasUserPermission } from 'store/selectors/app-selectors';
import { CREATE_POI_PATH } from 'features/create-poi/consts';

import styles from './header-button-group.module.scss';
import { myZoneBasename } from 'features/my-zone/constants';

const TOOLTIP_ALIGN = {
    offset: [-73, -3],
};

type DropdownLinksProps = {
    onSetIsMenuVisible: (url: string, type: string) => void;
};

const DropdownLinks = ({ onSetIsMenuVisible }: DropdownLinksProps) => {
    const hasCreateCustomPoiPermission = useSelectorWithProps(
        { permission: 'enable_custom_poi' },
        selectHasUserPermission,
    );

    return (
        <div className={styles.innerDropdownContent}>
            {hasCreateCustomPoiPermission && (
                <button
                    onClick={() => onSetIsMenuVisible(CREATE_POI_PATH, 'poi')}
                >
                    <CreatePoi />
                    <span>Create POI</span>
                </button>
            )}

            <button onClick={() => onSetIsMenuVisible(`${myZoneBasename}/tag/new`, 'tag')}>
                <ChainIcon className={styles.chainIconStylesCreateTag} />
                <span>Create Tag</span>
            </button>
        </div>
    );
};

export const HeaderButtonGroupComponent = () => {
    const [isMenuVisible, onSetIsMenuVisible] = useState(false);
    const sendEvent = useSplunkCallback();

    const btnClassNames = classNames(styles.createButtonGroup, {
        [styles.addTriggerActive]: isMenuVisible,
    });

    const toggleMorePopover = () => {
        onSetIsMenuVisible(!isMenuVisible);
    };

    const IconToShow = () => (isMenuVisible ? <PlusIconCreateActive /> : <PlusIconCreate />);

    const onCloseDropdown = (url: string, eventType: string) => {
        sendEvent({
            btn_name: 'header-create',
            action: 'button-click',
            value: eventType,
        });

        window.location.href = url;
        onSetIsMenuVisible(false);
    };

    return (
        <div>
            <Popover
                content={<DropdownLinks onSetIsMenuVisible={onCloseDropdown} />}
                trigger="click"
                placement="bottom"
                visible={isMenuVisible}
                autoAdjustOverflow={false}
                onVisibleChange={toggleMorePopover}
                overlayClassName="create-button-group-overlay"
                align={TOOLTIP_ALIGN}
            >
                <div className={btnClassNames}>
                    <Button icon={<IconToShow />} />
                </div>
            </Popover>
        </div>
    );
};
