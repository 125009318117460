import { createAsyncThunk } from '@reduxjs/toolkit';

import { topSearchGetEntities } from 'store/header/search/actions/top-search-get-entities';
import { TopSearchApiOptions } from 'store/header/search/models/top-search-api-options';
import { selectIsUserFreemium } from 'store/selectors/app-selectors';
import { AppState } from 'store/store';

export const topSearchSearchByTerm = createAsyncThunk(
    'top-search/search-by-term',
    async (
        { term, searchReportType, sendSplunkCallback, searchId }: TopSearchApiOptions,
        { dispatch, getState },
    ) => {
        const state = getState() as AppState;
        const isFreemium = selectIsUserFreemium(state);
        dispatch(
            topSearchGetEntities({
                term,
                searchReportType,
                source: 'top_bar',
                sendSplunkCallback,
                searchId,
                purchasedOnly: !isFreemium && searchReportType === 'Chain',
            }),
        );
    },
);
