/* eslint-disable max-len */
/* eslint-disable quotes */
import React from 'react';
import { SgBidIcon } from 'features/onboarding/solutions-guide/icons/sg-bid-icon';
import { CHAINS_ROUTE } from 'features/onboarding/solutions-guide/consts/constants';

export const OTHER_SOLUTIONS_GUIDE = {
    icon: <SgBidIcon />,
    solutions: [
        {
            title: 'Visitor Analysis',
            description:
                "Discover more about any property's visitors based on their demographics, favorite places, visitor journey, and more.",
            url: '/insights/venues/219a760d66f9d6f0e91695fb/overview',
        },
        {
            title: 'Market Trends',
            description:
                'Gain visibility into overall industry performance and market trends across retail categories in different regions.',
            url: '/labs/industry-trends',
        },
        {
            title: 'Competitive Intelligence',
            description:
                'Find out how different chains are performing to benchmark performance and identify growing trends.',
            url: CHAINS_ROUTE,
        },
        {
            title: 'Consumer Insights',
            description:
                "Uncover comprehensive insights about a property's visitors, based on their online and offline behavior.",
            url: '/advanced-reports/freemium/persona-live',
        },
        {
            title: 'Performance Monitoring',
            description:
                'Quantify, benchmark, explain, and showcase the performance of any physical property.',
            url: '/insights/venues/219a760d66f9d6f0e91695fb/overview',
        },
    ],
};
