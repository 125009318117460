import { useEffect } from 'react';

export const usePopstate = (callback: () => void) => {
    useEffect(() => {
        if (!callback) return;
        window.addEventListener('popstate', callback);

        return () => window.removeEventListener('popstate', callback);
    }, [callback]);
};
