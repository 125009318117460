/* eslint-disable max-len */
import React from 'react';
import {
    getMainIconProps,
    MainIconProps,
} from 'utils/temporary-ui-components-icons-utils/temporary-ui-components-icons-utils';

export function EducationMobileIcon(props: MainIconProps) {
    const { outline, size } = getMainIconProps(props);
    return (
        <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path shapeRendering="optimizeQuality" d="M15.1873 7.38818V11.1303C15.1876 11.2677 15.1375 11.4005 15.0466 11.5036C14.2521 12.3889 12.3114 14.063 8.99963 14.063C5.68785 14.063 3.74859 12.3889 2.95264 11.5036C2.86172 11.4005 2.8117 11.2677 2.81201 11.1303V7.38818L8.99963 10.688L15.1873 7.38818Z" fill="#D0D0D0"/>
<path shapeRendering="optimizeQuality" d="M17.702 5.69106L9.26438 1.191C9.18299 1.14767 9.09221 1.125 9 1.125C8.9078 1.125 8.81701 1.14767 8.73562 1.191L0.297955 5.69106C0.207956 5.73902 0.13269 5.81055 0.080207 5.89799C0.0277243 5.98543 0 6.08549 0 6.18747C0 6.28945 0.0277243 6.38951 0.080207 6.47695C0.13269 6.56439 0.207956 6.63592 0.297955 6.68388L2.24987 7.72522V11.1298C2.24928 11.4061 2.35097 11.6728 2.53534 11.8786C3.45645 12.9045 5.52017 14.6251 9 14.6251C10.1538 14.6346 11.299 14.4256 12.3751 14.0091V16.3126C12.3751 16.4618 12.4343 16.6049 12.5398 16.7104C12.6453 16.8158 12.7884 16.8751 12.9376 16.8751C13.0868 16.8751 13.2298 16.8158 13.3353 16.7104C13.4408 16.6049 13.5001 16.4618 13.5001 16.3126V13.4656C14.2336 13.0422 14.8965 12.5067 15.4647 11.8786C15.649 11.6728 15.7507 11.4061 15.7501 11.1298V7.72522L17.702 6.68388C17.792 6.63592 17.8673 6.56439 17.9198 6.47695C17.9723 6.38951 18 6.28945 18 6.18747C18 6.08549 17.9723 5.98543 17.9198 5.89799C17.8673 5.81055 17.792 5.73902 17.702 5.69106ZM9 13.5001C5.95752 13.5001 4.16803 12.0136 3.37489 11.1298V8.325L8.73562 11.1839C8.81701 11.2273 8.9078 11.2499 9 11.2499C9.09221 11.2499 9.18299 11.2273 9.26438 11.1839L12.3751 9.52525V12.7836C11.4891 13.197 10.3725 13.5001 9 13.5001ZM14.6251 11.127C14.2879 11.5012 13.9106 11.8372 13.5001 12.129V8.92477L14.6251 8.325V11.127ZM13.2188 7.80046L13.2034 7.79132L9.26579 5.69106C9.13441 5.62396 8.98194 5.61115 8.84121 5.65539C8.70049 5.69963 8.58277 5.79738 8.51343 5.92759C8.44408 6.05779 8.42866 6.21002 8.47049 6.35148C8.51231 6.49295 8.60803 6.61232 8.73703 6.68388L12.0235 8.4375L9 10.0498L1.75767 6.18747L9 2.32516L16.2423 6.18747L13.2188 7.80046Z" fill={outline}/>
</svg>
    );
}
EducationMobileIcon.__tags = [] as string[];
