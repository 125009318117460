import React from 'react';
import { PickInfo } from 'deck.gl';
import { DeckGlOverlay } from 'ui-components/google-map/components/deck-gl-overlay/deck-gl-overlay';
import { useMapLayers } from 'ui-components/google-map-layers/hooks/use-map-layers';
import { useGoogleMapLayersContextState } from 'ui-components/google-map-layers/context/google-map-layers-context';
import { LogoMarkerTooltip } from 'ui-components/google-map-layers/components/logo-marker-tooltip/logo-marker-tooltip';
import { useSetDrawerResult } from 'features/explore/context/use-explore-ui-state-actions';

type GoogleMapLayersProps = {
    enableMarkerTooltip?: boolean;
    alwaysShowPolygon?: boolean;
};

export const GoogleMapLayers = ({
    enableMarkerTooltip = true,
    alwaysShowPolygon,
}: GoogleMapLayersProps) => {
    const {
        layers,
        clusteredMarkers: { logo: logos },
    } = useMapLayers({
        alwaysShowPolygon,
    });
    const { setActiveId, markerTooltipComponent } = useGoogleMapLayersContextState();
    const setDrawerItem = useSetDrawerResult();

    const resetActiveId = ({ picked }: PickInfo<any>) => {
        setDrawerItem(undefined);
        if (!picked) {
            setActiveId(undefined);
        }
    };

    const logosTooltips = logos?.map((marker) => (
        <LogoMarkerTooltip key={marker.info.id} poi={marker} clickable={enableMarkerTooltip} />
    ));

    return (
        <>
            <DeckGlOverlay
                layers={layers}
                pickingRadius={0}
                onClick={resetActiveId}
                onMountOnlyLayer={false}
            />
            {logosTooltips}
            {enableMarkerTooltip && markerTooltipComponent()}
        </>
    );
};
