/* eslint-disable max-len */
import React, { useMemo } from 'react';
import type { SVGProps } from 'react';

type ChevronRightIconUniqueProps = {
    width?: number | string;
    height?: number | string;
    direction?: 'right' | 'left' | 'top' | 'bottom';
};

type ChevronRightIconProps = SVGProps<SVGSVGElement> & ChevronRightIconUniqueProps;

export const ChevronRightIcon = function ChevronRightIcon({
    width = 5,
    height = 9,
    direction = 'right',
    ...props
}: ChevronRightIconProps) {
    const rotationStyle: React.CSSProperties | undefined = useMemo(() => {
        switch (direction) {
            case 'left':
                return { transform: 'scaleX(-1)' };
            case 'top':
                return { transform: 'rotate(-90deg)' };
            case 'bottom':
                return { transform: 'rotate(90deg)' };
            default:
                return undefined;
        }
    }, [direction]);

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 5 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={rotationStyle}
            {...props}
        >
            <path d="M0.5 0.5L4.5 4.5" stroke="currentColor" strokeLinecap="round" />
            <path d="M0.5 8.5L4.5 4.5" stroke="currentColor" strokeLinecap="round" />
        </svg>
    );
};
ChevronRightIcon.__tags = ['chevron', 'right', 'arrow'] as string[];
