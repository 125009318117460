import { useMemo, useRef } from 'react';
import { placeTypeToPlaceCollection } from 'utils/place-type-collection-conversion/place-type-collection-conversion';
import { compose, reverse, sortBy } from 'lodash/fp';
import { useSelector } from 'react-redux';
import { userCustomSettingsSelector, userPermissionsSelector } from 'store/selectors/app-selectors';
import { APP_CONSTS } from 'core/constants/app-consts';
import { usePlatformConfiguration } from 'features/settings/store/selectors';
import { getFeatureFlags } from 'core/flow-control';

export const useGetIndexPath = () => {
    const DEFAULT_ROUTE = '/explore';
    const userCustomSettings = useSelector(userCustomSettingsSelector);
    const recentPois = useMemo(
        () => userCustomSettings?.recent_poi_ids || [],
        [userCustomSettings?.recent_poi_ids],
    );
    const userPermissions = useSelector(userPermissionsSelector);
    const userPreferences = usePlatformConfiguration();
    const platformConfigStartPage = userPreferences?.platform_config_start_page as string;
    const settingPermission = userPermissions?.enable_settings;
    const startupPage = (APP_CONSTS.startupPageByKey as any)[platformConfigStartPage];
    const isOverviewPage = startupPage === 'admin-panel.property.insights.overview';
    const isMyZoneViewPage = startupPage === 'admin-panel.my-zone-react';
    const isExploreViewPage = startupPage === 'admin-panel.explore';
    const { enable_my_properties_landing: enableMyPropertiesLandingFF } = getFeatureFlags();
    const hasFoundIndexPath = useRef(false);

    return useMemo(() => {
        if (!Object.keys(userPreferences).length || hasFoundIndexPath.current) {
            return '';
        }
        hasFoundIndexPath.current = true;

        if (!settingPermission || !startupPage) {
            return enableMyPropertiesLandingFF
                ? '/my-zone' //'admin-panel.my-zone-react'
                : DEFAULT_ROUTE;
        } else {
            if (isOverviewPage) {
                //'admin-panel.property.insights.overview',
                if (!recentPois.length) {
                    return DEFAULT_ROUTE;
                } else {
                    const recentPoiIds = compose(reverse, sortBy(['timestamp']))(recentPois);
                    const { type, id } = recentPoiIds[0];

                    return `/insights/${placeTypeToPlaceCollection(type)}/${id}/overview`;
                }
            } else if (isMyZoneViewPage) {
                return '/my-zone'; //'admin-panel.my-zone-react',
            } else if (isExploreViewPage) {
                return DEFAULT_ROUTE; //'admin-panel.explore2',
            } else {
                return '/chains'; //'admin-panel.chains',
            }
        }
    }, [
        enableMyPropertiesLandingFF,
        isExploreViewPage,
        isMyZoneViewPage,
        isOverviewPage,
        recentPois,
        settingPermission,
        startupPage,
        userPreferences,
    ]);
};
