import { useCallback } from 'react';
import { BackendFilter, BaseEntity } from 'features/insights/types/filter-types';
import { placeTypeToPlaceCollection } from 'utils/place-type-collection-conversion/place-type-collection-conversion';
import { generatePath, useHistory } from 'react-router-dom';
import { reportNameFromURL } from 'utils/get-from-url/get-from-url';
import { AnalyzePoi } from 'features/mobile-report/components/analyze-page/hooks/use-go-to-analyze';
import { INSIGHTS_REPORT_PATH } from 'features/insights/consts/routes';

import { useGetNewFilters } from './use-get-new-filters';

const isAnalyzePoi = (entity: BaseEntity | AnalyzePoi): entity is AnalyzePoi => {
    return (entity as AnalyzePoi).filter !== undefined;
};

export const useUpdateUrlByEntities = () => {
    const dashboardSection = reportNameFromURL();
    const history = useHistory();
    const getNewFilters = useGetNewFilters();

    return useCallback(
        ({
            entities,
            addCompetitor = false,
            section,
        }: {
            entities: BaseEntity[] | AnalyzePoi[];
            addCompetitor?: boolean;
            section?: string;
        }) => {
            const [poi, ...competitors] = entities;
            const poiFilters = entities.map((item) => {
                if (isAnalyzePoi(item)) {
                    return item.filter || {};
                } else {
                    return item.customData?.filter || {};
                }
            });
            const search = new URLSearchParams(history.location.search);
            const searchFilters = JSON.parse(search.get('filter') || '[]') as BackendFilter[];

            //existing filters
            if (searchFilters.length) {
                // competitor added or removed
                if (searchFilters.length !== entities.length) {
                    const newFilters = getNewFilters({
                        entities,
                        searchFilters,
                        poiFilters,
                    });
                    search.set('filter', JSON.stringify(newFilters));
                } else {
                    let needChangeConfigFilter = false;
                    const newFilters = poiFilters.map((poiFilter, index) => {
                        if (poiFilter?.config !== searchFilters[index]?.config) {
                            needChangeConfigFilter = true;
                        }
                        const newFilter = { ...searchFilters[index] };
                        if (poiFilters[index]?.config) {
                            newFilter.config = poiFilters[index].config;
                            return newFilter;
                        } else {
                            const { config: _, ...rest } = newFilter;
                            return rest;
                        }
                    });
                    if (needChangeConfigFilter) {
                        search.set('filter', JSON.stringify(newFilters));
                    }
                }
            } else {
                if (poiFilters.length) {
                    search.set('filter', JSON.stringify(poiFilters));
                }
            }

            if (competitors.length) {
                const urlCompetitors = competitors.map((competitor) => {
                    return {
                        collection: competitor.type && placeTypeToPlaceCollection(competitor.type),
                        id: competitor.id,
                    };
                });
                search.set('competitor', JSON.stringify(urlCompetitors));
            } else {
                search.delete('competitor');
            }

            const pathname = generatePath(INSIGHTS_REPORT_PATH, {
                section: section ?? dashboardSection,
                collection: poi.type && placeTypeToPlaceCollection(poi.type),
                id: poi.id,
            });

            history.push({
                pathname,
                search: search.toString(),
                state: { addCompetitor },
            });
        },
        [history, dashboardSection, getNewFilters],
    );
};
