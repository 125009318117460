import { createAction, Update } from '@reduxjs/toolkit';

import { ChainState } from 'features/industry/regular-view/components';
import { TagEntityModel } from 'core/entities';

const actionType = 'industry/updateChainFilters';

// TODO: this solution only support dates, we need toe expand for more filters
const action = (
    chains: Partial<ChainState>[],
): {
    payload: Update<TagEntityModel>[];
} => {
    const payload = chains.map((chain) => ({
        id: chain.uid,
        changes: {
            filters: chain.filters,
        },
    }));

    return {
        payload: payload as Update<TagEntityModel>[],
    };
};

export const updateChainFilters = createAction<typeof action>(actionType, action);
