/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

export type GroupOfPeopleIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type GroupOfPeopleIconProps = SVGProps<SVGSVGElement> & GroupOfPeopleIconUniqueProps;

export const GroupOfPeopleIcon = function GroupOfPeopleIcon({
    width = 57,
    height = 57,
    ...props
}: GroupOfPeopleIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 57 57"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect x="0.330078" y="0.646484" width="56" height="56" rx="3" fill="#F3F3FD" />
            <g clipPath="url(#clip0_7675_515950)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M26.8301 38.1006V24.7344C26.8301 23.6681 25.9193 22.8027 24.7949 22.8027H21.8653C20.7418 22.8027 19.8301 23.6681 19.8301 24.7344V38.1006H26.8301Z"
                    fill="#E1E2FF"
                    stroke="#676DFD"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.8301 18.457H25.8301V20.7528C25.8301 22.1619 24.7389 23.3151 23.4046 23.3151H23.2547C21.9213 23.3151 20.8301 22.1619 20.8301 20.7528V18.457Z"
                    fill="white"
                    stroke="#676DFD"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M25.8301 16.6465H22.0529C21.3775 16.6465 20.8301 17.225 20.8301 17.9378V18.5781H25.8301V16.6465Z"
                    fill="#E1E2FF"
                    stroke="#676DFD"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M36.8301 38.1006V24.7344C36.8301 23.6681 35.9193 22.8027 34.7949 22.8027H31.8653C30.7418 22.8027 29.8301 23.6681 29.8301 24.7344V38.1006H36.8301Z"
                    fill="#E1E2FF"
                    stroke="#676DFD"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M30.8301 18.457H35.8301V20.7528C35.8301 22.1619 34.7389 23.3151 33.4046 23.3151H33.2547C31.9213 23.3151 30.8301 22.1619 30.8301 20.7528V18.457Z"
                    fill="white"
                    stroke="#676DFD"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M35.8301 16.6465H32.0529C31.3775 16.6465 30.8301 17.225 30.8301 17.9378V18.5781H35.8301V16.6465Z"
                    fill="#E1E2FF"
                    stroke="#676DFD"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22.8301 40.1006V36.7344C22.8301 35.6681 21.9193 34.8027 20.7949 34.8027H17.8653C16.7418 34.8027 15.8301 35.6681 15.8301 36.7344V40.1006H22.8301Z"
                    fill="#E1E2FF"
                    stroke="#676DFD"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.8301 30.457H21.8301V32.7528C21.8301 34.1619 20.7389 35.3151 19.4046 35.3151H19.2547C17.9213 35.3151 16.8301 34.1619 16.8301 32.7528V30.457Z"
                    fill="white"
                    stroke="#676DFD"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.8301 28.6465H18.0529C17.3775 28.6465 16.8301 29.225 16.8301 29.9378V30.5781H21.8301V28.6465Z"
                    fill="#E1E2FF"
                    stroke="#676DFD"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M40.8301 40.1006V36.7344C40.8301 35.6681 39.9193 34.8027 38.7949 34.8027H35.8653C34.7418 34.8027 33.8301 35.6681 33.8301 36.7344V40.1006H40.8301Z"
                    fill="#E1E2FF"
                    stroke="#676DFD"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M34.8301 30.457H39.8301V32.7528C39.8301 34.1619 38.7389 35.3151 37.4046 35.3151H37.2547C35.9213 35.3151 34.8301 34.1619 34.8301 32.7528V30.457Z"
                    fill="white"
                    stroke="#676DFD"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M39.8301 28.6465H36.0529C35.3775 28.6465 34.8301 29.225 34.8301 29.9378V30.5781H39.8301V28.6465Z"
                    fill="#E1E2FF"
                    stroke="#676DFD"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M31.8301 40.1006V36.7344C31.8301 35.6681 30.9193 34.8027 29.7949 34.8027H26.8653C25.7418 34.8027 24.8301 35.6681 24.8301 36.7344V40.1006H31.8301Z"
                    fill="#E1E2FF"
                    stroke="#676DFD"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M25.8301 30.457H30.8301V32.7528C30.8301 34.1619 29.7389 35.3151 28.4046 35.3151H28.2547C26.9213 35.3151 25.8301 34.1619 25.8301 32.7528V30.457Z"
                    fill="white"
                    stroke="#676DFD"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M30.8301 28.6465H27.0529C26.3775 28.6465 25.8301 29.225 25.8301 29.9378V30.5781H30.8301V28.6465Z"
                    fill="#E1E2FF"
                    stroke="#676DFD"
                />
            </g>
            <defs>
                <clipPath id="clip0_7675_515950">
                    <rect
                        width="26"
                        height="25"
                        fill="white"
                        transform="translate(15.3301 15.6465)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
GroupOfPeopleIcon.__tags = ['people', 'group'] as string[];
