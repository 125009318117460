import { useEffect } from 'react';

import { useSelectorWithProps } from 'utils/structured-selector/structured-selector';
import { selectHasUserPermission } from 'store/selectors/app-selectors';

export const useHideHubspotPopup = () => {
    const hideHubSpotPopupPermission = useSelectorWithProps(
        {
            permission: 'ignore_hubspot_popup',
        },
        selectHasUserPermission,
    );

    useEffect(() => {
        if (!hideHubSpotPopupPermission) return;
        const destroyHubSpotPopup = () => {
            let intervalId = setInterval(() => {
                const elHubSpotPopup = document.querySelector<HTMLElement>(
                    '.leadinModal-theme-bottom-right-corner',
                );
                if (elHubSpotPopup?.isConnected) {
                    elHubSpotPopup.style.display = 'none';
                    clearInterval(intervalId);
                }
            }, 2000);
        };
        destroyHubSpotPopup();
    }, [hideHubSpotPopupPermission]);
};
