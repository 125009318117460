/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

export type FiltersSettingsIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type FiltersSettingsIconProps = SVGProps<SVGSVGElement> & FiltersSettingsIconUniqueProps;

export const FiltersSettingsIcon = function FiltersSettingsIcon({
    width = 16,
    height = 16,
    ...props
}: FiltersSettingsIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="1 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.23814 9.95146L3.64881 10.9417L2.88614 12.5452C2.82214 12.6795 2.8497 12.8377 2.9537 12.9426L4.20347 14.1915C4.30747 14.2955 4.4657 14.3221 4.59903 14.259L6.20347 13.4972L7.19281 13.9061L7.78836 15.5808C7.83725 15.7195 7.96881 15.8119 8.11636 15.8119H9.88348C10.031 15.8119 10.1626 15.7195 10.2115 15.5808L10.8061 13.9079L11.7964 13.4972L13.3999 14.259C13.5341 14.3221 13.6924 14.2955 13.7964 14.1915L15.0461 12.9426C15.1501 12.8386 15.1768 12.6795 15.1137 12.5452L14.3519 10.9426L14.7608 9.95235L16.6666 9.02879L1.33325 9.02879L3.23814 9.95146Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.17505 8.14538C6.17505 6.58538 7.43994 5.32138 8.99994 5.32138C10.5599 5.32138 11.8248 6.58538 11.8248 8.14538C11.8248 9.70538 10.5599 10.9703 8.99994 10.9703C7.43994 10.9703 6.17505 9.70538 6.17505 8.14538Z"
                fill="white"
                stroke="#49565D"
                strokeWidth="0.75"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.6666 8.83582V7.45537C16.6666 7.19226 16.5004 6.95759 16.2524 6.86959L14.7617 6.33893L14.3519 5.34959L15.031 3.92026C15.1439 3.68204 15.095 3.39848 14.9084 3.2127L13.9333 2.2367C13.7475 2.05004 13.463 2.00204 13.2257 2.11404L11.7964 2.79404L10.807 2.38426L10.2764 0.892703C10.1884 0.644703 9.95281 0.47937 9.6897 0.47937H8.31014C8.04614 0.47937 7.81147 0.644703 7.72347 0.892703L7.1937 2.38337L6.20347 2.79404L4.77414 2.11404C4.53592 2.00204 4.25325 2.05004 4.06659 2.2367L3.09059 3.2127C2.90481 3.39937 2.85592 3.68204 2.96881 3.92026L3.64792 5.3487L3.23903 6.33804L1.74659 6.86959C1.49947 6.95759 1.33325 7.19226 1.33325 7.45537V8.83582C1.33325 9.09893 1.49947 9.33448 1.74659 9.42159L3.23814 9.95226L3.64881 10.9416L2.96881 12.3718C2.85592 12.6091 2.90481 12.8918 3.09059 13.0785L4.06659 14.0545C4.25325 14.2411 4.53592 14.29 4.77414 14.1771L6.20347 13.4971L7.19281 13.9069L7.72347 15.3985C7.81147 15.6465 8.04614 15.8127 8.31014 15.8127H9.6897C9.95281 15.8127 10.1884 15.6465 10.2764 15.3985L10.8061 13.9078L11.7964 13.4971L13.2257 14.1771C13.463 14.29 13.7466 14.2411 13.9333 14.0545L14.9084 13.0785C15.095 12.8927 15.1439 12.6091 15.031 12.3718L14.3519 10.9425L14.7608 9.95315L16.2524 9.42159C16.5004 9.33448 16.6666 9.09893 16.6666 8.83582Z"
                stroke="#49565D"
                strokeWidth="0.75"
                strokeLinecap="round"
            />
        </svg>
    );
};
FiltersSettingsIcon.__tags = ['cog', 'settings', 'wheel', 'configuration'] as string[];
