import { actions } from 'features/explore/context/explore-context-reducer';
import { useCallback } from 'react';
import { useExploreUIStateDispatch } from 'features/explore/context/use-explore-ui-state';
import type { ExploreSearchResult, ExploreUIStateType } from 'features/explore/types/types';
import type { Dictionary, Place } from '@placer-ui/types';
import type { PlaceOverlay } from 'ui-components/google-map-layers/types/google-map-layers-types';
import type { ResponseStatus } from 'API/common/types';
import { exploreEnclosingComplexesSignal } from 'features/mobile-report/components/explore-mobile/state/explore.signals';

export const useCleanupExploreUIContext = () => {
    const dispatch = useExploreUIStateDispatch();
    return useCallback(() => dispatch(actions.cleanup()), [dispatch]);
};

export const useSetResults = () => {
    const dispatch = useExploreUIStateDispatch();
    return useCallback(
        (results: ExploreSearchResult[]) => dispatch(actions.setResults(results)),
        [dispatch],
    );
};

export const useSetCoordinatesList = () => {
    const dispatch = useExploreUIStateDispatch();
    return useCallback(
        (coordinates: ExploreSearchResult[]) => dispatch(actions.setCoordinates(coordinates)),
        [dispatch],
    );
};

export const useSetCoordinatesError = () => {
    const dispatch = useExploreUIStateDispatch();
    return useCallback(
        (error: ResponseStatus) => dispatch(actions.setCoordinatesError(error)),
        [dispatch],
    );
};

export const useSetDrawerResult = () => {
    const dispatch = useExploreUIStateDispatch();
    return useCallback(
        (result?: ExploreSearchResult) => dispatch(actions.setDrawerResult(result)),
        [dispatch],
    );
};

export const useSetLoading = () => {
    const dispatch = useExploreUIStateDispatch();
    return useCallback((loading?: boolean) => dispatch(actions.setLoading(loading)), [dispatch]);
};

export const useSetError = () => {
    const dispatch = useExploreUIStateDispatch();
    return useCallback((error?: boolean) => dispatch(actions.setError(error)), [dispatch]);
};

export const useSetActiveId = () => {
    const dispatch = useExploreUIStateDispatch();
    return useCallback((id?: string) => dispatch(actions.setActiveId(id)), [dispatch]);
};

export const useSetExploreUIContext = () => {
    const dispatch = useExploreUIStateDispatch();
    return useCallback(
        (params?: ExploreUIStateType) => dispatch(actions.setUIContext(params)),
        [dispatch],
    );
};

export const useSetSuggestion = () => {
    const dispatch = useExploreUIStateDispatch();
    return useCallback(
        (suggestion?: ExploreSearchResult) => dispatch(actions.setSuggestion(suggestion)),
        [dispatch],
    );
};

export const useSetCustomPoisAddresses = () => {
    const dispatch = useExploreUIStateDispatch();
    return useCallback(
        (customPoiAddresses: Dictionary<string>) =>
            dispatch(actions.setCustomPoiAddresses(customPoiAddresses)),
        [dispatch],
    );
};

export const useSetDropPinCoordinate = () => {
    const dispatch = useExploreUIStateDispatch();
    return useCallback(
        (coordinate?: mapboxgl.LngLat) => {
            dispatch(actions.setDropPinCoordinate(coordinate));
        },
        [dispatch],
    );
};

export const useSetIsDropPinMode = () => {
    const dispatch = useExploreUIStateDispatch();
    return useCallback(
        (isActive: boolean) => {
            dispatch(actions.setIsDropPinMode(isActive));
        },
        [dispatch],
    );
};

export const useSetHoverId = () => {
    const dispatch = useExploreUIStateDispatch();
    return useCallback((id?: string) => dispatch(actions.setHoverId(id)), [dispatch]);
};

export const useClearSelectedResults = () => {
    const dispatch = useExploreUIStateDispatch();
    return useCallback(() => dispatch(actions.clearSelectedResults()), [dispatch]);
};

export const useAddSelectedResult = () => {
    const dispatch = useExploreUIStateDispatch();
    return useCallback(
        (result: PlaceOverlay, poiSearchResultId: string) =>
            dispatch(
                actions.addSelectedResult({
                    result,
                    poiSearchResultId,
                }),
            ),
        [dispatch],
    );
};

type SetOverlayPoi = {
    sourcePoi: string;
    overlayPoi: PlaceOverlay;
};

export const useSetOverlayPOI = () => {
    const dispatch = useExploreUIStateDispatch();
    return useCallback(
        ({ sourcePoi, overlayPoi }: SetOverlayPoi) =>
            dispatch(
                actions.setOverlayPOI({
                    sourcePoi,
                    overlayPoi,
                }),
            ),
        [dispatch],
    );
};

export const useSetOverlayPOIOnce = () => {
    const dispatch = useExploreUIStateDispatch();
    return useCallback(
        (sourcePoi: string, overlayPoi: PlaceOverlay) =>
            dispatch(
                actions.setOverlayPOIOnce({
                    sourcePoi,
                    overlayPoi,
                }),
            ),
        [dispatch],
    );
};

export const useRemoveSelectedId = () => {
    const dispatch = useExploreUIStateDispatch();
    return useCallback(
        (id: string, selectedOverlay: PlaceOverlay) =>
            dispatch(
                actions.removeSelectedId({
                    id,
                    selectedOverlay,
                }),
            ),
        [dispatch],
    );
};

export const useRemoveAllSelectedId = () => {
    const dispatch = useExploreUIStateDispatch();
    return useCallback(
        (id: string, selectedOverlay: PlaceOverlay) =>
            dispatch(
                actions.removeAllOverlays({
                    id,
                    selectedOverlay,
                }),
            ),
        [dispatch],
    );
};

export const useSetExploreMultiselectDrawerVisibility = () => {
    const dispatch = useExploreUIStateDispatch();
    return useCallback(
        (isOpen: boolean) => dispatch(actions.setExploreMultiselectDrawerVisibility(isOpen)),
        [dispatch],
    );
};

export const useSetExploreCreatePoiLoading = () => {
    const dispatch = useExploreUIStateDispatch();
    return useCallback(
        (isLoading: boolean) => dispatch(actions.setExploreCreateReport(isLoading)),
        [dispatch],
    );
};

export const useSetEnclosingComplexes = () => {
    const dispatch = useExploreUIStateDispatch();
    return useCallback(
        (enclosingComplexes: Dictionary<Place[]>) => {
            dispatch(actions.setEnclosingComplexes(enclosingComplexes));
            exploreEnclosingComplexesSignal.value = enclosingComplexes;
        },
        [dispatch],
    );
};
