import { Tooltip } from 'ui-components/tooltip/tooltip';
import React, { ReactNode } from 'react';
import styles from './value-cell.module.scss';
import classnames from 'classnames';

type ValueCellProps = {
    values: (string | number)[];
    title?: string;
    tooltipText?: string | ReactNode;
    classNames?: {
        cellWrapper?: string;
        title?: string;
        label?: {
            firstNum?: string;
            secondNum?: string;
            slashSeparator?: string;
        };
    };
};

export const ValueCell = ({
    values,
    title,
    tooltipText,
    classNames,
    ...restProps
}: ValueCellProps) => {
    const slash = ' / ';
    const valuesWithSlash = (
        <span className={styles.label}>
            {values.map((val, idx) => (
                <span key={idx}>
                    <Tooltip
                        placement="top"
                        title={
                            tooltipText ? (
                                <div data-testid={'value-cell-value-tooltip'}>{tooltipText}</div>
                            ) : undefined
                        }
                    >
                        <span
                            className={classnames(
                                classNames?.label?.firstNum && {
                                    [classNames?.label?.firstNum]: idx % 2 === 0,
                                },
                                classNames?.label?.secondNum && {
                                    [classNames?.label?.secondNum]: idx % 2 !== 0,
                                },
                            )}
                        >
                            {val}
                        </span>
                    </Tooltip>

                    {idx !== values.length - 1 && (
                        <span
                            className={classnames(
                                styles.slashSeparator,
                                classNames?.label?.slashSeparator,
                            )}
                        >
                            {slash}
                        </span>
                    )}
                </span>
            ))}
        </span>
    );
    return (
        <div className={classnames(styles.cellWrapper, classNames?.cellWrapper)} {...restProps}>
            {title && <span className={classnames(styles.title, classNames?.title)}>{title}</span>}
            {valuesWithSlash}
        </div>
    );
};
