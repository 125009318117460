import React, { KeyboardEvent, MouseEvent, ReactNode, useState } from 'react';
import { CheckOutlined, MinusOutlined } from '@ant-design/icons';
import _ from 'lodash/fp';
import classNames from 'classnames';

import styles from './switch.module.scss';

export interface SwitchProps {
    uid: string;
    color?: string;
    onChange?: (value: boolean, uid: string) => void;
    onClick?: Function;
    onMouseUp?: Function;
    className?: string;
    labelClassName?: string;
    disabled?: boolean;
    loadingIcon?: ReactNode;
    checkedChildren?: ReactNode;
    unCheckedChildren?: ReactNode;
    toggleText?:
        | {
              on: string;
              off: string;
          }
        | string;
    defaultCheck?: boolean;
    checked?: boolean;
}

export const Switch = ({
    toggleText,
    className = '',
    disabled,
    loadingIcon,
    checkedChildren = <CheckOutlined />,
    unCheckedChildren = <MinusOutlined />,
    onChange,
    defaultCheck = true,
    checked,
    uid,
    onClick,
    onMouseUp,
    color = '#49565D',
    labelClassName,
    ...restProps
}: SwitchProps) => {
    const [isChecked, setIsChecked] = useState(defaultCheck);

    // https://reactjs.org/docs/hooks-faq.html#how-do-i-implement-getderivedstatefromprops
    if (!_.isNil(checked) && checked !== isChecked) {
        setIsChecked(checked);
    }

    const switchClasses = classNames(styles.plSwitch, className, {
        [styles.checked]: isChecked,
        [styles.disabled]: disabled,
    });

    function setChecked(checked: boolean) {
        if (disabled) {
            return;
        }

        setIsChecked(checked);

        if (onChange) {
            onChange(!!checked, uid);
        }
    }

    function handleClick(e: MouseEvent) {
        const newChecked = !isChecked;
        setChecked(newChecked);
        if (onClick) {
            onClick(newChecked);
        }
    }

    function handleKeyDown(e: KeyboardEvent) {
        if (e.keyCode === 37) {
            // Left
            setChecked(false);
        } else if (e.keyCode === 39) {
            // Right
            setChecked(true);
        }
    }

    // Handle auto focus when click switch in Chrome
    function handleMouseUp(e: MouseEvent) {
        if (onMouseUp) {
            onMouseUp(e);
        }
    }

    let toggleTextDisplay;
    if (toggleText) {
        toggleTextDisplay =
            typeof toggleText === 'string'
                ? toggleText
                : isChecked
                ? toggleText.on
                : toggleText.off;
    }
    return (
        <>
            <button
                {...restProps}
                type="button"
                role="switch"
                aria-checked={isChecked}
                className={switchClasses}
                onKeyDown={handleKeyDown}
                onClick={handleClick}
                onMouseUp={handleMouseUp}
                style={{ color }}
            >
                {loadingIcon}
                <div className={styles.plSwitchCircle}>
                    {isChecked ? checkedChildren : unCheckedChildren}
                </div>
            </button>
            {toggleTextDisplay && (
                <span className={classNames(styles.switchToggleText, labelClassName)}>
                    {toggleTextDisplay}
                </span>
            )}
        </>
    );
};

