import React, { FC } from 'react';
import classNames from 'classnames';

import styles from './table-cells.module.scss';

export const ColumnName: FC<{ className?: string }> = ({
    children,
    className,
}) => {
    return (
        <div className={classNames(styles.columnNameCell, className)}>
            {children}
        </div>
    );
};
