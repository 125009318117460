import { useCallback } from 'react';
import {
    flagIsNotAnEnclosingComplex,
    isAnEnclosingComplexFlag,
} from 'utils/enclosing-complexes-filter/enclosing-complexes-filter';
import { usePoiSelectionContext } from 'features/poi-selection-map/context/poi-selection-context';
import { useNearbyActivityRadiusDefault } from 'hooks/use-nearby-activity-radius/use-nearby-activity-radius';
import { createPlaceWithNearbyResult } from 'utils/create-place-with-nearby-result/create-place-with-nearby-result';
import { fetchEnclosingComplexes } from 'API/enclosing-complex/enclosing-complex-api';
import {
    OverlayPoiObject,
    PlacePois,
} from 'features/poi-selection-map/types/poi-selection-types';
import {
    getComplexPlaceOverlay,
    getNearbyPlaceOverlay,
    getPoiPlaceOverlay,
} from 'ui-components/google-map-layers/utils/get-place-overlay';
import { getReportEntityFlag } from 'core/services/report-entities-service/report-entities-service';

type ComplexTypes = {
    complexes: PlacePois;
    flaggedComplexes: PlacePois;
    enclosingComplexes: PlacePois;
};

export const useTransformPoiOverlays = () => {
    const fetchEnclosingComplexes = useFetchEnclosingComplexes();
    const setResultsOverlayPoi = useSetResultsOverlayPoi();
    return useCallback(
        async (results: PlacePois) => {
            const sortedComplexes = results.reduce<ComplexTypes>(
                (acc, curr) => {
                    if (isAnEnclosingComplexFlag(curr.info)) {
                        acc.enclosingComplexes.push(curr);
                    } else if (flagIsNotAnEnclosingComplex(curr.info)) {
                        acc.flaggedComplexes.push(curr);
                    } else if (!getReportEntityFlag(curr.info)) {
                        acc.complexes.push(curr);
                    }
                    return acc;
                },
                {
                    complexes: [],
                    flaggedComplexes: [],
                    enclosingComplexes: [],
                },
            );
            setResultsOverlayPoi(sortedComplexes);
            await fetchEnclosingComplexes(sortedComplexes.enclosingComplexes);
        },
        [fetchEnclosingComplexes, setResultsOverlayPoi],
    );
};

export const useSetResultsOverlayPoi = () => {
    const { setOverlayPoisList } = usePoiSelectionContext();
    const defaultRadius = useNearbyActivityRadiusDefault();

    return useCallback(
        ({ complexes, flaggedComplexes }: ComplexTypes) => {
            const flaggedOverlayPois = flaggedComplexes.reduce<OverlayPoiObject[]>((acc, curr) => {
                acc.push({
                    sourcePoi: curr.info.id,
                    overlayPoi: getNearbyPlaceOverlay(
                        createPlaceWithNearbyResult(curr.info, defaultRadius),
                    ),
                });
                return acc;
            }, []);

            const complexesOverlayPois = complexes.reduce<OverlayPoiObject[]>((acc, curr) => {
                acc.push({
                    sourcePoi: curr.info.id,
                    overlayPoi: getPoiPlaceOverlay(curr.info),
                });
                return acc;
            }, []);

            setOverlayPoisList([...flaggedOverlayPois, ...complexesOverlayPois], true);
        },
        [defaultRadius, setOverlayPoisList],
    );
};
export const useFetchEnclosingComplexes = () => {
    const { setOverlayPoisList, setEnclosingComplexes } = usePoiSelectionContext();
    return useCallback(
        async (enclosingComplexes: PlacePois) => {
            if (!enclosingComplexes.length) return;
            const enclosingComplexesResponse = await fetchEnclosingComplexes(
                enclosingComplexes.map(({ info }) => info.id),
            );

            setEnclosingComplexes(enclosingComplexesResponse);
            const overlayPois = Object.entries(enclosingComplexesResponse).reduce<
                OverlayPoiObject[]
                // for each entry, set the first complex as a default selection for overlaying POI
            >((acc, [baseVenueId, [defaultComplex]]) => {
                acc.push({
                    sourcePoi: baseVenueId,
                    overlayPoi: getNearbyPlaceOverlay(getComplexPlaceOverlay(defaultComplex)),
                });
                return acc;
            }, []);
            setOverlayPoisList(overlayPois, true);
        },
        [setEnclosingComplexes, setOverlayPoisList],
    );
};

export const useGetEnclosingComplexOptions = () => {
    const { enclosingComplexes } = usePoiSelectionContext();
    return useCallback(
        (venueId: string) => {
            return enclosingComplexes ? enclosingComplexes[venueId] : undefined;
        },
        [enclosingComplexes],
    );
};
