import { API } from 'API';

import {
    setUserCustomSettingsActionCreator,
    setUserSettingsActionCreator,
} from 'store/auth/actions/auth-action-creators';

import { AppDispatch, store } from 'store/store';
import { selectUserSettings } from 'store/selectors/app-selectors';
import { UserCustomSettings } from 'core/entities/user/user-custom-settings';

export const getUserSettings = () => async (dispatch: AppDispatch) => {
    const userSettings = selectUserSettings(store.getState());
    const userCustomSettings = await API.user.getCustomSettings();
    const userSettingsConfig = await API.user.getSettingsConfig();
    const customSettings = userCustomSettings.custom_settings || {};

    (Object.keys(customSettings) as Array<keyof UserCustomSettings>).forEach((value) => {
        try {
            customSettings[value] =
                customSettings[value] && JSON.parse(customSettings[value] as string);
        } catch {
            return;
        }
    });

    dispatch(
        setUserSettingsActionCreator({
            ...userSettings,
            ...userSettingsConfig.settings,
        }),
    );
    dispatch(setUserCustomSettingsActionCreator(customSettings));
};
