import React from 'react';
import { SgLeisureIcon } from 'features/onboarding/solutions-guide/icons/sg-leisure-icon';
import { CHAINS_ROUTE } from 'features/onboarding/solutions-guide/consts/constants';

export const LEISURE_SOLUTIONS_GUIDE = {
    icon: <SgLeisureIcon />,
    solutions: [
        {
            title: 'Site Selection & Expansion',
            description:
                'Easily identify ideal sites for a new venues based ' +
                'on customizable scoring parameters and filters.',
            url: '/advanced-reports/freemium/site-selection',
        },
        {
            title: 'Marketing & Advertising',
            description:
                'Better understand where visitors are coming from to ' +
                'optimize ad geotargeting to reach the highest potential areas.',
            url: '/insights/complexes/5d9707ee7afe4d1065763053/trade-area',
        },
        {
            title: 'Campaign Impact Analysis',
            description:
                'Measure event or marking campaign success, lift, ' +
                'or incrementality, with accurate offline foot traffic data.',
            url: '/insights/complexes/5d9707ee7afe4d1065763053/overview',
        },
        {
            title: 'Competitive Intelligence',
            description:
                'Find out how competing chains are performing to ' +
                'benchmark your performance and identify growing trends.',
            url: CHAINS_ROUTE,
        },
        {
            title: 'Visitor Insights',
            description:
                'Uncover comprehensive demographic and psychographic insights ' +
                'about your visitors at the property or chain level.',
            url: '/insights/complexes/5d9707ee7afe4d1065763053/audience',
        },
        {
            title: 'Sponsorships & Partnerships',
            description:
                'Attract ideal sponsors based on attendee demographics, cross-visitation, and brand affinity.',
            url: '/insights/complexes/5d9707ee7afe4d1065763053/overview',
        },
    ],
};
