import {
    MatchScore,
    MatchScoreParamContent,
} from 'features/void-analysis/common/types/server-responses';
import { ScoreBoundsKeys } from 'features/void-analysis/common/types/server-responses';
import { LOCATIONS_LABEL } from 'features/void-analysis/common/utils/locations-label';

export const getScoreParam = (
    score: MatchScore,
    name: ScoreBoundsKeys,
): MatchScoreParamContent | undefined => {
    return score.params[name];
};

export const getActiveTooltipContent = (
    key: ScoreBoundsKeys,
    voidEntityName: string,
    chainName: string,
) => {
    const locationsLabel = LOCATIONS_LABEL.toLowerCase();
    const scoreBoundEntries: Record<ScoreBoundsKeys, string> = {
        natural_co_tenants: `Number of ${voidEntityName} tenants that are typically located near ${chainName}`,
        // eslint-disable-next-line max-len
        cannibalization: `Potential population overlap between ${voidEntityName} and nearby ${chainName} ${locationsLabel}
        located within 50 mi`,
        avg_monthly_ft: `Average monthly visits to a ${chainName} location nationwide`,
        demographics: '',
        household_characteristics: '',
    };
    return scoreBoundEntries[key];
};

export const getInactiveTooltipContent = (filterName: string) =>
    `${filterName} is not included in the total relative fit score and only shown for reference`;

type TooltipContentProps = {
    isActive: boolean;
    key: ScoreBoundsKeys;
    chainName: string;
    filterName: string;
    voidEntityName?: string;
};
export const getTooltipContent = ({
    isActive,
    key,
    chainName,
    filterName,
    voidEntityName = '',
}: TooltipContentProps) => {
    return isActive
        ? getActiveTooltipContent(key, voidEntityName, chainName)
        : getInactiveTooltipContent(filterName);
};
