import { LngLat } from './lngLat';
import _ from 'lodash';

export class GeoPolygon {

    polygons: any = [];

    constructor(geoJSONArray: any) {
        if (!_.isArray(geoJSONArray)) return;

        _.forEach(geoJSONArray, (polygon: any) => {
            this.polygons.push(this.createLngLat(polygon));
        });
    }

    createLngLat(polygon: any) {
        // in case theres a multipolygon we get another hierarchy level of single array
        if (polygon.length === 1) {
            return _.map(polygon[0], (point: any) => {
                return new LngLat(point);
            });
        }
        return _.map(polygon, (point: any) => {
            return new LngLat(point);
        });
    }

    convertToGooglePoint(polygon: any) {
        return _.map(polygon, (point: any) => {
            return point.toObjectPoint();
        }).slice(0, -1);
    }

    convertToArrayPoint(polygon: any) {
        return _.map(polygon, (point: any) => {
            return point.toArrayPoint();
        });
    }

    toGeoJson() {
        return _.map(this.polygons, (polygon: any) => {
            return this.convertToArrayPoint(polygon);
        });
    }

    toGooglePath() {
        return _.map(this.polygons, (polygon: any) => {
            return this.convertToGooglePoint(polygon);
        });
    }
}
