import React, { Ref, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Popover } from 'antd';
import { identity } from 'lodash/fp';

import { SelectArrowIcon } from 'components/assets/Icons/Icons';

import styles from '../navigation-button.module.scss';
import { NavigationItem } from 'features/header/navigation/hooks/navigation-items';

type NavigationMoreButtonProps = {
    popoverItems?: NavigationItem[];
    buttonRef: Ref<HTMLLIElement>;
    label: string;
    handleMoreMenuItemClick: () => void;
};

const popoverAlignOffset = {
    offset: [32, -8],
};

export const NavigationMoreButton = ({
    popoverItems = [],
    buttonRef,
    label,
    handleMoreMenuItemClick,
}: NavigationMoreButtonProps) => {
    const [isMorePopoverVisible, setIsMorePopoverVisible] = useState<boolean>(
        false,
    );

    const PopoverContent = useCallback(() => {
        if (!popoverItems?.length) return null;

        const onClickHandler = () => {
            setIsMorePopoverVisible(false);
            handleMoreMenuItemClick();
        };

        return (
            <ul className={styles.menuListWrapper} onClick={onClickHandler}>
                {popoverItems.map((item, index) => {
                    const { link, label } = item;
                    return (
                        <li key={index} className={styles.navItem}>
                            <a href={link} className={styles.navItemButton}>
                                {label}
                            </a>
                        </li>
                    );
                })}
            </ul>
        );
    }, [handleMoreMenuItemClick, popoverItems]);

    useEffect(() => {
        return () => {
            setIsMorePopoverVisible(false);
        };
    }, [popoverItems]);

    if (!popoverItems?.length) return null;

    const toggleMorePopover = () => {
        setIsMorePopoverVisible(!isMorePopoverVisible);
    };

    return (
        <li className={styles.navPopoverItem} ref={buttonRef}>
            <Popover
                content={<PopoverContent />}
                trigger="click"
                placement="bottomLeft"
                visible={isMorePopoverVisible}
                onVisibleChange={toggleMorePopover}
                overlayClassName={styles.navigationMoreMenu}
                getPopupContainer={identity}
                align={popoverAlignOffset}
            >
                <span
                    className={classNames(styles.navItemButton, {
                        [styles.isActive]: isMorePopoverVisible,
                    })}
                >
                    <span className={styles.buttonLabel}>{label}</span>
                    <SelectArrowIcon className={styles.buttonArrow} />
                </span>
            </Popover>
        </li>
    );
};
