import React, { useEffect } from 'react';
import { useCheckConnection } from 'features/mobile-report/hooks/use-check-connection';
import { NoInternetIcon } from 'components/assets/Icons/IconsMobile';
import { Button } from 'ui-components/button/button';
import { useGoToExplore } from 'features/mobile-report/hooks/use-go-to-explore';

import styles from './no-internet-connection.module.scss';

export const NoInternetConnection = () => {
    const { isOnline } = useCheckConnection();
    const goToExplore = useGoToExplore();

    useEffect(() => {
        if (isOnline) {
            goToExplore();
        }
    }, [goToExplore, isOnline]);

    return (
        <div className={styles.page}>
            <NoInternetIcon className={styles.img} />
            <h1 className={styles.title}>No Internet Connection</h1>
            <p className={styles.description}>Please check your connection.</p>
            <Button
                className={styles.button}
                onClick={() => window.location.reload()}
                type="brand-primary-full"
            >
                Try Again
            </Button>
        </div>
    );
};
