import {
    useWidgetAllToggledEntities,
    useWidgetApis,
    useWidgetBlockingApiIds,
    useWidgetEntireContent,
    useWidgetModel,
    useWidgetNonBlockingApiIds,
} from 'extensions/widget/hooks/use-widget-hooks';
import { useWidgetPrintMode } from 'extensions/widget/hooks/use-widget-context-hooks';
import { useEffect, useMemo, useState } from 'react';
import { WidgetIDs } from 'extensions/widget/models/widget-ids';
import { useReportPlaces } from 'extensions/widget/hooks/use-poi-hooks';
import { getFeatureFlags } from 'core/flow-control';
import { getGoogleMapPermissions } from 'utils/get-google-map-permissions/get-google-map-permissions';

export enum PrintInfraEvents {
    readyForPrint = 'ready-for-print',
    mapFullyLoaded = 'widget-map-fully-loaded',
    externalApisLoaded = 'widget-external-apis-loaded',
}

export const fireWidgetFullyLoadedEvent = (type: WidgetIDs) => {
    console.log(`widget: ${type} has fully loaded`);
    const widgetFullyLoaded = new CustomEvent<string>(PrintInfraEvents.readyForPrint);
    window.dispatchEvent(widgetFullyLoaded);
};

export const useReadyForPrintEvent = () => {
    const apis = useWidgetApis();
    const widgetActivePOIsList = useWidgetAllToggledEntities();
    const widgetEntireContent = useWidgetEntireContent();
    const { entitiesWidgetShape: pois } = useReportPlaces();

    const { print, type } = useWidgetModel();
    const [mapLoaded, setMapLoaded] = useState(false);
    const [isExternalApisLoaded, setExternalApisLoaded] = useState(false);
    const isPrintMode = useWidgetPrintMode();
    const { enable_ready_for_print_event_ff } = getFeatureFlags();
    const shouldDisableReadyForPrintEvent =
        print?.manualDispatchLoadedEvent ||
        !print?.waitForEvent ||
        !enable_ready_for_print_event_ff ||
        !isPrintMode;
    const widgetHasMap = print?.widgetHasMap;
    const isWidgetHasExternalApis = print?.widgetHasExternalApiCalls;
    const blockingApis = useWidgetBlockingApiIds();
    const nonBlockingApisConfig = useWidgetNonBlockingApiIds();
    const allWidgetApisKeys = useMemo(
        () => [...blockingApis, ...nonBlockingApisConfig],
        [blockingApis, nonBlockingApisConfig],
    );
    const { hasDisabledMapsPermission } = getGoogleMapPermissions();

    const widgetHasFullContent = useMemo(() => {
        return allWidgetApisKeys.every((apiKey) => {
            /// for the old api infra of the widgets apis === undefined.
            const apiConfiguration = apis?.[apiKey];
            const shouldCallApi = apiConfiguration?.shouldCallApi;
            const isValidApi = widgetActivePOIsList.every(({ uid }) => {
                // if shouldCallApi not specified for the apiKey
                if (shouldCallApi === undefined) return true;
                // id specified 'shouldCallApi' test if the api can be triggered
                return shouldCallApi(pois[uid]);
            });
            // if the api shouldn't be triggered don't need to count it.
            if (!isValidApi) return true;

            const apiContent = widgetEntireContent?.[apiKey];
            if (!apiContent) {
                return false;
            }
            if (apiConfiguration?.combinedContent) {
                return apiContent.common !== undefined;
            }
            return widgetActivePOIsList.every(({ uid }) => {
                return apiContent[uid];
            });
        });
    }, [apis, allWidgetApisKeys, widgetActivePOIsList, widgetEntireContent, pois]);

    useEffect(() => {
        if (shouldDisableReadyForPrintEvent) {
            return;
        }

        const mapFullyLoadedListener = (event: CustomEvent<string>) => {
            setMapLoaded(true);
        };

        const externalApisLoadedListener = (event: CustomEvent<string>) => {
            setExternalApisLoaded(true);
        };

        if (widgetHasMap && !hasDisabledMapsPermission) {
            document.body.addEventListener(
                PrintInfraEvents.mapFullyLoaded as any,
                mapFullyLoadedListener,
            );
        }

        if (isWidgetHasExternalApis) {
            document.body.addEventListener(
                PrintInfraEvents.externalApisLoaded as any,
                externalApisLoadedListener,
            );
        }

        const isWidgetWithMapLoaded = widgetHasMap && !hasDisabledMapsPermission ? mapLoaded : true;
        const isWidgetWithExternalApisLoaded = isWidgetHasExternalApis
            ? isExternalApisLoaded
            : true;

        const shouldDispatchEvent =
            widgetHasFullContent && isWidgetWithMapLoaded && isWidgetWithExternalApisLoaded;

        if (shouldDispatchEvent) {
            fireWidgetFullyLoadedEvent(type);
        }

        return () => {
            if (widgetHasMap) {
                document.body.removeEventListener(
                    PrintInfraEvents.mapFullyLoaded as any,
                    mapFullyLoadedListener,
                );
            }

            if (isWidgetHasExternalApis && isExternalApisLoaded) {
                document.body.removeEventListener(
                    PrintInfraEvents.externalApisLoaded as any,
                    externalApisLoadedListener,
                );
            }
        };
    }, [
        isExternalApisLoaded,
        isWidgetHasExternalApis,
        mapLoaded,
        shouldDisableReadyForPrintEvent,
        type,
        allWidgetApisKeys.length,
        widgetEntireContent,
        widgetHasMap,
        widgetHasFullContent,
        hasDisabledMapsPermission,
    ]);
};
