import type { Place } from '@placer-ui/types';
import { signal } from '@preact/signals-react';
import { RecentEntitiesModel } from 'core/services/recent-entities-service/recent-entities-model';
import { CompareBarEntity } from 'features/insights/store/poi/poi-types';

export const isCompareStickyMode = signal(false);

export const filtersOverflow = signal(false);

export const inputFocusedSignal = signal<number | undefined>(undefined);

export const entityActionMenu = signal<{ isOpen?: boolean; elementIndex?: number }>({});

export const insightsCompareVenuesSignal = signal<CompareBarEntity[]>([]);

export const tempCompareEntitiesSignal = signal<Array<CompareBarEntity | Place | null>>([]);

export const errorModeSignal = signal(false);

export const upgradeYourPlanModalSignal = signal<{
    isOpen: boolean;
    venueName?: string;
    venueSubTitle?: string;
}>({
    isOpen: false,
});

export const editModeSignal = signal<{
    isActive: boolean;
}>({
    isActive: false,
});

export const openSuggestionSignal = signal<{
    isOpen: boolean;
    index: number;
    selectedEntity?: Place;
}>({
    isOpen: false,
    index: 0,
});

export const openQuickComparisonSignal = signal<{
    isOpen: boolean;
    selectedEntity?: CompareBarEntity;
}>({
    isOpen: false,
});

export const suggestionsOptions = signal<Record<number, RecentEntitiesModel[]>>({});
