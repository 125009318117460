import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

import { IconProps } from 'components/assets/Icons/Icons';

/* eslint-disable max-len */
const TagIconSvg = (props: CustomIconComponentProps | SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" {...props}>
        <g fill="none" fillRule="evenodd">
            <g>
                <g>
                    <g transform="translate(-30 -209) translate(30 209) translate(1)">
                        <path
                            fill="currentColor"
                            d="M1 0h14c.552 0 1 .448 1 1v5H0V1c0-.552.448-1 1-1z"
                        />
                        <path
                            stroke="#49565D"
                            strokeWidth=".75"
                            d="M1.2 17.586l-.127-.01c-.209-.032-.405-.145-.54-.328-.102-.142-.158-.312-.158-.487h0V1L15 .375l.625 16.386c0 .228-.092.434-.242.583-.149.15-.355.242-.583.242-.175 0-.345-.056-.487-.159h0L8 12.816l-6.313 4.611c-.184.135-.405.182-.614.15z"
                        />
                        <circle
                            cx="8"
                            cy="6"
                            r="2"
                            fill="#FFF"
                            stroke="#49565D"
                            strokeWidth=".75"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export const TagIcon = (props: IconProps) => <Icon component={TagIconSvg} {...props} />;
