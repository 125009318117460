/* eslint-disable max-len */
import React from 'react';

export const SgMarketingIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="20"
            viewBox="0 0 22 20"
            fill="none"
        >
            <path
                d="M0.5 8.51444C0.5 8.08033 0.780083 7.6958 1.19327 7.56265L14.1933 3.3732C14.8389 3.16512 15.5 3.64663 15.5 4.32499V15.675C15.5 16.3534 14.8389 16.8349 14.1933 16.6268L1.19327 12.4374C0.780084 12.3042 0.5 11.9197 0.5 11.4856V8.51444Z"
                fill="#E1E2FF"
                stroke="#5E63E5"
            />
            <path
                d="M3.1875 16.2344V12.865L8.40335 14.7216L8.25263 16.3278C8.20442 16.8416 7.7731 17.2344 7.257 17.2344H4.1875C3.63522 17.2344 3.1875 16.7867 3.1875 16.2344Z"
                stroke="#5E63E5"
            />
            <path
                d="M0.5 8.52759C0.5 8.09428 0.779073 7.71026 1.19121 7.57646L8.5 5.20364V14.6949L1.20172 12.4142C0.784249 12.2837 0.5 11.8971 0.5 11.4597V8.52759Z"
                fill="white"
                stroke="#5E63E5"
            />
            <path d="M18.1875 7.67188L20.7856 6.17187" stroke="#5E63E5" strokeLinecap="round" />
            <path d="M18.1875 10H21.1875" stroke="#5E63E5" strokeLinecap="round" />
            <path d="M18.1875 12.3281L20.7856 13.8281" stroke="#5E63E5" strokeLinecap="round" />
        </svg>
    );
};
