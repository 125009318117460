import type { Dictionary } from '@placer-ui/types';
import {
    fetchErrorMiddleware,
    getErrorOrStatus,
    parseJsonMiddleware,
} from 'API/common/fetch-middleware/fetch-middleware';
import { ResponseStatus, ResponseTypes } from 'API/common/types';
import { ERRORS } from 'API/common/constants';
import { isString } from 'lodash';

export interface EntityResultStatus {
    ref_id: string;
    result: 'SUCCESS' | 'FAILURE';
    status?: number;
    error?: ResponseTypes;
}

export interface BaseDataType {
    status?: string;
    statuses?: EntityResultStatus[];
}

function getDataFromResponse(response: Response): Promise<Dictionary<any>> {
    if (!response.status || response.status === 204) {
        return Promise.resolve({});
    }

    return response.json().then(
        (json) => json,
        () => Promise.resolve({}),
    );
}

interface RequestDataParams<BodyType> {
    targetUrl: string;
    method: string;
    body?: BodyType;
    headers?: Dictionary<string>;
    token: string;
    signal?: AbortSignal;
    fetchID?: string;
    title?: string;
}

export function requestData<DataType extends BaseDataType, BodyType>({
    targetUrl,
    method,
    body,
    headers: customHeaders = {},
    token,
    signal,
}: RequestDataParams<BodyType>) {
    const defaultHeaders: { 'Content-Type': string; Authorization?: string } = {
        'Content-Type': 'application/json',
    };

    if (token) {
        defaultHeaders['Authorization'] = `Token ${token}`;
    }

    const headers = {
        ...defaultHeaders,
        ...customHeaders,
    };
    const fetchConfig: Partial<RequestInit> = {
        method,
        headers,
        body: body && method !== 'GET' ? JSON.stringify(body) : undefined,
        cache: 'no-cache',
        signal,
    };

    return fetch(targetUrl, fetchConfig)
        .then(fetchErrorMiddleware)
        .then(parseJsonMiddleware, async (response: Response) => {
            return Promise.reject(
                getErrorOrStatus(response.status, await getDataFromResponse(response)),
            );
        })
        .then(
            (response: { data: DataType }) => {
                if (response.data) {
                    const { status, statuses } = response.data;
                    if (isString(status)) {
                        const error = getErrorOrStatus(500, {
                            error: status,
                        });
                        return Promise.reject({ common: error });
                    }

                    if (statuses) {
                        const errors = statuses.reduce<Dictionary<ResponseStatus>>(
                            (errorAcc, result) => {
                                if (result.status) {
                                    errorAcc[result.ref_id] = getErrorOrStatus(
                                        result.status,
                                        result,
                                    );
                                }
                                return errorAcc;
                            },
                            {},
                        );

                        if (Object.keys(errors).length > 0) {
                            return Promise.reject(errors);
                        }
                    }
                }

                return response;
            },
            (error: ResponseStatus | string) => {
                if (signal?.aborted) {
                    return Promise.reject({
                        common: {
                            type: ERRORS.ABORT_ERROR,
                        },
                    });
                }
                return Promise.reject({ common: error });
            },
        );
}
