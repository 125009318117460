import { userAPI } from 'API/user-api';
import { getAuthError } from 'features/authentication/utils/get-auth-error';
import type {
    ResetPasswordTokenCheckParameters,
    SetNewPasswordParameters,
} from 'store/auth/actions/auth-actions/auth-action-types';
import { history } from 'router/history';

export const INVALID_SAME_PASSWORD = 'INVALID_SAME_PASSWORD';
const INVALID_RESET_PASSWORD_TOKEN = 'INVALID_RESET_PASSWORD_TOKEN';

export const authCheckResetPasswordToken = async (userData: ResetPasswordTokenCheckParameters) => {
    try {
        const response = await userAPI.checkResetPasswordToken(userData);

        if (response.ok) {
            const { data } = await response.json();

            switch (data.status) {
                case 'enabled':
                    break;
                case 'disabled':
                case 'expired':
                case 'used':
                    history.push('/expired-reset-password');
                    break;
            }
        } else {
            return await getAuthError(response);
        }
    } catch (error) {
        console.error(error);
        return error;
    }
};

export const authSetNewPassword = async (userData: SetNewPasswordParameters) => {
    try {
        const response = await userAPI.setNewPassword(userData);
        if (!response.ok) {
            const error = await getAuthError(response);

            if (error.message === INVALID_SAME_PASSWORD) {
                return error;
            } else if (error.message === INVALID_RESET_PASSWORD_TOKEN) {
                history.push('/expired-reset-password');
            }
        }
    } catch (error) {
        console.error(error);
        return error;
    }
};
