import { createSelector } from 'reselect';

import { ErrorContent, Widget } from './widget-constants';
import { allVenuesDataSelector } from 'features/insights/store/selectors/insights-selectors';
import type { Dictionary } from '@placer-ui/types';
import { ERRORS } from 'API/common/constants';
import { AppState } from 'store/store';

export interface WidgetSelectorProps {
    id: string;
}

export const widgetSelector = (state: AppState, { id }: WidgetSelectorProps): Widget => {
    return id ? state.widgets[id] : {};
};

export interface WidgetsSelectorProps {
    ids: string[];
}

export const selectWidgetsByIds = (state: AppState, props: WidgetsSelectorProps): Widget[] => {
    return props.ids.map((id) => state.widgets[id] || ({} as Widget));
};

export const selectWidgets = (state: AppState) => {
    return state.widgets;
};

export const selectWidgetById = (state: AppState, props: { id: string }) => {
    const widgets = selectWidgets(state);

    return widgets[props.id] || ({} as Widget);
};

export const contentSelector = () => {
    return createSelector(widgetSelector, (widget: Widget) => widget?.content);
};

export const venuesSelectorSelector = () =>
    createSelector(widgetSelector, (widget: Widget) => {
        return widget && widget.venueSelector && Object.keys(widget.venueSelector).length
            ? widget.venueSelector
            : undefined;
    });

export const filteredVenuesSelector = () =>
    createSelector(
        allVenuesDataSelector,
        venuesSelectorSelector(),
        (venues, venuesSelector: Dictionary<boolean> | undefined) => {
            return venues?.filter((venue) =>
                venuesSelector && venuesSelector[venue.uid] !== undefined
                    ? venuesSelector[venue.uid]
                    : true,
            );
        },
    );

export type ContentWithVenueData = (object | ErrorContent) & {
    poiColor: string;
    poiName: string;
    poiAddress: string;
};

export const filteredContentSelectorByVenues = () =>
    createSelector(contentSelector(), filteredVenuesSelector(), (content, venues) => {
        return venues.map((venue) => ({
            poiName: venue.name,
            poiAddress: venue.address,
            poiColor: venue.customData.color.regular,
            ...(content ? content.default?.[venue.uid] : undefined),
        }));
    });

export const fastFetchFilters = () =>
    createSelector(widgetSelector, (widget) => widget?.fetchFilter);
export const fastVisualFilters = () =>
    createSelector(widgetSelector, (widget) => widget?.visualFilter);

export const selectFastFilters = () =>
    createSelector(fastFetchFilters(), fastVisualFilters(), (fetchFilters, visualFilters) => {
        return {
            ...fetchFilters,
            ...visualFilters,
        };
    });

const selectWidgetPOIsList = (_: unknown, props: { widgetPOIsList: string[] }) => {
    return props.widgetPOIsList;
};

export const errorContentSelector = () =>
    createSelector(contentSelector(), selectWidgetPOIsList, (content, widgetPOIsList) => {
        if (!content) return;

        for (let poiID of widgetPOIsList) {
            if ((content[poiID] as ErrorContent | undefined)?.isError) {
                return content[poiID] as ErrorContent;
            }
        }
    });

export const createSelectWidgetsContentIsLoading = (
    selectEntitiesIds: (state: any) => (number | string)[],
    takeInsufficientDataIntoAccount?: boolean,
) =>
    createSelector(selectEntitiesIds, selectWidgetsByIds, (allIds, widgets) => {
        if (!widgets.length || !allIds.length) return true;

        const widgetsWithLoadingInProgress: Widget[] = [];
        const widgetsWithInsufficientData: Widget[] = [];

        widgets.forEach((widget) => {
            const numberOfPoisWithInsufficientDataPois =
                getWidgetPoisIdsWithInsufficientData(widget).length;
            if (numberOfPoisWithInsufficientDataPois) {
                widgetsWithInsufficientData.push(widget);
                return;
            }

            const widgetContentPOICount = Object.keys(widget.content || {}).length;
            const isLoading = widgetContentPOICount < allIds.length;
            if (isLoading) {
                widgetsWithLoadingInProgress.push(widget);
            }
        });

        const totalNumberOfWidgets = widgets.length;
        const numberOfLoadingWidgets = widgetsWithLoadingInProgress.length;
        const numberOfWidgetsWithInsufficientData = widgetsWithInsufficientData.length;

        if (takeInsufficientDataIntoAccount) {
            const atLeastOneWidgetIsStillLoading = numberOfLoadingWidgets > 0;
            const allWidgetsAreEitherLoadingOrHaveInsufficientData =
                numberOfLoadingWidgets + numberOfWidgetsWithInsufficientData ===
                totalNumberOfWidgets;

            return (
                atLeastOneWidgetIsStillLoading && allWidgetsAreEitherLoadingOrHaveInsufficientData
            );
        }

        return numberOfLoadingWidgets === totalNumberOfWidgets;
    });

export const selectAllNonEmptyWidgetsHaveInsufficientData = createSelector(
    selectWidgetsByIds,
    (widgets) => {
        if (!widgets.length) return true;

        const widgetsWithContent = widgets.filter((widget) => !!widget.content);

        if (widgetsWithContent.length > 0) {
            return widgetsWithContent.every((widget) => {
                if (!Object.keys(widget.content || {}).length) {
                    return true;
                }
                const widgetPoisWithInsufficientData = getWidgetPoisIdsWithInsufficientData(widget);
                return widgetPoisWithInsufficientData.length > 0;
            });
        }
        return false;
    },
);

export const selectPoisIdsWithInsufficientData = createSelector(selectWidgetsByIds, (widgets) => {
    const result: string[] = [];
    for (let widget of widgets) {
        const poisWithInsufficientData = getWidgetPoisIdsWithInsufficientData(widget);
        for (let poiId of poisWithInsufficientData) {
            if (!result.includes(poiId)) {
                result.push(poiId);
            }
        }
    }
    return result;
});

const getWidgetPoisIdsWithInsufficientData = (widget: Widget): string[] => {
    if (!widget || !widget.content) {
        return [];
    }

    const content = widget.content;

    return Object.keys(content).reduce<string[]>((poisWithInsufficientData, apiId) => {
        const apiContent = content[apiId];
        const poiIds = Object.keys(apiContent).filter((poiId) => {
            const poiContent = apiContent[poiId];
            if (poiContent) {
                const poiContentError = poiContent as ErrorContent;
                return poiContentError.type === ERRORS.INSUFFICIENT_DATA;
            }

            return false;
        });
        poisWithInsufficientData.push(...poiIds);
        return poisWithInsufficientData;
    }, []);
};

export const selectAllWidgetPOIsHaveContent = createSelector(widgetSelector, (widget) => {
    const { content } = widget;

    if (!content) {
        return false;
    }

    return Object.keys(content).every((poiKey: string) => {
        const currentPoi = content[poiKey] || false;

        return Object.keys(currentPoi)?.length ?? false;
    });
});
