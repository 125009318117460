import { PlaceOverlay } from 'ui-components/google-map-layers/types/google-map-layers-types';
import { PlaceWithFilters } from 'features/insights/store/poi/poi-types';

export const getComplexPlaceOverlay = (placeOverlay: PlaceWithFilters): PlaceOverlay => ({
    ...placeOverlay,
    overlayID: 'complex',
    isActive: true,
});

export const getNearbyPlaceOverlay = (placeOverlay: PlaceWithFilters): PlaceOverlay => ({
    ...placeOverlay,
    overlayID: 'nearby',
    isActive: true,
});

export const getPoiPlaceOverlay = (placeOverlay: PlaceWithFilters): PlaceOverlay => ({
    ...placeOverlay,
    overlayID: 'poi',
    isActive: true,
});

export const getIsOverlaySelected = (overlays: PlaceOverlay[] | undefined) =>
    !!overlays?.some((overlay) => overlay.isSelected);

export const getActiveOverlay = (overlays: PlaceOverlay[] | undefined) =>
    overlays?.find((overlay) => overlay.isActive);
