/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

export type AllSitesIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type AllSitesIconProps = SVGProps<SVGSVGElement> & AllSitesIconUniqueProps;

export const AllSitesIcon = function AllSitesIcon({
    width = 16,
    height = 16,
    ...props
}: AllSitesIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.0225 16.1455H0.0224609V15.3955H16.0225V16.1455Z"
                fill="#49565D"
            />
            <rect
                x="1.87061"
                y="1.02499"
                width="9.08035"
                height="14.7455"
                stroke="#49565D"
                strokeWidth="0.75"
            />
            <rect
                x="10.9775"
                y="5.27702"
                width="3.21794"
                height="10.4934"
                fill="#D0D0D0"
                stroke="#49565D"
                strokeWidth="0.75"
            />
            <mask id="path-4-inside-1_31_4340" fill="white">
                <rect x="3.25586" y="2.69516" width="2.70242" height="2.70308" rx="0.5" />
            </mask>
            <rect
                x="3.25586"
                y="2.69516"
                width="2.70242"
                height="2.70308"
                rx="0.5"
                stroke="#49565D"
                strokeWidth="1.5"
                mask="url(#path-4-inside-1_31_4340)"
            />
            <mask id="path-5-inside-2_31_4340" fill="white">
                <rect x="3.25586" y="7.04623" width="2.70242" height="2.70308" rx="0.5" />
            </mask>
            <rect
                x="3.25586"
                y="7.04623"
                width="2.70242"
                height="2.70308"
                rx="0.5"
                stroke="#49565D"
                strokeWidth="1.5"
                mask="url(#path-5-inside-2_31_4340)"
            />
            <mask id="path-6-inside-3_31_4340" fill="white">
                <rect x="3.25586" y="11.3971" width="2.70242" height="2.70308" rx="0.5" />
            </mask>
            <rect
                x="3.25586"
                y="11.3971"
                width="2.70242"
                height="2.70308"
                rx="0.5"
                stroke="#49565D"
                strokeWidth="1.5"
                mask="url(#path-6-inside-3_31_4340)"
            />
            <mask id="path-7-inside-4_31_4340" fill="white">
                <rect x="6.86328" y="2.69516" width="2.70242" height="2.70308" rx="0.5" />
            </mask>
            <rect
                x="6.86328"
                y="2.69516"
                width="2.70242"
                height="2.70308"
                rx="0.5"
                stroke="#49565D"
                strokeWidth="1.5"
                mask="url(#path-7-inside-4_31_4340)"
            />
            <mask id="path-8-inside-5_31_4340" fill="white">
                <rect x="6.86328" y="7.04623" width="2.70242" height="2.70308" rx="0.5" />
            </mask>
            <rect
                x="6.86328"
                y="7.04623"
                width="2.70242"
                height="2.70308"
                rx="0.5"
                stroke="#49565D"
                strokeWidth="1.5"
                mask="url(#path-8-inside-5_31_4340)"
            />
            <mask id="path-9-inside-6_31_4340" fill="white">
                <rect x="6.86328" y="11.3971" width="2.70242" height="2.70308" rx="0.5" />
            </mask>
            <rect
                x="6.86328"
                y="11.3971"
                width="2.70242"
                height="2.70308"
                rx="0.5"
                stroke="#49565D"
                strokeWidth="1.5"
                mask="url(#path-9-inside-6_31_4340)"
            />
        </svg>
    );
};
AllSitesIcon.__tags = ['building', 'apartments'] as string[];
