/* eslint-disable max-len */
import type { PlaceTypeEntities } from '@placer-ui/types';
import {
    SearchFilters,
    SearchPoiContextState,
} from 'features/poi-selection-map/poi-selection-menu/menu-items/search-menu-item/types/search-poi-types';
import { PoiEntity } from 'ui-components/google-map-layers/types/google-map-layers-types';

export const initialPoiSearchContextState: SearchPoiContextState = {
    searchResults: [],
    currentSearchTerm: '',
    searchApiSuggestions: undefined,
    selectedSearchSuggestion: undefined,
    isLoadingPois: false,
    isLoadingSearchSuggestions: false,
    hasError: false,
    searchApiFilters: undefined,
    searchId: '',
    setSearchResults: () => {},
    setIsLoadingPois: () => {},
    setIsLoadingSearchSuggestions: () => {},
    setHasError: () => {},
    setCurrentSearchTerm: (val: string) => {},
    setSearchApiSuggestions: (suggestions?: PlaceTypeEntities[]) => {},
    setSelectedSearchSuggestion: (suggestion: PoiEntity | undefined) => {},
    setSearchApiFilters: (searchFilters: SearchFilters) => {},
    setSplunkSearchId: (searchId: string) => {},
};

export const defaultSearchApiFilters: SearchFilters = {
    type: 'Property',
    term: '',
    restrictions: {
        enable_custom_pois: true,
        enable_verified_pois: true,
        enable_unverified_pois: true,
        enable_closed_pois: true,
        enable_available_pois: true,
        enable_nearby_activity_pois: true,
    },
    categories: [],
};
