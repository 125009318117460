import { CardDetails } from 'features/void-analysis/sections/empty-space/types/method-card';
import driveTimeMethodMap from 'shared/images/void-analysis/empty-space/drive-time-method-map.png';
import tradeAreaMethodMap from 'shared/images/void-analysis/empty-space/trade-area-method-map.png';

export const methodsDetails: CardDetails[] = [
    {
        key: 'tta',
        title: 'True Trade Area',
        explainText: 'Prospective tenants list will be based on the\nproperty true trade area.',
        imageSrc: tradeAreaMethodMap,
        elevioTitle: '0',
    },
    {
        key: 'drive_time_ta',
        title: 'Drive Time',
        explainText: 'Prospective tenants list will be based\n on drive time to the property.',
        imageSrc: driveTimeMethodMap,
        elevioTitle: '0',
    },
    {
        key: 'nearby_entity_ta',
        title: 'Nearby Property True Trade Area',
        explainText:
            'Prospective tenants list will be based on a nearby\nproperty true trade area.',
        imageSrc: tradeAreaMethodMap,
        elevioTitle: '0',
    },
    {
        key: 'tta_nearby_combination',
        title: 'True Trade Area',
        explainText: 'Prospective tenants list will be based on true trade area for',
        imageSrc: tradeAreaMethodMap,
        elevioTitle: '0',
    },
];
