/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

export type ArrowSignIconUniqueProps = {
    width?: number | string;
    height?: number | string;
    color?: string;
};

type ArrowSignIconProps = SVGProps<SVGSVGElement> & ArrowSignIconUniqueProps;

export const ArrowSignIcon = function ArrowSignIcon({
    width = 10,
    height = 10,
    ...props
}: ArrowSignIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M8.53554 7.6538C8.29008 7.6538 8.08594 7.47693 8.0436 7.24368L8.03554 7.1538L8.03633 2.70469L1.81803 8.92157C1.62277 9.11683 1.30619 9.11683 1.11092 8.92157C0.937358 8.748 0.918073 8.47858 1.05307 8.28371L1.11092 8.21446L7.33064 1.99617L2.87869 1.99695C2.63323 1.99695 2.42908 1.82007 2.38675 1.58682L2.37869 1.49695C2.37869 1.25149 2.55557 1.04734 2.78882 1.005L2.87869 0.996948H8.53554L8.5526 0.997238C8.56966 0.997816 8.58669 0.999263 8.60362 1.00158L8.53554 0.996948C8.57349 0.996948 8.61046 1.00118 8.64599 1.00919C8.65879 1.01211 8.67178 1.01558 8.68463 1.01959C8.69958 1.02423 8.71381 1.02942 8.72773 1.03522C8.7403 1.04043 8.75312 1.04639 8.76573 1.05293C8.78 1.06039 8.79373 1.06839 8.80702 1.077C8.81562 1.08251 8.82425 1.08849 8.83273 1.09477C8.85003 1.10764 8.86627 1.12135 8.88157 1.13602C8.88377 1.13813 8.88645 1.14074 8.8891 1.1434L8.89716 1.15165C8.91158 1.16674 8.92505 1.18273 8.9375 1.19953L8.8891 1.1434C8.91462 1.16892 8.93681 1.19652 8.95566 1.22565C8.96411 1.23876 8.9721 1.25249 8.97944 1.26661C8.9861 1.27937 8.99206 1.29219 8.99746 1.3052C9.00307 1.31868 9.00826 1.33292 9.01281 1.34744C9.01691 1.36072 9.02038 1.3737 9.02332 1.38678C9.02484 1.39331 9.02624 1.40017 9.02749 1.40707L9.03092 1.42887C9.03323 1.44581 9.03468 1.46284 9.03526 1.47989L9.03554 1.49695V7.1538C9.03554 7.42995 8.81169 7.6538 8.53554 7.6538Z"
                fill="currentColor"
            />
        </svg>
    );
};
ArrowSignIcon.__tags = ['arrow', 'sign', 'up'] as string[];
