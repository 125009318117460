import { combineReducers } from '@reduxjs/toolkit';

import { configurationAttributesReducer } from './attributes/attributes-reducer';
import { configurationCategoriesReducer } from './categories/categories-reducer';
import { configurationReportOptionsReducer } from 'store/configuration/report-options/report-options-reducer';
import { configurationReportDateOptionsReducer } from 'store/configuration/report-date-options/report-date-options-reducer';
import { chainsByCategoriesReducer } from 'store/configuration/chains-by-categories/slice';
import { configurationReportAccessReducer } from 'store/configuration/report-access/report-access-reducer';
import { experianMosaicReducer } from 'store/configuration/experian-mosaic/slice';
import { shortVisitsConfigReducer } from 'store/configuration/short-visits-config/short-visits-config-slice';

export const reducer = combineReducers({
    attributes: configurationAttributesReducer,
    categories: configurationCategoriesReducer,
    reportOptions: configurationReportOptionsReducer,
    reportDateOptions: configurationReportDateOptionsReducer,
    ...chainsByCategoriesReducer,
    reportAccess: configurationReportAccessReducer,
    experianMosaic: experianMosaicReducer,
    shortVisitsConfig: shortVisitsConfigReducer,
});

export const configurationReducer = {
    configuration: reducer,
};
