import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { merge, isEqual } from 'lodash/fp';
import classNames from 'classnames';
import styles from './table-with-persistent-header.module.scss';

import { TableProps } from 'ui-components/table/table';
import { BaseRowDataType } from 'ui-components/table/types';
import { Loading } from 'ui-components/loading/loading-component';
import {
    FilterValue,
    Key,
    SorterResult,
    TableCurrentDataSource,
    TablePaginationConfig,
} from 'antd/lib/table/interface';
import { ANTD_SORTING } from 'ui-components/table/data-table/pagination/constants/sorting';
import { ColumnsType } from 'antd/es/table/interface';

type TableWithPersistentHeaderClassNamesMap = {
    container?: string;
    tableBody?: string;
    tableHeader?: string;
    loading?: string;
};

export type TableWithPersistentHeaderProps<RowDataType> = Pick<
    TableProps<RowDataType>,
    'classNamesMap' | 'onChange' | 'tableLayout'
> & {
    columns?: ColumnsType<RowDataType>;
    loading: boolean;
    tableWithPersistentHeaderClassNamesMap?: TableWithPersistentHeaderClassNamesMap;
    children?: ReactNode;
};

type TableType = 'header' | 'body';

export const TableWithPersistentHeader = <RowDataType extends BaseRowDataType>(
    tableProps: TableWithPersistentHeaderProps<RowDataType>,
) => {
    const [columns, setColumns] = useState<ColumnsType<RowDataType> | undefined>(
        tableProps.columns,
    );
    const tableHeaderRef = useRef<HTMLDivElement>(null);
    const [tableHeaderHeight, setTableHeaderHeight] = useState<number>(0);
    const isLoadingContent = tableProps.loading;
    const classNamesMap = tableProps.tableWithPersistentHeaderClassNamesMap;
    const { children } = tableProps;

    const tableHeaderClassNamesMap = useMemo(
        () =>
            merge(tableProps.classNamesMap || {}, {
                wrapper: styles.tableHeaderWrapper,
            }),
        [tableProps.classNamesMap],
    );

    useEffect(() => {
        if (tableHeaderRef.current) {
            setTableHeaderHeight(tableHeaderRef.current.clientHeight);
        }
    }, []);

    useEffect(() => {
        if (tableProps.columns && columns) {
            const tablePropsKeys = tableProps.columns.map<Key | undefined>((column) => column.key);
            const columnKeys = columns.map<Key | undefined>((column) => column.key);
            if (!isEqual(tablePropsKeys, columnKeys)) {
                setColumns(tableProps.columns);
            }
        }
    }, [columns, tableProps.columns]);

    const onSortByColumn = useCallback(
        (
            pagination: TablePaginationConfig,
            filters: Record<string, FilterValue | null>,
            sorter: SorterResult<RowDataType> | SorterResult<RowDataType>[],
            extra: TableCurrentDataSource<RowDataType>,
        ) => {
            if (!Array.isArray(sorter) && columns) {
                const columnsCopy = [...columns];
                columnsCopy.forEach((column) => {
                    if (column.key === sorter.columnKey) {
                        column.defaultSortOrder = ANTD_SORTING.DESCENDING;
                        column.sortOrder = sorter.order;
                    } else {
                        column.defaultSortOrder = undefined;
                        column.sortOrder = undefined;
                    }
                });
                setColumns(columnsCopy);
            }
            tableProps.onChange && tableProps.onChange(pagination, filters, sorter, extra);
        },
        [columns, tableProps],
    );

    const tableHeaderProps = useMemo(
        () => ({
            loading: false,
            pagination: false,
            onChange: onSortByColumn,
            dataSource: [],
            classNamesMap: tableHeaderClassNamesMap,
            tableLayout: tableProps.tableLayout,
        }),
        [onSortByColumn, tableHeaderClassNamesMap, tableProps.tableLayout],
    );

    const tableBodyProps = {
        toolbarWrapperMarginBottom: false,
        columns,
        tableLayout: tableProps.tableLayout,
    };

    const getTableChild = (type: TableType) => {
        if (React.isValidElement(children)) {
            return React.Children.only(
                React.cloneElement(children, type === 'header' ? tableHeaderProps : tableBodyProps),
            );
        }
    };

    return (
        <div className={classNames(styles.container, classNamesMap?.container)}>
            <div
                className={classNames(styles.tableBody, classNamesMap?.tableBody, {
                    [styles.isLoading]: isLoadingContent,
                })}
                style={{ paddingTop: tableHeaderHeight }}
            >
                <Loading
                    show={isLoadingContent}
                    className={classNames(styles.loader, classNamesMap?.loading)}
                >
                    {getTableChild('body')}
                </Loading>
            </div>

            <div
                className={classNames(styles.tableHeader, classNamesMap?.tableHeader)}
                ref={tableHeaderRef}
            >
                {getTableChild('header')}
            </div>
        </div>
    );
};
