import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTopVenuesByChainId } from '../get-search-top-venues';
import type { Chain } from '@placer-ui/types';

export const setChainsSearchActiveChain = createAsyncThunk(
    'chains/set-search-active-chain',
    (chain: Chain, { dispatch }) => {
        chain.id &&
            dispatch(
                getTopVenuesByChainId({
                    chainId: chain.id,
                    chainType: chain.type,
                }),
            );
        return chain;
    },
);
