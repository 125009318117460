import React from 'react';
import classNames from 'classnames';

import styles from './entity-type-dropdown-option.module.scss';

type EntityTypeDropdownOptionProps = {
    isSelected: boolean;
    label: string;
    selectEntityType: () => void;
};

export const EntityTypeDropdownOption = ({
    isSelected,
    label,
    selectEntityType,
}: EntityTypeDropdownOptionProps) => {
    const optionClassNames = classNames(styles.option, {
        [styles.selectedOption]: isSelected,
    });

    return (
        <div className={optionClassNames} onClick={selectEntityType}>
            {label}
        </div>
    );
};
