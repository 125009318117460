import React, { SVGProps } from 'react';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

export const GoToIconSvg = (props: CustomIconComponentProps | SVGProps<SVGSVGElement>) => (
    <svg {...props} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17.5 7.5V0.5L10.5 0.5"
            stroke="#49565D"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M7.5 10.5L17 1" stroke="#49565D" strokeLinecap="round" />
        <path
            d="M17.5 13L17.5 17.5H0.5V0.5H5"
            stroke="#49565D"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
