/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

type DirectionIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type DirectionIconProps = SVGProps<SVGSVGElement> & DirectionIconUniqueProps;

export const DirectionIcon = function DirectionIcon({
    width = 18,
    height = 18,
    ...props
}: DirectionIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_25889_34392)">
                <path
                    d="M11 7.75H7C6.4 7.75 6 8.15 6 8.75V12.75H7V9.05C7 8.88431 7.13431 8.75 7.3 8.75H11V11.25L14 8.25L11 5.25V7.75Z"
                    fill="currentColor"
                />
                <rect
                    x="0.302566"
                    y="9"
                    width="12.3"
                    height="12.3"
                    rx="0.5"
                    transform="rotate(-45 0.302566 9)"
                    stroke="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_25889_34392">
                    <rect width="18" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
DirectionIcon.__tags = [] as string[];
