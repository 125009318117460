import { createSelector } from '@reduxjs/toolkit';

import { WidgetPOIsDictionary } from 'extensions/widget/models/widget-poi-provider-type';
import { selectChainsReportEntities } from '../chains-report-chains-selectors-factory';
import { TagPOI } from 'core/entities';
import { selectChainsReportOptions } from 'features/chains/store/chains-report-search-store/selectors/select-chains-search-section';

export const createSelectChainsEntitiesAPIModel = (
    selectEntities: typeof selectChainsReportEntities,
) =>
    createSelector(selectEntities, selectChainsReportOptions, (entities, reportOptions) => {
        return Object.keys(entities).reduce<WidgetPOIsDictionary>((mappedEntities, entityIdKey) => {
            const value = entities[entityIdKey];
            if (!value) {
                return mappedEntities;
            }

            const entityReportOption = reportOptions.entitiesData.find(
                (option) => option.entity_id === value.originalEntity.id,
            );

            mappedEntities[entityIdKey] = {
                poiId: value.originalEntity.id,
                type: value.originalEntity.type,
                name: value.originalEntity.name,
                address: TagPOI.getLocationLabel(value.originalEntity),
                shortAddress: TagPOI.getLocationLabel(value.originalEntity),
                filters: {
                    ...value.filters,
                },
                uid: value.uid,
                color: value.color,
                subCategory: value.originalEntity.sub_category,
                ...(entityReportOption
                    ? { defaultProfileName: entityReportOption?.default_profile_name }
                    : {}),
            };

            return mappedEntities;
        }, {});
    });
