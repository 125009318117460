import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'API';
import { StarredReport } from 'core/entities';

export const saveStarredReport = createAsyncThunk(
    'starred-reports/save',
    async (report: StarredReport, { dispatch, rejectWithValue }) => {
        try {
            await API.starReport.save(report);

            return report;
        } catch {
            return rejectWithValue('cannot save');
        }
    },
);
