import { createContext } from 'react';
import { PlacerError } from './placer-error';
import type { PlacerErrorParams } from './errors-manager-types';

type ErrorsContextType = {
    errorsList: PlacerError[];
    setErrorsList: React.Dispatch<React.SetStateAction<PlacerError[]>>;
    page: PlacerErrorParams['page'];
    tab: PlacerErrorParams['tab'];
    section: PlacerErrorParams['section'];
    subSection: PlacerErrorParams['subSection'];
};

export const ErrorsContext = createContext<ErrorsContextType>({
    errorsList: [],
    setErrorsList: () => {},
    page: '',
    tab: '',
    section: '',
    subSection: '',
});
