import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAttributes } from 'store/configuration/attributes/actions/get-attributes/get-attributes';

import { ConfigurationAttributesState, createConfigurationAttributesState } from 'store/configuration/attributes/models';

const initialState = createConfigurationAttributesState();

export const attributesSlice = createSlice({
    name: 'attributes',
    initialState,
    reducers: {
        setAttributesData: (state: ConfigurationAttributesState,
            action: PayloadAction<ConfigurationAttributesState>) => {
            state.states = action.payload.states;
            state.filters = action.payload.filters;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAttributes.fulfilled, (state, action) => {
            return action.payload;
        });
    },
});

export const configurationAttributesActions = attributesSlice.actions;
