/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

export type LogoutIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type LogoutIconProps = SVGProps<SVGSVGElement> & LogoutIconUniqueProps;

export const LogoutIcon = function LogoutIcon({
    width = 18,
    height = 18,
    ...props
}: LogoutIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_1919_19079)">
                <path
                    d="M3.5 3C3.22386 3 3 3.22386 3 3.5V14.5C3 14.7761 3.22386 15 3.5 15H10.5C10.7761 15 11 14.7761 11 14.5V11H9C8.44772 11 8 10.5523 8 10V8C8 7.44772 8.44772 7 9 7H11V3.5C11 3.22386 10.7761 3 10.5 3H3.5Z"
                    fill="#D0D0D0"
                />
                <path
                    d="M1 1H13V4H14V1C14 0.447715 13.5523 0 13 0H1C0.447715 0 0 0.447716 0 1V17C0 17.5523 0.447716 18 1 18H13C13.5523 18 14 17.5523 14 17V15H13V17H1V1Z"
                    fill="currentColor"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.1431 6.14645C14.3383 5.95118 14.6549 5.95118 14.8502 6.14645L17.3502 8.64645C17.5455 8.84171 17.5455 9.15829 17.3502 9.35355L14.8502 11.8536C14.6549 12.0488 14.3383 12.0488 14.1431 11.8536C13.9478 11.6583 13.9478 11.3417 14.1431 11.1464L15.7895 9.5H10.4966C10.2205 9.5 9.99664 9.27614 9.99664 9C9.99664 8.72386 10.2205 8.5 10.4966 8.5H15.7895L14.1431 6.85355C13.9478 6.65829 13.9478 6.34171 14.1431 6.14645Z"
                    fill="#49565D"
                />
            </g>
            <defs>
                <clipPath id="clip0_1919_19079">
                    <rect width="18" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
LogoutIcon.__tags = ['log sign exit door leave out logout'] as string[];
