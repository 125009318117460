import { createAction } from '@reduxjs/toolkit';

import { TagEntityModel } from 'core/entities';

const setChainsSearchSearchOpenStatusAction = 'industry/set-search-open-status';
export const setChainsSearchSearchOpenStatus = createAction<boolean>(
    setChainsSearchSearchOpenStatusAction,
);

const setChainsSearchSelectedCategoryAction = 'industry/set-search-selected-category';
export const setChainsSearchSelectedCategory = createAction<string>(
    setChainsSearchSelectedCategoryAction,
);

const setChainsSearchSelectedRegionAction = 'industry/set-search-selected-region';
export const setChainsSearchSelectedRegion = createAction<string>(
    setChainsSearchSelectedRegionAction,
);

const setChainsSearchResultsAction = 'industry/set-search-results';
export const setChainsSearchResults = createAction<TagEntityModel[]>(setChainsSearchResultsAction);

const setChainsSearchAutoCompleteValueAction = 'industry/set-search-autocomplete-value';
export const setChainsSearchAutoCompleteValue = createAction<string>(
    setChainsSearchAutoCompleteValueAction,
);

const setChainsSearchMyTagsQueryValueAction = 'industry/set-search-my-tags-query-value';
export const setChainsSearchMyTagsQueryValue = createAction<string>(
    setChainsSearchMyTagsQueryValueAction,
);

const setChainsSearchCompanyTagsQueryValueAction = 'industry/set-search-company-tags-query-value';
export const setChainsSearchCompanyTagsQueryValue = createAction<string>(
    setChainsSearchCompanyTagsQueryValueAction,
);

const setMyTagsSearchResultsPotentiallyMoreResultsExistAction =
    'industry/set-my-tags-results-has-more';
export const setMyTagsSearchResultsPotentiallyMoreResultsExist = createAction<boolean>(
    setMyTagsSearchResultsPotentiallyMoreResultsExistAction,
);

const setCompanyTagsSearchResultsPotentiallyMoreResultsExistAction =
    'industry/set-company-tags-results-has-more';
export const setCompanyTagsSearchResultsPotentiallyMoreResultsExist = createAction<boolean>(
    setCompanyTagsSearchResultsPotentiallyMoreResultsExistAction,
);

const setChainsSearchResultsPotentiallyMoreResultsExistAction =
    'industry/set-search-results-has-more';
export const setChainsSearchResultsPotentiallyMoreResultsExist = createAction<boolean>(
    setChainsSearchResultsPotentiallyMoreResultsExistAction,
);

const setChainsSearchResultsAreLoadingAction = 'industry/set-search-results-are-loading';
export const setChainsSearchResultsAreLoading = createAction<boolean>(
    setChainsSearchResultsAreLoadingAction,
);

const setChainsAutoCompleteSearchResultsAreLoadingAction =
    'industry/set-autocomplete-search-results-are-loading';
export const setChainsAutoCompleteSearchResultsAreLoading = createAction<boolean>(
    setChainsAutoCompleteSearchResultsAreLoadingAction,
);

const setSelectedChainsEntityModelsAction = 'industry/set-selected-chains-entity-models';
export const setSelectedChainsEntityModels = createAction<TagEntityModel[]>(
    setSelectedChainsEntityModelsAction,
);

const resetActiveChainAction = 'industry/reset-active-chain';
export const resetActiveChain = createAction(resetActiveChainAction);

const setDeselectedChainsAction = 'industry/set-deselected-chains';
export const setDeselectedChainsState = createAction<TagEntityModel[]>(setDeselectedChainsAction);
