import 'shared/init-integrations';
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './app';
import { syncUserTokenFromCookie } from 'features/authentication/utils/sync-cookie-and-storage';
import { version } from '../package.json';

window.__PlacerVersion = version;

syncUserTokenFromCookie();

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root'),
);
