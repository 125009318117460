import type { Chain } from '@placer-ui/types';

class ChainsPermissionService {
    chainIsEligibleForTheReport(chain: Chain): boolean {
        if (chain.purchased || chain.enable_limited_report) {
            return true;
        }

        return false;
    }

    checkIsReportLimited = (chains: Chain[]) => {
        return chains.some((chain) => !chain.purchased && chain.enable_limited_report);
    };
}

export const chainsPermissionService = new ChainsPermissionService();
