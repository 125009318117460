import { Dictionary } from '@placer-ui/types';

export const ADVANCED_SETTINGS_FIELDS = {
    demographicAttributes: {
        name: 'demog_attributes',
        type: 'checkboxes' as const,
    },
    coTenancyThreshold: {
        name: 'co_tenancy_threshold' as const,
        type: 'slider' as const,
        min: 0,
        max: 100,
        unit: 'percentage',
    },
    averageMonthlyFootTraffic: {
        name: 'avg_monthly_ft',
    },
    demographicsScore: {
        name: 'demographics',
    },
    cannibalizationScore: {
        name: 'cannibalization',
    },
    coTenancyScore: {
        name: 'natural_co_tenants',
    },
};

export const SCORE_FIELDS_TYPE = 'toggle' as const;

export const DEMOGRAPHIC_ATTRIBUTES_LABELS_TO_KEYS_MAPPING: Dictionary<string> = {
    'Household Income': 'income',
    Households: 'households',
    Age: 'age',
    'Marital Status': 'marital_status',
    'Housing Value': 'housing_value',
    Education: 'education',
    Ethnicity: 'ethnicity',
};
