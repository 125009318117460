import { User } from 'core/entities';
import { isEmailBlockedForSegmentEvents } from 'hooks/use-segment-callback/utils';
import { getSegmentAnalyticsIntance } from 'tracking/segment/segment';

export const segmentIdentify = (user: User) => {
    if (isEmailBlockedForSegmentEvents(user.email)) {
        return;
    }
    const segmentAnalytics = getSegmentAnalyticsIntance();
    segmentAnalytics?.identify({
        username: user.username,
        email: user.email,
        isUserFreemium: !user.settings?.account || user.settings?.account?.is_active === false,
        fullName: user.fullName,
        accountName: user.settings?.account?.name,
        accountId: user.settings?.account?.id,
    });
};
