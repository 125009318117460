import { CategoriesIconsDictionary, Dictionary } from '@placer-ui/types';

export type LocalStorageCategoryIcon = {
    categoryPathMapping: Dictionary<CategoriesIconsDictionary>;
    expire: number;
};

export const STORAGE_CATEGORY_ICONS = 'category_icons';

export const getFetchedCategoriesIcons = (): LocalStorageCategoryIcon => {
    const categoryIconsLocalStorage = window.localStorage.getItem(STORAGE_CATEGORY_ICONS);
    const parsedCategoryIconsLocalStorage: LocalStorageCategoryIcon =
        categoryIconsLocalStorage && JSON.parse(categoryIconsLocalStorage);

    return {
        categoryPathMapping: parsedCategoryIconsLocalStorage?.categoryPathMapping ?? {},
        expire: parsedCategoryIconsLocalStorage?.expire,
    };
};
