/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';
import { IconProps } from 'components/assets/Icons/Icons';

export const ShippingAndStorageIconSvg = (
    props: CustomIconComponentProps | SVGProps<SVGSVGElement>,
) => (
    <svg {...props} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.77388 0C1.44956 0 1.14828 0.166331 0.968299 0.445306L0.0858463 1.81249C0.0481514 1.8708 0.030057 1.93793 0 2H5V0H1.77388Z"
            fill="currentColor"
        />
        <path
            d="M11.9142 1.81249L11.0317 0.445311C10.8517 0.166333 10.5289 0 10.2046 0H7V2H12C11.9699 1.93796 11.9518 1.87082 11.9142 1.81249Z"
            fill="currentColor"
        />
        <path
            d="M11.9891 3H0.0109453C0.00986719 3.02473 0 3.0478 0 3.07264V10.9668C0 11.5365 0.473109 12 1.05469 12H10.9453C11.5269 12 12 11.5365 12 10.9668V3.07264C12 3.0478 11.9901 3.02473 11.9891 3ZM7.46763 6H4.61049C4.01968 6 3.53906 5.55142 3.53906 5C3.53906 4.44858 4.01968 4 4.61049 4H7.46763C8.05844 4 8.53906 4.44858 8.53906 5C8.53906 5.55142 8.05844 6 7.46763 6Z"
            fill="currentColor"
        />
    </svg>
);

export const ShippingAndStorageIcon = (props: IconProps) => (
    <Icon component={ShippingAndStorageIconSvg} {...props} />
);
