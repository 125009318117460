import { EMPTY_SPACE_POI_TYPE } from 'features/empty-space/constants/empty-space-constants';

export const ERRORS = {
    INVALID_ARGUMENTS: 'INVALID_ARGUMENTS',
    INVALID_DATE_RANGE: 'INVALID_DATE_RANGE',
    PERMISSION_DENIED: 'PERMISSION_DENIED',
    INSUFFICIENT_DATA: 'INSUFFICIENT_DATA',
    RESTRICTED_INSUFFICIENT_DATA: 'RESTRICTED_INSUFFICIENT_DATA',
    PARTIAL_INSUFFICIENT_DATA: 'ERR_INSUFFICIENT_DATA',
    ERROR_NOT_FOUND: 'ERROR_NOT_FOUND',
    ERR_IN_PROGRESS: 'ERR_IN_PROGRESS',
    NO_CHAIN_COVERAGE: 'NO_CHAIN_COVERAGE',
    NO_DATA: 'NO_DATA',
    UNSUPPORTED_DATE_RANGE: 'UNSUPPORTED_DATE_RANGE',
    UNKNOWN_ERROR: 'UNKNOWN_ERROR',
    RESTRICTED_AREA: 'RESTRICTED_AREA',
    NEARBY_ACTIVITY_UNAVAILABLE: 'NEARBY_ACTIVITY_UNAVAILABLE',
    ERROR_CUSTOM_POI: 'ERROR_CUSTOM_POI',
    POLYGON_TOO_SMALL: 'POLYGON_TOO_SMALL',
    NO_SALES_ESTIMATION_DATA: 'NO_SALES_ESTIMATION_DATA',
    ABORT_ERROR: 'ABORT_ERROR',
    UNDELIVERABLE_ENTITY: 'UNDELIVERABLE_ENTITY',
} as const;

export const SINGULAR_QUERY_PARAM_COLLECTIONS = [EMPTY_SPACE_POI_TYPE];

export type ErrorType = keyof typeof ERRORS;
