import { getVenueStatus } from 'ui-components/venue-status-indicator/v2/venue-status-indicator-v2';
import { Alert } from 'ui-components/alert/alert';
import React from 'react';
import {
    getReportEntityClosingDate,
    getReportEntityFlag,
} from 'core/services/report-entities-service/report-entities-service';
import style from './explore-drawer-alert.module.scss';
import { useGetEnclosingComplexOptions } from 'features/explore/hooks/use-explore-enclosing-complex';
import type { Place } from '@placer-ui/types';

type ExploreDrawerAlertProps = {
    poi: Place;
};
export const ExploreDrawerAlert = ({ poi }: ExploreDrawerAlertProps) => {
    const flag = getReportEntityFlag(poi);
    const closingDate = getReportEntityClosingDate(poi);
    const getEnclosingComplexOptions = useGetEnclosingComplexOptions();

    const venueStatus = getVenueStatus({
        flag,
        closingDate,
        fullClosingDateAlert: true,
        name: poi.name,
        hasEnclosingComplexes: !!getEnclosingComplexOptions(poi.id),
    });
    return (
        <Alert type={'info'} className={style.alert} textClassName={style.alertText}>
            {venueStatus}
        </Alert>
    );
};
