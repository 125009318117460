import { MapStateVisualFilter } from 'ui-components/google-map/types/types';

export const MAP_VISUAL_FILTER_KEYS_TO_CLEAN: (keyof MapStateVisualFilter)[] = [
    'mapZoom',
    'mapFitBounds',
    'mapRadiusTool',
    'mapRadius',
    'mapRadiusCenter',
];

export enum MAP_SPLUNK_EVENTS {
    EXPLORE_PAGE = 'explore-page-map',
    SITE_SELECTION_PAGE_MAP = 'site-selection-page-map',
    SITE_SELECTION_REPORT_MAP = 'site-selection-report-map',
    SITE_SELECTION_MATCH_ANALYSIS_MAP = 'site-selection-match-analysis-map',
    SALES_ANALYSIS_MAP = 'sales-analysis-map',
    POI_SELECTION_MAP = 'poi-selection-map',
    TENANTS_MAP_THUMBNAIL = 'tenants-map-thumbnail',
    PROPERTY_COVER_PAGE_MAP = 'property-cover-map',
    DEFAULT_COVER_PAGE_MAP = 'default-cover-page-map',
    CHAIN_REPORT_MAP = 'chain-report-map',
    SITE_OVERVIEW_MAP = 'site-overview-map',
    TOP_BAR = 'top-bar',
    INDUSTRY_COVER_MAP = 'industry-cover-map',
    BASEMAP = 'basemap',
    VOID_ANALYSIS_MATCH_COVER_MAP = 'void-analysis-match-cover-map',
}
