import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import styles from './square-logo.module.scss';
import { LocationMarkIcon } from 'components/assets/Icons/LocationMarkIcon';
import classnames from 'classnames';
import { BILLBOARD_ENTITY, VenuePOI } from 'core/entities';
import type { ExternalVenueType, PlaceType } from '@placer-ui/types';
import { TrafficPinBigIcon } from 'components/assets/Icons/Icons';

type SquareLogoProps = {
    name: string;
    logoUrl?: string;
    className?: string;
    placeType?: PlaceType | ExternalVenueType | null;
    icon?: string;
};

export const SquareLogo = ({ name, logoUrl, className, placeType, icon }: SquareLogoProps) => {
    const [imgError, setImageError] = useState<boolean>(false);

    useEffect(() => {
        setImageError(false);
    }, [logoUrl]);

    const onImgError = () => {
        setImageError(true);
    };

    const isImgLogo = logoUrl && !imgError;
    const isCategoryIcon = !isImgLogo && (!!icon || placeType === BILLBOARD_ENTITY);

    const logoToShow: ReactNode = useMemo(() => {
        if (placeType === 'address') {
            return (
                <LocationMarkIcon className={classnames('location-icon', styles.locationIcon)} />
            );
        } else if (isImgLogo) {
            return <img crossOrigin="anonymous" src={logoUrl} alt={name} onError={onImgError} />;
        } else if (icon) {
            return (
                <img
                    className="category-default-icon-logo"
                    crossOrigin="anonymous"
                    src={icon}
                    alt={name}
                    onError={onImgError}
                />
            );
        } else if (placeType === BILLBOARD_ENTITY) {
            return <TrafficPinBigIcon />;
        } else {
            return (
                <div className={classnames('acronym-logo', styles.acronymLogo)}>
                    {VenuePOI.generateVenueAcronym(name)}
                </div>
            );
        }
    }, [icon, isImgLogo, logoUrl, name, placeType]);

    return (
        <div
            className={classNames('square-logo', styles.logo, className, {
                [styles.imgLogo]: isImgLogo,
                [styles.categoryIconLogoContainer]: isCategoryIcon,
            })}
        >
            {logoToShow}
        </div>
    );
};
