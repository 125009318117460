import type { PlaceType } from '@placer-ui/types';

export const EMPTY_SPACE_SUPPORTED_ENTITIES: PlaceType[] = [
    'complex',
    'billboard',
    'venue',
    'empty_space',
];

export const EMPTY_SPACE_POI_TYPE = 'empty_space';

export const EMPTY_SPACE_COORDINATE_ID = 'empty_space_coordinate_id';
