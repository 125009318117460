import React, { ComponentProps, ReactNode, useRef } from 'react';
import classnames from 'classnames';
import { formatDefault, numberFormat } from 'utils/number/number';
import styles from './number-cell.module.scss';
import { Tooltip } from 'ui-components/tooltip/tooltip';

const defaultAbsoluteChangeFormatter = (change: number) =>
    change > 0 ? `(+${Math.abs(change)})` : `(-${Math.abs(change)})`;

const defaultPercentChangeFormatter = (change: number) => {
    const formattedPercentage = formatDefault(Math.abs(change), 2);
    return change > 0 ? `(+${formattedPercentage}%)` : `(-${formattedPercentage}%)`;
};

const placeholders = {
    'n/a': 'N/A',
    dash: <>&mdash;</>,
    smallDash: '-',
};

type NumberCellProps = {
    value?: number | null;
    ofValue?: number | null;
    change?: number | null;
    changeType?: 'absolute' | 'percent';
    formatter?: (value: number) => string;
    changeFormatter?: (value: number) => string;
    invalidValuePlaceholder?: 'n/a' | 'dash' | 'smallDash';
    className?: string;
    valueClassName?: string;
    changeClassName?: string;
    formatterFraction?: number;
    isLimited?: boolean;
    tooltipInfo?: string | ReactNode;
    tooltipPlacement?: ComponentProps<typeof Tooltip>['placement'];
    dataTestId?: string;
    showOnlyInCaseOfEllipsis?: boolean;
};

export const NumberCell = ({
    value,
    ofValue,
    change,
    changeType = 'percent',
    formatterFraction = 1,
    invalidValuePlaceholder = 'n/a',
    formatter = (value: number) => numberFormat(value, formatterFraction, true, true),
    changeFormatter = changeType === 'absolute'
        ? defaultAbsoluteChangeFormatter
        : defaultPercentChangeFormatter,
    className,
    valueClassName,
    changeClassName,
    isLimited,
    tooltipInfo,
    tooltipPlacement,
    dataTestId,
    showOnlyInCaseOfEllipsis,
}: NumberCellProps) => {
    const invalidPlaceholder = placeholders[invalidValuePlaceholder];
    const valueIsValid = value !== null && value !== undefined && !Number.isNaN(value);
    const limitedStyle = classnames({ [styles.limited]: isLimited });
    const ref = useRef<HTMLDivElement>(null);
    return (
        <Tooltip
            small
            title={tooltipInfo}
            placement={tooltipPlacement}
            showOnlyInCaseOfEllipsis={showOnlyInCaseOfEllipsis}
            ellipsisElementRef={showOnlyInCaseOfEllipsis ? ref : undefined}
        >
            <div
                className={classnames(styles.wrapper, className)}
                data-testid={dataTestId}
                ref={ref}
            >
                <span className={classnames(styles.value, valueClassName, limitedStyle)}>
                    {valueIsValid && !isLimited ? formatter(value!) : invalidPlaceholder}
                </span>
                {ofValue && !isLimited && (
                    <span className={classnames(styles.value, valueClassName, limitedStyle)}>
                        {'/' + formatter(ofValue)}
                    </span>
                )}
                {!isLimited && value !== 0 && valueIsValid && change !== 0 && change && (
                    <span
                        className={classnames(styles.change, changeClassName, {
                            [styles.positive]: change > 0,
                            [styles.negative]: change < 0,
                        })}
                    >
                        {changeFormatter(change)}
                    </span>
                )}
            </div>
        </Tooltip>
    );
};
