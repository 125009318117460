const textToMultilineRecursive = (
    words: string[],
    maxCharsInLine: number,
    currentWordIndex: number,
    charsCounter: number,
    accumulator: string[][],
): string[][] => {
    if (words.length) {
        const [word, ...restOfWords] = words;
        if (charsCounter + word.length >= maxCharsInLine) {
            // not enough space for the current word in the current line, start a new line
            return textToMultilineRecursive(restOfWords, maxCharsInLine, currentWordIndex + 1, 0, [
                [word],
                ...accumulator,
            ]);
        } else {
            // concat to the same line
            return textToMultilineRecursive(
                restOfWords,
                maxCharsInLine,
                currentWordIndex + 1,
                charsCounter + word.length,
                [[...accumulator[0], word], ...accumulator.slice(1)],
            );
        }
    } else {
        return [...accumulator].reverse();
    }
};

/**
 * This util function coverts the given string to a multiline string with \n where needed,
 * according to the given max line length. Line breaks will only be inserted between words.
 * Example: textToMultiline('some too long string', 10) => 'some too\nlong string')
 */
export const textToMultiline = (text: string, maxCharsInLine: number = 20): string => {
    const words = text.split(' ');

    if (words.length === 1) {
        return text;
    }

    const lines = textToMultilineRecursive(words, maxCharsInLine, 0, 0, [[]]);
    return lines.map((g) => g.join(' ')).join('\n');
};
