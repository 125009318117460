import { lazy } from 'router/utils/lazy-with-reload/lazy-with-reload';

export const MobileAnalyzeLocationOnboardingPage = lazy(
    () => import('features/mobile-report/components/onboarding-screens/analyze-location'),
);

export const MobileAnalyzePage = lazy(
    () => import('features/mobile-report/components/analyze-page/analyze-page'),
);

export const MobileExplorePage = lazy(
    () => import('features/mobile-report/components/explore-mobile/explore-page'),
);

export const MobileExplorePropertiesOnboardingPage = lazy(
    () => import('features/mobile-report/components/onboarding-screens/explore-properties'),
);

export const MobileReportPage = lazy(() => import('features/mobile-report/mobile-report-page'));

export const MobileSettingsPage = lazy(
    () => import('features/mobile-report/components/settings/settings'),
);

export const MobileStandAloneWidgetPage = lazy(
    () => import('features/mobile-report/shared/components/stand-alone-widget/stand-alone-widget'),
);
