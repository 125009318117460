import { RGBColor, getPallettesByCategoryAndBucket } from '@placer-ui/components';
import { TradeAreaLayerType, TradeLayerType } from './types';
import { RankScopeType } from '@advanced-mapping/shared-types';

export const DEFAULT_MAP_IDS: Record<string, string> = {
    // Map ID for loading Map with Blank Map
    BLANK_NEW_MAP: 'd0',
    // Map ID for loading the default. It depends on the `enable_maptemplate` flag. Users with the
    // flag on will see the last saved map, users with the flag off will see the recent reports.
    DEFAULT: 'd1',
    // Map ID for loading new Map Templates
    FROM_TEMPLATE_STORE: 'd2',
    // Map ID for opening new Template Configuration Modal
    OPEN_TEMPLATE_MODAL: 'd3',
    // Map ID for documents created in BNM using templates
    FROM_BNM_TEMPLATE: 'd4',
    // Map ID for loading new Map with Recent Reports
    WITH_RECENT_REPORTS: 'd5',
};

export const BLANK_MAP_TEMPLATE_ID = 'blank-map';

export const chainVenuesDefaultFetchFilters = {};

export const chainVenuesDefaultVisualFilters = {
    inEditMode: false,
    offsetMap: {},
};

export const TradeAreaLayerKeyTypeToTradeName: Record<TradeAreaLayerType, string> = {
    tta: 'True Trade Area',
    tta_heat_map: 'True Trade Area Heat Map',
    distance_in_miles: 'Distance in Miles',
    drive_time: 'Drive Time',
    walk_time: 'Walk Time',
};

export const TradeAreaLayerKey: TradeLayerType[] = [
    'tta',
    'tta_heat_map',
    'distance_in_miles',
    'drive_time',
    'walk_time',
];

export const ChainRankTypes: Array<{ label: string; value: RankScopeType }> = [
    {
        label: 'Chain - Nationwide',
        value: 'chain.country',
    },
    {
        label: 'Chain - State',
        value: 'chain.state',
    },
    {
        label: 'Chain - DMA',
        value: 'chain.dma',
    },
    {
        label: 'Chain - CBSA',
        value: 'chain.cbsa',
    },
];

export const CategoryRankTypes: Array<{ label: string; value: RankScopeType }> = [
    {
        label: 'Category - Nationwide',
        value: 'primary.country',
    },
    {
        label: 'Category - State',
        value: 'primary.state',
    },
    {
        label: 'Category - DMA',
        value: 'primary.dma',
    },
    {
        label: 'Category - CBSA',
        value: 'primary.cbsa',
    },
];

export const CategoryGrupRankTypes: Array<{ label: string; value: RankScopeType }> = [
    {
        label: 'Category Group - Nationwide',
        value: 'group.country',
    },
    {
        label: 'Category Group - State',
        value: 'group.state',
    },
    {
        label: 'Category Group - DMA',
        value: 'group.dma',
    },
    {
        label: 'Category Group - CBSA',
        value: 'group.cbsa',
    },
];

export const RankTypes: Array<{ label: string; value: RankScopeType }> = [
    ...ChainRankTypes,
    ...CategoryRankTypes,
    ...CategoryGrupRankTypes,
];

export const DEFAULT_COLOR_TRADE_AREA_PALETTE_COLORS = getPallettesByCategoryAndBucket(
    'trade-area',
    1,
);
export const DEFAULT_HEATMAP_COLOR_TRADE_AREA_PALETTE_COLORS = getPallettesByCategoryAndBucket(
    'heatmap',
    5,
);
export const DEFAULT_COLOR_VTV_PALETTE_COLORS = getPallettesByCategoryAndBucket(
    'traffic-volume',
    6,
);

// will change, waiting for PM answer
export const DEFAULT_COLOR_SEQUENTIAL_PALETTE_COLORS = getPallettesByCategoryAndBucket(
    'trade-area',
    1,
);

export const TAStrokeColors: Record<string, RGBColor> = {
    'POI-1-TA': [121, 125, 246],
    'POI-2-TA': [233, 139, 155],
    'POI-3-TA': [65, 200, 184],
    'POI-4-TA': [225, 196, 65],
    'POI-5-TA': [221, 140, 70],
    'POI-6-TA': [79, 172, 252],
    'POI-7-TA': [161, 204, 62],
    'POI-8-TA': [228, 148, 229],
};
