import React, { useContext, useMemo } from 'react';
import moment, { Moment } from 'moment';
import { Select } from 'antd';
import styles from './date-panel-header.module.scss';
import {
    SelectArrowIcon,
    CheckMarkIcon,
    ArrowOutlinedSvgIcon,
} from 'components/assets/Icons/Icons';
import {
    MONTH_FORMAT,
    YEAR_FORMAT,
    useMonthsRange,
    useYearsRange,
} from 'ui-components/date-picker/date-hooks/use-date-range';
import { Button } from 'ui-components/button/button';
import { DateContext } from 'ui-components/date-picker/date-picker';
import classNames from 'classnames';

interface DatePanelHeaderProps {
    pickerValue: Moment;
    onChange: (date: Moment) => void;
    getPopupContainer?: (triggerNode?: HTMLElement) => HTMLElement;
}

const renderIncrementOrDecrementMonthBtn = (
    isIncrement: boolean,
    value: { year: string; month: string },
    onMonthSelect: (month: string, step?: number) => void,
    minDate?: Moment,
    maxDate?: Moment,
) => {
    let showBtn: boolean;
    const newDate = moment(`${value.year}-${value.month}-01`);

    if (isIncrement) {
        showBtn = !maxDate || newDate.add(1, 'M').isBefore(maxDate);
    } else {
        showBtn = !minDate || newDate.add(-1, 'M').endOf('month').isAfter(minDate);
    }

    return (
        <Button
            type="ghost"
            icon={
                <ArrowOutlinedSvgIcon
                    rotate={isIncrement ? -90 : 90}
                    className={styles.triggerIcon}
                />
            }
            size="small"
            onClick={() => onMonthSelect(value.month, isIncrement ? 1 : -1)}
            className={classNames(styles.trigger, { [styles.hide]: !showBtn })}
        />
    );
};

export const DatePanelHeader = ({
    pickerValue,
    onChange,
    getPopupContainer,
}: DatePanelHeaderProps) => {
    const months = useMonthsRange(pickerValue);
    const years = useYearsRange();
    const { minDate, maxDate, hideNavigation } = useContext(DateContext);

    const value = useMemo(
        () => ({
            year: pickerValue.format(YEAR_FORMAT),
            month: pickerValue.format(MONTH_FORMAT),
        }),
        [pickerValue],
    );

    const onYearSelect = (year: string) => {
        onChange(moment(`${year}-${value.month}-01`));
    };

    const onMonthSelect = (month: string, stepSize = 0) => {
        const newDate = moment(`${value.year}-${month}-01`);
        if (stepSize) {
            newDate.add(stepSize, 'M');
        }

        onChange(newDate);
    };

    return (
        <div className={styles.header}>
            {!hideNavigation
                ? renderIncrementOrDecrementMonthBtn(false, value, onMonthSelect, minDate, maxDate)
                : null}
            <div className={styles.container}>
                <Select
                    getPopupContainer={getPopupContainer}
                    className={`${styles.select} ${styles.monthSelect}`}
                    dropdownClassName={styles.dropdown}
                    value={value.month}
                    optionLabelProp="label"
                    onChange={(newMonth) => onMonthSelect(newMonth)}
                    suffixIcon={<SelectArrowIcon />}
                    listItemHeight={33}
                    listHeight={264}
                >
                    {months.map((month) => (
                        <Select.Option
                            className={styles.options}
                            key={month.value}
                            value={month.value}
                            label={month.label}
                            disabled={month.disabled}
                        >
                            <CheckMarkIcon className={styles.icon} />
                            {month.label}
                        </Select.Option>
                    ))}
                </Select>
                <Select
                    getPopupContainer={getPopupContainer}
                    className={`${styles.select} ${styles.yearSelect}`}
                    dropdownClassName={styles.dropdown}
                    value={value.year}
                    optionLabelProp="label"
                    onChange={onYearSelect}
                    suffixIcon={<SelectArrowIcon />}
                    listItemHeight={33}
                    listHeight={264}
                >
                    {years.map((year) => (
                        <Select.Option
                            className={styles.options}
                            key={year.value}
                            value={year.value}
                            label={year.label}
                        >
                            <CheckMarkIcon className={styles.icon} />
                            {year.label}
                        </Select.Option>
                    ))}
                </Select>
            </div>
            {!hideNavigation
                ? renderIncrementOrDecrementMonthBtn(true, value, onMonthSelect, minDate, maxDate)
                : null}
        </div>
    );
};
