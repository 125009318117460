import React, { ReactNode } from 'react';
import { QueryCache, QueryClient, defaultShouldDehydrateQuery } from '@tanstack/react-query';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import {
    PersistQueryClientOptions,
    PersistQueryClientProvider,
} from '@tanstack/react-query-persist-client';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { reportException } from 'core/exceptions';
import { version as appVersion } from '../../../package.json';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            cacheTime: 1000 * 60 * 60 * 24, // 24 hours
        },
    },
    queryCache: new QueryCache({
        onError: (error, { queryKey, meta }) => {
            reportException(error, {
                payload: {
                    queryKey,
                    meta,
                },
            });
        },
    }),
});

const localStoragePersister = createSyncStoragePersister({
    storage: window.localStorage,
});

const persistOptions: Omit<PersistQueryClientOptions, 'queryClient'> = {
    persister: localStoragePersister,
    buster: appVersion,
    dehydrateOptions: {
        shouldDehydrateQuery: (query) =>
            defaultShouldDehydrateQuery(query) && query.meta?.cache !== false,
    },
};

export const ReactQueryClient = ({ children }: { children: ReactNode }) => (
    <PersistQueryClientProvider client={queryClient} persistOptions={persistOptions}>
        {children}
        <ReactQueryDevtools initialIsOpen={false} />
    </PersistQueryClientProvider>
);
