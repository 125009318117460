/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

type ArrowsFiveToIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type ArrowsFiveToIconProps = SVGProps<SVGSVGElement> & ArrowsFiveToIconUniqueProps;

export const ArrowsFiveToIcon = function ArrowsFiveToIcon({
    width = 46,
    height = 257,
    ...props
}: ArrowsFiveToIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 46 257"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M1.98513 128.5C37.4599 128.5 -17.5649 4.73643 44.3982 6.13867M44.3982 6.13867L40.3982 2.13867M44.3982 6.13867L40.3982 10.1387"
                stroke="#767676"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1.92149 128.5C33.5304 124.136 -8.63447 67.1644 44.3982 67.1644M44.3982 67.1644L40.3982 63.1644M44.3982 67.1644L40.3982 71.1644"
                stroke="#767676"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1.92149 128.5C33.5304 132.864 -8.63447 189.836 44.3982 189.836M44.3982 189.836L40.3982 193.836M44.3982 189.836L40.3982 185.836"
                stroke="#767676"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1.92155 128.5C37.3029 128.5 -14.9661 250.861 44.3982 250.861M44.3982 250.861L40.3982 254.861M44.3982 250.861L40.3982 246.861"
                stroke="#767676"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1.91746 128.5C15.8593 128.5 44.3982 128.5 44.3982 128.5M44.3982 128.5L40.3982 124.5M44.3982 128.5L40.3982 132.5"
                stroke="#767676"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
ArrowsFiveToIcon.__tags = [] as string[];
