/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

type SelfCompareIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type SelfCompareIconProps = SVGProps<SVGSVGElement> & SelfCompareIconUniqueProps;

export const SelfCompareIcon = function SuggestionsIcon({
    width = 19,
    height = 19,
    ...props
}: SelfCompareIconProps) {
    return (
        <svg {...props} width={width} height={height} viewBox="0 0 19 19">
            <g fill="none" fillRule="evenodd">
                <path
                    stroke="#49565D"
                    strokeLinecap="round"
                    strokeWidth=".75"
                    d="M13.7 14.332c2.583 0 4.675-2.206 4.675-4.926 0-2.721-2.092-4.927-4.674-4.927H9.875M5.05 4.543C2.466 4.543.374 6.75.374 9.47c0 2.721 2.092 4.927 4.674 4.927h3.826"
                />
                <path fill="#D0D0D0" d="M5.875 10.292l4.894 4.104L5.875 18.5z" />
                <path
                    stroke="#49565D"
                    strokeLinejoin="round"
                    strokeWidth=".75"
                    d="M5.875 10.292l4.894 4.104L5.875 18.5z"
                />
                <path fill="#D0D0D0" d="M12.875 8.583L7.981 4.48 12.875.375z" />
                <path
                    stroke="#49565D"
                    strokeLinejoin="round"
                    strokeWidth=".75"
                    d="M12.875 8.583L7.981 4.48 12.875.375z"
                />
            </g>
        </svg>
    );
};
SelfCompareIcon.__tags = ['arrows'] as string[];
