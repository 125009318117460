import React, { useContext, useState, useEffect } from 'react';
import { Moment } from 'moment';
import { Button } from 'ui-components/button/button';
import styles from './date-picker-menu.module.scss';

import { DateContext } from 'ui-components/date-picker/date-picker';
import { RangeData } from 'ui-components/date-picker/date-types/RangeData';

import { RangePanel } from 'ui-components/date-picker/range-panel/range-panel';
import { FixedPanel } from 'ui-components/date-picker/fixed-panel/fixed-panel';
import { RelativePanel } from 'ui-components/date-picker/relative-panel/relative-panel';
import {
    DatePickerList,
    ListItem,
} from 'ui-components/date-picker/date-picker-list/date-picker-list';
import { SEGMENTS } from 'ui-components/date-picker/date-picker-constants/date-picker-segments';
import { ArrowOutlinedSvgIcon } from 'components/assets/Icons/Icons';
import classNames from 'classnames';

const initialRangeState: RangeData<null> = { value: [null, null] };

interface DatePickerMenuProps {
    onAdvanced?: () => void;
    onApply: (rangeData: RangeData<Moment>) => void;
    onCancel: () => void;
    segments?: Readonly<Array<ListItem<string>>>;
    defaultPanel?: string;
    showMaxDate?: boolean;
    panelSide?: 'left' | 'right';
    displayText?: string;
}

export const DatePickerMenu = ({
    onAdvanced,
    onCancel,
    onApply,
    segments = SEGMENTS,
    defaultPanel = 'custom',
    showMaxDate = true,
    panelSide = 'right',
    displayText,
}: DatePickerMenuProps) => {
    const { maxDate, startDate, endDate, hideNavigation } = useContext(DateContext);
    const [activePanel, setActivePanel] = useState<string>(defaultPanel);
    const [selectedRangeData, setSelectedRangeData] =
        useState<RangeData<Moment | null>>(initialRangeState);

    useEffect(() => {
        setSelectedRangeData(initialRangeState);
    }, [startDate, endDate]);

    const isValidRange = selectedRangeData.value[0] !== null && selectedRangeData.value[1] !== null;

    const onDateSelect = (value: RangeData<Moment>) => {
        setSelectedRangeData(value);
    };

    const onApplyDate = () => {
        isValidRange && onApply(selectedRangeData as RangeData<Moment>);
    };

    const onPanelSelect = (panel: string) => {
        const panelObj = segments.find((segment) => segment.key === panel);
        if (panelObj && panelObj.values) {
            onApply({
                value: panelObj.values,
                label: panelObj.label,
            });
            return;
        }
        setActivePanel(panel);
    };

    return (
        <section className={styles.menu} data-panelside={panelSide}>
            <div className={styles.leftPanel}>
                <DatePickerList<string>
                    dataSource={segments}
                    activeItem={activePanel}
                    onSelect={onPanelSelect}
                    icon={
                        panelSide === 'right' && (
                            <ArrowOutlinedSvgIcon className={styles.arrow} rotate={-90} />
                        )
                    }
                />
                {(showMaxDate || onAdvanced) && (
                    <div className={styles.footer}>
                        {onAdvanced && (
                            <Button type="default" onClick={onAdvanced} className={styles.advanced}>
                                Advanced
                            </Button>
                        )}
                        {showMaxDate && maxDate && (
                            <div className={styles.note}>
                                <span>Max date:</span>
                                <span className={styles.date}>{maxDate.format('MMMM Do')}</span>
                            </div>
                        )}
                    </div>
                )}
            </div>
            {activePanel && (
                <div
                    className={classNames(styles.rightPanel, {
                        [styles.wide]: !hideNavigation,
                    })}
                >
                    {activePanel === 'custom' && <RangePanel onSelect={onDateSelect} />}
                    {activePanel === 'fixed' && (
                        <FixedPanel onSelect={onDateSelect} selectedKeyLabel={displayText} />
                    )}
                    {activePanel === 'relative' && (
                        <RelativePanel
                            onSelect={onDateSelect}
                            selectedKeyLabel={displayText}
                        />
                    )}
                    <div className={styles.actions}>
                        <Button
                            type="ghost"
                            size="small"
                            onClick={onCancel}
                            className={styles.actionBtn}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            size="small"
                            onClick={onApplyDate}
                            className={styles.actionBtn}
                            disabled={!isValidRange}
                        >
                            Apply
                        </Button>
                    </div>
                </div>
            )}
        </section>
    );
};
