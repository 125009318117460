import { createAsyncThunk } from '@reduxjs/toolkit';
import { PLACE_COLORS } from 'core/constants/place-colors';
import { ChainEntityModelMapper } from 'features/chains/common/util/chain-placer-entity-model-mapper';

import {
    selectChainsReportChainsEntityModelsByUId,
    selectChainsReportAllChainsEntityModels,
    setReportChainsAtChains,
} from 'features/chains/store/chains-report-chains-store';
import { ChainsReportState } from 'features/chains/store/chains-store';

export const chainsDuplicateChain = createAsyncThunk<
    void,
    string,
    { state: ChainsReportState }
>('chains/duplicate-chain', (chainId: string, { getState, dispatch }) => {
    const state = getState();
    const chain = selectChainsReportChainsEntityModelsByUId(state, chainId);
    if (!chain) {
        return;
    }

    const chains = selectChainsReportAllChainsEntityModels(state);
    const color = PLACE_COLORS.main[chains.length];
    const newChain = ChainEntityModelMapper.createEntityModel(chain, color);

    const newChains = [...chains, newChain];

    dispatch(setReportChainsAtChains(newChains));
});
