import { useSelectorWithProps } from 'utils/structured-selector/structured-selector';
import { selectHasUserPermission } from 'store/selectors/app-selectors';
import { AvailableUserPermission } from 'core/entities/user/user-permissions';

/**
 * @deprecated use `getPermissions` or `hasPermissions` flow-control methods
 */
export const usePermission = (permissionName: AvailableUserPermission) => {
    return useSelectorWithProps(
        {
            permission: permissionName,
        },
        selectHasUserPermission,
    );
};
