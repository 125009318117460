import { reportException } from 'core/exceptions';
import { ComponentType, lazy as reactLazy, LazyExoticComponent } from 'react';
import { goToHref } from 'router/go-to';
import { shouldRenderMobileApp } from 'utils/detect-environment';

const importErrorHandler = (err: string): { default: ComponentType<any> } => {
    // Get the last reload time from session storage
    const savedReloadTime = sessionStorage.getItem('last-reload');
    const reloadTime = savedReloadTime ? Number(savedReloadTime) : null;

    const now = Date.now();

    // If the last reload time is more than 10 seconds ago
    const shouldRefresh = !reloadTime || reloadTime + 10_000 < now;

    if (!navigator.onLine) {
        goToHref('/offline');

        return { default: () => null };
    } else if (shouldRefresh) {
        reportException(
            {
                status: 'refresh page after "Failed to fetch dynamically imported module"',
                url: err,
            },
            {
                payload: { err },
            },
        );
        sessionStorage.setItem('last-reload', String(now));
        console.error(err);

        if (shouldRenderMobileApp()) {
            goToHref('/refresh-page');
        } else {
            window.location.reload();
        }

        // Return an empty module so we do not see the error in the app before reloading
        return { default: () => null };
    }

    // We let ErrorBoundary handle the error
    throw new Error('lazy import error');
};

export const lazy = (factory: () => Promise<{ default: ComponentType<any> }>) => {
    return reactLazy(() => factory().catch(importErrorHandler));
};

export type PlLazyComponent<T extends ComponentType<any> = ComponentType<any>> =
    LazyExoticComponent<T> & {
        preload: () => Promise<{ default: T } | { default: ComponentType<any> }>;
    };

export function prefetchLazyWithReload<T extends ComponentType<any>>(
    factory: () => Promise<{ default: T }>,
): PlLazyComponent<T> {
    const load = () => factory().catch(importErrorHandler);
    const Component = reactLazy(load) as PlLazyComponent<T>;
    Component.preload = factory;
    return Component;
}
