/* eslint-disable max-len */
import React from 'react';

export const SgEducationIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.19347 8.0193C0.280602 7.47547 0.265383 6.15885 1.16544 5.59406L9.73288 0.217915C10.1944 -0.07169 10.7808 -0.0720095 11.2427 0.217092L19.8332 5.5947C20.7345 6.15887 20.7199 7.47658 19.8065 8.02076L11.2287 13.1309C10.7805 13.3979 10.2219 13.3979 9.77369 13.1309L1.19347 8.0193Z"
                fill="#E1E2FF"
            />
            <path
                d="M20.5 18.3332C20.5 19.2537 19.7538 19.9999 18.8333 19.9999C17.9128 19.9999 17.1666 19.2537 17.1666 18.3332C17.1666 17.4128 17.9128 16.6666 18.8333 16.6666C19.7538 16.6666 20.5 17.4128 20.5 18.3332Z"
                fill="#E1E2FF"
            />
            <path
                d="M3.8333 9.59192V14.0153C3.8333 14.8385 4.28833 15.5942 5.01585 15.9793L9.47054 18.3374C10.122 18.6823 10.9019 18.6817 11.5528 18.3359L15.987 15.98C16.7129 15.5944 17.1666 14.8395 17.1666 14.0176V9.59338L16.2317 10.1504V14.0176C16.2317 14.4937 15.9688 14.931 15.5484 15.1543L11.1142 17.5102C10.7371 17.7105 10.2853 17.7108 9.90797 17.5111L5.45328 15.153C5.03186 14.9299 4.76827 14.4921 4.76827 14.0153V10.1489L3.8333 9.59192Z"
                fill="#5E63E5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.1758 0.923783L1.60838 6.29993C1.23597 6.53362 1.24227 7.07837 1.61997 7.30338L10.2002 12.415C10.3857 12.5254 10.6167 12.5254 10.8022 12.415L19.38 7.30484C19.7579 7.07968 19.7639 6.53448 19.391 6.30105L10.8005 0.923442C10.6094 0.803825 10.3668 0.803958 10.1758 0.923783ZM1.16544 5.59406C0.265383 6.15885 0.280602 7.47547 1.19347 8.0193L9.77369 13.1309C10.2219 13.3979 10.7805 13.3979 11.2287 13.1309L19.8065 8.02076C20.7199 7.47658 20.7345 6.15887 19.8332 5.5947L11.2427 0.217092C10.7808 -0.0720095 10.1944 -0.07169 9.73288 0.217915L1.16544 5.59406Z"
                fill="#5E63E5"
            />
            <path
                d="M19.25 8.33324C19.25 8.10312 19.0634 7.91657 18.8333 7.91657C18.6032 7.91657 18.4166 8.10312 18.4166 8.33324V16.7191C18.5498 16.6848 18.6894 16.6666 18.8333 16.6666C18.9772 16.6666 19.1168 16.6848 19.25 16.7191V8.33324Z"
                fill="#5E63E5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.8333 19.1666C19.2935 19.1666 19.6666 18.7935 19.6666 18.3332C19.6666 17.873 19.2935 17.4999 18.8333 17.4999C18.3731 17.4999 18 17.873 18 18.3332C18 18.7935 18.3731 19.1666 18.8333 19.1666ZM18.8333 19.9999C19.7538 19.9999 20.5 19.2537 20.5 18.3332C20.5 17.4128 19.7538 16.6666 18.8333 16.6666C17.9128 16.6666 17.1666 17.4128 17.1666 18.3332C17.1666 19.2537 17.9128 19.9999 18.8333 19.9999Z"
                fill="#5E63E5"
            />
        </svg>
    );
};
