import { useCallback, useRef } from 'react';
import type { Geolocation } from '@placer-ui/types';
import type { MapPositionWithOffset } from 'ui-components/google-map/types/types';

type MapPositionWithOffsetProps = {
    mapRef: google.maps.Map;
    offset: number;
    zoom?: number;
};

// add horizontal offset to map bounds
export const useGetMapPositionWithOffset = () => {
    return useCallback(
        ({ mapRef, offset, zoom }: MapPositionWithOffsetProps): MapPositionWithOffset => {
            const bounds = mapRef.getBounds()!;
            const northEast = bounds.getNorthEast();
            const southWest = bounds.getSouthWest();
            const { lat: neLat, lng: neLng } = northEast;
            const { lat: swLat, lng: swLng } = southWest;
            const newZoom = zoom || mapRef.getZoom();
            const scale = Math.pow(2, newZoom - 0.5);
            const boundsWithOffset = new google.maps.LatLngBounds(
                {
                    lng: swLng() + offset / scale,
                    lat: swLat(),
                },
                {
                    lng: neLng(),
                    lat: neLat(),
                },
            );

            const { lat: neLatWithOffset, lng: neLngWithOffset } = boundsWithOffset.getNorthEast();
            const { lat: swLatWithOffset, lng: swLngWithOffset } = boundsWithOffset.getSouthWest();

            const offsetDiff: Geolocation = {
                lat: bounds.getCenter().lat() - boundsWithOffset.getCenter().lat(),
                lng: bounds.getCenter().lng() - boundsWithOffset.getCenter().lng(),
            };

            return {
                ne: [neLat(), neLng()],
                sw: [swLat(), swLng()],
                neWithOffset: [neLatWithOffset(), neLngWithOffset()],
                swWithOffset: [swLatWithOffset(), swLngWithOffset()],
                offsetDiff,
                mapOffset: offset,
            };
        },
        [],
    );
};

type GetLatLngWithOffsetProps = {
    latLng: google.maps.LatLng;
    offsetX: number;
    offsetY?: number;
    mapRef: google.maps.Map;
};

export function getLatLngWithOffset({
    offsetX,
    offsetY,
    mapRef,
    latLng,
}: GetLatLngWithOffsetProps): google.maps.LatLng {
    offsetY = offsetY || 0;
    const projection = mapRef.getProjection();
    if (!projection) return latLng;
    const scale = Math.pow(2, mapRef.getZoom());
    const point = projection.fromLatLngToPoint(latLng);
    const pixelOffset = new google.maps.Point(offsetX / scale, offsetY / scale);
    const newPoint = new google.maps.Point(point.x - pixelOffset.x, point.y + pixelOffset.y);
    return projection.fromPointToLatLng(newPoint);
}

type SetMapZoomWithOffsetProps = {
    zoom: number;
    mapRef: google.maps.Map;
    mapOffset: number;
};

export function setMapZoomWithOffset({ zoom, mapRef, mapOffset }: SetMapZoomWithOffsetProps) {
    const currentZoom = mapRef.getZoom();
    const currentCenter = mapRef.getCenter();

    const diffFromCurrentZoom = Math.abs(zoom - currentZoom);
    const isZoomIn = zoom > currentZoom;
    // compensate for offset loss after zooming
    const compensateOffset = (isZoomIn ? -mapOffset / 4 : mapOffset / 2) * diffFromCurrentZoom;

    const newCenter = getLatLngWithOffset({
        offsetX: compensateOffset,
        latLng: currentCenter,
        mapRef,
    });
    mapRef.setOptions({
        zoom: zoom,
        center: newCenter.toJSON(),
    });
}

// for dev purposes only
export const useDrawRectangleOnMapBounds = () => {
    const rectangleRef = useRef<google.maps.Rectangle>();

    return useCallback((mapRef: google.maps.Map, bounds: google.maps.LatLngBounds) => {
        if (rectangleRef.current) {
            rectangleRef.current.setMap(null);
        }
        rectangleRef.current = new google.maps.Rectangle({
            bounds,
            editable: false,
            clickable: false,
            draggable: false,
            fillOpacity: 0.1,
            strokeColor: '#656565',
            strokeWeight: 1,
        });

        rectangleRef.current.setMap(mapRef);
    }, []);
};
