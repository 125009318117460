import { createSelector, Selector } from '@reduxjs/toolkit';
import { useFeatureContext } from 'context/FeatureContext';
import { WidgetPoi } from 'extensions/widget/models/widget-poi-provider-type';
import { usePrintContext } from 'features/print-menu/hooks/use-print-context';
import { mapValues, uniq } from 'lodash';
import { useCallback, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import type { Dictionary, Place, PlacerEntityModel } from '@placer-ui/types';
import { areDatesEqual } from 'utils/date/date';
import { useWidgetPOIsList, useWidgetVenueSelector } from './use-widget-hooks';
import { useWidgetPrintMode } from 'extensions/widget/hooks/use-widget-context-hooks';

const useFeatureContextWithColor = () => {
    const poiContext = useFeatureContext();
    const isPrintMode = useWidgetPrintMode();
    const { colorsConfig } = usePrintContext();

    const transformResultPoi = useCallback(
        <T extends PlacerEntityModel<Place> | Place>(poi: T): T => {
            return (poi as PlacerEntityModel<Place>).uid
                ? {
                      ...poi,
                      color: colorsConfig[(poi as PlacerEntityModel<Place>).uid],
                  }
                : {
                      ...poi,
                      uid: (poi as Place).id || (poi as PlacerEntityModel<Place>).originalEntity.id,
                      color: colorsConfig[
                          (poi as Place).id || (poi as PlacerEntityModel<Place>).originalEntity.id
                      ],
                  };
        },
        [colorsConfig],
    );

    const transformEntityModel = useCallback(
        <T extends PlacerEntityModel<Place> | Place>(selector: Selector<unknown, T[]>) =>
            createSelector(selector, (items) => items.map<T>(transformResultPoi)),
        [transformResultPoi],
    );

    const transformEntityModelDict = useCallback(
        <T extends PlacerEntityModel<Place> | Place>(selector: Selector<unknown, Dictionary<T>>) =>
            createSelector(
                selector,
                (items) => mapValues(items, transformResultPoi) as Dictionary<T>,
            ),
        [transformResultPoi],
    );

    const transformWidgetPoiDict = useCallback(
        <T extends WidgetPoi | Place>(selector: Selector<unknown, Dictionary<T>>) =>
            createSelector(
                selector,
                (items) => mapValues(items, transformResultPoi) as Dictionary<T>,
            ),
        [transformResultPoi],
    );

    return useMemo(
        () =>
            isPrintMode && Object.keys(colorsConfig).length
                ? {
                      selectAll: transformEntityModel(poiContext.selectAll),
                      selectIds: poiContext.selectIds,
                      selectEntities: transformEntityModelDict(poiContext.selectEntities),
                      selectTotal: poiContext.selectTotal,
                      selectEntitiesAPIModel: transformWidgetPoiDict(
                          poiContext.selectEntitiesAPIModel,
                      ),
                      adapterType: poiContext.adapterType,
                  }
                : poiContext,
        [
            isPrintMode,
            poiContext,
            transformEntityModel,
            transformEntityModelDict,
            colorsConfig,
            transformWidgetPoiDict,
        ],
    );
};

export const useReportPlaces = () => {
    const poiContext = useFeatureContextWithColor();

    const {
        selectAll,
        selectIds,
        selectEntities,
        selectTotal,
        selectEntitiesAPIModel,
        adapterType,
    } = poiContext;

    const all = useSelector(selectAll, shallowEqual);
    const ids = useSelector(selectIds, shallowEqual) as string[];
    const entities = useSelector(selectEntities, shallowEqual);
    const total = useSelector(selectTotal, shallowEqual);
    const entitiesWidgetShape = useSelector(selectEntitiesAPIModel, shallowEqual);
    const allEntitiesWidgetShape = useMemo(
        () => Object.values(entitiesWidgetShape),
        [entitiesWidgetShape],
    );

    const uidMap = useMemo(() => {
        let tmpMap: Dictionary<string> = {};
        Object.entries(entitiesWidgetShape).forEach(([uid, { poiId }]) => {
            tmpMap[poiId] = uid;
        });
        return tmpMap;
    }, [entitiesWidgetShape]);

    // Void-entity && salesForecast, adapter work differently from all other adapters
    // src/features/void-analysis/store/adapter.ts
    // also this changed caused from search bar new prop 'notInReport' - redundant at Void
    const excludedEntityModels =
        adapterType === 'voidAnalysis' ||
        adapterType === 'salesForecast' ||
        adapterType === 'siteSelection';

    const filteredAll = useMemo(() => all.filter((entity) => !entity.notInReport), [all]);

    const filteredIds = useMemo(() => filteredAll.map((entity) => entity.uid), [filteredAll]);

    const filteredEntities = useMemo(
        () =>
            Object.keys(entities).reduce<Record<string, any>>((acc, key) => {
                if (!entities[key].notInReport) {
                    acc[key] = entities[key];
                }
                return acc;
            }, {}),
        [entities],
    );

    return useMemo(
        () => ({
            ids: excludedEntityModels ? ids : filteredIds,
            all: excludedEntityModels ? all : filteredAll,
            entities: excludedEntityModels ? entities : filteredEntities,
            total: excludedEntityModels ? total : filteredIds.length,
            entitiesWidgetShape,
            allEntitiesWidgetShape,
            uidMap,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [ids, all, entities, total, entitiesWidgetShape, allEntitiesWidgetShape, uidMap],
    );
};

export const useSelectPoiFilters = () => {
    const { all } = useReportPlaces();

    const poiFilters = useMemo(() => all.map((poi) => poi.filters), [all]);

    return poiFilters;
};

export const useIsSelfCompare = () => {
    const { uidMap } = useReportPlaces();
    return uniq(Object.keys(uidMap)).length === 1;
};

export const useReportDateRange = () => {
    const { allEntitiesWidgetShape } = useReportPlaces();

    if (!allEntitiesWidgetShape.length) {
        return null;
    }

    const primaryEntity = allEntitiesWidgetShape[0];
    const primaryEntityStartDate = primaryEntity.filters.date_start;
    const primaryEntityEndDate = primaryEntity.filters.date_end;

    const isSameDateRange = allEntitiesWidgetShape.every(
        ({ filters: { date_start, date_end } }) =>
            areDatesEqual(primaryEntityStartDate, date_start) &&
            areDatesEqual(primaryEntityEndDate, date_end),
    );

    if (!isSameDateRange) {
        return null;
    }

    return {
        startDate: primaryEntityStartDate,
        endDate: primaryEntityEndDate,
    };
};

export const useVenuesSelectorWithDefaultValue = (): Dictionary<boolean> => {
    const venuesSelector = useWidgetVenueSelector();
    const selectedIds = useWidgetPOIsList();

    return useMemo(() => {
        if (venuesSelector) {
            return venuesSelector;
        }

        return selectedIds.reduce<Dictionary<boolean>>((acc, uid) => {
            acc[uid] = true;
            return acc;
        }, {});
    }, [venuesSelector, selectedIds]);
};
