import { ScoreBenchmark } from 'features/site-selection/common/types/score-benchmark';
import { CascaderProps } from 'antd';

export const includeExcludeTenantsMilesOptions = ['0.5', '1', '2', '3', '5', '10'];

export const benchmarkTypeFilterOptions = (isTag = false): CascaderProps['options'] => [
    {
        value: 'chain_nationwide',
        label: `All ${isTag ? 'Tag' : 'Chain'} Locations`,
    },
    {
        label: 'Sites in Market',
        value: 'sites_in_market',
        children: [
            {
                value: 'nationwide',
                label: 'Nationwide',
            },
            {
                value: 'state',
                label: 'State',
            },
            {
                value: 'dma',
                label: 'DMA',
            },
            {
                value: 'cbsa',
                label: 'CBSA',
            },
        ],
    },
];

export enum BENCHMARK_TYPE_OPTIONS {
    nationwide = 'nationwide',
    dma = 'dma',
    state = 'state',
    cbsa = 'cbsa',
    chainNationwide = 'chain_nationwide',
}

export const BENCHMARK_TYPE_TITLES: Record<ScoreBenchmark, string> = {
    nationwide: 'Nationwide',
    state: 'State',
    dma: 'DMA',
    cbsa: 'CBSA',
    chain_nationwide: 'Chain Locations',
};
