import React, { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './date-picker-list.module.scss';
import { RangeValue } from '@placer-ui/components';
import { Moment } from 'moment';

export interface ListItem<ItemKeys> {
    readonly key: ItemKeys;
    readonly label: string;
    isPanel?: boolean;
    values?: RangeValue<Moment>;
}

interface DatePickerListProps<ItemKeys> {
    dataSource: Readonly<Array<ListItem<ItemKeys>>>;
    activeItem?: ItemKeys;
    icon?: ReactNode;
    className?: string;
    onSelect: (value: ItemKeys) => void;
}

export function DatePickerList<ItemKeys extends string>({
    dataSource,
    activeItem,
    icon,
    className,
    onSelect,
}: DatePickerListProps<ItemKeys>) {
    return (
        <ul className={classNames(styles.list, className)}>
            {dataSource.map((item) => (
                <li
                    key={item.key}
                    className={classNames(styles.item, {
                        [styles.active]: activeItem === item.key,
                    })}
                    onClick={() => onSelect(item.key)}
                >
                    <span>{item.label}</span>
                    {item.isPanel ? icon : null}
                </li>
            ))}
        </ul>
    );
}
