/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

type MicIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type MicIconProps = SVGProps<SVGSVGElement> & MicIconUniqueProps;

export const MicIcon = function MicIcon({ width = 12, height = 16, fill = '#49565D', ...props }: MicIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 12 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.85722 3.25929C2.85722 1.45923 4.26433 0 6.00009 0C7.73585 0 9.14295 1.45924 9.14295 3.2593V7.38383C9.14295 9.18389 7.73585 10.6431 6.00009 10.6431C4.26433 10.6431 2.85722 9.18389 2.85722 7.38383V3.25929ZM8.28584 3.2593V7.38383C8.28584 8.69298 7.26248 9.75426 6.00009 9.75426C4.7377 9.75426 3.71433 8.69298 3.71433 7.38383V3.25929C3.71433 1.95014 4.7377 0.888865 6.00009 0.888865C7.26247 0.888865 8.28584 1.95014 8.28584 3.2593Z"
                fill={fill}
            />
            <path
                d="M1.71453 7.36834C1.71453 7.12288 1.52266 6.9239 1.28598 6.9239C1.04929 6.9239 0.857422 7.12288 0.857422 7.36834C0.857422 10.1745 2.93023 12.4797 5.57153 12.7066V15.1111H3.64293C3.40625 15.1111 3.21438 15.3101 3.21438 15.5556C3.21438 15.801 3.40625 16 3.64293 16H8.35723C8.59392 16 8.78579 15.801 8.78579 15.5556C8.78579 15.3101 8.59392 15.1111 8.35723 15.1111H6.42864V12.7067C9.07013 12.4799 11.1431 10.1747 11.1431 7.36834C11.1431 7.12288 10.9513 6.9239 10.7146 6.9239C10.4779 6.9239 10.286 7.12288 10.286 7.36834C10.286 9.83781 8.36531 11.8361 6.00028 11.8361C3.63524 11.8361 1.71453 9.83781 1.71453 7.36834Z"
                fill={fill}
            />
        </svg>
    );
};
MicIcon.__tags = [] as string[];
