/* eslint-disable max-len */
import React, { SVGProps } from 'react';

export type SettingsIconUniqueProps = {
    width?: number | string;
    height?: number | string;
    colorBottom?: string;
    colorTop?: string;
    colorStroke?: string;
};

type SettingsIconProps = SVGProps<SVGSVGElement> & SettingsIconUniqueProps;

export const SettingsIcon = ({
    width = 18,
    height = 18,
    colorBottom = 'var(--light-gray-2)',
    colorTop = 'white',
    colorStroke = 'var(--primary-gray)',
    ...props
}: SettingsIconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 18 18"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <g>
                <g>
                    <g>
                        <g>
                            <path
                                style={{ transition: 'fill .3s' }}
                                fill={colorTop}
                                d="M9 12.178c-1.755 0-3.178-1.423-3.178-3.178S7.245 5.823 9 5.823 12.178 7.245 12.178 9c0 1.755-1.423 3.178-3.178 3.178m8.364-4.541l-1.882-.67-.461-1.112.857-1.805c.071-.15.041-.33-.076-.446l-1.406-1.405c-.117-.118-.295-.148-.446-.077l-1.804.858-1.113-.461-.67-1.883C10.308.48 10.16.375 9.994.375H8.006c-.166 0-.314.105-.369.26l-.669 1.883-1.114.462-1.805-.858c-.15-.071-.328-.041-.445.077L2.198 3.604c-.117.117-.148.296-.076.446l.857 1.804-.46 1.113-1.883.67c-.157.056-.261.203-.261.369v1.988c0 .166.104.314.261.37l1.882.668.462 1.114-.858 1.804c-.072.15-.041.329.076.447l1.406 1.405c.117.117.295.147.445.076l1.805-.857 1.113.46.67 1.884c.055.156.203.26.369.26h1.988c.166 0 .314-.104.369-.26l.669-1.882 1.114-.462 1.804.857c.151.07.329.04.446-.076l1.406-1.405c.117-.117.147-.296.076-.447l-.857-1.803.46-1.114 1.883-.67c.157-.055.261-.203.261-.37V8.007c0-.166-.104-.313-.261-.37"
                                transform="translate(-1099.000000, -213.000000) translate(40.000000, 171.000000) translate(1047.000000, 32.000000) translate(12.000000, 10.000000)"
                            />
                            <path
                                style={{ transition: 'fill .3s' }}
                                fill={colorBottom}
                                d="M17.625 9.994l-2.144 1.039-.46 1.114.857 1.803c.071.15.041.33-.076.447l-1.406 1.405c-.117.117-.295.147-.446.076l-1.804-.857-1.114.462-.669 1.882c-.055.156-.203.26-.369.26H8.006c-.166 0-.314-.104-.369-.26l-.67-1.884-1.113-.46-1.805.857c-.15.07-.328.04-.445-.076l-1.406-1.405c-.117-.118-.148-.296-.076-.447l.858-1.804-.462-1.114L.375 9.994h5.606c.417 1.268 1.611 2.184 3.019 2.184 1.408 0 2.602-.916 3.02-2.184h5.605z"
                                transform="translate(-1099.000000, -213.000000) translate(40.000000, 171.000000) translate(1047.000000, 32.000000) translate(12.000000, 10.000000)"
                            />
                            <path
                                style={{ transition: 'stroke .3s' }}
                                stroke={colorStroke}
                                strokeLinecap="round"
                                strokeWidth=".75"
                                d="M12.178 9c0 1.755-1.423 3.178-3.178 3.178S5.823 10.755 5.823 9 7.245 5.822 9 5.822c1.755 0 3.178 1.423 3.178 3.178z"
                                transform="translate(-1099.000000, -213.000000) translate(40.000000, 171.000000) translate(1047.000000, 32.000000) translate(12.000000, 10.000000)"
                            />
                            <path
                                style={{ transition: 'stroke .3s' }}
                                stroke={colorStroke}
                                strokeLinecap="round"
                                strokeWidth=".75"
                                d="M17.625 9.776V8.223c0-.296-.187-.56-.466-.659l-1.677-.597-.461-1.113.764-1.608c.127-.268.072-.587-.138-.796L14.55 2.352c-.209-.21-.529-.264-.796-.138l-1.608.765-1.113-.46L10.436.84c-.099-.279-.364-.465-.66-.465H8.224c-.297 0-.561.186-.66.465l-.596 1.677-1.114.462-1.608-.765c-.268-.126-.586-.072-.796.138L2.352 3.45c-.209.21-.264.528-.137.796l.764 1.607-.46 1.113-1.679.598c-.278.1-.465.363-.465.66v1.552c0 .296.187.561.465.66l1.678.596.462 1.113-.765 1.61c-.127.266-.072.584.137.794l1.098 1.098c.21.21.528.265.796.138l1.608-.765 1.113.461.597 1.678c.099.28.363.466.66.466h1.552c.296 0 .561-.187.66-.466l.596-1.677 1.114-.462 1.608.765c.267.127.586.072.796-.138l1.097-1.098c.21-.209.265-.528.138-.795l-.764-1.608.46-1.113 1.678-.598c.279-.098.466-.363.466-.659z"
                                transform="translate(-1099.000000, -213.000000) translate(40.000000, 171.000000) translate(1047.000000, 32.000000) translate(12.000000, 10.000000)"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
