import React, { PropsWithChildren, useMemo, useRef, useEffect } from 'react';
import classNames from 'classnames';
import {
    GoogleMap as ReactGoogleMap,
    GoogleMapProps,
    StreetViewPanorama,
} from '@react-google-maps/api';
import styles from './google-map.module.scss';
import {
    defaultMapOptions,
    defaultVectorMapOptions,
} from 'ui-components/google-map/google-map-default-options';
import { useGoogleMapsChangeMetersToFeet } from 'ui-components/google-map/hooks/google-maps-hooks';
import { refreshZoom } from 'ui-components/google-map/utils';
import { MapRenderType } from 'ui-components/google-map/types/types';
import { useSplunkCallback } from 'hooks/use-splunk-callback/use-splunk-callback';
import { useSendSplunkOnMapLoad } from 'hooks/use-send-splunk-on-map-Load/use-send-splunk-on-map-load';
import { GESTURE_HANDLING_OPTIONS } from 'shared/constants/google-map';
import { useFeatureFlags } from 'hooks/feature-flags/use-feature-flags';
import { getGoogleMapPermissions } from 'utils/get-google-map-permissions/get-google-map-permissions';

type MapRenderTypeProps = {
    type?: MapRenderType;
    source?: string;
    shouldRenderMap?: boolean;
};

export type GoogleMapComponentProps = PropsWithChildren<GoogleMapProps> & MapRenderTypeProps;

export const GoogleMap = <PropsType extends GoogleMapComponentProps = GoogleMapComponentProps>({
    type = 'raster',
    children,
    options,
    mapContainerClassName,
    source, // source means in which widget/page the google-map is used,added for splunk events.
    shouldRenderMap,
    ...props
}: MapRenderTypeProps & PropsType) => {
    const { set_gesture_handling_cooperative_by_default } = useFeatureFlags();
    const ref = useRef(null);
    const sendSplunk = useSplunkCallback();
    const sendSplunkOnMapLoad = useSendSplunkOnMapLoad(source);
    const { hasDisabledMapsPermission } = getGoogleMapPermissions();
    const isMapHidden = hasDisabledMapsPermission && !shouldRenderMap;
    useGoogleMapsChangeMetersToFeet(ref);
    const mapOptions = useMemo(() => {
        return set_gesture_handling_cooperative_by_default
            ? {
                  ...(type === 'raster' ? defaultMapOptions : defaultVectorMapOptions),
                  ...options,
                  gestureHandling: options?.gestureHandling ?? GESTURE_HANDLING_OPTIONS.cooperative,
              }
            : {
                  ...(type === 'raster' ? defaultMapOptions : defaultVectorMapOptions),
                  ...options,
              };
    }, [options, set_gesture_handling_cooperative_by_default, type]);
    const onMapLoadLayers = (map: google.maps.Map) => {
        refreshZoom(map);
        sendSplunkOnMapLoad();
        props.onLoad?.(map);
    };

    useEffect(() => {
        const handleGoogleLogoClick = (event: MouseEvent) => {
            const targetHtmlElm = event.target as HTMLImageElement;
            if (targetHtmlElm.alt === 'Google') {
                sendSplunk({
                    action: 'Google Logo clicked',
                });
            }
        };

        document.addEventListener('click', handleGoogleLogoClick, true);
        return () => {
            document.removeEventListener('click', handleGoogleLogoClick, true);
        };
    }, [sendSplunk]);

    if (isMapHidden) {
        return null;
    }

    return (
        <ReactGoogleMap
            {...props}
            options={mapOptions}
            onLoad={onMapLoadLayers}
            mapContainerClassName={classNames(styles.map, mapContainerClassName)}
            ref={ref}
        >
            <StreetViewPanorama
                onPanoChanged={() =>
                    sendSplunk({
                        action: 'Streetview pano changed',
                    })
                }
            />
            {children}
        </ReactGoogleMap>
    );
};
