import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

import { IconProps } from 'components/assets/Icons/Icons';

/* eslint-disable max-len */
export const HomeImprovementIconSvg = (
    props: CustomIconComponentProps | SVGProps<SVGSVGElement>,
) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 17 16">
        <g fill="currentColor" fillRule="evenodd">
            <path fillRule="nonzero" d="M11.975 11v5h2.024v-5z" />
            <path d="M11.3 3.734v3.554h4.995c-.313 1.521-1.675 2.666-3.308 2.666-1.863 0-3.374-1.492-3.374-3.333 0-1.234.679-2.311 1.687-2.887zm3.375 0a3.33 3.33 0 011.67 2.553h-1.67zM4.213 3.8c0-.626-.507-1.133-1.133-1.133H3.05c-.626 0-1.133.507-1.133 1.133V4H.83a.666.666 0 01-.667-.667V.667C.162.298.46 0 .83 0h1.088v.2c0 .584.442 1.065 1.01 1.127l.123.006h.03c.625 0 1.132-.507 1.132-1.133V0h2.033a.68.68 0 01.264.054C8.207.356 9.517 1.9 9.607 3.79l.005.21h-.675c0-.736-.597-1.333-1.333-1.333H7.57c-.736 0-1.333.597-1.333 1.333v1.333H4.212zm0 2.533h2.025V9H4.212zM3.537 10h3.375v4.667c0 .736-.597 1.333-1.333 1.333H4.87a1.333 1.333 0 01-1.333-1.333V10z" />
        </g>
    </svg>
);

export const HomeImprovementIcon = (props: IconProps) => (
    <Icon component={HomeImprovementIconSvg} {...props} />
);
