import { useCallback } from 'react';

import { useAppDispatch } from 'store/app-store-hooks';
import { deleteStarredReport } from 'store/star-report/actions/delete-starred-report';
import { openStarredReport } from 'store/star-report/actions/open-starred-report';
import { saveStarredReport } from 'store/star-report/actions/save-starred-report';
import { updateStarredReport } from 'store/star-report/actions/update-starred-report';
import { FunctionFirstParamType } from 'types/typescript-utils';
import { updateStarredReportLastViewedDate } from 'store/star-report/actions/update-starred-report-last-viewed-date';
import { getStarredReports } from 'store/star-report/actions/get-starred-reports';

export const useStarReportActions = () => {
    const dispatch = useAppDispatch();

    const saveReport = useCallback(
        (props: FunctionFirstParamType<typeof saveStarredReport>) => {
            dispatch(saveStarredReport(props));
        },
        [dispatch],
    );
    const updateReport = useCallback(
        (report: FunctionFirstParamType<typeof updateStarredReport>) => {
            dispatch(updateStarredReport(report));
        },
        [dispatch],
    );
    const removeReport = useCallback(
        (report: FunctionFirstParamType<typeof deleteStarredReport>) => {
            dispatch(deleteStarredReport(report));
        },
        [dispatch],
    );
    const getReports = useCallback(() => {
        dispatch(getStarredReports());
    }, [dispatch]);

    const openReport = useCallback(
        (options: FunctionFirstParamType<typeof openStarredReport>) => {
            dispatch(updateStarredReportLastViewedDate(options.report));
            dispatch(openStarredReport(options));
        },
        [dispatch],
    );

    return {
        save: saveReport,
        update: updateReport,
        removeReport,
        getReports,
        openReport,
    };
};
