/* eslint-disable max-len */
import React from 'react';

export function WeatherThunderstormIcon() {
    return (
        <svg width="70" height="55" viewBox="0 0 70 55" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M42.3389 24C41.5504 24 40.8356 24.4632 40.5134 25.1829L35.4346 36.5291C34.5463 38.5138 35.9984 40.7548 38.1728 40.7548H40.9996C42.2301 40.7548 43.1688 41.8554 42.9746 43.0705L41.2676 53.7487C41.1626 54.4053 41.6698 55 42.3347 55C42.7107 55 43.0597 54.8046 43.2561 54.484L53.3482 38.016C54.1648 36.6834 53.2059 34.971 51.6429 34.971H48.0411C46.5926 34.971 45.6244 33.4789 46.2147 32.1561L49.8537 24H42.3389Z" fill="#FFD89E"/>
<path d="M16.3786 42.5909C16.7049 41.8295 16.3522 40.9476 15.5908 40.6213C14.8293 40.295 13.9475 40.6477 13.6212 41.4091L10.6212 48.4091C10.2948 49.1706 10.6476 50.0524 11.409 50.3787C12.1705 50.7051 13.0523 50.3523 13.3786 49.5909L16.3786 42.5909Z" fill="#6FA2CE"/>
<path d="M23.3786 42.5909C23.7049 41.8295 23.3522 40.9476 22.5908 40.6213C21.8293 40.295 20.9475 40.6477 20.6212 41.4091L16.1212 51.9091C15.7948 52.6706 16.1476 53.5524 16.909 53.8787C17.6705 54.2051 18.5523 53.8523 18.8786 53.0909L23.3786 42.5909Z" fill="#6FA2CE"/>
<path d="M35.5195 43.1213C36.2809 43.4476 36.6337 44.3295 36.3073 45.0909L32.8788 53.0909C32.5524 53.8523 31.6706 54.2051 30.9092 53.8787C30.1477 53.5524 29.795 52.6706 30.1213 51.9091L33.5499 43.9091C33.8762 43.1477 34.758 42.795 35.5195 43.1213Z" fill="#6FA2CE"/>
<path d="M30.3786 42.5909C30.7049 41.8295 30.3522 40.9476 29.5908 40.6213C28.8293 40.295 27.9475 40.6477 27.6212 41.4091L24.6212 48.4091C24.2948 49.1706 24.6476 50.0524 25.409 50.3787C26.1705 50.7051 27.0523 50.3523 27.3786 49.5909L30.3786 42.5909Z" fill="#6FA2CE"/>
<path d="M33.1703 37.9321L26.646 37.9158H5.92549C3.49312 35.2731 2.00925 31.757 2 27.8931C2.01589 21.2206 6.38919 15.5768 12.4161 13.6654C13.839 13.2162 15.3544 12.9739 16.927 12.9739C17.8352 12.9739 18.7228 13.0546 19.5835 13.2086C23.5448 13.9235 26.9639 16.2059 29.1785 19.3958C29.4935 19.8494 30.1166 19.9619 30.5703 19.6469C31.0239 19.3319 31.1364 18.7088 30.8214 18.2551C28.7151 15.2214 25.6429 12.9082 22.0502 11.7643C25.4995 6.48523 31.468 3 38.253 3C48.2969 3 56.6348 11.1227 57.5014 21.0199L57.6612 22.8455H60.3979C64.6012 22.8455 67.9999 26.2428 67.9999 30.423C67.9999 34.602 64.6023 37.999 60.3997 38H60.3979L55.5168 37.9878C55.5126 38.0033 55.5084 38.0187 55.5041 38.0341C56.1885 35.5931 54.3782 32.971 51.6429 32.971H48.0411L51.6802 24.8149C52.2704 23.4921 51.3023 22 49.8538 22H42.3389C40.762 22 39.3323 22.9265 38.688 24.3658L33.6092 35.712C33.2806 36.4461 33.1441 37.2013 33.1703 37.9321Z" fill="#D0D0D0"/>
</svg>

    );
}
WeatherThunderstormIcon.__tags = [] as string[];
