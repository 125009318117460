import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';
import styles from './limited-feature.module.scss';

import { LockedFunctionalityOverlay } from 'extensions/locked-functionality-overlay';
import { LockIcon } from 'components/assets/Icons/Icons';
import { DynamicOpenUpgradePlanPopupProps } from 'store/app-ui/actions';

interface LimitedFeatureProps {
    isLimited: boolean;
    position?: 'default' | 'under' | 'icon' | 'panel';
    addPaddingRight?: boolean;
    className?: string;
    wrapperClassName?: string;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    upgradePopupProps?: DynamicOpenUpgradePlanPopupProps;
}

export const LimitedFeature = ({
    isLimited,
    position = 'default',
    addPaddingRight = false,
    className,
    wrapperClassName,
    children,
    onMouseEnter,
    onMouseLeave,
    upgradePopupProps,
}: PropsWithChildren<LimitedFeatureProps>) => {
    return isLimited ? (
        <div
            className={classNames(
                styles.wrapper,
                { [styles.paddingRight]: addPaddingRight },
                className,
            )}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {children}
            <LockedFunctionalityOverlay
                isLocked={isLimited}
                className={classNames(styles.overlay, styles[position])}
                upgradePopupProps={upgradePopupProps}
            >
                <LockIcon className={styles.icon} />
            </LockedFunctionalityOverlay>
        </div>
    ) : (
        <div className={wrapperClassName} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            {children}
        </div>
    );
};
