import { useCallback } from 'react';

import { RecentEntitiesModel } from 'core/services/recent-entities-service/recent-entities-model';
import { topSearchOpenReport } from 'store/header/search/actions/top-search-open-report';
import { topSearchOpenReportByEnterClick } from 'store/header/search/actions/top-search-open-report-by-enter-click';

import { topSearchSearchByTerm } from 'store/header/search/actions/top-search-search-by-term';
import { TopSearchApiOptions } from 'store/header/search/models/top-search-api-options';
import { setTopSearchTerm } from 'store/header/search/actions/top-search-action-creators';
import { useAppDispatch } from 'store/app-store-hooks';
import { useAuthActions } from 'store/auth/use-auth-actions';
import { topSearchSlice } from 'store/header/search/top-search-slice';
import { FunctionFirstParamType } from 'types/typescript-utils';

export const useHeaderActions = () => {
    const { logout } = useAuthActions();
    const dispatch = useAppDispatch();

    const search = useCallback(
        (options: TopSearchApiOptions) => {
            dispatch(topSearchSearchByTerm(options));
        },
        [dispatch],
    );

    const resetSearchTerm = useCallback(() => {
        dispatch(setTopSearchTerm(''));
    }, [dispatch]);

    const setSearchTerm = useCallback(
        (term: string) => {
            dispatch(setTopSearchTerm(term));
        },
        [dispatch],
    );

    const setSearchLoading = useCallback(
        (loading: boolean) => {
            dispatch(setSearchLoading);
        },
        [dispatch],
    );

    const openReport = useCallback(
        (entity: RecentEntitiesModel) => {
            dispatch(topSearchOpenReport(entity));
        },
        [dispatch],
    );

    const resetSearchState = useCallback(() => {
        dispatch(topSearchSlice.actions.resetState());
    }, [dispatch]);

    const selectSearchItemByEnter = useCallback(
        (options: FunctionFirstParamType<typeof topSearchOpenReportByEnterClick>) => {
            dispatch(topSearchOpenReportByEnterClick(options));
        },
        [dispatch],
    );

    return {
        logout,
        search,
        setTerm: setSearchTerm,
        setSearchLoading,
        resetSearchTerm,
        openReport,
        resetSearchState,
        onKeyboardEnter: selectSearchItemByEnter,
    };
};
