import _ from 'lodash/fp';

import type {
    Billboard,
    Chain,
    CoverageContent,
    DateLabels,
    Filter,
    FilterConfigurationPayload,
    PlacerEntityModel,
    PlacerEntityWrapper,
    Venue,
} from '@placer-ui/types';

type ChainEntityModelMapperArgs<T> = {
    entityWrapper: PlacerEntityWrapper<T>;
    startDate: string;
    endDate: string;
    dateLabel?: DateLabels;
    config?: FilterConfigurationPayload;
    color?: string;
    uid?: string;
    attributes?: Filter['attributes'];
    coverage?: CoverageContent;
};

// TODO: this function should be used instead of ChainEntityModelMapper
export const mapEntityWrapperToEntityModel = <R>({
    entityWrapper,
    startDate,
    endDate,
    dateLabel,
    color = '',
    uid,
    attributes = {} as Filter['attributes'],
    config = undefined,
    coverage,
}: ChainEntityModelMapperArgs<R>): PlacerEntityModel<R> => {
    return {
        originalEntity: entityWrapper.info,
        uid: uid || _.uniqueId('entity_'),
        overview: entityWrapper.overview || undefined,
        color,
        filters: {
            attributes,
            date_start: startDate,
            date_end: endDate,
            date_label: dateLabel,
            config,
        },
        coverage,
    };
};

export type PlacerEntityModels = PlacerEntityModel<Chain | Venue | Billboard>;
