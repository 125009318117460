import React, { useMemo } from 'react';
import { NearbyEntityOption } from 'features/void-analysis/sections/empty-space/types/methods-selector-modal-types';
import { Button } from 'ui-components/button/button';
import styles from './footer-menu.module.scss';
import { EntityOption } from 'features/void-analysis/sections/empty-space/types/run-void-analysis-button-types';
import { useRunVoidReport } from 'features/void-analysis/sections/add-new-report/hooks/add-new-report-hooks';
import { CardMethodNames } from 'features/void-analysis/sections/empty-space/types/method-card';

export type RunVoidReport = (id?: string, method?: CardMethodNames) => void;

type FooterMenuProps = {
    methodSelected: CardMethodNames;
    nearByEntitySelected: NearbyEntityOption | undefined;
    locationRequest: EntityOption;
    onRunVoidReport: RunVoidReport;
};
export const FooterMenu = ({
    methodSelected,
    nearByEntitySelected,
    locationRequest,
    onRunVoidReport,
}: FooterMenuProps) => {
    const runVoidReport = useRunVoidReport();
    const customMethodSelected = useMemo(() => {
        if (methodSelected === 'tta_nearby_combination') {
            return nearByEntitySelected?.ta_entity_id === '0' ? 'tta' : 'nearby_entity_ta';
        }
        return methodSelected;
    }, [methodSelected, nearByEntitySelected]);
    const onClick = async () => {
        onRunVoidReport(locationRequest.entity_id, customMethodSelected);
        await runVoidReport(
            {
                ...locationRequest,
                ...(customMethodSelected === 'nearby_entity_ta' ? nearByEntitySelected : undefined),
                ta_method: customMethodSelected,
            },
            {
                openInNewTab: false,
                verifyOrderIdExists: false,
            },
        );
    };
    return (
        <div className={styles.container}>
            <div className={styles.description}>
                This can be altered later via advanced settings, located at the report’s top right
                corner.
            </div>
            <Button type="brand-primary-full" onClick={onClick}>
                Run Report
            </Button>
        </div>
    );
};
