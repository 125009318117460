import { useCallback } from 'react';
import { AdvancedSettingsResponse } from 'features/void-analysis/common/types/server-responses';
import { FilterEntity } from 'features/void-analysis/common/types/filters';
import { CheckboxOption } from 'types/advanced-reports-analysis/filters';
import type { Dictionary } from '@placer-ui/types';
import {
    ADVANCED_SETTINGS_FIELDS,
    SCORE_FIELDS_TYPE,
    // eslint-disable-next-line max-len
} from 'features/void-analysis/sections/ranking-page/components/report-content/components/report-actions/components/advanced-settings-modal/constants';

export const useNormalizeAdvancedSettingsResponse = () => {
    return useCallback((data: AdvancedSettingsResponse): FilterEntity[] => {
        const { demographicAttributes, coTenancyThreshold } = ADVANCED_SETTINGS_FIELDS;

        const normalizedList: FilterEntity[] = [];
        const scoreComponentsKeys = Object.keys(data.score_components);
        scoreComponentsKeys.forEach((key) => {
            normalizedList.push({
                name: key,
                type: SCORE_FIELDS_TYPE,
                checked: !!data.score_components[key].weight,
            });
        });

        const demographicAttributeKeys = Object.keys(data.demog_attributes);
        const checkboxOptions: Dictionary<CheckboxOption> = {};
        demographicAttributeKeys.forEach((key) => {
            const option = data.demog_attributes[key];
            checkboxOptions[option.label] = {
                checked: !!option.weight,
                sub_options: null,
            };
        });

        normalizedList.push({
            name: demographicAttributes.name,
            type: demographicAttributes.type,
            options: checkboxOptions,
        });

        normalizedList.push({
            ...coTenancyThreshold,
            value: data.co_tenancy_threshold,
        } as FilterEntity);

        return normalizedList;
    }, []);
};
