import React, { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/app-store-hooks';
import { goTo } from 'router/go-to';
import { userCookieStorage } from 'core/authentification';
import { setUserFromCookieStorageActionCreator } from 'store/auth/actions/auth-action-creators';
import { userIsAnonymousSelector } from 'store/selectors/app-selectors';
import { useRouteParam } from 'hooks/use-query/use-query';
import { useIsFreemiumUser } from 'hooks/use-freemium-user/use-freemium-user';
import { Desktop, Mobile } from 'features/mobile-report/hoc/media-query';
import { MobileApp } from './mobile-app';
import { DesktopAppMemo } from './desktop-app';
import { useInitialStateQuery } from 'store/use-initial-state-query';
import { invalidateUser } from 'store/auth/actions/auth-logout/auth-logout';
import { RedirectAuth } from 'features/authentication/components/redirect-anonymous/redirect-auth';
import useOpenTelemetry from 'tracing/tracing';

export const Dashboard = () => {
    const dispatch = useAppDispatch();
    const userIsAnonymous = useSelector(userIsAnonymousSelector);
    const isFreemiumUser = useIsFreemiumUser();
    const nextUrl = useRouteParam('next_url');
    const platform = useRouteParam('platform');
    const queryClient = useQueryClient();
    const { isSuccess, error } = useInitialStateQuery();

    useOpenTelemetry();

    window.isUserFreemium = isFreemiumUser;

    useEffect(() => {
        if (platform === 'mobile') {
            invalidateUser(dispatch, queryClient);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isSuccess && nextUrl) {
            goTo(nextUrl.replace(`${window.location.origin}`, ''));
        } else if (userIsAnonymous === undefined && userCookieStorage.user) {
            dispatch(setUserFromCookieStorageActionCreator(userCookieStorage.user));
        }
    }, [userIsAnonymous, nextUrl, dispatch, isSuccess]);

    if (error?.status === 401) {
        invalidateUser(dispatch, queryClient);
        return <RedirectAuth />;
    }

    return (
        <>
            <Desktop>
                <DesktopAppMemo />
            </Desktop>
            <Mobile>
                <MobileApp />
            </Mobile>
        </>
    );
};
