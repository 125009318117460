import { CHAIN, INDUSTRY, PROPERTY } from 'core/constants/report-type';
import { StarredReport } from 'core/entities';
import { AppState } from 'store/store';
import _ from 'lodash/fp';

type SelectStarredReportsByTermAndFilterOptions = {
    term: string;
    filter?: typeof PROPERTY | typeof CHAIN | typeof INDUSTRY;
};

export const selectTermFromOptions = (
    state: AppState,
    { term }: SelectStarredReportsByTermAndFilterOptions,
) => term;

export const selectFilterFromOptions = (
    state: AppState,
    { filter }: SelectStarredReportsByTermAndFilterOptions,
) => filter;

export function selectPropertyStarredReports<ReportUIShape extends StarredReport>(
    reports: ReportUIShape[],
) {
    return reports.filter((report) => report.type === PROPERTY);
}

export function selectChainStarredReports<ReportUIShape extends StarredReport>(
    reports: ReportUIShape[],
) {
    return reports.filter((report) => report.type === CHAIN);
}

export function selectIndustryStarredReports<ReportUIShape extends StarredReport>(
    reports: ReportUIShape[],
) {
    return reports.filter((report) => report.type === INDUSTRY);
}

export function selectStarredReportsByType<ReportUIShape extends StarredReport>(
    reports: ReportUIShape[],
    propertyReports: ReportUIShape[],
    chainsReports: ReportUIShape[],
    industryReports: ReportUIShape[],
    filter?: typeof PROPERTY | typeof CHAIN | typeof INDUSTRY,
) {

    switch (filter){
        case PROPERTY:
            return propertyReports;
        case CHAIN:
            return chainsReports;
        case INDUSTRY:
            return industryReports;
        default:
            return reports;
    }

}

export function selectStarredReportsByTypeAndTerm<ReportUIShape extends StarredReport>(
    reportsByType: ReportUIShape[],
    term: string,
) {
    return reportsByType.filter((report) => {
        const regExp = new RegExp(_.escapeRegExp(term.trim()), 'i');
        return report.name.match(regExp);
    });
}
