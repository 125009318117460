import React, { useEffect } from 'react';
import { DashboardRouter } from 'router/router';
import { useUserLocation } from 'features/mobile-report/hooks/use-user-location';
import { userLocationSignal } from 'features/mobile-report/state/mobile-report.signals';
import { SkeletonLoader } from 'utils/skeleton-loader/skeleton-loader';

import 'shared/styles/elevio-mobile.scss';

const MobileLoader = <SkeletonLoader height="100vh" />;

export const MobileApp = () => {
    const userLocation = useUserLocation();

    useEffect(() => {
        userLocationSignal.value = userLocation;
    }, [userLocation]);

    return <DashboardRouter AppLoader={MobileLoader} RouteLoader={MobileLoader} />;
};
