import queryString from 'query-string';
import { GetAllPOIsResponse } from 'features/my-zone/created-pois/view-pois/types/server_responses';
import { fetchWrapper } from 'API/authentification/fetch-wrapper';
import {
    DeletePOIRequest,
    GetAllPOIRequest,
} from 'features/my-zone/created-pois/view-pois/types/types';
import pluralize from 'pluralize';
import { reportException } from 'core/exceptions';
import type {
    CreatePOIResponse,
    CustomEntity,
    ExternalVenueProviders,
    PlacerEntityWrapper,
    Venue,
} from '@placer-ui/types';
import { simplePollRequest } from 'API/fetch/simple-fetch-no-error-handling';
import { getAppApiEndpoint } from 'core/default-endpoint-url';

export type CreatePOIProps = Pick<
    Venue,
    'name' | 'type' | 'address' | 'category' | 'sub_category' | 'geojson'
> & {
    provider: ExternalVenueProviders;
    signal?: AbortSignal;
};

export type StatusPOIProps = {
    id: string;
    type: string;
    signal?: AbortSignal;
};

type StatusPoiResponse = {
    error_code: number;
    result: string;
    request_id: string;
};

export const createdPOIsAPI = {
    getAllPOIs: async (payload: GetAllPOIRequest): Promise<PlacerEntityWrapper<CustomEntity>[]> => {
        const queryParams = queryString.stringify(payload, {
            skipEmptyString: true,
            skipNull: true,
        });
        const response = fetchWrapper<GetAllPOIsResponse>({
            method: 'GET',
            targetUrl: `/search?${queryParams}`,
        });
        let createdPOIs: PlacerEntityWrapper<CustomEntity>[] = [];
        await response
            .then(({ data }) => {
                createdPOIs = data as PlacerEntityWrapper<CustomEntity>[];
            })
            .catch((error) => {
                reportException(error, {
                    payload: {
                        ...payload,
                    },
                    target_url: `/search?${queryParams}`,
                    method: 'GET',
                });
            });
        return Promise.resolve(createdPOIs);
    },

    deletePOI: async ({ poiId, poiType }: DeletePOIRequest) =>
        fetchWrapper({
            targetUrl: `/custom-poi/v2/${poiType}/${poiId}/delete`,
            method: 'DELETE',
        }),
    createPOI: async ({
        name,
        type,
        address,
        category,
        sub_category,
        geojson,
        provider,
        signal,
    }: CreatePOIProps) =>
        fetchWrapper<CreatePOIResponse>({
            targetUrl: '/custom-poi/v2/create',
            method: 'POST',
            body: {
                name,
                type,
                address: {
                    formatted_address: address.formatted_address,
                    address: address.address,
                    city: address.city,
                    state: address.state,
                    state_code: address.state_code,
                    country: address.country,
                    country_code: address.country_code,
                    zip_code: address.zip_code,
                },
                category,
                sub_category,
                geojson,
                provider,
            },
            signal,
        }),
    statusPOI: async ({ id, type, signal }: StatusPOIProps) => {
        return simplePollRequest<StatusPoiResponse, {}>({
            targetUrl: `/custom-poi/v2/${pluralize(type)}/${id}/status`,
            method: 'GET',
            endPoint: getAppApiEndpoint(),
            signal,
        });
    },
};
