import { ChainEntityModel, ChainPOI } from 'core/entities';
import { TopVenues } from 'core/dto/top-venues';
import type { Chain } from '@placer-ui/types';

export interface ChainsReportSearchState {
    selectedChainsEntityModels: ChainEntityModel[];
    suggestedBrands: ChainPOI[];
    autoCompleteMatchingChains: Chain[];
    chainResultsEntityModels: ChainEntityModel[];
    potentiallyMoreSearchResultsExist: boolean;
    autoCompleteValue: string;
    term: string;
    selectedCategories: string[];
    selectedRegion: string;
    isOpen: boolean;
    activeChain: Chain | null;
    isTagsLoading: boolean;
    topVenues: TopVenues;
    isTopVenuesLoading: boolean;
    autoCompleteSearchResultsAreLoading: boolean;
    chainsSearchResultsAreLoading: boolean;
    isAutoCompleteMatchingChainsGotResults: boolean;
    deselectedSearchChains: ChainEntityModel[];
    myTagChains: ChainEntityModel[];
    companyTagChains: ChainEntityModel[];
    myTagsQuery: string;
    companyTagsQuery: string;
    potentiallyMoreMyTagResultsExist: boolean;
    potentiallyMoreCompanyTagResultsExist: boolean;
}

export const EMPTY_TOP_VENUES_OBJECT = {
    number_of_venues: 0,
    venues: [],
    monthly_ft: 0,
};

export const createChainsReportSearchState = (): ChainsReportSearchState => ({
    selectedChainsEntityModels: [],
    suggestedBrands: [],
    autoCompleteMatchingChains: [],
    chainResultsEntityModels: [],
    potentiallyMoreSearchResultsExist: true,
    autoCompleteValue: '',
    term: '',
    selectedCategories: [],
    selectedRegion: '',
    isOpen: false,
    activeChain: null,
    myTagChains: [],
    companyTagChains: [],
    isTagsLoading: false,
    topVenues: EMPTY_TOP_VENUES_OBJECT,
    isTopVenuesLoading: false,
    autoCompleteSearchResultsAreLoading: false,
    chainsSearchResultsAreLoading: false,
    isAutoCompleteMatchingChainsGotResults: false,
    deselectedSearchChains: [],
    myTagsQuery: '',
    companyTagsQuery: '',
    potentiallyMoreMyTagResultsExist: true,
    potentiallyMoreCompanyTagResultsExist: true,
});
