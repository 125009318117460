import type { Chain } from '@placer-ui/types';
export interface StateAutoCompleteViewModel {
    name: string;
    country: string;
}

export type ChainsSearchAutoCompleteOptionModel = Chain | StateAutoCompleteViewModel;

export const isChainsSearchAutoCompleteOptionAState = (
    optionModel: ChainsSearchAutoCompleteOptionModel,
): optionModel is StateAutoCompleteViewModel => {
    if (!optionModel) {
        return false;
    }
    return 'country' in optionModel;
};
