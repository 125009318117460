import {
    ElementRefObjects,
    PoiSelectionMapConfiguration,
    OverlayPoiObject,
    PlacePois,
    PoiSelectionContextState,
    PoiSelectionMenuItem,
    ResultsPagination,
    SetPoisListProps,
    SharedPoiSelectionState,
} from 'features/poi-selection-map/types/poi-selection-types';
import { defaultPoiSelectionConfiguration } from 'features/poi-selection-map/configuration/poi-selection-configuration';
import type { Dictionary } from '@placer-ui/types';
import { PointOfInterestFilters } from 'core/entities/point-of-interest-entities';
import { MAX_SEARCH_RESULTS } from 'features/poi-selection-map/constants/poi-selection-map-constants';
import { ResponseStatus } from 'API/common/types';

export const initialPoiSelectionContextState: PoiSelectionContextState = {
    categories: [],
    excludedSubcategories: [],
    isContentVisible: false,
    activeMenuItemId: '',
    configuration: defaultPoiSelectionConfiguration,
    currentItemConfiguration: undefined,
    poiHoverId: undefined,
    poiActiveId: undefined,
    sharedState: {
        companyTags: undefined,
        personalTags: undefined,
    },
    reportDates: {
        startDate: '',
        endDate: '',
    },
    poisList: undefined,
    poisListPerCurrentPage: undefined,
    additionalPois: undefined,
    overlayPois: {},
    enclosingComplexes: undefined,
    elementRefObjects: {
        mapContainer: {
            key: 'mapContainer',
            ref: null,
        },
        poiSelector: {
            key: 'poiSelector',
            ref: null,
        },
    },
    mapConfig: {
        useFitBounds: true,
    },
    mapRef: undefined,
    resultsPagination: {
        currentPage: 0,
        resultsPerPage: MAX_SEARCH_RESULTS,
        totalResults: undefined,
    },
    reportAccessError: {},
    isReportAccessLoading: false,
    setMenuItemId: (id: string) => {},
    setPoiHoverId: (id?: string) => {},
    setPoiActiveId: (id?: string) => {},
    setSharedState: (state: SharedPoiSelectionState) => {},
    setPoisList: (props: SetPoisListProps) => {},
    setAdditionalPoisList: (pois?: PlacePois) => {},
    setOverlayPoisList: (overlayPois: OverlayPoiObject[]) => {},
    setEnclosingComplexes: (enclosingComplexes: Dictionary<PointOfInterestFilters[]>) => {},
    setOverlayPoi: (overlayPoi: OverlayPoiObject) => {},
    setElementRefObjects: (object: ElementRefObjects) => {},
    clearPoisList: () => {},
    setMapRef: (map?: google.maps.Map) => {},
    setMapConfig: (options?: Partial<PoiSelectionMapConfiguration>) => {},
    setCurrentItemConfiguration: (currentItem?: PoiSelectionMenuItem) => {},
    setResultsPagination: (resultsPagination?: ResultsPagination) => {},
    setReportAccessError: (reportAccessError?: Dictionary<ResponseStatus | undefined>) => {},
    setIsReportAccessLoading: (isLoading: boolean) => {},
};
