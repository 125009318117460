import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

import { IconProps } from 'components/assets/Icons/Icons';

/* eslint-disable max-len */
export const TravelIconSvg = (props: CustomIconComponentProps | SVGProps<SVGSVGElement>) => (
    <svg {...props} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
        <g fill="currentColor" fillRule="evenodd">
            <path
                d="M10.5 9a.5.5 0 01.09.992L10.5 10h-9a.5.5 0 01-.09-.992L1.5 9h9z"
                fillRule="nonzero"
            />
            <path d="M10 7l2-4h-1L9 5H6.938l2.888-4h-.618a3.19 3.19 0 00-2.039.737L3.251 5H1a1 1 0 100 2" />
        </g>
    </svg>
);

export const TravelIcon = (props: IconProps) => <Icon component={TravelIconSvg} {...props} />;
