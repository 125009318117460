import React, { PropsWithChildren } from 'react';
import styles from './solutions-guide-popover.module.scss';
import { Divider, Heading, IconButton, UtilityCloseLightIcon } from '@placer-ui/components';
import { openElevioComponent } from 'utils/elevio/elevio';

type SolutionsGuidePopoverProps = {
    setOpen: (open: boolean) => void;
};

export const SolutionsGuidePopover = ({
    children,
    setOpen,
}: PropsWithChildren<SolutionsGuidePopoverProps>) => {
    const openHelpAndFeedback = () => {
        openElevioComponent('', 'home');
        setTimeout(() => {
            setOpen(false);
        }, 500);
    };

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <div className={styles.title}>
                    <Heading variant={'h3'} color={'white'}>
                        Solutions Guide
                    </Heading>
                </div>
                <div className={styles.actions}>
                    <div className={styles.helpAndFeedbackButton}>
                        <IconButton
                            Icon={<div>?</div>}
                            variant={'ghost'}
                            onClick={openHelpAndFeedback}
                        />
                    </div>
                    <Divider isVertical={true} />
                    <div className={styles.closeButton}>
                        <IconButton
                            Icon={<UtilityCloseLightIcon size={18} />}
                            variant={'ghost'}
                            onClick={() => setOpen(false)}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.body}>{children}</div>
        </div>
    );
};
