import { fetchWithBasePath } from 'API/authentification/fetch-wrapper';
import { getAppGatewayEndpoint } from 'core/default-endpoint-url';
import { isAutomationUserName } from 'shared/test-utils/automation-test';
import { BOOTSTRAP_TYPE, EVENT_TRIGGER, EVENT_TYPE, EVENT_TYPE_KEY } from 'telemetry/types';
import {
    pageNameFromURL,
    reportNameFromURL,
    sectionNameFromURL,
} from 'utils/get-from-url/get-from-url';

type TelemetryWindow = Window & typeof globalThis & { TELEMETRY_TIMING_STORAGE_KEY: string };

const STORAGE_OBJECT_NAME = (window as TelemetryWindow).TELEMETRY_TIMING_STORAGE_KEY;

const EVENT_TYPES: Record<EVENT_TYPE_KEY, EVENT_TYPE> = {
    ENDPOINT: 'BE_Endpoint',
    COMPONENT_LOAD: 'ComponentLoad',
    BOOTSTRAP: 'Bootstrap',
    EXPLORE_LIST_SEARCH_EXPERIENCE: 'EXPLORE_LIST_SEARCH_EXPERIENCE',
    EXPLORE_LIST_MAP_TRIGGER_EXPERIENCE: 'EXPLORE_LIST_MAP_TRIGGER_EXPERIENCE',
    SEARCH_API_REQUEST_MEASUREMENT: 'SEARCH_API_REQUEST_MEASUREMENT',
};

const LOG_LEVELS = {
    INFO: 'INFO',
    ERROR: 'ERROR',
};

const bulkTimeoutMs = 5000;

let bulkRequestItemsStorage: TelemetryMetricsData[] = [];

type LogLevelType = keyof typeof LOG_LEVELS;

type TelemetryMetricsData = {
    pageName: string;
    section?: string;
    component?: string;
    loadTimeMs: number;
    targetUrl?: string;
    eventType?: EVENT_TYPE;
    entityCount?: number;
    bootstrapType?: BOOTSTRAP_TYPE;
    trigger?: string;
    fullSection?: string;
    entityId?: string;
    entityType?: string;
    isMobile?: boolean;
};

type TelemetryReportsData = {
    appName: string;
    pageName: string;
    section?: string;
    component?: string;
    message: string;
    logLevel: LogLevelType;
};

export const telemetryApiService = {
    originServiceName: 'FE Dashboard',
    isFreemiumUser: () => window.isUserFreemium,
    pageName: () => {
        return pageNameFromURL();
    },
    subPageName: () => {
        const urlPath = window.location.pathname;
        if (urlPath.includes('ranking')) {
            return 'ranking';
        }
        if (urlPath.includes('match-analysis')) {
            return 'match-analysis';
        }

        return reportNameFromURL() || sectionNameFromURL();
    },
    getEventType: (eventType: EVENT_TYPE_KEY): EVENT_TYPE => {
        return EVENT_TYPES[eventType];
    },
    sendTelemetryMetrics: async (metricsData: TelemetryMetricsData[]) => {
        const reactAppGateway = getAppGatewayEndpoint();
        const telemetryMetricsUrl = `${reactAppGateway}/fe-telemetry/metrics/gauge`;

        try {
            await fetchWithBasePath({
                baseUrl: '',
                basePath: '',
                passedURL: telemetryMetricsUrl,
                options: {
                    method: 'POST',
                    body: JSON.stringify(metricsData),
                },
            });
        } catch (err) {
            console.error(err);
        }
    },

    sendTelemetryLog: async (monitorData: TelemetryReportsData) => {
        try {
            const reactAppGateway = getAppGatewayEndpoint();
            const telemetryReportsUrl = `${reactAppGateway}/fe-telemetry/reports/logs`;

            await fetchWithBasePath({
                baseUrl: '',
                basePath: '',
                passedURL: telemetryReportsUrl,
                options: {
                    method: 'POST',
                    body: JSON.stringify(monitorData),
                },
            });
        } catch (err) {
            console.error(err);
        }
    },

    reportStartEvent: (eventName: string) => {
        sessionStorage.setItem(STORAGE_OBJECT_NAME, JSON.stringify({ [eventName]: Date.now() }));
    },

    reportEndEvents: () => {
        const storageEventData = sessionStorage.getItem(STORAGE_OBJECT_NAME);
        if (storageEventData) {
            try {
                const eventData = JSON.parse(storageEventData);
                const pageName = telemetryApiService.pageName();
                const section = telemetryApiService.subPageName();
                const isAutomationUser = isAutomationUserName();
                const isFreemiumUser = telemetryApiService.isFreemiumUser();

                !isFreemiumUser &&
                    !isAutomationUser &&
                    Object.entries(eventData).forEach(([triggerEvent, tsValue]) => {
                        const windowOpener = window.opener;
                        const isAppEvent = triggerEvent === EVENT_TRIGGER.INITIAL_LOADING_TIME;
                        const bootstrapType = isAppEvent ? 'app' : 'report';
                        const trigger = isAppEvent
                            ? ''
                            : triggerEvent === EVENT_TRIGGER.PAGE_CONTENT_LOADING_TIME &&
                              windowOpener?.telemetryReport
                            ? EVENT_TRIGGER.EXPLORE_OPEN_NEW_TAB_LOADING_TIME
                            : triggerEvent;
                        const loadTimeMs = Date.now() - (tsValue as number);

                        telemetryApiService.sendTelemetryMetrics([
                            {
                                pageName,
                                section,
                                fullSection: `${pageName}/${section}`,
                                bootstrapType,
                                trigger,
                                eventType: telemetryApiService.getEventType('BOOTSTRAP'),
                                loadTimeMs,
                            },
                        ]);
                    });
                sessionStorage.removeItem(STORAGE_OBJECT_NAME);
            } catch (e) {
                console.log('No event ts in the storage ', e);
            }
        }
    },
    sendBulkTelemetryLog: (reportDataItem: TelemetryMetricsData) => {
        if (bulkRequestItemsStorage.length === 0) {
            bulkRequestItemsStorage.push(reportDataItem);

            window.setTimeout(() => {
                const isAutomationUser = isAutomationUserName();
                const isFreemiumUser = telemetryApiService.isFreemiumUser();

                !isFreemiumUser &&
                    !isAutomationUser &&
                    telemetryApiService.sendTelemetryMetrics(bulkRequestItemsStorage);

                bulkRequestItemsStorage = [];
            }, bulkTimeoutMs);
        } else {
            bulkRequestItemsStorage.push(reportDataItem);
        }
    },
};
