import React, { PropsWithChildren, ReactNode } from 'react';
import styles from './modal-header.module.scss';
import { HelpButton } from 'ui-components/help-button/help-button';
import { Button } from 'ui-components/button/button';
import { CloseIcon } from 'components/assets/Icons/CloseIcon';
import classnames from 'classnames';

type ModalHeaderProps = {
    title: ReactNode;
    elevioDataId?: string;
    onClose: () => void;
    classNames?: {
        container?: string;
        header?: string;
        closeAndInfo?: string;
        verticalSeparator?: string;
        closeButton?: string;
        bottomSeparator?: string;
    };
};

export const ModalHeader = ({
    children,
    title,
    elevioDataId,
    onClose,
    classNames,
}: PropsWithChildren<ModalHeaderProps>) => {
    return (
        <div className={classnames(styles.container, classNames?.container)}>
            <div className={classnames(styles.header, classNames?.header)}>
                <>{title}</>
                {children}
                <div className={classnames(styles.closeAndInfo, classNames?.closeAndInfo)}>
                    {!!elevioDataId && (
                        <HelpButton toolTipText="Learn More" elevioDataId={elevioDataId} />
                    )}
                    <div
                        className={classnames(
                            styles.verticalSeparator,
                            classNames?.verticalSeparator,
                        )}
                    />
                    <Button
                        type={'ghost'}
                        onClick={onClose}
                        className={classNames?.closeButton}
                        data-testid={'close-button'}
                        icon={<CloseIcon />}
                    />
                </div>
            </div>
            <div className={classnames(styles.bottomSeparator, classNames?.bottomSeparator)} />
        </div>
    );
};
