import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Dialog } from 'ui-components/dialog';
import { MethodsSelectorContent } from 'features/void-analysis/sections/empty-space/components/method-selection/method-selector-modal/components/methods-selector-content/methods-selector-content';
import {
    ElevioData,
    SelectorModalData,
    SelectorModalState,
} from 'features/void-analysis/sections/empty-space/types/methods-selector-modal-types';
import { ModalHeader } from 'ui-components/dialog/components/modal-header/modal-header';
import styles from './method-selector-modal.module.scss';
import {
    useGetElevioData,
    useGetMethodsAndVerifyOrderId,
} from 'features/void-analysis/sections/empty-space/hooks/use-method-selector-modal-hooks';
import { LocationOption } from 'features/void-analysis/sections/empty-space/types/run-void-analysis-button-types';
import { Loading } from 'ui-components/loading/loading-component';
import { UnexpectedError } from 'components/errors/unexpected-error/unexpected-error';
import { useRunVoidReport } from 'features/void-analysis/sections/add-new-report/hooks/add-new-report-hooks';
import { RunVoidReport } from 'features/void-analysis/sections/empty-space/components/method-selection/method-selector-modal/components/footer-menu/footer-menu';

const modalHeaderTitle = 'Select Void Analysis Method';

export type MethodSelectorProps = {
    onClose: () => void;
    visible: boolean;
    locationOption: LocationOption;
    modalContainer?: HTMLElement | null;
    onRunVoidReport: RunVoidReport;
};

export const MethodSelectorModal = ({
    onClose,
    visible,
    locationOption,
    modalContainer,
    onRunVoidReport,
}: MethodSelectorProps) => {
    const [selectorModalState, setResponseMethod] = useState<SelectorModalState>();
    const getMethodsAndVerifyOrderId = useGetMethodsAndVerifyOrderId();
    const hasRequestedData = useRef<boolean>(false);
    const elevioData: ElevioData = useGetElevioData(locationOption.entity_type!);
    const runVoidReport = useRunVoidReport();

    const getAndSetMethodData = useCallback(async () => {
        const responseMethodData = await getMethodsAndVerifyOrderId(locationOption);
        if (!responseMethodData) {
            //order ID exists, user has been redirected
            onRunVoidReport();
            return;
        }
        const methods = (responseMethodData as SelectorModalData)?.methods;
        const locationRequest = (responseMethodData as SelectorModalData)?.locationRequest;

        if (methods?.length === 1 && methods[0].name !== 'nearby_entity_ta') {
            onRunVoidReport(methods[0].name);
            await runVoidReport(
                {
                    ...locationRequest,
                    ta_method: methods[0].name,
                },
                {
                    openInNewTab: false,
                    verifyOrderIdExists: false,
                },
            );

            onClose();
        }

        setResponseMethod(responseMethodData);
    }, [getMethodsAndVerifyOrderId, locationOption, onClose, onRunVoidReport, runVoidReport]);

    useEffect(() => {
        if (visible && !hasRequestedData.current) {
            hasRequestedData.current = true;
            getAndSetMethodData();
        }
    }, [visible, getAndSetMethodData]);

    return (
        <Dialog
            visible={visible}
            destroyOnClose={true}
            closable={false}
            footer={null}
            className={styles.dialog}
            width={'auto'}
            getContainer={modalContainer as HTMLElement}
        >
            <div className={styles.modalContainer}>
                <ModalHeader
                    title={<span className={styles.modalHeaderTitle}>{modalHeaderTitle}</span>}
                    elevioDataId={'530'}
                    onClose={onClose}
                />
                <div className={styles.contentWrapper}>
                    <Loading show={!selectorModalState} className={styles.loading} customText={' '}>
                        {(selectorModalState as SelectorModalData)?.methods?.length ? (
                            <MethodsSelectorContent
                                selectorData={selectorModalState as SelectorModalData}
                                elevioData={elevioData}
                                onRunVoidReport={onRunVoidReport}
                            />
                        ) : (
                            <UnexpectedError showActionButton={false} className={styles.errorMsg} />
                        )}
                    </Loading>
                </div>
            </div>
        </Dialog>
    );
};
