/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

type MusicIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type MusicIconProps = SVGProps<SVGSVGElement> & MusicIconUniqueProps;

export const MusicIcon = function MusicIcon({ width = 24, height = 24, ...props }: MusicIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <circle cx="9.5" cy="20.5" r="3" fill="#E1E2FF" stroke="#5E63E5" />
            <circle cx="20.5" cy="17.5" r="3" fill="#E1E2FF" stroke="#5E63E5" />
            <circle cx="3" cy="13" r="2.5" fill="white" stroke="#5E63E5" />
            <path
                d="M12.5 7.69425C12.5 6.961 13.0301 6.33521 13.7534 6.21466L22.9178 4.68726C23.2226 4.63647 23.5 4.87149 23.5 5.18046V8.57644L12.5 10.4098V7.69425Z"
                fill="#E1E2FF"
                stroke="#5E63E5"
            />
            <path
                d="M5.5 2.69425C5.5 1.961 6.03012 1.33521 6.7534 1.21466L9.9178 0.687263C10.2226 0.636469 10.5 0.871491 10.5 1.18046V3.30575C10.5 4.039 9.96988 4.66479 9.2466 4.78534L5.5 5.40977V2.69425Z"
                fill="white"
                stroke="#5E63E5"
            />
            <path d="M12.5 20.5V9.5" stroke="#5E63E5" />
            <path d="M23.5 17.5V6.5" stroke="#5E63E5" />
            <path d="M5.5 13V4.5" stroke="#5E63E5" />
        </svg>
    );
};
MusicIcon.__tags = ['music', 'note'] as string[];
