import { createAsyncThunk } from '@reduxjs/toolkit';
import { PLACE_COLORS } from 'core/constants/place-colors';
import { ChainEntityModelMapper } from 'features/industry/common/util/chain-placer-entity-model-mapper';

import {
    selectChainsReportAllChainsEntityModels,
    selectChainsReportChainsEntityModelsByUId,
    setReportChainsAtChains,
} from 'features/industry/store/chains-report-chains-store';
import { ChainsReportState } from 'features/industry/store/chains-store';

export const chainsDuplicateChain = createAsyncThunk<void, string, { state: ChainsReportState }>(
    'industry/duplicate-chain',
    (chainId: string, { getState, dispatch }) => {
        const state = getState();
        const chain = selectChainsReportChainsEntityModelsByUId(state, chainId);
        if (!chain) {
            return;
        }

        const chains = selectChainsReportAllChainsEntityModels(state);
        const color = PLACE_COLORS.main[chains.length];
        const newChain = ChainEntityModelMapper.createEntityModel(chain, color);

        const newChains = [...chains, newChain];

        dispatch(setReportChainsAtChains(newChains));
    },
);
