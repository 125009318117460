import { createAsyncThunk } from '@reduxjs/toolkit';

import { ChainPOI } from 'core/entities';
import { chainsAPI } from 'API/chains-api';
import { ChainsReportState } from 'features/chains/store/chains-store';
import { selectIsChainsReportLimited } from 'features/chains/store/chains-report-chains-store';

const createGetSearchSuggestedBrands = (chainsApi: typeof chainsAPI) =>
    createAsyncThunk<ChainPOI[], void, { state: ChainsReportState }>(
        'chains/getSuggestedBrands',
        async (_, { getState }) => {
            const state = getState() as ChainsReportState;

            const isLimited = selectIsChainsReportLimited(state);

            const { data: suggestedBrandsApi } = await chainsApi.getSuggestedBrands(
                undefined,
                isLimited,
            );

            const suggestedBrands = suggestedBrandsApi.map(
                (suggestedBrandsApi) => new ChainPOI(suggestedBrandsApi),
            );

            return suggestedBrands;
        },
    );

export const getSearchSuggestedBrands = createGetSearchSuggestedBrands(chainsAPI);
