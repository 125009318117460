import { PlacePOI } from 'core/entities';
import {
    groupCategoriesIcons,
    primaryCategoriesIcons,
} from 'core/entities/category/category-icons';
import { BaseGroupCategory } from 'core/entities/category/types';
import { svgToDataURL } from 'ui-components/google-map/utils';
import { renderToString } from 'react-dom/server';
import type {
    CategoriesIconsDictionary,
    ConfigurationCategoriesData,
    Dictionary,
    Place,
} from '@placer-ui/types';
import { reportException } from 'core/exceptions';
import { isEqual } from 'lodash';
import {
    getFetchedCategoriesIcons,
    STORAGE_CATEGORY_ICONS,
} from 'utils/get-fetched-categories-icons/get-fetched-categories-icons';

type GetCategoryIconUrlParams = {
    categoriesIconData: ConfigurationCategoriesData[];
    info?: Place;
    subPoiCategory?: string;
    primaryPoiCategory?: string;
    groupPoiCategory?: string;
};

export const getCategoryIconUrlWithFallback = ({
    categoriesIconData,
    info,
    subPoiCategory,
    primaryPoiCategory,
    groupPoiCategory,
}: GetCategoryIconUrlParams) => {
    let iconUrl;

    iconUrl = getCategoryIconUrl({
        categoriesIconData,
        info,
    });
    if (iconUrl) return iconUrl;

    return getCategoryStaticIconUrl({
        info,
        subPoiCategory,
        primaryPoiCategory,
        groupPoiCategory,
    });
};

export const getCategoryIconUrl = ({
    categoriesIconData,
    info,
    subPoiCategory,
    primaryPoiCategory,
}: GetCategoryIconUrlParams) => {
    return getCategoryPathIcons({
        categoriesIconData,
        primaryPoiCategory: primaryPoiCategory,
        subPoiCategory: subPoiCategory,
        info,
    });
};

type GetCategoryIcon = {
    info?: Place;
    subPoiCategory?: string;
    primaryPoiCategory?: string;
    groupPoiCategory?: string;
};

export const getCategoryStaticIconUrl = ({
    info,
    subPoiCategory,
    primaryPoiCategory,
    groupPoiCategory,
}: GetCategoryIcon) => {
    const categoryIcon = getCategoryStaticIcon({
        info,
        groupPoiCategory,
        primaryPoiCategory,
        subPoiCategory,
    });
    return categoryIcon
        ? svgToDataURL(
              renderToString(
                  categoryIcon({
                      width: 18,
                      height: 18,
                      color: '#49565D',
                  }),
              ),
          )
        : undefined;
};

type CategoryPathIcons = {
    categoriesIconData: ConfigurationCategoriesData[];
    info?: Place;
    subPoiCategory?: string;
    primaryPoiCategory?: string;
};

const getCategoryPathIcons = ({
    categoriesIconData,
    info,
    primaryPoiCategory,
    subPoiCategory,
}: CategoryPathIcons) => {
    const groupCategory = info && PlacePOI.getGroupCategory(info);
    const primaryCategory = info ? PlacePOI.getPrimaryCategory(info) : primaryPoiCategory;
    const subCategory = info ? PlacePOI.getSubCategory(info) : subPoiCategory;
    const groupCategoryData = categoriesIconData.find((group) => group.label === groupCategory);
    const allPrimariesCategoryData = categoriesIconData.flatMap((group) => group.children);
    const primaryCategoryData = (groupCategoryData?.children! || allPrimariesCategoryData)?.find(
        (primary) => primary.label === primaryCategory,
    );
    const subCategoryData = primaryCategoryData?.children?.find((sub) => sub.label === subCategory);

    return subCategoryData?.path || primaryCategoryData?.path || groupCategoryData?.path;
};

type CategoryStaticIcons = {
    info?: Place;
    subPoiCategory?: string;
    primaryPoiCategory?: string;
    groupPoiCategory?: string;
};

export const getCategoryStaticIcon = ({
    info,
    primaryPoiCategory,
    groupPoiCategory,
}: CategoryStaticIcons) => {
    const groupCategory = info ? PlacePOI.getGroupCategory(info) : groupPoiCategory ?? '';
    const primaryCategory = info ? PlacePOI.getPrimaryCategory(info) : primaryPoiCategory ?? '';

    return (
        primaryCategoriesIcons[primaryCategory]?.svg ??
        groupCategoriesIcons[groupCategory as BaseGroupCategory]?.svg
    );
};

export async function getSvgUrlData(url: string): Promise<string | undefined> {
    try {
        const response = await fetch(url);
        if (response.ok) {
            return await response.text();
        }
        reportException(response, {
            target_url: url,
            method: 'GET',
            status_code: response.status,
        });
        return undefined;
    } catch (error) {
        console.error(error);
        reportException(error, {
            target_url: url,
            method: 'GET',
        });

        return undefined;
    }
}

export const getAllCategoriesPaths = (categories: ConfigurationCategoriesData[]) => {
    if (!categories) return {};

    return categories.reduce<Dictionary<CategoriesIconsDictionary>>((acc, group) => {
        const groupPath = group.path;
        const groupSmallPath = group.icon_small_path;
        const groupLabel = group.label;
        if (groupPath) {
            acc[groupLabel] = {
                ...acc[groupLabel],
                bigIconPath: groupPath,
            };
        }
        if (groupSmallPath) {
            acc[groupLabel] = {
                ...acc[groupLabel],
                smallIconPath: groupPath,
            };
        }
        group?.children!.forEach((primary) => {
            const primaryPath = primary.path;
            const primarySmallPath = primary.icon_small_path;
            const primaryLabel = primary.label;
            if (primaryPath) {
                acc[primaryLabel] = {
                    ...acc[primaryLabel],
                    bigIconPath: primaryPath,
                };
            }
            if (primarySmallPath) {
                acc[primaryLabel] = {
                    ...acc[primaryLabel],
                    smallIconPath: primarySmallPath,
                };
            }
            primary?.children!.forEach((sub) => {
                const subPath = sub.path;
                const subSmallPath = sub.icon_small_path;
                const subLabel = sub.label;
                if (subPath) {
                    acc[subLabel] = {
                        ...acc[subLabel],
                        bigIconPath: subPath,
                    };
                }
                if (subSmallPath) {
                    acc[subLabel] = {
                        ...acc[subLabel],
                        smallIconPath: subSmallPath,
                    };
                }
            });
        });

        return acc;
    }, {});
};

export const fetchPathUrls = async (
    categoriesIconsPaths: Dictionary<CategoriesIconsDictionary>,
) => {
    let categoryPathMapping: Dictionary<CategoriesIconsDictionary> = {};
    const { categoryPathMapping: localstorageCategoryMapping } = getFetchedCategoriesIcons();

    try {
        await Promise.all(
            Object.entries(categoriesIconsPaths).map(
                async ([label, { bigIconPath, smallIconPath }]) => {
                    let iconBigSvgPath: string | undefined;
                    let iconSmallSvgPath: string | undefined;

                    const categoryMapping = localstorageCategoryMapping?.[label];

                    const categoryBigIconLocalStorage = categoryMapping?.bigIconPath;
                    const categorySmallIconLocalStorage = categoryMapping?.smallIconPath;

                    if (bigIconPath && !categoryBigIconLocalStorage) {
                        try {
                            iconBigSvgPath = await getSvgUrlData(bigIconPath);
                        } catch {
                            iconBigSvgPath = undefined;
                        }
                    }
                    if (smallIconPath && !categorySmallIconLocalStorage) {
                        try {
                            iconSmallSvgPath = await getSvgUrlData(smallIconPath);
                        } catch {
                            iconSmallSvgPath = undefined;
                        }
                    }
                    if (iconBigSvgPath) {
                        categoryPathMapping[label] = {
                            ...categoryPathMapping[label],
                            bigIconPath: iconBigSvgPath,
                        };
                    }
                    if (iconSmallSvgPath) {
                        categoryPathMapping[label] = {
                            ...categoryPathMapping[label],
                            smallIconPath: iconSmallSvgPath,
                        };
                    }
                },
            ),
        );
    } catch (err) {
        console.error(err);
        reportException(err, {
            payload: categoriesIconsPaths,
        });
    }
    if (
        !isEqual(localstorageCategoryMapping, categoryPathMapping) &&
        Object.keys(categoryPathMapping).length
    ) {
        window.localStorage.setItem(
            STORAGE_CATEGORY_ICONS,
            JSON.stringify({
                categoryPathMapping,
                expire: new Date().getTime() + 1000 * 36000,
            }),
        );
    }
    return categoryPathMapping;
};
