/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';
import { IconProps } from 'components/assets/Icons/Icons';

export const DepartmentStoresIconSvg = (
    props: CustomIconComponentProps | SVGProps<SVGSVGElement>,
) => (
    <svg {...props} viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.510181 12H5.59205C5.87146 12 6.09799 11.7682 6.09799 11.4824C6.09799 11.1966 5.87146 10.9648 5.59205 10.9648H0.510181C0.230772 10.9648 0.00424194 11.1966 0.00424194 11.4824C0.00424194 11.7682 0.230772 12 0.510181 12Z"
            fill="currentColor"
        />
        <path
            d="M0.510181 10.0063H5.59205C5.87146 10.0063 6.09799 9.7746 6.09799 9.48876C6.09799 9.20293 5.87146 8.97119 5.59205 8.97119H0.510181C0.230772 8.97119 0.00424194 9.20293 0.00424194 9.48876C0.00424194 9.7746 0.230772 10.0063 0.510181 10.0063Z"
            fill="currentColor"
        />
        <path d="M1.43396 7.61408H3.19177V6.23389H1.6048L1.43396 7.61408Z" fill="currentColor" />
        <path d="M10.223 6.23389V7.61408H12.0042L11.8334 6.23389H10.223Z" fill="currentColor" />
        <path
            d="M6.3558 1.03514C6.3558 0.844738 6.51337 0.690096 6.70737 0.690096C6.90137 0.690096 7.05893 0.844738 7.05893 1.03514C7.05893 1.22555 6.90137 1.38019 6.70737 1.38019H6.3558V2.20202L5.21799 2.76038H4.27563C3.06741 2.76038 2.04678 3.63217 1.84793 4.80174L1.72195 5.54377H3.05112V4.85367H4.06674V8.32716H5.82846C6.50687 8.32716 7.05893 8.86899 7.05893 9.53482C7.05893 9.87286 6.91574 10.178 6.68686 10.3974C6.91574 10.6169 7.05893 10.922 7.05893 11.2601C7.05893 11.446 7.01233 11.8041 6.93552 11.9617H9.51987V4.85367H10.1605V5.54377H11.7162L11.5902 4.80174C11.3914 3.63217 10.3473 2.76038 9.1391 2.76038H8.19675L7.05893 2.20202V2.01134C7.46817 1.86874 7.76205 1.48496 7.76205 1.03514C7.76205 0.464377 7.28891 0 6.70737 0C6.12582 0 5.65268 0.464377 5.65268 1.03514H6.3558ZM7.74118 3.30842C7.64468 3.78232 7.21805 4.14058 6.70737 4.14058C6.19669 4.14058 5.77005 3.78232 5.67355 3.30842L6.70737 2.80118L7.74118 3.30842Z"
            fill="currentColor"
        />
    </svg>
);

export const DepartmentStoresIcon = (props: IconProps) => (
    <Icon component={DepartmentStoresIconSvg} {...props} />
);
