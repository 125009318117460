import get from 'lodash/get';
import { reportsApi } from 'API/reports-api/reports-api';
import { createInsightsEntities } from 'features/insights/utils/create-insigts-entities/create-insights-entities';
import {
    getAggregatedOptionsVenue,
    revertAggregatedOptionsVenue,
} from 'features/insights/utils/report-options-reducer/report-options-reducer';
import type { PointOfInterestEntities } from '@placer-ui/types';
import type { EntitiesDataOptionsType } from 'API/report-options-api/report-options-api';
import type { Venue, VenueClassDependencies } from 'core/entities/venue/venueAdmin';
import type { FetchReportParams } from 'features/insights/store/actions/fetch-report/types';

export const createVenues = (
    entitiesFromURL: FetchReportParams['entitiesFromURL'],
    entitiesData: PointOfInterestEntities[],
    venueClassDependencies: VenueClassDependencies,
) => {
    const venues = entitiesData.map((entityData, index) =>
        createInsightsEntities(
            venueClassDependencies,
            {
                entity: entityData,
                filter: entitiesFromURL[index].filter,
            },
            index,
        ),
    );
    return venues;
};

export const initVenuesOptions = (venues: Venue[], entitiesOptions: EntitiesDataOptionsType[]) => {
    venues.forEach((venue) => {
        const options = entitiesOptions.find((entity) => entity.entity_id === venue.id);
        if (options) {
            venue.originalShape.info = getAggregatedOptionsVenue({
                entity: revertAggregatedOptionsVenue(venue.originalShape.info),
                options,
                entityFilters: get(venue, 'customData.filter'),
            });
            venue.setInfo(
                getAggregatedOptionsVenue({
                    entity: revertAggregatedOptionsVenue(venue.info),
                    options,
                    entityFilters: get(venue, 'customData.filter'),
                }),
            );
        }
    });
};

export const initVenuesCoverage = async (venues: Venue[]) => {
    return await reportsApi.getChainCoverage(
        venues.map((venue) => ({
            id: venue.id,
            type: venue.collection,
            filter: venue.customData.filter,
        })),
    );
};
