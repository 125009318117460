import { AppDispatch } from 'store/store';
import { userAPI } from 'API/user-api';
import type { PlacerResponseData } from '@placer-ui/types';
import { AnonymousUserPermissions } from 'core/entities/user/anonymous-user-permissions';
import { setAnonymousUserPermissionsActionCreator } from 'store/auth/actions/set-anonymous-user-permissions/set-anonymous-user-permissions';

export const fetchAnonymousUserPermissions = () => async (dispatch: AppDispatch) => {
    try {
        const permissionsResponse: Response = await userAPI.getAnonymousUserPermissions();
        const permissionsData: PlacerResponseData<AnonymousUserPermissions> =
            await permissionsResponse.json();
        dispatch(setAnonymousUserPermissionsActionCreator(permissionsData.data));
    } catch (e) {
        dispatch(setAnonymousUserPermissionsActionCreator({} as AnonymousUserPermissions));
    }
};
