import { getCookieValueByName, isBase64Encoded, setCookie } from 'utils/cookies/utils';
import { getPlacerCurrentDomain } from 'store/auth/utils/utils';
import { COOKIE_KEY, TEN_YEARS } from 'ui-components/dismissable/dismissable-provider';

export const updateExistingDismissCookie = () => {
    const dismissCookieValue = getCookieValueByName(COOKIE_KEY);

    if (dismissCookieValue && !isBase64Encoded(dismissCookieValue)) {
        setCookie({
            key: COOKIE_KEY,
            value: btoa(dismissCookieValue), // encode existing values
            maxAge: TEN_YEARS,
            domain: getPlacerCurrentDomain(),
            secure: true,
        });
    }
};
