import { useGetActiveOverlay } from 'features/explore/hooks/use-explore-overlay-poi';
import { useGetEnclosingComplexOptions } from 'features/explore/hooks/use-explore-enclosing-complex';
import { useSetOverlayPOI } from 'features/explore/context/use-explore-ui-state-actions';
import React, { useMemo } from 'react';
import { ExploreDrawerOption } from 'features/explore/components/explore-side-bar/components/explore-drawer/explore-drawer-option/explore-drawer-option';
import { RegularOptionBody } from 'features/explore/components/explore-side-bar/components/explore-drawer/explore-drawer-option/option-body/regular-option-body';
import { SelectComplexOptionBody } from 'features/explore/components/explore-side-bar/components/explore-drawer/explore-drawer-option/option-body/select-complex-option-body';
import { POIActionProps } from 'features/explore/components/explore-side-bar/components/explore-drawer/drawer-options';
import { NearbyActivityOption } from 'features/explore/components/explore-side-bar/components/explore-drawer/explore-drawer-option/options/nearby-report-option';
import { PlaceOverlay } from 'ui-components/google-map-layers/types/google-map-layers-types';
import { getComplexPlaceOverlay } from 'ui-components/google-map-layers/utils/get-place-overlay';

export const EnclosedPoiOption = ({ poi, enclosingComplex, enableSelection }: POIActionProps) => {
    const getEnclosingComplexes = useGetEnclosingComplexOptions();
    const setOverlay = useSetOverlayPOI();
    const getActiveOverlay = useGetActiveOverlay();
    const overlayPoi: PlaceOverlay = useMemo(
        () => ({
            ...poi,
            overlayID: 'complex',
        }),
        [poi],
    );

    const overlay = useMemo(
        () => getActiveOverlay(overlayPoi),

        [getActiveOverlay, overlayPoi],
    );

    const isActive = useMemo(() => {
        const enclosingComplexes = getEnclosingComplexes(poi.id);
        return !!(
            overlay &&
            enclosingComplexes &&
            enclosingComplexes.some((complex) => complex.id === overlay?.id)
        );
    }, [getEnclosingComplexes, poi.id, overlay]);

    if (!enclosingComplex || !enclosingComplex.length) {
        return (
            <>
                <NearbyActivityOption poi={poi} enableSelection={enableSelection} />
            </>
        );
    }

    const onActivate = () => {
        setOverlay({
            sourcePoi: poi.id,
            overlayPoi: getComplexPlaceOverlay(enclosingComplex[0]),
        });
    };

    if (enclosingComplex.length === 1) {
        const title = `Open Report for ${enclosingComplex[0].name}`;
        const subtitle = `${poi.name} is a part of ${enclosingComplex[0].name}
                and cannot be reported on independently.`;
        return (
            <>
                <ExploreDrawerOption
                    onActivate={onActivate}
                    isActive={isActive}
                    poi={poi}
                    title={title}
                    subtitle={subtitle}
                    testId={'enclosed-poi-option-single'}
                >
                    <RegularOptionBody poi={overlayPoi} enableSelection={enableSelection} />
                </ExploreDrawerOption>
                <NearbyActivityOption poi={poi} enableSelection={enableSelection} />
            </>
        );
    }

    return (
        <>
            <ExploreDrawerOption
                isActive={isActive}
                onActivate={onActivate}
                poi={poi}
                title={'Open Report for the enclosing property'}
                subtitle={'Select enclosing property'}
                testId={'enclosed-poi-option-multiple'}
            >
                <SelectComplexOptionBody
                    poi={poi}
                    complexes={enclosingComplex}
                    enableSelection={enableSelection}
                />
            </ExploreDrawerOption>
            <NearbyActivityOption poi={poi} enableSelection={enableSelection} />
        </>
    );
};
