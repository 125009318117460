import { AppDispatch } from 'store/store';
import { ChainEntityModel } from 'core/entities';

import {
    cleanWidgetsContentAndSelectors,
    cleanWidgetsFetchFilters,
    wrapActionWithWidgetStatusUpdate,
} from 'extensions/widget/store/widget-actions';
import { updateChainFilters } from 'features/chains/store/chains-report-chains-store';

const updateReportChainsFiltersLogic = (chainState: Partial<ChainEntityModel>[]) => (
    dispatch: AppDispatch,
) => {
    dispatch(cleanWidgetsContentAndSelectors());
    dispatch(updateChainFilters(chainState));
    dispatch(cleanWidgetsFetchFilters());
};

export const updateReportChainsFilters = (chainState: Partial<ChainEntityModel>[]) => (
    dispatch: AppDispatch,
) => {
    dispatch(wrapActionWithWidgetStatusUpdate(updateReportChainsFiltersLogic(chainState)));
};
