import { AccessInfo, Geolocation, PlacerResponseData } from '@placer-ui/types';
import { fetchWrapper } from 'API/authentification/fetch-wrapper';
import * as querystring from 'querystring';

export type ReportAccessCoordinate = {
    access: Pick<AccessInfo, 'level' | 'warnings'>;
};

export const checkReportAccessForCoordinate = async (
    geolocation: Geolocation,
    signal?: AbortSignal,
): Promise<PlacerResponseData<ReportAccessCoordinate>> => {
    const queryParams = querystring.stringify({
        lat: geolocation.lat,
        lng: geolocation.lng,
    });
    const targetUrl = `/report-access/coordinates?${queryParams}`;
    return await fetchWrapper({
        method: 'GET',
        targetUrl,
        signal,
    });
};
