import { useContext, useMemo } from 'react';
import moment, { Moment } from 'moment';
import { DateContext } from 'ui-components/date-picker/date-picker';
import type { DateLabels, Dictionary } from '@placer-ui/types';
import { Preset } from 'ui-components/date-picker/date-types/Preset';
import { getRelativeDateByLabel } from 'core/services/date-service';

export const createLabelAndRangeValue = (labelsList: DateLabels[], maxDate: Moment) => {
    return labelsList.reduce<Dictionary<Preset>>((acc, label) => {
        acc[label] = {
            label,
            value: getRelativeDateByLabel(label, maxDate)!,
        };
        return acc;
    }, {});
};

export const createToMaxDatePresets = (maxDate: Moment = moment()): Dictionary<Preset> =>
    createLabelAndRangeValue(['Month to Date', 'Quarter to Date', 'Year to Date'], maxDate);

export const createFullPeriodPresets = (maxDate: Moment = moment()): Dictionary<Preset> =>
    createLabelAndRangeValue(
        [
            'Last full 4 weeks',
            'Last full 6 weeks',
            'Last full 3 months',
            'Last full 6 months',
            'Last full 12 months',
        ],
        maxDate,
    );

export const createPassPeriodPresets = (maxDate: Moment = moment()): Dictionary<Preset> =>
    createLabelAndRangeValue(
        [
            'Past 7 days',
            'Past 30 days',
            'Past 60 days',
            'Past 90 days',
            'Past 6 months',
            'Past 12 months',
        ],
        maxDate,
    );

export const useRelativePreset = () => {
    const { maxDate } = useContext(DateContext);
    return useMemo(() => {
        return {
            toMaxDate: createToMaxDatePresets(maxDate),
            fullPeriod: createFullPeriodPresets(maxDate),
            passPeriod: createPassPeriodPresets(maxDate),
        };
    }, [maxDate]);
};
