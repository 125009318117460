/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

export type EyeIconUniqueProps = {
    width?: number | string;
    height?: number | string;
    color?: string;
    isHide?: boolean;
};

type EyeIconProps = SVGProps<SVGSVGElement> & EyeIconUniqueProps;

export const EyeIcon = function EyeIcon({
    width = 18,
    height = 18,
    color = 'var(--light-gray-2)',
    isHide = false,
    ...props
}: EyeIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            {!isHide ? (
                <g id="Eye-icon-show">
                    <g id="Group 3">
                        <g id="Path">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0 9.00024C2.687 13.6669 5.687 16.0002 9 16.0002C12.313 16.0002 15.313 13.6669 18 9.00024C15.313 4.33358 12.313 2.00024 9 2.00024C5.687 2.00024 2.687 4.33358 0 9.00024Z"
                                stroke="#49565D"
                                strokeWidth="1"
                            />
                        </g>
                    </g>
                    <g id="Stroke 6">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9 13C11.2093 13 13 11.2093 13 9C13 6.79067 11.2093 5 9 5C6.79067 5 5 6.79067 5 9C5 11.2093 6.79067 13 9 13Z"
                            fill={color}
                            stroke="#49565D"
                            strokeWidth="1"
                        />
                    </g>
                </g>
            ) : (
                <g id="Eye-icon-hide">
                    <g id="Group">
                        <g id="Group 3">
                            <path
                                id="Combined Shape"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M13.4227 3.51673C12.0162 2.50574 10.542 2.00024 9 2.00024C5.687 2.00024 2.687 4.33358 0 9.00024C1.08801 10.8899 2.22735 12.3969 3.418 13.5214L3.94798 12.9914C2.95993 12.0622 1.99985 10.8433 1.06808 9.32879L0.869 9.00004L1.06808 8.6717C3.51833 4.68904 6.16434 2.75024 9 2.75024L9.24259 2.75499C10.494 2.80411 11.7082 3.23433 12.8846 4.05479L13.4227 3.51673ZM5.11517 13.9455C6.29163 14.7661 7.50589 15.1964 8.75741 15.2455L9 15.2502C11.8357 15.2502 14.4817 13.3115 16.9319 9.32879L17.13 9.00004L16.9319 8.6717C16.0001 7.15708 15.0399 5.93808 14.0518 5.00889L14.5818 4.47891C15.7725 5.60341 16.9119 7.11052 18 9.00024C15.313 13.6669 12.313 16.0002 9 16.0002C7.45789 16.0002 5.98359 15.4947 4.57712 14.4836L5.11517 13.9455Z"
                                fill={color}
                            />
                        </g>
                        <path
                            id="Combined Shape_2"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M11.2483 5.6911C10.6076 5.25493 9.83362 5 9 5C6.79067 5 5 6.79067 5 9C5 9.83362 5.25493 10.6076 5.6911 11.2483L11.2483 5.6911ZM6.75178 12.309C7.39247 12.7451 8.16643 13 9 13C11.2093 13 13 11.2093 13 9C13 8.16643 12.7451 7.39247 12.309 6.75178L6.75178 12.309Z"
                            fill={color}
                        />
                    </g>
                </g>
            )}
        </svg>
    );
};
EyeIcon.__tags = ['visibility', 'hide', 'show', 'eye', 'look', 'visibile'] as string[];
