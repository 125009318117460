import React from 'react';
import type { Billboard, Chain, Place, Venue } from '@placer-ui/types';
import { isNil } from 'lodash/fp';
import globalStyles from 'src/react.module.scss';

import {
    RecentEntitiesModel,
    RecentEntitiesModelAutocompleteEnriched,
} from 'core/services/recent-entities-service/recent-entities-model';
import { isCustomEntity } from 'core/entities/custom-entity/custom-entity';
import {
    getReportEntityClosingDate,
    getReportEntityFlag,
    getReportEntityLocationLabel,
    isRestrictedArea,
} from 'core/services/report-entities-service/report-entities-service';
import { isRecentEntity } from 'core/entities/recent-entity/recent-entity';
import { BillboardPOI, ChainPOI, PlacePOI, TagPOI, VenuePOI } from 'core/entities';

import { AutoCompleteMenuOptionData } from 'ui-components/auto-complete/auto-complete';
import {
    AlertColoredIcon,
    AlertOrangeIcon,
    AlertRedIcon,
    ChainIcon,
    CustomVenueIcon,
    PinIcon,
    RecentIcon,
    TagIcon,
    VenueIcon,
} from 'components/assets/Icons/Icons';
import { dateFormats, getSimpleFormatDate } from 'utils/date/date';
import { venueFlagInfoPublicPOIText } from 'ui-components/venue-status-indicator/v2/venue-status-indicator-v2';
import { GrayLockIcon } from 'components/assets/Icons/GrayLockIcon';
import { SearchAutocompleteSuggestion } from 'components/search-autocomplete-suggestion/search-autocomplete-suggestion';

type AutoCompleteSuggestion = {
    data: RecentEntitiesModelAutocompleteEnriched;
    term: string;
    getIsDisabledOption?: (data: RecentEntitiesModel) => boolean;
    flaggedEntityTooltipText?: string;
    hasUnlockNearbyActivity?: boolean;
    hasNearbyPropertyVenueIndicatorFF?: boolean;
    hasRestrictPermission?: boolean;
    hasStoreIdBadgeAutocompleteSearchPermission?: boolean;
};

export const mapAutoCompleteSuggestion = ({
    data,
    term,
    getIsDisabledOption,
    flaggedEntityTooltipText,
    hasUnlockNearbyActivity,
    hasNearbyPropertyVenueIndicatorFF,
    hasRestrictPermission,
    hasStoreIdBadgeAutocompleteSearchPermission: hasStoreIdBadgePermission,
}: AutoCompleteSuggestion): AutoCompleteMenuOptionData<RecentEntitiesModel> => {
    const placeData = data as Place;
    const location = getReportEntityLocationLabel(placeData);
    const { name, id } = data;
    const closingDate = getReportEntityClosingDate(placeData);
    const flag = getReportEntityFlag(placeData);
    const isRestricted = hasRestrictPermission && isRestrictedArea(placeData);
    const disable = getIsDisabledOption
        ? getIsDisabledOption(data as Venue | Chain | Billboard)
        : false;
    const provider = (data as Venue).provider_data?.provider;
    const providerEntityType = (data as Venue).provider_data?.entity_type;
    const flagsToIgnore = ['flagged_indoor_mall', 'flagged_multistory_building'];
    const isPrivacyFlag = flag === 'privacy_concerns';
    const isNearbyProfile = PlacePOI.isNearbyActivity(placeData);
    const storeId = hasStoreIdBadgePermission ? placeData?.store_id : '';

    // if a user does not have the explore2 + nearby activity +
    // unlock nearby activity + the entity flag is not enclosing / multistory
    const noUnlockNearbyActivity =
        hasUnlockNearbyActivity === false && flag && !flagsToIgnore.includes(flag);
    let warningInfo;

    if (
        (flag &&
            (isPrivacyFlag ||
                !hasUnlockNearbyActivity ||
                (isNearbyProfile && hasNearbyPropertyVenueIndicatorFF))) ||
        isRestricted
    ) {
        let flagInfo = '';
        if (flag) {
            flagInfo = `${venueFlagInfoPublicPOIText[flag]}`;
        }
        const alertBlackIcon = ({ ...props }) =>
            AlertColoredIcon({
                style: { color: 'var(--text-primary-color)' },
                ...props,
            });
        const alertPurpleIcon = ({ ...props }) =>
            AlertColoredIcon({
                style: { color: globalStyles.lightBlue },
                ...props,
            });

        let warningFlagIcon = AlertOrangeIcon;

        if (flag === 'privacy_concerns' || isRestricted) {
            warningFlagIcon = alertBlackIcon;
        } else if (isNearbyProfile && hasNearbyPropertyVenueIndicatorFF) {
            warningFlagIcon = alertPurpleIcon;
        }

        const warningIcon = noUnlockNearbyActivity ? GrayLockIcon : warningFlagIcon;

        let flaggedTooltipText = '';

        if (isRestricted) {
            flaggedTooltipText = 'Venue is part of a restricted area. Data is not available';
        } else if (isNearbyProfile && hasNearbyPropertyVenueIndicatorFF) {
            flaggedTooltipText = `${name} only supports a Nearby Activity report`;
        } else if (noUnlockNearbyActivity) {
            flaggedTooltipText = 'Locked. Premium account is needed';
        } else if (!flaggedEntityTooltipText) {
            flaggedTooltipText = `${flagInfo} Click to view other report options.`;
        } else {
            flaggedTooltipText = flaggedEntityTooltipText;
        }
        warningInfo = {
            Icon: warningIcon,
            tooltipText: flaggedTooltipText,
        };
    } else if (closingDate) {
        warningInfo = {
            Icon: AlertRedIcon,
            tooltipText: `Closed - Date available until ${getSimpleFormatDate(
                closingDate,
                dateFormats.shortMonthYearWithNumericOrdinalContractionDayComma,
            )}`,
        };
    }

    const disabledOption = isPrivacyFlag || disable;

    const commonOptions = {
        disabled: disabledOption,
        value: name,
        payload: data,
    };

    if (provider && providerEntityType === 'address') {
        const exactName = placeData.address.formatted_address || name;
        return {
            ...commonOptions,
            value: exactName,
            payload: {
                ...data,
                name: exactName,
            },
            component: (
                <SearchAutocompleteSuggestion
                    dataTestId={id}
                    key={id}
                    name={exactName}
                    termToHighlight={term}
                    Icon={PinIcon}
                    warningInfo={warningInfo}
                    rightSideIcon={data.rightSideIcon}
                />
            ),
        };
    }

    if (isRecentEntity(data)) {
        return {
            ...commonOptions,
            component: (
                <SearchAutocompleteSuggestion
                    dataTestId={id}
                    key={id}
                    Icon={provider ? PinIcon : RecentIcon}
                    name={name}
                    location={location}
                    termToHighlight={term}
                    warningInfo={warningInfo}
                    noUnlockNearbyActivity={noUnlockNearbyActivity}
                    storeId={storeId}
                    rightSideIcon={data.rightSideIcon}
                />
            ),
        };
    }

    if (isCustomEntity(data)) {
        return {
            ...commonOptions,
            component: (
                <SearchAutocompleteSuggestion
                    dataTestId={id}
                    key={id}
                    Icon={provider ? PinIcon : CustomVenueIcon}
                    name={name}
                    location={location}
                    termToHighlight={term}
                    rightSideIcon={data.rightSideIcon}
                />
            ),
        };
    }

    const { purchased, forceLockIconState } = data;
    let isLocked = forceLockIconState === 'show';
    if (isNil(forceLockIconState)) isLocked = !purchased;

    if (ChainPOI.isChain(placeData)) {
        const enableLimitedReport = (data as Chain).enable_limited_report;

        return {
            ...commonOptions,
            component: (
                <SearchAutocompleteSuggestion
                    dataTestId={id}
                    key={id}
                    Icon={ChainIcon}
                    name={name}
                    isLocked={isLocked && !enableLimitedReport}
                    location={location}
                    termToHighlight={term}
                    rightSideIcon={data.rightSideIcon}
                />
            ),
        };
    }

    if (VenuePOI.isVenue(placeData)) {
        return {
            ...commonOptions,
            component: (
                <SearchAutocompleteSuggestion
                    dataTestId={id}
                    key={id}
                    Icon={provider ? PinIcon : VenueIcon}
                    name={name}
                    isLocked={isLocked}
                    location={location}
                    termToHighlight={term}
                    displayUpgradeYourPlan={false}
                    warningInfo={warningInfo}
                    storeId={storeId}
                    noUnlockNearbyActivity={noUnlockNearbyActivity || isRestricted}
                    rightSideIcon={data.rightSideIcon}
                />
            ),
        };
    }

    if (BillboardPOI.isBillboard(placeData)) {
        return {
            ...commonOptions,
            component: (
                <SearchAutocompleteSuggestion
                    dataTestId={id}
                    key={id}
                    Icon={provider ? PinIcon : VenueIcon}
                    name={name}
                    isLocked={isLocked}
                    location={location}
                    termToHighlight={term}
                    rightSideIcon={data.rightSideIcon}
                />
            ),
        };
    }

    if (TagPOI.isTag(placeData)) {
        return {
            ...commonOptions,
            component: (
                <SearchAutocompleteSuggestion
                    dataTestId={id}
                    key={id}
                    Icon={TagIcon}
                    name={name}
                    isLocked={isLocked}
                    location={location}
                    termToHighlight={term}
                    rightSideIcon={data.rightSideIcon}
                />
            ),
        };
    }

    return {
        ...commonOptions,
        component: (
            <SearchAutocompleteSuggestion
                dataTestId={id}
                key={id}
                name={name}
                termToHighlight={term}
                Icon={provider ? PinIcon : VenueIcon}
                rightSideIcon={data.rightSideIcon}
            />
        ),
    };
};
