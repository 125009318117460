import { PLACE_COLORS } from 'core/constants/place-colors';
import { Venue, VenueClassDependencies } from 'core/entities/venue/venueAdmin';
import { VenueFilter } from 'core/entities/venue/venueAdminFilter';
import { BackendFilter } from 'features/insights/types/filter-types';
import type { PointOfInterestEntities } from '@placer-ui/types';

export interface InsightsEntitiesData {
    entity: PointOfInterestEntities;
    filter: BackendFilter;
}

export const createInsightsEntities = (
    venueClassDependencies: VenueClassDependencies,
    { entity, filter }: InsightsEntitiesData,
    index: number,
) => {
    const venue = new Venue({ info: entity }, venueClassDependencies.entities);
    VenueFilter.dates = venueClassDependencies.dates!;
    venue.setFilter(
        new VenueFilter({
            ...filter,
            attributes: filter.attributes,
        }),
    );
    venue.setColor(
        PLACE_COLORS.main[index],
        PLACE_COLORS.lighter[index],
        PLACE_COLORS.darker[index],
    );
    return venue;
};
