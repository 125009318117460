import PRSM from 'proxserve-react';
import { SectionType } from 'types/section';
import { SidePanelExpandedState } from 'store/app-ui/actions';
import type { ResolutionBreakpoints } from 'types/device-and-system';

type AppUiState = {
    upgradeYourPlanPopupScreen?: string;
    sidebarExpanded: Partial<Record<SectionType, SidePanelExpandedState>>;
    dialogs: {
        marketplace: {
            iframeSrc: string;
        };
    };
    resolutionBreakpoint?: ResolutionBreakpoints;
};

const appUiStore = new PRSM<AppUiState>('app-ui');

appUiStore.init({
    upgradeYourPlanPopupScreen: undefined,
    sidebarExpanded: {
        'Advanced Reports': 'expanded',
    },
    dialogs: {
        marketplace: {
            iframeSrc: 'catalogs/applications',
        },
    },
});

export { appUiStore };
