export class KeyboardKey {
    static ESC = new KeyboardKey('Escape');
    static ENTER = new KeyboardKey('Enter');
    static ARROW_UP = new KeyboardKey('ArrowUp');
    static ARROW_DOWN = new KeyboardKey('ArrowDown');

    constructor(private _key: string) {}

    get key() {
        return this._key;
    }
}
