import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

import { IconProps } from 'components/assets/Icons/Icons';

/* eslint-disable max-len */
export const ShoppingCenterIconSvg = (
    props: CustomIconComponentProps | SVGProps<SVGSVGElement>,
) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 17 16">
        <path
            d="M4.212 5.333V16H2.83a2.667 2.667 0 01-2.667-2.667v-8h4.05zm-1.35 1.334h-1.35v4h1.35v-4zm13.5-1.334v8A2.667 2.667 0 0113.697 16h-1.383V5.333h4.05zm-1.35 1.334h-1.35v4h1.35v-4zm-13.516-4h2.717V4H.163c0-.736.597-1.333 1.333-1.333zm10.817 0h2.717c.736 0 1.333.597 1.333 1.333h-4.05V2.667zM5.563 8h5.4v1.333h-5.4zm5.4 2.667V16H9.612v-3.333h-2.7V16h-1.35v-5.333h5.4zM8.263 0a2.7 2.7 0 012.7 2.7v3.967h-5.4V2.7a2.7 2.7 0 012.7-2.7zm0 1.667a1.35 1.35 0 00-1.35 1.35v2.316h2.7V3.017a1.35 1.35 0 00-1.35-1.35z"
            fill="currentColor"
            fillRule="evenodd"
        />
    </svg>
);

export const ShoppingCenterIcon = (props: IconProps) => (
    <Icon component={ShoppingCenterIconSvg} {...props} />
);
