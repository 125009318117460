import { RanksDictionaryType } from 'types/property-ranks';

const GROUP__CATEGORY_BENCH_TITLE = 'Cat. Group Rank';
const CATEGORY_BENCH_TITLE = 'Cat. Rank';
const CHAIN_BENCH_TITLE = 'Chain Rank';

const CBSA = 'CBSA';
const DMA = 'DMA';
const STATE = 'State';
const NATIONWIDE = 'Nationwide';

const PRIMARY_CATEGORY = 'primary';
const GROUP_CATEGORY = 'group';
const CATEGORY = 'category';
const CHAIN = 'chain';

const CATEGORY_TITLE = 'Category Ranking';
const CATEGORY_GROUP_TITLE = 'Category Group Ranking';
const CHAIN_TITLE = 'Chain Ranking';

export const ranksDictionary: RanksDictionaryType = {
    'category.cbsa': {
        title: `${CATEGORY_TITLE} / ${CBSA}`,
        benchMarkTitle: CATEGORY_BENCH_TITLE,
        region: CBSA,
        benchmarkCategory: CATEGORY,
    },
    'category.country': {
        title: `${CATEGORY_TITLE} / ${NATIONWIDE}`,
        benchMarkTitle: CATEGORY_BENCH_TITLE,
        region: NATIONWIDE,
        benchmarkCategory: CATEGORY,
    },
    'category.dma': {
        title: `${CATEGORY_TITLE} / ${DMA}`,
        benchMarkTitle: CATEGORY_BENCH_TITLE,
        region: DMA,
        benchmarkCategory: CATEGORY,
    },
    'category.state': {
        title: `${CATEGORY_TITLE} / ${STATE}`,
        benchMarkTitle: CATEGORY_BENCH_TITLE,
        region: STATE,
        benchmarkCategory: CATEGORY,
    },
    'chain.cbsa': {
        title: `${CHAIN_TITLE} / ${CBSA}`,
        benchMarkTitle: CHAIN_BENCH_TITLE,
        region: CBSA,
        benchmarkCategory: CHAIN,
    },
    'chain.country': {
        title: `${CHAIN_TITLE} / ${NATIONWIDE}`,
        benchMarkTitle: CHAIN_BENCH_TITLE,
        region: NATIONWIDE,
        benchmarkCategory: CHAIN,
    },
    'chain.dma': {
        title: `${CHAIN_TITLE} / ${DMA}`,
        benchMarkTitle: CHAIN_BENCH_TITLE,
        region: DMA,
        benchmarkCategory: CHAIN,
    },
    'chain.state': {
        title: `${CHAIN_TITLE} / ${STATE}`,
        benchMarkTitle: CHAIN_BENCH_TITLE,
        region: STATE,
        benchmarkCategory: CHAIN,
    },
    'group.cbsa': {
        title: `${CATEGORY_GROUP_TITLE} / ${CBSA}`,
        benchMarkTitle: GROUP__CATEGORY_BENCH_TITLE,
        region: CBSA,
        benchmarkCategory: GROUP_CATEGORY,
    },
    'group.country': {
        title: `${CATEGORY_GROUP_TITLE} / ${NATIONWIDE}`,
        benchMarkTitle: GROUP__CATEGORY_BENCH_TITLE,
        region: NATIONWIDE,
        benchmarkCategory: GROUP_CATEGORY,
    },
    'group.dma': {
        title: `${CATEGORY_GROUP_TITLE} / ${DMA}`,
        benchMarkTitle: GROUP__CATEGORY_BENCH_TITLE,
        region: DMA,
        benchmarkCategory: GROUP_CATEGORY,
    },
    'group.state': {
        title: `${CATEGORY_GROUP_TITLE} / ${STATE}`,
        benchMarkTitle: GROUP__CATEGORY_BENCH_TITLE,
        region: STATE,
        benchmarkCategory: GROUP_CATEGORY,
    },
    'primary.cbsa': {
        title: `${CATEGORY_TITLE} / ${CBSA}`,
        benchMarkTitle: CATEGORY_BENCH_TITLE,
        region: CBSA,
        benchmarkCategory: PRIMARY_CATEGORY,
    },
    'primary.country': {
        title: `${CATEGORY_TITLE} / ${NATIONWIDE}`,
        benchMarkTitle: CATEGORY_BENCH_TITLE,
        region: NATIONWIDE,
        benchmarkCategory: PRIMARY_CATEGORY,
    },
    'primary.dma': {
        title: `${CATEGORY_TITLE} / ${DMA}`,
        benchMarkTitle: CATEGORY_BENCH_TITLE,
        region: DMA,
        benchmarkCategory: PRIMARY_CATEGORY,
    },
    'primary.state': {
        title: `${CATEGORY_TITLE} / ${STATE}`,
        benchMarkTitle: CATEGORY_BENCH_TITLE,
        region: STATE,
        benchmarkCategory: PRIMARY_CATEGORY,
    },
};
