import { createSlice } from '@reduxjs/toolkit';

import {
    getSearchMatchingChains,
    setChainsSearchSearchTerm,
    setChainsSearchSelectedRegion,
    setChainsSearchSearchOpenStatus,
    setChainsSearchResults,
    setChainsSearchSelectedCategories,
    setChainsSearchActiveChain,
    getTopVenuesByChainId,
    setChainsAutoCompleteSearchResultsAreLoading,
    setChainsSearchResultsAreLoading,
    getFilteredChainResults,
    setChainsSearchResultsPotentiallyMoreResultsExist,
    setSelectedChainsEntityModels,
    setChainsSearchAutoCompleteValue,
    resetActiveChain,
    setDeselectedChainsState,
    getSearchSuggestedBrands,
    getChainsSearchMyTags,
    getChainsSearchCompanyTags,
    setChainsSearchMyTagsQueryValue,
    setChainsSearchCompanyTagsQueryValue,
    setMyTagsSearchResultsPotentiallyMoreResultsExist,
    setCompanyTagsSearchResultsPotentiallyMoreResultsExist,
} from '../actions';
import {
    createChainsReportSearchState,
    EMPTY_TOP_VENUES_OBJECT,
} from '../models/chains-report-search-state';

const initialChainsReportSearchState = createChainsReportSearchState();

export const chainsReportSearchSlice = createSlice({
    name: 'search',
    initialState: initialChainsReportSearchState,
    reducers: {
        resetState: (state) => {
            const newState = createChainsReportSearchState();
            Object.assign(newState, { suggestedBrands: state.suggestedBrands });
            return newState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(setSelectedChainsEntityModels, (state, action) => {
                state.selectedChainsEntityModels = action.payload;
            })
            .addCase(setChainsSearchAutoCompleteValue, (state, action) => {
                state.autoCompleteValue = action.payload;
            })
            .addCase(setChainsSearchSearchTerm, (state, action) => {
                state.term = action.payload;
            })
            .addCase(getSearchSuggestedBrands.fulfilled, (state, action) => {
                state.suggestedBrands = action.payload;
            })
            .addCase(setChainsSearchSearchOpenStatus, (state, action) => {
                state.isOpen = action.payload;
            })
            .addCase(setChainsAutoCompleteSearchResultsAreLoading, (state, action) => {
                state.autoCompleteSearchResultsAreLoading = action.payload;
            })
            .addCase(setChainsSearchResultsAreLoading, (state, action) => {
                state.chainsSearchResultsAreLoading = action.payload;
            })
            .addCase(getSearchMatchingChains.rejected, (state, action) => {
                state.autoCompleteSearchResultsAreLoading = false;
            })
            .addCase(getSearchMatchingChains.fulfilled, (state, action) => {
                state.isAutoCompleteMatchingChainsGotResults = true;
                state.autoCompleteMatchingChains = action.payload;
                state.autoCompleteSearchResultsAreLoading = false;
            })
            .addCase(getFilteredChainResults.pending, (state, action) => {
                state.chainsSearchResultsAreLoading = true;
            })
            .addCase(getFilteredChainResults.rejected, (state, action) => {
                state.chainsSearchResultsAreLoading = false;
            })
            .addCase(getFilteredChainResults.fulfilled, (state, action) => {
                state.chainsSearchResultsAreLoading = false;
            })
            .addCase(setChainsSearchResults, (state, action) => {
                state.chainResultsEntityModels = action.payload;
            })
            .addCase(setChainsSearchResultsPotentiallyMoreResultsExist, (state, action) => {
                state.potentiallyMoreSearchResultsExist = action.payload;
            })
            .addCase(setChainsSearchActiveChain.fulfilled, (state, action) => {
                state.activeChain = action.payload;
            })
            .addCase(getChainsSearchMyTags.pending, (state, action) => {
                state.isTagsLoading = true;
            })
            .addCase(getChainsSearchMyTags.fulfilled, (state, action) => {
                state.myTagChains = action.payload;
                state.isTagsLoading = false;
            })
            .addCase(getChainsSearchMyTags.rejected, (state, action) => {
                state.isTagsLoading = false;
            })
            .addCase(getChainsSearchCompanyTags.pending, (state, action) => {
                state.isTagsLoading = true;
            })
            .addCase(getChainsSearchCompanyTags.fulfilled, (state, action) => {
                state.companyTagChains = action.payload;
                state.isTagsLoading = false;
            })
            .addCase(getChainsSearchCompanyTags.rejected, (state, action) => {
                state.isTagsLoading = false;
            })
            .addCase(setChainsSearchMyTagsQueryValue, (state, action) => {
                state.myTagsQuery = action.payload;
                state.isTagsLoading = true;
                state.myTagChains = [];
            })
            .addCase(setChainsSearchCompanyTagsQueryValue, (state, action) => {
                state.companyTagsQuery = action.payload;
                state.isTagsLoading = true;
                state.companyTagChains = [];
            })
            .addCase(setMyTagsSearchResultsPotentiallyMoreResultsExist, (state, action) => {
                state.potentiallyMoreMyTagResultsExist = action.payload;
            })
            .addCase(setCompanyTagsSearchResultsPotentiallyMoreResultsExist, (state, action) => {
                state.potentiallyMoreCompanyTagResultsExist = action.payload;
            })
            .addCase(setChainsSearchSelectedCategories, (state, action) => {
                state.selectedCategories = action.payload;
            })
            .addCase(setChainsSearchSelectedRegion, (state, action) => {
                state.selectedRegion = action.payload;
            })
            .addCase(getTopVenuesByChainId.pending, (state, action) => {
                state.isTopVenuesLoading = true;
            })
            .addCase(getTopVenuesByChainId.rejected, (state) => {
                // TODO: cover error flow
                state.topVenues = EMPTY_TOP_VENUES_OBJECT;
                state.isTopVenuesLoading = false;
            })
            .addCase(getTopVenuesByChainId.fulfilled, (state, action) => {
                state.topVenues = action.payload;
                state.isTopVenuesLoading = false;
            })
            .addCase(resetActiveChain, (state) => {
                state.topVenues = EMPTY_TOP_VENUES_OBJECT;
                state.isTopVenuesLoading = false;
                state.activeChain = null;
            })
            .addCase(setDeselectedChainsState, (state, action) => {
                state.deselectedSearchChains = action.payload;
            });
    },
});

export const resetChainsReportSearchState = chainsReportSearchSlice.actions.resetState;

export const chainsReportSearchReducer = chainsReportSearchSlice.reducer;
