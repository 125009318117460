import React from 'react';
import { SgCPGIcon } from 'features/onboarding/solutions-guide/icons/sg-cpg-icon';
import { CHAINS_ROUTE } from 'features/onboarding/solutions-guide/consts/constants';

export const CPG_SOLUTIONS_GUIDE = {
    icon: <SgCPGIcon />,
    solutions: [
        {
            title: 'Retail Chain Analysis',
            description:
                'Research visitation fluctuations and market share shifts by chain and geography.',
            url: CHAINS_ROUTE,
        },
        {
            title: 'Industry Trends',
            description:
                'Gain visibility into overall industry performance ' +
                'and market trends across retail categories in different regions.',
            url: '/labs/industry-trends',
        },
        {
            title: 'Consumer Insights',
            description:
                'Uncover comprehensive insights about your shoppers, based on their online and offline behavior.',
            url: '/advanced-reports/freemium/persona-live',
        },
        {
            title: 'Product Distribution',
            description:
                'Analyze chain performance and reveal top-performing locations to optimize distribution channels.',
            url: CHAINS_ROUTE,
        },
        {
            title: 'Merchandising',
            description:
                'Reveal specific attributes and preferences of retail customers ' +
                'at any store or chain and tailor your local offerings to their needs.',
            url: '/insights/venues/b993e29a6166017d5590631c/overview',
        },
        {
            title: 'Demand Forecasting',
            description:
                'Explore migration patterns and related demographic changes ' +
                'to understand the impact on local consumer demand.',
            url: '/advanced-reports/migration',
        },
    ],
};
