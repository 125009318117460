/* eslint-disable max-len */
import React, { useMemo } from 'react';
import type { SVGProps } from 'react';

export type LongArrowIconUniqueProps = {
    width?: number | string;
    height?: number | string;
    direction?: 'right' | 'left' | 'top' | 'bottom';
    color?: string;
};

type LongArrowIconProps = SVGProps<SVGSVGElement> & LongArrowIconUniqueProps;

export const LongArrowIcon = function LongArrowIcon({
    width = 17,
    height = 10,
    direction = 'right',
    color = '#333333',
    ...props
}: LongArrowIconProps) {
    const rotationStyle: React.CSSProperties | undefined = useMemo(() => {
        switch (direction) {
            case 'left':
                return { transform: 'scaleX(-1)' };
            case 'top':
                return { transform: 'rotate(-90deg)' };
            case 'bottom':
                return { transform: 'rotate(90deg)' };
            default:
                return undefined;
        }
    }, [direction]);

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 17 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={rotationStyle}
            {...props}
        >
            <path
                d="M11.2168 8.71655L14.9888 4.71585L11.2168 0.716553"
                stroke={color}
                strokeWidth="1.5"
            />
            <path d="M14.7676 4.74762H0.767578" stroke={color} strokeWidth="1.5" />
        </svg>
    );
};
LongArrowIcon.__tags = ['right arrow', 'long'] as string[];
