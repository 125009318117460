import { useSelector } from 'react-redux';

import {AppState} from 'store/store';

export type selectorType<P=any,R=any> = (state: any, props: P, ...args: any[]) => R;

/**
 * Added an ability to pass props to the selector
 * @typeparam P - type for input - props
 * @typeparam R - type for result
 */
export const useSelectorWithProps = <P = any, R = any>(
    props: P,
    selector: selectorType<P,R>) => {
    return useSelector((state: AppState): R => selector(state, props));
};
