import { MAX_COMPETITORS_QUANTITY } from 'features/chains/common/constants';
import { ChainsReportState } from 'features/chains/store/chains-store';
import { selectChainsEntityModelsSelectedForReportInSearch } from 'features/chains/store/chains-report-search-store';

export const selectNumberOfChainsSelectedForReportInSearch = (
    state: ChainsReportState,
) => {
    const selectedChainsEntityModels = selectChainsEntityModelsSelectedForReportInSearch(
        state,
    );
    return selectedChainsEntityModels.length;
};

export const selectUserCanAddMoreChainsToReportFromSearch = (
    state: ChainsReportState,
) => {
    const numberOfChainsSelectedForReport = selectNumberOfChainsSelectedForReportInSearch(
        state,
    );
    return numberOfChainsSelectedForReport < MAX_COMPETITORS_QUANTITY;
};

export const selectUserCanUpdateReportFromSearch = (
    state: ChainsReportState,
) => {
    const numberOfChainsSelectedForReport = selectNumberOfChainsSelectedForReportInSearch(
        state,
    );
    return numberOfChainsSelectedForReport > 0;
};
