import _ from 'lodash/fp';

import { TagEntityModel } from 'core/entities';
import type { DateLabels, Attributes, PlacerEntityWrapper, Tag } from '@placer-ui/types';

type ChainEntityModelMapperArgs = {
    entityWrapper: PlacerEntityWrapper<Tag>;
    startDate: string;
    endDate: string;
    date_label?: DateLabels;
    attributes?: Attributes;
};

export class ChainEntityModelMapper {
    static mapEntityWrapperToEntityModel({
        entityWrapper,
        startDate,
        endDate,
        date_label,
        attributes,
    }: ChainEntityModelMapperArgs): TagEntityModel {
        return {
            originalEntity: entityWrapper.info,
            uid: _.uniqueId('chain_'),
            overview: entityWrapper.overview || undefined,
            color: '',
            filters: {
                date_start: startDate,
                date_end: endDate,
                date_label,
                attributes,
            },
        };
    }

    static createEntityModel(chain: TagEntityModel, color: string) {
        const newEntity = _.cloneDeep(chain);

        return {
            ...newEntity,
            uid: _.uniqueId('chain_'),
            color,
        };
    }
}
