import type { ErrorsManagerProps } from 'components/errors/errors-manager/errors-manager';

const PATH_START_INDEX = 1;

const getURLPath = () => window.location.pathname + (window.location.search || '');

export const pageNameFromURL = () => {
    const urlPath = getURLPath();
    return urlPath?.split('/')[PATH_START_INDEX]?.split('?')[0];
};

export const reportNameFromURL = () => {
    const urlPath = getURLPath();
    return urlPath.split('?')[0]?.split('/').pop();
};

export const sectionNameFromURL = () => {
    const urlPath = getURLPath();
    return urlPath.split('/?')[0]?.split('/').pop();
};

export const subPageNameFromUrl = () => {
    const urlPath = getURLPath();
    const pathArr = urlPath.split('/');
    return pathArr[pathArr.length - 1];
};

export const getPageFromPath = (path: string) => path?.split('/')[PATH_START_INDEX]?.split('?')[0];

export function getParsedSectionsFromUrl() {
    const urlPath = getURLPath();
    const partsArr = urlPath.split('/');
    const lastPartsArr = partsArr[partsArr.length - 1].split('?');
    let lastPart = lastPartsArr[0];
    // const queryParams = lastPartsArr[1];
    if (!lastPart) {
        // in case the URL was "/chains/demographics/?params=1234" (with the redundant last "/")
        partsArr.pop();
    }
    lastPart = partsArr[partsArr.length - 1];

    const sections: Omit<ErrorsManagerProps, 'children' | 'uncaughtExceptionComponent'> = {
        // initialize with naive guesses
        page: partsArr[0],
        tab: partsArr[1],
        section: partsArr[2],
        subSection: partsArr[3],
    };

    // make smarter divisions
    if (sections.page === 'advanced-reports') {
        if (sections.tab === 'site-selection') {
            sections.section = partsArr[3];
            sections.subSection = undefined;
        } else if (sections.tab === 'void-analysis') {
            sections.section = partsArr[4];
            sections.subSection = undefined;
        }
    } else if (
        sections.page === 'insights' ||
        sections.page === 'chains' ||
        sections.page === 'industry'
    ) {
        sections.tab = lastPart;
        sections.section = undefined;
        sections.subSection = undefined;
    } else if (sections.page === 'my-zone') {
        sections.subSection = undefined;
    }

    return sections;
}
