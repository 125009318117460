/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

type EventsCalendarIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type EventsCalendarIconProps = SVGProps<SVGSVGElement> & EventsCalendarIconUniqueProps;

export const EventsCalendarIcon = function EventsCalendarIcon({
    width = 48,
    height = 48,
    ...props
}: EventsCalendarIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24Z"
                fill="#F9F8F7"
            />
            <path
                d="M8 4C6.34315 4 5 5.34315 5 7V23C5 24.6569 6.34315 26 8 26H20V43C20 44.1046 20.8954 45 22 45H42C43.1046 45 44 44.1046 44 43V25C44 23.8954 43.1046 23 42 23H39V21.5C39 20.6716 38.3284 20 37.5 20C36.6716 20 36 20.6716 36 21.5V23H28V21.5C28 20.8469 27.5826 20.2913 27 20.0854V7C27 5.34315 25.6569 4 24 4H8Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M38.8031 31.2873C38.8628 31.0917 39.1379 31.0918 39.1975 31.2873L39.6825 32.8775C39.7091 32.9648 39.7893 33.0244 39.88 33.0242L41.4804 33.0212C41.6781 33.0208 41.7631 33.2735 41.6057 33.394L40.2912 34.4008C40.2222 34.4537 40.1934 34.5444 40.2191 34.6278L40.7193 36.2451C40.7791 36.4385 40.5565 36.5948 40.3968 36.4716L39.1259 35.4905C39.0519 35.4333 38.9489 35.4333 38.8749 35.4904L37.6031 36.4718C37.4434 36.595 37.2209 36.4387 37.2807 36.2454L37.7809 34.6278C37.8066 34.5444 37.7778 34.4537 37.7088 34.4008L36.3943 33.394C36.2369 33.2735 36.3219 33.0208 36.5196 33.0212L38.12 33.0242C38.2107 33.0244 38.2909 32.9649 38.3176 32.8776L38.8031 31.2873Z"
                fill="#FFD89E"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.4216 8.05417C15.5963 7.48184 16.4046 7.48198 16.5791 8.05436L17.0894 9.72739L18.7682 9.72423C19.3532 9.72313 19.5928 10.4638 19.1367 10.8131L17.7484 11.8765L18.2774 13.5873C18.4496 14.1441 17.8054 14.6146 17.33 14.2477L16.0004 13.2212L14.6698 14.248C14.1944 14.6148 13.5503 14.1443 13.7225 13.5875L14.2516 11.8765L12.8633 10.8131C12.4072 10.4638 12.6468 9.72313 13.2318 9.72423L14.9107 9.72739L15.4216 8.05417ZM16.0003 8.72725L16.4031 10.0482C16.4807 10.3024 16.7152 10.4781 16.9831 10.4776L18.3447 10.475L17.2133 11.3416C17.011 11.4965 16.9277 11.7611 17.0026 12.0034L17.4267 13.3748L16.3697 12.5588C16.1521 12.3908 15.8489 12.3908 15.6313 12.5587L14.5732 13.3752L14.9974 12.0034C15.0723 11.7611 14.989 11.4965 14.7867 11.3416L13.6553 10.475L15.017 10.4776C15.2848 10.4781 15.5193 10.3025 15.5969 10.0484L16.0003 8.72725Z"
                fill="#D0D0D0"
            />
            <path d="M22 17.875H10V17.125H22V17.875Z" fill="#D0D0D0" />
            <path d="M21 20.875H8V20.125H21V20.875Z" fill="#D0D0D0" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23 33C23 32.4477 23.4477 32 24 32H26C26.5523 32 27 32.4477 27 33V35C27 35.5523 26.5523 36 26 36H24C23.4477 36 23 35.5523 23 35V33ZM23.75 33C23.75 32.8619 23.8619 32.75 24 32.75H26C26.1381 32.75 26.25 32.8619 26.25 33V35C26.25 35.1381 26.1381 35.25 26 35.25H24C23.8619 35.25 23.75 35.1381 23.75 35V33Z"
                fill="#D0D0D0"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23 39C23 38.4477 23.4477 38 24 38H26C26.5523 38 27 38.4477 27 39V41C27 41.5523 26.5523 42 26 42H24C23.4477 42 23 41.5523 23 41V39ZM23.75 39C23.75 38.8619 23.8619 38.75 24 38.75H26C26.1381 38.75 26.25 38.8619 26.25 39V41C26.25 41.1381 26.1381 41.25 26 41.25H24C23.8619 41.25 23.75 41.1381 23.75 41V39Z"
                fill="#D0D0D0"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30 39C30 38.4477 30.4477 38 31 38H33C33.5523 38 34 38.4477 34 39V41C34 41.5523 33.5523 42 33 42H31C30.4477 42 30 41.5523 30 41V39ZM31 38.75H33C33.1381 38.75 33.25 38.8619 33.25 39V41C33.25 41.1381 33.1381 41.25 33 41.25H31C30.8619 41.25 30.75 41.1381 30.75 41V39C30.75 38.8619 30.8619 38.75 31 38.75Z"
                fill="#D0D0D0"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30 33C30 32.4477 30.4477 32 31 32H33C33.5523 32 34 32.4477 34 33V35C34 35.5523 33.5523 36 33 36H31C30.4477 36 30 35.5523 30 35V33ZM31 32.75H33C33.1381 32.75 33.25 32.8619 33.25 33V35C33.25 35.1381 33.1381 35.25 33 35.25H31C30.8619 35.25 30.75 35.1381 30.75 35V33C30.75 32.8619 30.8619 32.75 31 32.75Z"
                fill="#D0D0D0"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M37 39C37 38.4477 37.4477 38 38 38H40C40.5523 38 41 38.4477 41 39V41C41 41.5523 40.5523 42 40 42H38C37.4477 42 37 41.5523 37 41V39ZM37.75 39C37.75 38.8619 37.8619 38.75 38 38.75H40C40.1381 38.75 40.25 38.8619 40.25 39V41C40.25 41.1381 40.1381 41.25 40 41.25H38C37.8619 41.25 37.75 41.1381 37.75 41V39Z"
                fill="#D0D0D0"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27 20.0854V7C27 5.34315 25.6569 4 24 4H8C6.34315 4 5 5.34315 5 7V23C5 24.6569 6.34315 26 8 26H20V43C20 44.1046 20.8954 45 22 45H42C43.1046 45 44 44.1046 44 43V25C44 23.8954 43.1046 23 42 23H39V21.5C39 20.6716 38.3284 20 37.5 20C36.6716 20 36 20.6716 36 21.5V23H28V21.5C28 20.8469 27.5826 20.2913 27 20.0854ZM24 4.75H8C6.75736 4.75 5.75 5.75736 5.75 7V23C5.75 24.2426 6.75736 25.25 8 25.25H20V25C20 23.8954 20.8954 23 22 23H25V21.5C25 20.7568 25.5406 20.1398 26.25 20.0207V7C26.25 5.75736 25.2426 4.75 24 4.75ZM27.25 21.5V25.5C27.25 25.9142 26.9142 26.25 26.5 26.25C26.0858 26.25 25.75 25.9142 25.75 25.5V21.5C25.75 21.0858 26.0858 20.75 26.5 20.75C26.9142 20.75 27.25 21.0858 27.25 21.5ZM28 25.5V23.75H36V25.5C36 26.3284 36.6716 27 37.5 27C38.3284 27 39 26.3284 39 25.5V23.75H42C42.6904 23.75 43.25 24.3096 43.25 25V29.25H20.75V25C20.75 24.3096 21.3096 23.75 22 23.75H25V25.5C25 26.3284 25.6716 27 26.5 27C27.3284 27 28 26.3284 28 25.5ZM20.75 30H43.25V43C43.25 43.6904 42.6904 44.25 42 44.25H22C21.3096 44.25 20.75 43.6904 20.75 43V30ZM38.25 25.5V21.5C38.25 21.0858 37.9142 20.75 37.5 20.75C37.0858 20.75 36.75 21.0858 36.75 21.5V25.5C36.75 25.9142 37.0858 26.25 37.5 26.25C37.9142 26.25 38.25 25.9142 38.25 25.5Z"
                fill="#D0D0D0"
            />
        </svg>
    );
};
EventsCalendarIcon.__tags = ['event', 'calendar'] as string[];
