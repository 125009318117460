/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

type GisMapBlueIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type GisMapBlueIconProps = SVGProps<SVGSVGElement> & GisMapBlueIconUniqueProps;

export const GisMapBlueIcon = function GisMapBlueIcon({
    width = 19,
    height = 19,
    ...props
}: GisMapBlueIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_21280_103925)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.08171 0.802612C9.28628 0.802612 11.0737 2.62509 11.0737 4.8733C11.0737 5.9228 10.7249 6.77887 10.0408 7.59853C9.35702 8.41818 7.07373 10.6174 7.07373 10.6174C7.07373 10.6174 4.79079 8.41818 4.10667 7.59853C3.4222 6.77887 3.07373 5.9228 3.07373 4.8733C3.07373 2.62509 4.86118 0.802612 7.06541 0.802612H7.08171ZM7.07383 6.32347C7.84703 6.32347 8.47383 5.68264 8.47383 4.89214C8.47383 4.10164 7.84703 3.46082 7.07383 3.46082C6.30063 3.46082 5.67383 4.10164 5.67383 4.89214C5.67383 5.68264 6.30063 6.32347 7.07383 6.32347Z"
                    fill="#E1E2FF"
                />
                <path
                    d="M1.28258 13.1111L3.88147 11.7152L9.00105 14.465C9.50152 14.7986 10.3129 14.7986 10.8134 14.465L15.933 11.7152L18.5319 13.1111C19.0323 13.4447 19.0323 13.9857 18.5319 14.3193L10.8134 18.465C10.3129 18.7986 9.50152 18.7986 9.00105 18.465L1.28258 14.3193C0.78211 13.9857 0.78211 13.4447 1.28258 13.1111Z"
                    fill="#E1E2FF"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.07383 3.08582C6.08574 3.08582 5.29883 3.9024 5.29883 4.89214C5.29883 5.88188 6.08574 6.69847 7.07383 6.69847C8.06191 6.69847 8.84883 5.88188 8.84883 4.89214C8.84883 3.9024 8.06191 3.08582 7.07383 3.08582ZM6.04883 4.89214C6.04883 4.30088 6.51552 3.83582 7.07383 3.83582C7.63214 3.83582 8.09883 4.30088 8.09883 4.89214C8.09883 5.4834 7.63214 5.94847 7.07383 5.94847C6.51552 5.94847 6.04883 5.4834 6.04883 4.89214Z"
                    fill="#5E63E5"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.10667 7.59853C4.79079 8.41818 7.07373 10.6174 7.07373 10.6174C7.07373 10.6174 9.35702 8.41818 10.0408 7.59853C10.7249 6.77887 11.0737 5.9228 11.0737 4.8733C11.0737 2.62509 9.28628 0.802612 7.08171 0.802612H7.06541C4.86118 0.802612 3.07373 2.62509 3.07373 4.8733C3.07373 5.9228 3.4222 6.77887 4.10667 7.59853ZM9.46488 7.11809C9.14987 7.49569 8.43524 8.22436 7.77215 8.88339C7.51679 9.13718 7.27431 9.3755 7.07376 9.57157C6.87324 9.37551 6.63079 9.13719 6.37546 8.88341C5.71243 8.2244 4.99772 7.49565 4.68247 7.11794C4.09643 6.41616 3.82373 5.72613 3.82373 4.8733C3.82373 3.02542 5.28914 1.55261 7.06541 1.55261H7.08171C8.85836 1.55261 10.3237 3.02546 10.3237 4.8733C10.3237 5.72599 10.0508 6.4161 9.46488 7.11809Z"
                    fill="#5E63E5"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.6118 5.39429C11.5953 5.64587 11.5422 5.90352 11.4597 6.16393L12.7812 6.87369L11.1357 8.44404C11.057 8.51911 11.0148 8.62463 11.02 8.73325L11.1052 10.5138L7.46522 12.7888L1.74285 9.71523L4.03397 8.48465C3.87586 8.2862 3.72338 8.0831 3.58013 7.87707L1.28258 9.11111C0.78211 9.44475 0.78211 9.9857 1.28258 10.3193L3.88145 11.7152L1.28258 13.1111C0.78211 13.4447 0.78211 13.9857 1.28258 14.3193L9.00105 18.465C9.50152 18.7986 10.3129 18.7986 10.8134 18.465L18.5319 14.3193C19.0323 13.9857 19.0323 13.4447 18.5319 13.1111L15.933 11.7152L18.5319 10.3193C19.0323 9.9857 19.0323 9.44475 18.5319 9.11111L11.6118 5.39429ZM15.1405 12.1409L10.8134 14.465C10.3129 14.7986 9.50152 14.7986 9.00105 14.465L4.67397 12.1409L1.74285 13.7152L9.38738 17.8211L9.41708 17.8409C9.51935 17.9091 9.69447 17.9652 9.90723 17.9652C10.12 17.9652 10.2951 17.9091 10.3974 17.8409L10.4271 17.8211L18.0716 13.7152L15.1405 12.1409ZM11.7773 8.86841L13.4763 7.24704L18.0716 9.71523L14.1041 11.8462L11.8552 10.4969L11.7773 8.86841ZM11.4943 11.155L8.22629 13.1975L9.38738 13.8212L9.41708 13.841C9.51935 13.9091 9.69447 13.9652 9.90723 13.9652C10.12 13.9652 10.2951 13.9091 10.3974 13.841L10.4271 13.8212L13.3349 12.2593L11.4943 11.155Z"
                    fill="#5E63E5"
                />
            </g>
            <defs>
                <clipPath id="clip0_21280_103925">
                    <rect
                        width="18"
                        height="18"
                        fill="white"
                        transform="translate(0.907227 0.71521)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
GisMapBlueIcon.__tags = [] as string[];
