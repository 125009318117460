import { Drawer } from 'antd';
import React, { Fragment, useCallback } from 'react';
import { useExploreUIState } from 'features/explore/context/use-explore-ui-state';
import style from './explore-drawer.module.scss';
import { ExploreListItem } from 'features/explore/components/explore-side-bar/components/explore-list-item/explore-list-item';
import {
    useSetDrawerResult,
    useSetOverlayPOI,
} from 'features/explore/context/use-explore-ui-state-actions';
import { ExploreDrawerAlert } from 'features/explore/components/explore-side-bar/components/explore-drawer/explore-drawer-alert';
import { DrawerOptions } from 'features/explore/components/explore-side-bar/components/explore-drawer/drawer-options';
import { getReportEntityFlag } from 'core/services/report-entities-service/report-entities-service';
import { Button } from 'ui-components/button/button';
import { openElevioComponent } from 'utils/elevio/elevio';
import {
    editPopupElevioId,
    requestATrafficPinElevioId,
} from 'features/my-zone-properties/constants';
import { EditIconCopy, GoToIcon } from 'components/assets/Icons/Icons';
import { useGetEnclosingComplexOptions } from 'features/explore/hooks/use-explore-enclosing-complex';
import { ExploreSearchResult } from 'features/explore/types/types';
import { useIsIdSelected } from 'features/explore/hooks/use-explore-selection';
import { openChainsReport } from 'utils/navigation/navigation';
import {
    useGetActiveOverlay,
    useGetOverlayPOI,
} from 'features/explore/hooks/use-explore-overlay-poi';
import { useSendExploreSplunkEvent } from 'features/explore/hooks/use-explore-tracking';
import { useExplorePermissions } from 'features/explore/hooks/use-explore-permissions';
import { EMPTY_SPACE_POI_TYPE } from 'features/empty-space/constants/empty-space-constants';
import { COORDINATES_ENTITY } from 'features/explore/constants';

type ExploreDrawerComponentProps = {
    result: ExploreSearchResult;
    enableSelection?: boolean;
};

export const ExploreDrawerComponent = ({
    result,
    enableSelection = true,
}: ExploreDrawerComponentProps) => {
    const { customPoiAddresses } = useExploreUIState();
    const getEnclosingComplex = useGetEnclosingComplexOptions();
    const { hasStoreIdBadgeMoreOptionsPermission } = useExplorePermissions();
    const flag = getReportEntityFlag(result.info);
    const entityName = result?.info.name;
    const chainId = result?.info.parent_chain_id;
    const isCoordinate =
        (result?.info.type === EMPTY_SPACE_POI_TYPE &&
            !customPoiAddresses[result.info.provider_data?.entity_id || result.info.id]) ||
        result.info.provider_data?.entity_id === COORDINATES_ENTITY;
    const sendSplunkEvent = useSendExploreSplunkEvent();

    const onOpenChainReport = () => {
        sendSplunkEvent({
            component: 'Report Options Panel',
            action: 'open report',
            value: 'chain',
        });
        openChainsReport(chainId);
    };

    const bottomDrawer = (
        <div className={style.footerOptions}>
            {chainId && (
                <div className={style.option}>
                    <Button
                        onClick={onOpenChainReport}
                        className={style.suggestEditBtn}
                        icon={<GoToIcon />}
                    >
                        <span className={style.menuText}>Open a Chain Report for {entityName}</span>
                    </Button>
                </div>
            )}
            {/* TODO: PLAC-25371 - add industry link */}
            <div className={style.option}>
                <Button
                    onClick={() =>
                        openElevioComponent(
                            isCoordinate ? requestATrafficPinElevioId : editPopupElevioId,
                            'module',
                        )
                    }
                    className={style.suggestEditBtn}
                    icon={<EditIconCopy />}
                >
                    <span className={style.menuText}>
                        {isCoordinate ? 'Request a Traffic Pin' : 'Suggest an edit'}
                    </span>
                </Button>
            </div>
        </div>
    );

    return (
        <div data-testid={'explore-drawer'}>
            <ExploreListItem
                poi={result.info}
                withFlag={false}
                hasStoreIdBadgePermission={hasStoreIdBadgeMoreOptionsPermission}
            />
            {flag && <ExploreDrawerAlert poi={result.info} />}
            <div className={style.reportOptionsList}>
                {DrawerOptions[
                    result.info.type === EMPTY_SPACE_POI_TYPE ||
                    result.info.provider_data?.entity_id === COORDINATES_ENTITY ||
                    !!customPoiAddresses[result.info.provider_data?.entity_id || result.info.id]
                        ? 'unknown_flag'
                        : flag || 'default'
                ].map((option, key) => (
                    <Fragment key={key}>
                        {option({
                            poi: result.info,
                            enclosingComplex: getEnclosingComplex(result.info.id),
                            enableSelection,
                        })}
                    </Fragment>
                ))}
            </div>
            {bottomDrawer}
        </div>
    );
};

export const ExploreDrawer = () => {
    const { drawerResult } = useExploreUIState();
    const setDrawerResult = useSetDrawerResult();
    const getActiveOverlay = useGetActiveOverlay();
    const getOverlayPOI = useGetOverlayPOI();

    const setOverlayPOI = useSetOverlayPOI();
    const getIsIdSelected = useIsIdSelected();

    // on dismiss, reset the overlay if the current poi is not selected
    const onClose = useCallback(() => {
        if (drawerResult && !getIsIdSelected(drawerResult.info)) {
            setOverlayPOI({
                sourcePoi: drawerResult.info.id,
                overlayPoi:
                    getActiveOverlay(drawerResult.info) ?? getOverlayPOI(drawerResult.info)[0],
            });
        }
        setDrawerResult();
    }, [
        drawerResult,
        getActiveOverlay,
        getIsIdSelected,
        getOverlayPOI,
        setDrawerResult,
        setOverlayPOI,
    ]);

    if (!drawerResult) {
        return null;
    }

    return (
        <Drawer
            title="Report Options"
            placement="bottom"
            height={'80%'}
            className={style.exploreDrawer}
            closable={true}
            onClose={onClose}
            destroyOnClose={true}
            visible={!!drawerResult}
            getContainer={false}
        >
            <ExploreDrawerComponent result={drawerResult} />
        </Drawer>
    );
};
