import _ from 'lodash/fp';
import { createSelector, EntitySelectors } from '@reduxjs/toolkit';

import { theSameDateFilterApplied } from 'core/services/report-filters-service/report-filters-service';
import type { PlacerEntityModel } from '@placer-ui/types';

const includesIsPurchased = _.find(['isPurchased', false]);

export const createSelectHasNotPurchasedEntity = (selectEntities: any) =>
    createSelector(selectEntities, (entities: any[]) => {
        return Boolean(includesIsPurchased(entities));
    });

export const createSelectEntitiesHasTheSameDateFilter = (
    selectAllEntities: EntitySelectors<PlacerEntityModel<any>, any>['selectAll'],
) => createSelector(selectAllEntities, theSameDateFilterApplied);

export const createSelectPrimaryEntityFilters = (
    selectAllEntities: EntitySelectors<PlacerEntityModel<any>, any>['selectAll'],
) =>
    createSelector(selectAllEntities, (all) => {
        const primaryEntity = _.head(all);

        const primaryEntityFilter = primaryEntity!.filters;

        return primaryEntityFilter;
    });
