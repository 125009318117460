import React from 'react';
import classNames from 'classnames';
import styles from './search-poi-selector-table.module.scss';

import { PoiSelectionTable } from 'features/poi-selection-map/poi-selection-menu/shared-components/poi-selection-table/poi-selection-table';
import { usePoiSelectionContext } from 'features/poi-selection-map/context/poi-selection-context';
import { SearchInputAutocomplete } from 'features/poi-selection-map/poi-selection-menu/menu-items/search-menu-item/search-input-autocomplete/search-input-autocomplete';
import { useSearchPoiContext } from 'features/poi-selection-map/poi-selection-menu/menu-items/search-menu-item/context/search-poi-context';
import { SearchPoiFilters } from 'features/poi-selection-map/poi-selection-menu/menu-items/search-menu-item/search-poi-filters/search-poi-filters';
import { SearchPoiEmptyState } from 'features/poi-selection-map/poi-selection-menu/menu-items/search-menu-item/search-poi-empty-state/search-poi-empty-state';

type SearchPoiSelectorTableProps = {
    className?: string;
};

export const SearchPoiSelectorTable = ({ className }: SearchPoiSelectorTableProps) => {
    const { currentItemConfiguration } = usePoiSelectionContext();
    const { isLoadingPois } = useSearchPoiContext();
    const searchTitle = currentItemConfiguration?.config?.searchBarText || 'Search';

    return (
        <div className={classNames(styles.container, className)}>
            <SearchInputAutocomplete title={searchTitle} />
            <SearchPoiFilters />
            <PoiSelectionTable
                showSearchBar={false}
                isLoading={isLoadingPois}
                tableProps={{
                    customEmptyState: <SearchPoiEmptyState />,
                }}
            />
        </div>
    );
};
