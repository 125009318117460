import queryString from 'query-string';
import type {
    Dictionary,
    Place,
    PlaceType,
    PlacerEntityWrapper,
    PointOfInterestEntities,
    Venue,
    PlacerResponseData,
} from '@placer-ui/types';

import { fetchWrapper } from 'API/authentification/fetch-wrapper';
import { ComparisonDates } from 'types/properties-data';
import { searchProperty } from 'API/search';
import { RecentEntity } from 'core/entities/recent-entity/recent-entity';
import { PropertiesGroup } from 'features/my-zone-properties/types/groups';
import { PropertyMetricType, PropertyRanksType, RankResponseType } from 'types/property-ranks';
import { CacheDateOption } from 'core/entities/user/user-configuration';
import { reportException } from 'core/exceptions';
import { placeTypeToPlaceCollection } from 'utils/place-type-collection-conversion/place-type-collection-conversion';
import { URLEntity } from 'features/insights/types/url-types';
import { getAppGatewayEndpoint } from 'core/default-endpoint-url/default-endpoint-url';
import { getFeatureFlags, hasFeatureFlags } from 'core/flow-control';

export type MetricRankResponseType = {
    value: number | null;
    ranks?: { [key in PropertyRanksType]?: RankResponseType };
};

export type PropertyEntityMetrics = { [metric in PropertyMetricType]?: MetricRankResponseType };

export interface PropertyEntity {
    compared: {
        metrics: PropertyEntityMetrics;
        rank_error: string | null;
    };
    info: Place;
    main: {
        metrics: PropertyEntityMetrics;
        rank_error: string | null;
    };
}

export interface DateOption extends CacheDateOption {
    compare_start: string;
    compare_end: string;
}

export interface PropertiesResponseType {
    entities: PropertyEntity[];
    lists?: DataList;
    preset_dates?: Dictionary<DateOption>;
}

export interface DataList {
    [listId: string]: {
        chain: string;
        closed: number;
        flagged: number;
        metric: string;
        monthly_flagged: number;
        no_data: number;
        ranked: number;
        state: string;
        unfiltered: number;
        unverified: number;
        zero_ft: number;
    };
}

export type GroupType = 'user' | 'account';

export const propertiesApi = {
    checkSufficientData: async (properties: URLEntity[], options?: { signal?: AbortSignal }) => {
        const { enable_remove_competitors_params_from_api_ff } = getFeatureFlags();

        const place = properties[0];
        const promises: Promise<void>[] = [];
        properties.forEach(({ id, type, filter }, index) => {
            const baseUrl = `/${placeTypeToPlaceCollection(type)}/${id}/fullpage-wait`;
            const params = new URLSearchParams();
            if (index > 0 && !enable_remove_competitors_params_from_api_ff) {
                params.append(`competitor_${type}_id`, place.id);
                params.append('competitor_start_date', place.filter.date.start);
                params.append('competitor_end_date', place.filter.date.end);
            }
            params.append('start_date', filter.date.start);
            params.append('end_date', filter.date.end);

            const targetUrl = `${baseUrl}?${params.toString()}`;
            promises.push(
                fetchWrapper({
                    targetUrl,
                    method: 'POST',
                    body: {
                        config: filter.config,
                        filter: filter.attributes,
                    },
                    signal: options?.signal,
                }),
            );
        });
        await Promise.all(promises);
    },
    getAccountProperties: (accountPropertiesTagId: string | undefined, dates: ComparisonDates) => {
        const queryParams = queryString.stringify(dates);
        return fetchWrapper<PlacerResponseData<PropertiesResponseType>>({
            targetUrl: `/tags/${accountPropertiesTagId}/member-metrics?v2=t&${queryParams}`,
            basePath: `${process.env.REACT_APP_CHAINS_SERVICE_PATH}`,
            endpoint: getAppGatewayEndpoint(),
        }).then(({ data }) => data);
    },
    getSalesMetricsByTagId: (
        accountPropertiesTagId: string | undefined,
        dates: ComparisonDates,
    ) => {
        const queryParams = queryString.stringify(dates);
        return fetchWrapper<PlacerResponseData<PropertiesResponseType>>({
            targetUrl: `/tags/${accountPropertiesTagId}/sales/venues/metrics?${queryParams}`,
        }).then(({ data }) => data);
    },
    getPropertyTenants: (propertyId: string, dates: ComparisonDates) => {
        const queryParams = queryString.stringify({
            ...dates,
            ...{ exclude_closed: true },
        });
        return fetchWrapper<PlacerResponseData<PropertiesResponseType>>({
            targetUrl: `/complex/${propertyId}/member-metrics?v2=t&${queryParams}`,
            basePath: `${process.env.REACT_APP_CHAINS_SERVICE_PATH}`,
            endpoint: getAppGatewayEndpoint(),
        })
            .then(({ data }) => data)
            .catch((error) => {
                reportException(error, {
                    payload: {
                        basePath: `${process.env.REACT_APP_CHAINS_SERVICE_PATH}`,
                        endpoint: getAppGatewayEndpoint(),
                    },
                    target_url: `/complex/${propertyId}/member-metrics?v2=t&${queryParams}`,
                    method: 'GET',
                    status_code: error.statusCode,
                });

                return undefined;
            });
    },
    getSalesMetricsByComplexId: (propertyId: string, dates: ComparisonDates) => {
        const queryParams = queryString.stringify({
            ...dates,
            exclude_closed: true,
        });
        return fetchWrapper<PlacerResponseData<PropertiesResponseType>>({
            targetUrl: `/complexes/${propertyId}/sales/tenants/metrics?${queryParams}`,
        })
            .then(({ data }) => data)
            .catch((error) => {
                reportException(error, {
                    target_url: `/complexes/${propertyId}/sales/tenants/metrics?${queryParams}`,
                    method: 'GET',
                    status_code: error.statusCode,
                });

                return undefined;
            });
    },
    searchProperties: (term: string) => {
        const hasNearbyInTags = hasFeatureFlags(['enable_nearby_in_tags_and_portfolio_ff']);

        return searchProperty({
            term,
            source: 'portfolio',
            enableClosedPOIs: true,
            enableCustomPOIs: true,
            enableNearbyActivityPOIs: hasNearbyInTags,
            enableFlaggedPOIs: hasNearbyInTags,
        });
    },
    addAccountProperty: (
        accountPropertiesTagId: string | undefined,
        property: Place | RecentEntity,
    ) => {
        const queryParams = queryString.stringify({
            tag_id: accountPropertiesTagId,
            member_id: property.id,
            member_type: property.type,
        });

        return fetchWrapper<PlacerResponseData<{}>>({
            targetUrl: `/accountag/add_member?${queryParams}`,
            method: 'POST',
            body: {},
        });
    },
    removeAccountProperty: (accountPropertiesTagId: string | undefined, propertyId: string) => {
        const queryParams = queryString.stringify({
            tag_id: accountPropertiesTagId,
            member_id: propertyId,
        });
        return fetchWrapper<PlacerResponseData<{}>>({
            targetUrl: `/accountag/delete_member?${queryParams}`,
            method: 'POST',
        });
    },
    getAllGroups: async (scope?: GroupType): Promise<PlacerResponseData<PropertiesGroup[]>> => {
        const queryParams = scope ? queryString.stringify({ scope }) : '';
        return fetchWrapper({ targetUrl: `/properties/groups?${queryParams}` });
    },
    getCompetitors: async (id: string, type: PlaceType, options?: { signal?: AbortSignal }) => {
        const res = await fetchWrapper<PlacerResponseData<PlacerEntityWrapper<Venue>[]>>({
            targetUrl: `/${placeTypeToPlaceCollection(type)}/${id}/competitors`,
            signal: options?.signal,
        });
        return res.data;
    },
    getProperty: async (
        id: string,
        type: PlaceType,
        options?: { signal?: AbortSignal; is_nearby?: boolean },
    ) => {
        const queryParams = queryString.stringify({
            is_nearby: options?.is_nearby || false,
        });

        return fetchWrapper<PlacerResponseData<PointOfInterestEntities>>({
            targetUrl: `/${placeTypeToPlaceCollection(type)}/${id}?${queryParams}`,
            signal: options?.signal,
        });
    },
};
