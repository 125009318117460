import React, { BaseSyntheticEvent, MouseEvent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import _ from 'lodash/fp';
import { telemetryApiService } from 'API/telemetry-api/telemetry-api';
import { MARKETPLACE_EXTERNAL_URL } from 'shared/constants/marketplace';
import styles from '../navigation-button.module.scss';
import { ADVANCED_MAPS_URL } from 'shared/constants/advanced-maps';
import { DESKTOP_ROUTES } from 'router/routes/desktop.routes';
import { PlLazyComponent } from 'router/utils/lazy-with-reload/lazy-with-reload';

export type NavigationButtonProps = {
    label: string;
    link: string;
    isSelected: boolean;
    onClick: (event: MouseEvent) => void;
};

export const NavigationButton = ({
    link,
    label,
    isSelected,
    onClick = _.noop,
}: NavigationButtonProps) => {
    const [currentPath, setCurrentPath] = useState(window.location.pathname);

    useEffect(() => {
        if (currentPath !== window.location.pathname) {
            setCurrentPath(window.location.pathname);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.pathname]);

    if (!link) {
        return null;
    }

    const isExternalLink = link === MARKETPLACE_EXTERNAL_URL;

    //TODO: fix event type when migrating to React
    const defaultClick = (event: BaseSyntheticEvent) => {
        const shouldSendTelemetry = event.target.href.includes('insights') && !currentPath.includes('insights');

        if (shouldSendTelemetry) {
            telemetryApiService.reportStartEvent('switchTabLoadingTime');
        }
        onClick(event as MouseEvent);
    };

    const navComponentProps = {
        className: classNames(styles.navItemButton, {
            [styles.isSelected]: isSelected,
        }),
        target: isExternalLink ? '_blank' : '_self',
        rel: 'noreferrer',
    };

    const handleClickLogic = (event: any) => {
        // Prevent open new tab for advanced map tab
        if (
            document.location.href.includes(ADVANCED_MAPS_URL) &&
            link.includes(ADVANCED_MAPS_URL)
        ) {
            event.preventDefault();
        }
    };

    return (
        <span
            className={styles.navItemWithPopover}
            onClick={(e) => defaultClick(e)}
            onMouseOver={() => {
                const route = DESKTOP_ROUTES.find((route) => {
                    if (Array.isArray(route?.path)) {
                        return route.path.some((path) => link.includes(path));
                    } else {
                        return link.includes(route?.path as string);
                    }
                });
                (route?.Component as PlLazyComponent)?.preload?.();
            }}
        >
            {isExternalLink ? (
                <a href={link} onClick={handleClickLogic} {...navComponentProps}>
                    {label}
                </a>
            ) : (
                <Link to={link} {...navComponentProps}>
                    {label}
                </Link>
            )}
        </span>
    );
};
