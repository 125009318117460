import { createAsyncThunk } from '@reduxjs/toolkit';

import { chainsAPI } from 'API/industry-api/industry-api';

const createGetSearchSuggestedBrands = (chainsApi: typeof chainsAPI) =>
    createAsyncThunk('industry/getSuggestedBrands', async () => {
        const params = {
            term: '',
            limit: 50,
            purchasedOnly: false,
            region_type: 'nationwide',
        };

        const { data: suggestedIndustries } = await chainsApi.getChainsByTerm(params);

        return suggestedIndustries;
    });

export const getSearchSuggestedIndustries = createGetSearchSuggestedBrands(chainsAPI);
