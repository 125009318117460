import { heapIdentify, heapTrack } from 'utils/heap/heap';
import { sendGoogleAnalyticsEvent } from 'utils/google-analytics/google-analytics';
import { AuthValue } from 'features/authentication/types/types';

type SendAuthenticationEventsParams = {
    userEmail: string;
    authMode: AuthValue;
};

export const sendGoogleAuthEvents = ({ userEmail, authMode }: SendAuthenticationEventsParams) => {
    heapIdentify(userEmail);

    if (authMode === AuthValue.SignUp) {
        heapTrack('Signup Success', {
            method: 'google',
        });
        heapTrack('Account Activated', {
            method: 'Google',
        });
        sendGoogleAnalyticsEvent({
            category: 'Account Created',
            action: 'Google Authentication',
        });
    } else {
        heapTrack('Login Success', {
            method: 'google',
        });
    }
};
