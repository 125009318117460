import { anonymousUserPermissionsSelector } from 'store/selectors/app-selectors';
import { AvailableAnonymousUserPermissions } from 'core/entities/user/anonymous-user-permissions';
import { useSelector } from 'react-redux';

export const useHasAnonymousUserPermissions = (
    permissionName: AvailableAnonymousUserPermissions,
) => {
    const allPermissions = useSelector(anonymousUserPermissionsSelector);
    return allPermissions?.[permissionName] ?? false;
};
