/* eslint-disable max-len */
import React from 'react';

export function WeatherSleetIcon() {
    return (
        <svg width="70" height="54" viewBox="0 0 70 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.5908 39.6213C16.3522 39.9476 16.7049 40.8295 16.3786 41.5909L13.3786 48.5909C13.0523 49.3523 12.1705 49.7051 11.409 49.3787C10.6476 49.0524 10.2948 48.1706 10.6212 47.4091L13.6212 40.4091C13.9475 39.6477 14.8293 39.295 15.5908 39.6213Z" fill="#6FA2CE"/>
<path d="M22.5908 39.6213C23.3522 39.9476 23.7049 40.8295 23.3786 41.5909L18.8786 52.0909C18.5523 52.8523 17.6705 53.2051 16.909 52.8787C16.1476 52.5524 15.7948 51.6706 16.1212 50.9091L20.6212 40.4091C20.9475 39.6477 21.8293 39.295 22.5908 39.6213Z" fill="#6FA2CE"/>
<path d="M30.3786 41.5909C30.7049 40.8295 30.3522 39.9476 29.5908 39.6213C28.8293 39.295 27.9475 39.6477 27.6212 40.4091L24.6212 47.4091C24.2948 48.1706 24.6476 49.0524 25.409 49.3787C26.1705 49.7051 27.0523 49.3523 27.3786 48.5909L30.3786 41.5909Z" fill="#6FA2CE"/>
<path d="M46.5512 36.7385C46.4366 36.808 46.3279 36.8836 46.2251 36.9647L26.646 36.9158H5.92549C3.49312 34.2731 2.00925 30.757 2 26.8931C2.01589 20.2206 6.38919 14.5768 12.4161 12.6654C13.839 12.2162 15.3544 11.9739 16.927 11.9739C17.8352 11.9739 18.7228 12.0546 19.5835 12.2086C23.5448 12.9235 26.9639 15.2059 29.1785 18.3958C29.4935 18.8494 30.1166 18.9619 30.5703 18.6469C31.0239 18.3319 31.1364 17.7088 30.8214 17.2551C28.7151 14.2214 25.6429 11.9082 22.0502 10.7643C25.4995 5.48523 31.468 2 38.253 2C48.2969 2 56.6348 10.1227 57.5014 20.0199L57.6612 21.8455H60.3979C64.6012 21.8455 67.9999 25.2428 67.9999 29.423C67.9999 33.602 64.6023 36.999 60.3997 37H60.3979L59.8172 36.9986C59.7021 36.9046 59.5792 36.8176 59.4488 36.7385C60.9437 35.8314 61.4463 33.8911 60.5677 32.3693C59.6891 30.8474 57.7575 30.3126 56.2245 31.1536C56.1865 29.4054 54.7574 28 53.0001 28C51.2428 28 49.8137 29.4055 49.7758 31.1537C48.2428 30.3125 46.311 30.8474 45.4323 32.3693C44.5537 33.8911 45.0563 35.8314 46.5512 36.7385Z" fill="#D0D0D0"/>
<path d="M51.7749 31.2252C51.7749 30.5485 52.3235 30 53.0001 30C53.6767 30 54.2253 30.5485 54.2253 31.2252V34.6163L57.162 32.9208C57.748 32.5825 58.4973 32.7833 58.8356 33.3693C59.1739 33.9553 58.9731 34.7045 58.3871 35.0429L55.4503 36.7385L58.3872 38.4341C58.9731 38.7724 59.1739 39.5217 58.8356 40.1077C58.4973 40.6937 57.748 40.8944 57.162 40.5561L54.2253 38.8606V42.2516C54.2253 42.9283 53.6767 43.4768 53.0001 43.4768C52.3235 43.4768 51.7749 42.9283 51.7749 42.2516V38.8604L48.8379 40.5561C48.252 40.8944 47.5027 40.6936 47.1643 40.1077C46.826 39.5217 47.0268 38.7724 47.6128 38.4341L50.5496 36.7385L47.6128 35.0429C47.0268 34.7046 46.826 33.9553 47.1643 33.3693C47.5027 32.7833 48.252 32.5825 48.838 32.9208L51.7749 34.6165V31.2252Z" fill="#D0D0D0"/>
<path d="M38.9793 42.021C38.9793 41.4571 39.4364 41 40.0003 41C40.5641 41 41.0213 41.4571 41.0213 42.021V44.7316L43.3688 43.3762C43.8571 43.0943 44.4816 43.2616 44.7635 43.7499C45.0454 44.2383 44.8781 44.8627 44.3898 45.1446L42.0422 46.5L44.3896 47.8553C44.878 48.1372 45.0453 48.7617 44.7633 49.25C44.4814 49.7383 43.857 49.9057 43.3686 49.6237L41.0213 48.2685V50.979C41.0213 51.5429 40.5641 52 40.0003 52C39.4364 52 38.9793 51.5429 38.9793 50.979V48.2684L36.6319 49.6236C36.1436 49.9056 35.5192 49.7383 35.2372 49.2499C34.9553 48.7616 35.1226 48.1372 35.6109 47.8552L37.9582 46.5L35.6108 45.1447C35.1224 44.8628 34.9551 44.2383 35.2371 43.75C35.519 43.2617 36.1434 43.0943 36.6318 43.3763L38.9793 44.7316V42.021Z" fill="#D0D0D0"/>
</svg>

    );
}
WeatherSleetIcon.__tags = [] as string[];
