import type { ConfigurationCategoriesData } from '@placer-ui/types';
import { MultiSelectOption } from 'ui-components/multi-select/types';

type CategoriesAsMultiSelectOptions = {
    categories: ConfigurationCategoriesData[];
    selectedCategories: string[];
    disabledGroupCategory?: string[];
    hasSubCategorySelectionPermission?: boolean;
};

export const getCategoriesAsMultiSelectOptions = ({
    categories,
    selectedCategories,
    disabledGroupCategory,
}: CategoriesAsMultiSelectOptions): MultiSelectOption[] => {
    return categories.map((groupCategory) => {
        const subOptions: MultiSelectOption[] = groupCategory.children!.map((primary) => {
            return {
                name: primary.label,
                value: primary.label,
                selected:
                    selectedCategories?.includes(primary.label) || !selectedCategories?.length,
                disabled: disabledGroupCategory?.includes(groupCategory.label),
                key: `${groupCategory.label}-${primary.label}`,
            };
        });
        return {
            name: groupCategory.label,
            value: groupCategory.label,
            selected: subOptions?.every(({ selected }) => selected) ?? true,
            subOptions: subOptions ?? [],
            key: groupCategory.label,
        };
    });
};

export const getRegionCategoriesAsMultiSelectOptions = ({
    categories,
    selectedCategories,
    hasSubCategorySelectionPermission,
}: CategoriesAsMultiSelectOptions): MultiSelectOption[] => {
    const regionPrimaryCategories = categories.find((group) => group.label === 'Region')?.children;

    if (!regionPrimaryCategories?.length) return [];

    if (hasSubCategorySelectionPermission) {
        return regionPrimaryCategories.reduce<MultiSelectOption[]>((acc, primary) => {
            const subCategoriesOptions = primary.children?.map((subCategory) => {
                return {
                    name: subCategory.label,
                    value: subCategory.label,
                    selected:
                        selectedCategories?.includes(subCategory.label) ||
                        !selectedCategories?.length,
                    key: `${primary.label}-${subCategory.label}`,
                };
            });
            if (subCategoriesOptions?.length) {
                acc.push({
                    name: primary.label,
                    value: primary.label,
                    selected: subCategoriesOptions?.every(({ selected }) => selected) ?? true,
                    key: primary.label,
                    subOptions: subCategoriesOptions,
                });
            }
            return acc;
        }, []);
    } else {
        return regionPrimaryCategories.reduce<MultiSelectOption[]>((acc, primary) => {
            acc.push({
                name: primary.label,
                value: primary.label,
                selected:
                    selectedCategories?.includes(primary.label) || !selectedCategories?.length,
                key: primary.label,
            });
            return acc;
        }, []);
    }
};

type CategoriesAsMultiSelectHierarchyOptions = {
    categories: ConfigurationCategoriesData[];
    selectedCategories: string[];
    disabledGroupCategory?: string[];
};

export const getCategoriesAsTreeOptions = ({
    categories,
    selectedCategories,
    disabledGroupCategory,
}: CategoriesAsMultiSelectHierarchyOptions): MultiSelectOption[] => {
    return categories.reduce<MultiSelectOption[]>((accA, groupCategory) => {
        const primaryOptions: MultiSelectOption[] = groupCategory.children!.reduce<
            MultiSelectOption[]
        >((accB, primaryCategory) => {
            const subCategoriesOptions = primaryCategory.children?.map((subCategory) => {
                return {
                    name: subCategory.label,
                    value: subCategory.label,
                    selected:
                        selectedCategories?.includes(subCategory.label) ||
                        !selectedCategories?.length,
                    key: `${groupCategory.label}-${primaryCategory.label}-${subCategory.label}`,
                };
            });

            if (subCategoriesOptions?.length) {
                accB.push({
                    name: primaryCategory.label,
                    value: primaryCategory.label,
                    selected: subCategoriesOptions?.every(({ selected }) => selected) ?? true,
                    subOptions: subCategoriesOptions ?? [],
                    disabled: disabledGroupCategory?.includes(groupCategory.label),
                    key: `${groupCategory.label}-${primaryCategory.label}`,
                });
            }
            return accB;
        }, []);
        if (primaryOptions?.length) {
            accA.push({
                name: groupCategory.label,
                value: groupCategory.label,
                selected: primaryOptions?.every(({ selected }) => selected) ?? true,
                subOptions: primaryOptions ?? [],
                key: groupCategory.label,
            });
        }
        return accA;
    }, []);
};
