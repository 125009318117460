import {
    ComparisonDates,
    MetricRankData,
    PropertyData,
    PropertyDataUIModel,
    RankType,
} from 'types/properties-data';
import {
    propertiesApi,
    PropertiesResponseType,
    PropertyEntity,
    PropertyEntityMetrics,
} from 'API/properties-api/properties-api';
import { PropertyMetricType, PropertyRanksType } from 'types/property-ranks';
import {
    RANKING_SALES_FILTERED_OUT_METRICS,
    RETAIL_SALES_API_FILTERED_OUT_METRICS,
} from 'shared/constants/retail-sales';
import { get, set, sumBy } from 'lodash';
import { getFullAddress } from 'features/my-zone/shared/utils/utils';
import { reportException } from 'core/exceptions';
import { tagsApi } from 'API/tags-api';
import type { Place, PlacerEntityWrapper, Venue } from '@placer-ui/types';

const currencyMetrics: PropertyMetricType[] = [
    'sales',
    'avg_ticket_size',
    'sales_sq_ft',
    'SALES_EST',
    'SALES_SQ_FT_EST',
];

export const propertiesInitialSortingFn = (
    propertyA: PropertyDataUIModel,
    propertyB: PropertyDataUIModel,
) => {
    const weightA = propertyA.data.metrics.FT?.value ?? 0;
    const weightB = propertyB.data.metrics.FT?.value ?? 0;
    return weightB - weightA;
};

const normalizeEntityInfo = (entityInfo: Place) => {
    return {
        ...entityInfo,
        category: !entityInfo.category ? 'Other' : entityInfo.category,
    };
};

const getFilteredMetrics = (metrics: PropertyEntityMetrics, filteredList: PropertyMetricType[]) =>
    Object.entries(metrics).reduce<PropertyEntityMetrics>((acc, [metricKey, value]) => {
        if (!filteredList.includes(metricKey as PropertyMetricType)) {
            acc[metricKey as PropertyMetricType] = value;
        }
        return acc;
    }, {});

type GetContentWithFilteredMetricsArg = {
    content?: PropertiesResponseType;
    filteredList: PropertyMetricType[];
};

export const getContentWithFilteredMetrics = ({
    content,
    filteredList,
}: GetContentWithFilteredMetricsArg) => {
    const newContent: PropertiesResponseType = content && JSON.parse(JSON.stringify(content));

    newContent?.entities?.forEach((entity) => {
        const mainMetrics = entity.main?.metrics;
        const comparedMetrics = entity?.compared?.metrics;

        if (mainMetrics) {
            entity.main.metrics = getFilteredMetrics(mainMetrics, filteredList);
        }

        if (comparedMetrics) {
            entity.compared.metrics = getFilteredMetrics(comparedMetrics, filteredList);
        }
    });

    return newContent;
};

export const normalizePropertiesResponse = (
    response: PropertiesResponseType,
    hasShareMetricsPermission = false,
): PropertyData[] => {
    const { entities = [], lists = {} } = response;

    const totalVisits = sumBy(entities, 'main.metrics.FT.value');
    const totalSales = sumBy(entities, 'main.metrics.SALES_EST.value');

    const newEntitiesArr = entities.map((entity) => {
        const metricsIds = Object.keys(entity.main?.metrics ?? {}) as PropertyMetricType[];
        const metrics = metricsIds
            .slice(0)
            .sort()
            .reduce<{ [key in PropertyMetricType]?: MetricRankData }>((metricsObj, metricId) => {
                const { value: mainValue, ranks: mainRanks } = entity.main?.metrics[metricId] || {};
                const { value: comparedValue, ranks: comparedRanks } =
                    entity.compared?.metrics?.[metricId] || {};
                const ranksKeys = Object.keys(mainRanks || {}) as PropertyRanksType[];
                const change =
                    mainValue && comparedValue
                        ? Math.round(((mainValue - comparedValue) / comparedValue) * 100)
                        : null;
                const actualChange =
                    mainValue && comparedValue
                        ? ((mainValue - comparedValue) / comparedValue) * 100
                        : null;
                let ranks = ranksKeys.reduce<{ [key in PropertyRanksType]?: RankType }>(
                    (acc, rankType) => {
                        const { rank, list_id, percentile } = mainRanks?.[rankType] || {};
                        const comparedRank = comparedRanks?.[rankType]?.rank;
                        const ranked = list_id ? lists[list_id]?.ranked : null;
                        const change = rank && comparedRank ? comparedRank - rank : null;

                        acc[rankType] = {
                            rank,
                            ranked,
                            change,
                            percentile,
                        };
                        return acc;
                    },
                    {},
                );

                metricsObj[metricId] = {
                    value: mainValue,
                    change,
                    actualChange,
                    ranks,
                    signType: currencyMetrics.includes(metricId) ? 'currency' : undefined,
                };
                return metricsObj;
            }, {});

        const footTraffic = metrics.FT?.value;
        const footTrafficPerSqFt = metrics.FT_PER_SQFT?.value;
        const sales = metrics.SALES_EST?.value;

        metrics['areaSqFt'] = {
            value: footTraffic && footTrafficPerSqFt ? footTraffic / footTrafficPerSqFt : undefined,
            change: 0,
            actualChange: 0,
            ranks: {},
        };

        if (totalVisits && hasShareMetricsPermission) {
            metrics['visitsShare'] = {
                value: metrics['FT']?.value ? (metrics['FT'].value / totalVisits) * 100 : 0,
                change: 0,
                actualChange: 0,
                ranks: {},
                signType: 'percentage',
            };
        }

        if (totalSales && hasShareMetricsPermission) {
            metrics['salesShare'] = {
                value: sales ? (sales / totalSales) * 100 : 0,
                change: 0,
                actualChange: 0,
                ranks: {},
                signType: 'percentage',
            };
        }

        const info = normalizeEntityInfo(entity.info);

        return {
            info: {
                ...info,
                address: {
                    ...info.address,
                    formatted_address: getFullAddress(entity),
                },
            },
            data: {
                metrics,
                rankErrors: {
                    main: entity.main?.rank_error,
                    compared: entity.compared?.rank_error,
                },
            },
            key: entity.info.id,
        };
    });

    return newEntitiesArr.sort(propertiesInitialSortingFn);
};

export const isAvailable = (property: PropertyData, tagId?: string) => {
    if (tagId && (property.info.type === 'billboard' || property.info.type === 'chain')) {
        return true;
    } else {
        return (property.info as Venue)?.profile === 'available';
    }
};

export const getCombinedPropertiesMetricsContent = (
    mainContent?: PropertiesResponseType,
    salesContent?: PropertiesResponseType,
) => {
    const combined: PropertiesResponseType = mainContent
        ? JSON.parse(JSON.stringify(mainContent))
        : {};
    const distinctEntities: PropertyEntity[] = [];

    salesContent?.entities?.forEach((entity) => {
        const mainEntity = combined?.entities?.find(({ info: { id } }) => id === entity.info.id);
        if (mainEntity) {
            if (entity?.main?.metrics) {
                mainEntity.main.metrics = {
                    ...mainEntity.main.metrics,
                    ...entity.main.metrics,
                };
            }

            if (entity?.compared?.metrics) {
                mainEntity.compared.metrics = {
                    ...mainEntity.compared.metrics,
                    ...entity.compared.metrics,
                };
            }
        } else {
            distinctEntities.push(entity);
        }
    });

    combined.entities = [...(combined?.entities || []), ...distinctEntities];
    return combined;
};

type AllPropertiesValuesType = {
    dates: ComparisonDates;
    id?: string;
    hasSalesPermission?: boolean;
    hasSalesInMainApiPermission?: boolean;
    hasShareMetricsPermission?: boolean;
    combineTagsData?: boolean;
};

// Merges visits when there is no data in mainData
const mergeVisitsFromTagsAPIResponse = (
    mainData: PropertiesResponseType,
    tagsData: [PlacerEntityWrapper<Place>[], PlacerEntityWrapper<Place>[]],
) => {
    mainData?.entities.forEach((property) => {
        const { main, compared, info } = property;

        const mainFromTagsAPI = tagsData?.[0].find(({ info: { id } }) => id === info.id)?.overview;
        const comparedFromTagsAPI = tagsData?.[1].find(
            ({ info: { id } }) => id === info.id,
        )?.overview;

        const mainVisitsMetric = get(main, 'metrics.FT.value', undefined);
        const comparedVisitsMetric = get(
            compared,
            'metrics.FT.value',
            comparedFromTagsAPI?.estimated_foottraffic,
        );

        if (!mainVisitsMetric && mainFromTagsAPI?.estimated_foottraffic) {
            set(property, 'main.metrics.FT.value', mainFromTagsAPI.estimated_foottraffic);
        }

        if (
            (!comparedVisitsMetric || !Object.keys(compared.metrics).length) &&
            comparedFromTagsAPI?.estimated_foottraffic
        ) {
            set(property, 'compared.metrics.FT.value', comparedFromTagsAPI.estimated_foottraffic);
        }
    });
};

export const getAllPropertiesValues = async ({
    id = '',
    dates,
    hasSalesPermission = false,
    hasSalesInMainApiPermission = false,
    hasShareMetricsPermission = false,
    combineTagsData = false,
}: AllPropertiesValuesType): Promise<PropertyData[]> => {
    const promises: (
        | Promise<PropertiesResponseType>
        | Promise<[PlacerEntityWrapper<Place>[], PlacerEntityWrapper<Place>[]]>
    )[] = hasSalesPermission
        ? [
              propertiesApi.getAccountProperties(id, dates),
              propertiesApi.getSalesMetricsByTagId(id, dates),
          ]
        : [propertiesApi.getAccountProperties(id, dates)];

    if (combineTagsData) {
        const visitsForChainsParams = {
            id,
            startDate: dates?.start_date || '',
            endDate: dates?.end_date || '',
            compareEndDate: dates?.compared_end_date || '',
            compareStartDate: dates?.compared_start_date || '',
        };
        promises.push(tagsApi.getPlacesForTag(visitsForChainsParams));
    }

    const settledPromises = await Promise.allSettled(promises);

    const hasAllError = settledPromises.every((promise) => {
        if (promise.status === 'rejected') {
            reportException(promise.reason, {
                payload: {
                    id,
                    dates,
                    hasSalesPermission,
                    hasSalesInMainApiPermission,
                    hasShareMetricsPermission,
                },
                message: promise.reason,
            });
        }
        return promise.status === 'rejected';
    });

    if (hasAllError) {
        throw (settledPromises[0] as PromiseRejectedResult).reason;
    }

    const mainData =
        settledPromises[0].status === 'fulfilled'
            ? (settledPromises[0].value as PropertiesResponseType)
            : undefined;
    const salesData =
        hasSalesPermission && settledPromises[1]?.status === 'fulfilled'
            ? (settledPromises[1].value as PropertiesResponseType)
            : undefined;

    const tagsIndex = hasSalesPermission ? 2 : 1;
    const tagPromise = settledPromises[tagsIndex];

    const tagsData =
        tagPromise?.status === 'fulfilled'
            ? (tagPromise.value as [PlacerEntityWrapper<Place>[], PlacerEntityWrapper<Place>[]])
            : undefined;

    // Original API doesn't have visits for chains
    if (mainData && tagsData) {
        mergeVisitsFromTagsAPIResponse(mainData, tagsData);
    }

    const mainFilteredContent = hasSalesInMainApiPermission
        ? mainData
        : getContentWithFilteredMetrics({
              content: mainData,
              filteredList: RANKING_SALES_FILTERED_OUT_METRICS,
          });

    const salesFilteredContent = hasSalesInMainApiPermission
        ? getContentWithFilteredMetrics({
              content: salesData,
              filteredList: RETAIL_SALES_API_FILTERED_OUT_METRICS,
          })
        : salesData;

    return normalizePropertiesResponse(
        getCombinedPropertiesMetricsContent(mainFilteredContent, salesFilteredContent),
        hasShareMetricsPermission,
    );
};
