import { createAsyncThunk } from '@reduxjs/toolkit';

import { ChainsReportState } from 'features/chains/store/chains-store';
import {
    setChainsSearchSearchOpenStatus,
    setSelectedChainsEntityModels,
} from 'features/chains/store/chains-report-search-store';
import { chainsEntitySelectors } from 'features/chains/store/chains-report-chains-store';

export const openChainsSearchSection = createAsyncThunk<
    void,
    void,
    { state: ChainsReportState }
>('chains/open-search-section', async (_, { dispatch, getState }) => {
    const state = getState();
    const chainsInReport = chainsEntitySelectors.selectAll(state);

    dispatch(setSelectedChainsEntityModels(chainsInReport));
    dispatch(setChainsSearchSearchOpenStatus(true));
});
