import {
    BILLBOARD,
    COMPLEX,
    REGION,
    REPORT_ENTITY_TYPE_TO_TYPE,
    ReportEntityTypes,
    ReportTypes,
} from 'core/constants/report-type';

export const getExploreFilterType = (reportType: ReportTypes) => {
    if (reportType === BILLBOARD) {
        return 'billboards';
    }

    if (reportType === COMPLEX) {
        return 'complexes';
    }

    if (reportType === REGION) {
        return 'regions';
    }

    return encodeURIComponent('venues,complexes');
};

export const getExploreDecodedType = (reportType: string) => {
    const decodedReport = decodeURIComponent(reportType) as ReportEntityTypes;
    return REPORT_ENTITY_TYPE_TO_TYPE[decodedReport];
};
