/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';
import { IconProps } from 'components/assets/Icons/Icons';

export const IndustrialIconSvg = (props: CustomIconComponentProps | SVGProps<SVGSVGElement>) => (
    <svg {...props} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.60914 0H0.349272C0.156381 0 0 0.13432 0 0.3V1H3.95842V0.3C3.95842 0.13432 3.80204 0 3.60914 0Z"
            fill="currentColor"
        />
        <path
            d="M11.8244 3.12616C11.7158 3.06238 11.5821 3.06293 11.4741 3.12767L7.94563 5.19805V3.43651C7.94563 3.30832 7.87864 3.18995 7.77004 3.12616C7.66141 3.06238 7.52778 3.06293 7.41968 3.12767L3.96117 5.19805V2H0V11.6422C0 11.8398 0.156489 12 0.349515 12H11.6505C11.8435 12 12 11.8398 12 11.6422V3.43651C12 3.30832 11.933 3.18995 11.8244 3.12616ZM5 9.66667C5 9.85076 4.85076 10 4.66667 10H3.33333C3.14924 10 3 9.85076 3 9.66667V8.33333C3 8.14924 3.14924 8 3.33333 8H4.66667C4.85076 8 5 8.14924 5 8.33333V9.66667ZM9 9.66667C9 9.85076 8.85076 10 8.66667 10H7.33333C7.14924 10 7 9.85076 7 9.66667V8.33333C7 8.14924 7.14924 8 7.33333 8H8.66667C8.85076 8 9 8.14924 9 8.33333V9.66667Z"
            fill="currentColor"
        />
    </svg>
);

export const IndustrialIcon = (props: IconProps) => (
    <Icon component={IndustrialIconSvg} {...props} />
);
