import { EntityData } from 'features/void-analysis/common/types/server-responses';
import { chainsAPI } from 'API/chains-api';

export const modifyLogoUrlForChainData = async (chainData: EntityData) => {
    let modifiedChainData = { ...chainData };
    const { data: chainsResponse } = await chainsAPI.getChainsByIDs([chainData.id]);
    const logoImageUrl = chainsResponse[0]?.logo_img;
    if (logoImageUrl) modifiedChainData.logo_img = logoImageUrl;

    return modifiedChainData;
};
