import React from 'react';
import { SgHealthcareIcon } from 'features/onboarding/solutions-guide/icons/sg-healthcare-icon';
import { CHAINS_ROUTE } from 'features/onboarding/solutions-guide/consts/constants';

export const HEALTHCARE_SOLUTIONS_GUIDE = {
    icon: <SgHealthcareIcon />,
    solutions: [
        {
            title: 'Performance Monitoring',
            description:
                'Quantify, benchmark, explain, and showcase the performance of new and existing locations.',
            url: '/insights/venues/2f708f118458bae6f1e8a41a/overview',
        },
        {
            title: 'Consumer Insights',
            description:
                'Uncover comprehensive demographic and psychographic ' +
                'insights about your visitors at the property or chain level.',
            url: '/insights/venues/2f708f118458bae6f1e8a41a/audience',
        },
        {
            title: 'Site Selection & Expansion',
            description:
                'Easily identify ideal sites for a new locations ' +
                'based on customizable scoring parameters and filters.',
            url: '/advanced-reports/freemium/site-selection',
        },
        {
            title: 'Competitive Intelligence',
            description:
                'Find out how different chains are performing to ' +
                'benchmark your performance and identify growing trends.',
            url: CHAINS_ROUTE,
        },
        {
            title: 'Marketing & Advertising',
            description:
                'Better understand where visitors are coming from to ' +
                'optimize ad geotargeting to reach the highest potential areas.',
            url: '/insights/venues/2f708f118458bae6f1e8a41a/trade-area',
        },
        {
            title: 'Retail Sales Performance',
            description:
                'Discover how sales, transactions, and ticket sizes are ' +
                'trending at countless retail stores, including drugstores & pharmacies.',
            url: '/insights/venues/2f708f118458bae6f1e8a41a/retail-sales',
        },
    ],
};
