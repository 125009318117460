import React, { CSSProperties, PropsWithChildren } from 'react';
import styles from './loading-three-dots-indicator.module.scss';
import classNames from 'classnames';

type LoadingThreeDotIndicatorProps = {
    show: boolean;
    className?: string;
    style?: CSSProperties;
};

export const LoadingThreeDotIndicator = ({
    children,
    show,
    className,
    style,
}: PropsWithChildren<LoadingThreeDotIndicatorProps>) => {
    const indicatorClassName = classNames(
        styles.loadingMoreResultsIndicator,
        className,
    );
    return show ? (
        <div className={indicatorClassName} style={style}>
            <span />
            <span />
            <span />
        </div>
    ) : (
        <>{children}</>
    );
};
