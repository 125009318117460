import { userCookieStorage } from 'core/authentification';
import { DEFAULT_BASE_PATH, UserHasNoTokenError } from 'API/authentification/fetch-wrapper';
import { RESPONSE_STATUSES, RETRY_MS } from 'API/authentification/constants';
import { isNativeError } from 'components/errors/errors-manager/errors-manager-helpers';
import type { SimpleFetchError } from 'API/fetch/types';
import { reportException } from 'core/exceptions';
import { getAppApiEndpoint } from 'core/default-endpoint-url';

export type SimpleFetchProps<BodyType> = {
    targetUrl: string;
    additionalHeaders?: Record<string, string>;
    body?: BodyType;
    endPoint?: string;
    basePath?: string;
    method?: 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE';
    isTokenRequired?: boolean;
    signal?: AbortSignal;
};

export const simpleFetchNoErrorHandling = async <ResponseType = any, BodyType = any>({
    targetUrl,
    method = 'GET',
    additionalHeaders,
    body,
    basePath = DEFAULT_BASE_PATH,
    endPoint = getAppApiEndpoint(),
    isTokenRequired = true,
    signal,
}: SimpleFetchProps<BodyType>): Promise<ResponseType> => {
    const token = userCookieStorage.token;
    const baseUrl = endPoint + basePath;

    const defaultHeaders: { 'Content-Type': string; Authorization?: string } = {
        'Content-Type': 'application/json',
    };

    if (isTokenRequired && !token) {
        throw new UserHasNoTokenError();
    }
    if (isTokenRequired && token) {
        defaultHeaders['Authorization'] = `Token ${token}`;
    }

    const headers: HeadersInit = {
        ...defaultHeaders,
        ...additionalHeaders,
    };

    const URL = baseUrl + targetUrl;

    let status = 0; // uninitialized
    let statusText = ''; // uninitialized
    try {
        const response = await fetch(URL, {
            method,
            headers,
            body: body && method !== 'GET' ? JSON.stringify(body) : undefined,
            signal,
        });
        status = response.status;
        statusText = response.statusText;

        const json = await response.json();

        if (response.status >= 400) {
            throw json;
        }

        return json;
    } catch (error: any) {
        const errorObject: SimpleFetchError = {
            status,
            statusText,
            errorName: '',
            body: error,
        };

        if (isNativeError(error)) {
            errorObject.errorName = (error as Error).name;
            errorObject.body = (error as Error).message;
        } else if (status >= 400) {
            errorObject.errorName = 'bad status';
        }
        const errorDetails = error ? error?.error || JSON.stringify(error) : 'not provided';
        reportException(new Error(`fetch error, errorType ${errorDetails}`), {
            payload: {
                ...headers,
                ...body,
                ...error,
                signal,
            },
            target_url: URL,
            method,
            status_code: status,
        });
        return Promise.reject(errorObject);
    }
};

// there can be several different fields indicating the status, so we'll run over all of them.
const STATUS_FIELD_NAMES = ['status', 'report_status', 'result'];
// there are several possible statuses that mean in-progress.
const IN_PROGRESS_VALUES = [RESPONSE_STATUSES.IN_PROGRESS, RESPONSE_STATUSES.ERR_IN_PROGRESS];

function checkIsInProgress<ResponseType extends Record<any, any>>(response: ResponseType) {
    for (const statusFieldName of STATUS_FIELD_NAMES) {
        const fieldValue = response[statusFieldName];
        for (const inProgressValue of IN_PROGRESS_VALUES) {
            if (fieldValue === inProgressValue) {
                return true;
            }
        }
    }
    return false;
}

export type SimplePollRequestProps<BodyType> = SimpleFetchProps<BodyType> & {
    retryDelay?: number;
};
export const simplePollRequest = async <
    ResponseType extends Record<any, any>,
    BodyType extends Record<any, any>,
>(
    props: SimplePollRequestProps<BodyType>,
): Promise<ResponseType> => {
    if (props.retryDelay === undefined) props.retryDelay = RETRY_MS;

    const response = await simpleFetchNoErrorHandling<ResponseType, BodyType>(props);
    const responseData = response?.data ?? response;

    if (props.signal?.aborted === true) {
        throw new Error('aborted');
    } else if (checkIsInProgress<ResponseType>(responseData)) {
        return new Promise((resolve, reject) => {
            setTimeout(async () => {
                try {
                    resolve(await simplePollRequest(props));
                } catch (err) {
                    reject(err);
                }
            }, props.retryDelay);
        });
    } else {
        return response;
    }
};
