import type { Billboard, Place } from '@placer-ui/types';

export const BILLBOARD_ENTITY = 'billboard' as const;

export class BillboardPOI {
    static isBillboard(poi: Place): poi is Billboard {
        return poi.type === BILLBOARD_ENTITY;
    }

    static getLocationLabel(poi: Place) {
        return poi?.address?.formatted_address || '';
    }
}
