import { useCallback } from 'react';
import { useIsLockedPoi } from 'hooks/use-locked-poi-hooks/use-locked-poi-hooks';
import { useIsRestrictedPoi } from 'hooks/use-restricted-poi/use-restricted-poi';
import { getReportEntityFlag } from 'core/services/report-entities-service/report-entities-service';
import { flagsMap } from 'ui-components/venue-status-indicator/v2/venue-status-indicator-v2';
import { Place } from '@placer-ui/types';

export const useCheckVenueStatus = () => {
    const isLockedPoiCallback = useIsLockedPoi();
    const isRestrictedPoiCallback = useIsRestrictedPoi();

    return useCallback(
        (poi: Place) => {
            const isPoiAddress = poi.provider_data?.entity_type === 'address';
            const isLockedPoi = isLockedPoiCallback(poi);
            const isRestricted = isRestrictedPoiCallback(poi);
            const flag = getReportEntityFlag(poi);

            if (isPoiAddress && isLockedPoi) return 'Subject to Placer’s privacy policy.';
            else if (isLockedPoi) return 'Locked. Premium account is required';
            else if (isRestricted)
                return 'Venue is part of a restricted area. Data is not available.';
            else if (flag) return flagsMap[flag];
            return '';
        },
        [isLockedPoiCallback, isRestrictedPoiCallback],
    );
};
