/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

type HolidaysLocalTimezoneIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type HolidaysLocalTimezoneIconProps = SVGProps<SVGSVGElement> &
    HolidaysLocalTimezoneIconUniqueProps;

export const HolidaysLocalTimezoneIcon = function HolidaysLocalTimezoneIcon({
    width = 32,
    height = 32,
    ...props
}: HolidaysLocalTimezoneIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_146_60)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.5 17.2071V31.9924C25.1052 31.7282 32 24.6693 32 16C32 7.33075 25.1052 0.27184 16.5 0.00769043V14.7929L22.6464 8.64641C22.8417 8.45115 23.1583 8.45115 23.3535 8.64641C23.5488 8.84167 23.5488 9.15825 23.3535 9.35352L16.7071 16L22.8535 22.1465C23.0488 22.3417 23.0488 22.6583 22.8535 22.8536C22.6583 23.0488 22.3417 23.0488 22.1464 22.8536L16.5 17.2071ZM19.7531 1.99409C20.0198 2.06556 20.1781 2.33973 20.1067 2.60646L19.8478 3.57239C19.7764 3.83912 19.5022 3.99741 19.2355 3.92594C18.9687 3.85447 18.8104 3.5803 18.8819 3.31357L19.1407 2.34764C19.2122 2.08091 19.4864 1.92262 19.7531 1.99409ZM28.5002 16C28.5002 15.7239 28.7241 15.5 29.0002 15.5H30.0002C30.2764 15.5 30.5002 15.7239 30.5002 16C30.5002 16.2762 30.2764 16.5 30.0002 16.5H29.0002C28.7241 16.5 28.5002 16.2762 28.5002 16ZM19.1408 29.6524C19.2123 29.9191 19.4865 30.0774 19.7532 30.0059C20.0199 29.9345 20.1782 29.6603 20.1068 29.3936L19.8479 28.4276C19.7765 28.1609 19.5023 28.0026 19.2356 28.0741C18.9688 28.1455 18.8105 28.4197 18.882 28.6865L19.1408 29.6524ZM25.5462 24.8389C25.3509 24.6436 25.0343 24.6436 24.8391 24.8389C24.6438 25.0341 24.6438 25.3507 24.8391 25.546L25.5462 26.2531C25.7414 26.4483 26.058 26.4483 26.2533 26.2531C26.4485 26.0578 26.4485 25.7412 26.2533 25.546L25.5462 24.8389ZM26.2531 5.74706C26.4484 5.94232 26.4484 6.2589 26.2531 6.45416L25.546 7.16127C25.3507 7.35653 25.0342 7.35653 24.8389 7.16127C24.6436 6.96601 24.6436 6.64943 24.8389 6.45416L25.546 5.74706C25.7413 5.5518 26.0579 5.5518 26.2531 5.74706Z"
                    fill="#5E63E5"
                />
                <path
                    d="M11.938 1.03765C6.22289 2.58547 1.81813 7.32035 0.749147 13.2169C0.800458 13.1629 0.853104 13.1094 0.907039 13.0565C1.77726 12.202 3.01073 11.4554 4.49451 10.8392C5.28554 10.5106 6.15449 10.2163 7.08816 9.96128C7.55809 7.88353 8.26449 6.00886 9.15366 4.43973C9.93766 3.0562 10.8778 1.88931 11.938 1.03765Z"
                    fill="#D7A98F"
                />
                <path
                    d="M11.938 30.9622C6.22288 29.4144 1.81812 24.6794 0.749146 18.7828C0.800457 18.8368 0.853104 18.8903 0.907039 18.9433C1.77726 19.7978 3.01073 20.5443 4.49451 21.1606C5.28553 21.4891 6.15447 21.7835 7.08813 22.0385C7.55806 24.1163 8.26447 25.991 9.15366 27.5602C9.93766 28.9437 10.8778 30.1106 11.938 30.9622Z"
                    fill="#D7A98F"
                />
                <path
                    d="M15.5 31.4733V23.1421C12.8606 23.1088 10.3719 22.8133 8.18092 22.3122C8.6273 24.1039 9.25682 25.7139 10.0237 27.0671C11.5038 29.6791 13.4403 31.2532 15.5 31.4733Z"
                    fill="#D7A98F"
                />
                <path
                    d="M15.5 9.85778C12.7319 9.89352 10.1526 10.2249 7.93753 10.7735C7.61152 12.4042 7.43311 14.1631 7.43311 15.9999C7.43311 17.8367 7.61151 19.5956 7.93751 21.2263C10.1526 21.7749 12.7319 22.1062 15.5 22.142V9.85778Z"
                    fill="#D7A98F"
                />
                <path
                    d="M15.5 8.8577C12.8606 8.89093 10.3719 9.18644 8.18095 9.68761C8.62733 7.8959 9.25684 6.286 10.0237 4.93274C11.5038 2.32079 13.4403 0.746706 15.5 0.526611V8.8577Z"
                    fill="#D7A98F"
                />
                <path
                    d="M4.87807 11.7627C5.49021 11.5084 6.15464 11.2747 6.86448 11.0645C6.58378 12.6248 6.43311 14.2831 6.43311 15.9999C6.43311 17.7167 6.58378 19.375 6.86446 20.9352C6.15462 20.7251 5.4902 20.4913 4.87807 20.2371C3.46642 19.6508 2.35675 18.9653 1.60768 18.2298C0.860322 17.4959 0.5 16.7426 0.5 15.9999C0.5 15.2571 0.860322 14.5039 1.60768 13.77C2.35675 13.0344 3.46642 12.349 4.87807 11.7627Z"
                    fill="#D7A98F"
                />
            </g>
            <defs>
                <clipPath id="clip0_146_60">
                    <rect width="32" height="32" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
HolidaysLocalTimezoneIcon.__tags = ['holiday', 'time', 'earth'] as string[];
