/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

type PinPromotionIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type PinPromotionIconProps = SVGProps<SVGSVGElement> & PinPromotionIconUniqueProps;

export const PinPromotionIcon = function PinPromotionIcon({
    width = 20,
    height = 24,
    ...props
}: PinPromotionIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 20 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <mask id="path-1-inside-1_25924_324466" fill="white">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.0199 0C15.5314 0 20 4.45647 20 9.95399C20 12.5203 19.1279 14.6136 17.4176 16.6179C15.7082 18.6222 10 24 10 24C10 24 4.29265 18.6222 2.58235 16.6179C0.871186 14.6136 0 12.5203 0 9.95399C0 4.45647 4.46862 0 9.9792 0H10.0199Z"
                />
            </mask>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.0199 0C15.5314 0 20 4.45647 20 9.95399C20 12.5203 19.1279 14.6136 17.4176 16.6179C15.7082 18.6222 10 24 10 24C10 24 4.29265 18.6222 2.58235 16.6179C0.871186 14.6136 0 12.5203 0 9.95399C0 4.45647 4.46862 0 9.9792 0H10.0199Z"
                fill="#D0D0D0"
                stroke="#333333"
                strokeOpacity="0.25"
                strokeWidth="2"
                mask="url(#path-1-inside-1_25924_324466)"
            />
            <path
                d="M6.68984 10.0809C6.67299 10.1069 6.64479 10.124 6.6138 10.124H4.23485C4.14671 10.124 4.10167 10.0182 4.16276 9.95465L6.97451 7.02984C6.99288 7.01074 7.0178 7 7.04378 7H8.50103C8.5803 7 8.62806 7.08782 8.58497 7.15436L6.68984 10.0809Z"
                fill="white"
            />
            <path
                d="M12.5363 9.97176C12.5771 10.0384 12.5291 10.124 12.451 10.124H7.55306C7.47474 10.124 7.42682 10.038 7.468 9.97137L9.27728 7.04457C9.29553 7.01669 9.32585 7 9.35827 7H10.6642C10.6968 7 10.7273 7.01686 10.7455 7.04497L12.5363 9.97176Z"
                fill="white"
            />
            <path
                d="M7.4234 10.7353C7.35042 10.7353 7.30202 10.811 7.3326 10.8772L9.90097 16.4414C9.93648 16.5184 10.0417 16.5185 10.0775 16.4417L12.6651 10.8775C12.6959 10.8112 12.6475 10.7353 12.5744 10.7353H7.4234Z"
                fill="white"
            />
            <path
                d="M9.21083 16.3525C9.25947 16.4577 9.12002 16.5475 9.04431 16.4597L4.25215 10.9006C4.19628 10.8358 4.24232 10.7353 4.32789 10.7353H6.55867C6.59416 10.7353 6.62544 10.7577 6.64031 10.7899L9.21083 16.3525Z"
                fill="white"
            />
            <path
                d="M15.8372 9.95465C15.8983 10.0182 15.8533 10.124 15.7652 10.124H13.3862C13.3552 10.124 13.327 10.1069 13.3102 10.0809L11.415 7.15436C11.3719 7.08782 11.4197 7 11.499 7H12.9562C12.9822 7 13.0071 7.01074 13.0255 7.02984L15.8372 9.95465Z"
                fill="white"
            />
            <path
                d="M13.3587 10.79C13.3737 10.7577 13.4051 10.7353 13.4407 10.7353H15.6702C15.7561 10.7353 15.802 10.8364 15.7456 10.9011L10.8936 16.4573C10.8172 16.5447 10.6782 16.4536 10.7279 16.3487L13.3587 10.79Z"
                fill="white"
            />
        </svg>
    );
};
PinPromotionIcon.__tags = ['pin', 'diamond', 'upsell', 'upgrade', 'promotion'] as string[];
