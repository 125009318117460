import type { ShortVisitsConfigResp, ShortVisitsConfigType } from '@placer-ui/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: ShortVisitsConfigType = {};

const shortVisitsConfigSlice = createSlice({
    name: 'short_visits_config',
    initialState,
    reducers: {
        setShortVisitsImpactedCategories: (
            state: ShortVisitsConfigType,
            action: PayloadAction<ShortVisitsConfigResp>,
        ) => {
            state.impactedCategories = action.payload.impacted_categories;
        },
    },
});

export const shortVisitsConfigReducer = shortVisitsConfigSlice.reducer;
export const shortVisitsConfigSliceActions = shortVisitsConfigSlice.actions;
