import { GeoJSON } from 'geojson';
import { fetchWrapper } from 'API/authentification/fetch-wrapper';
import type {
    ConfigurationReportsOptions,
    Dictionary,
    PlaceType,
    PlacerArrayResponse,
} from '@placer-ui/types';

type EntityIds = {
    id: string;
    type: PlaceType;
};

type FtRadiusOptions = {
    value: number;
    area_sqft: number;
    geojson: GeoJSON;
};
export type DataSourceReportAvailability = Dictionary<boolean>;

export type EntitiesDataOptionsType = {
    entity_id: string;
    entity_type: PlaceType;
    ft_radius: FtRadiusOptions[];
    data_sources_availability?: DataSourceReportAvailability;
    is_visit_duration_limited?: boolean;
    is_posie?: boolean;
};

export type ReportOptionsResponse = {
    time_range: {
        from_date: string;
        to_date: string;
    };

    entities_data: EntitiesDataOptionsType[];
};

export type EntityDates = {
    entity_id: string;
    entity_type: PlaceType;
    start_date: string;
    end_date: string;
};

export const getReportOptions = async (pois: EntityIds[], options?: { signal?: AbortSignal }) => {
    const { data } = await fetchWrapper<PlacerArrayResponse<ReportOptionsResponse>>({
        targetUrl: '/entities/report-options',
        method: 'POST',
        body: {
            entity_ids: pois.map(({ id, type }) => ({
                entity_id: id,
                entity_type: type,
            })),
        },
        signal: options?.signal,
    });
    return data;
};

export const getReportDateOptions = async (
    pois: EntityDates[],
    options?: { signal?: AbortSignal },
) => {
    let storeData;
    try {
        storeData = (window as any)?.opener?.dataForUI || (window as any)?.dataForUI;
    } catch (e) {
        console.debug('No valid window opener in getReportDateOptions.');
    }
    const reportOptions: ConfigurationReportsOptions | undefined =
        storeData?.configuration?.reportDateOptions;

    let isSameDates = true;
    let isSamePois = true;

    pois.forEach((poi, i) => {
        if (reportOptions && reportOptions.entities_data?.length !== pois.length) return;
        if (
            reportOptions?.entities_data?.[i].start_date !== poi.start_date ||
            reportOptions?.entities_data?.[i].end_date !== poi.end_date
        ) {
            isSameDates = false;
            return;
        }
        if (reportOptions?.entities_data?.[i].entity_id !== poi.entity_id) {
            isSamePois = false;
            return;
        }
    });
    if (reportOptions && isSameDates && isSamePois) return reportOptions;

    const { data } = await fetchWrapper<PlacerArrayResponse<ConfigurationReportsOptions>>({
        targetUrl: '/entities/report-date-options',
        method: 'POST',
        body: {
            entities: pois.map(({ entity_id, entity_type, start_date, end_date }) => ({
                entity_id,
                entity_type,
                start_date,
                end_date,
            })),
        },
        signal: options?.signal,
    });
    return data;
};
