import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/app-store-hooks';
import { userCustomSettingsExploreSelector } from 'store/selectors/app-selectors';
import { useExploreConfiguration } from 'features/explore/store/selectors';
import { useCallback, useMemo } from 'react';
import {
    parseUserSettingsCoordinates,
    stringifyMapConfig,
} from 'features/explore/utils/map-persistency';
import { setCustomUserSettingsExplore } from 'store/explore/actions/set-custom-user-settings-explore';
import { useExploreUrlParams } from 'features/explore/hooks/use-explore-url-params';
import {
    fortWorthBoundingBox,
    fortWorthCenterPoint,
} from 'ui-components/google-map/google-map-default-options';
import { MapConfiguration } from 'ui-components/google-map/types/types';
import { useMap } from 'react-map-gl';

export const defaultMapConfiguration = {
    center: fortWorthCenterPoint,
    zoom: 13,
    ne: [fortWorthBoundingBox[3], fortWorthBoundingBox[2]],
    sw: [fortWorthBoundingBox[1], fortWorthBoundingBox[0]],
};

export const useMapOptions = () => {
    const userCustomSettings = useSelector(userCustomSettingsExploreSelector);
    const urlParams = useExploreUrlParams();

    return useMemo(() => {
        return {
            zoom: urlParams?.zoom || userCustomSettings?.zoom || defaultMapConfiguration.zoom,
            center:
                urlParams?.center || userCustomSettings?.center || defaultMapConfiguration.center,
        };
    }, [urlParams, userCustomSettings]);
};

export const useMapConfiguration = (): MapConfiguration => {
    const sessionParams = useExploreConfiguration();
    const userCustomSettings = useSelector(userCustomSettingsExploreSelector);
    const urlParams = useExploreUrlParams();

    return useMemo(
        () => ({
            zoom:
                sessionParams?.zoom ||
                urlParams?.zoom ||
                userCustomSettings?.zoom ||
                defaultMapConfiguration.zoom,
            center:
                sessionParams?.center ||
                urlParams?.center ||
                userCustomSettings?.center ||
                defaultMapConfiguration.center,
            ne:
                sessionParams?.ne ||
                parseUserSettingsCoordinates(urlParams?.ne) ||
                parseUserSettingsCoordinates(userCustomSettings?.ne) ||
                defaultMapConfiguration.ne,
            sw:
                sessionParams?.sw ||
                parseUserSettingsCoordinates(urlParams?.sw) ||
                parseUserSettingsCoordinates(userCustomSettings?.sw) ||
                defaultMapConfiguration.sw,
        }),
        [sessionParams, urlParams, userCustomSettings],
    );
};

export const useUpdateUserSettingsWithMapConfig = () => {
    const dispatch = useAppDispatch();
    const currentExploreSettings = useSelector(userCustomSettingsExploreSelector);

    return useCallback(
        (newMapConfig: MapConfiguration) => {
            dispatch(
                setCustomUserSettingsExplore({
                    ...currentExploreSettings,
                    ...stringifyMapConfig(newMapConfig),
                }),
            );
        },
        [currentExploreSettings, dispatch],
    );
};

export const useMapZoomFallback = () => {
    const { exploreMapbox } = useMap();
    let mapZoom;

    mapZoom = exploreMapbox?.getZoom();

    if (!mapZoom) {
        return defaultMapConfiguration.zoom;
    }

    if (mapZoom <= 8) {
        return 5;
    } else if (mapZoom <= 10) {
        return 8;
    }

    return 10;
};
