import { PickInfo } from 'deck.gl';
import { ReactElement, useCallback, useEffect, useRef } from 'react';
import {
    GoogleMapsOverlay as TGoogleMapsOverlay,
    GoogleMapsOverlayProps,
} from '@deck.gl/google-maps/typed';
import { GoogleMapsOverlay } from '@deck.gl/google-maps';
import { renderToString } from 'react-dom/server';
import { useGoogleMap } from '@react-google-maps/api';
import { DeckGLProps } from '@deck.gl/react/deckgl';
import { refreshZoom } from 'ui-components/google-map/utils';
import { useWidgetModel } from 'extensions/widget/hooks/use-widget-hooks';
import { useWidgetPrintMode } from 'extensions/widget/hooks/use-widget-context-hooks';
import { usePrintContext } from 'features/print-menu/hooks/use-print-context';
import { useFeatureFlags } from 'hooks/feature-flags/use-feature-flags';
import { PrintInfraEvents } from 'extensions/widget/hooks/use-ready-for-print-event';
import { dispatchEventForPrint } from 'utils/dispatch-event/dispatch-event-print-service';

type DeckGlOverlayProps = {
    tooltip?: (object: PickInfo<any>) => ReactElement;
    onMountOnlyLayer?: boolean;
    shouldPreserveDrawing?: boolean;
};

export type DeckGlLayerProps = DeckGLProps & DeckGlOverlayProps;
export type TDeckGlLayerProps = GoogleMapsOverlayProps & DeckGlOverlayProps;

export const DeckGlOverlay = ({
    layers,
    tooltip,
    pickingRadius = 20,
    onMountOnlyLayer = true,
    shouldPreserveDrawing,
    ...rest
}: DeckGlLayerProps) => {
    const map = useGoogleMap();
    const isPrintMode = useWidgetPrintMode();
    const { serverSideRender } = usePrintContext();
    const { enable_ready_for_print_event_ff } = useFeatureFlags();
    const { print } = useWidgetModel();
    const shouldDispatchEvent =
        print?.waitForEvent &&
        print?.widgetHasMap &&
        enable_ready_for_print_event_ff &&
        isPrintMode;

    const getTooltip = useCallback(
        (pickInfo: PickInfo<any>) => {
            if (pickInfo.object && tooltip) {
                return {
                    html: renderToString(tooltip(pickInfo)),
                    style: {
                        padding: '0',
                        color: 'unset',
                        position: 'relative',
                        backgroundColor: 'transparent',
                    },
                };
            }
            return null;
        },
        [tooltip],
    );

    const deckLayers = useRef(
        new GoogleMapsOverlay({
            layers: layers,
            pickingRadius,
            ...rest,
            glOptions: {
                preserveDrawingBuffer: shouldPreserveDrawing || (isPrintMode && !serverSideRender),
                powerPreference: 'default',
                depth: true,
            },
            getTooltip,
        }),
    );

    const mountLayer = (map: google.maps.Map, deckLayer: GoogleMapsOverlay) => {
        deckLayer.setMap(map);
        refreshZoom(map);
    };

    useEffect(() => {
        let deckLayer = deckLayers.current;
        if (map) {
            if (onMountOnlyLayer) {
                if (isPrintMode) {
                    mountLayer(map, deckLayer);
                } else {
                    google.maps.event.addListenerOnce(map, 'tilesloaded', () => {
                        mountLayer(map, deckLayer);
                    });
                }
            } else {
                mountLayer(map, deckLayer);
            }
            if (shouldDispatchEvent) {
                google.maps.event.addListenerOnce(map, 'tilesloaded', () => {
                    const event = new CustomEvent<string>(PrintInfraEvents.mapFullyLoaded);
                    dispatchEventForPrint(event);
                });
            }
        }
        return () => {
            deckLayer.finalize();
        };
    }, [isPrintMode, map, onMountOnlyLayer, shouldDispatchEvent]);

    useEffect(() => {
        deckLayers.current.setProps({ layers });
    }, [layers]);

    useEffect(() => {
        deckLayers.current.setProps({
            getTooltip,
        });
    }, [getTooltip]);

    return null;
};

// this should be the default once we upgrade to deck.gl v9
// https://deck.gl/docs/get-started/using-with-typescript
export const DeckGlOverlayTyped = ({
    layers,
    tooltip,
    pickingRadius = 20,
    onMountOnlyLayer = true,
    shouldPreserveDrawing,
    ...rest
}: TDeckGlLayerProps) => {
    const map = useGoogleMap();
    const isPrintMode = useWidgetPrintMode();
    const { serverSideRender } = usePrintContext();
    const { enable_ready_for_print_event_ff } = useFeatureFlags();
    const { print } = useWidgetModel();
    const shouldDispatchEvent =
        print?.waitForEvent &&
        print?.widgetHasMap &&
        enable_ready_for_print_event_ff &&
        isPrintMode;

    const getTooltipFunc = useCallback(
        (pickInfo) => {
            if (!pickInfo.object || !tooltip) {
                return null;
            }
            return {
                html: renderToString(tooltip(pickInfo.object)),
                style: {
                    padding: '0',
                    color: 'unset',
                    position: 'relative',
                    backgroundColor: 'transparent',
                },
            };
        },
        [tooltip],
    );

    const deckLayers = useRef(
        new TGoogleMapsOverlay({
            layers: layers,
            pickingRadius,
            ...rest,
            glOptions: {
                preserveDrawingBuffer: shouldPreserveDrawing || (isPrintMode && !serverSideRender),
                powerPreference: 'default',
                depth: true,
            },
            getTooltip: getTooltipFunc,
        }),
    );
    const mountLayer = (map: google.maps.Map, deckLayer: TGoogleMapsOverlay) => {
        deckLayer.setMap(map);
        refreshZoom(map);
    };
    useEffect(() => {
        let deckLayer = deckLayers.current;
        if (map) {
            if (onMountOnlyLayer) {
                if (isPrintMode) {
                    mountLayer(map, deckLayer);
                } else {
                    google.maps.event.addListenerOnce(map, 'tilesloaded', () => {
                        mountLayer(map, deckLayer);
                    });
                }
            } else {
                mountLayer(map, deckLayer);
            }
            if (shouldDispatchEvent) {
                google.maps.event.addListenerOnce(map, 'tilesloaded', () => {
                    const event = new CustomEvent<string>(PrintInfraEvents.mapFullyLoaded);
                    dispatchEventForPrint(event);
                });
            }
        }
        return () => {
            deckLayer.finalize();
        };
    }, [isPrintMode, map, onMountOnlyLayer, shouldDispatchEvent]);

    useEffect(() => {
        deckLayers.current.setProps({ layers });
    }, [layers]);

    useEffect(() => {
        if (tooltip) {
            deckLayers.current.setProps({
                getTooltip: getTooltipFunc,
            });
        }
    }, [getTooltipFunc, tooltip]);

    return null;
};
