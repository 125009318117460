import { startCase, toLower } from 'lodash/fp';
import { ConfigurationAttributesFiltersOptions } from 'store/selectors/app-selectors';
import { DEFAULT_AUDIENCE, VISITORS } from 'shared/constants/audience-type';
import { attributes } from 'core/constants/filter-attributes';
import { generateTwentyFourHoursOptions } from 'utils/date/date';
import { sumHouseHoldRange } from 'features/insights/utils';
import type { FilterOperator, Gender } from '@placer-ui/types';

export type MappedAttributes = {
    filterName?: string;
    values: string[];
};

interface VisitsFrequencyFilterNameProps {
    visitsFrequency: number;
    inequalitySign: FilterOperator;
    isShortForm: boolean;
}

const MILE = 1609.34;
const WEEK = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
const SHORT_WEEK_DAYS = ['Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat', 'Sun'];

export function getAudienceTypeFilterName(filterNameData: string[]): MappedAttributes {
    const defaultAudienceIndex = filterNameData.findIndex((item) => item === DEFAULT_AUDIENCE);
    const visitorsIndex = filterNameData.findIndex((item) => item === VISITORS);

    return filterNameData.reduce<MappedAttributes>(
        (filtersNames, item, i) => {
            const name = defaultAudienceIndex > -1 ? item : startCase(toLower(item));
            if (i === defaultAudienceIndex) {
                filtersNames.values = [name];
            } else if (i === visitorsIndex) {
                filtersNames.values = [name, ...filtersNames.values];
            } else {
                filtersNames.values = [...filtersNames.values, name];
            }

            return filtersNames;
        },
        {
            filterName: attributes.filterLabels.AUDIENCE_TYPE,
            values: [],
        },
    );
}

export function getGenderFilterName(gender: Gender): MappedAttributes {
    return {
        filterName: attributes.filterLabels.GENDER,
        values: [
            gender === 'female' ? attributes.genderLabels.FEMALE : attributes.genderLabels.MALE,
        ],
    };
}

export function getAgeFilterName(
    ageList: number[],
    filterAttributesConfiguration: ConfigurationAttributesFiltersOptions,
): MappedAttributes {
    return {
        filterName: attributes.filterLabels.AGE,
        values: ageList.map((age) => filterAttributesConfiguration.age[age]),
    };
}

export function getDataSourceFilterName(
    dataSource: string,
    filterAttributesConfiguration: ConfigurationAttributesFiltersOptions,
): MappedAttributes {
    return {
        filterName: attributes.filterLabels.DATA_SOURCE,
        values: [filterAttributesConfiguration.dataSource[dataSource]],
    };
}

export function getDistanceFilterName({
    placeType,
    dataSource,
    inequalitySign,
    isShortForm,
}: {
    placeType: 'home' | 'work';
    dataSource: number;
    inequalitySign: FilterOperator;
    isShortForm: boolean;
}): MappedAttributes {
    const operatorToWord =
        inequalitySign === '<'
            ? attributes.filterLabels.LESS_THEN
            : attributes.filterLabels.MORE_THEN;
    const inequalityWord = isShortForm ? inequalitySign : operatorToWord + ' / ';
    return {
        filterName:
            placeType === 'home'
                ? attributes.filterLabels.HOME_DISTANCE
                : attributes.filterLabels.WORK_DISTANCE,
        values: [`${inequalityWord}${(dataSource / MILE).toFixed(2)} mi`],
    };
}

export function getWeekDayFilterName(weekDays: number[], isShortForm: boolean): MappedAttributes {
    return {
        filterName: isShortForm
            ? attributes.shortFilterLabels.DAYS_OF_WEEK
            : attributes.filterLabels.DAYS_OF_WEEK,
        values: weekDays.map((weekDay) => (isShortForm ? SHORT_WEEK_DAYS[weekDay] : WEEK[weekDay])),
    };
}

export function getLengthOfStayFilterName(
    time: number,
    inequalitySign: FilterOperator,
): MappedAttributes {
    const inequalityWord =
        inequalitySign === '<'
            ? attributes.filterLabels.LESS_THEN
            : attributes.filterLabels.MORE_THEN;

    if (time === 0) {
        return {
            filterName: attributes.filterLabels.LENGTH_OF_STAY,
            values: ['All Visits'],
        };
    }

    return {
        filterName: attributes.filterLabels.LENGTH_OF_STAY,
        values: [`${inequalityWord} / ${time} min`],
    };
}

export function getHouseHoldIncomeFilterName(
    houseHoldIncome: number[],
    filterAttributesConfiguration: ConfigurationAttributesFiltersOptions,
    isShortForm: boolean,
): MappedAttributes {
    return {
        filterName: isShortForm
            ? attributes.shortFilterLabels.HOUSEHOLD_INCOME
            : attributes.filterLabels.HOUSEHOLD_INCOME,
        values: isShortForm
            ? sumHouseHoldRange(houseHoldIncome)
            : houseHoldIncome.map((item) => filterAttributesConfiguration.householdIncome[item]),
    };
}

const replaceTimeToShortForm = (val: string): string => {
    let optionLabel = val.replace(/\s/g, '');
    if (!optionLabel.includes(':59')) {
        if (optionLabel.startsWith('0')) optionLabel = optionLabel.substr(1);
        optionLabel = optionLabel.replace(':00', '');
    }
    return optionLabel;
};

export function getTimeOfDayFilterName(
    timeRange: string[],
    isShortForm: boolean,
): MappedAttributes {
    const timeOptions = generateTwentyFourHoursOptions();
    const start = timeRange[0];
    const end = timeRange[1];

    const { startLabel, endLabel } = timeOptions.reduce(
        (result, option) => {
            const timeLabel = isShortForm ? replaceTimeToShortForm(option.label) : option.label;
            if (option.value === start) {
                result.startLabel = timeLabel;
            }
            if (option.value === end) {
                result.endLabel = timeLabel;
            }

            return result;
        },
        {
            startLabel: '',
            endLabel: '',
        },
    );

    return {
        filterName: attributes.filterLabels.TIME_OF_DAY,
        values: [`${startLabel} - ${endLabel}`],
    };
}

export function getVisitsFrequencyFilterName({
    visitsFrequency,
    inequalitySign,
    isShortForm,
}: VisitsFrequencyFilterNameProps): MappedAttributes {
    const inequalityWord =
        inequalitySign === '>=' ? attributes.filterLabels.MIN : attributes.filterLabels.MAX;

    return {
        filterName: isShortForm
            ? `${inequalityWord}. ${attributes.filterLabels.NUMBER_OF_VISITS}`
            : attributes.filterLabels.NUMBER_OF_VISITS,
        values: [isShortForm ? `${visitsFrequency}` : `${inequalityWord}: ${visitsFrequency}`],
    };
}
