import { chainsAPI } from 'API/chains-api';
import { competitorAPI } from 'API/competitor-api/competitor-api';
import { searchAPI } from 'API/search';
import { starReportAPI } from 'API/star-report-api';
import { userAPI } from 'API/user-api';
import { configurationAPI } from 'API/configuration';
import { regionsAPI } from 'API/regions-api';
import { offlineReportsAPI } from 'API/offline-reports-api';
import { shareReportApi } from 'API/share-report-api';

export const API = {
    chains: chainsAPI,
    user: userAPI,
    configuration: configurationAPI,
    search: searchAPI,
    regions: regionsAPI,
    competitor: competitorAPI,
    starReport: starReportAPI,
    offlineReports: offlineReportsAPI,
    shareReport: shareReportApi,
};

export type APIType = typeof API;
