import React from 'react';
import {
    BrowserSupportPage,
    DesktopAcademyPage,
    DesktopAdvancedMapsPage,
    DesktopAdvancedReportsPage,
    DesktopChainsPage,
    DesktopCustomPOIPage,
    DesktopExplorePage,
    DesktopExportPage,
    DesktopExportWidgetPage,
    DesktopGISPage,
    DesktopIndustryPage,
    DesktopInsightsPage,
    DesktopMarketsSectionPage,
    DesktopMyZonePage,
    DesktopSettingsPage,
    DesktopSynapsePage,
    DesktopTrendsPage,
    DesktopVoidAnalysisPage,
} from 'router/lazy-pages';
import { DashboardRoute } from 'router/types';
import { myZoneStoreInitializer } from 'features/my-zone/store/slice';
import { voidAnalysisStoreInitializer } from 'features/void-analysis/store/slice';
import { advancedReportsStoreInitializer } from 'features/advanced-reports/store/advanced-reports-store';
import { ADVANCED_MAPS_PATH } from 'shared/constants/advanced-maps';
import { Redirect } from 'react-router-dom';

export const DESKTOP_ROUTES: DashboardRoute[] = [
    {
        path: '/explore/',
        title: 'Explore',
        Component: DesktopExplorePage,
    },
    {
        path: '/markets/',
        title: 'Markets',
        Component: DesktopMarketsSectionPage,
    },
    {
        path: '/explore2',
        title: 'Explore',
        render(props) {
            return <Redirect to={`/explore${props.location.search}`} />;
        },
    },
    {
        path: '/settings',
        title: 'Settings',
        Component: DesktopSettingsPage,
    },
    {
        path: ['/my-zone/', '/myzone/'],
        title: 'My Zone',
        initializer: myZoneStoreInitializer,
        Component: DesktopMyZonePage,
    },
    {
        path: '/insights/',
        title: 'Insights',
        Component: DesktopInsightsPage,
    },
    {
        path: '/chains/',
        title: 'Chains',
        Component: DesktopChainsPage,
    },
    {
        path: '/industry/',
        title: 'Industry',
        Component: DesktopIndustryPage,
        permission: 'show_industry_section',
    },
    {
        path: '/advanced-reports/',
        title: 'Advanced Reports',
        Component: DesktopAdvancedReportsPage,
        initializer: advancedReportsStoreInitializer,
    },
    {
        path: ADVANCED_MAPS_PATH,
        title: 'Map Studio',
        Component: DesktopAdvancedMapsPage,
    },
    {
        path: '/panel',
        title: 'Panel Visits',
        Component: () => <div>Panel Visits Placeholder</div>,
        permission: 'show_panel_tool',
    },
    {
        path: '/gis',
        title: 'GIS',
        Component: DesktopGISPage,
        permission: 'enable_gis',
    },
    {
        path: '/labs/',
        title: 'Trends',
        Component: DesktopTrendsPage,
    },
    {
        path: '/academy/',
        title: 'Academy',
        Component: DesktopAcademyPage,
    },
    {
        path: '/void-analysis/',
        title: 'Void Analysis',
        Component: DesktopVoidAnalysisPage,
        initializer: voidAnalysisStoreInitializer,
    },
    {
        path: '/custom-poi',
        title: 'Create POI',
        Component: DesktopCustomPOIPage,
    },
    {
        path: '/synapse',
        title: 'Synapse',
        Component: DesktopSynapsePage,
        featureFlag: 'enable_chat_agent_access',
    },
    {
        path: '/export/page',
        title: 'Export',
        Component: DesktopExportPage,
        isFullPage: true,
    },
    {
        path: '/export/widget',
        Component: DesktopExportWidgetPage,
        isFullPage: true,
    },

    {
        path: '/browser-support',
        Component: BrowserSupportPage,
        isFullPage: true,
    },
];
