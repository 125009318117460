import React, { ComponentProps, useMemo, useState } from 'react';
import { GoogleMap, GoogleMapComponentProps } from 'ui-components/google-map/google-map';
import {
    MapTypeController,
    VectorMapTypeOption,
} from 'ui-components/google-vector-map/controllers/map-type-controller/map-type-controller';
import {
    default3dOptions,
    defaultTiltingOptions,
} from 'ui-components/google-map/google-map-default-options';
import { placerHybridMapStyle, placerMapStyles } from 'ui-components/google-map/placer-map-styles';
import { MAP_SPLUNK_EVENTS } from 'ui-components/google-map/consts';
import { MapRenderType, MapTypeId } from 'ui-components/google-map/types/types';
import classnames from 'classnames';
import styles from './google-vector-map.module.scss';
import { useSplunkCallback } from 'hooks/use-splunk-callback/use-splunk-callback';
import { MapEventData } from 'types/splunk/events';

type VectorMapProps = Omit<ComponentProps<typeof GoogleMap>, 'options'> & {
    options: Omit<google.maps.MapOptions, 'styles'> & {
        mapId: string;
    };
};

export type GoogleVectorMapProps = Omit<ComponentProps<typeof GoogleMap>, 'type' | 'options'> & {
    /**
     * Required for Vector maps, should be a map id created via the cloud console.
     */
    mapId?: string;
    /**
     * When a mapId is present, map styles are controlled via the cloud console.
     */
    options?: Omit<google.maps.MapOptions, 'styles'>;
    includeCustomMapIdController?: boolean;
    fallbackToRasterMap?: boolean;
    mapTypeClassName?: string;
    defaultMapType?: VectorMapTypeOption;
};

const { REACT_APP_GOOGLE_VECTOR_MAP_ID } = process.env;

const defaultRasterMapStyles: { [key in MapTypeId]?: google.maps.MapTypeStyle[] } = {
    roadmap: placerMapStyles,
    hybrid: placerHybridMapStyle,
};

export const GoogleVectorMap = ({
    mapId = REACT_APP_GOOGLE_VECTOR_MAP_ID,
    defaultMapType,
    options,
    includeCustomMapIdController = true,
    fallbackToRasterMap = false,
    children,
    mapTypeClassName,
    ...props
}: GoogleVectorMapProps) => {
    const [map, setMap] = useState<google.maps.Map>();
    const [is3D, set3D] = useState<boolean>(false);
    const [currentMapType, setMapType] = useState<VectorMapTypeOption>(
        defaultMapType ?? google.maps.MapTypeId.ROADMAP,
    );
    const sendSplunk = useSplunkCallback<MapEventData>();

    /**
     * Google don't support custom cloud-based-styling for map modes other than 'roadmap',
     * this will allow the map to fallback to Raster map.
     */
    const renderRasterMap = fallbackToRasterMap && currentMapType !== 'roadmap';
    const mapRenderType: MapRenderType = renderRasterMap ? 'raster' : 'vector';

    const mapOptions = useMemo(() => {
        if (renderRasterMap) {
            return {
                ...options,
                mapTypeControl: false,
                rotateControl: false,
                mapTypeId: currentMapType,
                styles: defaultRasterMapStyles[currentMapType as MapTypeId] ?? placerMapStyles,
                tilt: 0,
            } as Partial<google.maps.MapOptions>;
        } else {
            return {
                mapId: mapId!,
                ...options,
            };
        }
    }, [renderRasterMap, currentMapType, options, mapId]);

    const set3DMode = (enabled: boolean = true) => {
        if (enabled) {
            set3D(true);
            setMapType(google.maps.MapTypeId.ROADMAP);
            map?.setMapTypeId(google.maps.MapTypeId.ROADMAP);
        } else {
            set3D(false);
        }
    };

    const onMapLoad = (loadedMap: google.maps.Map) => {
        setMap(loadedMap);
        props.onLoad && props.onLoad(loadedMap);
    };

    const onTiltChange = () => {
        if (!map) return;

        set3DMode(map.getTilt() > 0);
        props.onTiltChanged && props.onTiltChanged();
    };

    const onMapTypeIdChange = () => {
        const mapTypeId = map?.getMapTypeId();

        if (mapTypeId && mapTypeId !== currentMapType) {
            //mapTypeId was changed not by internal handleMapTypeChange()
            sendSplunk({
                action: 'toggle',
                component: `${MAP_SPLUNK_EVENTS.BASEMAP} switch`,
                value: mapTypeId,
                mapProvider: 'google',
            });
            setMapType(mapTypeId);
        }
    };

    const handleMapTypeChange = (option: VectorMapTypeOption) => {
        if (!map) return;

        if (option === '3d') {
            set3DMode();
            map.setOptions(default3dOptions);
        } else {
            setMapType(option);
            map.setMapTypeId(option);
            if (is3D) {
                set3DMode(false);
                map.setOptions(defaultTiltingOptions);
            }
        }
        props.onMapTypeIdChanged && props.onMapTypeIdChanged();
    };

    const mapProps = {
        type: mapRenderType,
        options: mapOptions,
        mapTypeId: currentMapType,
        ...props,
        onLoad: onMapLoad,
        onTiltChanged: onTiltChange,
        onMapTypeIdChanged: onMapTypeIdChange,
    };

    type MapPropsType = typeof mapProps extends VectorMapProps
        ? VectorMapProps
        : GoogleMapComponentProps;

    return (
        <GoogleMap<MapPropsType> {...mapProps} key={mapRenderType}>
            {includeCustomMapIdController && (
                <MapTypeController
                    value={is3D ? '3d' : currentMapType}
                    onChange={handleMapTypeChange}
                    options={['hybrid', 'roadmap']}
                    className={classnames(styles.mapTypeController, mapTypeClassName)}
                />
            )}
            {children}
        </GoogleMap>
    );
};
