import pluralize from 'pluralize';
import queryString from 'query-string';
import { fetchWrapper } from 'API/authentification/fetch-wrapper';
import { Region } from 'core/dto/region';
import type {
    Place,
    Tag,
    PlacerResponseData,
    PlacerArrayResponse,
    PlacerEntityResponse,
} from '@placer-ui/types';
import { getAppGatewayEndpoint } from 'core/default-endpoint-url/default-endpoint-url';

export type ChainsSearchParams = {
    term: string;
    skip?: number;
    limit: number;
    purchasedOnly: boolean;
    category?: string;
    region?: Partial<Region>;
    region_type?: string;
    region_code?: string;
    source?: string;
};

interface TopVenuesRankingResponse<T> extends PlacerResponseData<T> {
    total_ft: number;
}

type GetTopVenuesParams = {
    chainId: string;
    startDate: string;
    endDate: string;
    chainType: string;
    mode?: string;
};

const getEntitiesByIDs = async (ids: string[]): PlacerArrayResponse<Tag[]> => {
    const body = {
        entity_ids: ids.map((id) => ({
            entity_id: id,
            entity_type: 'chain',
        })),
    };

    return await fetchWrapper({
        targetUrl: '/entities',
        method: 'POST',
        body,
    });
};

const getTopVenues = async ({
    chainId,
    chainType,
    startDate,
    endDate,
    mode,
}: GetTopVenuesParams): Promise<TopVenuesRankingResponse<Place>> => {
    return await fetchWrapper({
        targetUrl: `/${pluralize(chainType)}/${chainId}/ranking?${queryString.stringify({
            mode,
            limit: 100,
            start_date: startDate,
            end_date: endDate,
        })}`,
        basePath: `${process.env.REACT_APP_CHAINS_SERVICE_PATH}`,
        endpoint: getAppGatewayEndpoint(),
    });
};

const getSuggestedBrands = async (limit: number = 20): PlacerArrayResponse<Tag[]> => {
    const params = queryString.stringify({ limit });
    const targetUrl = `/top-chains?${params}`;

    return await fetchWrapper({
        targetUrl,
        basePath: `${process.env.REACT_APP_CHAINS_SERVICE_PATH}`,
        endpoint: getAppGatewayEndpoint(),
    });
};

const getChainsByTerm = async ({
    term,
    skip,
    limit,
    purchasedOnly,
    category,
    region_type,
    region_code,
}: ChainsSearchParams): PlacerEntityResponse<Tag[]> => {
    const params = {
        limit,
        category,
        query: term,
        type: 'chains',
        purchased_only: purchasedOnly,
        source: 'industry',
        region_type,
        region_code,
        chain_type: 'industry',
    };

    skip && Object.assign(params, { skip });

    const queryParams = queryString.stringify(params, {
        skipEmptyString: true,
    });

    const targetUrl = `/search?${queryParams}`;

    return await fetchWrapper({ targetUrl });
};

export const chainsAPI = {
    getEntitiesByIDs,
    getChainsByTerm,
    getTopVenues,
    getSuggestedBrands,
};
