import type { Dictionary } from '@placer-ui/types';
import {
    getMetricsAndRankFromKey,
    getMetricsData,
    getMetricWithRankKey,
} from 'features/my-zone-properties/utils/metrics-utils';
import { MetricInfo, MetricsInfoWithKey } from 'components/metric-selector/types/types';
import { useMemo } from 'react';
import { useFeatureFlags } from 'hooks/feature-flags/use-feature-flags';

export const providersTitles: Dictionary<string> = {
    placer_ai: 'Placer.ai',
    acs: 'ACS',
};

export const benchMarksTitles: Dictionary<string> = {
    category: 'Cat.',
    chain: 'Chain',
    primary: 'Category',
    group: 'Category Group',
};

export const benchMarksFilterTitles: Dictionary<string> = {
    category: 'Category',
    chain: 'Chain',
    group: 'Category Group',
    primary: 'Category',
};

export const dataCategories: Dictionary<string> = {
    panel_data: 'Panel Data',
    visits: 'Visits',
    loyalty: 'Loyalty',
    property_information: 'Property Information',
    visitor_demographic: 'Visitor Demographic',
    retail_sales: 'Retail Sales',
};

export const regionTitles: Dictionary<string> = {
    cbsa: 'CBSA',
    dma: 'DMA',
    country: 'Nationwide',
    state: 'State',
};

export const metricDictionary: Dictionary<MetricInfo> = {
    FT: {
        title: 'Visits',
        category: 'visits',
        provider: 'placer_ai',
    },
    PANEL: {
        title: 'Panel Visits',
        category: 'panel_data',
        provider: 'placer_ai',
    },
    FT_PER_SQFT: {
        title: 'Visits / sq ft',
        category: 'visits',
        provider: 'placer_ai',
    },
    FT_SHORT: {
        title: 'Visits',
        category: 'visits',
        provider: 'placer_ai',
    },
    FT_LONG: {
        title: 'Visits',
        category: 'visits',
        provider: 'placer_ai',
    },
    FT_PER_SQFT_SHORT: {
        title: 'Visits / sq ft',
        category: 'visits',
        provider: 'placer_ai',
    },
    FT_PER_SQFT_LONG: {
        title: 'Visits / sq ft',
        category: 'visits',
        provider: 'placer_ai',
    },
    areaSqFt: {
        title: 'Size - sq ft',
        category: 'property_information',
        provider: 'placer_ai',
    },
    estimated_foottraffic: {
        title: 'Visits',
        category: 'visits',
        provider: 'placer_ai',
    },
    estimated_visits_sqft: {
        title: 'Visits / sq ft',
        category: 'visits',
        provider: 'placer_ai',
    },
    estimated_customer_count: {
        title: 'Visitors',
        category: 'visits',
        provider: 'placer_ai',
    },
    monthly_visitors: {
        title: 'Avg. monthly Visitors',
        provider: 'placer_ai',
    },
    mean_visits_per_customer: {
        title: 'Visit Frequency',
        category: 'loyalty',
        provider: 'placer_ai',
    },
    avg_dwell_time: {
        title: 'Avg. Dwell Time',
        category: 'visits',
        provider: 'placer_ai',
    },
    median_dwell_time: {
        title: 'Median Dwell Time',
        category: 'visits',
        provider: 'placer_ai',
    },
    panel_visits: {
        title: 'Panel Visits',
        category: 'panel_data',
        provider: 'placer_ai',
    },
    panel_devices: {
        title: 'Panel Devices',
        category: 'panel_data',
        provider: 'placer_ai',
    },
    average_household_income: {
        title: 'Average HHI - Census 2017',
        category: 'visitor_demographic',
        provider: 'acs',
    },
    median_household_income: {
        title: 'Median HHI - Census 2017',
        category: 'visitor_demographic',
        provider: 'acs',
    },
    sales: {
        title: 'Sales',
        category: 'retail_sales',
        provider: 'placer_ai',
        disablePermission: 'unlock_retail_sales',
    },
    sales_sq_ft: {
        title: 'Sales / sq ft',
        category: 'retail_sales',
        provider: 'placer_ai',
        disablePermission: 'unlock_retail_sales',
    },
    avg_monthly_unique_customers: {
        title: 'Average Monthly Customers',
        category: 'retail_sales',
        provider: 'placer_ai',
        disablePermission: 'unlock_retail_sales',
    },
    avg_weekly_unique_customers: {
        title: 'Average Weekly Customers',
        category: 'retail_sales',
        provider: 'placer_ai',
        disablePermission: 'unlock_retail_sales',
    },
    avg_daily_unique_customers: {
        title: 'Average Daily Customers',
        category: 'retail_sales',
        provider: 'placer_ai',
        disablePermission: 'unlock_retail_sales',
    },
    transactions: {
        title: 'Transactions',
        category: 'retail_sales',
        provider: 'placer_ai',
        disablePermission: 'unlock_retail_sales',
    },
    avg_ticket_size: {
        title: 'Average Ticket Size',
        category: 'retail_sales',
        provider: 'placer_ai',
        disablePermission: 'unlock_retail_sales',
    },
    retail_sales: {
        title: 'Sales',
        category: 'retail_sales',
        provider: 'placer_ai',
        disablePermission: 'unlock_retail_sales',
    },
    yoy: {
        title: 'Visits YoY',
        category: 'visits',
        provider: 'placer_ai',
    },
    yo2y: {
        title: 'Visits Yo2Y',
        category: 'visits',
        provider: 'placer_ai',
    },
    yo3y: {
        title: 'Visits Yo3Y',
        category: 'visits',
        provider: 'placer_ai',
    },
    SALES_EST: {
        title: 'Sales',
        category: 'retail_sales',
        provider: 'placer_ai',
        disablePermission: 'unlock_retail_sales',
    },
    SALES_SQ_FT_EST: {
        title: 'Sales / sq ft',
        category: 'retail_sales',
        provider: 'placer_ai',
        disablePermission: 'unlock_retail_sales',
    },
    TRANSACTIONS_EST: {
        title: 'Transactions',
        category: 'retail_sales',
        provider: 'placer_ai',
        disablePermission: 'unlock_retail_sales',
    },
    // TRANSACTIONS_SQ_FT_EST: {
    //     title: 'Transactions / sq ft',
    //     category: 'retail_sales',
    //     provider: 'placer_ai',
    // },
    visitsShare: {
        title: 'Visits Share',
        category: 'visits',
        provider: 'placer_ai',
    },
    salesShare: {
        title: 'Sales Share',
        category: 'retail_sales',
        provider: 'placer_ai',
    },
    average_per_venue: {
        title: 'Avg. Visits / Location',
        category: 'visits',
        provider: 'placer_ai',
    },
    number_of_venues: {
        title: 'Locations Showing Visits',
        category: 'visits',
        provider: 'placer_ai',
    },
    coverage: {
        title: 'Coverage',
        category: 'visits',
        provider: 'placer_ai',
    },
    weekly_average_visitors: {
        title: 'Avg. Weekly Visitors',
        category: 'visits',
        provider: 'placer_ai',
    },
    monthly_average_visitors: {
        title: 'Avg. Monthly Visitors',
        category: 'visits',
        provider: 'placer_ai',
    },
    average_visit_duration: {
        title: 'Avg. Dwell Time',
        category: 'visits',
        provider: 'placer_ai',
    },
    monthly_average_visits: {
        title: 'Avg. Monthly Visits',
        category: 'visits',
        provider: 'placer_ai',
    },
    monthly_average_visits_per_location: {
        title: 'Avg. Monthly Visits / Location',
        category: 'visits',
        provider: 'placer_ai',
    },
    monthly_average_panels: {
        title: 'Avg. Monthly Panel Visits',
        category: 'panel_data',
        provider: 'placer_ai',
    },
    weekly_average_visits: {
        title: 'Avg. Weekly Visits',
        category: 'visits',
        provider: 'placer_ai',
    },
    weekly_average_visits_per_location: {
        title: 'Avg. Weekly Visits / Location',
        category: 'visits',
        provider: 'placer_ai',
    },
    monthly_panel_visits: {
        title: 'Avg. Monthly Panel Visits / Location',
        category: 'panel_data',
        provider: 'placer_ai',
    },
};

export const useMetricsAttributes = (metrics: string[]) => {
    const { change_dwelltime_to_visit_duration_ff } = useFeatureFlags();
    return useMemo(() => {
        return metrics.reduce((result: MetricsInfoWithKey[], metricKey) => {
            const { metric: metricName } = getMetricsAndRankFromKey(metricKey);
            const metricsData = getMetricsData(metricKey);

            if (!metricsData) {
                return result;
            }

            const { rankType, subtitle, benchmarkCategory, region, benchmark } = metricsData;

            const key = getMetricWithRankKey(metricName, rankType);
            const label =
                (metricName === 'avg_dwell_time' || metricName === 'median_dwell_time') &&
                change_dwelltime_to_visit_duration_ff
                    ? metricDictionary[metricName].title.replace('Dwell Time', 'Visit Duration')
                    : metricDictionary[metricName].title;
            result.push({
                ...metricDictionary[metricName],
                title: label,
                benchmark,
                benchmarkCategory,
                region,
                subtitle,
                key,
            });

            return result;
        }, []);
    }, [change_dwelltime_to_visit_duration_ff, metrics]);
};

export const newRankCategories = [
    'chain.cbsa',
    'chain.country',
    'chain.dma',
    'chain.state',
    'group.cbsa',
    'group.country',
    'group.dma',
    'group.state',
    'primary.cbsa',
    'primary.country',
    'primary.dma',
    'primary.state',
];

export const ranksCategories = [
    'category.cbsa',
    'category.country',
    'category.dma',
    'category.state',
    'chain.cbsa',
    'chain.country',
    'chain.dma',
    'chain.state',
];
