import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectCacheDates, selectDateLimits } from 'store/selectors/app-selectors';
import { format } from 'date-fns';
import type { Dictionary } from '@placer-ui/types';

import { CacheDateOption } from 'core/entities/user/user-configuration';
import {
    splitGranularityWithYear,
    getGranularityWithYear,
} from 'components/date-compare-select/date-utils';
import { getSameYearFormatRange } from 'utils/date/date';
import {
    DateYear,
    DateOption,
    Granularity,
    GranularityWithYear,
} from 'components/date-compare-select/date-compare-select-types';
import { hasFeatureFlags } from 'core/flow-control';

function hasAdditionalDates() {
    return hasFeatureFlags(['Additional_dates_in_tags_portfolio']);
}

/** the minimum year received from backend */
const CONFIG_CACHE_DATES_MIN_YEAR = 2018;

const granularityToLabel = {
    week: 'Last Full Week',
    month: 'Last Month',
    previous_month: 'Previous Month',
    quarter_year: 'Last Quarter',
    year: 'Year to Date',
    'last-3-months': 'Last Full 3 Months',
    'last-6-months': 'Last Full 6 Months',
    'last-12-months': 'Last Full 12 Months',
} as {
    [key in Granularity]: string;
};
const oldGranularityToLabel: {
    [key in Granularity]: string;
} = {
    ...granularityToLabel,
    week: 'Last Week',
};

const granularityToPrevLabels = {
    week: 'Previous Week',
    month: 'Previous Month',
    previous_month: 'Previous Month',
    quarter_year: 'Previous Quarter',
    year: 'Previous Year To Date',
    'last-3-months': 'Previous 3 Months',
    'last-6-months': 'Previous 6 Months',
    'last-12-months': 'Previous 12 Months',
} as {
    [key in Granularity]: string;
};

const defaultPreviousLabel = 'Previous Period';

function sortCompareOption(optionA: DateOption, optionB: DateOption) {
    const granularityA = optionA.key as Granularity;
    const granularityB = optionB.key as Granularity;

    if (granularityA === 'prev') {
        return -1;
    }
    if (granularityB === 'prev') {
        return 1;
    }
    const { year: yearA } = splitGranularityWithYear(granularityA);
    const { year: yearB } = splitGranularityWithYear(granularityB);
    return Number(yearB) - Number(yearA);
}

function getDateLabel(startDateStr: string, endDateStr: string, granularity?: Granularity) {
    if (hasAdditionalDates()) {
        if (granularity === 'month' || granularity === 'previous_month') {
            const startDate = new Date(startDateStr);
            return format(startDate, 'MMMM yyyy');
        } else if (granularity === 'quarter_year') {
            const startDate = new Date(startDateStr);
            return format(startDate, 'QQQ yyyy');
        }
    }
    return getSameYearFormatRange(startDateStr, endDateStr);
}

const getComparableDateOption = (
    granularityWithYear: Granularity,
    preset: CacheDateOption,
    parentGranularity?: Granularity,
): DateOption => {
    const { start: startDate, end: endDate } = preset;
    let granularity = parentGranularity;
    let value: string;
    if (granularityWithYear === 'prev') {
        value = granularity
            ? granularityToPrevLabels[granularity] || defaultPreviousLabel
            : defaultPreviousLabel;
    } else {
        // granularityWithKey is probably "year-2000" etc.
        const { granularity: granularityFromSplit, year } =
            splitGranularityWithYear(granularityWithYear);
        granularity = granularityFromSplit;
        if (hasAdditionalDates()) value = year ?? granularityToLabel[granularity];
        else value = year ?? oldGranularityToLabel[granularity];
    }

    return {
        key: granularityWithYear,
        label: getDateLabel(startDate, endDate, parentGranularity ?? granularity),
        optionLabel: value,
        value,
        startDate,
        endDate,
    };
};

export const getComparableOptions = (
    comparableOptions?: Dictionary<CacheDateOption>,
    granularity?: Granularity,
) => {
    if (!comparableOptions) return;

    const options = Object.keys(comparableOptions).reduce<DateOption[]>((optionsAcc, key) => {
        const granularityWithYear = key as Granularity;
        const option = getComparableDateOption(
            granularityWithYear,
            comparableOptions[granularityWithYear],
            granularity,
        );
        optionsAcc.push(option);
        return optionsAcc;
    }, []);

    return options.sort(sortCompareOption);
};

const getDateOption = (
    datePresets: Dictionary<CacheDateOption> | undefined,
    granularity: Granularity,
    year?: DateYear,
): DateOption | undefined => {
    const granularityWithYear = getGranularityWithYear(granularity, year);
    const preset = datePresets?.[granularityWithYear];
    if (!preset) return;

    const { start: startDate, end: endDate, comparables } = preset;
    let value = year ?? granularityToLabel[granularity];
    if (!hasAdditionalDates()) value = year ?? oldGranularityToLabel[granularity];

    return {
        key: granularityWithYear,
        label: getDateLabel(startDate, endDate, granularity),
        optionLabel: value,
        value,
        startDate,
        endDate,
        compareOptions: getComparableOptions(comparables, granularity),
    };
};

/**
 * This is how the cache-dates from the server's configuration looks like:
 * 
 * {
  "month": {
    "start": "2024-05-01",
    "end": "2024-05-31",
    "comparables": {
      "year-2018": {
        "start": "2018-05-01",
        "end": "2018-05-31"
      },
      "year-2019": {
        "start": "2019-05-01",
        "end": "2019-05-31"
      },
      "year-2020": {
        "start": "2020-05-01",
        "end": "2020-05-31"
      },
      "year-2021": {
        "start": "2021-05-01",
        "end": "2021-05-31"
      },
      "year-2022": {
        "start": "2022-05-01",
        "end": "2022-05-31"
      },
      "year-2023": {
        "start": "2023-05-01",
        "end": "2023-05-31"
      },
      "prev": {
        "start": "2024-04-01",
        "end": "2024-04-30"
      }
    }
  },
  "quarter_year": {
    "start": "2024-01-01",
    "end": "2024-03-31",
    "comparables": {
      "year-2018": {
        "start": "2018-01-01",
        "end": "2018-03-31"
      },
      ...
      "prev": {
        "start": "2023-10-01",
        "end": "2023-12-31"
      }
    }
  },
  "last-3-months": {
    "start": "2024-03-01",
    "end": "2024-05-31",
    "comparables": {
      "year-2018": {
        "start": "2018-03-01",
        "end": "2018-05-31"
      },
      ...
      "prev": {
        "start": "2023-12-01",
        "end": "2024-02-29"
      }
    }
  },
  "last-6-months": { ... },
  "last-12-months": { ... },
  "week": { ... },
  "previous_month": { ... },
  "year-2023": { ... },
  "year-2022": { ... },
  "year-2021": { ... },
  "year-2020": { ... },
  "year": {
    "start": "2024-01-01",
    "end": "2024-06-23",
    "comparables": {
      "year-2018": {
        "start": "2018-01-01",
        "end": "2018-06-23"
      },
      "year-2019": {
        "start": "2019-01-01",
        "end": "2019-06-23"
      },
      "year-2020": {
        "start": "2020-01-01",
        "end": "2020-06-23"
      },
      "year-2021": {
        "start": "2021-01-01",
        "end": "2021-06-23"
      },
      "year-2022": {
        "start": "2022-01-01",
        "end": "2022-06-23"
      },
      "year-2023": {
        "start": "2023-01-01",
        "end": "2023-06-23"
      }
    }
  }
}
 */
export const useDateOptions = (): DateOption[] => {
    const { maxDate } = useSelector(selectDateLimits);
    const datePresets = useSelector(selectCacheDates);

    return useMemo(() => {
        let granularityWithYear: GranularityWithYear[] = [
            { granularity: 'week' },
            { granularity: 'month' },
            { granularity: 'quarter_year' },
            { granularity: 'year' },
        ];
        if (hasAdditionalDates()) {
            granularityWithYear = [
                { granularity: 'week' },
                { granularity: 'month' },
                { granularity: 'previous_month' },
                { granularity: 'quarter_year' },
                { granularity: 'year' },
                { granularity: 'last-3-months' },
                { granularity: 'last-6-months' },
                { granularity: 'last-12-months' },
            ];
        }

        const maxYear = new Date(maxDate).getFullYear();
        const minYear = CONFIG_CACHE_DATES_MIN_YEAR;
        for (let year = maxYear - 1; year >= minYear; year--) {
            granularityWithYear.push({
                granularity: 'year',
                year: String(year) as DateYear,
            });
        }

        return granularityWithYear.reduce<DateOption[]>((optionsAcc, { granularity, year }) => {
            const option = getDateOption(datePresets, granularity, year);
            if (option) {
                optionsAcc.push(option);
            }
            return optionsAcc;
        }, []);
    }, [maxDate, datePresets]);
};
