import React from 'react';
import styles from './search-poi-filters.module.scss';

import { CategoriesFilters } from 'features/poi-selection-map/poi-selection-menu/menu-items/search-menu-item/search-poi-filters/categories-filters/categories-filters';
import { PoiTypeFilters } from 'features/poi-selection-map/poi-selection-menu/menu-items/search-menu-item/search-poi-filters/poi-type-filters/poi-type-filters';

export const SearchPoiFilters = () => {
    return (
        <div className={styles.mainContainer}>
            <PoiTypeFilters />
            <hr className={styles.separator} />
            <CategoriesFilters />
        </div>
    );
};
