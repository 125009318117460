import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';

import type { FilterType } from '@placer-ui/types';
import { fromBackendFilters } from 'features/insights/utils/convert-filters/convert-filters';
import {
    poisFiltersSelector,
    poisFiltersWithDefaultsSelector,
} from 'features/insights/store/selectors/insights-selectors';

export const useAreSameFilters = () => {
    const filtersWithDefaults = useSelector(poisFiltersWithDefaultsSelector);
    const backendFilters = useSelector(poisFiltersSelector);
    const filters = fromBackendFilters(backendFilters);

    const areSameDefaults = useCallback(
        (type: FilterType) => {
            const firstFilter = filtersWithDefaults[0]?.[type];
            return filtersWithDefaults.every((filter) => isEqual(firstFilter, filter[type]));
        },
        [filtersWithDefaults],
    );

    return useCallback(
        (type: FilterType) => {
            const firstFilter = filters[0]?.[type];

            return firstFilter
                ? filters?.every((filter) => isEqual(firstFilter, filter[type]))
                : areSameDefaults(type);
        },
        [areSameDefaults, filters],
    );
};
