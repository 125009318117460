/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';
import { IconProps } from 'components/assets/Icons/Icons';

export const FastFoodAndQSRIconSvg = (
    props: CustomIconComponentProps | SVGProps<SVGSVGElement>,
) => (
    <svg {...props} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0 4.31818C0 4.69478 0.228126 5 0.5096 5H11.3811C11.6625 5 11.8907 4.69478 11.8907 4.31818C11.8907 1.93714 10.4428 0 8.6632 0H3.22747C1.44785 0 0 1.93714 0 4.31818Z"
            fill="currentColor"
        />
        <path
            d="M1.96774 6C1.43322 6 1 6.44766 1 7C1 7.55234 1.43322 8 1.96774 8H10.0323C10.5668 8 11 7.55234 11 7C11 6.44766 10.5668 6 10.0323 6H1.96774Z"
            fill="currentColor"
        />
        <path
            d="M11.4857 9H0.514286C0.230223 9 0 9.30716 0 9.68614C0 10.8211 0.692143 12 1.54286 12H10.4571C11.3079 12 12 10.8211 12 9.68614C12 9.30716 11.7698 9 11.4857 9Z"
            fill="currentColor"
        />
    </svg>
);

export const FastFoodAndQSRIcon = (props: IconProps) => (
    <Icon component={FastFoodAndQSRIconSvg} {...props} />
);
