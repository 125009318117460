import React from 'react';
import { Redirect } from 'react-router-dom';
import { useGetIndexPath } from 'router/hooks/use-get-index-path';
import { PlRoute } from 'shared/router';

export const RedirectAuth = () => {
    const indexPath = useGetIndexPath();

    return (
        <PlRoute
            render={() => {
                let next_url;
                if (
                    window.location.pathname !== '/' &&
                    window.location.pathname !== indexPath &&
                    !window.location.pathname.startsWith('/auth')
                ) {
                    next_url = window.location.href;
                }
                return (
                    <Redirect
                        to={{
                            pathname: '/auth/signin',
                            state: { next_url },
                        }}
                    />
                );
            }}
        />
    );
};
