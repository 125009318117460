import { EntityURLParams } from 'features/insights/types/url-types';

export const getCompetitorsFromURL = (searchParams: URLSearchParams) => {
    const competitorParam = searchParams.get('competitor');
    if (competitorParam) {
        try {
            return JSON.parse(competitorParam) as EntityURLParams[];
        } catch (e) {
            return undefined;
        }
    }
};
