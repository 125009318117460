import Supercluster from 'supercluster';
import useSupercluster from 'use-supercluster';
import type { Place } from '@placer-ui/types';
import {
    ClusteredMarkers,
    MarkerDrawType,
    MarkerPoint,
    MarkersCluster,
} from 'ui-components/google-map/types/types';
import { MapLayerVenue } from 'ui-components/google-map-layers/types/google-map-layers-types';
import { maxBy } from 'lodash';
import { USABoundingBox } from 'ui-components/google-map/google-map-default-options';
import type { PlacerEntityWrapper } from '@placer-ui/types';

/**
 * As Supercluster uses pixels as radius, and pixels varies between different devices,
 * a fixed value seems to yield the best results
 */
const CLUSTERING_RADIUS_PX = 130;
const MAX_ZOOM = 22;

const createVenuesGeoJSON = (venues: PlacerEntityWrapper<Place>[]): MarkerPoint[] => {
    return venues.map((venue) => {
        //@ts-expect-error PLAC-47814
        const { lat, lng } = venue.info.geolocation;
        return {
            type: 'Feature',
            properties: venue,
            geometry: {
                type: 'Point',
                coordinates: [lng, lat],
            },
        };
    });
};

const getPrimaryVenue = (clusterFeatures: MarkerPoint[]): MarkerPoint => {
    return maxBy(
        clusterFeatures,
        ({ properties }: MarkersCluster) => properties.overview?.estimated_foottraffic || 0,
    );
};

const getClusterPrimaryVenueId = (
    clusterer: Supercluster<PlacerEntityWrapper<Place>>,
    cluster: MarkersCluster | MarkerPoint,
) => {
    if (!(cluster as MarkersCluster).properties.cluster) {
        // an individual point
        return cluster.properties.info.id;
    }

    const { cluster_id } = (cluster as MarkersCluster).properties;
    const children = clusterer.getLeaves(cluster_id, Infinity);

    const maxFTChild = getPrimaryVenue(children);
    return maxFTChild.properties.info.id;
};

const getMarkerDrawType = (venue: Place, logosIds: string[], pinsIds: string[]): MarkerDrawType => {
    const { id, parent_chain_id } = venue;
    const isLogo = logosIds.includes(id);
    const isPin = pinsIds.includes(id);

    if (isLogo) {
        return parent_chain_id ? 'logo' : 'pin';
    } else if (isPin) {
        return 'pin';
    } else {
        return 'dot';
    }
};

/**
 * Partitions the venues into clusters and marks each venue with its draw type.
 * Supercluster pre-calculates the entire clustering for each zoom, meaning that with a single call
 * to useSupercluster we have both logos (larger) and pins (smaller) clusters.
 */
export const useClusteredVenues = (venues: PlacerEntityWrapper<Place>[], zoom: number) => {
    const integerZoom = Math.floor(zoom);

    const initialClusters: ClusteredMarkers = {
        all: [],
        dot: [],
        logo: [],
        pin: [],
    };

    const { clusters: pinClusters, supercluster } = useSupercluster<
        PlacerEntityWrapper<Place>,
        PlacerEntityWrapper<Place>
    >({
        points: createVenuesGeoJSON(venues),
        bounds: USABoundingBox,
        zoom: integerZoom,
        options: {
            radius: CLUSTERING_RADIUS_PX,
            maxZoom: MAX_ZOOM,
        },
    });

    if (!supercluster) {
        return initialClusters;
    }

    // wider clusters for logos
    const logoClusters = supercluster.getClusters(USABoundingBox, integerZoom - 1);
    const logosIds = logoClusters.map((cluster) => getClusterPrimaryVenueId(supercluster, cluster));

    // smaller clusters for pins
    const pinsIds = pinClusters.map((cluster) => getClusterPrimaryVenueId(supercluster, cluster));

    return venues.reduce<ClusteredMarkers>((groups, venue) => {
        const drawType = getMarkerDrawType(venue.info, logosIds, pinsIds);
        const marker: MapLayerVenue = {
            ...venue,
            drawType,
        };

        groups.all.push(marker);
        groups[drawType].push(marker);

        return groups;
    }, initialClusters);
};
