import { MultiSelectOption } from 'ui-components/multi-select/types';
import type { Dictionary } from '@placer-ui/types';
import { useSelectorWithProps } from 'utils/structured-selector/structured-selector';
import { selectHasUserPermission } from 'store/selectors/app-selectors';
import { isSubstring } from 'utils/substrings/substrings';

type GetFilteredOptionsBySearchStringProps = {
    options: MultiSelectOption[];
    searchValue?: string;
    isChildCheckboxList?: boolean;
};

export const getFilteredOptionsBySearchString = ({
    options,
    searchValue,
    isChildCheckboxList,
}: GetFilteredOptionsBySearchStringProps): MultiSelectOption[] => {
    return searchValue && !isChildCheckboxList
        ? options.reduce<MultiSelectOption[]>((filteredOptions, option) => {
              const isTopLevelItemMatch = isSubstring(option.name, searchValue);
              const filteredSubOptions = option.subOptions?.filter((subOption) =>
                  isSubstring(subOption.name, searchValue),
              );

              if (isTopLevelItemMatch) {
                  filteredOptions.push(option);
              } else if (filteredSubOptions?.length) {
                  const itemWithFilteredSubOptions: MultiSelectOption = {
                      ...option,
                      subOptions: filteredSubOptions,
                  };
                  filteredOptions.push(itemWithFilteredSubOptions);
              }

              return filteredOptions;
          }, [])
        : options;
};

export const getExpandedValues = (
    options: MultiSelectOption[],
    searchValue: string,
): Dictionary<boolean> => {
    return options.reduce<Dictionary<boolean>>((expandedValues, option) => {
        const isSubOptionsItemMatch = option.subOptions?.some(({ name }) =>
            isSubstring(name, searchValue),
        );

        if (isSubOptionsItemMatch) {
            expandedValues[option.value] = true;
        }
        return expandedValues;
    }, {});
};
export type TotalOptionsSelected = {
    total: number;
    selected: number;
    selectedList: string[];
};

export type OptionsSelectedData = TotalOptionsSelected & {
    clearMode: boolean;
};

type GetTotalOptionsSelected = {
    options: MultiSelectOption[];
    visualDisableAll: boolean;
    allowExpansion: boolean;
};

export const getTotalOptionsSelected = ({
    options,
    visualDisableAll,
    allowExpansion,
}: GetTotalOptionsSelected) => {
    return options.reduce<TotalOptionsSelected>(
        (acc, curr) => {
            if (!curr.subOptions || !allowExpansion) {
                acc.total += 1;
                if (curr.selected || visualDisableAll) {
                    acc.selected += 1;
                    acc.selectedList.push(curr.value);
                }
            } else {
                curr.subOptions.forEach((subOption) => {
                    acc.total += 1;
                    if (subOption.selected || visualDisableAll) {
                        acc.selected += 1;
                        acc.selectedList.push(subOption.value);
                    }
                });
            }
            return acc;
        },
        {
            total: 0,
            selected: 0,
            selectedList: [],
        },
    );
};

export const getTotalSelectedPerOption = (option: MultiSelectOption, allowExpansion: boolean) => {
    return getTotalOptionsSelected({
        options: [option],
        visualDisableAll: false,
        allowExpansion,
    });
};

type LastSelectionProps = {
    totalOptionsSelected: TotalOptionsSelected;
    newOption: MultiSelectOption;
    orgOption: MultiSelectOption;
    allowExpansion: boolean;
};

export const isFinalSelectedOptions = ({
    totalOptionsSelected,
    orgOption,
    newOption,
    allowExpansion,
}: LastSelectionProps) => {
    const totalSelectedPerNewOption = getTotalSelectedPerOption(newOption, allowExpansion);
    if (
        totalSelectedPerNewOption.selected === 0 &&
        totalOptionsSelected.selected <= totalSelectedPerNewOption.total
    ) {
        if (!orgOption.subOptions) {
            return totalOptionsSelected.selectedList.length === 1;
        } else {
            return totalOptionsSelected.selectedList.every((optionVal) => {
                return orgOption.subOptions!.find((subOption) => subOption.value === optionVal);
            });
        }
    }
    return false;
};

export const useEnableCheckboxListFiltersV3 = () =>
    useSelectorWithProps(
        { permission: 'enable_checkbox_list_filters_improvements_v3' },
        selectHasUserPermission,
    );
