import { Callout } from '@placer-ui/components';
import React from 'react';
import styles from './newly-added-location.module.css';

type NewCpoiCalloutProps = {
    state: 'updated' | 'created';
    isCustom?: boolean;
};
export const NewCpoiCallout = ({ state, isCustom = false }: NewCpoiCalloutProps) => {
    return (
        <div className={styles.newlyAddedLocation}>
            {isCustom ? (
                <Callout text={`Custom POI - Recently ${state}`} />
            ) : (
                <Callout text={`Recently ${state} POI`} />
            )}
        </div>
    );
};
