export function isEmailBlockedForSegmentEvents(email: string = ''): boolean {
    const normalizedEmail = email.toLowerCase();
    if (isEmailWhitelistedForSegmentEvents(normalizedEmail)) {
        return false;
    }

    const segmentBlockedPartialEmails = [
        'support_test',
        'ygr8lzns',
        'placer.io',
        'placer.ai',
        'automation',
    ];
    //Checking partial match
    return (
        !!normalizedEmail &&
        segmentBlockedPartialEmails.some((blockedEmail) => normalizedEmail.includes(blockedEmail))
    );
}

export function isEmailWhitelistedForSegmentEvents(email: string = ''): boolean {
    const segmentWhitelistedEmails = [
        'segment@placer.io',
        'automation_marketplace_2@placer.io',
        'segment.free@placer.io',
    ];
    const normalizedEmail = email.toLowerCase();
    //Checking exact match
    return !!email && segmentWhitelistedEmails.includes(normalizedEmail);
}
