import { VolumeSegmentFeature } from 'types/traffic';
import { TrafficDataVersion } from 'extensions/map/deck-gl/static-tiles-layer/layers/roads-traffic/types';
import { fetchWrapper } from 'API/authentification/fetch-wrapper';
import { TOTAL_HISTOGRAM_BINS, INITIAL_BINS_VALUES } from 'components/traffic-layer/constants';
import type { Bins } from 'utils/bins';

const minValue = INITIAL_BINS_VALUES.reduce((acc, currBin) => {
    return Math.min(acc, currBin[0]);
}, INITIAL_BINS_VALUES[0][0]);

const maxValue = INITIAL_BINS_VALUES.reduce((acc, currBin) => {
    return Math.max(acc, currBin[1]);
}, INITIAL_BINS_VALUES[0][1]);

export const getRoadInfo = async (
    segmentContent: VolumeSegmentFeature,
    year: TrafficDataVersion,
) => {
    return await fetchWrapper({
        targetUrl: `/traffic/v1/traffic_${year}/segments/${segmentContent.properties.id}`,
        method: 'GET',
    });
};

type HistogramValues = Array<{ from: number; size: number }>;

export const getHistogramValues = (
    carsPerSegment: number[],
): {
    values: HistogramValues;
    min: number;
    max: number;
} => {
    const histogramValues: HistogramValues = [];

    if (carsPerSegment.length === 0) {
        INITIAL_BINS_VALUES.forEach((bin) => {
            histogramValues.push({
                from: bin[0],
                size: bin[1] - bin[0],
            });
        });
        return {
            values: histogramValues,
            min: minValue,
            max: maxValue,
        };
    }

    const sortedCarsPerSegment = carsPerSegment.sort((a, b) => a - b);
    const min = sortedCarsPerSegment[0];
    const max = sortedCarsPerSegment[sortedCarsPerSegment.length - 1];
    // each bar shows amount of segments within a range of cars -
    // ranging from X volume of cars to the size of `barRangeSize`.
    const barRangeSize = (max - min) / TOTAL_HISTOGRAM_BINS;

    for (let binIndex = 0; binIndex < TOTAL_HISTOGRAM_BINS; binIndex++) {
        const binFrom = min + binIndex * barRangeSize;
        const binTo = binFrom + barRangeSize;
        const segmentsInBin = sortedCarsPerSegment.filter((seg) => seg > binFrom && seg <= binTo);
        histogramValues.push({
            from: binFrom,
            size: segmentsInBin.length,
        });
    }

    return {
        values: histogramValues,
        min,
        max,
    };
};

type ParsedRoadTypes =
    | {
          values: [number, number];
          isSequence: true;
      }
    | {
          values: number[];
          isSequence: false;
      };
/**
 * parse the filtering road-types to check if they are a sequence of numbers, so we
 * can use them as a range and thus better levarage data-filter-extension GPU usage.
 */
export function parseFilteringRoadTypes(selectedRoadTypes?: number[]): ParsedRoadTypes | undefined {
    if (!selectedRoadTypes || selectedRoadTypes.length === 0) {
        return undefined;
    } else if (selectedRoadTypes.length === 1) {
        const selectedRoad = selectedRoadTypes[0];
        // single selected road-type is like a range from X to itself. e.g. [3, 3]
        return {
            values: [selectedRoad, selectedRoad],
            isSequence: true,
        };
    }

    const sortedRoadTypes = selectedRoadTypes.slice(0).sort((a, b) => a - b);
    let isSequence = true;
    // check if selected road-types are a sequence like [2,3,4,5]
    // or just scattered numbers like [1, 3, 5]
    for (let i = 1; i < sortedRoadTypes.length; i++) {
        const prevRoadType = sortedRoadTypes[i - 1];
        const currRoadType = sortedRoadTypes[i];
        if (currRoadType - prevRoadType !== 1) {
            isSequence = false;
        }
    }

    if (isSequence) {
        // if it is a sequence, just return the range, like [2,5]
        const min = sortedRoadTypes[0];
        const max = sortedRoadTypes[sortedRoadTypes.length - 1];
        return {
            values: [min, max],
            isSequence: true,
        };
    }

    return {
        values: sortedRoadTypes,
        isSequence: false,
    };
}

type ParsedCarVolumes =
    | {
          values: [number, number];
          isSequence: true;
      }
    | {
          values: Bins;
          isSequence: false;
      };
/**
 * parse the filtering car volumes to check if they are a sequence, so we can
 * use them as a range and thus better levarage data-filter-extension GPU usage.
 */
export function parseFilteringCars(carVolumeRanges?: Bins): ParsedCarVolumes | undefined {
    if (!carVolumeRanges || carVolumeRanges.length === 0) {
        return undefined;
    } else if (carVolumeRanges.length === 1) {
        const selectedCarsRange = carVolumeRanges[0];
        // single selected car volume range is like a range from X to itself. e.g. [1457, 1457]
        return {
            values: selectedCarsRange,
            isSequence: true,
        };
    }

    // try and merge all bins to see if they form a continuous range
    const binsNotMerged = carVolumeRanges.slice(0);
    // initialize merged bin with the first bin
    const firstBinFrom = binsNotMerged[0][0];
    const firstBinTo = binsNotMerged[0][1];
    const mergedBin: [number, number] = [firstBinFrom, firstBinTo];

    // keep running as long as each iteration manages to merge at least one bin
    let mergedSome = true;
    while (mergedSome) {
        mergedSome = false;

        for (let i = binsNotMerged.length - 1; i >= 0; i--) {
            const bin = binsNotMerged[i];
            const binFrom = bin[0];
            const binTo = bin[1];

            const mergedBinFrom = mergedBin[0];
            const mergedBinTo = mergedBin[1];

            if (binFrom > mergedBinTo || binTo < mergedBinFrom) {
                // potential bin has no overlapping parts with the merged-bin
                continue;
            }

            // reached here so potential bin has overlapping parts with the merged-bin.
            // merge bin by combining ranges
            mergedBin[0] = Math.min(mergedBinFrom, binFrom);
            mergedBin[1] = Math.max(mergedBinTo, binTo);
            // bin's values were merged so now let's remove it from `binsNotMerged`
            binsNotMerged.splice(i, 1);
            // we merged at least one
            // (note: the for-loop might keep on merging before the while-loop re-iterates)
            mergedSome = true;
        }
    }

    if (binsNotMerged.length === 0) {
        // all bins formed a continuous range
        return {
            values: mergedBin,
            isSequence: true,
        };
    }

    // didn't merge all bins so they didn't form a continuous range
    return {
        values: carVolumeRanges,
        isSequence: false,
    };
}
