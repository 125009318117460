import { createSelector } from '@reduxjs/toolkit';

import { selectChainsDateAndState } from 'features/chains/store/chains-report-chains-store';
import { selectChainsReportDefaultDateRange } from 'features/chains/store/chains-report-chains-store/selectors';

export const selectChainsSearchResultsDateRange = createSelector(
    selectChainsDateAndState,
    selectChainsReportDefaultDateRange,
    (
        { chains, isSameDateFilter },
        { defaultEndDate, defaultStartDate },
    ) => {
        const dateRange = {
            startDate: defaultStartDate,
            endDate: defaultEndDate,
        };

        if (isSameDateFilter) {
            dateRange.startDate = chains[0]?.filters?.date_start;
            dateRange.endDate = chains[0]?.filters?.date_end;
        }

        return dateRange;
    },
);
