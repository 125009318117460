import { AggregationParams, BaseRowDataType, FieldFn } from 'ui-components/table/types';
import { includesCaseInsensitive } from 'utils/strings-sorter/strings-sorter';

const filterRowsBySearchTerm = <RowDataType extends BaseRowDataType>(
    rows: RowDataType[],
    searchTerm: string,
    fields: FieldFn<RowDataType>[],
) => {
    return rows.filter((row) => {
        return fields.some((fieldFn) => includesCaseInsensitive(fieldFn(row), searchTerm));
    });
};

/**
 * Performs the rows aggregation based on current table settings (filter by search term,
 * sorting and pagination are being taken care of natively by Antd)
 * This is used if the table is being used without lazy loading, and assumes {@param rows}
 * contains the entire table dataset.
 * @param rows - original data source (rows array)
 * @param config
 */
export const aggregateRowsLocally = <RowDataType extends BaseRowDataType>(
    rows: RowDataType[],
    config: AggregationParams<RowDataType>,
) => {
    let aggregatedRows: RowDataType[] = [...rows];
    const { searchTerm, searchFields } = config;

    if (searchTerm?.length && searchFields) {
        aggregatedRows = filterRowsBySearchTerm(aggregatedRows, searchTerm, searchFields);
    }

    return {
        totalRows: aggregatedRows.length,
        pageRows: aggregatedRows,
    };
};
