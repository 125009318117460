/* eslint-disable max-len */
import React, { useMemo } from 'react';
import styles from './search-map-legend.module.scss';

import { useSearchApiRestrictionItems } from 'features/poi-selection-map/poi-selection-menu/menu-items/search-menu-item/hooks/search-poi-filter-hooks';
import { MapLegend } from 'ui-components/map-legend/map-legend';
import { Switch } from 'ui-components/switch/switch';
import {
    getMapLayersPOIStyles,
    MapLayerPOIType,
} from 'ui-components/google-map-layers/utils/map-layers-entity-color';
import { BILLBOARD } from 'core/constants/report-type';

const ITEM_HEIGHT = 29;

export const SearchMapLegend = () => {
    const { type, onItemToggle, items } = useSearchApiRestrictionItems();

    const containerHeight = useMemo(() => items.length * ITEM_HEIGHT, [items.length]);

    if (type === BILLBOARD) return null;

    const switchItems = (
        <div>
            {items.map(({ id, disabled, checked, title }) => {
                return (
                    <div key={id} className={styles.switchItem}>
                        <Switch
                            color={getMapLayersPOIStyles(id as MapLayerPOIType).fill}
                            uid={id}
                            disabled={disabled}
                            checked={checked}
                            onChange={onItemToggle}
                        />
                        <span className={styles.title}>{title}</span>
                    </div>
                );
            })}
        </div>
    );

    return (
        <MapLegend
            className={styles.legend}
            disableMouseEvents={false}
            containerHeight={containerHeight}
            isInitiallyCollapsed={true}
        >
            {switchItems}
        </MapLegend>
    );
};
