import { Region } from 'core/dto/region';

export const isRegionNationwide = (region: Region) => {
    return region.type === 'nationwide';
};

export const isRegionTypeState = (region: Region) => {
    return region.type === 'state';
};

export const isRegionTypeDMA = (region: Region) => {
    return region.type === 'dma';
};

export const isRegionTypeCBSA = (region: Region) => {
    return region.type === 'cbsa';
};

export const getChainRegionType = (region: Region) => {
    const { type, name, code } = region;
    
    if (type === 'custom') return 'Custom Chain';

    if (isRegionTypeState(region)) {
        return name;
    }

    if (isRegionNationwide(region)) {
        return 'nationwide';
    }

    const regionType = type ? `${type.toUpperCase()},` : '';
    return `${regionType} ${code} - ${name}`;
};

export const getAnalyticsRegionNameFormat = (region: Region) => {
    const { type, code } = region;

    if (isRegionTypeState(region)) {
        return region.name;
    }

    if (isRegionNationwide(region)) {
        return 'nationwide';
    }

    return `${type.toUpperCase()} ${code}`;
};

export const getRegionFullName = (region: Region) => {
    const { type, code, name } = region;
    const chainRegion = getChainRegionType(region);

    if (chainRegion) {
        return chainRegion;
    }

    const regionType = type ? `${type.toUpperCase()},` : '';

    return `${regionType} ${code} - ${name}`;
};
