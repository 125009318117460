import { AnalyzePoi } from 'features/mobile-report/components/analyze-page/hooks/use-go-to-analyze';
import { BackendFilter, BaseEntity, BaseEntityFilter } from 'features/insights/types/filter-types';
import { useImmutableParams } from 'hooks/use-query/use-query';
import { EntityURLParams } from 'features/insights/types/url-types';
import { getCompetitorsFromURL } from 'features/insights/hooks/use-fetch-report/get-competitors-from-url';
import { useHistory } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { shouldRenderMobileApp } from 'utils/detect-environment';
import { getPermissions } from 'core/flow-control';
import { APP_CONSTS } from 'core/constants/app-consts';
import { BackendAttributeFilter } from '@placer-ui/types';

/*
    Hooks to get entities new filters based on the current search filters in the URL and a received set of filters.
*/

interface GetNewFiltersProps {
    entities: BaseEntity[] | AnalyzePoi[];
    searchFilters: BackendFilter[];
    poiFilters: (BackendFilter | BaseEntityFilter)[];
}
export const useGetNewFiltersDesktop = () => {
    const history = useHistory();
    const params = useImmutableParams<EntityURLParams>();
    const { enable_visit_frequency_filter_on_property } = getPermissions();

    const urlIds = useMemo(() => {
        const search = new URLSearchParams(history.location.search);
        const searchCompetitorsIds =
            getCompetitorsFromURL(search)?.map((competitor) => competitor.id) ?? [];
        return [params.id, ...searchCompetitorsIds];
    }, [params.id, history.location.search]);

    return useCallback(
        ({ entities, searchFilters, poiFilters }: GetNewFiltersProps) => {
            const visitFrequencyAttr = (
                (poiFilters[0]?.attributes || []) as BackendAttributeFilter[]
            ).filter(
                (attr: BackendAttributeFilter) =>
                    typeof attr !== 'string' &&
                    attr.includes(APP_CONSTS.filter.attributions.keys.VISIT_FREQUENCY),
            );
            return entities.map((entity, index) => {
                const urlIndex = urlIds.indexOf(entity.id);
                if (
                    poiFilters[index] &&
                    !poiFilters[index].attributes &&
                    visitFrequencyAttr.length &&
                    enable_visit_frequency_filter_on_property
                ) {
                    // for new entity added from compare bar there are no attributes.
                    return {
                        ...poiFilters[index],
                        attributes: ['all', ...visitFrequencyAttr],
                    };
                }
                // If a new filter for the poi is given, use it. Otherwise, use the search filter.
                return poiFilters[index] ? poiFilters[index] : searchFilters[urlIndex];
            });
        },
        [enable_visit_frequency_filter_on_property, urlIds],
    );
};

export const getNewFiltersMobile = ({
    entities,
    searchFilters,
    poiFilters,
}: GetNewFiltersProps) => {
    return entities.map((_, index) => {
        const newFilter = searchFilters[0];

        if (poiFilters[index]?.config) {
            newFilter.config = poiFilters[index].config;
            return newFilter;
        } else {
            const { config: _, ...rest } = newFilter;
            return rest;
        }
    });
};

export const useGetNewFilters = () => {
    const getDesktopNewFilters = useGetNewFiltersDesktop();
    return shouldRenderMobileApp() ? getNewFiltersMobile : getDesktopNewFilters;
};
