/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

import { IconProps } from 'components/assets/Icons/Icons';

const HasPolygonBadgeSvg = (props: CustomIconComponentProps | SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 14 16">
        <g fill="none" fillRule="evenodd">
            <path
                stroke="#55B18E"
                strokeWidth=".75"
                d="M7 .402L.375 2.94v4.914c-.002 1.902.407 3.309 2.016 4.914C3.463 13.838 4.977 14.79 7 15.597c2.023-.807 3.537-1.76 4.609-2.829 1.609-1.605 2.018-3.012 2.016-4.914V2.94L7 .402z"
            />
            <path
                fill="#55B18E"
                fillRule="nonzero"
                d="M6.13 11V8.596h.668c.811 0 1.437-.179 1.876-.536.44-.357.66-.872.66-1.542 0-.64-.2-1.122-.6-1.45-.4-.329-1.002-.493-1.804-.493H5.08V11h1.05zm.558-3.287H6.13V5.454h.73c.477 0 .828.09 1.054.273.226.181.338.457.338.826 0 .398-.126.691-.377.879-.252.187-.648.28-1.187.28z"
            />
        </g>
    </svg>
);

export const HasPolygonBadgeIcon = (props: IconProps) => (
    <Icon component={HasPolygonBadgeSvg} {...props} />
);
