import React from 'react';

import { UpgradeYourPlanDialog } from 'extensions/upgrade-your-plan-dialog';
import { SearchOptionsDialog } from 'components/search-options-dialog/search-options-dialog';
import { useLockTabletOrientation } from 'hooks/use-lock-tablet-orientation/use-lock-tablet-orientation';
import { MarketplaceSidebar } from 'features/marketplace/components/marketplace-sidebar/marketplace-sidebar';
import { useSetResponsiveness } from 'hooks/use-set-responsiveness/use-set-responsiveness';
import { useFetchExperianMosaicData } from 'features/general-and-initializers/hooks/use-experian-mosaic-actions';
import { useFetchShortVisitsConfigAction } from 'features/general-and-initializers/hooks/use-fetch-short-visits-config-action';
import { useHideHubspotPopup } from 'features/general-and-initializers/hooks/use-hide-hubspot-popup';
import { useCombineCategoriesAndChains } from 'features/general-and-initializers/hooks/use-combine-categories-and-chains';

const SignedInGeneralAndInitializers = () => {
    useLockTabletOrientation();
    useSetResponsiveness();

    // use-effects
    useFetchExperianMosaicData();
    useFetchShortVisitsConfigAction();
    useHideHubspotPopup();
    useCombineCategoriesAndChains();

    return (
        <>
            <UpgradeYourPlanDialog />
            <SearchOptionsDialog />
            <MarketplaceSidebar />
        </>
    );
};

export const SignedInGeneralAndInitializersMemo = React.memo(SignedInGeneralAndInitializers);
SignedInGeneralAndInitializersMemo.displayName = 'MemoizedSignedInGeneralAndInitializers - memo';
