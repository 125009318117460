import React from 'react';
import Highlighter from 'react-highlight-words';
import classNames from 'classnames';

import styles from './starred-reports-item.module.scss';
import { isPropertyReport, StarredReport } from 'core/entities';
import { ChainIcon, VenueIcon } from 'components/assets/Icons/Icons';

type StarredReportItemProps = {
    report: StarredReport;
    termToHighlight?: string;
};

export const StarredReportsItem = ({
    report,
    termToHighlight = '',
}: StarredReportItemProps) => {
    const { name } = report;
    const Icon = getIcon(report);
    const nameClassName = classNames(styles.reportName, {
        [styles.noSearch]: !termToHighlight,
    });

    return (
        <div className={styles.itemContentWrapper}>
            <Icon className={styles.poiIcon} />
            <div className={styles.reportDetailsWrapper}>
                <Highlighter
                    className={nameClassName}
                    highlightClassName={styles.reportNameTermMatchingPiece}
                    searchWords={[termToHighlight]}
                    autoEscape={true}
                    textToHighlight={name}
                />
                <p className={styles.lastViewed}>
                    Last opened {report.last_viewed_date}
                </p>
            </div>
        </div>
    );
};

function getIcon(report: StarredReport) {
    if (isPropertyReport(report)) {
        return VenueIcon;
    }

    return ChainIcon;
}
