import React, { SVGProps } from 'react';

/* eslint-disable max-len */
export type TrafficPinSmallIconSvgUniqueProps = {
    width?: number | string;
    height?: number | string;
    color?: string;
};

type TrafficPinSmallIconSvgProps = SVGProps<SVGSVGElement> & TrafficPinSmallIconSvgUniqueProps;

export const TrafficPinSmallIconSvg = ({
    width = 12,
    height = 12,
    color = 'var(--basic-white)',
    ...props
}: TrafficPinSmallIconSvgProps) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#clip0_2026_12087)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.451 5.77519C2.4808 5.69952 2.58424 5.68881 2.63121 5.7552C2.72444 5.88697 2.82615 6.01725 2.9363 6.14666C3.57262 6.89437 5.55032 8.64758 5.93462 8.98647C5.97226 9.01966 6.02713 9.01966 6.06477 8.98647C6.44907 8.64758 8.42677 6.89437 9.0631 6.14666C9.12537 6.07357 9.18494 6.0002 9.24181 5.92643C9.34069 5.79819 9.54749 5.82042 9.60723 5.97094L12 12H0L2.451 5.77519ZM6.5 9.49214V10.5273H5.5V9.49214H6.5ZM5.5 12V11.0039L6.5 11.0039L6.5 12L5.5 12Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.37874 3.52664C9.37874 1.66473 7.86909 0.154541 6.00664 0.154541H5.99319C4.13074 0.154541 2.62109 1.66473 2.62109 3.52664C2.62109 4.39629 2.91528 5.10513 3.49343 5.78439C4.008 6.38903 5.59472 7.79736 5.93498 8.0976C5.97261 8.13081 6.02669 8.13081 6.06432 8.0976C6.40458 7.79736 7.9913 6.38903 8.50587 5.78439C9.08456 5.10513 9.37874 4.39629 9.37874 3.52664ZM5.99968 4.41057C6.66252 4.41057 7.19993 3.87317 7.19993 3.21032C7.19993 2.54797 6.66252 2.01007 5.99968 2.01007C5.33733 2.01007 4.79993 2.54797 4.79993 3.21032C4.79993 3.87317 5.33733 4.41057 5.99968 4.41057Z"
                fill={color}
            />
        </g>
        <defs>
            <clipPath id="clip0_2026_12087">
                <rect width="12" height="12" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
