import React from 'react';
import { SgFinanceIcon } from 'features/onboarding/solutions-guide/icons/sg-finance-icon';
import { CHAINS_ROUTE } from 'features/onboarding/solutions-guide/consts/constants';

export const FINANCE_SOLUTIONS_GUIDE = {
    icon: <SgFinanceIcon />,
    solutions: [
        {
            title: 'Performance Monitoring',
            description:
                'Explore transaction growth and ground-level store performance ' +
                'with near real-time foot traffic and visitation metrics.',
            url: CHAINS_ROUTE,
        },
        {
            title: 'Consumer Insights',
            description:
                'Uncover chain-level demographic & psychographics insights, ' +
                'and see how these characteristics change over time.',
            // eslint-disable-next-line max-len
            url: '/chains/demographics/?state=%5B%7B"id":"58ef64a9173f5601f82d8f0e","date_start":"2024-06-01","date_end":"2024-06-30"%7D%5D',
        },
        {
            title: 'Market Trends',
            description:
                'Find out whether a chain’s performance is indicative of a ' +
                'specific strategy or whether it’s part of an overall sector shift.',
            url: '/labs/industry-trends',
        },
        {
            title: 'Store Cohort Analysis',
            description:
                'Analyze same store performance over time, or group stores, ' +
                'to analyze remodels, new product launches, promotions, and more.',
            url: '/insights/venues/31881113691d62f766df77da/overview',
        },
        {
            title: 'M&A Analysis',
            description:
                'Assess cannibalization risk and customer overlap at the ' +
                'store level for the most accurate and granular insights possible.',
            url: '/insights/venues/31881113691d62f766df77da/trade-area',
        },
    ],
};
