import * as Sentry from '@sentry/react';
import { ComponentType } from 'react';
import { SentryPayloadParseError } from 'shared/core/exceptions/exception-handler/payload-parse-error';
import { UnknownError } from 'shared/core/exceptions/exception-handler/unknown-error';

type EventExceptionEventTypes = 'click' | 'submit';

type BaseExceptionMetadata = {
    level?: Sentry.SeverityLevel;
    payload?: Record<string, any>;
    message?: string;
};
type FetchExceptionMetadata = BaseExceptionMetadata & {
    target_url: string;
    method: string;
    status_code?: number;
};
type EventExceptionMetadata = BaseExceptionMetadata & {
    target_element: string;
    event_type: EventExceptionEventTypes;
};

type ExceptionMetadata = BaseExceptionMetadata | FetchExceptionMetadata | EventExceptionMetadata;

export const reportException = (error: unknown, reportData?: ExceptionMetadata) => {
    const exception = castErrorObj(error);
    Sentry.withScope((scope) => {
        addTagsToScope(scope, error, reportData);
        reportData?.level && scope.setLevel(reportData?.level);
        reportData?.message && scope.setTransactionName(reportData.message);
        Sentry.captureException(exception);
    });
};

const addTagsToScope = (scope: Sentry.Scope, error: unknown, reportData?: ExceptionMetadata) => {
    const errorPayload = error instanceof Error ? { ...error } : {};
    const payload = {
        ...errorPayload,
        ...reportData?.payload,
    };

    try {
        for (const [tagKey, tagContent] of Object.entries(payload)) {
            if (tagKey !== 'Authorization') {
                scope.setTag(tagKey, JSON.stringify(tagContent));
            }
        }
    } catch (error) {
        const options: ErrorOptions = {};
        if (error instanceof Error) {
            options.cause = error;
        }
        Sentry.captureException(
            new SentryPayloadParseError(options),
            { contexts: { payload } },
        );
    }
};

const castErrorObj = (error: unknown) => {
    const isErrorInstance = error instanceof Error;
    const isValidErrorObj = !!error && !!(error as any).message && !!(error as any).name;
    if (!isErrorInstance && !isValidErrorObj) {
        return new UnknownError();
    }
    return error;
};

export const getSentryElementProfiler = (classElement: ComponentType<Record<string, any>>) => {
    return Sentry.withProfiler(classElement);
};
