import React, {
    createContext,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import {
    ElementRefObjects,
    ElementRefs,
    MenuItemConfig,
    PoiSelectionMapConfiguration,
    OverlayPoiObject,
    PlacePois,
    PoiSelectionContextState,
    PoiSelectionMenuItem,
    ReportDates,
    ResultsPagination,
    SetPoisListProps,
    SharedPoiSelectionState,
} from 'features/poi-selection-map/types/poi-selection-types';
import { initialPoiSelectionContextState } from 'features/poi-selection-map/context/initial-state';
import { PoiSelectionConfiguration } from 'features/poi-selection-map/configuration/poi-selection-configuration';
import type { ConfigurationCategoriesData, Dictionary } from '@placer-ui/types';
import { PlaceOverlay } from 'ui-components/google-map-layers/types/google-map-layers-types';
import { TrackingContext } from 'tracking/context';
import { usePoiSelectionTrackingContent } from 'features/poi-selection-map/hooks/use-poi-selection-tracking-hooks';
import type { ResponseStatus } from 'API/common/types';

export const PoiSelectionStateContext = createContext<PoiSelectionContextState>(
    initialPoiSelectionContextState,
);

export const usePoiSelectionContext = () => {
    return useContext(PoiSelectionStateContext);
};

const {
    activeMenuItemId: initialMenuItemId,
    poiActiveId: initialPoiActiveId,
    poiHoverId: initialPoiHoverId,
    sharedState: initialSharedState,
    poisList: initialPoisList,
    additionalPois: initialAdditionalPoisList,
    overlayPois: initialOverlayPois,
    enclosingComplexes: initialEnclosingComplexes,
    configuration: initialConfiguration,
    elementRefObjects: initialElementRefObjects,
    mapConfig: initialMapConfig,
    resultsPagination: initialResultsPagination,
} = initialPoiSelectionContextState;

type PoiSelectionContextProps = PropsWithChildren<{
    reportDates: ReportDates;
    visible: boolean;
    configuration: PoiSelectionConfiguration;
    categories?: ConfigurationCategoriesData[];
    excludedSubcategories?: string[];
}>;

export const PoiSelectionContext = ({
    children,
    reportDates: dates,
    visible,
    configuration: configProps,
    categories,
    excludedSubcategories,
}: PoiSelectionContextProps) => {
    const [poiHoverId, setPoiHoverId] = useState<string | undefined>(initialPoiHoverId);
    const [activeMenuItemId, setActiveMenuItemId] = useState<string>(initialMenuItemId);
    const [poiActiveId, setPoiActiveId] = useState<string | undefined>(initialPoiActiveId);
    const [sharedState, setSharedState] = useState<SharedPoiSelectionState>(initialSharedState);
    const [poisList, setPoisList] = useState<PlacePois | undefined>(initialPoisList);
    const [additionalPois, setAdditionalPoisList] = useState<PlacePois | undefined>(
        initialAdditionalPoisList,
    );
    const [enclosingComplexes, setEnclosingComplexes] = useState<
        Dictionary<PlaceOverlay[]> | undefined
    >(initialEnclosingComplexes);
    const [mapConfig, setMapConfig] = useState<Partial<PoiSelectionMapConfiguration> | undefined>(
        initialMapConfig,
    );
    const [reportAccessError, setReportAccessError] =
        useState<Dictionary<ResponseStatus | undefined>>({});
    const [isReportAccessLoading, setIsReportAccessLoading] = useState(false);
    const [elementRefObjects, setElementRefObjects] =
        useState<ElementRefs>(initialElementRefObjects);
    const [resultsPagination, setResultsPagination] =
        useState<ResultsPagination>(initialResultsPagination);
    const currentItemConfiguration = useRef<MenuItemConfig | undefined>(undefined);
    const reportDates = useRef<ReportDates>(dates);
    const mapRef = useRef<google.maps.Map | undefined>(undefined);
    const visibleRef = useRef<boolean>(visible);
    const overlayPois = useRef<Dictionary<PlaceOverlay[]>>(initialOverlayPois);
    const poiSelectionTrackingContent = usePoiSelectionTrackingContent();

    const onSetElementRefObject = (object: ElementRefObjects) => {
        elementRefObjects[object.key] = object;
        setElementRefObjects(elementRefObjects);
    };

    const setMapRef = (map?: google.maps.Map) => {
        mapRef.current = map;
    };

    const setCurrentItemConfiguration = (currentItem: PoiSelectionMenuItem) => {
        const { config, id } = currentItem;
        currentItemConfiguration.current = {
            id,
            config,
        };
    };

    useEffect(() => {
        visibleRef.current = visible;
    }, [visible]);

    const onPoisListSet = useCallback(({ pois, menuItemId }: SetPoisListProps) => {
        if (visibleRef.current && currentItemConfiguration.current) {
            const { id: currentItemId } = currentItemConfiguration.current;
            if (Array.isArray(menuItemId) && !menuItemId.includes(currentItemId)) {
                return;
            }
            if (typeof menuItemId === typeof String() && menuItemId !== currentItemId) {
                return;
            }
            setPoisList(pois);
        }
    }, []);

    const poisListPerCurrentPage = useMemo(() => {
        const { currentPage, resultsPerPage, totalResults } = resultsPagination;
        if (!poisList) return undefined;
        if (!totalResults) return poisList;
        const start = currentPage * resultsPerPage;
        const end = start + resultsPerPage;
        return poisList.slice(start, end);
    }, [poisList, resultsPagination]);

    const aggregatedPoisListPerPage = useMemo<PlacePois | undefined>(() => {
        if (!poisListPerCurrentPage && !additionalPois) return undefined;
        return [...(additionalPois || []), ...(poisListPerCurrentPage || [])];
    }, [additionalPois, poisListPerCurrentPage]);

    const clearPoisList = useCallback((clearAll?: boolean) => {
        setPoisList(initialPoisList);
        setAdditionalPoisList(initialAdditionalPoisList);
        setMapConfig(initialMapConfig);
        setPoiActiveId(initialPoiActiveId);
        setPoiHoverId(initialPoiHoverId);
        setOverlayPoisList([]);
        setResultsPagination(initialResultsPagination);
        clearAll && setElementRefObjects(initialElementRefObjects);
    }, []);

    const onMenuItemIdSelected = useCallback(
        (itemId: string) => {
            if (activeMenuItemId !== itemId) {
                clearPoisList();
                setActiveMenuItemId(itemId);
            }
        },
        [activeMenuItemId, clearPoisList],
    );

    const setOverlayPoisList = (
        overlayPoisList: OverlayPoiObject[],
        addToExistingList: boolean = false,
    ) => {
        const newOverlayPois = overlayPoisList.reduce<Dictionary<PlaceOverlay[]>>(
            (acc, { sourcePoi, overlayPoi }) => {
                acc[sourcePoi] = [overlayPoi];
                return acc;
            },
            {},
        );
        overlayPois.current = addToExistingList
            ? {
                  ...overlayPois.current,
                  ...newOverlayPois,
              }
            : newOverlayPois;
    };

    const setOverlayPoi = ({ sourcePoi, overlayPoi }: OverlayPoiObject) => {
        overlayPois.current[sourcePoi] = [overlayPoi];
    };

    const configuration: PoiSelectionConfiguration = {
        ...initialConfiguration,
        ...configProps,
    };

    const state: PoiSelectionContextState = {
        categories,
        excludedSubcategories,
        activeMenuItemId,
        setMenuItemId: onMenuItemIdSelected,
        poiActiveId,
        poiHoverId,
        setPoiActiveId,
        setPoiHoverId,
        sharedState,
        setSharedState,
        reportDates: reportDates.current,
        poisList,
        poisListPerCurrentPage: aggregatedPoisListPerPage,
        setPoisList: onPoisListSet,
        additionalPois,
        setAdditionalPoisList,
        overlayPois: overlayPois.current,
        setOverlayPoisList,
        setOverlayPoi,
        enclosingComplexes,
        setEnclosingComplexes,
        elementRefObjects,
        setElementRefObjects: onSetElementRefObject,
        clearPoisList,
        mapRef: mapRef.current,
        setMapRef,
        mapConfig,
        setMapConfig,
        isContentVisible: visible,
        configuration,
        currentItemConfiguration: currentItemConfiguration.current,
        setCurrentItemConfiguration,
        resultsPagination,
        setResultsPagination,
        reportAccessError,
        setReportAccessError,
        isReportAccessLoading,
        setIsReportAccessLoading,
    };

    return (
        <PoiSelectionStateContext.Provider value={state}>
            <TrackingContext.Provider value={poiSelectionTrackingContent}>
                {children}
            </TrackingContext.Provider>
        </PoiSelectionStateContext.Provider>
    );
};
