import { useEffect, useRef } from 'react';
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { BatchSpanProcessor } from '@opentelemetry/sdk-trace-base';
import { FetchInstrumentation } from '@opentelemetry/instrumentation-fetch';
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { diag, DiagConsoleLogger, DiagLogLevel, trace } from '@opentelemetry/api';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { userCookieStorage } from 'core/authentification';
import { CompositePropagator, W3CTraceContextPropagator } from '@opentelemetry/core';
import { JaegerPropagator } from '@opentelemetry/propagator-jaeger';
import { getFeatureFlags } from 'core/flow-control';
import { Resource } from '@opentelemetry/resources';
import { isBNMAutomationMachine, isLocalhost } from 'utils/detect-environment';

const useOpenTelemetry = () => {
    const token = userCookieStorage.token;
    const providerRef = useRef<WebTracerProvider | null>(null);
    const { enable_opentelemetry_fe_dashboard_ui_ff } = getFeatureFlags();

    useEffect(() => {
        // localhost causes CORS issue
        if (
            token &&
            enable_opentelemetry_fe_dashboard_ui_ff &&
            !isLocalhost &&
            !isBNMAutomationMachine
        ) {
            // Enable OpenTelemetry logging for debugging purposes
            diag.setLogger(new DiagConsoleLogger(), DiagLogLevel.DEBUG);

            const provider = new WebTracerProvider({
                resource: new Resource({
                    'service.name': 'analytics-dashboard',
                }),
            });

            const collectorExporter = new OTLPTraceExporter({
                headers: {
                    authorization: `Token ${token}`,
                },
                url: process.env.REACT_APP_TRACING_OTEL_COLECTOR,
            });

            provider.addSpanProcessor(new BatchSpanProcessor(collectorExporter));

            provider.register({
                propagator: new CompositePropagator({
                    propagators: [new JaegerPropagator(), new W3CTraceContextPropagator()],
                }),
            });

            const urlsRegex = /(placer\.ai|placer\.team)(\/2\/guru\/|\/eset\/|\/ranking\/)/;
            registerInstrumentations({
                instrumentations: [
                    new FetchInstrumentation({
                        propagateTraceHeaderCorsUrls: [urlsRegex],
                    }),
                    new XMLHttpRequestInstrumentation({
                        propagateTraceHeaderCorsUrls: [urlsRegex],
                    }),
                ],
            });

            providerRef.current = provider;
        }

        // Clean up function to unregister the provider if needed
        return () => {
            if (providerRef.current) {
                providerRef.current.shutdown();
            }
        };
    }, [enable_opentelemetry_fe_dashboard_ui_ff, token]);
};

export const startOpenTelemetrySpan = (spanName: string) => {
    const { enable_fe_open_telemetry_spans_measurement_FF } = getFeatureFlags();

    if (!enable_fe_open_telemetry_spans_measurement_FF) return null;

    const tracer = trace.getTracer('default');
    const span = tracer.startSpan(spanName);

    if (!span.isRecording()) {
        span.end();
        return null;
    }

    return span;
};

export default useOpenTelemetry;
