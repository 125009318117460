import { telemetryApiService } from 'API/telemetry-api/telemetry-api';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { EVENT_TRIGGER } from '../types';

const pathsToMeasure: ReadonlyArray<string> = ['overview'];

/**
 * This hook is used to measure the time it takes for the components in the page to load.
 * It is designed to be implemented in first rendered component.
 * Desktop component: <Header />
 * Mobile component: None (not used in mobile currently)
 */
export function useMonitorPageLoading() {
    const { pathname } = useLocation();
    const triggered = useRef<boolean>(false);

    useEffect(() => {
        if (shouldReportTelemetry(pathname, triggered.current)) {
            reportTelemetry();
            triggered.current = true;
        }
    }, [pathname]);
}

function shouldReportTelemetry(pathname: string, triggered: boolean): boolean {
    return !triggered && pathsToMeasure.some((path) => pathname.includes(path));
}

function reportTelemetry() {
    telemetryApiService.reportEndEvents(); // Collcting initialLoadingTime that is set in index.html
    telemetryApiService.reportStartEvent(EVENT_TRIGGER.PAGE_CONTENT_LOADING_TIME);
}
