/* eslint-disable max-len */
import React, { useMemo, useState } from 'react';
import styles from './methods-selector-content.module.scss';
import { MethodCard } from 'features/void-analysis/sections/empty-space/components/method-selection/method-selector-modal/components/method-card/method-card';
import {
    FooterMenu,
    RunVoidReport,
} from 'features/void-analysis/sections/empty-space/components/method-selection/method-selector-modal/components/footer-menu/footer-menu';
import {
    ElevioData,
    NearbyEntityOption,
    SelectorModalData,
} from 'features/void-analysis/sections/empty-space/types/methods-selector-modal-types';
import classNames from 'classnames';
import checkMark from 'shared/images/void-analysis/empty-space/white-check-mark.png';
import {
    useGetDefaultMethodNameAndNearbyOption,
    useGetCustomMethodsAndNearbyOption,
} from 'features/void-analysis/sections/empty-space/hooks/use-method-selector-modal-hooks';
import { CardMethodNames } from 'features/void-analysis/sections/empty-space/types/method-card';

type MethodsSelectorContentProp = {
    selectorData: SelectorModalData;
    elevioData: ElevioData;
    onRunVoidReport: RunVoidReport;
};

export const MethodsSelectorContent = ({
    selectorData,
    elevioData,
    onRunVoidReport,
}: MethodsSelectorContentProp) => {
    const { methods, name, locationRequest, default: defaultMethod } = selectorData;
    const getDefaultMethodNameAndNearbyOption = useGetDefaultMethodNameAndNearbyOption();
    const selectedDefaultMethod = useMemo(() => {
        return getDefaultMethodNameAndNearbyOption(methods, defaultMethod);
    }, [defaultMethod, getDefaultMethodNameAndNearbyOption, methods]);

    const getCustomMethodAndNearbyOption = useGetCustomMethodsAndNearbyOption();

    const customMethods = useMemo(() => {
        return getCustomMethodAndNearbyOption(methods);
    }, [getCustomMethodAndNearbyOption, methods]);

    const [methodSelected, setMethodSelected] = useState<CardMethodNames>(
        selectedDefaultMethod.methodName,
    );
    const [nearByEntitySelect, setNearByEntitySelect] = useState<NearbyEntityOption | undefined>(
        selectedDefaultMethod.nearbyEntityOption,
    );

    const MethodCards = useMemo(
        () =>
            customMethods.map((method) => {
                const currentSelectedMethod = method.name === methodSelected;
                return (
                    <MethodCard
                        key={method.name}
                        className={classNames(styles.methodCard, {
                            [styles.selectedMethodCard]: currentSelectedMethod,
                        })}
                        method={method}
                        onSelectMethod={setMethodSelected}
                        entityName={name}
                        locationData={
                            method.name === 'tta_nearby_combination' ? locationRequest : undefined
                        }
                        setNearByEntitySelect={setNearByEntitySelect}
                        elevioDataId={
                            elevioData[
                                method.name === 'tta_nearby_combination'
                                    ? 'nearby_entity_ta'
                                    : method.name
                            ]
                        }
                    >
                        {currentSelectedMethod && (
                            <img className={styles.icon} src={checkMark} alt={'checkMark'} />
                        )}
                    </MethodCard>
                );
            }),
        [customMethods, methodSelected, name, locationRequest, elevioData],
    );

    return (
        <div>
            <div className={styles.methods}>{MethodCards}</div>
            <FooterMenu
                methodSelected={methodSelected}
                nearByEntitySelected={nearByEntitySelect}
                locationRequest={locationRequest}
                onRunVoidReport={onRunVoidReport}
            />
        </div>
    );
};
