import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

import { IconProps } from 'components/assets/Icons/Icons';

/* eslint-disable max-len */
export const GrayLockIconSvg = (props: CustomIconComponentProps | SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
    >
        <path
            d="M1 8.5C1 7.67157 1.67157 7 2.5 7H13.5C14.3284 7 15 7.67157 15 8.5V14.5C15 15.3284 14.3284 16 13.5 16H2.5C1.67157 16 1 15.3284 1 14.5V8.5Z"
            fill="#D0D0D0"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.625 4.5C3.625 2.08389 5.58389 0.125 8 0.125C10.4161 0.125 12.375 2.08389 12.375 4.5V7.5H9.625V4.5C9.625 3.60311 8.89689 2.875 8 2.875C7.10311 2.875 6.375 3.60311 6.375 4.5V7.5H3.625V4.5ZM8 0.875C5.99811 0.875 4.375 2.49811 4.375 4.5V7.125H5.625V4.5C5.625 3.18889 6.68889 2.125 8 2.125C9.31111 2.125 10.375 3.18889 10.375 4.5V7.125H11.625V4.5C11.625 2.49811 10.0019 0.875 8 0.875Z"
            fill="#49565D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.5 7.75H2.5C2.08579 7.75 1.75 8.08579 1.75 8.5V14.5C1.75 14.9142 2.08579 15.25 2.5 15.25H13.5C13.9142 15.25 14.25 14.9142 14.25 14.5V8.5C14.25 8.08579 13.9142 7.75 13.5 7.75ZM2.5 7C1.67157 7 1 7.67157 1 8.5V14.5C1 15.3284 1.67157 16 2.5 16H13.5C14.3284 16 15 15.3284 15 14.5V8.5C15 7.67157 14.3284 7 13.5 7H2.5Z"
            fill="#49565D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.625 9.5C10.625 9.29289 10.7929 9.125 11 9.125H14.5C14.7071 9.125 14.875 9.29289 14.875 9.5C14.875 9.70711 14.7071 9.875 14.5 9.875H11C10.7929 9.875 10.625 9.70711 10.625 9.5Z"
            fill="#49565D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.625 11.5C10.625 11.2929 10.7929 11.125 11 11.125H14.5C14.7071 11.125 14.875 11.2929 14.875 11.5C14.875 11.7071 14.7071 11.875 14.5 11.875H11C10.7929 11.875 10.625 11.7071 10.625 11.5Z"
            fill="#49565D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.625 13.5C10.625 13.2929 10.7929 13.125 11 13.125H14.5C14.7071 13.125 14.875 13.2929 14.875 13.5C14.875 13.7071 14.7071 13.875 14.5 13.875H11C10.7929 13.875 10.625 13.7071 10.625 13.5Z"
            fill="#49565D"
        />
    </svg>
);

export const GrayLockIcon = (props: IconProps) => <Icon component={GrayLockIconSvg} {...props} />;
