export const ALL_CATEGORY = 'All';

export const All_CATEGORIES = 'All Categories';

export const defaultEventData = {
    component: 'Tenants Overview',
};

export const tenantsOverviewApiId = 'tenantsOverviewWidgetApi';

export const tenantsOverviewSalesMetricsApiId = 'tenantsOverviewSalesMetricsWidgetApi';
