/* eslint-disable max-len */
import React from 'react';

export const SgCREIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
        >
            <path
                d="M13.334 19.9992V5.55566H20.0007V20.0003H13.334L13.334 19.9992Z"
                fill="#E1E2FF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.000488281 19.9992H8.8895V20.0003H20.0008V5.55564H13.334V0H0.000488281V19.9992ZM13.334 6.43565V19.1203H19.1208V6.43565H13.334ZM0.880501 0.880013H12.454V19.1192H0.880501V0.880013Z"
                fill="#676DFD"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.22274 5.00007C2.22274 5.3069 2.47148 5.55564 2.77831 5.55564H5.00056C5.30739 5.55564 5.55612 5.3069 5.55612 5.00007V2.77782C5.55612 2.47099 5.30739 2.22225 5.00056 2.22225H2.77831C2.47148 2.22225 2.22274 2.47099 2.22274 2.77782V5.00007ZM3.10276 4.67562H4.67611V3.10227H3.10276V4.67562Z"
                fill="#676DFD"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.77838 5.00007C7.77838 5.3069 8.02711 5.55564 8.33394 5.55564H10.5562C10.863 5.55564 11.1118 5.3069 11.1118 5.00007V2.77782C11.1118 2.47099 10.863 2.22225 10.5562 2.22225H8.33394C8.02711 2.22225 7.77838 2.47099 7.77838 2.77782V5.00007ZM8.65839 4.67562H10.2317V3.10227H8.65839V4.67562Z"
                fill="#676DFD"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.77838 10.5557C7.77838 10.8625 8.02711 11.1113 8.33394 11.1113H10.5562C10.863 11.1113 11.1118 10.8625 11.1118 10.5557V8.33345C11.1118 8.02662 10.863 7.77789 10.5562 7.77789H8.33394C8.02711 7.77789 7.77838 8.02662 7.77838 8.33345V10.5557ZM8.65839 10.2313H10.2317V8.6579H8.65839V10.2313Z"
                fill="#676DFD"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.33394 16.6669C8.02711 16.6669 7.77838 16.4182 7.77838 16.1113V13.8891C7.77838 13.5823 8.02711 13.3335 8.33394 13.3335H10.5562C10.863 13.3335 11.1118 13.5823 11.1118 13.8891V16.1113C11.1118 16.4182 10.863 16.6669 10.5562 16.6669H8.33394ZM10.2317 15.7869H8.65839V14.2135H10.2317V15.7869Z"
                fill="#676DFD"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.77831 11.1113C2.47148 11.1113 2.22274 10.8625 2.22274 10.5557V8.33345C2.22274 8.02662 2.47148 7.77789 2.77831 7.77789H5.00056C5.30739 7.77789 5.55612 8.02662 5.55612 8.33345V10.5557C5.55612 10.8625 5.30739 11.1113 5.00056 11.1113H2.77831ZM4.67611 10.2313H3.10276V8.6579H4.67611V10.2313Z"
                fill="#676DFD"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.22274 16.1113C2.22274 16.4182 2.47148 16.6669 2.77831 16.6669H5.00056C5.30739 16.6669 5.55612 16.4182 5.55612 16.1113V13.8891C5.55612 13.5823 5.30739 13.3335 5.00056 13.3335H2.77831C2.47148 13.3335 2.22274 13.5823 2.22274 13.8891V16.1113ZM3.10276 15.7869H4.67611V14.2135H3.10276V15.7869Z"
                fill="#676DFD"
            />
        </svg>
    );
};
