import { APP_CONSTS } from 'core/constants/app-consts';
import { DateService } from './dateService';
import _ from 'lodash';
import moment from 'moment';
import { PoiDatesRecord } from 'features/insights/types/dates-types';

export class VenueFilter {
    static dates: PoiDatesRecord;

    date: any;
    attributes: any;
    config: any;

    // `dates` is only passed from react vite entry point
    constructor(data: any) {
        this.date = this.makeDateFilter(_.get(data, 'date'));
        this.attributes = this.makeAttributesFilter(_.get(data, 'attributes'));
        this.config = _.get(data, 'config');
    }

    makeDateFilter(dateData: any) {
        const {
            format: { FULL, SHORT_NAME },
            labels: { YEAR_TO_DATE: YEAR_TO_DATE_LABEL },
            keys: { LAST_6_MONTHS },
        } = APP_CONSTS.date;
        if (_.get(dateData, 'start') && _.get(dateData, 'end')) {
            const start = moment(dateData.start);
            const end = moment(dateData.end);

            const startFormatFull = start.format(FULL);
            const endFormatFull = end.format(FULL);

            if (dateData.chosenLabel !== 'Custom Range') {
                const presetByDates = this.getPresetForDates(startFormatFull, endFormatFull);
                const presetByLabels = this.getPresetForLabel(dateData.chosenLabel);
                const preset = presetByLabels ?? presetByDates;
                if (preset) {
                    const date = _.cloneDeep(preset);
                    const presetLabel =
                        dateData.chosenLabel &&
                        dateData.chosenLabel !== preset.name
                            ? preset.name
                            : dateData.chosenLabel ?? preset.name;
                    const name =
                        presetLabel === YEAR_TO_DATE_LABEL
                            ? new DateService(APP_CONSTS).buildFormattedRangeDate({
                                  start,
                                  end,
                              })
                            : date.name;
                    return {
                        ...date,
                        name,
                        chosenLabel: presetLabel,
                    };
                }
            }

            return {
                chosenLabel: dateData.chosenLabel,
                isCustomRange: true,
                key: startFormatFull + '_' + endFormatFull,
                start: startFormatFull,
                end: endFormatFull,
                name: `${start.format(SHORT_NAME)} - ${end.format(SHORT_NAME)}`,
            };
        }

        return VenueFilter.dates[LAST_6_MONTHS];
    }

    getPresetForLabel(label: any) {
        for (let key in VenueFilter.dates) {
            if (VenueFilter.dates[key].name === label) {
                return VenueFilter.dates[key];
            }
        }
    }

    getPresetForDates(start: any, end: any) {
        for (let key in VenueFilter.dates) {
            if (VenueFilter.dates[key].start === start && VenueFilter.dates[key].end === end) {
                return VenueFilter.dates[key];
            }
        }
    }

    makeAttributesFilter(attributesData: any) {
        if (!_.get(attributesData, 'length')) return;

        return attributesData;
    }
}
