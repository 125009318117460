import React, { ReactNode, useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import classnames from 'classnames';
import { noop } from 'lodash/fp';
import { LimitedFeature } from 'extensions/limited-feature/limited-feature';
import { PlacerLink } from 'ui-components/placer-link/placer-link';
import { Tab } from 'ui-components/side-panel/components/side-panel-section';
import styles from './tab-element.module.scss';

type TabElementPropsType = {
    tab: Tab;
    onItemClick?: (option: Tab, event: React.MouseEvent<HTMLDivElement>) => void;
    inheritQueryParams?: boolean;
    children: ReactNode;
};

const useRouteUrl = (path: string | undefined) => {
    const { url } = useRouteMatch();
    const baseUrl = url.match(/^\/([^/]+)/)?.[0] ?? '';
    if (path && !path.includes(baseUrl)) {
        return baseUrl + path;
    }
    return path;
};

export const TabElement = ({
    tab,
    onItemClick = noop,
    children,
    inheritQueryParams = true,
}: TabElementPropsType) => {
    const linkTo = useRouteUrl(tab.to);
    const onClick = useCallback(
        (event: React.MouseEvent<HTMLDivElement>) => {
            // NOTE - the 'Site Selection' condition is a hack for when opening the premium sidebar
            // it will not trigger the `onItemClick` which styles the button active.
            if (tab.disabled || (tab.title === 'Site Selection' && tab.onClick)) {
                return;
            }
            onItemClick(tab, event);
        },
        [tab, onItemClick],
    );

    return (
        <div
            data-testid={`tab-element-${tab.key}`}
            className={classnames(styles.item, {
                [styles.comingSoon]: tab.comingSoon,
            })}
            onClick={onClick}
            onMouseEnter={tab.onMouseEnter}
            onMouseLeave={tab.onMouseLeave}
        >
            <LimitedFeature
                isLimited={tab.isLocked ?? false}
                position="panel"
                className={styles.limited}
            >
                {linkTo && !tab.disabled ? (
                    <PlacerLink
                        to={{ pathname: linkTo }}
                        inherit={inheritQueryParams}
                        className={styles.link}
                    >
                        {children}
                    </PlacerLink>
                ) : (
                    <>{children}</>
                )}
            </LimitedFeature>
        </div>
    );
};
