/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

type ApproveAlertIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type ApproveAlertIconProps = SVGProps<SVGSVGElement> & ApproveAlertIconUniqueProps;

export const ApproveAlertIcon = function ApproveAlertIcon({
    width = 40,
    height = 40,
    ...props
}: ApproveAlertIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect width="40" height="40" rx="3" fill="#F3F3FD" />
            <path
                d="M29.2863 20.0001C29.2863 25.1284 25.1289 29.2858 20.0006 29.2858C14.8722 29.2858 10.7148 25.1284 10.7148 20.0001C10.7148 14.8717 14.8722 10.7144 20.0006 10.7144C25.1289 10.7144 29.2863 14.8717 29.2863 20.0001Z"
                fill="white"
            />
            <path
                d="M23.4579 18.304C23.6608 18.1041 23.6632 17.7774 23.4632 17.5745C23.2633 17.3716 22.9366 17.3692 22.7337 17.5691L18.9077 21.3394L17.2674 19.7234C17.0644 19.5234 16.7378 19.5259 16.5378 19.7289C16.3379 19.9318 16.3403 20.2584 16.5433 20.4584L18.9078 22.7878L23.4579 18.304Z"
                fill="#5E63E5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M29.2863 20.0001C29.2863 25.1284 25.1289 29.2858 20.0006 29.2858C14.8722 29.2858 10.7148 25.1284 10.7148 20.0001C10.7148 14.8717 14.8722 10.7144 20.0006 10.7144C25.1289 10.7144 29.2863 14.8717 29.2863 20.0001ZM28.2545 20.0001C28.2545 24.5586 24.5591 28.254 20.0006 28.254C15.442 28.254 11.7466 24.5586 11.7466 20.0001C11.7466 15.4415 15.442 11.7461 20.0006 11.7461C24.5591 11.7461 28.2545 15.4415 28.2545 20.0001Z"
                fill="#5E63E5"
            />
        </svg>
    );
};
ApproveAlertIcon.__tags = ['alert', 'aprove', 'map'] as string[];
