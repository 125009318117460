import React from 'react';
import classnames from 'classnames';

import styles from './search-box.module.scss';
import { Input } from 'antd';
import { SearchIcon } from 'components/assets/Icons/Icons';

type SearchBoxProps = {
    onChange: (value: string) => void;
    className?: string;
    searchPlaceholder?: string;
    value?: string;
};

export const SearchBox = ({
    onChange,
    className,
    value,
    searchPlaceholder,
    ...restProps
}: SearchBoxProps) => {
    return (
        <div className={classnames(styles.searchBox, className)}>
            <Input
                data-testid="search-box-input"
                onChange={({ target: { value } }) => onChange(value)}
                className={styles.input}
                suffix={<SearchIcon className={styles.searchIcon} />}
                placeholder={searchPlaceholder ?? 'Search...'}
                value={value}
                {...restProps}
            />
        </div>
    );
};
