import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'API';

export const getStarredReports = createAsyncThunk(
    'starred-reports/get-reports',
    async () => {
        const {
            data: { results: reports },
        } = await API.starReport.getReports();

        return reports;
    },
);
