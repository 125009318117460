
import React, { PropsWithChildren } from 'react';
import { ErrorComponentsProps } from 'router/errors/error-types';
import { PlRoute } from 'shared/router';
import { ErrorComponents } from 'router/errors/error-components';

export const ErrorRoutes = ({
    ctaText,
    failBackUrl,
}: PropsWithChildren<ErrorComponentsProps>) => (
    <PlRoute
        path={['/error/type?', '/error', '*']}
        component={() => <ErrorComponents ctaText={ctaText} failBackUrl={failBackUrl} />}
    />
);

