import React from 'react';

import { PlacerIcon } from 'components/assets/Icons/Icons';
import './dialog-header.scss';

type UpgradeYourPlanHeaderPropTypes = {
    screen?: string;
};

export const UpgradeYourPlanHeader = ({ screen = 'default' }: UpgradeYourPlanHeaderPropTypes) => {
    const renderHeading = () => {
        if (screen === 'error') {
            return <h1>Unexpected Error</h1>;
        }

        return <h1>Upgrade Your Plan</h1>;
    };

    return (
        <header className="upgrade-plan-dialog-header">
            <header className="upgrade-plan-dialog-header">
                <PlacerIcon className="placer-logo" />

                {renderHeading()}
            </header>
        </header>
    );
};
