import { useSelectorWithProps } from 'utils/structured-selector/structured-selector';
import { selectHasUserPermission } from 'store/selectors/app-selectors';
import { usePrintContext } from 'features/print-menu/hooks/use-print-context';

export const useEnableCoverPage = () =>
    useSelectorWithProps({ permission: 'enable_pdf_cover_page' }, selectHasUserPermission);

export const useEnableCoverMap = () =>
    useSelectorWithProps({ permission: 'enable_pdf_cover_map' }, selectHasUserPermission);

export const useEnableHeaderFooter = () =>
    useSelectorWithProps({ permission: 'enable_pdf_header_footer' }, selectHasUserPermission);

export const useEnableCustomerLogo = () =>
    useSelectorWithProps({ permission: 'enable_print_customer_logo' }, selectHasUserPermission);

export const useShowHeaderFooterByDefault = () =>
    useSelectorWithProps(
        { permission: 'show_print_header_footer_by_default' },
        selectHasUserPermission,
    );

export const useStartNewPageInLandscape = () => {
    const startNewPageInLandscape = useSelectorWithProps(
        { permission: 'print_always_start_new_page_landscape' },
        selectHasUserPermission,
    );
    const {
        pageConfig: { orientation },
    } = usePrintContext();
    return orientation === 'landscape' && startNewPageInLandscape;
};

export const useEnablePrintWidgetsInteractions = () =>
    useSelectorWithProps(
        { permission: 'enable_customize_screen_interactions' },
        selectHasUserPermission,
    );

export const usePrintPermission = () =>
    useSelectorWithProps({ permission: 'enable_pdf_export_v2' }, selectHasUserPermission);

export const usePrintPersistentSettings = () =>
    useSelectorWithProps(
        { permission: 'enable_customize_print_persistent_settings' },
        selectHasUserPermission,
    );

export const usePrintEntityTogglePermission = () =>
    useSelectorWithProps(
        { permission: 'enable_entity_toggle_customized_print_ff' },
        selectHasUserPermission,
    );

export const useEnableCustomFiltersOnCoverPage = () =>
    useSelectorWithProps(
        { permission: 'enable_customized_export_filters_in_cover_ff' },
        selectHasUserPermission,
    );

export const useEnableClickOnEntireWidgetToCustomize = () => {
    const enableClickToCustomize = useSelectorWithProps(
        { permission: 'enable_customized_screen_server_side_print_ff' },
        selectHasUserPermission,
    );
    const enableServerSideFlow = useShouldEnableServerSidePrintFlow();

    return enableClickToCustomize && enableServerSideFlow;
};

export const useShowDoneButtonInCustomize = () =>
    useSelectorWithProps(
        { permission: 'enable_customize_level2_done_button_ff' },
        selectHasUserPermission,
    );

export const useShouldEnableServerSidePrintFlow = () => {
    const { serverSideRender } = usePrintContext();
    return serverSideRender;
};

export const useCanViewPrintRouteDebugger = () =>
    useSelectorWithProps({ permission: 'enable_print_route_debugger' }, selectHasUserPermission);

export const useEnableRenderNonMapWidget = () =>
    useSelectorWithProps(
        { permission: 'server_side_print_render_non_map_widgets_ff' },
        selectHasUserPermission,
    );
