import { computed, signal } from '@preact/signals-react';
import type {
    FullPageWidgetType,
    PoiWithDistance,
    TempListItem,
    WidgetDrawerType,
    EntitiesListProps,
} from 'features/mobile-report/types/types';
import type { MLFilterType } from 'features/mobile-report/components/market-landscape-mobile/filters/filters';
import type {
    RankingIndexFetchFilters,
    RankingIndexVisualFilters,
} from 'features/insights/widgets/rankings/ranking-index/types';
import type { MobileSectionType } from 'features/insights/types/filter-types';
import { isMobileApp } from 'utils/detect-environment';
import { pinColors } from 'features/mobile-report/utils/utils';

type FullPageWidgetSize = 'fullPage' | 'default';

const DEFAULT_WIDGET_HEADER_HEIGHT = 248;
const DEFAULT_WIDGET_HEADER_HEIGHT_APP = 168;

export const analyzeVenuesSignal = signal<PoiWithDistance[]>([]);
export const analyzeVenuesWithColorsSignal = computed(() =>
    analyzeVenuesSignal.value.map((item, index) => ({
        ...item,
        color: pinColors[index],
    })),
);
export const tempAnalyzeVenuesSignal = signal<TempListItem[] | undefined>(undefined);

export const fullScreenItemSignal = signal<FullPageWidgetType | undefined>(undefined);
export const fullScreenTypeSignal = signal<FullPageWidgetSize | undefined>('default');

export const widgetDrawerTypeSignal = signal<WidgetDrawerType | undefined>(undefined);

export const mlLegendOpenSignal = signal(false);
export const activeMLFiltersSignal = signal<keyof typeof MLFilterType>('none');

export const analyzeFiltersOpenSignal = signal(false);

export const analyzeSearchOpenSignal = signal(false);

export const shouldOpenCompareDrawerSignal = signal(false);

export const analyzeAddToCompareSignal = signal(false);

export const widgetHeaderExpandedSignal = signal(false);

export const widgetHeaderHeightSignal = signal(
    isMobileApp() ? DEFAULT_WIDGET_HEADER_HEIGHT_APP : DEFAULT_WIDGET_HEADER_HEIGHT,
);

export const showAnalyzeBackArrowSignal = signal(true);

export const entitiesListSignal = signal<EntitiesListProps[]>([]);

export const expandedTenantsCardsSignal = signal<string[]>([]);

export const widgetSearchTermSignal = signal('');

export const activeTabSignal = signal('properties');

export const rankingIndexInitialFiltersSignal = signal<
    RankingIndexFetchFilters | RankingIndexVisualFilters | undefined
>(undefined);
export const rankingIndexOpenSignal = signal(false);

export const showAnalyzeMapSignal = signal(false);
export const showOnMapItemSignal = signal<PoiWithDistance | undefined>(undefined);

export const activeFilterSectionSignal = signal<MobileSectionType>('home');
