/* eslint-disable max-len */
import React from 'react';

export const SgRestaurantIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
        >
            <path
                d="M14.9058 5.875C15.4747 3.85551 16.8305 2.18575 17.6959 1.27609C17.9887 0.968212 18.4683 1.1798 18.4683 1.60472V14.0312H14.581C13.5847 14.0312 12.8646 13.0795 13.137 12.1212C13.5878 10.5359 14.2575 8.1762 14.9058 5.875Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.4056 3.70853C16.9763 2.74785 17.4381 2.17657 17.7813 1.8419L17.7063 13.5599H13.7262C13.3951 13.5599 13.1783 13.259 13.2568 12.9664C13.6183 11.6201 14.1475 9.73138 14.7233 7.95054C15.3094 6.13759 15.9117 4.53994 16.4056 3.70853ZM18.4195 1.40573C18.4194 1.40617 18.415 1.407 18.4063 1.4069C18.4152 1.40525 18.4195 1.40529 18.4195 1.40573ZM18.7032 14.0427L18.7884 0.747592C18.7893 0.602491 18.7244 0.459452 18.5831 0.426209C18.1743 0.329987 17.1597 0.481283 15.5459 3.19779C14.4278 5.07985 13.0179 9.99963 12.291 12.7071C12.0383 13.6485 12.7515 14.5599 13.7262 14.5599H17.5562V19.0185C17.5562 19.3362 17.8137 19.5938 18.1314 19.5938C18.4492 19.5938 18.7067 19.3362 18.7067 19.0185V14.1066C18.7067 14.085 18.7056 14.0636 18.7032 14.0427Z"
                fill="#5E63E5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.77018 1.77497C9.73891 1.5006 9.49114 1.30354 9.21678 1.3348C8.94241 1.36607 8.74534 1.61384 8.77661 1.88821L9.3306 6.74896C9.3174 8.25624 8.2143 9.50337 6.77107 9.7406V1.83159C6.77107 1.55545 6.54721 1.33159 6.27107 1.33159C5.99493 1.33159 5.77107 1.55545 5.77107 1.83159V9.7406C4.32475 9.50286 3.22003 8.2509 3.21148 6.73928L3.56964 1.86825C3.58989 1.59286 3.38305 1.35318 3.10765 1.33294C2.83225 1.31269 2.59258 1.51952 2.57233 1.79492L2.21277 6.68494L2.21143 6.70325V6.72161C2.21143 8.76839 3.72615 10.4614 5.69577 10.7408V19.0186C5.69577 19.3363 5.95334 19.5939 6.27107 19.5939C6.5888 19.5939 6.84637 19.3363 6.84637 19.0186V10.7408C8.81599 10.4614 10.3307 8.76839 10.3307 6.72161V6.6932L10.3275 6.66499L9.77018 1.77497Z"
                fill="#5E63E5"
            />
        </svg>
    );
};
