import React from 'react';
import { LockedFunctionalityOverlay } from 'extensions/locked-functionality-overlay';
import styles from './add-new-report-lock-button.module.scss';
import { LockIcon } from 'components/assets/Icons/Icons';
import { Button } from 'ui-components/button/button';
import { ADD_NEW_REPORT_LABEL } from 'features/void-analysis/constants';

export const AddNewReportLockButton = () => {
    return (
        <LockedFunctionalityOverlay isLocked={true} className={styles.lockedFunctionalityOverlay}>
            <LockIcon className={styles.lock} data-testid={'add-new-report-button-lock-icon'} />
            <Button type="brand-primary-full" className={styles.addNewReportButton}>
                {ADD_NEW_REPORT_LABEL}
            </Button>
        </LockedFunctionalityOverlay>
    );
};
