import pluralize from 'pluralize';

import { isIndustryReport, isPropertyReport, StarredReport } from 'core/entities';
import { createChainUrl, goToChainsPage, goToIndustryPage, goToInsightsPage } from 'router/go-to-routes';
import { AppDispatch } from 'store/store';
import { FunctionFirstParamType } from 'types/typescript-utils';
import { dateFormats, getSimpleFormatDate } from 'utils/date/date';
import { getDateLabelFromString } from 'core/services/date-service';

const createPropertyReport = (
    report: StarredReport,
    newTab?: boolean,
): FunctionFirstParamType<typeof goToInsightsPage> => {
    const [primaryEntity, ...competitors] = report.entities;
    const filters = report.entities.map((entity) => ({
        ...entity.filters,
        date_start: entity.filters.start_date,
        date_end: entity.filters.end_date,
    }));

    return {
        id: primaryEntity.id,
        competitor: JSON.stringify(
            competitors.map((competitor) => ({
                id: competitor.id,
                filters: competitor.filters,
                collection: pluralize(competitor.type),
            })),
        ),
        collection: pluralize(primaryEntity.type),
        filters,
        section: report.section,
        newTab,
    };
};

type OpenStarredReportOptions = {
    report: StarredReport;
    newTab?: boolean;
};

export const openStarredReport = ({ report, newTab }: OpenStarredReportOptions) => (
    dispatch: AppDispatch,
) => {
    if (isPropertyReport(report)) {
        const propertyReport = createPropertyReport(report, newTab);

        goToInsightsPage(propertyReport);
        return;
    }

    const chainsEntities = report.entities.map((entity) => {
        const chainUrl = createChainUrl({
            id: entity.id,
            date_start: getSimpleFormatDate(entity.filters.start_date, dateFormats.dashedFullDate),
            date_end: getSimpleFormatDate(entity.filters.end_date, dateFormats.dashedFullDate),
            ...getDateLabelFromString(entity.filters.date_label),
        });

        return chainUrl;
    });

    if (isIndustryReport(report)){
        goToIndustryPage({
            entities: chainsEntities,
            section: report.section,
            newTab,
        });
    } else {
        goToChainsPage({
            entities: chainsEntities,
            section: report.section,
            newTab,
        });
    }
};
