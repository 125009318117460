/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

export type MailIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type MailIconProps = SVGProps<SVGSVGElement> & MailIconUniqueProps;

export const MailIcon = function MailIcon({
    width = 16,
    height = 16,
    color = '#D0D0D0',
    fill = '#49565D',
    ...props
}: MailIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            color={color}
            {...props}
        >
            <path
                d="M0.548676 2.64441H15.4C15.4 3.1115 15.1899 3.55381 14.8279 3.84892L8.00998 9.40615L1.29956 4.18078C0.825755 3.81184 0.548681 3.24492 0.548676 2.64441Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.2223 13.25H1.77767C1.13394 13.25 0.75 12.7971 0.75 12.4V4.08335L7.12549 9.33396L7.1255 9.33397C7.61682 9.73861 8.38843 9.73861 8.87975 9.33397L8.87976 9.33396L15.25 4.08775V12.4C15.25 12.7971 14.8661 13.25 14.2223 13.25ZM15.1327 3.21276L8.40296 8.75503L8.40296 8.75503C8.18858 8.93159 7.81667 8.93159 7.60229 8.75503L7.60229 8.75503L0.869126 3.20985C1.02928 2.95488 1.34471 2.75 1.77767 2.75H14.2223C14.6569 2.75 14.9731 2.95644 15.1327 3.21276ZM14.2223 14H1.77767C0.796 14 0 13.2837 0 12.4V3.6C0 2.71633 0.796 2 1.77767 2H14.2223C15.204 2 16 2.71633 16 3.6V12.4C16 13.2837 15.204 14 14.2223 14Z"
                fill={fill}
            />
        </svg>
    );
};
MailIcon.__tags = ['mail', 'email', 'e-mail', 'envelop'] as string[];
