import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { AnonymousUserPermissions } from 'core/entities/user/anonymous-user-permissions';
import { setAnonymousUserPermissionsActionCreator } from 'store/auth/actions/set-anonymous-user-permissions/set-anonymous-user-permissions';
import {
    setUserActionCreator,
    setUserCustomSettingsActionCreator,
    setUserSettingsActionCreator,
    setUserPermissionsAndFfActionCreator,
    setUserConfigActionCreator,
    setUserPermittedPluginsActionCreator,
    setUserFromCookieStorageActionCreator,
    updateUserCustomSettingsActionCreator,
} from 'store/auth/actions/auth-action-creators';
import type { User } from 'core/entities/user/user';
import { FEATURE_FLAGS_DEFAULTS } from 'core/entities/user/user-feature-flags';
import type { UserCustomSettings } from 'core/entities/user/user-custom-settings';

export const setUser = setUserActionCreator;

export interface AuthState {
    user?: User;
    custom_settings?: UserCustomSettings;
    anonymousUserPermissions?: AnonymousUserPermissions;
}

const initialState = {};

const reducer = createReducer<AuthState>(initialState, (builder) => {
    builder
        .addCase(setUserSettingsActionCreator, (state, action) => {
            state.user!.settings = action.payload;
        })
        .addCase(setUserConfigActionCreator, (state, action) => {
            state.user!.configuration = action.payload;
        })
        .addCase(setAnonymousUserPermissionsActionCreator, (state, action) => {
            state.anonymousUserPermissions = action.payload;
        })
        .addCase(setUserPermissionsAndFfActionCreator, (state, action) => {
            state.user!.permissions = action.payload.permissions;
            state.user!.featureFlags = {
                ...FEATURE_FLAGS_DEFAULTS,
                ...action.payload.feature_flags,
            };
        })
        .addCase(setUserCustomSettingsActionCreator, (state, action) => {
            state.custom_settings = action.payload;
        })
        .addCase(updateUserCustomSettingsActionCreator, (state, action) => {
            const { key, value } = action.payload;
            if (state.custom_settings) {
                state.custom_settings[key] = value as any;
            }
        })
        .addCase(setUserPermittedPluginsActionCreator, (state, action) => {
            if (state.user?.settings) {
                state.user.settings.permitted_plugins = action.payload;
            }
        })
        .addMatcher(isAnyOf(setUser, setUserFromCookieStorageActionCreator), (state, action) => {
            const { user } = action.payload;
            state.user = user;
            delete state.anonymousUserPermissions;
        });
});

export const authReducer = {
    auth: reducer,
};
