import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import { NavigationButtonWithPopover } from '../common';
import { RecentReports } from 'features/header/navigation/components/recent-reports';
import { NavigationButtonProps } from 'features/header/navigation/components/common/navigation-button';
import { selectChainRecentReports } from 'store/header/navigation';
import { useScrollToTopOnVisibilityChange } from 'features/header/navigation/hooks/navigation-hooks';

export const Chain = ({
    link,
    label,
    isSelected,
    onClick,
}: Required<NavigationButtonProps>) => {
    const recentReports = useSelector(selectChainRecentReports);
    const elementRef = useRef<HTMLUListElement>(null);
    const onVisibleChange = useScrollToTopOnVisibilityChange(elementRef);

    const popoverContent = recentReports ? (
        <RecentReports
            onClick={onClick}
            recentReportsList={recentReports}
            reportsFor="chains"
            ref={elementRef}
        />
    ) : null;

    return (
        <NavigationButtonWithPopover
            link={link}
            label={label}
            popoverContent={popoverContent}
            isSelected={isSelected}
            onVisibleChange={onVisibleChange}
        />
    );
};
