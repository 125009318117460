import { createSelector } from '@reduxjs/toolkit';

import { ReportTypes } from 'core/constants/report-type';
import { getRecentEntitiesByTerm } from 'core/services/recent-entities-service/recent-entities-service';
import _ from 'lodash/fp';

import {
    selectTopSearchEntities,
    selectTopSearchIsLoading,
    selectTopSearchTerm,
} from 'store/header/search/selectors/top-search-selectors-factory';
import { selectTopSearchRecentEntities } from '../select-top-search-recent-entities/';
import {
    MAX_NUMBER_OF_TOP_SEARCH_ITEMS,
    MAX_NUMBER_OF_TOP_SEARCH_RECENT_ITEMS,
} from 'store/header/search/constants';
import type { PlaceCollection } from '@placer-ui/types';

export const selectTopSearchResultsByTerm = createSelector(
    selectTopSearchIsLoading,
    selectTopSearchEntities,
    selectTopSearchRecentEntities,
    selectTopSearchTerm,
    (_: any, { reportType }: { reportType: ReportTypes | PlaceCollection[] }) => reportType,
    (isLoading, entities, recentEntities, term, reportType) => {
        if (isLoading) {
            return [];
        }

        const recentEntitiesByTerm = getRecentEntitiesByTerm(recentEntities, term);

        if (recentEntitiesByTerm.length > MAX_NUMBER_OF_TOP_SEARCH_RECENT_ITEMS) {
            recentEntitiesByTerm.splice(MAX_NUMBER_OF_TOP_SEARCH_RECENT_ITEMS);
        }

        const searchResults = [...recentEntitiesByTerm, ...entities];

        const uniqValues = searchResults.filter((searchResult, index) => {
            const firstVenueIndex = _.findIndex(
                {
                    id: searchResult.id,
                    type: searchResult.type,
                },
                searchResults,
            );

            return firstVenueIndex === index;
        });

        if (uniqValues.length > MAX_NUMBER_OF_TOP_SEARCH_ITEMS) {
            uniqValues.length = MAX_NUMBER_OF_TOP_SEARCH_ITEMS;
        }

        return uniqValues;
    },
);
