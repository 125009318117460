import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

import { IconProps } from 'components/assets/Icons/Icons';

/* eslint-disable max-len */
export const DiningIconSvg = (props: CustomIconComponentProps | SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 17 15">
        <path
            d="M6.79.333c.361 0 .661.257.724.597l.012.13v4.364c0 1.758-1.265 3.226-2.945 3.563v5.285c0 .402-.33.728-.737.728a.735.735 0 01-.725-.597l-.012-.13V8.986C1.428 8.65.162 7.183.162 5.424V1.06c0-.4.33-.727.736-.727.362 0 .663.257.725.597l.012.13v3.636h1.472V1.06c0-.4.33-.727.737-.727.362 0 .663.257.725.597l.012.13v3.636h1.471V1.06c0-.4.33-.727.737-.727zM9 3.97C8.997 1.963 10.646.333 12.68.333c2.034 0 3.682 1.63 3.682 3.636v1.455c0 1.758-1.266 3.226-2.946 3.563v5.285c0 .402-.33.728-.736.728a.734.734 0 01-.724-.597l-.012-.13V8.986c-1.68-.337-2.946-1.805-2.946-3.563zm5.761 2.182h-4.163l.028.075a2.21 2.21 0 001.842 1.37l.212.01c.933 0 1.73-.572 2.055-1.38l.026-.075z"
            fill="currentColor"
            fillRule="nonzero"
        />
    </svg>
);

export const DiningIcon = (props: IconProps) => <Icon component={DiningIconSvg} {...props} />;
