/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

type EmailIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type EmailIconProps = SVGProps<SVGSVGElement> & EmailIconUniqueProps;

export const EmailIcon = function EmailIcon({ width = 18, height = 18, ...props }: EmailIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g id="18px icons/Email ">
                <path
                    id="Outline"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.0001 15.75H1.99988C0.8955 15.75 0 14.9441 0 13.95V4.05C0 3.05588 0.8955 2.25 1.99988 2.25H16.0001C17.1045 2.25 18 3.05588 18 4.05V13.95C18 14.9441 17.1045 15.75 16.0001 15.75ZM16.0001 15H1.99988C1.23344 15 0.75 14.4575 0.75 13.95V4.45583L8.04598 10.4645C8.58142 10.9055 9.4245 10.9055 9.95993 10.4645L17.25 4.46079V13.95C17.25 14.4575 16.7666 15 16.0001 15ZM17.1221 3.59452L9.48313 9.88559C9.22465 10.0985 8.78126 10.0985 8.52278 9.88559L0.87985 3.59117C1.07208 3.26145 1.46778 3 1.99988 3H16.0001C16.534 3 16.9306 3.26323 17.1221 3.59452Z"
                    fill="white"
                />
            </g>
        </svg>
    );
};
EmailIcon.__tags = [] as string[];
