import React, { useEffect, useState } from 'react';
import { Loading } from 'ui-components/loading/loading-component';
import styles from './user-loginas-dialog.module.scss';
import { userAPI } from 'API/user-api';
import { UserLoginAsTable } from './user-loginas-table';
import { UnexpectedIllustrationIcon } from 'components/assets/Icons/UnexpectedIllustrationIcon';
import { AlertColoredIcon } from 'components/assets/Icons/Icons';
import { Button } from 'ui-components/button/button';

export type UserLoginAsType = {
    account: string;
    username: string;
};

type UserLoginAsProps = {
    onClose: () => void;
};
export const UserLoginAs = ({ onClose }: UserLoginAsProps) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isSendingRequest, setIsSendingRequest] = useState(false);
    const [isErrrorResponse, setIsErrrorResponse] = useState(false);
    const [userItems, setUserItems] = useState<UserLoginAsType[]>([]);

    useEffect(() => {
        async function getLoginasImpersonateUsers() {
            setIsSendingRequest(true);
            try {
                const { data } = await userAPI.getImpersonateUsers();

                setUserItems(data);
            } catch (e) {
                setIsErrrorResponse(true);
            }
            setIsLoading(false);
            setIsSendingRequest(false);
        }

        getLoginasImpersonateUsers();
    }, []);

    return (
        <Loading className={styles.commentsLoading} show={isLoading}>
            <div className={styles.tableHeader}>Login As</div>
            {isErrrorResponse ? (
                <>
                    <div className={styles.errorView}>
                        <div className={styles.errorWarningMessageWrapper}>
                            <AlertColoredIcon className={styles.alertIcon} />
                            <div className={styles.errorWarningMessage}>
                                Cannot complete the action
                            </div>
                        </div>
                        <div>
                            <UnexpectedIllustrationIcon
                                className={styles.unexpectedIllustrationIcon}
                            />
                        </div>
                        <div className={styles.headlineText}>Try This</div>
                        <div className={styles.explanationText}>
                            Make sure you are connected to a VPN. <br />
                            If this issue persists, post on the #Support slack channel.
                        </div>
                    </div>
                    <div className={styles.buttonWrapper}>
                        <Button
                            type={'brand-primary-full'}
                            onClick={onClose}
                            className={styles.button}
                            disabled={isSendingRequest}
                        >
                            Close
                        </Button>
                    </div>
                </>
            ) : (
                <div className={styles.tableMainWrapper}>
                    <UserLoginAsTable
                        users={userItems}
                        onClose={onClose}
                        setIsLoading={setIsLoading}
                        setIsErrrorResponse={setIsErrrorResponse}
                    />
                </div>
            )}
        </Loading>
    );
};
