import queryString from 'query-string';
import pluralize from 'pluralize';
import { ReportOption, ReportOptionsPayload } from 'core/entities';
import { Region } from 'core/dto/region';
import { fetchWrapper } from 'API/authentification/fetch-wrapper';
import { getEntitiesById } from 'API/common/get-entities-by-id/get-entities-by-id';
import type {
    Chain,
    ChainPlaceTypes,
    Complex,
    PlacerResponseData,
    PlacerArrayResponse,
    PlacerEntityResponse,
} from '@placer-ui/types';
import { RestrictedRegions } from 'hooks/use-mhmd-hooks/use-chains-legislation-type';
import { getAppGatewayEndpoint } from 'core/default-endpoint-url/default-endpoint-url';

export type ChainsSearchParams = {
    term: string;
    skip?: number;
    limit: number;
    purchasedOnly: boolean;
    categories?: string[];
    region?: Partial<Region>;
    region_type?: string;
    region_code?: string;
    source?: string;
    subCategoryPermission?: boolean;
};

interface TopVenuesRankingResponse<T> extends PlacerResponseData<T> {
    total_ft: number;
}

type GetTopVenuesParams = {
    chainId: string;
    startDate: string;
    endDate: string;
    chainType: string;
    mode?: string;
};

type ReportOptionsResp = {
    entities_data: ReportOption[];
    max_date: string;
};

export type CoverageReason = {
    kind: string;
    name: string;
    count: number;
};

export type CoverageResponse = {
    coverage: number;
    total_venues: number;
    total_open_venues: number;
    coverage_date: string;
    contains_new_pois: boolean;
    contains_outdated_pois: boolean;
    reasons?: CoverageReason[] | null;
    restricted_regions?: RestrictedRegions | null;
};

const formatChains = (response: {
    data: { info: Chain }[];
    error_code: number;
    result: string;
}): PlacerArrayResponse<Chain[]> => {
    const { data, error_code, result } = response;

    return new Promise((res) => {
        res({
            data: data.map((item) => item.info) as Chain[],
            error_code: error_code,
            result: result,
        });
    });
};

export const chainsAPI = {
    async getSuggestedBrands(
        limit: number = 20,
        isLimited?: boolean,
    ): PlacerArrayResponse<Chain[]> {
        const params = queryString.stringify({
            limit,
            purchased_only: !isLimited,
            source: 'chains',
            type: 'chains',
            region_type: 'nationwide',
            sort_by: 'foot_traffic',
        });

        const targetUrl = `/search?${params}`;
        const response = await fetchWrapper({
            targetUrl,
        });

        return formatChains(response);
    },
    async getChainsByTerm({
        term,
        skip,
        limit,
        purchasedOnly,
        categories,
        region_type,
        region_code,
        subCategoryPermission,
    }: ChainsSearchParams): PlacerEntityResponse<Chain[]> {
        const encodedKeys = categories?.map((key) => encodeURIComponent(key));

        const params = {
            limit,
            category: !subCategoryPermission ? encodedKeys : undefined,
            sub_category: subCategoryPermission ? categories?.toString() : undefined,
            query: term,
            type: 'chains',
            purchased_only: purchasedOnly,
            source: 'chains',
            region_type,
            region_code,
        };

        skip && Object.assign(params, { skip });

        const queryParams = queryString.stringify(params, {
            skipEmptyString: true,
        });

        const targetUrl = `/search?${queryParams}`;

        return await fetchWrapper({ targetUrl });
    },
    async getTopVenues({
        chainId,
        chainType,
        startDate,
        endDate,
        mode,
    }: GetTopVenuesParams): Promise<TopVenuesRankingResponse<Complex>> {
        return await fetchWrapper({
            targetUrl: `/${pluralize(chainType)}/${chainId}/ranking?${queryString.stringify({
                mode,
                limit: 100,
                start_date: startDate,
                end_date: endDate,
            })}`,
            basePath: `${process.env.REACT_APP_CHAINS_SERVICE_PATH}`,
            endpoint: getAppGatewayEndpoint(),
        });
    },

    async getChainById(chainId: string): PlacerEntityResponse<Chain> {
        return await fetchWrapper({ targetUrl: `/chains/${chainId}/` });
    },

    async getChainsByIDs(chainIds: string[]): PlacerArrayResponse<Chain[]> {
        const ids = chainIds.map((chain) => ({
            id: chain,
            type: 'chain' as const,
        }));

        return await getEntitiesById(ids);
    },

    async getChainReportOptions(entity_ids: ReportOptionsPayload): Promise<ReportOptionsResp> {
        return await fetchWrapper({
            targetUrl: '/report-options',
            method: 'POST',
            basePath: '/eset/1',
            body: { entity_ids },
            endpoint: getAppGatewayEndpoint(),
        });
    },

    async getChainCoverage({
        id,
        start_date,
        end_date,
        type,
    }: {
        id: string;
        start_date: string;
        end_date: string;
        type?: ChainPlaceTypes;
    }): PlacerEntityResponse<CoverageResponse> {
        return await fetchWrapper({
            targetUrl: `/${pluralize(type || 'chains', 2)}/${id}/coverage?${queryString.stringify({
                start_date,
                end_date,
            })}`,
            method: 'GET',
            basePath: '/eset/1',
            endpoint: getAppGatewayEndpoint(),
        });
    },
};
