import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RouteInitializerProps } from 'router';
import { ChainOptionItem, SalesForecastEntity } from 'features/sales-forecast/types/entities';
import { SalesAnalysisSettings, SalesForecastUi } from 'features/sales-forecast/types/ui';
import type { Dictionary, Place } from '@placer-ui/types';
import {
    ChainCustomModelInfo,
    ChainModelInfo,
    ForecastMetadata,
    FTRankingInfo,
    MultipleModelsReport,
    RecentReport,
    ReportInfo,
    ReportMetadata,
} from 'features/sales-forecast/types/types';
import type { Chain } from '@placer-ui/types';
import { ReportAccessState } from 'store/configuration/report-access/slices/report-access-slice';

export interface SalesForecastState {
    entities: Record<string, SalesForecastEntity>;
    entities_ids: string[];
    reportSelectionPage: {
        reportPageLoading: boolean;
        chainSelection: {
            chainsList: ChainSelectionSearchState;
            selectedChain?: ChainOptionItem;
        };
        recentReports?: RecentReport[];
    };
    salesAnalysisPage: {
        reportInfo?: ReportInfo | {};
        reportAccess?: ReportAccessState;
        customModelReportMetadata?: MultipleModelsReport;
        forecastMetadata?: Dictionary<ForecastMetadata>;
        customModelRankingInfo?: Dictionary<FTRankingInfo>;
        forecastPredict?: Dictionary<ReportInfo>;
        chainInfo?: Chain;
        locationInfo?: Place;
        siteState?: string;
        reportMetadata?: ReportMetadata;
        isLoading: boolean;
        chainModelInfo?: ChainModelInfo;
        chainCustomModelInfo?: ChainCustomModelInfo;
        modelFTRankingInfo?: FTRankingInfo;
        settings?: SalesAnalysisSettings;
    };
    ui: SalesForecastUi;
    mapData: {};
}

export type ChainSelectionSearchState = {
    list?: [];
};

export const initialState: SalesForecastState = {
    entities: {},
    entities_ids: [],
    reportSelectionPage: {
        reportPageLoading: false,
        chainSelection: {
            chainsList: {},
        },
    },
    salesAnalysisPage: {
        isLoading: false,
    },
    ui: {
        pageWidth: 0,
        reportsPage: {
            headerMapExpanded: false,
        },
    },
    mapData: {},
};

export const initialSalesForecast: SalesForecastState = initialState;

export const salesForecastSlice = createSlice({
    name: 'sales_forecast',
    initialState: initialSalesForecast,
    reducers: {
        resetState: () => {
            return initialSalesForecast;
        },
        setReportPredict: (
            state: SalesForecastState,
            action: PayloadAction<ReportInfo | undefined | {}>,
        ) => {
            state.salesAnalysisPage.reportInfo = action.payload;
        },
        setReportMetadata: (
            state: SalesForecastState,
            action: PayloadAction<ReportMetadata | undefined>,
        ) => {
            state.salesAnalysisPage.reportMetadata = action.payload;
        },
        setChainModelInfo: (
            state: SalesForecastState,
            action: PayloadAction<ChainModelInfo | undefined>,
        ) => {
            state.salesAnalysisPage.chainModelInfo = action.payload;
        },
        setChainCustomModelInfo: (
            state: SalesForecastState,
            action: PayloadAction<ChainCustomModelInfo | undefined>,
        ) => {
            state.salesAnalysisPage.chainCustomModelInfo = action.payload;
        },
        setFTModelRankingInfo: (
            state: SalesForecastState,
            action: PayloadAction<FTRankingInfo | undefined>,
        ) => {
            state.salesAnalysisPage.modelFTRankingInfo = action.payload;
        },
        setReportsLoading: (state: SalesForecastState, action: PayloadAction<boolean>) => {
            state.reportSelectionPage.reportPageLoading = action.payload;
        },
        setReportAnalysisLoading: (state: SalesForecastState, action: PayloadAction<boolean>) => {
            state.salesAnalysisPage.isLoading = action.payload;
        },
        setUiProperties: (state: SalesForecastState, action: PayloadAction<Partial<{}>>) => {
            state.ui = {
                ...state.ui,
                ...action.payload,
            };
        },
        setChainsList: (
            { reportSelectionPage: { chainSelection } }: SalesForecastState,
            action: PayloadAction<Partial<ChainSelectionSearchState>>,
        ) => {
            chainSelection.chainsList = {
                ...chainSelection.chainsList,
                ...action.payload,
            };
        },
        setSelectedChain: (
            { reportSelectionPage: { chainSelection } }: SalesForecastState,
            action: PayloadAction<ChainOptionItem>,
        ) => {
            chainSelection.selectedChain = action.payload;
        },
        setRecentReports: (state: SalesForecastState, action: PayloadAction<RecentReport[]>) => {
            state.reportSelectionPage.recentReports = action.payload;
        },
        setSalesAnalysisEntity: (state: SalesForecastState, action: PayloadAction<Place>) => {
            state.salesAnalysisPage.locationInfo = action.payload;
        },
        setSalesAnalysisChain: (state: SalesForecastState, action: PayloadAction<Chain>) => {
            state.salesAnalysisPage.chainInfo = action.payload;
        },
        setSalesAnalysisSettings: (
            state: SalesForecastState,
            action: PayloadAction<SalesAnalysisSettings | undefined>,
        ) => {
            state.salesAnalysisPage.settings = action.payload;
        },
        setSalesAnalysisCustomModelReportMetadata: (
            state: SalesForecastState,
            action: PayloadAction<MultipleModelsReport | undefined>,
        ) => {
            state.salesAnalysisPage.customModelReportMetadata = action.payload;
        },
        setSalesAnalysisForecastPredict: (
            state: SalesForecastState,
            action: PayloadAction<Dictionary<ReportInfo> | undefined>,
        ) => {
            state.salesAnalysisPage.forecastPredict = {
                ...state.salesAnalysisPage.forecastPredict,
                ...action.payload,
            };
        },
        setSalesAnalysisForecastMetadata: (
            state: SalesForecastState,
            action: PayloadAction<Dictionary<ForecastMetadata> | undefined>,
        ) => {
            state.salesAnalysisPage.forecastMetadata = {
                ...state.salesAnalysisPage.forecastMetadata,
                ...action.payload,
            };
        },
        setSalesAnalysisCustomModelForecastRanking: (
            state: SalesForecastState,
            action: PayloadAction<Dictionary<FTRankingInfo> | undefined>,
        ) => {
            state.salesAnalysisPage.customModelRankingInfo = {
                ...state.salesAnalysisPage.customModelRankingInfo,
                ...action.payload,
            };
        },
        setSalesAnalysisEntities: (
            state: SalesForecastState,
            action: PayloadAction<SalesForecastEntity[]>,
        ) => {
            const entities = action.payload;

            state.entities_ids = entities.map((entity) => entity.id);
            state.entities = entities.reduce<Record<string, SalesForecastEntity>>(
                (entities, entity) => {
                    entities[entity.id] = entity;
                    return entities;
                },
                {},
            );
        },
        cleanSalesAnalysisEntities: (state: SalesForecastState) => {
            state.entities_ids = [];
            state.entities = {};
            state.salesAnalysisPage.locationInfo = undefined;
            state.salesAnalysisPage.chainInfo = undefined;
            state.salesAnalysisPage.reportInfo = undefined;
            state.salesAnalysisPage.forecastMetadata = undefined;
            state.salesAnalysisPage.forecastPredict = undefined;
            state.salesAnalysisPage.customModelRankingInfo = undefined;
            state.salesAnalysisPage.customModelReportMetadata = undefined;
            state.salesAnalysisPage.reportMetadata = undefined;
            state.salesAnalysisPage.modelFTRankingInfo = undefined;
            state.salesAnalysisPage.chainModelInfo = undefined;
        },
        setSalesAnalysisData: (state: SalesForecastState, action: PayloadAction<any>) => {
            const { entities, ids, recentReports, ui } = action.payload;

            state.entities = entities;
            state.entities_ids = ids;
            state.reportSelectionPage.recentReports = recentReports;
            state.ui = ui;
        },
    },
});

export const salesForecastReducer = {
    salesForecast: salesForecastSlice.reducer,
};

export const salesForecastActions = salesForecastSlice.actions;

export const salesForecastInitializer = ({ dispatch }: RouteInitializerProps) => {
    dispatch(salesForecastSlice.actions.resetState());
};
