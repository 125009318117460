import React from 'react';
import {
    MobileAnalyzeLocationOnboardingPage,
    MobileAnalyzePage,
    MobileExplorePage,
    MobileExplorePropertiesOnboardingPage,
    MobileStandAloneWidgetPage,
    MobileSettingsPage,
    MobileReportPage,
} from 'router/lazy-pages';
import { DashboardRoute } from 'router/types';
import { RefreshPage } from 'components/refresh-page/refresh-page';
import { ON_BOARDING_ROUTE } from 'features/mobile-report/consts';
import { UnexpectedMobileError } from 'features/mobile-report/shared/components/unexpected-error/unexpected-error';

export const MOBILE_ROUTES: DashboardRoute[] = [
    {
        path: '/refresh-page',
        Component: RefreshPage,
    },
    {
        path: `/${ON_BOARDING_ROUTE}/explore`,
        Component: MobileExplorePropertiesOnboardingPage,
        isFullPage: true,
    },
    {
        path: `/${ON_BOARDING_ROUTE}/analyze`,
        Component: MobileAnalyzeLocationOnboardingPage,
        isFullPage: true,
    },
    {
        path: '/unexpected-error',
        Component: () => <UnexpectedMobileError />,
    },
    // should be the last one
    {
        path: '/',
        Component: MobileReportPage,
    },
];

export const getMobileReportPageRoutes = (): DashboardRoute[] => [
    {
        path: '/explore/',
        title: 'Explore',
        Component: MobileExplorePage,
    },
    {
        path: '/insights/:collection/:id/overview',
        title: 'Insights',
        Component: MobileAnalyzePage,
    },
    {
        path: '/settings',
        title: 'Settings',
        Component: MobileSettingsPage,
    },
    {
        path: '/standAlone/:type',
        title: 'Stand Alone',
        render: (props) => <MobileStandAloneWidgetPage type={props.match.params.type} />,
        isFullPage: true,
    },
];
