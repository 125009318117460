import React, { useState } from 'react';
import styles from './solutions-guide-main.module.scss';
import { BodyText, Heading } from '@placer-ui/components';
import { SolutionList } from 'features/onboarding/solutions-guide/components/solution-list/solution-list';
import { InlineSelect } from 'components/freemium/inline-select/inline-select';
import {
    SOLUTIONS_GUIDE,
    solutionsGuideIndustries,
} from 'features/onboarding/solutions-guide/consts/solutions-guide';
import {
    getDefaultIndustry,
    setIndustryCookie,
} from 'features/onboarding/solutions-guide/utils/utils';

type SolutionsGuideMainProps = {
    setOpen: (open: boolean) => void;
};

export const SolutionsGuideMain = ({ setOpen }: SolutionsGuideMainProps) => {
    const [industry, setIndustry] = useState(getDefaultIndustry());
    const industrySolutions = SOLUTIONS_GUIDE[industry];

    return (
        <div className={styles.content}>
            <div className={styles.header}>
                <div className={styles.title}>
                    <Heading variant={'h5'}>Solutions for Professionals in</Heading>
                    <InlineSelect
                        items={solutionsGuideIndustries.map((_industry) => ({
                            value: _industry,
                            label: _industry,
                            isSelected: _industry === industry,
                        }))}
                        onItemSelection={(item) => {
                            setIndustryCookie(item.value);
                            setIndustry(item.value);
                        }}
                    />
                </div>
                <BodyText>Click on a solution to go to the relevant report</BodyText>
            </div>
            <div className={styles.body}>
                <SolutionList
                    solutions={industrySolutions.solutions}
                    industryIcon={industrySolutions.icon}
                    setOpen={setOpen}
                />
            </div>
        </div>
    );
};
