import { useExploreUIState } from 'features/explore/context/use-explore-ui-state';
import { useCallback } from 'react';
import { PlaceOverlay } from 'ui-components/google-map-layers/types/google-map-layers-types';
import { getActiveOverlay } from 'ui-components/google-map-layers/utils/get-place-overlay';

export const useGetOverlayPOI = () => {
    const { overlayPOIs } = useExploreUIState();
    return useCallback(
        (poi: PlaceOverlay): PlaceOverlay[] => {
            return overlayPOIs[poi.id];
        },
        [overlayPOIs],
    );
};

export const useGetActiveOverlay = () => {
    const getOverlayPOI = useGetOverlayPOI();

    return useCallback(
        (poi: PlaceOverlay) => {
            const poiOverlays = getOverlayPOI(poi);
            if (poiOverlays?.length) {
                return getActiveOverlay(poiOverlays);
            }
        },
        [getOverlayPOI],
    );
};
