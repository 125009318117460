import React, { ReactNode, useEffect, useState } from 'react';
import { noop } from 'lodash';
import classnames from 'classnames';
import {
    AggregationParams,
    BaseRowDataType,
    EmptyTableConfig,
    TableColumn,
    TableDataFetchFn,
    TableSearch,
} from 'ui-components/table/types';

import { OuterSearchInput } from 'ui-components/table/components/outer-search-input/outer-search-input';
import { EmptyDataMessage } from 'ui-components/empty-data-message/empty-data-message';
import { InnerSearchInput } from 'ui-components/table/components/inner-search-input/inner-search-input';
import {
    ExpandableTable,
    ExpandableTableProps,
} from 'ui-components/table/expandable-table/expandable-table';
import { DataTable } from 'ui-components/table/data-table/data-table';
import { SorterResult } from 'antd/lib/table/interface';
import { aggregateRowsLocally } from 'ui-components/table/utils/data-aggregation';
import {
    defaultExpandColumnConfig,
    defaultColumnConfig,
    defaultLoadingConfig,
    defaultEmptyState,
    dotsMenuColumnConfig,
    DEFAULT_VISIBLE_COLUMNS_NUM,
} from './constants';

import 'ui-components/table/data-table/pagination/pagination-antd-styles-overrides.css';
import styles from './table.module.scss';
import classNames from 'classnames';
import { TableScrollArrows } from 'ui-components/table/components/table-scroll-arrows/table-scroll-arrows';
import { useScrollPosition } from 'ui-components/table/components/table-scroll-arrows/table-scroll-arrows-hook';
import { TableWithPersistentHeader } from 'ui-components/table/table-with-persistent-header/table-with-persistent-header';

export type TableProps<RowDataType> = Omit<ExpandableTableProps<RowDataType>, 'expandable'> & {
    dataSource: RowDataType[];
    columns: TableColumn<RowDataType>[];
    pagination?: boolean;
    paginationPageSize?: number;
    paginationDisabled?: boolean;
    onPaginationChange?: (currentPage: number) => void;
    currentPageForPagination?: number;
    totalRecordsSize?: number;
    search?: TableSearch<RowDataType>;
    actions?: ReactNode;
    expandable?: ExpandableTableProps<RowDataType>['expandable'];
    persistentHeader?: boolean;
    emptyState?: EmptyTableConfig;
    customEmptyState?: ReactNode;
    rowDotsMenuRender?: (record: RowDataType) => ReactNode;
    rowHoverRender?: (record: RowDataType) => ReactNode;
    rowDisabledOverlayRender?: (record: RowDataType) => ReactNode;
    disabledRow?: (record: RowDataType) => boolean | undefined;
    lazyLoad?: boolean;
    onDataFetch?: TableDataFetchFn<RowDataType>;
    onRowClick?: (
        data: RowDataType,
        rowIndex?: number,
        event?: React.MouseEvent<HTMLElement>,
    ) => void | undefined;
    onRowsUpdated?: (records: RowDataType[]) => void;
    classNamesMap?: {
        wrapper?: string;
        table?: string;
        row?: string;
        column?: string;
        pagination?: string;
        toolbar?: string;
        expandableTable?: string;
        emptyStateWrapClass?: string;
        search?: string;
        noHoverClassName?: string;
    };
    enableColumnsScrolling?: boolean;
    columnVisibleCount?: number;
    columnsScrollClassName?: string;
    toolbarWrapperMarginBottom?: boolean;
    tablePaddingBottom?: boolean;
    tableCellPadding?: boolean;
    onInternalSearchChange?: (value: string) => void;
    supportDataFetchFlow?: boolean;
    noHoverStyle?: boolean | ((record: RowDataType) => boolean);
    onChangeSorting?: (sorter: SorterResult<RowDataType>) => void;
};

export const Table = <RowDataType extends BaseRowDataType>({
    dataSource,
    columns,
    pagination = true,
    paginationPageSize = 10,
    paginationDisabled = false,
    onPaginationChange,
    currentPageForPagination,
    totalRecordsSize,
    search,
    actions,
    rowDotsMenuRender,
    rowHoverRender,
    rowDisabledOverlayRender,
    disabledRow,
    lazyLoad,
    onDataFetch,
    onRowClick,
    onRowsUpdated,
    classNamesMap,
    customEmptyState,
    emptyState = defaultEmptyState,
    enableColumnsScrolling,
    columnVisibleCount = DEFAULT_VISIBLE_COLUMNS_NUM,
    columnsScrollClassName,
    toolbarWrapperMarginBottom = true,
    tablePaddingBottom = true,
    tableCellPadding = true,
    onInternalSearchChange,
    supportDataFetchFlow = true,
    noHoverStyle,
    onChangeSorting,
    ...props
}: TableProps<RowDataType>) => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isMainColumnSortable, setIsMainColumnSortable] = useState<boolean>(true);
    const [showMainColumnSort, setShowMainColumnSort] = useState<boolean>(!search?.searchTerm);
    const [totalRows, setTotalRows] = useState<number>(dataSource.length);
    const [currentPage, setCurrentPage] = useState<number>(currentPageForPagination ?? 1);
    const [pageRows, setPageRows] = useState<RowDataType[]>(lazyLoad ? [] : dataSource);
    const [internalSearchTerm, setInternalSearchTerm] = useState<string>();
    const [sortingConfig, setSortingConfig] = useState<SorterResult<RowDataType>>();
    const searchDebounceMs = lazyLoad ? 500 : 0;
    const { position, columnsCount, scrollRight, scrollLeft } = useScrollPosition(
        columnVisibleCount,
        columns,
    );

    useEffect(() => {
        setIsMainColumnSortable(!!columns[0]?.sorter);
    }, [columns]);

    const onSearchChange = (value: string) => {
        setInternalSearchTerm(value);
        onInternalSearchChange && onInternalSearchChange(value);
    };

    const onTableChange: TableProps<RowDataType>['onChange'] = (pagination, filters, sorter) => {
        if (Object.keys(sorter).length > 0) {
            const newSorter = sorter as SorterResult<RowDataType>;
            setSortingConfig(newSorter);
            onChangeSorting && onChangeSorting(newSorter);
        }
    };

    const searchSharedProps = {
        onChange: onSearchChange,
        placeholder: search?.placeholder,
        debounceMs: searchDebounceMs,
        className: classNamesMap?.search,
    };

    useEffect(() => {
        if (!onPaginationChange) {
            setCurrentPage(1);
        }
    }, [internalSearchTerm, search?.searchTerm, onPaginationChange]);

    /**
     * This effect re-aggregate the data, whether locally or via an API call,
     * on every change in the table state (page, sort, search)
     */
    useEffect(() => {
        if (supportDataFetchFlow) {
            if (lazyLoad) {
                setLoading(false);
            }

            const aggregationParams: AggregationParams<RowDataType> = {
                page: currentPageForPagination ?? currentPage,
                pageSize: paginationPageSize,
                searchTerm: search?.type === 'external' ? search?.searchTerm : internalSearchTerm,
                searchFields: search?.searchFields,
                sorting: sortingConfig,
            };

            if (!lazyLoad || !onDataFetch) {
                const { pageRows, totalRows } = aggregateRowsLocally(dataSource, aggregationParams);
                setPageRows(pageRows);
                setTotalRows(totalRows);
                onRowsUpdated && onRowsUpdated(pageRows);
            } else {
                onDataFetch(aggregationParams).then(({ totalRows, pageRows }) => {
                    setPageRows(pageRows);
                    setTotalRows(totalRows);
                    setLoading(false);
                });
            }
        } else {
            setPageRows(dataSource);
            setTotalRows(totalRows);
        }
    }, [
        currentPage,
        currentPageForPagination,
        internalSearchTerm,
        sortingConfig,
        paginationPageSize,
        lazyLoad,
        search?.type,
        search?.searchTerm,
        search?.searchFields,
        onRowsUpdated,
        dataSource,
        onDataFetch,
        supportDataFetchFlow,
        totalRows,
    ]);

    let columnsConfigArr = [...columns];
    if (enableColumnsScrolling && columnVisibleCount) {
        columnsConfigArr = [
            columns[0],
            ...columnsConfigArr.slice(position.start + 1, position.end),
        ];
    }

    const onSearchModeChange = (isSearchMode: boolean) => {
        if (isSearchMode) {
            setShowMainColumnSort(false);
        } else {
            setShowMainColumnSort(true);
        }
    };

    const getTitleElement = (column: TableColumn<RowDataType>, isColumnSearch: boolean) => {
        if (isMainColumnSortable && isColumnSearch) {
            return (
                <InnerSearchInput
                    title={column.title as ReactNode}
                    {...searchSharedProps}
                    onSearchModeChange={onSearchModeChange}
                    isSearchOnCellClick={false}
                    searchButtonClass={styles.searchButton}
                    isInitialInSearchModeState={!!search?.searchTerm}
                    initialSearchTerm={search?.searchTerm}
                />
            );
        } else if (isColumnSearch) {
            return <InnerSearchInput title={column.title as ReactNode} {...searchSharedProps} />;
        } else {
            return column.title;
        }
    };

    const columnsConfig = columnsConfigArr.map((column, i) => {
        const { searchColumnKey, type: searchType } = search || {};
        const isColumnSearch =
            (searchColumnKey ? column.key === searchColumnKey : i === 0) &&
            searchType === 'internal';

        return {
            ...defaultColumnConfig,
            ...column,
            className: classnames(column.className, classNamesMap?.column, {
                [styles.headerColumnSearch]: isColumnSearch,
                [styles.doNotShowMainColumnSort]: !showMainColumnSort,
                [styles.isSearchAndSortMode]:
                    isMainColumnSortable && isColumnSearch && showMainColumnSort,
                [styles.tableCellPadding]: tableCellPadding,
            }),
            title: getTitleElement(column, isColumnSearch),
        };
    });

    if (rowDotsMenuRender) {
        columnsConfig.push({
            ...dotsMenuColumnConfig,
            render: (record: RowDataType) => (
                <div
                    className={styles.dotsMenuWrapper}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    {rowDotsMenuRender(record)}
                </div>
            ),
        });
    }

    if (rowHoverRender) {
        columnsConfig.push({
            key: 'hover_cell',
            title: null,
            className: styles.hoverColumn,
            render: (record: RowDataType) => (
                <div onClick={(e) => e.stopPropagation()}>{rowHoverRender(record)}</div>
            ),
        });
    }

    if (rowDisabledOverlayRender) {
        columnsConfig.push({
            key: 'disabled_row_overlay',
            title: null,
            className: styles.disabledRowColumn,
            render: (record: RowDataType) =>
                disabledRow?.(record) ? <div>{rowDisabledOverlayRender(record)}</div> : null,
        });
    }

    const staticRowClasses = classnames(
        styles.row,
        {
            [styles.clickableRow]: props.expandable || onRowClick,
            [styles.noHoverStyle]: typeof noHoverStyle !== 'function' && noHoverStyle,
        },
        classNamesMap?.noHoverClassName,
        classNamesMap?.row,
    );

    const rowClassName = (record: RowDataType, index: number, indent: number) => {
        const disabledClasses = { [styles.disabledRow]: disabledRow && disabledRow(record) };
        const noHoverClasses = {
            [styles.noHoverStyle]: typeof noHoverStyle === 'function' && noHoverStyle(record),
        };
        if (props.rowClassName && typeof props.rowClassName === 'function') {
            return classNames(
                staticRowClasses,
                props.rowClassName(record, index, indent),
                disabledClasses,
                noHoverClasses,
            );
        }

        return classNames(staticRowClasses, props?.rowClassName, disabledClasses, noHoverClasses);
    };

    const handleOnChangePage = (page: number) => {
        onPaginationChange && onPaginationChange(page);
        !currentPageForPagination && setCurrentPage(page);
    };

    const tableSharedProps: Partial<ExpandableTableProps<RowDataType>> = {
        dataSource: pageRows,
        columns: columnsConfig,
        onChange: onTableChange,
        locale: {
            emptyText: customEmptyState || (
                <EmptyDataMessage
                    {...emptyState}
                    wrapClassName={classNamesMap?.emptyStateWrapClass}
                />
            ),
        },
        loading: isLoading && {
            ...defaultLoadingConfig,
            spinning: isLoading,
        },
        pagination: pagination
            ? {
                  disabled: paginationDisabled,
                  pageSize: paginationPageSize,
                  current: currentPageForPagination ?? currentPage,
                  total: totalRecordsSize ?? totalRows,
                  position: ['bottomRight'],
                  showSizeChanger: false,
                  onChange: handleOnChangePage,
                  showTotal: (total: number, [first, last]) => (
                      <div className={styles.showTotal} data-testid="pagination-summary">
                          Showing {first} - {last} / <span className={styles.total}>{total}</span>
                      </div>
                  ),
                  className: classnames(
                      'pagination-wrapper',
                      styles.pagination,
                      classNamesMap?.pagination,
                  ),
              }
            : undefined,
        onRow: (data, index) => ({
            onClick: (e) => (onRowClick ? onRowClick(data, index, e) : noop),
            ...props.onRow,
        }),
        ...props,
    };

    const tableWithPersistentHeaderProps = {
        columns: tableSharedProps.columns,
        onChange: props.onChange || tableSharedProps.onChange,
        classNamesMap,
        loading: !!(props.loading ?? isLoading),
        tableLayout: props.tableLayout || 'fixed',
    };

    const getTableToRender = () => {
        return props.expandable ? (
            <ExpandableTable<RowDataType>
                {...tableSharedProps}
                rowClassName={rowClassName}
                expandable={{
                    ...defaultExpandColumnConfig,
                    expandIconColumnIndex: columnsConfig.length,
                    ...props.expandable,
                }}
            />
        ) : (
            <DataTable<RowDataType> {...tableSharedProps} rowClassName={rowClassName} />
        );
    };

    const renderResult = (
        <>
            {props.persistentHeader ? (
                <TableWithPersistentHeader {...tableWithPersistentHeaderProps}>
                    {getTableToRender()}
                </TableWithPersistentHeader>
            ) : (
                <>{getTableToRender()}</>
            )}
        </>
    );

    return (
        <div
            className={classnames(styles.tableWrapper, classNamesMap?.wrapper, {
                [styles.columnsPagination]:
                    enableColumnsScrolling &&
                    columnVisibleCount &&
                    columnsCount > columnVisibleCount,
                [styles.tablePaddingBottom]: tablePaddingBottom,
            })}
        >
            <div
                className={classnames(styles.toolbarWrapper, classNamesMap?.toolbar, {
                    [styles.toolbarWrapperMarginBottom]: toolbarWrapperMarginBottom,
                })}
            >
                {search?.type === 'searchbar' && <OuterSearchInput {...searchSharedProps} />}
                {actions && <div className={styles.tableActionsWrapper}>{actions}</div>}
            </div>
            {enableColumnsScrolling && columnVisibleCount && (
                <TableScrollArrows
                    onScrollLeft={scrollLeft}
                    onScrollRight={scrollRight}
                    scrollPosition={position}
                    columnsCount={columnsCount}
                    className={classNames(columnsScrollClassName, {
                        [styles.withSearchbar]: search?.type === 'searchbar',
                    })}
                />
            )}
            <div className={classNamesMap?.table}>{renderResult}</div>
        </div>
    );
};
