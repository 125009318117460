/* eslint-disable max-len */
import React from 'react';

export function WeatherRainPossibleIcon() {
    return (
        <svg width="70" height="54" viewBox="0 0 70 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M34.8001 37.9316L62.2056 38H62.208C65.4119 37.9987 67.9997 35.4091 67.9997 32.2262C67.9997 29.0419 65.4104 26.4518 62.2056 26.4518H59.6391L59.4793 24.6263C58.7919 16.7752 52.176 10.3428 44.2301 10.3428C38.8622 10.3428 34.1395 13.0958 31.4046 17.2688C34.2221 18.1191 36.6564 19.7991 38.3523 22.2419C38.6672 22.6955 38.5548 23.3186 38.1011 23.6336C37.6475 23.9486 37.0244 23.8361 36.7094 23.3825C34.5879 20.3268 31.033 18.6241 26.915 18.6241C20.3197 18.6241 15.2511 23.6102 15.2511 30.2022C15.2511 30.3272 15.2282 30.4468 15.1863 30.5572C15.2847 33.3851 16.388 35.9567 18.1556 37.9316H34.8001Z" fill="#D0D0D0"/>
<path d="M36.5908 40.6213C37.3522 40.9476 37.7049 41.8294 37.3786 42.5909L34.3786 49.5909C34.0523 50.3523 33.1705 50.705 32.409 50.3787C31.6476 50.0524 31.2948 49.1706 31.6212 48.4091L34.6212 41.4091C34.9475 40.6477 35.8293 40.2949 36.5908 40.6213Z" fill="#6FA2CE"/>
<path d="M50.5908 40.6213C51.3522 40.9476 51.7049 41.8294 51.3786 42.5909L48.3786 49.5909C48.0523 50.3523 47.1705 50.705 46.409 50.3787C45.6476 50.0524 45.2948 49.1706 45.6212 48.4091L48.6212 41.4091C48.9475 40.6477 49.8293 40.2949 50.5908 40.6213Z" fill="#6FA2CE"/>
<path d="M44.3786 42.5909C44.7049 41.8294 44.3522 40.9476 43.5908 40.6213C42.8293 40.2949 41.9475 40.6477 41.6212 41.4091L37.1212 51.9091C36.7948 52.6706 37.1476 53.5524 37.909 53.8787C38.6705 54.205 39.5523 53.8523 39.8786 53.0909L44.3786 42.5909Z" fill="#6FA2CE"/>
<path d="M35.9644 10.4347C32.8326 4.24411 26.4119 0 19 0C8.50659 0 0 8.50659 0 19C0 28.274 6.64437 35.9961 15.4324 37.6656C15.1829 37.2873 14.9517 36.896 14.74 36.4929C6.85497 34.5794 1 27.4735 1 19C1 9.05888 9.05887 1 19 1C26.0429 1 32.141 5.04484 35.0981 10.9382C35.3815 10.7623 35.6704 10.5944 35.9644 10.4347Z" fill="#FFD89E"/>
<path d="M33.4423 12.1051C31.8479 13.3741 30.4833 14.9183 29.4207 16.6655C28.6095 16.5167 27.7735 16.4389 26.9192 16.4389C19.3308 16.4389 13.1792 22.5745 13.1792 30.1431C13.1792 31.528 13.3852 32.8649 13.7682 34.1251C7.50086 31.9575 3 26.0044 3 19C3 10.1634 10.1634 3 19 3C25.3678 3 30.8667 6.71988 33.4423 12.1051Z" fill="#FFD89E"/>
</svg>

    );
}
WeatherRainPossibleIcon.__tags = [] as string[];
