import React from 'react';
import { OverlayView } from '@react-google-maps/api';
import { CustomInfoWindowProps } from 'ui-components/google-map/components/custom-info-window/custom-info-window';

export const OverlayViewContainer = ({
    position,
    children,
    showAnchor,
    offset = [0, 0],
}: CustomInfoWindowProps) => {
    const calcPixelOffset = (width: number, height: number) => ({
        x: -0.5 * width + offset[0],
        y: -height + (showAnchor ? -10 : 0) + offset[1],
    });
    return (
        <OverlayView
            position={position}
            getPixelPositionOffset={calcPixelOffset}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
            {children}
        </OverlayView>
    );
};
