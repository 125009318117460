import { numberFormat } from 'utils/number/number';
import { getSimpleFormatDate } from 'utils/date/date';
import { STRING_FOR_NULL_DATA_TO_DISPLAY } from 'components/advanced-reports-analysis/widgets/constants/constants';
import { ValueType } from 'features/void-analysis/common/utils/metric-value-formatter';
import { isNil } from 'lodash/fp';

export const formatVenueSizeRange = (minSize: number, maxSize: number): string => {
    return `${formatQuantity(minSize, 0, true)} - ${formatQuantity(maxSize, 0, true)}`;
};

export const formatDistance = (miles: number, maximumFractionDigits: number = 1): string => {
    return new Intl.NumberFormat('en-US', {
        style: 'decimal',
        maximumFractionDigits,
    }).format(miles);
};

export const formatExpansionRate = (
    usRate: number | string,
    stateRate: number | string,
): string[] => {
    const country = typeof usRate === 'string' ? usRate : formatPercentage(usRate, 2);
    const state = typeof stateRate === 'string' ? stateRate : formatPercentage(stateRate, 2);

    return [country, state];
};

export const parseValueForDisplay = (
    value: number | null,
    supportNewChainFlow?: boolean | null,
) => {
    if (supportNewChainFlow) {
        return 'New';
    }
    return isNil(value) ? STRING_FOR_NULL_DATA_TO_DISPLAY : value!;
};

export const parseValueIfNull = (value: ValueType) =>
    isNil(value) ? STRING_FOR_NULL_DATA_TO_DISPLAY : value;

export const formatQuantity = (
    number: number,
    maximumFractionDigits: number = 0,
    formatThousands: boolean = false,
): string => numberFormat(number, maximumFractionDigits, true, formatThousands);

export const formatScore = (score: number, maximumFractionDigits: number = 1): string => {
    return formatPercentage(score, maximumFractionDigits);
};

export const formatPercentage = (percentage: number, maximumFractionDigits: number = 1): string => {
    const value = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        maximumFractionDigits,
    }).format(percentage);

    return `${value}%`;
};

export const formatDate = (date: string, format?: string): string => {
    return getSimpleFormatDate(date, format);
};

//replace all spaces with hyphen, remove commas, replace double with single hyphen
export const normalizeFilename = (str: string) =>
    str.replace(/\s/g, '-').replace(/,/g, '').replace(/-{2,}/g, '-');
