import { useExploreUIState } from 'features/explore/context/use-explore-ui-state';
import { useCallback } from 'react';
import {
    useSetEnclosingComplexes,
    useSetOverlayPOIOnce,
} from 'features/explore/context/use-explore-ui-state-actions';
import { fetchEnclosingComplexes } from 'API/enclosing-complex/enclosing-complex-api';
import {
    getComplexPlaceOverlay,
    getNearbyPlaceOverlay,
} from 'ui-components/google-map-layers/utils/get-place-overlay';
import type { PointOfInterestEntities } from '@placer-ui/types';
import { createPlaceWithNearbyResult } from 'utils/create-place-with-nearby-result/create-place-with-nearby-result';
import { batch } from 'react-redux';
import { setIsLoadingExploreEnclosingComplexes } from 'features/explore/store/explore-signals/explore-encloasing-complexes-signal';

export const useFetchEnclosingComplexes = () => {
    const setEnclosingComplexes = useSetEnclosingComplexes();
    const setOverlayPOI = useSetOverlayPOIOnce();

    return useCallback(
        async (venues: PointOfInterestEntities[]) => {
            if (!venues.length) return;

            setIsLoadingExploreEnclosingComplexes(true);
            const enclosingComplexesResponse = await fetchEnclosingComplexes(
                venues.map(({ id }) => id),
            );

            if (!Object.keys(enclosingComplexesResponse).length) {
                setIsLoadingExploreEnclosingComplexes(false);

                batch(() => {
                    venues.forEach((venue) => {
                        setOverlayPOI(
                            venue.id,
                            getNearbyPlaceOverlay(createPlaceWithNearbyResult(venue)),
                        );
                    });
                });

                return;
            } else {
                const enclosingComplexIds = Object.keys(enclosingComplexesResponse);

                batch(() => {
                    venues
                        .filter((venue) => !enclosingComplexIds.includes(venue.id))
                        .forEach((venue) => {
                            setOverlayPOI(
                                venue.id,
                                getNearbyPlaceOverlay(createPlaceWithNearbyResult(venue)),
                            );
                        });
                });
            }

            setEnclosingComplexes(enclosingComplexesResponse);
            setIsLoadingExploreEnclosingComplexes(false);

            batch(() => {
                // for each entry, set the first complex as a default selection for overlaying POI
                Object.entries(enclosingComplexesResponse).forEach(
                    ([baseVenueId, [defaultComplex]]) => {
                        setOverlayPOI(baseVenueId, getComplexPlaceOverlay(defaultComplex));
                    },
                );
            });
        },
        [setEnclosingComplexes, setOverlayPOI],
    );
};

export const useGetEnclosingComplexOptions = () => {
    const { enclosingComplexes } = useExploreUIState();
    return useCallback(
        (venueId: string) => (enclosingComplexes ? enclosingComplexes[venueId] : undefined),
        [enclosingComplexes],
    );
};
