const feature_flags_defaults_object = {
    /*
     * these are demo examples of a possible feature flags from launch-darkly
     * that are primitives, but not boolean:
     *    example where we create a FF in launch-darkly that can be
     *    a value of 'no-access' or 'basic-access' or 'full-access':
     * `_DEMO_access_level_string: 'no-access' as 'no-access' | 'basic-access' | 'full-access',`
     *    example where we create a FF in launch-darkly that can be
     *    a value between 0 to 9:
     * `_DEMO_access_level_number: 0,`
     */

    hide_property_overview_trade_area: false,
    print_pass_report_store_data_to_puppeteer_ff: false,
    enable_parcel_limit_warning_ff: false,
    enable_audience_profile_new_table_ff: false,
    enable_updated_audience_profile_print_layout_ff: false,
    enable_full_story_ff: false,
    enable_10_more_roads_ff: false,
    lock_tablet_landscape_mode: false,
    override_lock_tablet_landscape_mode: false,
    tooltip_trimmed_title: false,
    cpoi_available_categories_logic_ff: false,
    enable_marketplace_new_website_ff: false,
    enable_property_report_lazy_load_widgets_v1_ff: false,
    enable_property_report_lazy_load_widgets_v2_ff: false,
    enable_market_landscape_lazy_load_ff: false,
    enable_market_landscape_lazy_load_500ms_ff: false,
    enable_market_landscape_lazy_load_1000ms_ff: false,
    enable_market_landscape_lazy_load_1500ms_ff: false,
    enable_market_landscape_lazy_load_2000ms_ff: false,
    enable_market_landscape_async_enriched_metrics_ff: false,
    enable_retail_sales_market_landscape: false,
    enable_market_landscape_avoid_reload_minor_movements_ff: false,
    enable_audience_profile_cbg_limit_ff: false,
    enable_shp_file_service: false,
    enable_audience_profile_dataset_description_ff: false,
    enable_audience_profile_t0_ff: false,
    enable_audience_profile_persist_view_ff: false,
    enable_chain_info_highlights_ff: false,
    enable_chain_info_section_ff: false,
    unlock_retail_sales: false,
    hide_retail_sales: false,
    show_property_retail_sales: false,
    enable_retail_sales_customers_metrics_ff: false,
    enable_retail_sales_ranking_ff: false,
    enable_retail_sales_print_ff: false,
    remove_ticker_ui_from_chains_search_ff: false,
    enable_chains_coverage_warning_banner_ff: false,
    change_dwelltime_to_visit_duration_ff: false,
    enable_visiting_audience_terminology_ff: false,
    enable_ranking_print_in_property_overview: false,
    enable_accumulated_visits_pdf_export_ff: false,
    enable_visits_frequency_pdf_export_ff: false,
    enable_loyalty_vs_casual_pdf_export_ff: false,
    enable_widget_display_export_audience_overview_ff: false,
    enable_widget_display_export_length_of_stay_ff: false,
    enable_audience_overview_ff: false,
    retire_visitor_persona_ff: false,
    server_side_print_min_resolution_hf_ff: false,
    server_side_print_min_resolution_v2_ff: false,
    nearby_activity_risk_banner_ff: false,
    chain_category_picker_remove_empty_categories_ff: false,
    enable_privacy_link_in_account_window_ff: false,
    enable_hul_widget_disclaimer_v2_ff: false,
    enable_academy_iframe_logic_ff: false,
    enable_hul_widget_disclaimer_planned_dev_ff: false,
    enable_sales_forecasting_report: false,
    unlock_sales_forecasting_report: false,
    enable_app_permission_group_sales_forecasting_ff: false,
    enable_improved_sales_forecasting_distribution_tooltip_ff: false,
    create_poi_csv_injection_protection_ff: false,
    fe_telemetry_service_reports: false,
    fix_traffic_layer_terminology_ff: false,
    hide_company_tags: false,
    enable_mapbox_print_fix_ff: false,
    add_sales_metric_ranking_report_ff: false,
    enable_chat_agent_access: false,
    /** Compare Bar FFs: */
    enable_new_compare_bar_chains_ff: false,
    enable_new_compare_bar_chains_date_comparison_ff: false,
    enable_new_compare_bar_chains_audience_comparison_ff: false,
    enable_new_compare_bar_industry_ff: false,
    enable_new_compare_bar_industry_date_comparison_ff: false,
    enable_new_compare_bar_industry_audience_comparison_ff: false,
    enable_new_compare_bar_property_audience_comparison_ff: false,
    enable_new_compare_bar_property_date_comparison_ff: false,
    /** END Compare Bar FFs */
    sampling_disclaimer_visitor_by_origin_ff: false,
    enable_impersonate: false,
    trade_area_gis_no_dashborad_ff: false,
    unlock_audience_overview_full_experience: false,
    enable_audience_overview_overview_print_ff: false,
    retail_sales_compare_state_avg_label_ff: false,
    add_sales_metric_ranking_tenants_ff: false,
    validate_file_in_custom_report_ff: false,
    enable_datasets_marketplace_popup_ff: false,
    enable_ranking_in_retail_sales_ff: false,
    update_ranking_percentile_tooltips_ff: false,
    fix_venues_language_to_properties_ff: false,
    enable_improved_print_audience_overview_ff: false,
    enable_share_of_tag_metrics_ff: false,
    enable_visit_trends_ai_insights: false,
    enable_visit_trends_ai_insights_events: false,
    enable_visit_trends_ai_insights_summary: false,
    enable_visit_trends_ai_insights_benchmark: false,
    enable_visit_trends_ai_insights_weather: false,
    enable_visit_trends_ai_insights_holidays: false,
    tablet_explore_open_reports_in_same_tab_ff: false,
    enable_assist_sidebar_ff: false,
    visitor_by_origin_pdf_export_ff: false,
    enable_new_ranking_api_path: false,
    enable_customized_print_trade_area_report_ff: false,
    gender_pdf_export_ff: false,
    age_pdf_export_ff: false,
    enable_visit_trends_new_graph_design_ff: false,
    trade_area_coverage_by_distance_pdf_export_ff: false,
    set_gesture_handling_cooperative_by_default: false,
    trade_area_overlap_pdf_export_ff: false,
    enable_chain_demographics: false,
    enable_chain_demographics_ff: false,
    enable_chain_demographics_upsell_from_report_ff: false,
    enable_chain_demographics_audience_profile_ff: false,
    fullpage_load_disable_legacy_requests: false,
    fullpage_load_wait_for_backend: false,
    retail_sales_metric_pdf_export: false,
    retail_sales_estimation_pdf_export: false,
    retail_sales_avg_ticket_size_pdf_export: false,
    retail_sales_ranking_overview_pdf_export: false,
    retail_sales_day_of_week_pdf_export: false,
    retail_sales_transaction_trend_pdf_export: false,
    enable_server_side_print_puppeteer_single_viewport_ff: false,
    enable_audience_profile_500_mile_ff: false,
    enable_sales_forecasting_multiple_models_ff: false,
    enable_trade_area_report_react_migration_ff: false,
    enable_metrics_widget_refactor_api_call_ff: false,
    enable_tags_in_chain_demographics_ff: false,
    chain_demographics_tracts_to_cbg_breakpoint_zoom_level: 5,
    chain_demographics_cbg_to_high_cbg_breakpoint_zoom_level: 10,
    remove_tourism_widgets_recovery_dashboard: false,
    enable_ready_for_print_event_ff: false,
    enable_salesforce_data: false,
    enable_chain_demographics_map_bins_logic_ff: false,
    enable_baseline_trend_react_ff: false,
    disable_baseline_trend_angular_ff: false,
    enable_tag_table_update_ff: false,
    disable_visit_trends_ai_insights_event_enrichment_ff: false,
    enable_assist_google_places_image_ff: false,
    enable_chain_demographics_potential_market_ff: false,
    enable_chain_demographics_new_colors: false,
    enable_visit_trends_ai_insights_streetview_improvement_ff: false,
    enable_base_forecast_in_csv_ff: false,
    show_notification_quick_access: false,
    enable_chain_demographics_map_layers_show_at_once: false,
    enable_chain_demographics_one_option_dropdown_design_ff: false,
    enable_weighted_bins_chains_demos: false,
    enable_chain_demographics_pdf_export_ff: false,
    enable_visit_trends_ai_insights_benchmark_tooltips_ff: false,
    /** Site Selection FFs: */
    enable_site_selection_link_sharing: false,
    enable_sensitive_location_improvements_site_selection_ff: false,
    enable_site_selection_report: false,
    enable_site_selection_shared_with_me_tab: false,
    site_selection_enable_view_shared_reports: false,
    unlock_site_selection: false,
    enable_site_selection_match_analysis_site_similarity_location_age_filter: false,
    tablet_void_site_selection_open_match_analysis_in_same_tab_ff: false,
    site_selection_unavailable_due_to_maintenance: false,
    enable_site_selection_combined_chains_ff: false,
    enable_site_selection_complexes_ff: false,
    /** END Site Selection FFs */
    /** Market Landscape FFs: */
    enable_market_landscape_open_report_ff: false,
    enable_market_landscape_color_label_ff: false,
    enable_market_landscape_server_side_print_ff: false,
    enable_market_landscape_print_roads_layer_fix_ff: false,
    enable_market_landscape_print_server_side_flow_render_first_ff: false,
    enable_market_landscape_road_zoom_ff: false,
    enable_unlink_category_chain_market_landscape_ff: false,
    enable_market_landscape_name_only_mode_ff: false,
    enable_market_landscape_category_label_ff: false,
    enable_market_landscape_print_zoom_ff: false,
    update_market_landscape_date_warning_ff: false,
    enable_market_landscape_fix_refresh_ff: false,
    enable_market_landscape_always_show_selected_ff: false,
    market_landscape_pdf_duplicate_widget_ff: false,
    disable_billboard_category_market_landscape_ff: false,
    enable_full_address_market_landscape_print_ff: false,
    enable_market_landscape_edit_terms_ff: false,
    enable_customized_print_market_landscape_ff: false,
    disable_old_date_warning_market_landscape_ff: false,
    enable_market_landscape_rendering_order_change_ff: false,
    enable_road_tiles_market_landscape_ff: false,
    enable_auto_arrange_labels_market_landscape_ff: false,
    enable_auto_arrange_t0_market_landscape_ff: false,
    enable_market_landscape_visit_default_ff: false,
    enable_market_landscape_defaults_march_ff: false,
    enable_market_landscape_nice_png_ff: false,
    enable_market_landscape_all_visits_duration_segments_ff: false,
    enable_circle_tool_market_landscape_ff: false,
    enable_mapbox_market_landscape_map_ff: false,
    fix_market_landscape_zoom_freemium_ff: false,
    /** END Market Landscape FFs */
    visits_trend_assist_improve_yoy_benchmark_ff: false,
    enable_chain_visit_trend_masc_metrics_ff: false,
    enable_shared_retail_sales_metrics_widget_ff: false,
    disable_additional_chain_psychographic_datasets_ff: false,
    enable_chain_variance_compare_masc_metrics_ff: false,
    enable_chain_length_of_stay_widget: false,
    market_landscape_traffic_layer_configure_colors: false,
    enable_wighted_scale_legned: false,
    visitor_journey_routes_blur_fix_ff: false,
    enable_embedded_tableau_connected_app: false,
    enable_chain_demographics_pdf_export_map_cbgs_ff: false,
    enable_chain_demographics_pdf_export_map_venues_ff: false,
    enable_chain_demographics_percent_visits_70_or_100_ff: false,
    chain_demographics_map_hide_flagged_and_risky_venues_ff: false,
    enable_chain_audience_profile_map_tooltip_v2_ff: false,
    enable_chain_visits_variance_masc_metrics_ff: false,
    enable_chain_visits_ft_variance_v2_ff: false,
    enable_chain_year_over_year_v2_ff: false,
    enable_chain_year_over_year_masc_metrics_ff: false,
    enable_explore_drop_pin_ff: false,
    enable_explore_drop_pin_improvements_ff: false,
    enable_explore_cancel_search_call_when_selecting_non_autocomplete_recent_entity_FF: false,
    enable_sensitive_location_improvements_void_explore_ff: false,
    enable_sensitive_location_improvements_sales_forecasting_ff: false,
    enable_chain_daily_visits_widget: false,
    enable_chain_variance_list_masc_metrics_ff: false,
    enable_sales_forecasting_ms_1_improvements_ff: false,
    audience_profile_pdf_align_captured_column_width_ff: false,
    enable_chain_visit_frequency_widget: false,
    enable_chain_audience_overview_widget: false,
    enable_visits_trends_horizontal_view_ff: false,
    enable_trade_area_overlap_trade_service_api_ff: false,
    chain_variance_list_export_limits_ux_ff: false,
    enable_advancedmaps_header: false,
    enable_sales_forecasting_alignment_factor_warning_ff: false,
    enable_chain_audience_overview_upsell_banner_ff: false,
    enable_property_audience_profile_trade_area_api_ff: false,
    shared_widget_daily_hourly_length_of_stay_ff: false,
    enable_chain_hourly_visits_ff: false,
    enable_company_logo_name_in_my_zone: false,
    enable_my_properties_add_remove: false,
    enable_my_properties_landing: false,
    enable_my_zone_portfolio: false,
    enable_chain_demographics_only_100_percent_visits_temp_ff: false,
    enable_sorted_traffic_layer_ff: false,
    enable_industry_overview_individual_widget_pdf_export_ff: false,
    enable_chain_section_freemium_ux_v2_ff: false,
    enable_industry_section_freemium_ux_v2_ff: false,
    enable_chain_loyalty_trend_widget_ff: false,
    enable_chain_metrics_modal_ff: false,
    enable_chain_visitor_related_metrics_ff: false,
    enable_chain_visits_sq_ft_metrics_ff: false,
    enable_chain_avg_dwell_time_ff: false,
    disable_chain_demographics_non_default_duration_ff: false,
    /** Visits Duration FF: **/
    enable_short_visit_chains_all_visits_duration_segments_ff: false,
    disable_segmentations_ranking_chains_posie_entities_ff: false,
    enable_short_visit_industry_all_visits_duration_segments_ff: false,
    enable_short_visit_cpoi_property_ff: false,
    enable_short_visit_cpoi_portfolio_ff: false,
    enable_short_visit_copi_ranking_report_error_ff: false,
    enable_short_visits_ranking_all_visits_duration_segments_ff: false,
    enable_legacy_builders_for_regions_ff: false,
    disable_loyalty_trends_non_default_duration_ff: false,
    /** END Visits Duration FF */
    /** Contact Info FFs: */
    void_enable_contact_info: false,
    enable_contact_info_frandata: false,
    enable_contact_info_retail_lease_trac: false,
    enable_contact_info_chain_locations_ff: false,
    enable_contact_info_chain_representatives_ff: false,
    enable_contact_info_chain_franchisee_ff: false,
    enable_contact_info_headquarters_ff: false,
    enable_contact_info_access_from_chains_ff: false,
    enable_contact_info_franchisees_sidebar_ff: false,
    /** END Contact Info FFs */
    /** MHMD FFs **/
    enable_mhmd_chains_warning_ff: false,
    enable_chain_coverage_tooltip_ff: false,
    enable_mhmd_industry_warning_ff: false,
    enable_mhmd_void_ff: false,
    /** End MHMD FFs **/
    /** Void Analysis **/
    enable_void_analysis_create_report: false,
    va_enable_server_side_render_ff: false,
    enable_void_new_export_modal_ff: false,
    enable_void_analysis_filter_reorder: false,
    /** END Void Analysis **/
    enable_mapbox_googlemaps_cta_explore: false,
    audience_enable_ta_endpoint_for_hhi_and_ethnicity: false,
    enable_contacts_chain_locations_trade_area_ff: false,
    ff_xtra_marketplace_embedded: false,
    enable_maps_full_story_ff: false,
    disable_upgrade_message_freemium_mobile_ff: false,
    enable_ipads_on_app_ff: false,
    enable_ipad_web_app_ff: false,
    enable_visitor_journey_mobile_ff: false,
    chain_variance_list_export_periods_count_limit_ff: false,
    chain_variance_list_export_members_count_limit_ff: false,
    enable_retail_sales_pharmacies_disclaimers_ff: false,
    enable_unsupported_custom_filters_alert_sales_widgets_ff: false,
    enable_local_print_with_no_widgets_ff: false,
    enable_open_reports_from_favorite_places_ff: false,
    enable_planned_construction_widget: false,
    enable_market_landscape_properties_export_csv_ff: false,
    enable_hide_metrics_traffic_pins_ff: false,
    enable_hide_metrics_regions_ff: false,
    enable_chain_loyalty_report: false,
    enable_chain_visitor_journey_report: false,
    property_reports_bg_image_upsell_ff: false,
    ff_xtra_marketplace_new_tab: false,
    enable_tags_and_portfolio_with_one_entity_ff: false,
    align_industry_ranked_chains_print_behavior_ff: false,
    extended_bg_image_upsell_ff: false,
    enable_market_section: false,
    enable_infographics_ff: false,
    enable_map_studio_entry_points: false,
    send_clicked_search_results_sdr_ff: false,
    enable_retail_sales_clothing_sporting_disclaimers_ff: false,
    enable_widgets_loading_time: false,
    disable_click_navigation_ff: false,
    enable_monitor_splunk_url_mapping: false,
    sort_chain_results_by_availability_ff: false,
    show_lock_icons_on_limited_chains_ff: false,
    enable_focused_search_api: false,
    enable_updated_custom_poi_message_ff: false,
    enable_pl_mapbox_for_explore_map: false,
    enable_multiple_trade_areas_ml_mobile_ff: false,
    video_popup_upsell_ff: false,
    enable_pdf_cover_page_default_filters_as_applied_ff: false,
    tableau_get_account_dedicated_jwt_token_ff: false,
    enable_esri_basemaps_controller_explore_map: false,
    show_dynamic_upgrade_popup_ff: false,
    show_visitor_journey_routes_ff: false,
    enable_arc_gis_export_audience_profile_property: false,
    new_design_system_chain_selector_ff: false,
    enable_hidden_wigdets_message_mobile_ff: false,
    enable_remove_competitors_params_from_api_ff: false,
    change_ui_customize_mobile_ff: false,
    enable_send_email_customize_ff: false,
    enable_nearby_in_tags_and_portfolio_ff: false,
    Additional_dates_in_tags_portfolio: false,
    show_widgets_locked_cta_footer_ff: false,
    enable_esri_demo_mode: false,
    enable_visit_trend_annual_summary_lazy_loading_ff: false,
    enable_sales_trend_lazy_load_ff: false,
    enable_change_export_loading_text_ff: false,
    enable_comparable_config_mobile_ff: false,
    enable_opentelemetry_fe_dashboard_ui_ff: false,
    va_share_email_signup_page: false,
    void_enable_share_from_list: false,
    enable_site_selection_preview_mapbox: false,
    enable_explore_debounce_update_ff: false,
    enable_explore_debounce_update_100_ff: false,
    enable_explore_debounce_update_200_ff: false,
    show_new_locked_overlay_banner_ff: false,
    expose_property_widgets_for_freemium_ff: false,
    site_selection_on_tag_test_policy: false,
    enable_property_page_loading_time: false,
    site_selection_tags_allow_demog_score_by_state: false,
    /** DS Migration FFs **/
    accessibility_components_ff: false,
    /** End DS Migration FFs **/
    enable_freemium_extra_metrics_list: false,
    enable_fe_open_telemetry_spans_measurement_FF: false,
    enable_sales_forecasting_borderline_sensitivity_disclaimer_ff: false,
    enable_show_loading_export_widget_ff: false,
    enable_visits_page_customize_export_ff: false,
    enable_area_analysis_page_customize_export_ff: false,
    enable_parcel_info_page_customize_export_ff: false,
    enable_events_customize_export_ff: false,
    enable_demographic_page_customize_export_ff: false,
    enable_property_info_parcel_customize_export_ff: false,
    enable_property_info_highlights_customize_export_ff: false,
    enable_property_info_franchisee_customize_export_ff: false,
    enable_visitor_journey_page_customize_export_ff: false,
    enable_visits_variance_customize_export_ff: false,
    enable_variance_compare_customize_export_ff: false,
    enable_sentry_performance_monitor_ff: false,
    enable_performance_improvements_skip_availability_ff: false,
    enable_arcgis_reports_section_ff: false,
    disable_old_print_button_ff: false,
    site_selection_regional_demographic_v2: false,
    enable_site_selection_area_cannibalization_calc_ff: false,
    enable_export_hide_widget_insufficient_data_ff: false,
};

// NOTE: the type should be inferred from a pojo, not from a frozen object
export type FeatureFlags = typeof feature_flags_defaults_object;

export type FeatureFlagsKeys = keyof FeatureFlags;

export type UserFeatureFlags = Partial<FeatureFlags>;

export const FEATURE_FLAGS_DEFAULTS = Object.freeze(feature_flags_defaults_object);
