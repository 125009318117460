import { createSelector } from '@reduxjs/toolkit';

import { selectChainsSearchSelectedCategories } from '../select-chains-search-selected-category';
import { selectChainsSearchSelectedRegionName } from '../select-chains-search-selected-region-name';
import { selectChainsReportSearchSearchTerm } from '../select-chains-search-section';

export const selectChainsSearchCriteriaIsEmtpy = createSelector(
    selectChainsSearchSelectedRegionName,
    selectChainsSearchSelectedCategories,
    selectChainsReportSearchSearchTerm,
    (region, category, term) => !region && (!category || !category.length) && !term,
);
