import styles from './options-selected.module.scss';
import React from 'react';
import classNames from 'classnames';
import { OptionsSelectedData } from 'ui-components/multi-select/checkbox-list-v3/hooks/checkbox-list-hooks';

export const getSelectedOptionsElement = (
    optionsSelectedData?: OptionsSelectedData,
    className?: string,
) => {
    if (!optionsSelectedData) return null;

    const text = optionsSelectedData.clearMode
        ? 'No Filters Applied'
        : `${optionsSelectedData.selected}/${optionsSelectedData.total} Selected`;

    return <div className={classNames(className, styles.optionsSelectedData)}>{text}</div>;
};
