import { PrintWidgetContext } from 'features/print-menu/context/print-widget-context';
import { useContext } from 'react';
import { WidgetHideLoadingContext } from 'components/widget/widget-hide-loading/widget-hide-loading-context';

export const useWidgetPrintMode = () => {
    return useContext(PrintWidgetContext);
};

export const useHideWidgetLoading = () => {
    return useContext(WidgetHideLoadingContext);
};
