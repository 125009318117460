/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

export type DiamondIconUniqueProps = {
    width?: number | string;
    height?: number | string;
    color?: string;
    strokeColor?: string;
};

type DiamondIconProps = SVGProps<SVGSVGElement> & DiamondIconUniqueProps;

export const DiamondIcon = function DiamondIcon({
    width = 18,
    height = 19,
    color = 'var(--light-primary)',
    strokeColor = 'var(--primary-gray)',
    ...props
}: DiamondIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M4.21862 0.753407C4.1864 0.666345 4.06326 0.666345 4.03105 0.753407L3.4649 2.28339C3.26233 2.83083 2.83071 3.26245 2.28327 3.46502L0.753285 4.03117C0.666223 4.06339 0.666223 4.18653 0.753285 4.21874L2.28327 4.78489C2.83071 4.98746 3.26233 5.41908 3.4649 5.96652L4.03105 7.49651C4.06326 7.58357 4.1864 7.58357 4.21862 7.49651L4.78477 5.96652C4.98734 5.41908 5.41896 4.98746 5.9664 4.78489L7.49638 4.21874C7.58345 4.18653 7.58345 4.06339 7.49638 4.03117L5.9664 3.46502C5.41896 3.26245 4.98734 2.83083 4.78477 2.28339L4.21862 0.753407Z"
                fill={color}
            />
            <path
                d="M3.03925 6.97736L0.861779 9.15484C0.666516 9.3501 0.666517 9.66668 0.861779 9.86194L8.6464 17.6466C8.84167 17.8418 9.15825 17.8418 9.35351 17.6466L17.1381 9.86194C17.1942 9.80589 17.2342 9.73983 17.258 9.66952L9.25152 17.7197L5.13232 9.41333L7.93811 5.11621H13.0393L17.2843 9.49181C17.2803 9.36943 17.2315 9.24825 17.1381 9.15484L13.1258 5.14254C13.0321 5.04877 12.9049 4.99609 12.7723 4.99609H7.55715L6.22672 5.4884C5.88457 5.61501 5.61481 5.88477 5.4882 6.22692L4.92205 7.75691C4.64822 8.49693 3.60154 8.49693 3.32771 7.75691L3.03925 6.97736Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.57031 0.623268C4.41729 0.209724 3.83238 0.209725 3.67935 0.623268L3.11321 2.15326C2.94862 2.59805 2.59793 2.94874 2.15313 3.11333L0.623146 3.67948C0.209602 3.8325 0.209603 4.41741 0.623146 4.57044L2.15313 5.13658C2.59793 5.30117 2.94862 5.65186 3.11321 6.09666L3.67935 7.62664C3.83238 8.04019 4.41729 8.04019 4.57031 7.62664L5.13646 6.09666C5.30105 5.65186 5.65174 5.30117 6.09653 5.13658L7.62652 4.57044C8.04007 4.41741 8.04007 3.8325 7.62652 3.67948L6.09654 3.11333C5.65174 2.94874 5.30105 2.59805 5.13646 2.15326L4.57031 0.623268ZM3.8166 2.41353L4.12483 1.58053L4.43307 2.41353C4.67362 3.06362 5.18617 3.57617 5.83626 3.81672L6.66926 4.12496L5.83626 4.43319C5.18617 4.67375 4.67362 5.1863 4.43307 5.83638L4.12483 6.66938L3.8166 5.83638C3.57604 5.1863 3.06349 4.67375 2.41341 4.43319L1.58041 4.12496L2.41341 3.81672C3.06349 3.57617 3.57604 3.06362 3.8166 2.41353Z"
                fill={strokeColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.596614 8.88967L2.89601 6.59027L3.18249 7.36446L1.34358 9.20337H4.74423C4.75485 9.18503 4.76618 9.16699 4.77821 9.14931L7.34947 5.37109H6.54373L7.75671 4.92225C7.93067 4.85788 8.06374 4.7508 8.15591 4.62109H12.7723C13.0044 4.62109 13.2269 4.71328 13.391 4.87738L17.4033 8.88967C17.745 9.23138 17.745 9.7854 17.4033 10.1271L9.61868 17.9117C9.27697 18.2534 8.72295 18.2534 8.38124 17.9117L0.596614 10.1271C0.254905 9.7854 0.254905 9.23138 0.596614 8.88967ZM7.63355 16.1034L1.48354 9.95337H4.68401C4.69263 9.97596 4.70222 9.9983 4.7128 10.0203L7.63355 16.1034ZM8.8736 16.9533L5.51262 9.95337H12.4852L9.09881 16.9536C9.05322 17.0479 8.91891 17.0477 8.8736 16.9533ZM13.2859 10.0204C13.2966 9.99835 13.3063 9.97599 13.315 9.95337H16.5164L10.3216 16.1481L13.2859 10.0204ZM13.2569 9.20337H16.6563L12.8607 5.40771C12.8372 5.38426 12.8054 5.37109 12.7723 5.37109H10.6781L13.2239 9.15052C13.2356 9.16782 13.2466 9.18545 13.2569 9.20337ZM5.64863 9.20337H12.3553L9.95124 5.63451C9.92801 5.60002 9.88915 5.57935 9.84757 5.57935H8.18108C8.13971 5.57935 8.10102 5.59982 8.07774 5.63402L5.64863 9.20337Z"
                fill={strokeColor}
            />
        </svg>
    );
};
DiamondIcon.__tags = ['diamond', 'premium', 'upgrade', 'shine', 'shiny'] as string[];
