import type { PlacerResponse, PlacerResponseData } from '@placer-ui/types';
import type { SimpleFetchProps } from 'API/fetch/simple-fetch-no-error-handling';
import { simpleFetchNoErrorHandling } from 'API/fetch/simple-fetch-no-error-handling';
import type { MosaicMetadataResponse } from 'store/configuration/experian-mosaic/types';
import { getAppApiEndpoint } from 'core/default-endpoint-url';

const guruSimpleFetchWrapper = <ResponseType = any, BodyType = unknown>(
    fetchProps: SimpleFetchProps<BodyType>,
) => {
    const reactAppApiEndpoint = getAppApiEndpoint();
    return simpleFetchNoErrorHandling<ResponseType, BodyType>({
        ...fetchProps,
        targetUrl: `/2/guru/${fetchProps.targetUrl}`,
        endPoint: `${reactAppApiEndpoint}`,
        basePath: '',
    });
};

export function fetchMosaicData(): PlacerResponse<MosaicMetadataResponse> {
    return guruSimpleFetchWrapper<PlacerResponseData<MosaicMetadataResponse>, string>({
        targetUrl: 'datasets/mosaic/metadata',
        method: 'GET',
    });
}
