import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import styles from './nearby-selector.module.scss';
import {
    EntityData,
    NearbyEntityOption,
} from 'features/void-analysis/sections/empty-space/types/methods-selector-modal-types';
import { Select } from 'antd';
import { useNearbyOption } from 'features/void-analysis/sections/empty-space/hooks/use-method-selector-modal-hooks';
import { SelectArrowIcon } from 'components/assets/Icons/Icons';
import { useEmptySpacePermissions } from 'features/void-analysis/sections/empty-space/hooks/use-empty-space-permissions';

type NearbySelectorProps = {
    entityName: string;
    entityData: EntityData[] | undefined;
    setNearByEntitySelect: (nearByEntitySelect: NearbyEntityOption) => void;
    className?: {
        title?: string;
        selectContainer?: string;
        container?: string;
    };
    title?: string;
};
export const NearbySelector = ({
    entityName,
    entityData,
    setNearByEntitySelect,
    className,
    title,
}: NearbySelectorProps) => {
    const options = useNearbyOption(entityData, entityName);
    const { hasVoidAnalysisCustomTaMethodPermission } = useEmptySpacePermissions();

    const defaultTitle = useMemo(() => {
        return hasVoidAnalysisCustomTaMethodPermission
            ? 'Or select a nearby property true trade area:'
            : 'Select Nearby Property';
    }, [hasVoidAnalysisCustomTaMethodPermission]);

    useEffect(() => {
        entityData &&
            entityData[0] &&
            setNearByEntitySelect({
                ta_entity_type: entityData[0].type,
                ta_entity_id: entityData[0].id,
            } as NearbyEntityOption);
    }, [entityData, setNearByEntitySelect]);

    if (!entityData || !entityData[0]) {
        return null;
    }

    const onNearByChange = (entityId: string) => {
        const entityNearbySelected = entityData.find(({ id }) => id === entityId)!;
        setNearByEntitySelect({
            ta_entity_type: entityNearbySelected.type,
            ta_entity_id: entityNearbySelected.id,
        } as NearbyEntityOption);
    };

    return (
        <div className={classNames(styles.container, className?.container)}>
            <div className={classNames(styles.title, className?.title)}>
                {title ?? defaultTitle}
            </div>
            <div className={classNames(styles.selectContainer, className?.selectContainer)}>
                <Select
                    onChange={onNearByChange}
                    dropdownClassName={styles.dropdown}
                    defaultValue={entityData[0].id}
                    suffixIcon={<SelectArrowIcon />}
                    listHeight={214}
                >
                    {options}
                </Select>
            </div>
        </div>
    );
};
