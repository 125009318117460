import React from 'react';
import {
    BasicDropdownMenu,
    BasicDropdownMenuProps,
    Dropdown,
    Heading,
    UtilityChevronIcon,
} from '@placer-ui/components';
import styles from './inline-select.module.scss';

export const InlineSelect = <ValueType extends string = string>({
    onItemSelection,
    onSelection,
    items,
}: BasicDropdownMenuProps<ValueType>) => {
    const selectedItem = items.find((item) => item.isSelected)?.label || 'Select';

    return (
        <Dropdown
            closeOnSelection
            trigger={
                <div className={styles.root}>
                    <Heading variant={'h5'}>{selectedItem}</Heading>
                    <UtilityChevronIcon direction={'down'} />
                </div>
            }
            appendToParent
            menuWidth={200}
        >
            <BasicDropdownMenu
                items={items}
                onItemSelection={onItemSelection}
                onSelection={onSelection}
                maxVisibleItems={10}
            />
        </Dropdown>
    );
};
