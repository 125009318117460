export const safeJSONParse = <T>(value?: string | null, defaultValue?: T) => {
    if (!value) {
        return defaultValue;
    }

    try {
        return JSON.parse(value);
    } catch (e) {
        return defaultValue;
    }
};
