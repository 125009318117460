import React, { useCallback, useMemo } from 'react';
import { useExploreUIState } from 'features/explore/context/use-explore-ui-state';
import {
    useSetActiveId,
    useSetHoverId,
} from 'features/explore/context/use-explore-ui-state-actions';
import { useGetOverlayPOI } from 'features/explore/hooks/use-explore-overlay-poi';
import type {
    GoogleMapLayersProps,
    PoiEntity,
} from 'ui-components/google-map-layers/types/google-map-layers-types';
import {
    useEnableSelectionCheckbox,
    useToggleSelectedId,
} from 'features/explore/hooks/use-explore-selection';
import { ExplorePoiActionsButton } from 'features/explore/shared/components/explore-poi-actions-button/explore-poi-actions-button';
import { MarkerTooltip } from 'ui-components/google-map-layers/components/marker-tooltip/marker-tooltip';
import { useGetEnclosingComplexOptions } from 'features/explore/hooks/use-explore-enclosing-complex';
import { useExplorePermissions } from 'features/explore/hooks/use-explore-permissions';
import { useCheckCoordinateForNearbyReport } from 'features/explore/hooks/use-explore-nearby-activity';

export const useGoogleMapLayersProps = () => {
    const { selectedResults, hoverId, activeId, results: resultsList } = useExploreUIState();
    const setActiveMarkerId = useSetActiveId();
    const setHoverMarkerId = useSetHoverId();
    const toggleSelectedId = useToggleSelectedId();
    const enableSelectionCheckbox = useEnableSelectionCheckbox();
    const getOverlayPOI = useGetOverlayPOI();
    const getEnclosingComplexOptions = useGetEnclosingComplexOptions();
    const { hasStoreIdBadgePropertyCardPermission } = useExplorePermissions();
    const checkCoordinateForNearbyReport = useCheckCoordinateForNearbyReport();

    const markerTooltipComponent = useCallback(() => {
        return (
            <MarkerTooltip
                markerTooltipActionButton={(entity: PoiEntity) => (
                    <ExplorePoiActionsButton poi={entity} splunkComponent={'Property info card'} />
                )}
                showStoreId={hasStoreIdBadgePropertyCardPermission}
            />
        );
    }, [hasStoreIdBadgePropertyCardPermission]);

    return useMemo<GoogleMapLayersProps>(() => {
        return {
            selectedPois: selectedResults,
            setActiveId: setActiveMarkerId,
            setHoverId: setHoverMarkerId,
            allPois: resultsList,
            getOverlayPOI,
            getEnclosingComplexOptions,
            markerTooltipCheckboxConfig: {
                enableSelectionCheckbox,
                showCheckbox: true,
                onPoiToggleSelection: toggleSelectedId,
                tooltipCheckbox: checkCoordinateForNearbyReport,
            },
            disablePolyOnHoverForNearby: true,
            hoverId,
            activeId,
            markerTooltipComponent,
            mapboxBeta: true,
        };
    }, [
        selectedResults,
        setActiveMarkerId,
        setHoverMarkerId,
        resultsList,
        getOverlayPOI,
        getEnclosingComplexOptions,
        enableSelectionCheckbox,
        toggleSelectedId,
        checkCoordinateForNearbyReport,
        hoverId,
        activeId,
        markerTooltipComponent,
    ]);
};
