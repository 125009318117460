import React, { PropsWithChildren } from 'react';

import { LoadingBar } from 'ui-components/loading-bar/loading-bar';
import { Loading } from 'ui-components/loading/loading-component';

type PoiSelectionLoaderProps = {
    show: boolean;
};
export const PoiSelectionLoader = ({
    show,
    children,
}: PropsWithChildren<PoiSelectionLoaderProps>) => {
    return (
        <Loading show={show} customLoader={<LoadingBar />}>
            {children}
        </Loading>
    );
};
