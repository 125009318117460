/* eslint-disable react/jsx-no-target-blank */
import React, { useCallback } from 'react';
import classnames from 'classnames';
import logoUrl from 'shared/images/placer-logo-ai.svg';
import styles from './marketplace-sidebar.module.scss';

import { SidebarDialog } from 'ui-components/sidebar-dialog/sidebar-dialog';
import { CloseIcon } from 'components/assets/Icons/CloseIcon';
import { GoToIcon } from 'components/assets/Icons/Icons';
import { useSplunkCallback } from 'hooks/use-splunk-callback/use-splunk-callback';
import type { BaseEventData } from 'shared/types/splunk/events';
import { MARKETPLACE_EXTERNAL_URL } from 'shared/constants/marketplace';
import { appUiStore } from 'store/app-ui';

const { SidebarComponent, open, close, toggle } = new SidebarDialog();

// a hook to integrate with react's lifecycle in order to send splunk event upon openning.
const useOpen = () => {
    const sendSplunkEvent = useSplunkCallback<BaseEventData>();
    const appUi = appUiStore.useGet();

    return useCallback(
        (iframeSrc: string, splunkEventName?: string) => {
            if (splunkEventName) {
                sendSplunkEvent({
                    action: 'marketplace_popup_open',
                    value: splunkEventName,
                });
            }

            appUi.dialogs.marketplace.iframeSrc = iframeSrc;
            open();
        },
        [sendSplunkEvent, appUi],
    );
};

const MarketplaceSidebar = () => {
    const appUi = appUiStore.useGet((state) => state.dialogs.marketplace, { deep: true });
    const marketplaceDialogSrc = appUi.dialogs.marketplace.iframeSrc;
    let url = `${MARKETPLACE_EXTERNAL_URL}/${marketplaceDialogSrc}`;
    if (marketplaceDialogSrc.includes('?')) {
        url += '&';
    } else {
        url += '?';
    }
    url += 'utm_source=placer_iframe';
    const urlEmbedded = `${url}&view=embedded`;

    return (
        <SidebarComponent>
            <div className={styles.dialogHeader}>
                <span className={styles.logo}>
                    <img src={logoUrl} alt="placer ai logo" /> Marketplace
                </span>
                <a
                    href={url}
                    target="_blank"
                    rel="alternate"
                    className={classnames('reset-css-button', styles.openNewTab)}
                >
                    Open Marketplace
                    <GoToIcon />
                </a>
                <div className={styles.divider} />
                <button
                    onClick={close}
                    className={classnames('reset-css-button', styles.closeDialog)}
                >
                    <CloseIcon />
                </button>
            </div>
            <iframe
                className={styles.marketplaceIframe}
                src={urlEmbedded}
                title="Placer Marketplace"
            />
        </SidebarComponent>
    );
};

export { close, toggle, MarketplaceSidebar, useOpen };
