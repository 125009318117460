import { getPermissions } from 'core/flow-control';

export const getGoogleMapPermissions = () => {
    const {
        disable_maps_in_dashboard: hasDisabledMapsPermission,
        enable_map_market_landscape_widget: hasMarketLandscapeMapPermission,
        enable_map_visitor_journey_routes_widget: hasVisitorJourneyRoutesMapPermission,
        enable_map_explore: hasExplorePageMapPermission,
        enable_map_trade_area_profile_widget: hasTradeAreaProfileMapPermission,
        enable_map_trade_area_overlap_widget: hasTradeAreaOverlapMapPermission,
        enable_map_trade_area_heatmap_widget: hasTradeAreaHeatmapMapPermission,
        enable_map_visitors_by_origin_widget: hasVisitorByOriginMapPermission,
        enable_map_vehicle_traffic_volume_widget: hasVehicleTrafficVolumeMapPermission,
        enable_map_planned_development_widget: hasPlannedDevMapPermission,
        enable_map_crime_profile_widget: hasCrimeProfileMapPermission,
        enable_map_events_widget: hasEventsMapPermission,
        enable_map_chains_cover_map: hasChainsCoverMapPermission,
        enable_map_industry_cover_map: hasIndustryCoverMapPermission,
        enable_map_portfolio_report: hasTenantsMapThumbnailPermission,
        enable_map_alternative_report_options: hasTopBarMapPermission,
        enable_map_parcel_information_widget: hasParcelInfoMapPermission,
        enable_map_foot_traffic_recovery_by_city: hasFootTrafficRecoveryMapPermission,
        enable_map_recovery_by_zip_code: hasRecoveryByZipcodeMapPermission,
        enable_map_favorite_places_widget: hasFavoritePlacesMapPermission,
        enable_map_prior_post_compare_widget: hasPriorPostCompareMapPermission,
        enable_map_side_selection_explore_map: hasSiteSelectionMapPermission,
        enable_map_void_analysis_cover_map: hasVoidAnalysisCoverMapPermission,
        enable_map_match_analysis_cover_map: hasVoidAnalysisMatchMapPermission,
        enable_map_match_analysis_cannibalization_map: hasAnalysisCannibalizationMapPermission,
        enable_map_advanced_reports_top_search_map: hasVoidAnalysisTopSearchMapPermission,
        enable_map_property_cover_map: hasPropertyCoverMapPermission,
        enable_map_default_cover_page: hasDefaultCoverPageMapPermission,
        enable_map_audience_profile_widget: hasAudienceProfileMapPermission,
    } = getPermissions();

    return {
        hasMarketLandscapeMapPermission,
        hasVisitorJourneyRoutesMapPermission,
        hasExplorePageMapPermission,
        hasTradeAreaProfileMapPermission,
        hasTradeAreaOverlapMapPermission,
        hasTradeAreaHeatmapMapPermission,
        hasVisitorByOriginMapPermission,
        hasVehicleTrafficVolumeMapPermission,
        hasPlannedDevMapPermission,
        hasCrimeProfileMapPermission,
        hasEventsMapPermission,
        hasSiteSelectionMapPermission,
        hasChainsCoverMapPermission,
        hasIndustryCoverMapPermission,
        hasVoidAnalysisTopSearchMapPermission,
        hasVoidAnalysisCoverMapPermission,
        hasTenantsMapThumbnailPermission,
        hasVoidAnalysisMatchMapPermission,
        hasAnalysisCannibalizationMapPermission,
        hasTopBarMapPermission,
        hasParcelInfoMapPermission,
        hasFootTrafficRecoveryMapPermission,
        hasRecoveryByZipcodeMapPermission,
        hasFavoritePlacesMapPermission,
        hasPriorPostCompareMapPermission,
        hasDisabledMapsPermission,
        hasPropertyCoverMapPermission,
        hasDefaultCoverPageMapPermission,
        hasAudienceProfileMapPermission,
    };
};
