import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { usePoiSelectionContext } from 'features/poi-selection-map/context/poi-selection-context';
import { useMapEventListener } from 'ui-components/google-map/hooks/use-map-event-listener';
import { PoiSelectionMapConfiguration } from 'features/poi-selection-map/types/poi-selection-types';
import {
    usePoisListAsGeoLocation,
    useUpdateMapConfig,
} from 'features/poi-selection-map/hooks/poi-selection-hooks';
import {
    MAP_BOUNDS_CHANGE_DEBOUNCE,
    MAP_BOUNDS_PADDING,
} from 'features/poi-selection-map/constants/poi-selection-map-constants';
import type { Geolocation } from '@placer-ui/types';
import { defaultZoom } from 'ui-components/google-map/google-map-default-options';
import { useGetMapPositionWithOffset } from 'ui-components/google-map/hooks/use-get-map-position-with-offset';

export const useAddMapListenerEffect = (
    eventName: keyof google.maps.MapHandlerMap,
    callback: google.maps.MVCEventHandler<google.maps.Map, any>,
) => {
    const { mapRef } = usePoiSelectionContext();
    useMapEventListener({
        map: mapRef,
        callback,
        event: eventName,
    });
};

export const useUpdateMapConfigOnBoundsChanged = () => {
    const { elementRefObjects } = usePoiSelectionContext();
    const updateMapConfig = useUpdateMapConfig();

    const onBoundsChanged = () => {
        const hasValues = Object.values(elementRefObjects).every(({ ref, rect }) => {
            return ref && rect;
        });
        hasValues && updateMapConfig(elementRefObjects);
    };

    const debouncedSetRefsAndUpdateMap = useDebouncedCallback(
        onBoundsChanged,
        MAP_BOUNDS_CHANGE_DEBOUNCE,
    );
    useAddMapListenerEffect('bounds_changed', debouncedSetRefsAndUpdateMap);
};

export const useSetMapOptionsWithOffset = () => {
    const { mapConfig: currentConfig, mapRef } = usePoiSelectionContext();
    const getMapConfigWithOffset = useGetMapPositionWithOffset();
    return useCallback(
        (newConfig: Partial<PoiSelectionMapConfiguration>) => {
            if (mapRef && currentConfig && currentConfig.offsetDiff) {
                const offset = newConfig.mapOffset || currentConfig.mapOffset || 0;
                const { offsetDiff } = getMapConfigWithOffset({
                    mapRef,
                    offset,
                    zoom: newConfig.zoom,
                })!;
                mapRef.setOptions({
                    ...currentConfig,
                    ...newConfig,
                    ...(newConfig.center && {
                        center: {
                            lat: newConfig.center.lat,
                            lng: newConfig.center.lng + offsetDiff.lng,
                        },
                    }),
                });
            }
        },
        [currentConfig, getMapConfigWithOffset, mapRef],
    );
};

export const useIsGeolocationWithinMapBounds = () => {
    const { mapConfig } = usePoiSelectionContext();

    const boundsWithOffset = useMemo<google.maps.LatLngBounds | undefined>(() => {
        const bounds =
            mapConfig && mapConfig.swWithOffset && mapConfig.neWithOffset
                ? {
                      sw: {
                          lat: mapConfig.swWithOffset[0],
                          lng: mapConfig.swWithOffset[1],
                      },
                      ne: {
                          lat: mapConfig.neWithOffset[0],
                          lng: mapConfig.neWithOffset[1],
                      },
                  }
                : undefined;

        return bounds ? new google.maps.LatLngBounds(bounds?.sw, bounds?.ne) : undefined;
    }, [mapConfig]);

    return useCallback(
        (geolocation: Geolocation): boolean => {
            return boundsWithOffset
                ? boundsWithOffset.contains({
                      lat: geolocation.lat,
                      lng: geolocation.lng,
                  })
                : false;
        },
        [boundsWithOffset],
    );
};

export const useFilterGeolocation = () => {
    const { mapConfig } = usePoiSelectionContext();
    const isGeolocationWithinMapBounds = useIsGeolocationWithinMapBounds();

    return useCallback(
        (geolocation?: Geolocation): boolean => {
            //if mapConfig uses current bounds only, call isGeolocationWithinMapBounds()
            const useFitBounds = mapConfig?.useFitBounds ?? true;
            return (
                !!geolocation && (!useFitBounds ? isGeolocationWithinMapBounds(geolocation) : true)
            );
        },
        [isGeolocationWithinMapBounds, mapConfig?.useFitBounds],
    );
};

export const useFitBoundsEffect = () => {
    const { mapConfig, mapRef } = usePoiSelectionContext();
    const poisGeoLocations = usePoisListAsGeoLocation();
    const filterGeolocation = useFilterGeolocation();
    const boundsWereSet = useRef<boolean>(false);
    const filteredGeolocations = useMemo(
        () => poisGeoLocations?.filter(filterGeolocation),
        [filterGeolocation, poisGeoLocations],
    );

    useEffect(() => {
        if (!poisGeoLocations) {
            boundsWereSet.current = false;
        }
    }, [poisGeoLocations]);

    useEffect(() => {
        if (
            !mapRef ||
            !mapConfig?.useFitBounds ||
            !filteredGeolocations ||
            boundsWereSet.current ||
            !filteredGeolocations.length
        )
            return;

        const bounds = new google.maps.LatLngBounds();

        const padding = {
            top: MAP_BOUNDS_PADDING,
            bottom: MAP_BOUNDS_PADDING,
            right: MAP_BOUNDS_PADDING,
            left: (mapConfig?.mapOffset || 0) + MAP_BOUNDS_PADDING,
        };

        filteredGeolocations.forEach((geolocation) => {
            bounds.extend(geolocation);
        });

        mapRef.fitBounds(bounds, padding);
        boundsWereSet.current = true;

        if (mapRef.getZoom() >= defaultZoom) {
            mapRef.setCenter({
                lat: mapRef.getCenter().lat(),
                //compensation for slightly inaccurate bounds in large zoom
                lng: mapRef.getCenter().lng() - 0.015,
            });
        }
        // Call the getZoom after the fitBounds is finished
        google.maps.event.addListenerOnce(mapRef, 'idle', () => {
            mapRef.setZoom(Math.min(defaultZoom, mapRef.getZoom()));
        });
    }, [
        filterGeolocation,
        filteredGeolocations,
        mapConfig?.mapOffset,
        mapConfig?.useFitBounds,
        mapRef,
        poisGeoLocations,
    ]);
};

export const useOnZoomToGeolocation = () => {
    const setMapOptionsWithOffset = useSetMapOptionsWithOffset();
    return useCallback(
        (geolocation: Geolocation, zoom: number) => {
            setMapOptionsWithOffset({
                center: geolocation,
                zoom,
            });
        },
        [setMapOptionsWithOffset],
    );
};

export const useMapOptionsFromMapConfig = () => {
    const { mapConfig } = usePoiSelectionContext();
    return useMemo<google.maps.MapOptions>(() => {
        const { center, zoom, mapTypeId } = mapConfig || {};
        if (!center || !zoom) return {};
        return {
            center,
            zoom,
            mapTypeId,
        };
    }, [mapConfig]);
};

export const useChangeCurrentZoom = () => {
    const { mapRef } = usePoiSelectionContext();
    return useCallback(() => {
        if (!mapRef) return;
        const zoom = mapRef.getZoom();
        mapRef.setZoom(zoom < 8 ? 8 : zoom - 1);
    }, [mapRef]);
};
