import _ from 'lodash/fp';

import { goTo, goToInANewTab } from 'router/go-to';
import {
    ChainUrl,
    createChainUrl,
} from 'router/go-to-routes/go-to-chains-page/models/chains-url-model';

export type ChainURLState = ChainUrl[];

type GoToChainsPageOptions = {
    entities: ChainUrl[];
    addPurchasedCompetitor?: boolean;
    section?: string;
    newTab?: boolean;
};

export const goToChainsPage = ({
    newTab = false,
    section = 'overview',
    ...rest
}: GoToChainsPageOptions) => {
    const params = generateChainsLinkParams({
        ...rest,
        section,
    });
    const path = `chains/${section}${params.search}`;

    if (newTab) {
        goToInANewTab(path, origin);
    } else {
        goTo(`/${path}`);
    }
};

export const goToIndustryPage = ({
    newTab = false,
    section = 'overview',
    ...rest
}: GoToChainsPageOptions) => {
    const params = generateIndustryLinkParams({
        ...rest,
        section,
    });
    const path = `/industry/${section}${params.search}`;

    if (newTab) {
        goToInANewTab(path);
    } else {
        goTo(path);
    }
};

const generateChainsOrIndustryUrlParams = ({
    entities,
    addPurchasedCompetitor,
}: GoToChainsPageOptions) => {
    const primaryEntity = entities[0];
    const primaryUrlEntity = createChainUrl({
        id: primaryEntity.id,
        date_start: primaryEntity.date_start,
        date_end: primaryEntity.date_end,
        date_label: primaryEntity.date_label,
        addPurchasedCompetitor: addPurchasedCompetitor,
    });

    const competitors = _.tail(entities);
    const preparedEntities = [primaryUrlEntity, ...competitors];
    const params = `?state=${encodeURI(JSON.stringify(preparedEntities))}`;
    return params;
};

export const generateChainsLinkParams = ({
    entities,
    addPurchasedCompetitor,
    section = 'overview',
}: GoToChainsPageOptions) => {
    const params = generateChainsOrIndustryUrlParams({
        entities,
        addPurchasedCompetitor,
    });

    return {
        pathname: `/chains/${section}/`,
        search: params,
    };
};

export const generateIndustryLinkParams = ({
    entities,
    addPurchasedCompetitor,
    section = 'overview',
}: GoToChainsPageOptions) => {
    const params = generateChainsOrIndustryUrlParams({
        entities,
        addPurchasedCompetitor,
    });

    return {
        pathname: `/industry/${section}/`,
        search: params,
    };
};
