import React, { PropsWithChildren } from 'react';
import styles from './explore-drawer-option.module.scss';
import classNames from 'classnames';
import { PlaceWithFilters } from 'features/insights/store/poi/poi-types';

type ExploreDrawerOptionProps = {
    poi?: PlaceWithFilters;
    onActivate?: () => void;
    isActive: boolean;
    title: string;
    subtitle: string;
    testId?: string;
};

export const ExploreDrawerOption = ({
    isActive,
    title,
    subtitle,
    testId,
    children,
    onActivate,
}: PropsWithChildren<ExploreDrawerOptionProps>) => {
    const onClick = () => {
        !isActive && onActivate && onActivate();
    };

    return (
        <div
            className={classNames(styles.exploreDrawerOption, {
                [styles.isButton]: !isActive,
                [styles.isSelected]: isActive,
            })}
            data-testid={testId}
            onClick={onClick}
        >
            <div className={styles.description}>
                <div className={styles.title}>{title}</div>
                <div className={styles.subtitle}>{subtitle}</div>
            </div>
            {isActive && <div className={styles.actions}>{children}</div>}
        </div>
    );
};
