import React, { useEffect, useRef, useState } from 'react';
import styles from './styled-table.module.scss';

import { Table, TableProps } from 'ui-components/table/table';
import { BaseRowDataType } from 'ui-components/table/types';
import classNames from 'classnames';

const TABLE_HEADER_HEIGHT = 40;

export const StyledTable = <RowDataType extends BaseRowDataType>({
    className,
    classNamesMap,
    ...props
}: TableProps<RowDataType>) => {
    const tableContainerRef = useRef<HTMLDivElement>(null);
    const [scrollHeight, setScrollHeight] = useState<number>(0);

    const styledClassNamesMap = {
        wrapper: styles.wrapper,
    };

    useEffect(() => {
        if (tableContainerRef.current) {
            setScrollHeight(
                tableContainerRef.current.getBoundingClientRect().height - TABLE_HEADER_HEIGHT,
            );
        }
    }, []);

    return (
        <div ref={tableContainerRef} className={styles.tableContainer}>
            <Table<RowDataType>
                className={classNames(styles.table, className)}
                classNamesMap={{
                    ...styledClassNamesMap,
                    ...classNamesMap,
                }}
                toolbarWrapperMarginBottom={false}
                tablePaddingBottom={false}
                scroll={{
                    y: scrollHeight,
                    scrollToFirstRowOnChange: true,
                }}
                {...props}
            />
        </div>
    );
};
