import { createSelector } from '@reduxjs/toolkit';

import { selectChainsUiState } from 'features/chains/store/common';

export const selectChainsReportUiState = createSelector(
    selectChainsUiState,
    (uiState) => uiState.reportUiState,
);

export const selectIsMissingPermissionsCheckSuppressed = createSelector(
    selectChainsReportUiState,
    (uiState) => uiState.isMissingPermissionsCheckSuppressed,
);
