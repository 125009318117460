import Cookies from 'universal-cookie';
import { isDevelopment, isMobileApp } from 'utils/detect-environment';

export const placerCookies = new Cookies(null, {
    path: '/',
    // @ts-ignore - universal-cookie uses encodeURI by default. (missing types)
    encode: (value: string) => value,
});

export const loginCookieKey = 'login'; // login user cookie name
export const loginImpersonateCookieKey = 'login_impersonate'; // login impersonate user cookie name
export const loginCookiePath = '/';

export const getCookies = () => document.cookie.split(';');

export interface Cookie {
    key: string;
    value?: string;
    domain?: string;
    expires?: Date;
    httpOnly?: boolean;
    maxAge?: number;
    partitioned?: boolean;
    path?: string;
    secure?: boolean;
    sameSite?: 'strict' | 'lax' | 'none';
}

export const encodeCookieValue = (cookieValue: string) => btoa(cookieValue);
export const decodeCookieValue = (cookieValue: string) => atob(cookieValue);

export const setCookie = (cookie: Cookie) => {
    placerCookies.set(cookie.key, cookie.value, {
        domain: cookie.domain,
        expires: cookie.expires,
        httpOnly: cookie.httpOnly,
        // webview on localhost doesn't support secure cookies
        secure: isMobileApp() && isDevelopment() ? false : cookie.secure,
        sameSite: cookie.sameSite,
        maxAge: cookie.maxAge,
        path: cookie.path,
    });
};

export const deleteCookie = (cookie: Omit<Cookie, 'value'>) => {
    placerCookies.remove(cookie.key, {
        domain: cookie.domain,
        path: cookie.path,
    });
};

export const areCookiesEqual = (firstCookie: string, secondCookie: string) => {
    const secondCookieAttrs = secondCookie.split(';');
    return firstCookie.split(';').every((attr: string) => secondCookieAttrs.includes(attr));
};

export function isBase64Encoded(str: string) {
    try {
        return btoa(atob(str)) === str;
    } catch (err) {
        return false;
    }
}

export const getCookieValueByName = (name: string) => {
    return placerCookies.get(name);
};

export const getParsedCookieValue = (value: string) => {
    let decodedValue = value;
    if (isBase64Encoded(value)) {
        decodedValue = atob(value);
    }
    const parsed = JSON.parse(decodedValue);
    const matchingValues: string[] = parsed ? Object.values(parsed) : [];
    return matchingValues;
};

export const addCookieValueToObject = (existingValues: string[], value: string) => {
    if (existingValues.includes(value)) {
        console.warn(`This key ${value} already exists in this cookie. It must be a unique key`);
    }

    const newValue = JSON.stringify({
        ...existingValues,
        [existingValues.length]: value,
    });

    return btoa(newValue); //encode cookie value
};

export const getLoginCookie = () => getCookieValueByName(loginCookieKey);

export const defineCookies = (value: string) => {
    Object.defineProperty(document, 'cookie', {
        writable: true,
        value,
    });
};
