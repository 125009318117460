import { BaseDataType, requestData } from 'API/common/request-data/request-data';
import type { Dictionary } from '@placer-ui/types';
import { POLLING_STATUS, RETRY_MS } from 'API/common/poll-request/constants';
import { ERRORS } from 'API/common/constants';
import { ResponseStatus } from 'API/common/types';
import { FetchProps } from 'API/authentification/fetch-wrapper';
import { getDelayTimeOnErrorInProgress } from './utils';

const timerIDs: Dictionary<number> = {};
const attemptsCounterFetchIDs: Dictionary<number> = {};

const delay = (ms: number, fetchID: string) => {
    return new Promise<number>((resolve) => {
        const delayTime = getDelayTimeOnErrorInProgress(fetchID, attemptsCounterFetchIDs);

        const timerID = window.setTimeout(() => {
            resolve(timerID);
        }, delayTime || ms);

        timerIDs[fetchID] = timerID;
    });
};

const aggregateTargetUrl = (targetUrl: string, withStatus: boolean): string => {
    let newTargetUrl: string;
    if (withStatus) {
        newTargetUrl = targetUrl.includes('/status?')
            ? targetUrl
            : targetUrl.replace('?', '/status?');
    } else {
        newTargetUrl = targetUrl.replace('/status?', '?');
    }
    return newTargetUrl;
};

type RequestDataWithStatusProps<BodyType> = FetchProps<BodyType> & {
    token: string;
    fetchID: string;
    retryMsDelay?: number;
    signal?: AbortSignal;
    onApiRequestStatus?: (isCached: boolean) => void;
    title?: string;
};

type RetryRequestProps = {
    withStatus?: boolean;
    retryMS?: number;
    isCached?: boolean;
};

export function requestDataWithStatus<DataType extends BaseDataType = any, BodyType = unknown>({
    method = 'POST',
    fetchID,
    targetUrl,
    body,
    headers,
    token,
    retryMsDelay,
    signal,
    onApiRequestStatus,
    title,
}: RequestDataWithStatusProps<BodyType>): Promise<DataType> {
    return requestData<DataType, BodyType>({
        targetUrl,
        method,
        token,
        body,
        headers,
        signal,
        fetchID,
        title,
    }).then(
        (data) => {
            delete timerIDs[fetchID];
            delete attemptsCounterFetchIDs[fetchID];
            return data;
        },
        (errorByUid: Dictionary<ResponseStatus>) => {
            const retryRequest = ({
                withStatus = false,
                retryMS = RETRY_MS,
                isCached = true,
            }: RetryRequestProps) => {
                const newTargetUrl = aggregateTargetUrl(targetUrl, withStatus);
                onApiRequestStatus?.(isCached);
                return delay(retryMsDelay || retryMS, fetchID).then(() => {
                    return requestDataWithStatus({
                        targetUrl: newTargetUrl,
                        method,
                        token,
                        body,
                        fetchID,
                        retryMsDelay: retryMsDelay || retryMS,
                        signal,
                    });
                });
            };

            const generalError = errorByUid.common;
            if ([502, 503, 504].includes(generalError?.status)) {
                !attemptsCounterFetchIDs[fetchID]
                    ? (attemptsCounterFetchIDs[fetchID] = 1)
                    : attemptsCounterFetchIDs[fetchID]++;
                if (attemptsCounterFetchIDs[fetchID] < 4) {
                    return retryRequest({});
                }
            }
            switch (generalError && generalError.type) {
                case ERRORS.ERR_IN_PROGRESS:
                    !attemptsCounterFetchIDs[fetchID]
                        ? (attemptsCounterFetchIDs[fetchID] = 1)
                        : attemptsCounterFetchIDs[fetchID]++;
                    return retryRequest({
                        isCached: false,
                    });
                case POLLING_STATUS.ACCEPTED:
                    return retryRequest({
                        withStatus: true,
                        retryMS: 0,
                    });
                case POLLING_STATUS.RUNNING:
                case POLLING_STATUS.PENDING:
                    return retryRequest({
                        withStatus: true,
                        retryMS: RETRY_MS,
                    });
                case POLLING_STATUS.SUCCESS:
                    return retryRequest({
                        withStatus: false,
                        retryMS: 0,
                    });
                default:
                    delete timerIDs[fetchID];
                    delete attemptsCounterFetchIDs[fetchID];
                    return Promise.reject(errorByUid);
            }
        },
    );
}

export function stopFetchByIDs(fetchIDs: string[]) {
    fetchIDs.forEach((fetchID) => {
        window.clearTimeout(timerIDs[fetchID]);
        delete timerIDs[fetchID];
        delete attemptsCounterFetchIDs[fetchID];
    });
}
