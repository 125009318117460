import { FullStory, init } from '@fullstory/browser';

import { isAutomationTest } from 'shared/test-utils/automation-test';
import { UserSettings } from 'core/entities/user/user-settings';
import { isDevelopment } from 'utils/detect-environment/detect-environment';

const FREEMIUM = 'freemium';

export const fullStoryInit = (isFullStoryEnabled: boolean, userSettings: UserSettings) => {
    const { user_id, account } = userSettings;

    if (!isFullStoryEnabled || !user_id || isAutomationTest()) return;

    try {
        init(
            {
                orgId: 'o-1NAH30-na1',
                host: 'fullstory.com',
                /** global identifier for Fullstory object. equivalent to the imported `FullStory` */
                namespace: 'PlacerFS',
                /** if is in dev mode then will not record but will send a single event to FS */
                devMode: isDevelopment(),
                /** commented out so it will use the default (edge.fullstory.com) */
                // script: 'edge.fullstory.com/s/fs.js',
            },
            function onReady() {
                // Note: FullStory == window.PlacerFS
                FullStory('setIdentity', {
                    uid: user_id,
                    properties: {
                        displayName: user_id,
                        email: user_id,
                        accountId: account?.id ?? FREEMIUM,
                        accountName: account?.name ?? FREEMIUM,
                    },
                });
            },
        );
    } catch (err) {
        console.error(err);
    }
};
