import pluralize from 'pluralize';

import { VenuePOI, BillboardPOI, ChainPOI } from 'core/entities';
import { RecentEntitiesModel } from 'core/services/recent-entities-service/recent-entities-model';
import { goToChainsPage, goToInsightsPage } from 'router/go-to-routes';
import { setCustomUserSettingsRecentEntities } from 'store/auth/actions/set-custom-user-settings-recent-entities';
import { setTopSearchLoading } from 'store/header/search/actions/top-search-action-creators';
import { topSearchSlice } from 'store/header/search/top-search-slice';
import { AppDispatch } from 'store/store';

// TODO: Seems store should not be bounded to any feature and live inside a shared folder
import { chainsReportChainsSlice } from 'features/chains/store/chains-report-chains-store';
import { chainsReportSearchSlice } from 'features/chains/store/chains-report-search-store';
import type { Billboard, Chain, Place, PlaceType, Venue } from '@placer-ui/types';

export const topSearchOpenReport =
    (entity: RecentEntitiesModel) => async (dispatch: AppDispatch) => {
        dispatch(topSearchSlice.actions.resetState());
        if (VenuePOI.isVenue(entity as Venue) || BillboardPOI.isBillboard(entity as Billboard)) {
            goToInsightsPage({
                collection: pluralize(entity.type as PlaceType),
                id: entity.id,
            });
        }

        if (ChainPOI.isChain(entity as Chain)) {
            dispatch(setTopSearchLoading(true));
            const entities = [entity];

            dispatch(chainsReportChainsSlice.actions.resetState());
            dispatch(chainsReportSearchSlice.actions.resetState());
            goToChainsPage({
                entities,
                addPurchasedCompetitor: (entity as Place).purchased,
            });
        }

        dispatch(setCustomUserSettingsRecentEntities(entity));
    };
