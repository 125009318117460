import React, { ReactNode, SVGProps } from 'react';
import {
    ApparelIcon,
    ApparelIconSvg,
    DiningIcon,
    DiningIconSvg,
    EducationIcon,
    EducationIconSingleColorSvg,
    ElectronicsIcon,
    ElectronicsIconSvg,
    FitnessIcon,
    FitnessIconSvg,
    GroceriesIcon,
    GroceriesIconSvg,
    HomeImprovementIcon,
    HomeImprovementIconSvg,
    HotelIcon,
    HotelIconSvg,
    LeisureIcon,
    LeisureIconSvg,
    MedicalAndHealthIcon,
    MedicalAndHealthIconSvg,
    OfficeSuppliesIcon,
    OfficeSuppliesIconSvg,
    ShopAndServiceIcon,
    ShopAndServiceIconSvg,
    ShoppingCenterIcon,
    ShoppingCenterIconSvg,
    SpaAndBeautyIcon,
    SpaAndBeautyIconSvg,
    SuperstoresIcon,
    SuperstoresIconSvg,
    TravelIcon,
    TravelIconSvg,
} from 'components/assets/Icons/Icons';
import { BaseGroupCategory } from 'core/entities/category/types';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';
import {
    AirportFacilitiesIcon,
    AirportFacilitiesIconSvg,
} from 'components/assets/Icons/AirportFacilitiesIcon';
import { AttractionsIcon, AttractionsIconSvg } from 'components/assets/Icons/AttractionsIcon';
import { BarsAndPubsIcon, BarsAndPubsIconSvg } from 'components/assets/Icons/BarsAndPubsIcon';
import {
    StadiumsAndEventHallsIcon,
    StadiumsAndEventHallsIconSvg,
} from 'components/assets/Icons/StadiumsAndEventHallsIcon';
import {
    TheatersAndMusicVenuesIcon,
    TheatersAndMusicVenuesIconSvg,
} from 'components/assets/Icons/TheatersAndMusicVenuesIcon';
import { ClothingIcon, ClothingIconSvg } from 'components/assets/Icons/ClothingIcon';
import {
    DepartmentStoresIcon,
    DepartmentStoresIconSvg,
} from 'components/assets/Icons/DepartmentStoresIcon';
import {
    FashionAccessoriesIcon,
    FashionAccessoriesIconSvg,
} from 'components/assets/Icons/FashionAccessoriesIcon';
import { RestaurantsIcon, RestaurantsIconSvg } from 'components/assets/Icons/RestaurantsIcon';
import {
    CoffeeAndBakeriesAndDessertShopsIcon,
    CoffeeAndBakeriesAndDessertShopsIconSvg,
} from 'components/assets/Icons/CoffeeAndBakeriesAndDessertShopsIcon';
import {
    FastFoodAndQSRIcon,
    FastFoodAndQSRIconSvg,
} from 'components/assets/Icons/FastFoodAndQSRIcon';
import { IndustrialIcon, IndustrialIconSvg } from 'components/assets/Icons/IndustrialIcon';
import {
    MedicalCentersAndServicesIcon,
    MedicalCentersAndServicesIconSvg,
} from 'components/assets/Icons/MedicalCentersAndServicesIcon';
import {
    DrugstoresAndPharmaciesIcon,
    DrugstoresAndPharmaciesIconSvg,
} from 'components/assets/Icons/DrugstoresAndPharmaciesIcon';
import {
    DiscountAndDollarStoresIcon,
    DiscountAndDollarStoresIconSvg,
} from 'components/assets/Icons/DiscountAndDollarStoresIcon';
import {
    BanksAndFinancialServicesIcon,
    BanksAndFinancialServicesIconSvg,
} from 'components/assets/Icons/BanksAndFinancialServicesIcon';
import {
    ShippingAndStorageIcon,
    ShippingAndStorageIconSvg,
} from 'components/assets/Icons/ShippingAndStorageIcon';
import {
    HobbiesAndGiftsAndCraftsIcon,
    HobbiesAndGiftsAndCraftsIconSvg,
} from 'components/assets/Icons/HobbiesAndGiftsAndCraftsIcon';
import {
    ShoppingDistrictIcon,
    ShoppingDistrictIconSvg,
} from 'components/assets/Icons/ShoppingDistrictIcon';
import {
    GasStationsAndConvenienceStoresIcon,
    GasStationsAndConvenienceStoresIconSvg,
} from 'components/assets/Icons/GasStationsAndConvenienceStoresIcon';
import { RegionIcon, RegionIconSvg } from 'components/assets/Icons/RegionIcon';

export interface CategoryDisplayInfo {
    label: string;
    icon?: ReactNode;
    svg?: (props: CustomIconComponentProps | SVGProps<SVGSVGElement>) => JSX.Element;
}

export const groupCategoriesIcons: Record<BaseGroupCategory, CategoryDisplayInfo> = {
    Apparel: {
        icon: <ApparelIcon />,
        svg: ApparelIconSvg,
        label: 'Apparel',
    },
    'Beauty & Spa': {
        icon: <SpaAndBeautyIcon />,
        svg: SpaAndBeautyIconSvg,
        label: 'Beauty & Spa',
    },
    Dining: {
        icon: <DiningIcon />,
        svg: DiningIconSvg,
        label: 'Dining',
    },
    Education: {
        icon: <EducationIcon />,
        svg: EducationIconSingleColorSvg,
        label: 'Education',
    },
    Electronics: {
        icon: <ElectronicsIcon />,
        svg: ElectronicsIconSvg,
        label: 'Electronics',
    },
    Fitness: {
        icon: <FitnessIcon />,
        svg: FitnessIconSvg,
        label: 'Fitness',
    },
    Government: {
        label: 'Government',
    },
    Groceries: {
        icon: <GroceriesIcon />,
        svg: GroceriesIconSvg,
        label: 'Groceries',
    },
    'Home Improvement': {
        icon: <HomeImprovementIcon />,
        svg: HomeImprovementIconSvg,
        label: 'Home Improvement',
    },
    'Hotels & Casinos': {
        icon: <HotelIcon />,
        svg: HotelIconSvg,
        label: 'Hotels & Casinos',
    },
    Industrial: {
        label: 'Industrial',
    },
    Leisure: {
        icon: <LeisureIcon />,
        svg: LeisureIconSvg,
        label: 'LeisureIcon',
    },
    'Medical & Health': {
        icon: <MedicalAndHealthIcon />,
        svg: MedicalAndHealthIconSvg,
        label: 'Medical & Health',
    },
    'Office Supplies': {
        icon: <OfficeSuppliesIcon />,
        svg: OfficeSuppliesIconSvg,
        label: 'Office Supplies',
    },
    Other: {
        label: 'Other',
    },
    Region: {
        icon: <RegionIcon />,
        svg: RegionIconSvg,
        label: 'Region',
    },
    Residential: {
        label: 'Residential',
    },
    'Shopping Centers': {
        icon: <ShoppingCenterIcon />,
        svg: ShoppingCenterIconSvg,
        label: 'Shopping Centers',
    },
    'Shops & Services': {
        icon: <ShopAndServiceIcon />,
        svg: ShopAndServiceIconSvg,
        label: 'Shops & Services',
    },
    Superstores: {
        icon: <SuperstoresIcon />,
        svg: SuperstoresIconSvg,
        label: 'Superstores',
    },
    Travel: {
        icon: <TravelIcon />,
        svg: TravelIconSvg,
        label: 'Travel',
    },
    Workplace: {
        label: 'Workplace',
    },
};

export const primaryCategoriesIcons: Record<string, CategoryDisplayInfo> = {
    'Airport Facilities': {
        icon: <AirportFacilitiesIcon />,
        svg: AirportFacilitiesIconSvg,
        label: 'Airport Facilities',
    },
    Attractions: {
        icon: <AttractionsIcon />,
        svg: AttractionsIconSvg,
        label: 'Attractions',
    },
    'Banks & Financial Services': {
        icon: <BanksAndFinancialServicesIcon />,
        svg: BanksAndFinancialServicesIconSvg,
        label: 'Banks & Financial Services',
    },
    'Bars & Pubs': {
        icon: <BarsAndPubsIcon />,
        svg: BarsAndPubsIconSvg,
        label: 'Bars & Pubs',
    },
    'Coffee, Bakeries & Dessert Shops': {
        icon: <CoffeeAndBakeriesAndDessertShopsIcon />,
        svg: CoffeeAndBakeriesAndDessertShopsIconSvg,
        label: 'Coffee, Bakeries & Dessert Shops',
    },
    Clothing: {
        icon: <ClothingIcon />,
        svg: ClothingIconSvg,
        label: 'Clothing',
    },
    'Department Stores': {
        icon: <DepartmentStoresIcon />,
        svg: DepartmentStoresIconSvg,
        label: 'Department Stores',
    },
    'Discount & Dollar Stores': {
        icon: <DiscountAndDollarStoresIcon />,
        svg: DiscountAndDollarStoresIconSvg,
        label: 'Discount & Dollar Stores',
    },
    'Drugstores & Pharmacies': {
        icon: <DrugstoresAndPharmaciesIcon />,
        svg: DrugstoresAndPharmaciesIconSvg,
        label: 'Drugstores & Pharmacies',
    },
    'Fashion Accessories': {
        icon: <FashionAccessoriesIcon />,
        svg: FashionAccessoriesIconSvg,
        label: 'Fashion Accessories',
    },
    'Fast Food & QSR': {
        icon: <FastFoodAndQSRIcon />,
        svg: FastFoodAndQSRIconSvg,
        label: 'Fast Food & QSR',
    },
    'Gas Stations & Convenience Stores': {
        icon: <GasStationsAndConvenienceStoresIcon />,
        svg: GasStationsAndConvenienceStoresIconSvg,
        label: 'Gas Stations & Convenience stores',
    },
    'Hobbies, Gifts & Crafts': {
        icon: <HobbiesAndGiftsAndCraftsIcon />,
        svg: HobbiesAndGiftsAndCraftsIconSvg,
        label: 'Hobbies, Gifts & Crafts',
    },
    Industrial: {
        icon: <IndustrialIcon />,
        svg: IndustrialIconSvg,
        label: 'Industrial',
    },
    'Medical Centers & Services': {
        icon: <MedicalCentersAndServicesIcon />,
        svg: MedicalCentersAndServicesIconSvg,
        label: 'Medical Centers & Services',
    },
    'Office Supplies': {
        icon: <OfficeSuppliesIcon />,
        svg: OfficeSuppliesIconSvg,
        label: 'Office Supplies',
    },
    Restaurants: {
        icon: <RestaurantsIcon />,
        svg: RestaurantsIconSvg,
        label: 'Restaurants',
    },
    Region: {
        icon: <RegionIcon />,
        svg: RegionIconSvg,
        label: 'Region',
    },
    'Shipping & Storage': {
        icon: <ShippingAndStorageIcon />,
        svg: ShippingAndStorageIconSvg,
        label: 'Shipping & Storage',
    },
    'Shopping District': {
        icon: <ShoppingDistrictIcon />,
        svg: ShoppingDistrictIconSvg,
        label: 'Shopping District',
    },
    'Stadiums & Event Halls': {
        icon: <StadiumsAndEventHallsIcon />,
        svg: StadiumsAndEventHallsIconSvg,
        label: 'Stadiums & Event Halls',
    },
    'Theaters & Music Venues': {
        icon: <TheatersAndMusicVenuesIcon />,
        svg: TheatersAndMusicVenuesIconSvg,
        label: 'Theaters & Music Venues',
    },
};
