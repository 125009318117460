import { Button } from 'ui-components/button/button';
import React from 'react';
import { useOpenExploreReportLink } from 'features/explore/hooks/use-explore-report-link';
import styles from './explore-open-report-button.module.scss';
import { useGetOverlayPOI } from 'features/explore/hooks/use-explore-overlay-poi';
import { getSelectedPoiOverlay, isPoiRestrictedCoordinate } from 'features/explore/utils/utils';
import { PlaceOverlay } from 'ui-components/google-map-layers/types/google-map-layers-types';
import { useSendExploreSplunkEvent } from 'features/explore/hooks/use-explore-tracking';
import { useExploreUIState } from 'features/explore/context/use-explore-ui-state';
import { COORDINATES_ENTITY, reportSplunkMapOverlay } from 'features/explore/constants';
import { EMPTY_SPACE_POI_TYPE } from 'features/empty-space/constants/empty-space-constants';
import { Tooltip } from 'ui-components/tooltip/tooltip';
import classNames from 'classnames';
import { Spinner } from '@placer-ui/components';
import { useCheckCoordinateForNearbyReport } from 'features/explore/hooks/use-explore-nearby-activity';

type ExploreOpenReportButtonProps = {
    poi?: PlaceOverlay;
};

export const ExploreOpenReportButton = ({ poi }: ExploreOpenReportButtonProps) => {
    const { isLoading, openReport } = useOpenExploreReportLink();
    const sendSplunkEvent = useSendExploreSplunkEvent();
    const { selectedOverlayResults } = useExploreUIState();
    const checkCoordinateForNearbyReport = useCheckCoordinateForNearbyReport();
    const getOverlays = useGetOverlayPOI();

    let selectedOverlay = poi;
    let openBtnTooltipText = '';
    let isRestrictedCoordinate = false;
    let isBtnLoading = false;

    if (poi) {
        const poiOverlays = getOverlays(poi);
        selectedOverlay = getSelectedPoiOverlay(poiOverlays, poi?.overlayID);
        const checkCoorinateStatus = checkCoordinateForNearbyReport(poi);
        isBtnLoading = checkCoorinateStatus.isLoading;
        openBtnTooltipText = checkCoorinateStatus.text;
        isRestrictedCoordinate = isPoiRestrictedCoordinate(selectedOverlay);
    }

    const onOpenReport = async () => {
        if (isRestrictedCoordinate) return;
        sendSplunkEvent({
            component: 'Report Options Panel',
            action: 'open report',
            value:
                selectedOverlayResults.length > 1
                    ? 'compare'
                    : selectedOverlay?.overlayID &&
                      reportSplunkMapOverlay[selectedOverlay?.overlayID],
            selectedCategory: selectedOverlayResults
                .map((poi) =>
                    poi.type === EMPTY_SPACE_POI_TYPE ||
                    poi.provider_data?.entity_id === COORDINATES_ENTITY
                        ? 'Address-coordinate'
                        : poi.category,
                )
                .join(', '),
        });
        await openReport(selectedOverlay ?? poi);
    };

    return (
        <>
            <Tooltip small placement={'top'} title={openBtnTooltipText} useParentContainer>
                <Button
                    onClick={onOpenReport}
                    className={classNames(styles.openReportBtn, {
                        [styles.disabled]: isRestrictedCoordinate,
                    })}
                    type={'brand-primary-full'}
                    size="large"
                    disabled={isLoading || isBtnLoading}
                    data-testid={'open-report-drawer'}
                    block={true}
                >
                    {isBtnLoading && <Spinner variant={'white'} />}
                    {isLoading ? 'Loading...' : 'Open Report'}
                </Button>
            </Tooltip>
        </>
    );
};
