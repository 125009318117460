import { createAsyncThunk } from '@reduxjs/toolkit';

import { TagPOI } from 'core/entities';
import {
    ChainsSearchAutoCompleteOptionModel,
    isChainsSearchAutoCompleteOptionAState,
} from 'features/industry/store/chains-report-search-store/models';
import { getRegionFullName, isRegionNationwide } from 'core/services/regions-service';
import {
    getFilteredChainResults,
    setChainsSearchAutoCompleteValue,
    setChainsSearchSearchTerm,
    setChainsSearchSelectedRegion,
} from 'features/industry/store/chains-report-search-store';
import type { Chain } from '@placer-ui/types';

export const selectChainsSearchAutoCompleteOption = createAsyncThunk(
    'industry/select-search-auto-complete-option',
    (optionPayload: ChainsSearchAutoCompleteOptionModel, { dispatch }) => {
        let newAutoCompleteValue;
        if (TagPOI.isChain(optionPayload as Chain)) {
            const { name, area } = optionPayload as Chain;
            if (isRegionNationwide(area)) {
                newAutoCompleteValue = name;
            } else {
                newAutoCompleteValue = `${name} / ${getRegionFullName(area)}`;
            }
        } else if (isChainsSearchAutoCompleteOptionAState(optionPayload)) {
            newAutoCompleteValue = '';
            dispatch(setChainsSearchSelectedRegion(optionPayload.name));
        }

        if (newAutoCompleteValue !== undefined) {
            dispatch(setChainsSearchSearchTerm(newAutoCompleteValue));
            dispatch(setChainsSearchAutoCompleteValue(newAutoCompleteValue));
            dispatch(getFilteredChainResults({ chainsEntityModels: [] }));
        }
    },
);
