import {
    benchMarksTitles,
    providersTitles,
    regionTitles,
} from 'components/metric-selector/metrics-meta';
import type { Dictionary } from '@placer-ui/types';
import { MultiSelectOption } from 'ui-components/multi-select/types';
import { getMetricsAndRankFromKey } from 'features/my-zone-properties/utils/metrics-utils';
import { Filters, MetricsInfoWithKey } from 'components/metric-selector/types/types';
import { PropertyRanksType } from 'types/property-ranks';

export const getBenchmarkByRanksType = (rankType: PropertyRanksType | undefined) => {
    return rankType ? benchMarksTitles?.[rankType.split('.')[0]] : '';
};

export const getRegionByRankType = (rankType: PropertyRanksType | undefined) => {
    return rankType ? regionTitles?.[rankType.split('.')[1]] : '';
};

export const getFilterArray = (
    list: string[],
    filtersDataNameMapping: Dictionary<string>,
): MultiSelectOption[] => {
    const filterArr = [...list.values()].reduce<{ [key in string]: MultiSelectOption }>(
        (acc, item) => {
            const name = filtersDataNameMapping[item];
            acc[item] = {
                name: name,
                value: item,
                selected: true,
            };
            return acc;
        },
        {},
    );
    return Object.values(filterArr);
};

export const getFilters = (metricList: MetricsInfoWithKey[], filters: Filters): string[] => {
    return metricList
        .filter(({ key, title, provider, category, region, benchmark, benchmarkCategory }) => {
            const { rank: rankType } = getMetricsAndRankFromKey(key);
            const providerName = provider && providersTitles[provider];

            const name = `${title}${benchmark ? ' - ' + benchmark + ' Rank' : ''}, ${region}`;
            const noFilterData =
                !filters.dataProvider &&
                !filters.dataCategory &&
                (!rankType || !filters.benchmarkAgainst);

            const rankValid =
                !rankType ||
                (benchmarkCategory && filters?.benchmarkAgainst?.includes(benchmarkCategory));
            const validProvider = provider && filters?.dataProvider?.includes(provider);
            const validCategory = category && filters?.dataCategory?.includes(category);
            const searchFilterValue = filters?.search?.toLowerCase() ?? '';
            const searchTerm =
                name.toLowerCase().includes(searchFilterValue) ||
                providerName?.toLowerCase().includes(searchFilterValue);
            const validFiltersList = validProvider && validCategory && rankValid;
            const validFilters = (validFiltersList || noFilterData) ?? false;
            return searchTerm && validFilters;
        })
        .map(({ key }) => key);
};
