import { useCallback } from 'react';

export const useScrollToHeaderPosition = () => {
    return useCallback((top: number) => {
        window.scrollTo({
            top,
            behavior: 'smooth',
        });
    }, []);
};
