import React, { ReactNode } from 'react';
import { Button as AntButton } from 'antd';
import styles from './select-and-clear-all.module.scss';
import classNames from 'classnames';
import { SelectAllConfiguration } from 'ui-components/multi-select/types';
import { Tooltip } from 'ui-components/tooltip/tooltip';
import { HelpButton } from 'ui-components/help-button/help-button';
import { ELEVIO_DEFAULT_TOOLTIP } from 'utils/elevio/elevio';

export type SelectAndClearAllType = 'select all' | 'clear all';

type SelectAndClearAllProps = {
    onClick: (type: SelectAndClearAllType) => void;
    disabledClearAll: boolean;
    disabledSelectAll: boolean;
    selectAllButtonsContainerClassName?: string;
    selectAllConfiguration?: SelectAllConfiguration;
    disableSelectAllTooltip?: ReactNode;
    elevioId?: string | number;
};

type LinkButtonProps = {
    type: SelectAndClearAllType;
    onClick: SelectAndClearAllProps['onClick'];
    disabled: boolean;
    label: string;
};

const LinkButton = ({ type, onClick, disabled, label }: LinkButtonProps) => (
    <AntButton
        className={classNames({
            [styles.activeButton]: !disabled,
            [styles.clearAll]: type === 'clear all',
            [styles.selectAll]: type === 'select all',
        })}
        onClick={() => onClick(type)}
        type="link"
        disabled={disabled}
    >
        {label}
    </AntButton>
);

export const selectAndClearConfiguration: SelectAllConfiguration = {
    selectAll: {
        enabled: true,
        label: 'Select All',
    },
    clearAll: {
        enabled: true,
        label: 'Clear',
    },
    enableSelectedOptionsDisplay: true,
};

export const defaultSelectAllConfiguration: SelectAllConfiguration = {
    selectAll: {
        enabled: true,
        label: 'Select All',
    },
    clearAll: {
        enabled: false,
    },
};

export const disableSelectAndClearAll: SelectAllConfiguration = {
    selectAll: {
        enabled: false,
    },
    clearAll: {
        enabled: false,
    },
};

export const SelectAndClearAll = ({
    onClick,
    disabledSelectAll,
    disabledClearAll,
    selectAllButtonsContainerClassName,
    selectAllConfiguration,
    disableSelectAllTooltip,
    elevioId,
}: SelectAndClearAllProps) => {
    if (!selectAllConfiguration) return null;

    return (
        <div
            className={classNames(
                styles.selectAllButtonsContainer,
                selectAllButtonsContainerClassName,
                {
                    [styles.onlyClearAll]:
                        selectAllConfiguration.clearAll?.enabled &&
                        !selectAllConfiguration.selectAll?.enabled,
                },
            )}
        >
            {selectAllConfiguration.selectAll?.enabled &&
                selectAllConfiguration.selectAll?.label &&
                (disableSelectAllTooltip ? (
                    <div className={styles.selectAllWrapper}>
                        <Tooltip small placement="top" title={disableSelectAllTooltip}>
                            <div>
                                <LinkButton
                                    type={'select all'}
                                    onClick={onClick}
                                    disabled={disabledSelectAll}
                                    label={selectAllConfiguration.selectAll?.label}
                                />
                            </div>
                        </Tooltip>
                        {elevioId && (
                            <HelpButton
                                className={styles.helpBtn}
                                toolTipText={ELEVIO_DEFAULT_TOOLTIP}
                                toolTipPlacement="top"
                                elevioDataId={elevioId}
                            />
                        )}
                    </div>
                ) : (
                    <LinkButton
                        type={'select all'}
                        onClick={onClick}
                        disabled={disabledSelectAll}
                        label={selectAllConfiguration.selectAll?.label}
                    />
                ))}
            {selectAllConfiguration.clearAll?.enabled && selectAllConfiguration.clearAll?.label && (
                <LinkButton
                    type={'clear all'}
                    onClick={onClick}
                    disabled={disabledClearAll}
                    label={selectAllConfiguration.clearAll?.label}
                />
            )}
        </div>
    );
};
