import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import { chainsAPI } from 'API/chains-api';
import { ChainsReportState } from 'features/chains/store/chains-store';
import { selectDateLimits } from 'store/selectors/app-selectors';
import { dateFormats } from 'utils/date/date';
import { TopVenues } from 'core/dto/top-venues';
import type { ChainPlaceTypes, Place, PlacerEntityWrapper } from '@placer-ui/types';

const createGetSearchTopVenuesById = (chainsApi: typeof chainsAPI) =>
    createAsyncThunk<
        TopVenues,
        {
            chainId: string;
            chainType: ChainPlaceTypes;
        }
    >('chains/get-search-top-venues', async ({ chainId, chainType }, thunkAPI) => {
        const state = thunkAPI.getState() as ChainsReportState;
        const { maxDate } = selectDateLimits(state);
        const maxDateDayOne = moment(maxDate).clone().format('YYYY-MM-01');
        const isMaxDateLastDayOfMonth = moment(maxDate)
            .clone()
            .endOf('month')
            .isSame(maxDate, 'day');
        let startDate = moment(maxDateDayOne)
            .subtract(1, 'months')
            .format(dateFormats.dashedFullDate);
        let endDate = moment(maxDateDayOne).subtract(1, 'day').format('YYYY-MM-DD');

        // rare case when maxDate is last day of month
        if (isMaxDateLastDayOfMonth) {
            startDate = maxDateDayOne;
            endDate = maxDate;
        }

        const params = {
            mode: 'top_venues',
            chainType,
            chainId,
            startDate,
            endDate,
        };
        const apiResponse = await chainsApi.getTopVenues(params);
        if (apiResponse.error_code) {
            return thunkAPI.rejectWithValue(apiResponse.error_code); // TODO: cover error flow
        }

        const {
            data: { results, total_ft, total_results },
        } = apiResponse;

        return {
            number_of_venues: total_results,
            venues: results.map((result: PlacerEntityWrapper<Place>) => result.info),
            monthly_ft: total_ft,
        };
    });

export const getTopVenuesByChainId = createGetSearchTopVenuesById(chainsAPI);
