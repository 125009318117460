import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

import { IconProps } from 'components/assets/Icons/Icons';

/* eslint-disable max-len */
export const LeisureIconSvg = (props: CustomIconComponentProps | SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 12 12">
        <g fill="currentColor" fillRule="evenodd">
            <path d="M3 0a1.5 1.5 0 003 0h4.5a.5.5 0 01.5.5V3a3 3 0 01-3 3H4a3 3 0 01-3-3V.5a.5.5 0 01.5-.5H3zm1.5 4a.5.5 0 100 1 .5.5 0 000-1zM7 2a1 1 0 100 2 1 1 0 000-2z" />
            <rect width="1" height="7" x="5.5" y="5" rx=".5" transform="rotate(-180 6 8.5)" />
            <rect width="1" height="6" x="5.5" y="8.5" rx=".5" transform="rotate(90 6 11.5)" />
        </g>
    </svg>
);

export const LeisureIcon = (props: IconProps) => <Icon component={LeisureIconSvg} {...props} />;
