import { tagsApi } from 'API/tags-api';
import { ReportDates } from 'features/poi-selection-map/types/poi-selection-types';

export const PoiSelectionApi = {
    getAllTags: () => {
        return tagsApi.getAllTags();
    },
    getAllPlacesForTag: (tagId: string, reportDates: ReportDates) => {
        return tagsApi.getPlacesForTagWithoutComparisonData({
            id: tagId,
            ...reportDates,
        });
    },
};
