import { useCallback } from 'react';
import type { Tag } from '@placer-ui/types';
import { useSelector } from 'react-redux';
import { userSelector } from 'store/selectors/app-selectors';

type TagsLists = {
    personalTags: Tag[];
    companyTags: Tag[];
};

export const useGetTagsLists = () => {
    const user = useSelector(userSelector);
    return useCallback(
        (tags: Tag[]): TagsLists => {
            const personalTags: Tag[] = [];
            const companyTags: Tag[] = [];
            tags.forEach((tag) => {
                if (tag.owner.username === user?.username) {
                    personalTags.push(tag);
                } else {
                    companyTags.push(tag);
                }
            });
            return {
                personalTags,
                companyTags,
            };
        },
        [user?.username],
    );
};
