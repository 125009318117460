import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { capitalize } from 'lodash';
import type { FilterType } from '@placer-ui/types';
import { FilterConfig } from 'features/insights/types/filter-types';
import {
    dateFormats,
    getSameMonthFormatRange,
    getSameYearFormatRange,
    getSimpleFormatDate,
} from 'utils/date/date';
import { selectReportDefaultDates } from 'features/insights/store/selectors/insights-selectors';
import { useCreateHouseholdIncomeOptions } from 'features/mobile-report/components/analyze-page/hooks/use-create-household-income-filter';
import { APP_CONSTS } from 'core/constants/app-consts';
import { createTimeOptions } from 'features/insights/consts/filters/time-of-day-filter';
import { WEEK_DAYS } from 'utils/date/consts';
import { FilterDescriptionOptions, GetDescriptionFunction } from './types';
import { VISITS_DURATION_TITLE_DICT } from 'shared/constants/visit-duration';
import { createLabelAndRangeValue } from 'ui-components/date-picker/date-hooks/use-relative-preset';
import merge from 'lodash/merge';
import mapValues from 'lodash/mapValues';

const FILTERS_LABELS = APP_CONSTS.filter.attributions.labels;
const OPERATORS = APP_CONSTS.filter.operators;
const OPERATORS_LABELS = {
    [OPERATORS.LESS_THEN]: FILTERS_LABELS.LESS_THEN,
    [OPERATORS.MORE_THEN]: FILTERS_LABELS.MORE_THEN,
    [OPERATORS.BETWEEN]: FILTERS_LABELS.BETWEEN,
};

export const useFilterDefaultDates = () => {
    const defaultDates = useSelector(selectReportDefaultDates);
    const availablePresetDates = merge(
        mapValues(
            createLabelAndRangeValue(
                ['Last full 3 months', 'Last full 6 months', 'Last full 12 months'],
                defaultDates?.maxDataDate!,
            ),
            (preset) => {
                return {
                    name: preset.label,
                    chosenLabel: preset.label,
                    key: preset.label,
                    end: getSimpleFormatDate(preset.value[1], dateFormats.dashedFullDate),
                    start: getSimpleFormatDate(preset.value[0], dateFormats.dashedFullDate),
                };
            },
        ),
        defaultDates?.dates,
    );

    return availablePresetDates;
};

const getMiOptionalRangeDescription = ({ operator, value }: FilterConfig) => {
    const valueFormat = (num: number | string) => Number(num).toFixed(2);
    const operatorDescription = `${OPERATORS_LABELS[operator]}`;
    let filterValueDescription = '';
    if (operator === 'between') {
        value = value as [];
        filterValueDescription = `
            ${valueFormat(value[0])} mi /
            ${valueFormat(value[1])} mi`;
    } else {
        filterValueDescription = `${valueFormat(value as number)} mi`;
    }
    return `${operatorDescription} / ${filterValueDescription}`;
};

export const useFilterTypeToGetFilterDescription = (): Partial<
    Record<FilterType, GetDescriptionFunction>
> => {
    const defaultDates = useFilterDefaultDates();
    const householdIncomeOptions = useCreateHouseholdIncomeOptions();
    const timeOptions = createTimeOptions()!;

    return useMemo(
        () => ({
            visits_type: ({ value }: FilterConfig<string[]>) => value.map(capitalize).join(', '),
            days_of_week: ({ value }: FilterConfig<number[]>) =>
                `${FILTERS_LABELS.DAYS_OF_WEEK} / ${value.map((day) => WEEK_DAYS[day]).join(', ')}`,
            visit_duration: ({ value, operator }: FilterConfig<number>) => {
                if (value === 0) {
                    return VISITS_DURATION_TITLE_DICT.all_visits;
                }

                return `${operator === OPERATORS.LESS_THEN ? 'Under' : 'Over'} ${value} min`;
            },
            home_distance: ({ value, operator }) =>
                `${FILTERS_LABELS.HOME_DISTANCE} / ${getMiOptionalRangeDescription({
                    value,
                    operator,
                })}`,
            work_distance: (filterConfig) =>
                `${FILTERS_LABELS.WORK_DISTANCE} / ${getMiOptionalRangeDescription(filterConfig)}`,
            household_income: ({ value }: FilterConfig<string[]>) =>
                `${FILTERS_LABELS.HOUSEHOLD_INCOME_V2} /
                 ${value
                     .map(
                         (income) =>
                             householdIncomeOptions.find((option) => option.value === income)?.name,
                     )
                     .join(', ')}`,
            visit_frequency: ({ value, operator }: FilterConfig<number[] | number>) => {
                let valueString = '';
                if (operator === OPERATORS.BETWEEN && Array.isArray(value)) {
                    valueString = `Min: ${value[0]} Max: ${value[1]}`;
                } else if (
                    operator === OPERATORS.MORE_THEN ||
                    operator === OPERATORS.MORE_THEN_OR_EQUAL
                ) {
                    valueString = `Min: ${value}`;
                } else if (
                    operator === OPERATORS.LESS_THEN ||
                    operator === OPERATORS.LESS_THEN_OR_EQUAL
                ) {
                    valueString = `Max: ${value}`;
                }
                return `Number of Visits / ${valueString}`;
            },
            time: ({ value }: FilterConfig<string[]>) => {
                const [start, end] = value[0].split('-');
                const startTimeLabel = timeOptions.find((option) => option.value === start)?.label;
                const endTimeLabel = timeOptions.find((option) => option.value === end)?.label;
                return `${FILTERS_LABELS.TIME_OF_DAY} / ${startTimeLabel} - ${endTimeLabel}`;
            },
            date: ({ value }: FilterConfig<string[]>) => {
                const [start, end, , dateLabel] = value;
                const date = Object.values(defaultDates || {}).find(
                    (date) =>
                        date.start === start &&
                        date.end === end &&
                        (dateLabel ? date.key === dateLabel : true),
                );

                const monthDayAndYear = getSameMonthFormatRange(start, end);
                if (monthDayAndYear) {
                    return monthDayAndYear;
                }

                const formattedDate = getSameYearFormatRange(
                    start,
                    end,
                    dateFormats.monthDay,
                    dateFormats.monthDayAndYear,
                );

                return date?.name || formattedDate;
            },
            data_source: ({ value }: FilterConfig<string>) => 'WIP',
        }),
        [defaultDates, householdIncomeOptions, timeOptions],
    );
};

export const useDesktopFilterDescription = () => {
    const filterTypeToGetFilterDescription = useFilterTypeToGetFilterDescription();
    return useCallback(
        (
            key: FilterType,
            filterConfig?: FilterConfig,
            options: FilterDescriptionOptions = { isCompact: false },
            all?: any[],
        ) => {
            return filterTypeToGetFilterDescription[key]?.(filterConfig!, options, all) ?? '';
        },
        [filterTypeToGetFilterDescription],
    );
};
