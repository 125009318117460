import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import type { Dictionary } from '@placer-ui/types';
import styles from '../checkbox-list-v2/checkbox-list.module.scss';

import { MultiSelectOption, SelectAllConfiguration } from 'ui-components/multi-select/types';
import { Collapse } from 'ui-components/collapse/collapse';
import { Tooltip } from 'ui-components/tooltip/tooltip';
import { OuterSearchInput } from 'ui-components/table/components/outer-search-input/outer-search-input';
import {
    defaultSelectAllConfiguration,
    SelectAndClearAll,
    SelectAndClearAllType,
} from 'ui-components/multi-select/checkbox-list-v3/select-and-clear-all/select-and-clear-all';
import {
    ShowResultsButton,
    ShowResultsButtonType,
} from 'ui-components/multi-select/checkbox-list-v3/show-results-button/show-results-button';
import {
    getExpandedValues,
    getFilteredOptionsBySearchString,
    getTotalOptionsSelected,
    isFinalSelectedOptions,
    OptionsSelectedData,
    TotalOptionsSelected,
} from 'ui-components/multi-select/checkbox-list-v3/hooks/checkbox-list-hooks';
import { Checkbox } from 'ui-components/multi-select/checkbox-list-v2/checkbox/checkbox';
import { isEqual } from 'lodash/fp';

type IsClearModeProps = {
    totalOptionsSelected: TotalOptionsSelected;
    userInteraction: boolean;
    isChildCheckboxList?: boolean;
};

type InitSelectedStateProps = {
    setUserInteractionStateAndRef: (state: boolean) => void;
    options: MultiSelectOption[];
    userInteraction: boolean;
    allowExpansion: boolean;
    setOptionsSelected?: (totalOptionsSelected: OptionsSelectedData) => void;
    isChildCheckboxList?: boolean;
    activeUserInteraction?: boolean;
};

const applyOptionAndSubOptions = (option: MultiSelectOption, checked: boolean) => {
    option.selected = checked;
    option.subOptions && option.subOptions.forEach((subOption) => (subOption.selected = checked));
};

const initSelectedState = ({
    setUserInteractionStateAndRef,
    options,
    userInteraction,
    allowExpansion,
    setOptionsSelected,
    isChildCheckboxList,
    activeUserInteraction,
}: InitSelectedStateProps) => {
    const totalOptionsSelected = getTotalOptionsSelected({
        options,
        visualDisableAll: false,
        allowExpansion,
    });

    if (totalOptionsSelected.selected !== totalOptionsSelected.total || !activeUserInteraction) {
        setUserInteractionStateAndRef(true);
    }
    setOptionsSelected &&
        setOptionsSelected({
            ...totalOptionsSelected,
            clearMode: isClearMode({
                totalOptionsSelected,
                userInteraction,
                isChildCheckboxList,
            }),
        });
};

const isClearMode = ({
    totalOptionsSelected,
    userInteraction,
    isChildCheckboxList,
}: IsClearModeProps) =>
    totalOptionsSelected.selected === totalOptionsSelected.total &&
    !userInteraction &&
    !isChildCheckboxList;

export type DisplayType = 'vertical' | 'horizontal';
export type CheckboxListProps = {
    options: MultiSelectOption[];
    onChange: (
        options: MultiSelectOption[],
        changedOption: MultiSelectOption,
        totalOptionsSelected?: OptionsSelectedData,
    ) => void;
    onSelectAllChange?: (
        options: MultiSelectOption[],
        checked: boolean,
        totalOptionsSelected?: OptionsSelectedData,
    ) => void;
    title?: string;
    searchable?: boolean;
    searchPlaceholder?: string;
    collapsible?: boolean;
    collapsedByDefault?: boolean;
    className?: string;
    checkboxClassname?: string;
    checkboxWrapperClassName?: string;
    selectAllButtonsContainerClassName?: string;
    resultsSize?: number;
    isLimitResults?: boolean;
    enforceResultSize?: boolean;
    displayType?: DisplayType;
    clearSearchValue?: boolean;
    onSearchChange?: (value: string) => void;
    disableAllElementsUnselect?: boolean;
    allowExpansion?: boolean;
    expandOptionWhenSubOptionFound?: boolean;
    titleClassName?: string;
    inputWrapperClassName?: string;
    optionsClassName?: string;
    optionClassName?: string;
    subOptionsListClassName?: string;
    checkboxNameClass?: string;
    isSubOption?: boolean;
    addHover?: boolean;
    addSeparator?: boolean;
    subScroller?: boolean;
    addTooltip?: boolean;
    showTooltipOnlyInCaseOfEllipsis?: boolean;
    isChildCheckboxList?: boolean;
    selectAllConfiguration?: SelectAllConfiguration;
    setOptionsSelected?: (totalOptionsSelected: OptionsSelectedData) => void;
    isDropMenu?: boolean;
    initSearchValue?: string;
    isSearchDisabled?: boolean;
    isSelectAllBtn?: boolean;
    maxSelected?: number;
    disableSelectAllOnMaxConfiguration?: {
        shouldOverrideRender: boolean;
        tooltip: ReactNode;
        elevioId?: string | number;
    };
};

export const CheckboxList = ({
    options,
    onChange,
    onSelectAllChange,
    title,
    searchable,
    searchPlaceholder,
    collapsible,
    collapsedByDefault,
    className,
    checkboxClassname,
    checkboxWrapperClassName,
    selectAllButtonsContainerClassName,
    resultsSize = 0,
    isLimitResults = false,
    displayType = 'vertical',
    enforceResultSize,
    clearSearchValue = false,
    onSearchChange,
    disableAllElementsUnselect = false,
    allowExpansion = true,
    expandOptionWhenSubOptionFound = false,
    titleClassName,
    inputWrapperClassName,
    optionsClassName,
    subOptionsListClassName,
    checkboxNameClass,
    isSubOption = false,
    addHover = false,
    addSeparator = true,
    subScroller = true,
    isChildCheckboxList,
    setOptionsSelected,
    selectAllConfiguration = defaultSelectAllConfiguration,
    isDropMenu = false,
    initSearchValue,
    isSearchDisabled,
    showTooltipOnlyInCaseOfEllipsis = true,
    isSelectAllBtn = true,
    maxSelected = Infinity,
    disableSelectAllOnMaxConfiguration,
}: CheckboxListProps) => {
    const [expandedValues, setExpandedValues] = useState<Dictionary<boolean>>({});
    const [searchValue, setSearchValue] = useState<string | undefined>();
    const [showAllResults, setShowAllResults] = useState<boolean>(false);
    const showMoreResults = useRef<boolean>(false);
    const showLessResults = useRef<boolean>(false);
    const topElementRef = useRef<HTMLDivElement>(null);
    const [userInteraction, setUserInteraction] = useState<boolean>(false);
    const userInteractionRef = useRef<boolean>(false);
    const [isOpenDropMenu, setIsOpenDropMenu] = useState(false);
    const orgOptions = useRef<MultiSelectOption[]>(options);

    useEffect(() => {
        setSearchValue(initSearchValue);
    }, [initSearchValue]);

    const activeUserInteraction =
        selectAllConfiguration.selectAll?.enabled && selectAllConfiguration.clearAll?.enabled;

    const setUserInteractionStateAndRef = useCallback(
        (state: boolean) => {
            const interactionState = activeUserInteraction ? state : true;
            setUserInteraction(interactionState);
            userInteractionRef.current = interactionState;
        },
        [activeUserInteraction],
    );

    useEffect(() => {
        // received options have changed, reset userInteraction state
        if (!isEqual(options, orgOptions.current)) {
            setUserInteractionStateAndRef(false);
            orgOptions.current = options;
        }
    }, [options, setUserInteractionStateAndRef]);

    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setIsOpenDropMenu(false);
            }
        };

        if (isDropMenu) {
            document.addEventListener('mousedown', handleClickOutside);
        }
        return () => {
            if (isDropMenu) {
                document.removeEventListener('mousedown', handleClickOutside);
            }
        };
    }, [wrapperRef, setIsOpenDropMenu, isDropMenu]);

    useEffect(() => {
        initSelectedState({
            setUserInteractionStateAndRef,
            options,
            userInteraction: userInteractionRef.current,
            setOptionsSelected,
            isChildCheckboxList,
            activeUserInteraction,
            allowExpansion,
        });
    }, [
        isChildCheckboxList,
        options,
        setOptionsSelected,
        activeUserInteraction,
        setUserInteractionStateAndRef,
        allowExpansion,
    ]);

    useEffect(() => {
        if (enforceResultSize) {
            setShowAllResults(false);
        }
    }, [enforceResultSize]);

    useEffect(() => {
        if (clearSearchValue) {
            setSearchValue(undefined);
        }
    }, [clearSearchValue]);

    const filteredOptionsBySearch = useMemo(() => {
        showMoreResults.current = false;
        showLessResults.current = false;
        const results = getFilteredOptionsBySearchString({
            options,
            searchValue,
            isChildCheckboxList,
        });

        if (resultsSize && results.length > resultsSize) {
            if (!showAllResults) {
                showMoreResults.current = true;
            } else {
                showLessResults.current = true;
            }
        }

        return results;
    }, [options, resultsSize, searchValue, showAllResults, isChildCheckboxList]);

    useEffect(() => {
        if (searchValue && !isChildCheckboxList && expandOptionWhenSubOptionFound) {
            const newExpandedValues = getExpandedValues(options, searchValue);
            const newMergedExpandedValues = {
                ...expandedValues,
                ...newExpandedValues,
            };

            setExpandedValues(newMergedExpandedValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expandOptionWhenSubOptionFound, options, searchValue, isChildCheckboxList]);

    const slicedOptionsBySize = showMoreResults.current
        ? filteredOptionsBySearch.slice(0, resultsSize)
        : filteredOptionsBySearch;

    const allSelected = useMemo<boolean>(() => {
        if (!userInteraction) return true;
        return options.every(({ selected, subOptions }) => {
            const allSubOptionsSelected = subOptions
                ? subOptions.every(({ selected }) => selected)
                : true;
            return selected && allSubOptionsSelected;
        });
    }, [options, userInteraction]);

    const visualDisableAll = useMemo(
        () => allSelected && !userInteraction && !isChildCheckboxList,
        [allSelected, isChildCheckboxList, userInteraction],
    );

    const totalOptionsSelected = getTotalOptionsSelected({
        options,
        visualDisableAll,
        allowExpansion,
    });

    const onExpandChange = (value: string, expanded: boolean) => {
        setExpandedValues({
            ...expandedValues,
            [value]: expanded,
        });
    };

    const setOptionChange = (newOption: MultiSelectOption) => {
        const option = options.find((option) => option.value === newOption.value)!;
        option.selected = newOption.selected;
        if (newOption.subOptions) {
            newOption.subOptions.forEach((subOption) => {
                const orgSubOption = option.subOptions?.find(
                    (orgItem) => orgItem.value === subOption.value,
                );
                Object.assign(orgSubOption || {}, {
                    selected: subOption.selected,
                });
            });
        }
    };

    const resetAllOption = (checked: boolean) => {
        options.forEach((option) => {
            applyOptionAndSubOptions(option, checked);
        });
    };

    const selectAllChange = (checked: boolean, force?: boolean, callSelectAll: boolean = true) => {
        if (force) {
            resetAllOption(checked);
        } else {
            if (!checked && filteredOptionsBySearch.length === options.length) {
                setUserInteractionStateAndRef(false);
                selectAllChange(true);
                return;
            }
            filteredOptionsBySearch.forEach((newOption, idx) => {
                const option = options.find(
                    (option) => option.value === filteredOptionsBySearch[idx].value,
                )!;
                applyOptionAndSubOptions(option, checked);
            });
            // user has cleared all checked options
            const optionsSelected = getTotalOptionsSelected({
                options,
                visualDisableAll: false,
                allowExpansion,
            });
            if (!checked && !optionsSelected.selected) {
                setUserInteractionStateAndRef(false);
                resetAllOption(true);
            }
        }

        const totalSelectedOptions = getTotalOptionsSelected({
            options,
            visualDisableAll: false,
            allowExpansion,
        });

        orgOptions.current = options;

        callSelectAll &&
            onSelectAllChange &&
            onSelectAllChange(options, checked && userInteractionRef.current, {
                ...totalSelectedOptions,
                clearMode: isClearMode({
                    totalOptionsSelected: totalSelectedOptions,
                    userInteraction: userInteractionRef.current,
                    isChildCheckboxList,
                }),
            });
    };

    const onSelectOrClearAllClick = (type: SelectAndClearAllType) => {
        setUserInteractionStateAndRef(true);

        if (type === 'select all' && visualDisableAll) {
            resetAllOption(false);
        }

        selectAllChange(type === 'select all');
    };

    const onOptionChange = (newOption: MultiSelectOption) => {
        if (!isChildCheckboxList) {
            const orgOption = options.find((option) => option.value === newOption.value)!;
            const isLastSelection = isFinalSelectedOptions({
                totalOptionsSelected,
                orgOption,
                newOption,
                allowExpansion,
            });

            if (!isChildCheckboxList && isLastSelection && activeUserInteraction) {
                setUserInteractionStateAndRef(false);
                selectAllChange(true, true);
                return;
            }
            setUserInteractionStateAndRef(true);
            if (visualDisableAll) {
                //first userInteraction, deselect all others options
                selectAllChange(false, true, false);
            }
        }
        const totalSelectedOptionsBeforeChange = getTotalOptionsSelected({
            options,
            visualDisableAll: false,
            allowExpansion,
        });
        const newOptionWithLimit: MultiSelectOption = {
            ...newOption,
            selected: newOption.selected && totalSelectedOptionsBeforeChange.selected < maxSelected,
            ...(isFinite(maxSelected)
                ? { disabled: totalSelectedOptionsBeforeChange.selected === maxSelected }
                : {}),
        };
        setOptionChange(newOptionWithLimit);
        const totalSelectedOptions = getTotalOptionsSelected({
            options,
            visualDisableAll: false,
            allowExpansion,
        });

        orgOptions.current = options;
        onChange(options, newOptionWithLimit, {
            ...totalSelectedOptions,
            clearMode: isClearMode({
                totalOptionsSelected: totalSelectedOptions,
                userInteraction: userInteractionRef.current,
                isChildCheckboxList,
            }),
        });
    };

    const onShowAllOrLessResultsClick = (type: ShowResultsButtonType) => {
        if (type === 'show all') {
            setShowAllResults(true);
        } else {
            setShowAllResults(false);

            const topElementRefElement = topElementRef?.current;
            if (topElementRefElement) {
                topElementRefElement.scrollTop = 0;
            }
        }
    };

    const onSearchBoxChange = (value: string) => {
        setShowAllResults(false);
        setSearchValue(value);
        onSearchChange && onSearchChange(value);
    };

    const disableCheckbox = (selected: boolean) => {
        if (!disableAllElementsUnselect) return false;
        return selected && options.filter((option) => option.selected).length === 1;
    };

    const wrapperClasses = classNames(styles.checkboxList, className);
    const getSelectAllComponent = () => {
        const hasMoreOptionsThanMax =
            totalOptionsSelected.total > maxSelected && isFinite(maxSelected);

        const shouldPresentSelectAllOverride =
            disableSelectAllOnMaxConfiguration?.shouldOverrideRender && hasMoreOptionsThanMax;
        if (
            slicedOptionsBySize.length &&
            onSelectAllChange &&
            !!selectAllConfiguration &&
            (!hasMoreOptionsThanMax || shouldPresentSelectAllOverride)
        ) {
            const totalOptionsSelected = getTotalOptionsSelected({
                options,
                visualDisableAll,
                allowExpansion,
            });

            const disabledSelectAll =
                !visualDisableAll && totalOptionsSelected.selected === totalOptionsSelected.total;
            return (
                <SelectAndClearAll
                    onClick={onSelectOrClearAllClick}
                    disabledSelectAll={
                        disabledSelectAll ||
                        (hasMoreOptionsThanMax && !!shouldPresentSelectAllOverride)
                    }
                    disabledClearAll={visualDisableAll}
                    selectAllButtonsContainerClassName={selectAllButtonsContainerClassName}
                    selectAllConfiguration={selectAllConfiguration}
                    {...(hasMoreOptionsThanMax && shouldPresentSelectAllOverride
                        ? {
                              disableSelectAllTooltip: disableSelectAllOnMaxConfiguration?.tooltip,
                              elevioId: disableSelectAllOnMaxConfiguration?.elevioId,
                          }
                        : {})}
                />
            );
        }
    };

    const isDisableMaxItemsReached = useMemo(() => {
        return (
            isFinite(maxSelected) &&
            orgOptions.current.filter((o) => o.selected).length === maxSelected
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgOptions.current, maxSelected]);

    const list = (
        <>
            {searchable && (
                <OuterSearchInput
                    inputValue={searchValue}
                    onChange={onSearchBoxChange}
                    className={classNames(styles.searchBoxWrapper, inputWrapperClassName)}
                    placeholder={searchPlaceholder}
                    disabled={isSearchDisabled}
                />
            )}
            {(!isDropMenu || isOpenDropMenu) && (
                <div
                    ref={topElementRef}
                    className={classNames(
                        styles.optionsWrapper,
                        optionsClassName,
                        checkboxWrapperClassName,
                        {
                            [styles.optionsAsDropdown]: isDropMenu,
                        },
                    )}
                    data-testid={'checkbox-list-items-wrapper'}
                >
                    {isSelectAllBtn && getSelectAllComponent()}
                    <div
                        className={classNames({
                            [styles.listItemsWrapperVertical]: displayType === 'vertical',
                            [styles.listItemsWrapperHorizontal]: displayType === 'horizontal',
                            [styles.listItemsWrapperExpanded]: showAllResults,
                            [styles.allowVerticalScroll]: !isSubOption || subScroller,
                        })}
                        data-testid={'checkbox-list-content'}
                    >
                        {slicedOptionsBySize.map(
                            (
                                {
                                    name,
                                    value,
                                    selected,
                                    subOptions,
                                    disabled = false,
                                    checkedStyles,
                                    tooltipText,
                                },
                                idx,
                            ) => {
                                const isDisabled = disableCheckbox(selected) || disabled;
                                const isSelected = visualDisableAll ? false : selected;
                                const isTooltipShown =
                                    !tooltipText || showTooltipOnlyInCaseOfEllipsis;

                                return (
                                    <Tooltip
                                        small
                                        title={
                                            isDisabled && tooltipText
                                                ? tooltipText
                                                : isDisabled
                                                ? 'Minimum 1 parameter has to be selected'
                                                : ''
                                        }
                                        placement={'top'}
                                        key={`${name}+${idx}`}
                                    >
                                        <Checkbox
                                            key={`${name}+${idx}`}
                                            className={classNames(
                                                styles.checkboxItem,
                                                checkboxClassname,
                                            )}
                                            subOptionsListClassName={subOptionsListClassName}
                                            checkboxNameClass={checkboxNameClass}
                                            name={name}
                                            value={value}
                                            selected={isSelected}
                                            subOptions={
                                                visualDisableAll && subOptions
                                                    ? subOptions.map((subOption) => ({
                                                          ...subOption,
                                                          selected: false,
                                                      }))
                                                    : subOptions
                                            }
                                            onChange={onOptionChange}
                                            {...(allowExpansion && {
                                                expanded: expandedValues[value],
                                                onExpandChange: (expanded: boolean) =>
                                                    onExpandChange(value, expanded),
                                            })}
                                            disabled={
                                                disableCheckbox(selected) ||
                                                disabled ||
                                                (!isSelected && isDisableMaxItemsReached)
                                            }
                                            isSubOption={isSubOption}
                                            addHover={addHover}
                                            addSeparator={
                                                idx !== slicedOptionsBySize.length - 1 ||
                                                isSubOption
                                            }
                                            subScroller={subScroller}
                                            testId={
                                                selected
                                                    ? 'checkbox-selected'
                                                    : 'checkbox-unselected'
                                            }
                                            tooltipText={tooltipText ? tooltipText : name}
                                            showTooltipOnlyInCaseOfEllipsis={isTooltipShown}
                                            isChildCheckboxList={isChildCheckboxList}
                                            selectAllConfiguration={selectAllConfiguration}
                                            userInput={searchValue}
                                            checkedStyles={checkedStyles}
                                        />
                                        {/* a second child is needed to show the tooltip title */}
                                        {''}
                                    </Tooltip>
                                );
                            },
                        )}
                        {showMoreResults.current && !isLimitResults && (
                            <ShowResultsButton
                                type={'show all'}
                                onClick={onShowAllOrLessResultsClick}
                            />
                        )}
                        {showLessResults.current && (
                            <ShowResultsButton
                                type={'show less'}
                                onClick={onShowAllOrLessResultsClick}
                            />
                        )}
                    </div>
                </div>
            )}
        </>
    );

    return (
        <div ref={wrapperRef} onClick={() => !isSearchDisabled && setIsOpenDropMenu(true)}>
            {!title ? (
                <div className={wrapperClasses}>{list}</div>
            ) : (
                <div className={wrapperClasses}>
                    {collapsible ? (
                        <Collapse title={title} collapsedByDefault={collapsedByDefault}>
                            {list}
                        </Collapse>
                    ) : (
                        <>
                            <div className={classNames(styles.listHeader, titleClassName)}>
                                <span>{title}</span>
                            </div>
                            {list}
                        </>
                    )}
                </div>
            )}
        </div>
    );
};
