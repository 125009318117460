import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';
import { IconProps } from 'components/assets/Icons/Icons';

/* eslint-disable max-len */
export const ZoomInIconSvg = (props: CustomIconComponentProps | SVGProps<SVGSVGElement>) => (
    <svg
        width="18"
        height="18"
        {...props}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g id="Zoom_in-icon-18px">
            <g id="Zoom_in_icon">
                <path
                    id="Fill 1"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.9575 12.416L17.2605 15.733C17.6805 16.152 17.6805 16.833 17.2605 17.252L17.2515 17.261C16.8325 17.68 16.1525 17.68 15.7335 17.261L12.4305 13.943"
                    fill="currentColor"
                />
                <path
                    id="Stroke 3"
                    d="M13.9575 12.416L17.2605 15.733C17.6805 16.152 17.6805 16.833 17.2605 17.252L17.2515 17.261C16.8325 17.68 16.1525 17.68 15.7335 17.261L12.4305 13.943"
                    stroke="#49565D"
                    strokeWidth="0.75"
                    strokeLinecap="round"
                />
                <path
                    id="Stroke 5"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.375 7.875C15.375 12.018 12.017 15.375 7.875 15.375C3.733 15.375 0.375 12.018 0.375 7.875C0.375 3.732 3.733 0.375 7.875 0.375C12.017 0.375 15.375 3.732 15.375 7.875Z"
                    stroke="#49565D"
                    strokeWidth="0.75"
                    strokeLinecap="round"
                />
                <path
                    id="Stroke 7"
                    d="M7.875 4.375V11.375"
                    stroke="#49565D"
                    strokeLinecap="round"
                />
                <path
                    id="Stroke 9"
                    d="M11.375 7.875H4.375"
                    stroke="#49565D"
                    strokeLinecap="round"
                />
            </g>
        </g>
    </svg>
);

export const ZoomInIcon = (props: IconProps) => <Icon component={ZoomInIconSvg} {...props} />;
