/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { IconProps } from 'components/assets/Icons/Icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

export const AnalyticsSvg = (props: CustomIconComponentProps | SVGProps<SVGSVGElement>) => (
    <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="19"
        viewBox="0 0 18 19"
        fill="none"
    >
        <path
            d="M17.4706 17.3H16.5882V1.1C16.5882 0.94087 16.5325 0.788258 16.4332 0.675736C16.3339 0.563214 16.1992 0.5 16.0588 0.5H11.1176C10.9772 0.5 10.8426 0.563214 10.7433 0.675736C10.644 0.788258 10.5882 0.94087 10.5882 1.1V5.3H6.17647C6.03606 5.3 5.9014 5.36321 5.80212 5.47574C5.70284 5.58826 5.64706 5.74087 5.64706 5.9V10.1H1.94118C1.80077 10.1 1.66611 10.1632 1.56683 10.2757C1.46754 10.3883 1.41176 10.5409 1.41176 10.7V17.3H0.529412C0.389003 17.3 0.254345 17.3632 0.155061 17.4757C0.0557771 17.5883 0 17.7409 0 17.9C0 18.0591 0.0557771 18.2117 0.155061 18.3243C0.254345 18.4368 0.389003 18.5 0.529412 18.5H17.4706C17.611 18.5 17.7457 18.4368 17.8449 18.3243C17.9442 18.2117 18 18.0591 18 17.9C18 17.7409 17.9442 17.5883 17.8449 17.4757C17.7457 17.3632 17.611 17.3 17.4706 17.3ZM11.6471 1.7H15.5294V17.3H11.6471V1.7ZM6.70588 6.5H10.5882V17.3H6.70588V6.5ZM2.47059 11.3H5.64706V17.3H2.47059V11.3Z"
            fill="currentColor"
        />
        <path d="M11.6471 1.7H15.5294V17.3H11.6471V1.7Z" fill={props.fill} />
        <path d="M6.70588 6.5H10.5882V17.3H6.70588V6.5Z" fill="white" />
        <path d="M2.47059 11.3H5.64706V17.3H2.47059V11.3Z" fill="white" />
    </svg>
);

export const AnalyticsIcon = (props: IconProps) => (
    <Icon component={() => <AnalyticsSvg fill={props.fill} />} {...props} />
);
