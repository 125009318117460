import produce, { Draft } from 'immer';

import { RecoveryAction, RecoveryState } from 'features/recovery-dashboard/types/store';
import { RecoveryReportMetadata, RegionData } from 'features/recovery-dashboard/types/entities';

export const recoveryInitialState: RecoveryState = {
    region: {
        entities: {},
        ids: [],
    },
};

export const recoveryReducer = (
    state = recoveryInitialState,
    action: RecoveryAction<RecoveryReportMetadata> | RecoveryAction<RegionData>,
) => {
    return produce(state, (draft: Draft<RecoveryState>) => {
        switch (action.type) {
            case 'region':
                const region = action.payload as RegionData;
                const storeState = draft.region;

                const prevEntityId = storeState.ids[0];
                storeState.ids[0] = region.id;
                storeState.entities[region.id] = region;

                if (prevEntityId && prevEntityId !== region.id) {
                    delete storeState.entities[prevEntityId];
                }
                break;

            case 'metadata':
                const metadata = action.payload as RecoveryReportMetadata;

                draft.dates = {
                    report: {
                        month_start_date: metadata.month_start_date,
                        week_start_date: metadata.week_start_date,
                        end_date: metadata.report_end_date,
                    },
                    compared: {
                        month_start_date: metadata.compared_month_start_date,
                        week_start_date: metadata.compared_week_start_date,
                        end_date: metadata.compared_end_date,
                    },
                };
                break;
        }
    });
};
