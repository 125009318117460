/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

import { IconProps } from 'components/assets/Icons/Icons';

const VerifiedBadgeSvg = (props: CustomIconComponentProps | SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 14 16">
        <g fill="none" fillRule="evenodd">
            <path
                stroke="#55B18E"
                strokeWidth=".75"
                d="M7 .402L.375 2.94v4.914c-.002 1.902.407 3.309 2.016 4.914C3.463 13.838 4.977 14.79 7 15.597c2.023-.807 3.537-1.76 4.609-2.829 1.609-1.605 2.018-3.012 2.016-4.914V2.94L7 .402z"
            />
            <path
                fill="#55B18E"
                fillRule="nonzero"
                d="M7.55 11L9.8 4.575H8.71L7.37 8.552c-.05.14-.115.358-.194.65-.08.294-.138.551-.176.774a7.695 7.695 0 0 0-.154-.67c-.079-.3-.154-.546-.224-.736l-1.34-3.995H4.2L6.44 11H7.55z"
            />
        </g>
    </svg>
);

export const VerifiedBadgeIcon = (props: IconProps) => (
    <Icon component={VerifiedBadgeSvg} {...props} />
);
