import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useGetIndexPath } from 'router/hooks/use-get-index-path';
import { ANGULAR_PREFIX } from 'router/constants';
import { isSupportedBrowser, shouldRenderMobileApp } from 'utils/detect-environment';
import { userIsAnonymousSelector } from 'store/selectors/app-selectors';
import { useSelector } from 'react-redux';
import { useQueryParams } from 'hooks/use-query/use-query';

type PathRewriteProps = {
    children: React.ReactNode;
};

const QUERY_PARAMS_TO_KEEP = ['data-elevio-article'];
export const PathRewrite = ({ children }: PathRewriteProps) => {
    const { pathname, hash } = useLocation();
    const indexPath = useGetIndexPath();

    const queryParams = useQueryParams();
    const searchParams = new URLSearchParams(queryParams);
    const newSearchParams = new URLSearchParams();
    for (const key of QUERY_PARAMS_TO_KEEP) {
        const value = searchParams.get(key);
        value && newSearchParams.set(key, value);
    }

    const indexPathWithParams = `${indexPath}?${newSearchParams.toString()}`;

    const isAnonymous = useSelector(userIsAnonymousSelector);

    if (!shouldRenderMobileApp()) {
        const isBrowserSupported = isSupportedBrowser();
        if (!isBrowserSupported && !pathname.startsWith('/browser-support')) {
            return <Redirect to={`/browser-support?url=${window.location.href}`} />;
        }
    }

    if (hash.startsWith(`${ANGULAR_PREFIX}/admin`)) {
        return <Redirect to={hash.replace(/#!\/admin\/?/, '')} />;
    } else if (hash.startsWith(ANGULAR_PREFIX)) {
        return <Redirect to={hash.replace(ANGULAR_PREFIX, '')} />;
    } else if ((pathname === '/' || pathname === '/redirect') && isAnonymous === false) {
        // redirect path from activation email - redirect to the relevant path from Angular view
        return <Redirect to={indexPathWithParams} />;
    } else {
        return <>{children}</>;
    }
};
