import React, { ReactNode, useEffect, useState } from 'react';
import styles from './solution-list.module.scss';
import { type Solution as SolutionT } from 'features/onboarding/solutions-guide/consts/solutions-guide';
import { Solution } from 'features/onboarding/solutions-guide/components/solution/solution';
import { useHistory } from 'react-router-dom';
import {
    getDefaultSolution,
    setSolutionCookie,
} from 'features/onboarding/solutions-guide/utils/utils';
import { deleteCookie } from 'utils/cookies/utils';
import { ONBOARDING_SOLUTION_COOKIE_NAME } from 'features/onboarding/solutions-guide/consts/constants';
import { useNavigateToSolution } from 'features/onboarding/solutions-guide/components/solution-list/utils';

type SolutionListProps = {
    setOpen: (open: boolean) => void;
    solutions: SolutionT[];
    industryIcon: ReactNode;
};

export const SolutionList = ({ setOpen, solutions, industryIcon }: SolutionListProps) => {
    const history = useHistory();
    const [activeSolution, setActiveSolution] = useState(getDefaultSolution());
    const [initialPathName, setInitialPathName] = useState(history.location.pathname);
    const navigateToSolution = useNavigateToSolution();

    useEffect(() => {
        // Closing the guide when route is changed outside of guide
        if (history.location.pathname !== initialPathName) {
            setActiveSolution('');
            deleteCookie({ key: ONBOARDING_SOLUTION_COOKIE_NAME });
            setOpen(false);
        }
    }, [setOpen, history.location.pathname, initialPathName]);

    const setCurrentSolution = (solutionTitle: string) => {
        setActiveSolution(solutionTitle);
        setSolutionCookie(solutionTitle);
    };

    const handleClick = (solution: SolutionT) => {
        setInitialPathName(solution.url);
        setCurrentSolution(solution.title);
        navigateToSolution(solution.url);
    };

    return (
        <div className={styles.root}>
            {solutions.map((solution) => {
                return (
                    <Solution
                        key={solution.title}
                        title={solution.title}
                        icon={industryIcon}
                        description={solution.description}
                        onClick={() => handleClick(solution)}
                        isActive={solution.title === activeSolution}
                    />
                );
            })}
        </div>
    );
};
