import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';
import styles from 'components/assets/Icons/Icons.module.scss';

import { IconProps } from 'components/assets/Icons/Icons';

/* eslint-disable max-len */
const ExpandOutlinedSvg = (props: CustomIconComponentProps | SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        viewBox="0 0 19 18"
        stroke={props.fill}
        className={styles.animation}
    >
        <g fill="none" fillRule="evenodd">
            <g stroke="inherit">
                <g strokeLinecap="round">
                    <path
                        strokeLinejoin="round"
                        d="M4 0L4 4 0 4"
                        transform="translate(-1074 -135) translate(220 108) translate(854 27) matrix(-1 0 0 1 17.5 .5) translate(3 3) rotate(-180 2 2)"
                    />
                    <path
                        d="M0 5.5L5.5 0"
                        transform="translate(-1074 -135) translate(220 108) translate(854 27) matrix(-1 0 0 1 17.5 .5) translate(3 3) matrix(-1 0 0 1 5.5 0)"
                    />
                </g>
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M0 7.5L0 0 17 0 17 17 9.5 17"
                    transform="translate(-1074 -135) translate(220 108) translate(854 27) matrix(-1 0 0 1 17.5 .5) matrix(-1 0 0 1 17 0)"
                />
                <path
                    fill="#D0D0D0"
                    strokeLinejoin="round"
                    d="M10 10H17V17H10z"
                    transform="translate(-1074 -135) translate(220 108) translate(854 27) matrix(-1 0 0 1 17.5 .5)"
                />
            </g>
        </g>
    </svg>
);

export const ExpandOutlinedIcon = (props: IconProps) => (
    <Icon component={ExpandOutlinedSvg} {...props} />
);
