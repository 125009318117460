/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';
import { IconProps } from 'components/assets/Icons/Icons';

export const ClothingIconSvg = (props: CustomIconComponentProps | SVGProps<SVGSVGElement>) => (
    <svg {...props} viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.80252 0C4.80252 0.5515 5.38149 1 6.00252 1C6.62299 1 7.25468 0.5515 7.25468 0H4.80252Z"
            fill="currentColor"
        />
        <path
            d="M11.9869 1.56657C11.9611 1.49358 11.9075 1.43355 11.8377 1.40086C9.91281 0.537408 8.77618 0.0722531 8.42779 0.00539117C8.40939 0.00175427 8.39108 0 8.37302 0C8.24366 0 8.11565 0.088078 8.09899 0.222364C8.00252 1 7.00252 2 6.00252 2C5.00252 2 4.00252 1 4.00252 0.222364C4.00252 0.0869766 3.9318 0 3.80252 0C3.78446 0 3.82091 0.00175427 3.80252 0.00539117C3.27997 0.105684 3.06355 0.242902 2.55474 0.416618L0.167378 1.4C0.0971001 1.43269 0.0430991 1.49701 0.018557 1.57C0.00163052 1.61923 -0.00371655 2.32923 0.00251583 3.7C0.00251583 4 0.00251582 4 0.25421 4C0.422005 4 1.00477 4 2.00252 4V11.7143C2.00252 11.8716 2.14619 12 2.30252 12H9.70252C9.85841 12 10.0025 11.8716 10.0025 11.7143V4.00009L10.6018 4C10.6288 4.00817 11.6561 4.01211 11.683 4.01211C11.7872 4.01211 11.9533 3.95328 12.0025 3.85525V1.78983C12.0025 2 12.0119 1.64042 11.9869 1.56657Z"
            fill="currentColor"
        />
    </svg>
);

export const ClothingIcon = (props: IconProps) => <Icon component={ClothingIconSvg} {...props} />;
