import { VenueStyle } from 'ui-components/google-map-layers/types/google-map-layers-types';
import { getPlaceColorShade, POI_COLORS, PRIMARY_COLOR } from 'core/constants/place-colors';
import {
    getReportEntityClosingDate,
    getReportEntityFlag,
    getReportEntityProfile,
    isRestrictedArea,
} from 'core/services/report-entities-service/report-entities-service';
import type { Place, EntityProfile } from '@placer-ui/types';

export type MapLayerPOIType =
    | EntityProfile
    | 'closed'
    | 'selected'
    | 'flagged'
    | 'custom'
    | 'privacy_concerns'
    | 'restricted';

const primary = PRIMARY_COLOR;
const lightPrimary = getPlaceColorShade(PRIMARY_COLOR, 'lighter');
const darkerPrimary = getPlaceColorShade(PRIMARY_COLOR, 'darker');

const styleConfig: Record<MapLayerPOIType, VenueStyle> = {
    available: {
        fill: PRIMARY_COLOR,
        borderColor: darkerPrimary,
    },
    privacy_concerns: {
        fill: POI_COLORS.privacy_concern,
        borderColor: '#333333',
    },
    restricted: {
        fill: POI_COLORS.restricted,
        borderColor: '#333333',
    },
    nearby_activity: {
        fill: lightPrimary,
        borderColor: primary,
    },
    not_available: {
        fill: lightPrimary,
        borderColor: primary,
    },
    closed: {
        fill: POI_COLORS.closed,
        borderColor: '#ce344f',
    },
    custom: {
        fill: POI_COLORS.custom,
        borderColor: '#827F94',
    },
    flagged: {
        fill: lightPrimary,
        borderColor: primary,
    },
    selected: {
        fill: '#49565d',
        borderColor: '#35454e',
    },
};

export const getMapLayersPOIStyles = (profile: MapLayerPOIType) => styleConfig[profile];

export const getEntityColor = (venue: Place, selected?: boolean): VenueStyle => {
    const venueProfile = getReportEntityProfile(venue);
    const closingDate = getReportEntityClosingDate(venue);
    const flag = getReportEntityFlag(venue);
    const isRestricted = isRestrictedArea(venue);

    if (selected) {
        return getMapLayersPOIStyles('selected');
    } else if (isRestricted) {
        return getMapLayersPOIStyles('restricted');
    } else if (closingDate) {
        return getMapLayersPOIStyles('closed');
    } else if (flag) {
        if (flag === 'privacy_concerns') {
            return getMapLayersPOIStyles('privacy_concerns');
        }
        return getMapLayersPOIStyles('flagged');
    } else if (venue.is_custom_poi) {
        return getMapLayersPOIStyles('custom');
    } else {
        return getMapLayersPOIStyles(venueProfile);
    }
};
