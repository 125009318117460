import React, { ChangeEvent, useState } from 'react';
import type { BaseUserAuth } from '@placer-ui/types';
import styles from './user-loginas-dialog.module.scss';

import { Table } from 'ui-components/table/table';
import { Input } from 'ui-components/input/input';
import { SearchIcon } from 'components/assets/Icons/Icons';
import { useLoginAsTableContent } from './user-loginas-table-hooks';
import { Button } from 'ui-components/button/button';
import { userAPI } from 'API/user-api';
import { UserLoginAsType } from './user-loginas';
import { useChangeUserInLoginasMode } from './utils';

export type UserLoginAsTableItemType = {
    key: number;
    userName: string;
    account: string;
};

type UserLoginAsTableProps = {
    users: UserLoginAsType[];
    onClose: () => void;
    setIsLoading: (val: boolean) => void;
    setIsErrrorResponse: (val: boolean) => void;
};

export const UserLoginAsTable = ({
    users,
    onClose,
    setIsLoading,
    setIsErrrorResponse,
}: UserLoginAsTableProps) => {
    const changeUserInLoginasMode = useChangeUserInLoginasMode();
    const tableData = useLoginAsTableContent(users);
    const [selectedRow, setSelectedRow] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [usersTableData, setUsersTableData] = useState<UserLoginAsTableItemType[]>(
        tableData.usersTableData,
    );

    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const currValue = e.target.value;
        setSearchTerm(currValue);
        const filteredRowsData: UserLoginAsTableItemType[] = [];
        const { usersTableData } = tableData;

        if (currValue.replace(/\s/g, '') !== '') {
            usersTableData.forEach((user: UserLoginAsTableItemType) => {
                const { userName, account } = user;

                if (
                    userName.includes(currValue) ||
                    userName?.toLowerCase().includes(currValue.toLowerCase()) ||
                    account.includes(currValue) ||
                    account?.toLowerCase().includes(currValue.toLowerCase())
                ) {
                    filteredRowsData.push(user);
                }
            });
            setUsersTableData(filteredRowsData);
        } else {
            setUsersTableData(tableData.usersTableData);
        }
    };

    const onRowDataClick = (row: UserLoginAsTableItemType) => {
        if (selectedRow !== row.userName) {
            const selectedElement = document.getElementById(selectedRow) as HTMLInputElement;
            const newSelectedElement = document.getElementById(row.userName) as HTMLInputElement;

            if (selectedElement) {
                selectedElement.checked = false;
            }
            if (newSelectedElement) {
                newSelectedElement.checked = true;
            }
            setSelectedRow(row.userName);
        }
    };

    const loginImpersonateUser = async () => {
        setIsLoading(true);
        try {
            const { data } = await userAPI.loginImpersonateUsers({
                username: selectedRow,
            });

            const impersonatedUser: BaseUserAuth = {
                username: data.username,
                // server is probably going to return the email as username
                email: data.email || data.username,
                token: data.token,
                fullName: data.full_name,
            };
            changeUserInLoginasMode(impersonatedUser);
            onClose();
        } catch (e) {
            setIsLoading(false);
            setIsErrrorResponse(true);
        }
    };

    return (
        <>
            <div className={styles.inputMainWrapper}>
                <Input
                    data-testid={'login-as-input'}
                    value={searchTerm}
                    onChange={onInputChange}
                    className={styles.inputWrapper}
                    placeholder="Search by Username or Account Name"
                    suffix={<SearchIcon />}
                />
            </div>
            <div className={styles.tableWrapper}>
                <Table<UserLoginAsTableItemType>
                    size="middle"
                    pagination={false}
                    columns={tableData.columns}
                    dataSource={usersTableData}
                    onRowClick={(row: UserLoginAsTableItemType) => onRowDataClick(row)}
                    scroll={{
                        y: 450,
                    }}
                />
            </div>
            <div className={styles.buttonsContainer}>
                <Button onClick={onClose} className={styles.button}>
                    Cancel
                </Button>
                <Button
                    className={styles.button}
                    type={'brand-primary-full'}
                    disabled={selectedRow === ''}
                    onClick={loginImpersonateUser}
                >
                    Login
                </Button>
            </div>
        </>
    );
};
