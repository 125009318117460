/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

export type ContactPersonIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type ContactPersonIconProps = SVGProps<SVGSVGElement> & ContactPersonIconUniqueProps;

export const ContactPersonIcon = function ContactPersonIcon({
    width = 16,
    height = 16,
    color = '#D0D0D0',
    ...props
}: ContactPersonIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            color={color}
            {...props}
        >
            <path
                d="M3.875 18C3.875 16.5503 5.05025 15.375 6.5 15.375H13.5C14.9497 15.375 16.125 16.5503 16.125 18V19.625H3.875V18Z"
                fill="white"
                stroke="#49565D"
                strokeWidth="0.75"
            />
            <path
                d="M8.5 13.5H11.5V15C11.5 15.8284 10.8284 16.5 10 16.5C9.17157 16.5 8.5 15.8284 8.5 15V13.5Z"
                fill="white"
                stroke="#49565D"
                strokeWidth="0.75"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.5 18H14.5V17H13.5V18Z"
                fill="#49565D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 3.2C4 1.43269 5.43269 0 7.2 0H16V7.25H14.0563V4.51447H5.87451V7.25H4V3.2Z"
                fill="currentColor"
            />
            <path
                d="M4.375 3.2C4.375 1.6398 5.6398 0.375 7.2 0.375H15.625V7.375H4.375V3.2Z"
                stroke="#49565D"
                strokeWidth="0.75"
            />
            <path
                d="M4.375 8.5C4.375 9.12132 4.87868 9.625 5.5 9.625H5.875V7.375H4.375V8.5Z"
                fill="white"
                stroke="#49565D"
                strokeWidth="0.75"
            />
            <path
                d="M15.625 8.5C15.625 9.12132 15.1213 9.625 14.5 9.625H14.125V7.375H15.625V8.5Z"
                fill="white"
                stroke="#49565D"
                strokeWidth="0.75"
            />
            <path
                d="M5.875 10V4.375H14.125V10C14.125 12.2782 12.2782 14.125 10 14.125C7.72183 14.125 5.875 12.2782 5.875 10Z"
                fill="white"
                stroke="#49565D"
                strokeWidth="0.75"
            />
            <path
                d="M8.5 10.5C8.73035 11.0836 9.31309 11.5 9.99954 11.5C10.686 11.5 11.2687 11.0836 11.5 10.5"
                stroke="#49565D"
                strokeWidth="0.75"
                strokeLinecap="round"
            />
        </svg>
    );
};
ContactPersonIcon.__tags = ['contact-person', 'contactperson', 'contact', 'person'] as string[];
