import { useHistory, useLocation } from 'react-router-dom';
import React, { PropsWithChildren, useEffect, useRef } from 'react';

export const RedirectOnlyIfFirstEntry = ({ path }: { path: string }) => {
    const history = useHistory();
    const isFirstEntry = useRef(true);

    useEffect(() => {
        if (isFirstEntry.current) {
            isFirstEntry.current = false;
            history.replace(path);
        }
    }, [history, path]);

    return null;
};

export const RedirectOnEmptySearch = ({
    defaultUrl,
    children,
}: PropsWithChildren<{ defaultUrl: string }>) => {
    const { search } = useLocation();
    const history = useHistory();

    useEffect(() => {
        if (!search) {
            history.replace(defaultUrl);
        }
    });

    if (!search) {
        return null;
    }

    return <>{children}</>;
};
