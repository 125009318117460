interface GoogleEventData {
    category: string;
    action: string;
    label?: string;
    value?: number;
}

export const initGoogleAnalytics = () => {
    var productionHost = /^analytics\.placer\.(?:io|ai)/;
    if (productionHost.test(window.location.hostname)) {
        window.ga('create', 'UA-57901169-4', 'auto');
    }
};

export const sendGoogleAnalyticsEvent = ({ category, action, label, value }: GoogleEventData) => {
    window.ga('send', 'event', category, action, label, value);
};
