import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

import { IconProps } from 'components/assets/Icons/Icons';

/* eslint-disable max-len */
export const DotSvg = (props: CustomIconComponentProps | SVGProps<SVGSVGElement>) => {
    const color = props.style?.fill || 'inherit';
    const borderColor = props.style?.borderColor || '#49565d';

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            {...props}
            viewBox="0 0 10 10"
            fill="none"
        >
            <circle r="4.5" transform="matrix(-1 0 0 1 5 5)" fill={color} stroke={borderColor} />
        </svg>
    );
};

export const DotIcon = (props: IconProps) => <Icon component={DotSvg} {...props} />;
