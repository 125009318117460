/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';
import { IconProps } from 'components/assets/Icons/Icons';

export const AirportFacilitiesIconSvg = (
    props: CustomIconComponentProps | SVGProps<SVGSVGElement>,
) => (
    <svg {...props} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7 2V1C7 0.447715 6.55228 0 6 0H4C3.44772 0 3 0.447715 3 1V2H1C0.447715 2 0 2.44772 0 3V9H10V3C10 2.44772 9.55228 2 9 2H7ZM6 2H4V1H6V2Z"
            fill="currentColor"
        />
        <path d="M11 0V10L0 9.972V11H12L11.996 0H11Z" fill="currentColor" />
        <path
            d="M2 10C1.4486 10 1 10.4486 1 11C1 11.5514 1.4486 12 2 12C2.5514 12 3 11.5514 3 11C3 10.4486 2.5514 10 2 10Z"
            fill="currentColor"
        />
        <path
            d="M9 10C8.4486 10 8 10.4486 8 11C8 11.5514 8.4486 12 9 12C9.5514 12 10 11.5514 10 11C10 10.4486 9.5514 10 9 10Z"
            fill="currentColor"
        />
    </svg>
);

export const AirportFacilitiesIcon = (props: IconProps) => (
    <Icon component={AirportFacilitiesIconSvg} {...props} />
);
