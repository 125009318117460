import React, { MouseEvent, useState, useMemo, useEffect, useContext } from 'react';
import { Moment } from 'moment';
import styles from './fixed-panel.module.scss';

import { DatePickerList } from 'ui-components/date-picker/date-picker-list/date-picker-list';
import { useFixedPreset } from 'ui-components/date-picker/date-hooks/use-fixed-preset';
import { DateContext } from 'ui-components/date-picker/date-picker';
import { convertPresetToDateSource } from 'ui-components/date-picker/date-utils/date-utils';
import { RangeData } from 'ui-components/date-picker/date-types/RangeData';

interface FixedPanelProps {
    onSelect: (range: RangeData<Moment>) => void;
    selectedKeyLabel?: string;
}

export const FixedPanel = ({ onSelect, selectedKeyLabel }: FixedPanelProps) => {
    const [activePreset, setActivePreset] = useState(selectedKeyLabel ?? '');
    const date = useContext(DateContext);
    const { months, quarters, years } = useFixedPreset();

    useEffect(() => {
        setActivePreset(selectedKeyLabel ?? '');
    }, [selectedKeyLabel, date]);

    const monthsSource = useMemo(() => {
        return convertPresetToDateSource(months);
    }, [months]);

    const quartersSource = useMemo(() => {
        return convertPresetToDateSource(quarters);
    }, [quarters]);

    const yearsSource = useMemo(() => {
        return convertPresetToDateSource(years);
    }, [years]);

    const onPreventDefault = (event: MouseEvent) => {
        event.preventDefault();
    };

    const onSelectPreset = (key: string) => {
        const preset = months[key] || quarters[key] || years[key];

        setActivePreset(key);
        onSelect({
            ...preset,
            presetSection: 'fixed',
        });
    };

    return (
        <div className={styles.container} onMouseDown={onPreventDefault}>
            <div className={styles.column} data-testid="months">
                <h5 className={styles.title}>Months</h5>
                <DatePickerList
                    className={styles.list}
                    dataSource={monthsSource}
                    activeItem={activePreset}
                    onSelect={onSelectPreset}
                />
            </div>

            <div className={styles.column} data-testid="quarters">
                <h5 className={styles.title}>Quarters</h5>
                <DatePickerList
                    className={styles.list}
                    dataSource={quartersSource}
                    activeItem={activePreset}
                    onSelect={onSelectPreset}
                />
            </div>

            <div className={styles.column} data-testid="years">
                <h5 className={styles.title}>Years</h5>
                <DatePickerList
                    className={styles.list}
                    dataSource={yearsSource}
                    activeItem={activePreset}
                    onSelect={onSelectPreset}
                />
            </div>
        </div>
    );
};
