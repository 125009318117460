import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

import { IconProps } from 'components/assets/Icons/Icons';

/* eslint-disable max-len */
export const SpaAndBeautyIconSvg = (props: CustomIconComponentProps | SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 12 12">
        <g fill="none" fillRule="evenodd">
            <path
                fill="currentColor"
                d="M7.75 4.5h.5A3.75 3.75 0 0112 8.25V10a2 2 0 01-2 2H6a2 2 0 01-2-2V8.25A3.75 3.75 0 017.75 4.5z"
            />
            <path stroke="currentColor" strokeWidth=".75" d="M5.25 3.9C3.04 3.9 1.5 5.39 1.5 7.6" />
            <path
                fill="currentColor"
                d="M1.5 7H2a1 1 0 011 1v2a1 1 0 01-1 1h-.5A1.5 1.5 0 010 9.5v-1A1.5 1.5 0 011.5 7zm8.501-3.5l.001 2.286a3.977 3.977 0 00-2.001-.536A3.98 3.98 0 006 5.786L6.001 3.5h4zM7 0h2v2.75H7zm2 .75h1.25v1H9z"
            />
        </g>
    </svg>
);

export const SpaAndBeautyIcon = (props: IconProps) => (
    <Icon component={SpaAndBeautyIconSvg} {...props} />
);
