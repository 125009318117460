import { useAppDispatch } from 'store/app-store-hooks';
import { useCallback } from 'react';

import {
    closeUpgradeYourPlanPopup,
    DynamicOpenUpgradePlanPopupProps,
    openDynamicUpgradeYourPlanPopup,
    openUpgradeYourPlanPopup,
} from 'store/app-ui/actions';
import { heapTrack } from 'utils/heap/heap';
import { UPGRADE_PLAN_SCREEN } from 'extensions/upgrade-your-plan-dialog';
import { getAppUiPermissions } from 'store/app-ui/utils/app-ui-permissions';
import { useIsFreemiumUser } from 'hooks/use-freemium-user/use-freemium-user';

const HEAP_MESSAGE = {
    default: 'Upgrade Popup Opened',
    dynamic: 'Dynamic Upgrade Popup Opened',
};

export const useAppUiActions = () => {
    const dispatch = useAppDispatch();
    const { showDynamicUpgradePopupPermission } = getAppUiPermissions();
    const isFreemiumUser = useIsFreemiumUser();
    const showDynamicModal = showDynamicUpgradePopupPermission && isFreemiumUser;

    const openDynamicUpgradePlanPopup = useCallback(
        (payload: DynamicOpenUpgradePlanPopupProps) => {
            if (showDynamicModal) {
                heapTrack(HEAP_MESSAGE.dynamic);
                dispatch(openDynamicUpgradeYourPlanPopup(payload));
            } else {
                heapTrack(HEAP_MESSAGE.default);
                dispatch(openUpgradeYourPlanPopup(UPGRADE_PLAN_SCREEN.default));
            }
        },
        [dispatch, showDynamicModal],
    );

    const openUpgradePlanPopup = useCallback(
        (screen: string) => {
            if (showDynamicModal) {
                openDynamicUpgradePlanPopup({ screen: UPGRADE_PLAN_SCREEN.dynamic });
            } else {
                dispatch(openUpgradeYourPlanPopup(screen));
                heapTrack(HEAP_MESSAGE.default);
            }
        },
        [dispatch, openDynamicUpgradePlanPopup, showDynamicModal],
    );

    const closeUpgradePlanPopup = useCallback(() => {
        dispatch(closeUpgradeYourPlanPopup());
    }, [dispatch]);

    return {
        openUpgradePlanPopup,
        closeUpgradePlanPopup,
        openDynamicUpgradePlanPopup,
    };
};
