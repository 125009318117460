/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

export type ResetIconUniqueProps = {
    width?: number | string;
    height?: number | string;
    topArrowColor?: string;
    bottomArrowColor?: string;
};

type ResetIconProps = SVGProps<SVGSVGElement> & ResetIconUniqueProps;

export const ResetIcon = function ResetIcon({
    width = 18,
    height = 18,
    topArrowColor = '#676DFD',
    bottomArrowColor = 'var(--basic-white)',
    ...props
}: ResetIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 51 51"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M37.4377 38.3848C44.3048 38.3848 49.8686 32.5178 49.8686 25.2838C49.8686 18.0471 44.3048 12.1801 37.4377 12.1801H27.2622"
                stroke="#49565D"
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                d="M14.4289 12.3516C7.56188 12.3516 1.99805 18.2186 1.99805 25.4527C1.99805 32.6894 7.56188 38.5564 14.4289 38.5564H24.6044"
                stroke="#49565D"
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.2847 27.6402L32.3006 38.5551L19.2847 49.4699V27.6402Z"
                fill={bottomArrowColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.2847 27.6402L32.3006 38.5551L19.2847 49.4699V27.6402Z"
                stroke="#49565D"
                strokeWidth="2"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M32.5824 23.0963L19.5664 12.1814L32.5824 1.26649V23.0963Z"
                fill={topArrowColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M32.5824 23.0963L19.5664 12.1814L32.5824 1.26649V23.0963Z"
                stroke="#49565D"
                strokeWidth="2"
                strokeLinejoin="round"
            />
        </svg>
    );
};
ResetIcon.__tags = ['reset', 'restore'] as string[];
