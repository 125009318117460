import React, { ReactNode } from 'react';
import styles from './button-with-tooltip.module.scss';

import { Tooltip, TooltipProps } from 'ui-components/tooltip/tooltip';
import { Button, ButtonProps } from 'ui-components/button/button';
import classNames from 'classnames';

export type ButtonWithTooltipProps = {
    buttonText: string;
    onClick?: () => void;
    disabled?: boolean;
    tooltip?: string | ReactNode;
    buttonProps?: Partial<Omit<ButtonProps, 'disabled' | 'onClick'>>;
    tooltipProps?: Partial<Omit<TooltipProps, 'title'>>;
    buttonContainerClassName?: string;
    testId?: string;
    isLoading?: boolean;
};

/** A disabled button would not have a mouse out effect on a tooltip (the tooltip would remain)
 * support a <Tooltip> for a <Button>, with the ability to have the tooltip removed on mouse out */

export const ButtonWithTooltip = ({
    buttonText,
    onClick,
    disabled = false,
    tooltip,
    tooltipProps,
    buttonProps,
    buttonContainerClassName,
    testId,
    isLoading = false,
}: ButtonWithTooltipProps) => {
    return (
        <Tooltip small title={tooltip} placement="top" {...tooltipProps}>
            <div
                className={classNames(styles.buttonContainer, buttonContainerClassName, {
                    [styles.disabled]: disabled,
                })}
            >
                <Button
                    data-testid={testId}
                    onClick={disabled ? undefined : onClick}
                    type={'brand-primary-full'}
                    loading={isLoading}
                    {...buttonProps}
                >
                    {buttonText}
                </Button>
            </div>
        </Tooltip>
    );
};
