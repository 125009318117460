import { RefObject, useEffect } from 'react';
import { GoogleMap as RGMGoogleMap } from '@react-google-maps/api';
import { useSelectorWithProps } from 'utils/structured-selector/structured-selector';
import { selectHasUserPermission } from 'store/selectors/app-selectors';
import { toRadians } from 'chart.js/helpers';

const checkIfScaleIsMiles = (mapRef?: Element | null) => {
    let mapScale;
    if (mapRef) {
        const mapFooterElements = [...mapRef.querySelectorAll('.gm-style-cc:not(.gmnoprint)')];
        mapScale = mapFooterElements.find((el) => /\W(mi|ft)\W/.test(el.textContent || ''));
    }

    return !!mapScale;
};

const getMapScale = (mapRef?: Element | null) => {
    let mapScale;
    if (mapRef) {
        const mapFooterElements = [...mapRef.querySelectorAll('.gm-style-cc:not(.gmnoprint)')];
        mapScale = mapFooterElements.find((el) => /\W(km|m)\W/.test(el.textContent || ''));
    }

    return mapScale;
};

const changeMetersToFeet = (interval: NodeJS.Timeout, ref: RefObject<RGMGoogleMap>) => {
    const isMiles = checkIfScaleIsMiles(ref.current?.mapRef);
    const mapScaleInKm = getMapScale(ref.current?.mapRef);

    if (isMiles) {
        clearInterval(interval);
    } else if (mapScaleInKm) {
        mapScaleInKm.dispatchEvent(new Event('click'));
        clearInterval(interval);
    }
};

export const useGoogleMapsChangeMetersToFeet = (ref: RefObject<RGMGoogleMap>) => {
    const hasPermission = useSelectorWithProps(
        { permission: 'enable_maps_default_to_feet' },
        selectHasUserPermission,
    );

    useEffect(() => {
        let interval: NodeJS.Timeout;
        if (hasPermission) {
            interval = setInterval(() => changeMetersToFeet(interval, ref), 100);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [hasPermission, ref]);
};

// get distance between two coordinates using google maps API
export const getDistanceBetweenCoordinates = ([lat1, lng1]: number[], [lat2, lng2]: number[]) => {
    return google.maps.geometry.spherical.computeDistanceBetween(
        new google.maps.LatLng(lat1, lng1),
        new google.maps.LatLng(lat2, lng2),
    );
};

// get middle coordinate from 2 LatLng using google maps api
export const getMiddleCoordinate = ([lat1, lng1]: number[], [lat2, lng2]: number[]) => {
    const startLatLng = new google.maps.LatLng(lat1, lng1);
    const endLatLng = new google.maps.LatLng(lat2, lng2);
    const middleLatLng = google.maps.geometry.spherical.interpolate(startLatLng, endLatLng, 0.5);
    return [middleLatLng.lng(), middleLatLng.lat()];
};

// get angle between two coordinates using geometry
export const getAngleBetweenCoordinates = ([lat1, lng1]: number[], [lat2, lng2]: number[]) => {
    const startLatLng = new google.maps.LatLng(lat1, lng1);
    const endLatLng = new google.maps.LatLng(lat2, lng2);
    return toRadians(google.maps.geometry.spherical.computeHeading(startLatLng, endLatLng));
};
