import { selectHasUserPermission } from 'store/selectors/app-selectors';
import { useSelectorWithProps } from 'utils/structured-selector/structured-selector';
import { useFeatureFlags } from 'hooks/feature-flags/use-feature-flags';

export const useMyZonePropertiesPermissions = () => {
    const {
        enable_share_of_tag_metrics_ff,
        enable_short_visit_cpoi_portfolio_ff,
        enable_my_properties_add_remove: hasAddAndRemovePermission,
    } = useFeatureFlags();

    const hasEnableNewPropertiesTextPermission = useSelectorWithProps(
        { permission: 'enable_new_properties_text_change' },
        selectHasUserPermission,
    );
    const hasMetricSelectorPermissions = useSelectorWithProps(
        { permission: 'enable_metrics_selector_temp' },
        selectHasUserPermission,
    );
    const hasFlaggedTenantsPermissions = useSelectorWithProps(
        { permission: 'enable_tenant_filter_flagged' },
        selectHasUserPermission,
    );
    const showFullData = useSelectorWithProps(
        { permission: 'show_qa_features' },
        selectHasUserPermission,
    );
    const hasMoreMetricsPermission = useSelectorWithProps(
        { permission: 'enable_my_properties_more_default_metrics' },
        selectHasUserPermission,
    );
    const hadPercentileBarPermission = useSelectorWithProps(
        { permission: 'enable_nice_ranking_in_table' },
        selectHasUserPermission,
    );
    const hasRankingIndicatorPermission = useSelectorWithProps(
        { permission: 'enable_ranking_quality_indicator' },
        selectHasUserPermission,
    );

    const hasNewCategoriesFormat = useSelectorWithProps(
        { permission: 'enable_revamp_category_front_end' },
        selectHasUserPermission,
    );

    const hasCsvStoreIdFF = useSelectorWithProps(
        { permission: 'enable_store_id_in_csvs_ff' },
        selectHasUserPermission,
    );

    return {
        hasEnableNewPropertiesTextPermission,
        hasMetricSelectorPermissions,
        hasFlaggedTenantsPermissions,
        showFullData,
        hasMoreMetricsPermission,
        hadPercentileBarPermission,
        hasRankingIndicatorPermission,
        hasAddAndRemovePermission,
        hasNewCategoriesFormat,
        hasCsvStoreIdFF,
        hasEnabledShareMetrics: enable_share_of_tag_metrics_ff,
        hasNewCustomPoiComingSoon: enable_short_visit_cpoi_portfolio_ff,
    };
};
