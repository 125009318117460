import React from 'react';
import { PoiSelectionMenuItem } from 'features/poi-selection-map/types/poi-selection-types';
import { TagsSelectorMenu } from 'features/poi-selection-map/poi-selection-menu/menu-items/tags-menu-items/tags-selector-menu/tags-selector-menu';

export const MY_TAGS_ITEM_ID = 'my tags menu item';

export const MyTagsMenuItem: PoiSelectionMenuItem = {
    id: MY_TAGS_ITEM_ID,
    title: 'My Tags',
    render: ({ visible }) => {
        return <>{visible && <TagsSelectorMenu type={'personalTags'} />}</>;
    },
};
