import type { FilterCheckboxes } from 'features/site-selection/common/types/filters/filters';
import type { FilterTypeCheckboxes } from 'types/advanced-reports-analysis/filters';
import {
    CheckBoxAction,
    CheckboxOption,
    CheckboxOptionSubCategory,
} from 'types/advanced-reports-analysis/filters';

type SortedCheckboxOptions = {
    checked: { counter: number; options: FilterTypeCheckboxes['options'] };
    unchecked: { counter: number; options: FilterTypeCheckboxes['options'] };
    indeterminate: { options: FilterTypeCheckboxes['options'] };
};

export const hasSubOptionChecked = (subOption: CheckboxOptionSubCategory | undefined) => {
    if (subOption) {
        return Object.keys(subOption).some((key) => subOption[key]);
    }
    return false;
};

export const sortedCheckboxFilterOptions = (
    filterOptions: FilterTypeCheckboxes['options'] = {},
    preferIncludeCb?: (checkedCounter: number, uncheckedCounter: number) => boolean,
) => {
    const options = Object.keys(filterOptions).reduce<SortedCheckboxOptions>(
        (acc, curr) => {
            const checkboxOption: CheckboxOption = filterOptions[curr];
            const isIndeterminate = hasSubOptionChecked(checkboxOption.sub_options ?? undefined);
            if (checkboxOption.checked) {
                acc.checked.options[curr] = checkboxOption;
                acc.checked.counter++;
            } else if (isIndeterminate) {
                acc.indeterminate.options[curr] = checkboxOption;
            } else {
                acc.unchecked.options[curr] = checkboxOption;
                acc.unchecked.counter++;
            }
            return acc;
        },
        {
            checked: {
                counter: 0,
                options: {},
            },
            unchecked: {
                counter: 0,
                options: {},
            },
            indeterminate: {
                options: {},
            },
        },
    );
    const preferInclude =
        preferIncludeCb?.(options.checked.counter, options.unchecked.counter) ||
        options.checked.counter <= options.unchecked.counter;
    const actionType: CheckBoxAction = preferInclude ? 'include' : 'exclude';
    const optionsToSend =
        actionType === 'exclude' ? options.unchecked.options : options.checked.options;

    const results: {
        action: CheckBoxAction;
        options: FilterTypeCheckboxes['options'];
    } = {
        action: actionType,
        options: {
            ...optionsToSend,
            ...options.indeterminate.options,
        },
    };

    return results;
};

export function mergeOptions(
    allOptions: FilterCheckboxes['options'],
    selectedOptions: FilterCheckboxes['options'],
) {
    const newOptions: FilterCheckboxes['options'] = {};

    Object.keys(allOptions).forEach((key) => {
        const option = allOptions[key];
        const selectedOption = selectedOptions[key] ?? {};
        // `key` is the value of the option
        newOptions[key] = {
            ...option,
            ...selectedOption,
        };
    });

    return newOptions;
}
