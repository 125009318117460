import React, { MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { cloneDeep, isEqual } from 'lodash/fp';
import { userCustomSettingsSelector } from 'store/selectors/app-selectors';
import {
    ElementRefs,
    PoiMenuTableItem,
    PoiSelectionMapConfiguration,
    PoiSelectionUserSettings,
} from 'features/poi-selection-map/types/poi-selection-types';
import { usePoiSelectionContext } from 'features/poi-selection-map/context/poi-selection-context';
import { PoiSelectionListItem } from 'features/poi-selection-map/poi-selection-menu/shared-components/poi-selection-list-item/poi-selection-list-item';
import { hasTextEllipsis } from 'utils/has-text-ellipsis/has-text-ellipsis';
import type { Geolocation } from '@placer-ui/types';
import { useSetUserCustomSettings } from 'store/auth/actions/set-user-custom-settings/set-user-custom-settings';
import { MapLayerPOIType } from 'ui-components/google-map-layers/utils/map-layers-entity-color';
import { POI_TYPE_LABELS } from 'features/poi-selection-map/constants/poi-selection-map-constants';
import { MarkerTooltip } from 'ui-components/google-map-layers/components/marker-tooltip/marker-tooltip';
import {
    GoogleMapLayersProps,
    PoiEntity,
} from 'ui-components/google-map-layers/types/google-map-layers-types';
import { useOnZoomToGeolocation } from 'features/poi-selection-map/hooks/poi-selection-map-hooks';
import { numberFormatWithNullSupport } from 'utils/number-format-with-null/number-format-with-null';
import { useGetEnclosingComplexOptions } from 'features/poi-selection-map/poi-selection-menu/menu-items/search-menu-item/hooks/search-enclosing-complexes-hooks';
import { useGetOverlayPois } from 'features/poi-selection-map/hooks/use-map-overlay-hooks';
import { useSplunkData } from 'features/poi-selection-map/hooks/use-poi-selection-tracking-hooks';
import { useGetMapPositionWithOffset } from 'ui-components/google-map/hooks/use-get-map-position-with-offset';

export const usePoiSelectionColumns = () => {
    return useMemo(
        () => [
            {
                key: 'property',
                title: 'Property',
                width: '70%',
                render: (rowData: PoiMenuTableItem) => {
                    return (
                        <PoiSelectionListItem title={rowData.title} subtitle={rowData.subtitle} />
                    );
                },
            },
            {
                key: 'visits',
                title: 'Monthly Visits',
                width: '30%',
                render: (rowData: PoiMenuTableItem) => {
                    return (
                        <span>{numberFormatWithNullSupport(rowData.visits, 1, true, true)}</span>
                    );
                },
            },
        ],
        [],
    );
};

export const useShowTooltipIfNeeded = (ref: MutableRefObject<HTMLDivElement | null>) => {
    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    useEffect(() => {
        if (ref.current) {
            setShowTooltip(hasTextEllipsis(ref.current));
        }
    }, [ref]);
    return showTooltip;
};

export const usePoisListAsGeoLocation = () => {
    const { poisListPerCurrentPage } = usePoiSelectionContext();
    return useMemo(
        //@ts-expect-error PLAC-47814
        () => poisListPerCurrentPage?.map<Geolocation>(({ info: { geolocation } }) => geolocation),
        [poisListPerCurrentPage],
    );
};

export const useSetElementObjectRefs = (ref: HTMLDivElement | null, key: keyof ElementRefs) => {
    const { setElementRefObjects, elementRefObjects, isContentVisible } = usePoiSelectionContext();

    //set refs and update DOMRect
    const setRefs = useCallback(() => {
        elementRefObjects[key].ref = ref;
        Object.keys(elementRefObjects).forEach((key: string) => {
            const elementRefKey = key as keyof ElementRefs;
            const elementRefObject = elementRefObjects[elementRefKey];
            if (elementRefObject.ref) {
                const rect = elementRefObject.ref.getBoundingClientRect();
                setElementRefObjects({
                    ...elementRefObject,
                    rect,
                });
            }
        });
    }, [elementRefObjects, key, ref, setElementRefObjects]);

    const debouncedSetRefsAndUpdateMap = useDebouncedCallback(setRefs, 100);

    useEffect(() => {
        if (!ref || !isContentVisible) return;
        debouncedSetRefsAndUpdateMap();
    }, [
        ref,
        key,
        setElementRefObjects,
        isContentVisible,
        setRefs,
        elementRefObjects,
        debouncedSetRefsAndUpdateMap,
    ]);
};

export const useUpdateMapConfig = () => {
    const { setMapConfig, mapConfig: currentMapConfig } = usePoiSelectionContext();
    const getMapOffset = useGetMapOffset();
    const getMapConfigWithOffset = useGetMapPositionWithOffset();
    const { mapRef } = usePoiSelectionContext();

    return useCallback(
        (elementRefs: ElementRefs) => {
            if (!mapRef) return;
            const offset = getMapOffset(elementRefs) || 0;
            const mapConfigWithOffset = getMapConfigWithOffset({
                mapRef,
                offset,
            });
            const newConfig: PoiSelectionMapConfiguration = {
                ...currentMapConfig,
                ...mapConfigWithOffset,
                center: mapRef.getCenter().toJSON(),
                zoom: mapRef.getZoom(),
                mapTypeId: mapRef.getMapTypeId(),
                mapOffset: offset,
            };
            if (!isEqual(newConfig, currentMapConfig)) {
                setMapConfig(newConfig);
            }
        },
        [mapRef, getMapOffset, getMapConfigWithOffset, currentMapConfig, setMapConfig],
    );
};

export const useGetMapOffset = () => {
    return useCallback(({ mapContainer, poiSelector }: ElementRefs) => {
        if (poiSelector?.rect && mapContainer?.rect) {
            return poiSelector.rect.x + poiSelector.rect.width - mapContainer.rect.x;
        }
    }, []);
};

export const useSelectCustomSettingsPoiSelectionMap = () => {
    const customSettings = useSelector(userCustomSettingsSelector);
    const {
        configuration: { userCustomSettingsNamespace },
    } = usePoiSelectionContext();
    return customSettings?.poiSelectionMap?.[userCustomSettingsNamespace];
};

export const useGetOnEvent = () => {
    const {
        configuration: { onEvent },
    } = usePoiSelectionContext();

    return onEvent;
};

export const useUpdateCustomUserSettings = () => {
    const customSettings = useSelector(userCustomSettingsSelector);
    const {
        configuration: { userCustomSettingsNamespace },
    } = usePoiSelectionContext();
    const setCustomSettings = useSetUserCustomSettings();

    return useCallback(
        (userSettings: PoiSelectionUserSettings) => {
            const settings = cloneDeep(customSettings) || {};
            settings.poiSelectionMap = settings.poiSelectionMap || {};
            settings.poiSelectionMap![userCustomSettingsNamespace] = userSettings;
            setCustomSettings(settings);
        },
        [customSettings, setCustomSettings, userCustomSettingsNamespace],
    );
};

export const mapPoiTypeToLabel: Partial<Record<MapLayerPOIType, POI_TYPE_LABELS>> = {
    available: POI_TYPE_LABELS.AVAILABLE,
    nearby_activity: POI_TYPE_LABELS.NEARBY_ACTIVITY,
    custom: POI_TYPE_LABELS.CUSTOM,
    closed: POI_TYPE_LABELS.CLOSED,
};

export const useGoogleMapLayersProps = () => {
    const {
        poisListPerCurrentPage,
        poiHoverId,
        poiActiveId,
        setPoiActiveId,
        setPoiHoverId,
        configuration: { allowPoiMultiSelect, poiSelectionActionButton },
    } = usePoiSelectionContext();
    const getOverlayPOI = useGetOverlayPois();
    const onZoomToGeolocation = useOnZoomToGeolocation();
    const getEnclosingComplexOptions = useGetEnclosingComplexOptions();
    const splunkData = useSplunkData();
    const markerTooltipComponent = useMemo(
        () => () =>
            (
                <MarkerTooltip
                    markerTooltipActionButton={(entity: PoiEntity) => {
                        if (!splunkData) return null;
                        return poiSelectionActionButton({
                            entity,
                            splunkData: {
                                ...splunkData,
                                origin: 'Map Info Window',
                            },
                        });
                    }}
                    onZoomToMarker={onZoomToGeolocation}
                />
            ),
        [onZoomToGeolocation, poiSelectionActionButton, splunkData],
    );

    return useMemo<GoogleMapLayersProps>(() => {
        const validPois = poisListPerCurrentPage?.filter((poi) => poi.info.geolocation);
        return {
            selectedPois: [], //TODO: implement multi-selection by config
            allPois: validPois || [],
            setActiveId: setPoiActiveId,
            setHoverId: setPoiHoverId,
            getOverlayPOI,
            getEnclosingComplexOptions,
            markerTooltipCheckboxConfig: {
                showCheckbox: allowPoiMultiSelect ?? false,
                //enableSelectionCheckbox //TODO: implement multi-selection by config
                //onPoiToggleSelection, //TODO: implement multi-selection by config
            },
            disablePolyOnHoverForNearby: true,
            hoverId: poiHoverId,
            activeId: poiActiveId,
            markerTooltipComponent,
        };
    }, [
        allowPoiMultiSelect,
        getEnclosingComplexOptions,
        getOverlayPOI,

        markerTooltipComponent,
        poiActiveId,
        poiHoverId,
        poisListPerCurrentPage,
        setPoiActiveId,
        setPoiHoverId,
    ]);
};
