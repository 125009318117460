/**
 * wraps a section with a context for transporting errors (a bit like an event emitter).
 * this wrapper catches unexpected errors and emits them too.
 * this means that both, imperative code errors (e.g. from try-catch) and declarative code errors
 * (e.g. from a component), can be caught and handled at react's level.
 * an errors-manager can be nested inside another errors-manager. this is useful for handling
 * unexpected errors at different component levels and displaying different custom messages for
 * different sections where the unexpected error occured.
 */
import React, { PropsWithChildren, useState } from 'react';
import type { ReactNode } from 'react';
import { ErrorWrapper } from 'components/errors/error-wrapper/error-wrapper';
import type { PlacerErrorParams } from './errors-manager-types';
import { PlacerError } from './placer-error';
import { ErrorsContext } from './errors-manager-context';

export type ErrorsManagerProps = PropsWithChildren<
    Omit<PlacerErrorParams, 'type'> & { uncaughtExceptionComponent?: ReactNode }
>;
export function ErrorsManager({
    children,
    page = '',
    tab = '',
    section = '',
    subSection = '',
    uncaughtExceptionComponent,
}: ErrorsManagerProps) {
    const [errorsList, setErrorsList] = useState<PlacerError[]>([]);

    return (
        <ErrorsContext.Provider
            value={{
                errorsList,
                setErrorsList,
                page,
                tab,
                section,
                subSection,
            }}
        >
            <ErrorWrapper uncaughtExceptionComponent={uncaughtExceptionComponent}>
                {children}
            </ErrorWrapper>
        </ErrorsContext.Provider>
    );
}
