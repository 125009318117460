/* eslint-disable max-len */
import React from 'react';

export const SgCPGIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
        >
            <g clipPath="url(#clip0_3566_18452)">
                <path d="M0.5 8.88867H12.7222V16.6664H0.5V8.88867Z" fill="#E1E2FF" />
                <path d="M16.0556 8.88867V16.6664H20.5V8.88867H16.0556Z" fill="#E1E2FF" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.1099 5.89129L14.9444 2.22222V1.11111C14.9444 0.497461 15.4419 0 16.0556 0H17.1667C17.7803 0 18.2778 0.497461 18.2778 1.11111V2.22222L20.3827 6.43206C20.4598 6.58634 20.5 6.75647 20.5 6.92896V18.8889C20.5 19.5025 20.0025 20 19.3889 20L1.61111 20C0.997462 20 0.5 19.5025 0.5 18.8889V6.66678L2.72222 2.22234V1.11123C2.72222 0.49758 3.21968 0.000118679 3.83333 0.000118679H9.94444C10.5581 0.000118679 11.0556 0.49758 11.0556 1.11123V2.22234L13.1099 5.89129ZM13.5556 6.86339L15.6678 2.63889H17.5544L19.6373 6.80474C19.6566 6.84331 19.6667 6.88584 19.6667 6.92896V8.88889H16.0556V16.6667H19.6667V18.8889C19.6667 19.0423 19.5423 19.1667 19.3889 19.1667H13.5556V6.86339ZM17.4444 1.80556H15.7778V1.11111C15.7778 0.957699 15.9021 0.833333 16.0556 0.833333H17.1667C17.3201 0.833333 17.4444 0.957699 17.4444 1.11111V1.80556ZM19.6667 15.8333V9.72222H16.8889V15.8333H19.6667ZM3.44564 2.63889L1.64009 6.25H8.55858L10.1062 2.63889H3.44564ZM1.33333 7.08333H8.41667V8.88889H1.33333V7.08333ZM1.33333 15.8333V9.72222H8.41667V15.8333H1.33333ZM8.41667 16.6667H1.33333V18.8889C1.33333 19.0423 1.4577 19.1667 1.61111 19.1667H8.41667V16.6667ZM12.7108 19.1667H9.25V16.6667H12.7108V19.1667ZM12.7108 9.72222V15.8333H9.25V9.72222H12.7108ZM12.7108 6.95668V8.88889H9.25V6.75219L10.7184 3.32591L12.6754 6.82097C12.6986 6.86243 12.7108 6.90916 12.7108 6.95668ZM3.55556 1.80556H10.2222V1.11123C10.2222 0.957817 10.0979 0.833452 9.94444 0.833452H3.83333C3.67992 0.833452 3.55556 0.957817 3.55556 1.11123V1.80556Z"
                    fill="#676DFD"
                />
            </g>
            <defs>
                <clipPath id="clip0_3566_18452">
                    <rect width="20" height="20" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};
