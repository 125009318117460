import React from 'react';
import type { SVGProps } from 'react';

export type TrafficPinBigIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type TrafficPinBigIconProps = SVGProps<SVGSVGElement> & TrafficPinBigIconUniqueProps;

/* eslint-disable max-len */
export const TrafficPinBigIcon = function TrafficPinBigIcon({
    width = 24,
    height = 24,
    ...props
}: TrafficPinBigIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_1985_13265)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.9316 5.45799C13.9316 2.66512 11.6977 0.399841 8.94159 0.399841H8.92169C6.16562 0.399841 3.93164 2.66512 3.93164 5.45799C3.93164 6.76246 4.36698 7.82572 5.22253 8.84461C6.07728 9.8627 8.93124 12.3998 8.93124 12.3998C8.93124 12.3998 11.7852 9.8627 12.64 8.84461C13.4963 7.82572 13.9316 6.76246 13.9316 5.45799ZM8.93122 7.37744C10.036 7.37744 10.9316 6.48196 10.9316 5.37744C10.9316 4.27376 10.036 3.37744 8.93122 3.37744C7.82731 3.37744 6.93164 4.27376 6.93164 5.37744C6.93164 6.48196 7.82731 7.37744 8.93122 7.37744Z"
                    fill="#D0D0D0"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.55664 5.3526C6.55664 4.04194 7.62008 2.9776 8.93122 2.9776C10.2431 2.9776 11.3066 4.04184 11.3066 5.3526C11.3066 6.6643 10.243 7.7276 8.93122 7.7276C7.62018 7.7276 6.55664 6.66419 6.55664 5.3526ZM8.93122 3.7276C8.03455 3.7276 7.30664 4.4559 7.30664 5.3526C7.30664 6.25004 8.03445 6.9776 8.93122 6.9776C9.82894 6.9776 10.5566 6.24993 10.5566 5.3526C10.5566 4.456 9.82883 3.7276 8.93122 3.7276Z"
                    fill="#49565D"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.64936 12.6261L8.68204 12.6552L8.93124 12.8768L9.18097 12.6548L9.21312 12.6261L9.30568 12.5434C9.38575 12.4717 9.50099 12.3682 9.64191 12.2409C9.92367 11.9864 10.3084 11.6362 10.7202 11.254C11.5362 10.4965 12.4819 9.59125 12.9272 9.0609C13.7609 8.06887 14.2286 7.01847 14.2977 5.76317H18V5.01317H14.2908C14.0792 2.21341 11.7696 0 8.94159 0H8.92169C6.09372 0 3.78408 2.21341 3.57245 5.01317H0V5.76317H3.56561C3.63471 7.0185 4.10235 8.06888 4.93533 9.0609C5.38059 9.59125 6.32629 10.4965 7.14228 11.254C7.55404 11.6362 7.93881 11.9864 8.22058 12.2409C8.3615 12.3682 8.47674 12.4717 8.5568 12.5434L8.64936 12.6261ZM4.30664 5.43315C4.30664 2.84254 6.37755 0.75 8.92169 0.75H8.94159C11.4857 0.75 13.5566 2.84254 13.5566 5.43315C13.5566 6.63863 13.1596 7.61864 12.3528 8.57865C11.9433 9.06638 11.0346 9.93877 10.2099 10.7043C9.80136 11.0836 9.41921 11.4314 9.13915 11.6844C9.06142 11.7546 8.99158 11.8175 8.93124 11.8717C8.87091 11.8175 8.80107 11.7546 8.72333 11.6844C8.44327 11.4314 8.06113 11.0836 7.65255 10.7043C6.82788 9.93877 5.9192 9.06636 5.50971 8.57863C4.70356 7.61857 4.30664 6.63865 4.30664 5.43315Z"
                    fill="#49565D"
                />
                <path d="M1.52832 9.24571H3.27832V9.99571H1.52832V9.24571Z" fill="#49565D" />
                <path
                    d="M3.90332 13.2283H0.0283203V13.9783H3.90332C3.97236 13.9783 4.02832 14.0343 4.02832 14.1033V18H4.77832V14.1033C4.77832 13.62 4.38657 13.2283 3.90332 13.2283Z"
                    fill="#49565D"
                />
                <path
                    d="M13.9924 14.1033C13.9924 14.0343 14.0484 13.9783 14.1174 13.9783H17.9924V13.2283H14.1174C13.6342 13.2283 13.2424 13.62 13.2424 14.1033V18H13.9924V14.1033Z"
                    fill="#49565D"
                />
                <path d="M16.4924 9.24571H14.7424V9.99571H16.4924V9.24571Z" fill="#49565D" />
                <path d="M9.375 14.7283V16.4783H8.625V14.7283H9.375Z" fill="#49565D" />
            </g>
            <defs>
                <clipPath id="clip0_1985_13265">
                    <rect width="18" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
TrafficPinBigIcon.__tags = ['traffic pin', 'billboard'] as string[];
