/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

type HelpMobileIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type HelpMobileIconProps = SVGProps<SVGSVGElement> & HelpMobileIconUniqueProps;

export const HelpMobileIcon = function HelpMobileIcon({
    width = 19,
    height = 18,
    fill = '#827F94',
    ...props
}: HelpMobileIconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 19 18"
            fill="none"
        >
            <path
                d="M18.5938 9C18.5938 10.78 18.0659 12.5201 17.077 14.0001C16.088 15.4802 14.6824 16.6337 13.0379 17.3149C11.3934 17.9961 9.58377 18.1743 7.83794 17.8271C6.09211 17.4798 4.48846 16.6226 3.22979 15.364C1.97112 14.1053 1.11395 12.5016 0.766687 10.7558C0.41942 9.00998 0.597649 7.20038 1.27884 5.55585C1.96003 3.91131 3.11358 2.50571 4.59362 1.51677C6.07366 0.52784 7.81372 0 9.59375 0C11.9798 0.00280222 14.2674 0.951914 15.9546 2.63913C17.6418 4.32635 18.5909 6.61391 18.5938 9ZM17.5349 9C17.5349 7.42938 17.0692 5.89404 16.1966 4.58812C15.324 3.2822 14.0838 2.26436 12.6327 1.66331C11.1816 1.06226 9.58494 0.904999 8.04451 1.21141C6.50407 1.51782 5.08909 2.27415 3.97849 3.38474C2.8679 4.49533 2.11158 5.91031 1.80516 7.45075C1.49875 8.99119 1.65601 10.5879 2.25706 12.039C2.85811 13.49 3.87595 14.7303 5.18187 15.6028C6.48779 16.4754 8.02314 16.9412 9.59375 16.9412C11.6992 16.9388 13.7177 16.1014 15.2064 14.6127C16.6952 13.1239 17.5326 11.1054 17.5349 9Z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.81592 10.627V10.9346H8.47607V10.4971C8.47607 9.99577 8.56722 9.57878 8.74951 9.24609C8.9318 8.91341 9.25081 8.56934 9.70654 8.21387C10.2489 7.78548 10.5986 7.4528 10.7559 7.21582C10.9131 6.97884 10.9917 6.69629 10.9917 6.36816C10.9917 5.98535 10.8641 5.69141 10.6089 5.48633C10.3537 5.28125 9.98682 5.17871 9.5083 5.17871C9.07536 5.17871 8.67432 5.24023 8.30518 5.36328C7.93604 5.48633 7.57601 5.63444 7.2251 5.80762L6.65088 4.60449C7.57601 4.08952 8.56722 3.83203 9.62451 3.83203C10.5177 3.83203 11.2264 4.05078 11.7505 4.48828C12.2746 4.92578 12.5366 5.52962 12.5366 6.2998C12.5366 6.6416 12.4865 6.9458 12.3862 7.2124C12.286 7.479 12.1344 7.73307 11.9316 7.97461C11.7288 8.21615 11.3791 8.5306 10.8823 8.91797C10.4585 9.25065 10.1748 9.52637 10.0312 9.74512C9.8877 9.96387 9.81592 10.2578 9.81592 10.627ZM9.94922 13.8843C9.77376 14.0734 9.52425 14.168 9.20068 14.168C8.87712 14.168 8.62874 14.0757 8.45557 13.8911C8.28239 13.7065 8.1958 13.4479 8.1958 13.1152C8.1958 12.4271 8.53076 12.083 9.20068 12.083C9.52881 12.083 9.77946 12.173 9.95264 12.353C10.1258 12.533 10.2124 12.7871 10.2124 13.1152C10.2124 13.4388 10.1247 13.6951 9.94922 13.8843Z"
                fill={fill}
            />
        </svg>
    );
};
HelpMobileIcon.__tags = [] as string[];
