import React, { useRef } from 'react';
import { Tooltip } from 'ui-components/tooltip/tooltip';
import styles from './option-title.module.scss';
import Highlighter from 'react-highlight-words';
import classnames from 'classnames';
import { shouldRenderMobileApp } from 'utils/detect-environment';

type TreeTitleProps = {
    title: string;
    searchValue: string;
    isDropMenu: boolean;
    titleClass?: string;
};

export const TreeTitle = ({ title, searchValue, isDropMenu, titleClass }: TreeTitleProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const isMobile = shouldRenderMobileApp();
    return (
        <Tooltip
            title={isMobile ? undefined : title}
            placement="right"
            showOnlyInCaseOfEllipsis={true}
            ellipsisElementRef={containerRef}
        >
            <div
                ref={containerRef}
                data-testid={`leaf-title-${title}`}
                className={classnames(styles.checkboxName, titleClass, {
                    [styles.dropdown]: isDropMenu,
                })}
            >
                <Highlighter
                    autoEscape
                    searchWords={[searchValue]}
                    textToHighlight={title}
                    highlightClassName={styles.highlight}
                    className="highlightTextWrapper"
                />
            </div>
        </Tooltip>
    );
};
