import { createSelector } from '@reduxjs/toolkit';

import {
    selectChainsReportSearchAutoCompleteValue,
    selectChainsReportSearchSection,
} from './select-chains-search-section';
import { ChainsReportState } from 'features/industry/store/chains-store';
import { selectStatesNames } from 'store/configuration/attributes/selectors';
import { ChainsReportSearchState } from '../../models';

const selectChainsReportSearchSectionSearchResultsChains = (state: ChainsReportState) => {
    const searchSection = selectChainsReportSearchSection(state);

    return searchSection.autoCompleteMatchingChains;
};

const selectIsAutoCompleteMatchingChainsGotResults = createSelector(
    selectChainsReportSearchSection,
    (searchSection: ChainsReportSearchState) => {
        return searchSection.isAutoCompleteMatchingChainsGotResults;
    },
);

export const selectChainsSearchAddChainAutoCompleteResultsAreLoading = (
    state: ChainsReportState,
) => {
    const searchSection = selectChainsReportSearchSection(state);

    return searchSection.autoCompleteSearchResultsAreLoading;
};

export const selectChainsSearchResultsAreLoading = (state: ChainsReportState) => {
    const searchSection = selectChainsReportSearchSection(state);

    return searchSection.chainsSearchResultsAreLoading;
};

// NOTE! This is a temporary frontend solution to get states.
// This should be done on backend to keep fuzziness of the search results consistent.
export const selectChainsReportSearchSectionMatchingStates = (state: ChainsReportState) => {
    const states = selectStatesNames(state);
    const searchTerm = selectChainsReportSearchAutoCompleteValue(state).toLowerCase();
    if (!searchTerm) {
        return [];
    }

    return states.filter((stateName) => {
        return stateName.toLocaleLowerCase().includes(searchTerm);
    });
};

const selectMatchingStatesAutoCompleteViewModels = createSelector(
    selectChainsReportSearchSectionMatchingStates,
    (matchingStatesNames) =>
        matchingStatesNames.map((stateName) => {
            return {
                name: stateName,
                country: 'United States',
            };
        }),
);

export const selectChainsReportSearchSectionSearchResults = createSelector(
    selectMatchingStatesAutoCompleteViewModels,
    selectChainsReportSearchSectionSearchResultsChains,
    selectIsAutoCompleteMatchingChainsGotResults,
    (allMatchingStatesViewModels, allChains, isReturnedResponse) => {
        const MAX_NUMBER_OF_STATES_TO_DISPLAY = 3;
        const MAX_NUMBER_OF_AUTOCOMPLETE_OPTIONS = 6;

        const statesViewModelsToDisplay = allMatchingStatesViewModels.slice(
            0,
            MAX_NUMBER_OF_STATES_TO_DISPLAY,
        );
        const chainsToDisplay = allChains.slice(
            0,
            MAX_NUMBER_OF_AUTOCOMPLETE_OPTIONS - statesViewModelsToDisplay.length,
        );

        if (!isReturnedResponse || !chainsToDisplay.length) return [];

        return [...statesViewModelsToDisplay, ...chainsToDisplay];
    },
);
