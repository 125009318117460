import { createAction } from '@reduxjs/toolkit';
import { SectionType } from 'types/section';

interface KeyValue {
    key: string;
    value: unknown;
}
export type SidePanelExpandedState = 'expanded' | 'collapsed' | 'closed';
export type SetIsSidePanelExpanded = {
    section: SectionType;
    expandedState: SidePanelExpandedState;
};

export type DynamicOpenUpgradePlanPopupProps = {
    screen: string;
    title?: string;
    bullets?: string[];
    videoId?: string;
};

const openUpgradeYourPlanPopupAction = 'app-ui/open-upgrade-your-plan-popup';
export const openUpgradeYourPlanPopup = createAction<string>(openUpgradeYourPlanPopupAction);

const openDynamicUpgradeYourPlanPopupAction = 'app-ui/open-dynamic-upgrade-your-plan-popup';
export const openDynamicUpgradeYourPlanPopup = createAction<DynamicOpenUpgradePlanPopupProps>(
    openDynamicUpgradeYourPlanPopupAction,
);

const closeUpgradeYourPlanPopupAction = 'app-ui/close-upgrade-your-plan-popup';
export const closeUpgradeYourPlanPopup = createAction(closeUpgradeYourPlanPopupAction);

const setIsSidePanelExpandedAction = 'app-ui/set-is-side-panel-expanded';
export const setIsSidePanelExpanded = createAction<SetIsSidePanelExpanded>(
    setIsSidePanelExpandedAction,
);

export const setKeyValue = createAction<KeyValue>('app-ui/set-key-value');
