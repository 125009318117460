export const GOOGLE_CLIENT_ID: string =
    '689760566032-nnmetbiclo33bob8e94j38s1n81d2qf4.apps.googleusercontent.com';
export const GOOGLE_RECAPTCHA_SITE_KEY: string = '6Ldb5v4UAAAAAKH5qXumNGiJkeS5WGN4JgGUlyRL';
export const GOOGLE_RECAPTCH_VERSION: number = 2;

export const BACKEND_DEFAULT_CODE: number = 0;
export const BACKEND_SUCCESS_CODE: number = 200;
export const BACKEND_INVALID_ARGUMENTS_ERROR_CODE: number = 400;
export const BACKEND_INACTIVE_ACCOUNT_ERROR_CODE: number = 401;
export const BACKEND_USER_NOT_ACTIVE_ERROR_CODE: number = 1009;
export const SSO_FORCE_ACCOUNT: number = 1014;
export const BACKEND_INVALID_CREDENTIALS_ERROR_CODE: number = 403;
export const BACKEND_EMAIL_ALREADY_REGISTERED_ERROR_CODE: number = 409;
export const BACKEND_RECAPTCHA_ERROR_CODE: number = 412;
export const BACKEND_ACCOUNT_LOCKED_ERROR_CODE: number = 423;
export const BACKEND_UNKNOWN_ERROR_CODE: number = 500;
export const BACKEND_INTERNET_CONN_ERROR_CODE: number = 502;

export const BACKEND_ERROR_CODES_TO_REPORT: ReadonlyArray<number> = [
    BACKEND_UNKNOWN_ERROR_CODE,
    BACKEND_INTERNET_CONN_ERROR_CODE,
];

export const FORM_PLACEHOLDERS = {
    WORK_EMAIL_PLACEHOLDER: 'Work Email',
    YOUR_EMAIL_PLACEHOLDER: 'Your email address',
    PASSWORD_PLACEHOLDER: 'Password',
    NEW_PASSWORD_PLACEHOLDER: 'New Password',
    CONFIRM_PASSWORD_PLACEHOLDER: 'Confirm Password',
    COMPANY_NAME_PLACEHOLDER: 'Company',
    FIRST_NAME_PLACEHOLDER: 'First Name',
    LAST_NAME_PLACEHOLDER: 'Last Name',
} as const;

const baseSplunkData = {
    state: 'signin/signup-flow/sso',
    version: 'v1',
    source_url: window.location.href,
};

export const loginBaseSplunkData = {
    view_name: 'Signin',
    ...baseSplunkData,
};

export const signupBaseSplunkData = {
    view_name: 'Signup',
    ...baseSplunkData,
};

export const ssoBaseSplunkData = {
    view_name: 'sso',
    ...baseSplunkData,
};

export const forgotPasswordBaseSplunkData = {
    component: 'forgot-password',
    view_name: 'forgot-password',
    state: 'sign-in',
};

export const forgotPasswordLinkClickSplunkData = {
    action: 'forgot password',
    component: 'login',
    value: 'Click',
    view_name: 'login',
    state: 'sign-in',
};

export const loginFormId = '01b7d8b7-47e3-4f18-a6a9-2095a6371869';
export const signupFormId = 'e7ca6a8f-4a29-46a3-a81a-a9df72a62b63';
export const googleSignupFormId = 'eb189b2c-a213-40ae-ba27-9fbfe7c989e4';

export const HubSpotFieldNames = {
    email: 'email',
    firstname: 'firstname',
    lastname: 'lastname',
    company: 'company',
    signupMethod: 'signup_method',
    utmSource: 'utm_source',
    utmMedium: 'utm_medium',
    utmCampaign: 'utm_campaign_v2',
    phone: 'phone',
    WS_companyName: 'WS_companyName',
    WS_employeeCount: 'WS_employeeCount',
    WS_industries: 'WS_industries',
};
