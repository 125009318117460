import React from 'react';

export const BenefitIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
        >
            <rect y="0.469421" width="18" height="18" rx="9" fill="#E1E2FF" />
            <path
                d="M5 9.11648L7.39439 11.4052C7.43225 11.4414 7.49162 11.4423 7.53049 11.4072L13 6.46942"
                stroke="#5E63E5"
                strokeLinecap="round"
            />
        </svg>
    );
};
