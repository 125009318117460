import React, { PropsWithChildren, CSSProperties } from 'react';
import classnames from 'classnames';
import { useAppUiActions } from 'store/app-ui/use-app-ui-actions';
import { UPGRADE_PLAN_SCREEN } from 'extensions/upgrade-your-plan-dialog';
import styles from './locked-functionality-overlay.module.scss';
import { DynamicOpenUpgradePlanPopupProps } from 'store/app-ui/actions';

type LockedFunctionalityOverlayProps = {
    isLocked: boolean;
    style?: CSSProperties;
    className?: any;
    testId?: string;
    upgradePopupProps?: DynamicOpenUpgradePlanPopupProps;
};

export const LockedFunctionalityOverlay = ({
    isLocked,
    style,
    children,
    className,
    testId,
    upgradePopupProps,
}: PropsWithChildren<LockedFunctionalityOverlayProps>) => {
    const { openUpgradePlanPopup, openDynamicUpgradePlanPopup } = useAppUiActions();

    if (!isLocked) {
        return null;
    }

    const classes = classnames(styles.fullSizeLockedFunctionalityOverlay, className);

    return (
        <div
            className={classes}
            style={style}
            onClick={(e) => {
                e.stopPropagation();
                if (upgradePopupProps) {
                    openDynamicUpgradePlanPopup(upgradePopupProps);
                } else {
                    openUpgradePlanPopup(UPGRADE_PLAN_SCREEN.default);
                }
            }}
            data-testid={testId}
        >
            {children}
        </div>
    );
};
