import queryString from 'query-string';
import { fetchWrapper } from 'API/authentification/fetch-wrapper';
import type {
    Area,
    AreaType,
    Billboard,
    Chain,
    PlaceCollection,
    PlaceType,
    PlacerEntityResponse,
    Venue,
} from '@placer-ui/types';
import { searchAPIMeasureWrapper } from 'API/search/search-request-measurment-wrapper';
import { PointOfInterestEntities } from '@placer-ui/types/src/entities/entity-unions';
import { isMobileApp, isMobileWeb } from 'utils/detect-environment';

type NorthEastCoordinates = string;
type SouthWestCoordinates = string;

export type SearchSortByType = 'default' | 'foot_traffic';

export type SearchParams = {
    term?: string;
    skip?: number;
    limit?: number;
    sortBy?: SearchSortByType;
    purchasedOnly?: boolean;
    category?: string | string[];
    region?: Partial<Area>;
    sw?: SouthWestCoordinates;
    ne?: NorthEastCoordinates;
    enableOpenPIOs?: boolean;
    enableClosedPOIs?: boolean;
    enableFlaggedPOIs?: boolean;
    enableCustomPOIs?: boolean;
    enableVerifiedPOIs?: boolean;
    enableUnverifiedPOIs?: boolean;
    enableAvailablePOIs?: boolean;
    enableNearbyActivityPOIs?: boolean;
    enableNotApprovedCPOIs?: boolean;
    appendChains?: number;
    appendGoogleAddress?: number;
    fallbackGooglePOIs?: number;
    source?: string;
    showPoisInCriticalArea?: boolean;
    excludeCategory?: string[];
    subCategory?: string;
    regionType?: AreaType;
    chainType?: string;
    primaryEntityType?: PlaceType;
    primaryEntityId?: string;
    appKey?: string;
    chain_ids?: string;
};

export interface SearchAPIParams {
    query?: string;
    lat?: number;
    lng?: number;
    country?: string;
    state?: string;
    month?: string;
    type?: string;
    region_type?: Area['type'];
    region_code?: string;
    city?: string;
    category?: string | string[];
    sub_category?: string;
    chain_type?: string;
    distinct?: boolean;
    purchased_only?: boolean;
    skip?: number;
    limit?: number;
    fields?: string;
    sw?: string; // 32.4,25.5
    ne?: string; // 35.4,22.3
    primary_entity_type?: PlaceType;
    primary_entity_id?: string;
    only_created_by_me?: boolean;
    only_custom_pois?: boolean;
    enable_open_pois?: boolean;
    enable_closed_pois?: boolean;
    enable_flagged_pois?: boolean;
    enable_verified_pois?: boolean;
    enable_unverified_pois?: boolean;
    enable_custom_pois?: boolean;
    enable_available_pois?: boolean;
    enable_nearby_activity_pois?: boolean;
    enable_not_approved_cpoi?: boolean;
    append_chains?: number;
    order?: 'DESC' | 'ASC';
    sort_by?: SearchSortByType;
    append_google_address?: number;
    fallback_google_pois?: number;
    source?: string;
    show_pois_in_critical_area?: boolean;
    exclude_category?: string[];
    app_key?: string;
    chain_ids?: string;
}

function createGetEntityByType<T extends PointOfInterestEntities = PointOfInterestEntities>(
    entityType: PlaceCollection[],
) {
    return async (
        {
            term = '',
            skip,
            limit = 20,
            sortBy,
            purchasedOnly = false,
            region,
            category,
            ne,
            sw,
            enableClosedPOIs,
            enableCustomPOIs,
            enableFlaggedPOIs,
            enableOpenPIOs,
            enableVerifiedPOIs,
            enableUnverifiedPOIs,
            enableAvailablePOIs,
            enableNearbyActivityPOIs,
            enableNotApprovedCPOIs,
            appendChains,
            appendGoogleAddress,
            fallbackGooglePOIs,
            source,
            showPoisInCriticalArea,
            excludeCategory,
            subCategory,
            regionType,
            chainType,
            primaryEntityType,
            primaryEntityId,
            appKey,
            chain_ids,
        }: SearchParams,
        authorized = true,
        signal?: AbortSignal,
    ): PlacerEntityResponse<T[]> => {
        const isMobile = isMobileWeb() || isMobileApp();
        const updatedSource = source && isMobile ? `mobile_${source}` : source;

        const params: SearchAPIParams = {
            limit,
            category,
            skip,
            sort_by: sortBy,
            query: term,
            type: encodeURIComponent(entityType.join(',')),
            state: region?.code,
            chain_type: chainType ?? region?.type,
            purchased_only: purchasedOnly,
            ne,
            sw,
            enable_open_pois: enableOpenPIOs,
            enable_verified_pois: enableVerifiedPOIs,
            enable_unverified_pois: enableUnverifiedPOIs,
            enable_closed_pois: enableClosedPOIs,
            enable_flagged_pois: enableFlaggedPOIs,
            enable_custom_pois: enableCustomPOIs,
            enable_available_pois: enableAvailablePOIs,
            enable_nearby_activity_pois: enableNearbyActivityPOIs,
            enable_not_approved_cpoi: enableNotApprovedCPOIs,
            append_chains: appendChains,
            append_google_address: appendGoogleAddress,
            fallback_google_pois: fallbackGooglePOIs,
            source: updatedSource,
            show_pois_in_critical_area: showPoisInCriticalArea,
            exclude_category: excludeCategory,
            sub_category: subCategory,
            region_type: regionType,
            primary_entity_type: primaryEntityType,
            primary_entity_id: primaryEntityId,
            app_key: appKey,
            chain_ids,
        };

        const queryParams = queryString.stringify(params, {
            skipEmptyString: true,
            skipNull: true,
        });

        const url = `/search?${queryParams}`;

        return await searchAPIMeasureWrapper(
            fetchWrapper(
                {
                    targetUrl: url,
                    signal,
                },
                authorized,
            ),
            updatedSource,
        );
    };
}

export const searchChains = createGetEntityByType<Chain>(['chains']);
export const searchProperty = createGetEntityByType<Venue>(['venues', 'complexes']);
export const searchComplex = createGetEntityByType<Venue>(['complexes']);
export const searchBillboards = createGetEntityByType<Billboard>(['billboards']);
export const searchPropertyAndBillboard = createGetEntityByType<Venue & Billboard>([
    'venues',
    'complexes',
    'billboards',
]);
export const searchAnyPoi = createGetEntityByType<Venue & Billboard & Chain>([
    'venues',
    'complexes',
    'billboards',
    'chains',
]);

export const searchPOI = createGetEntityByType;

/**
 * @deprecated use the methods directly
 */
export const searchAPI = {
    searchChains,
    searchProperty,
    searchBillboards,
    searchPOI,
};
