import { createAsyncThunk } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash/fp';
import { ChainsReportState } from 'features/chains/store/chains-store';
import {
    updateReportChainsFilters,
    updateReportHavingChainsEntityModels,
} from 'features/chains/store/chains-report-chains-store';
import {
    resetChainsReportSearchState,
    selectChainsEntityModelsSelectedForReportInSearch,
} from 'features/chains/store/chains-report-search-store';
import { chainsAPI } from 'API/chains-api';
import { ChainEntityModel, ReportOption, ReportOptionsPayload } from 'core/entities';
import { updateChainsReportOptions } from 'features/chains/store/chains-report-ui-state-store/actions/update-chains-report-options/update-chains-report-options';
import { selectChains } from 'features/chains/store/common';
import type { Dictionary } from '@placer-ui/types';
import { keyBy } from 'lodash';
import { updateAddedPOIsBySelectedDuration } from 'utils/visit-duration/visit-duration';
import { getFeatureFlags } from 'core/flow-control';
import { AppDispatch } from 'store/store';
import { removePOIAttribute } from 'utils/poi-filter/poi-filter';
import { LENGTH_OF_STAY_ATTRIBUTE } from 'core/constants/filter-attributes';

export const updateReportWithChains = createAsyncThunk<void, void, { state: ChainsReportState }>(
    'chains/update-report-with-chains',
    async (_: void, { dispatch, getState }) => {
        const typedDispatch = dispatch as AppDispatch;

        // To invoke loading before receiving 'Unexpected error' case
        typedDispatch(
            updateChainsReportOptions({
                entitiesData: [],
            }),
        );

        const {
            enable_short_visit_chains_all_visits_duration_segments_ff,
            disable_segmentations_ranking_chains_posie_entities_ff,
        } = getFeatureFlags();

        const state = getState();
        const chainsState = selectChains(state);
        const entityModelsSelectedForReportInSearch =
            selectChainsEntityModelsSelectedForReportInSearch(state);

        if (isEmpty(entityModelsSelectedForReportInSearch)) {
            return;
        }

        typedDispatch(
            updateReportHavingChainsEntityModels({
                chainEntityModels: entityModelsSelectedForReportInSearch,
                suppressMissingPermissionsPopup: true,
            }),
        );

        let newEntitiesData: Dictionary<ReportOption> = {};

        try {
            const entityIds: ReportOptionsPayload = entityModelsSelectedForReportInSearch.map(
                (entity) => ({
                    entity_id: entity.originalEntity.id,
                    entity_type: entity.originalEntity.type,
                }),
            );
            const { entities_data, max_date } = await chainsAPI.getChainReportOptions(entityIds);

            newEntitiesData = keyBy(entities_data, 'entity_id');

            typedDispatch(
                updateChainsReportOptions({
                    entitiesData: entities_data,
                    maxDate: max_date,
                }),
            );
        } catch (e) {
            console.error(e);
            typedDispatch(
                updateChainsReportOptions({
                    entitiesData: [],
                }),
            );
        }

        if (enable_short_visit_chains_all_visits_duration_segments_ff) {
            let updatedEntities: ChainEntityModel[];

            // In case there is at least 1 entity with POSIE - visit duration filter should be default
            if (
                disable_segmentations_ranking_chains_posie_entities_ff &&
                Object.values(newEntitiesData).some(({ has_posie_entities }) => has_posie_entities)
            ) {
                updatedEntities = entityModelsSelectedForReportInSearch.map((poi) =>
                    removePOIAttribute(poi, LENGTH_OF_STAY_ATTRIBUTE),
                );
            } else {
                // To update visit duration filter as selected (by the user)
                updatedEntities = updateAddedPOIsBySelectedDuration(
                    chainsState.entities as Dictionary<ChainEntityModel>,
                    entityModelsSelectedForReportInSearch,
                    newEntitiesData,
                );
            }

            typedDispatch(updateReportChainsFilters(updatedEntities));
        }

        typedDispatch(resetChainsReportSearchState());
    },
);
