export function prettifyNumber(num: number, digits: number, isCurrency = false): string {
    const units = ['K', 'M', 'B', 'T', 'P', 'E', 'Z', 'Y'];

    if (isCurrency) {
        units[1] = 'MM';
    }

    for (var i = units.length - 1; i >= 0; --i) {
        let decimal = Math.pow(1000, i + 1);
        if (num > 1000000) {
            digits = digits > 2 ? digits : 2;
        }
        if (num <= -decimal || num >= decimal) {
            if ((num / decimal) % 2 > 0) {
                return +(num / decimal).toFixed(digits) + units[i];
            }
            return num / decimal + units[i];
        }
    }

    return num?.toString();
}
