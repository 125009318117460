/* eslint-disable max-len */
import React from 'react';

export const SgResearchIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="20"
            viewBox="0 0 22 20"
            fill="none"
        >
            <rect
                x="4.5"
                y="0.5"
                width="17"
                height="13"
                rx="2"
                fill="white"
                stroke="#5E63E5"
                strokeWidth="0.75"
            />
            <path
                d="M0.5 5.5C0.5 4.39543 1.39543 3.5 2.5 3.5H15.5C16.6046 3.5 17.5 4.39543 17.5 5.5V15.0385C17.5 16.143 16.6046 17.0385 15.5 17.0385H5.43758C3.83226 17.0385 2.29389 17.6818 1.16643 18.8245L0.5 19.5V5.5Z"
                fill="#E1E2FF"
                stroke="#5E63E5"
                strokeWidth="0.75"
                strokeLinejoin="round"
            />
            <rect x="3.5" y="11.5" width="2" height="3" rx="0.5" fill="#5E63E5" />
            <rect x="6.5" y="7.5" width="2" height="7" rx="0.5" fill="#5E63E5" />
            <rect x="9.5" y="8.5" width="2" height="6" rx="0.5" fill="#5E63E5" />
            <rect x="12.5" y="6.5" width="2" height="8" rx="0.5" fill="#5E63E5" />
        </svg>
    );
};
