/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';
import { IconProps } from 'components/assets/Icons/Icons';

export const MedicalCentersAndServicesIconSvg = (
    props: CustomIconComponentProps | SVGProps<SVGSVGElement>,
) => (
    <svg {...props} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.5 7C1.567 7 0 8.567 0 10.5V12H12V10.5C12 8.567 10.433 7 8.5 7H3.5ZM8 9V8H9V9H10V10H9V11H8V10H7V9H8Z"
            fill="currentColor"
        />
        <circle cx="6" cy="3" r="3" fill="currentColor" />
    </svg>
);

export const MedicalCentersAndServicesIcon = (props: IconProps) => (
    <Icon component={MedicalCentersAndServicesIconSvg} {...props} />
);
