import { MultiSelectOption } from 'ui-components/multi-select/types';

export const getSelectedOptionsKeys = (options: MultiSelectOption[]) => {
    return options.flatMap((option) => {
        if (option.selected) {
            if (option.subOptions) {
                return [
                    option.value,
                    ...option.subOptions.flatMap((subOption) => [subOption.value]),
                ];
            } else {
                return [option.value];
            }
        } else if (option.subOptions) {
            const subCategory = option.subOptions.flatMap((subOption) => {
                return subOption.selected ? [subOption.value] : [];
            });
            return [...subCategory];
        } else {
            return [];
        }
    });
};

export const getSelectedSubOptionsKeys = (options: MultiSelectOption[]): string[] => {
    return options
        .flatMap((option) => {
            return getSubOptionsRecursive(option);
        })
        .filter(({ selected }) => selected)
        .map(({ value }) => value);
};

export const getSubOptionsKeys = (options: MultiSelectOption[]): string[] => {
    return options
        .flatMap((option) => {
            return getSubOptionsRecursive(option);
        })
        .map(({ value }) => value);
};

export const getSelectedKeys = (options: MultiSelectOption[]): string[] => {
    return options.filter(({ selected }) => selected).map(({ value }) => value);
};

export const getSubOptionsRecursive = (node: MultiSelectOption): MultiSelectOption[] => {
    if (node.subOptions) {
        return node.subOptions.flatMap((item) => getSubOptionsRecursive(item));
    }
    return [node];
};

export const getSelectedSubOptions = (options: MultiSelectOption[]): MultiSelectOption[] => {
    return options
        .flatMap((option) => {
            return getSubOptionsRecursive(option);
        })
        .filter(({ selected }) => selected);
};

export const getSelectedPrimaryOptionsKeys = (options: MultiSelectOption[]): string[] => {
    return options
        .filter(({ subOptions, selected }) => {
            return !subOptions && selected;
        })
        .map(({ value }) => value);
};

export const areAllOptionsSelected = (options: MultiSelectOption[]): boolean => {
    return options.every((option) => {
        return option.selected;
    });
};
