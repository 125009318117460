import {
    CascadingItem,
    LeafMap,
    PaletteCategories,
    PaletteOption,
    RGBColor,
    SELECT_STATES,
    getPallettesByCategoryAndBucket,
    hierarchicalUtils,
} from '@placer-ui/components';
import {
    AreaType,
    CalloutBoxConfig,
    Chain,
    ConfigurationCategoriesData,
    DistanceInMilesFetchFilter,
    DistanceInMilesVisualFilter,
    DriveTimeFetchFilter,
    DriveTimeVisualFilter,
    LayerConfig,
    LayerGroupConfig,
    LayerGroupRenderTypeKey,
    POICalloutFetchFilter,
    POICalloutVisualFilter,
    Place,
    PlaceType,
    SubLayerRenderTypeKey,
    TTAHeatMapFetchFilter,
    TTAHeatMapVisualFilter,
    TaTypes,
    TradeLayerType,
    TradeLayersFetchFilters,
    TradeLayersVisualFilters,
    TrueTradeAreaFetchFilter,
    TrueTradeAreaVisualFilter,
    VehicleTrafficVolumesType,
    WalkTimeFetchFilter,
    WalkTimeVisualFilter,
    vehicleTrafficVolumeDefaultFetchFilter,
    vehicleTrafficVolumeDefaultVisualFilter,
} from './types';
import { GeoJSON } from 'geojson';
import * as turf from '@turf/turf';
import { rgbArrayToHex } from 'utils/rgb-array-to-hex copy/rgb-array-to-hex';
import {
    DEFAULT_COLOR_SEQUENTIAL_PALETTE_COLORS,
    DEFAULT_COLOR_TRADE_AREA_PALETTE_COLORS,
    DEFAULT_HEATMAP_COLOR_TRADE_AREA_PALETTE_COLORS,
} from './consts';
// import { LayersStoreState, PLMapboxLayersStore } from '@advanced-mapping/pl-mapbox';

type isChainPOIProp = {
    type?: Place['type'];
};
type CssColor =
    | `rgb(${number}, ${number}, ${number})`
    | `rgba(${number}, ${number}, ${number}, ${number})`
    | `#${string}`;

export const isChainPOI = (poiInfo: isChainPOIProp) => poiInfo.type === 'chain';

export const rgbArrayCssColor = (rgbArray: RGBColor): CssColor => {
    return `rgb(${rgbArray[0]}, ${rgbArray[1]}, ${rgbArray[2]})`;
};

type isShoppingCenterPOIProp = {
    category_info: Place['category_info'];
    type?: Place['type'];
    poiType?: string;
};

export const isShoppingCenterPOI = (poiInfo: isShoppingCenterPOIProp) => {
    return (
        (poiInfo?.category_info?.group === 'Shopping Centers' && poiInfo?.type === 'complex') ||
        (poiInfo?.category_info?.group === 'Shopping Centers' && poiInfo?.poiType === 'complex')
    );
};

export type VerifiablePOIProps = Pick<Place, 'profile' | 'type' | 'geojson' | 'geolocation'> &
    Partial<Pick<Chain, 'area'>>;

export const isPOIVerifiedOrAvailable = (poi: VerifiablePOIProps) => {
    if (poi.type === 'chain') return true;

    if (!poi.profile) {
        return false;
    }

    return poi.profile === 'available';
};

type GetTheNextFreePaletteColorsProps = {
    layerConfig: LayerConfig;
    layerGroupId?: string;
    category: PaletteCategories;
    bucket: number;
};

type CombinedLayersStatus = 'init' | 'loading' | 'loaded' | 'error';

type LayersStoreState = {
    layerGroupConfig: {
        [layerGroupId in string]: LayerGroupConfig;
    };
    backedUpLayerGroupConfig: Record<string, LayerGroupConfig>;
    combinedLayersStatus: Record<string, CombinedLayersStatus>;
    combinedLayersData: {
        [layerId in string]: Place | any;
    };
    combinedLayerSelection: Record<string, { [key in string]: any }>;
};

export const getTheNextFreePaletteColors = ({
    layerConfig,
    category,
    bucket,
    layerGroupId,
}: GetTheNextFreePaletteColorsProps) => {
    const defaultColorPaletteColors = getPallettesByCategoryAndBucket(category, bucket);

    const state = {
        maps: {
            template: {
                layerGroupConfig: layerConfig,
                combinedLayersData: {
                    ...(layerGroupId
                        ? {
                              [layerGroupId]: {
                                  type: layerConfig[layerGroupId].fetchFilter?.poiType,
                              },
                          }
                        : {}),
                },
            } as LayersStoreState,
        },
    };

    const poiPaletteIndex = getPOIPaletteIndex({
        state,
        mapId: 'template',
        layerGroupId,
    });

    return defaultColorPaletteColors[poiPaletteIndex];
};

type getPOIPaletteProps = {
    state: {
        maps?: {
            [mapId in string]: LayersStoreState;
        };
    };
    mapId: string;
    layerGroupId?: string;
};

type SupportedLayers = LayerGroupRenderTypeKey | SubLayerRenderTypeKey;

type ChainVenuesVisualFilters = {
    offsetMap: {};
    palette: {
        paletteName: string;
    };
};

const getPaletteIndexByPaletteName = (paletteName: string) => paletteName.split('-')[1];

export const getPOIPaletteIndex = ({ state, mapId, layerGroupId }: getPOIPaletteProps) => {
    const layerTypeWithPalette = new Set<SupportedLayers>([
        'tta',
        'walk_time',
        'drive_time',
        'distance_in_miles',
        'chain-venues',
    ]);
    const layerGroupConfig = state.maps?.[mapId]?.layerGroupConfig || {};
    const combinedLayersData = state.maps?.[mapId]?.combinedLayersData || {};

    if (layerGroupId) {
        const poiType = combinedLayersData[layerGroupId].type as PlaceType;

        // check if chain + have chain-venues layer with color palette
        if (poiType === 'chain') {
            const chainVenuesLayer = Object.values(
                layerGroupConfig[layerGroupId].subLayerConfig,
            ).find((layerObj) => {
                return (
                    // @ts-ignore
                    layerObj.renderType === 'chain-venues' &&
                    (layerObj.visualFilter as ChainVenuesVisualFilters).palette
                );
            });

            if (chainVenuesLayer) {
                const paletteIndex = getPaletteIndexByPaletteName(
                    (chainVenuesLayer.visualFilter as ChainVenuesVisualFilters).palette!
                        .paletteName,
                );
                return parseInt(paletteIndex) - 1;
            }
        }
    }

    const groupLayers: (LayerGroupConfig & { layerGroupId: string })[] = Object.entries(
        layerGroupConfig,
    ).map(([layerGroupId, layerGroupConfig]) => ({
        layerGroupId,
        ...layerGroupConfig,
    }));
    const subLayers = groupLayers
        .map(({ layerGroupId, subLayerConfig }) => {
            if (subLayerConfig) {
                return Object.entries(subLayerConfig).map(([subLayerId, subLayerConfig]) => {
                    return {
                        layerGroupId,
                        subLayerId,
                        ...subLayerConfig,
                    };
                });
            }
            return [];
        })
        .flat();

    const curPalettePOIIndexArr = [...groupLayers, ...subLayers]
        .filter(
            ({ renderType, visualFilter }) =>
                layerTypeWithPalette.has(renderType) && visualFilter?.palette,
        )
        .map(({ visualFilter }) => visualFilter?.palette?.paletteName.split('-')[1])
        .sort();
    //PaletteName structure ex 'POI-4-TA1' 'POI-3-TA1' extracting poi position
    //PaletteName Position from 1 to 8

    const poiIndexByCounts = curPalettePOIIndexArr.reduce(
        (acc, curVal) => {
            const intPos = parseInt(curVal);
            return {
                ...acc,
                [intPos]: acc[intPos] + 1,
            };
        },
        {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
            7: 0,
            8: 0,
        },
    );

    const countArr = Object.values(poiIndexByCounts);
    const minCount = Math.min(...(countArr as number[]));

    const [minPos] = Object.entries(poiIndexByCounts).filter(([, count]) => count === minCount)[0];

    return parseInt(minPos) - 1;
};

export const formatFullAddress = (
    place: Pick<Place, 'type' | 'address'>,
    missingDefault: string = '',
): string => {
    if (!place.address) {
        return missingDefault;
    }

    const { address, city, state_code, formatted_address, short_formatted_address } = place.address;

    if (address && city && state_code) {
        return `${address}, ${city}, ${state_code}`;
    } else if (formatted_address) {
        return formatted_address;
    } else if (short_formatted_address) {
        return short_formatted_address;
    } else {
        return missingDefault;
    }
};

export const capitalizeFirstLetter = (text: string): string => {
    return text.charAt(0).toUpperCase() + text.slice(1);
};

export const getTypeTitleText = (titleType: AreaType) => {
    if (titleType === 'cbsa' || titleType === 'dma') return titleType.toUpperCase();

    return capitalizeFirstLetter(titleType);
};

export const getShortAddressFormat = (place: Pick<Place, 'type' | 'address'>) => {
    return place.type === 'chain'
        ? `${getTypeTitleText((place as Chain).area.type)}${
              (place as Chain).area.code === 'US'
                  ? ''
                  : `, ${capitalizeFirstLetter((place as Chain).area.code)}`
          } - ${capitalizeFirstLetter((place as Chain).area.name)}`
        : formatFullAddress(place);
};

type isAddressPOIProp = {
    provider_data?: Place['provider_data'];
};

export const isAddressPOI = (poiInfo: isAddressPOIProp) =>
    poiInfo?.provider_data?.entity_type === 'address';

export const getCalloutName = (info: Place) => {
    if (info.category_info?.group === 'Shopping Centers') {
        return {
            name: 'Tenants Callout Box',
        };
    }
    if (info.category_info?.group === 'Shopping Centers') {
        return {
            name: 'Tenant Callout Box',
        };
    }
    if (info.is_custom_poi) {
        return {
            name: 'Callout Box',
        };
    }
    if (info.type === 'complex' || info.type === 'venue') {
        return {
            name: 'Tenant Callout Box',
        };
    }

    return {
        name: 'Callout Box',
    };
};

interface ThisGeolocation {
    lat: number;
    lng: number;
}

export const createPointBuffer = (
    location: mapboxgl.LngLat | ThisGeolocation,
    bufferSize: number = 250,
): GeoJSON => {
    const point = turf.point([location.lng, location.lat]);
    return turf.buffer(point, bufferSize ?? 250, { units: 'feet' }).geometry;
};

type AdjustmentFieldsForEntityProps = {
    name: string;
    location?: mapboxgl.LngLat | ThisGeolocation;
    bufferSize?: number;
    type?: PlaceType;
};

export const adjustmentFieldsForEntity = ({
    location,
    name,
    bufferSize,
    type,
}: AdjustmentFieldsForEntityProps): {
    name: string;
    type: PlaceType;
    geojson?: GeoJSON;
} => {
    let bufferedPolygon: GeoJSON | undefined = undefined;
    if (location) {
        bufferedPolygon = createPointBuffer(location, bufferSize);
    }

    return {
        name: `${name}${bufferSize ? ` (Nearby Activity ${bufferSize} ft)` : ''}`,
        type: type ?? 'complex',
        ...(bufferedPolygon ? { geojson: bufferedPolygon } : {}),
    };
};

export type BinItem = {
    id?: string;
    color: string;
    value: string;
    ranges?: [number, number];
    visible?: boolean;
    categoricalLookup?: string[] | undefined;
};

export const convertColorPaletteToLegendItems = (
    palette: PaletteOption,
    itemsValue: number[],
): BinItem[] => {
    return palette.paletteColors.map((color, index) => {
        const id = `${palette.paletteName}-${index}`;
        return {
            id: id,
            value: itemsValue[index].toString(),
            color: rgbArrayToHex(color),
            visible: true,
        };
    });
};

export type GetTTASuffixProps = {
    layerType: TaTypes;
    value: string;
};

export const getTTASuffix = ({ layerType, value }: GetTTASuffixProps) => {
    switch (layerType) {
        case 'distance_in_miles':
            return `${value} mi`;
        case 'choropleth':
        case 'tta': {
            return `${value}%`;
        }
        case 'dot_density': {
            return `${value} visits`;
        }
        case 'vtv': {
            return value;
        }
        case 'thematic': {
            return value;
        }
        default:
            return `${value} min`;
    }
};

export const gradientColorConvert = (colors: RGBColor[]) => {
    const colorStrings = [...colors].reverse().map((rgb) => `rgb(${rgb.join(', ')})`);
    return `linear-gradient(180deg, ${colorStrings.join(', ')})`;
};

// true = disable add layer button
// false = enable add layer button
export const shouldDisableAddLayer = (selectedPOI?: Place) => {
    // disable if no POI
    if (!selectedPOI) return true;

    // if custom POI, enable add layer. exception - don't allow mapbox provided custom pois
    if (selectedPOI.is_custom_poi && selectedPOI.provider_data?.provider !== 'mapbox') return false;

    // if region POI, enable add layer. exception - don't allow mapbox regions
    if (
        selectedPOI.category_info?.group === 'Region' &&
        selectedPOI.provider_data?.provider !== 'mapbox'
    )
        return false;

    // if address exists, enable add layer
    if (selectedPOI.address?.address) return false;

    // disable add layer by default
    return true;
};

export const rgbToHex = (rgb: RGBColor) => {
    const hex = rgb.map((color) => {
        const currentColor = color.toString(16);
        return currentColor.length === 1 ? '0' + currentColor : currentColor;
    });
    return '#' + hex[0] + hex[1] + hex[2];
};

type SelectedList = Array<{
    item: CascadingItem;
    selectedLeavesCount: number;
}>;

/**
 * generates a flat list of the selected items
 * (without the selected categories or similar parents)
 */
export function getSelectedLeaves(items: CascadingItem[]): LeafMap {
    const leaves = hierarchicalUtils.getAllLeaves(items);
    leaves.forEach((item, key) => {
        if (item.selected === SELECT_STATES.unselected) {
            leaves.delete(key);
        }
    });
    return leaves;
}

type InternalNode = Omit<CascadingItem, 'children'> & {
    children: Required<CascadingItem>['children'];
};

export function isInternalNode(item: CascadingItem): item is InternalNode {
    return Array.isArray(item.children) && item.children.length > 0;
}

/**
 * builds a list of selected items by groups from top to bottom -
 * if a category is selected entirely then it will show as one chip.
 * if not then its children will get a chip each.
 */
export function getGroupedSelectedItems(
    items: CascadingItem[],
    selectedList: SelectedList = [],
): SelectedList {
    items.forEach((item) => {
        if (item.selected === SELECT_STATES.selected) {
            // item is considered selected entirely so let's count all of its children and stop the recursion
            let selectedLeavesCount = 0;
            if (isInternalNode(item)) {
                selectedLeavesCount = getSelectedLeaves(item.children).size;
            }
            selectedList.push({
                item,
                selectedLeavesCount,
            });
        } else if (item.selected === SELECT_STATES.indeterminate) {
            getGroupedSelectedItems(item.children ?? [], selectedList);
        }
    });
    return selectedList;
}

export class PlacePOI {
    // TODO change all the any type to the current type
    static getGroupCategory = (poi: Place) => {
        return poi.category_info.group ?? (poi as unknown as Place).category;
    };

    static getPrimaryCategory = (poi: Place) => {
        return poi.category_info?.primary ?? (poi as unknown as Place).category;
    };

    static getSubCategory = (poi: Place) => {
        return poi.category_info.sub_category ?? (poi as unknown as Place)?.sub_category;
    };
}

type GetCategoryCascadingIntemsProps = {
    categoriesData: ConfigurationCategoriesData[];
    initialSubCategoriesLookup: Record<string | number, any>;
    checkLevel?: boolean;
};

const modifyData = ({
    categoriesData,
    initialSubCategoriesLookup,
    checkLevel,
    level,
}: GetCategoryCascadingIntemsProps & { level: number }): CascadingItem[] => {
    return categoriesData.map(({ children, id, label }) => {
        const subItems =
            children &&
            modifyData({
                categoriesData: children,
                initialSubCategoriesLookup,
                checkLevel,
                level: level + 1,
            });

        const anySubItemSelected = subItems?.some((item: CascadingItem) => item.isSelected);

        const allSubItemsSelected =
            subItems &&
            subItems.length > 0 &&
            subItems.every((item: CascadingItem) => item.isSelected);

        const isInitialSubCategorySelected =
            (checkLevel ? level === 2 : true) && label in initialSubCategoriesLookup;

        const item: CascadingItem = {
            value: id,
            label,
            isSelected: isInitialSubCategorySelected || anySubItemSelected,
            selected: getCascadingSelectState(
                allSubItemsSelected,
                isInitialSubCategorySelected,
                anySubItemSelected,
            ),
            children: subItems,
            childrenExpanded: false,
        };

        return item;
    });
};

const getCascadingSelectState = (
    allSubItemsSelected: boolean | undefined,
    isInitialSubCategorySelected: boolean,
    anySubItemSelected: boolean | undefined,
) => {
    if (isInitialSubCategorySelected || allSubItemsSelected) {
        return SELECT_STATES.selected;
    }
    if (anySubItemSelected) {
        return SELECT_STATES.indeterminate;
    }
    return SELECT_STATES.unselected;
};

export const getCategoryCascadingIntems = ({
    categoriesData,
    initialSubCategoriesLookup,
    checkLevel = false,
}: GetCategoryCascadingIntemsProps) => {
    return modifyData({
        categoriesData,
        initialSubCategoriesLookup,
        checkLevel,
        level: 0,
    });
};

export const arrToLookup = (
    array: (string | number)[],
    defaultValue?: string,
): Record<string | number, string | number> => {
    const initialValue = {};
    return array.reduce((obj, item) => {
        return {
            ...obj,
            [item]: defaultValue ? defaultValue : item,
        };
    }, initialValue);
};

export const pluralizeEntityTypes: Record<string, string> = {
    complex: 'complexes',
    venue: 'venues',
    billboard: 'billboards',
    complexes: 'complexes',
    venues: 'venues',
    billboards: 'billboards',
    empty_space: 'empty_space',
    chain: 'chains',
};

export const defaultPOICalloutBoxConfig: CalloutBoxConfig = {
    showHeader: true,

    showLogo: true,
    showPropertyName: true,
    showPropertyAddress: true,
    showMetrics: true,

    headerContainerColor: '#6FA2CE',
    headerContainerOpacity: 100,

    propertyNameFontFamily: 'Open Sans',
    propertyNameFontWeight: 700,
    propertyNameFontSize: 13,
    propertyNameFontColor: '#FFFFFF',
    propertyNameFontOpacity: 100,

    propertyAddressFontFamily: 'Open Sans',
    propertyAddressFontWeight: 400,
    propertyAddressFontSize: 11,
    propertyAddressFontColor: '#FFFFFF',
    propertyAddressFontOpacity: 100,

    showMetricsVisits: true,
    showMetricsVisitsRanking: false,
    metricsRankShowAs: 'rank',
    metricsRankType: 'primary.country',
    metricsDisplayAsNA: true,
};

export const POICalloutDefaultFetchFilter: POICalloutFetchFilter = {
    date_preset: 'last-12-months',
    sort_by: 'foot_traffic',
    includeTenants: true,
};

export const POICalloutDefaultVisualFilter: POICalloutVisualFilter = {
    isGrouped: true,
    metricsInitiallyLoading: true,
    poiCalloutBoxConfig: defaultPOICalloutBoxConfig,
    offsetMap: {},
};

export const walkTimeDefaultFetchFilter: WalkTimeFetchFilter = {
    timeSelected: [5],
};
export const walkTimeDefaultVisualFilter: WalkTimeVisualFilter = {
    palette: DEFAULT_COLOR_SEQUENTIAL_PALETTE_COLORS[2],
    opacity: 30,
    stroke: 3,
    inEditMode: true,
    includeLayer: true,
    legendTitle: 'Walk Time',
    items: [
        {
            color: rgbArrayToHex(DEFAULT_COLOR_SEQUENTIAL_PALETTE_COLORS[2].paletteColors[0]),
            value: '5',
            visible: true,
        },
    ],
};

export const distanceInMilesDefaultFetchFilter: DistanceInMilesFetchFilter = {
    ringRadius: [3],
};
export const distanceInMilesDefaultVisualFilter: DistanceInMilesVisualFilter = {
    inEditMode: true,
    showAs: 'solid',
    includeLabel: true,
    opacity: 20,
    palette: DEFAULT_COLOR_TRADE_AREA_PALETTE_COLORS[0],
    stroke: 3,
    includeLayer: true,
    legendTitle: 'Distance in Miles',
    items: [
        {
            color: rgbArrayToHex(DEFAULT_COLOR_TRADE_AREA_PALETTE_COLORS[0].paletteColors[0]),
            value: '3',
            visible: true,
        },
    ],
};

export const ttaHeatMapDefaultFetchFilter: TTAHeatMapFetchFilter = {
    visitorsOrigin: 'home',
    metric: 'visits',
    minVisits: 1,
};
export const ttaHeatMapDefaultVisualFilter: TTAHeatMapVisualFilter = {
    showAs: 'gradient',
    opacity: 20,
    palette: DEFAULT_HEATMAP_COLOR_TRADE_AREA_PALETTE_COLORS[6],
    inEditMode: true,
    includeLayer: true,
    legendTitle: 'True Trade Area Heat Map',
};

export const trueTradeAreaDefaultFetchFilter: TrueTradeAreaFetchFilter = {
    visualizationType: 'choropleth',
    boundaryType: 'polygon',
    numberOfVisitsSelected: [30],
    polygonMiRadius: 50,

    // DotDensityFilters
    minVisits: 1,
    visitorsOrigin: 'home',
    metric: 'visits',
};

export const trueTradeAreaDefaultVisualFilter: TrueTradeAreaVisualFilter = {
    opacity: 30,
    palette: DEFAULT_COLOR_TRADE_AREA_PALETTE_COLORS[0],
    stroke: 3,
    inEditMode: true,
    includeLayer: true,
    legendTitle: 'True Trade Area',
    dotRadius: 2,
    dotOpacity: 100,
    dotDensityLegendTitle: 'Visits',
    items: [
        {
            color: rgbArrayToHex(DEFAULT_COLOR_TRADE_AREA_PALETTE_COLORS[0].paletteColors[0]),
            value: '30',
            visible: true,
        },
    ],
};

export const driveTimeDefaultFetchFilter: DriveTimeFetchFilter = {
    timeSelected: [5],
};
export const driveTimeDefaultVisualFilter: DriveTimeVisualFilter = {
    inEditMode: true,
    opacity: 30,
    palette: DEFAULT_COLOR_SEQUENTIAL_PALETTE_COLORS[1],
    stroke: 3,
    includeLayer: true,
    legendTitle: 'Drive Time',
    items: [
        {
            color: rgbArrayToHex(DEFAULT_COLOR_SEQUENTIAL_PALETTE_COLORS[1].paletteColors[0]),
            value: '5',
            visible: true,
        },
    ],
};

export const fetchFilterDictionary: Record<
    TradeLayerType | VehicleTrafficVolumesType,
    TradeLayersFetchFilters
> = {
    distance_in_miles: distanceInMilesDefaultFetchFilter,
    drive_time: driveTimeDefaultFetchFilter,
    tta: trueTradeAreaDefaultFetchFilter,
    tta_heat_map: ttaHeatMapDefaultFetchFilter,
    walk_time: walkTimeDefaultFetchFilter,
    vtv: vehicleTrafficVolumeDefaultFetchFilter,
};

export const visualFilterDictionary: Record<
    TradeLayerType | VehicleTrafficVolumesType,
    TradeLayersVisualFilters
> = {
    distance_in_miles: distanceInMilesDefaultVisualFilter,
    drive_time: driveTimeDefaultVisualFilter,
    tta: trueTradeAreaDefaultVisualFilter,
    tta_heat_map: ttaHeatMapDefaultVisualFilter,
    walk_time: walkTimeDefaultVisualFilter,
    vtv: vehicleTrafficVolumeDefaultVisualFilter as any,
};
