import React from 'react';
import { SgNonProfitIcon } from 'features/onboarding/solutions-guide/icons/sg-non-profit-icon';

export const NON_PROFIT_SOLUTIONS_GUIDE = {
    icon: <SgNonProfitIcon />,
    solutions: [
        {
            title: 'Targeted Outreach',
            description:
                'Explore regional audience demographics to ' +
                'identify areas with the greatest need for your services.',
            url: '/insights/complexes/2ef7e8783eb3f0ed9a5f4105/audience',
        },
        {
            title: 'Donor Insights',
            description:
                'Uncover comprehensive insights about your donors, based on their online and offline behavior.',
            url: '/advanced-reports/freemium/persona-live',
        },
        {
            title: 'Impact Assessment',
            description:
                'Measure the effectiveness of programs by ' +
                'comparing pre- and post-implementation data for specific locations.',
            url: '/insights/complexes/2ef7e8783eb3f0ed9a5f4105/overview',
        },
        {
            title: 'Local Partnerships',
            description:
                'Identify and collaborate with favorite local organizations, businesses, and community leaders.',
            url: '/insights/complexes/2ef7e8783eb3f0ed9a5f4105/overview',
        },
        {
            title: 'Marketing & Advertising',
            description:
                'Run targeted advocacy and awareness campaigns ' +
                'in areas with demographics similar to ideal supporters.',
            url: '/insights/complexes/2ef7e8783eb3f0ed9a5f4105/audience',
        },
    ],
};
