import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';

import {
  selectChainStarredReports,
  selectFilterFromOptions,
  selectPropertyStarredReports,
  selectStarredReportsByType,
  selectStarredReportsByTypeAndTerm,
  selectTermFromOptions,
  selectIndustryStarredReports,
} from 'store/star-report/selectors/select-starred-reports-by-type-and-term/select-starred-reports-by-type-and-term';
import { dateFormats } from 'utils/date/date';
import { selectStarReportReports } from 'store/star-report/selectors/star-report-selectors-factory';

export const selectHeaderStarredReports = createSelector(
    selectStarReportReports,
    (reports) => {
        const headerStarredReports = [...reports]
            .sort((report1, report2) => {
                return moment(report2.last_viewed_date).diff(
                    moment(report1.last_viewed_date),
                );
            })
            .map((report) => ({
                ...report,
                last_viewed_date: moment(report.last_viewed_date).format(
                    dateFormats.monthHour,
                ),
            }));

        return headerStarredReports;
    },
);

const selectHeaderStarredReportsPropertyReports = createSelector(
    selectHeaderStarredReports,
    selectPropertyStarredReports,
);

const selectHeaderStarredReportsChainReports = createSelector(
    selectHeaderStarredReports,
    selectChainStarredReports,
);

const selectHeaderStarredReportsIndustryReports = createSelector(
    selectHeaderStarredReports,
    selectIndustryStarredReports,
);

export const selectHeaderStarredReportsByType = createSelector(
    selectHeaderStarredReports,
    selectHeaderStarredReportsPropertyReports,
    selectHeaderStarredReportsChainReports,
    selectHeaderStarredReportsIndustryReports,
    selectFilterFromOptions,
    selectStarredReportsByType,
);

export const selectHeaderStarredReportsByTypeAndTerm = createSelector(
    selectHeaderStarredReportsByType,
    selectTermFromOptions,
    selectStarredReportsByTypeAndTerm,
);
