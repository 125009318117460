/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

type FestivalIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type FestivalIconProps = SVGProps<SVGSVGElement> & FestivalIconUniqueProps;

export const FestivalIcon = function FestivalIcon({
    width = 24,
    height = 24,
    ...props
}: FestivalIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_1323_40730)">
                <path
                    d="M23.2802 10.1784L21.6008 12.6975L22.0168 12.9749L21.6008 12.6975C21.5351 12.7961 21.5 12.9119 21.5 13.0303V23.4973C18.2424 23.4609 16.1751 23.0574 14.8371 22.1978C13.5384 21.3633 12.8456 20.0422 12.4933 17.9182C12.4532 17.677 12.2446 17.5001 12 17.5001C11.7554 17.5001 11.5468 17.677 11.5067 17.9182C11.1544 20.0422 10.4616 21.3633 9.16287 22.1978C7.82495 23.0574 5.75765 23.4609 2.5 23.4973V13.0303C2.5 12.9119 2.46494 12.7961 2.39923 12.6975L1.98321 12.9749L2.39923 12.6975L0.730202 10.194L12.118 4.50005H12.8115L23.2802 10.1784Z"
                    fill="#E1E2FF"
                    stroke="#5E63E5"
                    strokeLinejoin="round"
                />
                <path
                    d="M12.5 0.5H16.5V2C16.5 2.27614 16.2761 2.5 16 2.5H12.5V0.5Z"
                    stroke="#5E63E5"
                />
                <path d="M1.5 11L8 12L16 12L22.5 11" stroke="#5E63E5" />
                <path d="M12.5 0.999998L12.5 5" stroke="#5E63E5" />
                <path d="M12.4998 4.99996L6.49977 10.5001" stroke="#5E63E5" />
                <path d="M12.4998 5.00005L18.4998 10.5" stroke="#5E63E5" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 23.5C0 23.2239 0.223858 23 0.5 23H23.5C23.7761 23 24 23.2239 24 23.5C24 23.7761 23.7761 24 23.5 24H0.5C0.223858 24 0 23.7761 0 23.5Z"
                    fill="#5E63E5"
                />
                <path
                    d="M1 10.5H0.5V11V12.5C0.5 14.1569 1.84315 15.5 3.5 15.5C5.15685 15.5 6.5 14.1569 6.5 12.5V11V10.5H6H1Z"
                    fill="white"
                    stroke="#5E63E5"
                />
                <path
                    d="M7 10.5H6.5V11V12.5C6.5 14.1569 7.84315 15.5 9.5 15.5C11.1569 15.5 12.5 14.1569 12.5 12.5V11V10.5H12H7Z"
                    fill="white"
                    stroke="#5E63E5"
                />
                <path
                    d="M13 10.5H12.5V11V12.5C12.5 14.1569 13.8431 15.5 15.5 15.5C17.1569 15.5 18.5 14.1569 18.5 12.5V11V10.5H18H13Z"
                    fill="white"
                    stroke="#5E63E5"
                />
                <path
                    d="M19 10.5H18.5V11V13C18.5 14.3807 19.6193 15.5 21 15.5C22.3807 15.5 23.5 14.3807 23.5 13V11V10.5H23H19Z"
                    fill="white"
                    stroke="#5E63E5"
                />
            </g>
            <defs>
                <clipPath id="clip0_1323_40730">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
FestivalIcon.__tags = ['festival', 'circus'] as string[];
