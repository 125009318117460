import React, { useEffect } from 'react';

import { Button } from 'antd';
import { NotificationIcon } from 'components/assets/Icons/Icons';
import { userSelector } from 'store/selectors/app-selectors';

import styles from './notifications-button.module.scss';
import { useSelector } from 'react-redux';
import { useFeatureFlags } from 'hooks/feature-flags/use-feature-flags';
import { isMobileApp } from 'utils/detect-environment';

const BEAMER_SCRIPT_ID = 'beamer-script';
/**
 * the interaction with this button is controlled via Google Tag manager
 */
export const NotificationsButton = () => {
    const user = useSelector(userSelector);
    const { show_notification_quick_access: showNotificationsButton } = useFeatureFlags();

    useEffect(() => {
        if (isMobileApp()) {
            return;
        }

        const isScriptLoaded = document.getElementById(BEAMER_SCRIPT_ID);
        if (showNotificationsButton && user && !isScriptLoaded) {
            // @ts-ignore
            window.beamer_config = {
                product_id: process.env.REACT_APP_BEAMER_PRODUCT_ID,
                selector: 'beamerButton',
                lazy: false,
                button: false,
                user_firstname: user.fullName,
                user_lastname: '',
                user_email: user.email,
                domain: user.email.split('@').pop(),
                user_id: user.email,
                filter_by_url: true,
            };

            const script = document.createElement('script');

            script.src = 'https://app.getbeamer.com/js/beamer-embed.js';
            script.async = true;
            script.onload = () => {
                // @ts-ignore
                window?.Beamer.init();
            };
            script.id = BEAMER_SCRIPT_ID;

            document.head.appendChild(script);
        }
    }, [showNotificationsButton, user]);

    return showNotificationsButton ? (
        <div className={styles.notificationsButton}>
            <Button icon={<NotificationIcon />} id="beamerButton" />
        </div>
    ) : null;
};
