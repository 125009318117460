import { fetchWrapper } from 'API/authentification/fetch-wrapper';
import { fetchPathUrls, getAllCategoriesPaths } from 'core/entities/category/utils';
import type {
    ChainsByCategoriesType,
    ConfigurationCategoriesDTO,
    ConfigurationCategoriesData,
    PlacerArrayResponse,
    CategoriesIconsDictionary,
    Dictionary,
} from '@placer-ui/types';
import { ConfigurationAttributesDTO } from 'store/configuration/attributes/models';
import { STORAGE_CATEGORY_ICONS } from 'utils/get-fetched-categories-icons/get-fetched-categories-icons';

export const configurationAPI = {
    getAttributes(): PlacerArrayResponse<ConfigurationAttributesDTO> {
        return fetchWrapper({ targetUrl: '/search/attributes' });
    },
    getCategories(
        hasSubcategoryDropdownPermission?: boolean,
        hasRemoveEmptyCategoriesFF?: boolean,
    ): PlacerArrayResponse<ConfigurationCategoriesDTO> {
        const urlPath = window.location.pathname + window.location.search;
        const onlyChains =
            hasRemoveEmptyCategoriesFF &&
            (urlPath.includes('/chains') ||
                urlPath.includes('/advanced-reports/site-selection'))
                ? '&type=chains'
                : '';
        const queryParams = hasSubcategoryDropdownPermission
            ? `?only_in_use=true${onlyChains}&only_available_categories=true&version=v2`
            : '?only_available_categories=true&version=v2';

        return fetchWrapper({
            targetUrl: `/categories${queryParams}`,
        });
    },
    getChainsByCategoriesApi(): PlacerArrayResponse<ChainsByCategoriesType> {
        return fetchWrapper({
            method: 'GET',
            targetUrl: '/entities/chains_by_categories',
        });
    },
    async getCategoriesPathUrl(categoriesData: ConfigurationCategoriesData[]) {
        const categoriesIconsPaths = getAllCategoriesPaths(categoriesData);

        const categoryIconsLocalStorage = window.localStorage.getItem(STORAGE_CATEGORY_ICONS);

        const parsedCategoryIconsLocalStorage: {
            categoryPathMapping: Dictionary<CategoriesIconsDictionary>;
            expire: number;
        } = categoryIconsLocalStorage && JSON.parse(categoryIconsLocalStorage);

        if (
            parsedCategoryIconsLocalStorage &&
            new Date().getTime() > parsedCategoryIconsLocalStorage.expire
        ) {
            // If the item is expired, delete the item from storage
            // and return null
            localStorage.removeItem(STORAGE_CATEGORY_ICONS);
        }

        return await fetchPathUrls(categoriesIconsPaths);
    },
    getChainsBySubCategoriesApi(): PlacerArrayResponse<ChainsByCategoriesType> {
        return fetchWrapper({
            method: 'GET',
            targetUrl: '/entities/chains_by_sub_categories',
        });
    },
};
