import React from 'react';
import { Tabs } from 'antd';

import { CHAIN, PROPERTY, INDUSTRY } from 'core/constants/report-type';
import { useSelector } from 'react-redux';
import { userPermissionsSelector } from 'store/selectors/app-selectors';

export const STARRED_REPORT_TABS = {
    ALL: 'All',
    PROPERTY: 'Property',
    CHAIN: 'Chains',
    INDUSTRY: 'Industry',
} as const;

type StarredReportTabsType = typeof STARRED_REPORT_TABS;
export type StarredReportTabsTypes = StarredReportTabsType[keyof StarredReportTabsType];

export const StarredReportsTabToReportTypeMap = {
    [STARRED_REPORT_TABS.ALL]: undefined,
    [STARRED_REPORT_TABS.PROPERTY]: PROPERTY,
    [STARRED_REPORT_TABS.CHAIN]: CHAIN,
    [STARRED_REPORT_TABS.INDUSTRY]: INDUSTRY,
};

type StarredReportsTabsProps = {
    selectedTab: string;
    onSelectTab: (selectedTab: StarredReportTabsTypes) => void;
    className?: string;
};

export const StarredReportsTabs = ({
    className,
    selectedTab,
    onSelectTab,
}: StarredReportsTabsProps) => {
    const userPermissions = useSelector(userPermissionsSelector);

    return (
        <div className={className}>
            <Tabs
                activeKey={selectedTab}
                defaultActiveKey={STARRED_REPORT_TABS.ALL}
                onChange={(value) =>
                    onSelectTab(value as StarredReportTabsTypes)
                }
            >
                <Tabs.TabPane
                    tab={STARRED_REPORT_TABS.ALL}
                    key={STARRED_REPORT_TABS.ALL}
                />
                <Tabs.TabPane
                    tab={STARRED_REPORT_TABS.PROPERTY}
                    key={STARRED_REPORT_TABS.PROPERTY}
                />
                <Tabs.TabPane
                    tab={STARRED_REPORT_TABS.CHAIN}
                    key={STARRED_REPORT_TABS.CHAIN}
                />
                {userPermissions?.show_industry_section && 
                    <Tabs.TabPane
                        tab={STARRED_REPORT_TABS.INDUSTRY}
                        key={STARRED_REPORT_TABS.INDUSTRY}
                    />
                }
              
            </Tabs>
        </div>
    );
};
