import { UserCustomSettings } from 'core/entities/user/user-custom-settings';
import { UserCustomSettingsExplore } from 'core/entities/user/user-custom-settings-explore';
import {
    userCustomSettingsSelector,
    userCustomSettingsExploreSelector,
} from 'store/selectors/app-selectors';
import { setUserCustomSettings } from 'store/auth/actions/set-user-custom-settings/set-user-custom-settings';
import { AppDispatch, AppState } from 'store/store';

export const setCustomUserSettingsExplore =
    (settings: Partial<UserCustomSettingsExplore>) =>
    (dispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();
        const customSettings = userCustomSettingsSelector(state);

        if (!customSettings) {
            return;
        }

        const exploreSettings = userCustomSettingsExploreSelector(state);

        const newCustomSettings = {
            explore: {
                ...exploreSettings,
                ...settings,
            },
        } as UserCustomSettings;

        const setCustomSettings = setUserCustomSettings(newCustomSettings);
        dispatch(setCustomSettings);
    };
