import pluralize from 'pluralize';
import { goToChainsPage, goToInsightsPage } from 'router/go-to-routes';

import type { Place } from '@placer-ui/types';
import { OVERVIEW_REPORT_NAME } from './navigation-consts';
import { FilterInsights } from 'core/entities/filter';
import { Filter } from '@placer-ui/types';

export const openPropertyReport = (
    property: Place,
    newTab = true,
    section = OVERVIEW_REPORT_NAME,
    filters?: Filter[] | FilterInsights[],
) => {
    const { id, type } = property;
    goToInsightsPage({
        id,
        collection: pluralize(type),
        newTab,
        useWindow: true,
        section,
        filters,
    });
};

export const openChainsReport = (chainId: string | null | undefined, newTab = true) => {
    if (chainId) {
        goToChainsPage({
            entities: [{ id: chainId }],
            newTab,
        });
    }
};
