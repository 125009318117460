import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

import { IconProps } from 'components/assets/Icons/Icons';

/* eslint-disable max-len */
export const MapViewSvg = (props: CustomIconComponentProps | SVGProps<SVGSVGElement>) => (
    <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="19"
        viewBox="0 0 18 19"
        fill="none"
    >
        <g clipPath="url(#clip0_4856_266431)">
            <path
                d="M17.7962 2.15279C17.7327 2.09906 17.6588 2.06173 17.5801 2.04364C17.5013 2.02555 17.4198 2.02719 17.3418 2.04842L11.8853 3.52878L6.41471 0.560405C6.30159 0.499175 6.17201 0.483891 6.04941 0.517316L0.402353 2.04938C0.287721 2.08013 0.185855 2.1516 0.11295 2.2524C0.0400454 2.3532 0.000288157 2.47757 8.68517e-10 2.60571V16.3943C-4.60942e-06 16.4816 0.0183452 16.5678 0.0536541 16.6463C0.088963 16.7248 0.140301 16.7936 0.203765 16.8473C0.267229 16.901 0.341147 16.9384 0.419899 16.9564C0.49865 16.9745 0.580162 16.9728 0.658235 16.9516L6.11471 15.4712L11.5853 18.4396C11.6984 18.5008 11.828 18.5161 11.9506 18.4827L17.5976 16.9506C17.7123 16.9199 17.8141 16.8484 17.8871 16.7476C17.96 16.6468 17.9997 16.5224 18 16.3943V2.60571C18 2.51837 17.9816 2.43218 17.9463 2.3537C17.911 2.27522 17.8596 2.20651 17.7962 2.15279ZM6.70588 2.00342L11.2941 4.49302V16.9966L6.70588 14.507V2.00342ZM1.05882 3.05671L5.64706 1.81191V14.4141L1.05882 15.6589V3.05671ZM16.9412 15.9462L12.3529 17.191V4.5859L16.9412 3.3411V15.9462Z"
                fill="currentColor"
            />
            <path d="M16.9412 15.9462L12.3529 17.191V4.5859L16.9412 3.3411V15.9462Z" fill="white" />
            <path
                d="M1.05882 3.05671L5.64706 1.81191V14.4141L1.05882 15.6589V3.05671Z"
                fill="white"
            />
            <path
                d="M6.70588 2.00342L11.2941 4.49302V16.9966L6.70588 14.507V2.00342Z"
                fill={props.fill}
            />
        </g>
        <defs>
            <clipPath id="clip0_4856_266431">
                <rect width="18" height="18" fill="white" transform="translate(0 0.5)" />
            </clipPath>
        </defs>
    </svg>
);

export const MapViewMobileIcon = (props: IconProps) => (
    <Icon component={() => <MapViewSvg fill={props.fill} />} {...props} />
);
