import { FilterId } from 'features/site-selection/common/types/filters/general';

export const SIS_STATIC_FILTERS: Partial<Record<FilterId, boolean>> = {
    bbox: true,
    benchmark_type: true,
    category_type: true,
    minimal_match_score: true,
    categories: true,
    include_tenants: true,
    exclude_tenants: true,
    mosaic: true,
};

export const SIS_CORE_FILTERS = ['benchmark_type', 'category_type'];

export const SIS_PERMANENT_FILTERS = ['bbox', 'benchmark_type', 'category_type'];
