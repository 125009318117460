export const PRIMARY_COLOR = '#5e63e5';
export const CUSTOM_COLOR = '#9b98aa';
export const FLAGGED_COLOR = '#fda467';
export const GREEN_ENTITY_COLOR = '#55b1a7';
export const PRIVACY_COLOR = '#ffffff';

export const PLACE_COLORS = {
    main: [
        PRIMARY_COLOR,
        '#ef5771',
        GREEN_ENTITY_COLOR,
        '#ffbe5e',
        '#4e5136',
        '#b05420',
        '#6fa2ce',
        '#d6ac84',
        FLAGGED_COLOR,
        '#f567f5', //purple
    ],
    darker: [
        '#393eb7',
        '#c8374f',
        '#388b82',
        '#e6a039',
        '#333620',
        '#8e3f12',
        '#40729d',
        '#ae7e50',
        '#de7730',
        '#d043d0',
    ],
    lighter: [
        '#b6b9f3',
        '#f7abb8',
        '#a2d4cf',
        '#ffd89e',
        '#acada0',
        '#d7a98f',
        '#b0cce4',
        '#e6ccb3',
        '#f7bd96',
        '#f7adf7',
    ],
};

export type Shades = Exclude<keyof typeof PLACE_COLORS, 'main'>;

export const EXTRA_COLORS = {
    blue: PRIMARY_COLOR,
    orange: FLAGGED_COLOR,
    purple: '#f567f5',
    green: GREEN_ENTITY_COLOR,
};

export const POI_COLORS = {
    public: '#b6b9f3',
    custom: CUSTOM_COLOR,
    custom_darker: '#827F94',
    closed: '#ef5771',
    flagged: FLAGGED_COLOR,
    privacy_concern: PRIVACY_COLOR,
    restricted: PRIVACY_COLOR,
} as const;

export const getPlaceColorShade = (
    color: string,
    shade: Shades = 'lighter',
    defaultShade?: string,
) => {
    const index = PLACE_COLORS.main.indexOf(color);
    return index === -1 ? defaultShade || color : PLACE_COLORS[shade][index];
};
