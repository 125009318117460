import { useRetailSalesPermissions } from 'hooks/use-retail-sales-permissions/use-retail-sales-permissions';
import { usePermission } from 'hooks/use-permission/use-permission';
import { useFeatureFlags } from 'hooks/feature-flags/use-feature-flags';
import { getFeatureFlags } from 'core/flow-control';

export const useExplorePermissions = () => {
    const { hideRetailSales } = useRetailSalesPermissions();

    const { send_clicked_search_results_sdr_ff } = getFeatureFlags();

    const {
        tablet_explore_open_reports_in_same_tab_ff: hasOpenExploreReportSamePageInTablet,
        enable_mapbox_googlemaps_cta_explore,
    } = useFeatureFlags();

    const hasTrafficPinPermission = usePermission('show_billboards');
    const hasRevampCategory = usePermission('enable_revamp_category_front_end');
    const hasPurchasedVenuesPermission = usePermission('show_only_purchased_venues');
    const hasBadgesPermission = usePermission('show_poi_verified_badge_explore');
    const hasUnlockNearbyActivity = usePermission('unlock_nearby_activity');
    const hasEnableRetailSales = usePermission('enable_retail_sales_in_explore_ff');
    const hasNearbyPropertyVenueIndicatorFF = usePermission(
        'enable_nearby_activity_property_warning_ff',
    );
    const hasFreemiumExpFF = usePermission('enable_explore2_dropdown_free_user_ff');
    const hasQAFeaturesPermission = usePermission('show_qa_features');
    const hasRegionCategoryFilteringPermission = usePermission('enable_explore2_region_filter_ff');
    const hasStoreIdBadgeAutocompleteSearchPermission = usePermission(
        'enable_store_id_badge_autocomplete_search_ff',
    );
    const hasStoreIdBadgeSidebarPermission = usePermission(
        'enable_store_id_badge_explore_side_bar_ff',
    );
    const hasStoreIdSelectedPropertiesPermission = usePermission(
        'enable_store_id_badge_explore_properties_drawer_ff',
    );
    const hasStoreIdBadgeMoreOptionsPermission = usePermission(
        'enable_store_id_badge_explore_more_options_ff',
    );
    const hasStoreIdBadgePropertyCardPermission = usePermission(
        'enable_store_id_badge_explore_property_card_ff',
    );
    const hasSubCategorySelectionPermission = usePermission('enable_subcategory_dropdown_ff');

    const hasGoogleMapLinkBtnPermissionAdmin = usePermission(
        'enable_mapbox_googlemaps_cta_explore',
    );

    return {
        hasTrafficPinPermission,
        hasRevampCategory,
        hasPurchasedVenuesPermission,
        hasBadgesPermission,
        hasUnlockNearbyActivity,
        hasEnableRetailSales: hasEnableRetailSales && !hideRetailSales,
        hasNearbyPropertyVenueIndicatorFF,
        hasFreemiumExpFF,
        hasQAFeaturesPermission,
        hasRegionCategoryFilteringPermission,
        hasStoreIdBadgeAutocompleteSearchPermission,
        hasStoreIdBadgeSidebarPermission,
        hasStoreIdBadgePropertyCardPermission,
        hasStoreIdBadgeMoreOptionsPermission,
        hasStoreIdSelectedPropertiesPermission,
        hasSubCategorySelectionPermission,
        hasGoogleMapLinkBtnPermission:
            hasGoogleMapLinkBtnPermissionAdmin || enable_mapbox_googlemaps_cta_explore,
        hasOpenExploreReportSamePageInTablet,
        hasLogSelectedSearchResultPermission: send_clicked_search_results_sdr_ff,
    };
};
