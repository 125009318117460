/* eslint-disable max-len */
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { isEqual } from 'lodash/fp';

import { useAddMapListenerEffect } from 'features/poi-selection-map/hooks/poi-selection-map-hooks';
import {
    ENTITY_PROFILES,
    MAP_BOUNDS_CHANGE_DEBOUNCE,
} from 'features/poi-selection-map/constants/poi-selection-map-constants';
import {
    useSelectCustomSettingsPoiSelectionMap,
    useUpdateCustomUserSettings,
} from 'features/poi-selection-map/hooks/poi-selection-hooks';
import { defaultZoom, USACenterPoint } from 'ui-components/google-map/google-map-default-options';
import { MapBoundsConfig } from 'ui-components/google-map/types/types';
import { usePoiSelectionContext } from 'features/poi-selection-map/context/poi-selection-context';
import { SearchParams, searchPOI } from 'API/search';
import {
    aggregateSuggestions,
    getExploreParams,
    useSearchFetchData,
    useSearchPoiWithAdditionalParams,
    useSearchRecentEntities,
} from 'hooks/use-entity-search/use-entity-search';
import {
    PROPERTY,
    REPORT_TYPE_TO_ENTITY_TYPE,
    REPORT_TYPE_TO_PLACE_COLLECTIONS,
} from 'core/constants/report-type';
import { usePoiSelectionPermissions } from 'features/poi-selection-map/hooks/use-poi-selection-permissions';
import { useSearchMenuPermissions } from 'features/poi-selection-map/poi-selection-menu/menu-items/search-menu-item/hooks/use-search-menu-permissions';
import { useSearchPoiContext } from 'features/poi-selection-map/poi-selection-menu/menu-items/search-menu-item/context/search-poi-context';
import { defaultSearchApiFilters } from 'features/poi-selection-map/poi-selection-menu/menu-items/search-menu-item/context/initial-state';
import {
    SearchFilters,
    SearchFiltersKey,
} from 'features/poi-selection-map/poi-selection-menu/menu-items/search-menu-item/types/search-poi-types';
import { useGetRestrictionByMapLayerPOIType } from 'features/poi-selection-map/poi-selection-menu/menu-items/search-menu-item/hooks/search-poi-filter-hooks';
import type { Dictionary, PlaceCollection } from '@placer-ui/types';
import { RestrictionsType } from 'types/entity-search';
import {
    PoiSelectionMapConfiguration,
    PoiSelectionUserSettings,
} from 'features/poi-selection-map/types/poi-selection-types';
import { useSetSelectedSearchSuggestionInResults } from 'features/poi-selection-map/poi-selection-menu/menu-items/search-menu-item/hooks/search-autocomplete-hooks';
import { useTransformPoiOverlays } from 'features/poi-selection-map/poi-selection-menu/menu-items/search-menu-item/hooks/search-enclosing-complexes-hooks';
import { initialPoiSelectionContextState } from 'features/poi-selection-map/context/initial-state';
import { SEARCH_ITEM_ID } from 'features/poi-selection-map/poi-selection-menu/menu-items/search-menu-item/search-menu-item';
import { useSplunkSearchCallback } from 'hooks/use-splunk-callback/use-splunk-callback';
import { v4 as uuidv4 } from 'uuid';
import { subPageNameFromUrl } from 'utils/get-from-url/get-from-url';
import { SEARCH_SPLUNK_ACTIONS, SPLUNK_SEARCH_PLACEMENTS } from 'shared/constants/search-splunk';
import { getFeatureFlags } from 'core/flow-control';
import { checkReportAccessForCoordinate } from 'API/report-access-api/report-access-api';
import { isAddressPOI } from 'features/insights/pages/visual-templates/utils';

const useSearchApiFiltersForUserSettings = () => {
    const searchApiFilters = useSearchApiFilters();
    return useMemo<SearchFilters>(
        () => ({
            ...searchApiFilters,
            term: '', //do not save search term on user settings
            categories: [], //do not save categories in settings
        }),
        [searchApiFilters],
    );
};

export const useUpdateUserSettingsOnMapBoundsChange = () => {
    const initialEvent = useRef<boolean>(true);
    const updateCustomUserSettings = useUpdateCustomUserSettings();
    const { mapConfig } = usePoiSelectionContext();
    const searchFilters = useSearchApiFiltersForUserSettings();
    const lastSearchSection = useRef<PoiSelectionUserSettings>();

    const onBoundsChanged = useDebouncedCallback(() => {
        if (mapConfig && !initialEvent.current) {
            const newSettings: PoiSelectionUserSettings = {
                searchSection: {
                    ...mapConfig,
                    searchFilters,
                },
            };
            if (isEqual(lastSearchSection.current, newSettings)) return;
            lastSearchSection.current = newSettings;
            updateCustomUserSettings(newSettings);
        }
        initialEvent.current = false;
    }, MAP_BOUNDS_CHANGE_DEBOUNCE);

    useAddMapListenerEffect('bounds_changed', onBoundsChanged);
};

export const useUpdateUserSettingsOnFiltersChange = () => {
    const searchFilters = useSearchApiFiltersForUserSettings();
    const updateCustomUserSettings = useUpdateCustomUserSettings();
    const customSettingsPoiSelectionMap = useSelectCustomSettingsPoiSelectionMap();
    const { type, restrictions } = searchFilters;
    // update user settings for type and restrictions (not categories)
    const userSettingsToUpdate = useMemo<SearchFiltersKey[]>(() => ['type', 'restrictions'], []);

    useEffect(() => {
        if (customSettingsPoiSelectionMap) {
            const { searchFilters: customUserSearchFilters } =
                customSettingsPoiSelectionMap.searchSection;
            const shouldUpdate = Object.keys(searchFilters).some((key) => {
                const currentKey = key as SearchFiltersKey;
                if (userSettingsToUpdate.includes(currentKey) && customUserSearchFilters) {
                    return !isEqual(searchFilters[currentKey], customUserSearchFilters[currentKey]);
                }
                return false;
            }, []);
            if (shouldUpdate) {
                updateCustomUserSettings({
                    searchSection: {
                        ...customSettingsPoiSelectionMap.searchSection,
                        searchFilters,
                    },
                });
            }
        }
    }, [
        type,
        restrictions,
        updateCustomUserSettings,
        customSettingsPoiSelectionMap,
        userSettingsToUpdate,
        searchFilters,
    ]);
};

export const useMapOptionsFromUserSettings = () => {
    const userSettings = useSelectCustomSettingsPoiSelectionMap();

    return useMemo<MapBoundsConfig | undefined>(() => {
        if (userSettings?.searchSection) {
            return {
                zoom: userSettings.searchSection.zoom || defaultZoom,
                center: userSettings.searchSection.center || USACenterPoint,
            };
        }
    }, [userSettings?.searchSection]);
};
export const useSearchApiFilters = () => {
    const userSettings = useSelectCustomSettingsPoiSelectionMap();
    const { searchApiFilters } = useSearchPoiContext();
    return useMemo<SearchFilters>(() => {
        const searchFilters = searchApiFilters || userSettings?.searchSection.searchFilters;
        return {
            ...defaultSearchApiFilters,
            ...searchFilters,
        };
    }, [searchApiFilters, userSettings?.searchSection.searchFilters]);
};

export const useUpdateSearchApiFilters = () => {
    const searchApiFilters = useSearchApiFilters();
    const { setSearchApiFilters } = useSearchPoiContext();

    return useCallback(
        (filter: Partial<SearchFilters>) => {
            setSearchApiFilters({
                ...searchApiFilters,
                ...filter,
            });
        },
        [searchApiFilters, setSearchApiFilters],
    );
};

export const useSetMapOptionsOnInitialRender = () => {
    const { setMapConfig, mapConfig, mapRef } = usePoiSelectionContext();
    const mapOptionsFromUserSettings = useMapOptionsFromUserSettings();
    const initialUpdate = useRef<boolean>(false);

    useEffect(() => {
        if (!initialUpdate.current && mapRef) {
            initialUpdate.current = true;
            if (mapOptionsFromUserSettings) {
                setMapConfig({
                    ...mapConfig,
                    ...mapOptionsFromUserSettings,
                    //opt out of <FitBounds/>
                    useFitBounds: false,
                });
            }
        }
    }, [mapConfig, mapOptionsFromUserSettings, mapRef, setMapConfig]);
};

const useReportTypeToPlaceCollection = () => {
    const { type } = useSearchApiFilters();

    return useMemo<PlaceCollection[]>(
        () => REPORT_TYPE_TO_PLACE_COLLECTIONS[type || PROPERTY],
        [type],
    );
};

export const useFiltersNormalizer = () => {
    const { type, restrictions, categories } = useSearchApiFilters();
    const getRestrictionByMapLayerPOIType = useGetRestrictionByMapLayerPOIType();
    const isBillboardType = type === 'Billboard';

    const mapPoiTypes = [...ENTITY_PROFILES];
    !ENTITY_PROFILES.includes('custom') && mapPoiTypes.push('custom');

    const normalizedRestrictions = mapPoiTypes.reduce<Dictionary<boolean>>((acc, curr) => {
        const restriction = getRestrictionByMapLayerPOIType(curr);
        const camlCaseRestriction = restrictionTypeToSearchParam[restriction];
        acc[camlCaseRestriction] = isBillboardType || !!restrictions[restriction];
        return acc;
    }, {});

    return {
        restrictions: normalizedRestrictions,
        categories: !isBillboardType ? categories.map((item) => encodeURIComponent(item)) : [],
    };
};

export const useSearchApiRequest = () => {
    const reportTypeToPlaceCollection = useReportTypeToPlaceCollection();
    return useMemo(() => searchPOI(reportTypeToPlaceCollection), [reportTypeToPlaceCollection]);
};

const useGetResultsSkip = () => {
    const { resultsPagination } = usePoiSelectionContext();
    const { resultsPerPage } = resultsPagination;
    return useCallback((currentPage: number) => currentPage * resultsPerPage, [resultsPerPage]);
};
export const useSearchApiOnMapConfigChange = () => {
    const {
        mapConfig,
        setPoisList,
        setOverlayPoisList,
        resultsPagination,
        setResultsPagination,
        excludedSubcategories,
    } = usePoiSelectionContext();
    const { resultsPerPage, currentPage } = resultsPagination;
    const searchApiFilters = useSearchApiFilters();
    const { setIsLoadingPois } = useSearchPoiContext();
    const { restrictions, categories } = useFiltersNormalizer();
    const searchSuggestionInResults = useSetSelectedSearchSuggestionInResults();
    const prevMapConfig = useRef<Partial<PoiSelectionMapConfiguration> | undefined>(mapConfig);
    const prevSearchApiFilters = useRef<SearchFilters>(searchApiFilters);
    const transformPoiOverlays = useTransformPoiOverlays();
    const searchPoiWithAdditionalParams = useSearchPoiWithAdditionalParams();
    const getResultsSkip = useGetResultsSkip();
    const lastCurrentPage = useRef<number>(currentPage);
    const { resultsPagination: initialResultsPagination } = initialPoiSelectionContextState;
    const sendSearchSplunkEvent = useSplunkSearchCallback();
    const { setReportAccessError, reportAccessError, setIsReportAccessLoading } =
        usePoiSelectionContext();
    const { enable_sensitive_location_improvements_void_explore_ff } = getFeatureFlags();
    const abortControllerRef = useRef<AbortController | null>();

    const search = async () => {
        const { term, type: searchPoiType } = searchApiFilters;
        if (mapConfig?.neWithOffset && mapConfig.swWithOffset && searchPoiType) {
            setIsLoadingPois(true);
            setOverlayPoisList([]);
            try {
                const searchParams = {
                    reportType: searchPoiType,
                    searchParams: {
                        term,
                        category: categories,
                        skip: getResultsSkip(lastCurrentPage.current),
                        limit: resultsPerPage,
                        ...restrictions,
                        ne: mapConfig.neWithOffset.join(','),
                        sw: mapConfig.swWithOffset.join(','),
                        source: 'poi-selection-map-entities',
                        excludeCategory: excludedSubcategories,
                    },
                };

                let results = await searchPoiWithAdditionalParams(searchParams);

                sendSearchSplunkEvent({
                    action: SEARCH_SPLUNK_ACTIONS.search,
                    search_type: 'instant',
                    num_of_results: results.length,
                    search_placement: SPLUNK_SEARCH_PLACEMENTS.poiSelectionEntities,
                    search_query_params: searchParams,
                    view_name: `Advanced Reports ${subPageNameFromUrl()}`,
                });

                if (enable_sensitive_location_improvements_void_explore_ff) {
                    const addressInResultIdx = results.findIndex((poi) => isAddressPOI(poi.info));
                    if (addressInResultIdx > -1 && results[addressInResultIdx].info.geolocation) {
                        let addressPoi = results[addressInResultIdx];
                        if (abortControllerRef.current) {
                            abortControllerRef.current.abort();
                        }
                        abortControllerRef.current = new AbortController();

                        const signal = abortControllerRef?.current?.signal;

                        try {
                            setIsReportAccessLoading(true);
                            setReportAccessError({
                                ...reportAccessError,
                                [addressPoi.info.id]: undefined,
                            });
                            const { data } = await checkReportAccessForCoordinate(
                                addressPoi.info.geolocation!,
                                signal,
                            );

                            addressPoi = {
                                ...addressPoi,
                                info: {
                                    ...addressPoi.info,
                                    access: data.access,
                                },
                            };
                            results[addressInResultIdx] = addressPoi;
                        } catch (error: any) {
                            setReportAccessError({
                                ...reportAccessError,
                                [addressPoi.info.id]: error?.common || error,
                            });
                            addressPoi = {
                                ...addressPoi,
                                info: {
                                    ...addressPoi.info,
                                    access: {
                                        level: 'not_allowed',
                                        warnings: [],
                                    },
                                },
                            };
                            results[addressInResultIdx] = addressPoi;
                        } finally {
                            setIsReportAccessLoading(false);
                        }
                    }
                }

                setPoisList({
                    pois: searchSuggestionInResults(results, resultsPerPage),
                    menuItemId: SEARCH_ITEM_ID,
                });
                transformPoiOverlays(results);
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoadingPois(false);
            }
        }
    };

    const searchWithDebounce = useDebouncedCallback(() => {
        if (
            !isEqual(prevSearchApiFilters.current, searchApiFilters) ||
            !isEqual(mapConfig, prevMapConfig.current) ||
            currentPage !== lastCurrentPage.current
        ) {
            prevMapConfig.current = mapConfig;
            prevSearchApiFilters.current = searchApiFilters;
            if (lastCurrentPage.current === currentPage) {
                //bounds have changed while remaining in the same page, reset pagination
                setResultsPagination(initialResultsPagination);
                lastCurrentPage.current = initialResultsPagination.currentPage;
            } else {
                lastCurrentPage.current = currentPage;
            }

            search();
        }
    }, MAP_BOUNDS_CHANGE_DEBOUNCE);

    useEffect(() => {
        searchWithDebounce();
    }, [searchWithDebounce, mapConfig, searchApiFilters, currentPage]);
};

export const useSearchApiOnSearchInputChange = () => {
    const { hasPurchasedVenuesPermission } = useSearchMenuPermissions();
    const { type: reportType } = useSearchApiFilters();
    const {
        setSearchApiSuggestions,
        currentSearchTerm,
        isCnp,
        setIsLoadingSearchSuggestions,
        setSplunkSearchId,
        setIsCnp,
    } = useSearchPoiContext();
    const fetchSearchData = useSearchFetchData();
    const { excludedSubcategories } = usePoiSelectionContext();
    const sendSearchSplunkEvent = useSplunkSearchCallback();

    const search = async () => {
        try {
            setIsLoadingSearchSuggestions(true);
            const requestReportType = reportType || PROPERTY;
            const params = {
                term: currentSearchTerm,
                reportType: requestReportType,
                source: 'poi-map-selection-searchbar',
                purchasedOnly: hasPurchasedVenuesPermission,
                customParams: {
                    ...getExploreParams(requestReportType),
                    excludeCategory: excludedSubcategories,
                },
            };
            const results = await fetchSearchData(params);

            const newSearchId = uuidv4();
            setSplunkSearchId(newSearchId);

            sendSearchSplunkEvent({
                action: SEARCH_SPLUNK_ACTIONS.search,
                search_type: 'instant',
                source_type: 'placer',
                view_name: `Advanced Reports ${subPageNameFromUrl()}`,
                search_id: newSearchId,
                search_placement: SPLUNK_SEARCH_PLACEMENTS.poiSelectionMenu,
                search_term: currentSearchTerm,
                num_of_results: results.length,
                search_query_params: params,
                CnP: isCnp,
            });

            setIsCnp?.(false);
            setSearchApiSuggestions(results);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoadingSearchSuggestions(false);
        }
    };

    const searchWithDebounce = useDebouncedCallback(() => {
        search();
    }, MAP_BOUNDS_CHANGE_DEBOUNCE);

    useEffect(() => {
        searchWithDebounce();
    }, [searchWithDebounce, currentSearchTerm]);
};

export const useAutoCompleteSuggestions = () => {
    const { type } = useSearchApiFilters();
    const { currentSearchTerm, searchApiSuggestions } = useSearchPoiContext();
    const { hasUnlockNearbyActivity } = usePoiSelectionPermissions();

    const validType = type && REPORT_TYPE_TO_ENTITY_TYPE[type] ? type : PROPERTY;
    const recentEntities = useSearchRecentEntities({
        term: currentSearchTerm,
        type: validType,
    });

    return useMemo(() => {
        const suggestions = aggregateSuggestions({
            recentEntities,
            apiSearchResults: searchApiSuggestions || [],
            isLoading: true,
            termSearch: currentSearchTerm,
            hasUnlockNearbyActivity,
        });

        return suggestions.map((suggestion, i) => ({
            ...suggestion,
            optionUniqueKey: `${suggestion.value}_${i}`,
        }));
    }, [recentEntities, searchApiSuggestions, currentSearchTerm, hasUnlockNearbyActivity]);
};

export const useRemoveSelectedSearchSuggestionEffect = () => {
    const { poiActiveId } = usePoiSelectionContext();
    const { selectedSearchSuggestion, setSelectedSearchSuggestion } = useSearchPoiContext();

    useEffect(() => {
        if (selectedSearchSuggestion && !poiActiveId) {
            //user's disabled selectedSearchSuggestion on map
            setSelectedSearchSuggestion(undefined);
        }
    }, [poiActiveId, selectedSearchSuggestion, setSelectedSearchSuggestion]);
};

const restrictionTypeToSearchParam: Record<RestrictionsType, keyof SearchParams> = {
    enable_verified_pois: 'enableVerifiedPOIs',
    enable_available_pois: 'enableAvailablePOIs',
    enable_custom_pois: 'enableCustomPOIs',
    enable_unverified_pois: 'enableUnverifiedPOIs',
    enable_nearby_activity_pois: 'enableNearbyActivityPOIs',
    enable_closed_pois: 'enableClosedPOIs',
    enable_not_approved_cpoi: 'enableNotApprovedCPOIs',
};
