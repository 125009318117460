import React, { PropsWithChildren, useMemo } from 'react';
import { Card } from 'antd';
import { NearbyEntityOption } from 'features/void-analysis/sections/empty-space/types/methods-selector-modal-types';
import styles from './method-card.module.scss';
import { methodsDetails } from 'features/void-analysis/sections/empty-space/components/method-selection/method-selector-modal/components/method-card/utils';
import {
    CardDetails,
    CardMethodOptions,
} from 'features/void-analysis/sections/empty-space/types/method-card';
import { NearbySelector } from 'features/void-analysis/sections/empty-space/components/method-selection/method-selector-modal/components/nearby-selector/nearby-selector';
import { HelpButton } from 'ui-components/help-button/help-button';
import { EntityOption } from 'features/void-analysis/sections/empty-space/types/run-void-analysis-button-types';
import { CardMethodNames } from 'features/void-analysis/sections/empty-space/types/method-card';

type MethodModalCardProps = {
    className: string;
    method: CardMethodOptions;
    onSelectMethod: (methodName: CardMethodNames) => void;
    entityName: string;
    locationData?: EntityOption;
    setNearByEntitySelect: (nearByEntitySelect: NearbyEntityOption) => void;
    elevioDataId: string | number;
};

export const MethodCard = ({
    className,
    method,
    onSelectMethod,
    children,
    entityName,
    locationData,
    setNearByEntitySelect,
    elevioDataId,
}: PropsWithChildren<MethodModalCardProps>) => {
    const methodDetails: CardDetails = useMemo(() => {
        return methodsDetails.find(({ key }) => key === method.name)!;
    }, [method]);

    return (
        <Card
            className={className}
            onClick={() => {
                onSelectMethod(method.name);
            }}
        >
            <img className={styles.image} src={methodDetails.imageSrc} alt={'description map'} />
            <div className={styles.title}>{methodDetails.title}</div>
            <div className={styles.descriptionText}>
                {`${methodDetails.explainText} `}
                {locationData?.address && (
                    <span className={styles.descriptionTextLocationAddress}>
                        {`${entityName} / ${locationData.address}`}
                    </span>
                )}
            </div>
            <HelpButton
                elevioDataId={elevioDataId}
                className={styles.elevioMethod}
                as={'textButton'}
            >
                <span className={styles.elevioTitle}>When to use it?</span>
            </HelpButton>
            {children}
            <NearbySelector
                entityData={method.options}
                entityName={entityName}
                setNearByEntitySelect={setNearByEntitySelect}
            />
        </Card>
    );
};
