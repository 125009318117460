/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

type ArrowsFiveFromIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type ArrowsFiveFromIconProps = SVGProps<SVGSVGElement> & ArrowsFiveFromIconUniqueProps;

export const ArrowsFiveFromIcon = function ArrowsFiveFromIcon({
    width = 52,
    height = 248,
    ...props
}: ArrowsFiveFromIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 52 248"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M38.7454 124C12.0245 128.364 46.7617 185.336 1.92999 185.336"
                stroke="#767676"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M38.7454 124.003C11.077 123.27 46.7617 246.365 1.92999 246.365"
                stroke="#767676"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1.93 124C15.8719 124 49.9997 124 49.9997 124M49.9997 124L45.9998 128M49.9997 124L45.9998 120"
                stroke="#767676"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M38.7454 124C6.78825 124 46.7617 1.63867 1.92999 1.63867"
                stroke="#767676"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M38.7421 124C10.2034 119.265 43.0749 62.0366 1.92999 62.8047"
                stroke="#767676"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
ArrowsFiveFromIcon.__tags = [] as string[];
