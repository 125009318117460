import { HttpClientError } from '@placer-ui/http-client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { propertiesApi } from 'API/properties-api/properties-api';
import { reportsApi } from 'API/reports-api/reports-api';
import { reportDateOptionsActions } from 'store/configuration/report-date-options/slices/report-date-options-slice';
import { reportOptionsActions } from 'store/configuration/report-options/slices/report-options-slice';
import { reportAccessActions } from 'store/configuration/report-access/slices/report-access-slice';
import { getReportDateOptions, getReportOptions } from 'API/report-options-api/report-options-api';
import {
    setPOIs,
    cleanWidgetsByIds,
    getVenuesToDelete,
} from 'features/insights/store/poi/poi-actions';
import type {
    FetchReportChanges,
    FetchReportProps,
} from 'features/insights/store/actions/fetch-report/types';
import {
    fetchCompetitor,
    getShouldAddCompetitor,
} from 'features/insights/store/actions/fetch-report/competitor';
import { updateEntitiesFilters } from 'features/insights/store/actions/fetch-report/update-entities-filters';
import { updateURL } from 'features/insights/store/actions/fetch-report/update-url';
import {
    createVenues,
    initVenuesCoverage,
    initVenuesOptions,
} from 'features/insights/store/actions/fetch-report/venue';
import { saveRecentReport } from 'features/insights/store/actions/fetch-report/save-recent-report';
import type { PointOfInterestEntities } from '@placer-ui/types';
import type { URLEntity } from 'features/insights/types/url-types';
import type { AppDispatch, AppState } from 'store/store';
import { ERRORS } from 'API/common/constants';
import { newApiErrorType } from 'features/insights/errors/error-types';
import { analyzeVenuesSignal } from 'features/mobile-report/components/analyze-page/state/analyze.signals';
import { selectIds } from 'features/insights/store/selectors/insights-selectors';
import { invalidateUser } from 'store/auth/actions/auth-logout/auth-logout';
import type { Venue } from 'core/entities/venue/venueAdmin';
import { getPathStateName } from 'tracking/get-path-state-name';
import { calculateTime } from 'extensions/widget/utils/sentry-utils';
import {
    getPropertyReportConfiguration,
    getPropertyReportName,
} from 'features/insights/store/selectors/insights-tracking-hooks';
import type { PoiByIdState } from 'features/insights/store/poi/poi-types';
import type { EventData } from 'types/splunk/events';
import { getFeatureFlags } from 'core/flow-control';
import { isFetchReportCachedSignal } from 'features/insights/store/signals/insights.signals';

// To prevent 'Uncaught (in promise)' errors when one of the POI returns an error
const ignorePromiseRejection = (promise: Promise<any>) => promise.catch(() => {});

let reportEntities: PointOfInterestEntities[] = [];
let venues: Venue[] = [];
let competitorWasAdded = false;

export const fetchReport = createAsyncThunk<
    void,
    FetchReportProps,
    { dispatch: AppDispatch; state: AppState }
>(
    'insights/fetch-report',
    async (
        {
            entitiesFromURL,
            shouldFetchReport,
            reportChanges,
            checkSufficientData,
            withoutCompetitor,
            queryClient,
            sendEvent,
        },
        { dispatch, getState, rejectWithValue, signal },
    ) => {
        const { enable_property_page_loading_time } = getFeatureFlags();

        function withSignal<T extends any[]>(
            fn: ((...args: T) => void) | undefined,
            ...args: T
        ): void {
            if (!signal.aborted) {
                fn?.(...args);
            }
        }
        async function fetchEntityData({
            id,
            type,
            filter,
        }: URLEntity): Promise<PointOfInterestEntities> {
            const { data } = await propertiesApi.getProperty(id, type, {
                signal,
                is_nearby: !!filter.config?.ft_radius,
            });
            return data;
        }

        const startTime = performance.now();
        try {
            if (shouldFetchReport) {
                competitorWasAdded = false;
                isFetchReportCachedSignal.value = true;

                const user = getState().auth.user;
                const custom_settings = getState().auth.custom_settings;
                const isFreemiumUser =
                    !user?.settings?.account || user?.settings?.account?.is_active === false;
                const shouldAddCompetitor = getShouldAddCompetitor(
                    user,
                    getState().settings,
                    entitiesFromURL,
                );
                let entitiesDataPromises = entitiesFromURL.map((e) =>
                    ignorePromiseRejection(fetchEntityData(e)),
                );
                let competitor;
                if (!withoutCompetitor && shouldAddCompetitor) {
                    competitor = await fetchCompetitor(entitiesFromURL[0], signal);
                    if (competitor) {
                        entitiesFromURL.push(competitor);
                        entitiesDataPromises.push(
                            ignorePromiseRejection(fetchEntityData(competitor)),
                        );
                        competitorWasAdded = true;
                    }
                }

                if (reportChanges.entities || competitorWasAdded) {
                    const entitiesIds = entitiesFromURL.map(({ id, type }) => ({
                        id,
                        type,
                    }));

                    const reportOptionsPromise = getReportOptions(entitiesIds, { signal });
                    const entitiesDataPromise = Promise.all(entitiesDataPromises);
                    const [entitiesData, reportOptions] = await Promise.all([
                        entitiesDataPromise,
                        reportOptionsPromise,
                    ]);

                    withSignal(dispatch, reportOptionsActions.setReportOptions(reportOptions));
                    reportEntities = entitiesData;
                }

                const { isFilterUpdated } = updateEntitiesFilters({
                    entitiesFromURL,
                    entitiesData: reportEntities,
                    isFreemiumUser,
                    userConfiguration: user?.configuration,
                    userSettings: getState().settings,
                    defaultDates: getState().poi.defaultDates,
                    reportOptions: getState().configuration.reportOptions,
                });

                if (competitor || isFilterUpdated) {
                    updateURL(
                        entitiesFromURL,
                        withoutCompetitor,
                        getState().poi.defaultDates?.dates,
                    );
                }

                venues = createVenues(entitiesFromURL, reportEntities, {
                    entities: getState().poi.entities,
                    dates: getState().poi.defaultDates?.dates,
                });

                withSignal(
                    initVenuesOptions,
                    venues,
                    getState().configuration.reportOptions.entities_data,
                );
                //@ts-expect-error PLAC-47814
                const venuesIdToDelete = getVenuesToDelete(venues, selectIds(getState()));
                //@ts-expect-error PLAC-47814
                withSignal(dispatch, setPOIs(venues));
                withSignal(dispatch, cleanWidgetsByIds(venuesIdToDelete));

                if (reportChanges.nearbyRadius) {
                    await Promise.all(
                        entitiesFromURL.map(async (entity) => {
                            if (entity.filter.config?.ft_radius) {
                                await reportsApi.getNearbyReportStatus({
                                    type: entity.type,
                                    id: entity.id,
                                    ft_radius: entity.filter.config?.ft_radius,
                                });
                            }
                        }),
                    );
                }

                if (reportChanges.entities || reportChanges.dates) {
                    const reportAccessPromise = reportsApi.getReportAccess(
                        entitiesFromURL,
                        venues,
                        {
                            signal,
                        },
                    );

                    const reportDatesOptionsPromise = getReportDateOptions(
                        entitiesFromURL.map(
                            (entity) => ({
                                entity_id: entity.id,
                                entity_type: entity.type,
                                start_date: entity.filter.date.start,
                                end_date: entity.filter.date.end,
                            }),
                            { signal },
                        ),
                    );

                    const [reportAccess, reportDatesOptions] = await Promise.all([
                        reportAccessPromise,
                        reportDatesOptionsPromise,
                    ]);

                    withSignal(
                        dispatch,
                        reportDateOptionsActions.setReportDateOptions(reportDatesOptions),
                    );
                    withSignal(dispatch, reportAccessActions.setReportAccess(reportAccess));

                    const coverageData = await initVenuesCoverage(venues);

                    venues.forEach((venue, index) => {
                        venue.setCoverage(coverageData[index].data);
                    });

                    const reportAccessError = Object.values(reportAccess).find(
                        (data) => !!data.error,
                    );
                    if (reportAccessError && reportAccessError.error) {
                        throw newApiErrorType(reportAccessError);
                    }
                }

                withSignal(dispatch, saveRecentReport(venues, custom_settings));
                analyzeVenuesSignal.value = reportEntities;
            }
            if (
                checkSufficientData &&
                (reportChanges.entities || reportChanges.dates || reportChanges.filters)
            ) {
                await reportsApi.checkSufficientData(entitiesFromURL, { signal });
            }

            enable_property_page_loading_time &&
                sendSplunkEvent(sendEvent, venues, startTime, reportChanges);
        } catch (error: any) {
            analyzeVenuesSignal.value = reportEntities;

            if (isUnauthorizedError(error.common || error)) {
                invalidateUser(dispatch, queryClient);
            }

            if (
                error?.body?.error === ERRORS.POLYGON_TOO_SMALL ||
                error?.body?.error === ERRORS.UNDELIVERABLE_ENTITY
            ) {
                throw rejectWithValue({
                    error,
                    status: 403,
                    type: error.body.error,
                });
            }
            console.error(error.common || error);
            throw rejectWithValue(error.common || error);
        }
    },
);

const isUnauthorizedError = (error: any) => {
    const isHttpClientUnauthorizedError = (payload: any | undefined) =>
        payload instanceof HttpClientError && payload.message === 'AUTHENTICATION_FAILED';

    const isFetchUnauthorizedError = (payload: any | undefined) =>
        payload?.status && payload.status === 401;

    return isHttpClientUnauthorizedError(error) || isFetchUnauthorizedError(error);
};

const sendSplunkEvent = (
    sendEvent: (eventData?: EventData | undefined) => void,
    venues: Venue[],
    startTime: number,
    reportChanges: FetchReportChanges,
) => {
    const limited = venues.some((poiData) => !poiData.isPurchased);
    const pois = venues.reduce((acc, poiData) => {
        const poiFilters = { ...poiData.customData?.filter };
        acc[poiData.uid] = {
            //@ts-expect-error PLAC-47814
            originalEntity: poiData,
            filter: poiFilters,
        };
        return acc;
    }, {} as PoiByIdState);

    const reportConfig = getPropertyReportConfiguration(pois, limited);
    const viewName = getPropertyReportName(pois);

    sendEvent({
        action: 'page-load',
        value: calculateTime({
            start: startTime,
            end: performance.now(),
        }),
        report_changes: reportChanges,
        report_config: reportConfig,
        state: getPathStateName(),
        view_name: viewName,
        source_url: window.location.href.split('?')[0],
        cached: String(isFetchReportCachedSignal.value),
    });
};
