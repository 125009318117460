import { createSlice } from '@reduxjs/toolkit';
import { ExploreConfiguration } from 'features/explore/types/types';
import { Action } from 'redux';

export const defaultInitialExploreState: ExploreConfiguration = {
    reportType: 'venues%2Ccomplexes',
    term: '',
    categories: [],
    chains: [],
    restrictions: {
        enable_custom_pois: true,
        enable_verified_pois: true,
        enable_unverified_pois: true,
        enable_closed_pois: true,
        enable_available_pois: true,
        enable_nearby_activity_pois: true,
    },
    limit: 100,
    skip: 0,
};

export const initialExploreState: ExploreConfiguration = {
    reportType: '',
    term: '',
    categories: [],
    chains: [],
    restrictions: {},
    limit: 100,
    skip: 0,
};

interface ExploreAction<T> extends Action {
    payload: T;
}

const exploreSlice = createSlice({
    name: 'explore',
    initialState: initialExploreState,
    reducers: {
        resetState: () => {
            return initialExploreState;
        },
        setExploreConfiguration: (state, action: ExploreAction<Partial<ExploreConfiguration>>) => {
            return {
                ...state,
                ...action.payload,
            };
        },
    },
});

export const exploreReducer = {
    explore: exploreSlice.reducer,
};

export const exploreActions = exploreSlice.actions;
