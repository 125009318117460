import { ActionCreatorWithPayload, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'API';
import { selectChainsSearchResultsDateRange } from '../../selectors';
import { ChainsReportState } from 'features/chains/store/chains-store';
import { GetChainsSearchTagsProps, TAGS_LIMIT } from 'API/user-api';
import { ChainEntityModel } from 'core/entities';
import {
    selectChainsReportSearchMyTagChains,
    selectChainsReportSearchCompanyTagChains,
    selectMyTagQuery,
    selectCompanyTagQuery,
} from 'features/chains/store/chains-report-search-store/selectors/chains-report-search-selectors-factory';
import {
    setMyTagsSearchResultsPotentiallyMoreResultsExist,
    setCompanyTagsSearchResultsPotentiallyMoreResultsExist,
} from '../chains-report-search-action-creator';
// eslint-disable-next-line max-len
import { TagsSectionNameType } from 'features/chains/regular-view/components/search/components/common/tags-section-body/tags-section-body';
import { ChainEntityModelMapper } from 'features/chains/common/util/chain-placer-entity-model-mapper';

type InitTagsSearchParams = {
    sectionType: TagsSectionNameType;
    actionType: string;
    selectTags: typeof selectChainsReportSearchCompanyTagChains;
    selectSearchQuery: typeof selectMyTagQuery;
    setPotentiallyMoreResultsExist: ActionCreatorWithPayload<boolean, string>;
};

const getChainsSearchTags = async (params: GetChainsSearchTagsProps) => {
    const { data: allTags } = await API.user.getChainsSearchTags(params);
    return allTags;
};

export const initTagsSearch = ({
    sectionType,
    actionType,
    selectTags,
    selectSearchQuery,
    setPotentiallyMoreResultsExist,
}: InitTagsSearchParams) => {
    return createAsyncThunk<
        ChainEntityModel[],
        GetChainsSearchTagsProps,
        { state: ChainsReportState }
    >(actionType, async (params = {}, { dispatch, getState }) => {
        const state = getState();
        const currentTags = selectTags(state);
        const currentQuery = selectSearchQuery(state);
        const isQueryValueChanged = currentQuery !== params.searchQuery;

        params['sectionType'] = sectionType;
        params['skip'] = isQueryValueChanged ? 0 : currentTags.length;

        const tags = await getChainsSearchTags(params);
        dispatch(setPotentiallyMoreResultsExist(tags.length === TAGS_LIMIT));

        const { startDate, endDate } = selectChainsSearchResultsDateRange(state);
        const nextTags = tags.map((tag) =>
            ChainEntityModelMapper.mapEntityWrapperToEntityModel({
                entityWrapper: {
                    info: {
                        type: 'tag',
                        area: {
                            code: 'US',
                            name: 'United States',
                            type: 'custom',
                        },
                        enable_limited_report: true,
                        purchased: true,
                        region_code: 'US',
                        region_type: 'nationwide',
                        ...tag,
                    } as any,
                    // TODO: improve type 
                    // https://placer.atlassian.net/browse/PLAC-15205
                },
                startDate,
                endDate,
            }),
        );

        return isQueryValueChanged ? nextTags : [...currentTags, ...nextTags];
    });
};

export const getChainsSearchMyTags = initTagsSearch({
    sectionType: 'my-tags',
    actionType: 'search/get-my-tags',
    selectTags: selectChainsReportSearchMyTagChains,
    selectSearchQuery: selectMyTagQuery,
    setPotentiallyMoreResultsExist: setMyTagsSearchResultsPotentiallyMoreResultsExist,
});

export const getChainsSearchCompanyTags = initTagsSearch({
    sectionType: 'company-tags',
    actionType: 'search/get-company-tags',
    selectTags: selectChainsReportSearchCompanyTagChains,
    selectSearchQuery: selectCompanyTagQuery,
    setPotentiallyMoreResultsExist: setCompanyTagsSearchResultsPotentiallyMoreResultsExist,
});
