import { titleCase } from 'utils/title-case/title-case';

const format = (str: string) => titleCase(str.replace('-', ' '));

export const getViewNameFromState = (state: string) => {
    const splitState = state.split('.');
    if (splitState.length > 2) {
        return `${format(splitState[1])} - ${format(splitState[2])}`;
    }
    return format(splitState[1]);
};
