/* eslint-disable max-len */
import React from 'react';

export const SgCivicIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.5008 5.55564L10.5006 0L0.500488 5.55564V10.0001H20.5008V5.55564ZM10.5006 7.77789C11.7279 7.77789 12.7229 6.78295 12.7229 5.55564C12.7229 4.32832 11.7279 3.33338 10.5006 3.33338C9.27331 3.33338 8.27838 4.32832 8.27838 5.55564C8.27838 6.78295 9.27331 7.77789 10.5006 7.77789Z"
                fill="#E1E2FF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.5006 7.77789C11.7279 7.77789 12.7229 6.78295 12.7229 5.55564C12.7229 4.32832 11.7279 3.33338 10.5006 3.33338C9.27331 3.33338 8.27838 4.32832 8.27838 5.55564C8.27838 6.78295 9.27331 7.77789 10.5006 7.77789ZM10.5006 6.94454C11.2677 6.94454 11.8895 6.32271 11.8895 5.55564C11.8895 4.78856 11.2677 4.16673 10.5006 4.16673C9.73356 4.16673 9.11172 4.78856 9.11172 5.55564C9.11172 6.32271 9.73356 6.94454 10.5006 6.94454Z"
                fill="#5E63E5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.5006 0L0.500488 5.55564V20.0003H20.5008V5.55564L10.5006 0ZM19.6674 10.0001H1.33383V19.1669H3.83387V13.8891C3.83387 12.3549 5.07754 11.1113 6.61169 11.1113C8.14583 11.1113 9.3895 12.3549 9.3895 13.8891V19.1669H11.6118V13.8891C11.6118 12.3549 12.8554 11.1113 14.3896 11.1113C15.9237 11.1113 17.1674 12.3549 17.1674 13.8891V19.1669H19.6674V10.0001ZM16.334 19.1669V13.8891C16.334 12.8152 15.4635 11.9446 14.3896 11.9446C13.3157 11.9446 12.4451 12.8152 12.4451 13.8891V19.1669H16.334ZM8.55616 19.1669V13.8891C8.55616 12.8152 7.68559 11.9446 6.61169 11.9446C5.53778 11.9446 4.66721 12.8152 4.66721 13.8891V19.1669H8.55616ZM19.6208 6.07344L10.5006 1.0067L1.3805 6.07344V9.12013H19.6208V6.07344Z"
                fill="#5E63E5"
            />
        </svg>
    );
};
