/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

type HolidaysFederalIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type HolidaysFederalIconProps = SVGProps<SVGSVGElement> & HolidaysFederalIconUniqueProps;

export const HolidaysFederalIcon = function HolidaysFederalIcon({
    width = 32,
    height = 32,
    ...props
}: HolidaysFederalIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M18 2.5H32V5.5H18V2.5Z" fill="#F7ABB8" />
            <path d="M32 6.5H18V9.5H32V6.5Z" fill="#F7ABB8" />
            <path d="M18 10.5H32V13.5H18V10.5Z" fill="#F7ABB8" />
            <path d="M18 14.5H32V17.5H18V14.5Z" fill="#F7ABB8" />
            <path d="M32 18.5H0V21.5H32V18.5Z" fill="#F7ABB8" />
            <path d="M0 26.5H32V27.5C32 28.6046 31.1046 29.5 30 29.5H0V26.5Z" fill="#F7ABB8" />
            <path d="M32 22.5H0V25.5H32V22.5Z" fill="#F7ABB8" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17 2.5H0V17.5H17V2.5ZM5 5.5C5 6.05228 4.55228 6.5 4 6.5C3.44772 6.5 3 6.05228 3 5.5C3 4.94772 3.44772 4.5 4 4.5C4.55228 4.5 5 4.94772 5 5.5ZM12 5.5C12 6.05228 11.5523 6.5 11 6.5C10.4477 6.5 10 6.05228 10 5.5C10 4.94772 10.4477 4.5 11 4.5C11.5523 4.5 12 4.94772 12 5.5ZM7 10.5C7.55228 10.5 8 10.0523 8 9.5C8 8.94772 7.55228 8.5 7 8.5C6.44772 8.5 6 8.94772 6 9.5C6 10.0523 6.44772 10.5 7 10.5ZM14 9.5C14 10.0523 13.5523 10.5 13 10.5C12.4477 10.5 12 10.0523 12 9.5C12 8.94772 12.4477 8.5 13 8.5C13.5523 8.5 14 8.94772 14 9.5ZM4 14.5C4.55228 14.5 5 14.0523 5 13.5C5 12.9477 4.55228 12.5 4 12.5C3.44772 12.5 3 12.9477 3 13.5C3 14.0523 3.44772 14.5 4 14.5ZM12 13.5C12 14.0523 11.5523 14.5 11 14.5C10.4477 14.5 10 14.0523 10 13.5C10 12.9477 10.4477 12.5 11 12.5C11.5523 12.5 12 12.9477 12 13.5Z"
                fill="#5E63E5"
            />
        </svg>
    );
};
HolidaysFederalIcon.__tags = ['holiday', 'usa', 'flag'] as string[];
