import { fetchWrapper } from 'API/authentification/fetch-wrapper';
import type { Dictionary, PlacerResponseData } from '@placer-ui/types';
import { PlaceWithFilters } from 'features/insights/store/poi/poi-types';

export const fetchEnclosingComplexes = async (poiIds: string[]) => {
    const { data } = await fetchWrapper<PlacerResponseData<Dictionary<PlaceWithFilters[]>>>({
        targetUrl: '/entities/enclosing_complexes',
        method: 'POST',
        body: {
            venues_ids: poiIds,
        },
    });
    return data;
};
