import { createSelector } from '@reduxjs/toolkit';

import { selectChainsReportTotalCount } from '../chains-report-chains-selectors-factory';
import { MAX_COMPETITORS_QUANTITY } from 'features/industry/common/constants';

export const selectCanAddMoreChains = createSelector(
    selectChainsReportTotalCount,
    (reportChainsTotalCount) => {
        return reportChainsTotalCount < MAX_COMPETITORS_QUANTITY;
    },
);
