import React from 'react';
import classnames from 'classnames';
import { MapLayerVenue } from 'ui-components/google-map-layers/types/google-map-layers-types';
import { CustomInfoWindow } from 'ui-components/google-map/components/custom-info-window/custom-info-window';
import { SquareLogo } from 'ui-components/square-logo/square-logo';
import { useGoogleMapLayersContextState } from 'ui-components/google-map-layers/context/google-map-layers-context';
import styles from './logo-marker-tooltip.module.scss';
import { getCategoryStaticIconUrl, getCategoryIconUrl } from 'core/entities/category/utils';
import { useSelectAvailableCategoriesData } from 'store/configuration/categories/selectors/selectors-hooks';

type LogoMarkerTooltipProps = {
    poi: MapLayerVenue;
    clickable?: boolean;
};

export const LogoMarkerTooltip = ({ poi: { info }, clickable = true }: LogoMarkerTooltipProps) => {
    const { id, geolocation, logo_img, name } = info;
    const { activeId, selectedPois, setHoverId, setActiveId, mapboxBeta } =
        useGoogleMapLayersContextState();
    const categoriesIconData = useSelectAvailableCategoriesData();

    const isSelected = selectedPois.some((poi) => poi.info.id === id);
    const selectedClass = {
        [styles.selected]: isSelected,
    };

    if (activeId === id) {
        return null;
    }

    const logoImgUrl = getCategoryIconUrl({
        categoriesIconData,
        info,
    });

    const categoryIcon = !logoImgUrl
        ? getCategoryStaticIconUrl({
              info,
          })
        : undefined;

    const icon = logoImgUrl || categoryIcon;

    return (
        <CustomInfoWindow
            //@ts-expect-error PLAC-47814
            position={geolocation}
            offset={[-1, 6]}
            className={classnames(styles.customTooltip, selectedClass)}
            anchorClassName={classnames(styles.anchor, selectedClass)}
            mapboxBeta={mapboxBeta}
        >
            <div
                onClick={() => (clickable ? setActiveId(id) : undefined)}
                onMouseOver={() => setHoverId(id)}
                onMouseOut={() => setHoverId(undefined)}
            >
                <SquareLogo name={name} logoUrl={logo_img} icon={icon} />
            </div>
        </CustomInfoWindow>
    );
};
