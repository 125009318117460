import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

import { IconProps } from 'components/assets/Icons/Icons';

/* eslint-disable max-len */
export const ApparelIconSvg = (props: CustomIconComponentProps | SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 17 14">
        <g fill="currentColor" fillRule="nonzero">
            <path d="M7.296 6.91a2.044 2.044 0 011.933 0l6.268 3.357c1.5.8.928 3.066-.771 3.066H1.8c-1.7 0-2.27-2.266-.773-3.065zm5.691 3.757h-9.45l-.12.01a.67.67 0 00-.554.656.67.67 0 00.675.667h9.45l.12-.01a.671.671 0 00.555-.657.67.67 0 00-.675-.666z" />
            <path d="M8.262 0c1.49 0 2.7 1.194 2.7 2.667 0 1.204-.81 2.223-1.92 2.553l-.105.027v2.586c0 .328-.239.6-.554.656l-.12.011a.673.673 0 01-.665-.547l-.01-.12V4.667A.67.67 0 018.261 4c.745 0 1.35-.598 1.35-1.333 0-.736-.605-1.334-1.35-1.334-.745 0-1.35.598-1.35 1.334a.67.67 0 01-.675.666.67.67 0 01-.675-.666C5.562 1.194 6.772 0 8.262 0z" />
        </g>
    </svg>
);

export const ApparelIcon = (props: IconProps) => <Icon component={ApparelIconSvg} {...props} />;
