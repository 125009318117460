/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

export type CheckedToggleIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type CheckedToggleIconProps = SVGProps<SVGSVGElement> & CheckedToggleIconUniqueProps;

export const CheckedToggleIcon = function CheckedToggleIcon({
    width = 8,
    height = 6,
    ...props
}: CheckedToggleIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 8 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.69067 0.368911C7.88388 0.566204 7.88057 0.882769 7.68328 1.07598L3.2493 5.41823L0.960523 3.18061C0.763067 2.98756 0.75949 2.671 0.952533 2.47355C1.14558 2.27609 1.46214 2.27251 1.65959 2.46556L3.2487 4.01915L6.9836 0.361521C7.18089 0.16831 7.49746 0.171619 7.69067 0.368911Z"
                fill="currentColor"
            />
        </svg>
    );
};
CheckedToggleIcon.__tags = [] as string[];
