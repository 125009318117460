import { DependencyList, useEffect } from 'react';

type ScrollPosition = {
    offsetX: number;
    offsetY: number;
};

export const useWindowScrollEffect = (
    effect: (newPosition: ScrollPosition) => void,
    deps: DependencyList,
) => {
    useEffect(() => {
        const handleScroll = () => {
            const { pageXOffset, pageYOffset } = window;
            effect({
                offsetX: pageXOffset,
                offsetY: pageYOffset,
            });
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
        // the component which consumes this hook is responsible for additional dependencies, if any
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...deps, effect]);
};
