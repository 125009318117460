/* eslint-disable max-len */
import React, { useMemo } from 'react';
import styles from 'features/void-analysis/common/styles/common-styles.module.scss';
import { NewChainInfo, PoiEntity } from 'features/void-analysis/common/types/chains';
import { ColumnTitleCell } from 'ui-components/table/components/cells/report-entity-cell/column-title-cell/column-title-cell';
import { ValueCell } from 'ui-components/table/components/cells/value-cell/value-cell';
import {
    RankingTableColumn,
    RankingTableColumnProps,
} from 'features/void-analysis/common/types/ranking-table';
import { getScoreParam } from 'features/void-analysis/common/utils/match-score';
import { ChainCell } from 'features/void-analysis/common/components/chain-cell/chain-cell';
import { useMatchScoreCalculation } from 'features/void-analysis/common/hooks/hooks';
import { useGetSortingProperties } from 'features/void-analysis/sections/ranking-page/components/report-content/components/void-analysis-ranking-table/hooks/table-sorting';
import { STRING_FOR_NULL_DATA_TO_DISPLAY } from 'components/advanced-reports-analysis/widgets/constants/constants';
import { useEmptySpacePermissions } from 'features/void-analysis/sections/empty-space/hooks/use-empty-space-permissions';
import { parseMetricValue } from 'features/void-analysis/common/utils/metric-value-formatter';
import { MinMax } from 'components/advanced-reports-analysis/match-analysis-page/types/types';
import { ENTITY_TYPE_LABEL } from 'features/void-analysis/sections/ranking-page/ranking-page-labels';
import { LOCATIONS_LABEL } from 'features/void-analysis/common/utils/locations-label';

const useValueColumnsList = () => {
    const matchScoreCalculation = useMatchScoreCalculation();
    const getSortingProps = useGetSortingProperties();
    const { hasEmptySpacePermission } = useEmptySpacePermissions();

    return useMemo<RankingTableColumnProps[]>(() => {
        const locationsNumberColumnLabel = `# of ${LOCATIONS_LABEL}`;

        const tableColumns = [
            {
                label: ENTITY_TYPE_LABEL,
                title: <ColumnTitleCell title={ENTITY_TYPE_LABEL} columnKey="chain_name" />,
                key: 'chain_name' as RankingTableColumn,
                width: '23.5%',
                toggleable: false,
                draggable: false,
                ...getSortingProps('chain_name' as RankingTableColumn),
                render: ({ name, category, sub_category, logo_img }: PoiEntity) => (
                    <ChainCell
                        name={name}
                        category={category}
                        subCategory={sub_category}
                        logoUrl={logo_img}
                    />
                ),
            },
            {
                label: 'Typical Size (sq ft)',
                title: <ColumnTitleCell title="Typical Size (sq ft)" columnKey="typical_size" />,
                key: 'typical_size' as RankingTableColumn,
                width: '15.3%',
                className: styles.valuesColumn,
                toggleable: true,
                ...getSortingProps('typical_size' as RankingTableColumn),
                render: ({ venues }: PoiEntity) => {
                    const { max_size_sq_ft, min_size_sq_ft } = venues;
                    const minmax: MinMax = {
                        min: min_size_sq_ft,
                        max: max_size_sq_ft,
                    };
                    return <ValueCell values={[parseMetricValue(minmax, 'typical_size')]} />;
                },
            },
            {
                label: 'Nearest Location (mi)',
                title: (
                    <ColumnTitleCell title="Nearest Location (mi)" columnKey="nearest_location" />
                ),
                key: 'nearest_location' as RankingTableColumn,
                width: '15.3%',
                className: styles.valuesColumn,
                toggleable: true,
                ...getSortingProps('nearest_location' as RankingTableColumn),
                render: ({ venues }: PoiEntity) => {
                    const { nearest_venue } = venues;
                    return (
                        <ValueCell values={[parseMetricValue(nearest_venue, 'nearest_location')]} />
                    );
                },
            },
            {
                label: locationsNumberColumnLabel,
                title: (
                    <ColumnTitleCell
                        title={locationsNumberColumnLabel}
                        columnKey="number_of_venues"
                    />
                ),
                key: 'number_of_venues' as RankingTableColumn,
                width: '15.3%',
                className: styles.valuesColumn,
                toggleable: true,
                ...getSortingProps('number_of_venues' as RankingTableColumn),
                render: ({ venues }: PoiEntity) => {
                    const { num_in_country, num_in_state } = venues;
                    const values = [
                        parseMetricValue(num_in_state, 'number_of_venues'),
                        parseMetricValue(num_in_country, 'number_of_venues'),
                    ];

                    return <ValueCell values={values} title="State / US" />;
                },
            },
            {
                label: 'Expansion Rate',
                title: <ColumnTitleCell title="Expansion Rate" columnKey="expansion_rate" />,
                key: 'expansion_rate' as RankingTableColumn,
                width: '15.3%',
                className: styles.valuesColumn,
                toggleable: true,
                ...getSortingProps('expansion_rate' as RankingTableColumn),
                render: ({ expansion_rate, new_chain_info }: PoiEntity) => {
                    let expansionRates: string[] = [];
                    const state = expansion_rate?.state;
                    const country = expansion_rate?.country;

                    const newChainInfo: NewChainInfo = {
                        state: new_chain_info?.state || null,
                        country: new_chain_info?.country || null,
                    };

                    const stateValue = parseMetricValue(
                        state,
                        'expansion_rate',
                        newChainInfo.state,
                    );
                    const countryValue = parseMetricValue(
                        country,
                        'expansion_rate',
                        newChainInfo.country,
                    );

                    expansionRates = [stateValue, countryValue];
                    return (
                        <ValueCell
                            values={expansionRates}
                            title="State / US"
                            data-testid={'expansion-rate-value-cell'}
                        />
                    );
                },
            },
            {
                label: 'Demographic Fit Score',
                title: (
                    <ColumnTitleCell
                        title="Demographic Fit Score"
                        columnKey="demographic_fit_score"
                    />
                ),
                key: 'demographic_fit_score' as RankingTableColumn,
                width: '15.3%',
                className: styles.valuesColumn,
                toggleable: true,
                ...getSortingProps('demographic_fit_score' as RankingTableColumn),
                render: ({ match_score }: PoiEntity) => {
                    const { value: demographicsValue } = getScoreParam(
                        match_score,
                        'demographics',
                    )!;

                    const demographicsValueParsed = parseMetricValue(
                        demographicsValue,
                        'demographic_fit_score',
                    );

                    return <ValueCell values={[demographicsValueParsed]} />;
                },
            },
            {
                label: 'Cannibalization',
                title: (
                    <ColumnTitleCell title="Cannibalization" columnKey="cannibalization_score" />
                ),
                key: 'cannibalization_score' as RankingTableColumn,
                width: '15.3%',
                className: styles.valuesColumn,
                toggleable: true,
                ...getSortingProps('cannibalization_score' as RankingTableColumn),
                render: ({ match_score }: PoiEntity) => {
                    const { value } = getScoreParam(match_score, 'cannibalization')!;

                    return (
                        <ValueCell values={[parseMetricValue(value, 'cannibalization_score')]} />
                    );
                },
            },
            {
                label: 'Avg. Monthly Visits',
                title: <ColumnTitleCell title="Avg. Monthly Visits" columnKey="avg_monthly_ft" />,
                key: 'avg_monthly_ft' as RankingTableColumn,
                width: '15.3%',
                className: styles.valuesColumn,
                toggleable: true,
                ...getSortingProps('avg_monthly_ft' as RankingTableColumn),
                render: ({ match_score }: PoiEntity) => {
                    const { value } = getScoreParam(match_score, 'avg_monthly_ft')!;

                    return <ValueCell values={[parseMetricValue(value, 'avg_monthly_ft')]} />;
                },
            },
            {
                label: 'Household Characteristics Score',
                title: (
                    <ColumnTitleCell
                        title="Household Characteristics Score"
                        columnKey="household_characteristics"
                    />
                ),
                key: 'household_characteristics' as RankingTableColumn,
                width: '15.3%',
                className: styles.valuesColumn,
                toggleable: true,
                visible: false,
                render: ({ match_score }: PoiEntity) => {
                    const { value } = getScoreParam(match_score, 'household_characteristics')!;

                    return (
                        <ValueCell
                            values={[parseMetricValue(value, 'household_characteristics')]}
                        />
                    );
                },
            },
            {
                label: 'Frequent Co-Tenants',
                title: (
                    <ColumnTitleCell title="Frequent Co-Tenants" columnKey="natural_co_tenants" />
                ),
                key: 'natural_co_tenants' as RankingTableColumn,
                width: '15.3%',
                className: styles.valuesColumn,
                toggleable: true,
                ...getSortingProps('natural_co_tenants' as RankingTableColumn),
                render: ({ match_score }: PoiEntity) => {
                    const { value } = getScoreParam(match_score, 'natural_co_tenants')!;

                    return <ValueCell values={[parseMetricValue(value, 'natural_co_tenants')]} />;
                },
            },
            {
                label: 'Relative Fit Score',
                title: <ColumnTitleCell title="Relative Fit Score" columnKey="total_match_score" />,
                key: 'total_match_score' as RankingTableColumn,
                width: '15.3%',
                className: styles.valuesColumn,
                toggleable: true,
                ...getSortingProps('total_match_score' as RankingTableColumn),
                render: ({ match_score }: PoiEntity) => {
                    const { total } = match_score;
                    if (matchScoreCalculation?.is_calculated === false) {
                        return (
                            <div>
                                <ValueCell
                                    values={[STRING_FOR_NULL_DATA_TO_DISPLAY]}
                                    tooltipText={`Relative fit score is supported for ${matchScoreCalculation?.min_results} or more chain matches.`}
                                />
                            </div>
                        );
                    }

                    return <ValueCell values={[parseMetricValue(total, 'total_match_score')]} />;
                },
            },
        ];

        if (hasEmptySpacePermission) {
            tableColumns.push({
                label: 'Typical Drive Time (min)',
                title: <ColumnTitleCell title="Typical Drive Time (min)" columnKey="median_dvt" />,
                key: 'median_dvt' as RankingTableColumn,
                width: '15.3%',
                className: styles.valuesColumn,
                toggleable: true,
                ...getSortingProps('median_dvt' as RankingTableColumn),
                render: ({ median_dvt }: PoiEntity) => {
                    const parseValue = parseMetricValue(median_dvt, 'median_dvt');
                    const displayValue =
                        parseValue === STRING_FOR_NULL_DATA_TO_DISPLAY
                            ? parseValue
                            : `${parseValue} min`;
                    return <ValueCell values={[displayValue]} />;
                },
            });
        }
        return tableColumns;
    }, [
        getSortingProps,
        hasEmptySpacePermission,
        matchScoreCalculation?.is_calculated,
        matchScoreCalculation?.min_results,
    ]);
};

export const useVisibleValueColumnsList = () => {
    const valueColumnsList = useValueColumnsList();
    return useMemo(() => {
        return valueColumnsList.filter((column) => column.visible !== false);
    }, [valueColumnsList]);
};

export const useRankingTableColumns = (columns?: RankingTableColumn[]) => {
    const columnsList = useVisibleValueColumnsList();

    return columns
        ? [...columns.map((columnName) => columnsList.find(({ key }) => columnName === key)!)]
        : columnsList;
};
