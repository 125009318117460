import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'store/store';
import { AppUiState } from 'store/app-ui/state/app-ui-state';

const selectAppUiState = (state: AppState): AppUiState => {
    return state.appUi;
};

export const selectUpgradeYourPlanPopupScreen = createSelector(
    selectAppUiState,
    (state) => state.upgradeYourPlanPopupScreen,
);

export const selectUpgradeYourPlanPopupParams = createSelector(
    selectAppUiState,
    (state) => state.upgradeYourPlanPopupParams,
);

export const selectUpgradeYourPlanPopupIsVisible = createSelector(
    selectUpgradeYourPlanPopupScreen,
    (screenName) => !!screenName,
);

export const selectIsSidePanelExpanded = createSelector(
    selectAppUiState,
    (state) => state.sidebarExpanded,
);

export const selectKeyValue = (state: AppState, key: string) => {
    return state.appUi[key];
};
