import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { selectAuthUserAccount } from 'store/selectors/app-selectors';
import { usePoiSelectionContext } from 'features/poi-selection-map/context/poi-selection-context';
import { getAllPropertiesValues } from 'utils/property-utils/property-utils';
import { useDefaultComparisonDates } from 'hooks/use-default-comparison-dates/use-default-comparison-dates';
import { PropertyDataUIModel } from 'types/properties-data';
import { PORTFOLIO_ITEM_ID } from 'features/poi-selection-map/poi-selection-menu/menu-items/portfolio-menu-item/portfolio-menu-item';
import { useRetailSalesPermissions } from 'hooks/use-retail-sales-permissions/use-retail-sales-permissions';
import { useMyZonePropertiesPermissions } from 'features/my-zone-properties/hooks/use-my-zone-properties-permissions';
import type { Place, PlacerEntityWrapper } from '@placer-ui/types';

const convertMetricsToOverview = (input: PropertyDataUIModel): PlacerEntityWrapper<Place> => {
    return {
        info: input.info,
        overview: {
            estimated_foottraffic: input.data.metrics.FT?.value ?? undefined,
        },
    };
};

export const useGetAllProperties = () => {
    const { setPoisList, setResultsPagination, resultsPagination } = usePoiSelectionContext();
    const account = useSelector(selectAuthUserAccount);
    const dates = useDefaultComparisonDates();
    const {
        hasRetailSalesPermission: hasRetailSalesForTenantsPermission,
        hasTenantsSalesRankingMetric,
    } = useRetailSalesPermissions();
    const { hasEnabledShareMetrics } = useMyZonePropertiesPermissions();

    return useCallback(async () => {
        if (!dates) return;
        const combinedNormalizedData = await getAllPropertiesValues({
            dates,
            id: account?.property_tag_id,
            hasSalesPermission: hasRetailSalesForTenantsPermission,
            hasSalesInMainApiPermission: hasTenantsSalesRankingMetric,
            hasShareMetricsPermission: hasEnabledShareMetrics,
        });

        const poiList = combinedNormalizedData.map<PlacerEntityWrapper<Place>>((propertyData) =>
            convertMetricsToOverview(propertyData),
        );
        setResultsPagination({
            ...resultsPagination,
            totalResults: poiList.length,
        });
        setPoisList({
            pois: poiList,
            menuItemId: PORTFOLIO_ITEM_ID,
        });
    }, [
        account?.property_tag_id,
        dates,
        hasEnabledShareMetrics,
        hasRetailSalesForTenantsPermission,
        hasTenantsSalesRankingMetric,
        resultsPagination,
        setPoisList,
        setResultsPagination,
    ]);
};
