import { createSlice } from '@reduxjs/toolkit';

import { chainsReportChainsEntityAdapter } from '../chains-report-chains-entity-adapter';
import {
    removeReportChainsByIds,
    setReportChainsAtChains,
    updateChainFilters,
} from '../actions';
import { PLACE_COLORS } from 'core/constants/place-colors';

const initialState = chainsReportChainsEntityAdapter.getInitialState();

export const chainsReportChainsSlice = createSlice({
    name: 'report',
    initialState: initialState,
    reducers: {
        resetState: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(setReportChainsAtChains, (state, action) => {
                return chainsReportChainsEntityAdapter.setAll(
                    state,
                    action.payload,
                );
            })
            .addCase(removeReportChainsByIds, (state, action) => {
                const newChainsEntityModels = chainsReportChainsEntityAdapter.removeMany(
                    state,
                    action.payload,
                );

                const updates = Object.entries(
                    newChainsEntityModels.entities,
                ).map(([key], i) => ({
                    id: key,
                    changes: {
                        color: PLACE_COLORS.main[i],
                    },
                }));

                chainsReportChainsEntityAdapter.updateMany(
                    newChainsEntityModels,
                    updates,
                );

                return newChainsEntityModels;
            })
            .addCase(updateChainFilters, (state, action) => {
                return chainsReportChainsEntityAdapter.updateMany(
                    state,
                    action.payload,
                );
            });
    },
});

export const chainsReducer = chainsReportChainsSlice.reducer;
