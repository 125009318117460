import type { Attributes, DateLabels } from '@placer-ui/types';

export type ChainUrl = {
    id: string;
    date_end?: string;
    date_start?: string;
    addPurchasedCompetitor?: boolean;
    date_label?: DateLabels;
    notInReport?: boolean;
    attributes?: Attributes;
};

export const createChainUrl = (properties: Partial<ChainUrl> & { id: string }) => ({
    id: properties.id,
    date_end: properties.date_end,
    date_start: properties.date_start,
    date_label: properties.date_label,
    addPurchasedCompetitor: properties.addPurchasedCompetitor,
    notInReport: properties.notInReport,
    attributes: properties.attributes,
});
