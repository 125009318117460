import type { BaseUserAuth } from '@placer-ui/types';

import { userCookieStorage, impersonateUserCookieStorage } from 'core/authentification';
import { saveSignedInUser } from 'store/auth/utils/utils';
import { goToAdminRedirect } from 'router/go-to';
import { useAuthActions } from 'store/auth/use-auth-actions';
import { useCallback } from 'react';

export const useChangeUserInLoginasMode = () => {
    const { impersonateAuthLogout } = useAuthActions();
    // the original user is the one saved in "impersonate cookie", and if it is empty
    // then obviously it is the current logged in user.
    const originalUser = impersonateUserCookieStorage.user || userCookieStorage.user!;

    return useCallback(
        (impersonatedUser: BaseUserAuth, removeImpersonateUserCookie = false) => {
            // reset everything asynchronously
            impersonateAuthLogout();

            // after `impersonateAuthLogout` dispatched everything, let's handle setting new cookies
            setTimeout(() => {
                if (removeImpersonateUserCookie) {
                    impersonateUserCookieStorage.removeUser();
                } else {
                    // save the original user in the impersonateUser Cookie
                    impersonateUserCookieStorage.setUser(originalUser);
                }
                // save the user we want to impersonate in the main User Cookie
                saveSignedInUser(impersonatedUser);
                goToAdminRedirect();
            }, 1);
        },
        [impersonateAuthLogout, originalUser],
    );
};
