export const GENERATE_CUSTOM_COORDINATE_REPORT =
    'Generating your report.\n This may take a few seconds.';

export const COORDINATE_IN_RESTRICTED_AREA =
    'Report unavailable. Coordinates overlap with a restricted area. Select different coordinates.';

export const COORDINATE_IN_RESTRICTED_AREA_MOBILE =
    'Coordinates overlap with a restricted area. Select different coordinates.';

export const ADDRESS_IN_RESTRICTED_AREA =
    'Report unavailable. Location overlap with a restricted area. Select different location.';

export const ADDRESS_IN_RESTRICTED_AREA_MOBILE =
    'Location overlaps with a restricted area. Please select a different location.';

export const ADDRESS_NOT_DELIVERABLE_ERROR =
    'Report unavailable. This address widely overlaps with a restricted area. Select a different address.';

export const COORDINATE_OUTSIDE_OF_US_ERROR = 'Polygon is outside US';
export const COORDINATE_NOT_DELIVERABLE_ERROR =
    'Report unavailable. This location widely overlaps with a restricted area. Select a different location.';
export const COORDINATE_OUTSIDE_OF_US_TOOLTIP_TEXT =
    'This area is outside the USA. Placer only supports data within the USA.';
export const COORDINATE_GENERAL_ERROR_TOOLTIP =
    // eslint-disable-next-line quotes
    "Can't generate a report for this location. Please contact support if you need assistance.";
