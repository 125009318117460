import React, {
    forwardRef,
    Ref,
    useEffect,
    useRef,
    useState,
    KeyboardEvent,
    MouseEvent,
    ComponentProps,
    ReactNode,
    useCallback,
    CSSProperties,
} from 'react';
import { noop } from 'lodash/fp';
import { AutoComplete as AntAutoComplete } from 'antd';
import { KeyboardKey } from 'utils/keyboard/keyboard_key';
import type { Dictionary } from '@placer-ui/types';
import { Primitive } from 'types/primitive';
import { LoadingBar } from 'ui-components/loading-bar/loading-bar';
import { ClearSearchIcon, SearchIcon } from 'components/assets/Icons/Icons';
import classnames from 'classnames';
import styles from './auto-complete.module.scss';

export type AutoCompleteMenuOptionData<T> = {
    value: string;
    payload?: T;
    component: JSX.Element;
    optionUniqueKey?: string;
    disabled?: boolean;
};

export type AutoCompleteProps<T> = {
    isLoading?: boolean;
    disabled?: boolean;
    placeholder?: string | ReactNode;
    value: string;

    style?: CSSProperties;
    searchIconStyle?: CSSProperties;
    className?: string;
    dropdownClassName?: string;
    listHeight?: number;
    onCNP?: (value: string) => void;
    autoFocus?: boolean;
    onFocus?: () => void;
    onBlur?: () => void;
    notFoundContent?: React.ReactNode;
    onChange: (newValue: string, option: any) => void;
    onSelect?: (optionPayload: T) => void;
    onEnter?: (optionPayload: T, event: KeyboardEvent) => void;
    onClear?: () => void;
    onClick?: (optionPayload: T, event: MouseEvent) => void;
    onInputKeyDown?: ComponentProps<typeof AntAutoComplete>['onInputKeyDown'];

    options: AutoCompleteMenuOptionData<T>[];
    getPopupContainer?: (triggerNode?: HTMLElement) => HTMLElement;
    dropdownAlign?: Dictionary<Primitive | Primitive[]>;
    autoCompleteScope?: string;
    footer?: ReactNode;
    autoCompleteWrapperClass?: string;
    searchAndClearIconClassName?: string;
    id?: string;
    ignoreInputIcon?: boolean;
    zIndex?: number;
    open?: boolean;
    loadingBarClassName?: string;
    dropdownMatchSelectWidth?: boolean | number;
    inputId?: string;
    allowSameStringSelectedOption?: boolean;
};

export const AutoComplete = forwardRef(
    (
        {
            id,
            isLoading,
            disabled,
            placeholder,
            value,
            style,
            searchIconStyle,
            className,
            dropdownClassName,
            listHeight,
            autoFocus,
            onFocus = noop,
            onChange,
            onCNP,
            onBlur = noop,
            notFoundContent,
            onSelect = noop,
            onClear = noop,
            onEnter = noop,
            onClick = noop,
            onInputKeyDown,
            options,
            getPopupContainer,
            dropdownAlign,
            autoCompleteScope,
            footer,
            autoCompleteWrapperClass,
            searchAndClearIconClassName,
            ignoreInputIcon,
            zIndex = 23,
            open,
            loadingBarClassName,
            dropdownMatchSelectWidth,
            inputId,
            allowSameStringSelectedOption,
        }: AutoCompleteProps<any>,
        ref: Ref<any>,
    ) => {
        const [focused, setFocused] = useState(false);
        const selectedPayload = useRef();
        const setSelectedPayload = (payload: any) => {
            selectedPayload.current = payload;
        };

        /*
         * We need this workaround because as a requirement we need to have different actions
         * for "select by click" and select by "keyboard-enter",
         * but autocomplete doesn't differentiate this type of events
         *
         * OLD ENTER behaviour: onSelect -> onEnter (2 events with this order)
         * NEW ENTER behaviour: onEnter
         *
         * */
        useEffect(() => {
            if (!selectedPayload.current) {
                return;
            }

            onSelect(selectedPayload.current);
            setSelectedPayload(null);

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [selectedPayload.current]);

        const onOptionSelect = (value: string, option: any) => {
            setSelectedPayload(option.payload);
            if (allowSameStringSelectedOption && selectedPayload.current === option.payload) {
                onSelect(selectedPayload.current);
                setSelectedPayload(null);
            }
        };

        const iconOnTheRight = value ? (
            <div onClick={onClear}>
                <ClearSearchIcon
                    className={classnames(styles.clearSearchIcon, searchAndClearIconClassName)}
                    style={searchIconStyle}
                />
            </div>
        ) : (
            <SearchIcon
                className={classnames(styles.searchIcon, searchAndClearIconClassName)}
                style={searchIconStyle}
            />
        );

        const onEnterAction = (event: KeyboardEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement | HTMLDivElement;
            const hasActiveItem = target.nodeName !== 'DIV' && selectedPayload.current;

            if (hasActiveItem) {
                onSelect(selectedPayload.current);
            } else {
                onEnter(selectedPayload.current, event);
            }

            setSelectedPayload(null);
        };
        const onEscapeAction = () => {
            setSelectedPayload(null);
            onClear();
        };

        const keyBindings = {
            [KeyboardKey.ENTER.key]: onEnterAction,
            [KeyboardKey.ESC.key]: onEscapeAction,
        };

        const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
            const keydownAction = keyBindings[event.key];
            keydownAction && keydownAction(event);
        };

        const onAutoCompleteFocus = () => {
            setFocused(true);
            onFocus();
        };

        const onAutoCompleteBlur = () => {
            setFocused(false);
            onBlur();
        };

        const shouldDisplayLoadingBar = isLoading && focused;

        const handlePaste = useCallback(
            (e: Event) => {
                const event = e as ClipboardEvent;
                event.clipboardData?.getData('text') &&
                    onCNP?.(event.clipboardData.getData('text'));
            },
            [onCNP],
        );

        const handleContextMenu = useCallback(
            (e: Event) => {
                const event = e as ClipboardEvent;
                e.preventDefault();

                event.clipboardData?.getData('text') &&
                    onCNP?.(event.clipboardData.getData('text'));
            },
            [onCNP],
        );

        useEffect(() => {
            const inputElement = document.querySelector(
                `#${id} .ant-select-selection-search-input`,
            );

            if (inputElement && onCNP) {
                // Handle the "on paste" event
                inputElement.addEventListener('paste', handlePaste);

                // Handle the "on right mouse click paste" event
                inputElement.addEventListener('contextmenu', handleContextMenu);

                return () => {
                    inputElement.removeEventListener('paste', handlePaste);
                    inputElement.removeEventListener('contextmenu', handleContextMenu);
                };
            }
        }, [handleContextMenu, handlePaste, id, onCNP]);

        return (
            <div
                className={classnames(styles.autoCompleteWrapper, autoCompleteWrapperClass)}
                id={id}
            >
                <AntAutoComplete
                    id={inputId}
                    onInputKeyDown={onInputKeyDown}
                    onFocus={onAutoCompleteFocus}
                    onBlur={onAutoCompleteBlur}
                    onSelect={onOptionSelect}
                    onKeyDown={onKeyDown}
                    onChange={onChange}
                    ref={ref}
                    placeholder={placeholder}
                    autoFocus={autoFocus}
                    disabled={disabled}
                    value={value}
                    getPopupContainer={getPopupContainer}
                    style={style}
                    defaultOpen={false}
                    className={className}
                    notFoundContent={notFoundContent}
                    dropdownClassName={dropdownClassName}
                    dropdownMatchSelectWidth={dropdownMatchSelectWidth}
                    dropdownStyle={{
                        zIndex,
                    }}
                    open={open}
                    data-testid="open-autosuggest-trigger"
                    dropdownAlign={dropdownAlign}
                    listHeight={listHeight}
                >
                    {options &&
                        options.map((option, i) => {
                            const {
                                value: optionValue,
                                payload: optionPayload,
                                component: optionComponent,
                                disabled,
                            } = option;

                            const uniqueKey = option.optionUniqueKey || `${optionValue}__${i}`;

                            return (
                                <AntAutoComplete.Option
                                    value={uniqueKey}
                                    key={uniqueKey}
                                    payload={optionPayload}
                                    disabled={disabled}
                                >
                                    {optionComponent}
                                </AntAutoComplete.Option>
                            );
                        })}
                    {footer && options && (
                        <AntAutoComplete.Option
                            value=""
                            key="footer"
                            className={styles.footerItem}
                            disabled
                        >
                            {footer}
                        </AntAutoComplete.Option>
                    )}
                </AntAutoComplete>

                {!ignoreInputIcon && iconOnTheRight}

                {shouldDisplayLoadingBar && (
                    <LoadingBar
                        className={classnames(styles.autoCompleteProgress, loadingBarClassName)}
                    />
                )}
            </div>
        );
    },
);
