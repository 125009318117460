import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

import { IconProps } from 'components/assets/Icons/Icons';

/* eslint-disable max-len */
const EthnicityIconSvg = (props: CustomIconComponentProps | SVGProps<SVGSVGElement>) => (
    <svg {...props} viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g
                id="Chain-Compatability-Analysis-3"
                transform="translate(-952.000000, -1371.000000)"
                stroke="#49565D"
                strokeWidth="0.75"
            >
                <g id="Group-3-Copy-2" transform="translate(40.000000, 845.000000)">
                    <g id="Group-68" transform="translate(912.000000, 524.000000)">
                        <g id="Icons_18/Blue/Ethnicity" transform="translate(0.000000, 2.000000)">
                            <path
                                d="M12.625,6.875 L12.625,16 C12.625,16.4487314 12.4431157,16.8549814 12.1490485,17.1490485 C11.8549814,17.4431157 11.4487314,17.625 11,17.625 L11,17.625 L7,17.625 C6.55126864,17.625 6.14501864,17.4431157 5.85095148,17.1490485 C5.55688432,16.8549814 5.375,16.4487314 5.375,16 L5.375,16 L5.375,6.875 L12.625,6.875 Z"
                                id="Rectangle-Copy-3"
                                fill="#FFFFFF"
                                transform="translate(9.000000, 12.250000) scale(1, -1) translate(-9.000000, -12.250000) "
                            />
                            <path
                                d="M7.625,14.875 L7.625,16 C7.625,16.4487314 7.44311568,16.8549814 7.14904852,17.1490485 C6.85498136,17.4431157 6.44873136,17.625 6,17.625 L6,17.625 L2,17.625 C1.55126864,17.625 1.14501864,17.4431157 0.850951481,17.1490485 C0.55688432,16.8549814 0.375,16.4487314 0.375,16 L0.375,16 L0.375,14.875 L7.625,14.875 Z"
                                id="Rectangle-Copy"
                                fill="#FFFFFF"
                                transform="translate(4.000000, 16.250000) scale(1, -1) translate(-4.000000, -16.250000) "
                            />
                            <path
                                d="M17.625,14.875 L17.625,16 C17.625,16.4487314 17.4431157,16.8549814 17.1490485,17.1490485 C16.8549814,17.4431157 16.4487314,17.625 16,17.625 L16,17.625 L12,17.625 C11.5512686,17.625 11.1450186,17.4431157 10.8509515,17.1490485 C10.5568843,16.8549814 10.375,16.4487314 10.375,16 L10.375,16 L10.375,14.875 L17.625,14.875 Z"
                                id="Rectangle-Copy-2"
                                fill="currentColor"
                                transform="translate(14.000000, 16.250000) scale(1, -1) translate(-14.000000, -16.250000) "
                            />
                            <path
                                d="M1.5,11 L6.5,11 L6.5,13.5 C6.5,14.8807119 5.38071187,16 4,16 C2.61928813,16 1.5,14.8807119 1.5,13.5 L1.5,11 L1.5,11 Z"
                                id="Rectangle"
                                fill="currentColor"
                            />
                            <path
                                d="M6.5,2.38 L11.5,2.38 L11.5,5.38 C11.5,6.76071187 10.3807119,7.88 9,7.88 C7.61928813,7.88 6.5,6.76071187 6.5,5.38 L6.5,2.38 L6.5,2.38 Z"
                                id="Rectangle-Copy-5"
                                fill="#FFFFFF"
                            />
                            <path
                                d="M7.6004197,9 L1.88,9 C1.05157288,9 0.38,9.67157288 0.38,10.5 L0.38,13 C0.38,13.5522847 0.82771525,14 1.38,14 L1.51255836,14 L1.51255836,14 L1.51255836,11.4123078 L6.3878044,11.4123078 C7.05780974,11.4123078 7.6004197,10.8480401 7.6004197,10.1528567 L7.6004197,9 Z"
                                id="Fill-37"
                                fill="#FFFFFF"
                            />
                            <path
                                d="M12.6004197,0.38 L6.88,0.38 C6.05157288,0.38 5.38,1.05157288 5.38,1.88 L5.38,4.38 C5.38,4.93228475 5.82771525,5.38 6.38,5.38 L6.51255836,5.38 L6.51255836,5.38 L6.51255836,2.79230785 L11.3878044,2.79230785 C12.0578097,2.79230785 12.6004197,2.22804013 12.6004197,1.53285671 L12.6004197,0.38 Z"
                                id="Fill-37-Copy-2"
                                fill="currentColor"
                            />
                            <path
                                d="M11.5,11 L16.5,11 L16.5,13.5 C16.5,14.8807119 15.3807119,16 14,16 C12.6192881,16 11.5,14.8807119 11.5,13.5 L11.5,11 L11.5,11 Z"
                                id="Rectangle-Copy-6"
                                fill="#FFFFFF"
                            />
                            <path
                                d="M17.6004197,9 L11.88,9 C11.0515729,9 10.38,9.67157288 10.38,10.5 L10.38,13 C10.38,13.5522847 10.8277153,14 11.38,14 L11.5125584,14 L11.5125584,14 L11.5125584,11.4123078 L16.3878044,11.4123078 C17.0578097,11.4123078 17.6004197,10.8480401 17.6004197,10.1528567 L17.6004197,9 Z"
                                id="Fill-37-Copy"
                                fill="#FFFFFF"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export const EthnicityIcon = (props: IconProps) => <Icon component={EthnicityIconSvg} {...props} />;
EthnicityIcon.__tags = ['ethnicity', 'heads', 'three people'] as string[];
