/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

export type LoginAsIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type LoginAsIconProps = SVGProps<SVGSVGElement> & LoginAsIconUniqueProps;

export const LoginAsIcon = function LoginAsIcon({
    width = 18,
    height = 18,
    ...props
}: LoginAsIconProps) {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_2119_205638)">
                <rect width="18" height="18" fill="#f9f8f7" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.31867 12.9404L5.6106 15.6484L7.27904 17.3169L7.85985 16.7361L7.1084 15.9846L8.65453 14.4385L9.40598 15.1899L9.98711 14.6088L8.31867 12.9404Z"
                    fill="currentColor"
                />
                <path
                    d="M5.6106 15.6484L5.34543 15.3833L5.08027 15.6484L5.34543 15.9136L5.6106 15.6484ZM8.31867 12.9404L8.58383 12.6752L8.31867 12.41L8.0535 12.6752L8.31867 12.9404ZM7.27904 17.3169L7.01387 17.582L7.27904 17.8472L7.5442 17.582L7.27904 17.3169ZM7.85985 16.7361L8.12502 17.0012L8.39018 16.7361L8.12502 16.4709L7.85985 16.7361ZM7.1084 15.9846L6.84323 15.7195L6.57807 15.9846L6.84323 16.2498L7.1084 15.9846ZM8.65453 14.4385L8.91969 14.1733L8.65453 13.9082L8.38936 14.1733L8.65453 14.4385ZM9.40598 15.1899L9.14081 15.4551L9.40598 15.7203L9.67114 15.4551L9.40598 15.1899ZM9.98711 14.6088L10.2523 14.874L10.5174 14.6088L10.2523 14.3436L9.98711 14.6088ZM5.87576 15.9136L8.58383 13.2055L8.0535 12.6752L5.34543 15.3833L5.87576 15.9136ZM7.5442 17.0517L5.87576 15.3833L5.34543 15.9136L7.01387 17.582L7.5442 17.0517ZM7.59469 16.4709L7.01387 17.0517L7.5442 17.582L8.12502 17.0012L7.59469 16.4709ZM8.12502 16.4709L7.37356 15.7195L6.84323 16.2498L7.59469 17.0012L8.12502 16.4709ZM7.37356 16.2498L8.91969 14.7037L8.38936 14.1733L6.84323 15.7195L7.37356 16.2498ZM8.38936 14.7037L9.14081 15.4551L9.67114 14.9248L8.91969 14.1733L8.38936 14.7037ZM9.72195 14.3436L9.14081 14.9248L9.67114 15.4551L10.2523 14.874L9.72195 14.3436ZM8.0535 13.2055L9.72195 14.874L10.2523 14.3436L8.58383 12.6752L8.0535 13.2055Z"
                    fill="currentColor"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.8314 2.12547C16.5454 3.83944 16.5647 6.59896 14.8747 8.28901C13.5558 9.60787 11.5857 9.88573 9.96942 9.12967L3.88165 15.2174C3.49113 15.608 2.85796 15.608 2.46744 15.2174L2.06903 14.819C1.67851 14.4285 1.67851 13.7953 2.06903 13.4048L8.05754 7.41631C7.02939 5.73261 7.22977 3.52023 8.66784 2.08216C10.3579 0.392104 13.1174 0.411493 14.8314 2.12547ZM13.3418 3.61487C14.2216 4.49461 14.2315 5.911 13.3641 6.77846C12.4966 7.64593 11.0802 7.63598 10.2005 6.75624C9.32072 5.8765 9.31077 4.46011 10.1782 3.59265C11.0457 2.72518 12.4621 2.73514 13.3418 3.61487Z"
                    fill="#D0D0D0"
                />
                <path
                    d="M9.96942 9.12967L10.1283 8.79L9.89016 8.6786L9.70426 8.86451L9.96942 9.12967ZM3.88165 15.2174L3.61649 14.9523L3.88165 15.2174ZM2.06903 13.4048L2.3342 13.67L2.06903 13.4048ZM8.05754 7.41631L8.3227 7.68148L8.53141 7.47278L8.37759 7.22088L8.05754 7.41631ZM15.1399 8.55418C16.9799 6.71417 16.9534 3.7172 15.0965 1.8603L14.5662 2.39063C16.1373 3.96169 16.1496 6.48374 14.6095 8.02385L15.1399 8.55418ZM9.81053 9.46935C11.5627 10.289 13.704 9.99004 15.1399 8.55418L14.6095 8.02385C13.4077 9.22569 11.6087 9.4825 10.1283 8.79L9.81053 9.46935ZM9.70426 8.86451L3.61649 14.9523L4.14682 15.4826L10.2346 9.39484L9.70426 8.86451ZM3.61649 14.9523C3.37241 15.1964 2.97668 15.1964 2.73261 14.9523L2.20228 15.4826C2.73925 16.0196 3.60985 16.0196 4.14682 15.4826L3.61649 14.9523ZM2.73261 14.9523L2.3342 14.5539L1.80387 15.0842L2.20228 15.4826L2.73261 14.9523ZM2.3342 14.5539C2.09012 14.3098 2.09012 13.9141 2.3342 13.67L1.80387 13.1397C1.2669 13.6766 1.2669 14.5472 1.80387 15.0842L2.3342 14.5539ZM2.3342 13.67L8.3227 7.68148L7.79237 7.15115L1.80387 13.1397L2.3342 13.67ZM8.40267 1.817C6.83699 3.38268 6.62296 5.78659 7.73749 7.61175L8.37759 7.22088C7.43582 5.67863 7.62256 3.65777 8.933 2.34733L8.40267 1.817ZM15.0965 1.8603C13.2397 0.00340648 10.2427 -0.0230078 8.40267 1.817L8.933 2.34733C10.4731 0.807216 12.9952 0.819579 14.5662 2.39063L15.0965 1.8603ZM13.6292 7.04363C14.6466 6.02622 14.6296 4.37237 13.607 3.34971L13.0767 3.88004C13.8135 4.61686 13.8164 5.79578 13.0989 6.5133L13.6292 7.04363ZM9.9353 7.0214C10.958 8.04406 12.6118 8.06104 13.6292 7.04363L13.0989 6.5133C12.3814 7.23081 11.2024 7.22789 10.4656 6.49107L9.9353 7.0214ZM9.91307 3.32748C8.89566 4.34489 8.91263 5.99874 9.9353 7.0214L10.4656 6.49107C9.72881 5.75425 9.72588 4.57533 10.4434 3.85781L9.91307 3.32748ZM13.607 3.34971C12.5843 2.32705 10.9305 2.31007 9.91307 3.32748L10.4434 3.85781C11.1609 3.1403 12.3398 3.14322 13.0767 3.88004L13.607 3.34971Z"
                    fill="#49565D"
                />
            </g>
            <defs>
                <clipPath id="clip0_2119_205638">
                    <rect width="18" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
LoginAsIcon.__tags = ['login as sign key login as support user'] as string[];
