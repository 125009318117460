import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type {
    ReportEntitiesResponse,
    ReportInfo,
    ScoreConfig,
    ShortenedReportInfo,
    TTAMetadataResponse,
} from 'features/site-selection/common/types/server-responses';
import type { SharedReport } from 'core/entities/share-report/share-report';
import type { SiteSelectionUi } from 'features/site-selection/common/types/ui';
import { SIS_PERMANENT_FILTERS } from 'features/site-selection/common/constants/site-selection-filters';
import type { DeleteReport } from 'features/site-selection/common/types/reports-table';
import type { MapData, SiteSelectionChainVenues } from 'features/site-selection/common/types/map';
import type {
    FilterUserPreferences,
    FilterUserPreferencesMap,
} from 'features/site-selection/common/types/filters/filters-user-preferences';
import type {
    FilterConfigs,
    FilterConfigsMap,
} from 'features/site-selection/common/types/filters/filters-configuration';
import type {
    RegionProperties,
    SiteSelectionArea,
    TopMatchesPageState,
    TopSiteInfo,
    MatchAnalysisPageState,
    SitesTypes,
    TrafficLayerConfiguration,
    TrafficLayerViewState,
    MosaicConfigsState,
    ReportAccessWithError,
} from 'features/site-selection/sections/top-matches-page/types';
import type { GeometryTypes } from 'ui-components/google-map/components/geometry/geometry';
import { SiteSelectionEntity } from 'features/site-selection/common/types/entities';
import { VolumeSegmentFeature } from 'shared/types/traffic';
import { TrafficLayerActiveTab } from 'features/site-selection/sections/top-matches-page/top-matches-side-panel/components/traffic-side-panel/tabs/constants';
import {
    defaultTrafficLayerConfigs,
    defaultTrafficLayerViewState,
} from 'features/site-selection/common/constants/layers';
import { includeExcludeTenantsMilesOptions } from 'features/site-selection/sections/top-matches-page/top-matches-filters/constants';
import type { Dictionary, Place } from '@placer-ui/types';

export interface SiteSelectionState {
    reportInfo: ReportInfo | null;
    reportSelectionPage: {
        recentReports: ShortenedReportInfo[] | null;
        sharedWithMeReports: SharedReport[] | null;
        deleteReport: DeleteReport;
    };
    topMatchesPage: TopMatchesPageState;
    matchAnalysisPage: MatchAnalysisPageState;
    ui: SiteSelectionUi;
    mapData: MapData;
}

export const initMatchAnalysisPageState: MatchAnalysisPageState = {
    siteInfo: undefined,
    siteState: undefined,
    entities: {},
    entities_ids: [],
};

const sitesSubSection = {
    records: null,
    filteredRecords: null,
    totalNumOfSites: 0,
    isLoading: false,
};
export const initialTopMatchesState: TopMatchesPageState = {
    topSites: {
        records: null,
        totalNumOfSites: 0,
        isLoading: false,
    },
    savedSites: sitesSubSection,
    addedSites: {
        ...sitesSubSection,
        newCreatedAddedSite: [],
    },
    filtersState: {
        isFilterSectionOpen: false,
        isLoadingFiltersConfigs: false,
        isInitialFiltersConfigsLoaded: false,
        // mosaic will not show anything if no categories are selected
        mosaicBaseConfigsState: 'uninitialized',
        mosaicHistogramConfigsState: 'uninitialized',
    },
    interactions: {
        selectedRegion: undefined,
    },
    filtersConfigs: {
        categories: {
            id: 'categories',
            type: 'checkboxes',
            options: {}, // should be filled on load
        },
        include_tenants: {
            id: 'include_tenants',
            type: 'chains_and_distance_selector',
            options: includeExcludeTenantsMilesOptions,
        },
        exclude_tenants: {
            id: 'exclude_tenants',
            type: 'chains_and_distance_selector',
            options: includeExcludeTenantsMilesOptions,
        },
        include_tenants_v2: {
            id: 'include_tenants_v2',
            type: 'chains_and_distance_selector_v2',
            options: includeExcludeTenantsMilesOptions,
            selectedChains: [],
            traitSetupStatus: undefined,
        },
        exclude_tenants_v2: {
            id: 'exclude_tenants_v2',
            type: 'chains_and_distance_selector_v2',
            options: includeExcludeTenantsMilesOptions,
            selectedChains: [],
            traitSetupStatus: undefined,
        },
        minimal_match_score: {
            id: 'minimal_match_score',
            type: 'select_range',
            unit: 'grades',
            min_value: 0,
            max_value: 100,
        },
        mosaic: {
            id: 'mosaic',
            type: 'mosaic',
            template: 'hh_default',
            attribute: 'Households',
            selected_categories: [],
            values: [],
            min_value: 0,
            max_value: 0,
        },
    },
    viewStates: {
        sites: {
            viewType: 'site_config',
            version: 1,
            state: {},
        },
        layers: {
            viewType: 'layer_config',
            version: 1,
            state: { traffic: defaultTrafficLayerViewState },
        },
    },
    chainVenues: undefined,
    tta: {
        enableSiteTTA: true,
        enableChainVenuesTTA: true,
    },
    selectedChainVenue: undefined,
    siteOverview: undefined,
    selectedRestrictedSite: undefined,
    mapSearchedEntity: undefined,
    subSectionViewing: 'all',
    trafficLayer: {
        active: false,
        hidden: true,
        activeTab: TrafficLayerActiveTab.configuration,
        selectedSegments: [],
        configuration: defaultTrafficLayerConfigs,
    },
    scoreConfig: {},
    isBlocked: false,
    sitesListPosition: 0,
    isDropPinMode: false,
};

export const initialState: SiteSelectionState = {
    reportInfo: null,
    reportSelectionPage: {
        recentReports: null,
        sharedWithMeReports: null,
        deleteReport: {
            name: undefined,
            reportType: 'recent',
            reportId: undefined,
        },
    },
    topMatchesPage: initialTopMatchesState,
    matchAnalysisPage: initMatchAnalysisPageState,
    ui: {
        elementRectangles: {
            map: undefined,
            menu: undefined,
        },
        reportsPage: {
            headerMapExpanded: false,
        },
        currentState: 'inactive',
    },
    mapData: {
        mapPosition: undefined,
        mapConfig: undefined,
    },
};

export const initialSiteSelection: SiteSelectionState = initialState;

export const siteSelectionSlice = createSlice({
    name: 'site_selection',
    initialState: initialSiteSelection,
    reducers: {
        resetState: () => {
            return initialSiteSelection;
        },
        setReportInfo: (state: SiteSelectionState, action: PayloadAction<ReportInfo | null>) => {
            state.reportInfo = action.payload;
        },
        setReportToDelete: (state: SiteSelectionState, action: PayloadAction<DeleteReport>) => {
            state.reportSelectionPage.deleteReport = action.payload;
        },
        setRecentReports: (
            state: SiteSelectionState,
            action: PayloadAction<ShortenedReportInfo[] | null>,
        ) => {
            state.reportSelectionPage.recentReports = action.payload;
        },
        setSharedWithMeReports: (
            state: SiteSelectionState,
            action: PayloadAction<SharedReport[] | null>,
        ) => {
            state.reportSelectionPage.sharedWithMeReports = action.payload;
        },
        setTopSitesRecords: (state: SiteSelectionState, action: PayloadAction<TopSiteInfo[]>) => {
            state.topMatchesPage.topSites.records = action.payload;
        },
        setSavedSitesRecords: (
            state: SiteSelectionState,
            action: PayloadAction<{ sites: TopSiteInfo[]; filtered_sites: TopSiteInfo[] }>,
        ) => {
            state.topMatchesPage.savedSites.records = action.payload.sites;
            state.topMatchesPage.savedSites.filteredRecords = action.payload.filtered_sites;
        },
        setAddedSitesRecords: (
            state: SiteSelectionState,
            action: PayloadAction<{ sites: TopSiteInfo[]; filtered_sites: TopSiteInfo[] }>,
        ) => {
            state.topMatchesPage.addedSites.records = action.payload.sites;
            state.topMatchesPage.addedSites.filteredRecords = action.payload.filtered_sites;
        },
        setTopSitesTotalNum: (state: SiteSelectionState, action: PayloadAction<number>) => {
            state.topMatchesPage.topSites.totalNumOfSites = action.payload;
        },
        setAddedSitesTotalNum: (state: SiteSelectionState, action: PayloadAction<number>) => {
            state.topMatchesPage.addedSites.totalNumOfSites = action.payload;
        },
        setSavedSitesTotalNum: (state: SiteSelectionState, action: PayloadAction<number>) => {
            state.topMatchesPage.savedSites.totalNumOfSites = action.payload;
        },
        setTopSitesIsLoading: (state: SiteSelectionState, action: PayloadAction<boolean>) => {
            state.topMatchesPage.topSites.isLoading = action.payload;
        },
        setSavedSitesIsLoading: (state: SiteSelectionState, action: PayloadAction<boolean>) => {
            state.topMatchesPage.savedSites.isLoading = action.payload;
        },
        setAddedSitesIsLoading: (state: SiteSelectionState, action: PayloadAction<boolean>) => {
            state.topMatchesPage.addedSites.isLoading = action.payload;
        },
        setIsLoadingFiltersConfigs: (state: SiteSelectionState, action: PayloadAction<boolean>) => {
            state.topMatchesPage.filtersState.isLoadingFiltersConfigs = action.payload;
        },
        setIsInitialFiltersConfigsLoaded: (
            state: SiteSelectionState,
            action: PayloadAction<boolean>,
        ) => {
            state.topMatchesPage.filtersState.isInitialFiltersConfigsLoaded = action.payload;
        },
        setMosaicBaseConfigsState: (
            state: SiteSelectionState,
            action: PayloadAction<MosaicConfigsState>,
        ) => {
            state.topMatchesPage.filtersState.mosaicBaseConfigsState = action.payload;
        },
        setMosaicHistogramConfigsState: (
            state: SiteSelectionState,
            action: PayloadAction<MosaicConfigsState>,
        ) => {
            state.topMatchesPage.filtersState.mosaicHistogramConfigsState = action.payload;
        },
        setNewCreatedAddedSite: (
            state: SiteSelectionState,
            action: PayloadAction<TopSiteInfo[]>,
        ) => {
            state.topMatchesPage.addedSites.newCreatedAddedSite = action.payload;
        },
        setSiteLocalChanges: (
            state: SiteSelectionState,
            action: PayloadAction<
                Partial<Pick<TopSiteInfo, 'id' | 'isFavorite' | 'name'> & { removeSite?: boolean }>
            >,
        ) => {
            const {
                id,
                isFavorite: currentIsFavorite,
                name: currentName,
                removeSite,
            } = action.payload;

            if (!id) return;

            function updateTabData(records: TopSiteInfo[]) {
                const index = records.findIndex((site) => site.id === id);
                if (removeSite && index >= 0) {
                    records.splice(index, 1);
                } else {
                    if (index >= 0) {
                        records[index] = {
                            ...records[index],
                            isFavorite: currentIsFavorite ?? records[index].isFavorite,
                            name: currentName ?? records[index].name,
                        };
                    }
                }
            }

            if (state.topMatchesPage.topSites.records) {
                updateTabData(state.topMatchesPage.topSites.records);
            }
            if (state.topMatchesPage.savedSites.records) {
                updateTabData(state.topMatchesPage.savedSites.records);
            }
            if (state.topMatchesPage.savedSites.filteredRecords) {
                updateTabData(state.topMatchesPage.savedSites.filteredRecords);
            }
            if (state.topMatchesPage.addedSites.records) {
                updateTabData(state.topMatchesPage.addedSites.records);
            }
            if (state.topMatchesPage.addedSites.filteredRecords) {
                updateTabData(state.topMatchesPage.addedSites.filteredRecords);
            }

            if (state.topMatchesPage.siteOverview?.id === id) {
                if (removeSite) {
                    state.topMatchesPage.siteOverview = undefined;
                } else {
                    if (currentIsFavorite !== undefined) {
                        state.topMatchesPage.siteOverview.isFavorite = currentIsFavorite;
                    }
                    if (currentName !== undefined) {
                        state.topMatchesPage.siteOverview.name = currentName;
                    }
                }
            }
        },
        setToggleSiteIntoFavoritesList: (
            state: SiteSelectionState,
            action: PayloadAction<Pick<TopSiteInfo, 'id' | 'isFavorite'>>,
        ) => {
            const { id, isFavorite } = action.payload;
            const selectedSubSection = state.topMatchesPage.subSectionViewing;

            const topSitesState =
                selectedSubSection === 'all'
                    ? state.topMatchesPage.topSites
                    : state.topMatchesPage.addedSites;

            const savedSitesState = state.topMatchesPage.savedSites;
            const newFavoriteRecords = savedSitesState.records?.slice(0) ?? [];
            const newFavoriteFilteredRecords = savedSitesState.filteredRecords?.slice(0) ?? [];
            let totalNumOfFavoriteSitesDelta = 0;

            if (!isFavorite && savedSitesState.records) {
                // site is unsaved (un-favorite) so it needs to be removed from the favorites list
                const index = newFavoriteRecords.findIndex((site) => site.id === id);
                if (index >= 0) {
                    newFavoriteRecords.splice(index, 1);
                    totalNumOfFavoriteSitesDelta = -1;
                }
            } else if (isFavorite && topSitesState.records) {
                // site is saved (favorite) so it needs to be added to the list
                const site = topSitesState.records.find((site) => site.id === id);
                if (site) {
                    newFavoriteRecords.push(site);
                    totalNumOfFavoriteSitesDelta = 1;
                }
            }

            if (!isFavorite && savedSitesState.filteredRecords) {
                // site is unsaved (un-favorite) so it needs to be removed from the filtered-out-favorites list
                const index = newFavoriteFilteredRecords.findIndex((site) => site.id === id);
                if (index >= 0) {
                    newFavoriteFilteredRecords.splice(index, 1);
                    totalNumOfFavoriteSitesDelta = -1;
                }
            }

            savedSitesState.records = newFavoriteRecords;
            savedSitesState.filteredRecords = newFavoriteFilteredRecords;
            // update the totalNum we got from the server
            if (savedSitesState.totalNumOfSites) {
                savedSitesState.totalNumOfSites += totalNumOfFavoriteSitesDelta;
            } else {
                // a fallback for when we don't have a totalNum so we'll use our local favorite list
                // as if there is no pagination and we hold the source of truth
                savedSitesState.totalNumOfSites = newFavoriteRecords.length;
            }
        },
        setTopMatchesPageState: (
            state: SiteSelectionState,
            action: PayloadAction<TopMatchesPageState>,
        ) => {
            state.topMatchesPage = action.payload;
        },
        setMatchAnalysisPageState: (
            state: SiteSelectionState,
            action: PayloadAction<MatchAnalysisPageState>,
        ) => {
            state.matchAnalysisPage = action.payload;
        },
        setSiteOverview: (
            state: SiteSelectionState,
            action: PayloadAction<TopSiteInfo | undefined>,
        ) => {
            state.topMatchesPage.siteOverview = action.payload;
        },
        setSelectedRestrictedSite: (
            state: SiteSelectionState,
            action: PayloadAction<TopSiteInfo | undefined>,
        ) => {
            state.topMatchesPage.selectedRestrictedSite = action.payload;
        },
        setMatchAnalysisEntities: (
            state: SiteSelectionState,
            action: PayloadAction<SiteSelectionEntity[]>,
        ) => {
            const entities = action.payload;

            state.matchAnalysisPage.entities_ids = entities.map((entity) => entity.id);
            state.matchAnalysisPage.entities = entities.reduce<Record<string, SiteSelectionEntity>>(
                (entities, entity) => {
                    entities[entity.id] = entity;
                    return entities;
                },
                {},
            );
        },
        cleanSiteSelectionEntity: (state: SiteSelectionState) => {
            state.matchAnalysisPage.entities_ids = [];
            state.matchAnalysisPage.entities = {};
        },
        setUiProperties: (
            state: SiteSelectionState,
            action: PayloadAction<Partial<SiteSelectionUi>>,
        ) => {
            state.ui = {
                ...state.ui,
                ...action.payload,
            };
        },
        setChainVenues: (
            { topMatchesPage }: SiteSelectionState,
            action: PayloadAction<ReportEntitiesResponse>,
        ) => {
            topMatchesPage.chainVenues = action.payload;
        },
        setSelectedChainVenue: (
            { topMatchesPage }: SiteSelectionState,
            action: PayloadAction<SiteSelectionChainVenues | undefined>,
        ) => {
            topMatchesPage.selectedChainVenue = action.payload;
        },
        setTopMatchesSelectedRegion: (
            { topMatchesPage: { interactions } }: SiteSelectionState,
            action: PayloadAction<RegionProperties | undefined>,
        ) => {
            interactions.selectedRegion = action.payload;
        },
        setMapData: (state: SiteSelectionState, action: PayloadAction<Partial<MapData>>) => {
            state.mapData = {
                ...state.mapData,
                ...action.payload,
            };
        },
        setSitesViewState: (
            { topMatchesPage }: SiteSelectionState,
            action: PayloadAction<FilterUserPreferencesMap>,
        ) => {
            topMatchesPage.viewStates.sites.state = action.payload;
        },
        setSitesViewStateId: (
            { topMatchesPage }: SiteSelectionState,
            action: PayloadAction<string | undefined>,
        ) => {
            topMatchesPage.viewStates.sites.id = action.payload;
        },
        changeSitesViewStateFilter: (
            { topMatchesPage }: SiteSelectionState,
            action: PayloadAction<FilterUserPreferences>,
        ) => {
            topMatchesPage.viewStates.sites.state[action.payload.id] = action.payload;
        },
        setLayersViewId: (
            { topMatchesPage }: SiteSelectionState,
            action: PayloadAction<string | undefined>,
        ) => {
            topMatchesPage.viewStates.layers.id = action.payload;
        },
        setFiltersConfigs: (
            { topMatchesPage }: SiteSelectionState,
            action: PayloadAction<FilterConfigsMap>,
        ) => {
            topMatchesPage.filtersConfigs = {
                // overwrite configs but preserve the untouched ones
                ...topMatchesPage.filtersConfigs,
                ...action.payload,
            };
        },
        changeFilterConfigs: (
            { topMatchesPage }: SiteSelectionState,
            action: PayloadAction<FilterConfigs>,
        ) => {
            topMatchesPage.filtersConfigs[action.payload.id] = action.payload;
        },
        toggleAllFilters: (
            { topMatchesPage }: SiteSelectionState,
            action: PayloadAction<boolean>,
        ) => {
            Object.values(topMatchesPage.viewStates.sites.state).forEach((filter) => {
                if (!SIS_PERMANENT_FILTERS.includes(filter.id)) {
                    filter.is_applied = action.payload;
                }
            });
        },
        setIsFilterSectionOpen: (
            { topMatchesPage }: SiteSelectionState,
            action: PayloadAction<boolean>,
        ) => {
            topMatchesPage.filtersState.isFilterSectionOpen = action.payload;
        },
        setTrafficLayerViewState: (
            { topMatchesPage }: SiteSelectionState,
            action: PayloadAction<TrafficLayerViewState>,
        ) => {
            topMatchesPage.viewStates.layers.state.traffic = action.payload;
        },
        setMapSearchedEntity: (
            state: SiteSelectionState,
            action: PayloadAction<Place | undefined>,
        ) => {
            state.topMatchesPage.mapSearchedEntity = action.payload;
        },
        setSubSectionViewing: (
            { topMatchesPage }: SiteSelectionState,
            action: PayloadAction<SitesTypes>,
        ) => {
            topMatchesPage.subSectionViewing = action.payload;
        },
        setAdditionalSiteOverviewData: (
            state: SiteSelectionState,
            action: PayloadAction<{
                geojson?: GeometryTypes;
                siteTTA?: GeometryTypes;
                scores?: TopSiteInfo['scores'];
                chainComparison?: TopSiteInfo['chainComparison'];
                benchmarkComparison?: TopSiteInfo['benchmarkComparison'];
                regionData?: SiteSelectionArea;
            }>,
        ) => {
            const { geojson, siteTTA, scores, regionData, chainComparison, benchmarkComparison } =
                action.payload;

            if (state.topMatchesPage.siteOverview) {
                if (siteTTA) state.topMatchesPage.siteOverview.siteTTA = siteTTA;
                if (geojson) state.topMatchesPage.siteOverview.geojson = geojson;
                if (scores) state.topMatchesPage.siteOverview.scores = scores;
                if (chainComparison)
                    state.topMatchesPage.siteOverview.chainComparison = chainComparison;
                if (regionData) state.topMatchesPage.siteOverview.address.regionData = regionData;
                if (benchmarkComparison)
                    state.topMatchesPage.siteOverview.benchmarkComparison = benchmarkComparison;
            }
        },
        setSelectedChainVenueTTA: (
            state: SiteSelectionState,
            action: PayloadAction<GeometryTypes>,
        ) => {
            if (action.payload && state.topMatchesPage.selectedChainVenue) {
                state.topMatchesPage.selectedChainVenue.venueTTA = action.payload;
            }
        },
        setEnableSiteTTA: (
            { topMatchesPage }: SiteSelectionState,
            action: PayloadAction<boolean>,
        ) => {
            topMatchesPage.tta.enableSiteTTA = action.payload;
        },
        setEnableChainVenuesTTA: (
            { topMatchesPage }: SiteSelectionState,
            action: PayloadAction<boolean>,
        ) => {
            topMatchesPage.tta.enableChainVenuesTTA = action.payload;
        },
        setEntityTTAMetadata: (
            { topMatchesPage }: SiteSelectionState,
            action: PayloadAction<TTAMetadataResponse>,
        ) => {
            topMatchesPage.tta.entityTTAMaxZoom = +action.payload?.maxzoom;
        },
        setScoreConfig: (
            { topMatchesPage }: SiteSelectionState,
            action: PayloadAction<ScoreConfig>,
        ) => {
            topMatchesPage.scoreConfig.original = action.payload;
            // deep copy of score config for changes
            topMatchesPage.scoreConfig.current = Object.assign(action.payload);
        },
        setCurrentScoreConfig: (
            { topMatchesPage }: SiteSelectionState,
            action: PayloadAction<Partial<ScoreConfig>>,
        ) => {
            if (topMatchesPage.scoreConfig.current) {
                topMatchesPage.scoreConfig.current = {
                    ...topMatchesPage.scoreConfig.current,
                    ...action.payload,
                };
            }
        },
        resetCurrentScoreConfig: ({ topMatchesPage }: SiteSelectionState) => {
            if (topMatchesPage.scoreConfig.original) {
                // deep copy of score config for changes
                topMatchesPage.scoreConfig.current = Object.assign(
                    topMatchesPage.scoreConfig.original,
                );
            }
        },
        setDefaultScoreConfig: (
            { topMatchesPage }: SiteSelectionState,
            action: PayloadAction<ScoreConfig>,
        ) => {
            topMatchesPage.scoreConfig.default = action.payload;
        },
        setIsBlocked: ({ topMatchesPage }: SiteSelectionState, action: PayloadAction<boolean>) => {
            topMatchesPage.isBlocked = action.payload;
        },
        setSitesListPosition: (
            { topMatchesPage }: SiteSelectionState,
            action: PayloadAction<number | undefined>,
        ) => {
            topMatchesPage.sitesListPosition = action.payload;
        },
        setMatchAnalysisSite: (state: SiteSelectionState, action: PayloadAction<TopSiteInfo>) => {
            state.matchAnalysisPage.siteInfo = action.payload;
        },
        setMatchAnalysisSiteState: (state: SiteSelectionState, action: PayloadAction<string>) => {
            state.matchAnalysisPage.siteState = action.payload;
        },
        setDropPinCoordinate: (
            state: SiteSelectionState,
            action: PayloadAction<mapboxgl.LngLat | undefined>,
        ) => {
            state.topMatchesPage.dropPinCoordinate = action.payload;
        },
        setReportAccessResponse: (
            state: SiteSelectionState,
            action: PayloadAction<Dictionary<ReportAccessWithError> | undefined>,
        ) => {
            state.topMatchesPage.reportAccessResponse = action.payload;
        },
        setIsDropPinMode: (state: SiteSelectionState, action: PayloadAction<boolean>) => {
            state.topMatchesPage.isDropPinMode = action.payload;
        },
        setTrafficLayerActive: (state: SiteSelectionState, action: PayloadAction<boolean>) => {
            state.topMatchesPage.trafficLayer.active = action.payload;
        },
        setTrafficLayerActiveTab: (
            state: SiteSelectionState,
            action: PayloadAction<TrafficLayerActiveTab>,
        ) => {
            state.topMatchesPage.trafficLayer.activeTab = action.payload;
        },
        setTrafficLayerHidden: (state: SiteSelectionState, action: PayloadAction<boolean>) => {
            state.topMatchesPage.trafficLayer.hidden = action.payload;
        },
        setTrafficLayerSegments: (
            state: SiteSelectionState,
            action: PayloadAction<VolumeSegmentFeature[]>,
        ) => {
            state.topMatchesPage.trafficLayer.selectedSegments = action.payload;
        },
        setTrafficLayerConfiguration: (
            state: SiteSelectionState,
            action: PayloadAction<TrafficLayerConfiguration>,
        ) => {
            state.topMatchesPage.trafficLayer.configuration = action.payload;
        },
    },
});

export const siteSelectionReducer = {
    siteSelection: siteSelectionSlice.reducer,
};

export const siteSelectionActions = siteSelectionSlice.actions;
