import React, { createContext, useContext } from 'react';

import { GoogleMapLayersInitialState } from 'ui-components/google-map-layers/context/initial-state';
import { GoogleMapLayersProps } from 'ui-components/google-map-layers/types/google-map-layers-types';

export const GoogleMapLayersContext = createContext(GoogleMapLayersInitialState);

export const useGoogleMapLayersContextState = () => {
    return useContext(GoogleMapLayersContext);
};

export const GoogleMapLayersStateContextProvider = (props: GoogleMapLayersProps) => {
    return (
        <GoogleMapLayersContext.Provider value={props}>
            {props.children}
        </GoogleMapLayersContext.Provider>
    );
};
