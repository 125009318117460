import {
    Widget,
    FilterType,
    WidgetStatus,
    WIDGET_ACTIONS,
    WIDGET_TYPES,
    WidgetApisInfoPayload,
} from 'extensions/widget/store/widget-constants';
import {
    CleanAllWidgetsAction,
    CleanAllWidgetsByPOIAction,
    CleanWidgetContentAction,
    CleanWidgetsByIdsAction,
    CleanWidgetShowVenueLegend,
    DuplicateWidgetData,
    MarkWidgetContentAsDirty,
    SetAllWidgetsStatusAction,
    SetFieldAction,
    SetFieldsAction,
    SetMetaDataAction,
    SetPOIsAction,
    SetWidgetApiContentAction,
    SetWidgetApiInfoAction,
    SetWidgetsBatchDataAction,
} from 'extensions/widget/store/widget-reducer';
import { AppDispatch } from 'store/store';
import type { Dictionary } from '@placer-ui/types';
import { DEFAULT_API_ID } from 'extensions/widget/models/widget-apis';

export const setWidgetContent = (
    widgetID: string,
    poiIds: string | string[],
    payload?: object | null,
    apiId: string = DEFAULT_API_ID,
): SetWidgetApiContentAction => {
    const actionPayload = Array.isArray(poiIds)
        ? {
              common: payload,
              ids: poiIds,
          }
        : { [poiIds]: payload };
    return {
        type: WIDGET_ACTIONS.SET_WIDGET_API_CONTENT,
        widgetID,
        apiId,
        payload: actionPayload,
    };
};

export const setWidgetsBatchDataContent = (payload?: object | null): SetWidgetsBatchDataAction => {
    return {
        type: WIDGET_ACTIONS.SET_WIDGETS_BATCH_DATA,
        payload: payload || {},
    };
};

export type RankedVisualFilterPayload = Array<number> | Object;

export const setWidgetFilter = (
    widgetID: string,
    filterType: FilterType,
    filterName: string,
    payload: any,
): SetFieldAction => ({
    type: WIDGET_ACTIONS.SET_WIDGET_FIELD,
    widgetID,
    widgetField: filterType,
    payload: {
        [filterName]: payload,
    },
});

export const setWidgetFilterParams = (
    widgetID: string,
    filterType: FilterType,
    payload: any,
): SetFieldAction => ({
    type: WIDGET_ACTIONS.SET_WIDGET_FIELD,
    widgetID,
    widgetField: filterType,
    payload,
});

export const cleanWidgetsFetchFilters = () => ({
    type: WIDGET_ACTIONS.CLEAN_ALL_WIDGETS_FILTERS,
});

/**
 * @deprecated use the cleanWidgetsOnlyByPOIs method
 */
export const cleanWidgetsContentAndSelectors = (
    poiIdsExceptionList: string[] = [],
): CleanAllWidgetsAction => ({
    type: WIDGET_ACTIONS.CLEAN_ALL_WIDGETS,
    poiIdsExceptionList,
});

export const cleanWidgetsOnlyByPOIs = (poiIds: string[]): CleanAllWidgetsByPOIAction => ({
    type: WIDGET_ACTIONS.CLEAN_ALL_WIDGETS_BY_POI,
    poiIds,
});

export const cleanWidgetsByIds = (widgetIDs: string[]): CleanWidgetsByIdsAction => ({
    type: WIDGET_ACTIONS.CLEAN_WIDGETS_BY_IDS,
    widgetIDs,
});

export const cleanWidgetContent = (
    widgetID: string,
    apiIds?: string[],
): CleanWidgetContentAction => ({
    type: WIDGET_ACTIONS.CLEAN_WIDGET_CONTENT,
    widgetID,
    apiIds,
});

export const markWidgetContentAsDirty = (
    widgetID: string,
    apiIds?: string[],
): MarkWidgetContentAsDirty => ({
    type: WIDGET_ACTIONS.MARK_WIDGET_CONTENT_AS_DIRTY,
    widgetID,
    apiIds,
});

export const setWidgetFields = (widgetID: string, payload: Partial<Widget>): SetFieldsAction => ({
    type: WIDGET_ACTIONS.SET_WIDGET_FIELDS,
    widgetID,
    payload,
});

export const setDuplicateWidgetData = (
    uniqueId: string,
    widgetData: Widget,
): DuplicateWidgetData => ({
    type: WIDGET_ACTIONS.SET_DUPLICATE_WIDGET_DATA,
    uniqueId,
    widgetData,
});

export const setWidgetError = (
    widgetID: string,
    poiId: string,
    payload: object,
    apiId: string = DEFAULT_API_ID,
): SetWidgetApiContentAction => ({
    type: WIDGET_ACTIONS.SET_WIDGET_API_CONTENT,
    widgetID,
    apiId,
    payload: {
        [poiId]: {
            ...payload,
            isError: true,
        },
    },
});

export const setWidgetInfo = (
    widgetID: string,
    poiId: string,
    apiId: string = DEFAULT_API_ID,
    payload: WidgetApisInfoPayload,
): SetWidgetApiInfoAction => ({
    type: WIDGET_ACTIONS.SET_WIDGET_API_INFO,
    widgetID,
    apiId,
    payload: {
        [poiId]: {
            ...payload,
        },
    },
});

export const setWidgetStatus = (widgetID: string, status: WidgetStatus): SetFieldAction => ({
    type: WIDGET_ACTIONS.SET_WIDGET_FIELD,
    widgetID,
    widgetField: WIDGET_TYPES.status,
    payload: status,
});

export const setAllWidgetsStatus = (status: WidgetStatus): SetAllWidgetsStatusAction => ({
    type: WIDGET_ACTIONS.SET_ALL_WIDGETS_STATUS,
    status,
});

export const setWidgetMeta = (widgetID: string, metadata: unknown): SetMetaDataAction => ({
    type: WIDGET_ACTIONS.SET_WIDGET_METADATA,
    widgetID,
    widgetField: 'metadata',
    payload: metadata,
});

export const setWidgetVenueSelector = (
    widgetID: string,
    payload: Dictionary<boolean>,
): SetPOIsAction => ({
    type: WIDGET_ACTIONS.SET_WIDGET_POIS,
    widgetID,
    widgetField: 'venueSelector',
    payload,
});

export const wrapActionWithWidgetStatusUpdate =
    <State>(action: (dispatch: AppDispatch, getState: () => State) => void) =>
    (dispatch: AppDispatch) => {
        dispatch(setAllWidgetsStatus('PENDING'));
        dispatch(action);
        dispatch(setAllWidgetsStatus('START'));
    };

export const setWidgetShowVenueLegend = (
    widgetID: string,
    showVenueLegend: boolean,
): SetFieldAction => ({
    type: WIDGET_ACTIONS.SET_WIDGET_FIELD,
    widgetID,
    widgetField: WIDGET_TYPES.showVenueLegend,
    payload: showVenueLegend,
});

export const cleanWidgetShowVenueLegend = (widgetID: string): CleanWidgetShowVenueLegend => ({
    type: WIDGET_ACTIONS.CLEAN_WIDGET_SHOW_VENUE_LEGEND,
    widgetID,
});
