import { createAsyncThunk } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash/fp';
import { ChainsReportState } from 'features/industry/store/chains-store';
import {
    updateReportChainsFilters,
    updateReportHavingChainsEntityModels,
} from 'features/industry/store/chains-report-chains-store';
import {
    resetChainsReportSearchState,
    selectChainsEntityModelsSelectedForReportInSearch,
} from 'features/industry/store/chains-report-search-store';
import { ReportOption, ReportOptionsPayload, TagEntityModel } from 'core/entities';
import { chainsAPI } from 'API/chains-api';
import { updateIndustryReportOptions } from 'features/industry/store/chains-report-ui-state-store/actions/update-industry-report-options/update-indusrtry-report-options';
import { selectIndustry } from 'features/industry/store/common';
import type { Dictionary } from '@placer-ui/types';
import { keyBy } from 'lodash';
import { getFeatureFlags } from 'core/flow-control';
import { updateAddedPOIsBySelectedDuration } from 'utils/visit-duration/visit-duration';
import { AppDispatch } from 'store/store';

export const updateReportWithIndustries = createAsyncThunk<
    void,
    void,
    { state: ChainsReportState }
>('industry/update-report-with-chains', async (_: void, { dispatch, getState }) => {
    const typedDispatch = dispatch as AppDispatch;

    // To invoke loading before receiving 'Unexpected error' case
    typedDispatch(
        updateIndustryReportOptions({
            entitiesData: [],
        }),
    );

    const { enable_short_visit_industry_all_visits_duration_segments_ff } = getFeatureFlags();

    const state = getState();
    const industriesState = selectIndustry(state);
    const entityModelsSelectedForReportInSearch =
        selectChainsEntityModelsSelectedForReportInSearch(state);

    if (isEmpty(entityModelsSelectedForReportInSearch)) {
        return;
    }

    typedDispatch(
        updateReportHavingChainsEntityModels({
            chainEntityModels: entityModelsSelectedForReportInSearch,
            suppressMissingPermissionsPopup: true,
        }),
    );

    let newEntitiesData: Dictionary<ReportOption> = {};

    try {
        const entityIds: ReportOptionsPayload = entityModelsSelectedForReportInSearch.map(
            (entity) => ({
                entity_id: entity.originalEntity.id,
                entity_type: entity.originalEntity.type,
            }),
        );
        const { entities_data, max_date } = await chainsAPI.getChainReportOptions(entityIds);

        newEntitiesData = keyBy(entities_data, 'entity_id');

        typedDispatch(
            updateIndustryReportOptions({
                entitiesData: entities_data,
                maxDate: max_date,
            }),
        );
    } catch (e) {
        console.error(e);
        typedDispatch(
            updateIndustryReportOptions({
                entitiesData: [],
            }),
        );
    }

    if (enable_short_visit_industry_all_visits_duration_segments_ff) {
        // To update visit duration filter as selected (by the user)
        const updatedEntities = updateAddedPOIsBySelectedDuration(
            industriesState.entities as Dictionary<TagEntityModel>,
            entityModelsSelectedForReportInSearch,
            newEntitiesData,
        );

        typedDispatch(updateReportChainsFilters(updatedEntities as TagEntityModel[]));
    }

    typedDispatch(resetChainsReportSearchState());
});
