import { userAPI } from 'API/user-api';
import { EmailDataFormParameters } from 'store/auth/actions/auth-actions/auth-action-types';
import { AppDispatch } from 'store/store';

export const authForgotPassword =
    (userData: EmailDataFormParameters) => async (dispatch: AppDispatch) => {
        const response: Response = await userAPI.sendForgotPasswordEmail(userData);

        return response.status;
    };
