/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

export type RankingColorIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type RankingColorIconProps = SVGProps<SVGSVGElement> & RankingColorIconUniqueProps;

export const RankingColorIcon = function RankingColorIcon({
    width = 57,
    height = 57,
    ...props
}: RankingColorIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 57 57"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M53.77 0.0324707H3.77002C2.11317 0.0324707 0.77002 1.37562 0.77002 3.03247V53.0325C0.77002 54.6893 2.11317 56.0325 3.77002 56.0325H53.77C55.4269 56.0325 56.77 54.6893 56.77 53.0325V3.03247C56.77 1.37562 55.4269 0.0324707 53.77 0.0324707Z"
                fill="#F3F3FD"
            />
            <path
                d="M33.1111 19.45L31.4211 21.1L31.8211 23.44C31.8511 23.6 31.7811 23.76 31.6611 23.86C31.5811 23.91 31.5011 23.94 31.4111 23.94C31.3411 23.94 31.2711 23.92 31.2111 23.89L29.1211 22.79L27.0311 23.89C26.8911 23.97 26.7111 23.95 26.5811 23.86C26.4511 23.76 26.3911 23.6 26.4211 23.44L26.8211 21.1L25.1311 19.45C25.0111 19.34 24.9711 19.17 25.0211 19.01C25.0711 18.85 25.2011 18.74 25.3611 18.72L27.7011 18.38L28.7411 16.25C28.8811 15.96 29.3611 15.96 29.5011 16.25L30.5411 18.38L32.8811 18.72C33.0411 18.74 33.1711 18.86 33.2211 19.01C33.2711 19.16 33.2211 19.33 33.1111 19.45Z"
                fill="#E1E2FF"
                stroke="#676DFD"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M41 30.1739V39.8911C41 39.9682 40.8999 40.0325 40.7797 40.0325H33.2203C33.1001 40.0325 33 39.9682 33 39.8911V30.1739C33 30.0967 33.1001 30.0325 33.2203 30.0325H40.7797C40.8999 30.0325 41 30.0967 41 30.1739Z"
                fill="white"
                stroke="#676DFD"
                strokeLinecap="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M33 26.2304V39.8345C33 39.9425 32.8999 40.0325 32.7797 40.0325H25.2203C25.1001 40.0325 25 39.9425 25 39.8345V26.2304C25 26.1224 25.1001 26.0325 25.2203 26.0325H32.7797C32.8999 26.0325 33 26.1224 33 26.2304Z"
                fill="#E1E2FF"
                stroke="#676DFD"
                strokeLinecap="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25 33.1353V39.9296C25 39.9841 24.9 40.0325 24.78 40.0325H17.22C17.1 40.0325 17 39.9901 17 39.9296V33.1353C17 33.0809 17.1 33.0325 17.22 33.0325H24.77C24.89 33.0325 24.99 33.0748 24.99 33.1353H25Z"
                fill="white"
                stroke="#676DFD"
                strokeLinecap="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M29.4981 31.0325H28V31.7821H29.2509V36.0325H30V31.5347C30 31.2574 29.7753 31.0325 29.4981 31.0325Z"
                fill="#676DFD"
            />
        </svg>
    );
};
RankingColorIcon.__tags = [] as string[];
