import { STICKY_HEADER_POSITION_THRESHOLD } from 'features/void-analysis/constants';
import { useWindowScrollEffect } from 'hooks/use-window-scroll-effect/use-window-scroll-effect';
import { useState } from 'react';
import { useEntityData, useFormattedReportDates, useReportMetadata } from '../../hooks/hooks';
import {
    createMatchAnalysisMethodInfoDescription,
    createTopMatchesMethodInfoDescription,
} from 'features/void-analysis/common/hooks/method-data';

export const useStickyHeader = () => {
    const [sticky, setStickyHeader] = useState<boolean>(false);
    useWindowScrollEffect(({ offsetY }) => {
        if (offsetY > STICKY_HEADER_POSITION_THRESHOLD && !sticky) {
            setStickyHeader(true);
        } else if (offsetY <= STICKY_HEADER_POSITION_THRESHOLD && sticky) {
            setStickyHeader(false);
        }
    }, []);
    return sticky;
};

export const useHeaderContent = () => {
    const metadata = useReportMetadata();
    const chainData = useEntityData();
    const chainName = chainData?.name;
    const formattedDateRange = useFormattedReportDates();

    const methodTopMatchesInfoDescription = createTopMatchesMethodInfoDescription(
        metadata?.methodData,
    );
    const methodMatchAnalysisInfoDescription = createMatchAnalysisMethodInfoDescription(
        metadata?.methodData,
        chainData,
    );

    const {
        entity: { name, geolocation, formattedAddress, geojson, address },
    } = metadata!;

    const mapMarkers = [
        {
            ...geolocation,
            label: name,
        },
    ];

    const mapGeometries = [geojson];

    return {
        mapMarkers,
        mapGeometries,
        formattedDateRange,
        formattedAddress,
        name,
        chainName,
        methodTopMatchesInfoDescription,
        methodMatchAnalysisInfoDescription,
        address,
    };
};
