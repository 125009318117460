import { useCallback } from 'react';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';

import type { Attribute, FilterType } from '@placer-ui/types';
import {
    AUDIENCE_TYPE_ATTRIBUTE,
    LENGTH_OF_STAY_ATTRIBUTE,
} from 'core/constants/filter-attributes';
import { useReportPlaces } from 'extensions/widget/hooks/use-poi-hooks';
import { Filter } from 'features/insights/types/filter-types';
import { FilterOperator, FilterValue } from '@placer-ui/types';
import { formatDefaultValue } from 'features/mobile-report/components/analyze-page/utils/utils';

const FILTERS_WITH_DEFAULT_SUPPORT: FilterType[] = [
    AUDIENCE_TYPE_ATTRIBUTE,
    LENGTH_OF_STAY_ATTRIBUTE,
];

export const useEntitiesDefaultFilters = () => {
    const { all } = useReportPlaces();

    const areSameOriginalDefaultFilters = useCallback(
        (type: FilterType) => {
            if (type === LENGTH_OF_STAY_ATTRIBUTE) {
                const firstDefault = all[0]?.originalEntity.default_profile_name;
                return (
                    firstDefault &&
                    all.every(
                        ({ originalEntity }) =>
                            originalEntity.default_profile_name === firstDefault,
                    )
                );
            } else {
                const primaryDefaultFilters = all[0]?.originalEntity.default_filters?.filter(
                    (defaultFilters: Attribute) =>
                        isArray(defaultFilters) && defaultFilters[1] === type,
                );
                return (
                    all.length === 1 ||
                    all.every(
                        (entity) =>
                            entity.originalEntity.default_filters?.length &&
                            isEqual(
                                primaryDefaultFilters,
                                entity.originalEntity.default_filters.filter(
                                    (defaultFilters: Attribute) =>
                                        isArray(defaultFilters) && defaultFilters[1] === type,
                                ),
                            ),
                    ) ||
                    all.every((entity) => !entity.originalEntity.default_filters?.length)
                );
            }
        },
        [all],
    );

    const areDefaultFilter = useCallback(
        (filters: Filter[], filterType: FilterType) => {
            return (
                !areSameOriginalDefaultFilters(filterType) &&
                filters.every((filter) => filter[filterType]?.isDefaultFilter)
            );
        },
        [areSameOriginalDefaultFilters],
    );

    const getDefaultFilters = useCallback(
        (filters: Filter[]) => {
            return FILTERS_WITH_DEFAULT_SUPPORT.reduce(
                (acc, filterType) => {
                    return areDefaultFilter(filters, filterType)
                        ? {
                              ...acc,
                              [filterType]: {
                                  operator: '==' as FilterOperator,
                                  value: formatDefaultValue[
                                      filterType as FilterType
                                  ] as FilterValue,
                                  isDefaultFilter: true,
                              },
                          }
                        : acc;
                },
                { ...filters[0] },
            );
        },
        [areDefaultFilter],
    );

    const getFilterValueByType = useCallback(
        (type: FilterType) =>
            all[0]?.originalEntity.default_filters?.find(
                (defaultFilters: Attribute) =>
                    isArray(defaultFilters) && defaultFilters[1] === type,
            )?.[2],

        [all],
    );

    return {
        areDefaultFilter,
        areSameOriginalDefaultFilters,
        getFilterValueByType,
        getDefaultFilters,
    };
};
