import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

import { IconProps } from 'components/assets/Icons/Icons';

/* eslint-disable max-len */
export const ElectronicsIconSvg = (props: CustomIconComponentProps | SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 17 16">
        <g transform="translate(.162)" fill="currentColor" fillRule="evenodd">
            <path d="M0 4h12.15v4L8.1 13.333H4.05L0 8z" />
            <path
                fillRule="nonzero"
                d="M16.2 1.333v10c0 2.506-2 4.55-4.509 4.662l-.216.005h-2.7c-1.802 0-3.274-1.395-3.37-3.15l-.005-.183v-.193h1.35v.193a2.01 2.01 0 001.874 1.994l.151.006h2.7c1.802 0 3.274-1.395 3.37-3.15l.005-.184v-10h1.35z"
            />
            <rect width="2.025" height="6.667" x="2.025" rx="1" />
            <rect width="2.025" height="6.667" x="8.1" rx="1" />
        </g>
    </svg>
);

export const ElectronicsIcon = (props: IconProps) => (
    <Icon component={ElectronicsIconSvg} {...props} />
);
