import { EmptySpaceOption } from 'features/empty-space/types/types';
import { ERRORS } from 'API/common/constants';
import { EMPTY_SPACE_COORDINATE_ID } from 'features/empty-space/constants/empty-space-constants';
import {
    ADDRESS_IN_RESTRICTED_AREA,
    ADDRESS_NOT_DELIVERABLE_ERROR,
    COORDINATE_IN_RESTRICTED_AREA,
    COORDINATE_NOT_DELIVERABLE_ERROR,
} from 'shared/API/report-access-api/constants';

export const creatEmptySpaceName = (emptySpaceOption: EmptySpaceOption) => {
    if (emptySpaceOption.name) {
        return emptySpaceOption.name;
    }

    return emptySpaceOption.address ?? `${emptySpaceOption.lat}, ${emptySpaceOption.lng}`;
};

export const adjustmentEmptySpaceCoordinate = (emptySpaceOption: EmptySpaceOption) => {
    return {
        ...emptySpaceOption,
        lat: Number(emptySpaceOption.lat.toFixed(5)),
        lng: Number(emptySpaceOption.lng.toFixed(5)),
    };
};

export const getRunBtnTooltipText = (poiError: string | object, id: string) => {
    if (poiError === ERRORS.POLYGON_TOO_SMALL) {
        if (id === EMPTY_SPACE_COORDINATE_ID) {
            return COORDINATE_IN_RESTRICTED_AREA;
        } else {
            return ADDRESS_IN_RESTRICTED_AREA;
        }
    } else if (poiError === ERRORS.UNDELIVERABLE_ENTITY) {
        if (id === EMPTY_SPACE_COORDINATE_ID) {
            return COORDINATE_NOT_DELIVERABLE_ERROR;
        } else {
            return ADDRESS_NOT_DELIVERABLE_ERROR;
        }
    } else {
        return '';
    }
};
