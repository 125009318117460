import React, { ComponentProps, useEffect, useState } from 'react';

import { NavigationButton } from 'features/header/navigation/components/common/navigation-button';
import { pageNameFromURL } from 'utils/get-from-url/get-from-url';

export const GisNavItem = ({
    link,
    label,
    isSelected,
    onClick,
}: ComponentProps<typeof NavigationButton>) => {
    const [shouldBeVisible, setIsVisible] = useState(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        const pageName = pageNameFromURL();
        const isGisPage = pageName === 'gis';

        if (isGisPage && !shouldBeVisible) {
            setIsVisible(true);
            return;
        }

        if (shouldBeVisible && !isGisPage) {
            setIsVisible(false);
        }
    });

    if (!shouldBeVisible) {
        return null;
    }

    return <NavigationButton label={label} link={link} isSelected={isSelected} onClick={onClick} />;
};
