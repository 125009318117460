import { ChainEntityModel, ReportOptions } from 'core/entities';
import { AppDispatch } from 'store/store';
import { updateReportChainsFilters } from 'features/chains/store/chains-report-chains-store';
import { ChainsReportState } from 'features/chains/store/chains-store';
import moment from 'moment';
import { cloneDeep } from 'utils/clone-deep/clone-deep';
import { set } from 'lodash';
import { DATE_FORMAT } from 'features/chains/common/constants';
import { setChainsReportOptions } from 'features/chains/store/chains-report-ui-state-store';

export const updateChainsReportOptions =
    (reportOptions: ReportOptions) =>
    (dispatch: AppDispatch, getState: () => ChainsReportState) => {
        dispatch(setChainsReportOptions(reportOptions));

        /**
         * The following lines handle the selected date range -
         * if it does not fit the max_date that come from the report options
         */
        const {
            chains: { entities },
        } = getState();

        const { maxDate } = reportOptions;
        const entitiesArr = Object.values(entities);
        const firstFilter = entitiesArr[0]?.filters;

        if (!(firstFilter && maxDate)) {
            return;
        }

        const { date_start, date_end } = firstFilter;

        const isMaxDateBeforeEndDate = moment(maxDate).isBefore(date_end);
        const isMaxDateBeforeStartDate = moment(maxDate).isBefore(date_start);

        if (isMaxDateBeforeEndDate || isMaxDateBeforeStartDate) {
            const newChainsFilters = entitiesArr.map((chain) => {
                const newCain = cloneDeep(chain);

                set(
                    newCain,
                    'filters.date_start',
                    isMaxDateBeforeStartDate
                        ? moment(maxDate).subtract(1, 'day').format(DATE_FORMAT)
                        : date_start,
                );
                set(newCain, 'filters.date_end', isMaxDateBeforeEndDate ? maxDate : date_end);

                return newCain as ChainEntityModel;
            });

            dispatch(updateReportChainsFilters(newChainsFilters));
        }
    };
