import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Error404 } from 'components/errors/error-404/error-404';
import { UnexpectedError } from 'components/errors/unexpected-error/unexpected-error';
import type { ErrorComponentsProps } from 'router/errors/error-types';

//TODO: add support for all router error types
export const ErrorComponents = ({ failBackUrl, ctaText }: ErrorComponentsProps) => {
    const { path, url } = useRouteMatch();
    const is404 = path.endsWith('*') && url !== '/unexpected-error';
    const ErrorComponent = is404 ? Error404 : UnexpectedError;
    return <ErrorComponent failBackUrl={failBackUrl} ctaText={ctaText} />;
};
