import React from 'react';
import {
    MethodInfoDescription,
    ReportMethodData,
} from 'features/void-analysis/common/types/report-method-metadata';
import { VoidEntity } from 'features/void-analysis/common/types/entities';
import { DriveTimeIcon, TradeAreaIcon } from 'components/assets/Icons/Icons';
import { distanceNumberFormat } from 'features/void-analysis/sections/empty-space/hooks/use-method-selector-modal-hooks';

export const ttaTopMatchesDescription = 'Analyzed using true trade area.';
export const driveTimeTopMatchesDescription = 'Analyzed using trade area by drive time.';
export const nearbyDescription = 'Analyzed using true trade area of';

export const ttaMatchAnalysisDescription = 'Analysis is based on true trade area.';

const emptyMethodInfoDescription: MethodInfoDescription = {
    infoDescription: '',
    icon: null,
};

export const createMatchAnalysisMethodInfoDescription = (
    methodData: ReportMethodData | undefined,
    chainData: VoidEntity | undefined,
): MethodInfoDescription => {
    if (!methodData) {
        return emptyMethodInfoDescription;
    }
    switch (methodData.ta_method) {
        case 'tta':
            return {
                infoDescription: ttaMatchAnalysisDescription,
                icon: <TradeAreaIcon />,
            };
        case 'drive_time_ta':
            return chainData && chainData.median_dvt
                ? {
                      infoDescription: `Analysis is based on ${chainData.name}
                        national median drive time of ${chainData.median_dvt} minutes.`,
                      icon: <DriveTimeIcon />,
                  }
                : emptyMethodInfoDescription;
        case 'nearby_entity_ta':
            return {
                infoDescription: makeNearbyDescription(
                    methodData.ta_entity_name,
                    methodData.ta_entity_distance,
                ),
                icon: <TradeAreaIcon />,
            };
        default:
            return emptyMethodInfoDescription;
    }
};

export const createTopMatchesMethodInfoDescription = (
    methodData: ReportMethodData | undefined,
): MethodInfoDescription => {
    if (!methodData) {
        return emptyMethodInfoDescription;
    }
    switch (methodData.ta_method) {
        case 'tta':
            return {
                infoDescription: ttaTopMatchesDescription,
                icon: <TradeAreaIcon />,
            };
        case 'drive_time_ta':
            return {
                infoDescription: driveTimeTopMatchesDescription,
                icon: <DriveTimeIcon />,
            };
        case 'nearby_entity_ta':
            return {
                infoDescription: makeNearbyDescription(
                    methodData.ta_entity_name,
                    methodData.ta_entity_distance,
                ),
                icon: <TradeAreaIcon />,
            };
        default:
            return emptyMethodInfoDescription;
    }
};

const makeNearbyDescription = (entityName: string, entityDistance?: number) => {
    if (!entityDistance) {
        return `${nearbyDescription} ${entityName}`;
    }

    const { unit, value } = distanceNumberFormat(entityDistance);
    return `${nearbyDescription} ${entityName} located
                 ${value} ${unit} away.`;
};
