import { FetchFilter } from 'extensions/widget/models/widget-model';
import { WidgetPoi } from 'extensions/widget/models/widget-poi-provider-type';

export interface APIEndpointConfiguration {
    getUrl: (poi: WidgetPoi) => string;
    method: 'GET' | 'POST';
    headers?: Record<string, string>;
    getBody?: (poi: WidgetPoi) => Record<string, any>;
}

export type WidgetAPI<FetchFiltersType> = APIEndpointConfiguration & {
    dependencies?: (keyof FetchFiltersType)[];
    isBlocking?: boolean;
    combinedContent?: boolean;
    shouldCallApi?: (poi: WidgetPoi) => boolean;
    shouldUseDebounce?: boolean;
    enableContentLoading?: boolean;
    isSharedMetaData?: boolean;
    normalizer?: (data: object) => object;
};

export type WidgetAPIConfiguration<
    WidgetApiIds extends string = string,
    FetchFiltersType = FetchFilter,
> = Record<WidgetApiIds, WidgetAPI<FetchFiltersType>>;

/** For backward compatibility with single-API widgets */
export const DEFAULT_API_ID = 'default';
