import type { Place } from '@placer-ui/types';
import { BillboardPOI, ChainPOI, TagPOI, VenuePOI } from 'core/entities';
import {
    isRecentEntity,
    RecentEntity,
    isRestrictedArea as isRecentEntityRestrictedArea,
} from 'core/entities/recent-entity/recent-entity';
import { EMPTY_SPACE_POI_TYPE } from 'features/empty-space/constants/empty-space-constants';

export const getReportEntityLocationLabel = (entity: Place | RecentEntity) => {
    if (isRecentEntity(entity)) {
        return entity.locationLabel;
    }

    if (VenuePOI.isVenue(entity)) {
        return VenuePOI.getLocationLabel(entity);
    }

    if (ChainPOI.isChain(entity)) {
        return ChainPOI.getLocationLabel(entity);
    }

    if (BillboardPOI.isBillboard(entity)) {
        return BillboardPOI.getLocationLabel(entity);
    }

    if (TagPOI.isTag(entity)) {
        return '';
    }

    return '';
};

export const getReportEntityClosingDate = (entity: Place | RecentEntity) => {
    if (isRecentEntity(entity)) {
        return entity.closingDate;
    }

    if (VenuePOI.isVenue(entity)) {
        return VenuePOI.getClosingInfo(entity)?.closing_date;
    }

    return;
};

export const getReportEntityFlag = (entity: Place | RecentEntity) => {
    if (entity.type === 'tag') {
        return;
    }

    if (isRecentEntity(entity)) {
        return entity.flag;
    }

    if (VenuePOI.isVenue(entity)) {
        return VenuePOI.getReportEntityFlag(entity);
    }

    return;
};

export const getReportEntityProfile = (entity: Place | RecentEntity) => {
    const defaultProfile = 'available';
    if (isRecentEntity(entity) || entity.type === EMPTY_SPACE_POI_TYPE) {
        return entity?.profile ?? defaultProfile;
    }

    if (VenuePOI.isVenue(entity)) {
        return VenuePOI.getReportEntityProfile(entity) ?? defaultProfile;
    }

    return defaultProfile;
};

export const isRestrictedArea = (entity: Place | RecentEntity) => {
    if (isRecentEntity(entity)) {
        return isRecentEntityRestrictedArea(entity);
    }

    if (VenuePOI.isVenue(entity)) {
        return VenuePOI.isRestrictedArea(entity);
    }

    return false;
};
