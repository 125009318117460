import { useGoogleMap } from '@react-google-maps/api';
import { useCallback, useEffect, useState } from 'react';
import { MAP_INITIAL_ZOOM } from 'features/insights/widgets/trade-area/trade-area-heatmap/constants';
import { useMap } from 'react-map-gl';

export const useMapZoom = () => {
    const mapInstance = useGoogleMap();
    const [zoom, setZoom] = useState<number>(MAP_INITIAL_ZOOM);

    const setMapZoom = useCallback(() => {
        if (mapInstance) {
            setZoom(mapInstance.getZoom());
        }
    }, [mapInstance]);

    useEffect(() => {
        mapInstance && setMapZoom();
        mapInstance && mapInstance.addListener('zoom_changed', setMapZoom);

        return () => {
            mapInstance && mapInstance.unbind('zoom_changed');
        };
    }, [mapInstance, setMapZoom]);

    return zoom;
};

export const useMapboxMapZoom = () => {
    const mapInstance = useMap();
    return mapInstance.current ? mapInstance.current.getZoom() : MAP_INITIAL_ZOOM;
};

export const useCommonZoom = (mapboxBeta: boolean) => {
    return mapboxBeta ? useMapboxMapZoom : useMapZoom;
};
