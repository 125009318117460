/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

type BarChartWithQuestionMarkIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type BarChartWithQuestionMarkIconProps = SVGProps<SVGSVGElement> &
    BarChartWithQuestionMarkIconUniqueProps;

export const BarChartWithQuestionMarkIcon = function BarChartWithQuestionMarkIcon({
    width = 27,
    height = 26,
    ...props
}: BarChartWithQuestionMarkIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 27 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M8.29093 10.1502V9.81877C8.29093 9.55921 8.32074 9.33298 8.38037 9.14007C8.44 8.94716 8.53996 8.76652 8.68026 8.59816C8.82056 8.4263 9.00996 8.24741 9.24847 8.06152C9.49399 7.86861 9.68866 7.702 9.83247 7.5617C9.97978 7.4214 10.085 7.2811 10.1481 7.1408C10.2148 6.997 10.2481 6.83039 10.2481 6.64099C10.2481 6.34285 10.1464 6.11662 9.94295 5.96229C9.74303 5.80445 9.46067 5.72554 9.09589 5.72554C8.77321 5.72554 8.46981 5.77113 8.1857 5.86233C7.9016 5.95352 7.621 6.06751 7.34391 6.20431L6.90723 5.27833C7.22641 5.10296 7.57189 4.96266 7.94369 4.85743C8.31899 4.7487 8.73112 4.69434 9.18007 4.69434C9.8956 4.69434 10.448 4.86796 10.8374 5.2152C11.2302 5.56244 11.4266 6.02016 11.4266 6.58838C11.4266 6.90054 11.3775 7.16886 11.2793 7.39334C11.1811 7.61431 11.0355 7.8195 10.8426 8.00891C10.6532 8.1948 10.4235 8.39122 10.1534 8.59816C9.93243 8.77354 9.76232 8.92611 9.64306 9.05589C9.52731 9.18216 9.44664 9.31018 9.40105 9.43996C9.35896 9.56973 9.33791 9.72582 9.33791 9.90821V10.1502H8.29093ZM8.091 11.8443C8.091 11.5567 8.16466 11.355 8.31197 11.2393C8.46279 11.12 8.64694 11.0604 8.8644 11.0604C9.07485 11.0604 9.25549 11.12 9.40631 11.2393C9.55713 11.355 9.63254 11.5567 9.63254 11.8443C9.63254 12.1249 9.55713 12.3284 9.40631 12.4546C9.25549 12.5774 9.07485 12.6388 8.8644 12.6388C8.64694 12.6388 8.46279 12.5774 8.31197 12.4546C8.16466 12.3284 8.091 12.1249 8.091 11.8443Z"
                fill="#49565D"
            />
            <circle cx="9.16667" cy="8.66667" r="8.16667" stroke="#49565D" />
            <path
                d="M22.1666 8.6665C21.7677 8.6665 21.4443 8.98985 21.4443 9.38873V25.2776C21.4443 25.6765 21.7677 25.9998 22.1666 25.9998H25.7777C26.1765 25.9998 26.4999 25.6765 26.4999 25.2776V9.38873C26.4999 8.98985 26.1765 8.6665 25.7777 8.6665H22.1666Z"
                fill="#D0D0D0"
            />
            <path
                d="M14.9443 16.611C14.9443 16.2121 15.2677 15.8888 15.6665 15.8888L19.2777 15.8887C19.6765 15.8887 19.9999 16.2121 19.9999 16.6109V25.2776C19.9999 25.6765 19.6765 25.9998 19.2777 25.9998H15.6666C15.2677 25.9998 14.9443 25.6765 14.9443 25.2776V16.611Z"
                fill="#D0D0D0"
            />
            <path
                d="M9.16656 20.2221C8.76769 20.2221 8.44434 20.5454 8.44434 20.9443V25.2776C8.44434 25.6765 8.76769 25.9998 9.16656 25.9998H12.7777C13.1765 25.9998 13.4999 25.6765 13.4999 25.2776V20.9443C13.4999 20.5454 13.1765 20.2221 12.7777 20.2221H9.16656Z"
                fill="#D0D0D0"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.1666 8.6665C21.7677 8.6665 21.4443 8.98985 21.4443 9.38873V25.2776C21.4443 25.6765 21.7677 25.9998 22.1666 25.9998H25.7777C26.1765 25.9998 26.4999 25.6765 26.4999 25.2776V9.38873C26.4999 8.98985 26.1765 8.6665 25.7777 8.6665H22.1666ZM22.5277 24.9165H25.4166V9.74984H22.5277V24.9165Z"
                fill="#49565D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.6665 15.8888C15.2677 15.8888 14.9443 16.2121 14.9443 16.611V25.2776C14.9443 25.6765 15.2677 25.9998 15.6666 25.9998H19.2777C19.6765 25.9998 19.9999 25.6765 19.9999 25.2776V16.6109C19.9999 16.2121 19.6765 15.8887 19.2777 15.8887L15.6665 15.8888ZM16.0277 16.9721V24.9165H18.9166V16.9721L16.0277 16.9721Z"
                fill="#49565D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.16656 20.2221C8.76769 20.2221 8.44434 20.5454 8.44434 20.9443V25.2776C8.44434 25.6765 8.76769 25.9998 9.16656 25.9998H12.7777C13.1765 25.9998 13.4999 25.6765 13.4999 25.2776V20.9443C13.4999 20.5454 13.1765 20.2221 12.7777 20.2221H9.16656ZM9.52767 21.3054V24.9165H12.4166V21.3054H9.52767Z"
                fill="#49565D"
            />
        </svg>
    );
};
BarChartWithQuestionMarkIcon.__tags = ['bar', 'chart', 'question mark', 'diagram'] as string[];
