export const POLLING_STATUS = {
    PENDING: 'PENDING',
    RUNNING: 'RUNNING',
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED',
    ACCEPTED: 'ACCEPTED',
    IN_PROGRESS: 'IN_PROGRESS',
} as const;

export const RETRY_MS: number = 5000;

export const ATTEMPT_NUM_TO_DELAY_MS: Record<number, number> = {
    1: 1000,
    2: 2000,
    3: 4000,
};
