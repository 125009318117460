import { useAppUiActions } from 'store/app-ui/use-app-ui-actions';
import { waitForMessages } from 'core/services/post-message-service/post-message-service';
import { UPGRADE_PLAN_SCREEN } from 'extensions/upgrade-your-plan-dialog';
import { useCallback, useEffect, useMemo } from 'react';

type MessageEventName = 'pl-upgrade-plan';

export function useMapMessageEventAction() {
    const { openUpgradePlanPopup } = useAppUiActions();

    const messageActionMap: Record<MessageEventName, () => void> = useMemo(
        () => ({
            'pl-upgrade-plan': () => openUpgradePlanPopup(UPGRADE_PLAN_SCREEN.default),
        }),
        [openUpgradePlanPopup],
    );

    const handleMessageAction = useCallback(
        (e: MessageEvent) => {
            const { message } = e.data as { message: MessageEventName };
            const action = messageActionMap[message];
            if (action) {
                action();
            }
        },
        [messageActionMap],
    );

    useEffect(() => {
        waitForMessages('*', handleMessageAction);
    }, [handleMessageAction]);
}
