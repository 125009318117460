import { ERRORS } from 'API/common/constants';
import { POLLING_STATUS } from 'API/common/poll-request/constants';

export const RESPONSE_STATUSES = {
    ...ERRORS,
    ...POLLING_STATUS,
} as const;

export type ResponseStatuses = typeof RESPONSE_STATUSES[keyof typeof RESPONSE_STATUSES];

export const RETRY_MS: number = 5000;
