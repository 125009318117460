/* eslint-disable max-len */
import React from 'react';
import type { SVGProps } from 'react';

export type FavoriteHeartIconUniqueProps = {
    width?: number | string;
    height?: number | string;
};

type FavoriteHeartIconProps = SVGProps<SVGSVGElement> & FavoriteHeartIconUniqueProps;

export const FavoriteHeartIcon = function FavoriteHeartIcon({
    width = 16,
    height = 16,
    ...props
}: FavoriteHeartIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="1 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.39762 14.7145C8.73895 15.031 9.26162 15.031 9.60206 14.7145C10.5203 13.863 12.2901 12.2203 13.5061 11.0887C15.2127 9.50118 16.667 8.00696 16.667 5.60518C16.667 3.68696 15.4687 1.62207 12.8376 1.62207C11.4803 1.62207 10.8101 2.29763 10.1016 3.01229C9.86517 3.2514 9.48206 3.62474 9.23495 3.86385C9.10251 3.99274 8.89717 3.99274 8.76473 3.86385C8.51762 3.62474 8.13451 3.2514 7.89806 3.01229C7.18962 2.29763 6.52029 1.62207 5.16206 1.62207C2.53184 1.62207 1.33362 3.68696 1.33362 5.60518C1.33362 8.00696 2.78784 9.50118 4.49451 11.0887C5.71051 12.2203 7.48029 13.863 8.39762 14.7145Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.39762 14.7145C8.73895 15.031 9.26162 15.031 9.60206 14.7145C10.5203 13.863 12.2901 12.2203 13.5061 11.0887C15.2127 9.50118 16.667 8.00696 16.667 5.60518C16.667 3.68696 15.4687 1.62207 12.8376 1.62207C11.4803 1.62207 10.8101 2.29763 10.1016 3.01229C9.86517 3.2514 9.48206 3.62474 9.23495 3.86385C9.10251 3.99274 8.89717 3.99274 8.76473 3.86385C8.51762 3.62474 8.13451 3.2514 7.89806 3.01229C7.18962 2.29763 6.52029 1.62207 5.16206 1.62207C2.53184 1.62207 1.33362 3.68696 1.33362 5.60518C1.33362 8.00696 2.78784 9.50118 4.49451 11.0887C5.71051 12.2203 7.48028 13.863 8.39762 14.7145Z"
                stroke="#49565D"
                strokeWidth="0.75"
                strokeLinecap="round"
            />
        </svg>
    );
};
FavoriteHeartIcon.__tags = ['heart', 'favorite', 'like', 'love'] as string[];
