/* eslint-disable max-len */
import React from 'react';

export const SgFinanceIcon = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="10" cy="10" r="9.5" fill="white" stroke="#5E63E5" />
            <rect x="3" y="3" width="14" height="14" rx="7" fill="#E1E2FF" />
            <path
                d="M9.54932 15.3101V14.0024C9.02458 13.994 8.54004 13.9474 8.0957 13.8628C7.6556 13.7739 7.27474 13.6597 6.95312 13.52V12.168C7.27897 12.3203 7.68099 12.4578 8.15918 12.5806C8.63737 12.7033 9.10075 12.7731 9.54932 12.79V10.4097C8.93571 10.2023 8.43636 9.98649 8.05127 9.76221C7.67041 9.53792 7.39111 9.27767 7.21338 8.98145C7.03564 8.68099 6.94678 8.32129 6.94678 7.90234C6.94678 7.4707 7.05469 7.10042 7.27051 6.7915C7.49056 6.47835 7.79525 6.23079 8.18457 6.04883C8.57812 5.86686 9.03304 5.75895 9.54932 5.7251V4.69043H10.3745V5.70605C10.8442 5.71875 11.278 5.77165 11.6758 5.86475C12.0778 5.95361 12.4608 6.07845 12.8247 6.23926L12.3613 7.41992C12.0439 7.29297 11.7139 7.18929 11.3711 7.10889C11.0283 7.02848 10.6961 6.97347 10.3745 6.94385V9.26709C10.8908 9.43636 11.3499 9.61833 11.752 9.81299C12.1582 10.0076 12.4756 10.2531 12.7041 10.5493C12.9368 10.8413 13.0532 11.2243 13.0532 11.6982C13.0532 12.3203 12.8247 12.8345 12.3677 13.2407C11.9106 13.6427 11.2463 13.884 10.3745 13.9644V15.3101H9.54932ZM10.3745 12.7393C10.7808 12.6885 11.0833 12.5827 11.2822 12.4219C11.4811 12.2568 11.5806 12.0431 11.5806 11.7808C11.5806 11.603 11.5382 11.4528 11.4536 11.3301C11.3732 11.2074 11.2441 11.0973 11.0664 11C10.8929 10.9027 10.6623 10.8075 10.3745 10.7144V12.7393ZM9.54932 8.95605V6.96924C9.30387 6.99463 9.09652 7.04541 8.92725 7.12158C8.75798 7.19775 8.63102 7.2972 8.54639 7.41992C8.46175 7.54264 8.41943 7.68864 8.41943 7.85791C8.41943 8.04411 8.45752 8.2028 8.53369 8.33398C8.60986 8.46517 8.73047 8.57943 8.89551 8.67676C9.06478 8.77409 9.28271 8.86719 9.54932 8.95605Z"
                fill="#5E63E5"
            />
        </svg>
    );
};
