import React from 'react';
import { Provider } from 'react-redux';

import { store } from 'store/store';

type AppStoreProviderProps = {
    children: React.ReactNode;
    createStore?: () => void;
};

export const AppStoreProvider = ({ createStore, children }: AppStoreProviderProps) => {
    if (createStore) {
        createStore();
    }
    return <Provider store={store}>{children}</Provider>;
};
