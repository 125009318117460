import React, { useRef } from 'react';
import styles from './poi-selection-list-item.module.scss';
import { useShowTooltipIfNeeded } from 'features/poi-selection-map/hooks/poi-selection-hooks';
import { Tooltip } from 'ui-components/tooltip/tooltip';

type PoiSelectionListItemProps = {
    title: string;
    subtitle?: string;
};

export const PoiSelectionListItem = ({ title, subtitle }: PoiSelectionListItemProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const showTooltip = useShowTooltipIfNeeded(containerRef);
    const tooltipTitle = (
        <>
            <div>{title}</div>
            {subtitle && <div>{subtitle}</div>}
        </>
    );
    return (
        <div className={styles.container} ref={containerRef}>
            <Tooltip title={showTooltip ? tooltipTitle : ''} placement={'top'}>
                <div className={styles.title}>{title}</div>
                {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
            </Tooltip>
        </div>
    );
};
