import React, { ChangeEvent } from 'react';
import { Input } from 'antd';
import { ClearSearchIcon, SearchIcon } from 'components/assets/Icons/Icons';
import styles from './starred-reports-search.module.scss';
import classNames from 'classnames';

type StarredReportsSearchProps = {
    value: string;
    placeholder?: string;
    onChange: (entry: string) => void;
    className?: string;
};

export const StarredReportsSearch = ({
    value,
    placeholder,
    onChange,
    className,
}: StarredReportsSearchProps) => {
    const onClear = () => {
        onChange('');
    };
    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {
            target: { value },
        } = e;
        onChange(value);
    };

    const iconOnTheRight = value ? (
        <div onClick={onClear}>
            <ClearSearchIcon className={styles.clearSearchIcon} />
        </div>
    ) : (
        <SearchIcon className={styles.searchIcon} />
    );

    return (
        <div className={classNames(styles.searchWrapper, className)}>
            <Input
                value={value}
                suffix={iconOnTheRight}
                allowClear={false}
                onChange={handleOnChange}
                className={styles.searchBox}
                placeholder={placeholder}
            />
        </div>
    );
};
